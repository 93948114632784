import {
  BrowserObject, Command, CommandInput, GmsSubscription, PropertyCommand, SubscriptionState,
  ValidationCommandInfo, ValidationResult, ValidationResultStatus, ValueDetails
} from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { AnyBitStringValue, AnyEnumValue, AnyPriorityArrayValue, ArrayItem, BigNumberValue,
  BooleanValue, DateTimeValue, NumberValue, PriorityArrayItem, RecordCollectionValue, StringValue,
  TimeDurationValue, ValueBase } from '@simpl/element-value-types';
import { Property } from '@simpl/object-browser-ng';
import * as Long from 'long';
import { mergeMap, Observable, Observer, Subject, Subscription, throwError } from 'rxjs';

import { Common } from '../shared/common';
import { CommandInvoc, CommandInvocState, SingleCommandInvoc, SubCommandInvoc } from './command-invoc-vm';
import { CommandViewModel } from './command-vm';
import { BACnetDateTime, PropertyValueType, SiTranslator, WsiTranslator } from './data-model-helper';
import { PropertyDefinition } from './property-definition-vm';
import { PropertyInstance } from './property-instance-vm';
import { PropertyCommandResult, PropertySubscriptionState } from './property-vm.types';
import { ContextState, ServiceStore, ViewModelContext } from './snapin-vm.types';

export enum ObjectPropertyEventType {

  ValueChanged = 0,

  CommandChanged,

  CommandExecuted

}

/**
 * Object property event.
 */
export class ObjectPropertyEventArgs {

  constructor(
    private readonly type: ObjectPropertyEventType,
    private readonly opInstance: ObjectPropertyInstance,
    private readonly dataInternal?: any) {
  }

  public get eventType(): ObjectPropertyEventType {
    return this.type;
  }

  public get objectPropertyInstance(): ObjectPropertyInstance {
    return this.opInstance;
  }

  public get data(): any {
    return this.dataInternal;
  }
}

/**
 * Single object property view-model.
 */
export class ObjectPropertyInstance extends PropertyInstance {

  private readonly fullPropertyId: string;

  private accessible: boolean;

  private valueBlk: ValueDetails;
  private normal: boolean;

  private valSubscription: GmsSubscription<ValueDetails>;
  private valChangeSubscription: Subscription;
  private valStateChangeSubscription: Subscription;
  private valSubscriptionState: SubscriptionState;

  private cmdState: CommandInvocState = CommandInvocState.Initial;
  private readonly activeInvocationList: CommandInvoc[];
  private isLastCommandSub: boolean;

  private commandable: boolean;
  private cmdListLocal: Command[];
  private cmdSubscription: GmsSubscription<PropertyCommand>;
  private cmdChangeSubscription: Subscription;
  private cmdStateChangeSubscription: Subscription;
  private cmdSubscriptionState: SubscriptionState;

  private cnsLabels: string[];

  private readonly objectPropertyEventInd: Subject<ObjectPropertyEventArgs>;

  public get isAggregate(): boolean {
    return false;
  }

  public get objectLabel(): string {
    if (this.cnsLabels && this.cnsLabels.length > 0) {
      return this.cnsLabels[0];
    }
    return this.browserObject.ObjectId; // default label in case CNS info is not available!
  }

  public get objectLabelSecondary(): string {
    if (this.cnsLabels && this.cnsLabels.length > 1) {
      return this.cnsLabels[1];
    }
    return undefined; // secondary label is optional
  }

  /**
   * Full object + property id of the associated DPE.
   */
  public get objectAndPropertyId(): string {
    return this.fullPropertyId;
  }

  public get objectId(): string {
    return this.browserObject.ObjectId;
  }

  public get browserObj(): BrowserObject {
    return this.browserObject;
  }

  /**
   * Indicates if the property is accessible.
   */
  public get isAccessible(): boolean {
    return this.accessible;
  }

  public set isAccessible(flag: boolean) {
    this.accessible = flag;
  }

  /**
   * Indicates if the property is commandable.
   */
  public get isCommandable(): boolean {
    return this.commandable && this.isAccessible && !this.isAbsent;
  }

  public set isCommandable(flag: boolean) {
    this.commandable = flag;
  }

  /**
   * Property value as a string.
   * This will return the "display value" as provided by the server or the "raw" value if
   * the display value is not defined or empty.
   *
   * NOTE: will be `undefined` for array and simple-bitstring properties.
   */
  public get value(): string {
    // return this.val;
    return 'deprecated';
  }

  /**
   * Formatted array property values.
   */
  public get valueArr(): readonly string[] {
    return this.valArr;
  }

  /**
   * Flag indicating if the value provided through the open subscription is in fault.
   * This can happen, for example, if the driver providing the value is not available or
   * the device hosting the data point is offline.
   */
  public get valueFault(): boolean {
    const v: ValueDetails = this.valueBlk;
    // NOTE: `QualityGood` must be defined AND set false to trigger a fault condition.  If
    //  missing from the object or set `undefined`, the defaults to no fault.  In other words,
    //  assume the value is ok unless explicitly told it is not.
    if (v?.Value?.QualityGood === false) {
      return true; // fault active
    }
    return false;
  }

  /**
   * Raw property value.
   * This is NOT accessible through the interface.  It is made a public property so that
   * it is accessible to other parts of the VM (e.g, aggregate-property-instance).
   */
  public get valueRaw(): ValueDetails {
    return this.valueBlk;
  }

  /**
   * Flag indicating if the property value is normal.  If there is no normal-value
   * defined for the property, this value will be `undefined`.
   */
  public get isNormal(): boolean {
    return this.normal;
  }

  /**
   * Flag indicating whether this property should be hidden due to its state.
   */
  public get isHiddenByState(): boolean {
    return this.def.displayWhenOffNormalOnly && this.isNormal;
  }

  /**
   * Indicates if the property has been marked "absent" by the driver in a COV.
   * Indexed properties are initially assumed to be "absent" until a COV is received.
   */
  public get isAbsent(): boolean {
    const v: ValueDetails = this.valueBlk;

    if (v?.Value) { // recieved a COV, report the actual absent value
      return v.Value.IsPropertyAbsent;
    } else { // no COV received, report as absent if indexed property
      return this.def.isIndexed;
    }
  }

  /**
   * Property value subscription state.
   */
  public get valueSubscriptionState(): PropertySubscriptionState {
    let state: PropertySubscriptionState = PropertySubscriptionState.Closed;
    if (this.valSubscription) {
      state = ObjectPropertyInstance.mapSubscriptionState(this.valSubscriptionState);
    }
    return state;
  }

  /**
   * Value subscription error code (will not be defined if no error).
   */
  public get valueSubscriptionErrorCode(): number {
    let ec: number;
    if (this.valSubscription) {
      ec = this.valSubscription.errorCode;
    }
    return ec;
  }

  /**
   * Property command state.
   */
  public get commandState(): CommandInvocState {
    return this.cmdState;
  }

  /**
   * Indicates if any commands are pending for the property.
   */
  public get isCommandPending(): boolean {
    return this.activeInvocationList.some(i => !i.isComplete);
  }

  /**
   * Property command subscription state.
   */
  public get commandSubscriptionState(): PropertySubscriptionState {
    let state: PropertySubscriptionState = PropertySubscriptionState.Closed;
    if (this.cmdSubscription) {
      state = ObjectPropertyInstance.mapSubscriptionState(this.cmdSubscriptionState);
    }
    return state;
  }

  /**
   * Command subscription error code (will not be defined if no error).
   */
  public get commandSubscriptionErrorCode(): number {
    let ec: number;
    if (this.cmdSubscription) {
      ec = this.cmdSubscription.errorCode;
    }
    return ec;
  }

  /**
   * Used  to communicate changes/actions in the object-property instance to a
   * potential parent aggregate-property instance.
   */
  public get objectPropertyEvent(): Observable<ObjectPropertyEventArgs> {
    return this.objectPropertyEventInd;
  }

  /**
   * Map subscription state to a friendlier property subscription state
   */
  private static mapSubscriptionState(subState: SubscriptionState): PropertySubscriptionState {
    // Map subscription state to a friendlier property-value state
    let pState: PropertySubscriptionState = PropertySubscriptionState.Closed;
    switch (subState) {
      case SubscriptionState.Subscribing:
      case SubscriptionState.ResubscribePending:
        pState = PropertySubscriptionState.OpenPending;
        break;

      case SubscriptionState.Subscribed:
        pState = PropertySubscriptionState.Open;
        break;

      case SubscriptionState.Unsubscribed:
        // The local subscription state change handler is always unhooked (by the parent
        // view-model class calling `updateValueSubscription` with an undefined value) BEFORE
        // the value/cmd subscription is unsubscribed.  So, if we "see" this state here, it
        // can only be the result of a subscription being closed without our request.
        pState = PropertySubscriptionState.Error;
        break;

      case SubscriptionState.Unsubscribing:
      default:
        pState = PropertySubscriptionState.Closed;
        break;
    }

    return pState;
  }

  /**
   * Constructor.
   */
  constructor(
    traceService: TraceService,
    svc: ServiceStore,
    vmContext: ViewModelContext,
    defBase: PropertyDefinition,
    cmdListBase: Command[],
    private readonly browserObject: BrowserObject
  ) {

    super(traceService, svc, vmContext, defBase, cmdListBase);

    this.accessible = true;
    this.commandable = true;
    this.cmdListLocal = [];
    this.cmdState = CommandInvocState.Initial;
    this.activeInvocationList = [];

    this.objectPropertyEventInd = new Subject<ObjectPropertyEventArgs>();

    // Build the full property id
    this.fullPropertyId = this.def.formatObjectPropertyId(this.browserObject.ObjectId);

    this.updateObjectLabels();
  }

  public overridePropertyDefinition(pd: PropertyDefinition): void {
    // Do some basic checking of key attributes of the definition to ensure the
    // local type is aligned with the base type used when this instance was created.
    if (pd &&
      (this.defBase.nativeType !== pd.nativeType ||
      this.defBase.isFunctionProperty !== pd.isFunctionProperty ||
      this.defBase.isArray !== pd.isArray ||
      this.defBase.isPriorityArray !== pd.isPriorityArray ||
      this.defBase.isSimpleBitstring !== pd.isSimpleBitstring ||
      this.defBase.sizeBitstring !== pd.sizeBitstring ||
      this.defBase.offsetBitstring !== pd.offsetBitstring)) {

      this.traceService.warn(this.trmod, 'Failed to override object-property instance with incompatible property definition: %s',
        this.objectAndPropertyId);
    } else {
      this.defLocal = pd; // override definition for this instance
    }
  }

  public resetDisplayValue(): void {
    const val: ValueDetails = this.valueBlk;
    this.traceService.debug(this.trmod, 'Reset single property display value: pid=%s, val=%s',
      this.fullPropertyId, val?.Value?.Value);
    this.onValueUpdate(); // force re-processing of current raw-value
  }

  /**
   * Re-evaluate object display labels.
   */
  public updateObjectLabels(): void {
    this.cnsLabels = undefined;
    if (this.svc.cnsHelperService && this.vmContext && this.vmContext.browserObjList) {
      const bo: BrowserObject = this.vmContext.browserObjList.find(item => item.Designation === this.browserObject.Designation);
      if (bo) {
        this.cnsLabels = this.svc.cnsHelperService.getCnsLabelsOrdered(bo);
      }
      // Object-property instances that are part of an aggregate property display object-name/desc (instead of prop name)
      // for their label in the UI
      if (this.vmContext.state !== ContextState.SingleObject) {
        this.siProperty.name = this.objectLabel;
      }
    }
  }

  /**
   * Provide a value subscription to the property-instance.
   * The property-instance VM must subscribe to the obsevables contained within it in
   * order to receive value and subscription-state change notifications.
   *
   * The currently set (soon to be "old") value subscription is returned to the caller so it
   * can be unsubscribed from the underlying gms-service after it has been torn down here.
   *
   * If an `undefined` value is passed in, this has the effect of disabling the subscription.
   */
  public updateValueSubscription(vs: GmsSubscription<ValueDetails>): GmsSubscription<ValueDetails> {
    if (vs && PropertyDefinition.trimSystemName(vs.gmsId) !== PropertyDefinition.trimSystemName(this.objectAndPropertyId)) {
      this.traceService.error(this.trmod, 'Value subscription property id mismatch: pidVm=%s, pidVs=%s',
        this.objectAndPropertyId,
        vs ? vs.gmsId : undefined);
      // throw new Error("bad value subscription");
      // return;
    }

    this.traceService.debug(this.trmod, 'Update value subscription: pid=%s, vs=%s',
      this.objectAndPropertyId,
      vs ? vs.id : undefined);

    // Unhook handlers from old value-subscription value and state observables (if any)
    if (this.valChangeSubscription && !this.valChangeSubscription.closed) {
      this.valChangeSubscription.unsubscribe();
    }
    if (this.valStateChangeSubscription && !this.valStateChangeSubscription.closed) {
      this.valStateChangeSubscription.unsubscribe();
    }
    this.valChangeSubscription = undefined;
    this.valStateChangeSubscription = undefined;

    // Set new value-subscription (need to return old!)
    const oldVs: GmsSubscription<ValueDetails> = this.valSubscription;
    this.valSubscription = vs;

    // Add handlers to new value-subscription value and state observables
    if (this.valSubscription) {
      this.valChangeSubscription = this.valSubscription.changed.subscribe(val => this.onValueChange(val));
      this.valStateChangeSubscription = this.valSubscription.stateChanged.subscribe(state => this.onValueSubscriptionStateChange(state));
    }

    return oldVs;
  }

  /**
   * Clear completed property command state.
   *
   * NOTE: `clearAll` flag indicates if the completed state should be cleared regardless of whether or not
   *  it reflects the result of a sub-command (rather than a command issued directly on the object-property).
   *  The aggregate-property sets this `true` prior to issuing an aggregate command (new set of sub-commands)
   *  in order to clear completed command states of ALL object-properties.  It is set `false` by the aggregate
   *  property when the aggregate command state is being cleared at the request of a subordinate object
   *  property prior to an object-property level command.
   */
  public clearCompletedCommandState(clearAll?: boolean): void {
    // Do not reset the state if
    // (1) a command is in progress, or
    // (2) the clear request applies only to command states reflecting the
    //   result of a sub-command (part of an aggregate command)
    if (this.isCommandPending || (!clearAll && !this.isLastCommandSub)) {
      return;
    }
    this.cmdState = CommandInvocState.Initial;
  }

  /**
   * Provide a command subscription to the property view-model.
   * The property view-model must subscribe to the obsevables contained within it in
   * order to receive command and subscription-state change notifications.
   *
   * The currently set (soon to be "old") command subscription is returned to the caller so it
   * can be unsubscribed from the underlying gms-service after it has been torn down here.
   */
  public updateCommandSubscription(cs: GmsSubscription<PropertyCommand>): GmsSubscription<PropertyCommand> {
    if (cs && PropertyDefinition.trimSystemName(cs.gmsId) !== PropertyDefinition.trimSystemName(this.objectAndPropertyId)) {
      this.traceService.warn(this.trmod, 'Command subscription property id mismatch: pidVm=%s, pidCs=%s',
        this.objectAndPropertyId,
        cs ? cs.gmsId : undefined);
      // return;
      // throw new Error("bad command subscription");
    }

    this.traceService.debug(this.trmod, 'Update command subscription: pid=%s, cs=%s',
      this.objectAndPropertyId,
      cs ? cs.id : undefined);

    // Unhook handlers from old command-subscription command and state observables (if any)
    if (this.cmdChangeSubscription && !this.cmdChangeSubscription.closed) {
      this.cmdChangeSubscription.unsubscribe();
    }
    if (this.cmdStateChangeSubscription && !this.cmdStateChangeSubscription.closed) {
      this.cmdStateChangeSubscription.unsubscribe();
    }
    this.cmdChangeSubscription = undefined;
    this.cmdStateChangeSubscription = undefined;

    // Set new command-subscription (need to return old!)
    const oldCs: GmsSubscription<PropertyCommand> = this.cmdSubscription;
    this.cmdSubscription = cs;

    // Add handlers to new value-subscription value and state observables
    if (this.cmdSubscription) {
      this.cmdChangeSubscription = this.cmdSubscription.changed.subscribe(cmd => this.onCmdChange(cmd));
      this.cmdStateChangeSubscription = this.cmdSubscription.stateChanged.subscribe(state => this.onCmdSubscriptionStateChange(state));
    }

    return oldCs;
  }

  public createSubCommandInvoc(commandVm: CommandViewModel): SubCommandInvoc {
    const invoc: SubCommandInvoc = new SubCommandInvoc(this.objectAndPropertyId, commandVm.id);
    this.addActiveInvoc(invoc);

    // Hook into command state change indications to monitor the command as it is
    // executed by the aggregate-property
    const cmdSub: Subscription = invoc.stateChanged.subscribe(() => {
      // this.traceService.debug(this.trmod, "Command state change ind: objProp=%s, cmdId=%s, state=%s, err=%s", );
      this.updateCommandState(invoc);

      // On completion, mark command as no longer active
      if (invoc.isComplete) {
        cmdSub.unsubscribe();
        this.removeActiveInvoc(invoc);
      }
    });

    return invoc;
  }

  /**
   * Update the command-VM list from the base command list and the currently "active" commands.
   */
  public updateCommandList(): void {
    if (this.isCommandable) {
      const cmdListActive: Command[] = this.cmdListLocal || [];
      // First, update the base command list with any new commands found in the active command list.
      // This is unlikely, but possible if DP "ownership" has been obtained by the user.  In this case,
      // the commands will not have been supplied in the original READ request (no ownership).  Instead,
      // the commands will be revealed for this first time in a COV when ownership is granted.
      cmdListActive.forEach(cmdActive => {
        if (this.cmdListBase.findIndex(cmdBase => cmdBase.Id === cmdActive.Id) < 0) {
          this.cmdListBase.push(cmdActive); // append to end; no command order field exists!
        }
      });

      // Update the list of command VMs.
      // All base commands must be represented by a VM in the command list.  If the command is also
      // in the "active" command list, it will be ENABLED; otherwise DISABLED.
      this.cmdListBase.forEach(cmdBase => {
        let cmdVm: CommandViewModel = this.cmdVmList.find(vm => vm.id === cmdBase.Id);
        if (!cmdVm) {
          cmdVm = new CommandViewModel(this.traceService, this.vmContext, this.objectId, this.def, cmdBase);
          this.cmdVmList.push(cmdVm); // new command encountered
        }
        let active = false;
        const cmdActive: Command = cmdListActive.find(cmd => cmd.Id === cmdBase.Id);
        if (cmdActive) {
          cmdVm.updateCommand(cmdActive);
          active = true;
        }
        cmdVm.isActive = active;
      });

      // update the property commands
      this.updateSiPropertyValueCommands();
    } else {
      this.cmdVmList.length = 0; // inaccessible properties should show no commands; not even disabled ones
    }
  }

  protected executeCommandInternal(commandVm: CommandViewModel, commandArgs: CommandInput[]): Observable<PropertyCommandResult> {
    if (!commandVm) {
      return throwError(new Error('invalid command'));
    }

    const showValidation: Observable<ValidationResult> = new Observable<ValidationResult>(observer => {
      const validationSubscription = this.svc.validationDialogService.show(validationCommandInfo).subscribe((result: ValidationResult) => {
        observer.next(result);
        observer.complete();
        validationSubscription.unsubscribe();
      });
    });

    // Validate command operation
    const propertyIds: string[] = [this.objectAndPropertyId];
    const validationCommandInfo: ValidationCommandInfo = new ValidationCommandInfo(propertyIds, commandVm.groupNumber);

    return showValidation.pipe(
      mergeMap((result: ValidationResult) => {
        if (result.Status === ValidationResultStatus.Success) {

          // Update command input with validation result

          if (commandArgs.length > 0) {
            commandArgs.forEach(commandInput => {
              commandInput.Password = result.Password;
              commandInput.SuperName = result.SuperName;
              commandInput.SuperPassword = result.SuperPassword;
              commandInput.Comments = result.Comments;
              commandInput.SessionKey = result.SessionKey;
            });
          } else {
            const commandInput: CommandInput = {
              Name: undefined,
              DataType: undefined,
              Value: undefined,
              Password: result.Password,
              SuperName: result.SuperName,
              SuperPassword: result.SuperPassword,
              SessionKey: result.SessionKey,
              Comments: result.Comments
            };

            commandArgs.push(commandInput);
          }

          return this.execute(commandVm, commandArgs);
        } else if (result.Status === ValidationResultStatus.Cancelled) {
          return throwError(this.svc.validationDialogService.ValidationCancelled);
        } else {
          this.traceService.info(this.trmod, 'Validate command op: Status: %s, Error: %s', ValidationResultStatus[result.Status], result.Error);
          return throwError(result.Error);
        }
      }));
  }

  private execute(commandVm: CommandViewModel, commandArgs: CommandInput[]): Observable<PropertyCommandResult> {
    return new Observable<PropertyCommandResult>(
      (o: Observer<PropertyCommandResult>) => {
        const invoc: SingleCommandInvoc = new SingleCommandInvoc(this.objectAndPropertyId, commandVm.id, commandArgs);
        this.addActiveInvoc(invoc);
        // Notify potential aggretate property that a command is being issued on one of its
        // subordinate object-instance properties (gives opportunity to clear ag command state)
        this.objectPropertyEventInd.next(new ObjectPropertyEventArgs(
          ObjectPropertyEventType.CommandExecuted,
          this,
          { commandId: commandVm.id }));

        invoc.execute(this.svc.execCommandService).subscribe(
          () => {
            this.updateCommandState(invoc);
          },
          err => { }, // does not emit error
          () => {
            // Command completion
            // Note that the execute observable is designed to NOT emit an error; rather command
            // errors from the server are communicate through the invoc state on completion.
            this.removeActiveInvoc(invoc);
            o.next(new PropertyCommandResult(
              this.objectId,
              this.def.propertyId,
              invoc.state === CommandInvocState.Success,
              invoc.error
            ));
            o.complete();
          });
      });
  }

  /**
   * Property command state is an aggregate state applied to the property as a whole
   * based on execution and completion states of individual commands that may potentially
   * be executing concurrently.
   * The property command state is managed as follows:
   * - When a new command is executed, the state is set to `Executing`
   * - When a command completes with an error, the state us set to `Error`
   * - When a command completes successfully, the state is set to `Success` only if:
   *    (1) there are no outstanding commands, and
   *    (2) the current state is not `Error`
   *
   * The state can be reset to `Initial` through an explicit call to clearCommandState.
   */
  private updateCommandState(invoc: CommandInvoc): void {
    if (!invoc) {
      return;
    }
    if (invoc.isComplete) {
      if (invoc.state === CommandInvocState.Error) {
        this.cmdState = CommandInvocState.Error; // error
      } else {
        if (!this.isCommandPending && this.cmdState !== CommandInvocState.Error) {
          this.cmdState = CommandInvocState.Success; // success
        }
      }
    } else {
      this.cmdState = CommandInvocState.Executing; // in progress...
    }
  }

  private addActiveInvoc(invoc: CommandInvoc): void {
    if (invoc) {
      this.activeInvocationList.push(invoc);
      this.isLastCommandSub = invoc.isSubCommandInvoc;
    }
  }

  private removeActiveInvoc(invoc: CommandInvoc): void {
    const pos: number = this.activeInvocationList.findIndex(i => i === invoc);
    if (pos >= 0) {
      this.activeInvocationList.splice(pos, 1); // remove the element at index `pos`
    }
  }

  /**
   * Process value subscription state change.
   */
  private onValueSubscriptionStateChange(state: SubscriptionState): void {
    if (this.traceService.isDebugEnabled(this.trmod)) {
      this.traceService.debug(this.trmod, 'Value subscription state change received: pid=%s, state=%s',
        this.fullPropertyId,
        state);
    }
    this.valSubscriptionState = state;
  }

  /**
   * Process property value change.
   */
  private onValueChange(val: ValueDetails): void {
    if (this.traceService.isDebugEnabled(this.trmod)) {
      this.traceService.debug(this.trmod, 'Value change received: pid=%s, val=%s',
        this.fullPropertyId, val?.Value?.Value);
    }
    this.valueBlk = val;
    this.onValueUpdate();

    // Notify listeners of value change
    this.objectPropertyEventInd.next(new ObjectPropertyEventArgs(
      ObjectPropertyEventType.ValueChanged,
      this)
    );
  }

  private onValueUpdate(): void {
    // Perform type specific processing of updated value
    if (this.siProperty) {
      if (this.def.isArray) {
        this.onValueUpdateArray();
      } else {
        this.onValueUpdateSimple();
      }
    }
  }

  private onValueUpdateSimple(): void {
    const pdef: PropertyDefinition = this.def;
    const vBlk: ValueDetails = this.valueBlk;

    // Check for value fault first
    if (this.valueFault) {
      this.siProperty.defaultText = '#COM'; // need to get from string resources ToDo
      this.siProperty.value = undefined;
    }

    if (!vBlk?.Value) {
      return; // no value to update!
    }

    if (!this.valueFault) {
      // Create the si-property value block if not yet defined
      this.siProperty.defaultText = undefined;
      if (!this.siProperty.value) {
        this.siProperty.value = PropertyInstance.createSiValue(this.def);
        this.updateSiPropertyValueCommands();
      }

      // Update si-property value based on type
      switch (pdef.valueType) {
        case PropertyValueType.NumericValue:
        case PropertyValueType.DurationValue:
          this.siProperty.value.value = WsiTranslator.decodeNumeric(vBlk.Value.Value);
          break;

        case PropertyValueType.Integer64Value:
        case PropertyValueType.UnsignedInteger64Value:
          const isUnsigned: boolean = pdef.valueType === PropertyValueType.UnsignedInteger64Value;
          const long: Long = WsiTranslator.decodeInteger64(vBlk.Value.Value, isUnsigned);
          if (long) {
            this.siProperty.value.value = long.toString();
          }
          break;

        case PropertyValueType.DateTimeValue:
        case PropertyValueType.BACnetDateTimeValue:
          let sidt: string;
          if (pdef.valueType === PropertyValueType.DateTimeValue) {
            // Basic (PVSS) date-time translation
            const dt: Date = WsiTranslator.decodeDateTime(vBlk.Value.Value);
            const bndt: BACnetDateTime = SiTranslator.toBACnetDateTime(dt);
            sidt = SiTranslator.formatSiDateTimeFromBACnet(bndt);
          } else {
            // BACnet date-time translation
            const bndt: BACnetDateTime = WsiTranslator.decodeBACnetDateTime(vBlk.Value.Value);

            sidt = SiTranslator.translateToSiBacnetDatetime(bndt, pdef.bnDateTimeDetail);
          }

          this.siProperty.value.value = sidt;
          break;

        // For si-boolean values, the si-property value is a boolean true/false value however the
        // text that is displayed is from a text-table stored in the options property of the value block.
        // This text-table is seeded when the value block is created with default "true", "false" text
        // but these texts are overridden as COVs are received with the correct translated text
        // from the server.
        // Additionaly, the text-table is cleared and fully populated if/when a default command is
        // received and populated from the one and only command parameter attributes of this command.
        case PropertyValueType.BooleanValue:
          if (this.siProperty.value.type === 'boolean') {
            const bv: BooleanValue = this.siProperty.value as BooleanValue;
            const currentValue: boolean = WsiTranslator.decodeBoolean(vBlk.Value.Value);
            const displayValue: string = vBlk.Value.DisplayValue || vBlk.Value.Value;
            bv.value = currentValue;

            // update string for this value
            const arrIndex = bv.options.findIndex(x => x.value === currentValue);
            if (arrIndex !== -1) {
              bv.options[arrIndex].text = displayValue;
            } else { // unexpected
              bv.options.push({
                value: currentValue,
                text: displayValue
              });
            }
          }
          break;

        // For si-enum values, the string displayed is retrieved from the text-table stored in the
        // options property of the value block. These texts are overridden as COVs are received with
        // the correct translated text from the server.
        // Additionaly, the text-table is cleared and fully populated if/when a default command is
        // received and populated from the one and only command parameter attributes of this command.
        case PropertyValueType.EnumeratedValue:
          if (this.siProperty.value.type === 'enum') {
            const ev: AnyEnumValue = this.siProperty.value as AnyEnumValue;
            const currentValue: number = WsiTranslator.decodeNumeric(vBlk.Value.Value);
            const displayValue: string = vBlk.Value.DisplayValue || vBlk.Value.Value;
            ev.value = currentValue;

            // update string for this value
            const arrIndex = ev.options.findIndex(x => x.value === currentValue);
            if (arrIndex !== -1) {
              ev.options[arrIndex].text = displayValue;
            } else { // unexpected
              ev.options.push({
                value: currentValue,
                text: displayValue
              });
            }
          }
          break;

        case PropertyValueType.Bitstring32Value:
        case PropertyValueType.Bitstring64Value:
          const bsValue: number[] = SiTranslator.translateToSiBitstring(vBlk.Value.Value, pdef.sizeBitstring, pdef.offsetBitstring);

          this.siProperty.value.value = bsValue;
          break;

        // String values are considered to be formatted at the server according to the uses's
        // language (think enum values).  As such, the formatted value will be obtained from
        // the DisplayValue field of the value blk.  If this field is not defined or empty,
        // the raw Value field will be used.
        case PropertyValueType.StringValue:
        default:
          this.siProperty.value.value = vBlk.Value.DisplayValue || vBlk.Value.Value;
          break;
      }
    }

    // Set normal flag if normal value if defined
    this.normal = undefined;
    if (!isNullOrUndefined(pdef.normalValue) && !isNullOrUndefined(vBlk.Value.Value)) {
      switch (pdef.valueType) {
        case PropertyValueType.NumericValue:
          const res: number = !isNaN(pdef.resolution) ? pdef.resolution : 2;
          this.normal = Common.isEqualValueNumeric(pdef.normalValue, vBlk.Value.Value, res);
          break;
        case PropertyValueType.DurationValue:
          this.normal = Common.isEqualValueNumeric(pdef.normalValue, vBlk.Value.Value, 0);
          break;
        case PropertyValueType.Bitstring32Value:
        case PropertyValueType.Bitstring64Value:
          this.normal = this.bitstringCompare(pdef.normalValue, vBlk.Value.Value);
          break;
        default:
          this.normal = (pdef.normalValue === vBlk.Value.Value);
          break;
      }
    }
  }

  private onValueUpdateArray(): void {
    const pdef: PropertyDefinition = this.def;
    const vBlk: ValueDetails = this.valueBlk;
    let vArr: string[];
    let vActiveArr: boolean[];

    if (vBlk?.Value) {
      switch (pdef.valueType) {
        case PropertyValueType.BACnetDateTimeValue: {
          vArr = this.decodeAndFormatBACnetDateTimeArray(vBlk.Value.Value);
          break;
        }

        case PropertyValueType.Bitstring32Value: {
          vArr = this.decodeAndFormatNumericArray(vBlk.Value.Value);
          break;
        }

        case PropertyValueType.Bitstring64Value: {
          vArr = this.decodeAndFormatInteger64Array(vBlk.Value.Value, true);
          break;
        }

        case PropertyValueType.BooleanValue: {
          vArr = this.decodeAndFormatTextArray(vBlk.Value.DisplayValue, vBlk.Value.Value);
          break;
        }

        case PropertyValueType.DateTimeValue: {
          vArr = this.decodeAndFormatDateTimeArray(vBlk.Value.Value);
          break;
        }

        case PropertyValueType.DurationValue: {
          vArr = this.decodeAndFormatDurationArray(vBlk.Value.Value);
          break;
        }

        case PropertyValueType.EnumeratedValue: {
          vArr = this.decodeAndFormatTextArray(vBlk.Value.DisplayValue, vBlk.Value.Value);
          break;
        }

        case PropertyValueType.Integer64Value:
        case PropertyValueType.UnsignedInteger64Value: {
          const isUnsigned: boolean = pdef.valueType !== PropertyValueType.Integer64Value;
          vArr = this.decodeAndFormatInteger64Array(vBlk.Value.Value, isUnsigned);
          break;
        }

        case PropertyValueType.NumericValue: {
          vArr = this.decodeAndFormatNumericArray(vBlk.Value.Value);
          break;
        }

        case PropertyValueType.StringValue:
        default: {
          vArr = this.decodeAndFormatTextArray(vBlk.Value.DisplayValue, vBlk.Value.Value);
          break;
        }
      }
    }

    // Set the corresponding element `active` array based on whether an element value
    // exists in each array position.
    if (vArr) {
      vActiveArr = vArr.map(v => !isNullOrUndefined(v));

      // For all array properties, the size is adjusted based the size of the value array
      // passed in the first COV.
      if (this.sizeArr === undefined || this.sizeArr === 0) {
        this.sizeArr = vArr.length;
      }
    }

    // Will be set to `undefined` if provided COV value is undefined or otherwise empty
    this.valArr = vArr;
    this.valActiveArr = vActiveArr;

    if (this.valueFault) {
    // Check for a value fault and if none, update the array
      this.siProperty.defaultText = '#COM'; // need to get from string resources ToDo
      this.siProperty.value = undefined;
    } else {
      this.siProperty.defaultText = undefined;
      // Update the corresponding array or priority array
      if (this.def.isPriorityArray) {
        this.updatePriorityArray();
      } else {
        this.updateSimpleArray();
      }
    }
  }

  private updatePriorityArray(): void {
    const pdef: PropertyDefinition = this.def;
    const vBlk: ValueDetails = this.valueBlk;

    if (this.siProperty && this.def.isPriorityArray) {
      // create the value block if required
      if (this.siProperty.value === undefined) {
        this.siProperty.value = PropertyInstance.createSiPriorityArrayValue(this.def);
        this.updateSiPropertyValueCommands();
      }

      switch (pdef.valueType) {
        case PropertyValueType.BACnetDateTimeValue: {
          const bndtArr = WsiTranslator.decodeBACnetDateTimeArray(vBlk.Value.Value, this.def.explicitUndefinedValue) || [];
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<DateTimeValue>[] = pap.value.value as PriorityArrayItem<DateTimeValue>[];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              if (bndtArr[i] === undefined) {
                iValue.value.value = undefined;
              } else {
                iValue.value.value = SiTranslator.translateToSiBacnetDatetime(bndtArr[i], pdef.bnDateTimeDetail);
              }
            }
          }
          break;
        }

        case PropertyValueType.Bitstring32Value: {
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<AnyBitStringValue>[] = pap.value.value as PriorityArrayItem<AnyBitStringValue>[];
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value, this.def.explicitUndefinedValue) || [];

          for (let prio = 0; prio < pap.value.value.length; prio++) {
            if (prio < numArr.length) {
              if (numArr[prio] === undefined) {
                pai[prio].value.value = undefined;
              } else {
                const bsValue: number[] = SiTranslator.translateToSiBitstring(numArr[prio].toString(), pdef.sizeBitstring, pdef.offsetBitstring);
                pai[prio].value.value = bsValue;
              }
            }
          }
          break;
        }

        case PropertyValueType.Bitstring64Value: {
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<AnyBitStringValue>[] = pap.value.value as PriorityArrayItem<AnyBitStringValue>[];
          const lArr: Long[] = WsiTranslator.decodeInteger64Array(vBlk.Value.Value, true, this.def.explicitUndefinedValue) || [];

          for (let prio = 0; prio < pap.value.value.length; prio++) {
            if (prio < lArr.length) {
              if (lArr[prio] === undefined) {
                pai[prio].value.value = undefined;
              } else {
                const bsValue: number[] = SiTranslator.translateToSiBitstring(lArr[prio].toString(), pdef.sizeBitstring, pdef.offsetBitstring);
                pai[prio].value.value = bsValue;
              }
            }
          }
          break;
        }

        case PropertyValueType.BooleanValue: {
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value, this.def.explicitUndefinedValue) || [];
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<BooleanValue>[] = pap.value.value as PriorityArrayItem<BooleanValue>[];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              iValue.value.value = numArr[i];
            }
          }
          break;
        }

        case PropertyValueType.DateTimeValue: {
          const strArr: string[] = WsiTranslator.decodeTextArray(vBlk.Value.Value) || []; // Basic (PVSS) date-time translation
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<DateTimeValue>[] = pap.value.value as PriorityArrayItem<DateTimeValue>[];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const dt: Date = WsiTranslator.decodeDateTime(strArr[i]);
            const bndt: BACnetDateTime = SiTranslator.toBACnetDateTime(dt);

            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              if (strArr[i] === undefined) {
                iValue.value.value = undefined;
              } else {
                iValue.value.value = SiTranslator.formatSiDateTimeFromBACnet(bndt);
              }
            }
          }
          break;
        }

        case PropertyValueType.DurationValue: {
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<TimeDurationValue>[] = pap.value.value as PriorityArrayItem<TimeDurationValue>[];
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value, this.def.explicitUndefinedValue) || [];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              iValue.value.value = numArr[i];
            }
          }
          break;
        }

        case PropertyValueType.EnumeratedValue: {
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<AnyEnumValue>[] = pap.value.value as PriorityArrayItem<AnyEnumValue>[];
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value, this.def.explicitUndefinedValue) || [];
          const displayArray: string[] = WsiTranslator.decodeTextArray(vBlk.Value.DisplayValue) || [];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<AnyEnumValue> = pai.find(x => x.position === i + 1);
            if (iValue) {
              const currentValue: number = numArr[i];

              // update string for this value
              if (currentValue !== undefined) {
                const arrIndex = iValue.value.options.findIndex((x => x.value === currentValue));
                if (arrIndex !== -1) {
                  iValue.value.options[arrIndex].text = displayArray[i];
                } else { // unexpected
                  iValue.value.options.push({ value: currentValue, text: displayArray[i] });
                }
              }

              iValue.value.value = currentValue;
            }
          }
          break;
        }

        case PropertyValueType.Integer64Value:
        case PropertyValueType.UnsignedInteger64Value: {
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<BigNumberValue>[] = pap.value.value as PriorityArrayItem<BigNumberValue>[];
          const isUnsigned: boolean = pdef.valueType === PropertyValueType.UnsignedInteger64Value;
          const lArr: Long[] = WsiTranslator.decodeInteger64Array(vBlk.Value.Value, isUnsigned, this.def.explicitUndefinedValue) || [];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              iValue.value.value = lArr[i];
            }
          }
          break;
        }

        case PropertyValueType.NumericValue: {
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<NumberValue>[] = pap.value.value as PriorityArrayItem<NumberValue>[];
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value, this.def.explicitUndefinedValue) || [];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              iValue.value.value = numArr[i];
            }
          }
          break;
        }

        case PropertyValueType.StringValue:
        default: {
          const sArr = this.decodeAndFormatTextArray(vBlk.Value.DisplayValue, vBlk.Value.Value) || [];
          const pap: Property<AnyPriorityArrayValue> = this.siProperty as Property<AnyPriorityArrayValue>;
          const pai: PriorityArrayItem<StringValue>[] = pap.value.value as PriorityArrayItem<StringValue>[];

          for (let i = 0; i < pap.value.maxLength; i++) {
            const iValue: PriorityArrayItem<ValueBase> = pai.find(x => x.position === i + 1);
            if (iValue) {
              iValue.value.value = sArr[i];
            }
          }
          break;
        }
      }
    }
  }

  private updateSimpleArray(): void {
    const pdef: PropertyDefinition = this.def;
    const vBlk: ValueDetails = this.valueBlk;

    if (this.siProperty && !this.def.isPriorityArray) {
      // create the value block if required
      if (this.siProperty.value === undefined) {
        this.siProperty.value = PropertyInstance.createSiArrayValue(this.def);
        this.updateSiPropertyValueCommands();
      }

      switch (pdef.valueType) {
        case PropertyValueType.BACnetDateTimeValue: {
          const arrayProperty: Property<RecordCollectionValue<DateTimeValue>> = this.siProperty as Property<RecordCollectionValue<DateTimeValue>>;
          const bndtArr = WsiTranslator.decodeBACnetDateTimeArray(vBlk.Value.Value) || [];

          if (bndtArr?.length) {
            this.initializeArray(bndtArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const iValue: ArrayItem<DateTimeValue> = arrayProperty.value.value.find(x => x.position === i + 1);
              if (iValue) {
                iValue.value.value = SiTranslator.translateToSiBacnetDatetime(bndtArr[i], pdef.bnDateTimeDetail);
              }
            }
          }

          break;
        }

        case PropertyValueType.Bitstring32Value: {
          const arrayProperty: Property<RecordCollectionValue<AnyBitStringValue>> = this.siProperty as Property<RecordCollectionValue<AnyBitStringValue>>;
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value);

          if (numArr?.length) {
            this.initializeArray(numArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let indx = 0; indx < arrayProperty.value.value.length; indx++) {
              const bsValue: number[] = SiTranslator.translateToSiBitstring(numArr[indx].toString(), pdef.sizeBitstring, pdef.offsetBitstring);

              arrayProperty.value.value[indx].value.value = bsValue;
            }
          }
          break;
        }

        case PropertyValueType.Bitstring64Value: {
          const arrayProperty: Property<RecordCollectionValue<AnyBitStringValue>> = this.siProperty as Property<RecordCollectionValue<AnyBitStringValue>>;
          const lArr: Long[] = WsiTranslator.decodeInteger64Array(vBlk.Value.Value, true);

          if (lArr?.length) {
            this.initializeArray(lArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let indx = 0; indx < arrayProperty.value.value.length; indx++) {
              const bsValue: number[] = SiTranslator.translateToSiBitstring(lArr[indx].toString(), pdef.sizeBitstring, pdef.offsetBitstring);

              arrayProperty.value.value[indx].value.value = bsValue;
            }
          }
          break;
        }

        case PropertyValueType.BooleanValue: {
          const boolArr: boolean[] = WsiTranslator.decodeBooleanArray(vBlk.Value.Value);
          const displayArray: string[] = WsiTranslator.decodeTextArray(vBlk.Value.DisplayValue);
          const arrayProperty: Property<RecordCollectionValue<BooleanValue>> = this.siProperty as Property<RecordCollectionValue<BooleanValue>>;

          if (boolArr?.length) {
            this.initializeArray(boolArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const iValue: ArrayItem<BooleanValue> = arrayProperty.value.value.find(x => x.position === i + 1);
              if (iValue) {
                const currentValue: boolean = boolArr[i];
                const displayValue: string = displayArray[i];

                // update string for this value
                const arrIndex = iValue.value.options.findIndex((x => x.value === currentValue));
                if (arrIndex !== -1) {
                  iValue.value.options[arrIndex].text = displayValue;
                } else { // unexpected
                  iValue.value.options.push({ value: currentValue, text: displayValue });
                }

                iValue.value.value = currentValue;
              }
            }
          }
          break;
        }

        case PropertyValueType.DateTimeValue: {
          const strArr: string[] = WsiTranslator.decodeTextArray(vBlk.Value.Value) || []; // Basic (PVSS) date-time translation
          const arrayProperty: Property<RecordCollectionValue<DateTimeValue>> = this.siProperty as Property<RecordCollectionValue<DateTimeValue>>;

          if (strArr?.length) {
            this.initializeArray(strArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const dt: Date = WsiTranslator.decodeDateTime(strArr[i]);
              const bndt: BACnetDateTime = SiTranslator.toBACnetDateTime(dt);
              const pai: ArrayItem<ValueBase> = arrayProperty.value.value[i] as ArrayItem<ValueBase>;

              pai.value.value = SiTranslator.formatSiDateTimeFromBACnet(bndt);
            }
          }
          break;
        }

        case PropertyValueType.DurationValue: {
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value);
          const arrayProperty: Property<RecordCollectionValue<TimeDurationValue>> = this.siProperty as Property<RecordCollectionValue<TimeDurationValue>>;

          if (numArr?.length) {
            this.initializeArray(numArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const pai: ArrayItem<TimeDurationValue> = arrayProperty.value.value[i] as ArrayItem<TimeDurationValue>;

              pai.value.value = numArr[i];
            }
          }
          break;
        }

        case PropertyValueType.EnumeratedValue: {
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value);
          const displayArray: string[] = WsiTranslator.decodeTextArray(vBlk.Value.DisplayValue);
          const arrayProperty: Property<RecordCollectionValue<AnyEnumValue>> = this.siProperty as Property<RecordCollectionValue<AnyEnumValue>>;

          if (numArr?.length) {
            this.initializeArray(numArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const iValue: ArrayItem<AnyEnumValue> = arrayProperty.value.value.find(x => x.position === i + 1);
              if (iValue) {
                const currentValue: number = numArr[i];

                // update string for this value
                const arrIndex = iValue.value.options.findIndex((x => x.value === currentValue));
                if (arrIndex !== -1) {
                  iValue.value.options[arrIndex].text = displayArray[i];
                } else { // unexpected
                  iValue.value.options.push({ value: currentValue, text: displayArray[i] });
                }

                iValue.value.value = currentValue;
              }
            }
          }
          break;
        }

        case PropertyValueType.Integer64Value:
        case PropertyValueType.UnsignedInteger64Value: {
          const isUnsigned: boolean = pdef.valueType === PropertyValueType.UnsignedInteger64Value;
          const lArr: Long[] = WsiTranslator.decodeInteger64Array(vBlk.Value.Value, isUnsigned);
          const arrayProperty: Property<RecordCollectionValue<BigNumberValue>> = this.siProperty as Property<RecordCollectionValue<BigNumberValue>>;

          if (lArr?.length) {
            this.initializeArray(lArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const pai: ArrayItem<ValueBase> = arrayProperty.value.value[i] as ArrayItem<BigNumberValue>;

              pai.value.value = lArr[i];
            }
          }
          break;
        }

        case PropertyValueType.NumericValue: {
          const numArr: number[] = WsiTranslator.decodeNumericArray(vBlk.Value.Value);
          const arrayProperty: Property<RecordCollectionValue<NumberValue>> = this.siProperty as Property<RecordCollectionValue<NumberValue>>;

          if (numArr?.length) {
            this.initializeArray(numArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const iValue: ArrayItem<ValueBase> = arrayProperty.value.value.find(x => x.position === i + 1);
              if (iValue) {
                iValue.value.value = numArr[i];
              }
            }
          }
          break;
        }

        case PropertyValueType.StringValue:
        default: {
          const sArr = this.decodeAndFormatTextArray(vBlk.Value.DisplayValue, vBlk.Value.Value);
          const arrayProperty: Property<RecordCollectionValue<StringValue>> = this.siProperty as Property<RecordCollectionValue<StringValue>>;

          if (sArr?.length) {
            this.initializeArray(sArr.length, this.def.arrayItemLabels); // Initialize array if the array elements haven't been populated

            // Update the values from the COV
            for (let i = 0; i < arrayProperty.value.value.length; i++) {
              const pai: ArrayItem<ValueBase> = arrayProperty.value.value[i] as ArrayItem<StringValue>;

              pai.value.value = sArr[i];
            }
          }
          break;

        }
      }
    }
  }

  /**
   * Decode and format a bitstring value to an array of strings.
   * Example:
   *   "5" ==> ["true", "false", "true"]
   */
  private decodeAndFormatBitstring(valEncoded: string): string[] {
    let bsStringArr: string[];
    if (!isNullOrUndefined(valEncoded)) {
      const bitArr: boolean[] = WsiTranslator.decodeBitstring(valEncoded, this.def.sizeBitstring, this.def.offsetBitstring);
      if (bitArr) {
        bsStringArr = bitArr.map(bitval => String(bitval));
      } else {
        this.traceParseError(valEncoded);
      }
    }
    return bsStringArr;
  }

  /**
   * Decode a and format a float-array or integer-array value.
   */
  private decodeAndFormatNumericArray(valEncoded: string): string[] {
    let numStringArr: string[];
    if (valEncoded) {
      const numArr: number[] = WsiTranslator.decodeNumericArray(valEncoded, this.def.explicitUndefinedValue);
      if (numArr) {
        numStringArr = numArr.map(num => this.def.formatNumeric(num));
      } else {
        this.traceParseError(valEncoded);
      }
    }
    return numStringArr;
  }

  /**
   * Decode and format an integer64-array value.
   */
  private decodeAndFormatInteger64Array(valEncoded: string, isUnsigned: boolean): string[] {
    let numStringArr: string[];
    if (valEncoded) {
      const bigintArr: Long[] = WsiTranslator.decodeInteger64Array(valEncoded, isUnsigned, this.def.explicitUndefinedValue);
      if (bigintArr) {
        numStringArr = bigintArr.map(bigint => this.def.formatLong(bigint));
      } else {
        this.traceParseError(valEncoded);
      }
    }
    return numStringArr;
  }

  /**
   * Decode and format a date-time-array value.
   */
  private decodeAndFormatDateTimeArray(valEncoded: string): string[] {
    let dtStrArr: string[];
    if (valEncoded) {
      const dtArr: Date[] = WsiTranslator.decodeDateTimeArray(valEncoded);
      if (dtArr) {
        dtStrArr = dtArr.map(dt => this.def.formatDateTime(dt));
      } else {
        this.traceParseError(valEncoded);
      }
    }
    return dtStrArr;
  }

  /**
   * Decode and format BACnet date-time-array value.
   */
  private decodeAndFormatBACnetDateTimeArray(valEncoded: string): string[] {
    let bndtStrArr: string[];
    if (valEncoded) {
      const bndtArr: BACnetDateTime[] = WsiTranslator.decodeBACnetDateTimeArray(valEncoded, this.def.explicitUndefinedValue);
      if (bndtArr) {
        bndtStrArr = bndtArr.map(bndt => bndt ? this.def.formatBACnetDateTime(WsiTranslator.encodeBACnetDateTime(bndt)) : undefined);
      } else {
        this.traceParseError(valEncoded);
      }
    }
    return bndtStrArr;
  }

  /**
   * Decode and format duration-array value.
   */
  private decodeAndFormatDurationArray(valEncoded: string): string[] {
    let durationStringArr: string[];
    if (valEncoded) {
      const numArr: number[] = WsiTranslator.decodeNumericArray(valEncoded, this.def.explicitUndefinedValue);
      if (numArr) {
        durationStringArr = numArr.map(num => this.def.formatDuration(num));
      } else {
        this.traceParseError(valEncoded);
      }
    }
    return durationStringArr;
  }

  /**
   * Decode and format text array value.
   */
  private decodeAndFormatTextArray(valEncoded: string, valRawEncoded: string): string[] {
    let textArr: string[];
    if (valEncoded) {
      textArr = WsiTranslator.decodeTextArray(valEncoded, this.def.explicitUndefinedValue);
    }
    if (isNullOrUndefined(textArr)) {
      textArr = WsiTranslator.decodeAnyArray(valRawEncoded);
    }
    return textArr;
  }

  /**
   * Trace parsing error (only if debugging enabled).
   */
  private traceParseError(valEncoded: string): void {
    if (this.traceService.isDebugEnabled(this.trmod)) {
      this.traceService.error(this.trmod, 'Parse error: prop=%s, type=%s, valEncoded=%s',
        this.objectAndPropertyId, this.def.valueType, valEncoded);
    }
  }

  /**
   * Process command subscription state change.
   */
  private onCmdSubscriptionStateChange(state: SubscriptionState): void {
    if (this.traceService.isDebugEnabled(this.trmod)) {
      this.traceService.debug(this.trmod, 'Command subscription state change received: pid=%s, state=%s',
        this.fullPropertyId,
        state);
    }
    this.cmdSubscriptionState = state;
  }

  /**
   * Process property value change.
   */
  private onCmdChange(cmd: PropertyCommand): void {
    // Reset the list of active commands
    this.cmdListLocal = [];
    if (cmd?.Commands) {
      this.cmdListLocal = cmd.Commands.slice(0);
    }

    // Reset the full command list
    this.updateCommandList();

    // Notify listeners of command change
    this.objectPropertyEventInd.next(new ObjectPropertyEventArgs(
      ObjectPropertyEventType.CommandChanged,
      this)
    );
  }
}
