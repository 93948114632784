import { MenuItem } from '@simpl/element-ng';
import { PriorityArrayItem, ValueBase } from '@simpl/element-value-types';
import { clone } from '@simpl/object-browser-ng/common';
import {
  AnyProperty,
  CommandEvent,
  DisplayMode,
  PropertyCommand
} from '@simpl/object-browser-ng/property-viewer';

import { AdvancedCommandValue, DataPoint, SceneCommand } from '../interfaces/scene-editor';
import {
  DEFAULT_COMMAND_MODE,
  DISABLED_STRING_VALUE,
  getPropertyId,
  getPropertyName,
  getSelectedPropertyId
} from './default-models';

type AnyPropertyWithCommands = AnyProperty & { actions: PropertyCommand[] };

export const updateEnableDisableContentAction = (
  property: AnyProperty,
  sceneCommand: SceneCommand
): void => {
  // Not actually a typecast, as all the properties of commands are optional.
  const commandingProperty = property as AnyPropertyWithCommands;
  const commandText = sceneCommand.isEnabled
    ? 'SCENE_EDITOR.TEXT_SHORT.DISABLE_DATA_POINT'
    : 'SCENE_EDITOR.TEXT_SHORT.ENABLE_DATA_POINT';
  const enableDisableAction = commandingProperty.actions?.find(obj => obj.id === 'enable-disable');
  if (enableDisableAction) {
    enableDisableAction.title = commandText;
    commandingProperty.actions?.forEach(command => {
      if (
        command.id !== 'enable-disable' &&
        command.id !== 'about-data-point' &&
        command.title !== '-'
      ) {
        command.items?.forEach(subCommand => enableDisableSubCommand(subCommand, sceneCommand));
        command.disabled = sceneCommand.isEnabled
          ? command.id === 'advanced-commands'
            ? !command.items
            : command.id === 'execution-settings'
              ? !command.parameters
              : !sceneCommand.isEnabled
          : !sceneCommand.isEnabled;
      }
    });
  }
};

export const populateAdvancedCommands = (
  actions: PropertyCommand[] | undefined,
  selectedCommand: string | undefined,
  propertyContentActions: PropertyCommand[],
  command: SceneCommand,
  settings?: PropertyCommand | undefined
): PropertyCommand[] => {
  const thisPropAction = clone(propertyContentActions);
  const advancedCommand = thisPropAction.find(cmd => cmd.id === 'advanced-commands');
  if (advancedCommand) {
    if (actions?.length && actions?.length > 1) {
      advancedCommand.items = actions.map(advancedCommandItem =>
        generateAdvancedCommand(advancedCommandItem, command)
      );
    }
    advancedCommand.disabled = !(actions?.length && actions?.length > 1);
  }
  populateExecuteSettingCommand(thisPropAction, command, settings);
  return thisPropAction;
};

export const populateExecuteSettingCommand = (
  propertyContentActions: PropertyCommand[],
  command: SceneCommand,
  settings?: PropertyCommand | undefined
): void => {
  const executionCommand = propertyContentActions.find(cmd => cmd.id === 'execution-settings');
  if (executionCommand && settings) {
    executionCommand.action = settings.action;
    if (command.executionValues && Array.isArray(command.executionValues.settings)) {
      const parameters = clone(settings.parameters);
      updateParamValues(parameters, command.executionValues.settings);
      executionCommand.parameters = parameters;
    } else {
      executionCommand.parameters = settings.parameters;
    }
  }
};

export const enableDisableProperty = (
  property: AnyProperty,
  selectedItemId: string,
  defaultProperty: Map<string, [string, AnyProperty]>,
  changedCommand?: Map<string, [string, AnyProperty]>,
  sceneCommand?: SceneCommand
): void => {
  if (sceneCommand) {
    if (sceneCommand.isEnabled) {
      property.value = clone(DISABLED_STRING_VALUE);
    } else if (property?.id) {
      const id = getPropertyId(property.id);
      if (changedCommand?.has(id)) {
        const command = changedCommand.get(id);
        if (command && command[0] === selectedItemId) {
          property.value = anyPropertyValue(changedCommand, id, selectedItemId);
        } else {
          property.value = anyPropertyValue(defaultProperty, id, selectedItemId);
        }
      } else if (defaultProperty.has(id)) {
        property.value = anyPropertyValue(defaultProperty, id, selectedItemId, sceneCommand);
      }
    }
    sceneCommand.isEnabled = !sceneCommand.isEnabled;
  }
};

export const anyPropertyValue = (
  property: Map<string, [string, AnyProperty]>,
  id: string,
  selectedItemId: string,
  sceneCommand?: SceneCommand
): any => {
  const originalValue = property.get(id);
  if (originalValue && originalValue[0] === selectedItemId) {
    if (sceneCommand?.value) {
      if (
        originalValue[1].value.type === 'collection' &&
        originalValue[1].value.kind === 'priority-array'
      ) {
        const newVal = sceneCommand.value[0] as PriorityArrayItem<ValueBase>;
        updatePrioArrayItems(
          newVal,
          originalValue[1].value.value as PriorityArrayItem<ValueBase>[]
        );
      } else {
        originalValue[1].value.value = sceneCommand.value;
      }
    }
    return originalValue[1].value;
  }
  return {};
};

export const updatePropertyValue = (
  datapoint: DataPoint,
  command: SceneCommand,
  displayProperty: AnyProperty,
  propertyContentActions: PropertyCommand[],
  selectedCommand?: string,
  isCommandChange?: boolean
): void => {
  displayProperty.overrideMode = DEFAULT_COMMAND_MODE;

  const dataPointProperty = datapoint?.properties?.find(
    prop => prop.id === datapoint.selectedPropertyId
  );
  // Not actually a typecast, as all the properties of commands are optional.
  const dataPointCommandingProperty = dataPointProperty as AnyPropertyWithCommands;
  const actions = dataPointCommandingProperty?.actions;
  let selectedAction = actions?.find(action =>
    selectedCommand ? action.action === selectedCommand : action.isActive
  );
  const isActive = selectedAction?.isActive;

  selectedAction =
    selectedAction ?? actions?.find(action => action.parameters?.length === 0) ?? actions?.[0];

  if (!command.isEnabled || !isActive) {
    displayProperty.value = clone(DISABLED_STRING_VALUE);
    displayProperty.value.value = !command.isEnabled
      ? displayProperty.value.value
      : selectedAction?.title;
  } else {
    displayProperty.value = dataPointCommandingProperty?.value ?? displayProperty.value;
    if (displayProperty.value.type !== 'collection') {
      displayProperty.value.value = !isCommandChange
        ? command.value
        : dataPointCommandingProperty?.value.value ?? displayProperty.value.value;
    } else if (
      displayProperty.value.type === 'collection' &&
      displayProperty.value.kind === 'priority-array' &&
      command.value
    ) {
      const newVal = command.value[0] as PriorityArrayItem<ValueBase>;
      updatePrioArrayItems(newVal, displayProperty.value.value as PriorityArrayItem<ValueBase>[]);
    }
  }
  displayProperty.actions = populateAdvancedCommands(
    actions,
    selectedCommand ?? (selectedAction?.action as string),
    propertyContentActions,
    command,
    datapoint.executionSettings
  );
};

export const setReadOnlyProperties = (property: AnyProperty, readonly?: boolean): void => {
  property.value.readonly = readonly;
  if (property.value.type === 'collection') {
    property.value.value?.forEach(recordItem => {
      recordItem.value.readonly = readonly;
    });
  }
};

export const setReadOnlyActions = (property: AnyProperty, isDisabled: boolean): void => {
  property.actions?.forEach(action => {
    if (action.id !== 'about-data-point') {
      action.disabled = isDisabled;
    }
    enableDisableMenuItem(action.items, isDisabled);
  });
};

export const enableDisableMenuItem = (items?: MenuItem[], disable?: boolean): void => {
  if (items?.length) {
    items.forEach(item => {
      item.disabled = disable;
      enableDisableMenuItem(item.items);
    });
  }
};

export const getDataPointFullName = (
  dataPoint: DataPoint,
  selectedProperty?: AnyProperty,
  displayMode?: DisplayMode
): string => {
  if (dataPoint) {
    if (dataPoint.userDefinedName) {
      return dataPoint.userDefinedName;
    }
    const isDefault =
      dataPoint.defaultPropertyId && dataPoint.defaultPropertyId === dataPoint.selectedPropertyId;
    const propertyName = selectedProperty?.name
      ? getPropertyName(selectedProperty.name)
      : dataPoint.selectedPropertyId;
    if (
      !displayMode ||
      displayMode === 'name' ||
      displayMode === 'name-description' ||
      displayMode === 'name-alias'
    ) {
      return isDefault ? dataPoint.name : dataPoint.name + '.' + propertyName;
    } else if (
      displayMode === 'description' ||
      displayMode === 'description-name' ||
      displayMode === 'description-alias'
    ) {
      const name = dataPoint.description ? dataPoint.description : dataPoint.name;
      return isDefault ? name : name + '.' + propertyName;
    }
  }
  return '';
};

export const getDataPointFullDescription = (
  dataPoint: DataPoint,
  selectedProperty?: AnyProperty,
  displayMode?: DisplayMode
): string => {
  if (!dataPoint?.description) {
    return '';
  }
  if (dataPoint.userDefinedName) {
    if (displayMode === 'name-description') {
      const isDefault =
        dataPoint.defaultPropertyId && dataPoint.defaultPropertyId === dataPoint.selectedPropertyId;
      const propertyName = selectedProperty?.name
        ? getPropertyName(selectedProperty.name)
        : dataPoint.selectedPropertyId;
      return isDefault ? dataPoint.name : dataPoint.name + '.' + propertyName;
    } else if (displayMode === 'description-name' || displayMode === 'description') {
      return dataPoint.userDefinedName;
    }
  }
  if (displayMode === 'description') {
    const isDefault =
      dataPoint.defaultPropertyId && dataPoint.defaultPropertyId === dataPoint.selectedPropertyId;
    if (!isDefault) {
      const propertyName = selectedProperty?.name
        ? getPropertyName(selectedProperty.name)
        : dataPoint.selectedPropertyId;
      return dataPoint.description
        ? dataPoint.description + '.' + propertyName
        : dataPoint.description;
    }
  }
  return dataPoint.description;
};

export const getCommandValue = (
  property: AnyProperty,
  selectedAdvancedCommand?: CommandEvent
): any | AdvancedCommandValue[] =>
  getCommandEventValue(selectedAdvancedCommand) ?? property.value.value;

export const getCommandEventValue = (
  selectedAdvancedCommand?: CommandEvent | PropertyCommand
): AdvancedCommandValue[] | undefined =>
  selectedAdvancedCommand?.parameters?.map(parm => ({
    name: parm.id ?? '',
    value: parm.value.value
  }));

export const getExecutionSettingValues = (
  executeSettingCommand: CommandEvent | PropertyCommand,
  sceneCommand: SceneCommand
): void => {
  const settings = getCommandEventValue(executeSettingCommand);
  sceneCommand.executionValues = { settings };
};

export const generateAdvancedCommand = (
  advancedCommandItem: PropertyCommand,
  command: SceneCommand
): PropertyCommand => {
  const singleAdvancedCommand = clone(advancedCommandItem);
  singleAdvancedCommand.title = singleAdvancedCommand.isActive
    ? 'SCENE_EDITOR.TEXT_SHORT.FALLBACK_TO_DEFAULT_COMMAND'
    : singleAdvancedCommand.title;
  if (Array.isArray(command.value) && singleAdvancedCommand.action === command.selectedCommand) {
    updateParamValues(singleAdvancedCommand.parameters, command.value);

    const commandParmValues = command.value as AdvancedCommandValue[];
    singleAdvancedCommand.parameters?.forEach(parm => {
      const valueParm = commandParmValues.find(item => item.name === parm.id);
      parm.value.value = valueParm?.value ?? parm.value.value;
    });
  }
  return singleAdvancedCommand;
};

export const getSelectedAction = (selectedProp?: AnyProperty): PropertyCommand | undefined => {
  const selectedCommandingProp = selectedProp as AnyPropertyWithCommands | undefined;
  let selectedAction = selectedCommandingProp?.actions?.find(act => act.isActive);
  selectedAction =
    selectedAction ??
    selectedCommandingProp?.actions?.find(act => act.parameters?.length === 0) ??
    selectedCommandingProp?.actions?.[0];
  return selectedAction;
};

export const updatePrioArrayItems = (
  event: PriorityArrayItem<ValueBase>,
  items: PriorityArrayItem<ValueBase>[]
): void => {
  items.forEach(element => {
    if (element.position === event.position) {
      element.value.value = event.value.value;
    } else {
      element.value.value = undefined;
    }
  });
};

export const updateCollectionPropertyValueInDataPointProperties = (
  dataPoint: DataPoint,
  property: AnyProperty
): DataPoint => {
  if (
    property.id &&
    property.value.type === 'collection' &&
    property.value.kind === 'priority-array'
  ) {
    const id = getSelectedPropertyId(property.id);
    const prop = dataPoint.properties?.find(p => p.id === id);
    if (prop && property.value.value) {
      const newValue = property.value.value[0] as PriorityArrayItem<ValueBase>;
      updatePrioArrayItems(newValue, prop.value.value as PriorityArrayItem<ValueBase>[]);
    }
  }
  return dataPoint;
};

export const updatePrioArrayInUI = (dataPoint: DataPoint, property: AnyProperty): void => {
  if (property.id) {
    const dpId = getSelectedPropertyId(property.id);
    const dpProp = dataPoint.properties?.find(p => p.id === dpId);

    if (property && dpProp) {
      property.value.value = dpProp.value.value;
    }
  }
};

export const enableDisableSubCommand = (
  subCommand: PropertyCommand,
  sceneCommand: SceneCommand
): void => {
  subCommand.disabled =
    sceneCommand.selectedCommand === subCommand.id && sceneCommand.isEnabled
      ? !subCommand.parameters?.length ?? subCommand.isActive
      : !sceneCommand.isEnabled;
};

export const checkSelectedCommandDisabled = (
  property: AnyProperty,
  sceneCommand: SceneCommand
): boolean => {
  const action = property.actions?.find(w => w.id === 'advanced-commands');
  const selectedCommand = action?.items?.find(w => w.id === sceneCommand.selectedCommand);
  if (selectedCommand && !sceneCommand.isEnabled && !selectedCommand.isActive) {
    property.value.value = selectedCommand.title;
    return true;
  }
  return false;
};

export const updateParamValues = (
  parameters: AnyProperty[] | undefined,
  commandParmValues: AdvancedCommandValue[]
): void => {
  parameters?.forEach(parm => {
    const valueParm = commandParmValues.find(item => item.name === parm.id);
    parm.value.value = valueParm?.value ?? parm.value.value;
  });
};
