import { NgModule } from '@angular/core';
import { IPreselectionService, /* IQParamService,*/ IStorageService } from '@gms-flex/core';

import { SystemBrowserPreselectService } from './services/system-browser-preselect.service';
import { SystemBrowserStorageService } from './services/system-browser-storage.service';
// import { SystemBrowserQParamService } from "./services/system-browser-qparam.service";

@NgModule({
  providers: [{ provide: IPreselectionService, useClass: SystemBrowserPreselectService, multi: true },
    { provide: IStorageService, useClass: SystemBrowserStorageService, multi: true } /* ,
   { provide: IQParamService, useClass: SystemBrowserQParamService, multi: true }*/]
})
export class GmsSystemBrowserRootServicesModule {}
