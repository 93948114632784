import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  DeleteConfirmationDialogResult,
  MenuItem,
  SiActionDialogService,
  SiContentActionBarComponent,
  SiTranslateModule,
  ViewType
} from '@simpl/element-ng';

import { Scene } from '../../interfaces/scene-editor';
import { SiSceneEditorConfigService } from '../../services/si-scene-editor-config.service';
import { SiSceneEditorService } from '../../services/si-scene-editor.service';

let instanceId = 0;

@Component({
  selector: 'si-scene-properties',
  templateUrl: './si-scene-properties.component.html',
  styleUrls: ['./si-scene-properties.component.scss'],
  standalone: true,
  imports: [SiContentActionBarComponent, SiTranslateModule]
})
export class SiScenePropertiesComponent implements OnInit, OnChanges {
  private siSceneConfig = inject(SiSceneEditorConfigService);
  private siSceneService = inject(SiSceneEditorService);
  siModal = inject(SiActionDialogService);

  @Input() selectedScene!: Scene;
  @Input() objectId!: string;
  @Input() scenesList!: Scene[];
  @Input() readOnly = false;

  @Output() readonly removeScene = new EventEmitter<Scene>();

  readonly sceneNameInputId = `si-scene-properties-scene-name-${instanceId++}`;
  readonly viewType: ViewType = 'expanded';
  primaryActions: MenuItem[] = [];

  secondaryActions: MenuItem[] = [];
  restrictSceneEdit = this.siSceneConfig.get().restrictSceneEdit;

  isRepetationDisplay = false;
  errorText?: string;
  displaySceneName?: string;

  ngOnChanges(): void {
    const deleteMenuItem = this.secondaryActions.find(item => item.id === 'delete-scene');
    if (deleteMenuItem) {
      deleteMenuItem.disabled = this.scenesList.length <= 1;
    }
    this.displaySceneName = this.selectedScene.name;
    this.errorText = '';
  }

  ngOnInit(): void {
    this.isRepetationDisplay = this.siSceneConfig.get().repetitionTime;
    this.setContentActions();
    this.siSceneService.isValid$.subscribe(() => {
      this.errorText = '';
    });
  }

  private setContentActions(): void {
    if (!this.restrictSceneEdit) {
      if (!this.siSceneConfig.get().actionToDisable.learnValues) {
        this.primaryActions.push({
          id: 'learn-values',
          title: 'SCENE_EDITOR.TEXT_SHORT.LEARN_VALUES',
          action: () => this.learnValues()
        });
      }

      if (!this.siSceneConfig.get().actionToDisable.test) {
        this.primaryActions.push({
          id: 'test-scene',
          title: 'SCENE_EDITOR.TEXT_SHORT.TEST_SCENE',
          action: () => this.testScene()
        });
      }

      if (!this.siSceneConfig.get().actionToDisable.lock) {
        this.secondaryActions.unshift({ title: '-' });
        this.secondaryActions.unshift({
          title: 'SCENE_EDITOR.TEXT_SHORT.LOCK_SCENE',
          action: () => this.toggleSceneReadOnly()
        });
      }

      this.secondaryActions.push({
        id: 'delete-scene',
        title: 'SCENE_EDITOR.TEXT_SHORT.DELETE_SCENE',
        action: () => this.deleteScene()
      });

      if (this.isRepetationDisplay) {
        this.secondaryActions.push({ title: 'SCENE_EDITOR.TEXT_SHORT.ADVANCED' }); // TODO: implement `action
      }
    }
  }

  private isValidText(value: any): boolean {
    const regEx = this.siSceneConfig.get().invalidCharRegEx;
    return !(!value || (!!regEx && RegExp(regEx).test(value)));
  }

  learnValues(): void {
    this.siSceneService.learnValues(this.selectedScene.id, this.objectId);
  }

  testScene(): void {
    this.siSceneService.testScene(this.selectedScene.id, this.objectId);
  }

  hasChangesflag(value: any): void {
    const list = this.scenesList.filter(scene => scene.name.trim() === value.trim());
    if (list.length && list[0].id !== this.selectedScene.id) {
      this.errorText = 'SCENE_EDITOR.TEXT_SHORT.SCENE_ALREADY_EXISTS';
    } else {
      const isValid = this.isValidText(value.trim());
      this.errorText = isValid ? '' : 'SCENE_EDITOR.TEXT_SHORT.INVALID_SCENE_NAME_ERROR';
    }
    if (!this.errorText) {
      this.selectedScene.name = value;
      this.setIsDirty(true);
    }
  }

  deleteScene(): void {
    this.siModal
      .showDeleteConfirmationDialog(
        'SCENE_EDITOR.MESSAGES.DELETE_SCENE_CONFIRMATION',
        'SCENE_EDITOR.DIALOG_TEXT_SHORT.DELETE_SCENE',
        'SCENE_EDITOR.DIALOG_TEXT_SHORT.DELETE',
        'SCENE_EDITOR.DIALOG_TEXT_SHORT.CANCEL',
        { sceneName: this.selectedScene.name }
      )
      .subscribe(result => {
        switch (result) {
          case DeleteConfirmationDialogResult.Delete:
            this.removeScene.emit(this.selectedScene);
            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  toggleSceneReadOnly(): void {
    this.siSceneService.toggleSceneReadOnly(this.objectId, this.selectedScene.id);
  }

  setIsDirty(isDirty: boolean): void {
    this.siSceneService.setIsDirty(isDirty);
  }
}
