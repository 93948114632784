import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  InjectionToken,
  Input
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';
export const LOADING_SPINNER_BLOCKING = new InjectionToken<boolean>('isBlockingSpinner');
export const LOADING_SPINNER_OVERLAY = new InjectionToken<boolean>('isSpinnerOverlay');

@Component({
  selector: 'si-loading-spinner',
  templateUrl: './si-loading-spinner.component.html',
  styleUrl: './si-loading-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in')]),
      transition(':leave', animate('200ms ease-out', style({ opacity: 0 })))
    ])
  ],
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiLoadingSpinnerComponent {
  @HostBinding('@fadeAnimation') fadeAnimation = '';
  @Input() isBlockingSpinner = inject(LOADING_SPINNER_BLOCKING, { optional: true });
  @Input() isSpinnerOverlay = inject(LOADING_SPINNER_OVERLAY, { optional: true });
  /**
   * Needed for a11y
   */
  @Input() ariaLabel = $localize`:@@SI_LOADING_SPINNER.LABEL:Loading`;
}
