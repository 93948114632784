import { NgModule } from '@angular/core';
import { GmsEventDetailsRootServicesModule } from '@gms-flex/event-details-root-services';
import { GmsEventListRootServicesModule } from '@gms-flex/event-list-root-services';
import { GmsGraphicsViewerRootServicesModule } from '@gms-flex/graphics-viewer-root-services';
import { GmsPropertyRootServicesModule } from '@gms-flex/property-root-services';
import { GmsSummaryBarRootServicesModule } from '@gms-flex/summary-bar-root-services';
import { GmsSystemBrowserRootServicesModule } from '@gms-flex/system-browser-root-services';
import { GmsTextualViewerRootServicesModule } from '@gms-flex/textual-viewer-root-services';
import { GmsTrendRootServicesModule } from '@gms-flex/trend-root-services';

@NgModule({
  imports: [
    // ADD HERE YOUR ROOT SNAPIN MODULE NAME
    GmsEventDetailsRootServicesModule,
    GmsEventListRootServicesModule,
    GmsGraphicsViewerRootServicesModule,
    GmsPropertyRootServicesModule,
    GmsSummaryBarRootServicesModule,
    GmsSystemBrowserRootServicesModule,
    GmsTextualViewerRootServicesModule,
    GmsTrendRootServicesModule
  ]
})

export class RootServicesModule {}
