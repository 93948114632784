const DEFAULT_FRACTION_DIGITS = 5;

export const formatNumber = (
  value: number | bigint | undefined,
  fractionDigits?: number
): string => {
  if (value == null) {
    return '';
  }
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
    // Wrong type definition, see:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format#parameters
  }).format(value as number);
};

export const getFractionDigits = (value?: number): number => {
  let digits = DEFAULT_FRACTION_DIGITS;
  if (value != null) {
    const numberString = value.toString();
    const decimals = numberString.split('.')[1];
    const ePos = numberString.indexOf('e-');
    if (ePos !== -1) {
      digits = parseInt(numberString.substr(ePos + 2), 10);
      // Note: No need to add the index of "e" if there is no decimal point e.g. 1e-7. JS converts 0.0000001 to 1e-7.
      // JS converts 0.000000001010 to 1.01e-9. Thus 9 + index of e i.e. to makes it 11.
      // This is same as last index of 1 in .000000001010.
      if (decimals) {
        const eIndex = decimals.indexOf('e');
        if (eIndex !== -1) {
          digits += eIndex;
        }
      }
    } else if (!numberString.includes('e+')) {
      digits = decimals ? decimals.length : 0;
    }
  }
  return digits;
};
