import { SvgUtility } from '../utilities/parser';

export class GmsDepth {

  private readonly _name: string = '';
  public get Name(): string {
    return this._name;
  }

  private readonly _displaySize: number = 0;
  public get DisplaySize(): number {
    return this._displaySize;
  }

  private readonly _symbolScaleFactor: number = 1;
  public get SymbolScaleFactor(): number {
    return this._symbolScaleFactor;
  }

  constructor(name: string, displaySize: number, symbolScaleFactor: number) {
    this._name = name;
    this._displaySize = displaySize;
    this._symbolScaleFactor = symbolScaleFactor;
  }
}

export class GmsDepths {

  public selectedDepth: GmsDepth;
  public depthList: GmsDepth[] = [];

  public static createDepths(graphicNode: Node): GmsDepths {

    const graphicsDepth: GmsDepths = new GmsDepths();
    const childnodes: NodeList = graphicNode.childNodes;

    if (childnodes !== undefined) {
      for (let i = 0; i < childnodes.length; ++i) {

        const graphicDepths: Node = childnodes[i];

        if (graphicDepths.nodeName === 'Graphic.Depths') {
          for (let j = 0; j < graphicDepths.childNodes.length; ++j) {

            const depthInfoNode: Node = graphicDepths.childNodes[j];
            const depth: GmsDepth = this.createDepth(depthInfoNode);
            if (depth !== undefined) {
              graphicsDepth.depthList.push(depth);
            }
          }
          break;
        }
      }
    }
    if (graphicsDepth.depthList !== undefined && graphicsDepth.depthList.length > 0) {

      // Calculate selected depth
      let max = 0;
      let selectedDepth: GmsDepth;
      for (let i = 0; i < graphicsDepth.depthList.length; i++) {
        if (graphicsDepth.depthList[i].DisplaySize > max) {
          max = graphicsDepth.depthList[i].DisplaySize;
          selectedDepth = graphicsDepth.depthList[i];
        }
      }
      graphicsDepth.selectedDepth = selectedDepth;
    }
    return graphicsDepth;
  }

  private static createDepth(node: Node): GmsDepth {
    let result: GmsDepth;
    if (node.nodeName === 'DepthInfo') {
      const name: string = SvgUtility.GetAttributeValue(node, 'DepthName');
      let displaySize: string = SvgUtility.GetAttributeValue(node, 'DisplaySize');
      if (displaySize === undefined) {
        displaySize = '0';
      }
      let symbolScaleFactor: string = SvgUtility.GetAttributeValue(node, 'SymbolScaleFactor');
      if (symbolScaleFactor === undefined) {
        symbolScaleFactor = '1';
      }
      if (name !== undefined) {
        result = new GmsDepth(name, Number(displaySize), Number(symbolScaleFactor));
      } else {
        // NOTE: log error
      }
    }
    return result;
  }
}
