import { Injectable } from '@angular/core';
import { ThemeType } from '@simpl/element-ng';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ProductInfo } from '../product/data.model';
import { ProductService } from '../product/product.service';
import { TraceService } from '../trace/trace.service';
import { TraceModules } from './trace-modules';

/**
 * This service provides user and culture specific information.
 *
 * @export
 * @class AppContextService
 */
@Injectable({
  providedIn: 'root'
})
export class AppContextService {

  private readonly _userName: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  private readonly _userDescriptor: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  private readonly _userCulture: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  private readonly _defaultCulture: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  private readonly _userLocalizationCulture: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  private readonly _themeType: BehaviorSubject<ThemeType> = new BehaviorSubject<ThemeType>('auto');

  public constructor(private readonly traceService: TraceService,
    private readonly productService: ProductService) {
    this.traceService.info(TraceModules.utilities, 'AppContextService created.');

    this.productService.getProductSettings().pipe(
      take(1)).subscribe((productInfo: ProductInfo) => {
      if (productInfo.fallbackCulture !== null) {
        this.traceService.info(TraceModules.utilities, 'Setting default culture to: ' + productInfo.fallbackCulture);
        this.setDefaultCulture(productInfo.fallbackCulture);
      }
    });
  }

  /**
   * Gets the user name of the logged in user.
   *
   * @readonly
   * @type {string}
   * @memberof AppContextService
   */
  public get userNameValue(): string {
    return this._userName.getValue();
  }

  /**
   * Gets the user name of the logged in user
   *
   * @returns
   * @memberof AppContextService
   */
  public get userName(): Observable<string> {
    return this._userName.asObservable();
  }

  /**
   * Sets the user name of the logged in user
   *
   * @returns
   * @memberof AppContextService
   */
  public setUserName(userName: string): void {
    if (userName !== null) {
      this._userName.next(userName);
    } else {
      this.traceService.error(TraceModules.utilities, 'Invalid parameter for user name');
    }
  }

  /**
   * Gets the user descriptor of the logged in user.
   *
   * @readonly
   * @type {string}
   * @memberof AppContextService
   */
  public get userDescriptorValue(): string {
    return this._userDescriptor.getValue();
  }

  /**
   * Gets the user descriptor of the logged in user
   *
   * @returns
   *
   */
  public get userDescriptor(): Observable<string> {
    return this._userDescriptor.asObservable();
  }

  /**
   * Sets the user descriptor of the logged in user
   *
   * @returns
   *
   */
  public setUserDescriptor(userDescriptor: string): void {
    if (userDescriptor !== null) {
      this._userDescriptor.next(userDescriptor);
    } else {
      this.traceService.error(TraceModules.utilities, 'Invalid parameter for user descriptor.');
    }
  }

  /**
   * Gets the user culture of the logged in user; use this culture for text translations
   *
   * @returns
   * @memberof AppContextService
   */
  public get userCulture(): Observable<string> {
    return this._userCulture.asObservable();
  }

  /**
   * Gets the user culture of the logged in user; use this culture for text translations
   *
   * @readonly
   * @type {string}
   * @memberof AppContextService
   */
  public get userCultureValue(): string {
    return this._userCulture.getValue();
  }

  /**
   * Sets the user culture of the logged in user; this culture is used for text translations
   *
   * @returns
   * @memberof AppContextService
   */
  public setUserCulture(userCulture: string): void {
    if (userCulture !== null) {
      this._userCulture.next(userCulture);
    } else {
      this.traceService.error(TraceModules.utilities, 'invalid parameter for user culture');
    }
  }

  /**
   * Gets the default culture
   *
   * @returns
   * @memberof AppContextService
   */
  public get defaultCulture(): Observable<string> {
    return this._defaultCulture.asObservable();
  }

  /**
   * Gets the value of the default culture
   *
   * @readonly
   * @type {string}
   * @memberof AppContextService
   */
  public get defaultCultureValue(): string {
    return this._defaultCulture.getValue();
  }

  /**
   * Gets the default culture
   *
   * @returns
   * @memberof AppContextService
   */
  public setDefaultCulture(defaultCulture: string): void {
    if (defaultCulture !== null) {
      this._defaultCulture.next(defaultCulture);
    } else {
      this.traceService.error(TraceModules.utilities, 'invalid parameter for default culture');
    }
  }

  /**
   * Gets the user localization culture of the logged in user; use this culture to format values.
   *
   * @returns
   * @memberof AppContextService
   */
  public get userLocalizationCulture(): Observable<string> {
    return this._userLocalizationCulture.asObservable();
  }

  /**
   * Gets the user localization culture of the logged in user; use this culture to format values.
   *
   * @readonly
   * @type {string}
   * @memberof AppContextService
   */
  public get userLocalizationCultureValue(): string {
    return this._userLocalizationCulture.getValue();
  }

  /**
   * Sets the user localization culture of the logged in user; this culture is used to format values.
   *
   * @returns
   * @memberof AppContextService
   */
  public setUserLocalizationCulture(userLocalizationCulture: string): void {
    if (userLocalizationCulture !== null) {
      this._userLocalizationCulture.next(userLocalizationCulture);
    } else {
      this.traceService.error(TraceModules.utilities, 'invalid parameter for user localization culture');
    }
  }

  /**
   * Gets the selected theme type of the application.
   *
   * @readonly
   * @type {string}
   * @memberof AppContextService
   */
  public get themeTypeValue(): ThemeType {
    return this._themeType.getValue();
  }

  /**
   * Gets the selected theme type of the application.
   *
   * @returns
   * @memberof AppContextService
   */
  public get themeType(): Observable<ThemeType> {
    return this._themeType.asObservable();
  }

  /**
   * Sets the selected theme type of the application.
   *
   * @returns
   * @memberof AppContextService
   */
  public setThemeType(themeType: ThemeType): void {
    if (themeType !== null) {
      this._themeType.next(themeType);
    } else {
      this.traceService.error(TraceModules.utilities, 'Invalid parameter for theme type');
    }
  }
}
