export class HfwUtility {

  /**
   * Returns stringified version of a generic object avoiding circular dependencies.
   *
   * @param obj
   * @returns
   *
   * @memberOf HfwUtility
   */
  public static serializeObject(obj: any): string {
    let res = '';
    if (obj !== null) {
      let cache: any[] = [];
      res = JSON.stringify(obj, (_key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.includes(value)) {
            // Duplicate reference found
            try {
              // If this value does not reference a parent it can be deduped
              return JSON.parse(JSON.stringify(value));
            } catch (error) {
              // discard key if value cannot be deduped
              return;
            }
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
      cache = [];
    }
    return res;
  }
}
