import { Injectable } from '@angular/core';
import { FullSnapInId, IStorageService } from '@gms-flex/core';
import { TraceService } from '@gms-flex/services-common';

import { TraceModules } from './trace-modules';

@Injectable()
export class SystemBrowserStorageService implements IStorageService {

  public typeId = 'SystemBrowserType';

  private readonly _trModule: string = TraceModules.sysBrowserServices;

  constructor(private readonly traceService: TraceService) {
    this.traceService.info(this._trModule, 'SystemBrowserStorageService created.');
  }

  public getState(fullId: FullSnapInId): any {
    //
  }

  public setState(fullId: FullSnapInId, state: any): void {
    //
  }

  public clearState(fullId: FullSnapInId): void {
    //
  }

  public getDirtyState(fullId: FullSnapInId): boolean {
    return;
  }

  public setDirtyState(fullId: FullSnapInId, state: boolean): void {
    //
  }
}
