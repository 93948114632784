<div class="filter-pill">
  <div class="btn-close" (click)="onDeleteClick()">
    <i class="icon element-cancel"></i>
  </div>
<!--  class="btn btn-circle btn-sm btn-tertiary pill-btn-close element-cancel ms-1"-->
<!--  <div>-->
<!--    <button (click)="onDeleteClick()"-->
<!--            class="btn-close"-->
<!--            style="width: 16px; height: 16px; flex: 0 0 auto;">-->
<!--    </button>-->
<!--  </div>-->
  <div class="name">
    <span>{{filterTitle}}</span>
  </div>
  <div class="value">
    <div style="display: flex">
      <span class="pill-ellipsis" [ngClass]="filterValuesCount > 1 ? 'multiple' : ''">{{ pillInfo() | async }}</span>
    </div>
</div>
