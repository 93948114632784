import { Injectable } from '@angular/core';
import { BrowserObject, CnsLocation, Designation, DpIdentifier, EventCommand, TraceModules, WSIEvent } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';

import { EventBx, EventStateBx } from '../../bx-services/alarm/events-proxy.model';
import { pointPropertyName } from '../properties/point-value-mapper.service';
import { unassignedDisciplineSubDiscipline } from '../system-browser/system-browser-mapper-bx-to-gms.service';
import { EventCategoriesBxSubstituteProxyService } from './event-categories-bx-substitute-proxy.service';

// TODO: needs to be removed/replaced. Discuss the icon to be used in the event list
export const disciplineBuildingAutomationId = 10000;
export const disciplineBuildingAutomationDescriptor = 'Comfort';

export const enum EventCommandIdGms {
  Ack = 'Ack',
  Reset = 'Reset',
  Silence = 'Silence',
  Unsilence = 'Unsilence',
  Close = 'Close',
  Suspend = 'Suspend',
  Select = 'Select'
}

export const enum EventCommandSuggestedActionGms {
  Acknowledge = 'Acknowledge',
  Silence = 'Silence',
  WaitForCommandExecution = 'WaitForCommandExecution',
  Suspend = 'Suspend',
  Reset = 'Reset',
  Close = 'Close',
  CompleteOP = 'CompleteOP',
  WaitforCondition = 'WaitforCondition',
  None = 'None'
}

// TODO: clarify possible entries and the use of the nextCommandField of the Event
export const enum EventCommandNextActionGms {
  Acknowledge = 'Acknowledge',
  Silence = 'Silence',
  WaitForCommandExecution = 'WaitForCommandExecution',
  Suspend = 'Suspend',
  Reset = 'Reset',
  Close = 'Close',
  CompleteOP = 'CompleteOP',
  NoActionPossible = 'NoActionPossible'
}

export enum EventSrcStateGms {
  Inactive = 'Quiet',
  Active = 'Active'
}

export enum EventStateGms {
  Unprocessed = 'Unprocessed',
  UnprocessedWithTimer = 'UnprocessedWithTimer',
  ReadyToBeReset = 'ReadyToBeReset',
  ReadyToBeResetWithTimer = 'ReadyToBeResetWithTimer',
  ReadyToBeClosed = 'ReadyToBeClosed',
  WaitingOPCompletion = 'WaitingOPCompletion',
  Acked = 'Acked',
  WaitingForCommandExecution = 'WaitingForCommandExecution',
  Closed = 'Closed'
}

@Injectable({
  providedIn: 'root'
})
export class EventMapperBxToGmsService {

  private static eventIdCounter = 0;
  private readonly eventIdCounterPerEventId: Map<string, number> = new Map<string, number>();

  constructor(private readonly eventCategoryService: EventCategoriesBxSubstituteProxyService,
    private readonly traceService: TraceService) {}

  /*
  Event State Display Mapping (to model 'WSIEvent') of DesigoCC:
  https://simpl.code.siemens.io/simpl-element/patterns/icons/event-state/

  SrcState: 'Qiet' and State:	'Unprocessed' => Event source inactive, Unacknowledged
  SrcState: 'Qiet' and State:	'UnprocessedWithTimer' => Event source inactive, Unacknowledged
  SrcState: 'Qiet' and State:	'ReadyToBeReset' => Event source inactive, Acknowledged
  SrcState: 'Qiet' and State:	'ReadyToBeResetWithTimer' => Event source inactive, Acknowledged
  SrcState: 'Qiet' and State:	'ReadyToBeClosed' => Event source inactive, Acknowledged
  SrcState: 'Qiet' and State:	'WaitingOPCompletion' => Event source inactive, Acknowledged
  SrcState: 'Qiet' and State:	'Acked' => Event source inactive, Acknowledged
  SrcState: 'Qiet' and State:	'WaitingForCommandExecution' => Event source inactive, Acknowledged
  SrcState: 'Qiet' and State:	'Closed' => Event is removed from list

  SrcState: 'Active' and State:	'Unprocessed' => Event source active, Unacknowledged
  SrcState: 'Active' and State:	'UnprocessedWithTimer' => Event source active, Unacknowledged
  SrcState: 'Active' and State:	'ReadyToBeReset' => Event source active, Acknowledged
  SrcState: 'Active' and State:	'ReadyToBeResetWithTimer' => Event source active, Acknowledged
  SrcState: 'Active' and State:	'ReadyToBeClosed' => Event source active, Acknowledged
  SrcState: 'Active' and State:	'WaitingOPCompletion' => Event source active, Acknowledged
  SrcState: 'Active' and State:	'Acked' => Event source active, Acknowledged
  SrcState: 'Active' and State:	'WaitingForCommandExecution' => Event source active, Acknowledged
  SrcState: 'Active' and State:	'Closed' => Event is removed from list

  Event State Display Mapping of BX:
  https://simpl.code.siemens.io/simpl-element/patterns/icons/event-state/

  => Alarm Service returns events; two fields define the alarm 'state'
  isActive: true => Event source active
  isActive: false => Event source inactive

  state: 'active' => Event source active, Unacknowledged
  state: 'activeAcknowledged' => Event source active, Acknowledged
  state: 'inactive' => Event source inactive, Unacknowledged
  state: 'inactiveAcknowledged' => Event source inactive, Acknowledged
  state: 'closed' => Event closed
  */

  public createEvent(eventData: EventBx, sourceNode?: BrowserObject, locationNode?: BrowserObject): WSIEvent {
    this.eventIdCounterPerEventId.set(eventData.eventId, EventMapperBxToGmsService.eventIdCounter);
    let event: WSIEvent;
    if (sourceNode !== undefined) {
      const loc: CnsLocation = new CnsLocation(sourceNode.Location);
      const locParent = new CnsLocation(loc.parentNodeLocation);
      const des = new Designation(sourceNode.Designation);
      const uniqueEventId = eventData.eventId;
      /* eslint-disable @typescript-eslint/naming-convention */
      event = {
        CategoryDescriptor:	this.eventCategoryService.getCategory(eventData.category).CategoryName,
        CategoryId: this.eventCategoryService.getCategory(eventData.category).CategoryId,
        Cause:	eventData.message ? eventData.message : 'No cause set!',
        Commands: this.getCommands(eventData.state, uniqueEventId), // TODO: return empty array
        CreationTime: eventData.raisedAt,
        Deleted:	false, // TODO: clarify the semantic/usage?
        DescriptionList: [{ ViewId: sourceNode.ViewId, Descriptor: sourceNode.Descriptor }],
        DescriptionLocationsList: [{ ViewId: sourceNode.ViewId, Descriptor: locParent.locationWoSystemView }],
        DesignationList: [{ ViewId: sourceNode.ViewId, Descriptor: sourceNode.Designation }],
        Direction: 'None', // No In/Out events are supported
        EventId: EventMapperBxToGmsService.eventIdCounter,
        Id: uniqueEventId,
        InfoDescriptor: 'InfoDescriptor',
        InProcessBy:	'',
        MessageText: [],
        NextCommand: this.getNextAction(eventData.state), // TODO: Check semantics, is used?
        Sound: '',
        SourceDesignationList: [{ ViewId: sourceNode.ViewId, Descriptor: `${sourceNode.Name}${DpIdentifier.propertyNameSeparator}${pointPropertyName}` }],
        SrcDescriptor: `${sourceNode.Descriptor}${DpIdentifier.propertyNameSeparator}${pointPropertyName}`,
        SrcDesignation: des.designationWoSystemView,
        SrcDisciplineDescriptor:	disciplineBuildingAutomationDescriptor,
        SrcDisciplineId: disciplineBuildingAutomationId,
        SrcLocation: `${loc.locationWoSystemView}${DpIdentifier.propertyNameSeparator}${pointPropertyName}`,
        SrcName:	`${sourceNode.Name}${DpIdentifier.propertyNameSeparator}${pointPropertyName}`,
        // TODO: colon is needed because of the grouping algorithm (in case grouping is enabled)
        SrcObservedPropertyId:	`${sourceNode.Attributes.ObjectId}${DpIdentifier.propertyNameSeparator}${pointPropertyName}:_alert_hdl.2._value`,
        // TODO: colon is needed because of the grouping algorithm (in case grouping is enabled)
        SrcPropertyId:	`${sourceNode.Attributes.ObjectId}${DpIdentifier.propertyNameSeparator}${pointPropertyName}:_alert_hdl.2._value`,
        SrcState: this.getEventSrcStateDcc(eventData.isActive),
        SrcSubDisciplineId: unassignedDisciplineSubDiscipline.id,
        SrcSystemId:	sourceNode.SystemId,
        SrcViewDescriptor:	loc.viewDescription,
        SrcViewName:	des.viewName,
        State: this.getEventStateDcc(eventData.state),
        SuggestedAction: this.getSuggestedAction(eventData.state), // TODO
        SrcSystemName: des.systemName,
        SrcAlias: '',
        OperatingProcedureId: undefined,
        InformationText: '',
        Timer: '0001-01-01T00:00:00Z', // clarify semantics and usage
        AutomaticTreatmentData: undefined,
        BuildingName: locationNode.Name
      };
      /* eslint-enable @typescript-eslint/naming-convention */
    } else {
      const uniqueEventId = eventData.eventId;
      /* eslint-disable @typescript-eslint/naming-convention */
      event = {
        CategoryDescriptor:	this.eventCategoryService.getCategory(eventData.category).CategoryName,
        CategoryId: this.eventCategoryService.getCategory(eventData.category).CategoryId,
        Cause:	eventData.message ? eventData.message : 'No cause set!',
        Commands: this.getCommands(eventData.state, uniqueEventId), // TODO
        CreationTime: eventData.raisedAt,
        Deleted:	false, // TODO
        DescriptionList: [],
        DescriptionLocationsList: [],
        DesignationList: [],
        Direction: 'None', // // No In/Out events are supported
        EventId: EventMapperBxToGmsService.eventIdCounter,
        Id: uniqueEventId,
        InfoDescriptor: 'InfoDescriptor',
        InProcessBy:	'',
        MessageText: [],
        NextCommand: this.getNextAction(eventData.state), // TODO: Check semantics, is used?
        Sound: '',
        SourceDesignationList: [],
        SrcDescriptor: '',
        SrcDesignation: '',
        SrcDisciplineDescriptor:	disciplineBuildingAutomationDescriptor,
        SrcDisciplineId: disciplineBuildingAutomationId,
        SrcLocation: '',
        SrcName:	'',
        SrcObservedPropertyId:	'', // TODO: colon is needed because of the grouping algorithm
        SrcPropertyId:	'', // TODO: colon is needed because of the grouping algorithm
        SrcState: this.getEventSrcStateDcc(eventData.isActive),
        SrcSubDisciplineId: unassignedDisciplineSubDiscipline.id,
        SrcSystemId:	undefined,
        SrcViewDescriptor:	'',
        SrcViewName:	'',
        State: this.getEventStateDcc(eventData.state),
        SuggestedAction: this.getSuggestedAction(eventData.state), // TODO
        SrcSystemName: '',
        SrcAlias: '',
        OperatingProcedureId: undefined,
        InformationText: '',
        Timer: '0001-01-01T00:00:00Z',
        AutomaticTreatmentData: undefined,
        BuildingName: locationNode ? locationNode.Name : ''
      };
      /* eslint-enable @typescript-eslint/naming-convention */
    }

    // TODO: properly implement the WSI API! What is the semantics of the event counter?
    (event as any).Counter = EventMapperBxToGmsService.eventIdCounter;
    (event as any).SrcSubDisciplineDescriptor = unassignedDisciplineSubDiscipline.descriptor;

    EventMapperBxToGmsService.eventIdCounter++;
    return event;
  }

  public getEventSrcStateDcc(srcStateOm: boolean): EventSrcStateGms {
    return (srcStateOm === true) ? EventSrcStateGms.Active : EventSrcStateGms.Inactive;
  }

  public getEventStateDcc(stateBx: EventStateBx): EventStateGms {
    switch (stateBx) {
      case EventStateBx.Active:
        return EventStateGms.Unprocessed;
      case EventStateBx.ActiveAcknowledged:
        return EventStateGms.Acked;
      case EventStateBx.Inactive:
        return EventStateGms.Unprocessed;
      case EventStateBx.InactiveAcknowledged:
        return EventStateGms.Acked;
      case EventStateBx.Closed:
        return EventStateGms.Closed;
      default:
        this.traceService.error(TraceModules.events, `EventMapperBxToGmsService.getEventStateDcc(): Unhandled BX event state: ${stateBx}`);
        return EventStateGms.Unprocessed;
    }
  }

  public getSuggestedAction(stateBx: EventStateBx): string {
    switch (stateBx) {
      case EventStateBx.Active:
        return EventCommandSuggestedActionGms.Acknowledge;
      case EventStateBx.ActiveAcknowledged:
        return EventCommandSuggestedActionGms.WaitforCondition;
      case EventStateBx.Inactive:
        return EventCommandSuggestedActionGms.Acknowledge;
      case EventStateBx.InactiveAcknowledged:
        return EventCommandSuggestedActionGms.Reset;
      case EventStateBx.Closed:
        return EventCommandSuggestedActionGms.None;
      default:
        this.traceService.error(TraceModules.events, `EventMapperBxToGmsService.getSuggestedAction(): Unhandled BX event state: ${stateBx}`);
        return EventCommandSuggestedActionGms.None;
    }
  }

  public getNextAction(stateBx: EventStateBx): string {
    switch (stateBx) {
      case EventStateBx.Active:
        return EventCommandNextActionGms.Acknowledge;
      case EventStateBx.ActiveAcknowledged:
        return EventCommandNextActionGms.NoActionPossible;
      case EventStateBx.Inactive:
        return EventCommandNextActionGms.Acknowledge;
      case EventStateBx.InactiveAcknowledged:
        return EventCommandNextActionGms.Reset;
      case EventStateBx.Closed:
        return EventCommandNextActionGms.NoActionPossible;
      default:
        this.traceService.error(TraceModules.events, `EventMapperBxToGmsService.getNextAction(): Unhandled BX event state: ${stateBx}`);
        return EventCommandNextActionGms.NoActionPossible;
    }
  }

  public getCommands(stateBx: EventStateBx, uniqueEventId: string): EventCommand[] {
    switch (stateBx) {
      case EventStateBx.Active:
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        return [{ Configuration: 1, Id: EventCommandIdGms.Ack, EventId: uniqueEventId }];
      case EventStateBx.ActiveAcknowledged:
        return [];
      case EventStateBx.Inactive:
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        return [{ Configuration: 1, Id: EventCommandIdGms.Ack, EventId: uniqueEventId }];
      case EventStateBx.InactiveAcknowledged:
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        return [{ Configuration: 1, Id: EventCommandIdGms.Reset, EventId: uniqueEventId }];
      case EventStateBx.Closed:
        return [];
      default:
        this.traceService.error(TraceModules.events, `EventMapperBxToGmsService.getCommands(): Unhandled BX event state: ${stateBx}`);
        return [];
    }
  }
}
