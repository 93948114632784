import { Observable, Observer, Subscription } from 'rxjs';

import { CommandViewModel, GmsCommandResult } from '../processor/command-view-model/gms-command-vm';
import { ParameterNumericViewModel } from '../processor/command-view-model/gms-parameter-numeric-vm';
import { ControlEditorMode, ParameterViewModelBase } from '../processor/command-view-model/gms-parameter-vm.base';
import { GmsCommand } from '../processor/command/gms-command';
import { ParameterType } from '../processor/command/parameters/gms-base-parameter';
import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementDisabledStyle, GmsElementType } from '../types/gms-element-types';
import { Constants } from '../utilities/constants';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { GmsElement } from './gms-element';
import { GmsGraphic } from './gms-graphic';
import { GmsGroup } from './gms-group';
import { GmsLayer } from './gms-layer';
import { GmsSymbolInstance } from './gms-symbol-instance';

export class GmsCommandControl extends GmsElement {

  protected static DEFAULT_MINIMUM: number = Number.NaN;
  protected static DEFAULT_MAXIMUM: number = Number.NaN;
  protected static DEFAULT_UPDATEINTERVAL = 500;
  protected static DEFAULT_TICKFREQUENCY = 1;
  protected static DISABLED_COLOR = '#EBEBE4';

  protected _evaluationCommandFontSize: Evaluation;
  protected _evaluationCommandFontFamily: Evaluation;
  protected _evaluationCommandFillColor: Evaluation;
  private _parameterVM: ParameterViewModelBase = null;
  private _subscribeParamVMupdates: Subscription = undefined;
  private _evaluationCommandParameterName: Evaluation;
  private _evaluationMinimum: Evaluation;
  private _evaluationMaximum: Evaluation;

  private _timer: any;

  private _isFocused = false;

  private _selectionStart: number = null;
  private _selectionEnd: number = null;

  public get ParameterVM(): ParameterViewModelBase {
    return this._parameterVM;
  }
  public set ParameterVM(value: ParameterViewModelBase) {
    if (this._parameterVM !== value) {
      if (this._parameterVM !== null) {
        if (this._subscribeParamVMupdates !== undefined && !this._subscribeParamVMupdates.closed) {
          this._subscribeParamVMupdates.unsubscribe();
          this._subscribeParamVMupdates = undefined;
        }
      }
      this._parameterVM = value;

      if (value !== null) { // re-subscribe for ParameterVM's properties update
        this._subscribeParamVMupdates = this._parameterVM.propertyChanged.subscribe(args => this.ParameterVM_PropertyChanged(args));
      }
    }
  }

  private _controlType: GmsCommandControlType = GmsCommandControlType.Undefined;
  public get ControlType(): GmsCommandControlType {
    return this._controlType;
  }

  public set ControlType(value: GmsCommandControlType) {
    if (this._controlType !== value) {
      this._controlType = value;
      this.NotifyPropertyChanged('ControlType');
    }
  }
  private _commandParameterName = '';
  public get CommandParameterName(): string {
    return this._commandParameterName;
  }
  public set CommandParameterName(value: string) {
    this._commandParameterName = value;
  }

  private _minimum: number = GmsCommandControl.DEFAULT_MINIMUM;
  public get Minimum(): number {
    return this._minimum;
  }
  public set Minimum(value: number) {
    this._minimum = value;
  }

  private _maximum: number = GmsCommandControl.DEFAULT_MAXIMUM;
  public get Maximum(): number {
    return this._maximum;
  }
  public set Maximum(value: number) {
    this._maximum = value;
  }

  // CommandFontFamily
  private _commandFontFamily = '';
  public get CommandFontFamily(): string {
    return this._commandFontFamily;
  }
  public set CommandFontFamily(value: string) {
    this._commandFontFamily = value;
  }

  private _commandFontSize = '';
  public get CommandFontSize(): string {
    return this._commandFontSize;
  }

  public set CommandFontSize(value: string) {
    this._commandFontSize = value;
  }

  private _commandFillColor = '';
  public get CommandFillColor(): string {
    return this._commandFillColor;
  }

  public set CommandFillColor(value: string) {
    this._commandFillColor = value;
  }

  public get isFloatType(): boolean {
    return this.ParameterVM !== null && (this.ParameterVM.dataType === 'BasicFloat' || this.ParameterVM.dataType === 'ExtendedReal');
  }

  public get IsFocused(): boolean {
    return this._isFocused;
  }

  public set IsFocused(value: boolean) {
    this._isFocused = value;
  }

  public get IsInViewMode(): boolean {
    return this.ParameterVM !== null && this.ParameterVM.ControlEditorMode === ControlEditorMode.View;
  }

  public get IsInHighlightMode(): boolean {
    return this.ParameterVM !== null && this.ParameterVM.ControlEditorMode === ControlEditorMode.Highlight;
  }

  public get IsControlInEditMode(): boolean {
    return this.ParameterVM !== null && this.ParameterVM.ControlEditorMode === ControlEditorMode.Edit;
  }

  public setControlEditorMode(mode: ControlEditorMode): void {
    if (this.ParameterVM.setControlEditorMode(mode)) {
      let reformattedValue: string = this.ParameterVM.FormattedValue;
      // reformattedValue = FormatHelper.Replace(reformattedValue,
      //    FormatHelper.getGroupingSeparator(this.locale));

      if (this.ParameterVM.ParameterType === ParameterType.Numeric) {
        // try to convert a user input to a number
        const num: number = FormatHelper.getNumberfromString(reformattedValue, this.locale);
        // format the number with Precision
        if (!Number.isNaN(num)) {
          // force to update the Editor mode's initial value
          reformattedValue = FormatHelper.NumberToString(num,
            this.locale,
            (this.Graphic as GmsGraphic).defaultLocale,
            this.isFloatType ? this.GetAnimatedCommandPrecision() : 0);
        }
      } else if (this.isLongTypeParameter) {
        const numLong: Long = FormatHelper.parseLongValue(reformattedValue,
          this.ParameterVM.ParameterType === ParameterType.NumericUInt64, this.locale);

        if (numLong !== undefined) {
          // force to update the Editor mode's initial value
          reformattedValue = FormatHelper.formatNumber(numLong, this.locale);
        }
      }

      if (this.IsControlInEditMode) {
        reformattedValue = FormatHelper.Replace(reformattedValue, FormatHelper.getGroupingSeparator(this.locale));
      }

      if (this.ParameterVM.FormattedValue !== reformattedValue) {
        this.ParameterVM.FormattedValue = reformattedValue;

        this.NotifyPropertyChanged('FormattedValue');
      }

      if (this.IsInHighlightMode) {
        this.NotifyPropertyChanged('SelectAll');
      }
    }

    this.NotifyPropertyChanged('IsInViewMode');
    this.NotifyPropertyChanged('IsInHighlightMode');
    this.NotifyPropertyChanged('IsControlInEditMode');
  }

  public updateEditor(): void {
    // when user modifies control, propagate changes to all subscribed children.
    this.ParameterVM.setControlEditorMode(this.CommandVM.isModified ? ControlEditorMode.Edit : ControlEditorMode.View);
    this.NotifyPropertyChanged('IsInViewMode');
    this.NotifyPropertyChanged('IsInHighlightMode');
    this.NotifyPropertyChanged('IsControlInEditMode');
  }

  public resetEditorMode(): void {
    if (this.ParameterVM !== null) {
      if (this.IsControlInEditMode) {
        if (this.ParameterVM.setControlEditorMode(ControlEditorMode.View)) {
          this.NotifyPropertyChanged('IsInViewMode');
          this.NotifyPropertyChanged('IsInHighlightMode');
          this.NotifyPropertyChanged('IsControlInEditMode');
        }
      }
    }
  }

  public get SelectionStart(): number {
    return this.IsInHighlightMode ? this._selectionStart : null;
  }

  public set SelectionStart(value: number) {
    if (this._selectionStart !== value) {
      this._selectionStart = value;
      this.NotifyPropertyChanged('SelectionStart');
    }
  }

  public get SelectionEnd(): number {
    return this.IsInHighlightMode ? this._selectionEnd : null;
  }

  public set SelectionEnd(value: number) {
    if (this._selectionEnd !== value) {
      this._selectionEnd = value;
      this.NotifyPropertyChanged('SelectionEnd');
    }
  }

  public constructor(controlType: GmsCommandControlType) {
    super(GmsElementType.CommandControl);

    this._controlType = controlType;
    this.children = [];
  }

  public Destroy(): void {

    this._evaluationCommandParameterName = undefined;
    this._evaluationMinimum = undefined;
    this._evaluationMaximum = undefined;
    this._evaluationCommandFontSize = undefined;
    this._evaluationCommandFontFamily = undefined;
    this.ParameterVM = null;
    clearTimeout(this._timer);

    super.Destroy();
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');

  }

  public Deserialize(node: Node): void {

    // Design Mode - no command available yet (possibly never)
    this.createDesignModeViewModel();

    // ControlType
    let result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ControlType);
    if (result !== undefined) {
      this.ControlType = GmsCommandControlType[result];
    }

    // CommandParameterName
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandParameterName);
    if (result !== undefined) {
      this.CommandParameterName = result;
    }

    // Minimum
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Minimum);
    if (result !== undefined) {
      this.Minimum = FormatHelper.StringToNumber(result);
    }

    // Maximum
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Maximum);
    if (result !== undefined) {
      this.Maximum = FormatHelper.StringToNumber(result);
    }

    // CommandFontFamily
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandFontFamily);
    if (result !== undefined) {
      this.CommandFontFamily = result;
    }

    // CommandFontSize
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandFontSize);
    if (result !== undefined) {
      this.CommandFontSize = result;
    }

    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Fill);
    if (result !== undefined) {
      this.CommandFillColor = result;
    }

    this.AddCommandButtons();
    super.Deserialize(node);
    super.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsCommandControl): void {

    // ControlType
    this.ControlType = element.ControlType;

    // CommandParameterName
    this.CommandParameterName = element.CommandParameterName;

    // Minimum
    this.Minimum = element.Minimum;

    // Maximum
    this.Maximum = element.Maximum;

    // CommandFontFamily
    this.CommandFontFamily = element.CommandFontFamily;

    // CommandFontSize
    this.CommandFontSize = element.CommandFontSize;

    super.CopyFrom(element);
    super.CopyEvaluations(element);
  }
  public IsTriggerEnabled(): boolean {
    return this.ParameterVM != null && this.ParameterVM.IsTriggerEnabled;
  }

  public GetCommandEnabled(): boolean {
    return this.CommandVM !== null ? this.CommandVM.IsCommandEnabled : false;
  }

  public get ParameterMin(): number {
    return this.ParameterVM !== null ? this.ParameterVM.minValue : ParameterViewModelBase.defaultModelMinimum;
  }
  public get ParameterMax(): number {
    return this.ParameterVM !== null ? this.ParameterVM.maxValue : ParameterViewModelBase.defaultModelMaximum;
  }
  public get isLongTypeParameter(): boolean {
    return (this.ParameterVM !== null &&
            (this.ParameterVM.ParameterType === ParameterType.NumericInt64 ||
            this.ParameterVM.ParameterType === ParameterType.NumericUInt64));
  }

  public GetFontSize(): string {
    return Evaluation.GetValue2(this._evaluationCommandFontSize, this.CommandFontSize, PropertyType.String);
  }

  public GetFontFamily(): string {
    return Evaluation.GetValue2(this._evaluationCommandFontFamily, this.CommandFontFamily, PropertyType.String);
  }

  public GetFillColor(): string {
    return Evaluation.GetValue2(this._evaluationCommandFillColor, this.CommandFontFamily, PropertyType.String);
  }

  // Border = Fill
  public GetCommandBorder(): string {
    const fill: string = this.Fill === Constants.transparentColor ? Constants.borderDefaultColor : this.Fill;
    return fill;

  }

  // NOTE: The GetDisabled() function is used in the template for each Command Control.
  public GetBackgroundColor(): string {
    const white = '#FFFFFF';
    if (this.ShowErrorBorder) {
      return white;
    }

    if (!this.GetCommandEnabled()) {
      switch (this.CommandDisabledStyle) {
        case GmsElementDisabledStyle.None:
          return white;
        case GmsElementDisabledStyle.Grayed:
          return GmsCommandControl.DISABLED_COLOR;
        default:
          // command style is hidden
          // element will be hidden
          // return this.Background;
          break;
      }
    }
    return this.Background !== 'transparent' ? this.Background : white;
  }

  public get StrokeColor(): string {
    return this.GetAnimatedStrokeColor(); // "#000000";
  }

  public get IsReadOnly(): boolean {
    return (this.ParameterVM === null || this.ParameterVM.isReadOnly);
  }

  public GetAnimateMinimum(): number {
    let value: number = Evaluation.GetValue2(this._evaluationMinimum, this.Minimum, PropertyType.Number);
    if (Number.isNaN(value) || value === GmsCommandControl.DEFAULT_MINIMUM) {
      const command: GmsCommand = this.CommandService?.getCommand(this.CommandVM.Key);
      value = (this.ParameterVM !== null) ? this.ParameterVM.getModelMinimum(command) : Number.MIN_VALUE;
    }
    return value;
  }

  public GetAnimateMaximum(): number {
    let value: number = Evaluation.GetValue2(this._evaluationMaximum, this.Maximum, PropertyType.Number);
    if (Number.isNaN(value) || value === GmsCommandControl.DEFAULT_MAXIMUM) {
      const command: GmsCommand = this.CommandService?.getCommand(this.CommandVM.Key);
      value = (this.ParameterVM !== null) ? this.ParameterVM.getModelMaximum(command) : Number.MAX_VALUE;
    }
    return value;
  }

  public GetAnimatedMinimumLong(): Long {
    let result: Long;
    const value: number = Evaluation.GetValue2(this._evaluationMinimum, this.Minimum, PropertyType.Number);
    if (!Number.isNaN(value)) {
      result = FormatHelper.parseLongValue(value, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
    } else if (this.ParameterVM != null) {
      const command: GmsCommand = this.CommandService?.getCommand(this.CommandVM.Key);
      result = this.ParameterVM.getModelMinimumLong(command);
    }
    return result;
  }

  public GetAnimatedMaximumLong(): Long {
    let result: Long;
    const value: number = Evaluation.GetValue2(this._evaluationMaximum, this.Maximum, PropertyType.Number);
    if (!Number.isNaN(value)) {
      result = FormatHelper.parseLongValue(value, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
    } else if (this.ParameterVM != null) {
      const command: GmsCommand = this.CommandService?.getCommand(this.CommandVM.Key);
      result = this.ParameterVM.getModelMaximumLong(command);
    }
    return result;
  }

  public SetCommandButtonExist(): void {
  }

  public PropagateAlarm(): boolean {
    let alarmPropogationHandled = false;

    // Don't propagate the alarms from
    // replication clones
    if (this.IsReplicationClone) {
      this.UpdateAlarmState();
      return true;
    }

    // Inside command controls with primitive children
    // Show the propagated alarms only on the
    // top level command control, if it is not a child of a symbol instance
    // or if it is the replication clone
    const isTopLevel: boolean = this.IsTopLevelCommandControl();
    if (isTopLevel || this.IsReplicationClone) {
      if (this.Zone !== undefined) {
        this.Zone.runOutsideAngular(() => setTimeout(() => this.UpdateAlarmState(), 0));
      }
      return true;
    }

    // For groups inside symbols alarms will be propagated upwards
    // to the top level symbol instance
    if (this.Parent !== undefined) {
      alarmPropogationHandled = this.Parent.PropagateAlarm();
    }

    return alarmPropogationHandled;
  }

  public GetDpsForAlarms(): Datapoint[] {
    if (!this.CalculateVisible()) {
      return [];
    }

    const ownedDpsForAlarms: Datapoint[] = super.GetDpsForAlarms();
    const propagatedDpsForAlarms: Datapoint[] = ownedDpsForAlarms;

    if (this.children.length > 0) {
      this.children.forEach(child => {
        const childDpsForAlarms: Datapoint[] = child.GetDpsForAlarms();
        // Process the datapoints only for the visible children
        if (child.Visible) {
          childDpsForAlarms.forEach(childDp => {
            if (!propagatedDpsForAlarms.includes(childDp)) {
              propagatedDpsForAlarms.push(childDp);
            }
          });
        }
      });
    }

    return propagatedDpsForAlarms;
  }

  protected UpdatePropertyCommandPrecision(evaluation: Evaluation): void {
    // override in Numeric control
  }
  protected GetAnimatedCommandPrecision(): number {
    // override in Numeric control
    return 0;
  }
  protected setReadOnly(isReadOnly: boolean): void {
    if (this.ParameterVM !== null) {
      this.ParameterVM.isReadOnly = isReadOnly;
      this.NotifyPropertyChanged('IsReadOnly');
    }
  }

  protected createDesignModeViewModel(): void {
    const commandViewModel: CommandViewModel = new CommandViewModel(
      this.GetAnimatedCommandParameters(), null, this.locale, this.defaultLocale);
    if (this.CommandVM !== null) {
      commandViewModel.IsConnected = this.CommandVM.IsConnected;
      commandViewModel.IsUIConfigurationValid = this.CommandVM.IsUIConfigurationValid;
    }

    const parameterVM: ParameterViewModelBase = this.CreateDesignModeParameterViewModel();
    if (parameterVM !== null) {
      parameterVM.isReadOnly = true;
      parameterVM.setControlEditorMode(ControlEditorMode.View);
      const values: ParameterViewModelBase[] = new Array<ParameterViewModelBase>();
      values.push(parameterVM);
      commandViewModel.Parameters.set(ParameterViewModelBase.designModeParameterName, values);
    }

    this.CommandVM = commandViewModel;
    this.ParameterVM = parameterVM;
    this.ParameterVM.Value = parameterVM.defaultValue;
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);
    switch (evaluation.Property) {
      case 'CommandParameterName':
        this.UpdateCommandParameterName(evaluation);
        break;

      case 'Minimum':
        this.UpdateCommandParameterMinimum(evaluation);
        break;

      case 'Maximum':
        this.UpdateCommandParameterMaximum(evaluation);
        break;

      case 'CommandFontSize':
        this.UpdateCommandFontSize(evaluation);
        break;

      case 'CommandFontFamily':
        this.UpdateCommandFontFamily(evaluation);
        break;
      case 'CommandFillColor':
        this.UpdateCommandFillColor(evaluation);
        break;
      default:
        break;
    }
  }

  protected UpdateCommandVMTriggerStatus(): void {
    if (this.ParameterVM !== null) {
      this.ParameterVM.IsTriggerEnabled = this.GetAnimatedIsCommandTriggerEnabled();
    }
  }

  protected UpdateCommandControl(): void {
    // Update the ParameterVM based on the ParameterName
    this.UpdateParameterVM();
    this.UpdateCommandVMTriggerStatus();
    this.UpdatePosition();
  }

  protected UpdateParameterVM(): void {

    if (this.CommandVM === null || this.CommandVM === undefined) {
      return;
    }

    if (!this.CommandVM.IsInDesignMode) {

      const parameterName: string = this.GetAnimatedCommandParameterName();
      if (parameterName === '' || parameterName === undefined) {
        return;
      }

      const command: GmsCommand = this.CommandService.getCommand(this.CommandVM.Key);

      if (command === undefined) {
        return;
      }

      const parameterVM: ParameterViewModelBase = this.CommandVM.addParameterViewModel(command, parameterName);

      if (parameterVM === null) {
        this.TraceService.warn(this.traceModuleCommand,
          `CreateCommandParameterViewModel: parameter ${parameterName} is null.`);

        this.CommandVM.IsUIConfigurationValid = false;
      } else {
        parameterVM.setControlEditorMode(ControlEditorMode.View);
        this.ParameterVM = parameterVM;

        if (!this.ValidateControlType(parameterVM)) {
          this.CommandVM.IsUIConfigurationValid = false;
        } else {
          this.CommandVM.validateCommandParameter(command);
        }
      }
      this.UpdateCommandVMTriggerStatus();
      this.UpdateCommandParameterMinimum(null);
      this.UpdateCommandParameterMaximum(null);
      this.UpdateCommandParameterPrecision();
    }
  }

  protected UpdateCommandParameterPrecision(): void {
  }

  protected ValidateControlType(parameterVM: ParameterViewModelBase): boolean {
    return true;
  }

  protected UpdatePosition(): void {
    // provide overrides to the Slider and Spinner
  }

  protected ParameterVM_PropertyChanged(propertyName: string): void {
    this.OnParameterVMPropertyChanged(propertyName);

  }
  // This method is called when a property of the ParameterVM changes
  protected OnParameterVMPropertyChanged(propertyName: string): void {
    this.NotifyPropertyChanged(propertyName);

    // provide overrides to the Slider and Spinner
  }

  protected CreateDesignModeParameterViewModel(): ParameterViewModelBase {
    return new ParameterNumericViewModel(null);
  }

  protected UpdateCommandParameterName(evaluation: Evaluation): void {
    if (evaluation !== null || evaluation !== undefined) {
      this._evaluationCommandParameterName = evaluation;
    }

    if (this.HasCommand) {
      this.UpdateParameterVM();
    } else {
      const group: GmsGroup = this.FindTopCommandGroup(this);
      // The CommnadVM needs to be updated when the name of the parameter changes
      if (group !== undefined) {
        group.UpdateCommand();
      }
    }
  }

  protected UpdateCommandParameterMinimum(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationMinimum = evaluation;
    }

    if (this.ParameterVM !== null) {
      if (this.ParameterVM.ParameterType === ParameterType.Numeric) {
        this.ParameterVM.minValue = this.GetAnimateMinimum();
      } else if (this.ParameterVM.ParameterType === ParameterType.NumericInt64 ||
                this.ParameterVM.ParameterType === ParameterType.NumericUInt64) {
        this.ParameterVM.minValueLong = this.GetAnimatedMinimumLong();
      }
    }
  }

  protected UpdateCommandParameterMaximum(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationMaximum = evaluation;
    }
    if (this.ParameterVM !== null) {
      if (this.ParameterVM.ParameterType === ParameterType.Numeric) {
        this.ParameterVM.maxValue = this.GetAnimateMaximum();
      } else if (this.ParameterVM.ParameterType === ParameterType.NumericInt64 ||
                this.ParameterVM.ParameterType === ParameterType.NumericUInt64) {
        this.ParameterVM.maxValueLong = this.GetAnimatedMaximumLong();
      }
    }
  }

  /**
   *
   *
   * @param newValue The command is executed with the new value
   * @param delay  The command is executed after this delay in ms has elapsed. By default the command is NOT delayed.
   * @param cancel  Any delayed action needs to be canceled when coming out of edit mode
   */
  protected ValueChanged(newValue: string, delay = 0, cancel = false): void {

    if (cancel || this.ParameterVM === null) {
      clearTimeout(this._timer);
      return;
    }
    if (this.ParameterVM.FormattedValue !== newValue) {
      this.ParameterVM.DpValue = newValue;
      this.ParameterVM.FormattedValue = newValue;

      if (this.GetAnimatedIsCommandTriggerEnabled() && delay >= 0) {
        if (delay > 0) {
          this.ExecuteCommandDelayed(delay).subscribe(
            () => {
              // will reset controls (numeric, string, password)
              this.UpdateExecuteCommandStatus(true);
            },
            error => {
              this.UpdateExecuteCommandStatus(false, error);
            }
          );
        } else {
          this.ExecuteCommand().subscribe(
            () => {
              // will reset controls (numeric, string, password)
              this.UpdateExecuteCommandStatus(true);
            },
            error => {
              this.UpdateExecuteCommandStatus(false, error);
            }
          );
        }
      } else {
        this.CommandVM.isModified = true;
      }
    }
  }

  /**
   * Process a command delayed execution
   **/
  private ExecuteCommandDelayed(delay: number): Observable<GmsCommandResult> {

    clearTimeout(this._timer);

    return Observable.create((observer: Observer<GmsCommandResult>) => {
      if (this.CanExecuteCommand && this.Zone !== undefined) {
        this.Zone.runOutsideAngular(() =>
          this._timer = setTimeout(() => this.CommandVM.executeCommand(
            this.ValidationService, this.ExecuteCommandService, observer, this.locale)
          , delay));
      }
    });
  }

  protected GetExecuteCommandCouter(): number {
    return 0;
  }

  protected AddExecuteCommandCouter(): void {
  }

  protected AddCommandButtons(): void {
    const graphic: any = this.Graphic;
    graphic.buttonsService.createButtons(this);
  }

  protected UpdateExecuteCommandStatus(success: boolean, error: any = undefined): void {
    if (this.CommandVM === null) {
      return;
    }
    let counter: number = this.GetExecuteCommandCouter();
    if (counter > 0) {
      counter = counter - 1;
    }
    this.CommandVM.isModified = counter > 0;
    this.CommandVM.isCommandExecuteDone = true;
    if (success) {
      const msg = `Command executed with success: ${this.CommandVM.Key}, Name = ${this.CommandVM.Name}`;
      this.TraceService.info(this.traceModuleCommand, msg);
    } else if (error !== undefined) {
      if (this.ToastNotificationService !== undefined) {
        this.ToastNotificationService.queueToastNotification('warning', 'Command Execute failed', error.message);
      }
    }
  }

  private UpdateCommandFontSize(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationCommandFontSize = evaluation;
    }
  }

  private UpdateCommandFontFamily(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationCommandFontFamily = evaluation;
    }
  }

  private UpdateCommandFillColor(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationCommandFillColor = evaluation;
    }
  }

  private GetAnimatedCommandParameterName(): string {
    return Evaluation.GetValue2(this._evaluationCommandParameterName, this.CommandParameterName, PropertyType.String);
  }

  // A top level command control
  // is a direct child of a graphic
  // which is not inside a symbolinstance
  private IsTopLevelCommandControl(): boolean {
    if (this.Parent === undefined) {
      return false;
    }

    return this.CheckParent(this.Parent);
  }

  // True if this command control is a child of the graphic itself
  // not the content of a symbolinstance inside the graphic.
  private CheckParent(parent: GmsElement): boolean {
    if (parent === undefined) {
      return false;
    }

    if (parent instanceof GmsSymbolInstance) {
      return false;
    } else if (parent instanceof GmsLayer) {
      return true;
    } else if (parent.Parent !== undefined) {
      return this.CheckParent(parent.Parent);
    }

    return false;
  }

}
