import {
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { SiResponsiveContainerDirective } from '@simpl/element-ng';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { DateFormat, TimeFormat } from '../../interfaces/date-time-formats';
import {
  BulkProperty,
  BulkPropertyValues,
  CommandEvent,
  Property,
  ValueState
} from '../../interfaces/property';
import { SiPropertyConfig } from '../../services/si-property-config.service';
import { SiPropertyService } from '../../services/si-property.service';
import { SiPropertyListComponent } from '../si-property-list/si-property-list.component';
import { PropertyDisplayStyle, PropertyTemplateContext } from '../si-property/si-property.model';
import {
  BulkPropertyState,
  SiBulkPropertyListComponent
} from './internal/si-bulk-property-list.component';

@Component({
  selector: 'si-property-viewer',
  templateUrl: './si-property-viewer.component.html',
  styleUrls: ['./si-property-viewer.component.scss'],
  standalone: true,
  imports: [SiBulkPropertyListComponent, SiPropertyListComponent, SiResponsiveContainerDirective],
  providers: [SiPropertyService]
})
export class SiPropertyViewerComponent implements OnDestroy, OnChanges {
  private subscription?: Subscription;
  private bulkValSubs?: Subscription;
  private singleObjectId?: string;

  private propertyService = inject(SiPropertyService);
  private configService = inject(SiPropertyConfig);

  @Input({ required: true }) objectId!: string | string[];
  @Input() filter?: string;
  /**
   * Way the properties should be displayed.
   * Nested collections are currently only supported in `"table"` view.
   */
  @Input() displayStyle: PropertyDisplayStyle = 'list';
  /**
   * Custom template for supporting custom property value types in {@link PropertyApi}.
   * This template will be used if it is not part of the built-in supported types: {@link AnyPropertyValueType} and `undefined` ({@link DefaultProperty}).
   * You may use the {@link SiPropertyComponent} to display sub properties.
   */
  @Input() customTemplate?: TemplateRef<PropertyTemplateContext>;

  @Output() readonly filtered = new EventEmitter<number>(true);

  @HostBinding('class.property-viewer-table')
  protected get tableClass(): boolean {
    return this.displayStyle === 'table';
  }

  dateFormat: DateFormat = this.configService.get().dateFormat!;
  timeFormat: TimeFormat = this.configService.get().timeFormat!;
  allowValuesOutOfRange = !!this.configService.get().allowValuesOutOfRange;
  properties: Property[] = [];
  valueState: ValueState[] = [];

  bulkMode: 'no' | 'select' | 'edit' = 'no';
  bulkProperties: BulkProperty[] = [];
  bulkObjectIds?: string[];
  bulkPropertyValues?: BulkPropertyValues;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId && this.objectId) {
      this.bulkMode = Array.isArray(this.objectId) && this.objectId.length > 1 ? 'select' : 'no';
      if (this.bulkMode === 'no') {
        this.subscribeService();
      } else {
        this.subscribeBulkService();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.bulkValSubs?.unsubscribe();
  }

  private subscribeService(): void {
    this.singleObjectId = this.objectId as string;
    this.bulkObjectIds = undefined;
    this.bulkProperties = [];

    this.subscription?.unsubscribe();
    this.subscription = this.propertyService
      .getProperties(this.singleObjectId!)
      .subscribe(properties => {
        this.properties = properties;
        this.valueState = new Array(properties.length).fill('none');
      });
  }

  private subscribeBulkService(): void {
    this.singleObjectId = undefined;
    this.bulkObjectIds = this.objectId as string[];
    this.properties = [];

    this.subscription?.unsubscribe();
    this.subscription = this.propertyService
      .getBulkProperties(this.bulkObjectIds!)
      .subscribe(properties => (this.bulkProperties = properties));
  }

  private setState(property: Property, state: ValueState): void {
    const index = this.properties?.indexOf(property) ?? -1;
    if (index >= 0) {
      this.valueState[index] = state;
    }
  }

  private getObjectId(prop: Property): string | undefined {
    if (this.bulkMode === 'no') {
      return this.singleObjectId;
    }

    const originalProp: Property = (prop as any)._original ?? prop;
    const objectProp = this.bulkPropertyValues!.objectValues.find(p => p.property === originalProp);
    return objectProp?.objectId;
  }

  submit(property: Property): void {
    const objectId = this.getObjectId(property);
    if (objectId === undefined) {
      return;
    }

    const originalProp: Property = (property as any)._original ?? property;

    this.setState(originalProp, 'loading');
    this.propertyService
      .writeProperty(objectId, property)
      .pipe(first())
      .subscribe({
        next: () => this.setState(originalProp, 'passed'),
        error: () => this.setState(originalProp, 'failed')
      });
  }

  command(event: CommandEvent): void {
    const objectId = this.getObjectId(event.property);
    if (objectId === undefined) {
      return;
    }

    this.setState(event.property, 'loading');
    this.propertyService
      .executeCommand(objectId, event)
      .pipe(first())
      .subscribe({
        next: () => this.setState(event.property, 'passed'),
        error: () => this.setState(event.property, 'failed')
      });
  }

  bulkPropertySelected(prop: BulkProperty): void {
    this.bulkMode = 'edit';
    this.bulkValSubs?.unsubscribe();
    this.bulkPropertyValues = undefined;
    this.properties = [];
    this.valueState = new Array(this.bulkObjectIds!.length).fill('none');
    this.bulkValSubs = this.propertyService
      .getBulkPropertyValues(this.bulkObjectIds!, prop.id)
      .subscribe(bulkValues => {
        this.bulkPropertyValues = bulkValues;
        this.properties = bulkValues.objectValues.map(p => p.property);
      });
  }

  bulkPropertyBack(): void {
    this.bulkPropertyValues = undefined;
    this.bulkMode = 'select';
    this.bulkValSubs?.unsubscribe();
  }

  setBulkPropertyState(state: BulkPropertyState): void {
    const bulkPropVal = this.bulkPropertyValues?.objectValues.find(
      p => p.objectId === state.objectId
    );
    if (bulkPropVal) {
      this.setState(bulkPropVal.property, state.state);
    }
  }
}
