import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { Injectable, QueryList } from '@angular/core';
import { SiDropdownToggleDirective } from '@simpl/element-ng/dropdown';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

/**
 * The orientation of an interactable list, determines which arrow keys do what
 * and how parent and sub lists connect.
 * `"ste"` means start to end and `"ets"` mean end to start,
 * they replace the previous left to right and right to left
 * in order to be more accurate when using rtl mode.
 */
export type InteractableListOrientation = 'ste' | 'ets' | 'vert';

/**
 * The position of an interactable list relative to its parent,
 * determines how the parent list connects.
 */
export type InteractableListPosition = 'adjacent' | 'integrated';

export interface InteractableListDirective {
  start: boolean;
  orientation: InteractableListOrientation;
  autofocusFromParent: boolean;
  ignoreKeydown: (event: KeyboardEvent) => void;
  open: () => void;
  close: () => void;
  getHostElement: () => HTMLElement;
  focusFirstItem: (origin?: FocusOrigin, reverse?: boolean, fromParent?: boolean) => void;
  focusActiveItem: (
    origin?: FocusOrigin,
    reverse?: boolean,
    nestedItem?: InteractableListItemDirective
  ) => void;
  setActiveItem: (activeItem: InteractableListItemDirective) => void;
}

export interface InteractableListItemDirective extends FocusableOption {
  parentList: InteractableListDirective | null;
  childList: InteractableListDirective | null;
  getHostElement: () => HTMLElement;
  hasChildElement(element: HTMLElement): boolean;
  dropdownToggle?: SiDropdownToggleDirective;
}

export interface InteractableList {
  directive: InteractableListDirective;
  element: HTMLElement;
  integratedNestedItems: InteractableListItem[];
  integratedParentItem: InteractableListItem | null;
  items: InteractableListItem[];
  allItemsQueryList: QueryList<InteractableListItemDirective>;
  nested: InteractableList[];
  nestedDisconnected: InteractableList[];
  parent: InteractableList | null;
  parentDisconnected: InteractableList | null;
  trigger: InteractableListItem | null;
  triggerDefined: BehaviorSubject<InteractableListItemDirective | null>;
  position: BehaviorSubject<InteractableListPosition>;
  orientation: BehaviorSubject<InteractableListOrientation>;
  focusLastFromParent: BehaviorSubject<boolean>;
  changeToProcess: boolean;
  isSubList: boolean;
  calculatedCallback?: () => void;
}

export interface InteractableListItem {
  directive: InteractableListItemDirective;
  element: HTMLElement;
  parent: InteractableList | null;
  child: InteractableList | null;
  parentIntegrationIndex: number;
  nestedIntegration: boolean;
}

/**
 * A change in a interactable list or list item,
 * which needs to be processed.
 */
interface InteractableListsAndListItemsChange {
  object: InteractableList | InteractableListItem;
  type: 'list' | 'item';
  change: 'add' | 'remove' | 'changes';
}

/**
 * An operation to call on an interactable list.
 */
type InteractableListOperation =
  | 'listSetChildItems'
  | 'listSetNestedLists'
  | 'listSetTriggerItem'
  | 'listSetIntegratedItems';

/**
 * @deprecated The {@link SiListInteractionService} and all related symbols should no longer be used.
 * - For creating menus, use {@link SiMenuModule} (not legacy!) instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating listbox, use the {@link https://material.angular.io/cdk/listbox/overview cdkListbox }
 * - For all other cases consider using tab-based strategy
 */
@Injectable({ providedIn: 'root' })
export class SiListInteractionService {
  private interactableLists: InteractableList[] = [];
  private interactableListItems: InteractableListItem[] = [];

  private changeQueue: InteractableListsAndListItemsChange[] = [];
  private queueTimer?: any;

  /**
   * Add an interactable list and add it to `listsAndItemsChanges`.
   * Return the interactable list object.
   */
  addInteractableList(
    directive: InteractableListDirective,
    element: HTMLElement,
    triggerDefined: InteractableListItemDirective | null,
    position: InteractableListPosition,
    orientation: InteractableListOrientation,
    focusLastFromParent: boolean,
    isSubList: boolean,
    calculatedCallback?: () => void
  ): InteractableList {
    const list: InteractableList = {
      directive,
      element,
      items: [],
      integratedNestedItems: [],
      integratedParentItem: null,
      allItemsQueryList: new QueryList(),
      nested: [],
      nestedDisconnected: [],
      parent: null,
      parentDisconnected: null,
      trigger: null,
      triggerDefined: new BehaviorSubject(triggerDefined),
      position: new BehaviorSubject(position),
      orientation: new BehaviorSubject(orientation),
      focusLastFromParent: new BehaviorSubject(focusLastFromParent),
      changeToProcess: true,
      isSubList,
      calculatedCallback
    };
    this.enqueueChange({ object: list, type: 'list', change: 'add' });
    return list;
  }

  /**
   * Remove an interactable list and add it to `listsAndItemsChanges`.
   */
  removeInteractableList(list: InteractableList): void {
    this.enqueueChange({ object: list, type: 'list', change: 'remove' });
  }

  /**
   * Add an interactable list item and add it to `listsAndItemsChanges`.
   * Return the interactable list item object.
   */
  addInteractableListItem(
    directive: InteractableListItemDirective,
    element: HTMLElement
  ): InteractableListItem {
    const item: InteractableListItem = {
      directive,
      element,
      parent: null,
      child: null,
      parentIntegrationIndex: -1,
      nestedIntegration: false
    };
    this.enqueueChange({ object: item, type: 'item', change: 'add' });
    return item;
  }

  /**
   * Remove an interactable list item and add it to `listsAndItemsChanges`.
   */
  removeInteractableListItem(item: InteractableListItem): void {
    this.enqueueChange({ object: item, type: 'item', change: 'remove' });
  }

  /**
   * Check if a HTMLElement is a child of a interactable list item.
   * First properly follows any possible dropdown containers through the DOM chain,
   * then continues of the list interaction chain.
   */
  isChildElementOfItem(item: InteractableListItem, element: HTMLElement): boolean {
    let currentItem = this.getClosestRelativeItem(element, true);
    if (currentItem) {
      do {
        if (currentItem.element === item.element) {
          return true;
        }
        currentItem = currentItem.parent
          ? currentItem.parent.trigger ??
            (currentItem.parent.parentDisconnected
              ? currentItem.parent.parentDisconnected!.trigger
              : null)
          : null;
      } while (currentItem);
    }
    return false;
  }

  private enqueueChange(change: InteractableListsAndListItemsChange): void {
    this.changeQueue.push(change);

    if (this.queueTimer) {
      return;
    }

    this.queueTimer = setTimeout(() => {
      this.processChanges(this.changeQueue);
      this.changeQueue = [];
      this.queueTimer = undefined;
    });
  }

  /**
   * Process the changes after the buffer and queue up all the necessary
   * operations and callbacks. Then call them.
   */
  private processChanges(changes: InteractableListsAndListItemsChange[]): void {
    const operations = [
      'listSetChildItems',
      'listSetNestedLists',
      'listSetTriggerItem',
      'listSetIntegratedItems'
    ];
    const allOperations = {
      'listSetChildItems': [] as InteractableList[],
      'listSetNestedLists': [] as InteractableList[],
      'listSetTriggerItem': [] as InteractableList[],
      'listSetIntegratedItems': [] as InteractableList[]
    };
    const operationsToProcess = {
      'listSetChildItems': { lists: [] as InteractableList[], callbacks: [] as (() => void)[] },
      'listSetNestedLists': { lists: [] as InteractableList[], callbacks: [] as (() => void)[] },
      'listSetTriggerItem': { lists: [] as InteractableList[], callbacks: [] as (() => void)[] },
      'listSetIntegratedItems': { lists: [] as InteractableList[], callbacks: [] as (() => void)[] }
    };

    /**
     * Add a callback function to an operation in `operationsToProcess`.
     */
    const addOperationCallback = (
      operation: InteractableListOperation,
      callback: () => void
    ): void => {
      operationsToProcess[operation].callbacks.push(callback);
    };

    /**
     * Add a list to an operation in `operationsToProcess`, only if there is not
     * already one inside.
     */
    const addOperation = (
      operation: InteractableListOperation,
      list: InteractableList,
      callback?: () => void
    ): void => {
      const operationObject = operationsToProcess[operation];
      const allArray = allOperations[operation];
      if (!allArray.find(operationList => operationList.element === list.element)) {
        operationObject.lists.push(list);
        allArray.push(list);
      }
      if (operation === 'listSetChildItems' || operation === 'listSetNestedLists') {
        removeOperation('listSetIntegratedItems', list);
      }
      if (callback) {
        addOperationCallback(operation, callback);
      }
    };

    /**
     * Remove a list from an operation in `allOperations`.
     */
    const removeOperation = (
      operation: InteractableListOperation,
      list: InteractableList
    ): void => {
      const operationObject = allOperations[operation];
      const listIndex = operationObject.findIndex(
        searchList => searchList.element === list.element
      );
      if (listIndex !== -1) {
        operationObject.splice(listIndex, 1);
      }
    };

    /**
     * The precedence of the changes.
     */
    const changePrecedence = {
      'remove': 1,
      'add': 2,
      'changes': 3
    };

    /**
     * The changes to process after they have been filtered to remove
     * duplicate (and unnecessary) changes. Sort them according to
     * `changePrecedence`.
     */
    const sortedAndFilteredChanges = changes
      .filter((change, index) => {
        const isChanges = change.change === 'changes';
        const duplicates: { change: InteractableListsAndListItemsChange; index: number }[] = [];
        const duplicateAddRemove: typeof duplicates = [];
        const duplicateChange: typeof duplicates = [];

        changes.forEach((searchChange, searchChangeIndex) => {
          if (searchChange.object.element === change.object.element) {
            const item = { change: searchChange, index: searchChangeIndex };
            duplicates.push(item);
            if (searchChange.change !== 'changes') {
              duplicateAddRemove.push(item);
            } else if (isChanges) {
              duplicateChange.push(item);
            }
          }
        });

        if (duplicates.length > 1) {
          const addRemoveStart = duplicateAddRemove.length
            ? duplicateAddRemove[0].change.change
            : '';
          const addRemoveEnd = duplicateAddRemove.length
            ? duplicateAddRemove[duplicateAddRemove.length - 1].change.change
            : '';
          if (isChanges) {
            if (addRemoveStart !== '' && addRemoveStart === addRemoveEnd) {
              return false;
            } else if (duplicateChange[duplicateChange.length - 1].index !== index) {
              return false;
            }
          } else {
            if (addRemoveStart === '' || addRemoveStart !== addRemoveEnd) {
              return false;
            } else if (duplicates[duplicates.length - 1].index !== index) {
              return false;
            }
          }
        }
        return true;
      })
      .sort(
        (a: InteractableListsAndListItemsChange, b: InteractableListsAndListItemsChange) =>
          changePrecedence[a.change] - changePrecedence[b.change]
      );

    /**
     * Go through the changes in `sortedAndFilteredChanges` and add operations
     * and callbacks for every change.
     */
    sortedAndFilteredChanges.forEach(change => {
      const actualChange = change.change;
      switch (actualChange) {
        case 'add':
          if (change.type === 'list') {
            const list = change.object as InteractableList;
            this.interactableLists.push(list);
            list.triggerDefined.pipe(skip(1)).subscribe(() => {
              this.enqueueChange({ object: list, type: 'list', change: 'changes' });
            });
            list.position.pipe(skip(1)).subscribe(() => {
              this.enqueueChange({ object: list, type: 'list', change: 'changes' });
            });
            list.orientation.pipe(skip(1)).subscribe(() => {
              this.enqueueChange({ object: list, type: 'list', change: 'changes' });
            });
            list.focusLastFromParent.pipe(skip(1)).subscribe(() => {
              this.enqueueChange({ object: list, type: 'list', change: 'changes' });
            });
            addOperation('listSetChildItems', list);
            addOperation('listSetNestedLists', list, () =>
              list.nested.forEach(nestedList => addOperation('listSetTriggerItem', nestedList))
            );
            addOperation('listSetIntegratedItems', list, () => {
              if (list.isSubList) {
                const parent = list.parent ?? this.getClosestParent(list.element);
                if (parent) {
                  addOperation('listSetNestedLists', parent);
                  addOperation('listSetTriggerItem', list);
                  addOperation('listSetIntegratedItems', parent);
                }
              } else {
                const parentDisconnected =
                  list.parentDisconnected ?? this.getClosestParent(list.element);
                if (parentDisconnected) {
                  addOperation('listSetNestedLists', parentDisconnected);
                  addOperation('listSetIntegratedItems', parentDisconnected);
                }
              }
            });
          } else {
            const item = change.object as InteractableListItem;
            this.interactableListItems.push(item);
            addOperationCallback('listSetChildItems', () => {
              const parent = item.parent ?? this.getClosestParent(item.element);
              if (parent) {
                addOperation('listSetChildItems', parent);
                addOperationCallback('listSetNestedLists', () => {
                  parent.nested.forEach(parentNestedList =>
                    addOperation('listSetTriggerItem', parentNestedList)
                  );
                  const grandparent = parent.parent;
                  if (grandparent) {
                    addOperation('listSetIntegratedItems', grandparent);
                  }
                });
                addOperation('listSetIntegratedItems', parent);
              }
            });
          }
          break;
        case 'remove':
          if (change.type === 'list') {
            const listIndex = this.interactableLists.findIndex(
              interactableList => interactableList.element === change.object.element
            );
            const list = change.object as InteractableList;
            this.interactableLists.splice(listIndex, 1);
            list.nested.forEach(nestedList => addOperation('listSetTriggerItem', nestedList));
            list.items.forEach(item => (item.parent = null));
            list.integratedNestedItems.forEach(item => (item.parentIntegrationIndex = -1));
            const integratedParentItem = list.integratedParentItem;
            if (integratedParentItem) {
              integratedParentItem.nestedIntegration = false;
            }
            const parent = list.parent;
            if (parent) {
              addOperation('listSetChildItems', parent);
              addOperation('listSetNestedLists', parent);
              addOperation('listSetIntegratedItems', parent);
              const grandparent = parent.parent;
              if (grandparent) {
                addOperation('listSetIntegratedItems', grandparent);
              }
              list.parent = null;
            } else {
              const parentDisconnected = list.parent;
              if (parentDisconnected) {
                addOperation('listSetChildItems', parentDisconnected);
                addOperation('listSetNestedLists', parentDisconnected);
                addOperation('listSetIntegratedItems', parentDisconnected);
                list.parentDisconnected = null;
              }
              list.triggerDefined.complete();
              list.position.complete();
              list.orientation.complete();
              list.focusLastFromParent.complete();
            }
            const parentItem = list.trigger;
            if (parentItem) {
              const listTrigger = list.trigger;
              if (listTrigger && listTrigger.child?.element === list.element) {
                listTrigger.child = null;
              }
            }
          } else {
            const itemIndex = this.interactableListItems.findIndex(
              interactableListItem => interactableListItem.element === change.object.element
            );
            const item = change.object as InteractableListItem;
            this.interactableListItems.splice(itemIndex, 1);
            const parent = item.parent;
            if (parent) {
              addOperation('listSetChildItems', parent);
              const grandparent = parent.parent;
              if (grandparent) {
                addOperation('listSetIntegratedItems', grandparent);
              }
              const childList = item.child;
              if (childList) {
                item.child = null;
                addOperation('listSetTriggerItem', childList);
                addOperation('listSetIntegratedItems', parent);
              }
            }
          }
          break;
        case 'changes':
          const changeList = change.object as InteractableList;
          addOperation('listSetTriggerItem', changeList, () => {
            const changeParent = changeList.parent;
            if (changeParent) {
              addOperation('listSetIntegratedItems', changeParent);
            }
          });
          addOperation('listSetIntegratedItems', changeList);
          break;
      }
    });

    /**
     * Call the queued operations and callbacks in order.
     * (Important) Callbacks can add operations, also for previously
     * executed operations.
     */
    const executeOperations = (): void => {
      for (let i = 0; i < operations.length; i++) {
        const currentOperation = operations[i] as InteractableListOperation;
        const operationObject = operationsToProcess[currentOperation];
        operationObject.lists.forEach(list => this[currentOperation](list));
        operationObject.lists = [];
        operationObject.callbacks.forEach(callback => callback());
        operationObject.callbacks = [];
        for (let u = 0; u < i + 1; u++) {
          const currentCheckOperation = operations[u] as InteractableListOperation;
          if (operationsToProcess[currentCheckOperation].lists.length > 0) {
            executeOperations();
            break;
          }
        }
      }
    };
    executeOperations();

    /**
     * Call `listSetAllItemsQueryList` for every list that had
     * relevant changes.
     */
    this.interactableLists.forEach(interactableList => {
      if (interactableList.changeToProcess) {
        interactableList.changeToProcess = false;
        this.listSetAllItemsQueryList(interactableList);
      }
    });

    sortedAndFilteredChanges.forEach(change => {
      if (
        change.change === 'add' &&
        change.type === 'list' &&
        (change.object as InteractableList).calculatedCallback
      ) {
        (change.object as InteractableList).calculatedCallback!();
      }
    });
  }

  /**
   * Set all the child items of an interactable list.
   */
  private listSetChildItems(list: InteractableList): void {
    list.items.forEach(item => {
      if (item.parent?.element === list?.element) {
        item.parent = null;
      }
    });
    list.changeToProcess = true;
    list.items = this.getChildItems(list.element);
    list.items.forEach(item => {
      const oldParent = item.parent;
      if (oldParent && oldParent.element !== list.element) {
        const oldParentChildIndex = oldParent.items.findIndex(
          parentChildItem => parentChildItem.element === item.element
        );
        if (oldParentChildIndex >= 0) {
          oldParent.changeToProcess = true;
          oldParent.items.splice(oldParentChildIndex, 1);
        }
      }
      item.parent = list;
    });
  }

  /**
   * Set all the nested lists of an interactable list.
   */
  private listSetNestedLists(list: InteractableList): void {
    list.nested.forEach(nestedList => {
      if (nestedList.parent?.element === list?.element) {
        nestedList.parent = null;
      }
    });
    list.nestedDisconnected.forEach(nestedList => {
      if (nestedList.parentDisconnected?.element === list?.element) {
        nestedList.parentDisconnected = null;
      }
    });

    const subList: InteractableList[] = [];
    const nonSubList: InteractableList[] = [];

    for (const item of this.getNestedLists(list.element)) {
      if (item.isSubList) {
        subList.push(item);
      } else {
        nonSubList.push(item);
      }
    }

    list.nested = subList;
    subList.forEach(nestedList => {
      const oldParent = nestedList.parent;
      if (oldParent) {
        const oldParentNestedListIndex = oldParent.nested.findIndex(
          parentNestedList => parentNestedList.element === nestedList.element
        );
        if (oldParentNestedListIndex >= 0) {
          oldParent.nested.splice(oldParentNestedListIndex, 1);
        }
      }
      nestedList.parent = list;
    });

    list.nestedDisconnected = nonSubList;
    nonSubList.forEach(nestedList => {
      const oldParent = nestedList.parentDisconnected;
      if (oldParent) {
        const oldParentNestedListIndex = oldParent.nestedDisconnected.findIndex(
          parentNestedList => parentNestedList.element === nestedList.element
        );
        if (oldParentNestedListIndex >= 0) {
          oldParent.nestedDisconnected.splice(oldParentNestedListIndex, 1);
        }
      }
      nestedList.parentDisconnected = list;
    });
  }

  /**
   * Set the trigger/parent item of an interactable list.
   */
  private listSetTriggerItem(list: InteractableList): void {
    const listTrigger = list.trigger;
    if (listTrigger && listTrigger.child?.element === list.element) {
      listTrigger.child = null;
    }
    list.trigger = null;
    const setParentItem = list.triggerDefined.value;
    let parentItem;
    if (setParentItem) {
      const setParentItemHost = setParentItem.getHostElement();
      parentItem = this.interactableListItems.find(item => item.element === setParentItemHost);
    } else {
      parentItem = this.getClosestRelativeItem(list.element);
    }
    if (parentItem) {
      const oldChild = parentItem.child;
      if (oldChild) {
        oldChild.trigger = null;
      }
      parentItem.child = list;
      list.trigger = parentItem;
    } else {
      list.trigger = null;
    }
  }

  /**
   * Set the integrated items (nested or parent) of an interactable list.
   * This is used to connect sub/parent interactable lists depending on
   * the position of the nested interactable lists.
   */
  private listSetIntegratedItems(list: InteractableList): void {
    list.integratedNestedItems.forEach(integratedNestedItem => {
      integratedNestedItem.parentIntegrationIndex = -1;
    });
    list.integratedNestedItems = [];
    list.changeToProcess = true;
    list.nested.forEach(nestedList => {
      const integratedParentItem = nestedList.integratedParentItem;
      if (integratedParentItem) {
        integratedParentItem.nestedIntegration = false;
      }
      nestedList.integratedParentItem = null;
      nestedList.changeToProcess = true;
      const nestedListTrigger = nestedList.trigger;
      if (nestedListTrigger && nestedList.items.length > 0) {
        if (this.areParentIntegratedItemsAppropriate(nestedList)) {
          if (nestedList.position.value === 'integrated') {
            let actualReverse = nestedList.orientation.value === 'ets';
            if (nestedList.focusLastFromParent.value) {
              actualReverse = !actualReverse;
            }
            const integratedNestedItem = (
              actualReverse ? nestedList.items.slice().reverse() : nestedList.items
            ).find(nestedListItem => !nestedListItem.child);
            if (integratedNestedItem) {
              const parentIntegrationIndex = list.items.findIndex(
                item => item.element === nestedListTrigger.element
              );
              if (parentIntegrationIndex >= 0) {
                integratedNestedItem.parentIntegrationIndex = parentIntegrationIndex;
                list.integratedNestedItems.push(integratedNestedItem);
              }
            }
          } else {
            nestedList.integratedParentItem = nestedListTrigger;
            nestedListTrigger.nestedIntegration = true;
          }
        }
      }
    });
  }

  /**
   * Set the `allItemsQueryList` of an interactable list.
   */
  private listSetAllItemsQueryList(list: InteractableList): void {
    const allItems = list.items.slice();
    let indexAddition = 0;

    const iter = (integratedItem: InteractableListItem): void => {
      allItems.splice(
        (integratedItem.parentIntegrationIndex === -1
          ? list.orientation.value === 'ets'
            ? allItems.length
            : -1
          : integratedItem.parentIntegrationIndex) +
          indexAddition +
          1,
        0,
        integratedItem
      );
      indexAddition++;
    };

    if (list.orientation.value !== 'ets' && list.integratedParentItem) {
      iter(list.integratedParentItem);
    }
    list.integratedNestedItems.forEach(iter);
    if (list.orientation.value === 'ets' && list.integratedParentItem) {
      iter(list.integratedParentItem);
    }

    list.allItemsQueryList.reset(allItems.map(item => item.directive));
  }

  /**
   * Check if integrated items are appropriate in the parent of an interactable list.
   */
  private areParentIntegratedItemsAppropriate(list: InteractableList): boolean {
    const parent = list.parent;
    if (parent) {
      const orientation = list.orientation.value;
      const parentOrientation = parent.orientation.value;
      return (
        (orientation === 'vert' && parentOrientation !== 'vert') ||
        (orientation !== 'vert' && parentOrientation === 'vert')
      );
    }
    return false;
  }

  /**
   * Get the child items of an interactable list.
   */
  private getChildItems(
    element: HTMLElement,
    ret: InteractableListItem[] = []
  ): InteractableListItem[] {
    if (this.interactableListItems.length) {
      const iter = (child: any): void => {
        const childResult = this.interactableListItems.find(
          item => item.element === (child as HTMLElement)
        );
        if (childResult) {
          ret.push(childResult);
        } else if (!this.interactableLists.find(list => list.element === (child as HTMLElement))) {
          this.getChildItems(child as HTMLElement, ret);
        }
      };

      Array.from(element.children).forEach(iter);
      Array.from((element as any).dropdownChildren || []).forEach(iter);
    }
    return ret;
  }

  /**
   * Get the nested lists of an interactable list.
   */
  private getNestedLists(element: HTMLElement, ret: InteractableList[] = []): InteractableList[] {
    if (this.interactableLists.length) {
      const iter = (child: any): void => {
        const childResult = this.interactableLists.find(
          list => list.element === (child as HTMLElement)
        );
        if (childResult) {
          ret.push(childResult);
        } else {
          this.getNestedLists(child as HTMLElement, ret);
        }
      };

      Array.from(element.children).forEach(iter);
      Array.from((element as any).dropdownChildren || []).forEach(iter);
    }
    return ret;
  }

  /**
   * Get the closest relative/parent/trigger item of an interactable list.
   * @param onlyCheckParent Determines if the children of parents should also be checked or ignored.
   */
  private getClosestRelativeItem(
    element: HTMLElement,
    onlyCheckParent = false
  ): InteractableListItem | null {
    if (this.interactableListItems.length) {
      let previousElement;
      let currentElement = element;
      let outOfMenu = false;
      while (currentElement.parentElement && !outOfMenu) {
        previousElement = currentElement;
        const checkElement = (currentElement =
          (currentElement as any).dropdownParentElement ?? currentElement.parentElement);
        const item = this.interactableListItems.find(
          interactableListItem =>
            interactableListItem.element === checkElement && interactableListItem.parent
        );
        if (item) {
          return item;
        }
        if (!onlyCheckParent) {
          const childResult = this.getClosestChildItemIndex(currentElement, previousElement);
          if (childResult !== null && childResult >= 0) {
            return this.interactableListItems[childResult];
          }
          if (
            currentElement !== element &&
            this.interactableLists.find(
              interactableList => interactableList.element === checkElement
            )
          ) {
            outOfMenu = true;
          }
        }
      }
    }
    return null;
  }

  /**
   * Get the closest parent list of an interactable list.
   */
  private getClosestParent(element: HTMLElement): InteractableList | null {
    if (this.interactableLists.length > 0) {
      let currentElement = element;
      while (currentElement.parentElement) {
        const checkElement = (currentElement =
          (currentElement as any).dropdownParentElement ?? currentElement.parentElement);
        const parent = this.interactableLists.find(
          interactableList => interactableList.element === checkElement
        );
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  /**
   * Get the index of the closest child item.
   */
  private getClosestChildItemIndex(
    element: HTMLElement,
    currentChild?: HTMLElement,
    reverse = false
  ): number | null {
    if (this.interactableListItems.length < 0) {
      return null;
    }

    if (!element.children.length && !(element as any).dropdownChildren?.length) {
      return null;
    }
    const children = [
      ...Array.from(element.children),
      ...Array.from((element as any).dropdownChildren || [])
    ];

    if (currentChild) {
      const currentElementIndex = children.indexOf(currentChild);
      let currentChildAppeared = false;

      const result: { itemIndex: number; elementIndex: number }[] = [];
      children.forEach((child, index) => {
        if (child !== currentChild) {
          const childIndex = this.interactableListItems.findIndex(
            item => item.element === (child as HTMLElement)
          );
          if (childIndex === -1) {
            const subResult = this.getClosestChildItemIndex(
              child as HTMLElement,
              undefined,
              !currentChildAppeared
            );
            if (subResult !== null) {
              result.push({ itemIndex: subResult, elementIndex: index });
            }
          } else {
            result.push({ itemIndex: childIndex, elementIndex: index });
          }
        } else {
          currentChildAppeared = true;
        }
      });

      result.sort(
        (firstItem, secondItem) =>
          Math.abs(currentElementIndex - firstItem!.elementIndex) -
          Math.abs(currentElementIndex - secondItem!.elementIndex)
      );

      return result.length ? result[0]!.itemIndex : null;
    } else {
      const correctedChildren = reverse ? children.reverse() : children;
      for (const child of correctedChildren) {
        const childIndex = this.interactableListItems.findIndex(item => item.element === child);
        if (childIndex === -1) {
          const subResult = this.getClosestChildItemIndex(child as HTMLElement, undefined, reverse);
          if (subResult !== null) {
            return subResult;
          }
        } else {
          return childIndex;
        }
      }
    }

    return null;
  }
}
