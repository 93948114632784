import { inject, Injectable } from '@angular/core';
import { createModalConfig, ModalOptions, SiModalService } from '@simpl/element-ng/modal';
import { TranslatableString } from '@simpl/element-ng/translate';
import { Observable } from 'rxjs';

import { SiColumnSelectionDialogComponent } from './si-column-selection-dialog.component';
import {
  Column,
  ColumnSelectionDialogResult,
  SiColumnSelectionDialogConfig
} from './si-column-selection-dialog.types';

@Injectable({ providedIn: 'root' })
export class SiColumnSelectionDialogService {
  private modalService = inject(SiModalService);

  /**
   * Opens a column selection dialog.
   *
   * Despite other dialogs,
   * this dialog informs the consumer not ONLY with clicking `submit` or `cancel`,
   * but also with changing the place or visibility of a dialog row
   * thanks to the `instant` type of emitted event.
   */
  showColumnSelectionDialog(
    config: SiColumnSelectionDialogConfig
  ): Observable<ColumnSelectionDialogResult>;
  /**
   * @deprecated use {showColumnSelectionDialog(config: SiColumnSelectionDialogConfig)} instead
   */
  showColumnSelectionDialog(
    heading?: TranslatableString,
    bodyTitle?: TranslatableString,
    submitBtnName?: TranslatableString,
    cancelBtnName?: TranslatableString,
    columns?: Column[],
    translationParams?: { [key: string]: any },
    restoreToDefaultBtnName?: TranslatableString,
    restoreEnabled?: boolean
  ): Observable<ColumnSelectionDialogResult>;
  /**
   * @deprecated use {showColumnSelectionDialog(config: SiColumnSelectionDialogConfig)} instead
   */
  showColumnSelectionDialog(
    headingOrConfig?: string | SiColumnSelectionDialogConfig,
    bodyTitle?: TranslatableString,
    submitBtnName?: TranslatableString,
    cancelBtnName?: TranslatableString,
    columns?: Column[],
    translationParams?: { [key: string]: any },
    restoreToDefaultBtnName?: TranslatableString,
    restoreEnabled?: boolean,
    visibleText?: TranslatableString,
    hiddenText?: TranslatableString
  ): Observable<ColumnSelectionDialogResult> {
    return new Observable<ColumnSelectionDialogResult>(subscriber => {
      let config: ModalOptions<any>;
      if (typeof headingOrConfig === 'object') {
        config = createModalConfig(headingOrConfig);
      } else {
        config = createModalConfig({
          heading: headingOrConfig,
          bodyTitle,
          submitBtnName,
          cancelBtnName,
          visibleText,
          hiddenText,
          columns,
          translationParams,
          restoreToDefaultBtnName,
          restoreEnabled
        });
      }
      config.class += ' modal-dialog-scrollable';
      config.keyboard = true;

      const modalRef = this.modalService.show<SiColumnSelectionDialogComponent>(
        SiColumnSelectionDialogComponent,
        config
      );
      const subscription = modalRef.hidden.subscribe(
        (confirmationResult: ColumnSelectionDialogResult | undefined) => {
          const keepModalOpen =
            confirmationResult?.type === 'instant' || confirmationResult?.type === 'restoreDefault';
          if (!confirmationResult) {
            confirmationResult = { type: 'cancel', columns: modalRef.content.backupColumns };
          }
          subscriber.next(confirmationResult);
          if (!keepModalOpen) {
            subscription.unsubscribe();
            subscriber.complete();
          }
        }
      );

      return () => {
        if (!subscription.closed) {
          subscription.unsubscribe();
          modalRef.detach();
        }
      };
    });
  }
}
