import { Injectable } from '@angular/core';
import { ExtendedStatusType } from '@simpl/element-ng';

import { SeverityServiceBase } from './severity.service.base';

/**
 * Severity service.
 * Provides the functionality to read severities and associated categories from WSI.
 *
 * @export
 * @class SeverityService
 * @extends {SeverityServiceBase}
 */

export class SeverityService extends SeverityServiceBase {

  public getSeverityByCategory(categoryDescription: string): ExtendedStatusType | undefined {
    return undefined;
  }
}
