import { TraceService } from '@gms-flex/services-common';

/**
 * Delegate class that wraps the `TraceService` for the following purposes:
 *
 * - Eliminate the need to specify the trace module-name as the first argument of
 * every method to the TraceService.  This, in turn, eliminates the common problem of
 * the caller accidentally omitting this parameter, which is not caught by the TS compiler
 * since it still satisfies the call signature of `error`, `warn`, `info`, and `debug`.
 *
 * - Checks the enabled-state of `info` and `debug` messages on the trance channel and,
 * if not enabled, skips the potentially expensive work of formatting a message that is
 * destined to go unused.
 */
export class TraceServiceDelegate {

  private readonly prefix: string;

  public get native(): TraceService {
    return this.traceService;
  }

  public constructor(
    private readonly traceService: TraceService,
    private readonly traceModuleName: string,
    pre?: string) {

    if (!traceService || !traceModuleName) {
      throw new Error('invalid argument');
    }
    this.prefix = pre ? pre + ' ' : ''; // add a space to end of prefix for separation from trace message
  }

  public error(message?: string, ...optionalParams: any[]): void {
    this.traceService.error(this.traceModuleName, '%s' + message, this.prefix, ...optionalParams);
  }

  public warn(message?: string, ...optionalParams: any[]): void {
    this.traceService.warn(this.traceModuleName, '%s' + message, this.prefix, ...optionalParams);
  }

  public info(message?: string, ...optionalParams: any[]): void {
    if (this.traceService.isInfoEnabled(this.traceModuleName)) {
      this.traceService.info(this.traceModuleName, '%s' + message, this.prefix, ...optionalParams);
    }
  }

  public debug(message?: string, ...optionalParams: any[]): void {
    if (this.traceService.isDebugEnabled(this.traceModuleName)) {
      this.traceService.debug(this.traceModuleName, '%s' + message, this.prefix, ...optionalParams);
    }
  }
}
