import { Injectable } from "@angular/core";
import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointValue } from "src/app/bx-services/point/point-proxy.model";

import { DccDataType, PropertyMapper } from "./property-mapper";
import { TextGroupBxService, textGroupIdCurrentPriority, textGroupIdStatusFlags } from "./text-group-bx.service";

// do not change this name, it is the name for this property and used in property viewer as well!
export const bacCurrentPriorityName = 'Current_Priority';

@Injectable({
  providedIn: 'root'
})
export class BacCurrentPrioMapperService extends PropertyMapper {

  constructor(private readonly textGroupOmService: TextGroupBxService, qudtPipe: SiqudtUnitConversionPipe) {
    super(qudtPipe);
  }

  public createCurrentPrio(order: number): PropertyDetails {
    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: bacCurrentPriorityName,
      Descriptor: 'Current Priority',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: undefined,
      Type: DccDataType.ExtendedEnum,
      Value: undefined,
      Min: '0',
      Max: '16',
      UnitDescriptor: undefined,
      UnitId: undefined,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      DisplayOffNormalOnly: true,
      NormalValue: '0',
      TextTable: textGroupIdCurrentPriority,
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return propDetails;
  }

  public createBacCurrentPrioValue(subGms: SubscriptionGmsVal, pointValue: PointValue, hide: boolean = false): ValueDetails | undefined {
    // TODO: finalize quality bits
    // let displayValue = '#NaN';

    let displayValue = '#NaN';
    let rawValue = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        if (pointValue?.attributes?.bac_current_prio) {
          rawValue = pointValue.attributes.bac_current_prio.toString();
          displayValue = this.createCurrentPrioDisplayValue(pointValue.attributes.bac_current_prio);
        } else {
          // there is no bac prio property
          return undefined;
        }
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: DccDataType.BasicUint,
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        // the bit positions which are set (e.g. value=6 => bit0=false, bit1=true, bit2=true, bit3=false)
        Value: rawValue,
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0',
        IsPropertyAbsent: hide
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private createCurrentPrioDisplayValue(currentPrio: number): string {
    const txgCurrentPrio = this.textGroupOmService.getGlobalTextGroup(textGroupIdCurrentPriority);
    return txgCurrentPrio.enum[currentPrio].label;
  }
}
