/* eslint-disable no-console, no-restricted-syntax*/
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProductInfo } from './data.model';

/**
 * This service provides product (branding) specific resources such as images product name...
 */
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private readonly productSettingFilePath: string;

  private productInfo!: ProductInfo;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  public constructor(private readonly httpClient: HttpClient, @Inject('productSettingFilePath') productSettingFilePath: string) {
    this.productSettingFilePath = productSettingFilePath;
    // This service is called during APP_INITIALIZATION so it does not make use of trace service
    console.info('hfwServicesCommon: Product service created. productSettingFilePath: %s', this.productSettingFilePath);
  }

  /**
   * Gets the prdouct(branding) specific data from WSI and an additional configuration file on the web server.
   *
   * @returns
   *
   * @memberOf ProductService
   */
  public getProductSettings(): Observable<ProductInfo> {
    console.info('hfwServicesCommon: getProductSettings() called.');

    if (this.productInfo !== null && this.productInfo !== undefined) {
      console.info('hfwServicesCommon: productInfo already retrived. returning stored data.');
      return of(this.productInfo);
    } else {
      console.info('hfwServicesCommon: reading %s file.', this.productSettingFilePath);

      return this.httpClient.get<ProductInfo>(this.productSettingFilePath).pipe(
        map((response: ProductInfo) =>
          this.onReadProductSettingsFile(response)),
        catchError((response: HttpErrorResponse) => {
          const responseStatus: string =
            response.status ? `${response.status} - ${response.statusText}` : `hfwServicesCommon: Error getting ${this.productSettingFilePath} file.`;
          const productError: string = (response.message) ? response.message : responseStatus;
          console.error(productError);
          return throwError(() => new Error(productError));
        }));
    }
  }

  private onReadProductSettingsFile(res: ProductInfo): ProductInfo {
    console.info('hfwServicesCommon: ProductInfo file read.');
    const body: ProductInfo = res;
    this.productInfo = body;
    this.traceProductInfo(this.productInfo);
    return body;
  }

  private traceProductInfo(productInfo: ProductInfo): void {
    let prInfo: string;
    prInfo = `brandProductName: ${productInfo.brandProductName}`;
    prInfo = prInfo + `\ndefaultCulture: ${productInfo.fallbackCulture}`;
    prInfo = prInfo + `\nProduct vendor name: ${productInfo.version}`;
    console.info('hfwServicesCommon: %s', prInfo);
  }
}
