import { CdkMenuGroup, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MenuItem } from '@simpl/element-ng/common';
import { SiLinkActionService, SiLinkModule } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiMenuActionService } from './si-menu-action.service';
import { SiMenuDividerDirective } from './si-menu-divider.directive';
import { SiMenuFactoryItemGuardDirective } from './si-menu-factory-item-guard.directive';
import { SiMenuHeaderDirective } from './si-menu-header.directive';
import { SiMenuItemCheckboxComponent } from './si-menu-item-checkbox.component';
import { SiMenuItemRadioComponent } from './si-menu-item-radio.component';
import { SiMenuItemComponent } from './si-menu-item.component';
import { MenuItemAction, MenuItemCheckbox, MenuItemNext, MenuItemRadio } from './si-menu-model';
import { SiMenuDirective } from './si-menu.directive';

@Component({
  selector: 'si-menu-factory',
  templateUrl: './si-menu-factory.component.html',
  standalone: true,
  imports: [
    SiMenuDirective,
    SiMenuItemComponent,
    SiMenuItemRadioComponent,
    SiMenuItemCheckboxComponent,
    SiMenuHeaderDirective,
    SiMenuDividerDirective,
    SiLinkModule,
    CdkMenuTrigger,
    SiTranslateModule,
    CdkMenuGroup,
    NgTemplateOutlet,
    RouterLink,
    SiMenuFactoryItemGuardDirective
  ]
})
export class SiMenuFactoryComponent {
  @Input() items?: readonly (MenuItem | MenuItemNext)[];
  @Input() actionParam?: any;

  private linkActionService = inject(SiLinkActionService, { optional: true });
  private menuActionService = inject(SiMenuActionService, { optional: true });

  protected isNewItemStyle(item: MenuItem | MenuItemNext): item is MenuItemNext {
    return 'label' in item || item.type === 'divider' || item.type === 'radio-group';
  }

  protected isLegacyItemStyle(item: MenuItem | MenuItemNext): item is MenuItem {
    return !this.isNewItemStyle(item);
  }

  protected radioOrCheckboxTriggered(item: MenuItem): void {
    if (typeof item.action === 'function') {
      item.action(this.actionParam);
    } else {
      this.linkActionService?.emit(item, this.actionParam);
    }
  }

  protected runAction(item: MenuItemAction | MenuItemRadio | MenuItemCheckbox): void {
    if (typeof item.action === 'function') {
      item.action(this.actionParam, item as any); // typescript cannot level down the item type properly
    }

    if (typeof item.action === 'string') {
      this.menuActionService?.actionTriggered(item, this.actionParam);
    }
  }
}
