import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsText } from '../elements/gms-text';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsTextAlignmentType, GmsTextType } from '../types/gms-text-types';
import { Guid } from '../utilities/guid';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-text]',
  template: `<svg:g
                    (mouseenter)="OnMouseEnter($event)"
                    (click)="OnMouseClick($event)" (mouseleave)="OnMouseLeave($event)"
                    id="{{element.Id}}"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [attr.transform]="element.GetTransformations()"
                    [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()"
                    [attr.filter]="element.Filter?.Url"
                    [attr.clip-path]="element.ClipPathUrl">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <svg:rect *ngIf="element.IsBackgroundVisible"
                              [ngClass]="element.IsHitTestVisible ? 'allptrevents': 'noptrevents'"
                              [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                        *ngIf="element.IsIcon">
                         <image [attr.x]="element.IconX"
                                [attr.y]="element.IconY"
                                [attr.width]="element.IconWidth"
                                [attr.height]="element.IconHeight"
                                [attr.xlink:href]="element.IconSource"/>
                    </svg>
                    <svg:text style="white-space: pre;"
                              [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                              [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                              *ngIf="!element.IsIcon"
                              [attr.clip-path]="element.ClipPathUrl"
                              [attr.fill]="element.Stroke"
                              [attr.fill-opacity]="element.StrokeOpacity"
                              [attr.stroke-width]="element.StrokeWidth"
                              [attr.font-family]="element.FontFamily"
                              [attr.font-size]="element.FontSize"
                              [attr.font-weight]="element.FontWeight"
                              [attr.font-style]="element.FontStyle"
                              [attr.Underline]="element.Underline"
                              [attr.StrikeThrough]="element.Strikethrough"
                              [attr.text-decoration]="element.TextDecoration"
                              [attr.visibility]="element.GetVisible()"
                              text-anchor="start"
                              alignment-baseline="hanging">
                              <svg:tspan
                                [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                                *ngFor="let line of element.TextLines;" [attr.x]="line.X" [attr.y]="line.Y"
                                alignment-baseline="hanging"
                                dominant-baseline="hanging"
                                text-anchor="start">{{line.Text}}</svg:tspan>
                              <!-- NOTE: Do not introduce a whitespace to the line above because of style="white-space: pre;" -->
                      </svg:text>

                   <!--<title>{{element.Tooltip}}</title>-->
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsTextComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsText = null;
}
