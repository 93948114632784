import { Injectable } from '@angular/core';
import { BrowserObject, ConnectionState, SubscriptionDeleteWsi, SystemBrowserServiceBase, SystemBrowserSubscription,
  SystemBrowserSubscriptionKey, SystemBrowserSubscriptionProxyServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject, throwError } from 'rxjs';

import { UtilityService } from '../shared/utility.service';
import { SystemBrowserBxSubstituteService } from './system-browser-bx-substitute.service';

@Injectable()
export class SystemBrowserSubscriptionBxSubstituteProxyService extends SystemBrowserSubscriptionProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _nodeChangeEvents: Subject<SystemBrowserSubscription[]> = new Subject<SystemBrowserSubscription[]>();
  private readonly trMod: string = TraceModules.sysBrowserNotification;
  private readonly subscriptions: Map<number, SystemBrowserSubscriptionKey> = new Map<number, SystemBrowserSubscriptionKey>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly utilityService: UtilityService,
    private readonly systemBrowserService: SystemBrowserServiceBase) {

    super();
    this.traceService.info(this.trMod, 'SystemBrowserSubscriptionBxSubstituteProxyService created.');

    asapScheduler.schedule(() => {
      // No real connection state is delivered.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);
  }

  /**
   * Subscribes the specified designation.
   */
  public subscribeNodeChanges(designation: string): Observable<SystemBrowserSubscriptionKey> | any {
    if (designation === undefined) {
      this.traceService.error(this.trMod, 'SystemBrowserSubscriptionBxSubstituteProxyService.subscribeNodeChanges() called with invalid argument!');
      return throwError(() => new Error('SystemBrowserSubscriptionBxSubstituteProxyService.subscribeNodeChanges(): Called with invalid argument!'));
    }
    this.traceService.info(this.trMod, 'SystemBrowserSubscriptionBxSubstituteProxyService.subscribeNodeChanges() called; designation:%s', designation);

    /* eslint-disable @typescript-eslint/naming-convention */
    const subKey: SystemBrowserSubscriptionKey = {
      Key: this.utilityService.getSubscriptionKey(),
      Designations: [designation],
      ErrorCode: 0,
      RequestId: this.utilityService.getSignalRCtx(),
      RequestFor: 'notifySystemBrowserChanges'
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    this.subscriptions.set(subKey.Key, subKey);
    return scheduled([subKey], asapScheduler);
  }

  /**
   * Event for the node change notifications.
   */
  public nodeChangeNotification(): Observable<SystemBrowserSubscription[]> {
    return this._nodeChangeEvents.asObservable();
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  /**
   * Unsubscribes designation (associated with the subscription key).
   */
  public unsubscribeNodeChanges(key: number): Observable<SubscriptionDeleteWsi> {
    this.traceService.info(this.trMod, 'SystemBrowserSubscriptionBxSubstituteProxyService.unsubscribeNodeChanges() called; key:%s', key);

    /* eslint-disable @typescript-eslint/naming-convention */
    const subDeleteKey: SubscriptionDeleteWsi = {
      Key: key,
      ErrorCode: 0
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    if (this.subscriptions.has(subDeleteKey.Key)) {
      this.subscriptions.delete(subDeleteKey.Key);
    } else {
      this.traceService.info(this.trMod, `SystemBrowserSubscriptionBxSubstituteProxyService.unsubscribeNodeChanges() called; key is unknown: ${key}`);
    }
    return scheduled([subDeleteKey], asapScheduler);
  }

  public notifyAddedNode(node: BrowserObject): void {
    (this.systemBrowserService as SystemBrowserBxSubstituteService).getView(node.SystemId, node.ViewId).subscribe(viewNode => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const addObj: SystemBrowserSubscription = {
        Action: 10,
        Change: 5,
        Node: node,
        View: viewNode,
        SubscriptionKey: undefined
      };
      /* eslint-enable @typescript-eslint/naming-convention */
      this.subscriptions.forEach((value, key) => {
        addObj.SubscriptionKey = key;
        this._nodeChangeEvents.next([addObj]);
      });
    });
  }

  public notifyDeletedNode(node: BrowserObject): void {
    (this.systemBrowserService as SystemBrowserBxSubstituteService).getView(node.SystemId, node.ViewId).subscribe(viewNode => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const addObj: SystemBrowserSubscription = {
        Action: 8,
        Change: 5,
        Node: node,
        View: viewNode,
        SubscriptionKey: undefined
      };
      /* eslint-enable @typescript-eslint/naming-convention */
      this.subscriptions.forEach((value, key) => {
        addObj.SubscriptionKey = key;
        this._nodeChangeEvents.next([addObj]);
      });
    });
  }
}
