/* eslint-disable no-warning-comments */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthenticationServiceBase, Credentials, isNullOrUndefined, SessionCookieStorage,
  TraceService, UserAccountType, UserInfo, UserInfoStorage } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { concatMap, Observable, Subscription } from 'rxjs';

import { TraceModules } from '../shared/trace-modules';
import { EnumSubsType } from './data.model';

export const PASSWORD_EXPIRED = 2400003;
@Component({
  selector: 'hfw-login-authentication',
  templateUrl: './login-authentication.component.html',
  styleUrl: './login-authentication.component.scss'
})
/*
 * hfw-login sub component that gets credentials from the user and call the authentication service.
 */
export class LoginAuthenticationComponent implements OnInit, OnDestroy {

  /**
   * Emit event when password need to be change
   */
  @Output() public readonly changePwd: EventEmitter<Credentials> = new EventEmitter<Credentials>();

  /*
   * Stores the error type in case of authentication failure.
   */
  public errorMessage!: string;
  /**
   * Pass href link to child component for Term of Use.
   */
  @Input() public linkTermOfUse!: string;

  public isSingleSignOn = true;
  public uservalue!: string;
  public pwdvalue!: string;
  public busy = false;
  public loading = false;

  private subAuth0: Subscription = new Subscription();
  private subLogin: Subscription = new Subscription();
  private subGetInfo: Subscription = new Subscription();

  private subsType!: EnumSubsType;
  private code!: string;
  private cred: Credentials = new Credentials();

  public ngOnInit(): void {
    const skip = this.cookieService.get(SessionCookieStorage.SkipDefaultUserAutoLogin);
    this.traceService.info(TraceModules.login, 'SkipDefaultUserAutoLogin cookie lenght: %s.', skip.length);
    if (skip.length === 0) {
      this.loading = true;
      this.traceService.info(TraceModules.login, 'Trying default user login.');
      this.subLogin = this.authenticationServiceBase.loginDefaultUser().
        subscribe({
          next: (data: string) => this.onLoginComplete(data, true),
          error: (err: any) => this.onDefaultLoginFailed(err)
        });
    } else {
      this.traceService.info(TraceModules.login, 'Skipping default user auto login.');
      this.loginWithAuthorizationCode();
    }
  }

  public ngOnDestroy(): void {
    if (!isNullOrUndefined(this.subAuth0)) {
      this.subAuth0.unsubscribe();
    }
    if (!isNullOrUndefined(this.subGetInfo)) {
      this.subGetInfo.unsubscribe();
    }
    if (!isNullOrUndefined(this.subLogin)) {
      this.subLogin.unsubscribe();
    }
  }

  public constructor(
    private readonly traceService: TraceService,
    private readonly authenticationServiceBase: AuthenticationServiceBase,
    private readonly cookieService: CookieService,
    private readonly translateService: TranslateService) {
    this.traceService.info(TraceModules.login, 'LoginAuthenticationComponent created.');
  }

  public get termsOfUseStatement(): Observable<string> {
    return this.translateService.get('LOGIN-SCREEN.TERMS-OF-USE').pipe(
      concatMap(resxToU => this.translateService.get(
        'LOGIN-SCREEN.TERMS-OF-USE-STATEMENT', { termsOfUseLink: `<a target="_blank" href="${this.linkTermOfUse}">${resxToU}</a>` }))
    );
  }

  /*
   * Try to authenticate the user with given credentials.
   */
  public authenticate(username: string, password: string): void {
    this.loading = true;
    this.cred = { username, password };

    this.traceService.debug(TraceModules.login, 'Try to authenticate the user.');
    this.errorMessage = '';
    this.busy = true;
    this.subsType = EnumSubsType.LOGIN;
    this.subLogin = this.authenticationServiceBase.login({ username, password }).
      subscribe({
        next: (data: string) => this.onLoginComplete(data, false),
        error: (err: any) => this.onLoginFailed(err)
      });
  }

  public getOpenIdConfiguration(username: string): void {
    this.loading = true;
    this.traceService.debug(TraceModules.login, 'Try to get OpenID configuration.');
    this.errorMessage = '';
    this.subsType = EnumSubsType.GETINFO;
    this.subGetInfo = this.authenticationServiceBase.getUserInfo(username).
      subscribe((userInfo: UserInfo) => {
        if (userInfo.AccountType === UserAccountType.OpenID) {
          window.location.href = userInfo.OpenIdLoginUri;
        } else {
          this.loading = false;
          this.isSingleSignOn = false;
        }
      },
      () => {
        this.loading = false;
        this.traceService.warn(TraceModules.login, 'Error in Single Sign On.');
        this.isSingleSignOn = false;
      });
  }

  public onLoginComplete(value: string, isDefaultUserLogin: boolean): void {
    const pathName = this.cookieService.get(SessionCookieStorage.PathName) === '' ? '/' : this.cookieService.get(SessionCookieStorage.PathName);
    const domainName = this.cookieService.get(SessionCookieStorage.DomainName);
    if (!isDefaultUserLogin) {
      if (this.traceService.isDebugEnabled(TraceModules.login)) {
        this.traceService.debug(TraceModules.login, 'Login succesful, token received %s', value);
      }
      this.authenticationServiceBase.setDefaultUserLoggedIn(isDefaultUserLogin);
      this.cookieService.delete(SessionCookieStorage.SkipDefaultUserAutoLogin, pathName, domainName);

    } else {
      if (this.traceService.isDebugEnabled(TraceModules.login)) {
        this.traceService.debug(TraceModules.login, 'Default user login succesful.');
      }
      this.authenticationServiceBase.setDefaultUserLoggedIn(isDefaultUserLogin);
      this.cookieService.set(SessionCookieStorage.SkipDefaultUserAutoLogin, SessionCookieStorage.True, { 'path': pathName, 'domain': domainName });
    }

    this.busy = false;
  }

  public onLoginFailed(error: any): void {
    this.busy = false;
    this.loading = false;
    this.pwdvalue = '';

    if (!isNullOrUndefined(error)) {
      this.manageErrors(error);
    }
  }

  public onGetOpenIdConfigComplete(_data: any): void {
    // TODO document why this method 'onGetOpenIdConfigComplete' is empty
  }

  public onGetOpenIdConfigFailed(_error: any): void {
    // TODO document why this method 'onGetOpenIdConfigFailed' is empty
  }

  public back(): void {
    this.isSingleSignOn = true;
    this.pwdvalue = '';
  }

  public requestCancel(): void {
    this.loading = false;
    switch (this.subsType) {
      case EnumSubsType.LOGIN:
        this.subLogin.unsubscribe();
        this.back();
        break;
      case EnumSubsType.GETINFO:
        this.subGetInfo.unsubscribe();
        this.back();
        break;
      case EnumSubsType.AUTH0:
        this.subAuth0.unsubscribe();
        this.back();
        break;
      default:
        break;
    }
  }

  private onDefaultLoginFailed(err: any): void {
    this.traceService.info(TraceModules.login, 'Error trying default user login: %s.', err);
    this.loading = false;
    this.loginWithAuthorizationCode();
  }

  private loginWithAuthorizationCode(): void {
    const query = window.location.href;
    if (query.includes('loginpage?') && !isNullOrUndefined(sessionStorage.getItem(UserInfoStorage.OpenIdLoginUriKey))) {
      this.loading = true;
      this.code = '&' + window.location.search.substring(1);
      this.subsType = EnumSubsType.AUTH0;
      this.subAuth0 = this.authenticationServiceBase.LoginUsingAuthorizationCode(this.code).subscribe(
        (data: string) => this.onLoginComplete(data, false),
        (err: any) => this.onLoginFailed(err));
    } else {
      if (!isNullOrUndefined(sessionStorage.getItem(UserInfoStorage.OpenIdLoginUriKey))) {
        this.subAuth0 = this.authenticationServiceBase.LoginUsingAuthorizationCode('').subscribe(
          (data: string) => this.onLoginComplete(data, false),
          (err: any) => this.onLoginFailed(err));
      }
    }
  }

  private changePassword(message: string): void {
    this.traceService.info(TraceModules.login, 'Change password required' + message);

    this.changePwd.emit(this.cred);
  }

  private manageErrors(error: any): void {
    // PASSWORD EXPIRED
    if (error.status === 403 && error.name === 'WsiErrorPasswordExpired') {
      this.traceService.info(TraceModules.login, error.statusText);
      this.changePassword(error.statusText);

    } else {
      this.errorMessage = error.statusText;
      this.traceService.warn(TraceModules.login, 'Login failed, token not received');
    }
  }

}
