import { ParameterType } from '../processor/command/parameters/gms-base-parameter';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { FormatHelper } from '../utilities/format-helper';
import { MathUtils } from '../utilities/mathUtils';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsCommandControlSlider } from './gms-commandcontrol-slider';

export class GmsCommandControlRotator extends GmsCommandControlSlider {

  private _minimumAngle = 0;
  public get MinimumAngle(): number {
    return this._minimumAngle;
  }
  public set MinimumAngle(value: number) {
    if (this._minimumAngle !== value) {
      this._minimumAngle = value;
      this.NotifyPropertyChanged('MinimumAngle');
    }
  }
  private _totalRotationAngle = 180;

  public get TotalRotationAngle(): number {
    return this._totalRotationAngle;
  }
  public set TotalRotationAngle(value: number) {
    if (this._totalRotationAngle !== value) {
      this._totalRotationAngle = value;
      this.NotifyPropertyChanged('TotalRotationAngle');
    }
  }

  private _rotX = 0;
  private _rotY = 0;
  private _radius = 0;
  private _originalAngle = 0;
  private _thumbHeight2: number;
  private _minimumAngleEvaluation = undefined;
  private _totalRotationAngleEvaluation = undefined;
  private _direction = 0;

  // convert angle from degrees to radians
  private D2R(angle: number): number {
    return angle * MathUtils.factor;
  }

  // convert angle from radians to degrees
  private R2D(angle: number): number {
    return angle / MathUtils.factor;
  }

  private Value2Radian(value: number): number {
    const minimumAngle: number = this.GetAnimatedMinimumAngle();
    const totalRotationAngle: number = this.GetAnimatedTotalRotationAngle();
    const MinMax: [number, number] = this.CalculateMinMax();
    const minimum: number = MinMax[0];
    const maximum: number = MinMax[1];
    let angle: number;
    if (maximum <= minimum) {
      angle = minimumAngle;
    } else {
      angle = minimumAngle + totalRotationAngle * (value - minimum) / (maximum - minimum);
    }

    return this.D2R(angle);
  }

  private Angle2Value(angle: number): number {
    const minimumAngle: number = this.GetAnimatedMinimumAngle();
    const totalRotationAngle: number = this.GetAnimatedTotalRotationAngle();
    const MinMax: [number, number] = this.CalculateMinMax();
    const minimum: number = MinMax[0];
    const maximum: number = MinMax[1];
    const scale: number = (angle - minimumAngle) / totalRotationAngle;
    return minimum + scale * (maximum - minimum);
  }

  private Value2Angle(value: number): number {
    const minimumAngle: number = this.GetAnimatedMinimumAngle();
    const totalRotationAngle: number = this.GetAnimatedTotalRotationAngle();
    const MinMax: [number, number] = this.CalculateMinMax();
    const minimum: number = MinMax[0];
    const maximum: number = MinMax[1];

    const scale: number = (value - minimum) / (maximum - minimum);
    return minimumAngle + scale * totalRotationAngle;
  }

  private Distance(x1: number, y1: number, x2: number, y2: number): number {
    const dx: number = x2 - x1;
    const dy: number = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
  }

  private RotationDirection(x: number, y: number): number {
    const dx0: number = this._startThumbX - this._rotX;
    const dy0: number = this._startThumbY - this._rotY;
    const dx1: number = x - this._startThumbX;
    const dy1: number = y - this._startThumbY;
    let result: number = dx0 * dy1 - dy0 * dx1;
    result = result < 0 ? -1 : result > 0 ? 1 : 0;
    return result;
  }

  protected UpdateValue(): void {
    if (!this.CommandVM.Suspended && this.ParameterVM !== null) {
      let minimumLong: Long;
      let maximumLong: Long;
      if (this.isLongTypeParameter) {
        minimumLong = this.GetAnimatedMinimumLong();
        maximumLong = this.GetAnimatedMaximumLong();
      }
      const MinMax: [number, number] = this.CalculateMinMax();
      const minimum: number = MinMax[0];
      const maximum: number = MinMax[1];
      const angle: number = this.Thumb.Angle;

      let tick: number = this.Angle2Value(angle);
      let tickLong: Long;
      if (this.GetAnimatedSnapToTickEnabled()) {
        tick = minimum + this.GetAnimatedTickFrequencyInterval() * Math.round((tick - minimum) / this.GetAnimatedTickFrequencyInterval());
      }
      if (this.isLongTypeParameter || this.ParameterVM.precision === 0) {
        tick = Math.round(tick);
      }
      if (this.isLongTypeParameter) {
        if (tick === minimum) {
          tickLong = minimumLong;
        } else if (tick === maximum) {
          tickLong = maximumLong;
        } else {
          tickLong = FormatHelper.parseLongValue(tick, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
          if (tickLong.lessThan(minimumLong)) {
            tickLong = minimumLong;
          } else if (tickLong.greaterThan(maximumLong)) {
            tickLong = maximumLong;
          }
        }
      }
      if (tick < minimum) {
        tick = minimum;
      } else if (tick > maximum) {
        tick = maximum;
      }
      this.ThumbClone.X = this.Thumb.X;
      this.ThumbClone.Y = this.Thumb.Y;
      this.ThumbClone.Angle = this.Thumb.Angle;

      const val: string = this.isLongTypeParameter ? tickLong?.toString() :
        FormatHelper.NumberToString(tick, this.locale, null, 10);

      this.ValueChanged(val);
    }
  }
  protected UpdateEvaluation(evaluation: Evaluation): void {
    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {
      case 'MinimumAngle':
        this._minimumAngleEvaluation = evaluation;
        break;
      case 'TotalRotationAngle':
        this._totalRotationAngleEvaluation = evaluation;
        break;
      default:
        break;
    }
  }
  protected GetAnimatedMinimumAngle(): number {
    const value: number = Evaluation.GetValue2(this._minimumAngleEvaluation, this.MinimumAngle, PropertyType.Number);
    return value;
  }

  protected GetAnimatedTotalRotationAngle(): number {
    const value: number = Evaluation.GetValue2(this._totalRotationAngleEvaluation, this.TotalRotationAngle, PropertyType.Number);
    return value;
  }

  protected UpdatePosition(): void {
    if (!this.CommandVM.Suspended && this.ParameterVM !== null) {
      const minimumAngle: number = this.GetAnimatedMinimumAngle();
      const totalRotationAngle: number = this.GetAnimatedTotalRotationAngle();
      let value: number = Number.NaN;
      const MinMax: [number, number] = this.CalculateMinMax();
      const minimum: number = MinMax[0];
      const maximum: number = MinMax[1];

      if (this.isLongTypeParameter) {
        const valueLong: Long = this.CommandVM.isModified ? FormatHelper.parseLongValue(this.ParameterVM.FormattedValue, this.ParameterVM.ParameterType === ParameterType.NumericUInt64) :
          FormatHelper.parseLongValue(this.ParameterVM.Value, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
        if (valueLong) {
          value = valueLong.toNumber();
        }
      } else {
        // value = this.CommandVM.isModified ? Utility.ConvertToDouble(this.ParameterVM.FormattedValue) :
        //     Utility.ConvertToDouble(this.ParameterVM.Value);

        value = FormatHelper.getNumberfromString(this.CommandVM.isModified ?
          this.ParameterVM.FormattedValue : this.ParameterVM.Value, this.locale);
      }

      if (Number.isNaN(value)) {
        value = minimum;
      }
      if (value < minimum) {
        value = minimum;
      } else if (value > maximum) {
        value = maximum;
      }

      let angle: number = this.Value2Angle(value);
      if (angle < minimumAngle) {
        angle = minimumAngle;
      } else if (angle > minimumAngle + totalRotationAngle) {
        angle = minimumAngle + totalRotationAngle;
      }

      this._thumbWidth2 = this.Thumb.Width / 2;
      this._thumbHeight2 = this.Thumb.Height / 2;
      this._startThumbX = this.Thumb.X + this._thumbWidth2;
      this._startThumbY = this.Thumb.Y + this._thumbHeight2;
      this._originalAngle = this.D2R(this.Thumb.Angle);
      const c: number = Math.cos(this._originalAngle);
      const s: number = Math.sin(this._originalAngle);
      const dx: number = this.Thumb.X + this.Thumb.AngleCenterX - this._startThumbX;
      const dy: number = this.Thumb.Y + this.Thumb.AngleCenterY - this._startThumbY;
      this._rotX = this._startThumbX + dx * c - dy * s;
      this._rotY = this._startThumbY + dx * s + dy * c;
      let x: number = this._rotX - this._startThumbX;
      let y: number = this._rotY - this._startThumbY;
      this._radius = Math.sqrt(x * x + y * y);

      this.Thumb.Angle = angle;

      // calculate new thumb center point
      angle = this.D2R(angle);
      x = this._rotX + this._radius * Math.sin(angle);
      y = this._rotY - this._radius * Math.cos(angle);

      // set new thumb position
      this.Thumb.X = x - this._thumbWidth2;
      this.Thumb.Y = y - this._thumbHeight2;
    }
  }

  public StartSliding(x: number, y: number): void {
    this.IsSliding = true;
    this._startMouseX = x;
    this._startMouseY = y;
    this._thumbWidth2 = this.Thumb.Width / 2;
    this._thumbHeight2 = this.Thumb.Height / 2;
    this._direction = 0;

    // calculate thumb starting center point (_startThumbX, _startThumbY)
    this._startThumbX = this.Thumb.X + this._thumbWidth2;
    this._startThumbY = this.Thumb.Y + this._thumbHeight2;

    // calculate original rotation angle
    this._originalAngle = this.D2R(this.Thumb.Angle);

    // calculate rotation point
    const c: number = Math.cos(this._originalAngle);
    const s: number = Math.sin(this._originalAngle);
    const dx: number = this.Thumb.X + this.Thumb.AngleCenterX - this._startThumbX;
    const dy: number = this.Thumb.Y + this.Thumb.AngleCenterY - this._startThumbY;
    this._rotX = this._startThumbX + dx * c - dy * s;
    this._rotY = this._startThumbY + dx * s + dy * c;

    // calculate radius
    x = this._rotX - this._startThumbX;
    y = this._rotY - this._startThumbY;
    this._radius = Math.sqrt(x * x + y * y);

    const interval: number = this.GetAnimatedUpdateInterval();
    if (interval >= 0) {
      this.StartTimer();
    }

    if (this.GetAnimatedSliderShadow()) {
      // update clone position
      if (this.children.length > 1) {
        this.ThumbClone.Angle = this.Thumb.Angle;
        this.ThumbClone.X = this.Thumb.X;
        this.ThumbClone.Y = this.Thumb.Y;
        this.ThumbClone.Visible = true;
      }
    }

    this.CommandVM.isCommandExecuteDone = false;
    this.CommandVM.isCommandExecuteCancel = false;
    if (!this.GetAnimatedIsCommandTriggerEnabled()) {
      // command will not be executed, so keep it modified
      this.CommandVM.isModified = true;
    }
  }

  public Slide(x: number, y: number): void {
    // get vector calculated from the original point
    let dx: number = x - this._startMouseX;
    let dy: number = y - this._startMouseY;

    // transform this vector to the slider coordinate system
    if (this.Angle !== 0) {
      const angle: number = this.D2R(-this.Angle);
      const c: number = Math.cos(angle);
      const s: number = Math.sin(angle);
      const dx1 = dx * c - dy * s;
      dy = dx * s + dy * c;
      dx = dx1;
    }

    if (this.ScaleX !== 1) {
      dx = dx / this.ScaleX;
    }
    if (this.ScaleY !== 1) {
      dy = dy / this.ScaleY;
    }

    if (this.CurrentZoomLevel !== 1) {
      dx = dx / this.CurrentZoomLevel;
      dy = dy / this.CurrentZoomLevel;
    }

    // new thumb center point will be located on line (_rotX, _rotY), (x, y)
    x = this._startThumbX + dx;
    y = this._startThumbY + dy;

    // Determine rotation angle from starting thumb center point axis to the current thumb center point axis
    const dx0: number = this._startThumbX - this._rotX;
    const dy0: number = this._startThumbY - this._rotY;

    const dx1: number = x - this._rotX;
    const dy1: number = y - this._rotY;

    const cs: number = (dx0 * dx1 + dy0 * dy1) / (Math.sqrt(dx0 * dx0 + dy0 * dy0) * Math.sqrt(dx1 * dx1 + dy1 * dy1));
    let angle: number = cs > 1 ? 0 : cs < -1 ? Math.PI : Math.acos(cs);

    // Determine the rotation direction (clockwize or counter-clockwize)
    const direction: number = this.RotationDirection(x, y);
    if (this._direction === 0) {
      // set initial direction
      this._direction = direction;
    } else if (this._direction !== direction) {
      if (this.Distance(this._startThumbX, this._startThumbY, x, y) < this.Distance(this._startThumbX, this._startThumbY, this._rotX, this._rotY)) {
        this._direction = direction;
      } else {
        angle = 2 * Math.PI - angle;
      }
    }
    if (this._direction < 0) {
      angle = -angle;
    }

    angle = this.R2D(this._originalAngle + angle);

    const minimumAngle: number = this.GetAnimatedMinimumAngle();
    const totalRotationAngle: number = this.GetAnimatedTotalRotationAngle();

    if (angle < minimumAngle) {
      angle = minimumAngle;
    } else if (angle > minimumAngle + totalRotationAngle) {
      angle = minimumAngle + totalRotationAngle;
    }
    // set thumb new angle
    this.Thumb.Angle = angle;

    // calculate new thumb center point
    angle = this.D2R(angle);
    x = this._rotX + this._radius * Math.sin(angle);
    y = this._rotY - this._radius * Math.cos(angle);

    // set new thumb position
    this.Thumb.X = x - this._thumbWidth2;
    this.Thumb.Y = y - this._thumbHeight2;
  }

  public EndSliding(x: number, y: number): void {

    this.StopTimer();

    this.Slide(x, y);

    this.UpdateValue();

    this.NotifyShapeChanged();

    this.ThumbClone.Visible = false;

    this.IsSliding = false;
  }

  public constructor() {
    super();
    this.ControlType = GmsCommandControlType.Rotator;
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {

    // MinimumAngle
    let result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.MinimumAngle);
    if (result !== undefined) {
      this.MinimumAngle = FormatHelper.StringToNumber(result);
    }

    // TotalRotationAngle
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.TotalRotationAngle);
    if (result !== undefined) {
      this.TotalRotationAngle = FormatHelper.StringToNumber(result);
    }

    super.Deserialize(node);
  }
}
