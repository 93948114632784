import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiInlineNotificationModule, SiLandingPageModule, SiLoadingSpinnerModule, SiPasswordStrengthModule } from '@simpl/element-ng';

import { LoginAuthenticationComponent } from './login-authentication/login-authentication.component';
import { LoginAuthenticationDirective } from './login-authentication/login-authentication.directive';
import { LoginChangePwdComponent } from './login-change-pwd/login-change-pwd.component';
import { FooterEmptylinkDirective } from './login-main/footer-emptylink.directive';
import { LoginComponent } from './login-main/login.component';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './i18n/', '.json');

@NgModule({
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SiInlineNotificationModule,
    SiLandingPageModule,
    SiLoadingSpinnerModule,
    SiPasswordStrengthModule],
  declarations: [FooterEmptylinkDirective,
    LoginAuthenticationComponent,
    LoginAuthenticationDirective,
    LoginChangePwdComponent,
    LoginComponent
  ],
  exports: [LoginAuthenticationComponent, LoginComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})

export class HfwLoginModule { }
