<si-scene-master-detail
  #scenes
  [sceneModel]="model"
  [objectId]="objectId"
  [description]="description"
  [displayMode]="displayMode"
  (isProcessing)="isLoading = $event"
/>

<div class="loading-spinner">
  @if (isLoading) {
    <si-loading-spinner />
  }
</div>
