import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SelectOption } from '@simpl/element-ng/select';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-readonly-threshold-option',
  template: `@if (option?.icon) {
      <i
        class="icon me-2"
        aria-hidden="true"
        [ngClass]="[option?.icon, option?.disabled ? '' : option?.color]"
      ></i>
    }
    <span class="text-truncate">{{ option?.title | translate }}</span>`,
  host: { class: 'd-flex align-items-center py-2 my-4 px-4 fw-bold' },
  imports: [NgClass, SiTranslateModule],
  standalone: true,
  styleUrl: './si-readonly-threshold-option.component.scss'
})
export class SiReadonlyThresholdOptionComponent implements OnChanges {
  @Input({ required: true }) value!: string;
  @Input({ required: true }) options!: SelectOption[];

  protected option?: SelectOption;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value && this.options) {
      this.option = this.options.find(opt => opt.id === this.value);
    }
  }
}
