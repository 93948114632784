import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FullQParamId,
  IHfwMessage,
  ISnapInConfig,
  SnapInBase
} from '@gms-flex/core';
import { BrowserObject, GraphicsService, Page, SystemBrowserServiceBase } from '@gms-flex/services';
import { AppContextService, isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { animationFrameScheduler, asyncScheduler, BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OPStep, ResolveExecutionResult, ResolveExecutionStatus } from '../../../events/event-data.model';
import { BrowserObjectService } from "../../../events/services/browser-object.service";
import { GraphicSnapinHldlConfig } from '../common/interfaces/GraphicSnapinHldlConfig';
import { GraphicSnapInArgs } from '../common/interfaces/GraphicsSnapinArgs';
import { TraceChannel } from '../common/trace-channel';
import { GmsBrowserObjectService } from '../services/gms-browser-object.service';
import { ElementBrushService } from '../services/gms-brush.service';
import { GmsCommandService } from '../services/gms-command-service';
import { DataPointService } from '../services/gms-datapoint2.service';
import { LibraryImageService } from '../services/gms-library-image-service';
import { GmsObjectSelectionService } from '../services/gms-object-selection.service';
import { PropertyImageService } from '../services/gms-property-image.service';
import { GraphicStateStorageService } from '../services/gms-storage.service';
import { GmsSystemsService } from '../services/gms-systems.service';
import { TextGroupService } from '../services/gms-text-group-service';
import { TimerService } from '../services/timer-service';

/**
 * The controller/viewmodel of the graphics viewer snapin.
 */

@Component({
  selector: 'gms-graphics-common',
  templateUrl: './graphics-common.component.html',
  styleUrl: '../gms-graphics-common.scss'
})

export class GraphicsCommonComponent extends SnapInBase implements OnInit, OnDestroy {
  @HostBinding('class.hfw-flex-container-column') public guardFrame = true;
  @HostBinding('class.hfw-flex-item-grow') public guardGrow = true;
  @HostBinding('class.panel') public guardPanel = true;
  @HostBinding('class.snapin-container') public guardSnapIn = true;

  public _snapinArgs: GraphicSnapInArgs;
  @Input() public snapinArgsSubject: BehaviorSubject<GraphicSnapInArgs> = new BehaviorSubject<GraphicSnapInArgs>(undefined);
  @Input() public _hldlConfig: GraphicSnapinHldlConfig;
  @Input() public onBeforeAttachSubject: Subject<void> = new Subject<void>();
  @Output() public readonly selectionChangedEmitter: EventEmitter<BrowserObject[]> = new EventEmitter<BrowserObject[]>();
  @Output() public readonly navigationEmitter: EventEmitter<BrowserObject[]> = new EventEmitter<BrowserObject[]>();

  @Input() public set browserObject(object: BrowserObject) {
    this._snapinArgs = { SelectionObject: object, CustomDataObject: undefined };
    this.snapinArgsSubject.next(this._snapinArgs);
  }

  @Input() public assistedTreatmentStep: OPStep = new OPStep();
  @Input() public resolveObs: BehaviorSubject<boolean> = new BehaviorSubject(null);
  @Output() public readonly resolveExecutionResult: EventEmitter<ResolveExecutionResult> = new EventEmitter<ResolveExecutionResult>();

  /**
   * NOTE:
   * This code suffices the need to get a browser object for a given event.
   * This will be refactored.
   * @param eventsArr
   */
  @Input() public set events(eventsArr: any[]) {
    const designationString: string = eventsArr?.[0]?.srcDesignation;
    if (!isNullOrUndefined(designationString)) {
      this.getBrowserObject(designationString)
        .then((browserObject: BrowserObject) => this.onGetBrowserObject(browserObject))
        .catch(() => this.onGetBrowserObjectError(designationString))
    }
  }

  private onGetBrowserObject(object: BrowserObject): void {
    this._snapinArgs = { SelectionObject: object, CustomDataObject: undefined };
    this.snapinArgsSubject.next(this._snapinArgs);
  }

  private onGetBrowserObjectError(designation: string): void {
    this.traceService.error(this.traceModule, `Could not retrieve browserObject for ${designation}`);
  }

  private async getBrowserObject(designation: string): Promise<BrowserObject> {
    let searchOption: any;

    const systemView = designation.split(':')[0];
    const systemName = systemView.split('.')[0];

    const systemId = await this.browserObjectService.getSystemIdFromSystemName(systemName);
    const page: Page = await lastValueFrom(this.systemBrowserService.searchNodes(systemId, designation, undefined, searchOption));
    const browserObject: BrowserObject = page.Nodes[0];
    return browserObject;
  }

  private readonly subscriptions: Subscription[] = [];
  private readonly traceModule: string;
  private readonly unsubscribe: Subject<void>;

  private isSinglePane: boolean;
  private fullQParamId: FullQParamId;

  // This flag is needed for secondary selection made 2 pane layout.
  // In this scenario we need to send 2 messages instead of one to update contextual and related panes.
  // This code needs to be removed once right-panel feature will be in place.
  private avoidPreselectOnSecondarySelection: boolean;

  public ngOnInit(): void {
    this.resolveObs.subscribe(res => {
      if (res === true) {
        // execute stuff on resolve button pressed, in case of the document viewer, nothing is done, and the execution is always a success
        // here you can also specify an error message to pass to the stepper on failure, it will show in a toast notification
        const resolveResult: ResolveExecutionResult = {
          status: ResolveExecutionStatus.Success
        }
        this.resolveExecutionResult.emit(resolveResult);
      }
    });

    // Initialize the hldlconfig for graphics snapin
    this.getHldlConfigs();

    this.subscriptions.push(this.onBeforeAttachSubject.subscribe(() => this.onBeforeAttach()));

    if (!isNullOrUndefined(this?.fullId?.frameId)) {
      this.fullQParamId = new FullQParamId(this.fullId.frameId, 'SystemQParamService', 'primary');
    }

    this.gmsObjectSelectionService.selectedObjects.pipe(takeUntil(this.unsubscribe)).subscribe(
      (selectedBrowserObjects: BrowserObject[]) => {
        this.selectionChangedEmitter.emit(selectedBrowserObjects);
      });
    this.gmsObjectSelectionService.navigate.pipe(takeUntil(this.unsubscribe)).subscribe(
      (navigationTargets: BrowserObject[]) => {
        this.navigationEmitter.emit(navigationTargets);
      });

    this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
      if (userCulture != null) {
        this.translateService.use(userCulture).subscribe((res: any) => {
          this.traceService.info(this.traceModule, 'use  user Culture');
        },
        (err: any) => {
          this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
            if (defaultCulture != null) {
              this.translateService.setDefaultLang(defaultCulture);
            } else {
              this.traceService.warn(this.traceModule, 'No default Culture for appContextService');
              this.translateService.setDefaultLang(this.translateService.getBrowserLang());
            }
          }));
        });
      } else {
        this.traceService.warn(this.traceModule, 'No user Culture for appContextService');
      }
    }));

    this.traceService.info(this.traceModule, 'guard for styling set to %s, %s and %s, ',
      this.guardFrame, this.guardPanel, this.guardSnapIn);

    this.initialiseValueSubscriptionService();

    this.storageService.initStorageService(this.messageBroker, this.fullId);
  }

  public onBeforeAttach(): void {
    super.onBeforeAttach();

    const containerId = `${this.storageService.SnapinId}-container`;

    const svgContainer: HTMLElement = document.getElementById(containerId);
    if (svgContainer !== null) {

      if (svgContainer.scrollWidth === 0 && svgContainer.scrollHeight === 0) {
        return;
      }

      const percentageX: number = svgContainer.scrollLeft / svgContainer.scrollWidth;
      const percentageY: number = svgContainer.scrollTop / svgContainer.scrollHeight;

      const task: () => void = (): void => {
        if (svgContainer.scrollWidth !== 0) {
          svgContainer.scrollLeft = svgContainer.scrollWidth * percentageX;
        }

        if (svgContainer.scrollHeight !== 0) {
          svgContainer.scrollTop = svgContainer.scrollHeight * percentageY;
        }
      };

      animationFrameScheduler.schedule(task, 1);
    }

    // update the hldlconfig for graphics snapin
    // this is not enough in case of reuse. this.location is not updated.
    this.getHldlConfigs();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.traceService.info(this.traceModule, 'Component destroyed.');
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription != null) {
        subscription.unsubscribe();
      }
    });

    if (this._snapinArgs !== undefined) {
      this._snapinArgs.CustomDataObject = undefined;
      this._snapinArgs.SelectionObject = undefined;
      this._snapinArgs = undefined;
    }

    if (this._hldlConfig !== undefined) {
      this._hldlConfig = undefined;
    }

    this.unInitialiseServices();
  }

  /**
   * Constructor
   * @param traceService The trace service.
   * @param messageBroker
   * @param activatedRoute
   */
  public constructor(private readonly traceService: TraceService,
    messageBroker: IHfwMessage,
    activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly dataPointService: DataPointService,
    private readonly graphicsService: GraphicsService,
    private readonly textGroupService: TextGroupService,
    private readonly propertyImageService: PropertyImageService,
    private readonly libraryImageService: LibraryImageService,
    private readonly elementBrushService: ElementBrushService,
    private readonly gmsSystemsService: GmsSystemsService,
    private readonly gmsObjectSelectionService: GmsObjectSelectionService,
    private readonly gmsBrowserObjectService: GmsBrowserObjectService,
    private readonly gmsCommandService: GmsCommandService,
    private readonly storageService: GraphicStateStorageService,
    private readonly snapinconfig: ISnapInConfig,
    private readonly systemBrowserService: SystemBrowserServiceBase,
    private readonly browserObjectService: BrowserObjectService,
    private readonly timerService: TimerService) {
    super(messageBroker, activatedRoute);
    this.traceModule = TraceChannel.Component;
    this.unsubscribe = new Subject<void>();
  }

  private getHldlConfigs(): void {
    this._hldlConfig = this.snapinconfig.getSnapInHldlConfig(this.fullId, this.location);
    if (isNullOrUndefined(this._hldlConfig)) {
      this.traceService.info(this.traceModule, 'Graphic snapin hldl config not available');
    } else {
      this.traceService.info(this.traceModule, 'Graphic snapin hldl config initialized');
      this.isSinglePane = this._hldlConfig.IsSinglePane;
      this.avoidPreselectOnSecondarySelection = this._hldlConfig.avoidPreselectOnSecondarySelection;
    }
  }

  private initialiseValueSubscriptionService(): void {
    if (this.dataPointService !== undefined) {
      this.dataPointService.initialiseValueSubscriptionService(GraphicsCommonComponent.name);
      this.traceService.info(this.traceModule, 'DataPointService: ValueSubscriptionService  initialised');
    }
  }

  private unInitialiseServices(): void {
    if (this.dataPointService !== undefined) {
      this.dataPointService.unInitialiseValueSubscriptionService();
      this.traceService.info(this.traceModule, 'DataPointService: ValueSubscriptionService  uninitialised');
    }
    if (this.propertyImageService !== undefined) {
      this.propertyImageService.unsubscribe();
      this.traceService.info(this.traceModule, 'PropertyImageService: Service  uninitialised');
    }
    if (this.textGroupService !== undefined) {
      this.textGroupService.unsubscribe();
      this.traceService.info(this.traceModule, 'TextGroupService: Service  uninitialised');
    }
    if (this.libraryImageService !== undefined) {
      this.libraryImageService.unsubscribe();
      this.traceService.info(this.traceModule, 'LibraryImageService: Service  uninitialised');
    }
    if (this.elementBrushService !== undefined) {
      this.elementBrushService.unsubscribe();
      this.traceService.info(this.traceModule, 'ElementBrushService: Service  uninitialised');
    }
    // Commanding
    if (this.gmsCommandService !== undefined) {
      this.gmsCommandService.unInitialiseCommandUpdateSubscriptionService();
    }
  }
}
