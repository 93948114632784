import { Injectable } from '@angular/core';
import { SeverityServiceBase } from '@gms-flex/services';
import { ExtendedStatusType } from '@simpl/element-ng';

@Injectable()
export class SeverityBxSubstituteService extends SeverityServiceBase {
  private readonly categorySeverityMap: Map<string, ExtendedStatusType> = new Map <string, ExtendedStatusType>();

  constructor() {
    super();
    this.categorySeverityMap.set('Life Safety', 'critical');
    this.categorySeverityMap.set('Emergency', 'critical');
    this.categorySeverityMap.set('Security', 'danger');
    this.categorySeverityMap.set('Supervisory', 'warning');
    this.categorySeverityMap.set('Trouble', 'warning');
    this.categorySeverityMap.set('High', 'warning');
    this.categorySeverityMap.set('Medium', 'caution');
    this.categorySeverityMap.set('Low', 'caution');
    this.categorySeverityMap.set('Fault', 'caution');
    this.categorySeverityMap.set('Status', 'info');
  }

  public getSeverityByCategory(categoryDescription: string): ExtendedStatusType {
    const severity = this.categorySeverityMap.get(categoryDescription);
    return severity ?? 'info';
  }
}
