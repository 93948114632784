import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { catchError, EMPTY, from, map, mergeMap, Observable, of, switchMap, toArray } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { HttpUtilityService } from "../shared/http-utility.service";
import { Digestion, PointDigestPV, PointDigestResponse, PointHistoryPeriod, PointHistoryRequest, PointHistoryResponse, PointHistoryResponsePV,
  Strategy } from "./point-history-proxy.model";
import { PointHistoryProxyService } from "./point-history-proxy.service";
import { PointBx } from "./point-proxy.model";
import { PointService } from "./point.service";

@Injectable({
  providedIn: 'root'
})
export class PointHistoryService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly pointHistoryProxy: PointHistoryProxyService,
    private readonly pointService: PointService,
    private readonly httpUtilityService: HttpUtilityService
  ) {
    this.traceService.info(TraceModules.bxServicesPoints, "PointHistoryService created.");
  }

  public getPointHistory(partitionId: string, pointId: string, fromData: string, to: string, zoomRange: number): Observable<PointHistoryResponse> {

    const pointDataRequest: PointHistoryRequest = {
      pointId: pointId,
      partitionId: partitionId,
      startDate: new Date(fromData),
      endDate: new Date(to),
      actualRange: zoomRange / 1000,
      strategy: "",
      precision: "",
      digestion: ""
    };

    return this.pointService.getPointById(partitionId, pointId).pipe(
      switchMap((pointBx: PointBx) => {
        return this.getPointHistoryResponse(pointBx, pointDataRequest).pipe(
          catchError((response: HttpResponse<any>) => {
            this.httpUtilityService.handleError(response, "PointHistoryService.getPointHistory()");
            return EMPTY;
          })
        );
      }),
      catchError((response: HttpResponse<any>) => {
        this.httpUtilityService.handleError(response, "PointHistoryService.getPointHistory()");
        return EMPTY;
      })
    );
  }

  private getPointHistoryResponse(point: PointBx, request: PointHistoryRequest): Observable<PointHistoryResponse> {

    request.strategy = this.getDigestionStrategy(point);
    return from(this.getPointHistoryData(request).pipe(
      switchMap(covs => {
        // Combine all the arrays received from the above calls
        const aggregatedCovs = covs.flat();
        return of(aggregatedCovs);
      }),
      switchMap(covs => {
        const aggregatedCovs: PointDigestResponse[] = covs.flat();
        if (Array.isArray(aggregatedCovs)) {
          return from(this.sortPointHistory(aggregatedCovs)).pipe(
            switchMap(sortedCovs => {
              const pointHistoryResponse: PointHistoryResponse = {
                data: sortedCovs,
                dataReductionApplied: this.getPrecision(request.actualRange, request.strategy) !== ""
              };
              return of(pointHistoryResponse);
            })
          );
        }
        // Handle the case where covs is not an array
        const pointHistoryResponse: PointHistoryResponse = {
          data: aggregatedCovs,
          dataReductionApplied: this.getPrecision(request.actualRange, request.strategy) !== ""
        };
        return of(pointHistoryResponse);
      }),
      catchError(err => {
        this.httpUtilityService.handleError(err, "PointHistoryService.getPointHistoryResponse()");
        // Return a default response or handle the error as needed
        return of({ data: [], dataReductionApplied: false });
      })
    ));
  }

  private getDigestionStrategy(point: PointBx): string {
    // check if a point belongs to an analog type (integer or number) but not an enum
    if (this.isAnalogPoint(point) && !this.hasEnumLabels(point)) {
      if (this.isEnergyPoint(point)) {
        return Strategy.CovEnergyData;
      }
      return Strategy.CovAverageMinMax;
    } else {
      return Strategy.CovSimple;
    }
  }

  // Determines if the given point is of analog type based on dataType,
  // valueType, or systemAttributes.kind
  private isAnalogPoint(point: PointBx): boolean {
    if (point.attributes.dataType != null && point.attributes.dataType) {
      return point.attributes.dataType === "integer" || point.attributes.dataType === "number";
    }

    if (point.attributes.valueType != null && point.attributes.valueType) {
      return point.attributes.valueType.includes("analog");
    }

    if (point?.attributes.systemAttributes?.kind) {
      return (point?.attributes.systemAttributes.kind as string).includes("Number");
    }
    return false;
  }

  // Checks if the point has an enum value. If present, iterates through the enum properties.
  // Collects labels into a result object. Returns false if labels are found, otherwise true.
  private hasEnumLabels(s: PointBx): boolean {
    const v = s.attributes.enum;

    if (!v) {
      return false;
    }

    const ret = new Map<string, string>();
    const val = v as { [key: string]: any };

    for (const [item1, item2] of Object.entries(val)) {
      const tmp = item2 as { [key: string]: any };
      if (tmp.label != null) {
        ret.set(item1, String(tmp.label));
      }
    }
    return ret.size > 0 ? true : false;
  }

  // To check if a point belongs to an EnergyPoint type
  private isEnergyPoint(point: PointBx): boolean {
    // Consider as energy point if point systemAttributes contain meter value.
    const sa = point.attributes.systemAttributes;
    if (sa && sa !== null) {
      const v = sa.meter;
      if (v !== undefined && v !== null) {
        return true;
      }
    }
    return false;
  }

  private getPointHistoryData(request: PointHistoryRequest): Observable<PointDigestPV[]> {
    return from(this.getPointHistoryRequest(request)).pipe(
      mergeMap(requests => {
        if (!requests) {
          return of([]);
        }
        return from(requests).pipe(
          mergeMap((req: PointHistoryRequest) =>
            from(this.getPointHistoryDataWithPagination(req)).pipe(
              catchError(err => {
                this.httpUtilityService.handleError(err, "PointHistoryService.getPointHistoryData()");
                return of([]);
              })
            )
          ),
          toArray() // Collect all emitted items into an array
        );
      })
    );
  }

  // Organize the covs in ascending order for structured analysis
  private sortPointHistory(pointHistory: PointDigestPV[]): Observable<PointDigestPV[]> {
    if (!Array.isArray(pointHistory)) {
      throw new Error("Invalid input: pointHistory must be an array");
    }
    pointHistory.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    return of(pointHistory);
  }

  private getPointHistoryRequest(request: PointHistoryRequest): Observable<PointHistoryRequest[]> {
    request.precision = this.getPrecision(
      request.actualRange,
      request.strategy
    );

    const requestArr: PointHistoryRequest[] = [];
    if (request.precision === "") {
      request.digestion = Digestion.Raw;
      requestArr.push(request);
      return of(requestArr);
    } else {
      const digestedDataRequest = this.getDigestedDataRequest(request);
      return digestedDataRequest;
    }
  }

  private getPrecision(diff: number, strategy: string): string {
    // const day = 86400; // Assuming Day is defined as 86400 seconds (24 hours)
    // const week = 604800; // Assuming Week is defined as 604800 seconds (7 days)
    // const covEnergyData = 'CovEnergyData'; // Assuming CovEnergyData is a specific strategy string
    // When the difference between the start date and end date exceeds one week, apply a precision of 24 hours.
    if (diff <= PointHistoryPeriod.Day) {
      // If the difference is less than or equal to 1 day, apply no precision
      return "";
    } else if (diff <= PointHistoryPeriod.Week) {
      // If the difference is less than or equal to 1 week, apply a precision of 15 minutes but for energy point apply a precision of 1 hour
      if (strategy === Strategy.CovEnergyData) {
        return "1h0m0s";
      }
      return "15m0s";
    }
    return "24h0m0s";
  }

  private getDigestedDataRequest(request: PointHistoryRequest): Observable<PointHistoryRequest[]> {
    // We need to add on the last TWO days of on demand digested history because
    // the user could be up to one day away in UTC time and
    // the PV team says the digester process could be up to one day behind
    // However if difference between start date and end date is less than 3 days get data on demand
    if ((request.endDate.getTime() - request.startDate.getTime()) / (1000 * 60 * 60) < 72) {
      const onDemandRequest = { ...request, digestion: Digestion.OnDemand };
      return of([onDemandRequest]);
    } else {
      return this.hasPredigestedData(request).pipe(
        switchMap(hasDefaultDigestion => {
          if (hasDefaultDigestion) {
            const requestData = this.getPredigestedDataRequest(request);
            return of(requestData);
          } else {
            // If the default predigested data rule is not available, retrieve on-demand data from the past 7 days that is accessible
            //  24 * 60 * 60 * 1000 = 1 day
            request.startDate = new Date(request.endDate.getTime() - PointHistoryPeriod.OnDemandMaxDuration * 24 * 60 * 60 * 1000);
            request.precision = this.getPrecision(PointHistoryPeriod.Week, request.strategy);
            request.digestion = Digestion.OnDemand;
            return of([request]);
          }
        })
      );
    }
  }

  private getPredigestedDataRequest(originalRequest: PointHistoryRequest): PointHistoryRequest[] {
    const requests: PointHistoryRequest[] = [];
    let newEndDate: Date;
    // We generally follow 2 digestion strategies for point
    // 1. Read on demand digestion for last 2 days
    // 2. Read predigested data from the start date to date 2 days before the end of the original request
    if (originalRequest.endDate.getTime() < (Date.now() - 2 * 24 * 60 * 60 * 1000)) {
      newEndDate = originalRequest.endDate;
    } else {
      const onDemandRequest = { ...originalRequest };
      newEndDate = new Date(originalRequest.endDate.getTime() - 2 * 24 * 60 * 60 * 1000);
      onDemandRequest.startDate = newEndDate;
      onDemandRequest.digestion = Digestion.OnDemand;
      requests.push(onDemandRequest);
    }

    const preDigestRequest = { ...originalRequest };
    preDigestRequest.digestion = Digestion.Predigest;
    preDigestRequest.endDate = newEndDate;
    requests.push(preDigestRequest);

    return requests;
  }

  private getPointHistoryDataWithPagination(request: PointHistoryRequest): Observable<PointDigestPV[]> {
    let digests: PointDigestPV[] = [];
    let paginationCursor = "";

    return new Observable<PointDigestPV[]>(observer => {
      const fetchData = (): void => {
        let respPoints: PointHistoryResponsePV;
        switch (request.digestion) {
          case "OnDemand":
            this.pointHistoryProxy.getOnDemandDigestedPointHistory(request, paginationCursor).subscribe(
              response => {
                respPoints = response;
                digests = digests.concat(respPoints.digests);
                paginationCursor = respPoints?.paginationCursor;
                if (!respPoints.paginationCursor || respPoints.paginationCursor == "") {
                  observer.next(digests);
                  observer.complete();
                } else {
                  // Iterates through pages until all are retrieved. Uses 'paginationCursor' to check for more pages.
                  // Makes subsequent server calls to get next pages.
                  fetchData();
                }
              },
              error => {
                observer.error(error);
              }
            );
            break;
          case "Predigest":
            this.pointHistoryProxy.getPredigestedPointHistory(request, paginationCursor).subscribe(
              response => {
                respPoints = response;
                digests = digests.concat(respPoints.digests);
                paginationCursor = respPoints?.paginationCursor;
                if (!paginationCursor || paginationCursor == "") {
                  observer.next(digests);
                  observer.complete();
                } else {
                  fetchData();
                }
              },
              error => {
                observer.error(error);
              }
            );
            break;
          case "Raw":
            this.pointHistoryProxy.getRawPointHistory(request, paginationCursor).subscribe(
              response => {
                respPoints = response;
                digests = digests.concat(respPoints.covs);
                paginationCursor = respPoints?.paginationCursor;
                if (!paginationCursor || paginationCursor == "") {
                  observer.next(digests);
                  observer.complete();
                } else {
                  fetchData();
                }
              },
              error => {
                observer.error(error);
              }
            );
            break;
          default:
            observer.error(new Error("Invalid Digestion type"));
        }
      };
      fetchData();
    });
  }

  private hasPredigestedData(request: PointHistoryRequest): Observable<boolean> {

    return this.pointHistoryProxy.getRegisteredDigestionsForPoint(request.pointId, request.partitionId).pipe(
      map(digestions => {
        if (digestions.digestions.length == 0) {
          return false;
        }
        return digestions.digestions[0].rules.some(rule =>
          rule.ruleID === `${request.strategy}+${request.precision}` && !rule.isDeleted
        );
      }),
      catchError(err => {
        this.traceService.warn(TraceModules.bxServicesPoints, `Unable to get registered digestion Error for: ${request.pointId}, error=${err}`);
        return of(false); // Return an observable of false in case of error
      })
    );
  }
}
