import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { SiDatatableModule, SiEmptyStateComponent } from '@simpl/element-ng';
import { SiLinkActionService } from '@simpl/element-ng/link';
import { focusFirstFocusable } from '@simpl/object-browser-ng/common';
import { Subscription } from 'rxjs';

import { DateFormat, TimeFormat } from '../../interfaces/date-time-formats';
import {
  AnyProperty,
  CommandEvent,
  DefaultProperty,
  Property,
  PropertyCommand,
  PropertyInfoData,
  ValueState
} from '../../interfaces/property';
import { SiPropertyPopoverService } from '../si-property-popover/si-property-popover.service';
import { SiPropertyComponent } from '../si-property/si-property.component';
import { PropertyDisplayStyle, PropertyTemplateContext } from '../si-property/si-property.model';
import { SiCustomActionPaneComponent } from './si-custom-action-pane/si-custom-action-pane.component';
import { SiPropertyTableComponent } from './si-property-table/si-property-table.component';
import { TableProperty } from './si-property-table/si-property-table.model';

@Component({
  selector: 'si-property-list',
  templateUrl: './si-property-list.component.html',
  styles: [':host { display: block; }'],
  providers: [SiLinkActionService, SiPropertyPopoverService],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SiCustomActionPaneComponent,
    SiDatatableModule,
    SiEmptyStateComponent,
    SiPropertyComponent,
    SiPropertyTableComponent
  ]
})
export class SiPropertyListComponent implements OnDestroy, OnChanges {
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  @Input() allowValuesOutOfRange = false;
  @Input({ required: true }) properties!: (Property | DefaultProperty)[];
  @Input() valueState: ValueState[] = [];
  @Input() filter?: string;
  @Input() rowClass = 'row';
  @Input() colClass = 'col-md-6 col-lg-4';
  /**
   * Way the list of properties (for non-bulk mode) should be displayed.
   */
  @Input() displayStyle: PropertyDisplayStyle = 'list';
  /**
   * Custom template to support additional types of properties (for non-bulk mode).
   */
  @Input() customTemplate?: TemplateRef<PropertyTemplateContext>;

  @Output() readonly submitted = new EventEmitter<Property>();
  @Output() readonly command = new EventEmitter<CommandEvent>();
  @Output() readonly filtered = new EventEmitter<number>();

  @ViewChild('customActionPane') customActionPane!: SiCustomActionPaneComponent;
  @ViewChild('table', { read: SiPropertyTableComponent }) table?: SiPropertyTableComponent;

  filteredProperties: (Property | DefaultProperty)[] = [];
  protected filteredIndexes: number[] = [];

  private actionSub: Subscription;
  private focusProperty?: HTMLElement;

  private siPropertyPopoverService = inject(SiPropertyPopoverService);
  private actionService = inject(SiLinkActionService);
  private elementRef = inject(ElementRef);

  constructor() {
    this.actionSub = this.actionService.action$.subscribe(action => {
      this.handleCommand(action.link as PropertyCommand, {
        command: action.link.action as string,
        property: action.param
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.properties || changes.filter || changes.displayStyle) {
      this.filterProps();
    }
  }

  ngOnDestroy(): void {
    this.actionSub.unsubscribe();
  }

  protected submit(event: Property | TableProperty): void {
    if (this.table) {
      this.submitted.emit(this.table.mapSubmit(event));
    } else {
      this.submitted.emit(event);
    }
  }

  trackById = (index: number, item: Property | DefaultProperty): string =>
    item.id ? item.id : `i${index}`;

  handleCommand(command: PropertyCommand, event: CommandEvent): void {
    if (command.parameters?.length) {
      this.saveFocusProperty(event.property);
      this.customActionPane.show(command, event);
    } else {
      this.command.emit(event);
    }
  }

  private saveFocusProperty(property: AnyProperty): void {
    const index = this.filteredProperties.indexOf(property);
    this.focusProperty =
      index >= 0
        ? this.elementRef.nativeElement.querySelectorAll('.row > *').item(index)
        : undefined;
  }

  refocus(): void {
    focusFirstFocusable(this.focusProperty);
  }

  private filterProps(): void {
    const filterLower = this.filter?.toLocaleLowerCase();
    this.filteredIndexes = [];
    this.filteredProperties = filterLower
      ? this.properties.filter((prop, index) => {
          this.filteredIndexes.push(index);
          return prop.name?.toLocaleLowerCase().includes(filterLower);
        })
      : this.properties;
    if (!this.filteredIndexes.length) {
      this.filteredIndexes = Array.from(Array(this.properties.length).keys());
    }
    this.filtered.emit(this.filteredProperties?.length ?? 0);
  }

  public showPropertyInfoPopover(dataPointPropertyInfo: PropertyInfoData): void {
    this.siPropertyPopoverService.showPropertyInfoDialog(dataPointPropertyInfo);
  }
}
