import { WildcardBACnet } from '../components/si-bacnet-datetime-picker/models/enums';
import { DateFormat } from '../interfaces/date-time-formats';

// Implements https://simpl.code.siemens.io/simpl-element/architecture/common-value-types/#date
export const dateString = (
  value: string = '',
  format: DateFormat,
  months?: { id: number; name: string }[],
  days?: { id: number; name: string }[]
): string => {
  const pad = (i: string = '*', size: number): string => (i === '*' ? i : i.padStart(size, '0'));
  if (isWeekSpecified(value)) {
    const lastIndexSpace = value.lastIndexOf(' ');
    value = value.slice(0, lastIndexSpace);
  }
  const splitVal = value.split('-').map(i => i || '*');
  const yyyy = splitVal[0];
  let mm = splitVal[1];
  let dd = splitVal[2];

  const month = WildcardBACnet[Number(mm)];
  if (month) {
    const val = months?.find(element => element.id === Number(mm))?.name;
    if (val) {
      mm = val;
    }
  }

  const day = WildcardBACnet[Number(dd)];
  if (day) {
    const val = days?.find(element => element.id === Number(dd))?.name;
    if (val) {
      dd = val;
    }
  }

  return format.replace('dd', pad(dd, 2)).replace('mm', pad(mm, 2)).replace('yyyy', pad(yyyy, 4));
};

export const dateToIsoString = (date: Date): string => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();
  month = month.padStart(2, '0');
  day = day.padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const parseBacnetDateTimeFormat = (dateTime: string): string | undefined => {
  const [date, time] = dateTime.split('T');
  if (date) {
    const weekNum = date.split(' ').slice(-1)[0];

    const lastIndexSpace = date.lastIndexOf(' ');
    if (!Number.isNaN(Number(weekNum))) {
      // week is specified
      const dateNoWeek = date.slice(0, lastIndexSpace);
      if (!time && dateNoWeek) {
        return dateNoWeek;
      }
      if (dateNoWeek) {
        return dateNoWeek + 'T' + time;
      }
    } else {
      return dateTime;
    }
  }
  return undefined;
};

export const isWeekSpecified = (date: string): boolean => {
  const weekNum = date.split(' ').slice(-1)[0];
  return !Number.isNaN(Number(weekNum)) || weekNum === '*';
};
