import { Directive } from '@angular/core';

import { SiSelectOptionRow } from './si-select.types';

@Directive({
  selector: '[siSelectOptionRowTemplate]',
  standalone: true
})
export class SiSelectOptionRowTemplateDirective {
  static ngTemplateContextGuard<T = any>(
    directive: SiSelectOptionRowTemplateDirective,
    context: unknown
  ): context is { $implicit: SiSelectOptionRow<T> } {
    return true;
  }
}
