<ng-content />

@for (gutter of gutters; track i; let i = $index) {
  @if (i < parts.length - 1) {
    <div
      class="si-split-gutter"
      [class.d-none]="!gutter.visible()"
      [style.grid-area]="'g-' + i"
      [style.width.px]="orientation === 'horizontal' ? gutterSize : null"
      [style.height.px]="orientation === 'vertical' ? gutterSize : null"
      (mousedown)="resizeStart(gutter, $event)"
      (touchstart)="resizeStart(gutter, $event)"
    ></div>
  }
}
