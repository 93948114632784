import { Injectable } from "@angular/core";
import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointAttributes, PointValue } from "src/app/bx-services/point/point-proxy.model";

import { DccDataType, PropertyMapper } from "./property-mapper";
import { textGroupIdStatusFlags } from "./text-group-bx.service";

// do not change this name, it is a well known name for this property and used in some viewers as well!
export const aggregatedSummaryPropertyName = 'StatusPropagation.AggregatedSummaryStatus';

@Injectable({
  providedIn: 'root'
})
export class SummaryStatusMapperService extends PropertyMapper {

  /* eslint-disable @typescript-eslint/no-useless-constructor */
  constructor(qudtPipe: SiqudtUnitConversionPipe) {
    super(qudtPipe);
  }
  /* eslint-enable @typescript-eslint/no-useless-constructor */

  public createAggregatedSummary(order: number): PropertyDetails {
    // Remark: Adjust code of system browser and textual viewer in order to remove this property.

    // const txgStatusFlags = this.textGroupOmService.getGlobalTextGroup(textGroupIdStatusFlags);
    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: aggregatedSummaryPropertyName,
      Descriptor: 'Summary Status',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: undefined,
      Type: DccDataType.ExtendedEnum,
      Value: undefined,
      Min: undefined,
      Max: undefined,
      UnitDescriptor: undefined,
      UnitId: undefined,
      Usage: 0, // Do not show in properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      DisplayOffNormalOnly: false,
      NormalValue: undefined,
      TextTable: textGroupIdStatusFlags, // TxG_PropagationSummaryStatus for DCC
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return propDetails;
  }

  public createAggregatedSummaryValue(
    subGms: SubscriptionGmsVal, pointValue: PointValue, pointAttributes: PointAttributes, hide: boolean = false): ValueDetails | undefined {

    // TODO: finalize quality bits
    let displayValue = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        displayValue = 'Normal';
      }
      if (displayValue === undefined) {
        displayValue = pointValue.value;
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }

    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: DccDataType.BasicUint,
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        Value: '0',
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0' // TODO: check the usage
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
