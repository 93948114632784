import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsAdornerType } from '../types/gms-adorner-types';
import { Utility } from '../utilities/utility';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-adorner]',
  template: `<svg:rect #container
                    [attr.visibility]="CalculateVisible()"
                    [attr.x]="x"
                    [attr.y]="y"
                    [attr.width]="width"
                    [attr.height]="height"
                    [attr.stroke]="adorner.Stroke"
                    [attr.stroke-width]="strokeWidth"
                    [attr.stroke-dasharray]="adorner.StrokeDashArray"
                    stroke-opacity= "1"
                    vector-effect="non-scaling-stroke"
                    [attr.fill-opacity]="0"
                    [attr.rx]="3"
                    [attr.ry]="3">
                    >
               </svg:rect>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsAdornerComponent extends GmsElementComponent implements OnInit, OnDestroy {
  public static readonly ADORNER_OFFSET: number = 5;
  public static readonly ADORNER_OUTLINE_WIDTH: number = 2;
  public static readonly ADORNER_MAX_WIDTH: number = 30;
  public static readonly ADORNER_MIN_WIDTH: number = 2;

  public height: number;
  public width: number;
  public x = 0;
  public y = 0;

  public outlineWidth: number = GmsAdornerComponent.ADORNER_OUTLINE_WIDTH;
  public selected: boolean;
  public offset: number = GmsAdornerComponent.ADORNER_OFFSET;
  public strokeWidth = 2;

  @Input() public adorner: GmsAdorner = null;

  @ViewChild('container', { static: true }) private readonly adornerContainerRef: ElementRef;
  private selectionSubscription: Subscription;

  private svgroot: SVGSVGElement;
  private adornerContainer: SVGGElement;
  private sourceElement: SVGElement;
  private locatableElement: SVGGraphicsElement;
  private svgZoomTransFormGroup: SVGGElement;
  private referenceSourceElementTransformMatrix: SVGMatrix;

  public ngOnInit(): void {
    this.sourceElement = document.getElementById(this.adorner.SourceElement.Id) as any as SVGElement;
    this.selected = this.adorner.AdornerType === GmsAdornerType.Selection;

    this.adornerContainer = this.adornerContainerRef?.nativeElement as SVGGElement;
    // this.adorner.StrokeWidth = GmsAdornerComponent.ADORNER_STROKE_WIDTH;

    this.locatableElement = this.sourceElement as any as SVGGraphicsElement;
    // let strokeWidth: number = +this.adorner.SourceElement.StrokeWidth;

    // the top-level svg element
    this.svgroot = this.locatableElement?.viewportElement as SVGSVGElement;

    // the graphic component container for child elements, affected by zooming and panning
    this.svgZoomTransFormGroup = this.svgroot?.getElementById('ContentGroup') as SVGGElement;

    this.UpdateAdorner();

    this.selectionSubscription = this.adorner.SourceElement.propertyChanged.pipe(debounceTime(10))
      .subscribe(value => this.UpdateAdorner());
  }

  public ngOnDestroy(): void {
    this.selectionSubscription?.unsubscribe();
    this.sourceElement = undefined;
    this.adornerContainer = undefined;
  }

  public CalculateVisible(): string {
    if (this.sourceElement === undefined) {
      return 'hidden';
    }

    if (this.sourceElement !== undefined && !Utility.isDomVisible(this.sourceElement)) {
      return 'hidden';
    }

    return this.adorner.SourceElement.GetVisible();
  }

  private async UpdateAdorner(): Promise<any> {
    if (this.sourceElement == null) {
      return;
    }

    // Get transform matrix of source element. Contains zoom/pan transformations
    const originalTransform: SVGMatrix = this.locatableElement.getCTM();

    if (this.svgZoomTransFormGroup !== null) {
      // cancel duplicated zoom/pan transformations
      this.referenceSourceElementTransformMatrix = this.svgZoomTransFormGroup.getCTM().inverse().multiply(originalTransform);
    } else { // Incase of inclusive adorners ( Replication Container )
      this.referenceSourceElementTransformMatrix = originalTransform;
      this.strokeWidth = 1; // 1px incase of adorner
    }

    // all transformations from root to source element
    const transform: SVGTransform = this.svgroot.createSVGTransformFromMatrix(this.referenceSourceElementTransformMatrix);

    this.width = this.adorner.SourceElement.AdornerWidth + GmsAdornerComponent.ADORNER_OFFSET * 2;
    this.height = this.adorner.SourceElement.AdornerHeight + GmsAdornerComponent.ADORNER_OFFSET * 2;
    this.x = this.adorner.SourceElement.AdornerX - GmsAdornerComponent.ADORNER_OFFSET;
    this.y = this.adorner.SourceElement.AdornerY - GmsAdornerComponent.ADORNER_OFFSET;

    this.changeDetector.detectChanges();

    this.adornerContainer.transform.baseVal.initialize(transform);
  }

}
