import { NgModule } from '@angular/core';
import { SiTranslateModule, SiTranslateServiceBuilder } from '@simpl/element-ng/translate';

import { SiTranslateNgxTServiceBuilder } from './si-translate-ngxt.service-builder';

/**
 * This module configures Element to use ngx-translate for translating {@link TranslatableString}
 * It should only be imported once in an applications root module (typically `app.module.ts`)
 */
@NgModule({
  providers: [{ provide: SiTranslateServiceBuilder, useClass: SiTranslateNgxTServiceBuilder }],
  imports: [SiTranslateModule]
})
export class SiTranslateNgxTModule {}
