<div class="modal-header">
    <span class="modal-title">{{ title }}</span>
    <button
      type="button"
      class="btn btn-circle btn-sm btn-ghost element-cancel"
      aria-label="Close"
      (click)="onDecline()"
    > </button>
  </div>
  <div class="modal-body-container">
    <div class="modal-body text-pre-wrap text-break">{{ saveLayoutMsg }}</div>
    <label class="form-check-label ms-7">
        <input class="form-check-input me-4" type="checkbox" [(ngModel)]="checkboxClicked">{{ enforceLayoutMsg }}
    </label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onDecline()">{{ cancel }}</button>
    <button *ngIf="checkboxClicked" type="button" class="btn btn-primary" (click)="onConfirmEnforce()">{{ save }}</button>
    <button *ngIf="!checkboxClicked" type="button" class="btn btn-primary" (click)="onConfirmNoEnforce()">{{ save }}</button>
  </div>
  