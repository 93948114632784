import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { ToastStateName } from '@simpl/element-ng';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TraceChannel } from '../common/trace-channel';
import { GmsAdorner } from '../elements/gms-adorner';
import { GmsCommandControl } from '../elements/gms-commandcontrol';
import { GmsCommandControlSlider } from '../elements/gms-commandcontrol-slider';
import { GmsCommandControlSpinner } from '../elements/gms-commandcontrol-spinner';
import { GmsElement } from '../elements/gms-element';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsCommandResult } from '../processor/command-view-model/gms-command-vm';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsCommandTriggerTypes } from '../types/gms-command-trigger-types';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementDisabledStyle, GmsElementType } from '../types/gms-element-types';
import { MouseMode } from '../types/gms-graphics-mouse-mode-types';
import { Utility } from '../utilities/utility';

@Component({
  // selector: "[gmsElement]",
  template: `<svg:g>
                    <xhtml:p>{{Type}}</xhtml:p>
              </svg:g>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmsElementComponent implements OnInit, OnDestroy, AfterViewInit {

  protected traceModule: string = TraceChannel.Model;

  // variables related to double-click workaround
  protected preventSingleClick = false;
  protected clickInFlight = false;
  protected timer: any;
  protected singleClickDelay = 250;

  protected adornerElement: GmsAdorner = null;
  protected hoverElement: GmsAdorner = null;

  public get Type(): string {
    return 'GmsElementComponent';
  }

  constructor(public changeDetector: ChangeDetectorRef,
    public adornerService: GmsAdornerService) {
    this.unsubscribe = new Subject<void>();
  }

  protected SubscribePropertyChanged(element: GmsElement): void {
    // subscribe to the property changes of the element
    this.propertyChangedSub = element.propertyChanged.subscribe(value => {
      this.element_PropertyChanged(value);
    });
  }

  protected SelectUnselectElement(isSelected: boolean): void {
    this.changeDetector.detectChanges();
    if (isSelected && this.adornerElement == null) {
      this.adornerElement = this.adornerService.createSelectionAdorner(this.element);
    } else if (!isSelected) {
      this.adornerService.RemoveAdorner(this.adornerElement);
      this.adornerElement = null;
    }
  }

  protected UnSubscribePropertyChanged(element: GmsElement): void {
    // subscribe to the property changes of the element
    this.propertyChangedSub.unsubscribe();
  }

  protected element_PropertyChanged(propertyName: string): void {
    // Let Angular know that a property has changed
    this.changeDetector.detectChanges();
  }

  public element: GmsElement;
  public unsubscribe: Subject<void>;
  public propertyChangedSub: Subscription = undefined;
  public commandControlType: any = GmsCommandControlType;
  public elementDisabledStyle: any = GmsElementDisabledStyle;

  // hover adorner enabled
  public hover = false;

  private _spinner: GmsCommandControlSpinner = null;

  @HostListener('mousedown', ['$event'])
  public OnMouseDown(e: any): void {
    ((this.element?.Graphic) as any)?.closePopover?.next();
    const slider: GmsCommandControlSlider = this.element !== undefined && !this.element.IsSlidingClone ?
      this.element.SliderControl as GmsCommandControlSlider : undefined;
    if (slider !== undefined && slider.CommandVM.IsCommandEnabled &&
            (slider.IsCommandableStandalone || slider.IsControlGrouped)) {
      e.stopPropagation();
      const graphic: GmsGraphic = this.element.Graphic as GmsGraphic;
      if (graphic !== undefined && graphic.mouseMode === MouseMode.None) {
        if (e.button === 0 || e.touches !== undefined) {
          graphic.mouseDown = true;
          graphic.mouseMode = MouseMode.Slide;
          slider.StartSliding(e.button !== undefined ? e.clientX : e.touches[0].clientX,
            e.button !== undefined ? e.clientY : e.touches[0].clientY);
        }
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  public OnMouseUp(e: any): void {
    const slider: GmsCommandControlSlider = this.element !== undefined ?
      this.element.SliderControl as GmsCommandControlSlider : undefined;
    if (slider !== undefined && slider.IsSliding) {
      event.stopPropagation();
      const graphic: GmsGraphic = this.element.Graphic as GmsGraphic;
      graphic.mouseMode = MouseMode.None;
      graphic.mouseDown = false;

      const x: number = e.button !== undefined ? e.clientX : e.changedTouches[0].clientX;
      const y: number = e.button !== undefined ? e.clientY : e.changedTouches[0].clientY;

      slider.EndSliding(x, y);
    } else if (slider === undefined && e.changedTouches !== undefined) {
      this.OnMouseClick(e);
    }
  }

  // includes workaround to enable single/double click handlers
  // on same Element
  // src: https://stackoverflow.com/questions/48295288
  public OnMouseClick(event: any): void {
    if (this.element === undefined) {
      return;
    }

    let ctrlKeyPressed = false;
    if (event.ctrlKey) {
      ctrlKeyPressed = event.ctrlKey;
    }

    // Parent's visibility is inherited
    // Check the actual Dom visibility
    if (event.srcElement !== undefined && !Utility.isDomVisible(event.srcElement)) {
      return; // permit no interaction
    }

    if (this.element.IsSelectable || this.element.IsTargetNavigable) {
      event.stopPropagation();
    }
    // avoid timer for the spinner, if TriggerType is a SingleClick
    if (this.element.IsSpinButton() &&
            this.GetControlSpinner(this.element).GetAnimatedCommandTriggerType() === GmsCommandTriggerTypes.SingleClick) {
      this.ProcessSpinnerCommand(this.element);
      return;
    }

    const isSafari: boolean = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');

    // Only allow click event(s) to invoke single/doubleclick handlers.
    // exception: SpinnerCommandControl
    if (!this.element.IsSpinButton()
          && event.type === 'touchend'
          && !isSafari) {
      return;
    }

    if (this.clickInFlight) {
      this.clickInFlight = false;
      clearTimeout(this.timer);
      this.OnDoubleClick(event);
    } else {
      this.clickInFlight = true;
      this.timer = setTimeout(() => this.singleClickDelayCallback(ctrlKeyPressed), this.singleClickDelay);
    }
  }

  public processTargetNavigation(element: GmsElement): GmsElement {
    let iterElement: GmsElement = element;
    while (iterElement.Parent !== undefined) {
      // 1. check for command
      if (iterElement.CommandVM !== null) { // && iterElement.IsCommandTriggerEnabled)
        if (iterElement.CanExecuteCommand || iterElement.IsSpinButton()) {
          return iterElement;
        } else {
          const spinner: GmsCommandControlSpinner = iterElement.Parent as GmsCommandControlSpinner;
          if (spinner !== undefined) {
            return iterElement.Parent;
          }
        }
      }
      // 2. no command, but target
      if (iterElement.IsTargetNavigable) {
        return iterElement;
      } else if (iterElement.IsSelectable) {
        return iterElement;
      }

      iterElement = iterElement.Parent;
    }

    return iterElement;
  }

  public OnDoubleClick(event: any): void {

    const commandElement: GmsElement = this.processTargetNavigation(this.element);

    if (commandElement.IsSpinButton() &&
            this.GetControlSpinner(commandElement).GetAnimatedCommandTriggerType() === GmsCommandTriggerTypes.DoubleClick) {
      this.ProcessSpinnerCommand(commandElement);
      return;
    }
    if (commandElement.CanExecuteCommand) {
      this.ProcessCommand(commandElement);
      return;
    }
    event.stopPropagation();
    if (commandElement.IsSelectable) {
      // Defect 1087751
      commandElement.processSelection();
      commandElement.processNavigation();
    }
    if (commandElement.IsTargetNavigable && commandElement.GetAnimatedCommandTriggerType() === GmsCommandTriggerTypes.DoubleClick) {
      commandElement.processTargetNavigation();
    }
  }

  public subscribeForSlidingUpdate(): void {

  }

  public ngOnInit(): void {
    // slider
    const slider: GmsCommandControlSlider = this.element !== undefined ?
      this.element.SliderControl as GmsCommandControlSlider : undefined;

    if (slider !== undefined && !this.element.IsSlidingClone) {
      const graphic: GmsGraphic = this.element.Graphic as GmsGraphic;
      if (graphic !== undefined) {
        graphic.sliding.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
          this.SlidingUpdate(value);
        });
      }
    }

    this.SubscribePropertyChanged(this.element);
    this.element.UpdateDisableSelection();
    this.element.UpdateHasDatapoints();
    this.element.UpdateIsSelectable();
    this.element.UpdateIsHitTestVisible();
    this.element.setInitialSelection();
    this.element.updateChildrenDisableSelection();

    if (!this.element.IsSlidingClone && this.element.Type !== GmsElementType.CommandControl) {
      // If selected state is already set
      if (this.element.IsSelectable && this.element.IsSelected) {
        this.SelectUnselectElement(this.element.IsSelected);
      }

      this.element.selectionChanged.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
        this.SelectUnselectElement(value);
      });
    }
  }

  /**
   * @NOTE:
   * After the view is initialized for an element component, the change detector will be triggered
   * to fix the positioning and scaling of not zoomable elements.
   */
  public ngAfterViewInit(): void {
    if (this.element?.Zoomable === false) {
      this.changeDetector.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.UnSubscribePropertyChanged(this.element);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('touchstart', ['$event'])
  public OnTouchStart(event: any): void {
    this.OnMouseDown(event);
  }

  @HostListener('touchend', ['$event'])
  public OnTouchEnd(event: any): void {
    this.OnMouseUp(event);
  }

  public OnMouseEnter(event: any): void {
    // Parent's visibility is inherited
    // Check the actual Dom visibility
    if (event.srcElement !== undefined && !Utility.isDomVisible(event.srcElement)) {
      return; // permit no interaction
    }

    if (!isNullOrUndefined(this.element)) {
      const graphic: GmsGraphic = this.element.Graphic as GmsGraphic;
      graphic.HoverTooltipElement = this.element;
    }

    if (!isNullOrUndefined(this.element) && (this.element.IsSelectable || this.element.IsTargetNavigable) && !(this.element instanceof GmsCommandControl)) {

      if (this.hoverElement === null && !this.element.IsSelected) {
        this.hoverElement = this.adornerService.createHoverAdorner(this.element);
      }
      this.changeDetector.detectChanges();
    }
  }

  public OnMouseLeave(event: any): void {
    const slider: GmsCommandControlSlider = this.element !== undefined ?
      this.element.SliderControl as GmsCommandControlSlider : undefined;

    const gmsGraphic: GmsGraphic = this.element !== undefined ?
      (this.element.Graphic as GmsGraphic) : undefined;

    const mouseDown: boolean = gmsGraphic !== undefined ? gmsGraphic.mouseDown : false;

    if (!isNullOrUndefined(this.element) && !isNullOrUndefined(gmsGraphic)) {
      gmsGraphic.HoverTooltipElement = undefined;
    }

    if (slider !== undefined && slider.IsSliding && !mouseDown) {
      event.stopPropagation();
      slider.EndSliding(event.clientX, event.clientY);
    } else {
      if (this.hoverElement !== null) {
        this.adornerService.RemoveAdorner(this.hoverElement);
        this.hoverElement = null;
        // Q: why do we need a  changeDetector here..
        this.changeDetector.detectChanges();
      }
    }
  }

  @HostListener('mouseout', ['$event'])
  public OMouseout(event: any): void {
    this.OnMouseLeave(event);
  }

  /**
   * @NOTE:
   * This code causes issues on touch end, such as triggering unnecessary double clicks.
   *
   *  @HostListener("touchend", ["$event"])
   *  public OnTouchEnd(e: any): void {
   *      this.OnMouseClick(e);
   *  }
   */

  public showToastNotification(state: ToastStateName, title: string, message: string): void {
    if (this.element !== undefined && this.element.ToastNotificationService !== undefined) {
      this.element.ToastNotificationService.queueToastNotification(state, title, message);
    }
  }
  // Introduced for better profiling
  private singleClickDelayCallback(ctrlKeyPressed: boolean): void {

    if (this.clickInFlight) {
      this.clickInFlight = false;

      const desiredElement: GmsElement = this.processTargetNavigation(this.element);
      if (desiredElement.CanExecuteCommand && desiredElement.GetAnimatedCommandTriggerType() === GmsCommandTriggerTypes.SingleClick) {
        const slider: GmsCommandControlSlider = this.element !== undefined && !this.element.IsSlidingClone ?
          this.element.SliderControl as GmsCommandControlSlider : undefined;
        if (slider === undefined) {
          // ignore slider
          this.ProcessCommand(desiredElement);
        }
      } else if (desiredElement.IsTargetNavigable && desiredElement.GetAnimatedCommandTriggerType() === GmsCommandTriggerTypes.SingleClick) {
        if (desiredElement.IsSelectable) {
          // Defect 1087751
          desiredElement.processSelection(ctrlKeyPressed);
        }
        desiredElement.processTargetNavigation();
      } else {
        desiredElement.processSelection(ctrlKeyPressed);
      }
    }
  }

  private GetControlSpinner(element: GmsElement): GmsCommandControlSpinner {
    if (this._spinner == null) {
      this._spinner = GmsCommandControlSpinner.getSpinnerFromButton(element);
    }
    return this._spinner;
  }

  private ProcessSpinnerCommand(element: GmsElement): void {
    const spinner: GmsCommandControlSpinner = this.GetControlSpinner(element);
    if (spinner !== undefined) {
      spinner.UpDownButton_Click(element);
    }
  }

  private SlidingUpdate(e: any): void {
    const slider: GmsCommandControlSlider = this.element !== undefined && !this.element.IsSlidingClone ?
      this.element.SliderControl as GmsCommandControlSlider : undefined;
    if (slider !== undefined && slider.IsSliding) {
      if (e === null) {
        slider.CancelSliding();
      } else {
        const x: number = e.button !== undefined ? e.clientX : e.changedTouches[0].clientX;
        const y: number = e.button !== undefined ? e.clientY : e.changedTouches[0].clientY;
        slider.Slide(x, y);
      }
    }
  }

  private ProcessCommand(desiredElement: GmsElement): void {
    if (desiredElement.CommandVM === null) {
      desiredElement.Graphic.TraceService.error(this.traceModule, 'Command Execute failed', 'Command View Model is NULL');
      return;
    }
    if (!desiredElement.CommandVM.IsInputValid(this.element.locale)) {
      this.showToastNotification('warning', 'Command Execute failed: Invalid input',
        desiredElement.CommandVM.ErrorDescription);
      return;
    }

    // reset the buttons and editors controls:
    // desiredElement.CommandVM.isModified = false;
    desiredElement.CommandVM.isCommandExecuteDone = false;
    desiredElement.ExecuteCommand().subscribe(
      (res: GmsCommandResult) => {
        if (res.Success) {
          // reset controls (numeric, string, password)
          desiredElement.CommandVM.isCommandExecuteDone = true;
          desiredElement.CommandVM.isModified = false;
          const msg = `Command executed with success: ${this.element.CommandVM.Key}, Name = ${this.element.CommandVM.Name}`;
          desiredElement.Graphic.TraceService.info(this.traceModule, 'Command executed', msg);
        } else {
          desiredElement.CommandVM.isCommandExecuteDone = true;
          desiredElement.CommandVM.isModified = false;
          this.showToastNotification('warning', 'Command Execute Failed', res.Error);
        }
      },
      error => {
        desiredElement.CommandVM.isCommandExecuteDone = true;
        desiredElement.CommandVM.isModified = false;
        this.showToastNotification('warning', 'Command Execute failed', error.message);
      }
    );
  }
}
