<div class="app-icon">
  <ng-content select="[app-icon]" />
</div>
<div class="si-title-2">
  <ng-content select="[app-name]" />
  @if (external) {
    <i class="icon element-export my-n3" role="presentation"></i>
  }
</div>
@if (enableFavoriteToggle) {
  <i
    role="presentation"
    class="icon favorite-icon"
    [class.element-favorites-filled]="favorite"
    [class.element-favorites]="!favorite"
    (click)="favoriteClicked($event)"
  ></i>
}
