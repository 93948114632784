import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AlarmConfigurationServiceBase, APP_CONFIGURATION_TOKEN, AppRightsServiceProxyBase,
  AuthenticationService, CommandSubscriptionProxyServiceBase, EventCategoriesProxyServiceBase,
  EventCounterProxyServiceBase, EventFilterServiceBase, EventNotesProxyService, EventNotesProxyServiceBase, EventProxyServiceBase, EventSoundProxyServiceBase,
  ExecuteCommandServiceBase, GraphicsService, GraphicsServiceBase, LanguageService, LicenseOptionsProxyServiceBase, LicenseProxyServiceBase,
  MultiMonitorServiceBase,
  ObjectsServiceBase, OwnershipServiceBase,
  PropertyServiceBase, ReadCommandServiceBase, SeverityServiceBase, SignalRService, SuppressedObjectsProxyServiceBase, SystemBrowserServiceBase,
  SystemBrowserSubscriptionProxyServiceBase, SystemsProxyServiceBase, TablesServiceBase, TrendServiceBase, UserRolesServiceProxyBase,
  ValidationHelperService, ValueSubscriptionProxyServiceBase
} from '@gms-flex/services';
import { AuthenticationServiceBase, LanguageServiceBase, SettingsServiceBase } from '@gms-flex/services-common';
import { EventInfoSettingsServiceBase, EventListSettingsServiceBase } from '@gms-flex/snapin-common';
import { SummaryBarSnapInServiceBase } from '@gms-flex/summary-bar';
import { TrendEvalutionServiceBase } from '@gms-flex/trend';

import { AlarmConfigurationSubstituteService } from './alarm-configuration/alarm-configuration-substitute.service';
import { AppRightsBxSubstituteProxyService } from './app-rights/app-rights-bx-substitute-proxy.service';
import { AuthenticationBxSubstituteService } from './authentication/authentication-bx-substitute.service';
import { CommandSubscriptionBxSubstituteProxyService } from './command/command-subscription-bx-substitute-proxy.service';
import { ExecuteCommandBxSubstituteService } from './command/execute-command-bx-substitute.service';
import { ReadCommandBxSubstituteService } from './command/read-command-bx-substitute.service';
import { EventBxSubstituteProxyService } from './event/event-bx-substitute-proxy.service';
import { EventCategoriesBxSubstituteProxyService } from './event/event-categories-bx-substitute-proxy.service';
import { EventCounterBxSubstituteProxyService } from './event/event-counter-bx-substitute-proxy.service';
import { EventFilterBxSubstituteService } from './event/event-filter-bx-substitute.service';
import { EventInfoSettingsBxSubstituteService } from './event/event-info-settings-bx-substitute.service';
import { EventListSettingsBxSubstituteService } from './event/event-list-settings-bx-substitute.service';
import { EventNotesBxSubstituteProxyService } from './event/event-notes-bx-substitute-proxy.service';
import { EventSoundBxSubstituteProxyService } from './event/event-sound-bx-substitute-proxy.service';
import { SeverityBxSubstituteService } from './event/severity-bx-substitute.service';
import { SummaryBarBxSubstituteService } from './event/summary-bar-bx-substitute.service';
import { SuppressedObjectsBxSubstituteProxyService } from './event/suppressed-objects-bx-substitute-proxy.service';
import { GraphicsBxSubstituteService } from './graphics/graphics-bx-substitute.service';
import { LanguageBxSubstituteService } from './language/language-bx-substitute.service';
import { LicenseOptionsBxSubstituteProxyService } from './license/license-options-bx-substitute-proxy.service';
import { LicenseSubscriptionBxSubstituteProxyService } from './license/license-subscription-bx-substitute-proxy.service';
import { MultiMonitorBxSubstituteService } from './multi-monitor/multi-monitor-bx-substitute.service';
import { ObjectsBxSubstituteService } from './objects/objects-bx-substitute.service';
import { OwnershipBxSubstituteService } from './ownership/ownership-bx-substitute.service';
import { PropertyBxSubstituteService } from './properties/property-bx-substitute.service';
import { SettingsBxSubstituteService } from './settings/settings-bx-substitute.service';
import { ModeInterceptor } from './shared/mode-interceptor';
// import { QueryCountInterceptorService } from './shared/query-count-interceptor.service';
import { SignalRBxSubstituteService } from './signalr/signalr-bx-substitute.service';
import { SystemBrowserBxSubstituteService } from './system-browser/system-browser-bx-substitute.service';
import { SystemBrowserSubscriptionBxSubstituteProxyService } from './system-browser/system-browser-subscription-bx-substitute-proxy.service';
import { SystemsBxSubstituteProxyService } from './systems/systems-bx-substitute-proxy.service';
import { TablesBxSubstituteService } from './tables/tables-bx-substitute.service';
import { TestDelegateOmService } from './test-delegate-om.service';
import { TrendBxSubstituteService } from './trend/trend-bx-substitute.service';
import { TrendEvalutionBxSubstituteService } from './trend/trend-evaluation-bx-substitute.service';
import { UserRolesBxSubstituteProxyService } from './user-roles/user-roles-bx-substitute.proxy.service';
import { ValidationHelperBxSubstituteService } from './validation/validation-helper-bx-substitute.service';
import { ValueSubscriptionBxSubstituteProxyService } from './value-subscriptions/value-subscription-bx-substitute-proxy.service';

/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ModeInterceptor, multi: true },
    SignalRBxSubstituteService,
    { provide: SignalRService, useExisting: SignalRBxSubstituteService },
    // { provide: HTTP_INTERCEPTORS, useClass: QueryCountInterceptorService, multi: true }, // enable only for analytics while development!!!
    AppRightsBxSubstituteProxyService,
    { provide: AppRightsServiceProxyBase, useExisting: AppRightsBxSubstituteProxyService },
    AuthenticationBxSubstituteService,
    { provide: AuthenticationServiceBase, useExisting: AuthenticationBxSubstituteService },
    // TODO: Fix snapins which inject the service with the AuthenticationService class as provider token
    { provide: AuthenticationService, useExisting: AuthenticationBxSubstituteService },
    CommandSubscriptionBxSubstituteProxyService,
    { provide: CommandSubscriptionProxyServiceBase, useExisting: CommandSubscriptionBxSubstituteProxyService },
    EventCategoriesBxSubstituteProxyService,
    { provide: EventCategoriesProxyServiceBase, useExisting: EventCategoriesBxSubstituteProxyService },
    EventCounterBxSubstituteProxyService,
    { provide: EventCounterProxyServiceBase, useExisting: EventCounterBxSubstituteProxyService },
    EventNotesBxSubstituteProxyService,
    { provide: EventNotesProxyServiceBase, useExisting: EventNotesBxSubstituteProxyService },
    { provide: EventNotesProxyService, useExisting: EventNotesBxSubstituteProxyService },
    ExecuteCommandBxSubstituteService,
    { provide: ExecuteCommandServiceBase, useExisting: ExecuteCommandBxSubstituteService },
    GraphicsBxSubstituteService,
    { provide: GraphicsServiceBase, useExisting: GraphicsBxSubstituteService },
    { provide: GraphicsService, useExisting: GraphicsBxSubstituteService },
    SystemsBxSubstituteProxyService,
    { provide: SystemsProxyServiceBase, useExisting: SystemsBxSubstituteProxyService },
    SettingsBxSubstituteService,
    { provide: SettingsServiceBase, useExisting: SettingsBxSubstituteService },
    SystemBrowserBxSubstituteService,
    { provide: SystemBrowserServiceBase, useExisting: SystemBrowserBxSubstituteService },
    ObjectsBxSubstituteService,
    { provide: ObjectsServiceBase, useExisting: ObjectsBxSubstituteService },
    LanguageBxSubstituteService,
    { provide: LanguageServiceBase, useExisting: LanguageBxSubstituteService },
    { provide: LanguageService, useExisting: LanguageBxSubstituteService },
    PropertyBxSubstituteService,
    { provide: PropertyServiceBase, useExisting: PropertyBxSubstituteService },
    ValueSubscriptionBxSubstituteProxyService,
    { provide: ValueSubscriptionProxyServiceBase, useExisting: ValueSubscriptionBxSubstituteProxyService },
    EventBxSubstituteProxyService,
    { provide: EventProxyServiceBase, useExisting: EventBxSubstituteProxyService },
    EventSoundBxSubstituteProxyService,
    { provide: EventSoundProxyServiceBase, useExisting: EventSoundBxSubstituteProxyService },
    LicenseOptionsBxSubstituteProxyService,
    { provide: LicenseOptionsProxyServiceBase, useExisting: LicenseOptionsBxSubstituteProxyService },
    LicenseSubscriptionBxSubstituteProxyService,
    { provide: LicenseProxyServiceBase, useExisting: LicenseSubscriptionBxSubstituteProxyService },
    OwnershipBxSubstituteService,
    { provide: OwnershipServiceBase, useExisting: OwnershipBxSubstituteService },
    ReadCommandBxSubstituteService,
    { provide: ReadCommandServiceBase, useExisting: ReadCommandBxSubstituteService },
    SuppressedObjectsBxSubstituteProxyService,
    { provide: SuppressedObjectsProxyServiceBase, useExisting: SuppressedObjectsBxSubstituteProxyService },
    SystemBrowserSubscriptionBxSubstituteProxyService,
    { provide: SystemBrowserSubscriptionProxyServiceBase, useExisting: SystemBrowserSubscriptionBxSubstituteProxyService },
    // TODO: remove
    TestDelegateOmService,
    TablesBxSubstituteService,
    { provide: TablesServiceBase, useExisting: TablesBxSubstituteService },
    TrendBxSubstituteService,
    { provide: TrendServiceBase, useExisting: TrendBxSubstituteService },
    TrendEvalutionBxSubstituteService,
    { provide: TrendEvalutionServiceBase, useExisting: TrendEvalutionBxSubstituteService },
    UserRolesBxSubstituteProxyService,
    { provide: UserRolesServiceProxyBase, useExisting: UserRolesBxSubstituteProxyService },
    SeverityBxSubstituteService,
    { provide: SeverityServiceBase, useExisting: SeverityBxSubstituteService },
    SummaryBarBxSubstituteService,
    { provide: SummaryBarSnapInServiceBase, useExisting: SummaryBarBxSubstituteService },
    ValidationHelperBxSubstituteService,
    { provide: ValidationHelperService, useExisting: ValidationHelperBxSubstituteService },
    { provide: APP_CONFIGURATION_TOKEN, useValue: { aliasSupported: false } },
    EventListSettingsBxSubstituteService,
    { provide: EventListSettingsServiceBase, useExisting: EventListSettingsBxSubstituteService },
    MultiMonitorBxSubstituteService,
    { provide: MultiMonitorServiceBase, useExisting: MultiMonitorBxSubstituteService },
    AlarmConfigurationSubstituteService,
    { provide: AlarmConfigurationServiceBase, useExisting: AlarmConfigurationSubstituteService },
    EventFilterBxSubstituteService,
    { provide: EventFilterServiceBase, useExisting: EventFilterBxSubstituteService },
    EventInfoSettingsBxSubstituteService,
    { provide: EventInfoSettingsServiceBase, useExisting: EventInfoSettingsBxSubstituteService }
  ]
})
export class BxGmsMapperModule {
  // static forRoot(environmentConfig: EnvironmentConfig): ModuleWithProviders<OmServicesModule> {
  //   return {
  //     ngModule: OmServicesModule,
  //     providers: [
  //       EnvironmentService, { provide: 'environmentConfig', useValue: environmentConfig }
  //     ]
  //   };
  // }
}
/* eslint-enable @angular-eslint/sort-ngmodule-metadata-arrays */
