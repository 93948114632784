import { Component, DoCheck, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { BrowserObject } from '@gms-flex/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverContainerComponent } from 'ngx-bootstrap/popover';
import { MemoPopoverServiceBase } from './services/memo-popover.service.base';
import { MemoViewModelIfc } from './view-model/memo-vm.base';

@Component({
  selector: 'gms-memo-popover',
  templateUrl: './memo-popover.component.html'
  // styleUrls: [""]
})
export class MemoPopoverComponent implements OnInit, OnDestroy, DoCheck {

  @Input() public clientId: string;

  @Input() public object: BrowserObject;

  @Input() public excludePopoverContainer: boolean;

  @Input() public set popoverPlacement(val: string) {
    this.placement = this.placementAllowedValues.find(s => s === val);
  }
  public get popoverPlacement(): string {
    return this.placement || this.placementDefault;
  }

  @Input() public set isDisabled(val: boolean) {
    this.disabled = !!val;
    if (this.disabled) {
      this.isOpen = false; // force popover closed when disabled!
    }
  }

  @Output() public readonly openStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public readonly emptyStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public readonly memoSaveError: EventEmitter<string> = new EventEmitter<string>();

  public vm: MemoViewModelIfc;
  public excludePopover: boolean;
  public isOpen: boolean;

  private disabled: boolean;
  private allowCloseOnOutsideClick: boolean;
  private outsideClickListener: () => void;
  private placement: string;
  private readonly placementDefault: string = 'auto';
  private readonly placementAllowedValues: string[] = [
    this.placementDefault,
    'top', 'bottom', 'left', 'right',
    'top left', 'top right', 'left top', 'right top',
    'bottom left', 'bottom right', 'left bottom', 'right bottom'
  ];
  private destroyInd: Subject<void>;

  private static isElementOutside(popoverContainer: PopoverContainerComponent, targetEl: any): boolean {
    if (!(popoverContainer && targetEl)) {
      return false;
    }
    const popoverId: string = popoverContainer.popoverId;
    let el: any = targetEl;
    while (el) {
      if (popoverId && popoverId === el.id) {
        return false;
      }
      el = el.parentElement;
    }
    return true;
  }

  public constructor(
    private readonly ngZone: NgZone,
    private readonly renderer: Renderer2,
    private readonly memoPopoverService: MemoPopoverServiceBase) {

    this.destroyInd = new Subject<void>();
    this.allowCloseOnOutsideClick = true;
  }

  public ngOnInit(): void {
    // These input setting is treated as one-time bindings on initialization
    this.excludePopover = !!this.excludePopoverContainer;

    this.vm = this.memoPopoverService.registerViewModel(this.clientId, this.ngZone);

    this.vm.contextChanged
      .pipe(
        takeUntil(this.destroyInd))
      .subscribe(() => {
        this.isOpen = false; // force close on context change
      });

    this.emptyStateChanged.emit(this.vm.isMemoEmpty); // report initial value!
    this.vm.emptyStateChanged
      .pipe(
        takeUntil(this.destroyInd))
      .subscribe(isEmpty => {
        this.emptyStateChanged.emit(isEmpty);
      });
  }

  public ngDoCheck(): void {
    if (this.vm && this.object !== this.vm.context) {
      this.vm.setContext(this.object).subscribe();
    }
  }

  public ngOnDestroy(): void {
    this.unregisterOutsideClickListener();
    this.destroyInd.next();
    this.destroyInd.complete();
    this.destroyInd = undefined;
  }

  public onSaveResult(err: Error): void {
    if (err) {
      this.memoSaveError.emit(err.toString());
    }
  }

  public onDirtyStateChanged(isDirty: boolean): void {
    this.allowCloseOnOutsideClick = !isDirty;
  }

  public onShown(event: any): void {
    // Delay one-click to avoid the click event that triggered the popover to be shown
    // from being evaluated by the outside-click handler.  Otherwise, the popover will be
    // immediately closed (i.e., same click event will open and then close the popover)!
    setTimeout(() => this.registerOutsideClickListener(event as PopoverContainerComponent), 0);
    this.isOpen = true;
    this.openStateChanged.emit(this.isOpen);
  }

  public onHidden(): void {
    this.unregisterOutsideClickListener();
    this.vm.editCancel();
    this.isOpen = false;
    this.openStateChanged.emit(this.isOpen);
  }

  public onClick(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    if (this.isOpen && !this.allowCloseOnOutsideClick) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  private onOutsideClick(event: MouseEvent): void {
    if (this.isOpen && this.allowCloseOnOutsideClick) {
      this.isOpen = false;
    }
  }

  private registerOutsideClickListener(popoverContainer: PopoverContainerComponent): void {
    this.unregisterOutsideClickListener(); // to be sure
    this.outsideClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (MemoPopoverComponent.isElementOutside(popoverContainer, event.target)) {
        this.onOutsideClick(event);
      }
    });
  }

  private unregisterOutsideClickListener(): void {
    if (this.outsideClickListener) {
      this.outsideClickListener();
      this.outsideClickListener = undefined;
    }
  }

}
