import { ElementRef } from '@angular/core';

import { SiFormItemControl } from '../si-form-item.control';

/**
 * This will be used by the `si-form-item` to create a form item control if none is provided.
 * It allows accessing the control's id and type.
 * It is required to support the old style of the form item.
 */
export class SiFormFieldFallbackControl implements SiFormItemControl {
  private static idCounter = 0;

  static createForNearestInput(
    element: ElementRef<HTMLElement>
  ): SiFormFieldFallbackControl | undefined {
    const controlElement = element.nativeElement.querySelector<HTMLElement>(
      'input, meter, progress, select, textarea'
    );

    if (controlElement) {
      return new SiFormFieldFallbackControl(controlElement);
    }

    return undefined;
  }

  private constructor(private readonly element: HTMLElement) {
    if (!element.id) {
      element.id = `__si-form-field-control-fallback-${SiFormFieldFallbackControl.idCounter++}`;
    }

    if (element instanceof HTMLInputElement) {
      this.isFormCheck = element.type === 'checkbox' || element.type === 'radio';
    } else {
      this.isFormCheck = false;
    }
  }

  isFormCheck: boolean;

  get id(): string {
    return this.element?.id;
  }

  set id(value: string) {
    this.element.id = value;
  }
}
