import { Injectable } from '@angular/core';
import { EventNotesProxyServiceBase, TraceModules, WsiEventNote } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class EventNotesBxSubstituteProxyService extends EventNotesProxyServiceBase {

  public constructor(
    private readonly traceService: TraceService) {
    super();
    this.traceService.info(TraceModules.events, 'EventNotesBxSubstituteProxyService created.');
  }

  /**
   * Gets the saved event notes.
   */
  public getEventNotes(eventID: string): Observable<WsiEventNote> {
    this.traceService.info(TraceModules.events, `EventNotesBxSubstituteProxyService:getEventNotes() called with Event ID: ${eventID}`);

    // TODO: throw not immplemented error as soon as the client does not call this anymore!!!
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const note: WsiEventNote = { MessageText: '', Time: undefined, UserName: '' };
    return of(note);
    // return scheduled([note], asapScheduler);
  }

  /**
   * Sets the event notes.
   */
  public setEventNote(eventID: string, eventNotes: string): void {
    this.traceService.info(TraceModules.events, `EventNotesBxSubstituteProxyService:setEventNote() called with Event ID:  ${eventID}, note: ${eventNotes}`);
    throwError(() => new Error('EventNotesBxSubstituteProxyService.setEventNote(): Not Implemented!'));
  }

}
