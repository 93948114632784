import { Injectable } from '@angular/core';
import { ConnectionState, EventCounter, EventCounterList, EventCounterProxyServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject, throwError } from 'rxjs';

import { EventBx, EventCategoryBx, EventStateBx } from '../../bx-services/alarm/events-proxy.model';
import { EventBxSubstituteProxyService, EventResponseOfBuilding } from './event-bx-substitute-proxy.service';
import { EventCategoriesBxSubstituteProxyService } from './event-categories-bx-substitute-proxy.service';

@Injectable()
export class EventCounterBxSubstituteProxyService extends EventCounterProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _eventCounters: Subject<EventCounterList> = new Subject<EventCounterList>();
  private subscriberCount = 0;

  public constructor(
    private readonly traceService: TraceService,
    private readonly eventProxyService: EventBxSubstituteProxyService,
    private readonly eventCategoryProxyService: EventCategoriesBxSubstituteProxyService
  ) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered. There is no constant streaming channel.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.traceService.info(TraceModules.events, 'EventCounterBxSubstituteProxyService created.');
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  /**
   * Gets all event counters for all categories of the system.
   *
   */
  public getEventCountersAll(): Observable<EventCounterList> {
    this.traceService.info(TraceModules.events, 'getEventCountersAll() called.');

    return throwError(() => new Error('EventCounterBxSubstituteProxyService.getEventCountersAll(): Not Implemented!'));

  }

  /**
   * Gets the event counters for the specified category Id
   *
   */
  public getEventCounters(categoryId: number): Observable<EventCounter> {
    this.traceService.info(TraceModules.events, 'getEventCounters() called. categoryId: %s', categoryId);

    return throwError(() => new Error('EventCounterBxSubstituteProxyService.getEventCountersAll(): Not Implemented!'));
  }

  /**
   * Subscribes for all event counters of the system.
   *
   */
  public subscribeEventCounters(hiddenEvents = false): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventCounterBxSubstituteProxyService.subscribeEventCounters() called.');
    this.traceService.info(TraceModules.eventCounterTiming, 'EventCounterBxSubstituteProxyService.subscribeEventCounters() called.');

    if (this.subscriptionActive === false) {
      // we use the EventProxyService to notify the counters upon new events:
      this.eventProxyService.eventsNotificationBuildingX().subscribe(events => {
        this._eventCounters.next(this.calculateEventCounters(events));
      });
      this.eventProxyService.subscribeEvents().subscribe();
    }
    this.subscriberCount++;
    return scheduled([true], asapScheduler);
  }

  /**
   * Event for the event counter notifications.
   *
   */
  public eventCountersNotification(): Observable<EventCounterList> {
    return this._eventCounters.asObservable();
  }

  public unSubscribeEventCounters(): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventCounterBxSubstituteProxyService.unSubscribeEventCounters() called');

    if (this.subscriptionActive) {
      this.subscriberCount--;
    }
    return scheduled([true], asapScheduler);
  }

  private get subscriptionActive(): boolean {
    return (this.subscriberCount > 0);
  }

  private calculateEventCounters(eventsAll: EventResponseOfBuilding[]): EventCounterList {
    const startTime = performance.now();
    this.traceService.info(TraceModules.events, `EventCounterBxSubstituteProxyService.calculateEventCounters() called`);

    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const eventCounters: EventCounterList = { EventCategoryCounters: [], TotalCounters: 0, TotalUnprocessedCounters: 0 };
    const events = eventsAll.map(eventsOfBuilding => eventsOfBuilding.eventResponse.map(event => event)).flat();

    this.addEventCounter(eventCounters,
      this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Emergency), EventCategoryBx.Emergency));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Fault), EventCategoryBx.Fault));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.High), EventCategoryBx.High));
    this.addEventCounter(eventCounters,
      this.getEventCounter(events.filter(event => event.category === EventCategoryBx.LifeSafety), EventCategoryBx.LifeSafety));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Low), EventCategoryBx.Low));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Medium), EventCategoryBx.Medium));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Security), EventCategoryBx.Security));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Status), EventCategoryBx.Status));
    this.addEventCounter(eventCounters,
      this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Supervisory), EventCategoryBx.Supervisory));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Trouble), EventCategoryBx.Trouble));
    this.addEventCounter(eventCounters, this.getEventCounter(events.filter(event => event.category === EventCategoryBx.Unknown), EventCategoryBx.Unknown));

    this.traceService.info(TraceModules.events, `EventCounterBxSubstituteProxyService.calculateEventCounters() done;
      total time used: ${performance.now() - startTime} ms; for number of events: ${eventCounters.TotalCounters}`);
    return eventCounters;
  }

  private addEventCounter(eventCounters: EventCounterList, eventCounter: EventCounter): void {
    eventCounters.EventCategoryCounters.push(eventCounter);
    eventCounters.TotalCounters += eventCounter.TotalCount;
    eventCounters.TotalUnprocessedCounters += eventCounter.UnprocessedCount;
  }

  private getEventCounter(events: EventBx[], category: EventCategoryBx): EventCounter {
    const eventsPerCat = events.filter(event => event.category === category);
    const unprocessedEvents = eventsPerCat.filter(event => (event.state === EventStateBx.Active) || (event.state === EventStateBx.Inactive));
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      CategoryId: this.eventCategoryProxyService.getCategory(category).CategoryId,
      CategoryDescriptor: this.eventCategoryProxyService.getCategory(category).CategoryName,
      TotalCount: eventsPerCat.length,
      TotalSubsequentGrouping: eventsPerCat.length,
      UnprocessedCount: unprocessedEvents.length,
      UnprocessedSubsequentGrouping: unprocessedEvents.length
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

}
