import { Injectable } from '@angular/core';

import { DateFormat, TimeFormat } from '../interfaces/date-time-formats';

export interface PropertyConfig {
  /**
   * Used to format date strings in the exception view. Some date input fields depend
   * on the configured browser language and will be unaffected by this setting.
   *
   * Default is "mm/dd/yyyy"
   */
  dateFormat?: DateFormat;

  /**
   * how either 12-hour or 24-hour clock labels for the 24-hour schedule profiles.
   *
   *  Default is 12-hour time format.
   */
  timeFormat?: TimeFormat;

  /**
   * controls the enforcement of min/max vs typeMin/typeMax. When set to `true`, the enforced
   * range is typeMin/typeMax. Otherwise min/max is enforced
   */
  allowValuesOutOfRange?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SiPropertyConfig {
  private config: PropertyConfig = {
    dateFormat: 'mm/dd/yyyy',
    timeFormat: 'hh:mm:ss',
    allowValuesOutOfRange: false
  };

  /**
   * Gets the configuration.
   */
  get(): PropertyConfig {
    return this.config;
  }

  /**
   * Updates the specified configuration values.
   */
  update(config: PropertyConfig): void {
    this.config = Object.assign(this.config, config);
  }
}
