import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import { SiLinkModule } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { AppItem, AppItemCategory } from '../si-navbar-primary/si-navbar-primary.model';
import { SiLaunchpadItemComponent } from './si-launchpad-item.component';

@Component({
  selector: 'si-launchpad',
  standalone: true,
  imports: [A11yModule, CommonModule, SiLinkModule, SiTranslateModule, SiLaunchpadItemComponent],
  templateUrl: './si-launchpad.component.html',
  styleUrl: './si-launchpad.component.scss'
})
export class SiLaunchpadComponent implements OnInit {
  /**
   * Text to close the launchpad. Needed for a11y.
   */
  @Input() closeAppSwitcherText = $localize`:@@SI_LAUNCHPAD.CLOSE:Close launchpad`;

  /**
   * title for the launchpad
   */
  @Input() appSwitcherTitle = $localize`:@@SI_LAUNCHPAD.TITLE:Launchpad`;

  /**
   * sub-title for the launchpad
   */
  @Input()
  appSwitcherSubTitle = $localize`:@@SI_LAUNCHPAD.SUB_TITLE:Access all your apps`;

  /**
   * Like `appItems` but with the addition of categories. If this is set, `appItems` is ignored.
   */
  @Input() appCategoryItems?: AppItemCategory[];

  /**
   * All app items shown in the launchpad. The launchpad will not be visible if the
   * app items are undefined. The launchpad will be visible if the app items are an
   * empty array.
   */
  @Input() appItems?: AppItem[];

  /**
   * Allow the user to favorite apps which will then be displayed at the top.
   */
  @Input({ transform: booleanAttribute }) appItemsFavorites = false;
  /**
   * Title or translate key for the favorite apps section.
   */
  @Input() favoriteAppsTitle = $localize`:@@SI_LAUNCHPAD.FAVORITE_APPS:Favorite apps`;

  /**
   * Title or translate key for the default apps section.
   */
  @Input() defaultAppsTitle = $localize`:@@SI_LAUNCHPAD.DEFAULT_CATEGORY_TITLE:Apps`;

  /**
   * Title or translate key for the show more apps button.
   */
  @Input() showMoreAppsTitle = $localize`:@@SI_LAUNCHPAD.SHOW_MORE:Show more`;

  /**
   * Title or translate key for the show less apps button.
   */
  @Input() showLessAppsTitle = $localize`:@@SI_LAUNCHPAD.SHOW_LESS:Show less`;

  /**
   * "all apps" link in the launchpad
   */
  @Input() allAppsLink?: MenuItem;

  @Output() readonly appItemFavoriteChanged = new EventEmitter<[AppItem, boolean]>();
  @Output() readonly hide = new EventEmitter<void>();

  protected isCategorized = false;
  protected showAllApps = false;
  protected showFavorites = false;
  protected appsFavorites?: AppItem[];
  protected appsCategorized?: AppItemCategory[];

  ngOnInit(): void {
    this.calcAppItems();
  }

  protected hideAppSwitcher(): void {
    this.hide.emit();
  }

  private calcAppItems(): void {
    this.appsCategorized =
      this.appCategoryItems ??
      (this.appItems ? [{ category: '', items: this.appItems }] : undefined);

    this.isCategorized =
      this.appItemsFavorites ||
      (!!this.appsCategorized &&
        (this.appsCategorized.length > 1 || this.appsCategorized.some(item => item.category)));
    this.calcAppFavorites();
  }

  private calcAppFavorites(): void {
    this.showFavorites =
      this.appItemsFavorites &&
      !!this.appsCategorized &&
      this.appsCategorized.some(item => item.items.some(appItem => appItem.isFavorite));
    this.appsFavorites = this.appItemsFavorites
      ? this.appsCategorized?.reduce(
          (acc, item) => acc.concat(item.items.filter(appItem => appItem.isFavorite)),
          [] as AppItem[]
        )
      : undefined;
  }

  protected toggleFavorite(item: AppItem): void {
    item.isFavorite = !item.isFavorite;
    this.showAllApps = true;
    this.calcAppFavorites();
    this.appItemFavoriteChanged.emit([item, item.isFavorite]);
  }

  protected escape(event: Event): void {
    event.stopPropagation();
    this.hideAppSwitcher();
  }
}
