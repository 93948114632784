import { SearchedItem } from './search.data.model';

export class ThumbnailRequestInfo {
  public searchedItem!: SearchedItem;
  public localStorageId!: string;
  public content!: string;
  public imageData!: string;
  public trendSeriesIDs!: string[];
  public objectID!: string;
  public colors!: string[];
  constructor() {
    this.trendSeriesIDs = new Array<string>();
    this.colors = new Array<string>();
  }
}

export class CompleteChartDataSource extends Array<number[][]> {
  public id!: string;
  public urls: string[];
  public pendingRequests: number;

  constructor(requestCount: number) {
    super();
    this.pendingRequests = requestCount;
    this.urls = new Array<string>();
  }

  public pushDataSource(item: number[][]): void {
    this.pendingRequests--;
    this.push(item);
  }
}
