import { GmsSymbolInstance } from '../elements/gms-symbol-instance';
import { SubstitutionHelper } from '../utilities/SubstitutionHelper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GraphicsDatapointHelper } from './datapoint/gms-datapoint-helper';

/**
 * The classes Substitution and Evaluation can be sources for Substitutions
 */
export interface SubstitutionSource {

  /**
   * Gets or sets the error message. It's an empty string when there is no error
   */
  ErrorMessage: string;

  /**
   * Updates the substitution or evaluation
   * @param clearSubstitutions >Value indicating whether to clear the substitution first or not
   * @param origin The origin of the substitution
   */
  Update(clearSubstitutions: boolean, origin: Substitution): void;
}

export class Substitution implements SubstitutionSource {
  public static OBJECTREF = '*'; // the name of the main substitution start with a "*"

  /**
   * Gets the key
   */
  public get Key(): string {
    return this.Name.toLowerCase(); // TBD culture must be passed
  }

  private _name = '';
  public get Name(): string {
    return this._name;
  }
  public set Name(value: string) {
    if (this._name !== value) {
      this._name = value;
    }
  }

  private readonly _isObjectRef: boolean = false;
  public get IsObjectRef(): boolean {
    return this.Name !== undefined && this.Name[0] === Substitution.OBJECTREF;
  }

  private _valueSubstituted: string = undefined;
  public get ValueSubstituted(): string {
    return this._valueSubstituted;
  }
  public set ValueSubstituted(value: string) {
    if (this._valueSubstituted !== value) {
      this._valueSubstituted = value;
    }
  }

  private readonly _substitutionSources: SubstitutionSource[] = new Array<SubstitutionSource>();

  /**
   * Gets a collection of substitution sources which can be either Siemens.Gms.Graphics.Processor.Substitution or Siemens.Gms.Graphics.Processor.Evaluation
   */
  public get SubstitutionSources(): SubstitutionSource[] {
    return this._substitutionSources;
  }

  private _value = '';
  public get Value(): string {
    return this._value;
  }
  public set Value(value: string) {
    const newValue: string = value === undefined ? '' : value;
    if (this._value !== newValue) {
      this._value = newValue;
    }
    this.Update(true, this);
  }

  private _isResolved = false;
  public get IsResolved(): boolean {
    return this._isResolved;
  }
  public set IsResolved(value: boolean) {
    if (this._isResolved !== value) {
      this._isResolved = value;
    }
  }

  private _errorMessage: string = undefined;
  public get ErrorMessage(): string {
    return this._errorMessage;
  }
  public set ErrorMessage(value: string) {
    if (this._errorMessage !== value) {
      this._errorMessage = value;
    }
  }

  private _substitutions: Map<string, Substitution> = undefined;

  public get Substitutions(): Map<string, Substitution> {
    return this._substitutions;
  }

  // Will be set for substitutions which belong to a SymbolInstance
  private _parentSymbolInstance: GmsSymbolInstance = undefined;
  public get ParentSymbolInstance(): GmsSymbolInstance {
    return this._parentSymbolInstance;
  }
  public set ParentSymbolInstance(value: GmsSymbolInstance) {
    if (this._parentSymbolInstance === undefined) {
      this._parentSymbolInstance = value;
    }
  }

  /**
   * Parses an expression and either returns the substitutions or returns the new expression where the passed substitutions are replaced
   * @param expression The source expression.
   * Example 1: "{Subst1=123}{Subst2=456}"
   * Example 2: "{Subst1=123}{Subst2}"
   * @param substitutions
   * a) If 'substitutions' is empty, the method parses the expression and fills the substitutions collection with the parsed substitutions.
   *  'substituted' will contain the new expression where the default values are replaced.
   *  'resolved' is set to true only if all default values are not empty, i.e. if all substitutions have a default value.
   * b) If 'substitutions' already contains some substitutions, the method uses this collection (read-only) to replace the expression with
   *  all values from the passed substitutions.
   *  'substituted' will contain the new expression where all substitutions are replaced by their values. Those substitutions in the expression,
   *  which don't exist in the 'substitutions' collection, will be replaced by their default value.
   *  'resolved' is set to true only if all default values (only for missing substitutions in 'substitutions') are not empty
   * @param parseoutput Will be set to false when at least one substitution has no default value.
   * Example 1 with empty substitutions returns true.
   * Example 2 with empty substitutions returns false.
   * @returns True if the expression is valid
   */
  public static Parse(expression: string, substitutions: Map<string, Substitution>, parseoutput: SubstitutionParseOutput): boolean {
    parseoutput.ValueSubstituted = expression;
    parseoutput.Resolved = true; // this will be set to false when at least one substitution has no default value

    if (expression === undefined || expression === '') {
      return true;
    }

    const matchParser: MatchParser = new MatchParser(substitutions);
    parseoutput.ValueSubstituted = SubstitutionHelper.XRegExp.replace(expression
      , SubstitutionHelper.RegExSubstitution, match => matchParser.Replace(match));

    parseoutput.Substitutions = matchParser.Substitutions;
    parseoutput.Resolved = matchParser.Resolved;
    return matchParser.Success;
  }

  public constructor(name: string = '', value: string = '') {
    this._name = name;
    if (value !== undefined || value !== '' || value.includes('{')) {
      this._value = value !== undefined ? value : '';
      this.ValueSubstituted = this._value;
      this.IsResolved = true;
    } else {
      this.Value = value;
    }
  }

  /**
   * The target of this substitution changed its value
   * 1. Update the ValueSubstituted property
   * 2. Updates all substitition sources (recursively). The substitution sources can be other substitutions or evaluations
   * @param clearSubstitutions Determines whether to clear the substitutions or not
   * @param origin The substitution object, which triggered the update, undefined if the Value changed
   * @returns
   */
  public Update(clearSubstitutions: boolean, origin: Substitution): void {
    try {
      if (clearSubstitutions) {
        // the substitution collection is cleared -> it will be populated in the Parse method
        if (this.Substitutions !== undefined) {
          this.Substitutions.clear();
        }
      } else if (this.Substitutions !== undefined && this.Substitutions.size > 0 && origin !== undefined) {
        // 'forward' the property ValueSubstituted from the origin of the change to the corresponding substitution
        if (origin.IsObjectRef) {
          this.Substitutions.forEach((substitution, key, map) => {
            if (substitution.IsObjectRef && substitution !== origin) {
              substitution.ValueSubstituted = origin.ValueSubstituted;
              substitution.IsResolved = origin.IsResolved;
            }
          });
        } else {
          if (this.Substitutions.has(origin.Key)) {
            const substitution: Substitution = this.Substitutions.get(origin.Key);
            if (substitution !== origin) {
              substitution.ValueSubstituted = origin.ValueSubstituted;
              substitution.IsResolved = origin.IsResolved;
            }
          }
        }
      }

      // parseOutput.Resolved - false if at least one substitution is unresolved (= no value and no default value present)
      const parseOutput: SubstitutionParseOutput = new SubstitutionParseOutput();

      const success: boolean = Substitution.Parse(this.Value, this._substitutions, parseOutput);
      this._substitutions = parseOutput.Substitutions;
      this.ValueSubstituted = parseOutput.Resolved ? parseOutput.ValueSubstituted : undefined;
      if (clearSubstitutions) {
        this.IsResolved = this.Substitutions === undefined || this.Substitutions.size === 0;
      } else {
        this.IsResolved = this.Substitutions === undefined ||
                    Array.from(this.Substitutions).every(keyValue => keyValue[1].IsResolved);
      }

      if (!success) {
        throw new Error('Substitution parse error');
      }

      // update all substitition sources (recursively)
      let errorMessage = '';
      if (this.SubstitutionSources !== undefined) {

        this.SubstitutionSources.forEach(substitutionSource => {
          substitutionSource.Update(false, this);

          if (substitutionSource.ErrorMessage !== undefined && substitutionSource.ErrorMessage.toString() !== '') {
            errorMessage += (errorMessage.length > 0 ? '\n' : '') + substitutionSource.ErrorMessage;
          }
        });
      }

      // Creates replication from the substitution
      if (this.ParentSymbolInstance !== undefined && !this.IsObjectRef) {
        if (this.Value.includes(Utility.REPLICATION_WILDCARD) && this.IsResolved && this.ValueSubstituted !== undefined) {
          const parent: GmsSymbolInstance = this.ParentSymbolInstance;
          const occurences: number = this.ValueSubstituted.length > 0 ? (this.ValueSubstituted.split(Utility.REPLICATION_WILDCARD).length - 1) : 0;

          const valueSubstituted: string = GraphicsDatapointHelper.RemoveLeadingSemicolons(this.ValueSubstituted);

          // clear replication & reset replication
          if (parent.Replication !== undefined && parent.Replication.WildCardReference !== valueSubstituted) {
            parent.Replication.Clear();
            parent.Replication.WildCardReference = this.ValueSubstituted;
          }

          if (!parent.IsReplicationClone && parent.Replication === undefined && occurences === 1) {
            parent.CreateReplication();
            parent.Replication.WildCardItem = this;
            parent.Replication.WildCardReference = this.ValueSubstituted;
          }
        }
      }

      this.ErrorMessage = errorMessage;
    } catch (ex) {
      this.ErrorMessage = 'Error in Substitution: Update substitutions Value:' + this.Value + ' ValueSubstituted='
                + this.ValueSubstituted + 'Error:' + ex.stack;
      return;
    }
  }

  public ReplaceReplicationIndex(replicationIndex: number): void {
    if (this._value.includes(Utility.REPLICATION_WILDCARD)) {
      this._value = this._value.replace(Utility.REPLICATION_SEARCH_REGX(), '[' + replicationIndex + ']');
      this.Update(false, undefined);
    }
  }

  public Deserialize(node: Node): void {
    if (node === undefined) {
      return;
    }
    let result: string = SvgUtility.GetAttributeValue(node, SubstitutionProperties.Name);
    if (result !== undefined) {
      this.Name = result;
    }

    result = SvgUtility.GetAttributeValue(node, SubstitutionProperties.Value);
    if (result !== undefined) {
      this.Value = result;
    }
  }

  public Clear(): void {
    if (this._substitutions !== undefined) {
      this._substitutions.forEach(substitution => substitution.Clear());
      this._substitutionSources.length = 0; // source substitution and evaluation will be cleared by the element
    }

    if (this.ParentSymbolInstance !== undefined) {
      this.ParentSymbolInstance = undefined;
    }
  }

  public CopyFrom(substitution: Substitution): void {
    this.Name = substitution.Name;
    this.Value = substitution.Value;
  }
}

export enum SubstitutionProperties {
  Name = `Name`,
  Value = `Value`
}

export class MatchParser {
  private readonly _replace: boolean = true; // true: the expression will be replaced by the existing substitutions in the collection
  // false: the substitution collection will be created and returned
  private _substitutions: Map<string, Substitution> = undefined;
  public get Substitutions(): Map<string, Substitution> {
    return this._substitutions;
  }

  private readonly _success: boolean = true;
  public get Success(): boolean {
    return this._success;
  }

  private _resolved = true; // true if no substitution value or default value is undefined
  public get Resolved(): boolean {
    return this._resolved;
  }

  public constructor(substitutions: Map<string, Substitution>) {
    this._substitutions = substitutions;
    this._replace = this._substitutions !== undefined && this._substitutions.size > 0;
  }

  /**
   * This method is called for each found substitution in the expression.
   * @param match
   * @returns Return the default value (if _replace is false or the substitution is not found in _substitutions) or
   * the current value from the substitution in _substitutions.
   * Returns "" in case of a parse error.
   * "{{" are returned as "{"
   * "}}" are returned as "}"
   * "{{" or "}}" in the default value are also replaced by "{" or "}"
   */
  public Replace(match: any): string {
    if (match.toString() === '{{') {
      return '{';
    }
    if (match.toString() === '}}') {
      return '}';
    }

    const groupSubst: string = match.Substitution; // Substitution - named group
    if (groupSubst === undefined) {
      return '';
    }

    // get the default value
    const groupDefault: string = match.Default; // Default - named group
    let defaultValue: string = groupDefault;
    if (defaultValue === undefined || defaultValue === '') {
      defaultValue = undefined;
    } else {
      defaultValue = defaultValue.replace('{{', '{').replace('}}', '}');
    }

    // get the substitution name and key
    let substitutionName: string = groupSubst.trim();
    if (substitutionName === undefined || substitutionName === '') {
      substitutionName = Substitution.OBJECTREF;
    }

    const key: string = substitutionName.toLowerCase(); // TBD locale needs to provided

    const found: boolean = this._substitutions !== undefined && this._substitutions.has(key);
    const subst: Substitution = found ? this._substitutions.get(key) : undefined;

    if (this._replace) {
      // return the substitution value for existing substitutions
      if (found) {
        defaultValue = subst.ValueSubstituted;
      }
    } else {
      if (found) {
        // update the default value if it was undefined
        if (defaultValue !== undefined) {
          subst.Value = defaultValue;
        }
      } else {
        // new substitutions are added to the collection
        if (this._substitutions === undefined) {
          this._substitutions = new Map<string, Substitution>();
        }
        this._substitutions.set(key, new Substitution(substitutionName, defaultValue));
      }
    }

    // return the default value
    if (defaultValue === undefined) {
      this._resolved = false;
    }
    return defaultValue === undefined ? '' : defaultValue;
  }
}

export class SubstitutionParseOutput {
  private _valueSubstituted: string = undefined;
  public get ValueSubstituted(): string {
    return this._valueSubstituted;
  }
  public set ValueSubstituted(value: string) {
    if (this._valueSubstituted !== value) {
      this._valueSubstituted = value;
    }
  }

  private _resolved = false;
  public get Resolved(): boolean {
    return this._resolved;
  }
  public set Resolved(value: boolean) {
    if (this._resolved !== value) {
      this._resolved = value;
    }
  }

  private _substitutions: Map<string, Substitution> = new Map<string, Substitution>();
  public get Substitutions(): Map<string, Substitution> {
    return this._substitutions;
  }
  public set Substitutions(value: Map<string, Substitution>) {
    if (this._substitutions !== value) {
      this._substitutions = value;
    }
  }
}
