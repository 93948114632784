import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import {
  compareDate,
  getMaxDate,
  getMinDate,
  isBetweenYears,
  isValid,
  parseDate
} from './date-time-helper';
import { DatepickerInputConfig, DateRange, getDatepickerFormat } from './si-datepicker.model';

/**
 * Base directive for date input fields.
 */
@Directive({
  selector: '[siDateInput]',
  exportAs: 'siDateInput',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiDateInputDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiDateInputDirective,
      multi: true
    }
  ],
  standalone: true
})
export class SiDateInputDirective
  implements ControlValueAccessor, OnChanges, OnInit, OnDestroy, Validator
{
  /**
   * Configuration object for the datepicker.
   */
  @Input() siDatepickerConfig?: DatepickerInputConfig = {};
  @Input() dateRange?: DateRange;
  /**
   * Indicate whether the input represent the start or the end of a range.
   */
  @Input() rangeType?: 'START' | 'END';
  /**
   * The debounce time for the date input changes.
   */
  @Input() dateInputDebounceTime = 200;
  /**
   * Emits an event to notify about disabling the time from the datepicker.
   * When time is disable, we construct a pure date object in UTC 00:00:00 time.
   */
  @Output() readonly siDatepickerDisabledTime = new EventEmitter<boolean>();
  /**
   * Emits an event on state changes e.g. readonly, disable, ... .
   */
  @Output() readonly stateChange = new EventEmitter<void>();

  private _disabled = false;
  get disabled(): boolean {
    return this._disabled;
  }
  /**
   * Whether the date range input is disabled.
   * @defaultref {@link _disabled}
   */
  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(value !== false);
  }

  /**
   * Whether the date range input is readonly.
   * @defaultref {@link _readonly}
   */
  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChange.next();
  }
  get readonly(): boolean {
    return this._readonly;
  }

  @HostBinding('attr.readonly') protected get readOnlyState(): boolean | null {
    return this._readonly ? true : null;
  }
  @HostBinding('class.readonly') private _readonly = false;

  /** @internal */
  validatorOnChange = (): void => {};
  /**
   * Date form input validator function, validating text format, min and max value.
   */
  protected validator = Validators.compose([
    c => this.formatValidator(c),
    () => this.minValidator(),
    () => this.maxValidator()
  ])!;
  protected date?: Date;
  /**
   * Emits a new `date` value on input field value changes.
   */
  protected readonly dateChange = new EventEmitter<Date | undefined>();
  protected onTouched: () => void = () => {};
  protected onModelChange: (value: any) => void = () => {};
  protected destroyer$ = new Subject<void>();
  protected readonly cdRef = inject(ChangeDetectorRef);
  protected readonly locale = inject(LOCALE_ID);
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private inputChange$ = new Subject<string>();
  private format = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.siDatepickerConfig && !changes.siDatepickerConfig.currentValue) {
      this.siDatepickerConfig = {};
    }
    if (changes.siDatepickerConfig) {
      // reflect possible change is date/time format
      const format = this.format;
      this.format = '';
      this.getFormat();
      const formatChanged = format !== this.format;
      if (this.date && formatChanged) {
        this.updateNativeValue();
      }
    }
  }

  ngOnInit(): void {
    this.inputChange$
      .pipe(debounceTime(this.dateInputDebounceTime), takeUntil(this.destroyer$))
      .subscribe((value: string) => this.processInput(value));
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.validatorOnChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    this.stateChange.next();
    this.cdRef.markForCheck();
  }

  writeValue(value?: Date | string): void {
    // remove date when user input is empty
    let emptyString = false;
    // Flag to define invalid string
    let invalidDate = false;
    if (typeof value === 'string') {
      emptyString = value.trim().length === 0;
      value = parseDate(value, this.getFormat(), this.locale);
      invalidDate = !value;
    }

    this.date = value;
    this.dateChange.next(this.date);

    // We should not change the content of the input field when the user typed
    // a wrong input. Otherwise the typed content changes and the user cannot
    // correct the content.
    if (!invalidDate || emptyString) {
      this.updateNativeValue();
    }
    this.cdRef.markForCheck();
  }

  private updateNativeValue(): void {
    let dtStr = '';
    if (this.date) {
      dtStr = formatDate(this.date, this.getFormat(), this.locale);
    }
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', dtStr);
  }

  @HostListener('change', ['$event'])
  protected onChange(event: Event): void {
    this.writeValue((event.target as HTMLInputElement).value);
    this.onModelChange(this.date);
    this.onTouched();
  }

  /**
   * Handles `input` events on the input element.
   * @param value current input value.
   */
  @HostListener('input', ['$event.target.value'])
  protected onInput(value: string): void {
    this.inputChange$.next(value);
  }

  private processInput(value: string): void {
    const parsedDate = parseDate(value, this.getFormat(), this.locale);
    // Ensure the string is a valid date and ignore years which aren't between 1900..2154.
    // The use user could remove the last char from 12.03.2022 which leads to
    // 12.03.202 which isn't we should ignore.
    const valid =
      value.trim() === '' ||
      (isValid(parsedDate) && isBetweenYears(parsedDate!, getMinDate(), getMaxDate()));
    if (!valid) {
      return;
    }
    // Is same date
    const hasChanged = !(parsedDate === this.date);
    if (hasChanged) {
      this.date = parsedDate;
      this.onModelChange(this.date);
      this.validatorOnChange();
      this.dateChange.next(this.date);
    }
  }

  private getFormat(): string {
    if (!this.format) {
      this.format = getDatepickerFormat(this.locale, this.siDatepickerConfig);
    }
    return this.format;
  }

  /**
   * Callback when the datepicker changes his value.
   * @param date updated date
   */
  protected onDateChanged(date: Date): void {
    // update input element
    this.writeValue(date);
    // update the Forms ngModel
    this.onModelChange(this.date);
    // trigger validation on date selection
    this.onTouched();
    this.cdRef.markForCheck();
  }

  /**
   * Datepicker consider time / ignore time changed.
   * @param disabledTime disable time
   * @internal
   */
  onDisabledTime(disabledTime: boolean): void {
    this.format = '';
    this.siDatepickerConfig!.disabledTime = disabledTime;
    // Ensure the time format will be removed
    this.onDateChanged(this.date!);
    this.siDatepickerDisabledTime.next(disabledTime);
  }

  /** The form control validator for date format */
  private formatValidator(control: AbstractControl): ValidationErrors | null {
    const invalidFormat = !!(!control?.value && this.elementRef.nativeElement.value);
    return invalidFormat ? { invalidFormat: { format: this.getFormat() } } : null;
  }

  /** The form control validator for the min date. */
  private minValidator(): ValidationErrors | null {
    const controlValue = this.date;
    const min = getMinDate(this.siDatepickerConfig?.minDate);
    const withTime = this.siDatepickerConfig?.showTime;

    return !min ||
      !controlValue ||
      (withTime ? controlValue >= min : compareDate(controlValue, min) >= 0)
      ? null
      : { invalidMinDate: { min, actual: controlValue } };
  }

  /** The form control validator for the min date. */
  private maxValidator(): ValidationErrors | null {
    const controlValue = this.date;
    const max = getMaxDate(this.siDatepickerConfig?.maxDate);
    const withTime = this.siDatepickerConfig?.showTime;

    return !max ||
      !controlValue ||
      (withTime ? controlValue <= max : compareDate(controlValue, max) <= 0)
      ? null
      : { invalidMaxDate: { max, actual: controlValue } };
  }
}
