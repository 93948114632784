<si-icon
  [icon]="icon"
  [stackedIcon]="stackedIcon"
  [color]="isDisabledOrCountZero ? undefined : color"
  [class.inactive]="isDisabledOrCountZero"
  [stackedColor]="isDisabledOrCountZero ? 'inactive-contrast' : color + '-contrast'"
/>
@if (!disabled) {
  <span>{{ count }}</span>
}
