/**
 * TraceModulesEn
 *
 * @enum {number}
 */
enum TraceModulesEn {
  GmsSnapinsVideoSnapIn,
  GmsSnapinsVideoSnapInService,
  GmsSnapinsVideoSnapInVmsDataService,
  GmsSnapinsVideoSnapInVmsDataSubscription,
  GmsSnapinsVideoSnapInVmsDataSubscriptionService,
  GmsSnapinsVideoHubProxyEvent,
  GmsSnapinsVideoObjectManager,
  GmsSnapinsVideoOpStep
}

/**
 * TraceModules
 *
 * @export
 * @class TraceModules
 */
export class TraceModules {
  public static videoSnapIn: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoSnapIn];
  public static videoService: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoSnapInService];
  public static videoVmsDataService: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoSnapInVmsDataService];
  public static videoVmsDataSubscription: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoSnapInVmsDataSubscription];
  public static videoVmsDataSubscriptionService: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoSnapInVmsDataSubscriptionService];
  public static videoHubProxyEvent: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoHubProxyEvent];
  public static videoObjectManager: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoObjectManager];
  public static videoOpStep: string = TraceModulesEn[TraceModulesEn.GmsSnapinsVideoOpStep];
}
