import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BigNumberValue } from '@simpl/element-value-types';

import { SiNumberValidatorDirective } from '../../directives/number-validator.directive';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';
import { SiBigNumberPipe } from './si-big-number.pipe';

const REGEX_DECIMALS = /^-?\d*.?\d*$/;
const REGEX_NO_DECIMALS = /^-?\d*$/;

@Component({
  selector: 'si-big-number-property',
  templateUrl: './si-big-number-property.component.html',
  styleUrls: ['./si-big-number-property.component.scss'],
  standalone: true,
  imports: [FormsModule, SiBigNumberPipe, SiNumberValidatorDirective, SiPropertyPopoverComponent]
})
export class SiBigNumberPropertyComponent {
  @Input({ required: true }) property!: Property<BigNumberValue>;
  @Input() valueState: ValueState = 'none';
  @Input() allowValuesOutOfRange = false;
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<BigNumberValue>>();

  @ViewChild('inputBox') inputBox!: ElementRef;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
      case 'open':
        setTimeout(() => {
          this.inputBox.nativeElement.focus();
          if (state === 'openKeyboard') {
            this.inputBox.nativeElement.select();
          }
        }, 0);
        break;
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }

  keydown(event: KeyboardEvent): void {
    // don't cancel copy-paste
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Backspace':
      case 'Delete':
      case 'Tab':
      case 'Enter':
      case 'Escape':
      case 'Alt':
      case 'Shift':
      case 'Meta':
        return;
    }

    const value = this.inputBox.nativeElement.value;
    const selStart = this.inputBox.nativeElement.selectionStart;
    const selEnd = this.inputBox.nativeElement.selectionEnd;

    const newNum = value.slice(0, selStart) + event.key + value.slice(selEnd);
    if (!this.validateValue(newNum)) {
      event.preventDefault();
      return;
    }
  }

  paste(event: ClipboardEvent): void {
    const text = event.clipboardData?.getData('text');
    if (!text) {
      return;
    }

    const value = this.inputBox.nativeElement.value;
    const selStart = this.inputBox.nativeElement.selectionStart;
    const selEnd = this.inputBox.nativeElement.selectionEnd;

    const newNum = value.slice(0, selStart) + text + value.slice(selEnd);
    if (!this.validateValue(newNum)) {
      event.preventDefault();
      return;
    }
  }

  private validateValue(value: string): boolean {
    if (this.property.value.decimalsAllowed) {
      if (!REGEX_DECIMALS.test(value)) {
        return false;
      }
    } else {
      if (!REGEX_NO_DECIMALS.test(value)) {
        return false;
      }
    }
    return (
      this.compareBigNum(value, this.property.value.min) >= 0 &&
      this.compareBigNum(value, this.property.value.max) <= 0
    );
  }

  private compareBigNum(a: string, b?: string): number {
    if (b === undefined) {
      return 0;
    }

    const negA = a.charAt(0) === '-';
    const negB = b.charAt(0) === '-';

    if (negA !== negB) {
      return negA ? -1 : 1;
    }
    if (a.length < b.length) {
      return negA ? 1 : -1;
    }
    if (a.length > b.length) {
      return negA ? -1 : 1;
    }

    const tmp = a.localeCompare(b);
    return negA ? -tmp : tmp;
  }
}
