import { CdkPortalOutlet, Portal, PortalModule } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MenuItem,
  SiContentActionBarComponent,
  SiEmptyStateComponent,
  SiLoadingSpinnerComponent,
  SiPopoverDirective,
  SiTranslateModule,
  SiTranslateService,
  ViewType
} from '@simpl/element-ng';
import { EnumValue, StringValue } from '@simpl/element-value-types';
import { clone, SiClickOutsideDirective } from '@simpl/object-browser-ng/common';
import {
  AnyProperty,
  CommandEvent,
  DisplayMode,
  Property,
  SiDatapointComponent,
  SiEnumPropertyComponent,
  SiStringPropertyComponent
} from '@simpl/object-browser-ng/property-viewer';
import { Subject, Subscription } from 'rxjs';

import { DEFAULT_DATAPOINT_ICON, getPropertyName } from '../../helpers/default-models';
import {
  getDataPointFullDescription,
  getDataPointFullName
} from '../../helpers/scene-property-command-helper';
import { DataPoint } from '../../interfaces/scene-editor';
import { SiSceneEditorConfigService, SiSceneEditorService } from '../../services/index';
import { SiCommandListComponent } from '../si-command-list/si-command-list.component';

export type ChangeType = 'SelectedProperty' | 'UserDefinedName' | 'CommandValue';

export interface PropertyEvent {
  data?: AnyProperty | string;
  change?: ChangeType;
}

export interface DataPointResult {
  dataPoint: DataPoint;
  isDeleted?: boolean;
}

@Component({
  selector: 'si-configure-datapoint',
  templateUrl: './si-configure-datapoint.component.html',
  styleUrls: ['./si-configure-datapoint.component.scss'],
  standalone: true,
  imports: [
    PortalModule,
    SiClickOutsideDirective,
    SiCommandListComponent,
    SiContentActionBarComponent,
    SiDatapointComponent,
    SiEmptyStateComponent,
    SiEnumPropertyComponent,
    SiLoadingSpinnerComponent,
    SiPopoverDirective,
    SiStringPropertyComponent,
    SiTranslateModule
  ]
})
export class SiConfigureDatapointComponent implements OnChanges, OnInit, OnDestroy {
  @Input() objectId!: string;
  @Input() selectedDataPoint!: DataPoint;
  @Input() propertyList: AnyProperty[] = [];
  @Input() readOnly = false;
  @Input() isDuplicateDataPoint?: boolean;
  @Input() displayMode!: DisplayMode;

  @Output() readonly addDataPoint = new EventEmitter<void>();
  @Output() readonly removeDataPoint = new EventEmitter<DataPointResult>();
  @Output() readonly propertyChanged = new EventEmitter<PropertyEvent>();
  @Output() readonly isPopOpen = new EventEmitter<boolean>();
  @Output() readonly command = new EventEmitter<CommandEvent>();

  @ViewChild('commandListViewer') commandListViewer!: SiCommandListComponent;
  @ViewChild('selectProp') selectProp!: ElementRef;
  @ViewChild('stringInputProp', { read: ElementRef }) stringInput!: ElementRef;
  @ViewChild('aboutDataPointTemplate', { read: CdkPortalOutlet, static: false })
  aboutDataPointTemplate!: CdkPortalOutlet;
  @ViewChild('popUpExpander') popUpExpander!: SiPopoverDirective;
  isDeleted = false;
  isRenaming = false;
  isPropertyExpanded = false;
  selectedDataPointUserDefinedName?: string;
  selectedProperty?: AnyProperty;
  selectedDataPointName?: string;
  isLoadingProperties?: boolean;
  selectedDataPointProperties?: AnyProperty[];
  selectedDataPointDescription?: string;
  selectedDataPointIcon?: string;
  selectedDataPointAlias?: string;
  propertyInfoSubscription?: Subscription;

  expanderPopup = { pop: undefined as SiPopoverDirective | undefined };

  public configureAboutDatapointSub: Subject<any> = new Subject<any>();
  readonly viewType: ViewType = 'expanded';
  primaryActions: MenuItem[] = [];
  secondaryActions: MenuItem[] = [
    {
      id: 'about-data-point',
      title: 'SCENE_EDITOR.TEXT_SHORT.ABOUT_DATA_POINT',
      action: () => this.aboutDataPoint()
    },
    {
      id: 'navigate-to',
      title: 'SCENE_EDITOR.TEXT_SHORT.NAVIGATE_TO',
      action: () => this.navigateToDataPoint()
    },
    {
      title: '-'
    },
    {
      id: 'remove-data-point',
      title: 'SCENE_EDITOR.TEXT_SHORT.REMOVE_DATA_POINT',
      action: () => this.deleteDataPoint()
    }
  ];

  enumPropertyEnums!: Property<EnumValue<string>>;
  readonly defaultDataPointProperties: Property<EnumValue<string>> = {
    name: '',
    value: {
      type: 'enum',
      value: '1',
      options: [{ text: 'Option 1', value: '1' }]
    },
    overrideMode: 'direct'
  };

  renameStringProperty: Property<StringValue> = {
    value: {
      type: 'string',
      value: '',
      minLength: 0,
      maxLength: 255,
      optional: true
    },
    overrideMode: 'direct'
  };

  private sceneService = inject(SiSceneEditorService);
  private translateService = inject(SiTranslateService);
  private sceneConfigService = inject(SiSceneEditorConfigService);

  ngOnChanges(): void {
    this.setReadOnlyActions(this.readOnly);
    this.updateActionsForInValidDataPoint();
    this.isDeleted = false;

    if (this.isDuplicateDataPoint) {
      this.selectProp.nativeElement.querySelector('span')?.click();
    }
    if (this.selectedDataPoint) {
      if (this.selectedDataPoint.name === '') {
        this.translateService
          .translateAsync('SCENE_EDITOR.TEXT_SHORT.UNKNOWN')
          .subscribe(localeText => {
            this.selectedDataPoint.name = localeText;
            this.selectedDataPoint.description = localeText;
          });
      }
      this.selectedProperty = this.selectedDataPoint.properties?.find(
        prop => prop.id === this.selectedDataPoint.selectedPropertyId
      );
      this.selectedDataPointUserDefinedName = this.selectedDataPoint.userDefinedName;
      this.selectedDataPointIcon = this.selectedDataPoint.icon
        ? this.selectedDataPoint.icon
        : DEFAULT_DATAPOINT_ICON;
      this.selectedDataPointAlias = this.selectedDataPoint.alias;
      this.setDataPointNameDescription();
    }
  }

  ngOnInit(): void {
    this.setContentActions();
    this.propertyInfoSubscription = this.sceneService.propertyInfo$.subscribe(dataPointInfo => {
      this.hideExpanderPopup();
      if (dataPointInfo.dataPointInfo) {
        setTimeout(() => {
          this.onExpandableClickedAbout(this.popUpExpander);
          setTimeout(() => {
            this.attachDetachAboutContent(dataPointInfo.dataPointInfo);
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.propertyInfoSubscription?.unsubscribe();
    this.hideExpanderPopup();
  }

  private hideExpanderPopup(): void {
    this.expanderPopup?.pop?.hide();
    this.expanderPopup = { pop: undefined as any };
    this.aboutDataPointTemplate?.detach();
  }

  setContentActions(): void {
    if (!this.sceneConfigService.get().restrictPropertyEdit) {
      this.primaryActions.push({
        id: 'select-property',
        title: 'SCENE_EDITOR.TEXT_SHORT.SELECT_PROPERTY',
        action: () => this.selectProperty()
      });
      this.secondaryActions.splice(2, 0, {
        id: 'rename-data-point',
        title: 'SCENE_EDITOR.TEXT_SHORT.RENAME_DATA_POINT',
        action: () => this.renameDataPoint()
      });
    }
  }

  private setDataPointNameDescription(): void {
    this.selectedDataPointDescription = getDataPointFullDescription(
      this.selectedDataPoint,
      this.selectedProperty,
      this.displayMode
    );
    if (this.displayMode === 'description-name') {
      if (this.selectedDataPointUserDefinedName) {
        const isDefault =
          this.selectedDataPoint.defaultPropertyId &&
          this.selectedDataPoint.defaultPropertyId === this.selectedDataPoint.selectedPropertyId;
        if (!isDefault) {
          const propertyName = this.selectedProperty?.name
            ? getPropertyName(this.selectedProperty.name)
            : this.selectedDataPoint.selectedPropertyId;
          this.selectedDataPointName = this.selectedDataPoint.description
            ? this.selectedDataPoint.description + '.' + propertyName
            : '';
        } else {
          this.selectedDataPointName = this.selectedDataPoint.description ?? '';
        }
      } else {
        this.selectedDataPointName = getDataPointFullName(
          this.selectedDataPoint,
          this.selectedProperty,
          'name'
        );
      }
    } else {
      this.selectedDataPointName = getDataPointFullName(
        this.selectedDataPoint,
        this.selectedProperty,
        this.displayMode
      );
    }
  }

  initializeSelectProperties(): void {
    this.isDuplicateDataPoint = true;
    this.isLoadingProperties = true;
    this.sceneService
      .getProperties(this.objectId, this.selectedDataPoint.id)
      .subscribe(properties => {
        this.selectedDataPoint.properties = properties;
        this.selectedProperty = this.selectedDataPoint.properties?.find(
          prop => prop.id === this.selectedDataPoint.selectedPropertyId
        );
        this.selectProperty();
        this.isLoadingProperties = false;
      });
  }

  selectProperty(): void {
    this.isPropertyExpanded = true;
    if (!this.isLoadingProperties) {
      this.initializeSelectProperties();
    }
    this.enumPropertyEnums = clone(this.defaultDataPointProperties);
    this.translateService
      .translateAsync('SCENE_EDITOR.TEXT_SHORT.PROPERTY')
      .subscribe(langText => (this.enumPropertyEnums.name = langText));
    this.enumPropertyEnums.value.options = this.selectedDataPoint.properties?.map(item => ({
      value: item.id ?? '',
      text: item.name ?? ''
    }));
    this.enumPropertyEnums.value.value = this.selectedProperty?.id ?? '';
  }

  addNewDataPoint(): void {
    // most possibly there won't be situation this code gets called.
    // keeping it for track.
    this.addDataPoint.emit();
  }

  deleteDataPoint(): void {
    this.isDeleted = true;
    this.removeDataPoint.emit({ dataPoint: this.selectedDataPoint, isDeleted: this.isDeleted });
  }

  aboutDataPoint(): void {
    this.sceneService.aboutDataPoint({
      dataPointId: this.selectedDataPoint.id
    });
  }

  navigateToDataPoint(): void {
    this.sceneService.navigateToDataPoint({
      dataPointId: this.selectedDataPoint.id
    });
  }

  renameDataPoint(): void {
    this.renameStringProperty.value.value = this.selectedDataPointUserDefinedName
      ? this.selectedDataPointUserDefinedName
      : this.selectedDataPoint.name;
    this.renameStringProperty.value.altText = this.selectedDataPointUserDefinedName
      ? this.selectedDataPointUserDefinedName
      : 'SCENE_EDITOR.TEXT_SHORT.ENTER_USER_DEFINED_NAME';
    this.isRenaming = true;

    setTimeout(() => {
      const input = this.stringInput.nativeElement.querySelector('input')!;
      input.click();
      setTimeout(() => {
        input.focus();
        input.select();
      });
    });
  }

  onPropertyChanged(event: any): void {
    if (event) {
      const selectedProp = this.selectedDataPoint.properties?.find(
        obj => obj.id === event.value.value
      );
      if (selectedProp) {
        this.selectedProperty = clone(selectedProp);
        this.isDuplicateDataPoint = false;
      }
    }
  }

  onDataPointNameChanged(event: any): void {
    this.selectedDataPoint.userDefinedName = event.value.value;
    this.selectedDataPointUserDefinedName = event.value.value;
    this.setDataPointNameDescription();
    this.propertyChanged.emit({
      data: this.selectedDataPoint.userDefinedName,
      change: 'UserDefinedName'
    });
    this.isRenaming = false;
  }

  closePopups(): void {
    this.closeExpanderPopup();
    if (this.isPopOpen) {
      this.setReadOnlyActions(this.readOnly);
      this.isPopOpen.emit(false);
    }
  }

  private closeExpanderPopup(): void {
    if (this.expanderPopup.pop) {
      this.expanderPopup.pop.hide();
      this.isPropertyExpanded = false;
    }
    this.selectedDataPointName = getDataPointFullName(
      this.selectedDataPoint,
      this.selectedProperty,
      this.displayMode
    );
    this.propertyChanged.emit({ data: this.selectedProperty, change: 'SelectedProperty' });
    this.expanderPopup = { pop: undefined };
  }

  private setReadOnlyActions(readonly: boolean): void {
    this.primaryActions.forEach(action => {
      action.disabled = readonly;
    });
    this.secondaryActions.forEach(action => {
      action.disabled = readonly;
    });
  }

  private updateActionsForInValidDataPoint(): void {
    if (!this.selectedDataPoint.properties?.length) {
      this.primaryActions.forEach(action => {
        action.disabled = true;
      });
      this.secondaryActions.forEach(element => {
        if (element.id !== 'remove-data-point') {
          element.disabled = true;
        }
      });
    }
  }

  onExpandableClicked(event: MouseEvent, pop: SiPopoverDirective): void {
    if (!this.readOnly && this.isPropertyExpanded) {
      event.stopPropagation();
      this.expanderPopup = { pop };
      this.isPopOpen.emit(true);
      this.setReadOnlyActions(true);
      setTimeout(() => {
        pop.show();
        this.isDuplicateDataPoint = false;
      });
    }
  }

  propertyChange(event: AnyProperty): void {
    this.propertyChanged.emit({ data: event, change: 'CommandValue' });
  }

  revertChanges(): void {
    this.isDeleted = false;
    this.removeDataPoint.emit({ dataPoint: this.selectedDataPoint, isDeleted: this.isDeleted });
  }

  onExpandableClickedAbout(pop: SiPopoverDirective): void {
    this.expanderPopup = { pop };
    pop.show();
  }

  private attachDetachAboutContent(portal?: Portal<any>): void {
    if (this.aboutDataPointTemplate !== undefined) {
      this.aboutDataPointTemplate.detach();
      if (portal) {
        this.aboutDataPointTemplate.attach(portal);
      }
    }
  }
}
