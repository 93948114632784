import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { UploadFile } from './si-file-uploader.model';

@Component({
  selector: 'si-file-dropzone',
  templateUrl: './si-file-dropzone.component.html',
  styleUrl: './si-file-dropzone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiFileDropzoneComponent implements OnChanges {
  /**
   * Text or translation key of the input file selector (is combined with the `uploadTextRest`).
   */
  @Input() uploadTextFileSelect = $localize`:@@SI_FILE_UPLOADER.FILE_SELECT:click to upload`;
  /**
   * Text or translation key of the drag&drop field (is combined with the `uploadTextFileSelect`).
   */
  @Input() uploadDropText = $localize`:@@SI_FILE_UPLOADER.DROP:Drop files here or`;
  /**
   * Text or translation key for max file size.
   */
  @Input() maxFileSizeText = $localize`:@@SI_FILE_UPLOADER.MAX_SIZE:Maximum upload size`;
  /**
   * Text or translation key for accepted types.
   */
  @Input() acceptText = $localize`:@@SI_FILE_UPLOADER.ACCEPTED_FILE_TYPES:Accepted file types`;
  /**
   * Text or translation key of message title if incorrect file type is dragged / dropped.
   */
  @Input()
  errorTextFileType = $localize`:@@SI_FILE_UPLOADER.ERROR_FILE_TYPE:Incorrect file type selected`;
  /**
   * Message or translation key if file exceeds the maximum file size limit.
   */
  @Input()
  errorTextFileMaxSize =
    $localize`:@@SI_FILE_UPLOADER.ERROR_FILE_SIZE_EXCEEDED:File exceeds allowed maximum size`;
  /**
   * Define which file types are suggested in file browser.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-accept
   */
  @Input() accept?: string;
  /**
   * Define maximal allowed file size in bytes.
   */
  @Input() maxFileSize?: number;
  /**
   * Defines whether the file input allows selecting multiple files.
   */
  @Input({ transform: booleanAttribute }) multiple = false;
  /**
   * Event emitted when files are added.
   */
  @Output() readonly filesAdded = new EventEmitter<UploadFile[]>();

  protected maxFileSizeString = '';
  protected dragOver = false;

  @ViewChild('fileInput') private fileInput?: ElementRef;
  private locale = inject(LOCALE_ID);
  private numberFormat = new Intl.NumberFormat(this.locale, { maximumFractionDigits: 2 });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxFileSize) {
      this.maxFileSizeString = this.maxFileSize ? this.fileSizeToString(this.maxFileSize) : '';
    }
  }

  protected dropHandler(event: DragEvent): void {
    event.preventDefault();
    this.handleFiles(event.dataTransfer!.files);
    this.dragOver = false;
  }

  protected dragOverHandler(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
  }

  protected inputEnterHandler(): void {
    this.fileInput?.nativeElement.click();
  }

  protected inputHandler(event: Event): void {
    this.handleFiles((event.target as HTMLInputElement).files);
  }

  protected handleFiles(files: FileList | null): void {
    if (!files?.length) {
      return;
    }

    const newFiles: UploadFile[] = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      newFiles.push(this.makeUploadFile(files[i]));
    }
    newFiles.sort((a, b) => a.fileName.localeCompare(b.fileName));

    this.filesAdded.emit(newFiles);
  }

  /**
   * Reset all the files inside the native file input (and therefore the dropzone).
   */
  reset(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private makeUploadFile(file: File): UploadFile {
    const uploadFile: UploadFile = {
      fileName: file.name,
      file,
      size: this.fileSizeToString(file.size),
      progress: 0,
      status: 'added'
    };
    // use MIME type of file if set. Otherwise fall back to file name ending
    const ext = '.' + uploadFile.file.name.split('.').pop();
    if (!this.verifyFileType(uploadFile.file.type, ext)) {
      uploadFile.status = 'invalid';
      uploadFile.errorText = this.errorTextFileType;
    } else if (!this.verifyFileSize(uploadFile.file.size)) {
      uploadFile.status = 'invalid';
      uploadFile.errorText = this.errorTextFileMaxSize;
    }
    return uploadFile;
  }

  private verifyFileSize(size: number): boolean {
    return !this.maxFileSize || size <= this.maxFileSize;
  }

  private verifyFileType(fileType: string | undefined, ext: string | undefined): boolean {
    if (!this.accept) {
      return true;
    }
    if (fileType === undefined && ext === undefined) {
      return false;
    }
    // Spec says that comma is the delimiter for filetypes. Also allow pipe for compatibility
    return this.accept.split(/,|\|/).some(acceptedType => {
      // convert accept glob into regex (example: images/* --> images/.*)
      const acceptedRegexStr = acceptedType.replace('.', '.').replace('*', '.*').trim();
      const acceptedRegex = new RegExp(acceptedRegexStr, 'i');

      // if fileType is set and accepted type looks like a MIME type, match that otherwise extension
      if (fileType && acceptedType.includes('/')) {
        return !!fileType.match(acceptedRegex);
      }
      return !!ext?.match(acceptedRegex);
    });
  }

  private fileSizeToString(num: number): string {
    let suffix = 'B';
    if (num >= 1_073_741_824) {
      num /= 1_073_741_824;
      suffix = 'GB';
    }
    if (num >= 1_048_576) {
      num /= 1_048_576;
      suffix = 'MB';
    } else if (num >= 1_024) {
      num /= 1_024;
      suffix = 'KB';
    }
    return this.numberFormat.format(num) + suffix;
  }
}
