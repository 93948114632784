import { A11yModule } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '@simpl/element-ng/common';
import { SI_HEADER_DROPDOWN_OPTIONS } from '@simpl/element-ng/header-dropdown';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { NavbarWithDropdowns, SI_NAVBAR_WITH_DROPDOWNS } from '@simpl/element-ng/navbar-dropdown';
import { BOOTSTRAP_BREAKPOINTS } from '@simpl/element-ng/resize-observer';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { filter, map } from 'rxjs/operators';

import { SiLaunchpadComponent } from '../launchpad/si-launchpad.component';
import { SiNavbarBrandComponent } from '../si-navbar-brand/si-navbar-brand.component';
import { SiNavbarItemComponent } from '../si-navbar-item/si-navbar-item.component';
import {
  AccountItem,
  SiNavbarProfileMenuComponent
} from '../si-navbar-profile-menu/si-navbar-profile-menu.component';
import { AppItem, AppItemCategory } from './si-navbar-primary.model';

@Component({
  selector: 'si-navbar-primary',
  templateUrl: './si-navbar-primary.component.html',
  styleUrl: './si-navbar-primary.component.scss',
  providers: [
    { provide: SI_NAVBAR_WITH_DROPDOWNS, useExisting: SiNavbarPrimaryComponent },
    { provide: SI_HEADER_DROPDOWN_OPTIONS, useValue: { disableRootFocusTrapForInlineMode: true } }
  ],
  standalone: true,
  imports: [
    A11yModule,
    SiLinkDirective,
    SiLaunchpadComponent,
    SiNavbarBrandComponent,
    SiNavbarItemComponent,
    SiNavbarProfileMenuComponent,
    SiTranslateModule
  ],
  host: {
    ngSkipHydration: 'true'
  }
})
export class SiNavbarPrimaryComponent implements OnChanges, NavbarWithDropdowns {
  /**
   * List of navbar items which should be displayed at the left (in LTR) side next to the
   * banner.
   */
  @Input() primaryItems: MenuItem[] = [];
  /**
   * List of account dropdown elements (defined by `title` and `link`).
   *
   * The menu item can have submenu items (supplying `items`: MenuItem[]).
   * Submenu items can be divided into groups by separators. A separator is
   * an item with only '-' set as `title`.
   *
   * Alternatively, you can can create a custom content by putting your html
   * code between the <si-navbar-primary> tags. In this case you don't need this
   * property (will be ignored if you set anyway).
   */
  @Input() accountItems?: MenuItem[];
  /**
   * Account settings name (`title`) and profile picture (`image` or `icon`)
   */
  @Input() account?: AccountItem;
  /**
   * URL of the navbar brand.
   */
  @Input() logoUrl?: string;
  /**
   * app title that is rendered instead of the logo
   */
  @Input() appTitle?: string;
  /**
   * Configurable home link that is used at the logo and app title.
   * Use `undefined` to disable the link.
   */
  @Input() home?: Link = { link: '/' };
  /**
   * title for the launchpad
   */
  @Input() appSwitcherTitle = $localize`:@@SI_LAUNCHPAD.TITLE:Launchpad`;

  /**
   * sub-title for the launchpad
   */
  @Input()
  appSwitcherSubTitle = $localize`:@@SI_LAUNCHPAD.SUB_TITLE:Access all your apps`;

  /**
   * Title or translate key for the favorite apps section.
   */
  @Input() favoriteAppsTitle = $localize`:@@SI_LAUNCHPAD.FAVORITE_APPS:Favorite apps`;

  /**
   * Title or translate key for the default apps section.
   */
  @Input() defaultAppsTitle = $localize`:@@SI_LAUNCHPAD.DEFAULT_CATEGORY_TITLE:Apps`;

  /**
   * Title or translate key for the show more apps button.
   */
  @Input() showMoreAppsTitle = $localize`:@@SI_LAUNCHPAD.SHOW_MORE:Show more`;

  /**
   * Title or translate key for the show less apps button.
   */
  @Input() showLessAppsTitle = $localize`:@@SI_LAUNCHPAD.SHOW_LESS:Show less`;

  /**
   * All app items shown in the launchpad. The launchpad will not be visible if the
   * app items are undefined. The launchpad will be visible if the app items are an
   * empty array.
   */
  @Input() appItems?: AppItem[];
  /**
   * Like `appItems` but with the addition of categories. If this is set, `appItems` is ignored.
   */
  @Input() appCategoryItems?: AppItemCategory[];

  /**
   * Allow the user to favorite apps which will then be displayed at the top.
   */
  @Input({ transform: booleanAttribute }) appItemsFavorites = false;
  /**
   * "all apps" link in the launchpad
   */
  @Input() allAppsLink?: MenuItem;
  /**
   * Specifies whether the component should automatically be focused as soon as it is loaded.
   */
  @Input({ transform: booleanAttribute }) focusOnLoad = false;

  /**
   * Marks the navbar as primary navigation element. Needed for a11y (screen reader).
   * Only one element should be primary. If multiple navbars are used, it's up to the
   * user of the components to label them in the correct order.
   */
  @Input() navAriaLabel = 'Primary';

  /**
   * Text to close the launchpad. Needed for a11y.
   */
  @Input() closeAppSwitcherText = $localize`:@@SI_LAUNCHPAD.CLOSE:Close launchpad`;

  /**
   * Text for the launchpad icon. Needed for a11y.
   */
  @Input() openAppSwitcherText = $localize`:@@SI_NAVBAR.OPEN_LAUNCHPAD:Open launchpad`;

  /**
   * Text or translate key for the toggle navigation icon. Needed for a11y.
   */
  @Input() toggleNavigationText = $localize`:@@SI_NAVBAR.TOGGLE_NAVIGATION:Toggle navigation`;

  /**
   * Aria label for the main menu landmark
   */
  @Input() ariaLabelMainMenu = 'Header main';

  /**
   * Aria label for the secondary menu landmark
   */
  @Input() ariaLabelSecondaryMenu = 'Header secondary';

  @Output() readonly appItemFavoriteChanged = new EventEmitter<[AppItem, boolean]>();

  @ViewChild('navbar', { static: true }) navbar!: ElementRef<HTMLElement>;

  /** @internal */
  readonly quickActionBadgeCount = signal(0);

  protected showMobileNav = false;
  protected accountItem?: AccountItem;
  protected selectedItemTitle?: string;
  protected appsVisible = false;

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private changeDetectorRef = inject(ChangeDetectorRef);

  /** @internal */
  readonly inlineDropdown = inject(BreakpointObserver)
    .observe(`(min-width: ${BOOTSTRAP_BREAKPOINTS.smMinimum}px)`)
    .pipe(map(({ matches }) => !matches));

  constructor() {
    this.setSelectedItemTitle(this.router.url);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.setSelectedItemTitle((event as NavigationEnd).url);
      this.appsVisible = false;
    });
  }

  private setSelectedItemTitle(url: string): void {
    setTimeout(() => {
      const compareSelected = (item: MenuItem): boolean => {
        const commands = Array.isArray(item.link) ? item.link : [item.link];
        const urlTree = this.router.createUrlTree(commands as any[], { relativeTo: this.route });
        const serialized = this.router.serializeUrl(urlTree);
        return serialized === url;
      };

      const selected =
        this.primaryItems.find(compareSelected) ??
        this.primaryItems.find(item => !!item.items?.find(compareSelected));
      this.changeDetectorRef.markForCheck();
      this.selectedItemTitle = selected?.title ?? '';
    });
  }

  ngOnChanges(): void {
    if (this.account) {
      this.accountItem = Object.assign({ items: this.accountItems }, this.account) as AccountItem;
    } else {
      this.accountItem = undefined;
    }
  }

  onDropdownItemTriggered(): void {
    this.hideMobileNav();
  }

  protected toggleAppSwitcher(): void {
    this.appsVisible = !this.appsVisible;
  }

  protected hideAppSwitcher(): void {
    this.appsVisible = false;
  }

  protected toggleMobileNav(): void {
    this.showMobileNav = !this.showMobileNav;
  }

  hideMobileNav(): void {
    this.showMobileNav = false;
  }

  @HostListener('document:click', ['$event.target'])
  protected onClick(targetElement: Node): void {
    const clickedInside = this.navbar.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showMobileNav = false;
    }
  }
}
