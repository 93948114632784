import { NgClass } from '@angular/common';
import { booleanAttribute, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { STATUS_ICON, StatusType } from '@simpl/element-ng/common';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-inline-notification',
  templateUrl: './si-inline-notification.component.html',
  styleUrl: './si-inline-notification.component.scss',
  standalone: true,
  imports: [NgClass, SiLinkDirective, SiIconComponent, SiTranslateModule]
})
export class SiInlineNotificationComponent implements OnChanges {
  /**
   * Status of inline notification.
   */
  @Input({ required: true }) severity!: StatusType;

  /**
   * Heading of the message.
   */
  @Input() heading?: string;

  /**
   * Main message of this inline notification.
   */
  @Input({ required: true }) message!: string;

  /**
   * Optional link action for inline notification events.
   */
  @Input() action?: Link;

  /**
   * Params passed to the translation pipe
   */
  @Input() translationParams?: { [key: string]: any } = {};

  /**
   * Displays in embedded style, w/o radius and indicator bar
   */
  @Input({ transform: booleanAttribute }) embedded = false;

  protected icon = STATUS_ICON.info;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.severity && this.severity) {
      this.icon = STATUS_ICON[this.severity];
    }
  }
}
