import { Injectable, NgZone } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { Subscription, timer } from 'rxjs';

import { GmsElement } from '../elements/gms-element';

/**
 * Provides element's visibility and brush related properties blinking
 */
@Injectable()
export class ElementBrushService {

  private readonly traceModule: string = 'gmsSnapins_ElementBrushService';

  private _timer: any;
  private _timerSubscription: Subscription;
  private _blinkVisible = false;

  private readonly elements: GmsElement[] = new Array<GmsElement>();

  public constructor(private readonly traceService: TraceService, private readonly zone: NgZone) {
    this.traceService.info(this.traceModule, 'Element Brush service created.');
    this.zone.runOutsideAngular(() => {
      this._timer = timer(100, 500);
    });
  }

  public async addElement(element: GmsElement): Promise<void> {
    if (element === undefined) {
      return;
    }
    if (this.elements.find(x => x.Id === element.Id) === undefined) {
      this.elements.push(element);
      this.UpdateTimer();
    }
  }
  /**
   * remove element from the collection of blinking items
   * @param element
   */
  public async removeElement(element: GmsElement): Promise<void> {
    if (element === undefined) {
      return;
    }
    const index: number = this.elements.findIndex(x => x.Id === element.Id);
    if (index >= 0) {
      const el: GmsElement = this.elements[index];
      // check if element is not blinking and has no blinking brushes
      if (!el.Blinking && !element.HasBlinkColors) {
        this.elements.splice(index);
        el.UpdateVisible();
      }
    }
  }

  /**
   * remove element from the collection of blinking items
   * @param element
   */
  public async forceRemoveElement(element: GmsElement): Promise<void> {
    if (element === undefined) {
      return;
    }
    const index: number = this.elements.findIndex(x => x.Id === element.Id);
    if (index >= 0) {
      this.elements.splice(index);
    }
  }

  /**
   * clear elements collection and unsubscribe from updates
   */
  public unsubscribe(): void {

    if (this._timerSubscription !== undefined) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }

    if (this.elements !== undefined) {
      // this.elements.length = 0;
      this.elements.splice(0, this.elements.length);
    }
  }

  /**
   *
   */
  private UpdateTimer(): void {
    if (this.elements === undefined) {
      return;
    }
    if (this._timerSubscription === undefined) {
      this._timerSubscription = this._timer.subscribe(value => this.onTimerTick(value));
    }
  }

  /**
   *
   * @param counter
   */
  private onTimerTick(counter: number): void {
    if (this.elements === undefined || this.elements.length === 0) {
      if (this._timerSubscription !== undefined) {
        this._timerSubscription.unsubscribe();
        this._timerSubscription = undefined;
      }
      return;
    }

    this._blinkVisible = !this._blinkVisible;
    this.Blink();
  }

  /**
   * Handles element's blink status and element's active brushes, if any
   */
  private Blink(): void {
    if (this.elements === undefined) {
      return;
    }
    this.elements.forEach(element => {
      if (element.Blinking) {
        element.BlinkVisible = this._blinkVisible;
      } else if (element.HasBlinkColors) {
        element.UpdateBlinkColors(); // (check for fillcolor, backgroundcollor, strokecolor, etc)
      } else {
        this.removeElement(element);
      }
    });
  }
}
