@if (!showCompletionPage) {
  @if (!verticalLayout) {
    <div
      #container
      class="container-wizard"
      [class.display-one-step]="visibleSteps === 1"
      (siResizeObserver)="calculateNumberOfVisibleSteps()"
    >
      <ng-container *ngTemplateOutlet="cancelBtnHorizontal" />
      <ng-container *ngTemplateOutlet="backBtnHorizontal" />
      <ng-container *ngTemplateOutlet="stepsTemplate" />
      <ng-container *ngTemplateOutlet="nextBtnHorizontal" />
    </div>

    <ng-container *ngTemplateOutlet="stepContent" />
    <ng-container *ngTemplateOutlet="saveBtn" />
  } @else {
    <div
      #container
      class="container-wizard vertical"
      [class.display-one-step]="visibleSteps === 1"
      (siResizeObserver)="calculateNumberOfVisibleSteps()"
    >
      <ng-container *ngTemplateOutlet="stepsTemplate" />
      <div class="steps-content">
        <ng-container *ngTemplateOutlet="stepContent" />
      </div>
    </div>

    <ng-container *ngTemplateOutlet="footerVertical" />
  }
}

@if (showCompletionPage) {
  <div class="text-center">
    <i class="completion-icon-size text-success element-checked"></i>
    <p class="si-title-1"> {{ completionText | translate }}</p>
  </div>
}

<ng-template #stepsTemplate>
  <div class="container-steps" [class.small]="isSmall" [class.vertical]="verticalLayout">
    @for (
      step of steps;
      track stepIndex;
      let stepIndex = $index;
      let lastIndex = $last;
      let firstIndex = $first
    ) {
      @if (isVisibleStep(stepIndex)) {
        <div class="step" [class.small]="isSmall">
          @if (!firstIndex && !isVisibleStep(stepIndex - 1)) {
            <div
              class="line dashed previous"
              [class.vertical]="verticalLayout"
              [ngClass]="getStateClass(stepIndex + 1)"
            ></div>
          }
          <a
            class="focus-none"
            [ngClass]="getStateClass(stepIndex)"
            [attr.aria-current]="getAriaCurrent(stepIndex)"
            [attr.href]="!canActivate(stepIndex) || !currentStep.isNextNavigable ? null : '#'"
            (click)="activateStep($event, stepIndex)"
          >
            <i [ngClass]="[getState(step, stepIndex)]"></i>
            <div
              class="title si-title-2"
              [class.text-center]="!verticalLayout"
              [attr.aria-disabled]="getAriaDisabled(stepIndex)"
              >{{ step.heading | translate }}</div
            >
          </a>
          @if (!lastIndex) {
            <div
              class="line"
              [class.vertical]="verticalLayout"
              [ngClass]="getStateClass(stepIndex + 1)"
              [class.dashed]="!isVisibleStep(stepIndex + 1)"
            ></div>
          }
        </div>
      }
    }
  </div>
</ng-template>

<ng-template #stepContent>
  <div>
    <ng-content />
  </div>
</ng-template>

<ng-template #cancelBtnHorizontal>
  @if (hasCancel) {
    <div #cancelButtonContainer class="me-7">
      <div class="wizard-btn-container" (click)="cancel.emit()">
        <button
          type="button"
          class="btn btn-sm btn-circle btn-secondary element-cancel mt-2 mb-4"
          [attr.aria-label]="cancelText | translate"
        >
        </button>
        <div class="si-title-2 text-primary">{{ cancelText | translate }}</div>
      </div>
    </div>
  }
</ng-template>

<ng-template #footerVertical>
  <div #buttonContainer class="d-flex flex-row justify-content-between px-5">
    <div>
      @if (hasCancel) {
        <button
          #cancelButtonContainer
          type="button"
          class="btn btn-tertiary"
          (click)="cancel.emit()"
        >
          {{ cancelText | translate }}
        </button>
      }
    </div>
    <div class="d-flex">
      @if (!hideNavigation) {
        <button
          type="button"
          class="btn btn-secondary mb-2 me-6"
          [class.d-none]="index === 0"
          (click)="back(1)"
        >
          {{ backText | translate }}
        </button>

        <button
          type="button"
          class="btn btn-primary mb-2"
          [disabled]="!currentStep.isValid"
          [class.d-none]="index === steps.length - 1"
          (click)="next(1)"
        >
          {{ nextText | translate }}
        </button>
      }

      <ng-container *ngTemplateOutlet="saveBtn" />
    </div>
  </div>
</ng-template>

<ng-template #backBtnHorizontal>
  <div #buttonContainer class="wizard-btn-container">
    @if (!hideNavigation) {
      <div class="back" [class.invisible]="index === 0" (click)="back(1)">
        <button
          type="button"
          class="btn btn-secondary btn-circle flip-rtl element-left-4 mb-2"
          [attr.aria-label]="backText | translate"
        >
        </button>
        <div class="si-title-2 text-primary">{{ backText | translate }}</div>
      </div>
    }
  </div>
</ng-template>

<ng-template #nextBtnHorizontal>
  <div class="wizard-btn-container" [class.wizard-text-deactivate]="!currentStep.isValid">
    @if (!hideNavigation) {
      <div class="next" [class.invisible]="index === steps.length - 1" (click)="next(1)">
        <button
          type="button"
          class="btn btn-primary btn-circle flip-rtl element-right-4 mb-2"
          [disabled]="!currentStep.isValid"
          [attr.aria-label]="nextText | translate"
        >
        </button>
        <div
          class="si-title-2 text-primary"
          [class.disabled]="!currentStep.isValid"
          [attr.aria-disabled]="!currentStep.isValid"
          >{{ nextText | translate }}</div
        >
      </div>
    }
  </div>
</ng-template>

<ng-template #saveBtn>
  @if (!hideSave) {
    <div [class.container-wizard]="!verticalLayout">
      @if (index === steps.length - 1) {
        <button
          type="button"
          class="btn btn-info save"
          [disabled]="!currentStep.isValid || !currentStep.isNextNavigable"
          (click)="save()"
          >{{ saveText | translate }}</button
        >
      }
    </div>
  }
</ng-template>
