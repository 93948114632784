export enum GmsElementPropertyType {
  // Layout
  X = `X`,
  Y = `Y`,
  PositionType = `PositionType`,
  Width = `width`,
  Height = `height`,
  Angle = `Angle`,
  AngleCenterX = `AngleCenterX`,
  AngleCenterY = `AngleCenterY`,
  RotationSpeed = `RotationSpeed`,
  RotationSteps = `RotationSteps`,
  FlipX = `FlipX`,
  FlipY = `FlipY`,
  Visible = `Visible`,
  Blinking = `Blinking`,
  Opacity = `opacity`,
  ScaleFactor = `ScaleFactor`,

  // Clip
  ClipLeft = `ClipLeft`,
  ClipTop = `ClipTop`,
  ClipRight = `ClipRight`,
  ClipBottom = `ClipBottom`,

  // Colors
  Stroke = `stroke`,
  StrokeColorWrap = `StrokeColor`,
  StrokeOpacity = `stroke-opacity`,
  StrokeWidth = `stroke-width`,
  StrokeLineCap = `stroke-linecap`,
  StrokeDashArray = `stroke-dasharray`,
  StrokeLineJoin = `stroke-linejoin`,
  Fill = `fill`,
  FillColorWrap = `FillColor`,
  FillOpacity = `fill-opacity`,
  Filter = `filter`,
  Background = `backcolor`,
  BackColorWrap = `BackColor`,

  // General
  Tooltip = `Tooltip`,
  Id = `Id`,
  Description = `Description`,
  SelectionRef = `SelectionRef`,
  DisableSelection = `DisableSelection`,
  Zoomable = `Zoomable`,
  MinVisibility = `MinVisibility`,
  MaxVisibility = `MaxVisibility`,
  ShadowDepth = `ShadowDepth`,
  ShadowColor = `ShadowColor`,
  ShadowDirection = `ShadowDirection`,

  // Replication
  ReplicationIndexRange = `ReplicationIndexRange`,
  ReplicationOrientation = `ReplicationOrientation`,
  MaxReplicationExtent = `MaxReplicationExtent`,
  ReplicationSpace = `ReplicationSpace`,

  // Commands
  HasCommand = `HasCommand`,
  LinkReference = `LinkReference`,
  CommandRule = `CommandRule`,
  CommandTrigger = `CommandTrigger`,
  CommandParameter = `CommandParameter`,
  LinkDescription = `LinkDescription`,
  IsCommandTriggerEnabled = `IsCommandTriggerEnabled`,
  CursorType = `CursorType`,
  CommandDisabled = `CommandDisabled`,
  CommandDisabledStyle = `CommandDisabledStyle`,
  IsCommandTooltipEnabled = `IsCommandTooltipEnabledX`,

  // Graphic
  BoundingRect = `BoundingRect`,
  ActualWidth = `ActualWidth`,
  ActualHeight = `ActualHeight`,
  GraphicType = `GraphicType`,
  // Note: There is no support yet to:
  // a temp solution for an element to hide, if the element is related to a command
  CommandRelated = `CommandRelated`,

  // a CoverageArea element to show/hide
  CoverageArea = `CoverageArea`,
  // Graphic may provide element(s) with coverage area(s)
  HasCoverageArea = `HasCoverageArea`,

  // Command Parameter Control
  ControlType = `ControlType`,
  CommandParameterName = `CommandParameterName`,
  Minimum = `Minimum`,
  Maximum = `Maximum`,
  CommandPrecision = `CommandPrecision`,
  CommandFontFamily = `CommandFontFamily`,
  CommandFontSize = `CommandFontSize`,
  CommandFillColor = `FillColor`,
  // Command Parameter Control - Spinner
  ChangeAmount = `ChangeAmount`,
  UpdateDelay = `UpdateDelay`,
  // Command Parameter Control - Slider, Rotator
  UpdateInterval = `UpdateInterval`,
  TickFrequency = `TickFrequency`,
  IsSnapToTickEnabled = `IsSnapToTickEnabled`,
  MinimumAngle = `MinimumAngle`,
  TotalRotationAngle = `TotalRotationAngle`,
  SliderShadow = `SliderShadow`,
  MinimumLength = `MinimumLength`,
  MaximumLength = `MaximumLength`,
  // Pipe Background Rect
  x = `x`,
  y = `y`,
  // CoverageArea
  CoverageAreaReference = `CoverageAreaReference`,
  IsGrayscale = `IsGrayscale`
}

export enum GmsGroupElementPropertyType {
  IsCommandGroupEnabled = `IsCommandGroupEnabled`
}

export enum GmsLayerElementPropertyType {
  Discipline = `Discipline`,
  Use = `Use`,
  Depths = `Depths`,
  Category = `Category`
}

export enum GmsRectElementPropertyType {
  RadiusX = `RadiusX`,
  RadiusY = `RadiusY`
}

export enum GmsEllipseElementPropertyType {
  EllipseType = `EllipseType`,
  StartAngle = `StartAngle`,
  EndAngle = `EndAngle`,
  IsArcClosed = `IsArcClosed`
}

export enum GmsPathElementPropertyType {
  // Data
  Data = `d`,
  CornerOffset = `CornerOffset`, // NOTE:
  // Color
  Fill = `fill`, // NOTE: update to FillColor
  FillOpacity = `FillOpacity`, // NOTE: reomve once fill color is read

  // Stroke
  Stroke = `stroke`,
  StrokeWidth = `stroke-width`,
  StrokeOpacity = `stroke-opacity`,
  StrokeDashArray = `stroke-dasharray`,
  ArrowStartShape = `ArrowStartShape`,
  ArrowEndShape = `ArrowEndShape`,
  StartEndArrows = `StartEndArrows`,
  StartArrow = `StartArrow`,
  EndArrow = `EndArrow`
}

export enum GmsTextElementPropertyType {
  Text = `Text`,
  TextType = `TextType`,
  // NOTE: uncomment it, if we plan to support it.
  // TextEditable = <any>"TextEditable",
  Precision = `Precision`, // not yet
  Units = `Units`, // not yet
  HorizontalTextAlignment = `HorizontalTextAlignment`,
  VerticalTextAlignment = `VerticalTextAlignment`,
  Trimming = `Trimming`,
  Wrapping = `Wrapping`,
  FontFamily = `font-family`,
  FontSize = `font-size`,
  FontWeight = `font-weight`,
  Bold = `Bold`,
  FontStyle = `font-style`, // normal, italic, oblique
  Italic = `Italic`,
  Strikethrough = `Strikethrough`,
  Underline = `Underline`,
  TextDecoration = `text-decoration`,
  TextHeight = `TextHeight`,
  Baseline = `Baseline`,
  DecimalOffset = `DecimalOffset`,
  Anchor = `text-anchor`
}

export enum GmsPolygonElementPropertyType {
  Offset = `Offset` // NOTE: Offset is not exported to SVG yet.
}

export enum GmsImageElementPropertyType {
  Href = `xlink:href`,
  Stretch = `Stretch`,
  DpiX = `DpiX`,
  DpiY = `DpiY`,
  ImageWidth = `ImageWidth`,
  ImageHeight = `ImageHeight`
}

export enum GmsAnimatedGifElementPropertyType {
  RunAnimation = `RunAnimation`,
  SpeedAnimation = `SpeedAnimation`
}

export enum GraphicType {
  Graphic,
  GraphicTemplate,
  Symbol
}
