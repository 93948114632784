import { inject, Injectable } from '@angular/core';
import {
  DeleteConfirmationDialogResult,
  SiActionDialogService,
  SiToastNotificationService
} from '@simpl/element-ng';
import { clone } from '@simpl/object-browser-ng/common';
import { AnyProperty } from '@simpl/object-browser-ng/property-viewer';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { SceneEditorApi } from '../api/scene-editor.api';
import { EMPTY_SCENE_MODEL } from '../helpers/default-models';
import {
  DataPoint,
  DataPointInfo,
  Scene,
  SceneCommand,
  SceneModel
} from '../interfaces/scene-editor';

interface ApiSubscription {
  objectId: string;
  subject: ReplaySubject<SceneModel>;
  apiSubscription?: Subscription;
}

interface SceneObjectData {
  model: SceneModel;
  copyForUndoAllChanges: SceneModel;
  subscription: ApiSubscription;
}

const DATAPOINT_ID_SEPERATOR = '#';

@Injectable()
export class SiSceneEditorService {
  public isProcessing$ = new BehaviorSubject<boolean>(false);
  public hasChanges$ = new BehaviorSubject<boolean>(false);
  public isValid$ = new BehaviorSubject<boolean>(false);
  public propertyInfo$: Subject<DataPointInfo> = new Subject<DataPointInfo>();
  public aboutDataPointSubject = new Subject<DataPointInfo>();

  private objects: { [key: string]: SceneObjectData } = {};

  private sceneApi = inject(SceneEditorApi);
  private siToastService = inject(SiToastNotificationService);
  private siModal = inject(SiActionDialogService);

  public setIsDirty(changes: boolean): void {
    this.hasChanges$.next(changes);
  }

  getSceneModel(objectId: string): Observable<SceneModel> {
    if (this.objects[objectId]) {
      return this.objects[objectId].subscription.subject;
    } else {
      const subscription: ApiSubscription = {
        objectId,
        subject: new ReplaySubject<SceneModel>(1)
      };
      const observable = subscription.subject.pipe(finalize(() => this.cleanup(subscription)));
      subscription.apiSubscription = this.sceneApi.getSceneModel(objectId).subscribe(localModel => {
        const model: SceneModel = localModel ?? clone(EMPTY_SCENE_MODEL);
        const copyForUndoAllChanges = clone(model);
        this.objects[objectId] = {
          subscription,
          model,
          copyForUndoAllChanges
        };
        subscription.subject.next(model);

        this.setIsDirty(false);
      });
      return observable;
    }
  }

  private updateModel(objectId: string): SceneModel {
    if (this.objects[objectId]) {
      this.objects[objectId].subscription.subject.next(this.objects[objectId].model);
    }
    return this.objects[objectId].model;
  }

  private cleanup(subscriptions: ApiSubscription): void {
    if (subscriptions.subject.observers.length <= 1) {
      if (subscriptions.apiSubscription) {
        subscriptions.apiSubscription.unsubscribe();
      }
      subscriptions.subject.complete();
      delete this.objects[subscriptions.objectId];
    }
  }

  addNewScene(objectId: string): Scene {
    const numberOfScenes = this.objects[objectId].model.scenes.length + 1;
    let newScene: Scene;
    if (this.objects[objectId].model.scenes?.length) {
      newScene = clone(this.objects[objectId].model.scenes[0]);
      newScene.name = 'Scene ' + numberOfScenes;
      newScene.id = 'Scene_' + numberOfScenes;
      const dataPointslist = this.objects[objectId].model.commands.filter(
        obj => obj.sceneId === this.objects[objectId].model.scenes[0].id
      );

      dataPointslist.forEach(element => {
        const newDp = clone(element);
        newDp.sceneId = 'Scene_' + numberOfScenes;
        this.objects[objectId].model.commands.push(newDp);
      });
      this.objects[objectId].model.scenes.push(newScene);
    } else {
      newScene = {
        name: 'Scene ' + numberOfScenes,
        id: 'Scene_' + numberOfScenes
      };
      this.objects[objectId].model.scenes.push(newScene);
    }

    this.setIsDirty(true);
    return newScene;
  }

  public addDataPoint(objectId: string): Observable<DataPoint[]> {
    return this.sceneApi.addDataPoint(objectId);
  }

  testScene(sceneId: string, objectId: string): void {
    this.sceneApi.testScene(objectId, sceneId, this.objects[objectId].model);
  }

  learnValues(sceneId: string, objectId: string): Observable<SceneCommand[]> {
    return this.sceneApi.learnValues(objectId, sceneId, this.objects[objectId].model).pipe(
      tap(ev => {
        this.setIsDirty(true);
      })
    );
  }

  saveSceneModel(objectId: string): Observable<boolean> {
    this.setProcessingStatus(objectId, true);
    return this.sceneApi.saveSceneModel(objectId, this.objects[objectId].model).pipe(
      tap(
        isSuccess => {
          if (isSuccess) {
            this.objects[objectId].copyForUndoAllChanges = clone(this.objects[objectId].model);
            if (this.hasChanges$.value) {
              this.setIsDirty(false);
            }
          } else {
            this.siToastService.queueToastNotification(
              'danger',
              'SCENE_EDITOR.TEXT_SHORT.FAILED_TO_SAVE',
              'SCENE_EDITOR.MESSAGES.SAVE_SCENE_FAILURE'
            );
          }
          this.setProcessingStatus(objectId, false);
        },
        error => {
          this.siToastService.queueToastNotification(
            'danger',
            'SCENE_EDITOR.TEXT_SHORT.FAILED_TO_SAVE',
            error.ERROR_MESSAGES
          );
          this.setProcessingStatus(objectId, false);
        }
      )
    );
  }

  saveSceneModelAs(objectId: string): Observable<boolean> {
    this.setProcessingStatus(objectId, true);
    return this.sceneApi.saveSceneModelAs(objectId, this.objects[objectId].model).pipe(
      tap(
        isSuccess => {
          if (isSuccess) {
            this.objects[objectId].copyForUndoAllChanges = clone(this.objects[objectId].model);
            this.setIsDirty(false);
          } else {
            this.siToastService.queueToastNotification(
              'danger',
              'SCENE_EDITOR.TEXT_SHORT.FAILED_TO_SAVE',
              'SCENE_EDITOR.MESSAGES.SAVE_AS_SCENE_FAILURE'
            );
          }
          this.setProcessingStatus(objectId, false);
        },
        error => {
          this.siToastService.queueToastNotification(
            'danger',
            'SCENE_EDITOR.TEXT_SHORT.FAILED_TO_SAVE',
            error.ERROR_MESSAGES
          );
          this.setProcessingStatus(objectId, false);
        }
      )
    );
  }

  deleteSceneModel(objectId: string, description?: string): Observable<boolean> {
    this.setProcessingStatus(objectId, true);
    const observable: Observable<boolean> = new Observable(observer => {
      this.siModal
        .showDeleteConfirmationDialog(
          'SCENE_EDITOR.MESSAGES.DELETE_SCHEDULE_CONFIRMATION',
          'SCENE_EDITOR.DIALOG_TEXT_SHORT.DELETE_SCHEDULER',
          'SCENE_EDITOR.DIALOG_TEXT_SHORT.DELETE',
          'SCENE_EDITOR.DIALOG_TEXT_SHORT.CANCEL',
          { scheduleObjectName: description ? description : objectId }
        )
        .subscribe(result => {
          switch (result) {
            case DeleteConfirmationDialogResult.Delete:
              this.sceneApi.deleteSceneModel(objectId).subscribe(
                success => {
                  if (!success) {
                    this.siToastService.queueToastNotification(
                      'danger',
                      'SCENE_EDITOR.TEXT_SHORT.FAILED_TO_DELETE',
                      'SCENE_EDITOR.MESSAGES.DELETE_FAILURE'
                    );
                  }
                  this.setProcessingStatus(objectId, false);
                  observer.next(success);
                  observer.complete();
                },
                error => {
                  this.siToastService.queueToastNotification(
                    'danger',
                    'SCENE_EDITOR.TEXT_SHORT.FAILED_TO_DELETE',
                    error.ERROR_MESSAGES
                  );
                  this.setProcessingStatus(objectId, false);
                  observer.next(false);
                  observer.complete();
                }
              );
              break;
            case DeleteConfirmationDialogResult.Cancel:
              this.setProcessingStatus(objectId, false);
              observer.next(false);
              observer.complete();
              break;
          }
        });
    });
    return observable;
  }

  discardChanges(objectId: string): void {
    this.objects[objectId].model = clone(this.objects[objectId].copyForUndoAllChanges);
    this.objects[objectId].subscription.subject.next(this.objects[objectId].model);
    this.setIsDirty(false);
    this.isValid$.next(false);
  }

  setModel(objectId: string, sceneModel: SceneModel): void {
    this.objects[objectId].model = clone(sceneModel);
    this.objects[objectId].subscription.subject.next(this.objects[objectId].model);
  }

  aboutDataPoint(dataPointInfo: DataPointInfo): void {
    dataPointInfo.dataPointId = this.getOriginalId(dataPointInfo.dataPointId);
    this.aboutDataPointSubject.next(dataPointInfo);
  }

  showAboutDataPoint(selectPropertyInfo: DataPointInfo): void {
    if (selectPropertyInfo.dataPointInfo) {
      this.propertyInfo$.next(selectPropertyInfo);
    }
  }

  toggleSceneReadOnly(objectId: string, sceneId: string): void {
    // TODO: implement
  }

  getCommandsForDataPoint(objectId: string, dataPointId: string): SceneCommand[] {
    const model = this.objects[objectId]?.model;
    if (model?.commands) {
      return model.commands.filter(obj => obj.dataPointId === dataPointId);
    }
    return [];
  }

  getCommandsForScene(objectId: string, sceneId: string): SceneCommand[] {
    const model = this.objects[objectId]?.model;
    if (model?.commands) {
      return model.commands.filter(obj => obj.sceneId === sceneId);
    }
    return [];
  }

  getDataPoints(objectId: string, dataPointIds: string[]): DataPoint[] {
    const model = this.objects[objectId]?.model;
    if (model?.commands) {
      return model.dataPoints.filter(obj => dataPointIds.includes(obj.id));
    }
    return [];
  }

  getScenes(objectId: string, sceneIds: string[]): Scene[] {
    const model = this.objects[objectId]?.model;
    if (model?.commands) {
      return model.scenes.filter(obj => sceneIds.includes(obj.id));
    }
    return [];
  }

  getProperties(objectId: string, dataPointId: string): Observable<AnyProperty[]> {
    if (typeof this.sceneApi.getProperties === 'function') {
      return this.sceneApi.getProperties(objectId, this.getOriginalId(dataPointId));
    }
    return of([]);
  }

  updatePropertyValueInModel(
    objectId: string,
    sceneCommands: SceneCommand[],
    dataPoints?: DataPoint[]
  ): void {
    sceneCommands.forEach(sceneCommand => {
      const index = this.objects[objectId].model.commands.findIndex(
        command =>
          command.sceneId === sceneCommand.sceneId &&
          command.dataPointId === sceneCommand.dataPointId
      );
      if (index !== -1) {
        this.objects[objectId].model.commands[index] = sceneCommand;
      }
    });
    if (dataPoints) {
      dataPoints.forEach(datapoint => {
        const index = this.objects[objectId].model.dataPoints.findIndex(
          dp => dp.id === datapoint.id
        );
        if (index !== -1) {
          this.objects[objectId].model.dataPoints[index] = datapoint;
        }
      });
    }
  }

  removeDataPoint(objectId: string, dataPoint: DataPoint): void {
    // first lets remove the references
    this.objects[objectId].model.commands = this.objects[objectId].model.commands.filter(
      command => command.dataPointId !== dataPoint.id
    );

    // now all the references are removed, we may remove the original datapoint object
    const index = this.objects[objectId].model.dataPoints.indexOf(dataPoint);
    if (index !== -1) {
      this.objects[objectId].model.dataPoints.splice(index, 1);
    }
    this.updateModel(objectId);
    this.setIsDirty(true);
  }

  removeScene(objectId: string, scene: Scene): void {
    this.objects[objectId].model.commands = this.objects[objectId].model.commands.filter(
      command => command.sceneId !== scene.id
    );

    const index = this.objects[objectId].model.scenes.indexOf(scene);
    if (index !== -1) {
      this.objects[objectId].model.scenes.splice(index, 1);
    }

    this.setIsDirty(true);
  }

  setReadOnly(objectId: string, isReadonly: boolean): void {
    this.objects[objectId].model.readonly = isReadonly;
    this.objects[objectId].subscription.subject.next(this.objects[objectId].model);
  }

  generateUniqueDataPoints(
    objectId: string,
    datapoints: DataPoint[],
    earlirAddedDatapoints?: DataPoint[]
  ): DataPoint[] {
    const newDataPoints: DataPoint[] = [];
    const listToIterate = earlirAddedDatapoints
      ? earlirAddedDatapoints
      : this.objects[objectId].model.dataPoints;
    datapoints.forEach(newlyAddedDp => {
      const sameDps = listToIterate.filter(dataPoint => dataPoint.id.includes(newlyAddedDp.id));
      if (sameDps?.length) {
        const newDp = clone(newlyAddedDp);
        newDp.id = newDp.id + DATAPOINT_ID_SEPERATOR + sameDps?.length;
        newDataPoints.push(newDp);
      } else {
        newDataPoints.push(newlyAddedDp);
      }
    });
    return newDataPoints;
  }

  getOriginalId(dataPointId: string): string {
    return dataPointId.includes(DATAPOINT_ID_SEPERATOR)
      ? dataPointId.substring(0, dataPointId.lastIndexOf(DATAPOINT_ID_SEPERATOR))
      : dataPointId;
  }

  getSameDataPoints(objectId: string, dataPointId?: string): DataPoint[] {
    if (dataPointId) {
      const actualObjectId = this.getOriginalId(dataPointId);
      const sameDataPoints = this.objects[objectId].model.dataPoints.filter(obj =>
        obj.id.includes(actualObjectId)
      );
      return sameDataPoints;
    }
    return [];
  }

  navigateToDataPoint(dataPointInfo: DataPointInfo): void {
    dataPointInfo.dataPointId = this.getOriginalId(dataPointInfo.dataPointId);
    this.sceneApi.navigateToDataPoint(dataPointInfo);
  }

  private setProcessingStatus(objectId: string, isBusy: boolean): void {
    this.setReadOnly(objectId, isBusy);
    this.isProcessing$.next(isBusy);
  }
}
