import { NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, HostBinding, inject, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { SiPopoverDirective } from '@simpl/element-ng/popover';
import { Breakpoints, SiResponsiveContainerDirective } from '@simpl/element-ng/resize-observer';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import {
  SI_FORM_VALIDATION_ERROR_MAPPER,
  SiFormValidationErrorMapper
} from '../si-form-validation-error-mapper.provider';

export interface SiFormValidationError {
  controlName?: string;
  controlNameTranslationKey?: string;
  errorCode: string;
  errorCodeTranslationKey?: string;
  errorParams?: any;
}

@Component({
  selector: 'si-form-container',
  templateUrl: './si-form-container.component.html',
  styleUrl: './si-form-container.component.scss',
  standalone: true,
  imports: [NgTemplateOutlet, SiPopoverDirective, SiResponsiveContainerDirective, SiTranslateModule]
})
export class SiFormContainerComponent<TControl extends { [K in keyof TControl]: AbstractControl }> {
  /**
   * Set the form entity to the container to enable the overall form validation on in
   * the form container edit panel.
   */
  @Input() form?: FormGroup<TControl>;

  /**
   * A form container in readonly mode is only displaying the form content without ability
   * to change it. The edit panel with typically save and cancel buttons is hidden. Set
   * to true to display the edit panel.
   */
  @Input({ transform: booleanAttribute }) readonly = false;

  /**
   * The container hosts the form within a siResizeContainer to configure the breakpoint for
   * different screen sizes. Optionally, change the container breakpoints with the contentContainerBreakpoints
   * input.
   */
  @Input() contentContainerBreakpoints?: Breakpoints;

  /**
   * The title of the help link that is used to display the form validation problems within a popover.
   * @deprecated Will be removed in next major release.
   */
  @Input() helpTitle = $localize`:@@SI_FORM_CONTAINER.HELP:Help`;

  /** @deprecated Will be removed in next major release. */
  @Input({ transform: booleanAttribute }) enableValidationHelp = false;

  /**
   * In some scenarios, one may not want the form container to be responsible for the layout relevant
   * `si-container-[xs|...]` classes, but let this be done by a different, nested component, e.g. by a
   * group box. In these cases, the property should be set to true.
   */
  @Input({ transform: booleanAttribute }) disableContainerBreakpoints = false;

  /**
   * Every validation error has an errorCode. This map holds translate keys for error codes. The keys can
   * be used to display a translated message for each validation error. The defaults old english readable
   * key defaults for the Angular standard validators of the `Validators` class like `min`, `max` or `required`.
   *
   * Use the input to set your own translate keys for the form validators you need.
   */
  @Input() set errorCodeTranslateKeyMap(
    value: Map<string, string> | SiFormValidationErrorMapper | undefined
  ) {
    this.formErrorMapper = this.buildFormErrorMapper(value);
  }

  /**
   * A map the maps from control names of the form to their translate keys.
   * The initial map is empty and the user is responsible to add the required
   * translate keys.
   */
  @Input() controlNameTranslateKeyMap = new Map<string, string>();

  /**
   * Disables the automatic error printing in all nested {@link SiFormItemComponent}. Error printing will be enabled by default in v46.
   */
  @Input({ transform: booleanAttribute }) disableErrorPrinting = false;

  /**
   * A custom width value to be applied to all labels.
   *
   * @example labelWidth="100px".
   */
  @Input() @HostBinding('style.--si-form-label-width') labelWidth?: string;

  protected hasParentContainer = !!inject(SiFormContainerComponent, {
    optional: true,
    skipSelf: true
  });

  private applicationFormErrorMapper = inject(SI_FORM_VALIDATION_ERROR_MAPPER);

  /** @internal */
  formErrorMapper = this.buildFormErrorMapper({});

  /**
   * Indicates whether the user interacted with the form.
   * @returns `true`, if the user selected at least one form element and
   * [blurred]{@link https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event} by losing focus again
   * (e.g. by setting focus on another element), or by editing the content of a form element.
   * Otherwise `false`.
   */
  get userInteractedWithForm(): boolean {
    return !!this.form && (this.form.dirty || this.form.touched);
  }

  /**
   * Indicates whether content editor message shall be styled as success.
   * @returns `true`, if {@link SiFormContainerComponent.userInteractedWithForm} is true and
   * the form is valid.
   */
  get validFormContainerMessage(): boolean {
    return this.userInteractedWithForm && this.form!.status === 'VALID';
  }

  /**
   * Indicates whether the content editor message shall be styled as warning.
   * @returns `true`, if {@link SiFormContainerComponent.userInteractedWithForm} is true and
   * the form is invalid.
   */
  get invalidFormContainerMessage(): boolean {
    return this.userInteractedWithForm && this.form!.status === 'INVALID';
  }

  /**
   * Returns the validation errors of the form's control when the control name is provided. Otherwise,
   * returns all validation errors of the form. Returns an empty arry when no form is available or if
   * the name does not match to a control.
   * @param controlName An optional name of a control that is part of the form.
   *
   * @deprecated The {@link SiFormItemComponent} is able to display validation errors automatically when `siFormInput` directive is used.
   */
  getValidationErrors(controlName?: string): SiFormValidationError[] {
    if (!this.form) {
      return [];
    } else if (!controlName) {
      return this.getFormValidationErrorsPrivate(this.form);
    } else {
      const control = this.form.get(controlName);
      if (control) {
        return this.getFormValidationErrorsPrivate(control, controlName);
      } else {
        return [];
      }
    }
  }

  protected getControlNameTranslateKey(controlName: string): string | undefined {
    if (!this.controlNameTranslateKeyMap) {
      return undefined;
    }
    return this.controlNameTranslateKeyMap.get(controlName) ?? controlName;
  }

  protected getErrorCodeTranslateKey(
    errorCode: string,
    errorBody: any,
    controlName?: string
  ): string | undefined {
    let errorResolver: string | ((error: any) => string) | undefined;

    if (controlName) {
      errorResolver = this.formErrorMapper[`${controlName}.${errorCode}`];
    }
    errorResolver ??= this.formErrorMapper[errorCode];

    if (typeof errorResolver === 'function') {
      return errorResolver(errorBody);
    } else {
      return errorResolver;
    }
  }

  private buildFormErrorMapper(
    map: Map<string, string> | SiFormValidationErrorMapper | undefined
  ): SiFormValidationErrorMapper {
    let customMapper: SiFormValidationErrorMapper | undefined;
    if (map instanceof Map) {
      customMapper = Object.fromEntries(map.entries());
    } else if (map) {
      customMapper = map;
    }

    return {
      ...this.applicationFormErrorMapper,
      ...customMapper
    };
  }

  private getFormValidationErrorsPrivate(
    control: AbstractControl,
    controlName?: string
  ): SiFormValidationError[] {
    let errors: SiFormValidationError[] = [];

    // a form must either consist of
    // a) a formgroup (with nested form controls or groups) or
    // b) a single form control
    if (control instanceof FormGroup) {
      const formGroupErrors = this.getFormGroupErrors(control);
      errors = errors.concat(formGroupErrors);
      if (control.controls) {
        Object.keys(control.controls).forEach(key => {
          const formGroupControl = control.controls[key];
          const formGroupControlErrors = this.getFormValidationErrorsPrivate(formGroupControl, key);
          errors = errors.concat(formGroupControlErrors);
        });
      }
    } else if (control instanceof FormControl) {
      const errorCodes = control.errors;
      if (errorCodes) {
        Object.entries(errorCodes).forEach(([errorCode, errorBody]) => {
          errors.push({
            controlName,
            controlNameTranslationKey: controlName
              ? this.getControlNameTranslateKey(controlName)
              : undefined,
            errorCode,
            errorCodeTranslationKey: this.getErrorCodeTranslateKey(
              errorCode,
              errorBody,
              controlName
            ),
            errorParams: errorCodes[errorCode]
          });
        });
      }
    }

    return errors;
  }

  private getFormGroupErrors(formGroup: FormGroup): SiFormValidationError[] {
    const errors: SiFormValidationError[] = [];
    const errorCodes = formGroup.errors;
    if (errorCodes) {
      Object.entries(errorCodes).forEach(([errorCode, errorBody]) => {
        errors.push({
          errorCode,
          errorCodeTranslationKey: this.getErrorCodeTranslateKey(errorCode, errorBody),
          errorParams: errorCodes[errorCode]
        });
      });
    }
    return errors;
  }
}
