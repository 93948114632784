/* eslint-disable @angular-eslint/no-host-metadata-property */
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  QueryList,
  signal,
  Signal,
  SimpleChanges
} from '@angular/core';
import {
  isRTL,
  SiUIStateService,
  UIState,
  WebComponentContentChildren
} from '@simpl/element-ng/common';
import { fromEvent, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiSplitPartComponent } from './si-split-part.component';
import { SplitOrientation } from './si-split.interfaces';

interface Gutter {
  before: SiSplitPartComponent;
  after: SiSplitPartComponent;
  visible: Signal<boolean>;
}

@Component({
  selector: 'si-split',
  templateUrl: './si-split.component.html',
  styleUrl: './si-split.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // Signals cannot be used directly with @HostBinding. See: https://github.com/angular/angular/issues/53888#issuecomment-1888935225
  host: {
    '[class]': '_orientation()',
    '[style.grid-template-rows]': 'gridTemplateRows()',
    '[style.grid-template-columns]': 'gridTemplateColumns()',
    '[style.grid-template-areas]': 'gridTemplateAreas()'
  }
})
export class SiSplitComponent implements AfterContentInit, OnChanges {
  @Input() gutterSize = 16;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _orientation = signal<SplitOrientation>('horizontal');

  get orientation(): SplitOrientation {
    return this._orientation();
  }

  @Input() set orientation(value: SplitOrientation) {
    this._orientation.set(value);
  }

  @Input() sizes: number[] = [];

  /**
   * An optional stateId to uniquely identify a component instance.
   * Required for persistence of ui state.
   */
  @Input() stateId?: string;

  @Output() readonly sizesChange = new EventEmitter<number[]>();

  @WebComponentContentChildren(SiSplitPartComponent)
  @ContentChildren(SiSplitPartComponent)
  protected parts!: QueryList<SiSplitPartComponent>;
  protected gutters: Gutter[] = [];

  protected gridTemplateRows!: Signal<string>;
  protected gridTemplateColumns!: Signal<string>;
  protected gridTemplateAreas!: Signal<string>;

  private elementRef = inject(ElementRef<HTMLElement>);
  private ngZone = inject(NgZone);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private document = inject(DOCUMENT);
  private uiStateService = inject(SiUIStateService, { optional: true });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sizes && !changes.sizes.firstChange) {
      this.sizes.forEach((size, index) => {
        const part = this.parts.get(index);
        if (part) {
          part.fractionalSize.set(size);
          part.expandedSize.set(undefined);
        }
      });
      this.alignRelativeSizes();
    }
  }

  ngAfterContentInit(): void {
    this.parts.changes.subscribe(() => {
      this.gutters = [];

      for (let index = 0; index < this.parts.length; index++) {
        const component = this.parts.get(index)!;
        component.index = index;
        component.after = this.parts.get(index + 1);
        component.before = this.parts.get(index - 1);
        component.fractionalSize.set(this.sizes[index]);
        component.saveUIState = () => this.saveUIState();

        if (component.after) {
          this.gutters.push({
            before: component,
            after: this.parts.get(index + 1)!,
            visible: computed(() => {
              return !component.after!.collapsedState() && !component.collapsedState();
            })
          });
        }
      }
      this.alignRelativeSizes();
      this.restoreFormUIState();

      const gridTemplate = computed(() =>
        this.parts
          .map(part =>
            part.collapsedState()
              ? part.collapseToMinSize
                ? `${part.minSize}px`
                : 'min-content'
              : part.actualSize()
                ? part.scale === 'auto'
                  ? `minmax(${part.minSize}px, ${part.actualSize()}fr)`
                  : `minmax(${part.minSize}px, ${part.actualSize()}px)`
                : `minmax(${part.minSize}px, ${part.fractionalSize()! * 10}fr)`
          )
          .join(' min-content ')
      );

      this.gridTemplateRows = computed(() =>
        this._orientation() === 'vertical' ? gridTemplate() : '1fr'
      );
      this.gridTemplateColumns = computed(() =>
        this._orientation() === 'horizontal' ? gridTemplate() : '1fr'
      );
      this.gridTemplateAreas = computed(() => {
        const areaNames = this.parts
          .map((part, index) => [`p-${index}`, part.after ? `g-${index}` : []])
          .flat(2) as string[];

        if (this._orientation() === 'horizontal') {
          return `"${areaNames.join(' ')}"`;
        } else {
          return `"${areaNames.join('" "')}"`;
        }
      });
      setTimeout(() => this.refreshAllPartSizes());
    });
    this.parts.notifyOnChanges();
  }

  private alignRelativeSizes(): void {
    const requestedNoSize = this.parts.filter(part => !part.size && !part.fractionalSize());
    const requestedRelSize = this.parts.filter(part => part.fractionalSize() && !part.size);

    if (requestedRelSize.length) {
      const totalRequestedRelSize = requestedRelSize.reduce((a, b) => a + b.fractionalSize()!, 0);
      const averageRelSize = totalRequestedRelSize / requestedRelSize.length;
      const totalAssignedRelSize = totalRequestedRelSize + requestedNoSize.length * averageRelSize;
      requestedNoSize.forEach(part =>
        part.fractionalSize.set(averageRelSize / totalAssignedRelSize)
      );
      requestedRelSize.forEach(part =>
        part.fractionalSize.set(part.fractionalSize()! / totalAssignedRelSize)
      );
    } else {
      requestedNoSize.forEach(part => part.fractionalSize.set(1 / requestedNoSize.length));
    }
  }

  private refreshAllPartSizes(): void {
    const refParts = this.parts.filter(
      part =>
        !part.collapsedState() &&
        part.scale === 'auto' &&
        (part.expandedSize() || part.fractionalSize())
    );
    const beforeFrSum = refParts.reduce((a, b) => a + (b.expandedSize() ?? b.fractionalSize()!), 0);
    this.parts.forEach(part => part.refreshSizePx(this.orientation));
    const afterFrSum = refParts.reduce((a, b) => a + b.expandedSize()!, 0);
    const beforeToAfterFactor = afterFrSum / beforeFrSum;
    this.parts
      .filter(
        part =>
          part.collapsedState() && (part.scale === 'auto' || part.expandedSize() === undefined)
      )
      .forEach(part =>
        part.expandedSize.update(prev => (prev ?? part.fractionalSize()!) * beforeToAfterFactor)
      );
  }

  protected resizeStart(gutter: Gutter, event: Event): void {
    this.refreshAllPartSizes();
    this.changeDetectorRef.detectChanges();

    let beforeSize = gutter.before.expandedSize()!;
    let afterSize = gutter.after.expandedSize()!;
    let appliedDelta = 0;
    const rtl = isRTL();
    const startPosition = this.getPosition(event);
    const minDelta = -1 * (beforeSize - gutter.before.minSize);
    const maxDelta = afterSize - gutter.after.minSize;
    const containerSize = this.measureContainerSize();
    event.preventDefault(); // prevents text-selection

    this.ngZone.runOutsideAngular(() => {
      merge(fromEvent(this.document, 'mousemove'), fromEvent(this.document, 'touchmove'))
        .pipe(
          takeUntil(
            merge(fromEvent(this.document, 'mouseup'), fromEvent(this.document, 'touchend'))
          )
        )
        .subscribe({
          next: move => {
            let delta = this.getPosition(move) - startPosition;
            if (rtl && this.orientation === 'horizontal') {
              delta *= -1;
            }

            delta -= appliedDelta;
            if (maxDelta < appliedDelta + delta) {
              delta = maxDelta - appliedDelta;
            } else if (minDelta > appliedDelta + delta) {
              delta = minDelta - appliedDelta;
            }

            if (delta === 0) {
              return;
            }

            beforeSize += delta;
            afterSize -= delta;
            appliedDelta += delta;
            gutter.before.expandedSize.set(beforeSize);
            gutter.after.expandedSize.set(afterSize);
            if (this.orientation === 'vertical') {
              this.elementRef.nativeElement.style.setProperty(
                'grid-template-rows',
                this.gridTemplateRows()
              );
            } else {
              this.elementRef.nativeElement.style.setProperty(
                'grid-template-columns',
                this.gridTemplateColumns()
              );
            }
            this.ngZone.run(() =>
              this.sizesChange.emit(
                this.parts.map(part => (part.actualSize() * 100) / containerSize)
              )
            );
          },
          complete: () => this.saveUIState()
        });
    });
  }

  private measureContainerSize(): number {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    if (this._orientation() === 'vertical') {
      return rect.height;
    } else {
      return rect.width;
    }
  }

  private getPosition(event: Event): number {
    if (event instanceof MouseEvent) {
      return this.orientation === 'horizontal' ? event.clientX : event.clientY;
    } else if (event instanceof TouchEvent) {
      return this.orientation === 'horizontal'
        ? event.touches[0].clientX
        : event.touches[0].clientY;
    }
    return 0;
  }

  private saveUIState(): void {
    if (!this.stateId || !this.uiStateService) {
      return;
    }

    const containerSize = this.measureContainerSize();
    const state: UIState = { contextId: this.stateId, splitParts: {} };
    this.parts.forEach((part, index) => {
      if (part.stateId) {
        state.splitParts![part.stateId] = {
          size: ((part.expandedSize() ?? 0) * 100) / containerSize,
          initialSize: this.sizes[part.index],
          expanded: part.expanded
        };
      }
    });
    this.uiStateService.save(state);
  }

  private restoreFormUIState(): void {
    if (!this.stateId || !this.uiStateService) {
      return;
    }

    this.uiStateService.load(this.stateId).subscribe(uiState => {
      this.parts
        .filter(part => part.stateId)
        .map(part => ({ part, state: uiState?.splitParts?.[part.stateId!] }))
        .filter(({ part, state }) => this.sizes[part.index] === state?.initialSize)
        .forEach(({ part, state }) => {
          part.fractionalSize.set(state?.size);
          part.collapsedState.set(!(state?.expanded ?? true));
        });
      setTimeout(() => this.refreshAllPartSizes());
    });
  }
}
