import { Injectable } from '@angular/core';
import { DpIdentifier, SubChartCollectionRepresentation, TrendViewDefinition, TrendViewDefinitionUpdate } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { map, Observable, of } from 'rxjs';

import { traceChannelHorizon } from '../shared/http-utility.service';

export interface SystemFolder {
  id: string;
  label: string;
  parentId: string;
}

@Injectable({
  providedIn: 'root'
})
export class TrendProxyService {

  public constructor(
    private readonly traceService: TraceService) {

    this.traceService.info(traceChannelHorizon, 'TrendProxyService created.');
  }

  public saveTrend(tvdUpdate: TrendViewDefinitionUpdate, parentFolderId: string): Observable<TrendViewDefinition> {
    const tvd = this.createTvd(tvdUpdate);
    return this.getTrends(parentFolderId).pipe(
      map(tvds => {
        tvds.push(tvd);
        localStorage.setItem(`Trends-${parentFolderId}`, JSON.stringify(tvds));
        localStorage.setItem(tvd.TvdObjectId, `Trends-${parentFolderId}`);
        return tvd;
      })
    );
  }

  public getTrends(parentFolderEntityId): Observable<TrendViewDefinition[]> {
    const trends = localStorage.getItem(`Trends-${parentFolderEntityId}`);
    if (trends !== undefined) {
      return of(JSON.parse(trends));
    } else {
      return of([]);
    }
  }

  public getTrend(entityId): Observable<TrendViewDefinition> {
    const parentFolderId = localStorage.getItem(entityId);
    if (parentFolderId !== undefined) {
      const trends: TrendViewDefinition[] = JSON.parse(localStorage.getItem(parentFolderId));
      return of(trends.find(tvd => tvd.TvdObjectId === entityId));
    } else {
      return of(undefined);
    }
  }

  private createTvd(tvdUpdate: TrendViewDefinitionUpdate): TrendViewDefinition {
    let tvdObjId = tvdUpdate.TvdObjectId;
    if (tvdObjId === undefined) {
      // this is 'Save as' workflow, the Id is not yet given.
      // tvdObjId = `${omSystemInfo.Name}:${crypto.randomUUID()}`
      tvdObjId = crypto.randomUUID();
    }
    /* eslint-disable @typescript-eslint/naming-convention */
    const tvd: TrendViewDefinition = {
      TvdObjectId: tvdObjId,
      TitleLeft: undefined, // not used in FlexClient
      TitleRight: undefined, // not used in FlexClient
      TitleTop: undefined, // not used in FlexClient
      TvCovType: 'Auto', // 'Auto' or 'Manual' used/read in Flex Client.
      // Note that FC allows not to configure TvCovType (only WPF). When FC creates a TVD, the TVD is set to 'Auto' by the backend initially
      ShowQualityIndication: tvdUpdate.ShowQualityIndication,
      RemoveOnlineTrendLogOfDeletedTrendSerie: tvdUpdate.RemoveOnlineTrendLogOfDeletedTrendSerie,
      NumberDisplayedSamplesPerTrendSerie: tvdUpdate.NumberDisplayedSamplesPerTrendSerie,
      TimeRange: tvdUpdate.TimeRange,
      NameTvd: tvdUpdate.CNSNode.Name,
      DescriptionTvd: '', // not used in FC
      IdTvd: '', // not used in FC
      TvdType: undefined, // not used in FC
      AxisX: undefined, // not used in FC
      AxisYLeft: undefined, // not used in FC
      AxisYRight: undefined, // not used in FC
      SubChartCollection: tvdUpdate.SubChartCollection as SubChartCollectionRepresentation,
      TsdCollectionInfo: tvdUpdate.TsdCollectionInfo as any
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    for (let idx = 0; idx < tvd.TsdCollectionInfo.TrendSeriesDefinitions.length; idx++) {
      const tsDef = tvd.TsdCollectionInfo.TrendSeriesDefinitions[idx];
      const tsDefUpdate = tvdUpdate.TsdCollectionInfo.TrendSeriesDefinitions[idx];
      // copy members which do not match the class fields and are needed.
      // some fields are not used in Flex Client:
      // ReservedDateTime, DescriptionTlField, DescriptionTlGms, DescriptionTo, DescriptionToProperty,
      // IsDefaultProperty, Index, UnitText, Resolution, ValueType, ArchiveOn, ShowDataLabels, ShowQualityIcons
      // Some fields are used but not always set (to be clarified):
      // MaxTimeStamp, MinTimeStamp
      tsDef.AccessDenied = false; // TODO: to be checked with trends team
      tsDef.PropertyName = new DpIdentifier(tsDefUpdate.ObjectPropertyId).propertName;
      tsDef.TrendSeriesId = tsDefUpdate.ObjectPropertyId;
      tsDef.TrendLogObjectId = undefined; // No trend log support yet
      tsDef.Smoothing = false; // TODO: check
      tsDef.DataType = undefined; // TODO: check
    }
    return tvd;
  }

}
