import { SvgUtility } from '../utilities/parser';

export class GmsSymbolNodeLibrary {

  private readonly _symbols: Map<string, Node> = new Map<string, Node>();

  public addSymbol(node: Node): void {
    const symbolRefId: string = SvgUtility.GetAttributeValue(node, 'symbolRef');
    if (symbolRefId !== undefined) {
      this._symbols.set(symbolRefId.toLowerCase(), node);
    }
  }

  public getSymbol(symbolRefId: string): Node {
    return this._symbols.get(symbolRefId.toLowerCase());
  }

  public hasSymbol(symbolRefId: string): boolean {
    return this._symbols.has(symbolRefId.toLowerCase());
  }

}
