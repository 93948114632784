import { Injectable } from '@angular/core';
import { BrowserObject, Designation, DpIdentifier, NewObjectParameters, ObjectCreationInfo, ObjectsServiceBase, SearchOption, ServiceTextInfo,
  ServiceTextParameters, SystemBrowserServiceBase, SystemBrowserSubscriptionProxyServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, asyncScheduler, concatMap, map, Observable, of, scheduled, tap, throwError } from 'rxjs';

import { FolderService } from '../../bx-services/folder/folder.service';
import { EntityType, FolderType } from '../../bx-services/shared/base.model';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';
import { SystemBrowserBxSubstituteService } from '../system-browser/system-browser-bx-substitute.service';
import { SystemBrowserMapperBxToGmsService } from '../system-browser/system-browser-mapper-bx-to-gms.service';
import { SystemBrowserSubscriptionBxSubstituteProxyService } from '../system-browser/system-browser-subscription-bx-substitute-proxy.service';

@Injectable()
export class ObjectsBxSubstituteService extends ObjectsServiceBase {
  public constructor(
    private readonly traceService: TraceService,
    private readonly systemBrowser: SystemBrowserServiceBase,
    private readonly systemBrowserMapper: SystemBrowserMapperBxToGmsService,
    private readonly systemBrowserSubscriptionService: SystemBrowserSubscriptionProxyServiceBase,
    private readonly folderService: FolderService,
    private readonly objectTypeMapper: ObjectTypeMapperService) {
    super();

    this.traceService.info(TraceModules.objects, 'ObjectsBxSubstituteService created.');
  }

  public getObjectCreationInfo(designation: string, includeChildInfo?: boolean): Observable<ObjectCreationInfo> {

    return (this.systemBrowser as SystemBrowserBxSubstituteService).getViews().pipe(
      concatMap(viewNodes => {
        const designationObj = new Designation(designation);
        const systemId = designationObj.getSystemId(viewNodes);
        const viewId = designationObj.getViewId(viewNodes);
        return this.systemBrowser.searchNodes(systemId, designation, viewId, SearchOption.designation).pipe(
          map(page => {
            /* eslint-disable @typescript-eslint/naming-convention */
            const ocInfo: ObjectCreationInfo = {
              Designation: designation,
              Description: page.Nodes[0].Attributes.TypeDescriptor, // The object type descriptor, e.g. Building or Campus Part
              Name: this.objectTypeMapper.getEntityType(page.Nodes[0].Attributes.TypeId), // The object type name, e.g. Building or CampusPart
              IsGenericDeleteAllowed: false, // To be defined in furure based on the backend capability
              ChildObjects: [
                {
                  Description: 'Folder',
                  IsGenericCreateAllowed: true,
                  IsGenericDeleteAllowed: false,
                  // object manager uses this field for comparison with createbleTypes and selectable types (object model names)
                  Name: EntityType.Folder.toString(),
                  TypeId: this.objectTypeMapper.entityTypeToDccTypeId(EntityType.Folder),
                  ExistingChildCount: undefined,
                  MaxChild: undefined
                }
                // TODO: check which members are used in object manager
              ]
            };
            /* eslint-enable @typescript-eslint/naming-convention */
            return ocInfo;
          })
        );
      })
    );
  }

  public createObject(childObject: NewObjectParameters): Observable<BrowserObject> {
    return (this.systemBrowser as SystemBrowserBxSubstituteService).getViews().pipe(
      concatMap(viewNodes => {
        const designation = new Designation(childObject.Designation);
        const systemId = designation.getSystemId(viewNodes);
        const viewId = designation.getViewId(viewNodes);
        return this.systemBrowser.searchNodes(systemId, childObject.Designation, viewId, SearchOption.designation).pipe(
          concatMap(parent => {
            if (parent.Nodes?.length === 1) {
              const parentNode = parent.Nodes[0];
              const parentEntityId = new DpIdentifier(parentNode.ObjectId).objectIdWoSystem;
              // TODO: abstract the folder type creation
              return this.folderService.createFolder(parentEntityId, childObject.Descriptor.CommonText, FolderType.Trend).pipe(
                map(systemFolder => this.systemBrowserMapper.mapFolderToBrowserObject(
                  systemFolder,
                  viewNodes.find(vNode => vNode.SystemId === systemId && vNode.ViewId === viewId),
                  childObject.Designation,
                  parentNode.Location,
                  parentEntityId,
                  this.systemBrowserMapper.getPartitionId(parentEntityId)
                )),
                tap(node => {
                  asyncScheduler.schedule(() => {
                    (this.systemBrowserSubscriptionService as SystemBrowserSubscriptionBxSubstituteProxyService).notifyAddedNode(node);
                  }, 100);
                })
              );
            } else {
              return of(undefined);
            }
          })
        );
      })
    );
  }

  public getServiceText(objectId: string): Observable<ServiceTextInfo> {
    /* eslint-disable @typescript-eslint/naming-convention */
    const svcText: ServiceTextInfo = {
      ObjectId: objectId,
      ServiceText: { InformationText: '', Memo: '' }
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return scheduled([svcText], asapScheduler);
  }

  public setServiceText(objectId: string, updatedServiceText: ServiceTextParameters): Observable < void > {
    return throwError(() => new Error('ObjectsBxSubstituteService.setServiceText(): Not Implemented!'));
  }
}
