import { TagsKeyValueInfo } from "@gms-flex/services";

export class TagHelper {
  public static getKeys(input: string): string[] {
    const result: string[] = [];
    let currentContent = '';
    let startBracket = false;

    for (let i = 0; i < input.length; i++) {
      const char = input[i];

      if (char === '{') {
        startBracket = true;
        currentContent = '';
      } else if (char === '}' && startBracket) {
        startBracket = false;
        if (currentContent.length > 0) {
          result.push(currentContent);
        }
      } else if (startBracket) {
        currentContent += char;
      }
    }

    return result;
  }

  public static replacePlaceholders(origStr: string, tagsMap: TagsKeyValueInfo): string {
    let result = '';
    let currentKey = '';
    let startBracket = false;

    for (let i = 0; i < origStr.length; i++) {
      const char = origStr[i];

      if (char === '{') {
        startBracket = true;
        currentKey = '';
      } else if (char === '}' && startBracket) {
        startBracket = false;
        result += tagsMap[currentKey] || '';
      } else if (startBracket) {
        currentKey += char;
      } else {
        result += char;
      }
    }

    return result;
  }
}
