import { Injectable } from '@angular/core';
import { ExtendedStatusType } from '@simpl/element-ng';

/**
 * Base class for the severity service.
 * Provides the functionality to ge severities.
 *
 * @export
 * @abstract
 * @class SeverityServiceBase
 */

export abstract class SeverityServiceBase {
  /**
   * Get Severity by category.
   *
   * @abstract
   * @returns { ExtendedStatusType | undefined }
   *
   * @memberOf SeverityServiceBase
   */
  public abstract getSeverityByCategory(categoryDescription: string): ExtendedStatusType | undefined;
}
