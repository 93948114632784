import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { map, Observable, of, share, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { useCache, useMulticast } from '../shared/http-utility.service';
import { Customer } from './user-self-proxy.model';
import { UserSelfProxyService } from './user-self-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class UserSelfService {
  private customers: Customer[];
  private multiCastCustomersObs: Observable<Customer[]>;

  public constructor(
    private readonly traceService: TraceService,
    private readonly customerProxy: UserSelfProxyService) {

    this.traceService.info(TraceModules.bxServicesUserSelf, 'UserSelfService created.');
  }

  /**
   * Reads the customers of the logged in user.
   * Filtering with customerName is done client-side.
   */
  public getCustomers(customerName?: string): Observable<Customer[]> {
    if ((useCache) && (this.customers !== undefined)) {
      const filtered = this.filterCustomers(this.customers, customerName);
      this.traceService.debug(TraceModules.bxServicesUserSelf, `UserSelfService.getCustomers() returned: no of customers: ${filtered.length} from cache
      customerName=${customerName}`);
      return of(filtered);
    } else {
      // using multicast to support the concurrent alarm source resolvement more efficient.
      // problem statement: finding/resolving the same object (e.g the device of alarms of the device) causes many parallel calls which do the same.
      // e.g reading the same device, gateway, location, partition... The cache cannot be maintained.
      if ((useMulticast) && (this.multiCastCustomersObs !== undefined)) {
        return this.multiCastCustomersObs;
      }
      const customersObs: Observable<Customer[]> = this.customerProxy.getCustomers().pipe(
        tap(result => this.customers = result.data),
        map(result => this.filterCustomers(result.data, customerName)),
        tap(customers => {
          this.traceService.debug(TraceModules.bxServicesUserSelf, `UserSelfService.getCustomers() returned: no of customers: ${customers.length} from backend
            customerName=${customerName}`);
        })
      );
      // return customersObs;
      if (useMulticast) {
        this.multiCastCustomersObs = customersObs.pipe(share());
        return this.multiCastCustomersObs;
      } else {
        return customersObs;
      }
    }
  }

  /**
   * Reads the customers of the logged in user.
   * Filtering with customerId is done client-side.
   */
  public getCustomer(customerId: string): Observable<Customer | undefined> {
    if ((useCache) && (this.customers !== undefined)) {
      const customer = this.filterCustomersById(this.customers, customerId);
      this.traceService.debug(TraceModules.bxServicesUserSelf, `UserSelfService.getCustomer() returned: customer id: ${customer?.id} from cache
        searched customerId=${customerId}`);
      return of(customer);
    } else {
      return this.getCustomers().pipe(
        tap(response => this.customers = response),
        map(response => this.filterCustomersById(response, customerId)),
        tap(customer => {
          this.traceService.debug(TraceModules.bxServicesUserSelf, `UserSelfService.getCustomer() returned: customer id: ${customer?.id} from backend
            searched customerId=${customerId}`);
        })
      );
    }
  }

  private filterCustomers(customers: Customer[], name?: string): Customer[] {
    if (name) {
      const found = this.findCustomer(customers, name);
      return found ? [found] : [];
    } else {
      return customers.filter(customer => (customer !== undefined));
    }
  }

  private findCustomer(customers: Customer[], name: string): Customer | undefined {
    return customers.find(customer => customer?.attributes.name === name);
  }

  private filterCustomersById(customers: Customer[], id: string): Customer | undefined {
    return customers.find(customer => customer?.id === id);
  }
}
