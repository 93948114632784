/* eslint-disable @typescript-eslint/naming-convention */
enum TraceModulesEn {
  hfwLogin_Authentication,
  hfwLogin_Login
}

export class TraceModules {
  public static authentication: string = TraceModulesEn[TraceModulesEn.hfwLogin_Authentication];
  public static login: string = TraceModulesEn[TraceModulesEn.hfwLogin_Login];
}
