import { Injectable } from '@angular/core';
import { GmsManagedType, GmsManagedTypes } from '@gms-flex/services';

import { ManagedType } from '../common/interfaces/trend.models';
import { TrendEvalutionServiceBase } from './trend-evalution.service.base';

@Injectable()
export class TrendEvalutionService implements TrendEvalutionServiceBase {

  public doCheckIfManagedTypeIsNewTrend(managedTypeName: string): boolean {
    return managedTypeName === GmsManagedTypes.NEW_TREND.name;
  }

  public doCheckIfManagedTypeIsTrendViewDefination(managedTypeName: string): boolean {
    return managedTypeName === GmsManagedTypes.TRENDVIEWDEFINITION.name;
  }

  public doShowTrendTile(managedTypeName: string): boolean {
    return managedTypeName === GmsManagedTypes.TREND_FOLDER.name;
  }

  public doAllowOnlineTrendModification(managedTypeName: string): boolean {
    return !managedTypeName.startsWith(ManagedType.TrendLog) 
          && managedTypeName !== GmsManagedTypes.TRENDVIEWDEFINITION.name 
          && managedTypeName !== GmsManagedTypes.TREND_LOG_PREDICTED.name;
  }

  public doShowSnapinContentAction(managedTypeName: string): boolean {
    const possibleOperations: GmsManagedType[] = this.getManagedTypes(managedTypeName);
    return possibleOperations.find(obj => obj.name.startsWith(ManagedType.TrendLog) || obj.id === GmsManagedTypes.TRENDVIEWDEFINITION.id) ? true : false;
  }

  public doCheckIfManagedTypeIsOfflineTrendLog(managedTypeName: string): boolean {
    return managedTypeName != undefined && managedTypeName !== ManagedType.TrendLogOnline && managedTypeName.startsWith(ManagedType.TrendLog);
  }

  public doCheckIfManagedTypeIsOfflineOrOnlineTrendLog(managedTypeName: string): boolean {
    return managedTypeName != undefined && managedTypeName.startsWith(ManagedType.TrendLog);
  }

  public getGenSettingsTimeout(): number {
    return 0;
  }

  public doShowButtonsAndContentAction(): boolean {
    return true;
  }
  
  private getManagedTypes(managedType: string): GmsManagedType[] {
    const SupportedManagedTypes: GmsManagedType[] = [];
    if (managedType && managedType === GmsManagedTypes.TREND_FOLDER.name) {
      SupportedManagedTypes.push(GmsManagedTypes.TREND_LOG);
      SupportedManagedTypes.push(GmsManagedTypes.TREND_LOG_ONLINE);
      SupportedManagedTypes.push(GmsManagedTypes.TRENDVIEWDEFINITION);
    }
    return SupportedManagedTypes;
  }
  
}
