import { NgModule } from '@angular/core';

import { SiSidePanelContentComponent } from './si-side-panel-content.component';
import { SiSidePanelComponent } from './si-side-panel.component';

@NgModule({
  imports: [SiSidePanelComponent, SiSidePanelContentComponent],
  exports: [SiSidePanelComponent, SiSidePanelContentComponent]
})
export class SiSidePanelModule {}
