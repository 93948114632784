export class TraceModules {
  public static bxServicesCustomers = "bxServices_Customers";
  public static bxServicesDevices = "bxServices_Devices";
  public static bxServicesEnums = "bxServices_Enums";
  public static bxServicesEquipments = "bxServices_Equipments";
  public static bxServicesEquipmentTypes = "bxServices_EquipmentTypes";
  public static bxServicesEvents = "bxServices_Events";
  public static bxServicesFolders = "bxServices_Folders";
  public static bxServicesLocations = "bxServices_Locations";
  public static bxServicesPartitions = "bxServices_Partitions";
  public static bxServicesPoints = "bxServices_Points";
  public static bxServicesPointHistory = "bxServices_PointHistory";
  public static bxServicesUserSelf = "bxServices_UserSelf";
  public static bxServicesSchedules = "bxServices_Schedules";
  public static bxServicesCalendars = "bxServices_Calendars";

  public static rightPanelState = "gmsApp_rightPanelState";
  
  public static eventListSettings: "cbmsApp_eventListSettings";
}
