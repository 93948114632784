import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GmsSnapInCommonModule } from '@gms-flex/snapin-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiEmptyStateModule, SiFilteredSearchModule, SiLoadingSpinnerModule, SiPromptDialogButtonsModule, SiResizeObserverModule,
  SiSearchBarModule, SiSliderModule, SiToastNotificationService } from '@simpl/element-ng';
import { SimplObjectBrowserNgModule } from '@simpl/object-browser-ng';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { propertySnapInRouting } from './gms-property-snapin.routing';
import { PropertySnapInService } from './services/property-snapin.service';
import { PropertySnapInComponent } from './snapin/property-snapin.component';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './@gms-flex/property/i18n/', '.json');

@NgModule({
  imports: [HttpClientModule, ModalModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient] },
      isolate: true
    }),
    CommonModule, FormsModule, ReactiveFormsModule, propertySnapInRouting, GmsSnapInCommonModule,
    AccordionModule, SiSliderModule, PopoverModule, SiEmptyStateModule, SiFilteredSearchModule, SiSearchBarModule,
    SiPromptDialogButtonsModule, SiLoadingSpinnerModule, SimplObjectBrowserNgModule, SiResizeObserverModule, TooltipModule],
  declarations: [PropertySnapInComponent],
  exports: [PropertySnapInComponent],
  providers: [SiToastNotificationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GmsPropertySnapInModule {}
