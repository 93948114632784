import { EnumerationItem } from '@gms-flex/services';
import { Subject, Subscription } from 'rxjs';

import { DatapointStatus } from '../../types/datapoint/gms-status';
import { Constants } from '../../utilities/constants';
import { FormatHelper } from '../../utilities/format-helper';
import { GmsCommand } from '../command/gms-command';
import { CommandParameter, ParameterType } from '../command/parameters/gms-base-parameter';
import { Datapoint, DataPointPropertyChangeArgs } from '../datapoint/gms-datapoint';
import { CommandViewModel } from './gms-command-vm';

export enum ControlEditorMode {
  View = 0,
  Highlight = 1,
  Edit = 2
}

export class ParameterViewModelBase {
  public static defaultPrecision = 2;
  public static defaultModelMaximum: number = Number.MAX_VALUE;
  public static defaultModelMinimum: number = Number.MIN_VALUE;
  public static designModeParameterName = 'DesignModeParameter';
  public static designModeParameterDataType = 'DesignModeDataType';
  public propertyChanged: Subject<string> = new Subject<string>();

  public hasValidValue: boolean;
  public isReadOnly: boolean;
  public isTriggerEnabled: boolean;
  public dataType: string;
  public defaultValue: string;
  public minValue: number;
  public maxValue: number;

  public maxValueLong: Long;
  public minValueLong: Long;

  public minLength: number;
  public maxLength: number;
  public formatString: string;
  public maskString: string;
  public errorStatus: string;
  public precision: number;
  public modelPrecision: number;
  public passwordChar: number;
  public description: string;
  public fontFamily: string;
  public fontSize: number;
  public strokeColor: string;
  public backColor: string;
  public fillColor: string;
  public locale: string;
  public defaultlocale: string;
  public name: string;
  public order: number;
  public formattedValue: string;
  public enums: EnumerationItem[] = [];

  public countUsage = 0;
  public defaultPrecision = 2;
  public defaultModelMaximum: number;
  public defaultModelMinimum: number;

  private _dataPointPropertyChangedSub: Subscription;
  private _editorMode: ControlEditorMode = ControlEditorMode.View;

  private _parameterType: ParameterType;

  public get ParameterType(): ParameterType {
    return this._parameterType;
  }
  public set ParameterType(value: ParameterType) {
    this._parameterType = value;
  }

  private _value: string = Constants.GENERAL_ERROR;
  public get Value(): string {
    return this._value;
  }
  public set Value(value: string) {
    if (this._value !== value && value !== undefined) {
      // Value expected from WSI - with locale en-US
      this._value = value;
      if (this._editorMode !== ControlEditorMode.Edit) {
        // Do formatting here
        this.UpdateFormattedValue();
      }
      this.NotifyPropertyChanged('Value');
    }
  }

  public UpdateFormattedValue(): void {
    this.FormattedValue = this.BuildFormattedValue(this.Value);
  }

  private _formattedValue = ''; // string = Constants.GENERAL_ERROR;
  public get FormattedValue(): string {
    return this._formattedValue;
  }
  public set FormattedValue(value: string) {
    if (this._formattedValue !== value) {
      this._formattedValue = value;
      this._dpValue = value;
      this.NotifyPropertyChanged('FormattedValue');

      // Parameters in Sync
      if (this.CommandViewModel !== null) {
        this.CommandViewModel.SetParamFormattedValue(this, value);
      }
    }
  }

  // DpValue: NOTE: support for Slider and Spinner
  private _dpValue: string = Constants.GENERAL_ERROR;
  public get DpValue(): string {
    return this._dpValue;
  }

  public set DpValue(value: string) {
    if (this._dpValue == null && value == null) {
      return;
    }

    if ((this._dpValue == null && value != null) || this._dpValue !== value) {
      this._dpValue = value;
      this.NotifyPropertyChanged('DpValue');
      this.NotifyPropertyChanged('Value');
      this.NotifyPropertyChanged('FormattedValue');
    }
  }

  private _isTriggerEnabled = false;
  public get IsTriggerEnabled(): boolean {
    return this._isTriggerEnabled;
  }
  public set IsTriggerEnabled(value: boolean) {
    this._isTriggerEnabled = value;
  }
  public get ControlEditorMode(): ControlEditorMode {
    return this._editorMode;
  }

  // Sync
  private _commandViewModel: CommandViewModel = null;
  public get CommandViewModel(): CommandViewModel {
    return this._commandViewModel;
  }
  public set CommandViewModel(value: CommandViewModel) {
    this._commandViewModel = value;
    this.locale = this._commandViewModel !== null ? this._commandViewModel.locale : FormatHelper.enUS;
    this.defaultlocale = this._commandViewModel !== null ? this._commandViewModel.defaultlocale : FormatHelper.enUS;
    this.Value = this.defaultValue;
  }

  public get isModified(): boolean {
    return this.CommandViewModel !== null && this.CommandViewModel.isModified;
  }
  public set isModified(value: boolean) {
    if (this.CommandViewModel !== null && this.CommandViewModel.isModified !== value) {
      this.CommandViewModel.isModified = value;
    }
  }

  protected BuildFormattedValue(value: string): string {
    return value;
  }

  public setControlEditorMode(mode: ControlEditorMode): boolean {
    if (this._editorMode !== mode) {
      this._editorMode = mode;
      return true;
    }
    return false;
  }

  public SetParameterValue(datapoint: Datapoint): void {
    let value: string = Constants.GENERAL_ERROR;

    if (datapoint === undefined) {
      this.Value = this.defaultValue;
    } else if (datapoint.Value !== undefined && datapoint.Status === DatapointStatus.Valid) {
      switch (datapoint.DatapointType) {
        case 'ExtendedBool':
        case 'BasicBool':
          const result: string = String(datapoint.Value).toLowerCase();
          if (result === 'true' || result === 'false') {
            value = result === 'true' ? '1' : '0';
          } else {
            value = datapoint.Value;
          }
          break;
        default:
          value = datapoint.Value;
          break;
      }
      this.Value = value;
    }
  }

  // Sync
  public setParameterFormattedValue(value: string, isSyncRequest: boolean = false): void {
    if (this.CommandViewModel !== null && this.CommandViewModel.isModified && !isSyncRequest) {
      return;
    }

    this.FormattedValue = value;
  }

  public setParameterToDefaultValue(): void {
    this.Value = this.defaultValue;
  }

  public subscribeForCommandParameterUpdates(parameter: CommandParameter): void {
    if (parameter.Datapoint !== undefined && this._dataPointPropertyChangedSub === undefined) {
      this._dataPointPropertyChangedSub = parameter.valueChanged.subscribe(arg => this.OnDataPointPropertyChanged(arg));
    }
  }

  public unSubscribeFromCommandParameterUpdates(): void {
    if (this._dataPointPropertyChangedSub !== undefined && !this._dataPointPropertyChangedSub.closed) {
      this._dataPointPropertyChangedSub.unsubscribe();
      this._dataPointPropertyChangedSub = undefined;
    }
  }

  public getModelMaximum(command: GmsCommand): number {
    return ParameterViewModelBase.defaultModelMaximum;
  }

  public getModelMinimum(command: GmsCommand): number {
    return ParameterViewModelBase.defaultModelMinimum;
  }

  public getModelMaximumLong(command: GmsCommand): Long {
    return undefined;
  }

  public getModelMinimumLong(command: GmsCommand): Long {
    return undefined;
  }

  public isInputValid(locale: string): boolean {
    return true;
  }
  public constructor(parameter: CommandParameter) {

    this.name = parameter !== null ? parameter.Name : ParameterViewModelBase.designModeParameterName;
    this.order = parameter !== null ? parameter.Order : -1;
    this.defaultValue = parameter !== null ? parameter.DefaultValue : Constants.GENERAL_ERROR;
    this.dataType = parameter !== null ? parameter.DataType : ParameterViewModelBase.designModeParameterDataType;
    this.modelPrecision = parameter !== null ? parameter.Precision : ParameterViewModelBase.defaultPrecision;
  }

  public Destroy(): void {

    this.unSubscribeFromCommandParameterUpdates();

  }

  public UpdateToNumericDefaults(): void {
    if (this.ParameterType === ParameterType.MultiState ||
            this.ParameterType === ParameterType.Priority) {

      this.ParameterType = ParameterType.Numeric;
      this.dataType = 'ExtendedReal';
      this.precision = 0;
    }
  }

  protected NotifyPropertyChanged(propertyName: string = ''): void {
    if (this.propertyChanged.observers !== null && this.propertyChanged.observers.length > 0) {
      this.propertyChanged.next(propertyName);
    }
  }

  private OnDataPointPropertyChanged(arg: DataPointPropertyChangeArgs): void {
    switch (arg.PropertyName) {
      case 'Value':
      case 'Status':
        this.SetParameterValue(arg.SourceDatapoint);
        break;

      case 'DefaultValue':
        if (this.CommandViewModel !== null) {
          this.defaultValue = arg.DefaultValue;
          this.setParameterToDefaultValue();
        }
        break;

      default:
        break;

    }
  }
}
