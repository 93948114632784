import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-ng/translate';

/* eslint-disable */
// TODO: upstream bug, re-enable when fixed
/**
 * Siemens Logo and link to main page.
 * Should be used in a `.navbar-header`.
 *
 * @example
 *   <nav class="navbar">
 *     <div class="navbar-header">
 *       <si-navbar-brand></si-navbar-brand>
 *     </div>
 *   </nav>
 *
 * <example-url>../dist-playground/?scenario=SiNavbarBrandComponent/Navbar%20Brand</example-url>
 */
/* eslint-enable */

@Component({
  selector: 'si-navbar-brand',
  templateUrl: './si-navbar-brand.component.html',
  standalone: true,
  imports: [SiLinkDirective, SiTranslateModule]
})
export class SiNavbarBrandComponent implements OnInit {
  /**
   * URL to the logo image.
   */
  @Input() logoUrl?: string;
  /**
   * like that is invoked on click, defaults to "/"
   */
  @Input() link?: Link = { link: '/' };
  /**
   * Text which describes the logo or the name. Needed for a11y.
   */
  @Input() logoText = $localize`:@@SI_NAVBAR.LOGO_TEXT:Siemens logo`;

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private themeLogoUrl?: string;
  private themeLogoText?: string;

  ngOnInit(): void {
    this.updateThemeLogoUrl();
  }

  /**
   * Returns value of css variable `--element-brand-logo-url` if configured,
   * otherwise the value of `logoUrl`.
   */
  protected get resolvedLogoUrl(): string | undefined {
    return this.themeLogoUrl ?? this.logoUrl;
  }

  /**
   * Returns value of css variable `--element-brand-logo-text` if configured,
   * otherwise the value of `logoText`.
   */
  protected get resolvedLogoText(): string {
    return this.themeLogoText ?? this.logoText;
  }

  @HostListener('window:theme-switch')
  protected onThemeSwitch(): void {
    this.updateThemeLogoUrl();
  }

  private updateThemeLogoUrl(): void {
    if (!this.isBrowser) {
      return;
    }

    const computed = window.getComputedStyle(document.documentElement);
    const themeLogoUrl = computed.getPropertyValue('--element-brand-logo-url');
    this.themeLogoUrl = themeLogoUrl.trim().length > 0 ? themeLogoUrl : undefined;

    const themeLogoText = computed.getPropertyValue('--element-brand-logo-text');
    this.themeLogoText = themeLogoText.trim().length > 0 ? themeLogoText : undefined;
  }
}
