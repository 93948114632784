export enum GmsCommandControlType {
  // Presents an undefined command control
  // eslint-disable-next-line id-blacklist
  Undefined = 0,
  // summary>Presents a selector display a dropdown
  Selector,
  // Presents a numeric input field
  Numeric,
  // Presents a textual input field
  // eslint-disable-next-line id-blacklist
  String,
  // Presents a password input field
  Password,
  // Presents a slider, a linear slider
  Slider,
  // Presents a rotator, a rotational slider
  Rotator,
  // Presents a spinner for numeric values
  Spinner
}
