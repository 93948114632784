import { Injectable } from '@angular/core';
import { ConnectionState, LanguageInfo, SystemInfo, SystemsProxyServiceBase, SystemsResponseObject, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { isNullOrUndefined } from '@siemens/ngx-datatable';
import { asapScheduler, concatMap, map, Observable, scheduled, Subject, throwError, zip } from 'rxjs';

import { PartitionService } from '../../bx-services/subscription/partition.service';
import { UserSelfService } from '../../bx-services/user/user-self.service';
import { ContextService } from '../state/context.service';
import { SystemsMapperBxToGmsService } from './systems-mapper-bx-to-gms.service';

const systemLanguages: LanguageInfo[] = [
  {
    /* eslint-disable @typescript-eslint/naming-convention */
    ArrayIndex: 0,
    Descriptor: 'English (United States)',
    Code: 'en-US'
    /* eslint-enable @typescript-eslint/naming-convention */
  }
];

@Injectable()
export class SystemsBxSubstituteProxyService implements SystemsProxyServiceBase {
  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _notifySystems: Subject<SystemInfo[]> = new Subject<SystemInfo[]>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly contextService: ContextService,
    private readonly partitionService: PartitionService,
    private readonly systemsMapper: SystemsMapperBxToGmsService,
    private readonly userSelfService: UserSelfService) {

    this.contextService.selectedPartitions$.subscribe(partitions => {
      if (!isNullOrUndefined(partitions) && partitions.length > 0) {
        this.traceService.info(TraceModules.systems, `Current selected partitions: ${partitions.map(partition => partition.id).join()}`);
        this.getSystemsExt().subscribe(sro => {
          this._notifySystems.next(sro.Systems);
        });
      }
    });

    this.traceService.info(TraceModules.systems, 'SystemsBxSubstituteProxyService created.');
  }

  public getSystemsExt(): Observable<SystemsResponseObject> {

    if (!this.contextService.selectedCustomer || (!this.contextService.selectedPartitions) || this.contextService.selectedPartitions.length === 0) {
      return this.userSelfService.getCustomers().pipe(
        concatMap(customers => {
          return this.partitionService.getPartitions(customers[0].id).pipe(
            concatMap(partitions => {
              // TODO: Handle the case when no permission for customer and partitions
              return this.createSystems(customers[0].id, [partitions[0].id]).pipe(
                map(systems => {
                  /* eslint-disable @typescript-eslint/naming-convention */
                  const sro: SystemsResponseObject = {
                    Systems: systems,
                    Languages: systemLanguages,
                    IdLocal: systems[0].Id,
                    IsDistributed: true
                  };
                  /* eslint-enable @typescript-eslint/naming-convention */
                  // TODO: bootstrapping code of the app needs to be refactored
                  localStorage.setItem('selectedCustomerId', customers[0].id);
                  localStorage.setItem('selectedPartitions', [partitions[0]].map(partition => partition.id).join());
                  this.contextService.selectedCustomer = customers[0] as any;
                  this.contextService.selectedPartitions = [partitions[0] as any];
                  return sro;
                })
              );
            })
          );
        })
      );
    } else {
      return this.createSystems(this.contextService.selectedCustomer.id, this.contextService.selectedPartitions.map(p => p.id)).pipe(
        map(systemInfos => {
          /* eslint-disable @typescript-eslint/naming-convention */
          const sro: SystemsResponseObject = {
            Systems: systemInfos,
            Languages: systemLanguages,
            IdLocal: systemInfos[0].Id,
            IsDistributed: true
          };
          /* eslint-enable @typescript-eslint/naming-convention */
          return sro;
        })
      );
    }
  }

  public getSystems(): Observable<SystemInfo[]> {
    return this.createSystems(this.contextService.selectedCustomer.id, this.contextService.selectedPartitions.map(p => p.id));
  }

  public getSystem(systemId: any): Observable<SystemInfo> {
    return this.createSystems(this.contextService.selectedCustomer.id, this.contextService.selectedPartitions.map(p => p.id)).pipe(
      map(systemInfos => systemInfos.find(sInfo => sInfo.Id === systemId))
    );
  }

  public getSystemLocal(): Observable<SystemInfo> {
    return this.createSystems(this.contextService.selectedCustomer.id, this.contextService.selectedPartitions.map(p => p.id)).pipe(
      map(systemInfos => systemInfos[0])
    );
  }

  public getSystemLanguages(): Observable<LanguageInfo[]> {
    return scheduled([systemLanguages], asapScheduler);
  }

  public subscribeSystems(): Observable<boolean> {
    return scheduled([true], asapScheduler);
  }

  public unSubscribeSystems(): Observable<boolean> {
    return scheduled([false], asapScheduler);
  }

  public systemsNotification(): Observable<SystemInfo[]> {
    return this._notifySystems.asObservable();
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public setSystemPath?(_systemId: string): Observable<any> {
    return throwError(() => new Error('SystemsBxSubstituteProxyService.setSystemPath(): Not Implemented!'));
  }

  public downloadCredentialsPack?(_systemId: string): Observable<any> {
    return throwError(() => new Error('SystemsBxSubstituteProxyService.downloadCredentialsPack(): Not Implemented!'));
  }

  public getCredentialsPack?(_url: string): Observable<any> {
    return throwError(() => new Error('SystemsBxSubstituteProxyService.getCredentialsPack(): Not Implemented!'));
  }

  private createSystems(customerId: string, partitionIds: string[]): Observable<SystemInfo[]> {
    const partObs = partitionIds.map(id => this.partitionService.getPartition(customerId, id));
    return zip(partObs).pipe(
      map(partitions => this.systemsMapper.mapPartitions(partitions))
    );
  }
}
