import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IHfwMessage, MessageParameters, ParamsSendMessage, QParam } from '@gms-flex/core';
import { GmsMessageData, gmsNoSelectionMessageType, GmsSelectionType } from '@gms-flex/services';
import { Action, NotifConfiguration, Notification, NotificationServiceBase, NotificationState } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogResult, SiActionDialogService, SiSidePanelService } from '@simpl/element-ng';

import { UtilityPanelsEn } from '../shared/utility-panel-model';
import { NotificationManagerService, NotificationPublic } from './notification-manager.service';

export class GroupProperties {
  public sender: string;
  public grouped: boolean;
  public count: number;
}

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private readonly _sanitizer: DomSanitizer) {
  }

  public transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}

const INVESTIGATIVE_MODE_ID = 'investigative';
const ASSISTED_MODE_ID = 'assisted';
const DEFAULT_MODE_ID = 'default';
@Component({
  selector: 'gms-notification-center',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notification.component.html',
  styleUrl: '../gms-navbar.scss'
})
export class NotificationComponent implements OnInit {

  @Output() public readonly utilityPanelChanged: EventEmitter<UtilityPanelsEn> = new EventEmitter<UtilityPanelsEn>();

  public emptyNotifString = '';
  public notificationsSorted: NotificationPublic[];
  public senders: GroupProperties[];
  public sendersOrder: string[] = ['newEvents', 'backToNormal', 'suppressedObjects', 'license', 'passwordReminder'];
  public configurations: Map<string, NotifConfiguration>;

  public count = 0;
  public dismissAllLabel = '';

  private timerRunning;
  private interval: any;
  private readonly intervalDelay = 1000;
  private isInInvestigativeMode = false;
  private isInAssistedMode = false;
  private popoverTitle = '';
  private popoverMessage = '';
  private modalBtnConfirm = ''; // "Yes"
  private modalBtnCancel = ''; // "No"

  public constructor(
    private readonly notificationService: NotificationServiceBase,
    private readonly translateService: TranslateService,
    private readonly notificationManager: NotificationManagerService,
    private readonly ngZone: NgZone,
    private readonly sidePanelService: SiSidePanelService,
    private readonly ref: ChangeDetectorRef,
    private readonly messageBroker: IHfwMessage,
    private readonly siModal: SiActionDialogService
  ) {
  }

  public ngOnInit(): void {
    this.translateService.get([
      'NOTIF.EMPTY-NOTIFICATION-CENTER',
      'NOTIF.DISMISS-ALL',
      'NOTIF.INVESTIGATIVE-TREATMENT-TITLE',
      'NOTIF.INVESTIGATIVE-TREATMENT-MESSAGE'

    ]).subscribe(res => {
      this.emptyNotifString = res['NOTIF.EMPTY-NOTIFICATION-CENTER'];
      this.dismissAllLabel = res['NOTIF.DISMISS-ALL'];
      this.popoverTitle = res['NOTIF.INVESTIGATIVE-TREATMENT-TITLE'];
      this.popoverMessage = res['NOTIF.INVESTIGATIVE-TREATMENT-MESSAGE'];
      this.modalBtnConfirm = res['LAYOUT.NOTIF.CLOSE'];
      this.modalBtnCancel = res['LAYOUT.NOTIF.CANCEL'];
    });

    this.notificationManager.getNotificationsAndSenders().subscribe(notsAndSends => {
      this.notificationsSorted = notsAndSends.notifications;
      this.senders = this.orderSenders(notsAndSends.senders);

      if (!this.timerRunning) {
        this.timerRunning = true;
        this.ngZone.runOutsideAngular(() => {
          this.interval = setInterval(() => { this.checkChanges(); }, this.intervalDelay);
        });
      }

    });

    this.messageBroker.getCurrentMode().subscribe(mode => {
      this.isInInvestigativeMode = mode.id === INVESTIGATIVE_MODE_ID;
      this.isInAssistedMode = mode.id === ASSISTED_MODE_ID;
    });

    this.notificationManager.getNotificationCount().subscribe(lenght => this.count = lenght);

    this.notificationManager.getConfigurations().subscribe(configs => {
      this.configurations = configs;
    });
  }

  public checkConfig(sender: string, config: string): boolean {
    if (this.configurations?.has(sender)) {
      if (config === 'show') {
        return this.configurations.get(sender).getShow();
      }
    }
  }

  public orderSenders(senders: Map<string, GroupProperties>): GroupProperties[] {
    const orderedSenders: GroupProperties[] = [];
    this.sendersOrder.forEach(s => {
      if (senders.get(s)) {
        orderedSenders.push(senders.get(s));
      }
    });
    return orderedSenders;
  }

  public group(config: any): void {
    config.grouped = !config.grouped;
  }

  public onClose(): void {
    this.sidePanelService.hideTemporaryContent();
    this.utilityPanelChanged.emit(null);
  }
  // execute action
  public clickAction(notification: NotificationPublic, action?: Action): void {
    const notif: Notification = this.notificationService.getActiveNotifications(notification.sender).find(x => x.id === notification.id);

    if (action) {
      this.notificationService.performAction(notif, action);
    } else {
      if (notif.getActions()) {
        if (notification.sender == 'newEvents' && (this.isInInvestigativeMode || this.isInAssistedMode)) {
          if (this.isInInvestigativeMode) {
            const actionShadow = notif.getActions().find(x => x.name === 'default');
            this.showInvestigativeTreatmentConfirmationDialog(notif, actionShadow);
          } else {
            const qParam: QParam = { name: '', value: null };
            const newSelectionMessage: MessageParameters =
            { types: [gmsNoSelectionMessageType], messageBody: new GmsMessageData([], GmsSelectionType.None), qParam };
            this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, 'event-list', newSelectionMessage).toPromise().then(modeSwitched => {
              this.notificationService.performAction(notif, notif.getActions().find(x => x.name === 'default'));
            });
          }
        } else {
          this.notificationService.performAction(notif, notif.getActions().find(x => x.name === 'default'));
        }
      }
    }
  }

  // group action
  public groupAction(sender: string): void {
    this.notificationService.performGroupAction(sender);
  }

  public cancelAll(sender: string): void {
    this.notificationService.cancelAll(sender);
  }

  private checkChanges(): void {
    this.ref.markForCheck();
    this.timerRunning = false;

    if (this.notificationManager.visible === false) {
      this.senders.forEach(sender => {
        sender.grouped = false;
      });
    }

    clearInterval(this.interval);
  }

  private showInvestigativeTreatmentConfirmationDialog(notif: Notification, action?: Action): void {
    // Open confirm
    this.siModal.showConfirmationDialog(this.popoverMessage, this.popoverTitle, this.modalBtnConfirm, this.modalBtnCancel).subscribe(confirmation => {
      switch (confirmation) {
        case ConfirmationDialogResult.Confirm:
          const openingFrameId = 'event-list';
          const qParam: QParam = { name: '', value: null };
          const newSelectionMessage: MessageParameters =
            { types: [gmsNoSelectionMessageType], messageBody: new GmsMessageData([], GmsSelectionType.None), qParam };
          this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, openingFrameId, newSelectionMessage).toPromise().then(modeSwitched => {
            this.notificationService.performAction(notif, action);
          });
          break;
        case ConfirmationDialogResult.Decline:
          break;
        default:
          break;
      }
    });
  }
}
