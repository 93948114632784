import { asapScheduler, of as observableOf,  Observable ,  Observer ,  Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { IPreselectionService, FullSnapInId } from "@gms-flex/core";
import { TraceService, HfwUtility, isNullOrUndefined } from "@gms-flex/services-common";
import { ApplicationRight, AppRightsService, BrowserObject, GraphicsService, GmsMessageData, GmsManagedTypes, Operation } from "@gms-flex/services";

export const traceModule: string = "gmsSnapins_GraphicsServices";
const graphicViewerSnapinId: number = 18;
const showAppRightsId: number = 576;

interface MessageInfo {
    messageTypes: Array<string>;
    messageData: any;
    fullId: FullSnapInId;
}

@Injectable()
export class GraphicsPreselectService implements IPreselectionService {

    public typeId: string = "GraphicsViewerType";
    private _subscriptions: Map<string, Subscription> = new Map<string, Subscription>();

    /*
    * This method deteremines whether or not the object is a graphic item that will display content in the grahics snapin
    *
    * @param {any} managedType, the managedType.
    * @returns {boolean} true if the specified managed type will display content in the graphics snapin, else false.
    *
    * @memberof GraphicsPreselectService
    */
    public static isGraphicItemWithContentToDisplay(managedTypeName: string): boolean {
        if (managedTypeName === GmsManagedTypes.GRAPHIC.name ||    // 1   = "Graphic"                             = graphic
            managedTypeName === GmsManagedTypes.GRAPHIC_PAGE.name ||   // 42  = "GraphicPage"                         = viewport
            managedTypeName === GmsManagedTypes.PROJECT_GRAPHIC_ROOT_FOLDER.name) {   // 43  = "ProjectGraphicRootFolder"            = graphics folder
            return true;
        }

        return false;
    }

    constructor(private traceService: TraceService, private graphicsService: GraphicsService,
        private appRightsService: AppRightsService) {
        this.traceService.info(traceModule, "GraphicsPreselectService created");
    }

    /*
    * This method is called by HFW in order to allow snapins evaluate if it can handle the corresponding message.
    * The snapin shall return an observable 'immediately'!
    * On the returned observable, the snapin shall push the evaluated result; true if it can handle the message, else false.
    * In order to support good user experience that timespan used for evaluation shall be < 100ms.
    * HLDL defines a timeout of 5000ms (configurable). After that time, the result of the callback is not considered anymore.
    * Important: The returned observable must support 'teardownLogic'!!
    * => Any client subscribing to the observable can call 'unsubscribe' on the correponding subscription. This causes the disposal of all underlying resources.
    *
    * @param {Array<string>} messageTypes, the messageTypes.
    * @param {*} messageData, the messageData.
    * @param {FullSnapInId} fullId, the snapinId for which the preselection is invoked.
    * @returns {Observable<boolean>}, true if the specified snapin can handle the message, else false.
    *
    * @memberOf EventListPreselectService
    */
    public receivePreSelection(messageTypes: Array<string>, messageBody: any, fullId: FullSnapInId): Observable<boolean> {

      this.traceService.info(traceModule, "receivePreSelection() called from HFW:\nmessageType=%s; messageBody=%s; fullId=%s",
          messageTypes.join("-"), HfwUtility.serializeObject(messageBody), fullId.fullId());

      if (!this.CheckShowAppRights()) {
          return observableOf(false, asapScheduler);
      }

      let messageData: BrowserObject[] = (messageBody as GmsMessageData).data;
      let managedType: any = this.getManagedTypeName(messageData);
      if (managedType != null) {
          // if this is a graphic then return true immediately
          if (GraphicsPreselectService.isGraphicItemWithContentToDisplay(managedType)) {
              return observableOf(true, asapScheduler);
          }
          // if this is a library element such as a symbols folder etc. then return false immediately
          else if (this.isGraphicItemWithNoContentToDisplay(managedType)) {
              return observableOf(false, asapScheduler);
          }

          // package up message information
          let messageInfo: MessageInfo = {
              messageTypes: messageTypes,
              messageData: messageData,
              fullId: fullId
          };
          let objectId: string = this.getObjectId(messageInfo.messageData);
          if (objectId === undefined) {
              return observableOf(false, asapScheduler);
          }

          let snapinId: string = fullId.fullId();

          let preSelectObservable: Observable<boolean> = Observable.create((observer: Observer<boolean>) => {
              this.onSubscription(messageInfo, observer, fullId.fullId());
              return () => this.teardownLogic(snapinId);
          });

          return preSelectObservable;
      } else {
          // Defect 2267200: Flex - multi select of datapoints from system browser is slow to populate properties
          this.traceService.info(traceModule, "receivePreSelection() for multiple selection. Returning false");
          return observableOf(false, asapScheduler);
      }
    }
    private onSubscription(messageInfo: MessageInfo, observer: Observer<boolean>, fullId: string): void {

        this.traceService.info(traceModule, "onSubscription() called for GraphicsPreselectService, preselection evaluation to be done for graphics-snapin");

        this.processPreselectionAsync(messageInfo, observer, fullId);
    }

    private teardownLogic(snapinId: string): void {

        this.traceService.info(traceModule, "teardownLogic() called for GraphicsPreselectService, snapinId %s", snapinId);

    }

    private getObjectId(messageData: any): string {

        if (messageData !== null) {
            let msgData: any = <Array<any>>messageData;
            if (msgData !== null && msgData.length > 0) {
                let browserObject: BrowserObject = msgData[0] as BrowserObject;
                return browserObject.Attributes.ObjectId;
            }
        }
        return undefined;
    }

    private processPreselectionAsync(messageInfo: MessageInfo, observer: Observer<boolean>, fullId: string): void {

        if (this._subscriptions != null && this._subscriptions.has(fullId) && this._subscriptions.get(fullId) !== undefined) {
            this._subscriptions.get(fullId).unsubscribe();
            this._subscriptions.set(fullId, undefined);
        }

        let objectId: string = this.getObjectId(messageInfo.messageData);
        if (objectId !== undefined) {
            let sub: Subscription =
                this.graphicsService.hasGraphicalItems(objectId).subscribe(
                    (result) => this.onProcessPreSelectionResult(objectId, result, observer, fullId),
                    error => this.onProcessPreSelectionResultError(objectId, error, observer, fullId));

            this._subscriptions.set(fullId, sub);
        }
        else {
            this.traceService.warn(traceModule, "browserObject.Attributes.ObjectId undefined");
        }
    }

    private onProcessPreSelectionResult(objectId: string, result: boolean, observer: Observer<boolean>, fullId: string): void {

        observer.next(result);

        if (this._subscriptions != null && this._subscriptions.has(fullId) && this._subscriptions.get(fullId) !== undefined) {
            this._subscriptions.get(fullId).unsubscribe();
        }

        this.traceService.info(traceModule, "onProcessPreSelectionResult(): objectId: %s", objectId);
    }

    private onProcessPreSelectionResultError(objectId: string, error: Error, observer: Observer<boolean>, fullId: string): void {

        observer.next(false);

        if (this._subscriptions != null && this._subscriptions.has(fullId) && this._subscriptions.get(fullId) !== undefined) {
            this._subscriptions.get(fullId).unsubscribe();
        }

        this.traceService.error(traceModule, "onProcessPreSelectionResultError(): error: %s, objectId: %s", error.message, objectId);
    }

    /**
     *
     * @param messageData
     */
    private getManagedTypeName(messageData: BrowserObject[]): any {
        if (messageData != null && messageData.length === 1) {
            return messageData[0].Attributes.ManagedTypeName;
        }
        return null;
    }

    /*
     * This method deteremines whether or not the object is a graphic item that will NOT display content in the grahics snapin
     *
     * @param {any} managedType, the managedType.
     * @returns {boolean} true if the specified managed type will display content in the graphics snapin, else false.
     *
     * @memberof GraphicsPreselectService
     */
    private isGraphicItemWithNoContentToDisplay(managedType: any): boolean {
        if (managedType === 39 ||   // 39  = "LibraryBlock_SymbolsFolder"          = symbols folder
            managedType === 40 ||   // 40  = "LibraryBlock_TemplateGraphicsFolder" = graphics templates folder
            managedType === 41 ||   // 41  = "LibraryBlock_SampleGraphicsFolder"   = sample graphics folder
            managedType === 104) {  // 104 = "GraphicTemplate"                     = graphic template
            return true;
        }

        return false;
    }

  private CheckShowAppRights(): boolean {
    const appRight: ApplicationRight | undefined = this.appRightsService?.getAppRights(graphicViewerSnapinId);

    let show: boolean = false;
    if (!isNullOrUndefined(appRight)) {
      const showRights: Operation[] = isNullOrUndefined(appRight.Operations) ? [] : appRight.Operations.filter(f => f.Id === showAppRightsId);
      show = showRights.length > 0;
    }
    return show;
  }

}
