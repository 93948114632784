import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { SiFormItemComponent } from '@simpl/element-ng/form';

@Component({
  selector: 'si-formly-wrapper',
  templateUrl: './si-formly-wrapper.component.html',
  standalone: true,
  imports: [SiFormItemComponent, NgIf, FormlyModule]
})
export class SiFormlyWrapperComponent extends FieldWrapper {
  protected withValidationIcon = true;

  protected get label(): string | undefined {
    return this.props.label && this.props.hideLabel !== true ? this.props.label : undefined;
  }

  protected get labelWidth(): number | undefined {
    return this.props.labelWidth ? this.props.labelWidth : undefined;
  }

  protected get labelClass(): string | undefined {
    return this.props.hideLabel ? 'px-0 py-0' : undefined;
  }
}
