import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiSelectOptionTemplateDirective } from '../si-select-option-template.directive';
import { SiSelectOptionRow } from '../si-select.types';

@Component({
  selector: 'si-select-option',
  templateUrl: './si-select-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SiTranslateModule, SiSelectOptionTemplateDirective],
  host: {
    class: 'd-flex align-items-center overflow-hidden'
  }
})
export class SiSelectOptionComponent {
  @Input({ required: true }) option!: SiSelectOptionRow<unknown>;
  @Input() optionTemplate?: TemplateRef<unknown>;
}
