import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[si-content-action-bar-toggle]',
  standalone: true,
  imports: [NgClass],
  templateUrl: './si-content-action-bar-toggle.component.html',
  styleUrl: '../menu/si-menu-item.component.scss',
  host: { class: 'dropdown-item flex-grow-0 focus-inside' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiContentActionBarToggleComponent {
  @Input({ required: true }) icon!: string;
}
