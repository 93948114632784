import { isNullOrUndefined } from '@gms-flex/services-common';

import { GmsCommand } from '../../processor/command/gms-command';
import { Datapoint } from '../datapoint/gms-datapoint';

export class GraphicsDatapointHelper {
  private static readonly SYSTEMNAME_CNS_ID_SEPARATOR = ':'; // e.g. "System1.ManagementView..."
  /**
   *  Read graphics header and create Datapoints
   * @param graphicNode
   */
  public static createDatapoints(graphicNode: Node): Datapoint[] {

    const datapoints: Datapoint[] = [];
    const childnodes: NodeList = graphicNode.childNodes;

    if (childnodes !== undefined) {

      for (let i = 0; i < childnodes.length; ++i) {

        const graphicDatapoints: Node = childnodes[i];

        if (graphicDatapoints.nodeName === 'Graphic.Datapoints') {
          for (let j = 0; j < graphicDatapoints.childNodes.length; ++j) {

            const datapointInfoNode: Node = graphicDatapoints.childNodes[j];
            const dp: Datapoint = this.createDatapoint(datapointInfoNode);
            if (dp !== null) {
              datapoints.push(dp);
            }
          }
          break;
        }
      }
    }
    return datapoints;
  }

  /**
   * creates a command list declared per each datapoint in the graphics file's header
   * @param datapoints declared in the  graphics file's header
   */
  public static getCommands(datapoints: Datapoint[]): GmsCommand[] {
    const commands: GmsCommand[] = new Array<GmsCommand>();
    if (datapoints !== undefined) {
      datapoints.forEach(datapoint => {
        if (datapoint.Designation !== undefined && datapoint.Id !== undefined) {
          datapoint.Commands.forEach(commandName => {
            const command: GmsCommand = new GmsCommand(datapoint.Designation, datapoint.Id, commandName);
            if (commands.includes(command) === false) {
              commands.push(command);
            }
          });
        }
      });
    }
    return commands;
  }

  /**
   * Remove all semicolons from a datapoint reference, except the last one, e.g. "abc"->"abc", "ab;c"->"ab;c", "a;b;c"->"ab;c".
   * This allowes the usage of function properties in graphic templates
   * @param designation The designation, which could be modified
   * @returns the new designation
   */
  public static RemoveLeadingSemicolons(designation: string): string {
    if (designation === undefined) {
      return '';
    }

    designation = designation.toString().trim();
    if (designation.length === 0) {
      return '';
    }
    const parts: string[] = designation.split(';');
    if (parts.length > 1) {
      const theLast: string = parts.pop();
      designation = parts.join('') + ';' + theLast;
    }

    return designation;
  }

  public static getSystemNameFromIdSeparator(designation: string): string {
    const splitted: string[] = isNullOrUndefined(designation) ? [] :
      designation.split(this.SYSTEMNAME_CNS_ID_SEPARATOR);

    return (splitted?.length > 1) ? splitted[0] : '';
  }

  private static createDatapoint(node: Node): Datapoint {
    let result: Datapoint = null;
    if (node.nodeName === 'Datapoint') {
      result = new Datapoint();
      result.Deserialize(node);
    }
    return result;
  }
}
