/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/prefer-literal-enum-member */

export enum DateParts {
  all = 0,
  yy = 1,
  mm = 2,
  dd = 3
}

export enum TimeParts {
  all = 0,
  hh = 1,
  mm = 2,
  ss = 3,
  hs = 4
}

export enum ViewChange {
  add,
  remove,
  update
}

export enum WildcardBACnet {
  all = -1,
  allDays = 0,
  allHours = all,
  allHundredths = all,
  allMinutes = all,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  allMonths = 0,
  allSeconds = all,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  allYears = 0,
  lastDayOfMonth = 32,
  evenDays = 34,
  evenMonths = 14,
  oddDays = 33,
  oddMonths = 13
}

export enum Bacnet {
  DAYS = 34,
  MONTHS = 14,
  YEARS = 255,
  START_YEAR = 1900,
  END_YEAR = 2154
}
