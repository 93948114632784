/**
 * Base class for the summary-bar service.
 * Provides the methods to get if summary bar functionalities are visible.
 *
 * @export
 * @abstract
 * @class SummaryBarSnapInServiceBase
 */

export abstract class SummaryBarSnapInServiceBase {

   /**
   * Get the visibility of buzzer.
   *s
   * @abstracts
   * @returns { boolean}
   *
   * @memberOf SummaryBarSnapInServiceBase
   */
   public abstract buzzerVisible(): boolean ;

}
