<si-form-item
  [label]="label"
  [labelWidth]="labelWidth"
  [labelClass]="labelClass"
  [required]="props.required || false"
  [readonly]="props.readonly || false"
>
  @if (withValidationIcon) {
    <div class="form-control-has-icon w-100">
      <ng-template #fieldComponent />
      <i
        class="invalid-icon element-validation-issue"
        [class.addon-second-icon]="
          (field.type === 'password' || field.type === 'date' || field.type === 'datetime') &&
          showError
        "
        [class.d-block]="showError"
      ></i>
      <div class="invalid-feedback" [class.d-block]="showError">
        <formly-validation-message [field]="field" />
      </div>
    </div>
  } @else {
    <div class="w-100">
      <ng-template #fieldComponent />
      <div class="invalid-feedback" [class.d-block]="showError">
        <formly-validation-message [field]="field" />
      </div>
    </div>
  }
</si-form-item>
