import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { ResizeObserverService } from '@simpl/element-ng';
import { Subscription } from 'rxjs';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsElement } from '../elements/gms-element';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsLayer } from '../elements/gms-layer';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsBrowserObjectService } from '../services/gms-browser-object.service';
import { GraphicStateStorageService } from '../services/gms-storage.service';
import { GraphicState } from '../shared/graphicState';
import { GmsAdornerType } from '../types/gms-adorner-types';
import { GraphicType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { MouseMode } from '../types/gms-graphics-mouse-mode-types';
import { GradientBrushType, Point } from '../utilities/color-utility';
import { Utility } from '../utilities/utility';
import { GmsElementComponent } from './gms-element.component';
import { GmsLayerComponent } from './gms-layer.component';

@Component({
  selector: 'gms-graphic',
  template: `
      <div class="hfw-flex-container-column hfw-flex-item-grow" style="transform: translateZ(0);">
          <div [id]="containerId" #svgContainer tabindex="0" class="hfw-flex-container-column hfw-flex-item-grow"
               style="overflow: scroll; text-align: center; user-select: none; top: 0; left: 0; outline: none; touch-action: manipulation;
                       position: relative;"
               (click)="OnMouseClick($event)" (dblclick)="OnDoubleClick($event)"
               [scrollLeft]="scrollLeft" [scrollTop]="scrollTop"
               [style.cursor]="graphic.IsSliding ? 'pointer': 'default'">
              <!-- Events list popover -->
              <div [style.position]="'absolute'"
                   [style.top]="popoverY"
                   [style.left]="popoverX">
                  <gms-event-popover
                          *ngIf="graphic.ShowAlarmIndication"
                          #eventPopover
                          [fullSnapinID]="storageService.SnapinId"
                          [designations]="designations"
                          [hideButton]="'true'" />
              </div>
              <div style="position: fixed;"
                   *ngIf="graphic?.IsTooltipEnabled && graphic.isDoneLoading"
                   gms-tooltip
                   [graphic]="graphic"
                   [containerId]="containerId">
              </div>
              <!-- Events list popover END -->
              <svg [style.width]="svgDocumentWidth" [style.height]="svgDocumentHeight"
                   style="background-color: white;fill-opacity:1;stroke-opacity:0; position: absolute;
                   left: 0px; top: 0px; overflow: visible;">
                  <!-- NOTE:
                       The overflow: visible style is used to properly show the background.
                       The overflow: visible allows the dimensions of the inner rectangle to be
                       disregarded with respect to the calculation of the scrollbars
                       of 'svgContainer'.
                  -->
                  <svg style="overflow: visible; position: absolute; left: 0px; top: 0px;"
                       [style.width]="svgDocumentWidth" [style.height]="svgDocumentHeight">
                      <rect [attr.width]="svgContainer?.scrollWidth" [attr.height]="svgContainer?.scrollHeight"
                            style="position: absolute; left: 0px; top: 0px;"
                            class="transparent-background" stroke-opacity="0"/>
                  </svg>
                  <svg:svg id="svgDoc" #svgDoc [style.width.px]="svgDocumentWidth" [style.height.px]="svgDocumentHeight"
                           style="background-color: transparent;fill-opacity:1;stroke-opacity:0; position: absolute; left: 0px; top: 0px;">
                      <clipPath [id]="clipId" clipPathUnits="userSpaceOnUse">
                          <rect x="0" y="0" [attr.width]="graphic.Width" [attr.height]="graphic.Height"/>
                      </clipPath>
                      <g #transformGroup [attr.transform]="filteredTransformMatrix()"
                         [attr.clip-path]="'url(#'+ clipId + ')'">
                          <g id="ContentGroup">
                              <rect [attr.width]="graphic.Width" [attr.height]="graphic.Height"
                                    [ngClass]="HasTransparentBackground ? 'transparent-background' : null"
                                    [attr.fill]="HasTransparentBackground ? null : graphic.Background"
                                    [attr.fill-opacity]="HasTransparentBackground ? 1 : graphic.BackgroundOpacity" stroke-opacity="0"/>
                              <g *ngFor="let item of graphic.children;">
                                  <g *ngIf="item.Type === elementType.Layer" gms-layer [layer]="item"/>
                              </g>
                              <g class="noptrevents" *ngFor="let adornerItem of graphic.adorners;">
                                  <g *ngIf="adornerItem.AdornerType === adornerType.Selection" gms-adorner
                                     [adorner]="adornerItem"
                                     class="noptrevents"/>
                                  <g *ngIf="adornerItem.AdornerType === adornerType.Hover" gms-adorner
                                     [adorner]="adornerItem"
                                     class="noptrevents"/>
                              </g>
                              <g gms-alarms-container (openAlarmPopover)="onAlarmPopoverFunc($event)"
                                 [alarmsContainer]="graphic" />
                              <g #resources />
                          </g>
                      </g>
                      <g class="noptrevents" *ngFor="let buttonElement of graphic.buttonElements;">
                          <g gms-buttons [graphic]="graphic" [buttonElement]="buttonElement"/>
                      </g>
                  </svg:svg>
                  <defs>
                      <pattern id="pattern-error-comm"
                               x="0"
                               y="0"
                               width="10"
                               height="10"
                               patternUnits="userSpaceOnUse"
                               patternContentUnits="userSpaceOnUse">
                          <rect fill="#F1D511" fill-opacity="0.3" width="10" height="10"/>
                          <circle cx="10" cy="10" r="2" fill="#5A5D60"/>
                          <circle cx="12" cy="12" r="2" fill="#F1D511"/>
                      </pattern>
                      <ng-container #dynamicresources *ngFor="let item of graphic.dynamicResources;">
                          <linearGradient *ngIf="item.Type === gradientBrushType.Linear" [attr.id]="item.Id"
                                          [attr.x1]="item.Start.X" [attr.y1]="item.Start.Y" [attr.x2]="item.End.X"
                                          [attr.y2]="item.End.Y"
                                          [attr.spreadMethod]="item.SpreadMethod">
                              <ng-container #gradientStops *ngFor="let gradientStop of item.GradientStops;">
                                  <stop [attr.offset]="gradientStop.Offset" [attr.stop-color]="gradientStop.ColorString"
                                        [attr.stop-opacity]="gradientStop.Opacity"/>
                              </ng-container>
                          </linearGradient>
                          <radialGradient *ngIf="item.Type === gradientBrushType.Radial" [attr.id]="item.Id"
                                          [attr.cx]="item.Center.X" [attr.cy]="item.Center.Y"
                                          [attr.r]="item.RadiusX" [attr.fx]="item.Origin.X" [attr.fy]="item.Origin.Y"
                                          [attr.gradientTransform]="item.GradientTransform"
                                          [attr.gradientUnits]="item.GradientUnits"
                                          [attr.spreadMethod]="item.SpreadMethod">
                              <ng-container #gradientStops *ngFor="let gradientStop of item.GradientStops;">
                                  <stop [attr.offset]="gradientStop.Offset" [attr.stop-color]="gradientStop.ColorString"
                                        [attr.stop-opacity]="gradientStop.Opacity"/>
                              </ng-container>
                          </radialGradient>
                      </ng-container>
                      <ng-container *ngFor="let item of graphic?.dynamicFilterArr;">
                          <filter [attr.id]="item.EvaluationFilterId"
                                  x="-2000px" y="-2000px" width="4000px" height="4000px" filterUnits="userSpaceOnUse">
                              <feDropShadow
                                      [attr.dx]="item.Dx"
                                      [attr.dy]="item.Dy"
                                      [attr.stdDeviation]="0"
                                      [attr.flood-color]="item.ColorString" />
                          </filter>
                      </ng-container>
                  </defs>
              </svg>
          </div>
          <div #gmsToolbar gms-toolbar [graphic]="graphic"></div>
          <div #gmsMenu gms-menu *ngIf="ShowMenuButton" [graphic]="graphic"></div>
      </div>

      <!-- symbol, group, replication templates-->
      <!-- used to break cyclic view component imports - Error NG3003-->
      <ng-template #symbolInstance let-model="model">
         <svg:g gms-symbol-instance [element]="model"/>
      </ng-template>
      <ng-template #group let-model="model">
         <svg:g gms-group [element]="model"/>
      </ng-template>
      <ng-template #replication let-model="model">
         <svg:g gms-replication [element]="model"/>
      </ng-template>
  `,
  viewProviders: [GmsLayerComponent],
  styleUrl: './gms-graphic.component.scss'
})

export class GmsGraphicComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public graphic: GmsGraphic = null;
  @Input() public setGraphicLoaded: () => void = null;
  public elementType: any = GmsElementType; // Store a reference to the enum, so that we can compare in the template
  public adornerType: any = GmsAdornerType;
  public gradientBrushType: any = GradientBrushType;
  @ViewChild('resources', { static: false }) public resourcesGroup: ElementRef;
  @ViewChild('svgContainer', { static: true }) public svgContainer: ElementRef;
  @ViewChild('svgDoc', { static: false }) public svgDoc: ElementRef;
  public popoverX = 0;
  public popoverY = 0;
  @ViewChild('eventPopover') public eventPopover: any;
  @ViewChild('gmsToolbar', { static: false }) public gmsToolbar: ElementRef;
  @ViewChild('gmsMenu', { static: false }) public gmsMenu: ElementRef;
  @ViewChild('transformGroup', { static: true }) public transformGroup: ElementRef;

  // Used to break the cyclic imports with view components
  // Examples
  // symbol --> group --> symbol
  // replication --> symbol --> replication ...and so on
  // Important: static needs to true for create embedded views during ngoninit.
  @ViewChild('symbolInstance', { static: true }) public symbolTemplateRef: TemplateRef<any>;
  @ViewChild('group', { static: true }) public groupTemplateRef: TemplateRef<any>;
  @ViewChild('replication', { static: true }) public replicationTemplateRef: TemplateRef<any>;
  @ViewChild('graphicsCommon', { static: true }) public graphicsCommonTemplate: TemplateRef<any>;

  public readonly identityMatrix: string = 'matrix(1, 0, 0, 1, 0, 0)';
  public pinchTimeout: any;
  public isPinching = false;
  public svgDocumentWidth = 0;
  public svgDocumentHeight = 0;
  public scrollLeft = 0;
  public scrollTop = 0;
  public boundingBox: number[];
  public clipId = '';
  public containerId = '';
  public readonly padding: number = 9;
  public resizeSub: Subscription;
  public touchX: number;
  public touchY: number;
  public touchPercentX: number;
  public touchPercentY: number;
  public pinchDistance: number;
  public isOrientationChanged = false;
  public DocumentTouchFunction: any;
  public onAlarmPopoverFunc: any = this.onAlarmPopover.bind(this);
  public readonly zoomOutLimit: number = 1e-8;
  public readonly zoomInLimit: number = 500;
  public readonly zoomInScale: number = 1.25;
  public readonly zoomOutScale: number = 0.8;
  public designations: string[] = [];
  public UtilityRef: Utility = Utility;
  private resourcesAddedSub: Subscription;
  private resourcesRemovedSub: Subscription;
  private initialScaleViewSub: Subscription;
  private scaleViewSub: Subscription;
  private scaleToFitSub: Subscription;
  private resetScrollBarsSub: Subscription;
  private calculateMinMaxSub: Subscription;
  private resetSVGDocumentSub: Subscription;
  private zoomInButtonSub: Subscription;
  private zoomOutButtonSub: Subscription;
  private dynamicresourcesSub: Subscription;
  private dynamicFiltersSub: Subscription;
  private updateCoverageAreaSub: Subscription;
  private openAlarmPopoverSub: Subscription;
  private allocateScrollbarsSub: Subscription;
  private resetToDefaultZoomSub: Subscription;
  private closePopoverSub: Subscription;
  private tooltipToggleSub: Subscription;
  private pointCenteredZoomSub: Subscription;

  public get mouseMode(): MouseMode {
    return this.graphic !== undefined ? this.graphic.mouseMode : MouseMode.None;
  }

  public set mouseMode(value: MouseMode) {
    if (this.graphic !== undefined) {
      this.graphic.mouseMode = value;
    }
  }

  public get mouseDown(): boolean {
    return this.graphic !== undefined ? this.graphic.mouseDown : false;
  }

  public set mouseDown(value: boolean) {
    if (this.graphic !== undefined) {
      this.graphic.mouseDown = value;
    }
  }

  public get IsSliding(): boolean {
    return true;
    // return (this.graphic.IsSliding && this.mouseMode === MouseMode.Slide);
  }

  public get transformMatrix(): string {
    return this.graphic.transformMatrix;
  }

  public set transformMatrix(value: string) {
    this.graphic.transformMatrix = value;
  }

  public filteredTransformMatrix(): string {
    const numMat: number[] = this.getMatrixValues();
    if (numMat.filter(x => Number.isNaN(x)).length > 0) {
      return this.identityMatrix;
    }

    return this.transformMatrix;
  }

  public TrackAlarm(index: number, alarm: any): any {
    return alarm.Id;
  }

  constructor(changeDetector: ChangeDetectorRef, adornerService: GmsAdornerService,
    public elem: ElementRef, public ngZone: NgZone,
    public storageService: GraphicStateStorageService,
    public gmsBrowserObjectService: GmsBrowserObjectService,
    public resizeObserver: ResizeObserverService) {
    super(changeDetector, adornerService);
  }

  public ngOnInit(): void {
    this.resourcesAddedSub = this.graphic.resourcesAdded.subscribe(() => this.AddResources());
    this.resourcesRemovedSub = this.graphic.resourcesRemoved.subscribe(() => this.RemoveResources());
    this.initialScaleViewSub = this.graphic.initialScaleView.subscribe(() => this.initialScaleView());
    this.scaleViewSub = this.graphic.scaleView.subscribe(() => {
      this.scaleView();
    });
    this.scaleToFitSub = this.graphic.scaleToFit.subscribe(() => {
      this.scaleToFit();
    });
    this.resetScrollBarsSub = this.graphic.resetScrollBars.subscribe(() => this.updateScrollValues());
    this.calculateMinMaxSub = this.graphic.calculateMinMaxVisibility.subscribe((element: any) => {
      this.setVisibility(element);
      this.setChildElementsVisibility(element);
    });

    this.resetSVGDocumentSub = this.graphic.resetSVGDocument.subscribe((element: any) => {
      this.transformMatrix = this.identityMatrix;
      this.resetSVGDocWidthHeight();
    });

    this.zoomInButtonSub = this.graphic.zoomInButton.subscribe(() => this.onZoomInClick());
    this.zoomOutButtonSub = this.graphic.zoomOutButton.subscribe(() => this.onZoomOutClick());
    this.resetToDefaultZoomSub = this.graphic.resetToDefaultZoom.subscribe(() => this.resetToDefaultZoom());
    this.pointCenteredZoomSub = this.graphic.setPointCenteredZoom.subscribe(() => this.setPointCenteredPosition());

    const RESIZE_THROTTLE_TIMEOUT = 300;
    this.resizeSub = this.resizeObserver.observe(this.svgContainer.nativeElement, RESIZE_THROTTLE_TIMEOUT, true, true)
      .subscribe(() => this.onSVGDivResize());

    this.dynamicresourcesSub = this.graphic.dynamicresourcesChanged.subscribe(() => this.changeDetector.detectChanges());
    this.dynamicFiltersSub = this.graphic.dynamicFiltersChanged.subscribe(() => this.changeDetector.detectChanges());

    this.updateCoverageAreaSub = this.graphic.updateCoverageArea.subscribe(() => {
      this.updateCoverageArea();
    });
    this.openAlarmPopoverSub = this.graphic.openAlarmPopover.subscribe((event: any) => {
      this.onAlarmPopover(event);
    });
    this.allocateScrollbarsSub = this.graphic.allocateScrollbarSpaceDepth.subscribe(() => this.allocateScrollSpaceDepth());
    this.closePopoverSub = this.graphic.closePopover.subscribe(() => this.eventPopover?.close?.());
    this.tooltipToggleSub = this?.graphic?.TooltipToggle?.subscribe(() => {
      if (!isNullOrUndefined(this.graphic)) {
        this.graphic.toggleTooltipEnabled();
      }
    });

    this.ngZone.runOutsideAngular(() => {
      this.svgContainer.nativeElement.addEventListener('mousemove', this.OnMouseMove.bind(this));
      this.svgContainer.nativeElement.addEventListener('wheel', this.OnWheel.bind(this));
      this.svgContainer.nativeElement.addEventListener('touchstart', this.OnTouchStart.bind(this));
      this.svgContainer.nativeElement.addEventListener('touchmove', this.OnTouchMove.bind(this));
      this.svgContainer.nativeElement.addEventListener('touchcancel', this.OnTouchCancel.bind(this));
      this.svgContainer.nativeElement.addEventListener('touchend', this.OnTouchEnd.bind(this));
      this.svgContainer.nativeElement.addEventListener('scroll', this.OnScroll.bind(this));

      // @NOTE: Event Listeners should be removed on the document.
      document.addEventListener('wheel', this.DocumentOnWheel, { passive: false });
    });

    this.clipId = `${this.storageService.SnapinId}-clip`;
    this.containerId = `${this.storageService.SnapinId}-container`;

    this.graphic.symbolTemplateRef = this.symbolTemplateRef;
    this.graphic.groupTemplateRef = this.groupTemplateRef;
    this.graphic.replicationTemplateRef = this.replicationTemplateRef;

  }

  public ngOnDestroy(): void {
    this.resourcesAddedSub?.unsubscribe();
    this.resourcesRemovedSub?.unsubscribe();
    this.initialScaleViewSub?.unsubscribe();
    this.scaleViewSub?.unsubscribe();
    this.scaleToFitSub?.unsubscribe();
    this.resetScrollBarsSub?.unsubscribe();
    this.calculateMinMaxSub?.unsubscribe();
    this.zoomInButtonSub?.unsubscribe();
    this.zoomOutButtonSub?.unsubscribe();
    this.dynamicresourcesSub?.unsubscribe();
    this.dynamicFiltersSub?.unsubscribe();
    this.resetSVGDocumentSub?.unsubscribe();
    this.updateCoverageAreaSub?.unsubscribe();
    this.openAlarmPopoverSub?.unsubscribe();
    this.allocateScrollbarsSub?.unsubscribe();
    this.resetToDefaultZoomSub?.unsubscribe();
    this.resizeSub?.unsubscribe();
    this.closePopoverSub?.unsubscribe();
    this.tooltipToggleSub?.unsubscribe();
    this.pointCenteredZoomSub?.unsubscribe();

    this.updateScrollValues();
    this.graphic.symbolTemplateRef = undefined;
    this.graphic.groupTemplateRef = undefined;
    this.graphic.replicationTemplateRef = undefined;
    this.graphic.scrollLeft = this.scrollLeft;
    this.graphic.scrollTop = this.scrollTop;
    this.graphic.svgDocumentWidth = this.svgDocumentWidth;
    this.graphic.svgDocumentHeight = this.svgDocumentHeight;
    this.graphic.transformMatrix = this.transformMatrix;

    this.changeDetector.detach();
    document.removeEventListener('wheel', this.DocumentOnWheel);
  }

  public onAlarmPopover(event: any): void {
    this.eventPopover?.close?.();
    this.changeDetector.detectChanges();
    const svgDocRect: ClientRect = this.svgDoc.nativeElement.getBoundingClientRect();
    const alarmRect: ClientRect = event.alarmRect;
    this.popoverX = alarmRect.left + (alarmRect.width / 2) - svgDocRect.left;
    this.popoverY = alarmRect.top - svgDocRect.top;
    this.designations = event.srcDesignations;
    this.eventPopover?.toggle?.();
  }

  public centerScrollbars(): void {
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    const [scrollLeftMax, scrollTopMax] = this.maxScrollDimensions();
    if (containerWidth < this.svgDocumentWidth) {
      this.scrollLeft = scrollLeftMax / 2;
    }

    if (containerHeight < this.svgDocumentHeight) {
      this.scrollTop = scrollTopMax / 2;
    }
  }

  public getBoundingBox(): number[] {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = 0;
    let maxY = 0;

    if (this.graphic.GraphicType === GraphicType.GraphicTemplate) {
      return [0, this.graphic.Width, 0, this.graphic.Height];
    }

    for (const layer of this.graphic.children) {
      for (const element of layer.children) {
        const rect: any = element.BoundingRectDesign;
        const centerX: number = rect.X + (rect.Width / 2);
        const centerY: number = rect.Y + (rect.Height / 2);
        const startX: number = Math.max(0, centerX - ((element.ScaleX * rect.Width) / 2));
        const startY: number = Math.max(0, centerY - ((element.ScaleY * rect.Height) / 2));
        const endX: number = Math.min(this.graphic.Width, centerX + ((element.ScaleX * rect.Width) / 2));
        const endY: number = Math.min(this.graphic.Height, centerY + ((element.ScaleY * rect.Height) / 2));

        if (endX < 0 || endY < 0 || startX > this.graphic.Width || startY > this.graphic.Height) {
          continue;
        }

        minX = Math.min(startX, minX);
        minY = Math.min(startY, minY);
        maxX = Math.max(endX, maxX);
        maxY = Math.max(endY, maxY);
      }
    }

    return [minX, maxX, minY, maxY].map(Math.floor);
  }

  public get HasTransparentBackground(): boolean {
    return this.graphic.BackgroundOpacity === 0;
  }

  public get ShowMenuButton(): boolean {
    let numLayers = 1;
    let numDepths = 1;
    if (this.graphic !== undefined && this.graphic.children !== undefined) {
      numLayers = this.graphic.children.filter((layer: GmsLayer) => layer.Depths !== undefined && layer.Depths.length > 0).length;
      if (this.graphic.depths !== undefined && this.graphic.depths.depthList !== undefined) {
        numDepths = this.graphic.depths.depthList.length;
      }
    }

    const showMenuButton = !(numDepths <= 1 && numLayers <= 1);
    return showMenuButton;
  }

  public get currentZoomLevel(): number {
    return this.graphic !== undefined ? this.graphic.CurrentZoomLevel : 1;
  }

  public set currentZoomLevel(value: number) {
    if (this.graphic !== undefined) {
      this.graphic.CurrentZoomLevel = value;
    }
  }

  public resetToDefaultZoom(): void {
    this.currentZoomLevel = 1;
    this.transformMatrix = this.identityMatrix;
    this.scrollLeft = 0;
    this.scrollTop = 0;
    this.resetSVGDocWidthHeight();
  }

  // includes workaraound to enable single/double click handlers
  // on same Element
  // src: https://stackoverflow.com/questions/48295288
  public OnMouseClick(event: any): void {
    this.preventSingleClick = false;
    this.timer = setTimeout(() => this.singleClickCallBack(), this.singleClickDelay);
  }

  public OnDoubleClick(event: any): void {
    this.preventSingleClick = true;
    clearTimeout(this.timer);
  }

  public resetSVGDocWidthHeight(): void {
    const [width, height] = this.getSVGContainerDimensions();
    this.svgDocumentWidth = width - 1;
    this.svgDocumentHeight = height - 1;
    this.changeDetector.detectChanges();
  }

  public updateScrollValues(): void {
    this.scrollLeft = this.svgContainer.nativeElement.scrollLeft;
    this.scrollTop = this.svgContainer.nativeElement.scrollTop;
  }

  // NOTE: check mouse listener warning
  public OnWheel(e: WheelEvent): void {
    if (e.ctrlKey) {
      e.preventDefault();
      this.updateScrollValues();
      const isScrollIn: boolean = e.deltaY < 0;

      const [percentageX, percentageY] = this.getMouseGraphicPercentagePosition(e);
      if (isScrollIn) {
        this.zoom(this.zoomInScale, percentageX, percentageY);
      } else {
        this.zoom(this.zoomOutScale, percentageX, percentageY);
      }

      if (e.shiftKey) {
        this.setPointCenteredPosition();
      }
    }
  }

  public DocumentOnWheel(e: KeyboardEvent): void {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  }

  // NOTE: The following code block is necessary for the zooming of a graphic.
  // When the code block is removed, and replaced with a seemingly equivalent
  // addEventListener, the zoom out behavior does not work as intended.
  @HostListener('window:wheel')
  public windowOnWheel(): void {
    return;
  }

  @HostListener('window:keydown.F10', ['$event'])
  public GlobalOnKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.scaleToFit();
  }

  @HostListener('mousedown', ['$event'])
  public OnMouseDown(e: MouseEvent): void {
    this.mouseDown = this.isPrimaryButtonPressed(e);
    // this.clearSelection();
  }

  @HostListener('window:mousedown', ['$event'])
  public WindowOnMouseDown(e: MouseEvent): void {
    const popoverList: HTMLElement[] = Array.from(document.querySelectorAll('popover-container'));
    const targetElement: HTMLElement = e.target as any;
    let isContainedInPopoverContainer = false;
    for (const popover of popoverList) {
      if (popover.contains(targetElement)) {
        isContainedInPopoverContainer = true;
      }
    }
    const isTargetInCurrentPane: boolean = this.svgDoc?.nativeElement?.contains(e.target) || isContainedInPopoverContainer;
    if (isTargetInCurrentPane === false) {
      this.eventPopover?.close?.();
    }
  }

  @HostListener('window:mouseup', ['$event'])
  public OnMouseUp(e: MouseEvent): void {
    switch (this.mouseMode) {
      case MouseMode.Slide:
        if (this.mouseDown) {
          // finish the last sliding event
          this.graphic.sliding.next(e);
          // and then stop the sliding
          this.stopSliding();
        }
        break;

      default:
        break;
    }
  }

  public OnMouseMove(e: MouseEvent): void {
    e.preventDefault();

    switch (this.mouseMode) {
      case MouseMode.Slide:
        if (this.mouseDown) {
          this.graphic.sliding.next(e);
          // CursorType = GmsElementCursorType.Default;
        }
        break;

      default:
        break;
    }
  }

  public calculateCenterPosition(): number[] {
    const containerRect: ClientRect = this.svgContainer.nativeElement.getBoundingClientRect();
    const svgRect: ClientRect = this.svgDoc.nativeElement.getBoundingClientRect();

    const midPoint: Point = new Point((containerRect.left - svgRect.left) + (containerRect.width / 2),
      (containerRect.top - svgRect.top) + (containerRect.height / 2));

    const percentX: number = midPoint.X / this.svgDocumentWidth;
    const percentY: number = midPoint.Y / this.svgDocumentHeight;

    return [percentX, percentY];
  }

  public onZoomInClick(): void {
    if (this.hasScrollbars()) {
      const [percentX, percentY] = this.calculateCenterPosition();
      this.zoom(this.zoomInScale, percentX, percentY);
    } else {
      this.zoom(this.zoomInScale, .5, .5);
    }
  }

  public onZoomOutClick(): void {
    if (this.hasScrollbars()) {
      const [percentX, percentY] = this.calculateCenterPosition();
      this.zoom(this.zoomOutScale, percentX, percentY);
    } else {
      this.zoom(this.zoomOutScale, .5, .5);
    }
  }

  public OnScroll(): void {
    if (!isNullOrUndefined(this?.graphic?.HoverTooltipElement)) {
      this.graphic.HoverTooltipElement = undefined;
    }
  }

  public OnTouchStart(e: any): void {
    switch (e.touches.length) {
      case 1:
        this.setTouchCoordinates(e);
        break;
      case 2:
        this.setTouchCoordinates(e);
        this.pinchDistance = this.calculateTouchDistance(e);
        break;
      default:
        break;
    }
  }

  public setOrientationChanged(): void {
    this.isOrientationChanged = true;
  }

  public unsetOrientationChanged(): void {
    this.isOrientationChanged = false;
  }

  public setTouchCoordinates(e: any): void {
    this.touchX = e.touches[0].clientX;
    this.touchY = e.touches[0].clientY;
  }

  public calculateTouchDistance(e: any): number {
    return Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
  }

  public OnTouchMove(e: any): void {

    e.preventDefault();

    if (this.mouseMode === MouseMode.Slide) {
      if (this.mouseDown) {
        this.graphic.sliding.next(e);
      }
      return;
    }

    switch (e.touches.length) {
      case 1:
        if (this.isPinching) {
          return;
        }

        if (this.touchX === 0 && this.touchY === 0) {
          this.setTouchCoordinates(e);
        }

        const horizontalDist: number = e.touches[0].pageX - this.touchX;
        const verticalDist: number = e.touches[0].pageY - this.touchY;
        this.touchX = e.touches[0].pageX;
        this.touchY = e.touches[0].pageY;
        this.decreaseScrollValues(horizontalDist, verticalDist);
        break;
      case 2:
        this.setPinchTimeout();
        const newPinchDistance: number = this.calculateTouchDistance(e);
        const diffDistance: number = newPinchDistance - this.pinchDistance;
        const isAbovePinchMovementThreshold: boolean = Math.abs(diffDistance) > 4;
        const [percentageX, percentageY] = this.getTouchPercentagePosition(e);
        if (newPinchDistance > this.pinchDistance && isAbovePinchMovementThreshold) {
          this.zoom(1.15, percentageX, percentageY);
        } else if (isAbovePinchMovementThreshold) {
          this.zoom(0.8695, percentageX, percentageY);
        }

        this.changeDetector.detectChanges();
        this.pinchDistance = newPinchDistance;
        break;
      default:
        break;
    }
  }

  @HostListener('onorientationchange')
  public onOrientationChange(): void {
    this.isOrientationChanged = true;
  }

  public OnTouchEnd(event: any): void {
    if (this.gmsMenu !== undefined && event.sourceElement === this.gmsMenu.nativeElement
      || this.gmsToolbar && event.sourceElement === this.gmsToolbar.nativeElement) {
      return;
    }

    this.OnMouseClick(event);
  }

  public OnTouchCancel(e: any): void {
    e.preventDefault();
  }

  public adjustZoomVisibility(): void {
    for (const layer of this.graphic.children) {
      this.setVisibility(layer);

      if (!layer.Visible) {
        continue;
      }

      this.setChildElementsVisibility(layer);
    }
  }

  public setChildElementsVisibility(element: GmsElement): void {
    for (const child of element.children) {
      this.setVisibility(child);
      if (child.Visible && child.MinMaxVisibility && child.hasChildren) {
        this.setChildElementsVisibility(child);
      }
    }
  }

  public setVisibility(element: GmsElement): void {
    if (element.MinVisibility === undefined && element.MaxVisibility === undefined) {
      return;
    }

    const currentVisibilityLevel: number = this.graphic.Width / this.currentZoomLevel;

    if (element.MinVisibility === undefined) {
      element.MinMaxVisibility = currentVisibilityLevel < element.MaxVisibility;
    } else if (element.MaxVisibility === undefined) {
      element.MinMaxVisibility = currentVisibilityLevel > element.MinVisibility;
    } else {
      element.MinMaxVisibility = currentVisibilityLevel > element.MinVisibility && currentVisibilityLevel < element.MaxVisibility;
    }

    this.changeDetector.detectChanges();
  }

  public scaleView(): void {
    if (this.storageService.HasDefinedState) {
      this.restoreState();
      this.graphic.stateRestored = true;
      return;
    }

    if (this.HasViewport) {
      this.scaleToFitViewport();
    } else if (this.graphic !== undefined && this.graphic.IsPermScaleToFit) {
      this.scaleToFit();
    } else {
      // For Auto Fit: Disabled
      this.allocateScrollSpace();
    }
  }

  public onSVGDivResize(): void {
    if (this.graphic !== undefined) {
      if (this.graphic.IsPermScaleToFit) {
        this.scaleToFit();
      } else {
        this.centerZoom();
        const boundaryWidth: number = this.graphic.Width * this.currentZoomLevel;
        const boundaryHeight: number = this.graphic.Height * this.currentZoomLevel;
        this.svgDocumentWidth = Math.max(this.svgDocumentWidth, boundaryWidth);
        this.svgDocumentHeight = Math.max(this.svgDocumentHeight, boundaryHeight);
        this.changeDetector.detectChanges();
      }
    }
  }

  private singleClickCallBack(): void {
    if (!this.preventSingleClick) {
      this.graphic.GmsObjectSelectionService.reset();
    }
  }

  private allocateScrollSpace(): void {
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    this.centerGraphic();
    this.svgDocumentWidth = Math.max(this.graphic.Width * this.currentZoomLevel, containerWidth);
    this.svgDocumentHeight = Math.max(this.graphic.Height * this.currentZoomLevel, containerHeight);
    this.scrollLeft = 0;
    this.scrollTop = 0;
    this.changeDetector.detectChanges();
  }

  private allocateScrollSpaceDepth(): void {
    const scale: number = this.graphic.getDisplaySizeScaleFactor() / this.currentZoomLevel;
    const [centerPercentageX, centerPercentageY] = this.getViewportCenterGraphicPercentagePosition();
    this.zoom(scale, centerPercentageX, centerPercentageY);
    this.changeDetector.detectChanges();
  }

  private AddResources(): void {
    const resourcesNode: Node = this.resourcesGroup.nativeElement;
    this.graphic.Resources.forEach(resource => {
      resourcesNode.appendChild(resource);
    });
  }

  private RemoveResources(): void {
    const resourcesNode: Node = this.resourcesGroup.nativeElement;
    while (resourcesNode && resourcesNode.hasChildNodes()) {
      resourcesNode.removeChild(resourcesNode.firstChild);
    }
  }

  private isZoomIn(scale: number): boolean {
    return scale > 1;
  }

  private passingZoomLimit(scale: number): boolean {
    if (this.svgDocumentWidth === 0 || this.svgDocumentHeight === 0) {
      return true;
    }

    const passingZoomOutLimit: boolean = this.currentZoomLevel < this.zoomOutLimit && !this.isZoomIn(scale);
    const passingZoomInLimit: boolean = this.currentZoomLevel > this.zoomInLimit && this.isZoomIn(scale);
    return passingZoomInLimit || passingZoomOutLimit;
  }

  private scaleSVGDocument(scale: number): void {
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    this.svgDocumentWidth = Math.max(this.graphic.Width * scale, containerWidth);
    this.svgDocumentHeight = Math.max(this.graphic.Height * scale, containerHeight);
    this.changeDetector.detectChanges();
  }

  private updateCurrentZoomLevel(scale: number): void {
    this.currentZoomLevel = this.preciseRound(this.currentZoomLevel * scale);
  }

  private scaleGraphicZoomLevel(scale: number): void {
    if (this.passingZoomLimit(scale)) {
      return;
    }

    this.updateCurrentZoomLevel(scale);
    this.scaleMatrix(scale);
    this.scaleSVGDocument(scale);
    this.setChildElementsVisibility(this.graphic);
  }

  private scaleToFitViewport(): void {
    this.resetToDefaultZoom();
    let scale = 1;
    const [tgtViewportX, tgtViewportY, tgtViewportWidth, tgtViewportHeight] = this.graphic.viewport.Value;
    const tgtViewportMidX: number = tgtViewportX + (tgtViewportWidth / 2);
    const tgtViewportMidY: number = tgtViewportY + (tgtViewportHeight / 2);
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();

    if (this.graphic.viewport.IsAutoViewport) {
      scale = this.graphic.Width / this.graphic.SelectedDepth.DisplaySize;
    } else {
      scale = this.proportionalScale(tgtViewportWidth, tgtViewportHeight);
    }

    this.svgDocumentWidth = Math.max(this.graphic.Width, this.svgDocumentWidth);
    this.svgDocumentHeight = Math.max(this.graphic.Height, this.svgDocumentHeight);

    const midXPercentagePosition: number = tgtViewportMidX / this.graphic.Width;
    const midYPercentagePosition: number = tgtViewportMidY / this.graphic.Height;

    this.scaleGraphicZoomLevel(scale);
    this.centerGraphic();
    const matrix: SVGMatrix = this.getMatrix();

    const targetCenterX: number = (midXPercentagePosition * this.svgDocumentWidth) + matrix.e;
    const targetCenterY: number = (midYPercentagePosition * this.svgDocumentHeight) + matrix.f;
    const xDisplacementPixels: number = targetCenterX - (containerWidth / 2);
    const yDisplacementPixels: number = targetCenterY - (containerHeight / 2);
    this.increaseScrollValues(xDisplacementPixels, yDisplacementPixels);
  }

  private hasScrollbars(): boolean {
    const hasVerticalScrollBar: boolean = this.svgContainer?.nativeElement?.scrollHeight > this.svgContainer?.nativeElement?.clientHeight;
    const hasHorizontalScrollBar: boolean = this.svgContainer?.nativeElement?.scrollWidth > this.svgContainer?.nativeElement?.clientWidth;
    return hasVerticalScrollBar || hasHorizontalScrollBar;
  }

  private resetTouchValues(): void {
    this.touchX = 0;
    this.touchY = 0;
  }

  private setPinchTimeout(): void {
    const timeoutVal = 200;
    this.isPinching = true;
    clearTimeout(this.pinchTimeout);
    this.pinchTimeout = setTimeout(() => this.pinchCallBack(), timeoutVal);
  }

  // Introduced for better profiling
  private pinchCallBack(): void {
    this.isPinching = false;
    this.resetTouchValues();
  }

  private getViewportCenterGraphicPercentagePosition(): number[] {
    const rect: ClientRect = this.svgContainer?.nativeElement?.getBoundingClientRect();
    const scrollLeft = this.svgContainer?.nativeElement?.scrollLeft;
    const scrollTop = this.svgContainer?.nativeElement?.scrollTop;
    if (rect === undefined || scrollLeft === undefined || scrollTop === undefined) {
      return [undefined, undefined];
    }

    const centerViewportX: number = scrollLeft + (rect.width / 2);
    const centerViewportY: number = scrollTop + (rect.height / 2);
    const currGraphicWidth: number = this.graphic.Width * this.currentZoomLevel;
    const currGraphicHeight: number = this.graphic.Height * this.currentZoomLevel;
    const centerPercentageX: number = centerViewportX / currGraphicWidth;
    const centerPercentageY: number = centerViewportY / currGraphicHeight;
    return [centerPercentageX, centerPercentageY];
  }

  private getMouseDocCoordinates(e: any): any {
    const rect: any = this.svgDoc?.nativeElement?.getBoundingClientRect();
    const mouseX: number = (e.clientX - rect.left);
    const mouseY: number = (e.clientY - rect.top);
    return [mouseX, mouseY];
  }

  private getSelectedElements(): GmsElement[] {
    const selectedElements: GmsElement[] = [];
    const adorners: GmsAdorner[] = this?.graphic?.adorners || [];
    for (const adorner of adorners) {
      if (adorner.AdornerType === this.adornerType.Selection) {
        const htmlElement: HTMLElement = document.getElementById(adorner?.SourceElement?.Id);
        if (!isNullOrUndefined(htmlElement) && Utility.isDomVisible(htmlElement)) {
          selectedElements.push(adorner.SourceElement);
        }
      }
    }

    return selectedElements;
  }

  private setPointCenteredPosition(): void {
    const selectedElements: GmsElement[] = this.getSelectedElements();
    if (selectedElements.length === 0) {
      return;
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    const svgDocumentRect: DOMRect = this.svgDoc?.nativeElement?.getBoundingClientRect();
    if (isNullOrUndefined(svgDocumentRect)) {
      return;
    }

    for (const element of selectedElements) {
      const htmlElement: HTMLElement = document.getElementById(element.Id);
      const elementRect: DOMRect = htmlElement.getBoundingClientRect();
      const currMinX: number = elementRect.left - svgDocumentRect.left;
      const currMinY: number = elementRect.top - svgDocumentRect.top;
      const currMaxX: number = currMinX + elementRect.width;
      const currMaxY: number = currMinY + elementRect.height;
      minX = Math.min(minX, currMinX);
      minY = Math.min(minY, currMinY);
      maxX = Math.max(maxX, currMaxX);
      maxY = Math.max(maxY, currMaxY);
    }

    const centerX: number = (minX + maxX) / 2;
    const centerY: number = (minY + maxY) / 2;
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    const containerCenterX: number = containerWidth / 2;
    const containerCenterY: number = containerHeight / 2;

    this.scrollLeft = Math.max(centerX - containerCenterX, 0);
    this.scrollTop = Math.max(centerY - containerCenterY, 0);
    this.changeDetector.detectChanges();
  }

  private getMouseGraphicPercentagePosition(e: WheelEvent): any {
    const [mouseX, mouseY] = this.getMouseDocCoordinates(e);
    const currGraphicWidth: number = this.graphic.Width * this.currentZoomLevel;
    const currGraphicHeight: number = this.graphic.Height * this.currentZoomLevel;
    const matrix: SVGMatrix = this.getMatrix();
    const maxX: number = (currGraphicWidth) + matrix.e;
    const maxY: number = (currGraphicHeight) + matrix.f;
    const boundedMouseX: number = this.clamp(mouseX, matrix.e, maxX);
    const boundedMouseY: number = this.clamp(mouseY, matrix.f, maxY);
    const sourceGraphicX: number = boundedMouseX - matrix.e;
    const sourceGraphicY: number = boundedMouseY - matrix.f;
    const mousePercentageX: number = this.preciseRound(sourceGraphicX / currGraphicWidth);
    const mousePercentageY: number = this.preciseRound(sourceGraphicY / currGraphicHeight);
    return [mousePercentageX, mousePercentageY];
  }

  private clamp(val: number, min: number, max: number): number {
    return Math.min(Math.max(val, min), max);
  }

  private getTouchCoordinates(e: any): any {
    const rect: any = this.svgDoc?.nativeElement?.getBoundingClientRect();
    const averageX: number = Math.abs((e.touches[1].clientX + e.touches[0].clientX) / 2);
    const averageY: number = Math.abs((e.touches[1].clientY + e.touches[0].clientY) / 2);

    const mouseX: number = (averageX - rect.left);
    const mouseY: number = (averageY - rect.top);
    return [mouseX, mouseY];
  }

  private getTouchPercentagePosition(e: any): number[] {
    const [touchX, touchY] = this.getTouchCoordinates(e);
    const touchPercentageX: number = this.preciseRound(touchX / this.svgDocumentWidth);
    const touchPercentageY: number = this.preciseRound(touchY / this.svgDocumentHeight);
    return [touchPercentageX, touchPercentageY];
  }

  private preciseRound(x: number): number {
    return Number(x.toPrecision(5));
  }

  private maxScrollDimensions(): number[] {
    const scrollLeftMax: number = (this.svgContainer.nativeElement.scrollWidth - this.svgContainer.nativeElement.clientWidth);
    const scrollTopMax: number = (this.svgContainer.nativeElement.scrollHeight - this.svgContainer.nativeElement.clientHeight);
    return [scrollLeftMax, scrollTopMax];
  }

  private setSVGScrollCoordinates(percentageX: number, percentageY: number, containerX: number, containerY: number): void {
    const matrix: SVGMatrix = this.getMatrix();
    this.scrollLeft = (percentageX * this.svgDocumentWidth) + matrix.e - containerX;
    this.scrollTop = (percentageY * this.svgDocumentHeight) + matrix.f - containerY;
    this.changeDetector.detectChanges();
  }

  private setScrollbarPosition(scale: number, percentageX: number, percentageY: number): void {
    const [containerX, containerY] = this.getPointerContainerCoors(percentageX, percentageY);
    this.scaleSVGDocument(scale);
    // NOTE: Check if change is needed in implementation
    this.scaleTransformMatrix(scale);
    this.setScrollSpace();
    this.centerGraphic();
    this.changeDetector.detectChanges();

    this.setSVGScrollCoordinates(percentageX, percentageY, containerX, containerY);
  }

  private getPointerContainerCoors(percentageX: number, percentageY: number): number[] {
    const xSvgCoor: number = percentageX * this.svgDocumentWidth;
    const ySvgCoor: number = percentageY * this.svgDocumentHeight;
    const containerRect: ClientRect = this.svgContainer.nativeElement.getBoundingClientRect();
    const svgDocRect: ClientRect = this.svgDoc.nativeElement.getBoundingClientRect();
    const xDistance: number = containerRect.left - svgDocRect.left;
    const yDistance: number = containerRect.top - svgDocRect.top;

    const xContainerCoor: number = xSvgCoor - xDistance;
    const yContainerCoor: number = ySvgCoor - yDistance;
    return [xContainerCoor, yContainerCoor];
  }

  private scaleTransformMatrix(scale: number): void {
    if (scale === 0) {
      this.transformMatrix = this.identityMatrix;
      return;
    }

    const numMat: number[] = this.getFilteredMatrixValues().map(x => this.preciseRound(Number(x) * scale));
    this.transformMatrix = `matrix(${numMat})`;
  }

  private scaleMatrix(scale: number): void {
    if (scale === 0) {
      this.transformMatrix = this.identityMatrix;
      return;
    }

    const numMat: number[] = this.getFilteredMatrixValues().map(x => this.preciseRound(Number(x) * scale));
    this.transformMatrix = `matrix(${numMat})`;
  }

  private checkTransformMatrix(): void {
    if (this.transformMatrix == null) {
      this.transformMatrix = this.identityMatrix;
    }
  }

  private getFilteredMatrixValues(): number[] {
    this.checkTransformMatrix();
    const numMat: number[] = this.getMatrixValues();
    return numMat;
  }

  private getMatrixValues(): number[] {
    const numMat: number[] = this.transformMatrix.slice(7, -1).split(',').map(x => this.preciseRound(Number(x)));
    return numMat;
  }

  private doesGraphicNeedScrollbars(): boolean {
    const matrix: SVGMatrix = this.getMatrix();
    const maxX: number = (this.graphic.Width * this.currentZoomLevel) + matrix.e;
    const maxY: number = (this.graphic.Height * this.currentZoomLevel) + matrix.f;
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    const isHorizontalScrollbar: boolean = maxX > containerWidth;
    const isVerticalScrollbar: boolean = maxY > containerHeight;
    return isHorizontalScrollbar || isVerticalScrollbar;
  }

  private zoom(scale: number, percentageX: number, percentageY: number): void {
    if (this.svgDocumentWidth === 0 || this.svgDocumentHeight === 0) {
      return;
    }

    if ((this.currentZoomLevel < this.zoomOutLimit && this.isZoomIn(scale)) || (this.currentZoomLevel > this.zoomInLimit && this.isZoomIn(scale))) {
      return;
    }

    this.graphic.IsPermScaleToFit = false;
    this.currentZoomLevel = this.currentZoomLevel * scale;

    if (this.hasScrollbars()) {
      this.zoomWithScrollbar(scale, percentageX, percentageY);
    } else {
      this.zoomWithoutScrollbar(scale, percentageX, percentageY);
    }

    this.adjustZoomVisibility();
    this.changeDetector.detectChanges();
    if (this.currentZoomLevel > 1 && this.graphic.updateButtonTransform !== undefined) {
      this.graphic.updateButtonTransform.next();
    }

    this.graphic.UpdateNotZoomableElements();

    if (this.currentZoomLevel <= 1 && this.graphic.zoomChanged) {
      this.graphic.zoomChanged.next(this.currentZoomLevel);
    }

    this.eventPopover?.close?.();
    this.graphic.HoverTooltipElement = undefined;
    this.graphic.TraceService.info(this.traceModule, `Current Zoom Level: ${this.currentZoomLevel}`);
  }

  private zoomWithScrollbar(scale: number, percentageX: number, percentageY: number): void {
    this.setScrollbarPosition(scale, percentageX, percentageY);
  }

  private zoomWithoutScrollbar(scale: number, percentageX: number, percentageY: number): void {
    const isScrollBarNeeded: boolean = this.doesGraphicNeedScrollbars();
    if (isScrollBarNeeded) {
      this.setScrollbarPosition(scale, percentageX, percentageY);
    } else {
      this.centerZoom();
    }
  }

  private get HasViewport(): boolean {
    return this.graphic.viewport !== undefined;
  }

  private getSVGContainerDimensions(): number[] {
    const width: number = this.svgContainer.nativeElement.clientWidth;
    const height: number = this.svgContainer.nativeElement.clientHeight;
    return [width - 1, height - 1];
  }

  private getMatrix(): SVGMatrix {
    let transformMatrix: SVGMatrix = this.transformGroup?.nativeElement?.transform?.baseVal?.[0]?.matrix;
    if (transformMatrix === undefined) {
      this.transformMatrix = this.identityMatrix;
      this.changeDetector.detectChanges();
      transformMatrix = this.transformGroup?.nativeElement?.transform?.baseVal?.[0]?.matrix;
    }

    return transformMatrix;
  }

  private offsetTransformMatrix(leftOffset: number, topOffset: number): void {
    const numMat: number[] = this.getFilteredMatrixValues();
    if (Number.isNaN(leftOffset) || Number.isNaN(topOffset)) {
      return;
    }

    numMat[4] += leftOffset;
    numMat[5] += topOffset;
    this.transformMatrix = `matrix(${numMat})`;
  }

  private centerZoom(): void {
    const currentWidth: number = this.graphic.Width * this.currentZoomLevel;
    const currentHeight: number = this.graphic.Height * this.currentZoomLevel;
    const centerX = currentWidth / 2;
    const centerY = currentHeight / 2;

    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();

    this.transformMatrix = this.identityMatrix;
    this.scaleMatrix(this.currentZoomLevel);
    this.changeDetector.detectChanges();

    const transformLeftOffset: number = Math.max(Math.floor((containerWidth / 2) - centerX), 0);
    const transformTopOffset: number = Math.max(Math.floor((containerHeight / 2) - centerY), 0);
    this.offsetTransformMatrix(transformLeftOffset, transformTopOffset);
    this.resetSVGDocWidthHeight();
  }

  private centerGraphic(): void {
    const currentWidth: number = this.graphic.Width * this.currentZoomLevel;
    const currentHeight: number = this.graphic.Height * this.currentZoomLevel;
    const centerX = currentWidth / 2;
    const centerY = currentHeight / 2;
    const transformLeftOffset: number = Math.floor((this.svgDocumentWidth / 2) - centerX);
    const transformTopOffset: number = Math.floor((this.svgDocumentHeight / 2) - centerY);
    this.transformMatrix = `matrix(${this.currentZoomLevel}, 0, 0, ${this.currentZoomLevel}, ${transformLeftOffset}, ${transformTopOffset})`;
    this.changeDetector.detectChanges();
  }

  private setScrollSpace(): void {
    const currGraphicWidth = this.graphic.Width * this.currentZoomLevel;
    const currGraphicHeight = this.graphic.Height * this.currentZoomLevel;
    const maxX: number = currGraphicWidth;
    const maxY: number = currGraphicHeight;
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    const targetWidth: number = Math.max(containerWidth, maxX);
    const targetHeight: number = Math.max(containerHeight, maxY);
    this.svgDocumentWidth = targetWidth;
    this.svgDocumentHeight = targetHeight;
    this.changeDetector.detectChanges();
  }

  private initialScaleView(): void {
    if (this.storageService.HasDefinedState) {
      this.restoreState();
      return;
    }

    if (this.HasViewport) {
      this.scaleToFitViewport();
    } else if (this.graphic !== undefined && this.graphic.IsPermScaleToFit) {
      this.initialScaleToFit();
    } else {
      this.allocateScrollSpace();
    }
  }

  private setSVGDocumentDimensions(width: number, height: number): void {
    this.svgDocumentWidth = width;
    this.svgDocumentHeight = height;
    this.changeDetector.detectChanges();
  }

  private restoreState(): void {
    const state: GraphicState = this.storageService.getState();
    this.currentZoomLevel = state.zoomLevel;
    this.transformMatrix = state.transformMatrix;
    this.setSVGDocumentDimensions(state.svgDocumentWidth, state.svgDocumentHeight);
    this.scrollLeft = state.scrollLeft;
    this.scrollTop = state.scrollTop;

    if (this.graphic !== undefined && state !== undefined) {
      this.graphic.SelectedDepth = state.selectedDepth;
      this.graphic.selectedDisciplines = new Set(state.selectedDisciplines);
      this.graphic.IsPermScaleToFit = state.isPermScaleToFit;

      const layers: GmsLayer[] = this.graphic.children as GmsLayer[];
      if (state.visibleLayers !== undefined && layers.length === state.visibleLayers.length) {
        for (let i = 0; i < layers.length; ++i) {
          layers[i].Visible = state.visibleLayers[i];
        }
      }

      this.adjustZoomVisibility();
      this.graphic.CoverageAreaMode = state.coverageAreaMode;
    }
  }

  private proportionalScale(contentWidth: number, contentHeight: number): number {
    const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
    let scale: number = Math.min(containerWidth / contentWidth, containerHeight / contentHeight);
    if (scale === 0 || Number.isNaN(scale)) {
      scale = 1;
    }

    return scale;
  }

  private initialScaleToFit(): void {
    this.resetToDefaultZoom();
    const [startX, startY, width, height] = this.graphic.InitialBoundingBox;
    const srcWidth: number = width + this.padding;
    const srcHeight: number = height + this.padding;

    const scale: number = this.proportionalScale(srcWidth, srcHeight);

    this.transformMatrix = `matrix(1, 0, 0, 1, 0, 0)`;
    this.scaleGraphicZoomLevel(scale);
    this.centerGraphic();
    this.resetSVGDocWidthHeight();
    this.changeDetector.detectChanges();
  }

  // NOTE: add infinity fix
  private scaleToFit(): void {
    const boundingBox: number[] = this.getBoundingBox();
    const xMin: number = boundingBox[0];
    const xMax: number = boundingBox[1];
    const yMin: number = boundingBox[2];
    const yMax: number = boundingBox[3];

    const srcWidth: number = (xMax - xMin);
    const srcHeight: number = (yMax - yMin);
    const centerX: number = xMin + ((srcWidth) / 2);
    const centerY: number = yMin + ((srcHeight) / 2);
    this.resetToDefaultZoom();
    const paddedWidth: number = srcWidth + this.padding;
    const paddedHeight: number = srcHeight + this.padding;
    const scale: number = this.proportionalScale(paddedWidth, paddedHeight);

    this.transformMatrix = `matrix(1, 0, 0, 1, 0, 0)`;
    this.scaleGraphicZoomLevel(scale);
    this.centerGraphic();

    if (this.hasScrollbars()) {
      // Scrollbar logic
      const newCenterX: number = (centerX * scale);
      const newCenterY: number = (centerY * scale);

      const [containerWidth, containerHeight] = this.getSVGContainerDimensions();
      const transformLeftOffset: number = Math.floor(newCenterX - (containerWidth / 2));
      const transformTopOffset: number = Math.floor(newCenterY - (containerHeight / 2));
      this.scrollLeft = transformLeftOffset;
      this.scrollTop = transformTopOffset;
    }

    this.changeDetector.detectChanges();
    this.graphic.updateButtonTransform.next();
    this.graphic.UpdateNotZoomableElements();
    this.setChildElementsVisibility(this.graphic);

    if (this.graphic.zoomChanged) {
      this.graphic.zoomChanged.next(this.currentZoomLevel);
    }

    this.changeDetector.detectChanges();
  }

  private updateCoverageArea(): void {
    this.graphic.UpdateCoverageAreas();
    this.changeDetector.detectChanges();
  }

  private decreaseScrollValues(movementX: number, movementY: number): void {
    const [scrollLeftMax, scrollTopMax] = this.maxScrollDimensions();
    const scrollLeftTarget: number = this.checkPanningBounds(this.svgContainer.nativeElement.scrollLeft, movementX, scrollLeftMax);
    const scrollTopTarget: number = this.checkPanningBounds(this.svgContainer.nativeElement.scrollTop, movementY, scrollTopMax);
    this.scrollLeft = scrollLeftTarget;
    this.scrollTop = scrollTopTarget;
    this.changeDetector.detectChanges();
  }

  private increaseScrollValues(tgtScrollLeft: number, tgtScrollTop: number): void {
    const [scrollLeftMax, scrollTopMax] = this.maxScrollDimensions();
    const scrollLeftTarget: number = this.checkScrollBounds(this.svgContainer.nativeElement.scrollLeft, tgtScrollLeft, scrollLeftMax);
    const scrollTopTarget: number = this.checkScrollBounds(this.svgContainer.nativeElement.scrollTop, tgtScrollTop, scrollTopMax);
    this.scrollLeft = scrollLeftTarget;
    this.scrollTop = scrollTopTarget;
  }

  private checkScrollBounds(scrollDirection: any, movement: number, maxValue: number): number {
    let target: number = scrollDirection + movement;
    if (target < 0) {
      target = 0;
    }

    if (target > maxValue) {
      target = maxValue;
    }

    return target;
  }

  private checkPanningBounds(scrollDirection: any, movement: number, maxValue: number): number {
    let target: number = scrollDirection - movement;
    if (target < 0) {
      target = 0;
    }

    if (target > maxValue) {
      target = maxValue;
    }

    return target;
  }

  private isPrimaryButtonPressed(e: MouseEvent): boolean {
    return e.button === 0;
  }

  private stopSliding(): void {
    if (this.mouseDown) {
      this.mouseDown = false;
      this.mouseMode = MouseMode.None;
      // release the current slider
      this.graphic.sliding.next(null);
    }
  }
}
