import { CdkMenuBar, CdkMenuModule } from '@angular/cdk/menu';
import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { SiAutoCollapsableListModule } from '@simpl/element-ng/auto-collapsable-list';
import { MenuItem } from '@simpl/element-ng/common';
import { SiLinkModule } from '@simpl/element-ng/link';
import {
  MenuItemAction,
  MenuItemCheckbox,
  MenuItemNext,
  MenuItemRadio,
  SiMenuActionService,
  SiMenuModule
} from '@simpl/element-ng/menu';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiContentActionBarToggleComponent } from './si-content-action-bar-toggle.component';
import { ContentActionBarMainItem, ViewType } from './si-content-action-bar.model';

@Component({
  selector: 'si-content-action-bar',
  templateUrl: './si-content-action-bar.component.html',
  styleUrl: './si-content-action-bar.component.scss',
  standalone: true,
  imports: [
    SiMenuModule,
    CdkMenuModule,
    SiAutoCollapsableListModule,
    SiTranslateModule,
    SiLinkModule,
    SiContentActionBarToggleComponent,
    RouterLink
  ] // OnPush cannot be enabled. This breaks cdkMenu. Try with ng17 again
})
export class SiContentActionBarComponent implements OnChanges, AfterViewInit {
  /**
   * List of primary actions. Supports up to **4** actions and omits additional ones.
   */
  @Input() primaryActions?: readonly (MenuItem | ContentActionBarMainItem)[];
  /**
   * List of secondary actions.
   */
  @Input() secondaryActions?: readonly (MenuItem | MenuItemNext)[];
  /**
   * A param that will be passed to the `action` in the primary/secondary actions.
   * This allows to re-use the same primary/secondary action arrays across rows
   * in a table.
   */
  @Input() actionParam?: any;
  /**
   * Selection of view type as 'collapsible', 'expanded' or 'mobile'.
   */
  @Input() @HostBinding('class') viewType: ViewType = 'expanded';
  /**
   * Toggle icon aria-label, required for a11y
   */
  @Input() toggleItemLabel = $localize`:@@SI_CONTENT_ACTION_BAR.TOGGLE:Toggle`;
  /**
   * Option to remove all icons from dropdown menus of the content action bar.
   *
   * Some apps provide only few actions with icons, located in the set of primary actions.
   * The icons are visible in the `collapsible` and `expanded` view type. On reduced space,
   * primary actions are relocated in the same dropdown menu as the secondary actions. The
   * dropdown menu can look unbalanced, if a large number of secondary actions without
   * icons are presented with few actions with icons. This option balances the look and feel
   * by removing all icons from actions in the dropdown menu.
   */
  @Input({ transform: booleanAttribute }) preventIconsInDropdownMenus = false;
  /**
   * Disables the whole content-action-bar.
   */
  @Input({ transform: booleanAttribute }) disabled = false;

  @ViewChild('expandElement', { read: ElementRef }) private expandElement?: ElementRef<HTMLElement>;
  @ViewChild(CdkMenuBar, { read: ElementRef })
  private menuBarElement!: ElementRef<HTMLDivElement>;

  protected mobileActions: readonly (MenuItem | MenuItemNext)[] = [];
  protected secondaryActionsInternal?: readonly (MenuItem | MenuItemNext)[];
  protected expanded = true;
  protected parentElement?: HTMLElement | null;

  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private menuActionService = inject(SiMenuActionService, { optional: true });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.primaryActions || changes.secondaryActions || changes.preventIconsInDropdownMenus) {
      if (this.primaryActions?.length && this.secondaryActions?.length) {
        this.mobileActions = [...this.primaryActions, { title: '-' }, ...this.secondaryActions];
      } else if (this.primaryActions?.length) {
        this.mobileActions = this.primaryActions;
      } else if (this.secondaryActions?.length) {
        this.mobileActions = this.secondaryActions;
      }
      this.secondaryActionsInternal = this.secondaryActions;

      if (this.preventIconsInDropdownMenus) {
        this.mobileActions = this.mobileActions.map(action => ({
          ...action,
          icon: undefined
        }));
        this.secondaryActionsInternal = this.secondaryActions?.map(action => ({
          ...action,
          icon: undefined
        }));
      }
    }

    if (changes.viewType) {
      this.expanded = this.viewType === 'expanded';
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.parentElement !== this.elementRef.nativeElement.parentElement) {
        this.parentElement = this.elementRef.nativeElement.parentElement;
      }
    });
  }

  protected expand(): void {
    this.expanded = true;
    setTimeout(() => this.menuBarElement.nativeElement.focus());
  }

  protected collapse(): void {
    this.expanded = false;
    setTimeout(() => this.expandElement?.nativeElement.focus());
  }

  protected isNewItemStyle(
    item: MenuItem | ContentActionBarMainItem
  ): item is ContentActionBarMainItem {
    return 'label' in item;
  }

  protected runAction(item: MenuItemAction | MenuItemRadio | MenuItemCheckbox): void {
    if (typeof item.action === 'function') {
      item.action(this.actionParam, item as any); // typescript cannot level down the item type properly
    }

    if (typeof item.action === 'string') {
      this.menuActionService?.actionTriggered(item, this.actionParam);
    }
  }
}
