import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { OverrideMode, PropertyInfoData } from '../../interfaces/property';

@Injectable()
export class SiPropertyPopoverService {
  private subject = new Subject<any>();
  private validitySubject = new Subject<boolean>();
  private propertyInfoSubject = new Subject<PropertyInfoData>();
  private overrideMode?: OverrideMode;
  private isOpen = false;
  private invalidSet = new Set<any>();

  containerOverrideMode?: OverrideMode;
  readonly stateChange$ = this.subject.asObservable();
  readonly validityChange$ = this.validitySubject.pipe(delay(0)); // async to prevent ViewCheckError
  readonly propertyInfoDialog$ = this.propertyInfoSubject.asObservable();

  canOpen(): boolean {
    return !this.isOpen || this.overrideMode === 'direct';
  }

  open(overrideMode: OverrideMode | undefined, caller: any): boolean {
    if (!this.canOpen()) {
      return false;
    }
    this.overrideMode = overrideMode ?? this.containerOverrideMode;
    this.isOpen = true;
    this.subject.next(caller);
    return true;
  }

  close(): void {
    this.isOpen = false;
    this.subject.next(null);
  }

  showPropertyInfoDialog(selectPropertyInfo: PropertyInfoData): void {
    this.propertyInfoSubject.next(selectPropertyInfo);
  }

  setValidity(isValid: boolean, caller: any): void {
    if (isValid) {
      this.invalidSet.delete(caller);
    } else {
      this.invalidSet.add(caller);
    }
    this.validitySubject.next(this.invalidSet.size === 0);
  }
}
