<si-select-input
  #trigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  [baseId]="id"
  [labelledby]="labelledby"
  [optionTemplate]="optionTemplate"
  [ariaLabel]="ariaLabel"
  [controls]="id + '-listbox'"
  [open]="open"
  [placeholder]="placeholder"
  [readonly]="readonly"
  (openListbox)="openListBox()"
/>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayOffsetX]="-1"
  [cdkConnectedOverlayMinWidth]="overlayWidth"
  (backdropClick)="backdropClick()"
  (detach)="overlayDetach()"
>
  @if (!hasFilter) {
    <si-select-list
      [baseId]="id"
      [optionTemplate]="optionTemplate"
      [groupTemplate]="groupTemplate"
      [labelledby]="labelledby"
      (closeOverlay)="overlayDetach()"
    />
  } @else {
    <si-select-list-has-filter
      [baseId]="id"
      [filterPlaceholder]="filterPlaceholder"
      [noResultsFoundLabel]="noResultsFoundLabel"
      [optionTemplate]="optionTemplate"
      [groupTemplate]="groupTemplate"
      [labelledby]="labelledby"
      (closeOverlay)="overlayDetach()"
    />
  }
</ng-template>
