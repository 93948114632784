import { isNullOrUndefined } from '@gms-flex/services-common';

/*
 *  This class represents the unique identifier of a QParamService channel instance.
 */
export class FullQParamId {

  /*
   * Returns true if the two specified FullQParamId points at the same path.
   */
  public static areEqual(x: FullQParamId, y: FullQParamId): boolean {
    return (x.channelId === y.channelId && x.typeId === y.typeId && x.frameId === y.frameId);
  }

  /*
   * Returns FullSnapIn from a given string
   */
  public static createFrom(fullId: string): FullQParamId | null {
    if (!isNullOrUndefined(fullId)) {
      const splitted: string[] = fullId.split('.', 3);
      if (!isNullOrUndefined(splitted) && splitted.length === 3) {
        return new FullQParamId(splitted[0], splitted[1], splitted[2]);
      }
    }
    return null;
  }

  public constructor(public frameId: string,
    public typeId: string,
    public channelId: string) {
  }

  /*
   * Gets the full Id as concatenated string.
   */
  public fullId(): string {
    return `${this.frameId}.${this.typeId}.${this.channelId}`;
  }

}
