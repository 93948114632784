import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subscription } from 'rxjs';

import { SiDatepickerDirective } from './si-datepicker.directive';

/**
 * Calendar toggle button used in combination with a datepicker directive.
 *
 * @example
 * ```
 * <si-calendar-button class="w-100">
 *   <input
 *     class="form-control"
 *     type="text"
 *     siDatepicker
 *     [siDatepickerConfig]="config"
 *   />
 * </si-calendar-button>
 * ```
 */
@Component({
  selector: 'si-calendar-button',
  template: `<ng-content />
    <button
      #calendarButton
      name="open-calendar"
      type="button"
      class="btn btn-circle btn-tertiary btn-xs element-calendar position-absolute end-0 top-0 me-2 mt-2"
      [attr.aria-label]="ariaLabel | translate"
      [disabled]="disabled"
      (click)="show()"
    >
    </button>`,
  host: {
    class: 'd-inline-block position-relative'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiCalendarButtonComponent implements OnInit, AfterContentInit, OnDestroy {
  /** Aria label for the calendar toggle button. */
  @Input()
  ariaLabel = $localize`:@@SI_DATEPICKER.CALENDAR_TOGGLE_BUTTON:Open calendar`;

  @ViewChild('calendarButton', { static: true }) protected button!: ElementRef<HTMLButtonElement>;
  /** Datepicker input directive instance used to watch for state changes and required to open the calendar. */
  @ContentChild(SiDatepickerDirective, { static: true, read: SiDatepickerDirective })
  protected datepicker?: SiDatepickerDirective;
  protected disabled = false;

  private cdRef = inject(ChangeDetectorRef);
  private sub?: Subscription;

  ngOnInit(): void {
    // Monitor input state changes and update the button accordingly
    this.sub = this.datepicker?.stateChange.subscribe(() => this.updateState());
  }

  ngAfterContentInit(): void {
    this.datepicker?.useExternalTrigger(this.button);
    this.updateState();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  protected show(): void {
    this.datepicker?.show(true);
  }

  private updateState(): void {
    this.disabled = !!this.datepicker?.disabled || !!this.datepicker?.readonly;
    this.cdRef.markForCheck();
  }
}
