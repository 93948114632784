<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="form.invalid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    <div
      class="form-control d-flex align-items-center"
      [attr.readonly]="readonly"
      [attr.tabindex]="readonly ? '' : '0'"
      (keydown.enter)="popover.open(true)"
      input
    >
      <span>
        @if (property.defaultText) {
          {{ property.defaultText }}
        } @else {
          {{ displayValue }}
        }
      </span>
    </div>
    <div body>
      <div class="row">
        <div class="col-8">
          <div class="form-group mb-6">
            <label class="d-block">
              <span class="control-label form-label">{{ typeLabel }}</span>
              <select
                #typeSelect
                name="objectType"
                class="form-control"
                [(ngModel)]="popover.modelValue?.objectType.value"
                [attr.readonly]="property.value.value?.objectType?.readonly || null"
                [required]="!property.value.value?.objectType?.optional"
              >
                @for (option of property.value.value!.objectType.options; track $index) {
                  <option [ngValue]="option.value">
                    {{ option.text }}
                  </option>
                }
              </select>
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group mb-6">
            <label class="d-block">
              <span class="control-label form-label">{{ instanceLabel }}</span>
              <input
                name="instance"
                type="number"
                autocomplete="off"
                class="form-control"
                [(ngModel)]="popover.modelValue?.instance.value"
                [min]="property.value.value?.instance?.min || 0"
                [max]="property.value.value?.instance?.max || ''"
                [step]="property.value.value?.instance?.resolution || 1"
                [readonly]="property.value.value?.instance?.readonly || null"
                [required]="!property.value.value?.instance?.optional"
                [siNumberValidator]="property.value.value?.instance"
                (keydown.enter)="popover.submitEnter($event)"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </si-property-popover>
</form>
