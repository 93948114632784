import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, concatMap, map, Observable, of, share, tap, zip } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { LocationService } from '../location/location.service';
import { EntityType } from '../shared/base.model';
import { useCache, useMulticast } from '../shared/http-utility.service';
import { DevicesResponse } from './device-proxy.model';
import { DeviceProxyService } from './device-proxy.service';
import { Device } from './device.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private readonly devicesPerPartition: Map<string, Device[]> = new Map<string, Device[]>();
  private readonly devicesPerPartitionAndLocation: Map<string, Map<string, Device[]>> = new Map<string, Map<string, Device[]>>();
  private readonly devicesPerPartitionAndGateway: Map<string, Map<string, Device[]>> = new Map<string, Map<string, Device[]>>();
  private readonly devicePerPartitionAndId: Map<string, Map<string, Device>> = new Map<string, Map<string, Device>>();
  private readonly multiCastFeatureObs: Map<string, Observable<Device>> = new Map<string, Observable<Device>>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly deviceProxy: DeviceProxyService,
    private readonly locationService: LocationService) {

    this.traceService.info(TraceModules.bxServicesDevices, 'DeviceService created.');
  }

  public getDevices(partitionId: string): Observable<Device[]> {
    if ((useCache) && (this.devicesPerPartition.has(partitionId))) {
      const devices = this.devicesPerPartition.get(partitionId);
      this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDevices() returned: no of devices: ${devices.length} from cache
        partitionId=${partitionId}`);
      return of(devices);
    } else {
      return this.deviceProxy.getDevices(partitionId).pipe(
        map(result => this.mapDevices(result)),
        tap(result => {
          this.devicesPerPartition.set(partitionId, result);
          this.updateDevicesCachePerPartitionAndId(partitionId, result);
          this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDevices() returned: no of devices: ${result.length} from backend
            partitionId=${partitionId}`);
        })
      );
    }
  }

  /**
   * Return devices per partition and location and an optional device name.
   * The query reads the location and uses the HasAssets data to get the device Id's and fetch the devices.
   * Use this method.
   */
  public getDevicesOfLocation(partitionId: string, locationId: string, deviceName?: string): Observable<Device[]> {
    if ((useCache) && (this.devicesPerPartitionAndLocation.has(partitionId) && (this.devicesPerPartitionAndLocation.get(partitionId).has(locationId)))) {
      const devices = this.devicesPerPartitionAndLocation.get(partitionId).get(locationId);
      const filtered = this.filterDevices(devices, deviceName);
      this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDevicesOfLocation() returned: no of devices: ${filtered.length} from cache
        partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
      return of(filtered);
    } else {
      // A regular get devices call for a partition lasts up to around 4-5s in case there are 5000 devices in the DB.
      // Filtering by location does not speed up significantly, as all devices are read from DB and filtered (still server side) by code.
      // Thus we use the hasAssets data to get the device id's in this case!
      return this.locationService.getLocationHasAssetsIds(partitionId, locationId, EntityType.Device).pipe(
        concatMap(deviceIds => {
          const dvcObs = deviceIds.map(dvcId => this.getDeviceById(partitionId, dvcId));
          if (dvcObs.length === 0) {
            this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDevicesOfLocation() returned: no of devices: 0 from backend
              partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
            return of([]);
          } else {
            return zip(dvcObs).pipe(
              map(devices => this.filterDevices(devices, deviceName)),
              tap(devices => {
                this.traceService.debug(TraceModules.bxServicesDevices,
                  `DeviceService.getDevicesOfLocation() returned: no of devices: ${devices.length} from backend
                  partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
              })
            );
          }
        })
      );
    }
  }

  /**
   * Return devices per partition and location and an optional device name.
   * The query uses the location id as filter on server side.
   * A regular get devices call for a partition lasts up to around 4-5s in case there are 5000 devices in the DB!!!
   * Consider using the method getDevicesOfLocation() with first prio.
   */
  public getDevicesOfLocationServerFiltersLocation(partitionId: string, locationId: string, deviceName?: string): Observable<Device[]> {
    if ((useCache) && (this.devicesPerPartitionAndLocation.has(partitionId) && (this.devicesPerPartitionAndLocation.get(partitionId).has(locationId)))) {
      const devices = this.devicesPerPartitionAndLocation.get(partitionId).get(locationId);
      const filtered = this.filterDevices(devices, deviceName);
      this.traceService.debug(TraceModules.bxServicesDevices,
        `DeviceService.getDevicesOfLocation_ServerFiltersLocation() returned: no of devices: ${filtered.length} from cache
        partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
      return of(filtered);
    } else {
      return this.deviceProxy.getDevicesOfLocation(partitionId, locationId).pipe(
        map(result => this.mapDevices(result)),
        tap(result => {
          if (this.devicesPerPartitionAndLocation.has(partitionId) === false) {
            this.devicesPerPartitionAndLocation.set(partitionId, new Map<string, Device[]>());
          }
          this.devicesPerPartitionAndLocation.get(partitionId).set(locationId, result);
          this.updateDevicesCachePerPartitionAndId(partitionId, result);
        }),
        map(devices => this.filterDevices(devices, deviceName)),
        tap(devices => {
          this.traceService.debug(TraceModules.bxServicesDevices,
            `DeviceService.getDevicesOfLocation_ServerFiltersLocation() returned: no of devices: ${devices.length} from backend
            partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
        })
      );
    }
  }

  /**
   * Return devices per partition and location and an optional device name.
   * The query uses filters on client side.
   * A regular get devices call for a partition lasts up to around 6-8s in case there are 5000 devices in the DB!!!
   * Consider using the method getDevicesOfLocation() with first prio.
   */
  public getDevicesOfLocationClientFilteresLocation(partitionId: string, locationId: string, deviceName?: string): Observable<Device[]> {
    if ((useCache) && (this.devicesPerPartitionAndLocation.has(partitionId) && (this.devicesPerPartitionAndLocation.get(partitionId).has(locationId)))) {
      const devices = this.devicesPerPartitionAndLocation.get(partitionId).get(locationId);
      const filtered = this.filterDevices(devices, deviceName);
      this.traceService.debug(TraceModules.bxServicesDevices,
        `DeviceService.getDevicesOfLocation_ClientFiltersLocation() returned: no of devices: ${filtered.length} from cache
        partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
      return of(filtered);
    } else {
      return this.getDevices(partitionId).pipe(
        map(result => this.filterDevicesPerLocation(result, locationId)),
        tap(filteredDvcs => {
          if (this.devicesPerPartitionAndLocation.has(partitionId) === false) {
            this.devicesPerPartitionAndLocation.set(partitionId, new Map<string, Device[]>());
          }
          this.devicesPerPartitionAndLocation.get(partitionId).set(locationId, filteredDvcs);
        }),
        map(devices => this.filterDevices(devices, deviceName)),
        tap(devices => {
          this.traceService.debug(TraceModules.bxServicesDevices,
            `DeviceService.getDevicesOfLocation_ClientFiltersLocation() returned: no of devices: ${devices.length} from backend
            partitionId=${partitionId}, locationId=${locationId}, deviceName=${deviceName}`);
        })
      );
    }
  }

  public getDeviceById(partitionId: string, deviceId: string): Observable<Device | undefined> {
    if ((useCache) && (this.devicePerPartitionAndId.has(partitionId) && (this.devicePerPartitionAndId.get(partitionId).has(deviceId)))) {
      const device = this.devicePerPartitionAndId.get(partitionId).get(deviceId);
      this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDeviceById() returned device: ${device?.name} from cache
        partitionId=${partitionId}, deviceId=${deviceId}`);
      return of(device);
    } else {
      // using multicast to support the concurrent alarm source resolvement more efficient.
      // problem statement: finding/resolving the same object (e.g the device of alarms of the device) causes many parallel calls which do the same.
      // cache cannot be maintained.
      if ((useMulticast) && (this.multiCastFeatureObs.has(deviceId))) {
        return this.multiCastFeatureObs.get(deviceId);
      }
      const dvcObs: Observable<Device> = this.deviceProxy.getDeviceById(partitionId, deviceId).pipe(
        // this call shall be removed whenever the API returns features also for single device requests
        concatMap(dvcResponse => this.deviceProxy.getDeviceFeatureById(partitionId, deviceId).pipe(
          map(dvcIncluded => {
            const device = new Device(dvcResponse.data, dvcIncluded.data);
            this.updateDevicesCachePerPartitionAndId(partitionId, [device]);
            this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDeviceById() returned device: ${device?.name} from backend
              partitionId=${partitionId}, deviceId=${deviceId}`);
            return device;
          })
        )),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesDevices, `Device not found: ${deviceId}, error=${error}`);
          this.updateDeviceCachePerPartitionAndId(partitionId, deviceId, undefined);
          return of(undefined);
        })
      );
      if (useMulticast) {
        const multicastObs = dvcObs.pipe(share());
        this.multiCastFeatureObs.set(deviceId, multicastObs);
        return multicastObs;
      } else {
        return dvcObs;
      }
    }
  }

  public getDevicesBehindGateway(partitionId: string, gatewayId: string, deviceName?: string): Observable<Device[]> {
    if ((useCache) && (this.devicesPerPartitionAndGateway.has(partitionId) && (this.devicesPerPartitionAndGateway.get(partitionId).has(gatewayId)))) {
      const devices = this.devicesPerPartitionAndGateway.get(partitionId).get(gatewayId);
      const filtered = this.filterDevices(devices, deviceName);
      this.traceService.debug(TraceModules.bxServicesDevices, `DeviceService.getDevicesBehindGateway() returned: no of devices: ${filtered.length} from cache
        partitionId=${partitionId}, gatewayId=${gatewayId}, deviceName=${deviceName}`);
      return of(filtered);
    } else {
      return this.deviceProxy.getDevicesBehindGateway(partitionId, gatewayId).pipe(
        map(result => this.mapDevices(result)),
        tap(devices => {
          if (this.devicesPerPartitionAndGateway.has(partitionId) === false) {
            this.devicesPerPartitionAndGateway.set(partitionId, new Map<string, Device[]>());
          }
          this.devicesPerPartitionAndGateway.get(partitionId).set(gatewayId, devices);
          this.updateDevicesCachePerPartitionAndId(partitionId, devices);
        }),
        map(devices => this.filterDevices(devices, deviceName)),
        tap(devices => {
          this.traceService.debug(TraceModules.bxServicesDevices,
            `DeviceService.getDevicesBehindGateway() returned: no of devices: ${devices.length} from backend
            partitionId=${partitionId}, gatewayId=${gatewayId}, deviceName=${deviceName}`);
        })
      );
    }
  }

  private updateDevicesCachePerPartitionAndId(partitionId: string, devices: Device[]): void {
    if (this.devicePerPartitionAndId.has(partitionId) === false) {
      this.devicePerPartitionAndId.set(partitionId, new Map<string, Device>());
    }
    devices.forEach(device => this.devicePerPartitionAndId.get(partitionId).set(device.id, device));
  }

  private updateDeviceCachePerPartitionAndId(partitionId: string, deviceId: string, device: Device): void {
    if (this.devicePerPartitionAndId.has(partitionId) === false) {
      this.devicePerPartitionAndId.set(partitionId, new Map<string, Device>());
    }
    this.devicePerPartitionAndId.get(partitionId).set(deviceId, device);
  }

  private filterDevices(devices: Device[], name?: string): Device[] {
    // device array can have undefined entries; e.g. when permission was not given when reading the device
    if (name) {
      const found = this.findDevice(devices, name);
      return found ? [found] : [];
    } else {
      return devices.filter(dvc => (dvc !== undefined));
    }
  }

  private findDevice(devices: Device[], name: string): Device | undefined {
    // filtering by name is done on client-side, reason: the device name is evaluated based on various fields and priority of the device response
    return devices.find(dvc => dvc?.name === name);
  }

  private mapDevices(deviceRes: DevicesResponse): Device[] {
    const devices: Device[] = [];
    deviceRes.data.forEach(item => {
      const dvc = new Device(item, deviceRes.included);
      devices.push(dvc);
    });
    return devices;
  }

  private filterDevicesPerLocation(devices: Device[], locationId: string): Device[] {
    return devices.filter(dvc => (dvc.locationId === locationId));
  }
}
