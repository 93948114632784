import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { CopyrightDetails, SI_COPYRIGHT_DETAILS } from './si-copyright-notice';

@Component({
  selector: 'si-copyright-notice',
  templateUrl: './si-copyright-notice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class SiCopyrightNoticeComponent implements OnChanges {
  private globalCopyrightInfo: CopyrightDetails | null = inject(SI_COPYRIGHT_DETAILS, {
    optional: true
  });
  /**
   * Copyright information to be displayed.
   *
   * Note: The {@link CopyrightDetails#company} defaults to `Siemens`,
   * only set it for other companies.
   */
  @Input() copyright?: CopyrightDetails;

  protected copyrightInfo?: string;

  constructor() {
    this.buildCopyright();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildCopyright();
  }

  protected get company(): string {
    return this.copyright?.company ?? this.globalCopyrightInfo?.company ?? 'Siemens';
  }

  protected get startYear(): number {
    return (
      this.copyright?.startYear ?? this.globalCopyrightInfo?.startYear ?? new Date().getFullYear()
    );
  }

  protected get lastUpdateYear(): number | undefined {
    return this.copyright?.lastUpdateYear ?? this.globalCopyrightInfo?.lastUpdateYear ?? undefined;
  }

  private buildCopyright(): void {
    this.copyrightInfo = `${this.company} ${this.startYear}`;
    if (this.lastUpdateYear) {
      this.copyrightInfo += `-${this.lastUpdateYear}`;
    }
  }
}
