/* eslint-disable @angular-eslint/no-host-metadata-property */
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  numberAttribute,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { Action, CollapseTo, PartState, Scale, SplitOrientation } from './si-split.interfaces';

@Component({
  selector: 'si-split-part',
  templateUrl: './si-split-part.component.html',
  styleUrl: './si-split-part.component.scss',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SiTranslateModule],
  // Signals cannot be used directly with @HostBinding. See: https://github.com/angular/angular/issues/53888#issuecomment-1888935225
  // Having every binding here for consistency.
  host: {
    '[class.is-collapsed]': 'collapsedState()',
    '[class.collapse-start]': 'collapseDirection === "start"',
    '[style.grid-area]': '"p-" + this.index'
  }
})
export class SiSplitPartComponent implements OnChanges {
  @Input() actions: Action[] = [];
  @Input() collapseDirection: CollapseTo = 'start';

  /**
   * Sets the icon class that is used in the buttons of split parts to
   * collapse and uncollapse the parts.
   */
  @Input() collapseIconClass = 'element-double-right';

  /**
   * Collapse only to the given min size.
   */
  @Input({ transform: booleanAttribute }) collapseToMinSize = false;

  /**
   * Sets the status color on the split part header, visible as a bottom border and,
   * if a headerStatusIcon is defined, as the icon´s background color.
   *
   * @deprecated Legacy input with no functionality. Will be removed in future major release.
   */
  @Input() headerStatusColor?: string;

  /**
   * Sets the icon class that is used as status icon in the split part header.
   *
   * @deprecated Legacy input with no functionality. Will be removed in future major release.
   */
  @Input() headerStatusIconClass?: string;

  @Input() headerTemplate?: TemplateRef<any>;

  /**
   * Sets the title of the split part header.
   */
  @Input() heading!: string;

  /**
   * Minimum size in px.
   */
  @Input({ transform: numberAttribute }) minSize = 0;

  /**
   * When a split part is collapsed, the content gets hidden but it will
   * still remain within the DOM. If you want to remove and destroy the component
   * in collapsed mode and recreate it on un-collapse, set this property to
   * true.
   */
  @Input({ transform: booleanAttribute }) removeContentOnCollapse = false;

  /**
   * Defines the behavior of the split part during scaling.
   * E.g. when set to `none`, the spit part will keep its current size even when the parent container grows or shrinks.
   */
  @Input() scale: Scale = 'auto';

  /**
   * Defines if the collapse button of a split part is displayed. Default value is true.
   */
  @Input({ transform: booleanAttribute }) showCollapseButton = true;

  /**
   * Defines if the header of the split part is visible. Default is true.
   */
  @Input({ transform: booleanAttribute }) showHeader = true;
  /**
   * Aria label for collapse button. Needed for a11y
   */
  @Input() collapseLabel = 'collapse';
  /**
   * An optional stateId to uniquely identify a component instance.
   * Required for persistence of ui state.
   */
  @Input() stateId?: string;

  /**
   * Expanded size in px.
   */
  @Input({ transform: numberAttribute }) size?: number;

  @Output() readonly collapseChanged = new EventEmitter<boolean>();
  @Output() readonly stateChange = new EventEmitter<PartState>();

  /** @internal */
  index = 0;
  /** @internal */
  before?: SiSplitPartComponent;
  /** @internal */
  after?: SiSplitPartComponent;

  /** @internal */
  readonly fractionalSize = signal<number | undefined>(undefined);

  /** @internal */
  readonly collapsedSize = signal(0);

  /** @internal */
  readonly collapsedState = signal(false);

  get collapsed(): boolean {
    return this.collapsedState();
  }

  /** @internal */
  readonly expandedSize = signal<number | undefined>(undefined);

  /** @internal */
  readonly actualSize = computed(() => {
    if (this.collapsedState()) {
      return this.collapsedSize();
    }
    return this.expandedSize() ?? 0;
  });

  /** @internal */
  saveUIState!: () => void;

  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  protected headerContext: { $implicit: SiSplitPartComponent } = {
    $implicit: this
  };

  /** @default `true` */
  @Input({ transform: booleanAttribute }) set expanded(value: boolean) {
    this.collapsedState.set(!value);
    this.before?.refreshCollapseToStart();
    this.after?.refreshCollapsedToEnd();
  }
  get expanded(): boolean {
    return !this.collapsedState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapseToMinSize && this.collapseToMinSize) {
      this.collapsedSize.set(this.minSize ?? 40);
    } else {
      this.collapsedSize.set(40); // 40px is default size of the header
    }

    if (changes.size) {
      this.expandedSize.set(this.size);
    }
  }

  /** @internal */
  refreshSizePx(orientation: SplitOrientation): void {
    if (!this.collapsedState()) {
      const rect = this.elementRef.nativeElement.getBoundingClientRect();
      if (orientation === 'vertical') {
        this.expandedSize.set(rect.height);
      } else {
        this.expandedSize.set(rect.width);
      }
    }
  }

  /**
   * Toggles the collapsed or expanded state.
   */
  toggleCollapse(): void {
    this.collapsedState.update(v => !v);
    this.before?.refreshCollapseToStart();
    this.after?.refreshCollapsedToEnd();
    this.collapseChanged.emit(this.collapsedState());
    this.stateChange.emit({ expanded: this.expanded, size: this.actualSize() });
    this.saveUIState();
  }

  private refreshCollapsedToEnd(): void {
    if (this.before?.collapsedState() && this.before.collapseDirection === 'end') {
      this.collapsedState.set(true);
      this.collapseDirection = 'end';
      this.after?.refreshCollapsedToEnd();
    }
  }

  private refreshCollapseToStart(): void {
    if (this.after?.collapsedState() && this.after.collapseDirection === 'start') {
      this.collapsedState.set(true);
      this.collapseDirection = 'start';
      this.before?.refreshCollapseToStart();
    }
  }
}
