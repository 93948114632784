import { EnumerationItem } from '@gms-flex/services';

import { CommandParameter, ParameterType } from '../command/parameters/gms-base-parameter';
import { MultiStateParameter } from '../command/parameters/gms-multistate-parameters';
import { ParameterViewModelBase } from './gms-parameter-vm.base';

export class ParameterMultiStateViewModel extends ParameterViewModelBase {

  constructor(parameter: CommandParameter) {
    super(parameter);

    this.ParameterType = ParameterType.MultiState;
    const parameterMultiState: MultiStateParameter = parameter as MultiStateParameter;
    if (parameterMultiState !== null) {
      this.enums = parameterMultiState.ParameterEnum
        .filter(item => this.isValueInRage(item, parameter.MinValue, parameter.MaxValue));
      this.Value = this.defaultValue;
    }
  }
  private isValueInRage(en: EnumerationItem, min: number, max: number): boolean {
    if (en && en.Value >= min && en.Value <= max) {
      return true;
    }
    return false;
  }
}
