<form class="form-group mb-5">
  <label for="state" class="form-label"> {{ translatedLabels.get('value') }} </label>
  <si-select
    multi
    id="state"
    class="form-select"
    [placeholder]="translatedLabels.get('select-placeholder')"
    [options]="optionsList"
    [(value)]="value"
    (valueChange)="onSelect($event)"
  />
</form>
