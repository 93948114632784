import { NgModule } from '@angular/core';

import { PopoverComponent } from './si-popover.component';
import { SiPopoverDirective } from './si-popover.directive';

@NgModule({
  imports: [PopoverComponent, SiPopoverDirective],
  exports: [PopoverComponent, SiPopoverDirective]
})
export class SiPopoverModule {}
