import { Component, Input } from '@angular/core';
import { SiAvatarComponent } from '@simpl/element-ng/avatar';
import { EntityStatusType, MenuItem } from '@simpl/element-ng/common';
import {
  SiNavbarDropdownComponent,
  SiNavbarDropdownItemsFactoryComponent,
  SiNavbarDropdownTriggerDirective
} from '@simpl/element-ng/navbar-dropdown';
import { SiTranslateModule, TranslatableString } from '@simpl/element-ng/translate';

import { SiNavbarItemComponent } from '../si-navbar-item/si-navbar-item.component';

export interface AccountItem extends MenuItem {
  /**
   * Full name of user
   */
  title: string; // title is also in MenuItem. Make it required here.
  /**
   * Initials to be displayed as avatar if no `icon` and also no `image` are provided.
   * If also no initials are provided, they will be automatically calculated from the `title`.
   */
  initials?: string;
  /**
   * The status (success, info, warning, caution, danger, critical, pending, progress) to be
   * visualized.
   */
  avatarStatus?: EntityStatusType;
  /**
   * aria-label for avatar status
   */
  avatarStatusAriaLabel?: TranslatableString;
  /**
   * Email address of the user
   */
  email?: string;
  /**
   * Name of the Company
   */
  company?: string;
  /**
   * Role name shown as a badge in the bottom
   */
  role?: string;
}

@Component({
  selector: 'si-navbar-profile-menu',
  templateUrl: './si-navbar-profile-menu.component.html',
  styleUrl: './si-navbar-profile-menu.component.scss',
  standalone: true,
  imports: [
    SiAvatarComponent,
    SiTranslateModule,
    SiNavbarItemComponent,
    SiNavbarDropdownTriggerDirective,
    SiNavbarDropdownComponent,
    SiNavbarDropdownItemsFactoryComponent
  ],
  host: {
    class: 'nav-item quick-action'
  }
})
export class SiNavbarProfileMenuComponent {
  @Input({ required: true }) account!: AccountItem;
  /**
   * MenuItems to display in the navbar menu.
   */
  @Input() items?: MenuItem[];
}
