import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { Observable, of } from "rxjs";

import { TraceModules } from "../shared";
import { AlarmConfigurationServiceBase } from "./alarm-configuration.service.base";
import { AlarmConfigAttributes, AlarmConfigBx } from "./data.model";

@Injectable({
  providedIn: 'root'
})
export class AlarmConfigurationService extends AlarmConfigurationServiceBase {
  public constructor(
    private readonly traceService: TraceService) {
    super();
    this.traceService.info(TraceModules.events, 'AlarmConfigurationService created.');
  }

  public getAlarmConfiguration(partitionId: string, pointId: string): Observable<AlarmConfigBx[] | undefined> {
  	return of(undefined);
  }

  public updateAlarmConfiguration(
    partitionId: string,
    pointId: string,
    updatedConfiguration: AlarmConfigBx
  ): Observable<AlarmConfigBx | undefined> {
    return of(undefined);
  }

  public deleteAlarmConfiguration(partitionId: string, pointId: string, configurationId: string): Observable<boolean | undefined> {
    return of(undefined);
  }

  public createAlarmConfiguration(
    partitionId: string,
    pointId: string,
    configurationAttributes: AlarmConfigAttributes
  ): Observable<AlarmConfigBx | undefined> {
    return of(undefined);
  }
}