import { EventColors, EventCounter } from "@gms-flex/services";

export class Lamp {
  public constructor(
    public categoryId: number,
    public category: string,
    public colors: Map<EventColors, string>,
    public eventCounter: EventCounter = undefined) {
  }

  public get eventCounterInfo(): string {
    if ((this.eventCounter == undefined) || (this.eventCounter.TotalSubsequentGrouping === 0)) {
      return "0";
    }
    else {
      return this.eventCounter.UnprocessedSubsequentGrouping + "/" + this.eventCounter.TotalSubsequentGrouping;
    }
  }

  public get eventCounterInfoSmall(): string {
    if ((this.eventCounter == undefined) || (this.eventCounter.TotalSubsequentGrouping === 0)) {
      return "0";
    }
    else {
      const unprocessed: string = this.eventCounter.UnprocessedSubsequentGrouping > 99 ? "99+" :
        this.eventCounter.UnprocessedSubsequentGrouping.toString();
      const total: string = this.eventCounter.TotalSubsequentGrouping > 99 ? "99+" :
        this.eventCounter.TotalSubsequentGrouping.toString();
      return unprocessed + "/" + total;
    }
  }

  public get eventCounterTotal(): string {
    if (this.eventCounter != null) {
      if (this.eventCounter.TotalSubsequentGrouping > 99) {
        return "99+";
      } else {
        return this.eventCounter.TotalSubsequentGrouping.toString();
      }
    }
    else {
      return "0";
    }
  }

  public getStyleBottomBorder(): string {
    return `rgb(${this.colors.get(EventColors.ButtonGradientDark)})`;
  }

  public getTextColorStyle(lampBlinkOn: boolean): string {
    if (this.eventCounter == undefined || this.eventCounter.TotalCount === 0) {
      return ""; // pass the control to hfw-control theme
    }
    if (this.eventCounter.UnprocessedCount > 0 && lampBlinkOn === false) {
      return ""; // pass the control to hfw-control theme
    }
    return `rgb(${this.colors.get(EventColors.TextButtonNormal)})`;
  }

  public getStyle(lampBlinkOn: boolean): string {
    if (this.eventCounter == undefined) {
      return "";
    }
    else {
      if (this.eventCounter.TotalCount === 0) {
        return "";
      }
      else if (this.eventCounter.UnprocessedSubsequentGrouping > 0) {
        if (lampBlinkOn === true) {
          return `rgb(${this.colors.get(EventColors.ButtonGradientDark)})`;
        }
        else {
         return "";
        }
      }
      else {
        return `rgb(${this.colors.get(EventColors.ButtonGradientDark)})`;
      }
    }
  }
}
