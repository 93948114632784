import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextMeasureService {
  private measureCanvas?: CanvasRenderingContext2D;
  private defaultFont?: string;

  measureText(text: string, fontRef?: HTMLElement | string): number {
    this.ensureCanvas();
    this.setFontStyle(fontRef);
    return Math.ceil(this.measureCanvas!.measureText(text).width);
  }

  getFontStyle(element: HTMLElement): string {
    const style = getComputedStyle(element);
    // this is necessary since Firefox returns style.font as ""
    return `${style.fontStyle} ${style.fontVariant} ${style.fontWeight} ${style.fontSize} ${style.fontFamily}`
      .replace(/ +/g, ' ')
      .trim();
  }

  private ensureCanvas(): void {
    if (this.measureCanvas) {
      return;
    }
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    this.measureCanvas = canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  private setFontStyle(fontRef?: HTMLElement | string): void {
    if (typeof fontRef === 'string') {
      this.measureCanvas!.font = fontRef;
      return;
    }
    if (fontRef) {
      this.measureCanvas!.font = this.getFontStyle(fontRef);
      return;
    }
    if (!this.defaultFont) {
      this.defaultFont = this.getFontStyle(document.body);
    }
    this.measureCanvas!.font = this.defaultFont;
  }
}
