import { Directive, inject, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[siTreeViewItemTemplate]',
  standalone: true
})
export class SiTreeViewItemTemplateDirective {
  @Input('siTreeViewItemTemplate') name!: string;
  template = inject(TemplateRef<any>);
}
