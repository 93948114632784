import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HfwControlsModule, TilesViewModule } from '@gms-flex/controls';
import { ProductService } from '@gms-flex/services-common';
import { AboutPopoverModule, GmsSnapInCommonModule, ValidationDialogService } from '@gms-flex/snapin-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SimplChartsNgModule } from '@simpl/charts-ng';
import { SiContentActionBarModule, SiSearchBarModule, SiSliderModule, SiToastNotificationService } from '@simpl/element-ng';
import { SimplTrendviewerNgModule, SiTimeRangeService, SiTrendviewerConfigService, SiTrendViewerPropertiesService,
  SiTrendviewerService, TrendConfigurationApi, TrendviewerApi } from '@simpl/trendviewer-ng';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { TREND_SNAPIN_ROUTING } from './gms-trend-snapin.routing';
import { SearchViewComponent } from './search/search.component';
import { PerformanceRef, TimerService, WindowPerformance } from './services/timer-service';
import { TrendDefinitionService } from './services/trend-definition-service';
import { TrendSnapinService } from './services/trend-snapin.service';
import { TrendviewerApiService } from './services/trendviewer-api-service';
import { TrendConfigApiService } from './services/trendviewer-config-api-service';
import { TrendValidationHelperService } from './shared/trend-validation-helper.service';
import { TrendSnapInComponent } from './snapin/trend-snapin.component';
import { TrendChartComponent } from './trend-chart/trend-chart.component';
import { TrendLogManager } from './trend-chart/trendLog.manager';
import { TrendDefinitionManager } from './trend-chart/trendViewDefinition.manager';
import { TrendHostComponent } from './trend-host/trend-host.component';
import { TrendThumbnailManagerClientComponent } from './trend-thumbnail-manager/trend-thumnail-manager';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './@gms-flex/trend/i18n/', '.json');

export const TrendApiServiceFactory = (trendDefinitionService: TrendDefinitionService, timerService: TimerService): TrendviewerApiService =>
  new TrendviewerApiService(trendDefinitionService, timerService);

export const TrendConfigApiServiceFactory = (trendValidationHelperService: TrendValidationHelperService,
  trendDefinitionService: TrendDefinitionService): TrendConfigApiService =>
  new TrendConfigApiService(trendDefinitionService, trendValidationHelperService);

@NgModule({
  imports: [
    AboutPopoverModule,
    AccordionModule,
    CommonModule,
    FormsModule,
    GmsSnapInCommonModule,
    HfwControlsModule,
    HttpClientModule,
    ModalModule,
    PortalModule,
    SiContentActionBarModule,
    SimplChartsNgModule,
    SimplTrendviewerNgModule.forRoot({
      trendviewerApi: { provide: TrendviewerApi, useFactory: TrendApiServiceFactory, deps: [TrendDefinitionService, TimerService] },
      trendconfigurationApi: { provide: TrendConfigurationApi, useFactory: TrendConfigApiServiceFactory,
        deps: [TrendValidationHelperService, TrendDefinitionService] }
    }),
    SiSearchBarModule,
    SiSliderModule,
    TabsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true
    }),
    TilesViewModule,
    TREND_SNAPIN_ROUTING
  ],
  declarations: [
    GeneralSettingsComponent,
    SearchViewComponent,
    TrendChartComponent,
    TrendHostComponent,
    TrendSnapInComponent,
    TrendThumbnailManagerClientComponent
  ],
  providers: [
    ProductService,
    SiTimeRangeService,
    SiToastNotificationService,
    SiTrendviewerConfigService,
    SiTrendViewerPropertiesService,
    SiTrendviewerService,
    TimerService,
    { provide: PerformanceRef, useClass: WindowPerformance },
    TrendDefinitionManager,
    TrendDefinitionService,
    TrendLogManager,
    TrendSnapinService,
    TrendValidationHelperService,
    ValidationDialogService
  ],
  exports: [TrendSnapInComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GmsTrendSnapInModule { }
