import { Pipe, PipeTransform } from '@angular/core';

import { formatNumber, getFractionDigits } from '../../helpers/number-helper';

@Pipe({
  name: 'siNumber',
  standalone: true
})
export class SiNumberPipe implements PipeTransform {
  transform(value?: number, resolution?: number, decimalsAllowed?: boolean): string {
    if (value === undefined) {
      return '';
    }
    return formatNumber(value, decimalsAllowed ? getFractionDigits(resolution) : 0);
  }
}
