import { CommandParameters } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { AnyProperty } from '@simpl/object-browser-ng';

import { CommandParamType, DateFieldOrder } from './command-vm.types';
import { ViewModelContext } from './snapin-vm.types';

/**
 * Command parameter view-model implementation.
 */
export abstract class CommandParamViewModel {

  public siParam: AnyProperty;

  protected desc: string;
  protected isInferred: boolean;

  /**
   * Parameter id unique across all parameters for a given command.
   */
  public get id(): string {
    return this.param.Name;
  }

  /**
   * Parameter description displayed in the UI.
   */
  public get description(): string {
    return this.desc;
  }

  /**
   * Indicates if the parameter can be inferred from the index of the array element
   * on which the command is being executed.
   *
   * Applicable only to element-based commands.
   */
  public get inferred(): boolean {
    return this.isInferred;
  }

  /**
   * Native parameter type.
   */
  public get nativeType(): string {
    return this.param.DataType;
  }

  /**
   * Locale to use for formatting.
   */
  public get locale(): string {
    return this.vmContext.locale;
  }

  /**
   * Parameter data type.
   */
  public abstract get dataType(): CommandParamType;

  public get paramRaw(): CommandParameters {
    return this.param;
  }

  /**
   * Set date field order for input of date command parameter values based on locale.
   *
   * This is needed to indicate to the view which of two format specifiers to inject into
   * the UI component used to prompt for data input.
   * Eventually, it is expected that the UI component used will recognize a locale and then
   * this logic can be removed. I.e., this is intended to be short-lived.
   *
   * For the above reason, the logic used to establish field order will be very simple;
   * use MM-DD-YYYY for the USA and DD-MM-YYYY for every thing else.
   */
  protected static dateFieldOrderFromLocale(locale: string): DateFieldOrder {
    if (locale === 'en-US') {
      return DateFieldOrder.MonthDayYear;
    }
    return DateFieldOrder.DayMonthYear;
  }

  /**
   * Constructor.
   */
  public constructor(
    protected traceService: TraceService,
    protected readonly vmContext: ViewModelContext,
    protected param: CommandParameters) {

    if (!param || !vmContext) {
      throw new Error('undefined argument');
    }

    this.desc = param.Descriptor;

    // Inferred flag indicates  if this parameter is an inferred value (meaning, the value
    // does not have to be prompted; rather it derived from the index of the array element
    // being commanded).  Applies only to array-type properties, including bit-string.
    this.isInferred = param.Application === 1 ? true : false;

    // Create the object to be bound to the si-element UI component
    // Value properties will be filled in by derived param-type classes
    this.siParam = {
      id: this.id,
      name: this.description,
      // userDefinedName?: string,
      // overrideMode?: OverrideMode,
      // overridden?: boolean,
      // actions?: MenuItem[],
      // defaultText?: string,
      value: undefined
    };
  }

  /**
   * Encode the provided object into a parameter value according to the parameter
   * data type and return the value as a string.
   *
   * Undefined must be returned of the provided object cannot be encoded.
   */
  public abstract encodeValue(val: any): string;

  /**
   * Update parameter.
   * Called when a command change indication is received.
   *
   * NOTE: Typically, command change indications are sent when a property command becomes enabled/disabled
   *   due to a property value/state change.  In this case, the only parameter property likely to have changed
   *   is the parameter default-value, since this is often based on the properties current value.  A command
   *   change will also be sent if the command is re-engineered, in which case any of the parameter properties
   *   may have changed, but this is a much less common scenario.
   *
   *   For this reason, the only parameter property that will be considered for modification AFTER the parameter
   *   view-model has been created is the default-value.  All other command parameter changes will be reflected
   *   the next time the property and its commands are read (i.e., DP is re-selected by the user).
   */
  public updateParam(p: CommandParameters): void {
    if (p) {
      if (p.Name !== this.id) {
        throw new Error('updateParam called with parameter object containing a different id');
      }

      // do not update the parameter if it is an inferred parameter of bitstring
      if (((p.DataType !== 'BasicBit32') && (p.DataType !== 'BasicBit64')) || (!this.isInferred)) {
        this.param = p;
        // this.desc = p.Descriptor;
        this.updateParamAttributes();
      }
    }
  }

  protected abstract updateParamAttributes(): void;

  public abstract resetParamValue(): void;

  /**
   * Align this parameter with the provided param.
   * This method is used to align parameters of aggregate property commands across
   * a set of object-properties.
   * In the case that the provided alignment parameter is incompatible with this parameter,
   * false will be returned.
   */
  public abstract alignParam(param: CommandParamViewModel): boolean;
}
