import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { WebComponentContentChildren } from '@simpl/element-ng/common';
import { SiResizeObserverDirective } from '@simpl/element-ng/resize-observer';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiWizardStepComponent } from './si-wizard-step.component';

@Component({
  selector: 'si-wizard',
  templateUrl: './si-wizard.component.html',
  styleUrl: './si-wizard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiResizeObserverDirective, SiTranslateModule, NgTemplateOutlet]
})
export class SiWizardComponent implements AfterContentInit, OnDestroy {
  @WebComponentContentChildren(SiWizardStepComponent)
  @ContentChildren(SiWizardStepComponent)
  private wizardSteps!: QueryList<SiWizardStepComponent>;

  @ViewChild('container', { static: false }) private wizardContainerElement!: ElementRef;
  @ViewChild('cancelButtonContainer', { static: false })
  private wizardCancelButtonContainerElement!: ElementRef;
  @ViewChild('buttonContainer', { static: false })
  private wizardButtonContainerElement!: ElementRef;

  /** Description of back button. */
  @Input() backText = $localize`:@@SI_WIZARD.BACK:Back`;
  /** Description of next button. */
  @Input() nextText = $localize`:@@SI_WIZARD.NEXT:Next`;

  /** @deprecated Use {@link hideNavigation} instead. */
  @Input({ transform: booleanAttribute }) set hasNavigation(value: boolean) {
    this.hideNavigation = !value;
  }

  /** Hide the navigation buttons previous/next. */
  @Input({ transform: booleanAttribute }) hideNavigation = false;
  /** Description of save button. */
  @Input() saveText = $localize`:@@SI_WIZARD.SAVE:Save`;
  /** Hide the save button. */
  @Input({ transform: booleanAttribute }) hideSave = false;
  /** Text shown if you complete the wizard. */
  @Input() completionText = $localize`:@@SI_WIZARD.COMPLETED:Wizard completed!`;
  /** Description of cancel button. */
  @Input() cancelText = $localize`:@@SI_WIZARD.CANCEL:Cancel`;
  /** Show the cancel button */
  @Input({ transform: booleanAttribute }) hasCancel = false;
  /** Display a predefined page by the end of the wizard. */
  @Input({ transform: booleanAttribute }) enableCompletionPage = false;
  /** Define how many milliseconds the completion page is visible. */
  @Input() completionPageVisibleTime = 3000;
  /** Class name of icon shown for current and upcoming steps. */
  @Input() stepIcon = 'element-not-checked';
  /** Class name of icon shown when a step was completed. */
  @Input() stepCompletedIcon = 'element-checked-filled';
  /** Class name of icon shown when a step had an error. */
  @Input() stepFailedIcon = 'element-warning-filled';
  /** Set the orientation of the wizard to vertical. */
  @Input({ transform: booleanAttribute }) @HostBinding('class.vertical') verticalLayout = false;

  /** Callback function triggered after the wizard has been completed. */
  @Output() readonly completionAction = new EventEmitter();
  /** Callback function triggered if the wizard has been canceled. */
  @Output() readonly cancel = new EventEmitter();

  /** Get the current step wizard step index. */
  get index(): number {
    return this._index;
  }

  /** Get number of wizard steps. */
  get stepCount(): number {
    return this.steps.length;
  }

  /** Get current visible wizard step. */
  get currentStep(): SiWizardStepComponent {
    return this._currentStep;
  }

  protected steps: SiWizardStepComponent[] = [];
  protected allStepsAreVisible = true;
  protected visibleSteps = 0;
  protected showCompletionPage = false;
  protected isSmall = false;

  private _index = 0;
  private _currentStep!: SiWizardStepComponent;
  private destroyer = new Subject<void>();
  private cdRef = inject(ChangeDetectorRef);

  ngAfterContentInit(): void {
    this.updateSteps();
    this._currentStep = this.steps[0];
    this.cdRef.markForCheck();
    queueMicrotask(() => {
      if (this.steps.length > 0) {
        this.steps[0].isActive = true;
        this.cdRef.markForCheck();
      }
    });

    this.wizardSteps.changes.pipe(takeUntil(this.destroyer)).subscribe(() => {
      this.updateSteps();
      if (!this.wizardSteps.some(step => step === this.currentStep)) {
        this._currentStep = this.wizardSteps.first;
        this._index = 0;
      } else {
        this._index = this.steps.indexOf(this.currentStep);
      }
      this.cdRef.markForCheck();
      queueMicrotask(() => {
        this.activate(this.currentStep);
        this.calculateNumberOfVisibleSteps();
        this.cdRef.markForCheck();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  /** @internal */
  notifyChildrenUpdated(): void {
    this.cdRef.markForCheck();
  }

  protected canActivate(stepIndex: number): boolean {
    if (stepIndex < 0) {
      return false;
    }
    // Can always activate previous steps
    if (stepIndex < this.index) {
      return true;
    }
    // We are already in the step. Nothing to activate.
    if (stepIndex === this.index) {
      return false;
    }
    // Fast-forward: check all steps if they are valid
    for (let i = this.index; i < stepIndex; i++) {
      const theStep = this.steps[i];
      if (!theStep.isValid) {
        return false;
      }
    }
    return true;
  }

  protected activateStep(event: Event, stepIndex: number): void {
    event.preventDefault();
    if (this.canActivate(stepIndex)) {
      if (stepIndex > this.index) {
        this.next(stepIndex - this.index);
      }
      if (stepIndex < this.index) {
        this.back(this.index - stepIndex);
      }
    }
  }

  protected getStateClass(stepIndex: number): string {
    if (stepIndex === this.index) {
      return 'active';
    }
    if (!this.canActivate(stepIndex)) {
      return 'disabled';
    }
    if (stepIndex < this.index) {
      return 'completed';
    }
    return '';
  }

  protected getAriaDisabled(stepIndex: number): string {
    if (!this.canActivate(stepIndex)) {
      return 'true';
    }
    return 'false';
  }

  protected getAriaCurrent(stepIndex: number): string {
    if (stepIndex === this.index) {
      return 'step';
    }
    return 'false';
  }

  /**
   * Go to the next wizard step.
   * @param delta optional number of steps to move forward.
   */
  next(delta: number = 1): void {
    if (this.index === this.steps.length) {
      return;
    }
    const stepIndex = this.index + delta;
    const nextStep = this.steps[stepIndex];
    if (this.canActivate(stepIndex)) {
      this.currentStep.next.emit();
      if (this.currentStep.isNextNavigable) {
        this.activate(nextStep);
      }
    }
    this.cdRef.markForCheck();
  }

  /**
   * Go to the previous wizard step.
   * @param delta optional number of steps to move backwards.
   */
  back(delta: number = 1): void {
    if (this.index === 0) {
      return;
    }
    this.currentStep.back.emit();
    this.activate(this.steps[this.index - delta]);
    this.cdRef.markForCheck();
  }

  /** Triggers the save action to complete the wizard. */
  save(): void {
    this.currentStep.save.emit();

    if (this.enableCompletionPage && this.completionPageVisibleTime > 0) {
      this.showCompletionPage = true;
      setTimeout(() => {
        this.showCompletionPage = false;
        this.completionAction.emit();
        this.cdRef.markForCheck();
      }, this.completionPageVisibleTime);
    } else {
      this.completionAction.emit();
    }
  }

  protected getState(step: SiWizardStepComponent, stepIndex: number): string {
    if (step.failed === true) {
      return `title-warning ${this.stepFailedIcon}`;
    } else {
      const txtStyle = step.isActive ? 'wizard-active element-radio-checked' : `${this.stepIcon}`;
      return stepIndex >= this.index ? `${txtStyle}` : `text-primary ${this.stepCompletedIcon}`;
    }
  }

  protected isVisibleStep(stepIndex: number): boolean {
    if (this.allStepsAreVisible) {
      return true;
    }
    if (this.index === stepIndex) {
      return true;
    }

    let visibleStepsDelta = (this.visibleSteps - 1) / 2;
    for (let i = visibleStepsDelta; i > 0; i--) {
      if (this.index - i < 0 || this.index + i > this.steps.length - 1) {
        visibleStepsDelta = this.visibleSteps - i;
      }
    }
    for (let i = visibleStepsDelta; i > 0; i--) {
      if (stepIndex === this.index - i || stepIndex === this.index + i) {
        return true;
      }
    }
    return false;
  }

  private activate(step: SiWizardStepComponent): void {
    if (this.currentStep) {
      this.currentStep.isActive = false;
    }

    step.isActive = true;
    this._currentStep = step;
    this._index = this.steps.indexOf(step);
  }

  protected calculateNumberOfVisibleSteps(): void {
    const clientWidth = this.wizardContainerElement.nativeElement.clientWidth;
    const clientHeight = this.wizardContainerElement.nativeElement.clientHeight;
    const containerSize = this.verticalLayout ? clientHeight : clientWidth;
    const prevNextWidth = this.wizardButtonContainerElement.nativeElement.clientWidth;
    const prevNextHeight = this.wizardButtonContainerElement.nativeElement.clientHeight;
    const cancelWidth = this.hasCancel
      ? this.wizardCancelButtonContainerElement.nativeElement.clientWidth
      : 0;
    const oneStep = this.wizardContainerElement.nativeElement.querySelector('.step');
    const oneStepSize = this.verticalLayout ? oneStep?.clientHeight : oneStep?.clientWidth;

    // visible steps can be only 3, 5, 7,..
    const allButtonsSize = this.verticalLayout ? prevNextHeight : 2 * prevNextWidth + cancelWidth;
    let placeableSteps = oneStepSize
      ? Math.floor((containerSize - allButtonsSize) / oneStepSize)
      : 1;
    this.allStepsAreVisible = placeableSteps >= this.steps.length;
    if (!this.allStepsAreVisible && placeableSteps % 2 === 0) {
      placeableSteps--;
    }

    const smallBreakPoint = this.verticalLayout ? 240 : 540;
    if (containerSize < smallBreakPoint + allButtonsSize) {
      this.isSmall = true;
      this.visibleSteps = 3;
    } else {
      this.isSmall = false;
      this.visibleSteps = placeableSteps;
    }
  }

  private updateSteps(): void {
    this.steps = this.wizardSteps.toArray();
    this.steps.forEach(step => step.registerParent(this));
  }
}
