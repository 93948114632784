@if (visible) {
  @if (smallScreen && !collapsed) {
    <div
      class="modal-backdrop"
      [@backdrop]="smallScreen && !collapsed ? 'show' : 'hide'"
      (click)="toggleCollapse()"
    ></div>
  }
  <nav
    #nav
    tabindex="-1"
    class="elevation-1 bg-base-1 focus-sub-inside"
    [siSkipLinkTarget]="skipLinkNavigationLabel"
    [@collapse]="collapsed ? 'collapsed' : 'expanded'"
    [class.text-only]="textOnly"
  >
    <div class="collapse-toggle ms-auto">
      <div class="mobile-drawer focus-inside navbar-vertical-no-collapse">
        <button
          type="button"
          class="btn btn-circle btn-sm btn-ghost flip-rtl"
          [class.element-double-left]="!collapsed"
          [class.element-double-right]="collapsed"
          [attr.aria-label]="
            (collapsed ? navbarExpandButtonText : navbarCollapseButtonText) | translate
          "
          [attr.aria-expanded]="!collapsed"
          (click)="toggleCollapse()"
        >
        </button>
      </div>
    </div>
    @if (searchable) {
      <div class="nav-search">
        <si-search-bar
          #searchBar
          class="mx-4"
          [placeholder]="searchPlaceholder | translate"
          [colorVariant]="'base-0'"
          [debounceTime]="searchInputDelay"
          [showIcon]="true"
          [prohibitedCharacters]="'*?'"
          (searchChange)="doSearch($event)"
        />
        <button
          type="button"
          class="btn-search bg-base-0 p-2 mx-4 mobile navbar-vertical-no-collapse text-secondary"
          [attr.aria-label]="searchPlaceholder | translate"
          (click)="expandForSearch()"
        >
          <i class="icon element-search"></i>
        </button>
      </div>
    }
    <div class="nav-scroll">
      @for (item of items; track $index) {
        <si-navbar-vertical-item
          class="mb-4"
          [item]="item"
          [textOnly]="textOnly"
          [collapseButtonText]="navbarCollapseButtonText | translate"
          [expandButtonText]="navbarExpandButtonText | translate"
          (menuTrigger)="menuTriggered()"
          (itemTrigger)="itemTriggered()"
        />
      }
    </div>
  </nav>
}
<main
  class="si-layout-inner focus-none"
  tabindex="-1"
  [siSkipLinkTarget]="skipLinkMainContentLabel"
>
  <ng-content />
</main>
