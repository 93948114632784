import { NgModule } from '@angular/core';
import { IPreselectionService, IStorageService } from '@gms-flex/core';

import { PropertyPreselectService } from './services/property-preselect.service';
import { PropertyStorageService } from './services/property-storage.service';

@NgModule({
  providers: [{ provide: IPreselectionService, useClass: PropertyPreselectService, multi: true },
    { provide: IStorageService, useClass: PropertyStorageService, multi: true }]
})
export class GmsPropertyRootServicesModule {}
