import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

/**
 * @deprecated The SiPromptDialogButtonsComponent only reuses element's
 * circle buttons and does it's arrangement. We will remove the component
 * in the future. Please add the circle buttons directly to you application.
 */
@Component({
  selector: 'si-prompt-dialog-buttons',
  templateUrl: './si-prompt-dialog-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiPromptDialogButtonsComponent implements OnInit {
  /**
   * Input for disabling cancel button.
   */
  @Input({ transform: booleanAttribute }) cancelDisabled = false;
  /**
   * Input for disabling confirm button.
   */
  @Input({ transform: booleanAttribute }) confirmDisabled = false;
  /**
   * Input for disabling save button.
   */
  @Input({ transform: booleanAttribute }) saveDisabled = false;
  /**
   * Text for aria-label of cancel button. Needed for a11y.
   */
  @Input() cancelLabel = $localize`:@@SI_PROMPT_DIALOG_BUTTONS.CANCEL:Cancel`;
  /**
   * Text for aria-label of confirm button. Needed for a11y.
   */
  @Input() confirmLabel = $localize`:@@SI_PROMPT_DIALOG_BUTTONS.CONFIRM:Confirm`;
  /**
   * Text for aria-label of save button. Needed for a11y.
   */
  @Input() saveLabel = $localize`:@@SI_PROMPT_DIALOG_BUTTONS.SAVE:Save`;

  /**
   * Output callback event notifying you when the cancel button is pushed.
   */
  @Output() readonly cancel = new EventEmitter();
  /**
   * Output callback event that is trigger on confirm.
   */
  @Output() readonly confirm = new EventEmitter();
  /**
   * Output callback event this triggers when the save button was pressed.
   */
  @Output() readonly save = new EventEmitter();

  protected showSave = false;

  ngOnInit(): void {
    this.showSave = this.save.observers.length > 0;
  }
}
