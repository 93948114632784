import { NgModule } from "@angular/core";
import { IPreselectionService, IStorageService } from "@gms-flex/core";
import { GraphicsPreselectService }  from "./services/graphics-preselect.service";
import { GraphicsStorageService }  from "./services/graphics-storage.service";

@NgModule({
  providers: [{ provide: IPreselectionService, useClass: GraphicsPreselectService, multi: true },
      { provide: IStorageService, useClass: GraphicsStorageService, multi: true }]
  })
export class GmsGraphicsViewerRootServicesModule { }
