import { Subject } from 'rxjs';

import { Evaluation, EvaluationType, PropertyType } from '../processor/evaluation';
import { GmsAnimatedGifElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { FormatHelper } from '../utilities/format-helper';
import { GifReader } from '../utilities/gif-reader';
import { SvgUtility } from '../utilities/parser';
import { GmsImage } from './gms-image';

export class GmsAnimatedGif extends GmsImage {

  public gifLoaded: Subject<string | undefined> = new Subject<string | undefined>();
  public runStateChanged: Subject<string> = new Subject<string>();
  private _evaluationRun: Evaluation;
  private _evaluationSpeed: Evaluation;
  private _designValueRun = true;
  private _designValueSpeed = 1;

  private _speedAnimation = 1;
  public get SpeedAnimation(): number {
    return this._speedAnimation;
  }
  public set SpeedAnimation(value: number) {

    if (this._speedAnimation !== value) {
      this._speedAnimation = value;

      this._runReverse = this._speedAnimation < 0 ? true : false;

      this.runStateChanged.next('Speed');
    }
  }

  private _runAnimation = true;
  public get RunAnimation(): boolean {
    return this._runAnimation;
  }
  public set RunAnimation(value: boolean) {

    if (this._runAnimation !== value) {
      this._runAnimation = value;

      this.runStateChanged.next('Run');
    }
  }

  private _runReverse = false;
  public get RunReverse(): boolean {
    return this._runReverse;
  }

  private _currentFrameUrl: string;
  public get CurrentFrameURL(): string {
    return this._currentFrameUrl;
  }
  public set CurrentFrameURL(value: string) {

    if (this._currentFrameUrl !== value) {
      this._currentFrameUrl = value;

      this.NotifyPropertyChanged('CurrentFrameURL');
    }
  }

  private _framesLoaded = false;
  public get FramesLoaded(): boolean {
    return this._framesLoaded;
  }
  public set FramesLoaded(value: boolean) {

    if (this._framesLoaded !== value) {
      this._framesLoaded = value;

      this.NotifyPropertyChanged('FramesLoaded');
      this.gifLoaded.next(undefined);
    }
  }

  private _frames: any[] = [];
  public get Frames(): any[] {
    return this._frames;
  }

  private _globalDispose = -1;
  public get GlobalDispose(): number {
    return this._globalDispose;
  }

  constructor() {
    super(GmsElementType.AnimatedGif);
  }

  public Deserialize(node: Node): void {

    let result: string = SvgUtility.GetAttributeValue(node, GmsAnimatedGifElementPropertyType.RunAnimation);
    if (result !== undefined) {
      this._runAnimation = result === 'True';
      this._designValueRun = this._runAnimation;
    }

    result = SvgUtility.GetAttributeValue(node, GmsAnimatedGifElementPropertyType.SpeedAnimation);
    if (result !== undefined) {
      this._speedAnimation = FormatHelper.StringToNumber(result); // Number(result);
      this._designValueSpeed = this._speedAnimation;
      this._runReverse = this._speedAnimation < 0 ? true : false;
    }

    super.Deserialize(node);
  }

  public CopyFrom(element: GmsAnimatedGif): void {
    this.IsCopying = true;

    this._runAnimation = element._designValueRun;
    this._designValueRun = element._designValueRun;

    this._speedAnimation = element._designValueSpeed;
    this._designValueSpeed = element._designValueSpeed;
    this._runReverse = this._speedAnimation < 0 ? true : false;

    super.CopyFrom(element);

    this.IsCopying = false;
  }

  public SetGifContent(dataURI: string): void {

    this._frames = GifReader.Read(dataURI);

    for (let i = 0; i < this.Frames.length; i++) {
      if (this._frames.length > 0) {
        if (this._globalDispose === -1) {
          this._globalDispose = this.Frames[i].disposal;
        } else if (this._globalDispose !== 0 && this.Frames[i].disposal !== this._globalDispose) {
          this._globalDispose = 0;
        }
      }
      this.Frames[i].visible = 'hidden';
    }
    this.FramesLoaded = true;
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'RunAnimation':
        this.UpdatePropertyRun(evaluation);
        break;
      case 'SpeedAnimation':
        this.UpdatePropertySpeed(evaluation);
        break;
      default:
        return;
    }
  }

  private UpdatePropertyRun(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationRun = evaluation;
    }
    const run: any = Evaluation.GetValue2(this._evaluationRun, this._designValueRun, PropertyType.Boolean);

    this.RunAnimation = run;
  }

  private UpdatePropertySpeed(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationSpeed = evaluation;
    }
    const speed: any = Evaluation.GetValue2(this._evaluationSpeed, this._designValueSpeed, PropertyType.Number);

    this.SpeedAnimation = speed;
  }
}
