import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { last } from 'rxjs/operators';

import { TraceChannel } from '../common/trace-channel';

/**
 * Class representing the Window.performance object
 */
export abstract class PerformanceRef {
  public abstract now(): number;
}

@Injectable({
  providedIn: 'root'
})
/**
 * Wrapper of window.performance
 */
export class WindowPerformance extends PerformanceRef {
  public now(): number {
    return window.performance.now();
  }
}

/**
 * The phases of graphics loading
 */
export enum TimerPhaseEnum {
  /**
   * Processing of a WSI message to determine whether to handle by GraphicsViewer snapin
   */
  PreSelection = 'PreSelection',

  /**
   * Request to WSI for svg document string representing the requested graphic
   */
  RetrievingGraphicItems = 'RetrievingGraphicItems',

  /**
   * Parsing of svg nodes into graphic element models
   */
  Deserialization = 'Deserialization',

  /**
   * Processing of regular datapoints
   */
  DatapointsProcessing = 'DatapointsProcessing',

  /**
   * Processing of deferred datapoints
   */
  DeferredDatapointsProcessing = 'DeferredDatapointsProcessing',

  /**
   * Initialization of subscriptions to WSI data points
   */
  GetGraphicsContent = 'GetGraphicsContent'

  /**
   * Rendering of Element Models
   */
  // Rendering = "Rendering"
}

/**
 * A timer, responsible for tracking 1 phase of the graphics
 */
export class TimerPhase {
  private readonly _startTime: number;
  private _endTime: number;
  private _isEnded: boolean;

  public constructor(startTime: number) {
    this._startTime = startTime;
    this._isEnded = false;
  }

  public get IsEnded(): boolean {
    return this._isEnded;
  }

  public stop(stopTime: number): void {
    this._endTime = stopTime;
    this._isEnded = true;
  }

  public getDuration(): number {
    if (!this.IsEnded) {
      return -1;
    }
    return this._endTime - this._startTime;
  }
}

@Injectable()
export class TimerService {
  private readonly _traceModule: TraceChannel;
  private readonly _timers: Map<TimerPhaseEnum, TimerPhase>;
  private _startTime: number;
  private _endTime: number;

  public constructor(private readonly traceService: TraceService, private readonly performance: PerformanceRef) {
    this._timers = new Map<TimerPhaseEnum, TimerPhase>();
    this._traceModule = TraceChannel.Timings;
  }

  public startPhase(phaseEnum: TimerPhaseEnum): void {
    // if (this._timers.has(phaseEnum)) {
    //     this.traceService.warn(this._traceModule, `Attempting to start timer on graphics loading phase: ${phaseEnum} that
    // has already been started.`);
    //     return;
    // }
    const curTime: number = this.now();
    if (!this.isStarted()) {
      this._startTime = curTime;
    }
    this._timers.set(phaseEnum, new TimerPhase(curTime));
  }

  public allComplete(): boolean {
    // get list of all graphics loading phases
    const allPhases: string[] = Object.keys(TimerPhaseEnum);

    // check timer associated with each phase for IsEnded
    for (const phase of allPhases) {
      if (!this._timers.has(phase as TimerPhaseEnum) || !this._timers.get(phase as TimerPhaseEnum).IsEnded) {
        return false;
      }
    }
    return true;
  }

  public isStarted(): boolean {
    return this._timers.size > 0;
  }

  public isStartedPhase(phaseEnum: TimerPhaseEnum): boolean {
    return this._timers.has(phaseEnum);
  }

  public isEndedPhase(phaseEnum: TimerPhaseEnum): boolean {
    if (!this.isStartedPhase(phaseEnum)) {
      return false;
    }
    const curTimer: TimerPhase = this._timers.get(phaseEnum);
    return curTimer.IsEnded;
  }

  public totalDuration(): number {
    if (!this.allComplete()) {
      this.traceService.warn(this._traceModule, `Attempting to calculate total duration on incomplete graphics loading timer`);
      return -1;
    }
    return this._endTime - this._startTime;
  }

  public stopPhase(phaseEnum: TimerPhaseEnum): void {
    if (!this._timers.has(phaseEnum)) { // attempting to stop timer that has not been started
      this.traceService.warn(this._traceModule, `Attempting to stop timer on graphics loading phase: ${phaseEnum} that
        has not been started.`);
      return;
    }

    const curTimer: TimerPhase = this._timers.get(phaseEnum);

    if (curTimer.IsEnded) {
      this.traceService.warn(this._traceModule, `Attempting to stop timer on graphics loading phase: ${phaseEnum} that has
        already been stopped.`);
      return;
    }

    const curTime: number = this.now();
    curTimer.stop(curTime);
    if (this.allComplete()) {
      this._endTime = curTime;
    }
  }

  /**
   * Get the duration of a phase
   * @return the total duration of a completed phase, or -1 if phase is not completed
   * @param phaseEnum
   */
  public durationPhase(phaseEnum: TimerPhaseEnum): number {
    if (!this.isStartedPhase(phaseEnum)) {
      return -1;
    }
    const curTimer: TimerPhase = this._timers.get(phaseEnum);
    const duration: number = curTimer.getDuration();
    return duration;
  }

  public logSummary(): void {
    // Collect timings for all graphics loading phases
    const timePre: number = this.durationPhase(TimerPhaseEnum.PreSelection);
    const timeRet: number = this.durationPhase(TimerPhaseEnum.RetrievingGraphicItems);
    const timeContent: number = this.durationPhase(TimerPhaseEnum.GetGraphicsContent);
    const timeDes: number = this.durationPhase(TimerPhaseEnum.Deserialization);
    const timeDp: number = this.durationPhase(TimerPhaseEnum.DatapointsProcessing);
    const timeDef: number = this.durationPhase(TimerPhaseEnum.DeferredDatapointsProcessing);
    // let timeRendering: number = this.durationPhase(TimerPhaseEnum.Rendering);
    const timeTotal: number = this.allComplete() ? this.totalDuration() : 0;

    /*
         NOTE: remove this old summary string, after finalizing set of timer phases to include
                summaryString =
                    `\n**Graphic Load Timing**
        Preselection:                          ${this.isEndedPhase(TimerPhaseEnum.PreSelection) ? timePre + "ms." : "not completed"}
        RetrievingGraphicItems:                ${this.isEndedPhase(TimerPhaseEnum.RetrievingGraphicItems) ? timeRet + "ms." : "not completed"}
        GetGraphicsContent:                    ${this.isEndedPhase(TimerPhaseEnum.GetGraphicsContent) ? timeContent + "ms." : "not completed"}
        Deserialization:                       ${this.isEndedPhase(TimerPhaseEnum.Deserialization) ? timeDes + "ms." : "not completed"}
        ---->Processing Datapoints             ${this.isEndedPhase(TimerPhaseEnum.DatapointsProcessing) ? timeDp + "ms." : "not completed"}
        ---->Processing Deferred Datapoints    ${this.isEndedPhase(TimerPhaseEnum.DeferredDatapointsProcessing) ? timeDef + "ms." : "not completed"}
        Rendering:                             ${this.isEndedPhase(TimerPhaseEnum.Rendering) ? timeRendering + "ms." : "not completed"}
        Total Time:                            ${this.allComplete() ? timeTotal + "ms." : "All Graphics Loading phases have not completed yet."}
        **End Graphic Load Timing**`;
        */
    const notCompleted = 'not completed';
    const summaryString =
            `\n**Graphic Load Timing**
RetrievingGraphicItems:                 ${this.isEndedPhase(TimerPhaseEnum.RetrievingGraphicItems) ? timeRet + 'ms.' : notCompleted}
GetGraphicsContent:                     ${this.isEndedPhase(TimerPhaseEnum.GetGraphicsContent) ? timeContent + 'ms.' : notCompleted}
Deserialization & Visualization:        ${this.isEndedPhase(TimerPhaseEnum.Deserialization) ? timeDes + 'ms.' : notCompleted}
Total Time:                             ${this.allComplete() ? timeTotal + 'ms.' : 'All Graphics Loading phases have not completed yet.'}
**End Graphic Load Timing**`;
    this.traceService.info(this._traceModule, summaryString);
  }

  public reset(): void {
    this._timers.clear();
  }

  private now(): number {
    const retVal: number = Math.round(this.performance.now());
    return retVal;
  }
}
