import { Injectable } from '@angular/core';
import { TrendEvalutionServiceBase } from '@gms-flex/trend';

@Injectable()
export class TrendEvalutionBxSubstituteService implements TrendEvalutionServiceBase {
  public doShowButtonsAndContentAction(): boolean {
    return false;
  }

  public doCheckIfManagedTypeIsNewTrend(managedType: string): boolean {
    return false;
  }

  public doCheckIfManagedTypeIsTrendViewDefination(managedType: string): boolean {
    return false;
  }

  public doShowTrendTile(managedType: string): boolean {
    return false;
  }

  public doAllowOnlineTrendModification(managedTypeName: string): boolean {
    return false;
  }

  public doShowSnapinContentAction(managedTypeName: string): boolean {
    return false;
  }

  public doCheckIfManagedTypeIsOfflineTrendLog(managedTypeName: string): boolean {
    return false;
  }

  public doCheckIfManagedTypeIsOfflineOrOnlineTrendLog(managedTypeName: string): boolean {
    return false;
  }

  public getGenSettingsTimeout(): number {
    return 300;
  }
}
