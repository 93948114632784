import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, map, Observable } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { EquipmentTypeResponse } from './equipment-type-proxy.model';

const equipmentUrl = `${environment.bxPlatform.deviceVerticalApiUrl}/v2/partitions`

@Injectable({
  providedIn: 'root'
})
export class EquipmentTypeProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesEquipmentTypes, 'EquipmentTypeProxyService created.');
  }

  public getEquipmentTypes(partitionId: string): Observable<EquipmentTypeResponse> {
    this.traceService.debug(TraceModules.bxServicesEquipmentTypes, `EquipmentTypeProxyService.getEquipmentTypes() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${equipmentUrl}/${partitionId}/equipment-types`;

    return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getEquipmentTypes()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEquipmentTypes()')));
  }

}
