import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModeService } from '@gms-flex/services-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModeInterceptor implements HttpInterceptor {

  constructor(private readonly modeService: ModeService) {
    //
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modeReq: HttpRequest<any> = req.clone({
      headers: req.headers.delete('Alertid')
    });
    return next.handle(modeReq);
  }
}
