import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyForm, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { JSONSchema7 } from 'json-schema';

// eslint-disable-next-line @angular-eslint/prefer-standalone, @angular-eslint/prefer-standalone-component
@Component({
  selector: 'si-formly',
  templateUrl: './si-formly.component.html'
})
export class SiFormlyComponent<TControl extends { [K in keyof TControl]: AbstractControl }>
  implements OnInit
{
  @ViewChild(FormlyForm) formlyForm?: FormlyForm;
  @Input() form?: FormGroup<TControl>;
  /** Mapping of field name and its value. */
  @Input() model = {};
  /** Define FormlyFormOptions. */
  @Input() options: FormlyFormOptions = {};

  /**
   * JSONSchema7 can be used instead of FormlyFieldConfig array for defining form fields.
   * */
  @Input() set schema(s: JSONSchema7) {
    this.handleSchema(s);
  }

  /** Define all form fields with FormlyFieldConfig array. */
  @Input() set fields(f: FormlyFieldConfig[]) {
    this.fieldConfig = f;
    this.applyLabelWidth();
  }

  /** Define width for field labels in pixel */
  @Input() set labelWidth(w: number | undefined) {
    this.labelWidthInternal = w;
    this.applyLabelWidth();
  }

  @Output() readonly fieldsChange = new EventEmitter<FormlyFieldConfig[]>();
  @Output() readonly formChange = new EventEmitter<FormGroup<TControl>>();
  @Output() readonly modelChange = new EventEmitter<any>();

  protected fieldConfig?: FormlyFieldConfig[];
  protected ownForm = false;
  private labelWidthInternal?: number;
  private readonly formlyJsonschema = inject(FormlyJsonschema);

  ngOnInit(): void {
    if (!this.form) {
      this.ownForm = true;
      this.form = new FormGroup<TControl>({} as TControl);
      this.formChange.emit(this.form);
    }
  }

  private handleSchema(schema: JSONSchema7): void {
    const opts = {
      map: (field: FormlyFieldConfig, s: JSONSchema7): FormlyFieldConfig => {
        if (this.labelWidthInternal) {
          field.props ??= {};
          field.props.labelWidth = this.labelWidthInternal;
        }
        return field;
      }
    };
    this.fieldConfig = [this.formlyJsonschema.toFieldConfig(schema, opts)];
    this.fieldsChange.emit(this.fieldConfig);
  }

  private applyLabelWidth(): void {
    if (!this.fieldConfig) {
      return;
    }

    const apply = (cfg: FormlyFieldConfig[]): void => {
      cfg.forEach(field => {
        if (!field.props) {
          field.props = {};
        }
        field.props.labelWidth = this.labelWidthInternal;
        if (Array.isArray(field.fieldGroup)) {
          apply(field.fieldGroup);
        }
        if (typeof field.fieldArray !== 'function') {
          if (Array.isArray(field.fieldArray?.fieldGroup)) {
            apply(field.fieldArray!.fieldGroup);
          }
        }
      });
    };
    apply(this.fieldConfig);
  }
}
