import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TraceModules } from '@gms-flex/services';
import { AuthenticationServiceBase, Credentials, isNullOrUndefined, SessionCookieStorage,
  TraceService, UserAccountType, UserInfo, UserInfoStorage } from '@gms-flex/services-common';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';

/**
 * Service for managing user authentication and session maintenance.
 */

@Injectable()
export class AuthenticationBxSubstituteService implements AuthenticationServiceBase {

  private static readonly maxRenewTimeout: number = 60; // 30s
  /**
   * Stores the current user token, undefined if no user is logged.
   */
  private _userToken: string | undefined | null;
  private _userName: string | undefined | null;
  private _userDescriptor: string | undefined | null;
  private _userProfile: string | undefined | null;
  private readonly _personallyUsed: string | undefined | null;
  private readonly _clientId: string | undefined;
  private readonly _openIdLoginUri: string | undefined;
  private readonly _openIdLogoutUri: string | undefined;
  private readonly _openIdRedirectUri: string | undefined;
  private _userInactivity: string | undefined | null;
  private _isDefaultUserLoggedIn = false;

  private readonly heartBeatTimer: any = undefined;

  private readonly pathName: string | undefined;
  private readonly domainName: string | undefined;
  private readonly sub: Subscription;

  private readonly _userTokenEvent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly _userNameEvent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly _userDescriptorEvent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly _userProfileEvent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly _restoreAuthentication: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  private readonly _userInactivityEvent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly loginEvent: Subject<boolean> = new Subject<boolean>();

  public constructor(
    protected httpClient: HttpClient,
    protected traceService: TraceService,
    @Inject(CookieService) private readonly cookieService: CookieService,
    private readonly platformLocation: PlatformLocation) {

    this.traceService.info(TraceModules.authentication, 'AuthenticationBxSubstituteService created.');

    this.cookieService.set(UserInfoStorage.UserNameKey, 'PoCTest');
    this.cookieService.set(UserInfoStorage.UserDescriptorKey, 'PoCTest');
    this.cookieService.set(UserInfoStorage.UserTokenKey, 'DummyToken');
    this.cookieService.set(UserInfoStorage.UserProfileKey, 'DEFAULT');
    this.cookieService.set(UserInfoStorage.UserInactivityKey, '0');
    this.cookieService.set(UserInfoStorage.UserIsDefaultUserKey, 'false');

    // const source = interval(1000);
    // this.sub = source.subscribe(value => this.reloadLandingPage(value));

    // this.getStoredUserToken();
    // this.notifyNewUserLoggedIn();
  }

  public get loginObservable(): Observable<boolean> {
    return this.loginEvent.asObservable();
  }

  public get userTokenEvent(): Observable<string> | any {
    return this._userTokenEvent.asObservable();
  }

  public get restoreAuthentication(): Observable<boolean> | any {
    return this._restoreAuthentication.asObservable();
  }

  public get userToken(): string | any {
    return this._userToken;
  }

  public get userDescriptorEvent(): Observable<string> | any {
    return this._userDescriptorEvent.asObservable();
  }

  public get userProfileEvent(): Observable<string> | any {
    return this._userProfileEvent.asObservable();
  }

  public get userDescriptor(): string | any {
    return this._userDescriptor;
  }

  public get userProfile(): string | any {
    return 'DEFAULT';
  }

  public get userNameEvent(): Observable<string> | any {
    return this._userNameEvent.asObservable();
  }

  public get userName(): string | any {
    return this._userName;
  }

  public get userInactivityEvent(): Observable<string> | any {
    return this._userInactivityEvent.asObservable();
  }

  public get userInactivity(): string | any {
    return this._userInactivity;
  }

  public login(credentials: Credentials): Observable<string> | any {
    this.getStoredUserToken();
    this.notifyNewUserLoggedIn();
    return of(true);
  }

  public loginDefaultUser(): Observable<string> | any {
    throw new Error('This method should not be called for cBMS.');
  }

  /**
   * Login the user with its password. If valid, a token is returned.
   *
   * @param credentials
   * @returns
   * @memberOf AuthenticationService
   */
  public getUserInfo(username: string): Observable<UserInfo> {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    return of({ UserName: '', AccountType: UserAccountType.DesigoCC, OpenIdLoginUri: '' });
  }

  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  public LoginUsingAuthorizationCode(code: string): Observable<string> | any {
    throw new Error('This method should not be called for cBMS.');
  }

  public logout(isInactivityLogout: boolean): Observable<boolean> {

    return of(true);
  }

  public setDefaultUserLoggedIn(isDefault: boolean): void {
    this._isDefaultUserLoggedIn = isDefault;
  }

  public get defaultUserLoggedIn(): boolean {
    return this._isDefaultUserLoggedIn;
  }

  private getStoredUserToken(): boolean {
    let savedToken = '';
    let savedUserName = '';
    let savedUserProfile = '';
    let savedDescriptor = '';
    let savedUserInactivity = '';
    let savedIsDefaultUser = '';
    if (this.cookieService.check(UserInfoStorage.UserTokenKey)) {
      savedToken = this.cookieService.get(UserInfoStorage.UserTokenKey);
    }
    if (this.cookieService.check(UserInfoStorage.UserNameKey)) {
      savedUserName = this.cookieService.get(UserInfoStorage.UserNameKey);
    }
    if (this.cookieService.check(UserInfoStorage.UserProfileKey)) {
      savedUserProfile = this.cookieService.get(UserInfoStorage.UserProfileKey);
    }
    if (this.cookieService.check(UserInfoStorage.UserInactivityKey)) {
      savedUserInactivity = this.cookieService.get(UserInfoStorage.UserInactivityKey);
    }
    if (this.cookieService.check(UserInfoStorage.UserDescriptorKey)) {
      savedDescriptor = this.cookieService.get(UserInfoStorage.UserDescriptorKey);
    }
    if (this.cookieService.check(UserInfoStorage.UserIsDefaultUserKey)) {
      savedIsDefaultUser = this.cookieService.get(UserInfoStorage.UserIsDefaultUserKey);
    }
    if (!isNullOrUndefined(savedToken) &&
      !isNullOrUndefined(savedUserName) &&
      !isNullOrUndefined(savedUserProfile) &&
      !isNullOrUndefined(savedUserInactivity) &&
      !isNullOrUndefined(savedDescriptor) &&
      !isNullOrUndefined(savedIsDefaultUser) &&
      savedToken !== '' &&
      savedUserName !== '' &&
      savedUserProfile !== '' &&
      savedIsDefaultUser !== '') {
      this._userToken = savedToken;
      this._userName = savedUserName;
      this._userProfile = savedUserProfile;
      this._userDescriptor = savedDescriptor;
      this._userInactivity = savedUserInactivity;
      this._isDefaultUserLoggedIn = JSON.parse(savedIsDefaultUser);
      return true;
    } else {
      return false;
    }
  }

  // private storeUserToken(): void {
  //   this.sub.unsubscribe();
  //   sessionStorage.setItem(SessionCookieStorage.TabLogged, SessionCookieStorage.False);
  //   this.pathName = this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf(loginPage))) === '' ?
  //     '/' :
  //     this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf(loginPage)));
  //   this.domainName = (this.platformLocation as any)._location?.hostname;
  //   this.cookieService.set(SessionCookieStorage.PathName, this.pathName, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(SessionCookieStorage.DomainName, this.domainName!, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(UserInfoStorage.UserTokenKey, this.userToken, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(UserInfoStorage.UserNameKey, this.userName, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(UserInfoStorage.UserProfileKey, this.userProfile, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(UserInfoStorage.UserDescriptorKey, this.userDescriptor, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(UserInfoStorage.UserInactivityKey, this.userInactivity, { 'path': this.pathName, 'domain': this.domainName });
  //   this.cookieService.set(UserInfoStorage.UserIsDefaultUserKey,
  //     JSON.stringify(this._isDefaultUserLoggedIn), { 'path': this.pathName, 'domain': this.domainName });
  //   sessionStorage.setItem(SessionCookieStorage.TabLogged, SessionCookieStorage.True);
  // }

  // private cleanStoredUserToken(): void {
  //   this.pathName = this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf('/main/page'))) === '' ?
  //     '/' :
  //     this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf('/main/page')));
  //   this.domainName = (this.platformLocation as any)._location?.hostname;

  //   // remove no more valid user token for the session.
  //   if (this.cookieService.check(UserInfoStorage.UserTokenKey)) {
  //     this.cookieService.delete(UserInfoStorage.UserTokenKey, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(UserInfoStorage.UserNameKey)) {
  //     this.cookieService.delete(UserInfoStorage.UserNameKey, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(UserInfoStorage.UserProfileKey)) {
  //     this.cookieService.delete(UserInfoStorage.UserProfileKey, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(UserInfoStorage.UserDescriptorKey)) {
  //     this.cookieService.delete(UserInfoStorage.UserDescriptorKey, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(UserInfoStorage.UserInactivityKey)) {
  //     this.cookieService.delete(UserInfoStorage.UserInactivityKey, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(UserInfoStorage.UserIsDefaultUserKey)) {
  //     this.cookieService.delete(UserInfoStorage.UserIsDefaultUserKey, this.pathName, this.domainName);
  //   }
  //   sessionStorage.removeItem(SessionCookieStorage.TabLogged);
  // }

  // private storeOpenIdConfigData(): void {
  //   sessionStorage.setItem(UserInfoStorage.ClientIdKey, this._clientId!);
  //   sessionStorage.setItem(UserInfoStorage.OpenIdLoginUriKey, this._openIdLoginUri!);
  //   sessionStorage.setItem(UserInfoStorage.OpenIdRedirectUrlKey, this._openIdRedirectUri!);
  //   sessionStorage.setItem(UserInfoStorage.UserNameKey, this._userName!);
  // }

  // private cleanOpenIdConfigData(): void {
  //   sessionStorage.removeItem(UserInfoStorage.ClientIdKey);
  //   sessionStorage.removeItem(UserInfoStorage.OpenIdLoginUriKey);
  //   sessionStorage.removeItem(UserInfoStorage.OpenIdRedirectUrlKey);
  //   sessionStorage.removeItem(UserInfoStorage.UserNameKey);

  // }

  // private cleanActiveSessionData(): void {
  //   sessionStorage.removeItem(SessionCookieStorage.ActiveState);
  //   sessionStorage.removeItem(SessionCookieStorage.ShowModal);
  //   sessionStorage.removeItem(SessionCookieStorage.RefreshTab);
  //   if (this.cookieService.check(SessionCookieStorage.TabCounter)) {
  //     this.cookieService.delete(SessionCookieStorage.TabCounter, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(SessionCookieStorage.TabCounterActive)) {
  //     this.cookieService.delete(SessionCookieStorage.TabCounterActive, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(SessionCookieStorage.ShowModal)) {
  //     this.cookieService.delete(SessionCookieStorage.ShowModal, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(SessionCookieStorage.PathName)) {
  //     this.cookieService.delete(SessionCookieStorage.PathName, this.pathName, this.domainName);
  //   }
  //   if (this.cookieService.check(SessionCookieStorage.DomainName)) {
  //     this.cookieService.delete(SessionCookieStorage.DomainName, this.pathName, this.domainName);
  //   }
  // }

  // private cleanMuteData(): void {
  //   sessionStorage.removeItem(SessionCookieStorage.Mute);
  //   if (this.cookieService.check(SessionCookieStorage.Mute)) {
  //     this.cookieService.delete(SessionCookieStorage.Mute, this.pathName, this.domainName);
  //   }
  // }

  private notifyNewUserLoggedIn(): void {
    this._userTokenEvent.next(this.userToken);
    this._userInactivityEvent.next(this.userInactivity);
    this._userNameEvent.next(this.userName);
    this._userProfileEvent.next(this.userProfile);
    this._userDescriptorEvent.next(this.userDescriptor);
    if (this._userToken !== this.cookieService.get(UserInfoStorage.UserTokenKey)) {
      this._restoreAuthentication.next(false);
    } else {
      this._restoreAuthentication.next(true);
      this.sub?.unsubscribe();
      sessionStorage.setItem(SessionCookieStorage.TabLogged, SessionCookieStorage.False);
    }
    this.loginEvent.next(true);
  }

  private reloadLandingPage(value: number): void {

    if (this.cookieService.check(UserInfoStorage.UserTokenKey) &&
    sessionStorage.getItem(SessionCookieStorage.TabLogged) !== SessionCookieStorage.True) {
      sessionStorage.setItem(SessionCookieStorage.TabLogged, SessionCookieStorage.True);
      const pathName: string = this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf('/loginpage'))) === '' ?
        '/' :
        this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf('/loginpage')));
      const basUrl: string = (this.platformLocation as any)._location?.origin + pathName;
      window.open(basUrl, '_self', '');
    }
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
interface WsiToken {
  access_token: string;
  expires_in: number;
  token_type: string;
  user_name: string;
  user_descriptor: string;
  user_profile: string;
  flex_user_profile: string;
  user_inactivity_timeout: string;
  personally_used: string;
}
/* eslint-enable @typescript-eslint/naming-convention */
