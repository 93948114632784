import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiSliderComponent } from '@simpl/element-ng';
import { BlindValue } from '@simpl/element-value-types';

import { SiNumberValidatorDirective } from '../../directives/number-validator.directive';
import { resetValueBlind } from '../../helpers/property';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

let instanceId = 0;

@Component({
  selector: 'si-blind-property',
  templateUrl: './si-blind-property.component.html',
  styleUrls: ['./si-blind-property.component.scss'],
  standalone: true,
  imports: [FormsModule, SiNumberValidatorDirective, SiPropertyPopoverComponent, SiSliderComponent]
})
export class SiBlindPropertyComponent {
  @Input({ required: true }) property!: Property<BlindValue>;
  @Input() valueState: ValueState = 'none';
  @Input() allowValuesOutOfRange = false;
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<BlindValue>>();

  @ViewChild('heightInput') heightInput?: ElementRef;

  readonly heightInputId = `si-blind-property-height-${instanceId++}`;
  readonly angleInputId = `si-blind-property-height-${instanceId++}`;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
        setTimeout(() => this.heightInput?.nativeElement.focus());
        break;
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        resetValueBlind(this.property);
        this.submitted.emit(this.property);
        break;
    }
  }
}
