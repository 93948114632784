<div class="memo-container px-2">

  <!-- Header -->
  <div class="header">
    <div class="middle">
      <span class="si-title-1">{{ headerTitle }}</span>
    </div>
  </div>
  <hr class="my-2"  style="flex: 0 0 auto;">

  <!-- Scrollable section for memo -->
  <div class="d-flex form-group my-2" style="flex: 1 1 auto;">
    <textarea class="form-control h-100 py-2" style="overflow-y: auto;"
      spellcheck="false"
      [readonly]="!vm.isEditActive"
      [(ngModel)]="this.vm.memo"
      [maxlength]="2048">
    </textarea>
  </div>

  <!-- Edit buttons -->
  <div class="my-2 d-flex flex-row"  style="flex: 0 0 auto;">
    <button type="button" *ngIf="!vm.isEditActive" class="btn btn-secondary w-100"
      (click)="onEdit($event)">
      {{ labelEditButton }}
    </button>
    <button type="button" *ngIf="vm.isEditActive" class="btn btn-secondary w-100 me-5"
      (click)="onEditCancel($event)">
      {{ labelEditCancelButton }}
    </button>
    <button type="button" *ngIf="vm.isEditActive" class="btn btn-primary w-100"
      [disabled]="!vm.isMemoDirty || vm.isSavePending"
      (click)="onEditSave($event)">
      {{ labelEditSaveButton }}
    </button>
  </div>

</div>
