import {
  booleanAttribute,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[si-launchpad-item]',
  standalone: true,
  imports: [],
  templateUrl: './si-launchpad-item.component.html',
  styleUrl: './si-launchpad-item.component.scss',
  host: {
    class: 'focus-none'
  }
})
export class SiLaunchpadItemComponent {
  @Input({ transform: booleanAttribute }) external = false;
  @Input({ transform: booleanAttribute }) @HostBinding('class.active') active = false;
  @Input({ transform: booleanAttribute }) enableFavoriteToggle = false;
  @Input({ transform: booleanAttribute }) favorite?: boolean;

  @Output() readonly favoriteChanged = new EventEmitter<boolean>();

  @HostListener('keydown.space', ['$event'])
  protected favoriteClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.favorite = !this.favorite;
    this.favoriteChanged.next(this.favorite);
  }
}
