<audio *ngIf="this.buzzerVisible" #audioControl id="audioControl" [src]="soundToPlay" autoplay></audio>
<div class="gms-summary-bar">
  <si-status-bar *ngIf="buzzerVisible" [allOkText]="this.labels.get('allOk')" [items]="statusItems" [compact]="statusBarState === statusBarStateEnum.Collapsed" [blink]="true"
    [muteButton]="!mute" (muteToggle)="clickMuteButton($event)">
    <button *ngIf="isULResoundVisible" type="button" class="btn btn-tertiary mx-2 my-1" (click)="clickResetSoundButton($event)"><i class="icon element-redo"></i>{{categoryResound[0]}}</button>
  </si-status-bar>

  <si-status-bar *ngIf="!buzzerVisible" [allOkText]="this.labels.get('allOk')" [items]="statusItems" [compact]="statusBarState === statusBarStateEnum.Collapsed" [blink]="true"
    (muteToggle)="clickMuteButton($event)">
    <button *ngIf="isULResoundVisible" type="button" class="btn btn-tertiary mx-2 my-1" (click)="clickResetSoundButton($event)"><i class="icon element-redo"></i>{{categoryResound[0]}}</button>
  </si-status-bar>

	<div class="timer-alert" *ngIf="this.timer !=='' && this.buzzerVisible">
		<div class="alert-textbox">
			<span class="alert-icon element-timer icon me-4"></span>
			<span class="alert-heading"> {{this.timer === 'Timer expired' ? this.labels.get('timer-expired') : this.timer}}: </span>
			<span class="alert-text"> {{this.timer === 'Timer expired' ? this.labels.get('timer-escalated') : this.labels.get('timer-text')}} </span>
			<button type="button" class="alert-button btn btn-link bl-auto" (click)="this.timerAction()"> Go to events </button>
		</div>
	</div>
</div>

<gms-ownership-dialog />

<!-- Event Hub System Configuration -->
