/** class imports */
import { SubscriptionGms, SubscriptionWsiVal } from '@gms-flex/services';

/**
 * VMSDataChange
 *
 * @export
 * @class VMSDataChange
 */
export class VMSDataChange {
  /* eslint-disable @typescript-eslint/naming-convention */
  public SubscriptionKey: number;
  public objectChangedName: string;

  public NameId: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

/**
 * SubscriptionGmsVMSData
 *
 * @export
 * @class SubscriptionGmsVMSData
 * @implements {SubscriptionGms}
 */
export class SubscriptionGmsVMSData implements SubscriptionGms {
  constructor(subWsi: SubscriptionWsiVal) {
    this.subWsi = subWsi;
  }

  // public fields
  public readonly key: number;
  public readonly errorCode: number;
  public readonly propertyId: string;
  public readonly originalId: string;

  public readonly subscriptionKey: number;
  public readonly objectChangedName: string;

  // private fields
  private readonly subWsi: SubscriptionWsiVal;
}
