import { NgZone, TemplateRef } from '@angular/core';
import { BrowserObject, ExecuteCommandServiceBase } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { SiToastNotificationService } from '@simpl/element-ng';
import { Observable, Observer, Subject, Subscription } from 'rxjs';

import { ValidationDialogService } from '../../../validation-dialog/services/validation-dialog.service';
import { AlarmsContainer } from '../common/interfaces/AlarmsContainer';
import { SelectableGraphicElement } from '../common/interfaces/selectableGraphicElement';
import { TraceChannel } from '../common/trace-channel';
import { GmsButtons } from '../elements/gms-buttons';
import { GmsGraphic } from '../elements/gms-graphic';
import {
  CommandViewModel,
  GmsCommandResult,
  PropertyChangedEventArgs
} from '../processor/command-view-model/gms-command-vm';
import { ControlEditorMode } from '../processor/command-view-model/gms-parameter-vm.base';
import { GmsCommand } from '../processor/command/gms-command';
import {
  CommandStatusChangeArgs,
  CommandValidationStatus
} from '../processor/command/gms-command-status';
import { AlarmInfo } from '../processor/datapoint/gms-alarm-info';
import { Datapoint, DataPointPropertyChangeArgs } from '../processor/datapoint/gms-datapoint';
import { GraphicsDatapointHelper } from '../processor/datapoint/gms-datapoint-helper';
import { Evaluation, PropertyChangeType, PropertyType } from '../processor/evaluation';
import { Expression } from '../processor/expression';
import { InstanceProperty } from '../processor/gms-symbol-instance-property';
import { Substitution, SubstitutionSource } from '../processor/substitution';
import { GmsAnimationTimerService } from '../services/gms-animation-timer.service';
import { ElementBrushService } from '../services/gms-brush.service';
import { GmsCommandService } from '../services/gms-command-service';
import { DataPointService } from '../services/gms-datapoint2.service';
import { GmsObjectSelectionService } from '../services/gms-object-selection.service';
import { TextGroupService } from '../services/gms-text-group-service';
import { TimerService } from '../services/timer-service';
import { AlarmState } from '../types/datapoint/gms-alarm-state';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { GmsCommandTriggerTypes } from '../types/gms-command-trigger-types';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType, GraphicType } from '../types/gms-element-property-types';
import {
  GmsElementCursorType,
  GmsElementDisabledStyle,
  GmsElementReplicationOrientationType,
  GmsElementType,
  PositionType
} from '../types/gms-element-types';
import { Color, ColorWrap, Filter, Point } from '../utilities/color-utility';
import { Constants } from '../utilities/constants';
import { FormatHelper } from '../utilities/format-helper';
import { Guid } from '../utilities/guid';
import { MathUtils } from '../utilities/mathUtils';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsAlarm } from './gms-alarm';
import { GmsCommandControl } from './gms-commandcontrol';
import { GmsGroup } from './gms-group';

export class GmsElement implements SelectableGraphicElement {

  private static readonly MIN_WIDTH_HEIGHT: number = 0;
  private static readonly MAX_WIDTH_HEIGHT: number = 4000;
  private static readonly DEFAULT_SCALEFACTOR: number = 1;
  private static readonly DEFAULT_OPACITY: number = 1;
  protected static DEFAULT_STROKE = '#000000';

  public children: GmsElement[] = undefined;
  public propertyChanged: Subject<string> = new Subject<string>();
  public selectionChanged: Subject<boolean> = new Subject<boolean>();
  public shapeChanged: Subject<void> = new Subject<void>();
  public subExecuteStatus: Subscription;

  protected traceModule: string;
  protected traceModuleCommand: string;

  private _evaluationX: Evaluation;
  private _evaluationY: Evaluation;
  private _evaluationPositionType: Evaluation;
  private _evaluationWidth: Evaluation;
  private _evaluationHeight: Evaluation;
  private _evaluationAngle: Evaluation;
  private _evaluationAngleCenterX: Evaluation;
  private _evaluationAngleCenterY: Evaluation;
  private _evaluationFlipX: Evaluation;
  private _evaluationFlipY: Evaluation;
  private _evaluationVisible: Evaluation;
  private _evaluationBackground: Evaluation;
  private _evaluationStroke: Evaluation;
  private _evaluationFill: Evaluation;
  private _evaluationCursorType: Evaluation;
  private _evaluationSelectionRef: Evaluation;
  private _evaluationLinkReference: Evaluation;
  private _evaluationDisableSelection: Evaluation;
  private _evaluationCoverageAreaReference: Evaluation;
  private _evaluationTooltip: Evaluation;
  private _evaluationClipLeft: Evaluation;
  private _evaluationClipTop: Evaluation;
  private _evaluationClipRight: Evaluation;
  private _evaluationClipBottom: Evaluation;
  private _evaluationBlinking: Evaluation;
  private _evaluationOpacity: Evaluation;
  private _evaluationCommandDisabled: Evaluation;
  private _evaluationCommandTriggerEnabled: Evaluation;
  private _evaluationCommandDisabledStyle: Evaluation;
  private _evaluationScaleFactor: Evaluation;
  private _evaluationRotationSpeed: Evaluation;
  private _evaluationRotationSteps: Evaluation;
  private _evaluationReplicationIndexRange: Evaluation;
  private _evaluationReplicationOrientation: Evaluation;
  private _evaluationReplicationMaxExtent: Evaluation;
  private _evaluationReplicationSpace: Evaluation;
  private readonly _evaluationCommandTrigger: Evaluation;
  private _evaluationGrayscale: Evaluation;
  private _evaluationShadowDepth: Evaluation;
  private _evaluationShadowColor: Evaluation;
  private _evaluationShadowDirection: Evaluation;

  private _designValueX = 0;
  private _designValueY = 0;
  private _designValueAngle = 0;
  private _designValueAngleCenterX: string = undefined;
  private _designValueAngleCenterY: string = undefined;
  private _designValuePositionType: PositionType = PositionType.Relative;
  private _designValueVisible = true;
  private _designValueBlinking = false;
  private _designValueScaleFactor: number = GmsElement.DEFAULT_SCALEFACTOR;
  private _designValueFlipX = false;
  private _designValueFlipY = false;
  private _designValueRotationSpeed = '';
  private _designValueRotationSteps = 0;
  private _designValueStroke = '#000000';
  private _designValueFill = 'transparent';
  private _designValueShadowColor: string = undefined;
  private _designValueShadowDirection: number = undefined;
  private _designValueShadowDepth: number = undefined;
  private _designValueFilter: string = undefined;
  private _designValueBackground = 'transparent';
  private _designValueStrokeWrap: ColorWrap = undefined;
  private _designValueFillWrap: ColorWrap = undefined;
  private _designValueBackgroundWrap: ColorWrap = undefined;
  private _designValueLinkReference = '';

  private _designValueReplicationIndexRange = '';
  private _designValueReplicationMaxExtent = '300%';
  private _designValueReplicationSpace = '10';
  private _designValueReplicationOrientation: GmsElementReplicationOrientationType = GmsElementReplicationOrientationType.Vertical;

  private _evaluationStrokeWrap: ColorWrap = new ColorWrap('#000000');
  private _evaluationFillWrap: ColorWrap = new ColorWrap('transparent');
  private _evaluationBackgroundWrap: ColorWrap = new ColorWrap('transparent');
  private readonly _colorWraps: ColorWrap[] = [];
  private readonly _evaluationColorWraps: ColorWrap[] = [];
  private readonly _activeBrushUrls: string[] = [];

  private _designValueClipLeft: string = undefined;
  private _designValueClipTop: string = undefined;
  private _designValueClipRight: string = undefined;
  private _designValueClipBottom: string = undefined;
  private _designValueSelectionRef = '';

  private _rotationDeltaAngle = 0;
  private _rotationInterval = 0;
  private readonly _rotationMaxFps: number = 30; // >0 !!
  private readonly _rotationMinDeltaAngle: number = 0.5; // >0 !!
  private _evaluationCommandRule: Evaluation;
  private _evaluationCommandParameter: Evaluation;

  private _commandVMpropertyChangedSub: Subscription;
  private _commandPropertyChangedSub: Subscription;
  private _commandDatapointResolvedSub: Subscription;
  private _targetNavigationDatapointSub: Subscription;

  private _linkReferenceDpSubscription: Subscription;
  protected linkreferenceDp: Datapoint;

  private _selectionReferenceDpSubscription: Subscription;
  protected selectionrefDp: Datapoint;

  private _parent: GmsElement;
  public get Parent(): GmsElement {
    return this._parent;
  }
  public set Parent(value: GmsElement) {
    // remove this element from old parent
    if (this._parent !== undefined) {
      this._parent.RemoveChild(this);
    }

    // assign new parent and add this element to the parent children
    this._parent = value;
    if (this._parent !== undefined) {
      this._parent.AddChild(this);
      this.IsParentVisible = this._parent.Visible;
    }
  }

  private _id: string;
  public get Id(): string {
    if (this._id === undefined) {
      this._id = Guid.newGuid();
    }
    return this._id;
  }
  public set Id(value: string) {

    if (this._id !== value) {
      this._id = value;

      this.NotifyPropertyChanged('Id');
    }
  }
  /**
   * An element unique Id utilized for symbol instances element customization
   */
  private _internalId: string = null;
  public get InternalId(): string {
    return this._internalId;
  }
  public set InternalId(value: string) {
    this._internalId = value;
  }

  private _coverageAreaReference: string;
  public get CoverageAreaReference(): string {
    return this._coverageAreaReference;
  }
  public set CoverageAreaReference(value: string) {

    if (this._coverageAreaReference !== value) {
      this._coverageAreaReference = value;
    }
  }

  private _commandViewModel: CommandViewModel = null;
  public get CommandVM(): CommandViewModel {
    return this._commandViewModel;
  }
  public set CommandVM(value: CommandViewModel) {
    if (this._commandVMpropertyChangedSub !== undefined && !this._commandVMpropertyChangedSub.closed) {
      this._commandVMpropertyChangedSub.unsubscribe();
      this._commandVMpropertyChangedSub = undefined;
    }
    if (this._commandViewModel !== null && value === null) {
      this._commandViewModel.Destroy();
      this._commandViewModel = null;
    } else if (this._commandViewModel !== value) {
      this._commandViewModel = value;
      if (this._commandViewModel !== null) {
        if (!this._commandViewModel.IsInDesignMode) {
          this._commandVMpropertyChangedSub = this._commandViewModel.propertyChanged.subscribe(args => this.CommandViewModel_PropertyChanged(args));
          this.UpdateCommandControl();
          this.UpdateErrorBorder();
        }
        // grouped elements need to be updated
        this.UpdateChildrenCommandVM();
      }
      this.UpdateIsSelectable();
      this.UpdateIsHitTestVisible();

      this.UpdateOpacity();

      if (this.GetAnimatedIsCommandTriggerEnabled()) {
        this.CursorType = GmsElementCursorType.Hand;
      }
    }
  }

  // GmsSlider thumb's clone
  private _isSlidingClone = false;
  public get IsSlidingClone(): boolean {
    return this._isSlidingClone;
  }
  public set IsSlidingClone(value: boolean) {
    if (this._isSlidingClone !== value) {
      this._isSlidingClone = value;
      if (value === true) {
        this.Visible = false;
      }
    }
  }

  /** A temp solution to hide a command element
   */
  private _commandRelated = false;
  public get CommandRelated(): boolean {
    return this._commandRelated;
  }
  public set CommandRelated(value: boolean) {

    if (this._commandRelated !== value) {
      this._commandRelated = value;
      this.UpdateIsTargetNavigable();
    }
  }
  /**
   * Element is "CoverageArea", aka "CA"
   */
  private _coverageArea = false;
  public get CoverageArea(): boolean {
    return this._coverageArea;
  }
  public set CoverageArea(value: boolean) {
    this._coverageArea = value;
  }

  private _showErrorBorder = false;
  public get ShowErrorBorder(): boolean {
    return this._showErrorBorder;
  }
  public set ShowErrorBorder(value: boolean) {
    if (this._showErrorBorder !== value) {
      this._showErrorBorder = value;
      this.NotifyPropertyChanged('ShowErrorBorder');
    }
  }

  private _description = '';
  public get Description(): string {
    return this._description;
  }
  public set Description(value: string) {

    if (this._description !== value) {
      this._description = value;

      this.NotifyPropertyChanged('Description');
    }
  }

  private _evaluationStatus: DatapointStatus = DatapointStatus.Valid;
  public get EvaluationStatus(): DatapointStatus {
    return this._evaluationStatus;
  }
  public set EvaluationStatus(value: DatapointStatus) {
    if (this._evaluationStatus !== value) {
      this._evaluationStatus = value;
    }
  }

  public get EvaluationTooltip(): Evaluation {
    return this._evaluationTooltip;
  }

  public set EvaluationTooltip(evaluation: Evaluation) {
    if (this._evaluationTooltip !== evaluation) {
      this._evaluationTooltip = evaluation;
    }
  }

  private _positionType: PositionType = PositionType.Relative;
  public get PositionType(): PositionType {
    return this._positionType;
  }
  public set PositionType(value: PositionType) {
    if (this._positionType !== value) {
      this._positionType = value;
      this.NotifyPropertyChanged('PositionType');
    }
  }

  private _X = 0;
  public get X(): number {
    return this._X;
  }
  public set X(value: number) {
    if (this._X !== value) {
      this._X = value;
      this.NotifyPropertyChanged('X');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  private _Y = 0;
  public get Y(): number {
    return this._Y;
  }
  public set Y(value: number) {
    if (this._Y !== value) {
      this._Y = value;
      this.NotifyPropertyChanged('Y');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  private _designValueWidth = 0;
  public get DesignValueWidth(): number {
    return this._designValueWidth;
  }
  public set DesignValueWidth(value: number) {
    this._designValueWidth = value;
  }
  private _Width = 0;
  public get Width(): number {
    return this._Width;
  }
  public set Width(value: number) {
    if (this._Width !== value) {
      this._Width = value;
      this.NotifyPropertyChanged('Width');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }
  private _designValueHeight = 0;
  public get DesignValueHeight(): number {
    return this._designValueHeight;
  }
  public set DesignValueHeight(value: number) {
    this._designValueHeight = value;
  }
  private _Height = 0;
  public get Height(): number {
    return this._Height;
  }
  public set Height(value: number) {
    if (this._Height !== value) {
      this._Height = value;
      this.NotifyPropertyChanged('Height');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  // adorner box dimensions
  private _adornerWidth = 0;
  public set AdornerWidth(value: number) {
    this._adornerWidth = value;
  }
  public get AdornerWidth(): number {
    return this._adornerWidth;
  }

  private _adornerHeight = 0;
  public set AdornerHeight(value: number) {
    this._adornerHeight = value;
  }
  public get AdornerHeight(): number {
    return this._adornerHeight;
  }

  private _adornerX = 0;
  public set AdornerX(value: number) {
    this._adornerX = value;
  }
  public get AdornerX(): number {
    return this._adornerX;
  }

  private _adornerY = 0;
  public set AdornerY(value: number) {
    this._adornerY = value;
  }
  public get AdornerY(): number {
    return this._adornerY;
  }

  // Has the deserialized X, Y, Width, Height all the time
  private _boundingRectDesign: BoundingRectangle = null;
  public get BoundingRectDesign(): BoundingRectangle {
    return this._boundingRectDesign;
  }
  public set BoundingRectDesign(value: BoundingRectangle) {
    if (this._boundingRectDesign !== value) {
      this._boundingRectDesign = value;
    }
  }

  private _scaleFactor: number = GmsElement.DEFAULT_SCALEFACTOR;
  public get ScaleFactor(): number {
    return this._scaleFactor;
  }
  public set ScaleFactor(value: number) {

    if (this._scaleFactor !== value) {
      // Coerce the value
      if (Number.isNaN(value) || !Number.isFinite(value)) {
        value = GmsElement.DEFAULT_SCALEFACTOR;
      }
      this._scaleFactor = value;
      this.ScaleX = value;
      this.ScaleY = value;
    }
  }

  // Resize factor from the parent for width/x
  private _resizeFactorWidth = 1;
  public get ResizeFactorWidth(): number {
    return this._resizeFactorWidth;
  }
  public set ResizeFactorWidth(value: number) {
    if (this._resizeFactorWidth !== value) {
      this._resizeFactorWidth = value;
    }
  }

  // Resize factor from the parent for height/y
  private _resizeFactorHeight = 1;
  public get ResizeFactorHeight(): number {
    return this._resizeFactorHeight;
  }
  public set ResizeFactorHeight(value: number) {
    if (this._resizeFactorHeight !== value) {
      this._resizeFactorHeight = value;
    }
  }

  private _scaleX: number = GmsElement.DEFAULT_SCALEFACTOR;
  public get ScaleX(): number {
    return this._scaleX;
  }

  public set ScaleX(value: number) {
    if (this._scaleX !== value) {
      this._scaleX = value;
      this.NotifyPropertyChanged('ScaleX');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  private _scaleY: number = GmsElement.DEFAULT_SCALEFACTOR;
  public get ScaleY(): number {
    return this._scaleY;
  }

  public set ScaleY(value: number) {
    if (this._scaleY !== value) {
      this._scaleY = value;
      this.NotifyPropertyChanged('ScaleY');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  // #region Angle Property
  private _angle = 0;
  public get Angle(): number {
    return this._angle;
  }
  public set Angle(value: number) {

    if (this._angle !== value) {
      this._angle = value;

      this.NotifyPropertyChanged('Angle');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  // #region Angle Property
  private _angleCenterX: number = undefined;
  public get AngleCenterX(): number {
    return this._angleCenterX;
  }
  public set AngleCenterX(value: number) {

    if (this._angleCenterX !== value) {
      this._angleCenterX = value;

      this.NotifyPropertyChanged('AngleCenterX');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }
  // #endregion

  // #region Angle Property
  private _angleCenterY: number = undefined;
  public get AngleCenterY(): number {
    return this._angleCenterY;
  }
  public set AngleCenterY(value: number) {

    if (this._angleCenterY !== value) {
      this._angleCenterY = value;

      this.NotifyPropertyChanged('AngleCenterY');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  // #endregion
  private _blinking = false;
  public get Blinking(): boolean {
    return this._blinking;
  }
  public set Blinking(value: boolean) {

    if (this._blinking !== value) {
      this._blinking = value;
      // update Brush Service
      if (value === true) {
        if (!!this.ElementBrushService) {
          this.ElementBrushService.addElement(this);
        }
      } else {
        if (!!this.ElementBrushService) {
          this.ElementBrushService.removeElement(this);
          this.NotifyPropertyChanged('Visible');
        }
      }
    }
  }

  // #endregion

  // Only the first level children of a symbolinstance will contain values for ChildAnchorX
  private _childAnchorX: number;
  public get ChildAnchorX(): number {
    return this._childAnchorX;
  }
  public set ChildAnchorX(value: number) {

    if (this._childAnchorX !== value) {
      this._childAnchorX = value;

      this.NotifyPropertyChanged('ChildAnchorX');
    }
  }

  // Only the first level children of a symbolinstance will contain values for ChildAnchorY
  private _childAnchorY: number;
  public get ChildAnchorY(): number {
    return this._childAnchorY;
  }
  public set ChildAnchorY(value: number) {

    if (this._childAnchorY !== value) {
      this._childAnchorY = value;

      this.NotifyPropertyChanged('ChildAnchorY');
    }
  }

  private _rotationSpeed = '';
  public get RotationSpeed(): string {
    return this._rotationSpeed;
  }
  public set RotationSpeed(value: string) {

    if (this._rotationSpeed !== value) {
      this._rotationSpeed = value;

      this.NotifyPropertyChanged('RotationSpeed');
    }
  }

  private _rotationSteps = 0;
  public get RotationSteps(): number {
    return this._rotationSteps;
  }
  public set RotationSteps(value: number) {

    if (this._rotationSteps !== value) {
      this._rotationSteps = value;

      this.NotifyPropertyChanged('RotationSteps');
    }
  }

  private _rotationAngle = 0;
  public get RotationAngle(): number {
    return this._rotationAngle;
  }
  public set RotationAngle(value: number) {
    if (this._rotationAngle !== value) {
      this._rotationAngle = value;
    }
    this.NotifyPropertyChanged('RotationAngle');
  }

  private _visible = true;

  public get DesignValueVisible(): boolean {
    return this._designValueVisible as boolean;
  }

  public get Visible(): boolean {
    return this._visible;
  }

  public set Visible(value: boolean) {
    if (this._visible !== value) {
      this._visible = value;

      this.UpdateIsHitTestVisible();
      this.UpdateChildrenVisible();
      this.UpdateIsSelectable();
      this.NotifyPropertyChanged('Visible');
    }
  }

  private _blinkVisible = true;
  public get BlinkVisible(): boolean {
    return this._blinkVisible;
  }
  public set BlinkVisible(value: boolean) {

    if (this._blinkVisible !== value) {
      this._blinkVisible = value;

      this.NotifyPropertyChanged('BlinkVisible');
    }
  }

  // FlipX
  private _flipX = false;
  public get FlipX(): boolean {
    return this._flipX;
  }
  public set FlipX(value: boolean) {

    if (this._flipX !== value) {
      this._flipX = value;

      this.NotifyPropertyChanged('FlipX');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  // FlipX
  private _flipY = false;
  public get FlipY(): boolean {
    return this._flipY;
  }
  public set FlipY(value: boolean) {

    if (this._flipY !== value) {
      this._flipY = value;

      this.NotifyPropertyChanged('FlipY');
      this.NotifyShapeChanged();
      this.ShapeChanged();
    }
  }

  private _cursorType: GmsElementCursorType = GmsElementCursorType.Default;
  public get CursorType(): GmsElementCursorType {
    return this._cursorType;
  }
  public set CursorType(value: GmsElementCursorType) {

    if (this._cursorType !== value) {
      this._cursorType = value;

      this.NotifyPropertyChanged('CursorType');
      this.NotifyPropertyChanged('IsDefaultCursorType');
    }
  }

  private _selectionRef = '';
  public get SelectionRef(): string {
    return this._selectionRef;
  }
  public set SelectionRef(value: string) {

    if (this._selectionRef !== value) {
      this._selectionRef = value;
      this.UpdateIsSelectable();
      this.NotifyPropertyChanged('SelectionRef');
    }
  }

  private _disableSelection = false;
  public get DisableSelection(): boolean {
    return this._disableSelection;
  }
  public set DisableSelection(value: boolean) {
    if (this._disableSelection !== value) {
      this._disableSelection = value;
      this.UpdateIsSelectable();
      this.updateChildrenDisableSelection();
      this.NotifyPropertyChanged('DisableSelection');
    }
  }

  private _isParentDisableSelection = false;
  public get IsParentDisableSelection(): boolean {
    return !!this._isParentDisableSelection;
  }
  public set IsParentDisableSelection(value: boolean) {
    if (value !== this._isParentDisableSelection) {
      this._isParentDisableSelection = value;
      this.UpdateDisableSelection();
    }
  }

  private _isParentVisible = true;
  public get IsParentVisible(): boolean {
    return !!this._isParentVisible;
  }
  public set IsParentVisible(value: boolean) {
    if (value !== this._isParentVisible) {
      this._isParentVisible = value;
      this.UpdateVisible();
    }
  }

  private _zoomable = true;
  public get Zoomable(): boolean {
    return this._zoomable;
  }
  public set Zoomable(value: boolean) {

    if (this._zoomable !== value) {
      this._zoomable = value;

      if (this._zoomable === false) {
        const graphic: GmsGraphic = this.Graphic as GmsGraphic;
        if (graphic !== undefined) {
          graphic.AddNotZoomableElement(this);
        }
      }

      if (this._zoomable === true) {
        const graphic: GmsGraphic = this.Graphic as GmsGraphic;
        if (graphic !== undefined) {
          graphic.RemoveNotZoomableElement(this);
        }
      }

      this.NotifyPropertyChanged('Zoomable');
    }
  }

  private _minVisibility: number;
  public get MinVisibility(): number {
    return this._minVisibility;
  }
  public set MinVisibility(value: number) {

    if (this._minVisibility !== value) {
      this._minVisibility = value;

      this.NotifyPropertyChanged('MinVisibility');
    }
  }

  private _maxVisibility: number;
  public get MaxVisibility(): number {
    return this._maxVisibility;
  }
  public set MaxVisibility(value: number) {

    if (this._maxVisibility !== value) {
      this._maxVisibility = value;

      this.NotifyPropertyChanged('MaxVisibility');
    }
  }

  private _minMaxVisibility = true;
  public set MinMaxVisibility(value: boolean) {
    if (this._minMaxVisibility !== value) {
      this._minMaxVisibility = value;
      this.UpdateVisible();
      this.NotifyPropertyChanged('MinMaxVisibility');
    }
  }

  public get MinMaxVisibility(): boolean {
    return this._minMaxVisibility;
  }

  public get hasChildren(): boolean {
    return this.children !== undefined;
  }

  private _commandRule = '';
  public get CommandRule(): string {
    return this._commandRule;
  }
  public set CommandRule(value: string) {

    if (this._commandRule !== value) {
      this._commandRule = value;
      this.UpdateIsTargetNavigable();
      this.NotifyPropertyChanged('CommandRule');
    }
  }

  private _commandParameter = '';
  public get CommandParameter(): string {
    return this._commandParameter;
  }
  public set CommandParameter(value: string) {

    if (this._commandParameter !== value) {
      this._commandParameter = value;

      this.NotifyPropertyChanged('CommandParameter');
    }
  }

  private _linkReference = '';
  public get LinkReference(): string {
    return this._linkReference;
  }
  public set LinkReference(value: string) {

    if (this._linkReference !== value) {
      this._linkReference = value;
      this.UpdateIsTargetNavigable();
      this.NotifyPropertyChanged('LinkReference');
    }
  }

  private _linkDescription = '';
  public get LinkDescription(): string {
    return this._linkDescription;
  }
  public set LinkDescription(value: string) {

    if (this._linkDescription !== value) {
      this._linkDescription = value;

      this.NotifyPropertyChanged('LinkDescription');
    }
  }

  private _isCommandTriggerEnabled = false;
  public get IsCommandTriggerEnabled(): boolean {
    return this._isCommandTriggerEnabled;
  }
  public set IsCommandTriggerEnabled(value: boolean) {

    if (this._isCommandTriggerEnabled !== value) {
      this._isCommandTriggerEnabled = value;
      this.UpdateCommandCursor();
      this.NotifyPropertyChanged('IsCommandTriggerEnabled');
    }
  }
  private _commandTrigger: GmsCommandTriggerTypes = GmsCommandTriggerTypes.SingleClick;
  public get CommandTrigger(): GmsCommandTriggerTypes {
    return this._commandTrigger;
  }
  public set CommandTrigger(value: GmsCommandTriggerTypes) {

    if (this._commandTrigger !== value) {
      this._commandTrigger = value;

      this.NotifyPropertyChanged('CommandTrigger');
    }
  }
  private _commandDisabled = false;
  public get CommandDisabled(): boolean {
    return this._commandDisabled;
  }
  public set CommandDisabled(value: boolean) {

    if (this._commandDisabled !== value) {
      this._commandDisabled = value;

      this.UpdateOpacity();
      this.UpdateVisible();
      this.UpdateIsTargetNavigable();
      this.UpdateIsHitTestVisible();
      this.UpdateCommandCursor();
    }
  }

  public get IsDefaultCursorType(): boolean {
    if (this.CommandVM == null) {
      return this.GetAnimatedCommandDisabled() || this.GetAnimatedCursorType() === GmsElementCursorType.Default;
    }

    return !this.isCommandCursor();
  }

  private _shadowDepth = 0;
  public get ShadowDepth(): number {
    return this._shadowDepth;
  }
  public set ShadowDepth(value: number) {

    if (this._shadowDepth !== value) {
      this._shadowDepth = value;

      this.NotifyPropertyChanged('ShadowDepth');
    }
  }

  private _shadowColor = 'black';
  public get ShadowColor(): string {
    return this._shadowColor;
  }
  public set ShadowColor(value: string) {

    if (this._shadowColor !== value) {
      this._shadowColor = value;

      this.NotifyPropertyChanged('ShadowColor');
    }
  }

  private _shadowDirection = 135;
  public get ShadowDirection(): number {
    return this._shadowDirection;
  }
  public set ShadowDirection(value: number) {

    if (this._shadowDirection !== value) {
      this._shadowDirection = value;

      this.NotifyPropertyChanged('ShadowDirection');
    }
  }

  private _replication: any = undefined;
  public get Replication(): any {
    return this._replication;
  }

  private _isReplicationClone = false;
  public get IsReplicationClone(): boolean {
    return this._isReplicationClone;
  }
  public set IsReplicationClone(value: boolean) {

    if (this._isReplicationClone !== value) {
      this._isReplicationClone = value;
    }
  }

  private _fixedAlarmSize = false;
  public get FixedAlarmSize(): boolean {
    return this._fixedAlarmSize;
  }
  public set FixedAlarmSize(value: boolean) {
    if (this._fixedAlarmSize !== value) {
      this._fixedAlarmSize = value;
    }
  }

  private _replicationIndexRange = '';
  public get ReplicationIndexRange(): string {
    return this._replicationIndexRange;
  }
  public set ReplicationIndexRange(value: string) {

    if (this._replicationIndexRange !== value) {
      this._replicationIndexRange = value;

      this.NotifyPropertyChanged('ReplicationIndexRange');
    }
  }

  private _replicationOrientation: GmsElementReplicationOrientationType = GmsElementReplicationOrientationType.Vertical;
  public get ReplicationOrientation(): GmsElementReplicationOrientationType {
    return this._replicationOrientation;
  }
  public set ReplicationOrientation(value: GmsElementReplicationOrientationType) {

    if (this._replicationOrientation !== value) {
      this._replicationOrientation = value;

      this.NotifyPropertyChanged('ReplicationOrientation');
    }
  }

  private _maxReplicationExtent = '300%';
  public get MaxReplicationExtent(): string {
    return this._maxReplicationExtent;
  }
  public set MaxReplicationExtent(value: string) {

    if (this._maxReplicationExtent !== value) {
      this._maxReplicationExtent = value;

      this.NotifyPropertyChanged('MaxReplicationExtent');
    }
  }

  private _replicationSpace = '10';
  public get ReplicationSpace(): string {
    return this._replicationSpace;
  }
  public set ReplicationSpace(value: string) {

    if (this._replicationSpace !== value) {
      this._replicationSpace = value;

      this.NotifyPropertyChanged('ReplicationSpace');
    }
  }

  private _commandDisabledStyle: GmsElementDisabledStyle = GmsElementDisabledStyle.Grayed;
  public get CommandDisabledStyle(): GmsElementDisabledStyle {
    return this._commandDisabledStyle;
  }
  public set CommandDisabledStyle(value: GmsElementDisabledStyle) {

    if (this._commandDisabledStyle !== value) {
      this._commandDisabledStyle = value;

      this.NotifyPropertyChanged('CommandDisabledStyle');
    }
  }

  private _locked = false;
  public get Locked(): boolean {
    return this._locked;
  }
  public set Locked(value: boolean) {

    if (this._locked !== value) {
      this._locked = value;

      this.NotifyPropertyChanged('Locked');
    }
  }

  private _designOpacity: number = GmsElement.DEFAULT_OPACITY;

  private _opacity: number = GmsElement.DEFAULT_OPACITY;
  public set Opacity(value: number) {

    if (this._opacity !== value) {
      this._opacity = value;
      this.NotifyPropertyChanged('Opacity');
    }
  }
  public get Opacity(): number {
    return this._opacity;
  }

  // #region Fill property
  private _fill: string = Constants.transparentColor;
  public get Fill(): string {
    return this._fill;
  }
  public set Fill(value: string) {
    if (this._fill !== value) {
      this._fill = value;
      this.NotifyPropertyChanged('Fill');
    }
  }
  // #endregion

  private _fillOpacity: number = GmsElement.DEFAULT_OPACITY;
  public get FillOpacity(): number {
    return this._fillOpacity;
  }
  public set FillOpacity(value: number) {

    if (this._fillOpacity !== value) {
      this._fillOpacity = value;

      this.NotifyPropertyChanged('FillOpacity');
    }
  }

  private _filter: Filter = undefined;
  public get Filter(): Filter {
    return this._filter;
  }

  public set Filter(value: Filter) {
    if (this._filter !== value) {
      this._filter = value;

      this.NotifyPropertyChanged('Filter');
    }
  }

  private _stroke: string = GmsElement.DEFAULT_STROKE;
  public get Stroke(): string {
    return this._stroke;
  }
  public set Stroke(value: string) {
    if (this._stroke !== value) {
      this._stroke = value;
      this.NotifyPropertyChanged('Stroke');
    }
  }

  public get HasStroke(): boolean {
    return this.Stroke !== undefined && this.Stroke.trim() !== GmsElement.DEFAULT_STROKE;
  }

  private _strokeOpacity: number = GmsElement.DEFAULT_OPACITY;
  public get StrokeOpacity(): number {
    return this._strokeOpacity;
  }
  public set StrokeOpacity(value: number) {

    if (this._strokeOpacity !== value) {
      this._strokeOpacity = value;

      this.NotifyPropertyChanged('StrokeOpacity');
    }
  }

  private _strokeWidth = 1;
  public get StrokeWidth(): number {
    return this._strokeWidth;
  }

  public set StrokeWidth(value: number) {

    if (this._strokeWidth !== value) {
      this._strokeWidth = value;

      this.NotifyPropertyChanged('StrokeWidth');
    }
  }

  private _stokeDashArray = '';
  public get StrokeDashArray(): string {
    return this._stokeDashArray;
  }
  public set StrokeDashArray(value: string) {

    if (this._stokeDashArray !== value) {
      this._stokeDashArray = value;

      this.NotifyPropertyChanged('StrokeDashArray');
    }
  }

  private _strokeLineCap = 'butt';
  public get StrokeLineCap(): string {
    return this._strokeLineCap;
  }

  public set StrokeLineCap(value: string) {
    if (this._strokeLineCap !== value) {
      this._strokeLineCap = value;

      this.NotifyPropertyChanged('StrokeLineCap');
    }
  }

  private _strokeLineJoin = 'miter';
  public get StrokeLineJoin(): string {
    return this._strokeLineJoin;
  }

  public set StrokeLineJoin(value: string) {
    if (this._strokeLineJoin !== value) {
      this._strokeLineJoin = value;

      this.NotifyPropertyChanged('StrokeLineJoin');
    }
  }

  private _startArrow: string = undefined;
  public get StartArrow(): string {
    return this._startArrow;
  }

  public set StartArrow(value: string) {
    if (this._startArrow !== value) {
      this._startArrow = value;
      this.NotifyPropertyChanged('StartArrow');
    }
  }

  private _endArrow: string = undefined;
  public get EndArrow(): string {
    return this._endArrow;
  }

  public set EndArrow(value: string) {
    if (this._endArrow !== value) {
      this._endArrow = value;
      this.NotifyPropertyChanged('EndArrow');
    }
  }

  public get HasArrows(): boolean {
    return this._startArrow !== undefined || this._endArrow !== undefined;
  }

  private _IsSelected = false;
  public get IsSelected(): boolean {
    return this._IsSelected;
  }
  public set IsSelected(value: boolean) {
    if (this._IsSelected !== value) {
      this._IsSelected = value;
      this.NotifySelectionChanged(value);
    }
  }

  // Transient state indication to differentiate
  // between a auto selection and manual selection
  private _autoSelectionInProgress = false;
  public get AutoSelectionInProgress(): boolean {
    return this._autoSelectionInProgress;
  }

  private _background = 'transparent';
  public get Background(): string {
    return this._background;
  }

  public set Background(value: string) {
    if (this._background !== value) {
      this._background = value;
      this.NotifyPropertyChanged('Background');
    }
  }

  private _backgroundOpacity = 1;
  public get BackgroundOpacity(): number {
    return this._backgroundOpacity;
  }
  public set BackgroundOpacity(value: number) {
    if (this._backgroundOpacity !== value) {
      this._backgroundOpacity = value;

      this.NotifyPropertyChanged('BackgroundOpacity');
    }
  }

  private _designValueTooltip: string = undefined;
  public get DesignValueTooltip(): string {
    return this._designValueTooltip;
  }

  private _tooltip: string = undefined;
  public get Tooltip(): string {
    return this._tooltip;
  }

  public set Tooltip(value: string) {
    if (this._tooltip !== value) {
      this._tooltip = value;
      this.NotifyPropertyChanged('ToolTip');
    }
  }

  private _clipLeft: string = undefined;
  public get ClipLeft(): string {
    return this._clipLeft;
  }

  public set ClipLeft(value: string) {

    if (this._clipLeft !== value) {
      this._clipLeft = value;

      this.NotifyPropertyChanged('ClipLeft');
    }
  }

  private _clipTop: string = undefined;
  public get ClipTop(): string {
    return this._clipTop;
  }

  public set ClipTop(value: string) {

    if (this._clipTop !== value) {
      this._clipTop = value;

      this.NotifyPropertyChanged('ClipTop');
    }
  }

  private _clipRight: string = undefined;
  public get ClipRight(): string {
    return this._clipRight;
  }
  public set ClipRight(value: string) {

    if (this._clipRight !== value) {
      this._clipRight = value;

      this.NotifyPropertyChanged('ClipRight');
    }
  }

  private _clipBottom: string = undefined;
  public get ClipBottom(): string {
    return this._clipBottom;
  }
  public set ClipBottom(value: string) {

    if (this._clipBottom !== value) {
      this._clipBottom = value;

      this.NotifyPropertyChanged('ClipBottom');
    }
  }

  private type: GmsElementType;
  public get Type(): GmsElementType {
    return this.type;
  }

  // Original Type preserved when the element has replication
  private _originalType: GmsElementType;
  public get OriginalType(): GmsElementType {
    return this._originalType;
  }

  private _clipPathId: Guid = null;
  public get ClipPathId(): Guid {
    if (this.HasClipInformation) {
      if (this._clipPathId === null) {
        this._clipPathId = Guid.newGuid();
      }

      return this._clipPathId;
    }
    return null;
  }

  private _evaluations: Map<string, Evaluation> = new Map<string, Evaluation>();
  public get Evaluations(): Map<string, Evaluation> {
    return this._evaluations;
  }
  public set Evaluations(value: Map<string, Evaluation>) {
    if (this._evaluations !== value) {
      this._evaluations = value;
    }
  }

  private _graphic: GmsElement = undefined;
  public get Graphic(): GmsElement {
    return this._graphic;
  }
  public set Graphic(value: GmsElement) {
    if (this._graphic !== value) {
      this._graphic = value;
      const curGraphic: GmsGraphic = value as GmsGraphic;
      if (curGraphic !== undefined) {
        // subscribe to changes to this graphic's primary selection, for adorning primary selection
        curGraphic.selectedObjectUpdated.subscribe((val: void) => this.setInitialSelection());
      }
    }
  }

  public get SymbolTemplate(): TemplateRef<any> {
    const graphic: GmsGraphic = this._graphic as GmsGraphic;
    return graphic !== undefined ? graphic.symbolTemplateRef : undefined;
  }

  public get GroupTemplate(): TemplateRef<any> {
    const graphic: GmsGraphic = this._graphic as GmsGraphic;
    return graphic !== undefined ? graphic.groupTemplateRef : undefined;
  }

  public get ReplicationTemplate(): TemplateRef<any> {
    const graphic: GmsGraphic = this._graphic as GmsGraphic;
    return graphic !== undefined ? graphic.replicationTemplateRef : undefined;
  }

  private _alarmsContainer: AlarmsContainer = undefined;
  public get AlarmsContainerRef(): AlarmsContainer {

    if (!this.IsReplicationClone) {
      const graphic: GmsGraphic = this.Graphic as GmsGraphic;
      return graphic as AlarmsContainer;
    }

    return this._alarmsContainer;
  }
  public set AlarmsContainerRef(value: AlarmsContainer) {
    if (this._alarmsContainer !== value) {
      this._alarmsContainer = value;

      if (this.Zone !== undefined) {
        // After the AlarmContainer is injected
        this.Zone.runOutsideAngular(() => setTimeout(() => this.UpdateAlarmState(), 0));
      }
    }
  }

  public get CurrentZoomLevel(): number {
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    return graphic !== undefined ? graphic.CurrentZoomLevel : 1;
  }

  public get locale(): string {
    return (this.Graphic !== undefined) ? this.Graphic.locale : FormatHelper.enUS;
  }
  public get defaultLocale(): string {
    return (this.Graphic !== undefined) ? this.Graphic.defaultLocale : FormatHelper.enUS;
  }

  public get AllSubstitution(): GmsElement[] {
    const allSubstitutions: GmsElement[] = new Array<GmsElement>();
    if (this.children !== undefined) {
      this.children.forEach(child => {
        allSubstitutions.push(child);

        const childElements: GmsElement[] = child.AllSubstitution;
        if (childElements.length > 0) {
          childElements.forEach(nestedChild => {
            allSubstitutions.push(nestedChild);
          });
        }
      });
    }

    if (this.Replication !== undefined && this.Replication.children.length > 0) {
      this.Replication.children.forEach((clone: GmsElement) => {
        allSubstitutions.push(clone);
        const childElements: GmsElement[] = clone.AllSubstitution;
        if (childElements.length > 0) {
          childElements.forEach(nestedChild => {
            allSubstitutions.push(nestedChild);
          });
        }
      });
    }

    return allSubstitutions;
  }

  // All the child elements
  // Except the replication clones
  public get AllChildren(): GmsElement[] {
    const allChildren: GmsElement[] = new Array<GmsElement>();
    if (this.children !== undefined) {
      this.children.forEach(child => {
        allChildren.push(child);

        const childElements: GmsElement[] = child.AllChildren;
        if (childElements.length > 0) {
          childElements.forEach(nestedChild => {
            allChildren.push(nestedChild);
          });
        }
      });
    }

    return allChildren;
  }

  public get ClipPathUrl(): string {
    return this.ClipPathId === null ? null : 'url(#' + this._clipPathId + ')';
  }

  public get HasBlinkColors(): boolean {
    return this._colorWraps.some(colorWrap => colorWrap.HasBlinkColor);
  }

  public get HasClipInformation(): boolean {

    const clipLeft: string = this._evaluationClipLeft !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipLeft, this._designValueClipLeft, PropertyType.String) : this.ClipLeft;
    const clipTop: string = this._evaluationClipTop !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipTop, this._designValueClipTop, PropertyType.String) : this.ClipTop;
    const clipRight: string = this._evaluationClipRight !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipRight, this._designValueClipRight, PropertyType.String) : this.ClipRight;
    const clipBottom: string = this._evaluationClipBottom !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipBottom, this._designValueClipBottom, PropertyType.String) : this.ClipBottom;

    const clipLeftValue: number = clipLeft !== undefined ? Utility.ParsePercentage(clipLeft, this.Width) : NaN; // Might be 50% of the width
    const clipTopValue: number = clipTop !== undefined ? Utility.ParsePercentage(clipTop, this.Height) : NaN; // Might be 50% of the height
    const clipRightValue: number = clipRight !== undefined ? Utility.ParsePercentage(clipRight, this.Width) : NaN; // Might be 50% of the width
    const clipBottomValue: number = clipBottom !== undefined ? Utility.ParsePercentage(clipBottom, this.Height) : NaN; // Might be 50% of the height

    if (!isNaN(clipLeftValue) || !isNaN(clipTopValue)
            || !isNaN(clipRightValue) || !isNaN(clipBottomValue)) {
      return true;
    }

    return false;
  }
  private _hasDatapoints = false;

  public get HasDatapoints(): boolean {
    return this._hasDatapoints;
  }
  public set HasDatapoints(value: boolean) {
    if (value !== this._hasDatapoints) {
      this._hasDatapoints = value;
      this.UpdateIsSelectable();
    }
  }

  public get IsSlidingThumb(): boolean {
    const parent: GmsCommandControl = this.Parent as GmsCommandControl;

    return (parent !== undefined && parent.ControlType === GmsCommandControlType.Slider);

  }
  /**
   *  Gets the slider control from an element if the element is a thumb.
   */
  public get SliderControl(): GmsCommandControl {

    if (this.Type === GmsElementType.CommandControl) {
      // the slider itself should not be included
      return undefined;
    }
    const slider: GmsCommandControl = GmsElement.FindVisualAncesterByType(this, GmsElementType.CommandControl) as GmsCommandControl;
    if (slider === undefined) {
      return undefined;
    }
    if (slider.ControlType === GmsCommandControlType.Slider ||
            slider.ControlType === GmsCommandControlType.Rotator) {

      return slider;
    }
    return undefined;
  }

  public IsTriggerEnabled(): boolean {
    return this.CommandVM != null && this.CommandVM.IsTriggerEnabled;
  }
  public IsSpinButton(): boolean {
    // let parent: GmsCommandControl = this.Parent as GmsCommandControl;
    const parent: GmsCommandControl = GmsElement.FindVisualAncesterByType(this, GmsElementType.CommandControl) as GmsCommandControl;

    return (parent !== undefined && parent.ControlType === GmsCommandControlType.Spinner);
  }

  public get IsGroupBased(): boolean {
    return this.Type === GmsElementType.Group;
  }

  public get HasLinkReference(): boolean {
    return ((this.LinkReference !== undefined && this.LinkReference.length > 0) ||
            this._evaluationLinkReference !== undefined && this._evaluationLinkReference.Enabled && !this._evaluationLinkReference.IsEmpty);
  }

  private _isHitTestVisible = false;
  public get IsHitTestVisible(): boolean {
    return this._isHitTestVisible;
  }
  public set IsHitTestVisible(value: boolean) {
    if (value !== this._isHitTestVisible) {
      this._isHitTestVisible = value;

      // only notify listeners on value changed
      this.NotifyPropertyChanged('IsHitTestVisible');
    }
  }

  private _isSelectable: boolean;
  public get IsSelectable(): boolean {
    return this._isSelectable;
  }

  public set IsSelectable(value: boolean) {
    if (value !== this._isSelectable) {
      this._isSelectable = value;
      this.NotifyPropertyChanged('IsSelectable');
      this.UpdateIsHitTestVisible();
    }
  }

  private _isTargetNavigable = false;
  public get IsTargetNavigable(): boolean {
    return this._isTargetNavigable;
  }

  public set IsTargetNavigable(value: boolean) {
    if (value !== this._isTargetNavigable) {
      this._isTargetNavigable = value;
      this.NotifyPropertyChanged('IsTargetNavigable');
      this.UpdateIsHitTestVisible();
    }
  }

  public IsCommandable(): boolean {
    // NOTE:
    return false;
  }

  public static FindVisualAncesterByType(start: GmsElement, type: GmsElementType): GmsElement {
    while (start !== undefined) {
      if (start.Type === type) {
        return start;
      }
      start = start.Parent;
    }
    return undefined;
  }

  public isCommandGroupEnabled(): boolean { return false; }

  /**
   * Gets whether an element is commandable because it is in a group that commandable or not
   */
  public get IsCommandableGrouped(): boolean {
    if (this.Type === GmsElementType.Group) {
      if (this.GetAnimatedIsCommandTriggerEnabled() && !this.isCommandGroupEnabled()) {
        const parentGroup: GmsGroup = this.FindTopCommandGroup(this);
        return (parentGroup !== undefined && parentGroup.IsCommandableGrouped);
      }
    }
    const group: GmsElement = GmsElement.FindVisualAncesterByType(this, GmsElementType.Group);
    if (group !== undefined) {
      if (!group.GetAnimatedIsCommandTriggerEnabled()) {
        return false;
      }
      if (group.GetAnimatedCommandRule() === '') {
        return false;
      }
      if (group.GetAnimatedLinkReference() === '') {
        return false;
      }
      return true;
    }
    return false;
  }

  public get IsControlGrouped(): boolean {
    const parentGroup: GmsGroup = this.FindTopCommandGroup(this);
    return (parentGroup !== undefined && parentGroup.IsCommandGroupEnabled);
  }

  public FindTopCommandGroup(element: GmsElement): GmsGroup {
    if (element === undefined) {
      return undefined;
    }
    let group: GmsGroup = GmsElement.FindVisualAncesterByType(element, GmsElementType.Group) as GmsGroup;
    while (group !== undefined) {
      if (group.IsCommandGroupEnabled) {
        break;
      }
      group = GmsElement.FindVisualAncesterByType(group.Parent, GmsElementType.Group) as GmsGroup;
    }

    return group;
  }

  public FindTopCommandTriggerGroup(element: GmsElement): GmsGroup {

    if (element === undefined) {
      return undefined;
    }
    let group: GmsGroup = GmsElement.FindVisualAncesterByType(element, GmsElementType.Group) as GmsGroup;
    while (group !== undefined) {
      if (group.GetAnimatedIsCommandTriggerEnabled()) {
        break;
      }
      group = GmsElement.FindVisualAncesterByType(element.Parent, GmsElementType.Group) as GmsGroup;
    }

    return group;
  }

  public get HasCommand(): boolean {

    return (this.LinkReference !== '' ||
            (this._evaluationLinkReference !== undefined &&
                this._evaluationLinkReference.Enabled && !this._evaluationLinkReference.IsEmpty)) &&
            (this.CommandRule !== '' ||
                (this._evaluationCommandRule !== undefined &&
                    this._evaluationCommandRule.Enabled && !this._evaluationCommandRule.IsEmpty));
  }

  private _isCopying = false;
  public get IsCopying(): boolean {
    return this._isCopying;
  }
  public set IsCopying(value: boolean) {
    this._isCopying = value;
  }
  public get IsCommandableStandalone(): boolean {
    // IsCommandTrigger = true and Element has CommandRule and Target => return true

    if (!this.GetAnimatedIsCommandTriggerEnabled()) {
      return false;
    }
    if (this.GetAnimatedCommandRule() === '') {
      return false;
    }
    if (this.GetAnimatedLinkReference() === '') {
      return false;
    }
    return true;
  }
  //  Handle Command Adorner
  // Note: currently, the adorner is not supported
  public HasCommandAdorner(): boolean {
    return this.HasCommand || this.IsCommandableGrouped;
  }

  private _alarmsPropagated = false;
  private _alarmsStateUpdateStarted = false;

  private _alarm: GmsAlarm = undefined;
  public get Alarm(): GmsAlarm {
    return this._alarm;
  }
  public set Alarm(value: GmsAlarm) {
    if (this._alarm === undefined) {
      this._alarm = value;
    }
  }

  public get hasAlarm(): boolean {
    return this._alarm !== undefined;
  }

  private _isGrayscale = false;
  public get IsGrayscale(): boolean {
    return this._isGrayscale;
  }
  public set IsGrayscale(value: boolean) {
    if (this._isGrayscale !== value) {
      this._isGrayscale = value;

      this.NotifyPropertyChanged('IsGrayscale');
    }
  }

  constructor(type: GmsElementType) {
    this.type = type;
    this.Id = Guid.newGuid();
    this.traceModule = TraceChannel.Model;
    this.traceModuleCommand = TraceChannel.Commanding;
  }

  public buttons: GmsButtons = undefined;
  // public TryAddButtons(parent: GmsElement): void {
  //     let isButtonOptionNeeded: boolean = false;
  //     if (this.Type === GmsElementType.CommandControl && !parent.IsControlGrouped) {
  //         isButtonOptionNeeded = true;
  //     }

  //     else if (this.Type === GmsElementType.Group && this.isCommandGroupEnabled) {
  //         isButtonOptionNeeded = true;
  //     }

  //     if (isButtonOptionNeeded) {
  //         let graphic: any = this.Graphic;
  //         graphic.buttonsService.createButtons(this);
  //     }
  // }

  protected AddCommandButtons(): void {
  }

  // Handles  #COM, #ENG and design mode values
  protected ResetValue(value: string = null): void {
    // NOTE:
    // provide override methods for GmsCommandControlNumeric, GmsCommandControlPassword, GmsCommandControlSelector, GmsCommandControlString
  }

  protected UpdateCommandControl(): void {
    // see the gmscommandcontrol
  }

  protected processNavigationTargets(): void {
    const graphic: any = this.Graphic as any;
    const navigationTargets: string[] = [this._linkReference];
    this.GmsObjectSelectionService.clearNavigations();
    this.GmsObjectSelectionService.addNavigations(navigationTargets);
    this.GmsObjectSelectionService.executeNavigation();
  }

  protected tryOpenURL(): void {
    let url: string = this._linkReference;
    if (!Utility.URLprotocolRegEx.test(this._linkReference)) {
      url = 'https://' + this._linkReference;
      if (Utility.URLpatternRegEx.test(url)) {
        window.open(url);
      }
    }
  }

  protected async ResolveTargetNavigationDatapoint(datapoint: Datapoint): Promise<void> {
    if (datapoint === undefined || datapoint.Status === DatapointStatus.Pending) {
      return;
    }
    if (this._targetNavigationDatapointSub !== undefined && !this._targetNavigationDatapointSub.closed) {
      this._targetNavigationDatapointSub.unsubscribe();
      this._targetNavigationDatapointSub = undefined;
    }
    if (datapoint.Status === DatapointStatus.DoesNotExist) {
      this.tryOpenURL();
    } else {
      this.processNavigationTargets();
    }
  }

  protected async ResolveCommandDatapoint(datapoint: Datapoint): Promise<void> {
    if (datapoint.Status === DatapointStatus.Pending) {
      return;
    }
    if (this._commandDatapointResolvedSub !== undefined && !this._commandDatapointResolvedSub.closed) {
      this._commandDatapointResolvedSub.unsubscribe();
      this._commandDatapointResolvedSub = undefined;
    }
    // 1. Datapoint resolved - it is either Valid, Invalid, or DoesntExist
    if (this.HasCommand && datapoint !== null) {
      const linkRef: string = this.GetAnimatedLinkReference();
      const commandRule: string = this.GetAnimatedCommandRule();

      if (this.TraceService !== undefined) {
        this.TraceService.info(this.traceModuleCommand,
          'ResolveCommandDatapoint: Command Target = %s Command Name = %s, Datapoint Id = %s  Datapoint Status = %s ',
          linkRef, commandRule, datapoint.Id, datapoint.Status);

      }
      if (datapoint.Status !== DatapointStatus.DoesNotExist) {
        if (linkRef === '' || commandRule === '') {
          return;
        }
        // 2. Get command
        const command: GmsCommand = this.CommandService !== undefined ? this.CommandService.getOrCreateCommand(datapoint, commandRule) : null;
        if (command === null) {
          return;
        }
        if (command.CommandStatus.IsValidating) {
          // subscribe when command status
          this.SubscribeForCommandUpdates(command);
        } else if (command.CommandStatus.IsValid || command.CommandStatus.Misconfigured || command.CommandStatus.DoesNotExist) {
          this.SetCommandVM(command);

          if (command.CommandStatus.DoesNotExist) {
            // Ownership can be changed, so subscribe for command status updates: we need to set a new datacontext when the status changed
            this.SubscribeForCommandUpdates(command);
          }
        }
      } else {
        if (this.TraceService !== undefined) {
          this.TraceService.info(this.traceModuleCommand,
            'ResolveCommandDatapoint: Command Datapoint cannot be resolved. Target = %s Command Name = %s, Datapoint Id = %s  Datapoint Status = %s ',
            linkRef, commandRule, datapoint.Id, datapoint.Status);

        }
        // set Empty/Invalid CommandModel
        if (this.CommandVM !== null /* && !IsCommandableGrouped*/) {
          this.CommandVM.IsUIConfigurationValid = false;
          if (this.Type === GmsElementType.CommandControl) {
            this.ResetValue();
          }
        } else {
          this.InvalidCommandVM();
        }
        this.UpdateVisible();
        this.UpdateEvaluationStatus();
      }
    }
  }
  public UpdateZoomableTransform(): void {
    this.NotifyPropertyChanged();
  }

  protected NotifyPropertyChanged(propertyName: string = ''): void {
    if (this.propertyChanged.observers !== null && this.propertyChanged.observers.length > 0) {
      this.propertyChanged.next(propertyName);
    }
  }

  protected NotifySelectionChanged(isSelected: boolean = false): void {
    if (!!this.selectionChanged && !this.selectionChanged.isStopped) {
      this.selectionChanged.next(isSelected);
    }
  }

  protected NotifyShapeChanged(): void {
    if (this.shapeChanged.observers !== null && this.shapeChanged.observers.length > 0) {
      this.shapeChanged.next();
    }
  }

  public UpdateCommandExecuteStatus(success: boolean, error: string): void {
    if (this.subExecuteStatus && !this.subExecuteStatus.closed) {
      this.subExecuteStatus.unsubscribe();
    }
    this.CommandVM.isCommandExecuteDone = true;
    this.CommandVM.isModified = false;
    if (success) {
      const msg = `Command executed with success: ${this.CommandVM.Key}, Name = ${this.CommandVM.Name}`;
      this.TraceService.info(TraceChannel.Commanding, msg);

    } else {
      this.TraceService.warn(TraceChannel.Commanding, error);
      this.ToastNotificationService.queueToastNotification('warning', 'Command Execute failed', error);
    }
  }

  private _commandButtonFound = false;
  public SetCommandButtonExist(): void {
    if (this.CommandVM !== null) {
      const triggerEnabled = this.GetAnimatedIsCommandTriggerEnabled();
      if (triggerEnabled && !this._commandButtonFound) {
        this.CommandVM.AddCommandButton();
        this._commandButtonFound = true;
      }
    }
  }

  // commanding
  public UpdateCommand(): void {

    if (!this.HasCommand) { // missing one or both properties: LinkReference or CommandRule
      // Reset the control to the design mode view
      if (this._commandViewModel !== null && !this._commandViewModel.IsInDesignMode) {
        if (this.type === GmsElementType.CommandControl) {
          // if (this.type === GmsElementType.CommandControl && !IsCommandableGrouped) {  // NOTE: IsCommandableGrouped
          this.UnsubscribeCommandFromUpdates();
          this._commandViewModel.IsInDesignMode = true;
          this._commandViewModel.IsUIConfigurationValid = true;
          this.ResetValue();
        }
        this.UpdateEvaluationStatus(); // Update adorner
      }
      return;
    }

    const target: string = this.GetAnimatedLinkReference();
    if (target === undefined || target === '') {
      return;
    }

    const datapoint: Datapoint = this.DatapointService.GetOrCreateByDesignation(target, false);
    if (datapoint.Status === DatapointStatus.Pending) { // not resolved yet
      if (this._commandDatapointResolvedSub !== undefined && !this._commandDatapointResolvedSub.closed) {
        this._commandDatapointResolvedSub.unsubscribe();
        this._commandDatapointResolvedSub = undefined;
      }
      this._commandDatapointResolvedSub =
                datapoint.propertyChanged.subscribe(args => this.ResolveCommandDatapoint(args.SourceDatapoint));
    } else if (datapoint.Status === DatapointStatus.Undefined && this.Graphic.DatapointService.RunInDeferredMode) {
      this._commandDatapointResolvedSub =
                this.Graphic.DatapointService.propertyChanged.subscribe(args =>
                  this.PostResolveDatapoint(datapoint));
    } else {
      this.ResolveCommandDatapoint(datapoint);
    }
  }

  public UnsubscribeCommandFromUpdates(command: GmsCommand = null, isDispose: boolean = false): void {
    if (this._commandPropertyChangedSub !== undefined && !this._commandPropertyChangedSub.closed) {
      this._commandPropertyChangedSub.unsubscribe();
      this._commandPropertyChangedSub = undefined;
    }
    if (this._commandVMpropertyChangedSub !== undefined && !this._commandVMpropertyChangedSub.closed) {
      this._commandVMpropertyChangedSub.unsubscribe();
      this._commandVMpropertyChangedSub = undefined;
    }
    if (this._commandDatapointResolvedSub !== undefined && !this._commandDatapointResolvedSub.closed) {
      this._commandDatapointResolvedSub.unsubscribe();
      this._commandDatapointResolvedSub = undefined;

    }
  }
  // Needs here for command status resolved, so to validate a current command arguments
  public SubscribeForCommandUpdates(command: GmsCommand): void {
    if (command === undefined || command === null) {
      return;
    }
    if (this._commandPropertyChangedSub !== undefined && !this._commandPropertyChangedSub.closed) {
      this._commandPropertyChangedSub.unsubscribe();
      this._commandPropertyChangedSub = undefined;
    }
    this._commandPropertyChangedSub = command.statusChanged.subscribe(args => this.Command_PropertyChanged(args));
  }

  public UpdateChildrenVisible(): void {
    if (!!this.children) {
      this.children.forEach((element: GmsElement) => {
        element.IsParentVisible = this.Visible;
        if (element.Replication !== undefined) {
          element.Replication.IsParentVisible = this.Visible;
        }
      });
    }
  }

  public updateChildrenDisableSelection(): void {
    if (!!this.children) {
      this.children.forEach((element: GmsElement) => { element.IsParentDisableSelection = this._disableSelection; });
    }
  }

  public async CnsDisplayLabelChanged(): Promise<any> {
  }

  public updateElementSelection(): void {
    this.UpdateDisableSelection();
    this.UpdateHasDatapoints();
    this.UpdateIsSelectable();
    this.UpdateIsHitTestVisible();
    this.setInitialSelection();
    this.updateChildrenDisableSelection();
    this.UpdateErrorBorder();
  }

  /**
   *
   * @param element
   */
  public AddChild(element: GmsElement): void {
    //
  }

  /**
   *
   * @param element
   */
  public RemoveChild(element: GmsElement): void {
    //
  }
  public get SelectedObject(): BrowserObject {
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    return (graphic !== undefined) ? graphic.SelectedObject : undefined;
  }

  public get DatapointService(): DataPointService {
    let service: DataPointService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.DatapointService;
    }

    return service;
  }

  public get CommandService(): GmsCommandService {
    let service: GmsCommandService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.GmsCommandService;
    }

    return service;
  }

  public get ExecuteCommandService(): ExecuteCommandServiceBase {
    let service: ExecuteCommandServiceBase;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.ExecuteCommandService;
    }

    return service;
  }
  public get ValidationService(): ValidationDialogService {
    let service: ValidationDialogService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.ValidationDlgService;
    }

    return service;
  }

  public get ToastNotificationService(): SiToastNotificationService {
    let service: SiToastNotificationService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.ToastNotificationService;
    }

    return service;
  }
  public get AnimationTimerService(): GmsAnimationTimerService {
    let service: GmsAnimationTimerService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.AnimationTimerService;
    }

    return service;
  }

  public get TextGroupService(): TextGroupService {
    let service: TextGroupService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.TextGroupService;
    }

    return service;
  }

  public get ElementBrushService(): ElementBrushService {
    let service: ElementBrushService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.ElementBrushService;
    }

    return service;
  }

  public get GmsObjectSelectionService(): GmsObjectSelectionService {
    let service: GmsObjectSelectionService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.GmsObjectSelectionService;
    }

    return service;
  }

  public get ReplicationService(): any {
    let service: any;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.ReplicationService;
    }

    return service;
  }

  public get TraceService(): TraceService {
    let service: TraceService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.TraceService;
    }

    return service;
  }

  public get TimerService(): TimerService {
    let service: TimerService;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      service = graphic.TimerService;
    }

    return service;
  }

  public get Zone(): NgZone {
    let zone: NgZone;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined) {
      zone = graphic.Zone;
    }

    return zone;
  }

  /**
   * Gets the internal scale factor
   */
  public get InternalScaleFactor(): number {

    const scaleFactor: number = Math.max(0, Evaluation.GetValue2(this._evaluationScaleFactor, this._designValueScaleFactor, PropertyType.Number));
    return !Number.isFinite(scaleFactor) && Number.isNaN(scaleFactor) ? GmsElement.DEFAULT_SCALEFACTOR : scaleFactor;
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateAdornerDimensions();
  }

  public isCommandCursor(): boolean {
    return this.GetCommandEnabled() && this.CursorType === GmsElementCursorType.Hand;
  }

  public GetCommandEnabled(): boolean {
    return this.CommandVM !== null ? this.CommandVM.IsCommandEnabled : false;
  }

  public GetAll(withReplications: boolean, withLayers: boolean = true, withSymbolInstanceChildren: boolean = true): GmsElement[] {
    const result: GmsElement[] = new Array<GmsElement>();
    this.GetAllRecursive(result, withReplications, withLayers, withSymbolInstanceChildren);
    return result;
  }

  public ProcessBackgroundRectangle(backgroundNode: Node): void {
    let result: string = SvgUtility.GetAttributeValue(backgroundNode, GmsElementPropertyType.Fill);
    if (result !== undefined) {
      this.Background = result;

      result = SvgUtility.GetAttributeValue(backgroundNode, GmsElementPropertyType.FillOpacity);
      if (result !== undefined) {
        this.BackgroundOpacity = +result;
      }
    }

    const backgroundOpacity: string = (this.BackgroundOpacity * 255.0).toString(16);
    this._designValueBackground = this.Background.startsWith('url') ? this.Background
      : ('#' + backgroundOpacity + this.Background.substring(1, this.Background.length));
  }

  /**
   * Iterate attributes and assign values to properties
   * @param node
   */
  public Deserialize(node: Node): void {
    if (node === undefined) {
      return;
    }

    // _internalId
    let result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Id);
    if (result !== undefined) {
      this._internalId = result;
    }
    // CoverageAreaReference
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CoverageAreaReference);
    if (result !== undefined) {
      this.CoverageAreaReference = result;
      this.UpdatePropertyCoverageAreaReference();
    }

    // PositionType
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.PositionType);
    if (result !== undefined) {
      this.PositionType = PositionType[result];
      this._designValuePositionType = this.PositionType;
    }
    // X
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.X);

    if (result !== undefined) {
      this.X = FormatHelper.StringToNumber(result); // Number(result);
      this._designValueX = this.X;
    }
    // Y
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Y);

    if (result !== undefined) {
      this.Y = FormatHelper.StringToNumber(result); // Number(result);
      this._designValueY = this.Y;
    }
    // Width

    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Width);

    if (result !== undefined) {
      this.Width = FormatHelper.StringToNumber(result); // Number(result);
      this.DesignValueWidth = this.Width;
    }
    // Height
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Height);
    if (result !== undefined) {
      this.Height = FormatHelper.StringToNumber(result); // Number(result);
      this.DesignValueHeight = this.Height;
    }
    this.BoundingRectDesign = new BoundingRectangle(this.X, this.Y, this.Width, this.Height);
    // Angle
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Angle);
    if (result !== undefined) {
      this.Angle = FormatHelper.StringToNumber(result); // Number(result);
      this._designValueAngle = this.Angle;
    }
    // AngleCenterX
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.AngleCenterX);
    if (result !== undefined) {
      this._angleCenterX = Utility.ParsePercentage(result, this.Width);
      this._designValueAngleCenterX = result;

    }
    // AngleCenterY
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.AngleCenterY);
    if (result !== undefined) {
      this._angleCenterY = Utility.ParsePercentage(result, this.Width);
      this._designValueAngleCenterY = result;
    }
    // RotationSpeed
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.RotationSpeed);
    if (result !== undefined) {
      this._designValueRotationSpeed = result;
      this.RotationSpeed = result;
    }
    // RotationSteps
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.RotationSteps);
    if (result !== undefined) {
      this._designValueRotationSteps = Math.floor(FormatHelper.StringToNumber(result)); // Math.floor(parseFloat(result));
      this.RotationSteps = FormatHelper.StringToNumber(result); // Number(result);
    }

    // Update the default rotation
    if (this._rotationSpeed !== '') {
      this.UpdateRotation();
    }

    // FlipX
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.FlipX);
    if (result !== undefined) {
      this.FlipX = result === 'True';
      this._designValueFlipX = this.FlipX;
    }
    // FlipY
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.FlipY);
    if (result !== undefined) {
      this.FlipY = result === 'True';
      this._designValueFlipY = this.FlipY;
    }

    // Opacity
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Opacity);
    if (result !== undefined) {
      this._designOpacity = FormatHelper.StringToNumber(result); // Number(result);
      this.Opacity = this._designOpacity;
    }
    // Visible
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Visible);
    if (result !== undefined) {
      this.Visible = result === 'True';
      this._designValueVisible = this.Visible;
    }
    // Blinking
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Blinking);
    if (result !== undefined) {
      this.Blinking = result === 'True';
      this._designValueBlinking = this.Blinking;
    }
    // CursorType
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CursorType);
    if (result !== undefined) {
      this.CursorType = GmsElementCursorType[result];
    }
    // Command Disabled Style
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandDisabledStyle);
    if (result !== undefined) {
      this.CommandDisabledStyle = GmsElementDisabledStyle[result];
    }
    // ClipLeft
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ClipLeft);
    if (result !== undefined) {
      this.ClipLeft = result;
      this._designValueClipLeft = this.ClipLeft;
    }
    // ClipTop
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ClipTop);
    if (result !== undefined) {
      this.ClipTop = result;
      this._designValueClipTop = this.ClipTop;
    }
    // ClipRight
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ClipRight);
    if (result !== undefined) {
      this.ClipRight = result;
      this._designValueClipRight = this.ClipRight;
    }
    // ClipBottom
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ClipBottom);
    if (result !== undefined) {
      this.ClipBottom = result;
      this._designValueClipBottom = this.ClipBottom;
    }

    // BackColor
    let backgroundNode: Node;
    if (node.hasChildNodes) {
      let nodes: NodeList = node.childNodes;
      for (let i = 0; i < nodes.length; i++) {
        const innerNode: Node = nodes.item(i);
        if (innerNode.nodeName === 'rect' && (innerNode as Element).id === 'backcolor') {
          backgroundNode = innerNode;
          break;
        } else if (SvgUtility.IsNodeScaleTransformationGroup(innerNode)) {
          i = -1;
          nodes = innerNode.childNodes;
          continue;
        }
      }
    }
    if (backgroundNode !== undefined) {
      this.ProcessBackgroundRectangle(backgroundNode);
    } else {
      this.BackgroundOpacity = 0;
    }

    // StrokeColor
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Stroke);
    if (result !== undefined) {
      this.Stroke = result;
      // Stroke Opacity
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.StrokeOpacity);
      if (result !== undefined) {
        this.StrokeOpacity = +result;
      }

      // StrokeWidth
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.StrokeWidth);
      if (result !== undefined) {
        this.StrokeWidth = +result;
      }

      // Stroke Line Cap
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.StrokeLineCap);

      if (result !== undefined) {
        this.StrokeLineCap = result;
      }

      // Stroke Dash Array
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.StrokeDashArray);
      if (result !== undefined) {
        this.StrokeDashArray = result;
      }

      // Stroke Line Join
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.StrokeLineJoin);
      if (result !== undefined) {
        this.StrokeLineJoin = result;
      }

      const strokeOpacity: string = (this.StrokeOpacity * 255.0).toString(16);
      this._designValueStroke = this.Stroke.startsWith('url') ? this.Stroke
        : ('#' + strokeOpacity + this.Stroke.substring(1, this.Stroke.length));
    } else {
      this.StrokeOpacity = 0;
    }

    if (this.Type !== GmsElementType.Line) {
      // FillColor
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Fill);
      if (result !== undefined) {
        this.Fill = result;

        // FillOpacity
        result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.FillOpacity);
        if (result !== undefined) {
          this.FillOpacity = +result;
        }

        const fillOpacity: string = (this.FillOpacity * 255.0).toString(16);
        this._designValueFill = this.Fill.startsWith('url') ? this.Fill
          : ('#' + fillOpacity + this.Fill.substring(1, this.Fill.length));
      } else {
        this.FillOpacity = 0;
      }
    }

    // Filter
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Filter);
    if (result !== undefined) {
      this._designValueFilter = result;
    }

    // Stroke Color Wrap
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.StrokeColorWrap);
    if (result !== undefined) {
      this._designValueStrokeWrap = new ColorWrap(result);
      // For blink colors the compound brushes are not urls. They are resolved during deserialization.
      // And added to the dynamic resources
      if (this._designValueStrokeWrap.HasBlinkColor) {
        if (this._designValueStrokeWrap.First.HasResolvedBrush) {
          (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueStrokeWrap.First.Brush);
        }
        if (this._designValueStrokeWrap.Second.HasResolvedBrush) {
          (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueStrokeWrap.Second.Brush);
        }
      }
    } else {
      this._designValueStrokeWrap = new ColorWrap('#000000');
    }

    // Fill Color wrap
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.FillColorWrap);
    if (result !== undefined) {
      this._designValueFillWrap = new ColorWrap(result);
      // For blink colors the compound brushes are not urls. They are resolved during deserialization.
      // And added to the dynamic resources
      if (this._designValueFillWrap.HasBlinkColor) {
        if (this._designValueFillWrap.First.HasResolvedBrush) {
          (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueFillWrap.First.Brush);
        }
        if (this._designValueFillWrap.Second.HasResolvedBrush) {
          (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueFillWrap.Second.Brush);
        }
      }
    } else {
      this._designValueFillWrap = new ColorWrap('transparent');
    }

    // Back Color wrap
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.BackColorWrap);
    if (result !== undefined) {
      this._designValueBackgroundWrap = new ColorWrap(result);
      // For blink colors the compound brushes are not urls. They are resolved during deserialization.
      // And added to the dynamic resources
      if (this._designValueBackgroundWrap.HasBlinkColor) {
        if (this._designValueBackgroundWrap.First.HasResolvedBrush) {
          (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueBackgroundWrap.First.Brush);
        }
        if (this._designValueBackgroundWrap.Second.HasResolvedBrush) {
          (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueBackgroundWrap.Second.Brush);
        }
      }
    } else {
      this._designValueBackgroundWrap = new ColorWrap('transparent');
    }

    // Used for checking the blink state
    this._colorWraps.push(this._designValueStrokeWrap);
    this._colorWraps.push(this._designValueFillWrap);
    this._colorWraps.push(this._designValueBackgroundWrap);
    this._colorWraps.push(this._evaluationStrokeWrap);
    this._colorWraps.push(this._evaluationFillWrap);
    this._colorWraps.push(this._evaluationBackgroundWrap);
    this.SetBlinkColorState();

    // Used to manage the dynamic brushes
    this._evaluationColorWraps.push(this._evaluationStrokeWrap);
    this._evaluationColorWraps.push(this._evaluationFillWrap);
    this._evaluationColorWraps.push(this._evaluationBackgroundWrap);

    // Tooltip
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Tooltip);
    if (result !== undefined) {
      this._designValueTooltip = result;
      this.Tooltip = this._designValueTooltip;
    }
    // Description
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Description);
    if (result !== undefined) {
      this.Description = result;
    }

    // SelectionRef
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.SelectionRef);
    if (result !== undefined) {
      this.SelectionRef = result;
      this._designValueSelectionRef = result;
      this.UpdateSelectionRef();
    }
    // LinkReference
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.LinkReference);
    if (!!result) {
      this._designValueLinkReference = result;
      this.UpdateLinkReference();
    }

    // DisableSelection
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.DisableSelection);
    if (result !== undefined) {
      this._disableSelection = Utility.ConvertBooleanStringToBool(result);
      this.UpdateDisableSelection();
    }
    // Zoomable
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Zoomable);
    if (result !== undefined) {
      this.Zoomable = Utility.ConvertBooleanStringToBool(result);
    }
    // MinVisibility
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.MinVisibility);
    if (result !== undefined) {
      this.MinVisibility = FormatHelper.StringToNumber(result); // Number(result);
    }
    // MaxVisibility
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.MaxVisibility);
    if (result !== undefined) {
      this.MaxVisibility = FormatHelper.StringToNumber(result); // Number(result);
    }
    // ScaleX, ScaleY
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ScaleFactor);
    if (result !== undefined) {
      this.ScaleX = FormatHelper.StringToNumber(result); // Number(result);
      this.ScaleY = FormatHelper.StringToNumber(result); // Number(result);
      this._designValueScaleFactor = this.ScaleX;
    }

    // CommandRule
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandRule);
    if (result !== undefined) {
      this.CommandRule = result;
    }

    // CommandParameter
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandParameter);
    if (result !== undefined) {
      this.CommandParameter = result;
    }
    // LinkDescription
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.LinkDescription);
    if (result !== undefined) {
      this.LinkDescription = result;
    }
    // IsCommandTriggerEnabled
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.IsCommandTriggerEnabled);
    if (result !== undefined) {
      this.IsCommandTriggerEnabled = Utility.ConvertBooleanStringToBool(result);
    }

    // CommandTrigger
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandTrigger);
    if (result !== undefined && Object.values(GmsCommandTriggerTypes).includes(result as GmsCommandTriggerTypes)) {
      this.CommandTrigger = GmsCommandTriggerTypes[result];
    }
    // CommandDisabled
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandDisabled);
    if (result !== undefined) {
      this.CommandDisabled = Utility.ConvertBooleanStringToBool(result);
    }
    // ShadowDepth
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ShadowDepth);
    if (result !== undefined) {
      this.ShadowDepth = FormatHelper.StringToNumber(result); //  Number(result);
      this._designValueShadowDepth = this.ShadowDepth;
    }
    // ShadowColor
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ShadowColor);
    if (result !== undefined) {
      this.ShadowColor = result;
      this._designValueShadowColor = this.ShadowColor;
    }
    // ShadowDirection
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ShadowDirection);
    if (result !== undefined) {
      this.ShadowDirection = FormatHelper.StringToNumber(result); //  Number(result);
      this._designValueShadowDirection = this.ShadowDirection;
    }
    // ReplicationIndexRange
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ReplicationIndexRange);
    if (result !== undefined) {
      this.ReplicationIndexRange = result;
      this._designValueReplicationIndexRange = result;

    }
    // ReplicationOrientation
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ReplicationOrientation);
    if (result !== undefined) {
      this.ReplicationOrientation = GmsElementReplicationOrientationType[result];
      this._designValueReplicationOrientation = this.ReplicationOrientation;
    }
    // MaxReplicationExtent
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.MaxReplicationExtent);
    if (result !== undefined) {
      this.MaxReplicationExtent = result;
      this._designValueReplicationMaxExtent = result;
    }
    // ReplicationSpace
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ReplicationSpace);
    if (result !== undefined) {
      this.ReplicationSpace = result;
      this._designValueReplicationSpace = result;
    }
    // Grayscale support to Image and Xps
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.IsGrayscale);
    if (result !== undefined) {
      this.IsGrayscale = Utility.ConvertBooleanStringToBool(result);
    }

    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('Transformation');

    this.UpdateCommand();
    this.UpdateIsTargetNavigable();
    this.UpdateIsHitTestVisible();
    this.UpdateOpacity();

    this._filter = new Filter(this._designValueFilter);
    this.modifyFilterColor();
    this.modifyFilterDepthAndDirection();
  }

  private _isHeightModified = false;
  public get IsHeightModified(): boolean {
    return this._isHeightModified;
  }
  private _isWidthModified = false;
  public get IsWidthModified(): boolean {
    return this._isWidthModified;
  }
  private _isXModified = false;
  public get IsXModified(): boolean {
    return this._isXModified;
  }
  private _isYModified = false;
  public get IsYModified(): boolean {
    return this._isYModified;
  }

  public IsFigureModified(): boolean {
    return false;
  }

  public ApplyWidth(elementProperties: Map<string, [string, string]>): void {
    // Width = <any>"width",
    const propertyValue: [string, string] = elementProperties.get(GmsElementPropertyType.Width.toString());
    if (propertyValue !== undefined) {
      this.Width = FormatHelper.StringToNumber(propertyValue[1]);
      // this.DesignValueWidth = this.Width;
      // this.BoundingRectDesign.Width = this.Width;
      this._isWidthModified = true;
      elementProperties.delete(GmsElementPropertyType.Width.toString());
    }
  }

  public ApplyHeight(elementProperties: Map<string, [string, string]>): void {
    const propertyValue: [string, string] = elementProperties.get(GmsElementPropertyType.Height.toString());
    if (propertyValue !== undefined) {
      this.Height = FormatHelper.StringToNumber(propertyValue[1]);
      // this._designValueHeight = this.Height;
      // this.BoundingRectDesign.Height = this.Height;
      this._isHeightModified = true;
      elementProperties.delete(GmsElementPropertyType.Height.toString());
    }
  }

  public ApplyX(elementProperties: Map<string, [string, string]>): void {
    const propertyValue: [string, string] = elementProperties.get(GmsElementPropertyType.X.toString());
    if (propertyValue !== undefined) {
      const x: number = this.BoundingRectDesign.X * this.ResizeFactorWidth;
      this.X = FormatHelper.StringToNumber(propertyValue[1]) + x;
      this._isXModified = true;
      elementProperties.delete(GmsElementPropertyType.X.toString());
    }
  }

  public ApplyY(elementProperties: Map<string, [string, string]>): void {
    const propertyValue: [string, string] = elementProperties.get(GmsElementPropertyType.Y.toString());
    if (propertyValue !== undefined) {
      const y: number = this.BoundingRectDesign.Y * this.ResizeFactorHeight;
      this.Y = FormatHelper.StringToNumber(propertyValue[1]) + y;
      this._isYModified = true;
      elementProperties.delete(GmsElementPropertyType.Y.toString());
    }
  }
  /**
   * Apply instance properties if any found
   * @param instanceProperties
   */
  public Apply(instanceProperties: InstanceProperty[]): void {
    if (this.InternalId !== null && instanceProperties !== undefined) {
      const instanceProperty: InstanceProperty =
                instanceProperties.find((inst: InstanceProperty) => inst.SourceId === this.InternalId);
      const elementProperties: Map<string, [string, string]> = instanceProperty !== undefined ?
        instanceProperty.ElementProperties : undefined;
      // property type and value
      let propertyValue: [string, string];
      if (elementProperties !== undefined && elementProperties.size > 0) {
        // X
        this.ApplyX(elementProperties);
        if (elementProperties.size === 0) {
          return;
        }
        // Y
        this.ApplyY(elementProperties);
        if (elementProperties.size === 0) {
          return;
        }
        // Width = <any>"width",
        this.ApplyWidth(elementProperties);
        if (elementProperties.size === 0) {
          return;
        }
        // Height = <any>"height",
        this.ApplyHeight(elementProperties);
        if (elementProperties.size === 0) {
          return;
        }
        // PositionType
        propertyValue = elementProperties.get(GmsElementPropertyType.PositionType.toString());
        if (propertyValue !== undefined) {
          const positionType: string = PositionType[propertyValue[1]];
          this.PositionType = PositionType[positionType];
          this._designValuePositionType = this.PositionType;
          elementProperties.delete(GmsElementPropertyType.PositionType.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // Angle = <any>"Angle",
        propertyValue = elementProperties.get(GmsElementPropertyType.Angle.toString());
        if (propertyValue !== undefined) {
          this.Angle = FormatHelper.StringToNumber(propertyValue[1]);
          this._designValueAngle = this.Angle;
          elementProperties.delete(GmsElementPropertyType.Angle.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // AngleCenterX = <any>"AngleCenterX",
        propertyValue = elementProperties.get(GmsElementPropertyType.AngleCenterX.toString());
        if (propertyValue !== undefined) {
          this._angleCenterX = Utility.ParsePercentage(propertyValue[1], this.Width);
          this._designValueAngleCenterX = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.AngleCenterX.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // AngleCenterY = <any>"AngleCenterY",
        propertyValue = elementProperties.get(GmsElementPropertyType.AngleCenterY.toString());
        if (propertyValue !== undefined) {
          this._angleCenterY = Utility.ParsePercentage(propertyValue[1], this.Width);
          this._designValueAngleCenterY = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.AngleCenterY.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // RotationSpeed = <any>"RotationSpeed",
        propertyValue = elementProperties.get(GmsElementPropertyType.RotationSpeed.toString());
        if (propertyValue !== undefined) {
          this._designValueRotationSpeed = propertyValue[1];
          this.RotationSpeed = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.RotationSpeed.toString());
        }
        // RotationSteps = <any>"RotationSteps",
        propertyValue = elementProperties.get(GmsElementPropertyType.RotationSteps.toString());
        if (propertyValue !== undefined) {
          this._designValueRotationSteps = Math.floor(FormatHelper.StringToNumber(propertyValue[1])); // Math.floor(parseFloat(result));
          this.RotationSteps = FormatHelper.StringToNumber(propertyValue[1]); // Number(result);
          elementProperties.delete(GmsElementPropertyType.RotationSpeed.toString());
        }
        // Update the default rotation
        if (this._rotationSpeed !== '') {
          this.UpdateRotation();
        }
        if (elementProperties.size === 0) {
          return;
        }
        // FlipX = <any>"FlipX",
        propertyValue = elementProperties.get(GmsElementPropertyType.FlipX.toString());
        if (propertyValue !== undefined) {
          this.FlipX = propertyValue[1] === 'True';
          this._designValueFlipX = this.FlipX;
          elementProperties.delete(GmsElementPropertyType.FlipX.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // FlipY = <any>"FlipY",
        propertyValue = elementProperties.get(GmsElementPropertyType.FlipY.toString());
        if (propertyValue !== undefined) {
          this.FlipY = propertyValue[1] === 'True';
          this._designValueFlipY = this.FlipY;
          elementProperties.delete(GmsElementPropertyType.FlipY.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // Visible = <any>"Visible",
        propertyValue = elementProperties.get(GmsElementPropertyType.Visible.toString());
        if (propertyValue !== undefined) {
          this.Visible = propertyValue[1] === 'True';
          this._designValueVisible = this.Visible;
          elementProperties.delete(GmsElementPropertyType.Visible.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // Blinking = <any>"Blinking",
        propertyValue = elementProperties.get(GmsElementPropertyType.Blinking.toString());
        if (propertyValue !== undefined) {
          this.Blinking = propertyValue[1] === 'True';
          this._designValueBlinking = this.Blinking;
          elementProperties.delete(GmsElementPropertyType.Blinking.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // Opacity = <any>"opacity",
        propertyValue = elementProperties.get(GmsElementPropertyType.Opacity.toString());
        if (propertyValue !== undefined) {
          this._designOpacity = FormatHelper.StringToNumber(propertyValue[1]) / 100; // Number(result);
          this.Opacity = this._designOpacity;
          elementProperties.delete(GmsElementPropertyType.Opacity.toString());
        }

        if (elementProperties.size === 0) {
          return;
        }
        // ScaleFactor = <any>"ScaleFactor"
        propertyValue = elementProperties.get(GmsElementPropertyType.ScaleFactor.toString());
        if (propertyValue !== undefined) {
          this.ScaleX = FormatHelper.StringToNumber(propertyValue[1]);
          this.ScaleY = FormatHelper.StringToNumber(propertyValue[1]);
          this._designValueScaleFactor = this.ScaleX;

          elementProperties.delete(GmsElementPropertyType.ScaleFactor.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // ClipLeft = <any>"ClipLeft"
        propertyValue = elementProperties.get(GmsElementPropertyType.ClipLeft.toString());
        if (propertyValue !== undefined) {
          this.ClipLeft = propertyValue[1];
          this._designValueClipLeft = this.ClipLeft;

          elementProperties.delete(GmsElementPropertyType.ClipLeft.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // ClipTop = <any>"ClipTop",
        propertyValue = elementProperties.get(GmsElementPropertyType.ClipTop.toString());
        if (propertyValue !== undefined) {
          this.ClipTop = propertyValue[1];
          this._designValueClipTop = this.ClipTop;

          elementProperties.delete(GmsElementPropertyType.ClipTop.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // ClipRight = <any>"ClipRight",
        propertyValue = elementProperties.get(GmsElementPropertyType.ClipRight.toString());
        if (propertyValue !== undefined) {
          this.ClipRight = propertyValue[1];
          this._designValueClipRight = this.ClipRight;

          elementProperties.delete(GmsElementPropertyType.ClipRight.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // ClipBottom = <any>"ClipBottom"
        propertyValue = elementProperties.get(GmsElementPropertyType.ClipBottom.toString());
        if (propertyValue !== undefined) {
          this.ClipBottom = propertyValue[1];
          this._designValueClipBottom = this.ClipBottom;

          elementProperties.delete(GmsElementPropertyType.ClipBottom.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // NOTE:
        // 1. Colors
        // ...
        // StrokeColor
        propertyValue = elementProperties.get(GmsElementPropertyType.Stroke.toString());

        if (propertyValue !== undefined) {
          this.Stroke = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.Stroke.toString());
          // Stroke Opacity
          propertyValue = elementProperties.get(GmsElementPropertyType.StrokeOpacity.toString());
          if (propertyValue !== undefined) {
            this.StrokeOpacity = FormatHelper.StringToNumber(propertyValue[1]);
            elementProperties.delete(GmsElementPropertyType.StrokeOpacity.toString());
          }
          const strokeOpacity: string = (this.StrokeOpacity * 255.0).toString(16);
          this._designValueStroke = this.Stroke.startsWith('url') ? this.Stroke
            : ('#' + strokeOpacity + this.Stroke.substring(1, this.Stroke.length));

          this.NotifyPropertyChanged('Stroke');
        }
        if (elementProperties.size === 0) {
          return;
        }

        // StrokeWidth
        propertyValue = elementProperties.get(GmsElementPropertyType.StrokeWidth.toString());
        if (propertyValue !== undefined) {
          this.StrokeWidth = FormatHelper.StringToNumber(propertyValue[1]);
          elementProperties.delete(GmsElementPropertyType.StrokeWidth.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }

        // Stroke Line Cap
        propertyValue = elementProperties.get(GmsElementPropertyType.StrokeLineCap.toString());
        if (propertyValue !== undefined) {
          this.StrokeLineCap = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.StrokeLineCap.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // StrokeDashArray
        propertyValue = elementProperties.get(GmsElementPropertyType.StrokeDashArray.toString());
        if (propertyValue !== undefined) {
          this.StrokeDashArray = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.StrokeDashArray.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }

        // StrokeLineJoin
        propertyValue = elementProperties.get(GmsElementPropertyType.StrokeLineJoin.toString());
        if (propertyValue !== undefined) {
          this.StrokeLineJoin = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.StrokeLineJoin.toString());
        }
        if (elementProperties.size === 0) {
          return;
        }
        // BackColor
        propertyValue = elementProperties.get(GmsElementPropertyType.Background.toString());
        if (propertyValue !== undefined) {
          this.Background = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.Background.toString());
          propertyValue = elementProperties.get('backcolor-opacity');
          if (propertyValue !== undefined) {
            this.BackgroundOpacity = Number(propertyValue[1]);
            elementProperties.delete('backcolor-opacity');
          }
          // else {
          //    this.BackgroundOpacity = 1;
          // }
        }
        if (elementProperties.size === 0) {
          return;
        }
        // Check colors and the color wraps
        // Fill Color
        propertyValue = elementProperties.get(GmsElementPropertyType.Fill.toString());
        if (propertyValue !== undefined) {
          this.Fill = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.Fill.toString());
        }
        propertyValue = elementProperties.get(GmsElementPropertyType.FillOpacity.toString());
        if (propertyValue !== undefined) {
          this.FillOpacity = Number(propertyValue[1]);
          elementProperties.delete(GmsElementPropertyType.FillOpacity.toString());
        } else {
          this.FillOpacity = GmsElement.DEFAULT_OPACITY;
        }
        const fillOpacity: string = (this.FillOpacity * 255.0).toString(16);
        this._designValueFill = this.Fill.startsWith('url') ? this.Fill
          : ('#' + fillOpacity + this.Fill.substring(1, this.Fill.length));

        this.NotifyPropertyChanged('Fill');
        if (elementProperties.size === 0) {
          return;
        }

        // Stroke Color Wrap
        propertyValue = elementProperties.get(GmsElementPropertyType.StrokeColorWrap.toString());

        if (propertyValue !== undefined) {
          this._designValueStrokeWrap = new ColorWrap(propertyValue[1]);
          // For blink colors the compound brushes are not urls. They are resolved during deserialization.
          // And added to the dynamic resources
          if (this._designValueStrokeWrap.HasBlinkColor) {
            if (this._designValueStrokeWrap.First.HasResolvedBrush) {
              (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueStrokeWrap.First.Brush);
            }
            if (this._designValueStrokeWrap.Second.HasResolvedBrush) {
              (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueStrokeWrap.Second.Brush);
            }
          }
          elementProperties.delete(GmsElementPropertyType.StrokeColorWrap.toString());
        }
        // Fill Color wrap
        propertyValue = elementProperties.get(GmsElementPropertyType.FillColorWrap.toString());

        if (propertyValue !== undefined) {
          this._designValueFillWrap = new ColorWrap(propertyValue[1]);
          // For blink colors the compound brushes are not urls. They are resolved during deserialization.
          // And added to the dynamic resources
          if (this._designValueFillWrap.HasBlinkColor) {
            if (this._designValueFillWrap.First.HasResolvedBrush) {
              (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueFillWrap.First.Brush);
            }
            if (this._designValueFillWrap.Second.HasResolvedBrush) {
              (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueFillWrap.Second.Brush);
            }
          }
          elementProperties.delete(GmsElementPropertyType.FillColorWrap.toString());
        }
        // Back Color wrap
        propertyValue = elementProperties.get(GmsElementPropertyType.BackColorWrap.toString());
        if (propertyValue !== undefined) {
          this._designValueBackgroundWrap = new ColorWrap(propertyValue[1]);
          // For blink colors the compound brushes are not urls. They are resolved during deserialization.
          // And added to the dynamic resources
          if (this._designValueBackgroundWrap.HasBlinkColor) {
            if (this._designValueBackgroundWrap.First.HasResolvedBrush) {
              (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueBackgroundWrap.First.Brush);
            }
            if (this._designValueBackgroundWrap.Second.HasResolvedBrush) {
              (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueBackgroundWrap.Second.Brush);
            }
          }
          elementProperties.delete(GmsElementPropertyType.BackColorWrap.toString());
        }
        // Used for checking the blink state
        this._colorWraps.push(this._designValueStrokeWrap);
        this._colorWraps.push(this._designValueFillWrap);
        this._colorWraps.push(this._designValueBackgroundWrap);
        this.SetBlinkColorState();

        if (elementProperties.size === 0) {
          return;
        }

        propertyValue = elementProperties.get(GmsElementPropertyType.SelectionRef.toString());
        if (propertyValue !== undefined) {
          this.SelectionRef = propertyValue[1];

          elementProperties.delete(GmsElementPropertyType.SelectionRef.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // Link Reference
        propertyValue = elementProperties.get(GmsElementPropertyType.LinkReference.toString());
        if (propertyValue !== undefined) {
          this.LinkReference = propertyValue[1];
          this.UpdateLinkReference();

          elementProperties.delete(GmsElementPropertyType.LinkReference.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }

        // DisableSelection = <any>"DisableSelection",
        propertyValue = elementProperties.get(GmsElementPropertyType.DisableSelection.toString());
        if (propertyValue !== undefined) {
          this.DisableSelection = Utility.ConvertBooleanStringToBool(propertyValue[1]) ||
                        this._isParentDisableSelection;

          elementProperties.delete(GmsElementPropertyType.DisableSelection.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // Zoomable = <any>"Zoomable",
        propertyValue = elementProperties.get(GmsElementPropertyType.Zoomable.toString());
        if (propertyValue !== undefined) {
          this.Zoomable = Utility.ConvertBooleanStringToBool(propertyValue[1]);

          elementProperties.delete(GmsElementPropertyType.Zoomable.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // MinVisibility = <any>"MinVisibility",
        propertyValue = elementProperties.get(GmsElementPropertyType.MinVisibility.toString());
        if (propertyValue !== undefined) {
          this.MinVisibility = FormatHelper.StringToNumber(propertyValue[1]);

          elementProperties.delete(GmsElementPropertyType.MinVisibility.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // MaxVisibility = <any>"MaxVisibility",
        propertyValue = elementProperties.get(GmsElementPropertyType.MaxVisibility.toString());
        if (propertyValue !== undefined) {
          this.MaxVisibility = FormatHelper.StringToNumber(propertyValue[1]);

          elementProperties.delete(GmsElementPropertyType.MaxVisibility.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // ShadowDepth
        propertyValue = elementProperties.get(GmsElementPropertyType.ShadowDepth.toString());
        if (propertyValue !== undefined) {
          this.ShadowDepth = FormatHelper.StringToNumber(propertyValue[1]);

          elementProperties.delete(GmsElementPropertyType.ShadowDepth.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // ShadowColor
        propertyValue = elementProperties.get(GmsElementPropertyType.ShadowColor.toString());
        if (propertyValue !== undefined) {
          this.ShadowColor = propertyValue[1];
          elementProperties.delete(GmsElementPropertyType.ShadowDirection.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // ShadowDirection
        propertyValue = elementProperties.get(GmsElementPropertyType.ShadowDirection.toString());
        if (propertyValue !== undefined) {
          this.ShadowDirection = FormatHelper.StringToNumber(propertyValue[1]);

          elementProperties.delete(GmsElementPropertyType.ShadowDirection.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        propertyValue = elementProperties.get(GmsElementPropertyType.Filter.toString());
        if (propertyValue !== undefined) {
          this.Filter = new Filter(propertyValue[1]);
          elementProperties.delete(GmsElementPropertyType.Filter.toString());
        }
      }
    }
  }

  protected CopyFrom(element: GmsElement): void {

    // element is "CoverageArea", aka "CA"
    this.CoverageArea = element.CoverageArea;

    // PositionType
    this.PositionType = element._designValuePositionType;
    this._designValuePositionType = element._designValuePositionType;

    // X
    this.X = element._designValueX;
    this._designValueX = element._designValueX;

    // Y
    this.Y = element._designValueY;
    this._designValueY = element._designValueY;

    // Width
    this.Width = element.DesignValueWidth;
    this.DesignValueWidth = element.DesignValueWidth;

    // Height
    this.Height = element.DesignValueHeight;
    this.DesignValueHeight = element.DesignValueHeight;
    this.BoundingRectDesign = new BoundingRectangle(element._designValueX, element._designValueY
      , element.BoundingRectDesign.Width, element.BoundingRectDesign.Height);

    // Angle
    this.Angle = element._designValueAngle;
    this._designValueAngle = element._designValueAngle;

    // AngleCenterX
    this._angleCenterX = Utility.ParsePercentage(element._designValueAngleCenterX, this.DesignValueWidth);
    this._designValueAngleCenterX = element._designValueAngleCenterX;

    // AngleCenterY
    this._angleCenterY = Utility.ParsePercentage(element._designValueAngleCenterY, this.DesignValueHeight);
    this._designValueAngleCenterY = element._designValueAngleCenterY;

    // RotationSpeed
    this.RotationSpeed = element._designValueRotationSpeed;
    this._designValueRotationSpeed = element._designValueRotationSpeed;

    // RotationSteps
    this.RotationSteps = element._designValueRotationSteps;
    this._designValueRotationSteps = element._designValueRotationSteps;

    // Update the default rotation
    if (this._rotationSpeed !== '') {
      this.UpdateRotation();
    }

    // FlipX
    this.FlipX = element._designValueFlipX;
    this._designValueFlipX = element._designValueFlipX;

    // FlipY
    this.FlipY = element._designValueFlipY;
    this._designValueFlipY = element._designValueFlipY;

    // Opacity
    this._designOpacity = element._designOpacity;
    this.Opacity = element._designOpacity;

    // Visible
    this.Visible = element._designValueVisible;
    this._designValueVisible = element._designValueVisible;

    // Blinking
    this.Blinking = element._designValueBlinking;
    this._designValueBlinking = element._designValueBlinking;

    // CursorType
    this.CursorType = element.CursorType;

    // Command Disabled Style
    this.CommandDisabledStyle = element.CommandDisabledStyle;

    // ClipLeft
    this.ClipLeft = element._designValueClipLeft;
    this._designValueClipLeft = element._designValueClipLeft;

    // ClipTop
    this.ClipTop = element._designValueClipTop;
    this._designValueClipTop = element._designValueClipTop;

    // ClipRight
    this.ClipRight = element._designValueClipRight;
    this._designValueClipRight = element._designValueClipRight;

    // ClipBottom
    this.ClipBottom = element._designValueClipBottom;
    this._designValueClipBottom = element._designValueClipBottom;

    // BackColor
    this.Background = element.Background;
    this._designValueBackground = element._designValueBackground;
    this.BackgroundOpacity = element.BackgroundOpacity;

    // StrokeColor
    this.Stroke = element.Stroke;
    this.StrokeOpacity = element.StrokeOpacity;

    const strokeOpacity: string = (this.StrokeOpacity * 255.0).toString(16);
    this._designValueStroke = this.Stroke.startsWith('url') ? this.Stroke
      : ('#' + strokeOpacity + this.Stroke.substring(1, this.Stroke.length));

    this._designValueStroke = element._designValueStroke;

    // StrokeWidth
    this.StrokeWidth = element.StrokeWidth;

    // Stroke Line Cap
    this.StrokeLineCap = element.StrokeLineCap;

    // Stroke Dash Array
    this.StrokeDashArray = element.StrokeDashArray;

    // Stroke Line Join
    this.StrokeLineJoin = element.StrokeLineJoin;

    if (this.Type !== GmsElementType.Line) {

      this.Fill = element.Fill;
      this.FillOpacity = element.FillOpacity;

      const fillOpacity: string = (this.FillOpacity * 255.0).toString(16);
      this._designValueFill = this.Fill.startsWith('url') ? this.Fill
        : ('#' + fillOpacity + this.Fill.substring(1, this.Fill.length));
    }

    // Filter
    this.Filter = element.Filter;

    // Stroke Color Wrap
    this._designValueStrokeWrap = new ColorWrap(element._designValueStroke);

    // For blink colors the compound brushes are not urls. They are resolved during deserialization.
    // And added to the dynamic resources
    if (this._designValueStrokeWrap.HasBlinkColor) {
      if (this._designValueStrokeWrap.First.HasResolvedBrush) {
        (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueStrokeWrap.First.Brush);
      }
      if (this._designValueStrokeWrap.Second.HasResolvedBrush) {
        (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueStrokeWrap.Second.Brush);
      }
    }

    // Fill Color wrap
    this._designValueFillWrap = new ColorWrap(element._designValueFill);

    // For blink colors the compound brushes are not urls. They are resolved during deserialization.
    // And added to the dynamic resources
    if (this._designValueFillWrap.HasBlinkColor) {
      if (this._designValueFillWrap.First.HasResolvedBrush) {
        (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueFillWrap.First.Brush);
      }
      if (this._designValueFillWrap.Second.HasResolvedBrush) {
        (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueFillWrap.Second.Brush);
      }
    } else {
      this._designValueFillWrap = new ColorWrap('transparent');
    }

    // Back Color wrap
    this._designValueBackgroundWrap = new ColorWrap(element._designValueFill);
    // For blink colors the compound brushes are not urls. They are resolved during deserialization.
    // And added to the dynamic resources
    if (this._designValueBackgroundWrap.HasBlinkColor) {
      if (this._designValueBackgroundWrap.First.HasResolvedBrush) {
        (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueBackgroundWrap.First.Brush);
      }
      if (this._designValueBackgroundWrap.Second.HasResolvedBrush) {
        (this.Graphic as GmsGraphic).AddDynamicResources(this._designValueBackgroundWrap.Second.Brush);
      }
    } else {
      this._designValueBackgroundWrap = new ColorWrap('transparent');
    }

    // Used for checking the blink state
    this._colorWraps.push(this._designValueStrokeWrap);
    this._colorWraps.push(this._designValueFillWrap);
    this._colorWraps.push(this._designValueBackgroundWrap);
    this._colorWraps.push(this._evaluationStrokeWrap);
    this._colorWraps.push(this._evaluationFillWrap);
    this._colorWraps.push(this._evaluationBackgroundWrap);
    this.SetBlinkColorState();

    // Used to manage the dynamic brushes
    this._evaluationColorWraps.push(this._evaluationStrokeWrap);
    this._evaluationColorWraps.push(this._evaluationFillWrap);
    this._evaluationColorWraps.push(this._evaluationBackgroundWrap);

    // Tooltip
    this.Tooltip = element.Tooltip;

    // Description
    this.Description = element.Description;

    this.InternalId = element.InternalId;

    // SelectionRef
    this.SelectionRef = element._designValueSelectionRef;
    this._designValueSelectionRef = element._designValueSelectionRef;
    this.UpdateSelectionRef();

    // Link Reference
    this._designValueLinkReference = element._designValueLinkReference;
    this.UpdateLinkReference();

    // DisableSelection
    this._disableSelection = element._disableSelection;
    this.UpdateDisableSelection();

    // Zoomable
    this.Zoomable = element.Zoomable;

    // MinVisibility
    this.MinVisibility = element.MinVisibility;

    // MaxVisibility
    this.MaxVisibility = element.MaxVisibility;

    // ScaleX, ScaleY
    this.ScaleX = element._designValueScaleFactor;
    this.ScaleY = element._designValueScaleFactor;
    this._designValueScaleFactor = element._designValueScaleFactor;

    // CommandRule
    this.CommandRule = element.CommandRule;

    // CommandParameter
    this.CommandParameter = element.CommandParameter;

    // LinkDescription
    this.LinkDescription = element.LinkDescription;

    // IsCommandTriggerEnabled
    this.IsCommandTriggerEnabled = element.IsCommandTriggerEnabled;

    // CommandTrigger
    this.CommandTrigger = element.CommandTrigger;

    // CommandDisabled
    this.CommandDisabled = element.CommandDisabled;

    // ShadowDepth
    this.ShadowDepth = element.ShadowDepth;

    // ShadowColor
    this.ShadowColor = element.ShadowColor;

    // ShadowDirection
    this.ShadowDirection = element.ShadowDirection;

    // ReplicationIndexRange
    this.ReplicationIndexRange = element._designValueReplicationIndexRange;
    this._designValueReplicationIndexRange = element._designValueReplicationIndexRange;

    // ReplicationOrientation
    this.ReplicationOrientation = element._designValueReplicationOrientation;
    this._designValueReplicationOrientation = element._designValueReplicationOrientation;

    // MaxReplicationExtent
    this.MaxReplicationExtent = element._designValueReplicationMaxExtent;
    this._designValueReplicationMaxExtent = element._designValueReplicationMaxExtent;

    // ReplicationSpace
    this.ReplicationSpace = element._designValueReplicationSpace;
    this._designValueReplicationSpace = element._designValueReplicationSpace;

    // Grayscale
    this.IsGrayscale = element.IsGrayscale;

    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('Transformation');
    this.UpdateIsHitTestVisible();
  }

  protected DeserializeEvaluations(node: Node): void {
    if (node.hasChildNodes) {
      this.CreateEvaluations(node);
    }
    this.UpdateDisableSelection();
    this.UpdateHasDatapoints();
    this.UpdateIsSelectable();
    this.UpdateIsHitTestVisible();
  }

  protected CopyEvaluations(element: GmsElement): void {

    if (element.Evaluations.size > 0) {
      element.Evaluations.forEach((value: Evaluation, key: string) => {
        const newEvaluation: Evaluation = new Evaluation();
        newEvaluation.Element = this;
        newEvaluation.CopyFrom(value);
        this._evaluations.set(newEvaluation.Property, newEvaluation);
      });

      this.UpdateDisableSelection();
      this.UpdateHasDatapoints();
      this.UpdateIsSelectable();
      this.UpdateIsHitTestVisible();
    }
  }

  public GetTransformations(): string {
    // position transform
    let transform: string = Utility.TRANSLATEOPEN + this.X + ',' + this.Y + Utility.TRANSLATECLOSE;

    if (this.Zoomable === false) {
      transform += this.getInverseMatrixString();
    }

    if (this.Angle !== 0) {
      // original code
      // transform += " rotate(" + this.Angle + "," + this._designValueWidth / 2 + "," + this._designValueHeight / 2 + ")";
      // Note: changes applied to support a coverage element's angle modification.
      const cx: number = this.IsWidthModified ? this.Width / 2 : this._designValueWidth / 2;
      const cy: number = this.IsHeightModified ? this.Height / 2 : this._designValueHeight / 2;

      transform += ' rotate(' + this.Angle + ',' + cx + ',' + cy + ')';
    }

    if (this.RotationAngle !== 0) {
      // rotate transform
      const centerX: number = this.AngleCenterX === undefined ? 0 : this.AngleCenterX;
      const centerY: number = this.AngleCenterY === undefined ? 0 : this.AngleCenterY;
      transform += 'rotate(' + this.RotationAngle + ',' + centerX.toString() + ',' + centerY.toString() + ')';
    }

    if (this.ScaleX !== 1 || this.ScaleY !== 1 || this.FlipX || this.FlipY) {
      // scale and flip transform
      const scaleX: string = this.FlipX ? '-' + this.ScaleX : this.ScaleX.toString();
      const scaleY: string = this.FlipY ? '-' + this.ScaleY : this.ScaleY.toString();

      const translatePreFlip: string = Utility.TRANSLATEOPEN + this.DesignValueWidth / 2 + ',' + this.DesignValueHeight / 2 + Utility.TRANSLATECLOSE;
      const translatePostFlip: string = Utility.TRANSLATEOPEN + this.DesignValueWidth / -2 + ',' + this.DesignValueHeight / -2 + Utility.TRANSLATECLOSE;

      transform += translatePreFlip + 'scale(' + scaleX + ',' + scaleY + ')' + translatePostFlip;
    }

    return transform;
  }

  public getInverseMatrixString(): string {
    let transform = '';

    /**
     * @NOTE:
     * The following code calculates the positioning and scaling for not zoomable elements.
     */
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic?.CurrentZoomLevel !== undefined && this.Zoomable === false) {
      const inverseScale: number = 1 / graphic.CurrentZoomLevel;

      if (Number.isNaN(inverseScale) === false) {
        const elementRef: any = document.getElementById(this.Id) as any;
        if (elementRef != null) {
          const elementRect: ClientRect = elementRef.getBoundingClientRect();
          const matrix: SVGMatrix = elementRef.getCTM();

          const centerPoint: Point = new Point((elementRect.left + elementRect.width) / 2,
            (elementRect.top + elementRect.height) / 2);

          const newCenterPoint: Point = new Point((elementRect.left + (elementRect.width * inverseScale)) / 2,
            (elementRect.top + (elementRect.height * inverseScale)) / 2);

          const displacementScale: number = 1 / (matrix.a * inverseScale);
          const displacement: Point = new Point((centerPoint.X - newCenterPoint.X) * displacementScale,
            (centerPoint.Y - newCenterPoint.Y) * displacementScale);

          const inverseScaleString = `scale(${inverseScale})`;

          const scaleX: number = this.FlipX ? -1 : 1;
          const scaleY: number = this.FlipX ? -1 : 1;
          const translateString = `translate(${displacement.X * scaleX} ${displacement.Y * scaleY})`;

          transform += inverseScaleString;
          transform += translateString;
        }
      }
    }

    return transform;
  }

  public GetVisible(): string {
    if (!this.Visible || !this.MinMaxVisibility) {
      return 'hidden';
    }

    // Command visibility support:
    if (this.CommandVM !== null && !this.GetCommandEnabled() && this.CommandVM.IsConnected &&
            this.CommandDisabledStyle === GmsElementDisabledStyle.Hidden) {
      return 'hidden';
    }
    if (this.Blinking) {
      return this.BlinkVisible ? 'inherit' : 'hidden';
    }

    return 'inherit';
  }

  public GetClipPathData(): string {
    let path: string;

    const clipLeftValue: string = this._evaluationClipLeft !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipLeft, this._designValueClipLeft, PropertyType.String) : this.ClipLeft;

    const clipTopValue: string = this._evaluationClipTop !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipTop, this._designValueClipTop, PropertyType.String) : this.ClipTop;

    const clipRightValue: string = this._evaluationClipRight !== undefined ? Evaluation.GetValue2(this
      ._evaluationClipRight, this._designValueClipRight, PropertyType.String) : this.ClipRight;

    const clipBottomValue: string = this._evaluationClipBottom !== undefined
      ? Evaluation.GetValue2(this._evaluationClipBottom, this._designValueClipBottom, PropertyType.String) : this.ClipBottom;

    // Get the values as the clip information of the element can contain percentages
    // Might be 50% of the width
    const clipLeft: number = clipLeftValue !== undefined ? Utility.ParsePercentage(clipLeftValue, this.Width) : Number.NaN;
    // Might be 50% of the height
    const clipTop: number = clipTopValue !== undefined ? Utility.ParsePercentage(clipTopValue, this.Height) : Number.NaN;
    // Might be 50% of the height
    const clipRight: number = clipRightValue !== undefined ? Utility.ParsePercentage(clipRightValue, this.Width) : Number.NaN;
    // Might be 50% of the height
    const clipBottom: number = clipBottomValue !== undefined ? Utility.ParsePercentage(clipBottomValue, this.Height) : Number.NaN;

    const topLeftX: number = Number.isNaN(clipLeft) ? -100000 : clipLeft;
    const topLeftY: number = Number.isNaN(clipTop) ? -100000 : clipTop;

    const topRightX: number = Number.isNaN(clipRight) ? 100000 : this.Width - clipRight;
    const topRightY: number = Number.isNaN(clipTop) ? -100000 : clipTop;

    const bottonRightX: number = Number.isNaN(clipRight) ? 100000 : this.Width - clipRight;
    const bottonRightY: number = Number.isNaN(clipBottom) ? 100000 : this.Height - clipBottom;

    const bottonLeftX: number = Number.isNaN(clipLeft) ? -100000 : clipLeft;
    const bottonLeftY: number = Number.isNaN(clipBottom) ? 100000 : this.Height - clipBottom;

    path = 'M';

    path += topLeftX + ',' + topLeftY + ' L' + topRightX + ',' + topRightY + ' L' + bottonRightX + ','
            + bottonRightY + ' L' + bottonLeftX + ',' + bottonLeftY + 'z';

    return path;
  }

  public EvaluationChanged(evaluation: Evaluation, propertyChangeType: PropertyChangeType): void {

    this.UpdateEvaluation(evaluation);

    if (propertyChangeType === PropertyChangeType.ValueAndStatus ||
            propertyChangeType === PropertyChangeType.Status) {

      const newEvaluationStatus: DatapointStatus = this.CalculateStatus(evaluation);

      if (newEvaluationStatus !== this.EvaluationStatus) {
        this.EvaluationStatus = newEvaluationStatus;
        this.UpdateVisible();

        // show or hide the #COM grid
        this.UpdateEvaluationStatus();

        // NOTE:  HitTestVisible
        // Vertical, horizontal and rotational sliders
        // need their IsHitTestVisiblity updated when the evaluations changes
        // if (evaluation.Property == XProperty || evaluation.Property == YProperty ||
        // evaluation.Property == AngleProperty)
        //    UpdateIsHitTestVisible();
      }
    } else if (propertyChangeType === PropertyChangeType.Format) {
      this.UpdatePropertyText(evaluation);
    } else if (propertyChangeType === PropertyChangeType.Datapoints) {

      // Update the property whenever an evaluation changes the datapoints collection
      this.UpdateHasDatapoints();
      this.setInitialSelection();
    }
  }

  public async TickAnimation(): Promise<void> {
    this.RotationAngle += this._rotationDeltaAngle;
  }

  public CreateReplication(): void {
    if (this._replication === undefined && !this._isReplicationClone) {
      this._replication = this.ReplicationService.CreateReplication(this);
      this._originalType = this.type;
      this._replication._isParentVisible = this._isParentVisible;
      // Visually changes the element view to replication view
      this.type = GmsElementType.Replication; // Indicate the view to render replication container

      if (this.Parent !== undefined) {
        // NOTE change this once the parent rendering uses track by
        this.Parent.NotifyPropertyChanged('Child Type Changed');
      }
    }
  }

  public async UpdateAlarmState(): Promise<void> {
    if (this.Zone !== undefined) {
      this.Zone.runOutsideAngular(() => {
        let alarmPropogationHandled = false;

        // For Symbols alarms will be propagated upwards
        // To the top level symbol instance
        // Don't propogate for replication clones
        if (this.Parent !== undefined && !this.IsReplicationClone) {
          alarmPropogationHandled = this.Parent.PropagateAlarm();
          if (alarmPropogationHandled) {
            this._alarmsPropagated = alarmPropogationHandled;
            return;
          }
        }

        const datapointsForAlarms: Datapoint[] = this.GetDpsForAlarms();

        // Used to update/handle multiple alarms from one or more datapoint.
        const datapointToUpdate: Datapoint[] = datapointsForAlarms.filter(datapoint => datapoint.AlarmState > AlarmState
          .None);

        if (this.Alarm !== undefined) {
          // Used to determine the removal of the Alarm after processing.
          const datapointToProcess: Datapoint[] = datapointToUpdate.filter(datapoint => datapoint.AlarmState > AlarmState
            .None && datapoint.AlarmState < AlarmState.Closed);

          // No datapoints with active Alarm.
          // Clear the Alarm
          if (datapointToProcess.length === 0) {

            if (this.AlarmsContainerRef !== undefined) {
              this.AlarmsContainerRef.RemoveAlarm(this._alarm);
            }

            this._alarm.Destroy();
            this._alarm = undefined;
            return;
          }
        }

        if (datapointToUpdate.length <= 0) {
          return;
        }

        // Add Alarm Infos
        const alarmInfos: AlarmInfo[] = [];
        datapointToUpdate.forEach(datapoint => {
          datapoint.AlarmInfos.forEach(alarmInfo => {
            alarmInfos.push(alarmInfo);
          });
        });

        // set the Alarm for the element
        if (this.Alarm === undefined) {
          this.Alarm = new GmsAlarm(this, alarmInfos);

          if (this.AlarmsContainerRef !== undefined) {
            this.AlarmsContainerRef.AddAlarm(this.Alarm);
          }

          this._alarmsStateUpdateStarted = true; // This element has started receiving alarms
        } else {
          this.Alarm.UpdateAlarmInfos(alarmInfos);
        }
      });
    }
  }

  // Only SymbolInstance or a Group needs to propogate the Alarms
  // to the top level symbol instances.
  public PropagateAlarm(): boolean {
    return false;
  }

  public GetDpsForAlarms(): Datapoint[] {
    const datapoints: Datapoint[] = this.CalculateVisible() ? this.Datapoints.filter(datapoint => datapoint.HasAlarms) : [];

    // Add Target or Command datapoint
    if (this.linkreferenceDp !== undefined && !datapoints.includes(this.linkreferenceDp)) {
      datapoints.push(this.linkreferenceDp);
    }

    // Add selectionReference datapoint
    if (this.selectionrefDp !== undefined && !datapoints.includes(this.selectionrefDp)) {
      datapoints.push(this.selectionrefDp);
    }

    return datapoints;
  }

  protected IsUseEvaluation(evaluation: Evaluation): boolean {
    return (evaluation !== undefined && evaluation.Enabled &&
            !evaluation.IsEmpty && evaluation.Value !== undefined &&
            evaluation.Status === DatapointStatus.Valid);
  }

  protected UpdateEvaluationStatus(): void {
    this.UpdateErrorBorder();
  }

  /**
   * Updates #COM status
   */
  protected UpdateErrorBorder(): void {
    const isCommandOffline: boolean = this.CommandVM !== null && !this.CommandVM.IsConnected;

    // let visible: boolean = Evaluation.GetValue2(this._evaluationVisible, this.Visible, PropertyType.Boolean);
    const visible: boolean = this.CalculateVisible();
    this.ShowErrorBorder = visible &&
            ((this.EvaluationStatus === DatapointStatus.Invalid) || this.EvaluationStatus === DatapointStatus.DoesNotExist || isCommandOffline);

  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    // Delay till the element is fully deserialized.
    if (evaluation === undefined || evaluation.Status === DatapointStatus.Pending) {
      return;
    }

    switch (evaluation.Property) {

      case 'X':
        this.UpdatePropertyX(evaluation);
        break;

      case 'Y':
        this.UpdatePropertyY(evaluation);
        break;
      case 'PositionType':
        this.UpdatePropertyPositionType(evaluation);
        break;
      case 'Width':
        this.UpdatePropertyWidth(evaluation);
        break;

      case 'Height':
        this.UpdatePropertyHeight(evaluation);
        break;

      case 'Angle':
        this.UpdatePropertyAngle(evaluation);
        break;

      case 'AngleCenterX':
        this.UpdatePropertyAngleCenterX(evaluation);
        break;

      case 'AngleCenterY':
        this.UpdatePropertyAngleCenterY(evaluation);
        break;

      case 'DisableSelection':
        this.UpdatePropertyDisableSelection(evaluation);
        break;

      case 'FlipX':
        this.UpdatePropertyFlipX(evaluation);
        break;

      case 'FlipY':
        this.UpdatePropertyFlipY(evaluation);
        break;

      case 'Visible':
        this.UpdatePropertyVisible(evaluation);
        break;

      case 'CursorType':
        this.UpdatePropertyCursorType(evaluation);
        break;
      case 'SelectionRef':
        this.UpdatePropertySelectionRef(evaluation);
        break;
      case 'LinkReference':
        this.UpdatePropertyLinkReference(evaluation);
        break;
      case 'ScaleFactor':
        this.UpdatePropertyScaleFactor(evaluation);
        break;
      case 'ClipLeft':
        this.UpdatePropertyClipLeft(evaluation);
        break;

      case 'ClipTop':
        this.UpdatePropertyClipTop(evaluation);
        break;

      case 'ClipRight':
        this.UpdatePropertyClipRight(evaluation);
        break;

      case 'ClipBottom':
        this.UpdatePropertyClipBottom(evaluation);
        break;

      case 'Blinking':
        this.UpdatePropertyBlinking(evaluation);
        break;

      case 'BackColor':
        this.UpdatePropertyBackground(evaluation);
        break;

      case 'StrokeColor':
        this.UpdatePropertyStroke(evaluation);
        break;

      case 'FillColor':
        this.UpdatePropertyFill(evaluation);
        break;

      case 'Opacity':
        this.UpdatePropertyOpacity(evaluation);
        break;

      case 'CommandDisabledStyle':
        this.UpdatePropertyCommandDisabledStyle(evaluation);
        break;

      case 'RotationSpeed':
        this.UpdatePropertyRotationSpeed(evaluation);
        break;

      case 'RotationSteps':
        this.UpdatePropertyRotationSteps(evaluation);
        break;

      case 'CommandParameter':
        this.UpdatePropertyCommandParameter(evaluation);
        break;

      case 'CommandRule':
        this.UpdatePropertyCommandRule(evaluation);
        break;

      case 'CommandDisabled':
        this.UpdatePropertyCommandDisabled(evaluation);
        break;

      case 'IsCommandTriggerEnabled':
        this.UpdatePropertyCommandTriggerEnabled(evaluation);
        break;

      case 'ReplicationIndexRange':
        this.UpdatePropertyReplicationIndexRange(evaluation);
        break;

      case 'ReplicationOrientation':
        this.UpdatePropertyReplicationOrientation(evaluation);
        break;

      case 'MaxReplicationExtent':
        this.UpdatePropertyReplicationMaxExtent(evaluation);
        break;

      case 'ReplicationSpace':
        this.UpdatePropertyReplicationSpace(evaluation);
        break;

      case 'CoverageAreaReference':
        this.UpdatePropertyCoverageAreaReference(evaluation);
        break;

      case 'IsGrayscale':
        this.UpdatePropertyGrayscale(evaluation);
        break;
      case 'ShadowDepth':
        this.UpdatePropertyShadowDepth(evaluation);
        break;
      case 'ShadowColor':
        this.UpdatePropertyShadowColor(evaluation);
        break;
      case 'ShadowDirection':
        this.UpdatePropertyShadowDirection(evaluation);
        break;
      case 'Tooltip':
        this.UpdatePropertyTooltip(evaluation);
        break;
      default:
        break;
    }
    this.UpdateHasDatapoints();
  }

  protected CalculateStatus(evaluation: Evaluation): DatapointStatus {

    let newEvaluationStatus: DatapointStatus = DatapointStatus.Undefined;

    if (evaluation.Status === DatapointStatus.Hide) {
      newEvaluationStatus = DatapointStatus.Hide;
    } else if (evaluation.Status === DatapointStatus.DoesNotExist) {
      newEvaluationStatus = DatapointStatus.DoesNotExist;
    } else {
      newEvaluationStatus = this.GetEvaluationStatus(evaluation);
    }

    return newEvaluationStatus;
  }

  protected GetEvaluationStatus(evaluation: Evaluation): DatapointStatus {

    const evaluations: Evaluation[] = this.Evaluations !== undefined ? Array.from(this.Evaluations.values()) : undefined;

    if (evaluations === undefined) {
      return DatapointStatus.Valid;
    }

    // Evaluation still deserializing, might not be in Evaluations map
    if (!evaluations.includes(evaluation)) {
      if (evaluation !== undefined) {
        evaluations.push(evaluation);
      } else {
        return DatapointStatus.Valid;
      }
    }

    // 1. Check the Hidden status
    for (let i = 0; i < evaluations.length; i++) {
      if (evaluations[i].Status === DatapointStatus.Hide) {
        return DatapointStatus.Hide;
      }
    }
    // 2. Check the DoesNotExist status
    for (let i = 0; i < evaluations.length; i++) {
      if (evaluations[i].Status === DatapointStatus.DoesNotExist) {
        return DatapointStatus.DoesNotExist;
      }
    }
    // 3. Check the Invalid status
    for (let i = 0; i < evaluations.length; i++) {
      if (evaluations[i].Status === DatapointStatus.Invalid) {
        return DatapointStatus.Invalid;
      }
    }
    return DatapointStatus.Valid;
  }

  protected CalculateVisible(): boolean {
    // check Parent's visibility
    if (!this._isParentVisible) {
      return false;
    }
    // check Element's visibility
    const visible: boolean = Evaluation.GetValue2(this._evaluationVisible, this._designValueVisible, PropertyType.Boolean);
    if (!visible) {
      return false;
    }
    // check Element's calculated MinMax visibility
    if (!this.MinMaxVisibility) {
      return false;
    }

    // Command is misconfigured: hide it
    if (this.CommandVM !== null && !this.CommandVM.IsUIConfigurationValid) {
      return false;
    }
    if (this.EvaluationStatus === DatapointStatus.Hide) {
      // hide the entire element if at least one datapoint, expression or evaluation is hidden
      return false;
    }
    if (this.EvaluationStatus === DatapointStatus.DoesNotExist) {
      // hide the element
      return false;
    }
    if (this.EvaluationStatus === DatapointStatus.Invalid) {
      // show the #COM so it has to be visible
      return true;
    }
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic !== undefined && !graphic.CoverageAreaMode && this.CoverageArea) {
      return false;
    }

    // NOTE: check visibility range
    // if (!GraphicView.DisableLayerVisibilityRange) {
    //    double roundedZoomFactor = Math.Round(GraphicView.Child.Zoom, Utility.RoundingDigits);

    //    double displaySize = GraphicView.Child.Width / roundedZoomFactor;
    //    if (!double.IsNaN(MinVisibility) && displaySize < MinVisibility)
    //        return false;

    //    if (!double.IsNaN(MaxVisibility) && displaySize > MaxVisibility)
    //        return false;
    // }

    // Selection Ref
    // Hide element if the SelectionRef is unresolved at loading time, so that the element does NOT show and then hide again
    if (this.SelectionRef !== undefined && this.SelectionRef !== '') {
      const datapoint: Datapoint = this.DatapointService.GetByDesignation(this.SelectionRef);
      if (datapoint !== undefined && datapoint.Status === DatapointStatus.DoesNotExist) {
        return false;
      }
    }

    let disabled = false;
    if (this.CommandVM !== null) {
      // BL/UI Disable - apply disabled style
      disabled = !this.CommandVM.IsEnabled;
      if (!disabled /* NOTE: && IsCommandableGrouped */) {
        // Grouped element: has disabled properties too
        disabled = this.GetAnimatedCommandDisabled();
      }
    }

    let disabledStyle: any = Evaluation.GetValue2(this._evaluationCommandDisabledStyle,
      GmsElementDisabledStyle[this.CommandDisabledStyle], PropertyType.String);
    disabledStyle = GmsElementDisabledStyle[disabledStyle as string];

    if (disabled && this.CommandVM.IsConnected && disabledStyle === GmsElementDisabledStyle.Hidden) {
      return false;
    }

    if (this.IsSlidingClone) {
      return false;
    }
    return true;
  }
  /*
    protected UpdateOpacity(): void {
        let opacity: number = Evaluation.GetValue2(this._evaluationOpacity, this._designOpacity, PropertyType.Number);
        if (opacity === undefined) {
            opacity = GmsElement.DEFAULT_OPACITY;
        }
        // let opacity: number = Number(value);
        // Coerce it
        if (Number.isNaN(opacity) || !Number.isFinite(opacity)) {
            opacity = GmsElement.DEFAULT_OPACITY;
        }
        if (opacity < 0) {
            opacity = 0;
        }
        if (opacity > 1) {
            opacity = opacity / 100;
        }

        if (this.CommandVM !== null) {
            if (!this.CommandVM.IsEnabled) {
                if (this.IsSlidingClone) {
                    opacity = 0;
                }
                else {
                    let style: any = Evaluation.GetValue2(this._evaluationCommandDisabledStyle,
                        GmsElementDisabledStyle[this.CommandDisabledStyle], PropertyType.String);
                    style = GmsElementDisabledStyle[<string>style];
                    if (style === GmsElementDisabledStyle.Grayed) {
                        opacity *= 0.4;
                    }
                    else if (style === GmsElementDisabledStyle.None) {
                        opacity = GmsElement.DEFAULT_OPACITY;
                    }
                }
            }
            else if (this.IsSlidingClone) {
                opacity *= 0.3;
            }
        }
        this.Opacity = opacity;
    }
*/
  protected UpdateOpacity(): void {
    let opacity: number = Evaluation.GetValue2(this._evaluationOpacity, this._designOpacity, PropertyType.Number);
    if (opacity === undefined) {
      opacity = GmsElement.DEFAULT_OPACITY;
    }
    if (Number.isNaN(opacity) || !Number.isFinite(opacity)) {
      opacity = GmsElement.DEFAULT_OPACITY;
    }
    if (opacity < 0) {
      opacity = 0;
    }
    if (opacity > 1) {
      opacity = opacity / 100;
    }
    const disabled: boolean = Evaluation.GetValue2(this._evaluationCommandDisabled, this.CommandDisabled, PropertyType.Boolean);

    if (this.CommandVM !== null) {
      if (!this.CommandVM.IsEnabled) {
        if (this.IsSlidingClone) {
          opacity = 0;
        } else {
          opacity = this.CalculateDisableElementOpacity(opacity, true);
        }
      } else if (this.IsSlidingClone) {
        opacity *= 0.3;
      }
    } else {
      opacity = this.CalculateDisableElementOpacity(opacity, disabled);
    }
    this.Opacity = opacity;
  }

  public UpdateVisible(): void {
    this.Visible = this.CalculateVisible();

    if (this._alarmsPropagated || this._alarmsStateUpdateStarted) {
      this.UpdateAlarmState();
    }
  }
  public UpdateCA(): void {
    this.UpdateVisible();
    this.NotifyPropertyChanged('Scale');
  }

  public UpdateBlinkColors(): void {

    const fillColor: Color = this._evaluationFillWrap.HasBlinkColor ? this
      ._evaluationFillWrap.Next() : (this._designValueFillWrap.HasBlinkColor ? this._designValueFillWrap.Next() : undefined);
    if (fillColor !== undefined) {
      this._fill = fillColor.ColorString;
      this._fillOpacity = fillColor.Opacity;
    }

    const strokeColor: Color = this._evaluationStrokeWrap.HasBlinkColor ? this
      ._evaluationStrokeWrap.Next() : (this._designValueStrokeWrap.HasBlinkColor ? this._designValueStrokeWrap.Next() : undefined);
    if (strokeColor !== undefined) {
      this._stroke = strokeColor.ColorString;
      this._strokeOpacity = strokeColor.Opacity;
    }

    const backColor: Color = this._evaluationBackgroundWrap.HasBlinkColor ? this
      ._evaluationBackgroundWrap.Next() : (this._designValueBackgroundWrap.HasBlinkColor ? this._designValueBackgroundWrap.Next() : undefined);
    if (backColor !== undefined) {
      this._background = backColor.ColorString;
      this._backgroundOpacity = backColor.Opacity;
    }
    this.NotifyPropertyChanged('BlinkColors');
  }

  protected async UpdateRotation(): Promise<void> {
    let rpm = 0;
    let rotationSteps = 0;
    let invertRotation = false;
    let handIndex = -1; // -1=no hand, 0=h, 1=m, 2=s

    // the RotationSpeed can be either a number (as string) or "h", "H", "m", "M", "s" or "S"
    const rotationSpeed: string = Evaluation.GetValue2(this._evaluationRotationSpeed, this._designValueRotationSpeed, PropertyType.String);
    if (rotationSpeed !== undefined && rotationSpeed.length === 1) {
      handIndex = 'hms'.indexOf(rotationSpeed.toLowerCase());
    }

    if (handIndex !== -1) {
      const now: Date = new Date();
      if (handIndex === 0) { // hour
        rpm = 1 / 60 / 12;
        rotationSteps = 0; // smooth animation
        this.RotationAngle = (now.getHours() + now.getMinutes() / 60 + now.getSeconds() / 60 / 60) / 24 * 720;
      } else if (handIndex === 1) { // minute
        rpm = 1 / 60;
        rotationSteps = 0; // smooth animation
        this.RotationAngle = (now.getMinutes() + now.getSeconds() / 60) / 60 * 360;
      } else if (handIndex === 2) { // second
        rpm = 1;
        rotationSteps = 60; // only every second
        this.RotationAngle = now.getSeconds() / 60 * 360;
      }
    } else {
      const parsedRpm: number = parseFloat(rotationSpeed);
      rpm = !Number.isNaN(parsedRpm) ? parsedRpm : 0;

      if (rpm === 0) {
        // stop rotating
        this.AnimationTimerService.unsubscribe(this);
        this._rotationInterval = 0;
      } else {
        invertRotation = rpm < 0;
        if (invertRotation) {
          rpm *= -1;
        }

        rotationSteps = Evaluation.GetValue2(this._evaluationRotationSteps, this._designValueRotationSteps, PropertyType.Number);
      }
    }

    if (rpm !== 0) {
      const minDelay: number = 1000 / this._rotationMaxFps; // min delay in milliseconds

      let delay: number = rotationSteps > 0 ? (60 / rpm) * 1000 / rotationSteps : 0;
      if (delay < minDelay) {
        delay = minDelay;
        const rps: number = rpm / 60; // rps, rotations per second
        this._rotationDeltaAngle = 360 / ((1000 / delay) / rps);
        if (this._rotationDeltaAngle < this._rotationMinDeltaAngle) {
          this._rotationDeltaAngle = this._rotationMinDeltaAngle;
          delay = (this._rotationDeltaAngle * 1000 / rps) / 360;
        }
      } else {
        this._rotationDeltaAngle = 360 / rotationSteps;
      }

      if (invertRotation) {
        this._rotationDeltaAngle *= -1;
      }

      const newInterval: number = delay;
      if (newInterval !== this._rotationInterval) {
        this.AnimationTimerService.unsubscribe(this);
        this._rotationInterval = newInterval;
        this.AnimationTimerService.subscribe(this, this._rotationInterval);
      }
    }
  }

  /**
   * Adds or updates a collection of substitutions
   * @param substitutions a collection of substitutions
   */
  public AddOrUpdateSubstitutions(substitutions: Map<string, Substitution>): void {
    // get all substitutions from all evaluations
    if (this.Evaluations != null) {
      this.Evaluations.forEach((evaluation: Evaluation) => {
        evaluation.Expressions.forEach((expression: Expression) => {
          expression.Substitutions.forEach((substitution: Substitution) => {
            this.AddOrUpdate(substitutions, substitution, evaluation); // the evaluation is the substitution source
          });
        });
      });
    }
  }

  /**
   * Adds/updates the new substitution to/in the substitutions collection
   * @param substitutions The substitution collection
   * @param newSubst The new substitution. If it alreay exists, it updates the default value (if not null)
   * @param substitutionSource The substitution source of the new substitution (can be an evaluation or another substitution)
   */
  protected AddOrUpdate(substitutions: Map<string, Substitution>, newSubst: Substitution, substitutionSource: SubstitutionSource): void {

    if (newSubst.Value === undefined) {
      newSubst.Value = '';
    }

    const key: string = newSubst.Name.toLowerCase();
    let substitution: Substitution = substitutions.get(key);
    if (substitution === undefined) {
      substitutions.set(key, newSubst); // doesn't exist yet - so add it
      substitution = newSubst;
    } else {
      if ((substitution.Value === undefined || substitution.Value === '') && newSubst.Value !== '') {
        substitution.Value = newSubst.Value; // already exists but use the latest non-empty default value
      }
    }

    if (substitutionSource !== undefined && !substitution.SubstitutionSources.includes(substitutionSource)) {
      substitution.SubstitutionSources.push(substitutionSource);
    }
  }

  // Called only for first level children of GmsGroup or GmsSymbolInstance
  public UpdateWidthResize(factor: number): void {
    if (!this.IsUseEvaluation(this._evaluationWidth)) {
      this.Width = this.BoundingRectDesign.Width * factor;
      this.DesignValueWidth = this.Width;
    }

    this.ResizeFactorWidth = factor;
    if (this.InternalId === null) {
      if (!this.IsUseEvaluation(this._evaluationX)) {
        this.X = this.BoundingRectDesign.X * factor;
      } else {
        this.UpdatePropertyX(this._evaluationX);
      }
    }
  }

  // Called only for first level children of GmsGroup or GmsSymbolInstance
  public UpdateHeightResize(factor: number): void {
    if (!this.IsUseEvaluation(this._evaluationHeight)) {
      this.Height = this.BoundingRectDesign.Height * factor;
      this.DesignValueHeight = this.Height;
    }

    this.ResizeFactorHeight = factor;
    if (this.InternalId === null) {
      if (!this.IsUseEvaluation(this._evaluationY)) {
        this.Y = this.BoundingRectDesign.Y * factor;
      } else {
        this.UpdatePropertyY(this._evaluationY);
      }
    }
  }
  /**
   * ca
   * @param factor
   * @param parentFactor
   */
  public UpdateInternalHeightResize(factor: number, parentFactor: number): void {
    // NOTE: some clean up will be applied when tests done
    // this.Height = this.BoundingRectDesign.Height * factor;
    // this.DesignValueHeight = this.Height;

    this.ResizeFactorHeight = parentFactor;

    // this.UpdatePropertyY(this._evaluationY);
    this.NotifyPropertyChanged('Y');
    this.NotifyShapeChanged();
    this.ShapeChanged();
  }

  public UpdateInternalWidthResize(factor: number, parentFactor: number): void {
    // NOTE: some clean up will be applied when tests done
    // this.Width = this.BoundingRectDesign.Width * factor;
    // this.DesignValueWidth = this.Width;

    this.ResizeFactorWidth = parentFactor;

    // this.UpdatePropertyX(this._evaluationX);
    this.NotifyPropertyChanged('X');
    this.NotifyShapeChanged();
    this.ShapeChanged();
  }

  public Destroy(): void {

    // this._evaluations.Values.forEach(evaluation => evaluation.Clear(true));

    this._evaluations.forEach((evaluation, key, map) => {
      evaluation.Clear(true);
    });
    this._evaluations.clear();

    this._evaluationX = undefined;
    this._evaluationY = undefined;
    this._evaluationPositionType = undefined;
    this._evaluationWidth = undefined;
    this._evaluationHeight = undefined;
    this._evaluationAngle = undefined;
    this._evaluationAngleCenterX = undefined;
    this._evaluationAngleCenterY = undefined;
    this._evaluationFlipX = undefined;
    this._evaluationFlipY = undefined;
    this._evaluationVisible = undefined;
    this._evaluationBackground = undefined;
    this._evaluationFill = undefined;
    this._evaluationStroke = undefined;
    this._evaluationCursorType = undefined;
    this._evaluationSelectionRef = undefined;
    this._evaluationLinkReference = undefined;
    this._evaluationDisableSelection = undefined;
    this._evaluationCoverageAreaReference = undefined;
    this._evaluationCommandDisabled = undefined;
    this._evaluationTooltip = undefined;
    this._evaluationClipLeft = undefined;
    this._evaluationClipTop = undefined;
    this._evaluationClipRight = undefined;
    this._evaluationClipBottom = undefined;
    this._evaluationBlinking = undefined;
    this._evaluationOpacity = undefined;
    this._evaluationCommandDisabledStyle = undefined;
    this._evaluationScaleFactor = undefined;
    this._evaluationRotationSpeed = undefined;
    this._evaluationRotationSteps = undefined;
    this._evaluationCommandRule = undefined;
    this._evaluationCommandParameter = undefined;
    this._evaluationGrayscale = undefined;
    this.Parent = undefined;

    // clear the color wrap objects
    this.ElementBrushService?.forceRemoveElement(this);
    this._colorWraps.forEach(colorwrap => colorwrap.Clear);
    this._colorWraps.length = 0;
    this._evaluationColorWraps.length = 0;
    this._evaluationFillWrap = undefined;
    this._evaluationStrokeWrap = undefined;
    this._evaluationBackgroundWrap = undefined;
    this._activeBrushUrls.length = 0;

    if (this.Replication !== undefined) {
      this.Replication.Destroy();
    }
    this.propertyChanged.unsubscribe();
    this.selectionChanged.unsubscribe();
    if (this.subExecuteStatus && !this.subExecuteStatus.closed) {
      this.subExecuteStatus.unsubscribe();
    }
    if (this.shapeChanged !== undefined) {
      this.shapeChanged.unsubscribe();
    }

    if (this._targetNavigationDatapointSub !== undefined && !this._targetNavigationDatapointSub.closed) {
      this._targetNavigationDatapointSub.unsubscribe();
      this._targetNavigationDatapointSub = undefined;
    }

    if (this._alarm !== undefined) {
      // alarms are destroyed by the alarm container - graphic or replication
      this._alarm = undefined;
    }

    if (this._alarmsContainer !== undefined) {
      this._alarmsContainer = undefined;
    }

    // commanding
    if (this.CommandVM !== null) {
      this.CommandVM.unsubscribe();
      this.CommandVM = null;
    }

    if (this._linkReferenceDpSubscription !== undefined) {
      this._linkReferenceDpSubscription.unsubscribe();
      this._linkReferenceDpSubscription = undefined;
    }
    if (this.linkreferenceDp !== undefined) {
      this.linkreferenceDp = undefined;
    }

    if (this._selectionReferenceDpSubscription !== undefined) {
      this._selectionReferenceDpSubscription.unsubscribe();
      this._selectionReferenceDpSubscription = undefined;
    }
    if (this.selectionrefDp !== undefined) {
      this.selectionrefDp = undefined;
    }
  }

  public AddInterpolatedBrushes(color: Color): void {
    if (color === undefined) {
      return;
    }
    if (color.HasResolvedBrush) {
      if (!this._activeBrushUrls.includes(color.Brush.Url)) {
        this._activeBrushUrls.push(color.Brush.Url);
        (this.Graphic as GmsGraphic).AddDynamicResources(color.Brush);
      }
    }
  }

  public unSelect(): void {
    this.IsSelected = false;
  }

  public sendSecondarySelection(): void {
    if (this.Datapoints !== undefined) {
      // extract desisagmations from element's datapoints
      // and ship it to your object reference service
    }
  }

  /**
   * Update adorner dimensions
   */
  public UpdateAdornerDimensions(): void {
    this.AdornerWidth = this.Width;
    this.AdornerHeight = this.Height;
  }
  protected UpdatePropertyText(evaluation: Evaluation): void {
    // see GmsText
  }
  /**
   * Calculate HasDatapoints on dependency values
   */
  public UpdateHasDatapoints(): void {
    let hasEvalDatapoint = false;

    if (!!this.Evaluations) {
      const evaluations: Evaluation[] = Array.from(this.Evaluations.values());
      hasEvalDatapoint = !!evaluations.find((curEval: Evaluation) => curEval.Datapoints.length > 0);
    }

    this.HasDatapoints = !!this.SelectionRef || hasEvalDatapoint;
  }

  /**
   * Calculate IsHitTestVisible on dependency values
   */
  public UpdateIsHitTestVisible(): void {
    const type: GmsElementType = this.Type;
    let curIsHitTestVisible = false;
    if (type === GmsElementType.Graphic) {
      curIsHitTestVisible = true;
    } else if (!this.Visible) {
      curIsHitTestVisible = false;
    } else if (this.DisableSelection) {
      // True if disable selection of parent's or current element's is true.
      // Defect 2227054
      // curIsHitTestVisible = false;
      curIsHitTestVisible = this.IsSelectable || this.IsTargetNavigable || this.CommandVM !== null;
    } else if (this.CoverageArea) {
      curIsHitTestVisible = (this.Graphic as GmsGraphic).CoverageAreaMode;
    } else if (this.IsSlidingThumb) {
      curIsHitTestVisible = true;
    } else if (this.IsGroupBased) {
      curIsHitTestVisible = this.IsSelectable || this.IsTargetNavigable || this.CommandVM !== null;
      if (!!this.children) {
        this.children.forEach((element: GmsElement) => {
          element.IsHitTestVisible = curIsHitTestVisible;
        });
      }
    } else if (!!this.Tooltip) {
      curIsHitTestVisible = true;
    } else if (this.CommandVM !== null) {
      curIsHitTestVisible = this.CommandVM.IsCommandEnabled && this.CommandVM.IsTriggerEnabled;
      if (!!this.children) {
        this.children.forEach((element: GmsElement) => {
          element.IsHitTestVisible = curIsHitTestVisible;
        });
      }
    } else {
      const disabled: boolean = Evaluation.GetValue2(this._evaluationCommandDisabled, this.CommandDisabled, PropertyType.Boolean);

      if (!disabled) {
        // Defect 1688960
        const group: GmsElement = GmsElement.FindVisualAncesterByType(this, GmsElementType.Group);
        curIsHitTestVisible = group?.IsSelectable || group?.IsTargetNavigable || this._isSelectable || this._isTargetNavigable || this.HasDatapoints;
      }
    }

    this.IsHitTestVisible = curIsHitTestVisible;
  }

  /**
   * Calculate IsSelectable on dependency values
   */
  public UpdateIsSelectable(): void {
    this.IsSelectable = this.CommandVM == null && !this._disableSelection &&
            (!!this._selectionRef || this._hasDatapoints) && this.Visible && this.noDisableSelectionAncestor();
  }

  public noDisableSelectionAncestor(): boolean {
    let element: GmsElement = this as GmsElement;

    let noDisableSelectionAncestorBool = true;
    while (element.Parent != null && noDisableSelectionAncestorBool) {
      const parent: GmsElement = element.Parent;
      noDisableSelectionAncestorBool = noDisableSelectionAncestorBool && parent.DisableSelection === false;
      element = element.Parent;
    }

    return noDisableSelectionAncestorBool;
  }

  /**
   * Calculate IsTargetNavigable on dependency values
   */
  public UpdateIsTargetNavigable(): void {
    this.IsTargetNavigable = !this.HasCommand && !!this._linkReference;
  }

  /**
   * Calculate DisableSelection on dependency values
   */
  public UpdateDisableSelection(): void {
    const evalDisableSelection: boolean = Evaluation.GetValue2(this._evaluationDisableSelection, this.DisableSelection, PropertyType.Boolean);
    this.DisableSelection = evalDisableSelection || this._isParentDisableSelection;
  }

  /**
   * Process a selection event on this element
   */
  public processSelection(multiSelect: boolean = false): void {
    if (this.IsSelectable) {
      const designations: string[] = this.Datapoints.map((dp: Datapoint) => dp.Designation);

      // ctrl click on a selected element must unselect
      if (multiSelect && this.IsSelected) {
        this.GmsObjectSelectionService.removeSelections(designations, [this]);
        this.IsSelected = false;
        return;
      }

      this.GmsObjectSelectionService.setSelections(designations, [this], !multiSelect);
      this.IsSelected = true;
    }
  }

  /**
   * Process a command execution
   **/
  public ExecuteCommand(): Observable<GmsCommandResult> {
    return Observable.create((observer: Observer<GmsCommandResult>) => {
      if (this.CanExecuteCommand && this.Zone !== undefined) {
        this.Zone.runOutsideAngular(() =>
          this.CommandVM.executeCommand(
            this.ValidationService, this.ExecuteCommandService, observer, this.locale));
      }
    });
  }

  /**
   * Process a target navigation event on this element
   */
  public processTargetNavigation(): void {
    this.TraceService.info(TraceChannel.Component,
      'processTargetNavigation: for _linkReference %s = ', this._linkReference);
    // link can be e.g. 'notepad C:\my text.txt' or 'notepad "C:\my text.txt"' or '"notepad C:\my text.txt"'  --> not supported by Flex
    if (this._linkReference !== null && this._linkReference.length > 0 && this._linkReference[0] !== '"') {

      if (Utility.URLpatternRegEx.test(this._linkReference)) {
        window.open(this._linkReference);
      } else {
        const datapoint: Datapoint = this.DatapointService.GetOrCreateByDesignation(this._linkReference, false);

        if (datapoint.Status <= DatapointStatus.Pending) {
          if (this._targetNavigationDatapointSub !== undefined && !this._targetNavigationDatapointSub.closed) {
            this._targetNavigationDatapointSub.unsubscribe();
            this._targetNavigationDatapointSub = undefined;
          }
          this._targetNavigationDatapointSub =
                        datapoint.propertyChanged.subscribe(args => this.ResolveTargetNavigationDatapoint(args.SourceDatapoint));
        } else {
          if (datapoint.Status === DatapointStatus.DoesNotExist) {
            this.tryOpenURL();
          } else {
            this.processNavigationTargets();
          }
        }
      }
    }
  }

  /**
   * Process a navigation event on this element
   */
  public processNavigation(): void {
    const navigationTargets: string[] = this.Datapoints.map((datapoint: Datapoint) => datapoint.Designation)
      .filter((designation, index, self) => self.indexOf(designation) === index);

    this.GmsObjectSelectionService.clearNavigations();
    this.GmsObjectSelectionService.addNavigations(navigationTargets);
    this.GmsObjectSelectionService.executeNavigation();
  }

  /**
   * Initialize this element to selected if it has a datapoint matching the primary selection.
   */
  public setInitialSelection(): void {
    let primarySelection = '';
    let primarySelectionObjectId = '';
    let primarySelectionDefaultProperty = '';

    // Check existence of primary selection
    if (!!this._graphic && !!this._graphic.SelectedObject && !!this._graphic.SelectedObject.Designation) {
      primarySelection = this._graphic.SelectedObject.Designation;
      primarySelectionObjectId = this._graphic.SelectedObject.ObjectId;
      // eslint-disable-next-line
            primarySelectionDefaultProperty = this._graphic.SelectedObject.Attributes["DefaultProperty"];
      primarySelectionDefaultProperty = primarySelectionDefaultProperty !== undefined ? primarySelectionDefaultProperty.trim() : '';
    } else {
      return;
    }

    if (this._isSelectable) {
      let datapoints: Datapoint[] = [];
      let datapointIds: string[] = [];
      let objectIds: string[] = [];

      const graphicInstance: GmsGraphic = this.Graphic as GmsGraphic;

      const isTemplate: boolean = graphicInstance.GraphicType === GraphicType.GraphicTemplate;

      // Do not show adorner for the object-reference/context of the graphic template
      // Only sub point selection must be adorned
      if (isTemplate) {
        // Get the actual object ref datapoint
        const objectRefDp: Datapoint = this.DatapointService.GetByDesignation(graphicInstance.ObjectReference);

        if (objectRefDp?.ObjectId !== undefined) {
          datapoints = this.Datapoints.filter((dp: Datapoint) => dp.Status > DatapointStatus.Pending
                        && (dp.ObjectId !== undefined && dp.ObjectId !== objectRefDp.ObjectId));
        }
      } else {
        datapoints = this.Datapoints.filter((dp: Datapoint) => dp.Status > DatapointStatus.Pending);
      }

      // designation strings from all datapoints owned by this element
      datapointIds = datapoints.map((dp: Datapoint) => dp.Designation);
      objectIds = datapoints.map((dp: Datapoint) => dp.ObjectId);

      // check with designation
      let match = !!datapointIds.find((id: string) => id !== undefined
                && id.replace(/;$/, '') === primarySelection.replace(/;$/, ''));

      if (!match) { // check with object id
        match = !!objectIds.find((id: string) => id !== undefined
                    && id === primarySelectionObjectId);
      }

      // check datapoints for matches with primary selection
      if (match) {
        this.GmsObjectSelectionService.addSelections([primarySelection], [this], true);
        this._autoSelectionInProgress = true;
        this.IsSelected = true;
        this._autoSelectionInProgress = false;
      }
    }
  }

  /**
   * Calculate and return datapoint references from evaluations and selection reference property
   */
  public get Datapoints(): Datapoint[] {
    const objectIds: Datapoint[] = new Array<Datapoint>();
    let datapoint: Datapoint;
    // 1. add the object from the property 'SelectionRef', if any
    const selectionRef: string = this.GetAnimatedSelectionRef();
    if (selectionRef !== undefined && selectionRef !== '') {
      datapoint = this.DatapointService.GetByDesignation(selectionRef);
      if (datapoint !== undefined) {
        objectIds.push(datapoint);
      } else {
        this.TraceService.info(this.traceModule,
          'GlobalDatapoints does not contain datapoint for SelectionRef %s in', selectionRef);
      }
    }
    // 2. add the object from 'LinkReference', if any
    // let linkRef: string = this.GetAnimatedLinkRef();
    // if (!!linkRef) {
    //    datapoint = this.DatapointService.GetByDesignation(linkRef);
    //    if (!!datapoint) {
    //        objectIds.push(datapoint);
    //    } else {
    //        this.TraceService.warn(this.traceModule,
    //            `GlobalDatapoints does not contain a datapoint for LinkReference ${linkRef}.`);
    //    }
    // }
    // 3. add the objects from all evaluations, if any
    if (this.Evaluations !== undefined && this.Evaluations.size !== 0) {
      const evaluations: Evaluation[] = Array.from(this.Evaluations.values());
      for (let i = 0; i < evaluations.length; i++) {
        for (let j = 0; j < evaluations[i].Datapoints.length; j++) {
          datapoint = evaluations[i].Datapoints[j];
          objectIds.push(datapoint);
        }
      }
    }

    return objectIds;
  }

  public GetMaxReplicationExtent(): number {
    let maxReplicationExtent = 0;

    if (this.ReplicationOrientation === GmsElementReplicationOrientationType.Horizontal) {
      maxReplicationExtent = Utility.ParsePercentage(this._maxReplicationExtent, this._Width);
    } else {
      maxReplicationExtent = Utility.ParsePercentage(this._maxReplicationExtent, this._Height);
    }

    return maxReplicationExtent;
  }

  public GetReplicationSpace(): number {
    let replicationSpace = 0;

    if (this.ReplicationOrientation === GmsElementReplicationOrientationType.Horizontal) {
      replicationSpace = Utility.ParsePercentage(this._replicationSpace, this._Width);
    } else {
      replicationSpace = Utility.ParsePercentage(this._replicationSpace, this._Height);
    }

    return replicationSpace;
  }

  protected GetAnimatedLinkReference(): string {
    const linkReference: string = this._evaluationLinkReference !== undefined &&
            this._evaluationLinkReference.LastDatapoint != null ?
      this._evaluationLinkReference.LastDatapoint.Designation :
      Evaluation.GetValue2(this._evaluationLinkReference, this.LinkReference, PropertyType.String);

    return GraphicsDatapointHelper.RemoveLeadingSemicolons(linkReference);
  }
  protected GetAnimatedStrokeColor(): string {
    const defaultColorString: string = this._designValueStrokeWrap.HasBlinkColor ?
      this._designValueStrokeWrap.ActualColorString : this._designValueStrokeWrap.Current.ColorString;

    const colorValue: string = Evaluation.GetValue2(this._evaluationStroke, defaultColorString, PropertyType.Color);
    return colorValue;
  }

  // Commanding
  protected UpdateCommandCursor(): void {
    if (this.CommandVM != null) {
      if (this.GetCommandEnabled()) {
        this.CursorType = this.IsSpinButton() || this.GetAnimatedIsCommandTriggerEnabled() ||
                    this.FindTopCommandTriggerGroup(this) !== undefined
          ? GmsElementCursorType.Hand : GmsElementCursorType.Default;
      } else {
        this.CursorType = GmsElementCursorType.Default;
      }
      if (!!this.children) {
        this.children.forEach((element: GmsElement) => { element.CursorType = this.CursorType; });
      }
    }
  }

  // Commanding
  public get CanExecuteCommand(): boolean {
    return this.CommandVM !== null &&
            this.CommandVM.IsCommandEnabled &&
            this.GetAnimatedIsCommandTriggerEnabled();
  }

  // Commanding
  public GetAnimatedCommandTriggerType(): GmsCommandTriggerTypes {
    let result: any = Evaluation.GetValue2(this._evaluationCommandTrigger, GmsCommandTriggerTypes[this.CommandTrigger], PropertyType.String);
    result = GmsCommandTriggerTypes[result as string];
    return result;
  }

  // Commanding
  protected GetAnimatedIsCommandTriggerEnabled(): boolean {
    const param: boolean = Evaluation.GetValue2(this._evaluationCommandTriggerEnabled, this.IsCommandTriggerEnabled, PropertyType.Boolean);
    return param;
  }

  // Commanding
  protected GetAnimatedCommandDisabled(): boolean {
    return Evaluation.GetValue2(this._evaluationCommandDisabled, this.CommandDisabled, PropertyType.Boolean);
  }

  // Commanding
  protected GetAnimatedCommandParameters(): string {
    const param: string = Evaluation.GetValue2(this._evaluationCommandParameter, this.CommandParameter, PropertyType.String);
    return param;
  }

  // Commanding
  protected GetAnimatedCommandRule(): string {
    let commandRule: string = Evaluation.GetValue2(this._evaluationCommandRule, this.CommandRule, PropertyType.String);

    if (!!commandRule) {
      // It's either just the rule name or label with rule name
      // "Write" or "Command [Write]"
      // GlobalPersistency.LoadCommandRuleEntriesAsync() adds the label and name
      let start: number = commandRule.indexOf('[');
      if (start >= 0) {
        // find the command rule name in "Command [Write]", which is text between square brackets
        start += 1;
        const end: number = commandRule.lastIndexOf(']');
        commandRule = commandRule.substring(start, end);
      }
    }

    return commandRule;
  }

  // Commanding
  protected GetAnimatedCursorType(): GmsElementCursorType {
    const cursorType: any = Evaluation.GetValue2(this._evaluationCursorType, GmsElementCursorType[this.CursorType], PropertyType.String);
    return GmsElementCursorType[cursorType as string];

  }

  // Commanding
  protected UpdatePropertyCommandTriggerEnabled(evaluation: Evaluation): void {

    const oldTriggerEnabled = this.GetAnimatedIsCommandTriggerEnabled();

    if (evaluation !== null || evaluation !== undefined) {
      this._evaluationCommandTriggerEnabled = evaluation;
    }
    const newTriggerEnabled = this.GetAnimatedIsCommandTriggerEnabled();

    if (oldTriggerEnabled !== newTriggerEnabled && this.CommandVM !== null) {
      if (oldTriggerEnabled) {
        if (this._commandButtonFound) {
          this.CommandVM.RemoveCommandButton();
          this._commandButtonFound = false;
        }
      } else if (!this._commandButtonFound) {
        this.CommandVM.AddCommandButton();
        this._commandButtonFound = true;
      }
    }
    this.UpdateCommandVMTriggerStatus();
    this.UpdateCommandCursor();
  }
  // Commanding
  protected UpdatePropertyCommandParameter(evaluation: Evaluation): void {
    if (evaluation !== null || evaluation !== undefined) {
      this._evaluationCommandParameter = evaluation;
    }

    this.UpdateIsHitTestVisible();
    this.UpdateCommandParameters();
  }

  // Commanding
  protected UpdatePropertyCommandRule(evaluation: Evaluation): void {
    let isResolved = false;
    if (evaluation !== null || evaluation !== undefined) {
      this._evaluationCommandRule = evaluation;
      isResolved = evaluation.IsResolved;
    }
    this.UpdateIsHitTestVisible();
    if (isResolved) {
      this.UpdateCommand();
    }
  }

  // Commanding
  protected UpdateCommandParameters(): void {
    if (!this.HasCommand) {
      return;
    }
    this.CommandParameter = this.GetAnimatedCommandParameters();

    if (this.CommandVM !== null) {
      this.CommandVM.CommandParameter = this.CommandParameter;
    }
  }

  // Commanding
  protected UpdateCommandVMTriggerStatus(): void {
    if (!this.HasCommand) {
      return;
    }

    if (this.CommandVM !== null) {
      this.CommandVM.IsTriggerEnabled = this.GetAnimatedIsCommandTriggerEnabled();
    }
  }

  public setControlEditorMode(mode: ControlEditorMode): void {
    // implementation is provided for Numeric, String, Password editors
  }

  public resetEditorMode(): void {
    // when a command execution done, it applies to numeric, string and password control types.
    if (this.CommandVM !== null && !this.CommandVM.isModified && this.CommandVM.isCommandExecuteDone) {
      this.setControlEditorMode(ControlEditorMode.View);

      if (!!this.children) {
        this.children.forEach((element: GmsElement) => {
          element.resetEditorMode();
        });
      }
    }
  }

  public updateEditor(): void {
    // when user modifies control, propagate changes to all subscribed children.
    if (this.CommandVM !== null) {
      this.setControlEditorMode(this.CommandVM.isModified ? ControlEditorMode.Edit : ControlEditorMode.View);

      if (!!this.children) {
        this.children.forEach((element: GmsElement) => {
          element.setControlEditorMode(this.CommandVM.isModified ? ControlEditorMode.Edit : ControlEditorMode.View);
        });
      }
    }
  }

  public UpdateLinkReference(): void {
    const linkref = Evaluation.GetValue2(this._evaluationLinkReference, this._designValueLinkReference, PropertyType.String);
    // Remove all semicolons from a datapoint reference, except the last one
    this.LinkReference = GraphicsDatapointHelper.RemoveLeadingSemicolons(linkref);

    if (this.linkreferenceDp !== undefined) {
      this.linkreferenceDp = undefined;
    }

    if (this._linkReferenceDpSubscription !== undefined) {
      this._linkReferenceDpSubscription.unsubscribe();
      this._linkReferenceDpSubscription = undefined;
    }

    // if it is not a url
    // subscribe to the linkreference datapoint for alarms
    let target: string = this.GetAnimatedLinkReference();
    target = target !== undefined ? target.trim() : undefined;
    if (target !== undefined && !Utility.URLpatternRegEx.test(target)) {
      const linkRefDp: Datapoint = this.DatapointService.GetOrCreateByDesignation(target);

      if (linkRefDp?.Status === DatapointStatus.Valid) {
        this.linkreferenceDp = linkRefDp;
        this.UpdateAlarmState();

        // Subscribe to the datapoint
        this._linkReferenceDpSubscription = linkRefDp.propertyChanged.subscribe(args => this.linkRefDatapoint_PropertyChanged(args));
      } else if (linkRefDp?.Status <= DatapointStatus.Pending || linkRefDp?.Status === DatapointStatus.Invalid) {
        // Subscribe to the datapoint
        this._linkReferenceDpSubscription = linkRefDp.propertyChanged.subscribe(args => this.linkRefDatapoint_PropertyChanged(args));
      }
    }

    this.UpdateCommand();
  }

  protected InvalidCommandVM(): void {
    this.UnsubscribeCommandFromUpdates();
    const command: GmsCommand = new GmsCommand('', '', '');
    command.setCommandValidationStatus(CommandValidationStatus.DoesNotExist);

    let commandViewModel: CommandViewModel = this.CommandVM;
    if (commandViewModel === null) {
      commandViewModel = new CommandViewModel(this.GetAnimatedCommandParameters(), command, this.locale, this.defaultLocale);
      this.CommandVM = commandViewModel;
    } else {
      commandViewModel.initCommandViewModel(command, this.GetAnimatedCommandParameters());
    }
    commandViewModel.setParametersReadOnly(commandViewModel.IsInDesignMode);
    commandViewModel.IsUIConfigurationValid = command.CommandStatus.IsValid;
  }

  protected UpdateChildrenCommandVM(): void {
    if (this.CommandVM !== null) {
      this.SetCommandButtonExist();
      // Update elements' VMs
      if (!!this.children) {
        this.children.forEach((element: GmsElement) => {
          element.CommandVM = this.CommandVM;
          element.SetCommandButtonExist();
          element.CursorType = GmsElementCursorType.Hand;
        });
      }
    }
  }

  protected SetCommandVM(command: GmsCommand): void {

    this.UnsubscribeCommandFromUpdates(command, true);

    let commandViewModel: CommandViewModel = this.CommandVM;
    if (commandViewModel === null) {
      commandViewModel = new CommandViewModel(this.GetAnimatedCommandParameters(), command, this.locale, this.defaultLocale);
      commandViewModel.IsUIConfigurationValid = command.CommandStatus.IsValid;
      // additionally, commandViewModel.IsUIConfigurationValid will be validated at the ValidateControlType
      this.CommandVM = commandViewModel;
    } else {
      commandViewModel.initCommandViewModel(command, this.GetAnimatedCommandParameters());
      commandViewModel.IsUIConfigurationValid = command.CommandStatus.IsValid;
    }
    this._commandVMpropertyChangedSub = this._commandViewModel.propertyChanged.subscribe(args => this.CommandViewModel_PropertyChanged(args));
    this.UpdateCommandControl();

    commandViewModel.setParametersReadOnly(commandViewModel.IsInDesignMode);
    this.UpdateCommandEnableStatus();
    this.UpdatePropertyCommandDisabled(null);
    this.UpdateVisible();
    this.UpdateCommandParameters();
    this.UpdateIsHitTestVisible();
    this.UpdateEvaluationStatus();
    this.SetCommandButtonExist();
  }

  protected Command_PropertyChanged(args: CommandStatusChangeArgs): void {
    const command: GmsCommand = args !== undefined ? args.Command : undefined;
    const animatedCommandRule: string = this.GetAnimatedCommandRule();
    if (command !== undefined && command.CommandName === animatedCommandRule) {
      switch (args.PropertyName) {

        case 'CommandStatus':
          if (command.CommandStatus.IsValidating) {
            return;
          }

          this.SetCommandVM(command);

          if (command.CommandStatus.CommandValidationStatus === CommandValidationStatus.DoesNotExist) {
            this.SubscribeForCommandUpdates(command); // Ownership changed
          }
          break;

        default:
          break;
      }
    }
  }

  // Handles visibility, ability and connectivity
  protected CommandViewModel_PropertyChanged(args: PropertyChangedEventArgs): void {
    if (args === undefined || this.CommandVM === null) {
      return;
    }
    switch (args.PropertyName) {
      case 'IsUIConfigurationValid':
        // Ownership changed - hide/show command element, command.CommandStatus.Misconfigured || command.CommandStatus.DoesNotExist
        this.UpdateVisible();
        break;

      case 'IsEnabled':
        // Handle the BL/UI enabled status
        this.UpdateVisible();
        this.UpdateIsHitTestVisible();
        this.UpdatePropertyCommandDisabled(null);

        this.NotifyPropertyChanged('IsCommandEnabled');
        break;

      case 'IsConnected':
        this.UpdateEvaluationStatus();
        this.UpdateIsHitTestVisible();
        if (!this.CommandVM.IsConnected) {
          this.CommandVM.setParametersReadOnly();
        } else {
          this.CommandVM.setParametersReadOnly(false);
          if (this.type === GmsElementType.CommandControl) {
            this.setReadOnly(this.GetAnimatedCommandDisabled());
          }
        }
        break;

      case 'isCommandExecuteDone':
        this.resetEditorMode();
        break;

      case 'isModified':
        if (!this.CommandVM.isCommandExecuteDone) {
          // parameters sync up: editting, canceling..
          this.updateEditor();
        }
        break;

      default:
        break;
    }
  }

  protected UpdateCommandEnableStatus(): void {
    if (this.CommandVM !== null) {
      this.CommandVM.IsUIEnabled = this.GetAnimatedCommandDisabled() === false;
      this.NotifyPropertyChanged('IsCommandEnabled');
    }
  }

  protected UpdatePropertyCommandDisabled(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationCommandDisabled = evaluation;
    }

    if (this.HasCommand && this.CommandVM !== null) {
      this.UpdateCommandEnableStatus();
      this.UpdateVisible();
    }
    this.UpdateIsHitTestVisible();
    this.UpdateOpacity();
    if (this.type === GmsElementType.CommandControl) {
      this.setReadOnly(this.GetAnimatedCommandDisabled());
    }
  }

  protected setReadOnly(commandDisabled: boolean): void {
    // see it GmsCommandControl
  }

  protected SetBlinkColorState(): void {
    if (this.HasBlinkColors) {
      this.ElementBrushService?.addElement(this);
    } else {
      this.ElementBrushService?.removeElement(this);
    }
  }

  protected UpdatePropertyCursorType(evaluation: Evaluation = undefined): void {
    if (evaluation !== undefined) {
      this._evaluationCursorType = evaluation;
    }
    this.NotifyPropertyChanged('CursorType');
    this.NotifyPropertyChanged('IsDefaultCursorType');
  }

  public IsSlider(): boolean {
    return false;
  }

  private async linkRefDatapoint_PropertyChanged(args: DataPointPropertyChangeArgs): Promise<void> {
    if (args.SourceDatapoint.Status === DatapointStatus.DoesNotExist) {
      this._linkReferenceDpSubscription.unsubscribe();
      this._linkReferenceDpSubscription = undefined;
    }

    if (args.PropertyName === 'AlarmState') {
      this.linkreferenceDp = args.SourceDatapoint;
      this.UpdateAlarmState();
    }
  }

  private async selectionRefDatapoint_PropertyChanged(args: DataPointPropertyChangeArgs): Promise<void> {
    if (args.SourceDatapoint.Status === DatapointStatus.DoesNotExist) {
      this._selectionReferenceDpSubscription.unsubscribe();
      this._selectionReferenceDpSubscription = undefined;
      this.UpdateVisible();
    }

    // For initial selection of design time selection ref.
    if (args.PropertyName === 'Resolved') {
      this.setInitialSelection();
    }

    if (args.PropertyName === 'AlarmState') {
      this.selectionrefDp = args.SourceDatapoint;
      this.UpdateAlarmState();
    }
  }

  private PostResolveDatapoint(datapoint: Datapoint): void {
    if (datapoint.Status === DatapointStatus.Pending) { // not resolved yet
      if (this._commandDatapointResolvedSub !== undefined && !this._commandDatapointResolvedSub.closed) {
        this._commandDatapointResolvedSub.unsubscribe();
        this._commandDatapointResolvedSub = undefined;
      }
      this._commandDatapointResolvedSub =
                datapoint.propertyChanged.subscribe(args => this.ResolveCommandDatapoint(args.SourceDatapoint));
    } else {
      this.ResolveCommandDatapoint(datapoint);
    }
  }

  private GetAnimatedSelectionRef(): string {
    const selectionRef: string = this._evaluationSelectionRef !== undefined &&
            this._evaluationSelectionRef.LastDatapoint !== undefined ?
      this._evaluationSelectionRef.LastDatapoint.Designation :
      Evaluation.GetValue2(this._evaluationSelectionRef, this.SelectionRef, PropertyType.String);

    return GraphicsDatapointHelper.RemoveLeadingSemicolons(selectionRef);
  }

  private CreateEvaluations(node: Node): void {
    const evaluationsTagName = '.Evaluations';
    for (let i: number = node.childNodes.length - 1; i >= 0; i--) {
      const child: Node = node.childNodes[i];

      if (child.nodeName.endsWith(evaluationsTagName)) {
        for (let j: number = child.childNodes.length - 1; j >= 0; j--) {
          const evaluationNode: Node = child.childNodes[j];
          if (evaluationNode.nodeName === 'Evaluation') {
            const evaluation: Evaluation = new Evaluation();
            evaluation.Element = this;
            evaluation.Deserialize(evaluationNode);
            this._evaluations.set(evaluation.Property, evaluation);
          }
        }
        break;
      }
    }
    this.UpdateIsHitTestVisible();
  }

  // Grayscale
  private UpdatePropertyGrayscale(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationGrayscale = evaluation;
    }
    this.IsGrayscale = Evaluation.GetValue2(this._evaluationGrayscale, this.IsGrayscale, PropertyType.Boolean);
  }

  private UpdatePropertyShadowDepth(evaluation: Evaluation): void {
    if (!isNullOrUndefined(evaluation)) {
      this._evaluationShadowDepth = evaluation;
    }

    const tempShadowDepth: number = this.ShadowDepth;
    this.ShadowDepth = Evaluation.GetValue2(this._evaluationShadowDepth, this._designValueShadowDepth, PropertyType.Number);
    if (this.ShadowDepth !== tempShadowDepth) {
      this.FilterDepthAndDirectionChanged();
    }
  }

  private UpdatePropertyShadowDirection(evaluation: Evaluation): void {
    if (!isNullOrUndefined(evaluation)) {
      this._evaluationShadowDirection = evaluation;
    }

    const tempShadowDirection: number = this.ShadowDirection;
    this.ShadowDirection = Evaluation.GetValue2(this._evaluationShadowDirection, this._designValueShadowDirection, PropertyType.Number);
    if (this.ShadowDirection !== tempShadowDirection) {
      this.FilterDepthAndDirectionChanged();
    }
  }

  private UpdatePropertyShadowColor(evaluation: Evaluation): void {
    if (!isNullOrUndefined(evaluation)) {
      this._evaluationShadowColor = evaluation;
    }

    const tempShadowColor: string = this.ShadowColor;
    this.ShadowColor = Evaluation.GetValue2(this._evaluationShadowColor, this._designValueShadowColor, PropertyType.Color);
    if (this.ShadowColor !== tempShadowColor) {
      this.FilterColorChanged();
    }
  }

  private UpdatePropertyTooltip(evaluation: Evaluation): void {
    if (!isNullOrUndefined(evaluation)) {
      this._evaluationTooltip = evaluation;
    }

    this.Tooltip = Evaluation.GetValue2(this._evaluationTooltip, this._designValueTooltip, PropertyType.String);
  }

  private modifyFilterDepthAndDirection(): void {
    const degrees: number = -(this.ShadowDirection - 90);
    const alphaRad: number = MathUtils.DegreesToRadians(degrees);
    const dx: number = this.ShadowDepth * Math.cos(alphaRad);
    const dy: number = this.ShadowDepth * Math.sin(-alphaRad);
    if (!isNullOrUndefined(this.Filter)) {
      this.Filter.Dx = dx;
      this.Filter.Dy = dy;
    }
  }

  private modifyFilterColor(): void {
    const color: Color = new Color(this.ShadowColor);
    if (!isNullOrUndefined(this.Filter)) {
      this.Filter.ColorString = color.ColorString;
    }
  }

  private FilterDepthAndDirectionChanged(): void {
    if (!isNullOrUndefined(this.Filter)) {
      this.modifyFilterDepthAndDirection();
      this.FilterPropertyChanged();
    }
  }

  private FilterColorChanged(): void {
    if (!isNullOrUndefined(this.Filter)) {
      this.modifyFilterColor();
      this.FilterPropertyChanged();
    }
  }

  private FilterPropertyChanged(): void {
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;

    if (!this.HasCurrentElementFilter && !isNullOrUndefined(graphic)) {
      graphic.AddDynamicFilter(this.Filter);
    }

    this.NotifyPropertyChanged('FilterChanged');
    if (!isNullOrUndefined(graphic)) {
      graphic.dynamicFiltersChanged.next('FilterChanged');
    }
  }

  private get HasCurrentElementFilter(): boolean {
    const graphic: GmsGraphic = (this._graphic as GmsGraphic);
    const dynamicResources: any[] = graphic?.dynamicResources;

    if (!isNullOrUndefined(dynamicResources) && !isNullOrUndefined(this?.Filter?.EvaluationFilterId)) {
      for (const dynamicResource of graphic?.dynamicResources) {
        if (dynamicResource?.EvaluationFilterId === this?.Filter?.EvaluationFilterId) {
          return true;
        }
      }
    }

    return false;
  }

  // Coverage Area
  private UpdatePropertyCoverageAreaReference(evaluation: Evaluation = undefined): void {
    if (evaluation !== undefined) {
      this._evaluationCoverageAreaReference = evaluation;
    }
    this.CoverageAreaReference = Evaluation.GetValue2(this._evaluationCoverageAreaReference,
      this.CoverageAreaReference, PropertyType.String);

    this.CoverageArea = this.CoverageAreaReference !== undefined && this.CoverageAreaReference !== '';
    if (this.CoverageArea) {
      const graphic = this.Graphic as GmsGraphic;
      if (graphic !== undefined) {
        graphic.AddCoverageAreaElement(this);
        this.UpdateVisible();
        this.UpdateIsSelectable();
      }
    }
  }

  private UpdatePropertyX(evaluation: Evaluation = undefined): void {

    if (evaluation !== undefined) {
      this._evaluationX = evaluation;
    }

    if (this.IsReplicationClone) {
      return;
    }

    let positionType: any = Evaluation.GetValue2(this._evaluationPositionType, PositionType[this._designValuePositionType], PropertyType.String);
    positionType = PositionType[positionType as string];

    let x: number = +Evaluation.GetValue2(this._evaluationX, 0, PropertyType.Number);

    if (positionType === PositionType.Relative || (!this.IsUseEvaluation(evaluation)/* && this.IsScaleUniform*/)) {
      // add the design value for relative position type or in case there is no valid and active evaluation
      x = (+x + (this.BoundingRectDesign.X * this.ResizeFactorWidth));
    }
    this.X = +x;

    if (this._replication !== undefined && this._replication.HasClones && this.Replication.X !== this.X) {
      this._replication.UpdatePosition();
    }
  }

  private UpdatePropertyY(evaluation: Evaluation = undefined): void {
    if (this.IsReplicationClone) {
      return;
    }

    if (evaluation !== undefined) {
      this._evaluationY = evaluation;
    }

    let positionType: any = Evaluation.GetValue2(this._evaluationPositionType, PositionType[this._designValuePositionType], PropertyType.String);
    positionType = PositionType[positionType as string];

    let y: number = Evaluation.GetValue2(this._evaluationY, 0, PropertyType.Number);

    if (positionType === PositionType.Relative || !this.IsUseEvaluation(evaluation)) {
      // add the design value for relative position type or in case there is no valid and active evaluation
      y = (+y + (this.BoundingRectDesign.Y * this.ResizeFactorHeight));
    }
    this.Y = +y;

    if (this._replication !== undefined && this._replication.HasClones && this.Replication.Y !== this.Y) {
      this._replication.UpdatePosition();
    }
  }

  protected UpdatePropertyWidth(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationWidth = evaluation;
    }
    const width: number = Evaluation.GetValue2(this._evaluationWidth, this.DesignValueWidth, PropertyType.Number);
    this.Width = width < 0 ? GmsElement.MIN_WIDTH_HEIGHT : width > GmsElement.MAX_WIDTH_HEIGHT ? GmsElement.MAX_WIDTH_HEIGHT : width;

    this.UpdateClipping();
    this.UpdateAngleCenterX();

    this.UpdateEvaluationStatus();

    if (this._replication !== undefined && this._replication.HasClones) {
      this._replication.UpdatePosition();
    }
  }

  protected UpdatePropertyHeight(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationHeight = evaluation;
    }
    const height: number = Evaluation.GetValue2(this._evaluationHeight, this.DesignValueHeight, PropertyType.Number);
    this.Height = height < 0 ? GmsElement.MIN_WIDTH_HEIGHT : height > GmsElement.MAX_WIDTH_HEIGHT ? GmsElement.MAX_WIDTH_HEIGHT : height;

    this.UpdateClipping();
    this.UpdateAngleCenterX();

    this.UpdateEvaluationStatus();

    if (this._replication !== undefined && this._replication.HasClones) {
      this._replication.UpdatePosition();
    }
  }

  private UpdatePropertyAngle(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationAngle = evaluation;
    }
    // let defaultAngleValue: number = !isNaN(this._designValueAngle) ? this._designValueAngle : 0;

    const angle: number = Evaluation.GetValue2(this._evaluationAngle, 0, PropertyType.Number);

    if (angle === undefined || isNaN(angle)) {
      return;
    }

    // Final Angle is (evaluation angle + design value angle)
    // if (this.IsUseEvaluation(this._evaluationAngle)) {
    // this._angle = angle + this._designValueAngle;

    this._rotationAngle = angle;

    // }

    if (this.IsUseEvaluation(this._evaluationAngle) && !isNaN(angle)) { // Set the design values of the angle center x and y

      const defaultCenterX: number = this._designValueAngleCenterX !== undefined ? Utility.ParsePercentage(this
        ._designValueAngleCenterX, this.Width) : 0;
      const defaultCenterY: number = this._designValueAngleCenterY !== undefined ? Utility.ParsePercentage(this
        ._designValueAngleCenterY, this.Height) : 0;

      const angleCenterX: string = Evaluation.GetValue2(this._evaluationAngleCenterX, String(defaultCenterX), PropertyType.String);
      const angleCenterY: string = Evaluation.GetValue2(this._evaluationAngleCenterY, String(defaultCenterY), PropertyType.String);

      const parsedCenterX: number = Utility.ParsePercentage(angleCenterX, this.Width);
      const parsedCenterY: number = Utility.ParsePercentage(angleCenterY, this.Height);

      this._angleCenterX = !isNaN(parsedCenterX) ? parsedCenterX : (!isNaN(defaultCenterX) ? defaultCenterX : 0);
      this._angleCenterY = !isNaN(parsedCenterY) ? parsedCenterY : (!isNaN(defaultCenterY) ? defaultCenterY : 0);
    } else {
      this._angleCenterX = undefined;
      this._angleCenterY = undefined;
    }

    this.NotifyPropertyChanged('Angle'); // GetTransformations gets called
  }

  private UpdatePropertyAngleCenterX(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationAngleCenterX = evaluation;
    }
    this.UpdateAngleCenterX();
  }

  private UpdatePropertyAngleCenterY(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationAngleCenterY = evaluation;
    }
    this.UpdateAngleCenterY();
  }

  private UpdatePropertyPositionType(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationPositionType = evaluation;
    }
    const positionType: any = Evaluation.GetValue2(this._evaluationPositionType, PositionType[this._designValuePositionType], PropertyType.String);
    this._positionType = PositionType[positionType as string];

    this.UpdatePropertyX(this._evaluationX);
    this.UpdatePropertyY(this._evaluationY);
  }

  private UpdatePropertyFlipX(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationFlipX = evaluation;
    }
    const flipX: boolean = Evaluation.GetValue2(this._evaluationFlipX, this._designValueFlipX, PropertyType.Boolean);
    this.FlipX = flipX;

    // NOTE:
    // UpdateAdorner();
  }

  private UpdatePropertyFlipY(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationFlipY = evaluation;
    }

    const flipY: boolean = Evaluation.GetValue2(this._evaluationFlipY, this._designValueFlipY, PropertyType.Boolean);
    this.FlipY = flipY;

    // NOTE:
    // UpdateAdorner();
  }

  private UpdatePropertyVisible(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationVisible = evaluation;
    }
    this.UpdateVisible();
  }

  private UpdatePropertyLinkReference(evaluation: Evaluation): void {
    let updateLinkRef = false;
    if (evaluation !== undefined) {
      this._evaluationLinkReference = evaluation;
      updateLinkRef = evaluation.IsResolved;
    }
    if (updateLinkRef) {
      this.UpdateLinkReference();
    }
  }
  private UpdatePropertySelectionRef(evaluation: Evaluation): void {

    if (evaluation !== undefined) {
      this._evaluationSelectionRef = evaluation;
    }

    this.UpdateSelectionRef();
  }

  private UpdatePropertyClipLeft(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationClipLeft = evaluation;
      this.NotifyPropertyChanged('ClipLeft');
    }
  }

  private UpdatePropertyClipTop(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationClipTop = evaluation;
      this.NotifyPropertyChanged('ClipTop');
    }
  }

  private UpdatePropertyClipRight(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationClipRight = evaluation;
      this.NotifyPropertyChanged('ClipRight');
    }
  }

  private UpdatePropertyClipBottom(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationClipBottom = evaluation;
      this.NotifyPropertyChanged('ClipBottom');
    }
  }

  private UpdateClipping(): void {
    // Expecting this will not be needed. Since the change of width will retrigger the getclippathdata.
  }

  private UpdatePropertyBlinking(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationBlinking = evaluation;
    }
    const blinking: boolean = Evaluation.GetValue2(this._evaluationBlinking, this._designValueBlinking, PropertyType.Boolean);
    this.Blinking = blinking;
  }

  private UpdatePropertyDisableSelection(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationDisableSelection = evaluation;
    }
    this.UpdateDisableSelection();
  }

  private UpdatePropertyOpacity(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationOpacity = evaluation;
    }
    this.UpdateOpacity();
  }

  private UpdatePropertyCommandDisabledStyle(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationCommandDisabledStyle = evaluation;
    }
    this.UpdateOpacity();
    this.UpdateVisible();
    this.NotifyPropertyChanged('Visible');
  }

  private UpdatePropertyScaleFactor(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationScaleFactor = evaluation;
    }
    const scaleFactor: number = this.InternalScaleFactor;
    this._scaleX = scaleFactor;
    this._scaleY = scaleFactor;
    this.UpdateAngleCenterX();
    this.UpdateAngleCenterY();
    this.NotifyPropertyChanged('Scale');
    this.NotifyShapeChanged();

    // UpdateAdorner();
    // NOTE:
    // workaround to adjust adorners on scale factor changes
    // Size finalSize = new Size(Width * scaleFactor, Height * scaleFactor);
    // RearrangeAdorners(finalSize);
  }

  private UpdateAngleCenterX(): void {

    this.UpdatePropertyAngle(this._evaluationAngle);
    // NOTE Remove the commendted code, after testing all cases.
    // Defaultvalue for AngleCenterX is always the element center X
    // let defaultAngleCenterX: string = (this.Width / 2).toString();
    // let angleCenterX: string = Evaluation.GetValue2(this._evaluationAngleCenterX, defaultAngleCenterX, PropertyType.String);
    // if (angleCenterX !== undefined) {
    //    let centerX: number = Utility.ParseAbsoluteOrRelative(angleCenterX, this.Width);
    //    this.AngleCenterX = centerX;

    // UpdateAdorner();
    // }
  }

  private UpdateAngleCenterY(): void {

    this.UpdatePropertyAngle(this._evaluationAngle);
    // NOTE Remove the commendted code, after testing all cases.
    // Defaultvalue for AngleCenterY is always the element center Y
    // let defaultAngleCenterY: string = (this.Height / 2).toString();
    // let angleCenterY: string = Evaluation.GetValue2(this._evaluationAngleCenterY, defaultAngleCenterY, PropertyType.String);

    // if (angleCenterY !== undefined) {
    //    let centerY: number = Utility.ParseAbsoluteOrRelative(angleCenterY, this.Height);
    //    this.AngleCenterY = centerY;

    // UpdateAdorner();
    // }
  }

  private UpdatePropertyBackground(evaluation: Evaluation): void {
    this._evaluationBackground = evaluation;

    const defaultColorString: string = this._designValueBackgroundWrap.HasBlinkColor ? this._designValueBackgroundWrap
      .ActualColorString : this._designValueBackground;

    const colorValue: string = Evaluation.GetValue2(this._evaluationBackground, defaultColorString, PropertyType.Color);

    this._evaluationBackgroundWrap.SetColor(colorValue);

    if (this._evaluationBackgroundWrap.Current !== undefined) {
      this.AddBrushes(this._evaluationBackgroundWrap);
      const backColor: Color = this._evaluationBackgroundWrap.Current;
      this._backgroundOpacity = backColor.Opacity;
      this._background = backColor.ColorString;
      this.NotifyPropertyChanged('Background');
    }

    this.SetBlinkColorState();
    this.RemoveBrushes();
  }

  private UpdatePropertyStroke(evaluation: Evaluation): void {
    this._evaluationStroke = evaluation;

    const defaultColorString: string = this._designValueStrokeWrap.HasBlinkColor ? this._designValueStrokeWrap.ActualColorString : this._designValueStroke;

    const colorValue: string = Evaluation.GetValue2(this._evaluationStroke, defaultColorString, PropertyType.Color);

    this._evaluationStrokeWrap.SetColor(colorValue);

    if (this._evaluationStrokeWrap.Current !== undefined) {
      this.AddBrushes(this._evaluationStrokeWrap);
      const strokeColor: Color = this._evaluationStrokeWrap.Current;
      this._strokeOpacity = strokeColor.Opacity;
      this._stroke = strokeColor.ColorString;
      this.NotifyPropertyChanged('Stroke');
    }

    this.SetBlinkColorState();
    this.RemoveBrushes();
  }

  private UpdatePropertyRotationSpeed(evaluation: Evaluation): void {
    this._evaluationRotationSpeed = evaluation;

    this.UpdateRotation();
  }

  private UpdatePropertyRotationSteps(evaluation: Evaluation): void {
    this._evaluationRotationSteps = evaluation;

    this.UpdateRotation();
  }

  private UpdatePropertyFill(evaluation: Evaluation): void {
    this._evaluationFill = evaluation;

    const defaultColorString: string = this._designValueFillWrap.HasBlinkColor ? this._designValueFillWrap.ActualColorString : this._designValueFill;

    const colorValue: string = Evaluation.GetValue2(this._evaluationFill, defaultColorString, PropertyType.Color);

    this._evaluationFillWrap.SetColor(colorValue);

    if (this._evaluationFillWrap.Current !== undefined) {
      this.AddBrushes(this._evaluationFillWrap);
      const fillColor: Color = this._evaluationFillWrap.Current;
      this._fillOpacity = fillColor.Opacity;
      this._fill = fillColor.ColorString;
      this.NotifyPropertyChanged('Fill');
    }

    this.SetBlinkColorState();
    this.RemoveBrushes();
  }

  private UpdatePropertyReplicationIndexRange(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationReplicationIndexRange = evaluation;
    }

    const evaluationReplicationIndexRange: string = Evaluation.GetValue2(this._evaluationReplicationIndexRange, this._designValueReplicationIndexRange
      , PropertyType.String);

    // If element has replication and has a value in index range
    // Parse the index range value and set it to the Replication.
    if (evaluationReplicationIndexRange !== undefined) {
      this._replicationIndexRange = evaluationReplicationIndexRange;

      if (this._replication !== undefined && this._replication.HasClones) {
        this._replication.UpdateReplicationIndexRange();
      }
    }
  }

  private UpdatePropertyReplicationOrientation(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationReplicationOrientation = evaluation;
    }

    let evaluationReplicationOrientation: any = Evaluation.GetValue2(this._evaluationReplicationOrientation, GmsElementReplicationOrientationType[this
      ._designValueReplicationOrientation], PropertyType.String);

    evaluationReplicationOrientation = GmsElementReplicationOrientationType[evaluationReplicationOrientation as string];
    // If has replication and the direction has changed.
    // set and update the replication.
    if (evaluationReplicationOrientation !== undefined) {
      this._replicationOrientation = evaluationReplicationOrientation;

      if (this._replication !== undefined && this._replication.HasClones
                && this._replication.Orientation !== this._replicationOrientation) {
        this._replication.UpdatePosition();
      }
    }
  }

  private UpdatePropertyReplicationMaxExtent(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationReplicationMaxExtent = evaluation;
    }

    const evaluationReplicationMaxExtent: string = Evaluation.GetValue2(this._evaluationReplicationMaxExtent, this._designValueReplicationMaxExtent
      , PropertyType.String);

    if (evaluationReplicationMaxExtent !== undefined) {
      this._maxReplicationExtent = evaluationReplicationMaxExtent;

      if (this._replication !== undefined && this._replication.HasClones) {
        this._replication.UpdatePosition();
      }
    }
  }

  private UpdatePropertyReplicationSpace(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationReplicationSpace = evaluation;
    }

    const evaluationReplicationSpace: string = Evaluation.GetValue2(this._evaluationReplicationSpace, this._designValueReplicationSpace
      , PropertyType.String);

    if (evaluationReplicationSpace !== undefined) {
      this._replicationSpace = evaluationReplicationSpace;

      if (this._replication !== undefined && this._replication.HasClones) {
        this._replication.UpdatePosition();
      }
    }
  }

  private AddBrushes(colorWrap: ColorWrap): void {
    if (colorWrap === undefined) {
      return;
    }

    if (colorWrap.First.HasResolvedBrush) {
      if (!this._activeBrushUrls.includes(colorWrap.First.Brush.Url)) {
        (this.Graphic as GmsGraphic).AddDynamicResources(colorWrap.First.Brush);
        this._activeBrushUrls.push(colorWrap.First.Brush.Url);
      }
    }

    if (colorWrap.HasBlinkColor && colorWrap.Second.HasResolvedBrush) {
      if (!this._activeBrushUrls.includes(colorWrap.Second.Brush.Url)) {
        (this.Graphic as GmsGraphic).AddDynamicResources(colorWrap.Second.Brush);
        this._activeBrushUrls.push(colorWrap.Second.Brush.Url);
      }
    }
  }

  private RemoveBrushes(): void {
    // Get the urls from the evaluation color wraps
    const brushUrls: string[] = [];

    this._evaluationColorWraps.forEach((colorWrap: ColorWrap) => {
      if (colorWrap.First.HasUrl()) {
        brushUrls.push(colorWrap.First.ColorString);
      }

      if (colorWrap.HasBlinkColor && colorWrap.Second.HasUrl()) {
        brushUrls.push(colorWrap.Second.ColorString);
      }
    });

    const brushesToRemove: any[] = [];
    // Remove the  brushes which are not used from dynamic resources
    this._activeBrushUrls.forEach(activeBrushUrl => {
      if (!brushUrls.includes(activeBrushUrl)) {
        brushesToRemove.push(activeBrushUrl);
      }
    });

    brushesToRemove.forEach(brush => {
      const indextoRemove: number = this._activeBrushUrls.findIndex(activeBrush => activeBrush === brush);
      if (indextoRemove !== -1) {
        this._activeBrushUrls.splice(indextoRemove, 1);
      }
    });

    (this.Graphic as GmsGraphic).RemoveDynamicResources(brushesToRemove);
  }

  protected IsUpButton(element: GmsElement): boolean {
    return false;
  }
  protected IsDownButton(element: GmsElement): boolean {
    return false;
  }

  protected CalculateSpinnerCursor(): GmsElementCursorType {
    let cursorType: GmsElementCursorType = GmsElementCursorType.Default;
    const spinner: GmsCommandControl = GmsElement.FindVisualAncesterByType(this, GmsElementType.CommandControl) as GmsCommandControl;
    if (spinner !== undefined) {
      let spinButton: GmsElement;
      if (spinner.IsUpButton(this)) {
        spinButton = spinner.children.length > 0 ? spinner.children[0] as GmsElement : null;
        if (spinButton != null) {
          cursorType = spinButton.GetAnimatedCursorType();
        }
      } else if (spinner.IsDownButton(this)) {
        spinButton = spinner.children.length > 1 ? spinner.children[1] as GmsElement : null;
        if (spinButton != null) {
          cursorType = spinButton.GetAnimatedCursorType();
        }
      }

    }
    return cursorType;
  }

  protected DisableGroupCursor(): void {
    if (this.Type === GmsElementType.Group) {
      this.children.forEach(item => {
        if (item.Type === GmsElementType.Group && item.GetAnimatedIsCommandTriggerEnabled()) {
          item.CursorType = GmsElementCursorType.Default;
          item.DisableGroupCursor();
        } else {
          item.CursorType = GmsElementCursorType.Default;
        }
      });
    }
  }

  private UpdateSelectionRef(): void {
    let selectionRef: string = this._selectionRef;

    if (this._selectionReferenceDpSubscription !== undefined) {
      this._selectionReferenceDpSubscription.unsubscribe();
      this._selectionReferenceDpSubscription = undefined;
      this.selectionrefDp = undefined;
    }

    // parse selectionRef evaluation
    const evaluationSelRef: string = Evaluation.GetValue2(this._evaluationSelectionRef, this._designValueSelectionRef, PropertyType.String);

    // evaluation contains reference to datapoint
    if (!!this._evaluationSelectionRef && this._evaluationSelectionRef.Datapoints.length > 0) {
      // set selectionRef to datapoint reference
      selectionRef = this._evaluationSelectionRef.Datapoints[0].Designation;
    } else {
      // literal substitution
      selectionRef = evaluationSelRef;

      // Only for the selection ref used in design time.
      if (selectionRef !== undefined && selectionRef.trim() !== '') {
        const designation: string = GraphicsDatapointHelper.RemoveLeadingSemicolons(selectionRef);
        const selectionRefDp: Datapoint = this.DatapointService
          .GetOrCreateByDesignation(selectionRef);
        if (selectionRefDp.Status === DatapointStatus.Valid) {
          this.selectionrefDp = selectionRefDp;
          this.UpdateAlarmState();

          // Subscribe to the datapoint
          this._selectionReferenceDpSubscription = selectionRefDp.propertyChanged.subscribe(args => this
            .selectionRefDatapoint_PropertyChanged(args));
        } else if (selectionRefDp.Status <= DatapointStatus.Pending || selectionRefDp.Status === DatapointStatus.Invalid) {
          // Subscribe to the datapoint
          this._selectionReferenceDpSubscription = selectionRefDp.propertyChanged
            .subscribe(args => this.selectionRefDatapoint_PropertyChanged(args));
        } else if (selectionRefDp.Status === DatapointStatus.DoesNotExist) {
          this.UpdateVisible();
        }
      }
    }

    if (selectionRef !== undefined) {
      this.SelectionRef = selectionRef;
    }
  }

  private GetAllRecursive(result: GmsElement[], withReplications: boolean, withLayers: boolean, withSymbolInstanceChildren: boolean): void {
    if (this.children !== undefined) {
      const children: GmsElement[] = Array.from(this.children);
      const length: number = children.length;
      for (let i = 0; i < length; i++) {
        const element: GmsElement = children[i];

        // NOTE:
        // if (element.IsMovingClone) {
        //    continue;
        // }
        if (withReplications) {
          // NOTE:
          // don't add the element itself (or it's children) - only add the replications (maybe with children)
          // if (element.Replication !== null && !element.Replication.IsEmpty) {
          // add replicated elements
          //    var replications = element.Replication.ReplicationClonesContainer.Children.Cast<GmsElement>();
          //    result.AddRange(replications);

          //    // add children of replications
          //    if (withSymbolInstanceChildren || !(element is GmsSymbolInstance))
          //    foreach(var replication in replications)
          //    replication.GetAllRecursive(result, withReplications, withLayers, withSymbolInstanceChildren);
          // }
        }
        if (withLayers || element.Type !== GmsElementType.Layer) {
          result.push(element);
        }

        // add children
        if (withSymbolInstanceChildren || element.Type !== GmsElementType.SymbolInstance) {
          element.GetAllRecursive(result, withReplications, withLayers, withSymbolInstanceChildren);
        }
      }
    }
  }

  private CalculateDisableElementOpacity(opacity: number, disabled: boolean): number {
    let style: any = Evaluation.GetValue2(this._evaluationCommandDisabledStyle,
      GmsElementDisabledStyle[this.CommandDisabledStyle], PropertyType.String);
    style = GmsElementDisabledStyle[style as string];

    let result: number = opacity;

    if (disabled && style === GmsElementDisabledStyle.Grayed) {
      result *= 0.4;
    } else if (disabled && style === GmsElementDisabledStyle.None) {
      result = opacity; // preserve the original opacity, not a GmsElement.DEFAULT_OPACITY;
    }
    return result;
  }
}

export class BoundingRectangle {
  private _X = 0;
  public get X(): number {
    return this._X;
  }
  public set X(value: number) {
    if (this._X !== value) {
      this._X = value;
    }
  }

  private _Y = 0;
  public get Y(): number {
    return this._Y;
  }
  public set Y(value: number) {
    if (this._Y !== value) {
      this._Y = value;
    }
  }

  private _Width = 0;
  public get Width(): number {
    return this._Width;
  }
  public set Width(value: number) {
    if (this._Width !== value) {
      this._Width = value;
    }
  }

  private _Height = 0;
  public get Height(): number {
    return this._Height;
  }

  public set Height(value: number) {
    if (this._Height !== value) {
      this._Height = value;
    }
  }

  constructor(x: number, y: number, width: number, height: number) {
    this.X = x;
    this.Y = y;
    this.Width = width;
    this.Height = height;
  }
}
