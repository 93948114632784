/* eslint-disable @typescript-eslint/naming-convention */
enum TraceModulesEn {
  gmsSnapins_EventList,
  gmsSnapins_EventListPerformance,
  gmsSnapins_EventInfo,
  gmsSnapins_EventGrid,
  gmsSnapins_EventGridPerformance,
  gmsSnapins_EventsCommon,
  gmsSnapins_License
}

export class TraceModules {

  public static objectManagerCore = 'gmsSnapins_ObjectManager_Core';

  public static objectManagerSvc = 'gmsSnapins_ObjectManager_Service';

  public static objectManager = 'gmsSnapins_ObjectManager_Component';

  public static objectManagerValueSubscriptions = 'gmsSnapins_ObjectManager_ValueSubscriptions';

  public static objectManagerViewResync = 'gmsSnapins_ObjectManager_ViewResync';

  public static pointInfo = 'gmsSnapins_PointInfo';

  public static eventList: string = TraceModulesEn[TraceModulesEn.gmsSnapins_EventList];
  public static eventListPerformance: string = TraceModulesEn[TraceModulesEn.gmsSnapins_EventListPerformance];
  public static eventGrid: string = TraceModulesEn[TraceModulesEn.gmsSnapins_EventGrid];
  public static eventGridPerformance: string = TraceModulesEn[TraceModulesEn.gmsSnapins_EventGridPerformance];
  public static aboutObject = 'gmsSnapins_AboutObject';
  public static memoPopover = 'gmsSnapins_MemoPopover';
  public static eventInfo: string = TraceModulesEn[TraceModulesEn.gmsSnapins_EventInfo];
  public static eventsCommon: string = TraceModulesEn[TraceModulesEn.gmsSnapins_EventsCommon];
  public static ownershipDialog = 'gmsSnapins_OwnershipDialog';
  public static license: string = TraceModulesEn[TraceModulesEn.gmsSnapins_License];

  public static systemQParamSvc = 'gmsSnapins_SysteQParam_Service';
  public static eventAssistedTreatment = 'gmsSnapins_eventAssistedTreatment';

  public static eventListSettingsService = 'gmsSnapins_eventListSettinsService';
}
