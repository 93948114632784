<div class="si-configure-modal">
  <div class="modal-header">
    <h4 class="modal-title" [id]="modalTitleId">
      {{ 'SCENE_EDITOR.TEXT_SHORT.CONFIGURE_DATA_POINT' | translate }}
    </h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  @if (inlineNotificationError) {
    <si-inline-notification
      class="mt-4"
      [severity]="inlineNotificationError.severity"
      [message]="inlineNotificationError.message"
      [translationParams]="inlineNotificationError.errorParams"
      [action]="inlineNotificationError.action"
    />
  }
  <div class="modal-body pt-0">
    <si-configure-datapoint
      class="bg-base-1 mx-1 rounded-2 elevation-1 si-config-datapoint"
      #configureDatapoint
      [selectedDataPoint]="selectedDataPointCopy"
      [propertyList]="propertyList"
      (propertyChanged)="propertyChanged($event)"
      [readOnly]="isDeleted"
      [objectId]="objectId"
      (removeDataPoint)="confirmDelete($event)"
      (isPopOpen)="isPropertyPopOpen = $event"
      (command)="commandSelected($event)"
      [isDuplicateDataPoint]="isDuplicateDataPoint"
      [displayMode]="displayMode"
    />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-secondary" (click)="cancel()">
      {{ 'SCENE_EDITOR.DIALOG_TEXT_SHORT.CANCEL' | translate }}
    </button>
    @if (!isDeleted) {
      <button
        type="button"
        class="btn btn-default btn-primary"
        [disabled]="isPropertyPopOpen || inlineNotificationError"
        (click)="confirm()"
      >
        {{ 'SCENE_EDITOR.DIALOG_TEXT_SHORT.OK' | translate }}
      </button>
    }
    @if (isDeleted) {
      <button type="button" class="btn btn-default btn-danger" (click)="confirm()">
        {{ 'SCENE_EDITOR.DIALOG_TEXT_SHORT.REMOVE' | translate }}
      </button>
    }
  </div>
</div>
