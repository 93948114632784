import { Component, Input } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { DefaultProperty } from '../../interfaces/property';

@Component({
  selector: 'si-default-property',
  templateUrl: './si-default-property.component.html',
  styleUrls: ['./si-default-property.component.scss'],
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiDefaultPropertyComponent {
  @Input({ required: true }) property!: DefaultProperty;
}
