import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { WebComponentContentChildren } from '@simpl/element-ng/common';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiStepperStepComponent } from './si-stepper-step.component';

@Component({
  selector: 'si-stepper',
  templateUrl: './si-stepper.component.html',
  styleUrl: './si-stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'si-layout-inner' },
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiStepperComponent implements AfterContentInit, OnDestroy, OnChanges {
  private _activeStepIdx = 0;
  protected get activeStepIdx(): number {
    return this._activeStepIdx;
  }
  protected set activeStepIdx(value) {
    if (this._activeStepIdx !== value) {
      this._activeStepIdx = value;
      this.activeStepChange.emit(this._activeStepIdx);
    }
  }

  /** Show only required steps */
  @Input({ transform: booleanAttribute })
  showOnlyRequired = false;

  /**
   * If enabled the header will be `position: sticky`.
   * The offset can be changed by setting the css variable: `--element-stepper-sticky-header-top-offset: <value>`
   */
  @Input({ transform: booleanAttribute }) stickyHeader = false;

  /** Shows/hides mandatory switch button */
  @Input({ transform: booleanAttribute }) showOnlyRequiredSwitch = true;

  /** Shows/hides next/prev buttons */
  @Input({ transform: booleanAttribute }) showPagination = true;

  /** Label for mandatory steps switch */
  @Input()
  onlyRequiredSwitchLabel =
    $localize`:@@SI_STEPPER.SHOW_ONLY_REQUIRED_SWITCH:Show required steps only`;
  /** Aria Label next button */
  @Input() nextBtnAriaLabel = $localize`:@@SI_STEPPER.NEXT:Next step`;
  /** Aria Label previous button */
  @Input() prevBtnAriaLabel = $localize`:@@SI_STEPPER.PREVIOUS:Previous step`;

  /** Outputs the index of the currently active step */
  @Output() readonly activeStepChange = new EventEmitter<number>();
  /** When the value of showOnlyRequired changes */
  @Output() readonly showOnlyRequiredChange = new EventEmitter<boolean>();

  @WebComponentContentChildren(SiStepperStepComponent)
  @ContentChildren(SiStepperStepComponent)
  protected steps!: QueryList<SiStepperStepComponent>;

  protected actualSteps: SiStepperStepComponent[] = [];

  private cdRef = inject(ChangeDetectorRef);
  private destroyer = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showOnlyRequired) {
      this.checkActiveStepsForRequiredOnly();
    }
  }

  ngAfterContentInit(): void {
    this.changeHandler();
    this.steps.changes.pipe(takeUntil(this.destroyer)).subscribe(() => this.changeHandler());
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  /**
   * Shows/hides non-mandatory steps
   * @param showOnlyRequired
   */
  protected onShowOnlyRequiredToggleHandler(showOnlyRequired: boolean): void {
    this.showOnlyRequired = showOnlyRequired;
    this.checkActiveStepsForRequiredOnly();
    this.showOnlyRequiredChange.emit(this.showOnlyRequired);
  }

  /**
   * Switches to next step
   */
  protected onNextHandler(): void {
    let newStep = this.activeStepIdx + 1;
    for (; this.actualSteps[newStep]?.disabled && newStep < this.actualSteps.length; newStep++);
    this.updateActiveStep(newStep);
  }

  /**
   * Switches to previous step
   */
  protected onPrevHandler(): void {
    let newStep = this.activeStepIdx - 1;
    for (; this.actualSteps[newStep]?.disabled && newStep >= 0; newStep--);
    this.updateActiveStep(newStep);
  }

  /** @internal */
  updateActiveStep(newIdOrStep: number | SiStepperStepComponent, force = false): void {
    let newId: number;
    if (typeof newIdOrStep === 'number') {
      newId = newIdOrStep;
    } else {
      newId = this.actualSteps.indexOf(newIdOrStep);
    }
    this.setActiveStep(newId, force);
  }

  private setActiveStep(newId: number, force = false): void {
    if (newId >= this.actualSteps.length || this.actualSteps[newId]?.disabled) {
      return;
    }
    if (!force && this.activeStepIdx === newId) {
      return;
    }

    this.actualSteps[this.activeStepIdx]?.updateActive(false);
    this.activeStepIdx = newId;
    this.actualSteps[this.activeStepIdx]?.updateActive(true);
    this.cdRef.markForCheck();
  }

  private changeHandler(): void {
    this.onShowOnlyRequiredToggleHandler(this.showOnlyRequired);
    this.steps.forEach(step => step.registerParent(this));
    this.updateActiveStep(0, true);
    this.cdRef.markForCheck();
  }

  private checkActiveStepsForRequiredOnly(): void {
    if (!this.steps) {
      return;
    }

    this.steps
      .filter(step => !step.required)
      .forEach(step => step.updateShow(!this.showOnlyRequired));

    const currentActive = this.actualSteps[this.activeStepIdx];
    this.actualSteps = this.steps.filter(step => step.required || !this.showOnlyRequired);

    const newIndex = this.actualSteps.indexOf(currentActive);
    if (newIndex === -1) {
      currentActive?.updateActive(false);
    }
    this.updateActiveStep(newIndex !== -1 ? newIndex : 0, true);
  }
}
