import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiIconStatusComponent } from '../icon-status/si-icon-status.component';

@Component({
  selector: 'si-commissioning-status',
  templateUrl: './si-commissioning-status.component.html',
  styleUrl: './si-commissioning-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiIconStatusComponent, SiTranslateModule]
})
export class SiCommissioningStatusComponent {
  /**
   * Is the component disabled.
   */
  @Input({ transform: booleanAttribute }) disabled?: boolean;
  /**
   * Number of "overwritten" items. Hidden when `null` is passed.
   */
  @Input() overwritten: number | null = null;
  /**
   * Number of "checked" items. Hidden when `null` is passed.
   */
  @Input() checked: number | null = null;
  /**
   * Number of "failed" items. Hidden when `null` is passed.
   */
  @Input() failed: number | null = null;
  /**
   * Number of "unchecked" items. Hidden when `null` is passed.
   */
  @Input() unchecked: number | null = null;
}
