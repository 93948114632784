import { Subject, Subscription } from 'rxjs';

import { CommandValidationStatus } from '../command/gms-command-status';
import { Datapoint, DataPointPropertyChangeArgs } from '../datapoint/gms-datapoint';
import { CommandStatus, CommandStatusChangeArgs } from './gms-command-status';
import { CommandParameter } from './parameters/gms-base-parameter';

export class GmsCommand {
  public statusChanged: Subject<CommandStatusChangeArgs> = new Subject<CommandStatusChangeArgs>();

  private _isEnabled = false;
  private _datapointPropertyChangedSubscription: Subscription;
  /**
   * get/set command enabled status
   */
  public get IsEnabled(): boolean {
    return this._isEnabled;
  }
  public set IsEnabled(value: boolean) {
    if (this._isEnabled !== value) {
      this._isEnabled = value;
      this.NotifyPropertyChanged('IsEnabled');
    }
  }

  private _isDefaultCommand = false;
  public get DefaultCommand(): boolean {
    return this._isDefaultCommand;
  }
  public set DefaultCommand(value: boolean) {
    if (this._isDefaultCommand !== value) {
      this._isDefaultCommand = value;
    }
    this.subscribeForUpdates();
  }

  private _parameters: CommandParameter[] = undefined;
  public get Parameters(): CommandParameter[] {
    if (this._parameters === undefined) {
      this._parameters = new Array<CommandParameter>();
    }
    return this._parameters;
  }
  /**
   * Designation of the property to be commanded
   */
  private _designation: string = undefined;
  public get Designation(): string {
    return this._designation;
  }
  public set Designation(value: string) {
    this._designation = value;
  }
  /**
   * Id of the property to be commanded
   */
  private _id: string = undefined;
  public get Id(): string {
    return this._id;
  }
  public set Id(value: string) {
    this._id = value;
  }

  /**
   * command status can be
   * Undefined (at the beginning),
   * Validating (during the validation),
   * Valid (command validated OK),
   * DoesNotExist (command doesn't exists in the property definition),
   * or Misconfigured (non-supported parameters by the graphic)
   */
  private _commandStatus: CommandStatus = undefined;
  public get CommandStatus(): CommandStatus {
    this.InitCommandStatus();
    return this._commandStatus;
  }

  /**
   * command Name
   */
  private readonly _commandName: string = undefined;
  public get CommandName(): string {
    return this._commandName;
  }
  /**
   * creates a unique command key as Id.CommandName
   */
  public get Key(): string {
    return `${this.Id}.${this.CommandName}`;
  }

  /**
   * command's datapoint
   */
  private _datapoint: Datapoint;
  public get Datapoint(): Datapoint {
    return this._datapoint;
  }
  public set Datapoint(value: Datapoint) {
    this._datapoint = value;
  }

  public static getKey(id: string, commandName: string): string {
    return `${id}.${commandName}`;
  }

  public setCommandValidationStatus(status: CommandValidationStatus): void {

    this._commandStatus.CommandValidationStatus = status;
    this.NotifyPropertyChanged('CommandStatus');

  }

  public subscribeForUpdates(): void {
    if (this.Datapoint === undefined) {
      return;
    }
    if (this._datapointPropertyChangedSubscription === undefined) {
      this._datapointPropertyChangedSubscription = this.Datapoint.propertyChanged.subscribe(arg => this.onDataPointPropertyChanged(arg));
      this.Datapoint.CountUsage++;
      // subscribe parameter for Datapoint  COV
      if (this.DefaultCommand) {
        this.Parameters.forEach(p => {
          p.Datapoint = this.Datapoint;
          p.subscribeForUpdates();
        });
      }
    }
  }

  public unsubscribeFromUpdates(): void {
    if (this.Datapoint === undefined) {
      return;
    }
    if (this.Datapoint.CountUsage > 0) {
      this.Datapoint.CountUsage--;
    }
    this.Parameters.forEach(p => {
      p.unsubscribeFromUpdates();
    });
  }

  public unsubscribe(): void {
    if (this._datapointPropertyChangedSubscription !== undefined && !this._datapointPropertyChangedSubscription.closed) {
      this._datapointPropertyChangedSubscription.unsubscribe();
    }
    if (this.Datapoint !== undefined && this.Datapoint.CountUsage > 0) {

      this.Datapoint.CountUsage--;
    }

    this.Parameters.forEach(p => {
      p.unsubscribe();
    });
  }

  public constructor(designation: string, id: string, commandName: string) {

    this.InitCommandStatus();
    this._designation = designation;
    this._id = id;
    this._commandName = commandName;
    this._parameters = new Array<CommandParameter>();
  }

  protected NotifyPropertyChanged(propertyName: string = ''): void {
    const args: CommandStatusChangeArgs = new CommandStatusChangeArgs();
    args.PropertyName = propertyName;
    args.Command = this;
    this.statusChanged.next(args);
  }

  private onDataPointPropertyChanged(arg: DataPointPropertyChangeArgs): void {
    if (arg.PropertyName === 'Status') {
      // to handle "Status" of the commandable property
      this.NotifyPropertyChanged(arg.PropertyName);
    }
  }

  private InitCommandStatus(): void {
    if (this._commandStatus === undefined) {
      this._commandStatus = new CommandStatus(false);

      this._commandStatus.IsBLEnabled = false;
      this._commandStatus.IsConnected = true;
    }
  }
}
