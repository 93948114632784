import {
  Injectable,
  OnDestroy,
  Renderer2,
  RendererFactory2
} from '@angular/core';
import { fromEventPattern, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationMouseService implements OnDestroy {
  public onClick$: Observable<Event>;
  private readonly _destroy$ = new Subject();

  constructor(private readonly rendererFactory2: RendererFactory2) {
    const renderer = this.rendererFactory2.createRenderer(null, null);

    this.createOnClickObservable(renderer);
  }

  public ngOnDestroy(): void {
    this._destroy$.next(undefined);
    this._destroy$.complete();
  }

  private createOnClickObservable(renderer: Renderer2): void {
    let removeClickEventListener: () => void;
    const createClickEventListener: any = (
      handler: (e: Event) => boolean | void
    ) => {
      removeClickEventListener = renderer.listen('document', 'click', handler);
    };

    this.onClick$ = fromEventPattern<Event>(createClickEventListener, () =>
      removeClickEventListener()
    ).pipe(takeUntil(this._destroy$));
  }
}
