import { Directive } from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';

import { MenuItemNext } from './si-menu-model';

/** Required to have compiler checks on the factory template */
@Directive({ selector: '[siMenuFactoryItemGuard]', standalone: true })
export class SiMenuFactoryItemGuardDirective {
  static ngTemplateContextGuard(
    dir: SiMenuFactoryItemGuardDirective,
    ctx: any
  ): ctx is { $implicit: MenuItemNext | MenuItem } {
    return true;
  }
}
