import { NgZone } from '@angular/core';
import { asyncScheduler, interval, Observable, Observer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { TraceChannel } from '../common/trace-channel';
import { GmsAnimatedGif } from '../elements/gms-animated-gif';
import { GmsCommandControl } from '../elements/gms-commandcontrol';
import { GmsCommandControlNumeric } from '../elements/gms-commandcontrol-numeric';
import { GmsCommandControlRotator } from '../elements/gms-commandcontrol-rotator';
import { GmsCommandControlSelector } from '../elements/gms-commandcontrol-selector';
import { GmsCommandControlSlider } from '../elements/gms-commandcontrol-slider';
import { GmsCommandControlSpinner } from '../elements/gms-commandcontrol-spinner';
import { GmsCommandControlString } from '../elements/gms-commandcontrol-string';
import { BoundingRectangle, GmsElement } from '../elements/gms-element';
import { GmsEllipse } from '../elements/gms-ellipse';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsGroup } from '../elements/gms-group';
import { GmsImage } from '../elements/gms-image';
import { GmsLayer } from '../elements/gms-layer';
import { GmsLine } from '../elements/gms-line';
import { GmsPath } from '../elements/gms-path';
import { GmsPipe } from '../elements/gms-pipe';
import { GmsPolygon } from '../elements/gms-polygon';
import { GmsRectangle } from '../elements/gms-rectangle';
import { GmsSymbolInstance } from '../elements/gms-symbol-instance';
import { GmsText } from '../elements/gms-text';
import { GmsXps } from '../elements/gms-xps';
import { GmsSymbolNodeLibrary } from '../processor/gms-symbol-library';
import { Substitution } from '../processor/substitution';
import { PropertyChangeArgs } from '../services/gms-datapoint2.service';
import { TimerPhaseEnum, TimerService } from '../services/timer-service';
import { GmsElementPropertyType, GraphicType } from '../types/gms-element-property-types';
import { GmsTextAlignmentType } from '../types/gms-text-types';
import { SvgUtility } from '../utilities/parser';

export class SvgLoader {
  private readonly traceModule: string = TraceChannel.Services;
  private svgcontent: string = undefined;
  private svgDocument: XMLDocument;
  private gmsGraphic: GmsGraphic = null;
  private gmsSymbolLibrary: GmsSymbolNodeLibrary = null;
  private _subscriptionSymbolLibraryCall: Subscription = null;

  private _ngZone: NgZone;
  private _onReadPropertiesMultiSubscription: Map<string, Subscription>;
  private elementGraphicCount = 0;
  private observer: Observer<boolean>;
  private tearDownAllTasks = false;
  private readonly layersQueue: Node[] = [];
  private readonly defsCopiedForSymbols: string[] = [];

  /**
   *
   * @param content HTTP response returned as a text
   * @param graphic Graphic instance
   */
  public loadSVGContent(content: string, graphic: GmsGraphic,
    onReadPropertiesMultiSubscription: Map<string, Subscription>, zone: NgZone): Observable<boolean> {
    this.gmsGraphic = graphic;
    this._onReadPropertiesMultiSubscription = onReadPropertiesMultiSubscription;
    this._ngZone = zone;

    // gmsGraphic Clear call done, before the load started
    this.svgcontent = content;

    if (this._subscriptionSymbolLibraryCall != null) {
      this._subscriptionSymbolLibraryCall.unsubscribe();
      this._subscriptionSymbolLibraryCall = null;
    }

    // return an observable which the consumer can subscribe to
    // the method 'onSubscription' is called as soon as the consumer
    // subscribes to the observable.
    const obs: Observable<boolean> = Observable.create((observer: Observer<boolean>): any => {
      this.onSubscription(observer);
      return (): void => this.teardownLogic();
    });

    return obs;
  }

  // NOTE: change this function to private
  public onSubscription(observer: Observer<boolean>): void {
    this.DeserializeContent(observer);
  }

  public DeserializeContent(observer: Observer<boolean>): void {
    this.observer = observer;
    if (this.tearDownAllTasks) {
      return;
    }

    this.svgDocument = this.parseDOM();
    const nodes: NodeList = this.createSymbolsLibrary();
    this.parseGraphics(nodes);
  }

  private teardownLogic(): void {
    // called upon unsubscribing / cancelling the invocation of 'loadSVGContent()'
    this.tearDownAllTasks = true;
  }

  private parseDOM(): XMLDocument {
    const parser: DOMParser = new DOMParser();
    let start: number = performance.now();
    this.gmsGraphic.TraceService.info(this.traceModule, 'Parse DOM...');

    // Minified svg string passed
    const svgDoc: XMLDocument = parser.parseFromString(this.svgcontent, 'image/svg+xml') as XMLDocument;

    this.gmsGraphic.TraceService.info(this.traceModule, 'Parse DOM done; Time used: %s [ms]', performance.now() - start);
    start = performance.now();
    return svgDoc;
  }

  private createSymbolsLibrary(): NodeList {
    let start: number = performance.now();
    this.gmsGraphic.TraceService.info(this.traceModule, 'Creating symbols library...');

    const nodes: NodeList = this.svgDocument.childNodes;

    for (let i = 0; i < nodes.length; i++) {
      const graphicItems: Node = nodes[i];
      if (graphicItems.nodeName === 'graphicItems') {
        const graphicNodes: NodeList = graphicItems.childNodes;
        for (let j = 0; j < graphicNodes.length; j++) {
          const libNode: Node = graphicNodes[j];
          if (libNode.nodeName === 'libraries') {
            this.createLibrary(libNode);
            break;
          }
        }
      }
    }

    this.gmsGraphic.TraceService.info(this.traceModule, 'Create library done; Time used: %s [ms]', performance.now() - start);
    start = performance.now();
    return nodes;
  }

  private parseGraphics(nodes: NodeList): void {
    this.gmsGraphic.TraceService.info(this.traceModule, 'Parsing graphics...; runs async and incrementally loads');
    this.gmsGraphic.Initialize();
    this.layersQueue.length = 0;

    for (let i = 0; i < nodes.length; i++) {
      const item: Node = nodes[i];
      if (item.nodeName === 'graphicItems') {
        const version: string = SvgUtility.GetAttributeValue(item, 'version');
        if (version !== '0.9') {
          return;
        }
      }

      const graphicNodes: NodeList = item.childNodes;
      for (let j = 0; j < graphicNodes.length; j++) {
        const graphicNode: Node = graphicNodes[j];
        if (graphicNode.nodeName === 'graphic' && graphicNode.childNodes.length > 0) {
          const svgNodes: NodeList = graphicNode.childNodes;
          for (let k = 0; k < svgNodes.length; k++) {
            const svg: Node = svgNodes[k];
            if (svg.nodeName === 'svg') {
              this.gmsGraphic.TraceService.info(this.traceModule, 'Creating SVG-node...');
              this.createElement(svg);
              break;
            }
          }
        }
      }
    }
  }

  /**
   *
   * @param node
   */
  private createLibrary(node: Node): void {

    this.gmsSymbolLibrary = new GmsSymbolNodeLibrary();

    // Symbols
    // <libraries>
    //    <svg symbolRef="SomeSymbolReference" >
    // ... symbol definition ...
    //   </svg>
    //       < svg symbolRef= "AnotherSymbolReference" >
    // ... symbol definition ...
    //   </svg>

    const svgNodes: NodeList = node.childNodes;
    for (let i = 0; i < svgNodes.length; i++) {
      const svg: Node = svgNodes[i];
      if (svg.nodeName === 'svg') {
        this.gmsSymbolLibrary.addSymbol(svg);
      }
    }
  }

  private processSymbolTree(item: Node, gmsSymbolInstance: GmsSymbolInstance): void {
    const nodes: NodeList = item.childNodes;
    for (let i = 0; i < nodes.length; ++i) {
      const childItem: Node = nodes.item(i);
      const element: GmsElement = this.createElement(childItem);
      if (element !== null) {
        element.Parent = gmsSymbolInstance;
        element.Apply(gmsSymbolInstance.InstanceProperties);
      }
    }
  }

  private createElement(item: Node): GmsElement {

    // prempt
    // graphic is already destroyed - uscase - rapid switching of snapins
    if (this.gmsGraphic.IsDestroyed) {
      return;
    }

    let element: GmsElement = null;
    if (item === undefined || (item as Element).attributes === undefined) {
      return element;
    }

    // object access via string literals is disallowed
    const classTag = 'Class';
    const attrClass: Attr = (item as Element).attributes[classTag];

    if (attrClass === undefined) {
      return element;
    }
    const className: string = attrClass.nodeValue;

    try {
      switch (className) {
        case 'Graphic':
          element = this.createGraphic(item);
          break;
        case 'GmsLayer':
          element = this.createLayer(item);
          break;
        case 'GmsGroup':
          element = this.createGroup(item);
          break;
        case 'GmsPath':
          element = this.createPath(item);
          break;
        case 'GmsPolygon':
          element = this.createPolygon(item);
          break;
        case 'GmsEllipse':
          element = this.createEllipse(item);
          break;
        case 'GmsRectangle':
          element = this.createRectangle(item);
          break;
        case 'GmsLine':
          element = this.createLine(item);
          break;
        case 'GmsText':
          element = this.createText(item);
          break;
        case 'GmsImage':
          element = this.createImage(item);
          break;
        case 'GmsAnimatedGif':
          element = this.createAnimatedGif(item);
          break;
        case 'GmsXps':
          element = this.createXps(item);
          break;
        case 'GmsSymbolInstance':
          element = this.createSymbolInstance(item);
          break;
        case 'GmsCommandControlNumeric':
        case 'GmsCommandControlSelector':
        case 'GmsCommandControlSpinner':
        case 'GmsCommandControlSlider':
        case 'GmsCommandControlRotator':
        case 'GmsCommandControlString':
          element = this.createCommandControl(item, className);
          break;
        case 'GmsPipe':
        case 'GmsPipeCoupling':
          element = this.createPipe(item);
          break;
        default:
          break;
      }

      return element;
    } catch (ex) {
      this.gmsGraphic.TraceService.error(this.traceModule, className + ' Element deserialization failed.', ex.stack);
    }
  }

  /**
   *
   * @param item
   */
  private createGraphic(node: Node): GmsGraphic {
    if (node === undefined) {
      return null;
    }
    this.elementGraphicCount = this.elementGraphicCount + 1;

    const start: number = performance.now();
    this.gmsGraphic.TraceService.info(this.traceModule, 'createGraphic()...');

    this.gmsGraphic.Deserialize(node);

    this._ngZone.runOutsideAngular(() => {
      // addPoints2: Validate Datapoints and subscribe to COV's.
      this.gmsGraphic.TimerService.startPhase(TimerPhaseEnum.DatapointsProcessing);
      this.gmsGraphic.DatapointService.addPoints2(this.gmsGraphic.datapoints, this.gmsGraphic.SelectedObject.ObjectId);
      this.gmsGraphic.TimerService.stopPhase(TimerPhaseEnum.DatapointsProcessing);
      this.gmsGraphic.ReplicationService.Initialize();
    });

    const nodes: NodeList = node.childNodes;

    this.gmsGraphic.deleteResources();
    this.CopyDefs(nodes);

    this.gmsGraphic.DatapointService.RunInDeferredMode = true;
    this.gmsGraphic.DatapointService.DeferReplicationIndexResolution = true;

    const classTag = 'Class';

    for (let i = 0; i < nodes.length; ++i) {
      const childItem: Node = nodes[i];

      const attrClass: Attr = (childItem as Element).attributes[classTag];
      if (attrClass !== undefined && attrClass.nodeValue === 'GmsLayer') {
        this.layersQueue.push(childItem);
      }
    }

    for (let i = 0; i < nodes.length; ++i) {
      const childItem: Node = nodes.item(i);
      const layer: GmsLayer = this.createElement(childItem) as GmsLayer;
      if (layer != null && layer.Use === true) {
        layer.Parent = this.gmsGraphic;
      }
    }

    return this.gmsGraphic;
  }

  private onReadPropertiesMulti(args: PropertyChangeArgs, selectedObjectObjectId: string, guid: string): void {
    if (this._onReadPropertiesMultiSubscription !== undefined && this._onReadPropertiesMultiSubscription.has(guid)) {
      const sub: Subscription = this._onReadPropertiesMultiSubscription.get(guid);
      sub.unsubscribe();
      this._onReadPropertiesMultiSubscription.delete(guid);

    }
    // start graphics commands validation per a giving list of properties
    if (this.gmsGraphic.SelectedObject.ObjectId === selectedObjectObjectId) {
      this.gmsGraphic.GmsCommandService.validateCommands(args.Datapoints, this.gmsGraphic.commands);
    }
  }

  private async afterGraphicLoad(): Promise<void> {
    const elements: GmsElement[] = this.gmsGraphic.AllSubstitution;

    let result: Promise<void>;
    if (this.gmsGraphic.GraphicType === GraphicType.GraphicTemplate && this.gmsGraphic.ObjectReference !== undefined) {
      result = this.UpdateGraphicTemplateObjectReference(elements)
        .then(() => this.ProcessDatapoints())
        .then(() => this.CheckObjectRefExistence(elements));
    } else {
      this.ProcessDatapoints();
      result = this.CheckObjectRefExistence(elements);
    }

    const timerService: TimerService = this.gmsGraphic.TimerService;

    // Try stop timer phase: Deserialization
    if (timerService.isStartedPhase(TimerPhaseEnum.Deserialization) && !timerService.isEndedPhase(TimerPhaseEnum.Deserialization)) {
      this.gmsGraphic.TimerService.stopPhase(TimerPhaseEnum.Deserialization);
    }

    if (this.gmsGraphic !== undefined) {
      if (this.gmsGraphic.stateRestored === false) {
        this.gmsGraphic.scaleView.next(undefined);
      }

      this.gmsGraphic.SetGraphicLoaded();

      // update CA visibility
      this.gmsGraphic.updateCoverageArea.next();
      this?.gmsGraphic?.setupMenuItems?.next();
    }

    interval(1).pipe(take(1)).subscribe(() => {
      if (this.gmsGraphic !== undefined && this.gmsGraphic.TimerService !== undefined) {
        this.gmsGraphic.TimerService.logSummary();
        this.gmsGraphic.TimerService.reset();
      }
    });

    this.clearLoader();
  }

  private async ProcessDatapoints(): Promise<void> {
    // Stop deferring the datapoint/replication processing
    this.gmsGraphic.DatapointService.RunInDeferredMode = false;
    this.gmsGraphic.DatapointService.DeferReplicationIndexResolution = false;

    this._ngZone.runOutsideAngular(() => {
      this.gmsGraphic.TimerService.startPhase(TimerPhaseEnum.DeferredDatapointsProcessing);
      this.gmsGraphic.DatapointService.processDeferredDatapoints(this.gmsGraphic.SelectedObject.ObjectId).then(() => {
        this.gmsGraphic.TimerService.stopPhase(TimerPhaseEnum.DeferredDatapointsProcessing);
      });

      // Resolve the indices for replication
      // Resolve replications wildcards "[*]" and start cloning
      this.gmsGraphic.DatapointService.processDeferredWildCards()
        .then(() => this.gmsGraphic.ReplicationService.ProcessDeferredReplications())
        .then(() => {
          if (this.gmsGraphic.ShowAlarmIndication) {
            this.gmsGraphic.DatapointService.subscribeToEvents();
          }
        }
        );
    });
  }

  // Clear any loader resources used per graphic load.
  private clearLoader(): void {
    this.defsCopiedForSymbols.length = 0;
  }

  private async CopyDefs(nodes: NodeList): Promise<void> {
    const resources: Node[] = [];

    // Load the defs, images
    if (nodes.length > 0) {

      for (let j: number = nodes.length - 1; j >= 0; j--) {
        const childItem: Node = nodes.item(j);
        if (childItem.nodeName === 'defs') {
          resources.push(childItem);
        }

        if (childItem.nodeName === 'symbol') {
          resources.push(childItem);
        }
      }
    }

    if (resources.length > 0) {
      this.gmsGraphic.AddResources(resources);
    }
  }

  private async UpdateGraphicTemplateObjectReference(elements: GmsElement[]): Promise<void> {

    const substObjectReference: Substitution = new Substitution('*', this.gmsGraphic.ObjectReference);
    elements.forEach(element => {
      // handle all star substitutions from all evaluations
      if (element.Evaluations !== undefined) {
        element.Evaluations.forEach(evaluation => {
          evaluation.Expressions.forEach(expression => {
            expression.Substitutions.forEach(substitution => {
              if (substitution.IsObjectRef) {
                // since this is not a child of a symbol instance, the SubstitutionSources have to be updated here
                if (!substitution.SubstitutionSources.includes(evaluation)) {
                  substitution.SubstitutionSources.push(evaluation);
                }
              }
            });
            expression.Update(false, substObjectReference);
          });
        });
      }

      // handle all star substitutions from the GmsSymbolInstance.Substitutions collection (but only when GmsSymbolInstance.ObjectRef contains "{*}")
      if (element instanceof GmsSymbolInstance) {
        element.UpdateGraphicViewObjectReference(substObjectReference);
      }
    });
  }

  // only top symbol instances are hidden
  private async CheckObjectRefExistence(elements: GmsElement[]): Promise<void> {
    elements.forEach(element => {
      if (element instanceof GmsSymbolInstance && element.IsTopLevelSymbolInstance()) {
        element.CheckObjectReferenceExistence();
      }
    });
  }

  // NOTE: check createLayer
  private createLayer(node: Node): GmsLayer {
    if (node === undefined) {
      return null;
    }

    this.gmsGraphic.TraceService.info(this.traceModule, 'createLayer()...; No. of children: %s', node.childNodes.length);
    const layer: GmsLayer = new GmsLayer();
    layer.Graphic = this.gmsGraphic;
    layer.Deserialize(node);
    this.gmsGraphic?.UnsetIsLoadingBeforeDeserialization();

    if (node.childNodes.length > 0) {
      const initIdx = 0;
      const groupSize: number = Math.ceil(node.childNodes.length / 10);

      const task: (args: any) => void = (state: { nodes: NodeListOf<Node>; idx: number; displayName: string }) => {
        // prempt the async action
        // graphic is already destroyed - uscase - rapid switching of snapins
        if (this.gmsGraphic.IsDestroyed) {
          return;
        }

        if (this.gmsGraphic.DisplayName !== state.displayName) {
          return;
        }

        const upper: number = Math.min((state.idx + groupSize), state.nodes.length);
        let i: number = state.idx;

        for (i = state.idx; i < upper; ++i) {
          const childItem: Node = state.nodes.item(i);
          this.gmsGraphic.TraceService.info(this.traceModule, 'Create layer child element: %s', i);

          const element: GmsElement = this.createElement(childItem);
          // NOTE: check if double equals is necessary
          if (element != null) {
            element.Parent = layer;
          }

        }

        state.idx = upper;
        if ((state.idx < state.nodes.length)) {
          asyncScheduler.schedule(task, 0, { nodes: state.nodes, idx: state.idx, displayName: this.gmsGraphic.DisplayName });
        } else {
          this.gmsGraphic.TraceService.info(this.traceModule, 'createLayer() done');

          if (this.layersQueue.includes(node)) {
            const index: number = this.layersQueue.indexOf(node);
            this.layersQueue.splice(index, 1);
          }

          if (this.layersQueue.length === 0) {
            this.afterGraphicLoad();
          }

          this.observer.next(true);
          this.observer.complete();
        }
      };

      // Run Outside Angular could work and fix incremental painting of graphics
      asyncScheduler.schedule(task, 0, { nodes: node.childNodes, idx: initIdx, displayName: this.gmsGraphic.DisplayName });
    } else {
      if (this.layersQueue.includes(node)) {
        const index: number = this.layersQueue.indexOf(node);
        this.layersQueue.splice(index, 1);
      }

      if (this.layersQueue.length === 0) {
        this.afterGraphicLoad();
      }
    }

    return layer;
  }

  private createGroup(node: Node): GmsGroup {
    if (node === undefined) {
      return null;
    }
    const group: GmsGroup = new GmsGroup();
    group.Graphic = this.gmsGraphic;
    group.Deserialize(node);

    let nodes: NodeList = node.childNodes;

    for (let i = 0; i < nodes.length; ++i) {
      const childItem: Node = nodes.item(i);

      // skip the ScaleTransform group and access the styles
      if (SvgUtility.IsNodeScaleTransformationGroup(childItem)) {
        i = -1;
        nodes = childItem.childNodes;
        continue;
      }

      const element: GmsElement = this.createElement(childItem);
      // element
      if (element != null) {
        element.Parent = group;
      }
    }

    return group;
  }

  private createRectangle(node: Node): GmsRectangle {
    if (node === undefined) {
      return null;
    }

    const rectangle: GmsRectangle = new GmsRectangle();
    rectangle.Graphic = this.gmsGraphic;
    rectangle.Deserialize(node);

    return rectangle;
  }

  private createPath(node: Node): GmsPath {
    if (node === undefined) {
      return null;
    }

    const path: GmsPath = new GmsPath();
    path.Graphic = this.gmsGraphic;
    path.Deserialize(node);

    return path;
  }

  private createText(node: Node): GmsText {
    if (node === undefined) {
      return null;
    }

    const text: GmsText = new GmsText();
    text.Graphic = this.gmsGraphic;
    text.Deserialize(node);

    return text;
  }

  private createLine(node: Node): GmsLine {
    if (node === undefined) {
      return null;
    }

    const line: GmsLine = new GmsLine();
    line.Graphic = this.gmsGraphic;
    line.Deserialize(node);

    return line;
  }

  private createPolygon(node: Node): GmsPolygon {
    if (node === undefined) {
      return null;
    }

    const polygon: GmsPolygon = new GmsPolygon();
    polygon.Graphic = this.gmsGraphic;
    polygon.Deserialize(node);

    return polygon;
  }

  private createEllipse(node: Node): GmsEllipse {
    if (node === undefined) {
      return null;
    }

    const ellipse: GmsEllipse = new GmsEllipse();
    ellipse.Graphic = this.gmsGraphic;
    ellipse.Deserialize(node);

    return ellipse;
  }

  private createImage(node: Node): GmsImage {
    if (node === undefined) {
      return null;
    }

    const image: GmsImage = new GmsImage();
    image.Graphic = this.gmsGraphic;
    image.Deserialize(node);

    return image;
  }

  private createAnimatedGif(node: Node): GmsAnimatedGif {
    if (node === undefined) {
      return null;
    }

    const gifImage: GmsAnimatedGif = new GmsAnimatedGif();
    gifImage.Graphic = this.gmsGraphic;
    gifImage.Deserialize(node);

    return gifImage;
  }

  private createXps(node: Node): GmsXps {
    if (node === undefined) {
      return null;
    }

    const xps: GmsXps = new GmsXps();
    xps.Graphic = this.gmsGraphic;
    xps.Deserialize(node);

    return xps;
  }

  /**
   *
   * @param node
   */
  private createSymbolInstance(node: Node): GmsSymbolInstance {
    if (node === undefined) {
      return null;
    }
    let gmsSymbolInstance: GmsSymbolInstance = null;
    const symbolRefId: string = SvgUtility.GetAttributeValue(node, 'SymbolRef');
    const gmsSymbolNode: Node = this.gmsSymbolLibrary.getSymbol(symbolRefId);
    if (gmsSymbolNode !== undefined) {

      // NOTE:  InstanceProperties
      // parse GmsSymbolInstance.InstanceProperties Node
      // if an element's Id set, collect the element's modified property(s)

      gmsSymbolInstance = new GmsSymbolInstance();
      gmsSymbolInstance.Graphic = this.gmsGraphic;
      gmsSymbolInstance.Deserialize(node);

      const nodes: NodeList = gmsSymbolNode.childNodes;

      // Bounding Rectangle
      // This could be seperate attributes written to SABT
      const actualWidth: string = SvgUtility.GetAttributeValue(gmsSymbolNode, GmsElementPropertyType.ActualWidth);
      const actualHeight: string = SvgUtility.GetAttributeValue(gmsSymbolNode, GmsElementPropertyType.ActualHeight);

      if (actualWidth !== undefined && actualHeight !== undefined) {
        gmsSymbolInstance.ActualWidth = +actualWidth;
        gmsSymbolInstance.ActualHeight = +actualHeight;
      }

      // Copy symbol's Defs and  images only once per graphic.
      const symbolref: string = symbolRefId.toLowerCase();
      if (!this.defsCopiedForSymbols.includes(symbolref)) {
        this.defsCopiedForSymbols.push(symbolref);
        this.CopyDefs(nodes);
      }

      for (let i = 0; i < nodes.length; ++i) {
        const childNode: Node = nodes.item(i);
        const sabt: string = SvgUtility.GetAttributeValue(childNode, 'id');
        if (sabt === 'SABT') {
          const anchorX: string = SvgUtility.GetAttributeValue(childNode, 'AnchorX');
          const anchorY: string = SvgUtility.GetAttributeValue(childNode, 'AnchorY');

          gmsSymbolInstance.AnchorX = anchorX ? +anchorX : 0;
          gmsSymbolInstance.AnchorY = anchorY ? +anchorY : 0;

          const nodesSABT: NodeList = childNode.childNodes;
          for (let j = 0; j < nodesSABT.length; ++j) {
            const childItem: Node = nodesSABT.item(j);
            let element: GmsElement = null;
            if (childItem !== undefined && (childItem as Element).attributes !== undefined) {
              const classTag = 'Class';
              const attrClass: Attr = (childItem as Element).attributes[classTag];
              if (attrClass !== undefined) {
                const className: string = attrClass.nodeValue;
                if (className === 'GmsLayer') {
                  // Add children to the gmsSymbolInstance
                  this.processSymbolTree(childItem, gmsSymbolInstance);
                } else {
                  element = this.createElement(childItem);
                  if (element !== null) {
                    element.Parent = gmsSymbolInstance;
                    element.Apply(gmsSymbolInstance.InstanceProperties);
                  }
                }
              }
            }
          }
        }
      }

      gmsSymbolInstance.UpdateWidthOfChildren();
      gmsSymbolInstance.UpdateHeightOfChildren();
      gmsSymbolInstance.ProcessSubstitutions();
    } else {

      this.gmsGraphic.TraceService.warn(this.traceModule, 'Symbol not found: %s', symbolRefId);

      const tempSymbolInstance: GmsSymbolInstance = new GmsSymbolInstance();
      tempSymbolInstance.Graphic = this.gmsGraphic;

      gmsSymbolInstance = new GmsSymbolInstance();
      gmsSymbolInstance.Graphic = this.gmsGraphic;
      gmsSymbolInstance.Deserialize(node);
      gmsSymbolInstance.ActualWidth = gmsSymbolInstance.Width;
      gmsSymbolInstance.ActualHeight = gmsSymbolInstance.Height;

      // Generate RefError symbol elements

      const text: GmsText = new GmsText();
      text.Graphic = this.gmsGraphic;
      text.X = 0;
      text.Y = 0;
      text.Width = Math.max(5, gmsSymbolInstance.Width);
      text.Height = Math.max(5, gmsSymbolInstance.Height);
      text.VerticalAlignment = GmsTextAlignmentType.Center;
      text.HorizontalAlignment = GmsTextAlignmentType.Center;
      text.Background = '#FFE37F';
      text.Text = '#Ref';
      text.BoundingRectDesign = new BoundingRectangle(text.X, text.Y, text.Width, text.Height);
      text.Parent = gmsSymbolInstance;

      const element: GmsRectangle = new GmsRectangle();
      element.Graphic = this.gmsGraphic;
      element.X = 0;
      element.Y = 0;
      element.Width = 5;
      element.Height = Math.max(5, gmsSymbolInstance.Height);

      // NOTE: blinking colors - red/yellow
      element.Fill = '#FF0000'; // "#FF0000/#FFFF00"
      element.Stroke = 'transparent';
      element.Blinking = true;
      element.BoundingRectDesign = new BoundingRectangle(element.X, element.Y, element.Width, element.Height);
      element.Parent = gmsSymbolInstance;
      // element.Visible = false;
    }

    return gmsSymbolInstance;
  }

  private createCommandControl(node: Node, commandControlType: string): GmsCommandControl {

    if (node === undefined) {
      return null;
    }
    let commandControl: GmsCommandControl;

    switch (commandControlType) {

      case 'GmsCommandControlNumeric':
        commandControl = new GmsCommandControlNumeric();
        break;

      case 'GmsCommandControlString':
        commandControl = new GmsCommandControlString();
        break;

      case 'GmsCommandControlSelector':
        commandControl = new GmsCommandControlSelector();
        break;

      case 'GmsCommandControlSpinner':
      case 'GmsCommandControlSlider':
      case 'GmsCommandControlRotator':
        let nodes: NodeList = node.childNodes;
        commandControl = commandControlType === 'GmsCommandControlSpinner' ?
          new GmsCommandControlSpinner() :
          commandControlType === 'GmsCommandControlSlider' ?
            new GmsCommandControlSlider() :
            new GmsCommandControlRotator();
        // adding clone
        if (commandControlType !== 'GmsCommandControlSpinner') {
          for (let i = 0; i < nodes.length; ++i) {
            const childItem: Node = nodes.item(i);

            // skip the ScaleTransform group and access the styles
            if (SvgUtility.IsNodeScaleTransformationGroup(childItem)) {
              i = -1;
              nodes = childItem.childNodes;
              continue;
            }
            const clone: GmsElement = this.createElement(childItem);
            if (clone !== null) {
              clone.IsSlidingClone = true;
              clone.Parent = commandControl;
            }
          }
        }

        for (let i = 0; i < nodes.length; ++i) {
          const childItem: Node = nodes.item(i);

          // skip the ScaleTransform group and access the styles
          if (SvgUtility.IsNodeScaleTransformationGroup(childItem)) {
            i = -1;
            nodes = childItem.childNodes;
            continue;
          }
          const element: GmsElement = this.createElement(childItem);
          if (element != null) {
            element.Parent = commandControl;
          }
        }
        break;

        // NOTE: add all other command control type
      default:
        return null;

    }
    commandControl.Graphic = this.gmsGraphic;
    commandControl.Deserialize(node);

    return commandControl;
  }

  private createPipe(node: Node): GmsPipe {
    if (node === undefined) {
      return null;
    }
    const pipe: GmsPipe = new GmsPipe();
    pipe.Graphic = this.gmsGraphic;

    pipe.Deserialize(node);

    let nodes: NodeList = node.childNodes;

    for (let i = 0; i < nodes.length; ++i) {
      const childItem: Node = nodes.item(i);

      // skip the ScaleTransform group and access the styles
      if (SvgUtility.IsNodeScaleTransformationGroup(childItem)) {
        i = -1;
        nodes = childItem.childNodes;
        continue;
      }

      const element: GmsElement = this.createElement(childItem);
      // element
      if (element !== null) {
        element.Parent = pipe;
      }
    }
    return pipe;
  }
}
