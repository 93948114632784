import { GmsSubscription, PropertyCommand } from '@gms-flex/services';
import { Subscription } from 'rxjs';

export class SubscribedProperty {

  public _commandSubscription: GmsSubscription<PropertyCommand>;
  public get CommandSubscription(): GmsSubscription<PropertyCommand> {
    return this._commandSubscription;
  }

  public set CommandSubscription(value: GmsSubscription<PropertyCommand>) {
    this._commandSubscription = value;
  }

  private _commandChangeSubscription: Subscription;
  public get CommandChangeSubscription(): Subscription {
    return this._commandChangeSubscription;
  }

  public set CommandChangeSubscription(value: Subscription) {
    this.unsubscribeCommandSubscription();
    this._commandChangeSubscription = value;
  }

  /**
   * Subcribed commands reference counter
   */
  private _refCommandsCount = 0;
  /** DpIdentifier of a property, subcribed for enabled commands status updates
   */
  private readonly _dpId: string;
  public get DpId(): string {
    return this._dpId;
  }

  public addCommand(): void {
    this._refCommandsCount++;
  }

  public deleteCommand(): boolean {
    --this._refCommandsCount;
    return this._refCommandsCount <= 0;
  }

  public constructor(dpId: string) {
    this._dpId = dpId;
    this._refCommandsCount = 0;
  }

  public unsubscribeCommandSubscription(): void {
    if (this._commandChangeSubscription && !this._commandChangeSubscription.closed) {
      this._commandChangeSubscription.unsubscribe();
    }
    this._commandChangeSubscription = undefined;
  }

}
