import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IHfwMessage } from '@gms-flex/core';
import { UserRole, UserRolesService } from '@gms-flex/services';
import { AppContextService, SessionCookieStorage, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiSidePanelService } from '@simpl/element-ng';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, Subscription } from 'rxjs';

import { TraceModules } from '../shared/trace-modules';

@Component({
  selector: 'gms-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrl: '../gms-navbar.scss'
})

export class UserRolesComponent implements OnInit {
  public dialogTitle: string; // "User Roles";
  public dialogApplyBtn: string; // "Ok";
  public dialogCancelBtn: string; // "Cancel";
  public dialogNoRolesToDisplay: string; // "No role to display";
  public userRoles: UserRole[] = null;
  public prevUserRoles: UserRole[] = null;

  public constructor(
    private readonly router: Router,
    public bsModalRef: BsModalRef,
    private readonly translateService: TranslateService,
    private readonly traceService: TraceService,
    private readonly userRolesService: UserRolesService,
    private readonly appContextService: AppContextService,
    private readonly cookieService: CookieService,
    private readonly sidePanelService: SiSidePanelService,

    @Inject(IHfwMessage) private readonly messageBroker: IHfwMessage) {
  }

  private readonly subscriptions: Subscription[] = [];
  private userLang: string;
  public ngOnInit(): void {
    this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
      if (defaultCulture != null) {
        this.translateService.setDefaultLang(defaultCulture);
      } else {
        this.traceService.warn(TraceModules.navigationBar, 'No default Culture for appContextService');
        this.translateService.setDefaultLang(this.userLang === undefined ? this.translateService.getBrowserLang() :
          this.userLang);
      }
    }));
    this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
      if (userCulture != null) {
        this.translateService.use(userCulture).subscribe((res: any) => {
          this.traceService.info(TraceModules.navigationBar, 'use  user Culture');
        });
      } else {
        this.traceService.warn(TraceModules.navigationBar, 'No user Culture for appContextService');
      }
    }));

    this.userRolesService.getUserRoles().toPromise().then(res => {
      if (res != null) {
        this.userRoles = res;
        this.prevUserRoles = JSON.parse(JSON.stringify(this.userRoles));
      }
    });

    this.subscriptions.push(this.translateService.get([
      'ROLES.LABEL',
      'ROLES.APPLY-BTN',
      'ROLES.CANCEL-BTN',
      'ROLES.NO-ROLES-TO-DISPLAY'
    ]).subscribe(values => this.onTraslateStrings(values)));
  }

  public changeRoleState(role: UserRole): void {
    this.userRoles.forEach(element => {
      if (element.RoleId === role.RoleId) {
        element.RoleState = !element.RoleState;
      }
    });
  }

  public onApply(): void {
    this.messageBroker.canChangeUserRoles().toPromise().then(res => {
      if (res) {
        this.userRolesService.updateUserRoles(this.userRoles).toPromise().then(() => {
          sessionStorage.setItem(SessionCookieStorage.UserRoles, SessionCookieStorage.True);
          location.reload();
        });
      }
    });
  }

  public get isDataChanged(): boolean {
    return JSON.stringify(this.prevUserRoles).localeCompare(JSON.stringify(this.userRoles)) !== 0;
  }

  public onCancel(): void {
    this.userRoles = JSON.parse(JSON.stringify(this.prevUserRoles));
  }

  public onClose(): void {
    this.sidePanelService.hideTemporaryContent();
  }

  private onTraslateStrings(strings: string[]): void {
    this.dialogTitle = strings['ROLES.LABEL'];
    this.dialogApplyBtn = strings['ROLES.APPLY-BTN'];
    this.dialogCancelBtn = strings['ROLES.CANCEL-BTN'];
    this.dialogNoRolesToDisplay = strings['ROLES.NO-ROLES-TO-DISPLAY'];
    this.userLang = this.translateService.getBrowserLang();
  }

  /**
   * Atomic code for align sessionstorage and cookies
   */
  private cookiesChanges(booleanString: string): void {
    sessionStorage.setItem(SessionCookieStorage.UserRoles, booleanString);
  }

}
