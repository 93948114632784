<form class="form-group mb-5">
  <label class="form-label" for="category"> {{ translatedLabels.get('category') }} </label>
  <si-select
    name="category"
    id="category"
    class="form-select"
    [options]="categoryValues"
    [(value)]="selectedCategory"
    (valueChange)="onChange($event)"
  />
  <div *ngIf="isAnalog" class="col-1"></div>
</form>
