/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { SearchTypes } from '../data-model/search.data.model';

@Injectable({
  providedIn: 'root'
})
export class SearchConfig {
  public displayType: Subject<SearchTypes> = new Subject<SearchTypes>();

  private config: any = {

    /**
     * This is the number of pages worth tile items to keep in memory and UI. 3 is the default
     * value as it would be ideal to load one page above and oone page below the current page
     */
    numberOfFolds: 3,

    // this is height of the tile of px
    tileHeight: 139,

    // this is the width of the tile in px
    tileWidth: 243,

    // this is the left and right margin value of the tile in px
    leftRightMargin: 20,

    // this is the top and  bottom margin value of the tile in px
    topBottomMargin: 20,

    // this is number of tile items per page
    numberOfTiles: 10,

    // in case of empty trend use this local storageID.
    DefaultLocalStorageID: 'DEFAULT_LOCAL_STORAGE_ID',

    // in case of empty trend use this local storageID.

    DefaultLocalStorageData: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSF` +
    `lzAAADkgAAA5IByhLtsAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAlx` +
    `SURBVHja7Z1RTBNpHsCLMbBerAWxFz2JOaNxs2diw8OttAcsyT2QvcSHe/DejMk9kLjRZE0IotmV8chec` +
    `jG5B9/uLrtlHzzosBuxchVaOHBBo7TjhjO4lTJsNq45rt0W6Mxgqcjc/6vthuOg/T6YKbTzb/ILjQzWnd+P+b75prM1q` +
    `apqMhLw2HHS4WiyO+w9NQ77CwJ5Tv6MfM9w+8NI/7Hv1r97uMbhGAHp6to4Rsg2GEARcvz48dKaX9nH15efBrYh22IARUa` +
    `No6Y9p/wfqWnHAIouAHuIPgB7CAMoIk6ePLkHpC4zBLBMfgYDKBJqa2sPMchPQX4GA8gTLX9qMbdyXMOla1ffv/zHy1adh` +
    `oAoQwBRPf4NlZWVB8vLy6srKirqqqqqdhk+gOb2jw5f4jjvJa7tNaBmaOXaxls4rkbTAOx2N3UAsK2Wrw3S6/fu3TsI4tU` +
    `VKMAtiOIdQwZwmeN+A/LlleJX8br12tWLWr2e3W4/CnIVigAUsq2G8s+uEr+aGDkiGCqAZo77KcgPZ5Gf4VXrHz7+pWaTQY` +
    `fjDAhezCJ/kWyjofwGkJvMEQBBMpvNlYYJAOT/lUJ+Gm5M09XA2lobiBbWkC+Q72n5WiC2l0J+hk8MFEDbBEMASxf/fFHzC` +
    `VNdXZ0VDvWNBPJc679/3759ZpCaYAhANEQAHMe9RaTSBwCTQg2HgXwBh38bg/zUMGCUAHbkmPz9Hy3tH71daAHArL+KMYCk` +
    `yWQqMcoQcJ8hAIlEU4CXnUtBqkwbABwxvIaZA8A5/ofUh3+O6yjUVTYQe53hCPCBYQJ4Mwy0jVBMAL//kOPKCzUAq9W6H8R` +
    `GKOTfgyNGmaEWguAo8DMQPJAlgAmY/FUX+lq7xWI5AoKDWeQLsE2FIZeCTaqpBA7xv2/l2u5ADDPAHIgfbLl29fKFGxfKCl` +
    `3+iqHAApPCJvjqgbF+Bp5PwvNh+Hp6q9+Gts2uTOV/Fmx0cCdgALgTMAAEA0AwAAQDQDAABAOgwel0voU7cNtceCrRPQBnZ` +
    `+fPnS7XDSfvCgNLwDIw6uzuunDD4ylDEXmXXgacB4aBJWAOeAo0A7s1DeDz7q73O3jXKxCursO9mzdvVqCYvMm3AEMmsna6` +
    `NhGgWpMAOr74+3EQPJ9FfgahEK/bF+LhHniYRX6GMHBs0wGA2FsU8t/Q3XUaJekewCkK+RncmwqA5/ldIFahDoB3DaMk3QP` +
    `wMgSQAMwbDsDZ3VnHIB/omkRJugcQZAiA8N6GA/j8i85qtgBcMyhJ9wBmGAOwbTwAnj/IEsBnPO9BSboHMMAg/zVg3ewkcJ` +
    `A2gA6eb0JJugdwjiGA7s2fBrpc9ZQBBP/C8xaUlJc1gCcU8l/lOvxTLwR95nKdBcHJ9X/zXZGOrq4jKChvERwAxByz/1PaL` +
    `gV3dzaA7F4gsUK+3NHtuu7k+f0oZkuOBFfSCz4Z8eTuortAvW4Xgz69fdv8aVeX7W9fflnF83wpytjyEHamjwgnAOZ7KHAn` +
    `4uVg3AkYAIIBIBgAggEgGACCASAYAIIBIBgAggEgGACCASAYAGKcADwez547Pt8h3EFFA3G5J2sA5M0dt32+drfPGwKWARW` +
    `Iuge87tt9fUdxJxYcxBm5M4h8/A15LAPk09DIR+KV/k8A7v7+w25f/3ha+loobq/3DO7UgoG4UtT1H+NA6hNSU//bVhA8kk` +
    `V+hsU7fX023LnbHuJoUc39GAF2mNwD/U0U8tP4BNzB2x5BpX80meDQ30MfgFeFCaIVd/K2xaqyPXogAO8LlgB6fb5G3NHbl` +
    `kbGAF5gABgADgHGHgJwEmjsSSCeBhr8NBAXggy+EIRLwbgUjBeD8GIQgpeDEQwAwQAQDADBABAMAMEAEAwAwQAQDADBABAM` +
    `AMEAEAwAMXgAQ19/Xf5QEE58FQgcGBoa2ok7ccspXVhYqJKTSVtEVc26BXD/8Vj9qN9/937AnwTUNOFRwX8lEAhYUER+kWV` +
    `5fzyRuB5/+VIG1DQJoFd6+bJB0wAeCI9+C7ITK8SvRiRHBBSTH+YSiSMgOrJC/GqSwFlNAhgVhBoQvJRFfoYneCTQn5iqWk` +
    `BuMIv8H4EjQf2mA7jv9w9QyE8TOIeS9EVKJJpo5KcZ3FQAXz1++At6+Sn+iZL0BaR6GAJQYYJ4cMMBPBCERsYA8IMj9Q9gh` +
    `iUARVGqNxzAqDD2O8YAgihJ9wAmWQIA6jY+BMDMnnICmOEfKEn3AIZp5cMkkNwdtGtzZwFvzv2pAnjweOzXKEnnSeDCwmnq` +
    `3/5E4tamzwJGHj06RhZ8KALoR0F5YQecCQgU8mPS4uI72iwE+f3VIDiyvvyxvkAg8BOUk6eFoLm5CpB8L0sAUq6xn3kpeGh` +
    `iYveDwFgzCH8KzKXmBoJ/GIaI8xMTE/gBkvmnDI4EH8A4702v/BHpIvBJPB6vzMfVwBKUsG3YsAvceXg5GHcCBoBgAAgGgG` +
    `AACAaAYAAIBpC3de3g9PTp4NTU8KQoTj4TxRnAMzk11SSKYtG8vSwWi1kkSTo3L8sDcVmeAYKSLHvjinJqqxfUtuyF//Xdd` +
    `xVBURRAuLoOwVAodKTQ5SuKcgCEPwHUdXhIAjFUACC2DATfyyI/Q2Ti22/3F/JvflySxCzyMwyRtX3DBPBsevo8hfwUMDxc` +
    `L9g3byjKFQr5KeYl6bxhAiBjPm0AgFygVxt3gtgwbQDAsFECKAGpSwwBqDBkVBXo2K8ysLQVE8KtGQJEcY4lABgybAV469Y` +
    `JxgDmjDMHmJp6yhBAIhgMmrX+N/xbkqzRaLSRQJ5r/ffPzs6Wg9QkQwBPDRPA5PR0M0MAvVq+9n9mZ20/xGICoK5CIN/TdB` +
    `Ioy3cZAmg2TAAwqdtNTvEo5Ce/CYUatHrdH2Znz4DoxTXkZ1gk22j2Dl5JqgexCQr5kXA4vNtQC0EwsasGweGsAUxNndXq9` +
    `cLz80dBsJJFfgaFbKvhqeApEPwqi/xwrrt3inYp+BtRPAaibwVFUVklfxCGiXotXwvEuinkZ3BrPCG0QQjdIPv1CvHkyOCO` +
    `x+PHDH8x6Pnz57tgpl+XOio8e3ZQj9cAqVGGAKK63NQBk00SA3x9LxKJmLfDvjfGPfWx2CEG+SnIz+DVwCIBTvX2gNRlhgC` +
    `Wyc9gAEUESA0xBBDC9wMUWwDRaDt1ALAtBlB8lEZisfFc8sk26orP1cMAiuumysMgeCSL/BGyDb4lrMhvrwbRTSC8B3iRpo` +
    `f8Gfme0fbHfwFmjpaazJCONgAAAABJRU5ErkJggg==`,
    /**
     * Number of tiles to load initially. By default, the value is 20.
     * This is to be set at the begining before making the first search request.
     * This is not to be updated for each subsequent search, as this will be updated automatically based on the available space.
     */
    initialTileCount: 20,

    // maximum no of local images
    localStorageUpperLimit: 50,

    // chech timestamp difference if image is 1 day older
    // 60sec * 60min * 24hr
    daySeconds: 86400,

    displayType: SearchTypes.NAME,
    contentActionControlConfig: {
      show: false,
      viewType: 'collapsible',
      primaryActions: [],
      secondaryActions: []
    },

    /**
     * comma-seperated array of strings, that should not be used for searching of tiles, in the search box.
     */
    prohibitedCharacters: '*, ?'
  };

  // Gets the configuration.
  public get(): any {
    return this.config;
  }

  // Updates the specified configuration values.
  public update(config: any): void {
    this.config = Object.assign(this.config, config);
    this.displayType.next(this.config.displayType);
  }
}
