import { TranslateService } from '@ngx-translate/core';
import { Observable, of as observableOf } from 'rxjs';

export enum ErrorDisplayMode {
  /**
   * the GUI shows the error as a 'toast'; the toast disappers after some seconds automatically, user input is blocked while the toast is 'Active'
   */
  Toast = 0,
  /**
   * the GUI shows the error as a modal dialog, user input is blocked while the error is 'Active',
   * the modal dialog disappers when the error state changes to either 'Inactive' or 'Deleted'.
   */
  Modal = 1
}

export enum ErrorDisplayState {
  Active = 0,
  Inactive = 1,
  Deleted = 2
}

export class ErrorDisplayItem {

  private static idCounter = 0;
  private readonly _id: number;
  private _message!: string;
  private _messageKey!: string;
  private _messageParams!: string[];
  private _title!: string;
  private _titleKey!: string;

  public static convertState(state: number): string {
    if (state === ErrorDisplayState.Active) {
      return 'Active';
    } else if (state === ErrorDisplayState.Deleted) {
      return 'Deleted';
    } else if (state === ErrorDisplayState.Inactive) {
      return 'Inactive';
    } else {
      return 'Unknown';
    }
  }

  public static convertMode(mode: number): string {
    if (mode === ErrorDisplayMode.Modal) {
      return 'Modal';
    } else if (mode === ErrorDisplayMode.Toast) {
      return 'Toast';
    } else {
      return 'Unknown';
    }
  }

  /**
   * Creates an instance of ErrorDisplayItem.
   * @param mode See enum definition
   * @param [state=ErrorDisplayState.Active] See enum definition.
   * @param [debounceTime=undefined] debounceTime in millisecond. If set, error state changes are debounced.
   * @memberof ErrorDisplayItem
   */
  public constructor(public mode: ErrorDisplayMode, public state: ErrorDisplayState = ErrorDisplayState.Active, public debounceTime?: number) {
    this._id = ErrorDisplayItem.idCounter;
    ErrorDisplayItem.idCounter++;
  }

  /**
   * Unique id of the error.
   *
   * @readonly
   * @type {number}
   * @memberof ErrorDisplayItem
   */
  public get id(): number {
    return this._id;
  }

  /**
   * Sets any message text.
   *
   * @param message
   * @memberof ErrorDisplayItem
   */
  public setDisplayMessage(message: string): void {
    this._message = message;
  }

  /**
   * Sets the translation key for any message with optional params.
   *
   * @param messageKey
   * @param optionalParams
   * @memberof ErrorDisplayItem
   */
  public setDisplayMessageKey(messageKey: string, ...optionalParams: string[]): void {
    this._messageKey = messageKey;
    this._messageParams = optionalParams;
  }

  /**
   * Gets the stored message.
   *
   * @param translateService
   * @returns
   * @memberof ErrorDisplayItem
   */
  public getMessage(translateService: TranslateService): Observable<string> {
    if (this._message !== undefined) {
      return observableOf(this._message);
    } else if (this._messageKey !== undefined) {
      return translateService.get(this._messageKey, this._messageParams);
    } else {
      return observableOf('No message defined!');
    }
  }

  /**
   * Sets the title text.
   *
   * @param title
   * @memberof ErrorDisplayItem
   */
  public setDisplayTitle(title: string): void {
    this._title = title;
  }

  /**
   * Sets the translation key for the title text.
   *
   * @param titleKey
   * @memberof ErrorDisplayItem
   */
  public setDisplayTitleKey(titleKey: string): void {
    this._titleKey = titleKey;
  }

  /**
   * Gets the title text.
   *
   * @param translateService
   * @returns
   * @memberof ErrorDisplayItem
   */
  public getTitle(translateService: TranslateService): Observable<string> {
    if (this._title !== undefined) {
      return observableOf(this._title);
    } else if (this._titleKey !== undefined) {
      return translateService.get(this._titleKey);
    } else {
      return observableOf('No title defined!');
    }
  }

  public getTrace(): string {
    return `id=${this._id}; titleKey=${this._titleKey}; msgKey=${this._messageKey};
    mode=${ErrorDisplayItem.convertMode(this.mode)}; state=${ErrorDisplayItem.convertState(this.state)}; debounceTime=${this.debounceTime}`;
  }
}
