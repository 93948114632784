@if (
  (!disableParentClick || !item.items?.length) &&
  (!disableLinklessClick || item.action || item.link || item.href) &&
  !item.isHeading
) {
  <a
    #menuItem
    [ngClass]="itemClass"
    [class.focus-inside]="focusStylingInside"
    [class.active]="forceActive || active"
    [class.disabled]="item.disabled"
    [class.dropdown-item-icon-only]="
      iconOnly ||
      (!item.title &&
        item.icon &&
        !item.selectionState &&
        !dropdownCaret &&
        (!item.items || item.items.length === 0))
    "
    [class.dropdown-item-no-link]="item.type"
    [siLink]="item"
    [actionParam]="actionParam"
    [attr.tabindex]="disableListInteraction ? '' : '-1'"
    [attr.aria-label]="item.title | translate"
    [attr.aria-disabled]="item.disabled"
    [attr.aria-checked]="getAriaChecked(item)"
    [attr.data-id]="item.id"
    [attr.role]="getItemRole(item)"
    (activeChange)="linkActiveChange($event)"
    (keydown.space)="handleSpace()"
  >
    <ng-container *ngTemplateOutlet="itemTemplate" />
  </a>
}
@if (
  (disableParentClick && item.items?.length) ||
  (disableLinklessClick && !item.action && !item.link && !item.href) ||
  item.isHeading
) {
  <a
    #menuItem
    class="dropdown-item-no-link"
    [class.dropdown-header]="item.isHeading"
    [ngClass]="itemClass"
    [class.focus-inside]="focusStylingInside"
    [class.active]="forceActive || active"
    [class.disabled]="item.disabled"
    [class.dropdown-item-static]="
      (!item.items || item.items.length === 0) &&
      ((disableLinklessClick && !item.action && !item.link && !item.href) || item.isHeading)
    "
    [class.dropdown-item-icon-only]="
      iconOnly ||
      (!item.title && item.icon && !item.selectionState && !dropdownCaret && !item.items?.length)
    "
    [attr.tabindex]="disableListInteraction ? '' : '-1'"
    [attr.aria-label]="item.title | translate"
    [attr.aria-disabled]="item.disabled"
    [attr.aria-checked]="getAriaChecked(item)"
    [attr.data-id]="item.id"
    [attr.role]="getItemRole(item)"
  >
    <ng-container *ngTemplateOutlet="itemTemplate" />
  </a>
}

<ng-template #itemTemplate>
  @if (item.icon) {
    <span class="item-icon icon" [ngClass]="iconClass!" [class.badge-dot]="item.badgeDot"></span>
  }
  @if (
    (theBadgeStyle === 'dot' || (!theBadgeStyle && (iconOnly || (!item.title && item.icon)))) &&
    item.badge &&
    item.icon
  ) {
    <span class="badge-text">{{ badgeText }}</span>
  }
  @if (
    !iconOnly &&
    (item.title || !item.icon || item.selectionState || dropdownCaret || item.items?.length)
  ) {
    <span class="item-title text-truncate">{{ item.title | translate }}</span>
  }
  @if (
    (theBadgeStyle === 'inline' || (!theBadgeStyle && !iconOnly && (item.title || !item.icon))) &&
    item.badge
  ) {
    <span class="badge me-n2" [ngClass]="'bg-' + (item.badgeColor || 'default')">{{
      item.badge
    }}</span>
  }
  @if (!iconOnly && item.selectionState && !dropdownCaret && !item.items?.length) {
    <span
      class="icon menu-end-icon ms-2"
      [class.element-ok]="item.selectionState === 'check'"
      [class.element-record-filled]="item.selectionState === 'radio'"
    ></span>
  }
  @if (!iconOnly && (item.items?.length || dropdownCaret) && dropdownCaret !== 'none') {
    <span
      class="icon menu-end-icon element-down-2 dropdown-caret-animation flip-rtl ms-2"
      [class.dropdown-caret-end]="dropdownCaret === 'end'"
      [class.dropdown-caret-start]="dropdownCaret === 'start'"
      [class.dropdown-caret-up]="dropdownCaret === 'up'"
    ></span>
  }
</ng-template>
