import { DateFormat, TimeFormat } from '../../../interfaces/date-time-formats';
import { TValueType } from './types';

export class Options {
  static readonly none = 0x00;
  static readonly optional = 0x01;
  static readonly special = 0x02;
  static readonly wildcard = 0x04;
  static readonly readonly = 0x08;
  static readonly allOptional = 0x10;

  dateFormat!: DateFormat;
  timeFormat!: TimeFormat;
  type!: TValueType;

  constructor(private value = Options.none) {}

  get allOptional(): boolean {
    return this.hasOption(Options.allOptional);
  }

  get isOptional(): boolean {
    return this.hasOption(Options.optional);
  }

  get isReadonly(): boolean {
    return this.hasOption(Options.readonly);
  }

  get isSpecial(): boolean {
    return this.hasOption(Options.special);
  }

  get isWildcarded(): boolean {
    return this.hasOption(Options.wildcard);
  }

  clearOption(flag: number): void {
    // eslint-disable-next-line no-bitwise
    this.value &= ~flag;
  }

  hasOption(flag: number): boolean {
    // eslint-disable-next-line no-bitwise
    return (this.value & flag) > 0;
  }

  setOption(flag: number): void {
    // eslint-disable-next-line no-bitwise
    this.value |= flag;
  }

  toggleAllOptional(): void {
    if (this.allOptional) {
      this.clearOption(Options.allOptional);
    } else {
      this.setOption(Options.allOptional);
    }
  }

  toggleOptional(): void {
    if (this.isOptional) {
      this.clearOption(Options.optional);
    } else {
      this.setOption(Options.optional);
    }
  }

  toggleReadonly(): void {
    if (this.isReadonly) {
      this.clearOption(Options.readonly);
    } else {
      this.setOption(Options.readonly);
    }
  }

  toggleSpecial(): void {
    if (this.isSpecial) {
      this.clearOption(Options.special);
    } else {
      this.setOption(Options.special);
    }
  }

  toggleWildcard(): void {
    if (this.isWildcarded) {
      this.clearOption(Options.wildcard);
    } else {
      this.setOption(Options.wildcard);
    }
  }
}
