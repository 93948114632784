/**
 * Represents the credentials data.
 */
export class Credentials {

  /**
   * Stores the username.
   */
  public username!: string;

  /**
   * Stores the password.
   */
  public password!: string;
}
export interface OpenIdUriRepresentation {
  /**
   * OpenIdUri Login
   */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  Login: string;
  /**
   * OpenIdUri Logout
   */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  Logout: string;
}

/**
 * Represents the value retrieved by api/users/logininfo/{username}.
 */

export enum UserAccountType {
  // 0 = Desigo CC account
  DesigoCC,
  // 1 = Windows account
  Windows,
  // 2 = Software account
  Software,
  // 3 = OpenID account
  OpenID
}

export interface UserInfo {
/* eslint-disable-next-line @typescript-eslint/naming-convention */
  UserName: string;
  // Account type of user.
  // 0 = Desigo CC account
  // 1 = Windows account
  // 2 = Software account
  // 3 = OpenID account
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  AccountType: UserAccountType;
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  OpenIdLoginUri: string;
}

export enum UserInfoStorage {
  ClientIdKey = 'gms-client-id',
  UserTokenKey = 'gms-user-token',
  UserNameKey = 'gms-user-name',
  UserProfileKey = 'gms-user-profile',
  UserDescriptorKey = 'gms-user-descriptor',
  UserInactivityKey = 'gms-user-inactivity',
  OpenIdLoginUriKey = 'gms-openId-loginUri',
  OpenIdLogoutUriKey = 'gms-openId-logoutUri',
  OpenIdRedirectUrlKey = 'gms-openId-redirectUrl',
  PersonallyUsed = 'personally_used',
  UserIsDefaultUserKey = 'gms-is-default-user-profile'
}
