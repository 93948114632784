import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { GmsLine } from '../elements/gms-line';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-line]',
  template: `<svg:g (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                    (mouseout)="OnMouseLeave($event)"
                    id="{{element.Id}}"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                    [attr.filter]="element.Filter?.Url"
                    [attr.transform]="element.GetTransformations()"
                    [attr.visibility]="element.GetVisible()"
                    [attr.opacity]="element.Opacity" [attr.clip-path]="element.ClipPathUrl">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <svg:rect [ngClass]="'noptrevents'"
                              *ngIf="element.Background !== 'transparent'"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg:path
                            [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                            [attr.transform]="element.ResizeScaleTransformation()"
                            [attr.d]="element.Data"
                            [attr.stroke]="element.Stroke"
                            [attr.stroke-opacity]="element.StrokeOpacity"
                            [attr.stroke-dasharray]="element.StrokeDashArray"
                            [attr.stroke-width]="element.StrokeWidth"
                            [attr.stroke-linecap]="element.StrokeLineCap"
                            fill-rule="evenodd" />
                    <g *ngIf="element.HasArrows" fill-rule="evenodd" stroke-opacity="0" stroke-linejoin="round"
                        [attr.fill]="element.Stroke" fill-opacity="element.StrokeOpacity">
                        <svg:path
                            *ngIf="element.StartArrow !== undefined"
                            [attr.d]="element.StartArrow" />
                        <svg:path
                            *ngIf="element.EndArrow !== undefined"
                            [attr.d]="element.EndArrow" />
                    </g>
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
                    <!--<title *ngIf="element.Tooltip !== undefined">{{element.Tooltip}}</title>-->
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsLineComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsLine = null;
}
