import { Subject } from 'rxjs';

import { AlarmInfo } from '../processor/datapoint/gms-alarm-info';
import { AlarmState } from '../types/datapoint/gms-alarm-state';
import { GmsElement } from './gms-element';

export class GmsAlarm {
  public alarmChanged: Subject<string> = new Subject<string>();

  private _sourceElement: GmsElement = null;
  public get SourceElement(): GmsElement {
    return this._sourceElement;
  }
  public set SourceElement(value: GmsElement) {
    if (this._sourceElement !== value) {
      this._sourceElement = value;
    }
  }

  private _id: string;
  public get Id(): string {
    return this._id;
  }
  public set Id(value: string) {

    if (this._id !== value) {
      this._id = value;
    }
  }

  // Object Id used for matching events/alarms
  private _objectIdSet: Set<string> = undefined;
  public get ObjectIdSet(): Set<string> {
    return this._objectIdSet;
  }
  public set ObjectIdSet(value: Set<string>) {
    if (this._objectIdSet !== value) {
      this._objectIdSet = value;
    }
  }

  private _designation: string;
  public get Designation(): string {
    return this._designation;
  }
  public set Designation(value: string) {

    if (this._designation !== value) {
      this._designation = value;
    }
  }

  // Represents the cumulative alarm(s) state for the element.
  private _state: AlarmState = AlarmState.None;
  public get State(): AlarmState {
    return this._state;
  }
  public set State(value: AlarmState) {

    if (this._state !== value) {
      this._state = value;
    }
  }

  private _isActive = false;
  public get IsActive(): boolean {
    return this._isActive;
  }
  public set IsActive(value: boolean) {

    if (this._isActive !== value) {
      this._isActive = value;
    }
  }

  private _disciplineColor = 'rgb(255, 255, 255)';
  public get DisciplineColor(): string {
    return this._disciplineColor;
  }

  private readonly alarmInfos: Map<number, AlarmInfo> = new Map<number, AlarmInfo>();
  public get AlarmInfos(): AlarmInfo[] {
    return Array.from(this.alarmInfos.values());
  }

  public getDesignations(): string[] {
    const srcDesignationSet: Set<string> = new Set<string>();
    const alarmInfoList: AlarmInfo[] = this.AlarmInfos;
    if (alarmInfoList !== undefined) {
      for (const alarmInfo of alarmInfoList) {
        srcDesignationSet.add(alarmInfo.SrcDesignationEvents);
      }
    }

    const srcDesignations: string[] = Array.from(srcDesignationSet);
    return srcDesignations;
  }

  constructor(sourceElement: GmsElement, alarmInfos: AlarmInfo[]) {
    this.Id = sourceElement.Id;
    this.SourceElement = sourceElement;
    alarmInfos.forEach(alarmInfo => this.alarmInfos.set(alarmInfo.Id, alarmInfo));
    this.SetState();
  }

  public UpdateAlarmInfos(alarmInfos: AlarmInfo[]): void {
    alarmInfos.forEach(alarmInfo => {

      if ((alarmInfo.State > AlarmState.ReadyToBeReset || alarmInfo.State < AlarmState.Unprocessed) && this.alarmInfos.has(alarmInfo.Id)) {
        this.alarmInfos.delete(alarmInfo.Id);
      } else {
        this.alarmInfos.set(alarmInfo.Id, alarmInfo);
      }
    });

    this.SetState();
  }

  public SetState(): void {
    const currentAlarmInfos: AlarmInfo[] = this.AlarmInfos;

    // Active & Unprocessed
    let currentAlarmInfo: AlarmInfo = currentAlarmInfos
      .find(alarmInfo => alarmInfo.IsActive && alarmInfo.State === AlarmState.Unprocessed);

    // In-Active & Unprocessed
    if (currentAlarmInfo === undefined) {
      currentAlarmInfo = currentAlarmInfos
        .find(alarmInfo => alarmInfo.State === AlarmState.Unprocessed);

      // Active & Acked
      if (currentAlarmInfo === undefined) {
        currentAlarmInfo = currentAlarmInfos
          .find(alarmInfo => alarmInfo.IsActive && alarmInfo.State === AlarmState.Acked);

        // In-Active & Acked
        if (currentAlarmInfo === undefined) {
          currentAlarmInfo = currentAlarmInfos
            .find(alarmInfo => alarmInfo.State === AlarmState.Acked);

          // In-Active & ReadyToBeReset
          if (currentAlarmInfo === undefined) {
            currentAlarmInfo = currentAlarmInfos
              .find(alarmInfo => alarmInfo.State === AlarmState.ReadyToBeReset);
          }
        }
      }
    }

    if (currentAlarmInfo !== undefined) {
      if (this._isActive !== currentAlarmInfo.IsActive || this._state !== currentAlarmInfo.State) {
        this._isActive = currentAlarmInfo.IsActive;
        this._state = currentAlarmInfo.State;
        this._disciplineColor = currentAlarmInfo.DisciplineColor;
        this.NotifyAlarmChanged('AlarmsChanged');
      }
    } else {
      this._state = AlarmState.None;
      this.NotifyAlarmChanged('AlarmsChanged');
    }
  }

  public Destroy(): void {
    if (this.alarmChanged !== undefined) {
      this.alarmChanged.unsubscribe();
    }

    this._state = AlarmState.None;
    this._sourceElement = undefined;
    this.alarmInfos.clear();
  }

  private NotifyAlarmChanged(propertyName: string = ''): void {
    if (this.alarmChanged.observers !== null && this.alarmChanged.observers.length > 0) {
      this.alarmChanged.next(propertyName);
    }
  }
}
