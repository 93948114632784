import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem, SiInlineNotificationComponent, SiTranslateModule } from '@simpl/element-ng';
import { PriorityArrayItem, ValueBase } from '@simpl/element-value-types';
import { clone } from '@simpl/object-browser-ng/common';
import { AnyProperty, CommandEvent, DisplayMode } from '@simpl/object-browser-ng/property-viewer';

import {
  getPropertyId,
  NotificationError,
  setPropertyId,
  ValidationError
} from '../../helpers/default-models';
import {
  enableDisableProperty,
  getCommandValue,
  getExecutionSettingValues,
  getSelectedAction,
  updateCollectionPropertyValueInDataPointProperties,
  updateEnableDisableContentAction,
  updatePrioArrayInUI,
  updatePropertyValue
} from '../../helpers/scene-property-command-helper';
import { DataPoint, Scene, SceneCommand } from '../../interfaces/scene-editor';
import { SiSceneEditorConfigService, SiSceneEditorService } from '../../services/index';
import {
  PropertyEvent,
  SiConfigureDatapointComponent
} from '../si-configure-datapoint/si-configure-datapoint.component';

export interface ConfigureDataPointDialogResult {
  result: 'ok' | 'cancel';
  data?: {
    isDeleted?: boolean;
    selectedDataPoint?: DataPoint;
    selectedProperty?: AnyProperty;
    sceneCommands?: SceneCommand[];
  };
}

@Component({
  selector: 'si-configure-model',
  templateUrl: './si-configure-model.component.html',
  styleUrls: ['./si-configure-model.component.scss'],
  standalone: true,
  imports: [SiConfigureDatapointComponent, SiInlineNotificationComponent, SiTranslateModule]
})
export class SiConfigureModelComponent implements OnInit {
  @Input() objectId!: string;
  @Input() selectedDataPoint!: DataPoint;
  @Input() itemsList: SceneCommand[] = [];
  @Input() sceneList!: Scene[];
  @Input() displayMode!: DisplayMode;
  @Input() modalTitleId!: string;

  @Output() readonly closeDialog = new EventEmitter<ConfigureDataPointDialogResult>();
  @ViewChild('configureDatapoint') configureDatapoint!: SiConfigureDatapointComponent;

  result!: ConfigureDataPointDialogResult;
  isDeleted = false;
  isPropertyPopOpen = false;
  isPrioDefaultCommandUpdated = false;
  propertyList: AnyProperty[] = [];
  dataPointSelectedProperty?: AnyProperty;
  updatedSceneCommands: SceneCommand[] = [];

  selectedDataPointCopy!: DataPoint;
  inlineNotificationError?: NotificationError;
  isDuplicateDataPoint?: boolean;

  readonly propertyContentActions: MenuItem[] = [
    {
      id: 'enable-disable',
      title: '',
      action: property => this.enableDisableDataPoint(property)
    }
  ];

  private sceneProperty = new Map<string, [string, AnyProperty]>();
  private changedCommand = new Map<string, [string, AnyProperty]>();

  private siSceneService = inject(SiSceneEditorService);
  private sceneConfigService = inject(SiSceneEditorConfigService);

  ngOnInit(): void {
    this.setContentActions();
    this.isDeleted = false;
    this.selectedDataPointCopy = clone(this.selectedDataPoint);
    this.addExecuteSettingAction();
    this.setProperties();
  }

  setContentActions(): void {
    if (!this.sceneConfigService.get().actionToDisable.advanced) {
      this.propertyContentActions.push(
        { title: '-' },
        {
          id: 'advanced-commands',
          title: 'SCENE_EDITOR.TEXT_SHORT.ADVANCED_COMMANDS',
          disabled: true
        }
      );
    }
  }

  setProperties(property?: AnyProperty, isCommandChange?: boolean): void {
    this.propertyList = [];
    if (this.itemsList?.length) {
      this.itemsList.forEach(item => {
        const scene = this.sceneList.find(element => element.id === item.sceneId);
        if (scene) {
          let defaultSelectedProperty: AnyProperty;
          if (!property) {
            property = this.selectedDataPointCopy.properties?.find(
              prop => prop.id === this.selectedDataPointCopy.selectedPropertyId
            );
          }
          if (property) {
            this.sceneProperty.set(scene.id, clone([this.selectedDataPointCopy.id, property]));
            defaultSelectedProperty = clone(
              this.updateProperty(scene, item, clone(property), isCommandChange)
            );
          } else {
            defaultSelectedProperty = this.getInValidProperty(scene, item);
          }
          this.propertyList.push(defaultSelectedProperty);
        }
      });
    }
  }

  commandSelected(event: CommandEvent): void {
    const property = event.property as AnyProperty;
    const command = event.command;
    const sceneCommand = this.getSceneCommand(property);

    const scene = this.sceneList.find(item =>
      item.id === property.id ? getPropertyId(property.id) : property.id
    );

    if (sceneCommand && scene) {
      if (command === 'execution-settings') {
        this.updateExecutionSettingValues(event, sceneCommand, property, scene);
      } else {
        this.updateSceneCommandValue(sceneCommand, command, property, event, scene);
      }
    }
  }

  private addExecuteSettingAction(): void {
    if (this.sceneConfigService.get().actionToDisable.executeSettings) {
      this.propertyContentActions.splice(
        this.propertyContentActions.findIndex(w => w.id === 'enable-disable') + 1,
        0,
        {
          id: 'execution-settings',
          title: 'SCENE_EDITOR.TEXT_SHORT.EXECUTION_SETTINGS'
        }
      );
    }
  }

  private updateSceneCommandValue(
    sceneCommand: SceneCommand,
    command: string,
    property: AnyProperty,
    event: CommandEvent,
    scene: Scene
  ): void {
    if (sceneCommand.selectedCommand === command) {
      sceneCommand.value = getCommandValue(property, event);
      this.updateProperty(scene, sceneCommand, property, false);
      this.updateForSingle(property);
    } else {
      sceneCommand.selectedCommand = command;
      sceneCommand.value = undefined;
      this.updateProperty(scene, sceneCommand, property, true);
      this.updateForSingle(property, sceneCommand.isEnabled, event);
      if (!this.isPrioDefaultCommandUpdated) {
        sceneCommand.value = getCommandValue(property, event);
      }
    }
    this.changedCommand.set(sceneCommand.sceneId, [this.selectedDataPointCopy.id, clone(property)]);
  }

  private updateExecutionSettingValues(
    event: CommandEvent,
    sceneCommand: SceneCommand,
    property: AnyProperty,
    scene: Scene
  ): void {
    getExecutionSettingValues(event, sceneCommand);
    this.updateProperty(scene, sceneCommand, property, false);
    this.updateForSingle(property);
  }

  private getInValidProperty(scene: Scene, sceneCommand: SceneCommand): AnyProperty {
    const errorProperty: AnyProperty = {
      id: scene.id,
      name: scene.name,
      value: {
        type: 'string',
        value: sceneCommand.value,
        readonly: true
      }
    };
    return errorProperty;
  }

  private updateProperty(
    scene: Scene,
    command: SceneCommand,
    property: AnyProperty,
    isCommandChange?: boolean
  ): AnyProperty {
    if (property.id) {
      property.id = setPropertyId(scene.id, property.id);
    }
    property.name = scene.name;
    updatePropertyValue(
      this.selectedDataPointCopy,
      command,
      property,
      this.propertyContentActions,
      command.selectedCommand,
      isCommandChange
    );
    updateEnableDisableContentAction(property, command);
    return property;
  }

  private enableDisableDataPoint(property: AnyProperty): void {
    const sceneCommand = this.getSceneCommand(property);
    if (sceneCommand) {
      // else condition value can be undefined
      const id = property?.id ? getPropertyId(property.id) : property?.id;
      if (id && !this.changedCommand?.has(id) && sceneCommand.isEnabled) {
        // not changed command && if it commanded for disabling
        // here sceneCommand.isEnabled is always true
        this.changedCommand.set(sceneCommand.sceneId, [
          this.selectedDataPointCopy.id,
          clone(property)
        ]);
      }

      enableDisableProperty(
        property,
        this.selectedDataPointCopy.id,
        this.sceneProperty,
        this.changedCommand,
        sceneCommand
      );
      updateEnableDisableContentAction(property, sceneCommand);
      this.updateForSingle(property, sceneCommand.isEnabled);
    }
  }

  private updateForSingle(
    property: AnyProperty,
    isEnabled: boolean = true,
    event?: CommandEvent
  ): void {
    if (property?.id) {
      const sceneId = getPropertyId(property.id);
      const sceneCommand = this.itemsList.find(
        command =>
          command.sceneId === sceneId && command.dataPointId === this.selectedDataPointCopy.id
      );
      if (sceneCommand) {
        if (isEnabled) {
          sceneCommand.value = getCommandValue(property);
          this.updateValueInPriorityProperty(sceneCommand, property, event);
        }
        sceneCommand.isEnabled = isEnabled;
        this.updatedSceneCommands.push(sceneCommand);
      }
    }
  }

  private updateValueInPriorityProperty(
    sceneCommand: SceneCommand,
    property: AnyProperty,
    event?: CommandEvent
  ): void {
    if (property.value.type === 'collection' && property.value.kind === 'priority-array') {
      const advanceAction = property.actions?.find(action => action.id === 'advanced-commands');
      const inActiveCommand = advanceAction?.items?.find(item => !item.isActive);
      if (sceneCommand.selectedCommand !== inActiveCommand?.id) {
        const prop = this.propertyList.find(p => p.id === property.id);
        if (event) {
          if (prop) {
            const cloned = clone(prop);
            const prioItems = cloned.value.value as PriorityArrayItem<ValueBase>[];
            prioItems.forEach(item => {
              item.value.value = undefined;
            });
            cloned.value.value = prioItems;
            this.selectedDataPointCopy = updateCollectionPropertyValueInDataPointProperties(
              clone(this.selectedDataPointCopy),
              cloned
            );
            sceneCommand.value = [prioItems[0]];
            this.isPrioDefaultCommandUpdated = true;
          }
        } else {
          this.selectedDataPointCopy = updateCollectionPropertyValueInDataPointProperties(
            clone(this.selectedDataPointCopy),
            property
          );
        }
        if (prop) {
          updatePrioArrayInUI(this.selectedDataPointCopy, prop);
        }
      }
    }
  }

  private updateForMultiple(property: AnyProperty): void {
    if (property?.id) {
      this.itemsList.map(command => {
        const sceneCommand = this.updateCommandForProperty(command, property);
        this.updatedSceneCommands.push(sceneCommand);
      });
    }
  }

  private updateCommandForProperty(command: SceneCommand, property: AnyProperty): SceneCommand {
    const sceneCommand = command;
    if (property.value.type === 'collection') {
      if (property.value.value?.length) {
        sceneCommand.value = [property.value.value[0]];
      }
    } else {
      sceneCommand.value = getCommandValue(property);
    }
    sceneCommand.selectedCommand = getSelectedAction(property)?.id;
    return sceneCommand;
  }

  getSceneCommand(property: AnyProperty): SceneCommand | undefined {
    if (property?.id) {
      const sceneId = getPropertyId(property.id);
      return this.itemsList.find(command => command.sceneId === sceneId);
    }
    return undefined;
  }

  confirmDelete(event: any): void {
    this.isDeleted = event.isDeleted;
  }

  confirm(): void {
    this.result = {
      result: 'ok',
      data: {
        isDeleted: this.isDeleted,
        selectedDataPoint: this.selectedDataPointCopy,
        selectedProperty: this.dataPointSelectedProperty,
        sceneCommands: this.updatedSceneCommands
      }
    };
    this.closeDialog.emit(this.result);
  }

  cancel(): void {
    this.updatedSceneCommands = [];
    this.result = {
      result: 'cancel'
    };
    this.closeDialog.emit(this.result);
  }

  propertyChanged(event: PropertyEvent): void {
    if (event?.data) {
      const property = event.data as AnyProperty;
      if (event.change === 'CommandValue') {
        this.updateForSingle(property);
      } else if (event.change === 'SelectedProperty') {
        this.dataPointSelectedProperty = property;
        if (property?.id) {
          this.selectedDataPointCopy.selectedPropertyId = property?.id;
        }
        this.updateForMultiple(property);
        this.setProperties(property, true);
      } else if (event.change === 'UserDefinedName') {
        this.selectedDataPointCopy.userDefinedName = event.data as string;
      }
      this.validateDataPoints();
    }
  }

  validateDataPoints(): void {
    let duplicationError: ValidationError | undefined;
    const sameDataPoints = this.siSceneService.getSameDataPoints(
      this.objectId,
      this.selectedDataPoint?.id
    );
    if (sameDataPoints?.length) {
      const datapointWithSameProp = sameDataPoints.filter(
        item =>
          item.selectedPropertyId === this.selectedDataPointCopy.selectedPropertyId &&
          item.id !== this.selectedDataPointCopy.id
      );
      if (datapointWithSameProp?.length && !duplicationError) {
        duplicationError = {
          errorReason: 'DuplicateDataPoint',
          errorObject: this.selectedDataPointCopy
        };
      }
    }
    this.updateInlineNotification(duplicationError);
  }

  updateInlineNotification(error?: ValidationError): void {
    if (error) {
      const errorDataPoint = error.errorObject as DataPoint;
      this.inlineNotificationError = {
        severity: 'warning',
        message: 'SCENE_EDITOR.MESSAGES.DUPLICATE_DATA_POINT_PROPERTY',
        action: {
          title: 'SCENE_EDITOR.DIALOG_TEXT_SHORT.SELECT_ANOTHER_PROPERTY',
          action: (param: ValidationError) => this.correctiveAction(error)
        },
        errorParams: {
          propertyId: errorDataPoint?.selectedPropertyId,
          datapointId: errorDataPoint?.name
        }
      };
    } else {
      this.inlineNotificationError = undefined;
    }
  }

  correctiveAction(element: ValidationError): any {
    this.isDuplicateDataPoint = true;
  }
}
