/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BlinkService, STATUS_ICON, TextMeasureService } from '@simpl/element-ng/common';
import {
  ResizeObserverService,
  SiResizeObserverDirective
} from '@simpl/element-ng/resize-observer';
import { SiTranslateModule, SiTranslateService } from '@simpl/element-ng/translate';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { SiStatusBarItemComponent } from './si-status-bar-item/si-status-bar-item.component';
import { StatusBarItem } from './si-status-bar-item/si-status-bar-item.model';

interface ExtendedStatusBarItem extends StatusBarItem {
  isSpecial?: boolean;
  mutePadding?: boolean;
}

// this is a function because Angular compiler exports arrows for no good reason
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function itemSortFunction(a: StatusBarItem, b: StatusBarItem): number {
  return a.status && b.status ? STATUS_ICON[a.status].severity - STATUS_ICON[b.status].severity : 0;
}

let idCounter = 1;

/**
 * The status bar is the main component within an application to inform users at all times
 * about important status information.
 */
@Component({
  selector: 'si-status-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './si-status-bar.component.html',
  styleUrl: './si-status-bar.component.scss',
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SiStatusBarItemComponent,
    SiResizeObserverDirective,
    SiTranslateModule
  ]
})
export class SiStatusBarComponent implements DoCheck, OnDestroy, OnChanges {
  private static readonly itemMinWidth = 100;
  private static readonly itemMaxWidth = 152;
  private static readonly itemSpacing = 4;
  private static readonly itemPaddingX = 44; // padding + icon size + icon margin
  private static readonly itemPaddingXdeprecated = 20; // padding + color bar
  private static readonly muteButtonWidth = 48;

  /**
   * Array of status bar items.
   */
  @Input({ required: true }) items!: StatusBarItem[];
  /**
   * When true, items with a value have a blinking background
   */
  @Input({ transform: booleanAttribute }) blink = false;
  /**
   * When true, shows a mute button reflecting the state of the `muted` input
   */
  @Input({ transform: booleanAttribute }) muteButton?: boolean;
  /**
   * Text/translation key on mute button for screen reader
   */
  @Input() muteButtonText = $localize`:@@SI_STATUS_BAR.MUTE:Mute/unmute`;
  /**
   * Text/translation key for "All OK" status in mobile
   */
  @Input() allOkText = $localize`:@@SI_STATUS_BAR.ALL_OK:All OK`;
  /**
   * compact mode
   */
  @Input({ transform: booleanAttribute }) compact = false;
  /**
   * blink pulse generator for synchronized blinking with other components
   */
  @Input() blinkPulse?: Observable<boolean>;
  /**
   * Text for the navbar expand button. Required for a11y
   */
  @Input() expandButtonText = $localize`:@@SI_STATUS_BAR.EXPAND:Expand`;
  /**
   * Text for the navbar collapse button. Required for a11y
   */
  @Input() collapseButtonText = $localize`:@@SI_STATUS_BAR.COLLAPSE:Collapse`;

  /**
   * Emitted when the mute toggle button is clicked
   */
  @Output() readonly muteToggle = new EventEmitter();

  @ViewChild('thebar', { static: true }) private theBar!: ElementRef;
  @ViewChild('content', { static: true }) private content!: ElementRef;
  @ViewChild('custom', { static: true }) private custom!: ElementRef;

  protected responsiveItems: ExtendedStatusBarItem[] = [];
  protected responsiveMode = 0;
  protected expanded = 0;
  protected placeholderHeight = 0;
  protected contentHeight: number | undefined;
  protected blinkOnOff?: boolean;
  protected statusId = `__si-status-bar-${idCounter++}`;

  private timer: any;
  private resizeSubs: Subscription;
  private blinkSubs?: Subscription;

  private element = inject(ElementRef);
  private blinkService = inject(BlinkService);
  private translateService = inject(SiTranslateService);
  private resizeObserver = inject(ResizeObserverService);
  private cdRef = inject(ChangeDetectorRef);
  private measureService = inject(TextMeasureService);

  constructor() {
    this.resizeSubs = this.resizeObserver
      .observe(this.element.nativeElement, 100, true)
      .subscribe(() => this.resizeHandler());
    this.resizeSubs.add(
      this.translateService.translationChange.subscribe(() => this.resizeHandler())
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.blinkService && changes.blink) {
      this.blinkSubs?.unsubscribe();
      if (this.blink) {
        const pulse = this.blinkPulse ?? this.blinkService.pulse$;
        this.blinkSubs = pulse.subscribe(onOff => {
          this.blinkOnOff = onOff;
          this.cdRef.markForCheck();
        });
      }
    }
    this.resizeHandler();
  }

  ngDoCheck(): void {
    if (this.responsiveMode) {
      this.calcResponsiveItems();
    }
  }

  ngOnDestroy(): void {
    this.resizeSubs.unsubscribe();
    this.blinkSubs?.unsubscribe();
  }

  protected onItemClicked(item: StatusBarItem): void {
    if (item.action) {
      item.action(item);
    }
  }

  protected toggleExpand(): void {
    clearTimeout(this.timer);
    if (!this.expanded) {
      this.expanded = 2;
      this.placeholderHeight = this.theBar.nativeElement.offsetHeight;
      this.contentHeight = 0;
      this.cdRef.markForCheck();

      this.timer = window.setTimeout(() => {
        this.contentHeight = this.content.nativeElement.scrollHeight;
        this.cdRef.markForCheck();
        window.setTimeout(() => {
          this.contentHeight = undefined;
          this.cdRef.markForCheck();
        }, 500);
      }, 10);
    } else {
      this.contentHeight = this.content.nativeElement.scrollHeight;
      this.expanded = 1;
      this.cdRef.markForCheck();

      window.setTimeout(() => {
        this.contentHeight = 0;
        this.cdRef.markForCheck();
        window.setTimeout(() => {
          this.placeholderHeight = 0;
          this.expanded = 0;
          this.cdRef.markForCheck();
        }, 500);
      }, 10);
    }
  }

  protected resizeHandler(): void {
    const size = this.element.nativeElement.clientWidth;
    const muteWidth = this.muteButton !== undefined ? SiStatusBarComponent.muteButtonWidth : 0;
    const customWidth = this.custom.nativeElement.scrollWidth ?? 0;
    const minWidth =
      this.items.length * (SiStatusBarComponent.itemMinWidth + SiStatusBarComponent.itemSpacing) +
      SiStatusBarComponent.itemSpacing +
      muteWidth +
      customWidth;
    if (size < minWidth) {
      this.setResponsiveMode(true);
    } else if (this.items.length) {
      this.calculateRequiredWidth(muteWidth, customWidth);
    }
  }

  private setResponsiveMode(responsive: boolean): void {
    if (responsive) {
      const size = this.element.nativeElement.clientWidth;
      this.responsiveMode = Math.max(Math.floor(size / SiStatusBarComponent.itemMaxWidth) - 1, 2);
    } else {
      this.responsiveMode = 0;
    }

    if (this.responsiveMode) {
      this.contentHeight = this.expanded ? this.content.nativeElement.scrollHeight : 0;
    } else {
      this.expanded = 0;
      this.placeholderHeight = 0;
      this.contentHeight = undefined;
    }
    this.cdRef.markForCheck();
  }

  private calcResponsiveItems(): void {
    const activeItems: ExtendedStatusBarItem[] = this.items
      .filter(item => item.value)
      .sort(itemSortFunction);

    if (activeItems.length > this.responsiveMode) {
      activeItems[this.responsiveMode - 1] = {
        status: activeItems[this.responsiveMode - 1].status,
        color: activeItems[this.responsiveMode - 1].color,
        value: activeItems.length - this.responsiveMode + 1 + '+',
        title: '',
        action: () => this.toggleExpand(),
        isSpecial: true
      };
      activeItems.length = this.responsiveMode;
    } else if (!activeItems.length) {
      activeItems.push({
        status: 'success',
        title: '',
        value: this.allOkText,
        isSpecial: true
      });
    }
    if (activeItems.length === this.responsiveMode) {
      activeItems[activeItems.length - 1].mutePadding = true;
    }
    this.responsiveItems = activeItems;
    this.cdRef.markForCheck();
  }

  private calculateRequiredWidth(muteWidth: number, customWidth: number): void {
    const keys = this.items.map(item => item.title);
    this.translateService
      .translateAsync(keys)
      .pipe(first())
      .subscribe(translations => {
        const size = this.element.nativeElement.clientWidth;

        const requiredWidth = this.items.reduce(
          (acc, item) => {
            const textWidth = this.measureService.measureText(translations[item.title]);
            const itemWidth =
              Math.max(
                SiStatusBarComponent.itemMinWidth,
                textWidth +
                  (item.color
                    ? SiStatusBarComponent.itemPaddingXdeprecated
                    : SiStatusBarComponent.itemPaddingX)
              ) + SiStatusBarComponent.itemSpacing;
            return acc + itemWidth;
          },
          muteWidth + customWidth + SiStatusBarComponent.itemSpacing
        );

        this.setResponsiveMode(size < requiredWidth);
      });
  }
}
