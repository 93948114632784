<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="form.invalid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    <div
      class="form-control d-flex align-items-center"
      [attr.readonly]="readonly"
      [attr.tabindex]="readonly ? '' : '0'"
      (keydown.enter)="popover.open(true)"
      input
    >
      @if (!property.defaultText) {
        @if (
          property.value.value?.height?.value !== undefined || property.value.value?.height?.altText
        ) {
          <i class="icon element-height"></i>
        }
        <span>
          @if (property.value.value?.height?.value !== undefined) {
            {{ popover.modelValue?.height?.value }}{{ property.value.value?.height?.unit }}
          } @else {
            {{ property.value.value?.height?.altText }}
          }
        </span>
        @if (
          property.value.value?.angle?.value !== undefined || property.value.value?.angle?.altText
        ) {
          <i class="ml-4 ms-4 icon element-angle"></i>
        }
        <span>
          @if (property.value.value?.angle?.value !== undefined) {
            {{ popover.modelValue?.angle?.value }}{{ property.value.value?.angle?.unit }}
          } @else {
            {{ property.value.value?.angle?.altText }}
          }
        </span>
      } @else {
        <span>{{ property.defaultText }}</span>
      }
      <ng-template #defaultText>
        <span>{{ property.defaultText }}</span>
      </ng-template>
    </div>
    <div body>
      <div class="row pb-7 align-items-end">
        <si-slider
          class="col-lg-8"
          [min]="property.value.value?.height?.min || 0"
          [max]="property.value.value?.height?.max || 100"
          [step]="property.value.value?.height?.resolution || 1"
          [(value)]="popover.modelValue.height.value"
        />
        <div class="col-lg-4">
          <label class="mt-2 text-truncate d-block text-right text-end" [for]="heightInputId">
            {{ property.value.value?.height?.min || 0 }}..{{
              property.value.value?.height?.max || 100
            }}
            {{ property.value.value?.height?.unit || '%' }}
          </label>
          <div class="text-right text-end d-flex align-items-end">
            <i class="icon element-height mb-2 mr-4 me-4"></i>
            <input
              #heightInput
              [id]="heightInputId"
              name="height"
              type="number"
              autocomplete="off"
              class="form-control text-right text-end"
              [(ngModel)]="popover.modelValue.height.value"
              [readonly]="readonly"
              [min]="property.value.value?.height?.min || 0"
              [max]="property.value.value?.height?.max || 100"
              [step]="property.value.value?.height?.resolution || 1"
              [required]="!property.value.value?.height?.optional"
              [siNumberValidator]="property.value.value?.height"
              [allowValuesOutOfRange]="allowValuesOutOfRange"
              (keydown.enter)="angleInput.focus()"
            />
          </div>
        </div>
      </div>
      <div class="row pb-7 align-items-end">
        <si-slider
          class="col-lg-8"
          [min]="property.value.value?.angle?.min || 0"
          [max]="property.value.value?.angle?.max || 100"
          [step]="property.value.value?.angle?.resolution || 1"
          [(value)]="popover.modelValue.angle.value"
        />
        <div class="col-lg-4">
          <label class="mt-2 text-truncate d-block text-right text-end" [for]="angleInputId">
            {{ property.value.value?.height?.min || 0 }}..{{
              property.value.value?.height?.max || 100
            }}
            {{ property.value.value?.height?.unit || '%' }}
          </label>
          <div class="text-right text-end d-flex align-items-end">
            <i class="icon element-angle mb-2 mr-4 me-4"></i>
            <input
              #angleInput
              [id]="angleInputId"
              name="angle"
              type="number"
              autocomplete="off"
              class="form-control text-right text-end"
              [(ngModel)]="popover.modelValue.angle.value"
              [readonly]="property.value.value?.angle?.readonly || null"
              [min]="property.value.value?.angle?.min || 0"
              [max]="property.value.value?.angle?.max || 100"
              [step]="property.value.value?.angle?.resolution || 1"
              [required]="!property.value.value?.angle?.optional"
              [siNumberValidator]="property.value.value?.angle"
              [allowValuesOutOfRange]="allowValuesOutOfRange"
              (keydown.enter)="popover.submitEnter($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </si-property-popover>
</form>
