import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { map, Observable, tap } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { CalendarBx, CalendarStatus } from "./calendar-proxy.model";
import { CalendarProxyService } from "./calendar-proxy.sevice";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly calendarProxyService: CalendarProxyService) {

    this.traceService.info(TraceModules.bxServicesCalendars, 'CalendarService created.');
  }

  public getCalendars(partitionId: string, entityId: string, calendarName?: string, status?: CalendarStatus): Observable<CalendarBx[]> {
    this.traceService.debug(TraceModules.bxServicesCalendars, `CalendarService.getCalendars() called: partitionId: ${partitionId}, entityId: ${entityId}`);

    return this.calendarProxyService.getCalendars(partitionId, entityId, status).pipe(
      map(result => this.filterCalendars(result, calendarName)),
      tap(calendars => {
        this.traceService.debug(TraceModules.bxServicesCalendars, `CalendarService.getCalendars() returned: no of calendars: ${calendars.length} from backend
          partitionId=${partitionId}, calendarName=${calendarName}`);
      })
    );
  }

  private filterCalendars(calendars: CalendarBx[], name?: string): CalendarBx[] {
    if (name) {
      const found = this.findCalendar(calendars, name);
      return found ? [found] : [];
    } else {
      return calendars;
    }
  }

  private findCalendar(calendarData: CalendarBx[], name: string): CalendarBx | undefined {
    return calendarData.find(item => item.base.name === name);
  }
}
