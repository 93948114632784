import { isPlatformBrowser } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {
  Align,
  Direction,
  getContentPositionString,
  Placement,
  resolveReference,
  responsivelyCheckDirection
} from '@simpl/element-ng/common';
import { ResizeObserverService } from '@simpl/element-ng/resize-observer';
import { Subscription } from 'rxjs';

import { SiDropdownContainerComponent } from './si-dropdown-container.component';
import { SiDropdownMenuDirective } from './si-dropdown-menu.directive';
import { SiDropdownToggleDirective } from './si-dropdown-toggle.directive';

const RESIZE_THROTTLE_TIMEOUT = 100;

/**
 * @deprecated The {@link SiDropdownDirective} and its related directives should no longer be used.
 * - For creating menus, use {@link SiMenuModule} instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating plain overlays, use the {@link https://material.angular.io/cdk/overlay/overview CDK Overlay}: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/dropdowns/
 */
@Directive({
  selector: '[siDropdown]',
  exportAs: 'si-dropdown',
  host: {
    class: 'dropdown'
  },
  standalone: true
})
export class SiDropdownDirective implements OnChanges, OnDestroy, OnInit {
  /**
   * Disables the dropdown so it cannot be opened.
   */
  @Input({ transform: booleanAttribute }) dropdownIsDisabled = false;

  /**
   * Opens the dropdown on changes, can be changed at any time.
   * To open it only on first load use {@link dropdownOpenOnLoad}
   */
  @Input({ transform: booleanAttribute }) dropdownIsOpen = false;

  /**
   * Opens the dropdown when it first loads, to open it later use {@link dropdownIsOpen}.
   */
  @Input({ transform: booleanAttribute }) dropdownOpenOnLoad = false;

  /**
   * Specifies whether the dropdown should be closed on selection of a dropdown item.
   */
  @Input({ transform: booleanAttribute }) dropdownCloseOnSelect = true;

  /**
   * Specifies whether the dropdown should be closed on click outside.
   */
  @Input({ transform: booleanAttribute }) dropdownCloseOnClickOut = true;

  /**
   * Specifies whether the dropdown should be closed on escape.
   */
  @Input({ transform: booleanAttribute }) dropdownCloseOnEsc = true;

  /**
   * Specifies the direction of the dropdown, can be `down`, `up`, `start`, `end`.
   * Per default it opens downwards.
   */
  @Input() dropdownDirection: Direction = 'down';

  /**
   * Aligns the dropdown to a side, only works if the direction is not `start` or `end`.
   * Can be `start`, `center` and `end`, per default it aligns to the start.
   */
  @Input() dropdownAlign: Align = 'start';

  /**
   * Specifies whether the dropdown menu(s) should be appended to another container.
   * It will still be positioned the same.
   */
  @Input() dropdownContainer = '';

  /**
   * Specifies whether the direction should automatically be flipped if there is not enough space in the container or its parents.
   */
  @Input({ transform: booleanAttribute }) dropdownResponsiveDirection = false;

  /**
   * Specifies whether the direction should automatically be flipped also according to the placement reference and its parents.
   * Only relevant when `dropdownResponsiveDirection` is set to `true`.
   */
  @Input({ transform: booleanAttribute }) dropdownResponsiveDirectionToPlacement = true;

  /**
   * Specifies custom placement of the dropdown menu(s). This overrides the placement
   * specified by the direction. Can include `start`, `end`, `top` and `bottom`.
   */
  @Input() dropdownPlacement: Placement = '';

  /**
   * Specifies what the placement of the dropdown menu(s) should be relative to.
   */
  @Input() dropdownPlacementReference = '';

  /**
   * Specifies the selector to determine which elements inside the dropdown menu(s) are dropdown items.
   * The default selector targets the bootstrap `dropdown-item` class.
   */
  @Input() dropdownItemSelector = '.dropdown-item';

  /**
   * Specifies the selector to determine which elements inside the dropdown menu(s) are not dropdown items.
   * The default selector excepts the `dropdown-item-no-link` class.
   */
  @Input() dropdownItemSelectorException = '.dropdown-item-no-link';

  /**
   * Focuses the first menu on open of the dropdown.
   */
  @Input({ transform: booleanAttribute }) dropdownFocusOnOpen = true;

  /**
   * Focuses the first toggle on close of the dropdown.
   */
  @Input({ transform: booleanAttribute }) dropdownFocusOnClose = true;

  /**
   * Closes the dropdown when the menu(s) are not visible anymore.
   */
  @Input({ transform: booleanAttribute }) closeOnMenuScrollOut = true;

  /**
   * Closes the dropdown when the placement reference is not visible anymore.
   * Only works when either `placementReference` or `dropdownContainer` is set.
   */
  @Input({ transform: booleanAttribute }) closeOnPlacementReferenceScrollOut = true;

  /**
   * Emits the current dropdown state whenever it is opened or closed.
   */
  @Output() readonly dropdownOnToggle = new EventEmitter<boolean>();

  /**
   * Emits when any dropdown item inside the dropdown menu(s) is selected.
   * The event is the element of the dropdown item.
   */
  @Output() readonly dropdownOnSelect = new EventEmitter<HTMLElement>();

  /**
   * Emits when any the direction of the dropdown is changed, automatically or manually.
   * The event contains the new direction.
   */
  @Output() readonly dropdownResponsiveDirectionChange = new EventEmitter<string>();

  /** Whether the dropdown is actually open. */
  get isOpen(): boolean {
    return this.isOpenForConsumer;
  }

  /** Whether the dropdown appears open. */
  get appearsOpen(): boolean {
    return (
      !!this._menus.length && this._menus.every(menu => menu.content.classList.contains('show'))
    );
  }

  /**
   * Whether the dropdown has previously been opened.
   */
  dropdownOpened = false;

  // the consumer facing state
  private isOpenForConsumer = false;
  private dropdownOpen = false;

  private loaded = false;

  private containerHostElement: HTMLElement | null = null;

  private stateChangeTimeout: any = 0;

  private displayContainerComponent = false;
  containerComponentDisplayed = false;
  private containerComponentRef!: ComponentRef<SiDropdownContainerComponent>;
  private containerComponent!: SiDropdownContainerComponent;

  private reopenAfterContainerTimeout: any = 0;

  private changeDirectionTimeout: any = 0;
  private closeDropdownTimeout: any = 0;
  private _responsiveDirection: Direction = 'down';
  private suppressOutsideClickInCurrentTick = false;

  private resizeSubs?: Subscription;
  private viewContainer = inject(ViewContainerRef);
  private renderer = inject(Renderer2);
  private resizeObserver = inject(ResizeObserverService);
  private zone = inject(NgZone);
  private cdRef = inject(ChangeDetectorRef);
  parent = inject(SiDropdownDirective, { optional: true, skipSelf: true });

  /**
   * @defaultref {@link _responsiveDirection}
   */
  set responsiveDirection(direction: Direction) {
    this._responsiveDirection = direction || 'down';
    this.cdRef.markForCheck();
    this.dropdownResponsiveDirectionChange.emit(this._responsiveDirection);
  }

  get responsiveDirection(): Direction {
    return this._responsiveDirection;
  }

  private focusOnOpenTimeout = 0;

  hostElement: HTMLElement = inject(ElementRef).nativeElement;

  private _menus: SiDropdownMenuDirective[] = [];

  get menus(): readonly SiDropdownMenuDirective[] {
    return this._menus;
  }

  private _toggles: SiDropdownToggleDirective[] = [];

  get toggles(): readonly SiDropdownToggleDirective[] {
    return this._toggles;
  }

  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private children: SiDropdownDirective[] = [];

  @HostBinding('class.dropup') get isDropup(): boolean {
    return this.responsiveDirection === 'up';
  }

  @HostBinding('class.dropstart') get isDropstart(): boolean {
    return this.responsiveDirection === 'start';
  }

  @HostBinding('class.dropend') get isDropend(): boolean {
    return this.responsiveDirection === 'end';
  }

  @HostBinding('class.show') get dropdownShowClass(): boolean {
    return this.dropdownOpen;
  }

  ngOnInit(): void {
    this.parent?.addChild(this);
    this.updateInputs();
  }

  ngOnChanges(): void {
    this.updateInputs();
  }

  ngOnDestroy(): void {
    clearTimeout(this.changeDirectionTimeout);
    clearTimeout(this.stateChangeTimeout);
    clearTimeout(this.reopenAfterContainerTimeout);
    clearTimeout(this.focusOnOpenTimeout);
    clearTimeout(this.closeDropdownTimeout);
    this.parent?.removeChild(this);
    this.removeContainer();
    if (this.isBrowser) {
      removeEventListener('click', this.onClickOut);
      this.zone.runOutsideAngular(() => {
        removeEventListener('resize', this.onPlacementReferenceScroll);
        if (this.containerComponentDisplayed) {
          removeEventListener('scroll', this.onPlacementReferenceScroll, true);
        }
      });
    }
    this.resizeSubs?.unsubscribe();
  }

  private updateInputs(): void {
    let close = false;
    if (!this.loaded) {
      this.loaded = true;
      if ((this.dropdownOpenOnLoad || this.dropdownIsOpen) && !this.dropdownIsDisabled) {
        this.open();
      }
    } else {
      if (this.dropdownOpen && this.dropdownIsDisabled) {
        this.close();
        close = true;
      } else if (!this.dropdownOpen && this.dropdownIsOpen) {
        this.open();
      }
    }
    this.responsiveDirection = this.dropdownDirection;
    this.resetContainerAndPlacement(close);
  }

  private getPlacementReferenceElement(): HTMLElement {
    return resolveReference(this.hostElement, this.dropdownPlacementReference) ?? this.hostElement;
  }

  private resetContainerAndPlacement(close = false): void {
    const wasOpen = this.dropdownOpen;
    if (wasOpen) {
      this.closeDropdown();
    }
    this.displayContainerComponent = false;
    const containerHost = resolveReference(this.hostElement, this.dropdownContainer);
    if (containerHost) {
      this.containerHostElement = containerHost;
      this.displayContainerComponent = true;
    } else if (
      this.dropdownResponsiveDirection ||
      this.dropdownPlacement ||
      this.dropdownPlacementReference ||
      this.dropdownAlign === 'end' ||
      this.dropdownAlign === 'center'
    ) {
      this.containerHostElement = this.hostElement as HTMLElement;
      this.displayContainerComponent = true;
    } else {
      this.containerHostElement = null;
    }
    if (wasOpen && !close) {
      this.reopenAfterContainerTimeout = setTimeout(() => this.attemptDropdownOpen());
    }
  }

  private responsivelyChangeDirection(
    placementReferenceElement: HTMLElement,
    isScrolling = false
  ): void {
    if (this.dropdownResponsiveDirection && this.containerComponentDisplayed && this.dropdownOpen) {
      const { responsiveDirection, close } = responsivelyCheckDirection({
        isScrolling,
        currentDirection: this.dropdownDirection,
        contentElements: this._menus.map(menu => menu.content),
        hostElement: this.containerHostElement,
        placement: this.dropdownPlacement,
        placementReferenceElement,
        align: this.dropdownAlign,
        responsiveDirectionToPlacement: this.dropdownResponsiveDirectionToPlacement,
        closeOnPlacementReferenceScrollOut: this.closeOnPlacementReferenceScrollOut,
        closeOnContentScrollOut: this.closeOnMenuScrollOut
      });
      if (responsiveDirection && responsiveDirection !== this.responsiveDirection) {
        // wont emit but value is there for position update; emit in zone
        this._responsiveDirection = responsiveDirection;
        this.zone.run(() => {
          if (close) {
            this.closeDropdown();
          }
          this.responsiveDirection = responsiveDirection;
        });
      } else if (close) {
        this.zone.run(() => this.closeDropdown());
      }
    }
  }

  addMenu(menu: SiDropdownMenuDirective): void {
    this._menus.push(menu);
    if (this.containerComponentDisplayed) {
      this.containerComponent.removeMenus();
      this.containerComponent.addMenus();
    }
    menu.updateState(this.dropdownOpen, this.getActualAlign());
  }

  removeMenu(menu: SiDropdownMenuDirective): void {
    const menuIndex = this._menus.indexOf(menu);
    if (menuIndex >= 0) {
      this._menus.splice(menuIndex, 1);
      if (this.containerComponentDisplayed) {
        this.containerComponent.removeMenus();
        this.containerComponent.addMenus();
      }
    }
  }

  private onPlacementReferenceScroll = (): void => this.placementReferenceScrollHandler();

  private placementReferenceScrollHandler(): void {
    if (this.dropdownOpen && this.containerComponentDisplayed) {
      const placementReferenceElement = this.getPlacementReferenceElement();
      this.responsivelyChangeDirection(placementReferenceElement, true);
      const position = getContentPositionString({
        contentElement: this.containerComponent.content,
        direction: this.responsiveDirection,
        placement: this.dropdownPlacement,
        placementReferenceElement,
        align: this.dropdownAlign
      });
      if (position !== this.containerComponent.elementTransform) {
        this.zone.run(() => {
          this.containerComponent.elementTransform = position;
          this.cdRef.markForCheck();
        });
      }
    }
  }

  private addContainer(): void {
    if (!this.containerComponentDisplayed) {
      this.containerComponentRef = this.viewContainer.createComponent(SiDropdownContainerComponent);
      this.containerComponent = this.containerComponentRef.instance;
      this.containerComponent.setParent(this);
      this.renderer.appendChild(this.containerHostElement, this.containerComponent.content);
      this.containerComponentDisplayed = true;
      // Set a property of the HTML element for others to follow the chain without having to use the service.
      (this.hostElement as any).dropdownChildren = this.containerComponent.content.children;
    }
  }

  private removeContainer(): void {
    if (this.containerComponentDisplayed) {
      if (this.containerHostElement!.contains(this.containerComponent.content)) {
        this.renderer.removeChild(this.containerHostElement, this.containerComponent.content);
      }
      this.containerComponentRef.destroy();
      this.containerComponentDisplayed = false;
      delete (this.hostElement as any).dropdownChildren;
    }
  }

  private onClickOut = (event: MouseEvent): void => this.clickOutHandler(event);

  private clickOutHandler(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      !this.suppressOutsideClickInCurrentTick &&
      this.dropdownOpen &&
      this.dropdownCloseOnClickOut &&
      (!this.isInsideOverlay(target) || this.isInsideOverlay(this.hostElement)) &&
      !this.isElementOfAnyDropdownInHierarchy(target)
    ) {
      this.closeDropdown();
    }
  }

  @HostListener('keydown.escape', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const target = event.target as HTMLElement;
    if (this.dropdownCloseOnEsc && !this.isElementOfAnyChildDropdownInHierarchy(target)) {
      this.closeDropdown();
    }
  }

  /** Toggles the dropdown. */
  toggle(): void {
    if (!this.dropdownIsDisabled) {
      if (this.dropdownOpen) {
        this.closeDropdown();
      } else {
        this.attemptDropdownOpen();
      }
    }
  }

  /** Opens the dropdown. */
  open(): void {
    if (!this.dropdownIsDisabled) {
      this.attemptDropdownOpen();
    }
  }

  /** Closes the dropdown. */
  close(): void {
    this.closeDropdown();
  }

  addToggle(toggle: SiDropdownToggleDirective): void {
    this._toggles.push(toggle);
  }

  removeToggle(toggle: SiDropdownToggleDirective): void {
    this._toggles.splice(this._toggles.indexOf(toggle));
  }

  addChild(child: SiDropdownDirective): void {
    this.children.push(child);
  }

  removeChild(child: SiDropdownDirective): void {
    this.children.splice(this.children.indexOf(child));
  }

  private waitForVisibilityChange(): void {
    if (
      this.containerHostElement &&
      (this.containerHostElement.offsetParent ||
        (window.getComputedStyle(this.containerHostElement).position === 'fixed' &&
          window.getComputedStyle(this.containerHostElement).display !== 'none') ||
        this.containerHostElement === document.body ||
        this.containerHostElement === document.documentElement)
    ) {
      this.resizeSubs?.unsubscribe();
      this.openDropdown();
    }
  }

  private attemptDropdownOpen(): void {
    if (
      this.containerHostElement &&
      this.containerHostElement !== document.body &&
      this.containerHostElement !== document.documentElement &&
      !(
        this.containerHostElement.offsetParent ||
        window.getComputedStyle(this.containerHostElement).position === 'fixed' ||
        window.getComputedStyle(this.containerHostElement).display !== 'none'
      )
    ) {
      this.resizeSubs?.unsubscribe();
      this.resizeSubs = this.resizeObserver
        .observe(this.containerHostElement, RESIZE_THROTTLE_TIMEOUT, true, true)
        .subscribe(() => this.waitForVisibilityChange());
    } else {
      this.openDropdown();
    }
  }

  private openDropdown(): void {
    this.suppressOutsideClickInCurrentTick = true;
    setTimeout(() => (this.suppressOutsideClickInCurrentTick = false));
    if (!this.dropdownOpen) {
      if (this.displayContainerComponent) {
        this.addContainer();
        if (this.dropdownResponsiveDirection) {
          this.containerComponent.hide = true;
        }
      }
      this.responsiveDirection = this.dropdownDirection;
      clearTimeout(this.closeDropdownTimeout);
      this.dropdownOpen = true;
      this.dropdownOpened = true;
      if (this.displayContainerComponent) {
        setTimeout(() => {
          this.isOpenForConsumer = true;
          this.dropdownOnToggle.emit(this.isOpenForConsumer);
        });
      } else {
        this.isOpenForConsumer = true;
        this.dropdownOnToggle.emit(this.isOpenForConsumer);
      }
      setTimeout(() => addEventListener('click', this.onClickOut));
      this._toggles.forEach(dropdownToggle => dropdownToggle.updateState(true));
      const align = this.getActualAlign();
      this._menus.forEach(dropdownMenu => dropdownMenu.updateState(true, align));
      if (this.containerComponentDisplayed) {
        this.zone.runOutsideAngular(() => {
          // Add event listener to window as capturing to handle tested element scrolling
          addEventListener('scroll', this.onPlacementReferenceScroll, true);
          // here we _actually_ want window.resize, nothing else since this is about a fixed position
          addEventListener('resize', this.onPlacementReferenceScroll);
        });
        this.containerComponent.elementTransform = getContentPositionString({
          contentElement: this.containerComponent.content,
          direction: this.responsiveDirection,
          placement: this.dropdownPlacement,
          placementReferenceElement: this.getPlacementReferenceElement(),
          align: this.dropdownAlign
        });
      }
      this.changeDirectionTimeout = setTimeout(() => {
        this.changeDirectionTimeout = 0;
        const placementReferenceElement = this.getPlacementReferenceElement();
        this.responsivelyChangeDirection(placementReferenceElement);
        if (this.containerComponentDisplayed) {
          this.containerComponent.elementTransform = getContentPositionString({
            contentElement: this.containerComponent.content,
            direction: this.responsiveDirection,
            placement: this.dropdownPlacement,
            placementReferenceElement,
            align: this.dropdownAlign
          });
        }
        if (this.containerComponent) {
          this.containerComponent.hide = false;
          this.cdRef.detectChanges();
        }
      });
      if (this.dropdownFocusOnOpen) {
        this.focusOnOpenTimeout = window.setTimeout(() => {
          this.focusOnOpenTimeout = 0;
          if (this._menus.length) {
            this._menus[0].content.focus();
          }
        });
      }
    }
  }

  private closeDropdown(): void {
    if (this.dropdownOpen) {
      this.responsiveDirection = this.dropdownDirection;
      clearTimeout(this.closeDropdownTimeout);
      this.dropdownOpen = false;
      this.closeDropdownTimeout = setTimeout(() => {
        this.isOpenForConsumer = false;
        this.dropdownOnToggle.emit(this.isOpenForConsumer);
      });
      removeEventListener('click', this.onClickOut);
      this._toggles.forEach(dropdownToggle => dropdownToggle.updateState(false));
      this._menus.forEach(dropdownMenu => dropdownMenu.updateState(false));
      if (this.containerComponentDisplayed) {
        this.zone.runOutsideAngular(() => {
          removeEventListener('scroll', this.onPlacementReferenceScroll, true);
          removeEventListener('resize', this.onPlacementReferenceScroll);
        });
      }
      if (this.displayContainerComponent) {
        this.removeContainer();
      }
      if (this.dropdownFocusOnClose && this._toggles.length) {
        this._toggles[0].content.focus();
      }
      this.children.forEach(child => child.closeDropdown());
      this.cdRef.markForCheck();
    }
  }

  private getActualAlign(): string {
    return this.responsiveDirection === 'start' || this.responsiveDirection === 'end'
      ? 'none'
      : this.dropdownAlign;
  }

  /** Selects a dropdown item. */
  selectItem(item: HTMLElement): void {
    this.dropdownOnSelect.emit(item);
    if (
      this.isElementOfDropdown(item) &&
      !this.isDropdownToggleForDirectChild(item) &&
      !this.isElementOfAnyChildDropdownInHierarchy(item) &&
      this.dropdownCloseOnSelect
    ) {
      this.close();
    }
  }

  private isInsideOverlay(item: HTMLElement): boolean {
    let currentItem: HTMLElement | null = item;
    while (currentItem) {
      if (currentItem.classList.contains('cdk-overlay-container')) {
        return true;
      }
      currentItem = currentItem.parentElement;
    }
    return false;
  }

  private isElementOfAnyDropdownInHierarchy(item: HTMLElement): boolean {
    return this.isElementOfDropdown(item) || this.isElementOfAnyChildDropdownInHierarchy(item);
  }

  private isElementOfAnyChildDropdownInHierarchy(item: HTMLElement): boolean {
    return this.children.some(child => child.isElementOfAnyDropdownInHierarchy(item));
  }

  private isElementOfDropdown(item: HTMLElement): boolean {
    return this._menus.some(menu => menu.content.contains(item));
  }

  private isDropdownToggleForDirectChild(item: HTMLElement): boolean {
    return this.children.some(child => child.toggles.some(toggle => toggle.content.contains(item)));
  }
}
