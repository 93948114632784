import { AsyncPipe, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiAutocompleteDirective } from '@simpl/element-ng/autocomplete';
import { BackgroundColorVariant, isRTL } from '@simpl/element-ng/common';
import {
  SiTranslateModule,
  SiTranslateService,
  TranslatableString
} from '@simpl/element-ng/translate';
import { SiTypeaheadDirective, TypeaheadMatch, TypeaheadOption } from '@simpl/element-ng/typeahead';
import { Observable, Observer, of, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { getNamedFormat } from '../datepicker/date-time-helper';
import { SiDatepickerOverlayDirective } from '../datepicker/si-datepicker-overlay.directive';
import { SiDatepickerDirective } from '../datepicker/si-datepicker.directive';
import { DatepickerInputConfig, getDatepickerFormat } from '../datepicker/si-datepicker.model';
import {
  CriterionIntern,
  differenceByName,
  filterByLabel,
  findByName,
  hasCriteriaLabel,
  OptionCriterionIntern,
  SearchCriteriaIntern,
  selectOptions,
  toCriteriaIntern,
  toOptionCriteria,
  toSearchCriteriaIntern
} from './si-filtered-search-helper';
import {
  Criterion,
  DisplayedCriteriaEventArgs,
  OptionCriterion,
  OptionType,
  SearchCriteria
} from './si-filtered-search.model';
import { ToLabelPipe } from './to-label.pipe';

const FOCUS_INDEX_FREE_TEXT_INPUT_FIELD = -1;
const FOCUS_INDEX_OUT_OF_COMPONENT = -2;

interface LazyValueRequest {
  criterionName: string;
  typed: string | string[];
}

@Component({
  selector: 'si-filtered-search',
  templateUrl: './si-filtered-search.component.html',
  styleUrl: './si-filtered-search.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    FormsModule,
    NgClass,
    NgTemplateOutlet,
    SiAutocompleteDirective,
    SiDatepickerOverlayDirective,
    SiDatepickerDirective,
    SiTypeaheadDirective,
    SiTranslateModule,
    ToLabelPipe
  ]
})
export class SiFilteredSearchComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly criterionRegex = '(\\S+:)';

  // See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
  // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
  private static readonly keys = {
    backspace: 'Backspace',
    colon: ':',
    enter: 'Enter',
    semicolon: ';',
    separator: ';',
    tab: 'Tab'
  };

  /**
   * Output callback event that provides an object describing the
   * selected criteria and additional filter text.
   */
  @Output() readonly doSearch: EventEmitter<SearchCriteria> = new EventEmitter();

  /**
   * If this is set to `true`, the search triggers for each input (implicit search).
   * By default search is triggered by "Apply search criteria" explicitelly only.
   * This hides "Apply search criteria" also.
   */
  @Input({ transform: booleanAttribute }) doSearchOnInputChange = false;
  /**
   * In addition to lazy loaded value, you can also lazy load the criteria itself
   */
  @Input() lazyCriterionProvider?: (
    typed: string,
    searchCriteria?: SearchCriteria
  ) => Observable<Criterion[]>;
  /**
   * In many cases, your application defines the criteria, but the values need
   * to be loaded from a server. In this case you can provide a function that
   * returns the possible criterion options as an Observable.
   */
  @Input() lazyValueProvider?: (
    criterionName: string,
    typed: string | string[]
  ) => Observable<OptionType[]>;
  /**
   * Disable any interactivity.
   */
  @HostBinding('class.disabled')
  @Input({ transform: booleanAttribute })
  disabled = false;
  /**
   * Do not allow changes. Search can still be triggered.
   * @deprecated Use {@link disabled} instead.
   */
  @Input({ transform: booleanAttribute }) readonly = false;
  /**
   * Limit criteria to the predefined ones.
   */
  @Input({ transform: booleanAttribute }) strictCriterion = false;
  /**
   * Limit criterion options to the predefined ones. `[strictValue]`
   * enforces `[strictCriterion]` to true automatically.
   */
  @Input({ transform: booleanAttribute }) strictValue = false;
  /**
   * Limit criterion options to the predefined ones and prevent typing. `[onlySelectValue]`
   * enforces `[strictValue]` and `[strictCriterion]` to true automatically.
   */
  @Input({ transform: booleanAttribute }) onlySelectValue = false;
  /**
   * Custom debounce time for lazy loading of criteria data.
   */
  @Input() lazyLoadingDebounceTime = 500;

  /**
   * Custom debounce time (in mills) to delay the search emission.
   * (Default is 0 as in most cases a users manually triggers a search.
   * Recommended to increase a bit when using doSearchOnInputChange=true)
   */
  @Input() searchDebounceTime = 0;
  /**
   * The placeholder for input field.
   */
  @Input() placeholder = '';
  /**
   * Show a search icon in input field.
   */
  @Input({ transform: booleanAttribute }) showIcon = false;
  /**
   * Custom 'submit' text.
   */
  @Input() submitText = $localize`:@@SI_FILTERED_SEARCH.SUBMIT:Apply search criteria`;
  /**
   * Index of the criteria in dropdown which should be selected initially.
   */
  @Input() selectedCriteriaIndex = 0;
  /**
   * Defines the number of criterions or criteria values or operators visible at once.
   */
  @Input() optionsInScrollableView = 10;
  /**
   * The current selected search criteria and entered search text.
   */
  @Input() searchCriteria?: SearchCriteria = { criteria: [], value: '' };
  /**
   * Emits changes of the {@link searchCriteria} and enables a bi-directionally binding.
   */
  @Output() readonly searchCriteriaChange = new EventEmitter<SearchCriteria>();

  /**
   * Predefine criteria options.
   */
  @Input() criteria: Criterion[] = [];

  /**
   * Opt-in to search for each criterion only once.
   */
  @Input({ transform: booleanAttribute }) exclusiveCriteria = false;

  /**
   * Limit the number of possible criteria. The default is undefined so that any number of criteria can be used.
   * For example, setting the value to 1 let you only select one criterion that you need to remove before being
   * able to set another one.
   */
  @Input() maxCriteria: number | undefined = undefined;

  /**
   * Defines the maximum options within one criteria. The default is 20 and 0 means unlimited.
   */
  @Input() maxCriteriaOptions = 20;

  /**
   * Search input aria label, Needed by a11y
   */
  @Input() searchLabel = $localize`:@@SI_FILTERED_SEARCH.SEARCH:Search`;
  /**
   * Clear button aria label. Needed for a11y
   */
  @Input() clearButtonLabel = $localize`:@@SI_FILTERED_SEARCH.CLEAR:Clear`;
  /**
   * Items count text appended to the count in case of multi-selection of values.
   * Translation key, `{{itemCount}}` in the translation will be replaced with the actual value.
   */
  @Input() itemCountText: TranslatableString = '';
  /**
   * Color variant to determine component background
   */
  @Input() colorVariant: BackgroundColorVariant = 'base-1';
  /**
   * Text or translate key for multi selection pills text.
   * @deprecated Use the new input {@link itemCountText} instead.
   */
  @Input() items = $localize`:@@SI_FILTERED_SEARCH.ITEMS:items`;
  /**
   * Disables the free text search to only use the criterion for filtering.
   */
  @Input({ transform: booleanAttribute }) disableFreeTextSearch = false;
  /**
   * Limit on the number of criteria/criteria value to be displayed by the typeahead
   */
  @Input() typeaheadOptionsLimit = 20;
  /**
   * Text for first entry in dropdown in case that no criteria
   * match the text input and the property `disableFreeTextSearch`
   * is set to `true`.
   */
  @Input()
  noMatchingCriteriaText =
    $localize`:@@SI_FILTERED_SEARCH.NO_MATCHING_CRITERIA:No matching criteria`;

  /**
   * The interceptor is called when the list of criteria is shown as soon as the user starts typing in the input field.
   * The interceptor's {@link DisplayedCriteriaEventArgs.allow} method can be used to filter the list of displayed criteria.
   *
   * **Note:** The interceptor is called as long as the {@link searchCriteria} does not exceed {@link maxCriteria}.
   * Further, the interceptor is not called when using the {@link lazyCriterionProvider}.
   *
   * @example
   * ```
   * <si-filtered-search
   *   [criteria]="[{ name: 'foo', label: 'Foo' }, { name: 'bar', label: 'Bar' }]"
   *   (interceptDisplayedCriteria)="$event.allow(
   *       $event.searchCriteria.criteria.some(s => s.name === 'foo')
   *         ? $event.criteria.filter(c => c !== 'foo')
   *         : $event.criteria
   *     ))">
   * </si-filtered-search>
   * ```
   */
  @Output() readonly interceptDisplayedCriteria = new EventEmitter<DisplayedCriteriaEventArgs>();

  @HostBinding('class.focus-within')
  protected get focusWithin(): boolean {
    return this.editCriterionIndex === -1 && this.internSearchCriteria.criteria.length === 0;
  }
  @HostBinding('class.dark-background')
  protected get darkBackground(): boolean {
    return this.colorVariant === 'base-0';
  }

  @ViewChild('clearInputButton', { read: ElementRef }) private clearInputButton?: ElementRef;
  @ViewChild('valueInput', { read: ElementRef }) private valueInput!: ElementRef;
  @ViewChild('scrollContainer', { read: ElementRef, static: true })
  private scrollContainer!: ElementRef;

  protected dataSource: Observable<Criterion[]>;
  protected editCriterionIndex = FOCUS_INDEX_FREE_TEXT_INPUT_FIELD;

  /** Internal representation of the input search criteria */
  protected internSearchCriteria: SearchCriteriaIntern = {
    criteria: [],
    value: ''
  };
  /** Internal criteria model */
  protected internCriteria: CriterionIntern[] = [];
  protected shortDateFormat: string;
  protected focusState = false;

  private integerInvalidChar: string[] = [',', '.'];
  private debouncedCriterionQueueSubject = new Subject<any>();
  private previousEditingValue!: string;
  /** Used to debounce while fetching for criteria data */
  private debouncedValueSubject = new Subject<LazyValueRequest>();
  /** Used to trigger a renewed search */
  private typeaheadInputChange = new Subject<string>();
  private lastTypeaheadInput = '';
  /** Used to debounce the Search emissions */
  private searchEmitQueue = new Subject<SearchCriteria | undefined>();
  private destroySubscriptions = new Subject<boolean>();
  private translationSubs: Subscription[] = [];
  private lazyLoadedValue = new Subject<OptionType[]>();
  private elementRef = inject(ElementRef);
  private locale = inject(LOCALE_ID);
  private cdRef = inject(ChangeDetectorRef);
  private translateService = inject(SiTranslateService);
  /**
   * The cache is used to control when the interceptDisplayedCriteria event needs to be called.
   * Every time a criteria gain the focus we have to reset the cache to call the interceptor.
   */
  private allowedCriteriaCache?: string[];

  constructor() {
    this.shortDateFormat = getNamedFormat(this.locale, 'shortDate');
    if (!this.shortDateFormat.includes('yyyy')) {
      this.shortDateFormat = this.shortDateFormat.replace('yy', 'yyyy');
    }
    this.dataSource = new Observable((observer: Observer<CriterionIntern[]>) => {
      if (this.lazyCriterionProvider) {
        this.debouncedCriterionQueueSubject.next(observer);
      } else {
        observer.next(this.getCriteriaToDisplay(''));
      }
      this.typeaheadInputChange.subscribe(value => {
        if (this.lazyCriterionProvider) {
          this.debouncedCriterionQueueSubject.next(observer);
        } else {
          observer.next(this.getCriteriaToDisplay(value));
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchCriteria || changes.criteria) {
      this.initCriteria();
      this.initSearchCriteria();
      this.processTranslationChange();
    }
  }

  ngOnInit(): void {
    if (this.onlySelectValue) {
      this.strictValue = true;
    }
    if (this.strictValue) {
      this.strictCriterion = true;
    }
    if (this.strictCriterion && this.internCriteria.length === 0) {
      throw new Error('strict criterion mode activated without predefined criteria!');
    }
    this.setupCriteriaFetchQueue();

    this.searchEmitQueue
      .pipe(debounceTime(this.searchDebounceTime), takeUntil(this.destroySubscriptions))
      .subscribe(searchCriteria => this.doSearch.emit(searchCriteria));

    if (this.elementRef.nativeElement.attributes.disabled) {
      this.disabled = true;
    }
  }

  private processTranslationChange(): void {
    this.cleanupTranslationSubs();
    this.translateCriterions();
    this.translateCriterionOptions();
  }

  private translateCriterionOptions(): void {
    // prepare criteria options keys for translation
    const options = this.internCriteria
      .map(c => c.options)
      .flat()
      .filter(o => typeof o !== 'string' && o?.label);

    const translationKeys = options.map(o => (o as OptionCriterion)!.label!);
    const optionValues = Array.from(new Set(translationKeys));

    if (optionValues.length > 0) {
      this.translationSubs.push(
        this.translateService.translateAsync(optionValues).subscribe(keys => {
          // update the criteria with the translated values
          options.forEach(opt => {
            const optionCrit = opt as OptionCriterion;
            optionCrit.label = keys[optionCrit.label!] ?? optionCrit.label;
          });
        })
      );
    }
  }

  private translateCriterions(): void {
    // prepare criterion keys for translation
    const criteriaLabels = this.internCriteria.filter(criteria => criteria.label);

    const criteriaKeys = criteriaLabels.map(criteria => criteria.label!);
    if (criteriaKeys.length > 0) {
      this.translationSubs?.push(
        this.translateService.translateAsync(criteriaKeys).subscribe(keys => {
          criteriaLabels.forEach(crit => {
            crit.label = keys[crit.label!] ?? crit.label;
          });
          // update the search criteria with the translated values
          this.internSearchCriteria.criteria
            .filter(criteria => criteria.label)
            .forEach(crit => {
              crit.label = keys[crit.label!] ?? crit.label;
            });
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroySubscriptions.next(true);
    this.destroySubscriptions.unsubscribe();
    this.cleanupTranslationSubs();
  }

  private cleanupTranslationSubs(): void {
    this.translationSubs.forEach(s => s.unsubscribe());
    this.translationSubs = [];
  }

  private initCriteria(): void {
    this.criteria = this.criteria ?? [];
    this.internCriteria = this.criteria.map(c => toCriteriaIntern(c));
  }

  private initSearchCriteria(): void {
    this.searchCriteria = this.searchCriteria ?? { criteria: [], value: '' };
    this.internSearchCriteria = {
      criteria:
        this.searchCriteria.criteria.map(c =>
          toSearchCriteriaIntern(c, this.getCriterionByName(c.name))
        ) ?? [],
      value: this.searchCriteria?.value ?? ''
    };
  }

  /**
   * It sets the value for searchCriteria for free text and patch the form control accordingly
   * @param newValue the new value
   */
  private setSearchCriteriaValue(newValue: string): void {
    if (this.internSearchCriteria.value !== newValue) {
      this.internSearchCriteria.value = newValue;
      this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    }
  }

  private buildCriteriaTopItem(showNoMatchingCriteria: boolean): any {
    return showNoMatchingCriteria
      ? {
          name: '',
          value: '',
          label: this.noMatchingCriteriaText
        }
      : {
          /** Provide the current free text value to ensure the model change submit the correct value. */
          name: this.internSearchCriteria.value,
          value: '',
          label: this.submitText,
          isSubmitItem: true
        };
  }

  private getCriteriaToDisplay(value: string): CriterionIntern[] {
    const filtered = this.getFilteredTypeaheadCriteria(value);
    const showNoMatchingCriteria = this.disableFreeTextSearch && filtered.length === 0;
    const topItems =
      showNoMatchingCriteria || !this.doSearchOnInputChange
        ? [this.buildCriteriaTopItem(showNoMatchingCriteria)]
        : [];
    return [...topItems, ...filtered];
  }

  protected criterionInputKeyUp(event: KeyboardEvent): void {
    let newValue = (event.target as HTMLInputElement).value;
    if (event.key === SiFilteredSearchComponent.keys.enter) {
      newValue = this.previousEditingValue;
    } else if (event.key === SiFilteredSearchComponent.keys.backspace) {
      this.handleInputBackspace();
    } else if (event.key === SiFilteredSearchComponent.keys.colon && !this.onlySelectValue) {
      this.handleInputColon();
      // emit clear, when doSearchOnInputChange is wished or value is empty
      if (this.doSearchOnInputChange || !this.internSearchCriteria.value) {
        this.searchEmitQueue.next(this.searchCriteria);
      }
    } else if (this.doSearchOnInputChange) {
      // trigger search if value doesn't include criterion.label
      const labelIncluded = hasCriteriaLabel(this.internCriteria, newValue);
      if (labelIncluded) {
        this.searchEmitQueue.next(this.searchCriteria);
      }
    }

    this.previousEditingValue = newValue;
  }

  protected handleContextMenuPaste(event: ClipboardEvent): void {
    if (!this.disableFreeTextSearch) {
      const clipboardData = event.clipboardData;
      this.previousEditingValue = clipboardData?.getData('Text') ?? '';
    }
  }

  /**
   * Deletes all currently selected criteria and effectively resets the filtered search.
   */
  deleteAllCriteria(event?: MouseEvent): void {
    if (this.isReadOnly()) {
      return;
    }
    event?.stopPropagation();

    // Reset search criteria
    this.internSearchCriteria = { criteria: [], value: '' };
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    this.doSearch.emit(this.searchCriteria);
  }

  protected deleteCriterion(event: Event | null, index: number): void {
    if (this.isReadOnly()) {
      return;
    }
    if (event) {
      event.stopPropagation();
      this.editCriterionByIndex(FOCUS_INDEX_FREE_TEXT_INPUT_FIELD);
    }

    this.internSearchCriteria.criteria.splice(index, 1);
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    this.doSearch.emit(this.searchCriteria);
  }

  protected editCriterion(criterion: CriterionIntern): void {
    if (this.isReadOnly()) {
      return;
    }
    const index = this.internSearchCriteria.criteria.indexOf(criterion);
    const criterionOptions = this.getCriterionByName(criterion.name);
    this.editCriterionByIndex(index, (criterionOptions?.operators?.length ?? 0) > 0);
  }

  protected editOperator(criterion: CriterionIntern, event: Event): void {
    if (this.isReadOnly()) {
      return;
    }
    event.stopPropagation();
    const index = this.internSearchCriteria.criteria.indexOf(criterion);
    this.editCriterionByIndex(index, true);
  }

  protected editValue(criterion: CriterionIntern, event: Event): void {
    if (this.isReadOnly()) {
      return;
    }
    event.stopPropagation();
    const index = this.internSearchCriteria.criteria.indexOf(criterion);
    this.editCriterionByIndex(index);
  }

  protected getCriterionOptions(
    name: string,
    index: number,
    typed?: string | string[]
  ): Observable<OptionCriterion[]> {
    return new Observable((observer: Observer<OptionCriterion[]>) => {
      if (!typed) {
        typed = '';
      }
      const searchLabel = this.onlySelectValue ? '' : typed?.toString();
      const existingSearchCriteria = this.internSearchCriteria.criteria[index];
      if (this.lazyValueProvider) {
        const lazyValRequest: LazyValueRequest = {
          criterionName: name,
          typed
        };
        // Initiate call to app with typed string and selected criteria
        this.debouncedValueSubject.next(lazyValRequest);

        // Listen to subscription when app sends back server data
        this.lazyLoadedValue.pipe(takeUntil(this.destroySubscriptions)).subscribe(result => {
          const criteria = this.internCriteria.find(crit => crit.name === name);
          if (criteria) {
            criteria.options = result;
          }

          const options = this.filterOptionCriteria(
            name,
            existingSearchCriteria.value as string[],
            searchLabel,
            result
          );
          observer.next(options);
        });
      } else {
        const criterionOptions = this.getCriterionByName(name);
        const selected = existingSearchCriteria.value as string[];
        const options = this.filterOptionCriteria(
          name,
          selected,
          searchLabel,
          criterionOptions?.options
        );
        observer.next(options);
      }
    });
  }

  /** Create a filtered criterions option list based on the search label */
  private filterOptionCriteria(
    optionName: string,
    selected: string[],
    searchLabel?: string,
    options?: OptionType[]
  ): OptionCriterionIntern[] {
    const optionCriteria = toOptionCriteria(options);
    if (this.isMultiSelect(optionName)) {
      selectOptions(optionCriteria, selected);
    }
    return filterByLabel(optionCriteria, searchLabel?.toString());
  }

  protected getCriterionOperators(name: string, typed?: string): Observable<string[]> {
    return new Observable((observer: Observer<string[]>) => {
      const criterionOptions = this.getCriterionByName(name);
      if (!criterionOptions?.operators) {
        observer.next([]);
      } else if (typed && typed !== this.previousEditingValue) {
        observer.next(criterionOptions.operators.filter(operator => operator.includes(typed)));
      } else {
        observer.next(criterionOptions.operators);
      }
    });
  }

  protected getCriterionOnlySelectValue(name: string): boolean {
    const criterion = this.getCriterionByName(name);
    return criterion ? !!criterion.onlySelectValue && !criterion.multiSelect : false;
  }

  protected getSelectedOperatorIndex(name: string, operator?: string): number {
    const criterionOptions = this.getCriterionByName(name);
    if (!operator || !criterionOptions?.operators) {
      return -1;
    } else if (operator && operator !== this.previousEditingValue) {
      return criterionOptions.operators.filter(op => op.includes(operator)).indexOf(operator);
    }
    return criterionOptions.operators.indexOf(operator);
  }

  protected getLongestOperatorLength(name: string): number {
    const criterionOptions = this.getCriterionByName(name);
    if (!criterionOptions?.operators) {
      return 0;
    }
    return Math.max(...criterionOptions.operators.map(a => a.length));
  }

  private getValueInputForCriterion(index: number, editOperator = false): HTMLInputElement {
    if (index === FOCUS_INDEX_FREE_TEXT_INPUT_FIELD) {
      return this.elementRef.nativeElement.getElementsByClassName('value-input')[0];
    } else if (editOperator) {
      return this.elementRef.nativeElement.getElementsByClassName('operator-input-' + index)[0];
    } else {
      return this.elementRef.nativeElement.getElementsByClassName('value-input-' + index)[0];
    }
  }

  protected highlight(criterion: CriterionIntern): Observable<string> {
    let label = criterion.label;
    const lcValue = this.internSearchCriteria.value
      ? this.internSearchCriteria.value.toLowerCase()
      : '';
    if (criterion.name === '' || !lcValue || lcValue.length === 0 || criterion.isSubmitItem) {
      return this.translateService.translateAsync(label);
    }
    let lcLabel = label.toLowerCase();
    let idx: number;
    // eslint-disable-next-line no-cond-assign
    while ((idx = lcLabel.lastIndexOf(lcValue)) >= 0) {
      // eslint-disable-next-line max-len
      label = `${label.substring(0, idx)}<strong>${label.substring(
        idx,
        idx + lcValue.length
      )}</strong>${label.substring(idx + lcValue.length)}`;
      lcLabel = lcLabel.substring(0, idx);
    }
    return of(label);
  }

  protected inputFocused(event: FocusEvent, index = FOCUS_INDEX_FREE_TEXT_INPUT_FIELD): void {
    this.editCriterionIndex = index;
    this.previousEditingValue = (event.target as HTMLInputElement).value;
    this.focusState = true;
    this.allowedCriteriaCache = undefined;
    // Ensure that the free text input is fully visible in the scroll container
    if (index === FOCUS_INDEX_FREE_TEXT_INPUT_FIELD) {
      const scrollDirection = isRTL() ? -1 : 1;
      const position = scrollDirection * this.scrollContainer.nativeElement.scrollWidth;
      this.scrollContainer.nativeElement.scrollLeft = position;
    }
  }

  protected isOperatorSpecified(criterionName: string): boolean {
    const criterionOptions = this.getCriterionByName(criterionName);
    return (criterionOptions?.operators?.length ?? 0) > 0;
  }

  /** selectValue is invoked by select boxes in strict value mode. If the user selects an entry, the criterion is deselected. */
  protected selectValue(): void {
    this.editCriterionByIndex(FOCUS_INDEX_FREE_TEXT_INPUT_FIELD);
  }

  protected typeaheadOnSelectCriterion(event: TypeaheadOption): void {
    const criterion: CriterionIntern = event as CriterionIntern;
    if (criterion.label === this.noMatchingCriteriaText) {
      return;
    } else if (!criterion.name || criterion.name.trim().length === 0 || criterion.isSubmitItem) {
      // The user selected a top item (submit or no matching criteria)
      this.internSearchCriteria.value = this.previousEditingValue;
      this.setSearchCriteriaValue(this.previousEditingValue);
      this.searchEmitQueue.next(this.convertToExternalModel(this.internSearchCriteria));
      return;
    }
    // The user selected a criterion and we remove the free text search value and add the criterion
    this.internSearchCriteria.value = '';
    const editor = this.getValueInputForCriterion(this.editCriterionIndex);
    const endIndex = editor.selectionStart;
    const value = criterion.name;

    this.addCriterion({
      name: value,
      label: criterion.label,
      value: ''
    });
    if (!endIndex) {
      // selected from typeahead dropdown
      this.setSearchCriteriaValue('');
    } else {
      // not selected from typeahead dropdown
      this.setSearchCriteriaValue(
        value.substring(0, endIndex - value.length - 1) + value.substring(endIndex, value.length)
      );
    }
  }

  protected typeaheadOnSelectValue(
    criterionName: string,
    index: number,
    match: TypeaheadMatch
  ): void {
    const existingCriteria = this.getCriterionByName(criterionName);
    if (!existingCriteria?.multiSelect) {
      this.handleInputSeparator(false);
    } else {
      // In multi-select scenarios the internal model value is always of type array (see toSearchCriteriaIntern)
      const existingSearchCriteria = this.internSearchCriteria.criteria[index];
      const option = match.option as OptionCriterionIntern;
      if (existingSearchCriteria) {
        if (match.itemSelected) {
          (existingSearchCriteria.value as string[]).push(option.value);
        } else {
          if (typeof existingSearchCriteria.value !== 'string') {
            existingSearchCriteria.value = existingSearchCriteria.value?.filter(
              elem => elem !== option.value
            );
          }
        }
      }
      // Since in case of multiselect the model will not change, we need to explicitly call the output event
      this.updateAndEmitSearchCriteria(this.internSearchCriteria);
      if (this.doSearchOnInputChange) {
        this.searchEmitQueue.next(this.searchCriteria);
      }
    }
  }

  protected typeaheadOnSelectOperator(): void {
    // When an operator is selected from typeahead using the keyboard enter, it selects the operator and edits
    // the value input field. The action is so fast that the user keyup event is later called for the value
    // input which then exists the current criterion To delay this focus, below timeout is provided.
    this.editCriterionByIndex(this.editCriterionIndex, false, 200);
  }

  protected validateCriterionValue(criterion: CriterionIntern): boolean {
    const originalCriterion = this.getCriterionByName(criterion.name);
    const validMinMax = this.validateDateMinMaxCriteria(criterion);
    if (
      !originalCriterion ||
      (!this.strictValue && !originalCriterion.strictValue && !originalCriterion.onlySelectValue)
    ) {
      return validMinMax;
    }
    const values = this.getOptionsForCriterion(criterion.name, null);
    return values && criterion.value
      ? validMinMax && this.matchesValues(values, criterion)
      : !values && !!criterion.value;
  }

  private matchesValues(values: OptionCriterion[], criterion: CriterionIntern): boolean {
    return Array.isArray(criterion.value)
      ? criterion.value.every(val =>
          values.some(v => (v.label ? v.label === val : v.value === val))
        )
      : values.some(v =>
          v.label ? v.label === criterion.valueLabel : v.value === criterion.value
        );
  }

  protected validateCriterionLabel(criterion: CriterionIntern): boolean {
    if (!this.strictCriterion) {
      return true;
    }
    return !!this.getCriterionByName(criterion.name);
  }

  private trimCharBasedOnType(criterionName: string): boolean {
    let trimLastCharacter = true;
    const existingCriteria = this.getCriterionByName(criterionName);
    if (
      existingCriteria &&
      (existingCriteria.validationType === 'float' || existingCriteria.validationType === 'integer')
    ) {
      trimLastCharacter = false;
    }
    return trimLastCharacter;
  }

  protected operatorKeyUp(event: KeyboardEvent): void {
    const newValue = (event.target as HTMLInputElement).value;
    if (event.key === SiFilteredSearchComponent.keys.backspace) {
      this.handleInputBackspace(true);
    } else if (event.key === SiFilteredSearchComponent.keys.enter) {
      this.editCriterionByIndex(this.editCriterionIndex);
    }
    this.previousEditingValue = newValue;
  }

  protected inputFocusOut(event: Event, criterionName: string, index: number): void {
    const newValue = (event.target as HTMLInputElement).value;
    const criterionOptions = this.getCriterionByName(criterionName);
    if (criterionOptions?.operators && !criterionOptions.operators.includes(newValue)) {
      const existingSearchCriteria = this.internSearchCriteria.criteria[index];
      if (existingSearchCriteria) {
        existingSearchCriteria.operator = criterionOptions.operators.includes('=')
          ? '='
          : criterionOptions.operators[0];
        this.updateAndEmitSearchCriteria(this.internSearchCriteria);
      }
    }
  }

  /**
   * It gets the array of OptionType for the criteria passed
   * @param name
   */
  protected getOptionsFromCriteria(name: string): OptionType[] {
    return this.getCriterionByName(name)?.options ?? [];
  }

  /**
   * Converts the internally used data model to the external model.
   * In case options for Criterion is Option[] map to the value from the label.
   * @param searchCriteria searchCriteria to be converted.
   */
  private convertToExternalModel(searchCriteria: SearchCriteriaIntern): SearchCriteria {
    const correctedCriteria: SearchCriteria = {
      ...searchCriteria,
      criteria: searchCriteria.criteria.map(criterion => {
        // Strip internal properties from result
        const { valueLabel, ...sc } = criterion;
        if (this.isDate(sc.name)) {
          return { ...sc, value: sc.dateValue ? this.getISODateString(sc.dateValue) : '' };
        } else if (this.isDateTime(sc.name)) {
          if (sc.datepickerConfig?.disabledTime) {
            return { ...sc, value: sc.dateValue ? this.getISODateString(sc.dateValue) : '' };
          } else {
            return { ...sc, value: sc.dateValue ? sc.dateValue.toISOString() : '' };
          }
        } else {
          return { ...sc, value: this.getValue(sc) };
        }
      })
    };
    if (this.disableFreeTextSearch) {
      correctedCriteria.value = '';
    }
    return correctedCriteria;
  }

  private getValue(sc: CriterionIntern): string | string[] | undefined {
    const val = ToLabelPipe.getValue(this.getOptionsFromCriteria(sc.name), sc.value ?? '');
    return val ? this.getValueBasedOnType(val, sc.value) : sc.value;
  }

  private getValueBasedOnType(
    val: string | string[],
    searchCritVal: string | string[] | undefined
  ): string | string[] | undefined {
    if (Array.isArray(searchCritVal) && Array.isArray(val)) {
      // this is the case for multi-select
      return val.map((value, index) => value ?? searchCritVal[index]);
    }
    return val;
  }

  private getISODateString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  private addCriterion(criterion: CriterionIntern): void {
    if (!criterion.label || criterion.label.trim().length === 0) {
      criterion.label = criterion.name;
    }
    const criterionOptions = this.getCriterionByName(criterion.name);
    this.internSearchCriteria.criteria.push(toSearchCriteriaIntern(criterion, criterionOptions));
    // on initialization we do not want to set any criterion to editing
    if ((criterionOptions?.operators?.length ?? 0) > 0) {
      // when a new criterion is selected from typeahead using the keyboard enter, it adds the editable operator
      // input field and focuses on the operator field. The action is so fast that the user keyup event is later
      // called for the edited operator which then shifts focus and edit the value field. to delay this focus,
      // below timeout is provided.
      this.editCriterionByIndex(this.internSearchCriteria.criteria.length - 1, true, 200);
    } else {
      this.editCriterionByIndex(this.internSearchCriteria.criteria.length - 1);
    }
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    if (this.doSearchOnInputChange) {
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }

  private editCriterionByIndex(index: number, editOperator = false, timeout = 0): void {
    this.editCriterionIndex = index;
    setTimeout(() => {
      const editor = this.getValueInputForCriterion(index, editOperator);
      if (editor) {
        editor.focus();
      }
      this.cdRef.markForCheck();
    }, timeout);
  }

  private getCriterionByName(criterionName: string): CriterionIntern | undefined {
    if (!this.internCriteria) {
      return undefined;
    }
    // TODO: This does not work for lazy load criteria
    return findByName(this.internCriteria, criterionName);
  }

  protected hasMultiSelections(criterionName: string, criterionValue: string | string[]): boolean {
    return (
      this.isMultiSelect(criterionName) &&
      Array.isArray(criterionValue) &&
      criterionValue.length > 1
    );
  }

  /**
   * Get criteria list to be shown in typeahead.
   * @param token input field value.
   * @returns list of criteria to be shown in typeahead.
   */
  private getFilteredTypeaheadCriteria(token: string): CriterionIntern[] {
    if (
      this.maxCriteria === undefined ||
      this.internSearchCriteria.criteria.length < this.maxCriteria
    ) {
      let allowedCriteria = !this.exclusiveCriteria
        ? this.internCriteria
        : differenceByName(this.internCriteria, this.internSearchCriteria.criteria);

      if (allowedCriteria.length > 0 && !this.allowedCriteriaCache) {
        // Call interceptor to allow applications to customize the list of available criteria
        const available = allowedCriteria.map(c => c.name);
        // Ensure that all entries are allowed in case the consumer doesn't use the allow callback
        this.allowedCriteriaCache = available;
        this.interceptDisplayedCriteria.next({
          criteria: available,
          searchCriteria: this.internSearchCriteria,
          allow: criteriaNamesToDisplay => {
            if (criteriaNamesToDisplay) {
              this.allowedCriteriaCache = criteriaNamesToDisplay;
            }
          }
        });
      }

      allowedCriteria = allowedCriteria.filter(c => this.allowedCriteriaCache?.includes(c.name));
      return filterByLabel(allowedCriteria, token.length > 0 ? token : undefined);
    } else {
      return [];
    }
  }

  private getOptionsForCriterion(criterionName: string, emptyResult: any): OptionCriterion[] {
    const criterion = this.getCriterionByName(criterionName);
    if (!criterion) {
      return emptyResult;
    }

    if (criterion.options && criterion.options.length > 0) {
      // return criterion options if not empty
      return toOptionCriteria(criterion.options);
    } else {
      if (this.lazyValueProvider) {
        const request: LazyValueRequest = { criterionName, typed: '' };
        this.debouncedValueSubject.next(request);
      }
      return emptyResult;
    }
  }

  private handleInputBackspace(actionFromOperator = false): void {
    // True if the backspace is pressed in operator input field.
    if (actionFromOperator) {
      const editor = this.getValueInputForCriterion(this.editCriterionIndex, true);
      this.deleteCriterionAndEditLastCriterion(editor);
    } else if (this.editCriterionIndex === FOCUS_INDEX_FREE_TEXT_INPUT_FIELD) {
      if (this.internSearchCriteria.value.length === 0 && this.previousEditingValue.length === 0) {
        // edit last criterion if user presses backspace in empty search input
        this.editCriterionByIndex(this.internSearchCriteria.criteria.length - 1);
      }
    } else {
      // The condition executes when user enters backspace in the value input field
      const editor = this.getValueInputForCriterion(this.editCriterionIndex);
      const operatorEditor = this.getValueInputForCriterion(this.editCriterionIndex, true);
      // Check if operator is specified for the criteria. If yes edit the operator when value field is empty.
      if (
        operatorEditor &&
        editor &&
        editor.value.length === 0 &&
        this.previousEditingValue.length === 0
      ) {
        this.editCriterionByIndex(this.editCriterionIndex, true);
      } else {
        this.deleteCriterionAndEditLastCriterion(editor);
      }
    }

    if (this.doSearchOnInputChange && this.previousEditingValue.length > 0) {
      this.updateAndEmitSearchCriteria(this.internSearchCriteria);
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }

  private deleteCriterionAndEditLastCriterion(editor: HTMLInputElement): void {
    if (editor && editor.value.length === 0 && this.previousEditingValue.length === 0) {
      this.deleteCriterion(null, this.editCriterionIndex);
      if (this.editCriterionIndex >= 0) {
        this.editCriterionByIndex(this.editCriterionIndex - 1);
      }
    }
  }

  private handleInputColon(): void {
    if (this.editCriterionIndex !== FOCUS_INDEX_FREE_TEXT_INPUT_FIELD) {
      return;
    }
    const editor = this.getValueInputForCriterion(this.editCriterionIndex);
    if (!editor || editor.value.length <= 1) {
      return;
    }
    let match: RegExpExecArray | null;
    const endIndex = editor.selectionStart;
    const value = editor.value;
    const re = new RegExp(SiFilteredSearchComponent.criterionRegex, 'g');
    // eslint-disable-next-line no-cond-assign
    while ((match = re.exec(value)) != null) {
      if (endIndex === re.lastIndex) {
        const criterionName = value.substring(match.index, re.lastIndex - 1);
        this.addCriterion({ name: criterionName, label: criterionName, value: '' });
        this.setSearchCriteriaValue(
          value.substring(0, match.index - 1) + value.substring(re.lastIndex, value.length)
        );
      }
    }
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
  }

  private handleInputSeparator(trimLastCharacter: boolean): void {
    const editedCriterion = this.internSearchCriteria.criteria[this.editCriterionIndex];
    if (!editedCriterion) {
      return;
    }

    if (trimLastCharacter) {
      editedCriterion.value =
        editedCriterion.value?.toString().substring(0, editedCriterion.value.length - 1) ?? '';
    }
    if (editedCriterion.value) {
      this.editCriterionByIndex(FOCUS_INDEX_FREE_TEXT_INPUT_FIELD);
    }
  }

  private setupCriteriaFetchQueue(): void {
    this.debouncedCriterionQueueSubject
      .pipe(debounceTime(this.lazyLoadingDebounceTime))
      .subscribe((observer: Observer<Criterion[]>) => {
        let subscription: Subscription;
        if (this.lazyCriterionProvider) {
          subscription = this.getCriteriaDisplaySubscription(this.lastTypeaheadInput, observer)!;
        }
        this.typeaheadInputChange.subscribe(() => {
          if (this.lazyCriterionProvider) {
            subscription?.unsubscribe();
            subscription = this.getCriteriaDisplaySubscription(this.lastTypeaheadInput, observer)!;
          }
        });
      });

    this.debouncedValueSubject
      .pipe(debounceTime(this.lazyLoadingDebounceTime))
      .subscribe((request: LazyValueRequest) => {
        if (this.lazyValueProvider) {
          this.lazyValueProvider(request.criterionName, request.typed)
            .pipe(takeUntil(this.destroySubscriptions))
            .subscribe(result => {
              this.lazyLoadedValue.next(result);
            });
        }
      });
  }

  private getCriteriaDisplaySubscription(
    value: string,
    observer: Observer<Criterion[]>
  ): Subscription | undefined {
    return this.lazyCriterionProvider
      ? this.lazyCriterionProvider(value, this.searchCriteria)
          .pipe(takeUntil(this.destroySubscriptions))
          .subscribe(result => {
            observer.next(this.getCriteriaToDisplayFromSubscription(result));
          })
      : undefined;
  }

  private getCriteriaToDisplayFromSubscription(result: Criterion[]): Criterion[] {
    this.criteria = result;
    this.internCriteria = this.criteria.map(c => toCriteriaIntern(c));
    let displayCriteria: Criterion[] = [];
    if (
      this.maxCriteria === undefined ||
      this.internSearchCriteria.criteria.length < this.maxCriteria
    ) {
      displayCriteria = !this.exclusiveCriteria
        ? result
        : differenceByName(result, this.internSearchCriteria.criteria);
    }

    const showNoMatchingCriteria =
      this.disableFreeTextSearch &&
      this.internSearchCriteria.value.length > 0 &&
      displayCriteria.length === 0;
    const topItems =
      showNoMatchingCriteria || !this.doSearchOnInputChange
        ? [this.buildCriteriaTopItem(showNoMatchingCriteria)]
        : [];
    return [...topItems, ...displayCriteria];
  }

  protected getInputType(criterionName: string): string {
    const existingCriteria = this.getCriterionByName(criterionName);
    if (
      existingCriteria &&
      (existingCriteria.validationType === 'integer' || existingCriteria.validationType === 'float')
    ) {
      return 'number';
    }
    return 'text';
  }

  protected isDateOrDateTime(criterionName: string): boolean {
    const existingCriteria = this.getCriterionByName(criterionName);
    return (
      existingCriteria?.validationType === 'date-time' ||
      existingCriteria?.validationType === 'date'
    );
  }

  protected isDate(criterionName: string): boolean {
    const existingCriteria = this.getCriterionByName(criterionName);
    return existingCriteria?.validationType === 'date';
  }

  protected isDateTime(criterionName: string): boolean {
    const existingCriteria = this.getCriterionByName(criterionName);
    return existingCriteria?.validationType === 'date-time';
  }

  protected getDateTimeFormat(searchCriterion: CriterionIntern): string {
    const existingCriteria = this.getCriterionByName(searchCriterion.name);
    // The information if the time is currently disabled is only present in the
    // current search criterion instance and not in the generic configuration.
    // So we need to merge the initial config with the current instance config.
    const config = { ...existingCriteria?.datepickerConfig, ...searchCriterion.datepickerConfig };
    return getDatepickerFormat(this.locale, config);
  }

  protected disableTime(criterion: CriterionIntern, disabledTime: boolean): void {
    const dateConfig = criterion.datepickerConfig ? criterion.datepickerConfig : {};
    dateConfig.disabledTime = disabledTime;
    criterion.datepickerConfig = dateConfig;
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    if (this.doSearchOnInputChange) {
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }

  protected getDatepickerConfig(
    searchCriterion: CriterionIntern
  ): DatepickerInputConfig | undefined {
    const existingCriteria = this.getCriterionByName(searchCriterion.name);
    // a clone of the general criterion configuration
    const config = { ...existingCriteria?.datepickerConfig };
    // the current value could have an updated version, so we update
    if (searchCriterion.datepickerConfig?.disabledTime) {
      config.disabledTime = searchCriterion.datepickerConfig?.disabledTime;
    }
    return config;
  }

  protected isMultiSelect(criterionName: string): boolean {
    const existingCriteria = this.getCriterionByName(criterionName);
    return !!existingCriteria?.multiSelect;
  }

  protected getStepAttribute(criterionName: string): string {
    const existingCriteria = this.getCriterionByName(criterionName);
    if (existingCriteria && existingCriteria.validationType === 'integer') {
      return '1';
    }
    return 'any';
  }

  protected filterKeyInputEvents(event: KeyboardEvent, criterionName: string): void {
    const existingCriteria = this.getCriterionByName(criterionName);
    if (
      existingCriteria &&
      existingCriteria.validationType === 'integer' &&
      this.integerInvalidChar.includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  protected onValueInputKeyEvent(event: KeyboardEvent, criterionName: string): void {
    const newValue = (event.target as HTMLInputElement).value;
    if (event.key === SiFilteredSearchComponent.keys.backspace) {
      this.handleInputBackspace();
    } else if (
      event.key === SiFilteredSearchComponent.keys.separator &&
      !this.isMultiSelect(criterionName)
    ) {
      const trimLastCharacter = this.trimCharBasedOnType(criterionName);
      this.handleInputSeparator(trimLastCharacter);
    } else if (
      event.key === SiFilteredSearchComponent.keys.enter &&
      !this.isMultiSelect(criterionName)
    ) {
      this.handleInputSeparator(false);
    }

    this.previousEditingValue = newValue;
  }

  protected onValueInputClosed(): void {
    if (this.clearInputButton?.nativeElement === document.activeElement) {
      return;
    }
    // editCriterionIndex = -1 represents that the free text input is currently edited
    // all other numbers will represent the filtered pill, so we use -2 here.
    // Only apply when the focus does not shift on free text (i.e. "-1")
    if (this.editCriterionIndex !== FOCUS_INDEX_FREE_TEXT_INPUT_FIELD) {
      this.editCriterionIndex = FOCUS_INDEX_OUT_OF_COMPONENT;
    }
    this.cdRef.markForCheck();
  }

  protected onDateTimeKeyDown(event: KeyboardEvent): void {
    if (event.key === SiFilteredSearchComponent.keys.tab) {
      setTimeout(() => {
        this.onValueInputClosed();
      });
    }
  }

  protected onDateTimeKeyEvent(event: KeyboardEvent): void {
    const newValue = (event.target as HTMLInputElement).value;
    if (event.key === SiFilteredSearchComponent.keys.backspace) {
      this.handleInputBackspace();
    } else if (event.key === SiFilteredSearchComponent.keys.enter) {
      this.handleInputSeparator(false);
    }
    this.previousEditingValue = newValue;
  }

  protected onDatePickerClose(): void {
    if (!this.elementRef.nativeElement.contains(document.activeElement)) {
      this.editCriterionIndex = FOCUS_INDEX_OUT_OF_COMPONENT;
    }
  }

  protected selectDate(searchCriterion: CriterionIntern, date?: Date): void {
    // In case the user type an illegal date into the date input,
    // our directive emits a new undefined value and keeps
    if (!date && searchCriterion.dateValue) {
      date = new Date(searchCriterion.dateValue);
    }
    searchCriterion.dateValue = date;
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    if (this.doSearchOnInputChange) {
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }

  protected validateDateMinMaxCriteria(criterion: CriterionIntern): boolean {
    const dateConfig = this.getDatepickerConfig(criterion);
    const minDate = dateConfig?.minDate ?? false;
    const maxDate = dateConfig?.maxDate ?? false;
    const dateValue = criterion?.dateValue ?? false;
    return (
      (!minDate || (minDate && dateValue && dateValue >= minDate)) &&
      (!maxDate || (maxDate && dateValue && dateValue <= maxDate))
    );
  }

  protected focusFreeInputField(): void {
    this.editCriterionByIndex(FOCUS_INDEX_FREE_TEXT_INPUT_FIELD);
    this.allowedCriteriaCache = undefined;
  }

  protected editFreeInputField(): void {
    this.editCriterionIndex = FOCUS_INDEX_FREE_TEXT_INPUT_FIELD;
  }

  protected onCriterionValueInputChange(
    criterion: CriterionIntern,
    newValue: string | string[]
  ): void {
    const criterionConfig = this.getCriterionByName(criterion.name);
    if (!criterionConfig?.multiSelect) {
      criterion.value = newValue;
      criterion.value = this.getValue(criterion) as string;
      criterion.valueLabel = newValue as string;
      this.updateAndEmitSearchCriteria(this.internSearchCriteria);
      if (this.doSearchOnInputChange && newValue) {
        this.searchEmitQueue.next(this.searchCriteria);
      }
    }
  }

  protected onCriterionOperatorInputChange(criterion: CriterionIntern, newOperator: string): void {
    criterion.operator = newOperator;
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    // Also make sure event is not empty to prevent extra emissions. For example
    // when the user presses "Enter" or clicks to manually trigger the search
    if (this.doSearchOnInputChange && newOperator) {
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }

  protected onSearchTermChange(searchTerm: string): void {
    this.internSearchCriteria.value = searchTerm;
    // Ignore value changes when the filtered search doesn't allow free text values.
    // Background: In case the consumer has a bidirectional binding on the searchCriteria
    // input the new created update object will trigger another change detection cycle in
    // on push mode. When the current searchCriteria.value will be dropped we are not able
    // to indicate that there is no matching criteria (see noMatchingCriteriaText).
    if (!this.disableFreeTextSearch) {
      this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    }
    // Also make sure event is not empty to prevent extra emissions. For example
    // when the user presses "Enter" or clicks to manually trigger the search
    if (this.doSearchOnInputChange && searchTerm) {
      this.searchEmitQueue.next(this.searchCriteria);
    }
  }

  protected onTypeaheadInputChange(event: string): void {
    this.typeaheadInputChange.next(event);
    this.lastTypeaheadInput = event;
  }

  private isReadOnly = (): boolean => this.readonly || this.disabled;

  protected clearValueInput(index: number, criterion: CriterionIntern): void {
    if (this.isDateOrDateTime(criterion.name)) {
      criterion.dateValue = undefined;
      criterion.value = '';
    } else {
      if (this.isMultiSelect(criterion.name)) {
        this.valueInput.nativeElement.value = '';
        criterion.value = [];
      } else {
        criterion.value = '';
        criterion.valueLabel = '';
      }
    }
    this.editCriterionByIndex(index);
    this.updateAndEmitSearchCriteria(this.internSearchCriteria);
    this.doSearch.emit(this.searchCriteria);
  }

  private updateAndEmitSearchCriteria(internSearchCriteria: SearchCriteriaIntern): void {
    this.searchCriteria = this.convertToExternalModel(internSearchCriteria);
    this.searchCriteriaChange.next(this.searchCriteria);
  }
}
