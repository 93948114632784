@if (displayStyle === 'table') {
  <si-property-table
    #table
    [filteredProperties]="filteredProperties"
    [propertyTempl]="propertyTempl"
  />
}

@if (displayStyle === 'list') {
  <div [ngClass]="rowClass">
    @for (property of filteredProperties; track trackById(i, property); let i = $index) {
      <ng-container *ngTemplateOutlet="propertyTempl; context: { property: property, index: i }" />
    }
    @if (filter && filteredProperties && !filteredProperties.length) {
      <si-empty-state
        class="w-100"
        icon="element-search"
        heading="OBJECT_BROWSER.DIALOG_TEXT_LONG.FILTER_NO_RESULTS"
      />
    }
  </div>
}

<si-custom-action-pane
  #customActionPane
  [dateFormat]="dateFormat"
  [timeFormat]="timeFormat"
  (command)="command.emit($event)"
  (hidden)="refocus()"
/>

<ng-template #propertyTempl let-property="property" let-i="index">
  <si-property
    [property]="property"
    [valueState]="valueState[filteredIndexes[i]]"
    [allowValuesOutOfRange]="allowValuesOutOfRange"
    [colClass]="colClass"
    [displayStyle]="displayStyle"
    [dateFormat]="dateFormat"
    [timeFormat]="timeFormat"
    [customTemplate]="customTemplate"
    (propertySubmit)="submit($event)"
  />
</ng-template>
