import { Component, OnInit, ViewChild } from '@angular/core';
import { Customer, LoadingState, Partition, Permission, SiAppDataService, SiCustomerListComponent } from '@building-x/common-ui-ng';

import { ContextService } from '../bx-gms-mapper/state/context.service';
import { CustomerSelectorStateService } from './customer-selector-state.service';

@Component({
  selector: 'gms-customer-selector',
  templateUrl: './customer-selector.component.html'
})
export class CustomerSelectorComponent implements OnInit {

  public allowFilter = true;
  public showPartitionSubscriptionName = true;
  @ViewChild('customerList') public custList!: SiCustomerListComponent;

  constructor(
    private readonly csStateService: CustomerSelectorStateService,
    private readonly contextService: ContextService,
    private readonly siAppDataService: SiAppDataService) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      // extremly ugly!
      // however, the customer list maintains this variable internally as well. without this I cannot get rid of the bloody spinner!
      this.custList.isAllPartitionsLoaded = true;
      // this.siAppDataService.isAllPartitionsLoaded$.next(LoadingState.Complete);
      this.siAppDataService.isAllCustomersLoaded$.next(LoadingState.Complete);
    }, 100);
  }

  public get selectedCustomer(): Customer {
    return this.csStateService.selectedCustomer;
  }

  public get selectedPartitions(): Partition[] {
    return this.csStateService.selectedCustomerPartitions;
  }

  public get isAllPartitionsLoaded(): boolean {
    return this.csStateService.isAllPartitionsLoaded;
  }

  public get allCustomers(): Customer[] {
    return this.csStateService.allCustomers;
  }

  public get customerPartitions(): Partition[] {
    return this.csStateService.customerPartitions;
  }

  public get allPermissions(): Permission {
    return this.csStateService.allPermissions;
  }

  public onCustomerChange(event): void {
    this.csStateService.onCustomerChange(event);
    setTimeout(() => {
      // extremly ugly
      this.custList.changedPartitionSelectInput = {};
      this.custList.isAllPartitionsLoaded = true;
      // this.siAppDataService.isAllPartitionsLoaded$.next(LoadingState.Complete);
    }, 100);
  }

  public onSelectedCustomerPartitions(event): void {
    /* refresh when you change customer and apply, to avoid empty system browser issue */
    const isDifferentCustomer = this.selectedCustomer.id !== event.customer?.id;
    /* refresh when you select different partitions and apply, to avoid empty system browser issue
      (no common partition with the initial array) */
    const hasNoCommonElements = this.selectedCustomer.id === event.customer?.id && 
                            !this.checkForCommonPartitions(this.contextService.selectedPartitions, event.partitions);
    /* refresh when you add a different partition and apply, to avoid indentation issue
      (common partition and at least one different with the initial array) */
    const newPartitionAdded = this.selectedCustomer.id === event.customer?.id &&
                            this.newPartitionAdded(this.contextService.selectedPartitions, event.partitions);
    const shouldRefresh = isDifferentCustomer || hasNoCommonElements || newPartitionAdded;
    this.csStateService.onSelectedCustomerPartitions(event);
    if (shouldRefresh) {
      window.location.reload();
    }
  }

  private checkForCommonPartitions(initial: Partition[], updated: Partition[]): boolean {
    const initialIds = new Set(initial.map(partition => partition.id));
    return updated.some(partition => initialIds.has(partition.id));
  }

  private checkForUncommonPartitions(initial: Partition[], updated: Partition[]): boolean {
    const initialIds = new Set(initial.map(partition => partition.id));
    for (const item of updated) {
      if (!initialIds.has(item.id)) {
        return true;
      }
    }
    return false;
  }

  private newPartitionAdded(initial: Partition[], updated: Partition[]): boolean {
    const newPartitionAdded = this.checkForCommonPartitions(initial, updated) && this.checkForUncommonPartitions(initial, updated);
    return newPartitionAdded;
  }
}
