import { InstanceProperty } from '../processor/gms-symbol-instance-property';
import { GmsElementPropertyType, GmsPathElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { GmsElement } from './gms-element';

export class GmsPath extends GmsElement {

  private _data: string;
  public get Data(): string {
    return this._data;
  }
  public set Data(value: string) {

    if (this._data !== value) {
      this._data = value;

      this.NotifyPropertyChanged('Data');
    }
  }
  private _closed = false;
  public get Closed(): boolean {
    return this._closed;
  }
  public set Closed(value: boolean) {

    if (this._closed !== value) {
      this._closed = value;

      this.NotifyPropertyChanged('Closed');
    }
  }
  private _isFigureModified = false;
  public IsFigureModified(): boolean {
    return this._isFigureModified;
  }

  private _cornerOffset: number;
  public get CornerOffset(): number {
    return this._cornerOffset;
  }
  public set CornerOffset(value: number) {

    if (this._cornerOffset !== value) {
      this._cornerOffset = value;
      // NOTE:
      // Calculate and Update Path Geometry
      // this.NotifyPropertyChanged("Data");

      this.NotifyPropertyChanged('CornerOffset');
    }
  }

  constructor(type: GmsElementType = GmsElementType.Path) {
    super(type);
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('ShapeChanged');
  }

  // Specifically for path resizing when the parent resizes
  public ResizeScaleTransformation(): string {
    // original code
    // const scaleX: number = this.BoundingRectDesign.Width > 0 ? Math.abs(this.Width / this.BoundingRectDesign.Width) : 1;
    // const scaleY: number = this.BoundingRectDesign.Height > 0 ? Math.abs(this.Height / this.BoundingRectDesign.Height) : 1;
    // Note: changes applied to support coverage element's properties modifications.
    let scaleX = 1;
    if (this.BoundingRectDesign.Width > 0) {
      if (!this.IsWidthModified || !this.IsFigureModified()) {
        scaleX = Math.abs(this.Width / this.BoundingRectDesign.Width);
      }
    }
    let scaleY = 1;
    if (this.BoundingRectDesign.Height > 0) {
      if (!this.IsHeightModified || !this.IsFigureModified()) {
        scaleY = Math.abs(this.Height / this.BoundingRectDesign.Height);
      }
    }
    return 'scale(' + scaleX + ' ' + scaleY + ')';
  }

  public UpdateAdornerDimensions(): void {
    if (!!this.BoundingRectDesign) {
      const scaleWidth: number = this.BoundingRectDesign.Width > 0 ? Math.abs(this.Width / this.BoundingRectDesign.Width) : 1;
      const scaleHeight: number = this.BoundingRectDesign.Height > 0 ? Math.abs(this.Height / this.BoundingRectDesign.Height) : 1;
      this.AdornerWidth = this.BoundingRectDesign.Width * scaleWidth * this.ScaleX;
      this.AdornerHeight = this.BoundingRectDesign.Height * scaleHeight * this.ScaleY;
    }
  }

  /**
   * Iterate attributes and assign values to properties
   * @param node
   */
  public Deserialize(node: Node): void {
    let result: string;
    // Data
    if (node.hasChildNodes) {
      let nodes: NodeList = node.childNodes;
      for (let i = 0; i < nodes.length; i++) {
        const childItem: Node = nodes.item(i);
        let pathNode: Node = null;
        if (childItem.nodeName === 'path') {
          pathNode = childItem;
        } else if (SvgUtility.IsNodeScaleTransformationGroup(childItem)) {
          i = -1;
          nodes = childItem.childNodes;
          continue;
        }

        result = SvgUtility.GetAttributeValue(pathNode, GmsPathElementPropertyType.Data);
        if (result !== undefined) {
          this.Data = result;
          break;
        }
      }
    }

    if (this.Type !== GmsElementType.Line) {
      // Fill
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.Fill);
      if (result !== undefined) {
        this.Fill = result;
      }
      // FillOpacity
      result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.FillOpacity);
      if (result !== undefined) {
        this.FillOpacity = Number(result);
      }
    }

    // StrokeWidth
    result = SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.StrokeWidth);
    if (result !== undefined) {
      this.StrokeWidth = FormatHelper.StringToNumber(result); //  Number(result);
    }
    // StrokeOpacity
    result = SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.StrokeOpacity);
    if (result !== undefined) {
      this.StrokeOpacity = FormatHelper.StringToNumber(result); //  Number(result);
    }
    // Stroke
    result = SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.Stroke);
    if (result !== undefined) {
      this.Stroke = result;
    }
    // StrokeDashArray
    result = SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.StrokeDashArray);
    if (result !== undefined) {
      this.StrokeDashArray = result;
    }

    // Start Arrow and End Arrow
    if (SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.ArrowStartShape) !== undefined ||
        SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.ArrowEndShape) !== undefined) {
      const classTag = 'Class';
      for (let i = 0; i < node.childNodes.length; i++) {
        const currentNode: Node = node.childNodes[i];
        const isArrowNode: boolean = SvgUtility.GetAttributeValue(currentNode, classTag)
                                         === GmsPathElementPropertyType[GmsPathElementPropertyType.StartEndArrows];
        if (isArrowNode) {
          for (let j = 0; j < currentNode.childNodes.length; j++) {
            const pathNode: Node = currentNode.childNodes[j];
            const pathNodeClass: string = SvgUtility.GetAttributeValue(pathNode, classTag);
            if (pathNodeClass === GmsPathElementPropertyType[GmsPathElementPropertyType.StartArrow]) {
              this.StartArrow = SvgUtility.GetAttributeValue(pathNode, 'd');
            }
            if (pathNodeClass === GmsPathElementPropertyType[GmsPathElementPropertyType.EndArrow]) {
              this.EndArrow = SvgUtility.GetAttributeValue(pathNode, 'd');
            }
          }

          break;
        }
      }
    }
    super.Deserialize(node);

    this.DeserializeEvaluations(node);
  }

  public Apply(instanceProperties: InstanceProperty[]): void {
    if (this.InternalId !== null && instanceProperties !== undefined) {
      const instanceProperty: InstanceProperty =
                instanceProperties.find((inst: InstanceProperty) => inst.SourceId === this.InternalId);
      const elementProperties: Map<string, [string, string]> = instanceProperty !== undefined ?
        instanceProperty.ElementProperties : undefined;
      // property type and value
      let propertyValue: [string, string];
      if (elementProperties !== undefined && elementProperties.size > 0) {
        // Data = <any>"d",
        propertyValue = elementProperties.get(GmsPathElementPropertyType.Data.toString());
        if (propertyValue !== undefined) {
          this._isFigureModified = true;
          this.Data = propertyValue[1];
          elementProperties.delete(GmsPathElementPropertyType.Data.toString());
          if (elementProperties.size === 0) {
            return;
          }
          // Start Arrow and End Arrow
          propertyValue = elementProperties.get(GmsPathElementPropertyType.StartArrow.toString());
          if (propertyValue !== undefined) {
            this.StartArrow = propertyValue[1];// SvgUtility.GetAttributeValue(pathNode, "d");
          }
          elementProperties.delete(GmsPathElementPropertyType.StartArrow.toString());
          if (elementProperties.size === 0) {
            return;
          }
          propertyValue = elementProperties.get(GmsPathElementPropertyType.EndArrow.toString());
          if (propertyValue !== undefined) {
            this.EndArrow = propertyValue[1];
          }
          elementProperties.delete(GmsPathElementPropertyType.EndArrow.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        super.Apply(instanceProperties);
      }
    }
  }

  public CopyFrom(element: GmsPath): void {
    this.IsCopying = true;

    this.Data = element.Data;

    if (this.Type !== GmsElementType.Line) {
      // Fill
      this.Fill = element.Fill;

      // FillOpacity
      this.FillOpacity = element.FillOpacity;

      // CoverageAreaReference
      this.CoverageAreaReference = element.CoverageAreaReference;
    }

    // StrokeWidth
    this.StrokeWidth = element.StrokeWidth;

    // StrokeOpacity
    this.StrokeOpacity = element.StrokeOpacity;

    // Stroke
    this.Stroke = element.Stroke;

    // StrokeDashArray
    this.StrokeDashArray = element.StrokeDashArray;

    // Start Arrow and End Arrow
    this.StartArrow = element.StartArrow;
    this.EndArrow = element.EndArrow;

    super.CopyFrom(element);

    this.CopyEvaluations(element);

    this.IsCopying = false;
  }
}
