import { Injectable } from '@angular/core';
import { OwnershipServiceBase, TraceModules, WsiOwner, WsiOwnership } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject, throwError } from 'rxjs';

@Injectable()
export class OwnershipBxSubstituteService implements OwnershipServiceBase {

  private readonly _ownershipNotification: Subject<void> = new Subject();

  constructor(
    private readonly traceService: TraceService
  ) { 
    this.traceService.info(TraceModules.ownership, 'OwnershipBxSubstituteService created.');
  }

  public fetchOwnership(): Observable<WsiOwner> {
    return throwError(() => new Error('OwnershipBxSubstituteService.fetchOwnership(): Not Implemented!'));
  }

  public updateOwnership(owner: WsiOwnership): Observable<WsiOwnership> {
    return throwError(() => new Error('OwnershipBxSubstituteService.updateOwnership(): Not Implemented!'));
  }

  public subscribeOwnership(): Observable<boolean> {
    this.traceService.info(TraceModules.ownership, 'OwnershipBxSubstituteService.subscribeOwnership() called.');

    return scheduled([true], asapScheduler);
  }

  public unsubscribeOwnership(): Observable<boolean> {
    this.traceService.info(TraceModules.ownership, 'OwnershipBxSubstituteService.unsubscribeOwnership() called.');

    return scheduled([true], asapScheduler);
  }

  public ownershipNotification(): Observable<void> {
    return this._ownershipNotification.asObservable();
  }
}
