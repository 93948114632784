@if (propertyList?.length) {
  <div class="si-command-list card-body">
    <si-property-list
      #propertyListViewer
      siResponsiveContainer
      [properties]="propertyList"
      (submitted)="propertyChanged.emit($any($event))"
      (command)="command.emit($event)"
    />
  </div>
}
@if (!propertyList?.length) {
  <div>
    <si-empty-state
      icon="element-physical-output"
      heading="SCENE_EDITOR.DIALOG_TEXT_LONG.NO_DATA_POINT_HEADER"
      content="SCENE_EDITOR.DIALOG_TEXT_LONG.NO_DATA_POINT_CONTENT"
    >
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="readOnly"
        (click)="addingDataPoints.emit()"
      >
        {{ 'SCENE_EDITOR.TEXT_SHORT.ADD_DATAPOINT' | translate }}
      </button>
    </si-empty-state>
  </div>
}
