import { SelectOption } from "@simpl/element-ng";
import { DccDataType, PropertyMapper } from "src/app/bx-gms-mapper/properties/property-mapper";
import { PointBxWithValue } from "src/app/bx-services/point/point.model";

const binaryValOrder: Map<number, string> = new Map([
  [0, "false"],
  [1, "true"]
]);

export class PointHelper {
  public static getStatesOptions(point: PointBxWithValue): SelectOption[] {
    const currentType = PropertyMapper.getDccType(point.attributes);
    // get type to distinguish multistate (ExtendedEnum) from binary (setting true or false from binaryValOrder)

    if (point?.attributes?.enum) {
      const entries = Object.entries(point?.attributes?.enum);
      const options: SelectOption[] = [];
      entries.forEach(([key, value], index) => {
        options.push({
          id: currentType === DccDataType.ExtendedEnum ? key : binaryValOrder.get(index),
          title: value?.label
        });
      });
      return options;
    }

    if (currentType !== DccDataType.ExtendedEnum) {
      return [
        { id: "true", title: "True" },
        { id: "false", title: "False" }
      ];
    }

    return [];
  }
}