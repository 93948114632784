import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { GmsCommandControlSpinner } from '../elements/gms-commandcontrol-spinner';
import { GmsElementType } from '../types/gms-element-types';
import { GmsAnimatedGifComponent } from './gms-animated-gif.component';
import { GmsElementComponent } from './gms-element.component';
import { GmsEllipseComponent } from './gms-ellipse.component';
import { GmsImageComponent } from './gms-image.component';
import { GmsLineComponent } from './gms-line.component';
import { GmsPathComponent } from './gms-path.component';
import { GmsPolygonComponent } from './gms-polygon.component';
import { GmsRectangleComponent } from './gms-rectangle.component';
import { GmsTextComponent } from './gms-text.component';
import { GmsXpsComponent } from './gms-xps.component';

@Component({
  selector: '[gms-spinner]',
  template: `<svg:g
                    class="noptrevents"
                    [attr.visibility]="element.GetVisible()"
                    [attr.opacity]="element.Opacity"
                    [attr.transform]="element.GetTransformations()" [attr.id]="element.Id"
                    [attr.filter]="element.Filter?.Url"
                    [attr.clip-path]="element.ClipPathUrl"
                    (mouseenter)="OnMouseEnter($event)"
                    >
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <rect
                        id="{{element.Id}}"
                        class="noptrevents"
                        [attr.width]="element.Width"
                        [attr.height]="element.Height"
                        [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0"
                        />
                    <svg:g
                        [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                        [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                        *ngFor="let item of element.children;">
                    <!-- List all possible components that can be in a group -->
                        <ng-container *ngIf="item.Type === elementType.SymbolInstance">
                          <ng-container *ngTemplateOutlet="item.SymbolTemplate; context: {model: item}"/>
                        </ng-container>
                        <ng-container *ngIf="item.Type === elementType.Group">
                          <ng-container *ngTemplateOutlet="item.GroupTemplate; context: {model: item}"/>
                        </ng-container>
                        <svg:g *ngIf="item.Type === elementType.Ellipse" gms-ellipse [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Rectangle" gms-rectangle [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Path" gms-path [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Line" gms-line [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Polygon" gms-polygon [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Text" gms-text [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Image" gms-image [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.Xps" gms-xps [element]="item"/>
                        <svg:g *ngIf="item.Type === elementType.AnimatedGif" gms-animated-gif [element]="item"/>
                    </svg:g>
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [GmsRectangleComponent, GmsEllipseComponent, GmsPathComponent, GmsLineComponent, GmsPolygonComponent,
    GmsTextComponent, GmsImageComponent, GmsXpsComponent, GmsAnimatedGifComponent]
})
export class GmsSpinnerComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsCommandControlSpinner = null;
  public elementType: any = GmsElementType; // Store a reference to the enum, so that we can compare in the template

  public OnMouseClick(event: any): void {
    //
  }
  public OnTouchEnd(e: any): void {
    this.OnMouseClick(e);
    e.preventDefault();
  }
}
