import { CdkMenuTrigger } from "@angular/cdk/menu";
import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from "@gms-flex/core";
import { isNullOrUndefined } from "@gms-flex/services-common";
import { SiMenuModule, TreeItem } from "@simpl/element-ng";

import { SelectionChangedService } from "../services/selection-changed.service";

@Component({
  selector: '[gms-easy-navigation-bar-menu]',
  standalone: true,
  imports: [
    CommonModule,
    SiMenuModule,
    CdkMenuTrigger
  ],
  template: `
    <div class="si-menu-container">
      <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ treeItems: items }" />
    </div>
    <ng-template #menuTemplate let-treeItems="treeItems" let-parentHasScrollbar="parentHasScrollbar">
      <si-menu
        class="si-menu"
        #siMenuElementRef
        [ngClass]="{'si-menu-parent-with-scrollbar': parentHasScrollbar, 'si-menu-parent-no-scrollbar': !parentHasScrollbar}">
        <ng-container *ngFor="let item of treeItems">
          <si-menu-item #siMenuItem *ngIf="!isDividerItem(item)"
                        (click)="onSelectionChanged(item)"
                        [cdkMenuTriggerFor]="hasChildItems(item)  ? menuTemplate : null"
                        [cdkMenuTriggerData]="{ treeItems: item.children, parentHasScrollbar: calculateHasScrollbar(siMenuElementRef) }">{{ item.label }}
          </si-menu-item>
          <si-menu-divider *ngIf="isDividerItem(item)" />
        </ng-container>
      </si-menu>
    </ng-template>
  `,
  styleUrl: './gms-easy-navigation-bar-menu.scss'
})
export class GmsEasyNavigationBarMenuComponent implements OnInit {
  @Input() public treeItem: any;
  @Input() public parentHasScrollbar = false;
  @ViewChild('siMenuElementRef') public siMenuElementRef: ElementRef;
  public items: any[] = [];

  public isDividerItem(item: any): boolean {
    return !isNullOrUndefined(item) && item.label === '-';
  }

  constructor(public selectionChangedService: SelectionChangedService) {
  }

  public calculateHasScrollbar(element: HTMLElement): boolean {
    let hasScrollbar = false;
    if (!isNullOrUndefined(element)) {
      const scrollbarWidth: number = element.offsetWidth - element.clientWidth;
      hasScrollbar = scrollbarWidth >= 5;
    }

    return hasScrollbar;
  }

  public onSelectionChanged(treeItem: TreeItem): void {
    if (treeItem?.children?.length === 0 || isNullOrUndefined(treeItem?.children)) {
      this?.selectionChangedService?.onSelectionChanged?.next(treeItem);
    }
  }

  public hasChildItems(item: TreeItem): boolean {
    return item?.children?.length > 0;
  }

  public ngOnInit(): void {
    this.items = this?.treeItem?.children;
  }
}
