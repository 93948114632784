import { Directive } from '@angular/core';

@Directive({
  // violating eslint rule, to a have clean API
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'si-menu-divider',
  standalone: true,
  host: {
    class: 'd-block dropdown-divider'
  }
})
export class SiMenuDividerDirective {}
