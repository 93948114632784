import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'si-number-input',
  templateUrl: './si-number-input.component.html',
  styleUrl: './si-number-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiNumberInputComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiNumberInputComponent,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiNumberInputComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SiNumberInputComponent
  implements OnChanges, ControlValueAccessor, Validator, SiFormItemControl
{
  private static idCounter = 0;

  /** The min. value for HTML input */
  @Input() min?: number;
  /** The max. value for HTML input */
  @Input() max?: number;
  /** The step size for HTML input */
  @Input() step = 1;
  /** The value */
  @Input() value?: number;
  /** Optional unit label */
  @Input() unit?: string;
  /** Show increment/decrement buttons? */
  @Input({ transform: booleanAttribute }) showButtons = true;
  /** The aria-label passed to the input */
  @Input('aria-label') ariaLabel?: string;
  /** ID that is set on the input, e.g. for `<label for="...">` */
  @Input() inputId = `__si-number-input-${SiNumberInputComponent.idCounter++}`;

  get id(): string {
    return this.inputId;
  }

  @Input({ transform: booleanAttribute }) @HostBinding('class.disabled') disabled = false;
  @Input({ transform: booleanAttribute }) @HostBinding('class.readonly') readonly = false;

  /**
   * The placeholder for input field.
   */
  @Input() placeholder?: string;

  @Output() readonly valueChange = new EventEmitter<number>();

  protected internalValue?: number;
  protected canInc = true;
  protected canDec = true;
  protected onTouched: () => void = () => {};
  protected onChange: (val: any) => void = () => {};
  protected validator: ValidatorFn | null = null;

  private autoUpdate$ = timer(400, 80);
  private autoUpdateSubs?: Subscription;
  private changeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.internalValue = this.value;
    }
    if (changes.min || changes.max) {
      this.validator = Validators.compose([
        this.min != null ? Validators.min(this.min) : null,
        this.max != null ? Validators.max(this.max) : null
      ])!;
    }
    this.updateValue(this.internalValue);
  }

  /** @internal */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @internal */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** @internal */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @internal */
  writeValue(value: number | undefined): void {
    this.updateValue(value);
    this.changeDetectorRef.markForCheck();
  }

  /** @internal */
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validator ? this.validator(control) : null;
  }

  protected input(input: Event): void {
    this.modelChanged(+(input.target as HTMLInputElement).value);
  }

  protected modelChanged(value: number | undefined): void {
    this.updateValue(value);
    this.onChange(value);
    this.valueChange.emit(value);
  }

  protected autoUpdateStart(event: Event, isIncrement: boolean): void {
    const mouseButton = (event as MouseEvent).button;
    if (mouseButton) {
      return;
    }

    this.onTouched();
    event.preventDefault();
    const trigger = isIncrement ? () => this.increment() : () => this.decrement();
    this.autoUpdateSubs?.unsubscribe();
    this.autoUpdateSubs = this.autoUpdate$.subscribe(trigger);
    trigger();
  }

  protected autoUpdateStop(): void {
    this.autoUpdateSubs?.unsubscribe();
    this.autoUpdateSubs = undefined;
  }

  private updateValue(value: number | undefined): void {
    this.internalValue = value;

    this.canInc =
      this.max == null || this.internalValue == null || this.internalValue + this.step <= this.max;
    this.canDec =
      this.min == null || this.internalValue == null || this.internalValue - this.step >= this.min;
  }

  private roundToStepPrecision(value: number): number {
    const factor = 1 / this.step;
    if (factor > 1) {
      return Math.round(value * factor) / factor;
    }
    return Math.round(value / this.step) * this.step;
  }

  private decrement(): void {
    this.internalValue =
      this.internalValue == null
        ? this.max ?? this.min ?? 0
        : this.roundToStepPrecision(this.internalValue - this.step);
    this.modelChanged(this.internalValue);
    if (!this.canDec) {
      this.autoUpdateStop();
    }
  }

  private increment(): void {
    this.internalValue =
      this.internalValue == null
        ? this.min ?? this.max ?? 0
        : this.roundToStepPrecision(this.internalValue + this.step);
    this.modelChanged(this.internalValue);
    if (!this.canInc) {
      this.autoUpdateStop();
    }
  }
}
