import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, EMPTY, expand, map, Observable, reduce, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { EventBx, EventCategoryBx, EventResponsePaged } from './events-proxy.model';

const eventsUrl = `${environment.bxPlatform.alarmVerticalApiUrl}/v1/organizations`

@Injectable({
  providedIn: 'root'
})
export class EventsProxyService {

  private readonly supportedCategories: Map<EventCategoryBx, EventCategoryBx> = new Map<EventCategoryBx, EventCategoryBx>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.supportedCategories.set(EventCategoryBx.Emergency, EventCategoryBx.Emergency);
    this.supportedCategories.set(EventCategoryBx.LifeSafety, EventCategoryBx.LifeSafety);
    this.supportedCategories.set(EventCategoryBx.Security, EventCategoryBx.Security);
    this.supportedCategories.set(EventCategoryBx.High, EventCategoryBx.High);
    this.supportedCategories.set(EventCategoryBx.Medium, EventCategoryBx.Medium);
    this.supportedCategories.set(EventCategoryBx.Low, EventCategoryBx.Low);
    this.supportedCategories.set(EventCategoryBx.Fault, EventCategoryBx.Fault);
    this.supportedCategories.set(EventCategoryBx.Trouble, EventCategoryBx.Trouble);
    this.supportedCategories.set(EventCategoryBx.Supervisory, EventCategoryBx.Supervisory);
    this.supportedCategories.set(EventCategoryBx.Status, EventCategoryBx.Status);
    this.supportedCategories.set(EventCategoryBx.Unknown, EventCategoryBx.Unknown);

    this.traceService.info(TraceModules.bxServicesEvents, 'EventsProxyService created.');
  }

  public getEventsOfBuilding(partitionId: string, buildingId: string): Observable<EventBx[]> {
    this.traceService.debug(TraceModules.bxServicesEvents,
      `EventsProxyService.getEventsOfBuilding() called: partitionId: ${partitionId}, buildingId: ${buildingId}`);

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${eventsUrl}/${partitionId}/buildings/${buildingId}/events`;
    let params: HttpParams = new HttpParams();
    params = params.set('minPageSize', 1000);

    return this.httpClient.get<EventResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.lastEvaluatedKey) {
          params = params.set('lastEvaluatedKey', response.body?.lastEvaluatedKey.trim());
          return this.httpClient.get<EventResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => response.body?.events ? response.body.events : []),
      reduce((accumulator, current) => [...accumulator, ...current]),
      tap(events => this.mapCategories(events)),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEventsOfBuilding()')));
  }

  public getEventsOfBuildingPaged(partitionId: string, buildingId: string, pageSize: number): Observable<EventResponsePaged> {
    this.traceService.debug(TraceModules.bxServicesEvents,
      `EventsProxyService.getEventsOfBuildingPaged() called: partitionId: ${partitionId}, buildingId: ${buildingId}`);
    if (pageSize > 1000) {
      pageSize = 1000; // max allowed size
    }
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${eventsUrl}/${partitionId}/buildings/${buildingId}/events`;
    let params: HttpParams = new HttpParams();
    params = params.set('minPageSize', pageSize);

    return this.httpClient.get(url, { headers, params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getEventsOfBuildingPaged()')),
      tap(eventResponse => this.mapCategories(eventResponse.events)),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEventsOfBuildingPaged()')));
  }

  private mapCategories(events: EventBx[]): void {
    events.forEach(event => {
      if (!this.supportedCategories.has(event.category)) {
        event.category = EventCategoryBx.Unknown;
      }
    });
  }
}
