import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import { SiLinkDirective } from '@simpl/element-ng/link';
import {
  SI_NAVBAR_WITH_DROPDOWNS,
  SiNavbarDropdownComponent,
  SiNavbarDropdownItemsFactoryComponent,
  SiNavbarDropdownTriggerDirective
} from '@simpl/element-ng/navbar-dropdown';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiNavbarPrimaryComponent } from '../si-navbar-primary/si-navbar-primary.component';

@Component({
  selector: 'si-navbar-item',
  templateUrl: './si-navbar-item.component.html',
  standalone: true,
  imports: [
    SiLinkDirective,
    SiTranslateModule,
    SiNavbarDropdownTriggerDirective,
    SiNavbarDropdownItemsFactoryComponent,
    NgClass,
    NgTemplateOutlet,
    SiNavbarDropdownComponent
  ],
  host: {
    class: 'nav-item'
  }
})
export class SiNavbarItemComponent implements DoCheck {
  /**
   * MenuItem to display in the navbar.
   */
  @Input({ required: true }) item!: MenuItem;

  /**
   * Is the item a quick action displayed on the end (right in LTR) side
   */
  @Input({ transform: booleanAttribute }) @HostBinding('class.quick-action') quickAction?: boolean;

  @ViewChild(SiNavbarDropdownTriggerDirective) trigger?: SiNavbarDropdownTriggerDirective;

  protected active = false;
  protected elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private parent = inject<SiNavbarPrimaryComponent>(SI_NAVBAR_WITH_DROPDOWNS);

  private hasBadge = false;

  ngDoCheck(): void {
    const newHasBadge = !!(this.item.badge ?? this.item.badgeDot);
    if (this.quickAction && this.hasBadge !== newHasBadge) {
      this.hasBadge = newHasBadge;
      if (this.hasBadge) {
        this.parent.quickActionBadgeCount.update(value => value + 1);
      } else {
        this.parent.quickActionBadgeCount.update(value => value - 1);
      }
    }
  }

  @HostListener('click')
  protected click(): void {
    if (!this.trigger) {
      this.parent.onDropdownItemTriggered!();
    }
  }
}
