<!-- <tabset *ngIf="!mobileNavigate" #tabset class="hfw-pane-tab" (mousedown)="tryToSwitch($event)"> -->
  <!-- (deselect)="tab.active = false" -->
   <!-- <tab *ngFor="let tab of tabs"
       [active]="tab.active"
       [customClass]="tab.customClass">
       <ng-template tabHeading>
        <div class="hfw-pane-tab-header">
          {{tab.tabTitle}}
        </div>
      </ng-template>
  </tab>
</tabset> -->
<!-- mobile navigation view -->
<!-- <hfw-mobile-navset *ngIf="mobileNavigate" headerOnly="true">
   <hfwMobileNav *ngFor="let tab of tabs"
       [heading]="tab.tabTitle"
       [active]="tab.tabId === selectedTabId"
       (selectTab)="selectTab(tab)"
       (deselect)="tab.active = false"
       [customClass]="tab.customClass">
  </hfwMobileNav>
</hfw-mobile-navset> -->

<si-tabset [(selectedTabIndex)]="selectedIndex" (mousedown)="tryToSwitch($event)" style="position: relative;" #tabset>
  <si-tab *ngFor="let tab of tabs;trackBy: trackByIndex" [hidden]="tab.hidden" heading="{{ tab.tabTitle }}" />
</si-tabset>
