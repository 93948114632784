import { NgModule } from '@angular/core';

import { SiStepperStepComponent } from './si-stepper-step.component';
import { SiStepperComponent } from './si-stepper.component';

@NgModule({
  exports: [SiStepperComponent, SiStepperStepComponent],
  imports: [SiStepperComponent, SiStepperStepComponent]
})
export class SiStepperModule {}
