import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ToastStateName } from '@simpl/element-ng';
import { Subscription } from 'rxjs';

import { TraceChannel } from '../common/trace-channel';
import { GmsButtons } from '../elements/gms-buttons';
import { GmsElement } from '../elements/gms-element';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsCommandResult } from '../processor/command-view-model/gms-command-vm';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementType } from '../types/gms-element-types';

@Component({
  selector: '[gms-buttons]',
  template: `
      <svg>
          <g #groupSvgElement [style.display]="ButtonVisibility" [attr.transform]="inverseTransform">
              <g id="translate-center" stroke-opacity="1" stroke="#006064" stroke-width="1" fill="white">
                  <g #cancelButton (click)="onCancel()" class="allptrevents">
                      <circle cx="12" cy="12" r="11.5" />
                      <line x1="8" y1="8" x2="16" y2="16" />
                      <line x1="16" y1="8" x2="8" y2="16" />
                  </g>
                  <g #confirmButton *ngIf="ConfirmButtonEnabled" class="allptrevents" transform="translate(30)" (click)="onConfirm($event)">
                      <circle cx="12" cy="12" r="11.5" />
                      <polyline points="7 12 10 15 17 8" />
                  </g>
              </g>
          </g>
      </svg>`,
  styles: [`.allptrevents {
      pointer-events: all;
  }`,
  `#translate-center {
      transform: translateY(5px);
  }`]
})

export class GmsButtonsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('groupSvgElement', { static: false }) public groupSvgElement: ElementRef;
  @ViewChild('cancelButton', { static: false }) public cancelButton: ElementRef;
  @ViewChild('confirmButton', { static: false }) public confirmButton: ElementRef;
  @Input() public graphic: GmsGraphic;
  @Input() public buttonElement: GmsButtons;
  public inverseTransform: string;
  public linearTransform: string;
  public updateButtonSubscription: Subscription;
  public controlElement: any;
  public DOMElement: SVGGraphicsElement;

  constructor(public cdRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.controlElement = this.buttonElement.CommandElement;
    this.DOMElement = document.getElementById(this.buttonElement.CommandElement.Id) as any as SVGGraphicsElement;
    this.updateButtonSubscription = this.graphic.updateButtonTransform.subscribe(() => this.setButtonPosition());
    this.setButtonPosition();
  }

  public ngAfterViewChecked(): void {
    this.setButtonPosition();
  }

  public ngOnDestroy(): void {
    this.updateButtonSubscription.unsubscribe();
    this.buttonElement.Destroy();
    this.cdRef.detach();
  }

  public get ButtonVisibility(): string {
    let isModified: boolean = (this.buttonElement.CommandElement?.CommandVM !== undefined && this.buttonElement.CommandElement?.CommandVM?.isModified);
    isModified = isModified && this.IsButtonControlGrouped();

    const visibility: string = isModified ? null : 'none';
    return visibility;
  }

  public get ButtonComponentWidth(): number {
    return this.ConfirmButtonEnabled ? 90 : 52;
  }

  public get ButtonComponentHeight(): number {
    return 42;
  }

  public get ConfirmButtonEnabled(): boolean {
    if (this.controlElement.ControlType === GmsCommandControlType.Slider || this.controlElement.ControlType === GmsCommandControlType.Spinner ||
      this.controlElement.ControlType === GmsCommandControlType.Rotator) {
      return false;
    }
    return this.isValidCommandVM()
      && !this.buttonElement?.CommandElement?.CommandVM?.CommandButtonExist
      && this.buttonElement?.CommandElement?.IsTriggerEnabled();
  }

  public IsButtonControlGrouped(): boolean {
    if (this.controlElement.Parent === undefined) {
      return false;
    }

    if (this.controlElement.IsControlGrouped) {
      const isCommandTriggerGroup: boolean = this.controlElement.Type === GmsElementType.Group && this.controlElement.IsCommandGroupEnabled;
      return isCommandTriggerGroup;
    }

    return true;
  }

  public hideButtons(): void {
    if (this.isValidCommandVM() && !this.isSuspendedCommandVM()) {
      this.buttonElement.CommandElement.CommandVM.isModified = false;
    }
  }

  public showButtons(): void {
    if (this.isValidCommandVM()) {
      this.buttonElement.CommandElement.CommandVM.isModified = true;
    }
  }

  @HostListener('window:keydown.escape')
  public onCancel(): void {
    if (this.isValidCommandVM()) { // spinner has a delayed execute command option
      this.buttonElement.CommandElement.CommandVM.isCommandExecuteCancel = true;
    }
    this.hideButtons();
    const children: GmsElement[] = this.retrieveChildren();
    if (children.length > 0) {
      for (const child of children) {
        if (child.buttons !== undefined) {
          child.buttons.cancelSubscription.next();
        }
      }
    } else {
      this.buttonElement.cancelSubscription.next();
    }
  }

  @HostListener('keydown.enter', ['$event'])
  public onConfirm(event: Event): void {
    event.preventDefault();

    if (this.isValidCommandVM()) {
      if (!this.buttonElement.CommandElement.CommandVM.IsInputValid(this.buttonElement.CommandElement.locale)) {
        this.showToastNotification(this.buttonElement.CommandElement, 'warning', 'Command Execute failed: Invalid input',
          this.buttonElement.CommandElement.CommandVM.ErrorDescription);
        return;
      }
      this.buttonElement.CommandElement.CommandVM.isCommandExecuteCancel = false;
    }

    const children: GmsElement[] = this.retrieveChildren();

    if (children.length > 0) {
      this.iterateChildElements(this.buttonElement.CommandElement);
    } else {
      this.buttonElement.confirmSubscription.next();
    }

    this.hideButtons();
  }

  public iterateChildElements(element: GmsElement): void {
    if (element.CanExecuteCommand) {
      this.ProcessCommand(element);
      return;
    }

    for (const child of element.children) {
      if (child.CanExecuteCommand) {
        this.ProcessCommand(child);
        return;
      }

      if (child.hasChildren && child.children.length > 0) {
        this.iterateChildElements(child);
      }
    }
  }

  public retrieveChildren(): GmsElement[] {
    const children: GmsElement[] = [];
    const commandElement: any = this.buttonElement.CommandElement;
    if (commandElement.IsCommandGroupEnabled !== undefined) {
      for (const child of commandElement.children) {
        children.push(child);
      }
    }

    return children;
  }

  public setButtonPosition(): string {
    if (this.buttonElement === undefined || this.DOMElement === null || this.groupSvgElement === undefined) {
      return;
    }

    this.inverseTransform = `matrix(1, 0, 0, 1, 0, 0)`;
    this.cdRef.detectChanges();

    const matrix: SVGMatrix = this.DOMElement.getCTM();
    const scale: number = matrix.a;
    this.inverseTransform = `matrix(1, ${matrix.b}, ${matrix.c}, 1, 0, 0)`;

    const elementRect: ClientRect = this.DOMElement.getBoundingClientRect();
    const buttonsRect: ClientRect = this.groupSvgElement.nativeElement.getBoundingClientRect();

    if (elementRect === undefined || buttonsRect === undefined) {
      return;
    }

    const elementRectWidth: number = this.buttonElement.CommandElement.Width * scale;
    const elementRectRight: number = elementRect.left + elementRectWidth;
    const buttonRectRight: number = buttonsRect.left + buttonsRect.width;
    const buttonRectBottom: number = buttonsRect.top + buttonsRect.height;

    const leftOffset: number = elementRectRight - buttonRectRight;
    const topOffset: number = elementRect.top - buttonRectBottom - 5;
    const retMat = `translate(${leftOffset}, ${topOffset})`;

    this.inverseTransform += retMat;
    this.cdRef.detectChanges();
  }

  private showToastNotification(element: GmsElement, state: ToastStateName, title: string, message: string): void {
    if (element?.ToastNotificationService !== undefined) {
      element.ToastNotificationService.queueToastNotification(state, title, message);
    }
  }
  private _subscription: Subscription;
  /**
   * NOTE: This function was originally implemented in the GmsElementComponent
   * and cannot be accessed from the GmsButtonComponent, so it is implemented
   * as a duplicate.
   **/
  private ProcessCommand(desiredElement: GmsElement): void {
    const commandExecuteFailed = 'Command Execute failed';
    if (desiredElement.CommandVM === null) {
      this.showToastNotification(desiredElement, 'warning', commandExecuteFailed, 'Command View Model is NULL');
      return;
    }

    desiredElement.CommandVM.isCommandExecuteDone = false;
    if (desiredElement.CommandVM.IsInputValid(desiredElement.locale)) {
      desiredElement.CommandVM.isCommandExecuteDone = false;
      this._subscription = desiredElement.ExecuteCommand().subscribe(
        (res: GmsCommandResult) => {
          this._subscription.unsubscribe();
          if (res.Success) {
            desiredElement.CommandVM.isCommandExecuteDone = true;
            desiredElement.CommandVM.isModified = false;
            const msg = `Command executed with success: ${desiredElement.CommandVM.Key}, Name = ${desiredElement.CommandVM.Name}`;
            desiredElement.TraceService.info(TraceChannel.Commanding, msg);
          } else {
            desiredElement.CommandVM.isCommandExecuteDone = true;
            desiredElement.CommandVM.isModified = false;
            this.showToastNotification(desiredElement, 'warning', commandExecuteFailed, res.Error);
          }
        },
        error => {
          this._subscription.unsubscribe();
          desiredElement.CommandVM.isCommandExecuteDone = true;
          desiredElement.CommandVM.isModified = false;
          this.showToastNotification(desiredElement, 'warning', commandExecuteFailed, error.message);
        }
      );
    } else {
      this.showToastNotification(desiredElement, 'warning', 'Command Execute failed: Invalid input',
        desiredElement.CommandVM.ErrorDescription);
    }
  }

  private isValidCommandVM(): boolean {
    return this.buttonElement !== undefined
      && this.buttonElement.CommandElement !== undefined
      && this.buttonElement.CommandElement.CommandVM !== undefined;
  }
  private isSuspendedCommandVM(): boolean {
    return this.buttonElement !== undefined
      && this.buttonElement.CommandElement !== undefined
      && this.buttonElement.CommandElement.CommandVM.Suspended;
  }
}
