import { EntityTypeEquipment } from '../shared/base.model';
import { EquipmentBx } from './equipment-proxy.model';

export class Equipment {

  private _description: string;

  constructor(private readonly equipment: EquipmentBx) {
    this.initDescription();
  }

  public get id(): string {
    return this.equipment.id;
  }

  public get type(): EntityTypeEquipment {
    return this.equipment.type;
  }

  public get name(): string {
    return this.equipment.attributes.name;
  }

  public get description(): string {
    return this._description;
  }

  public get hasLocation(): boolean {
    return (this.equipment.relationships.hasLocation !== undefined);
  }

  public get locationId(): string | undefined {
    return this.equipment.relationships.hasLocation ? this.equipment.relationships.hasLocation.data.id : undefined;
  }

  public get hasEquipmentType(): boolean {
    return (this.equipment.relationships.hasEquipmentType !== undefined);
  }

  public get equipmentTypeId(): string | undefined {
    return this.equipment.relationships.hasEquipmentType ? this.equipment.relationships.hasEquipmentType.data.id : undefined;
  }

  private initDescription(): void {
    // prio 1: Type
    this._description = `Equipment (type)`;
  }

}
