import { SvgUtility } from '../utilities/parser';

export class InstanceProperty {

  private readonly _sourceId: string = null;
  public get SourceId(): string {
    return this._sourceId;
  }

  // Map<PropertyName, [PropertyType, PropertyValue]>
  // Property Name="ScaleFactor" Type="Double" Value="2"
  private readonly _elementProperties: Map<string, [string, string]> = new Map<string, [string, string]>();

  public get ElementProperties(): Map<string, [string, string]> {
    return this._elementProperties;
  }

  public static Deserialize(instanceNode: Node): InstanceProperty {
    let instanceProperty: InstanceProperty = null;
    if (instanceNode !== undefined) {
      const sourceId: string = SvgUtility.GetAttributeValue(instanceNode, 'SourceId');
      if (sourceId !== undefined) {
        let propertyName: string;
        let typeString: string;
        let valueString: string;
        for (let i = 0; i < instanceNode.childNodes.length; i++) {
          const propertyNode: Node = instanceNode.childNodes[i];
          if (propertyNode.nodeName === 'Property') {
            propertyName = SvgUtility.GetAttributeValue(propertyNode, 'Name');
            if (propertyName !== undefined) {
              typeString = SvgUtility.GetAttributeValue(propertyNode, 'Type');
              if (typeString !== undefined) {
                valueString = SvgUtility.GetAttributeValue(propertyNode, 'Value');
                if (valueString !== undefined) {
                  if (instanceProperty === null) {
                    instanceProperty = new InstanceProperty(sourceId);
                  }
                  instanceProperty.ElementProperties.set(propertyName, [typeString, valueString]);
                }
              }
            }
          }
        }
      }
    }
    return instanceProperty;
  }

  private constructor(sourceId: string) {
    this._sourceId = sourceId;
  }
}
