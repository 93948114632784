import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { SiDropdownDirective } from './si-dropdown.directive';

/**
 * @deprecated The {@link SiDropdownContainerComponent} and its related directives should no longer be used.
 * - For creating menus, use {@link SiMenuModule} instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating plain overlays, use the {@link https://material.angular.io/cdk/overlay/overview CDK Overlay}: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/dropdowns/
 */
@Component({
  selector: 'si-dropdown-container',
  templateUrl: './si-dropdown-container.component.html',
  styleUrl: './si-dropdown-container.component.scss',
  standalone: true
})
export class SiDropdownContainerComponent implements OnDestroy, OnInit {
  private parent!: SiDropdownDirective;

  elementTransform = '';

  @HostBinding('class.hidden') hide = false;

  get isDropup(): boolean {
    return this.parent?.isDropup ?? false;
  }

  get isDropstart(): boolean {
    return this.parent?.isDropstart ?? false;
  }

  get isDropend(): boolean {
    return this.parent?.isDropend ?? false;
  }

  content: HTMLElement = inject(ElementRef).nativeElement;

  @ViewChild('menu', { read: ElementRef, static: true }) private menu!: ElementRef;

  ngOnInit(): void {
    // Set a property of the HTML element for others to follow the chain without having to use the service.
    (this.content as any).dropdownParentElement = this.parent.hostElement;
    this.addMenus();
  }

  ngOnDestroy(): void {
    this.removeMenus();
  }

  @HostListener('keydown', ['$event'])
  @HostListener('keyup', ['$event'])
  @HostListener('keypress', ['$event'])
  onKeyboardEvent(event: KeyboardEvent): void {
    if (this.parent) {
      const newEvent = new KeyboardEvent(event.type, { ...event, cancelable: true });
      event.stopPropagation();
      Object.defineProperty(newEvent, 'target', { writable: false, value: event.target });
      this.parent.hostElement.dispatchEvent(newEvent);
      if (event.defaultPrevented) {
        newEvent.preventDefault();
      }
    }
  }

  @HostListener('focus', ['$event'])
  @HostListener('blur', ['$event'])
  @HostListener('focusin', ['$event'])
  @HostListener('focusout', ['$event'])
  onFocusEvent(event: FocusEvent): void {
    if (this.parent) {
      const newEvent = new FocusEvent(event.type, { ...event, cancelable: true });
      event.stopPropagation();
      Object.defineProperty(newEvent, 'target', { writable: false, value: event.target });
      this.parent.hostElement.dispatchEvent(newEvent);
      if (event.defaultPrevented) {
        newEvent.preventDefault();
      }
    }
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('click', ['$event'])
  onMouseEvent(event: MouseEvent): void {
    if (this.parent) {
      const newEvent = new MouseEvent(event.type, { ...event, cancelable: true });
      event.stopPropagation();
      Object.defineProperty(newEvent, 'target', { writable: false, value: event.target });
      this.parent.hostElement.dispatchEvent(newEvent);
      if (event.defaultPrevented) {
        newEvent.preventDefault();
      }
    }
  }

  removeMenus(): void {
    if (this.parent) {
      this.parent.menus.forEach(
        menuContent => menuContent.parentElement?.appendChild(menuContent.content)
      );
    }
  }

  addMenus(): void {
    if (this.parent) {
      this.parent.menus.forEach(menuContent =>
        this.menu.nativeElement.appendChild(menuContent.content)
      );
    }
  }

  setParent(parent: SiDropdownDirective): void {
    this.parent = parent;
  }
}
