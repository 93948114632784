import { NgModule }      from "@angular/core";

import { IPreselectionService, IStorageService } from "@gms-flex/core";

import { SummaryBarPreselectService }  from "./services/summary-bar-preselect.service";

import { SummaryBarStorageService }  from "./services/summary-bar-storage.service";

@NgModule({
  providers: [ { provide: IPreselectionService, useClass: SummaryBarPreselectService, multi: true },
  { provide: IStorageService, useClass: SummaryBarStorageService, multi: true }]
})
export class GmsSummaryBarRootServicesModule {}
