<si-main-detail-container
  [largeLayoutBreakpoint]="largeLayoutBreakpoint"
  [(detailsActive)]="detailsActive"
  [resizableParts]="true"
  [hideBackButton]="false"
  class="si-scene-container"
  containerClass=""
  detailContainerClass="pb-4"
  masterContainerClass="pb-4"
>
  <div slot="masterActions" class="si-scene-properties-row rounded-2">
    <div class="mt-6 col d-flex">
      @if (!sceneModel.readonly) {
        <si-content-action-bar
          class="ms-auto me-5"
          [primaryActions]="primaryActions"
          [secondaryActions]="secondaryActions"
          [viewType]="viewType"
        />
      }
    </div>
  </div>

  <div slot="masterData" class="vertical-menu card elevation-1">
    @for (listItem of sceneModel.scenes; track trackBySceneId($index, listItem)) {
      <a
        class="scene-item focus-inside"
        [class.active]="selectedScene === listItem"
        tabindex="0"
        (click)="handleSelectedListItemChange(listItem)"
        (keydown.enter)="handleSelectedListItemChange(listItem)"
      >
        <div class="row">
          <div class="col d-flex align-items-center">
            <i class="icon element-scenes me-2"></i>
            {{ listItem.name }}
            <i class="icon element-right-2 ms-auto"></i>
          </div>
        </div>
      </a>
    }
  </div>

  <si-scene-properties
    #properties
    slot="detailActions"
    class="si-scene-prop si-scene-properties-row rounded-2"
    [readOnly]="readOnly"
    [selectedScene]="selectedScene"
    [scenesList]="sceneModel.scenes"
    [objectId]="objectId"
    (removeScene)="handleRemoveScene($event)"
  />

  <!-- details -->
  <div slot="details" class="card elevation-1 overflow-hidden">
    <div class="card-body p-0">
      <div class="si-scene-container justify-content-center">
        <si-command-list
          #commandListViewer
          class="w-100"
          [objectId]="objectId"
          [readOnly]="readOnly"
          [propertyList]="propertyCommands"
          [isSceneView]="true"
          (propertyChanged)="propertyChanged($event)"
          (addingDataPoints)="addingDataPoint()"
          (command)="commandSelected($event)"
        />
      </div>
    </div>
  </div>
</si-main-detail-container>

<ng-template #configureModalContent>
  <si-configure-model
    [itemsList]="sceneCommands"
    [sceneList]="sceneList"
    [objectId]="objectId"
    [modalTitleId]="modalTitleId"
    [selectedDataPoint]="selectedDataPoint!"
    [displayMode]="displayMode"
    (closeDialog)="handleDPDialogClose($event)"
  />
</ng-template>

<ng-template #dataPointViewModalContent>
  <si-datapoint-view
    [sceneModel]="sceneModel"
    [objectId]="objectId"
    [modalTitleId]="modalTitleId"
    [displayMode]="displayMode"
    [newDataPoints]="newDataPoints"
    (closeDialog)="handleDialogClose($event)"
  />
</ng-template>

<div>
  <ng-container #portalOutlet cdkPortalOutlet />
</div>
