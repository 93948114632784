import { Directive } from '@angular/core';

export interface SiPillsInputValueParseResult {
  newValue: string;
  newPills: string[];
}

export type SiPillsInputValueHandlerTrigger = 'input' | 'keydown.enter' | 'blur';

@Directive({ standalone: true })
export abstract class SiPillsInputValueHandlerDirective {
  abstract handle(
    value: string,
    trigger: SiPillsInputValueHandlerTrigger
  ): SiPillsInputValueParseResult | undefined;
}
