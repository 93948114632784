import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ColumnMode, NgxDatatableModule, SelectionType, TableColumn } from '@siemens/ngx-datatable';
import { SI_DATATABLE_CONFIG, SiDatatableModule } from '@simpl/element-ng';
import { AnyCollectionValue } from '@simpl/element-value-types';
import { clone } from '@simpl/object-browser-ng/common';

import { Property } from '../../../interfaces/property';
import { SiPropertyComponent } from '../../si-property/si-property.component';
import { PropertyOptional, TableProperty, TablePropertyOptional } from './si-property-table.model';

const TABLE_MAX_INLINE_LEVEL = 3;

@Component({
  selector: 'si-property-table',
  templateUrl: './si-property-table.component.html',
  styles: [':host { display: block; }'],
  providers: [{ provide: 'configuration', useValue: SI_DATATABLE_CONFIG }],
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SiDatatableModule, SiPropertyComponent, NgxDatatableModule],
  styleUrls: ['./si-property-table.component.scss'],
  host: { class: 'd-flex flex-column flex-fill' }
})
export class SiPropertyTableComponent implements OnChanges, OnInit {
  @Input({ required: true }) filteredProperties!: PropertyOptional[];
  @Input({ required: true }) propertyTempl!: TemplateRef<any>;

  @ViewChild('valueCellTempl', { static: true }) valueCellTempl!: TemplateRef<any>;
  @ViewChild('toggleTempl', { static: true }) toggleTempl!: TemplateRef<any>;
  @ViewChild('table', { read: ElementRef }) tableElement?: ElementRef<HTMLElement>;

  processedProperties: TablePropertyOptional[] = [];
  tableProperties: TablePropertyOptional[] = [];
  columns!: TableColumn[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ColumnMode = ColumnMode;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SelectionType = SelectionType;

  private tableUiState: (boolean | undefined)[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filteredProperties) {
      this.updateTableProperties();
    }
  }

  ngOnInit(): void {
    this.initTableColumns();
  }

  mapSubmit(event: Property | TableProperty): Property {
    if ('__internalState' in event && event.__internalState.parent) {
      this.tableUiState[event.__internalState.parent.__internalState.itemId] = true;
      if (event.__internalState.parent.value.kind === 'priority-array') {
        const clonedRef = clone(event.__internalState.parent.__internalState.ref);
        clonedRef.value.value = [event];
        (clonedRef as any)._original = event.__internalState.parent;
        const cloned = this.copyAnnotations(event.__internalState.parent, clonedRef);
        return this.mapSubmit(cloned);
      }
      return this.mapSubmit(event.__internalState.parent);
    }
    if ('__internalState' in event && event.__internalState.ref) {
      return event.__internalState.ref;
    } else {
      return event;
    }
  }

  getTableRowClass(row: TablePropertyOptional): string | undefined {
    return 'property-' + row.__internalState.itemId;
  }

  toggleTableRowGroup = (event: {
    type: string;
    row: TablePropertyOptional;
    event?: Event;
  }): void => {
    if (!event.row) {
      return;
    }
    const property = event.row;
    if (
      event?.type === 'keydown' &&
      event.event instanceof KeyboardEvent &&
      event.event.key === 'Enter' &&
      (!property.treeStatus || property.treeStatus === 'disabled')
    ) {
      this.keyboardSelect(property);
      return;
    }
    if (
      event?.type !== 'click' &&
      (event?.type !== 'keydown' ||
        !(event.event instanceof KeyboardEvent) ||
        event.event.key !== 'Enter')
    ) {
      return;
    }
    if (property.treeStatus && property.treeStatus !== 'disabled') {
      property.treeStatus = property.treeStatus !== 'expanded' ? 'expanded' : 'collapsed';
      // Force update
      // TODO: Maybe make less process-intensive.
      this.flattenProperties();

      // TODO: Once fixed in NgxDatatable, remove again.
      setTimeout(() => {
        (
          this.tableElement?.nativeElement.querySelector(
            '.property-' + property.__internalState.itemId
          ) as HTMLElement | null
        )?.focus();
      });
    }
  };

  private findFocusableChild(element: HTMLElement): HTMLElement | null {
    const select = element.querySelector('select');
    if (select) {
      return select;
    }
    const input = element.querySelector('input');
    if (input) {
      return input;
    }
    const formGroup = element.classList.contains('form-group')
      ? element
      : (element.querySelector('.form-group') as HTMLElement | null);
    return formGroup;
  }

  private keyboardSelect(row: TablePropertyOptional): void {
    const formSelector = '.property-' + row.__internalState.itemId + ' .form-control';
    let form = this.tableElement?.nativeElement.querySelector(formSelector) as HTMLElement | null;
    if (form) {
      form.click();
      setTimeout(() => {
        form = this.tableElement?.nativeElement.querySelector(formSelector) as HTMLElement | null;
        if (!form) {
          return;
        }
        const modal = document?.querySelector('si-modal');
        if (modal) {
          (modal.querySelector('.modal-body') as HTMLElement | null)?.focus();
          return;
        }
        if (document.activeElement?.classList?.contains('form-control')) {
          return;
        }
        const inlineDropdown = document?.querySelector(
          '.value-content.active'
        ) as HTMLElement | null;
        if (inlineDropdown) {
          this.findFocusableChild(inlineDropdown)?.focus();
          return;
        }
        this.findFocusableChild(form)?.focus();
      }, 100);
    }
  }

  private initTableColumns(): void {
    this.columns = [
      {
        name: 'Name',
        prop: 'name',
        minWidth: 100,
        resizeable: true,
        canAutoResize: true,
        isTreeColumn: true,
        treeLevelIndent: 12,
        // Set empty because it's always on the left side.
        treeToggleTemplate: this.toggleTempl
      },
      {
        name: 'Value',
        prop: 'value',
        minWidth: 100,
        resizeable: true,
        canAutoResize: true,
        cellClass: 'align-items-start datatable-body-cell-overflow',
        cellTemplate: this.valueCellTempl
      }
    ];
  }

  private isPropertyWithChildren(
    property: PropertyOptional | TablePropertyOptional
  ): property is Property<AnyCollectionValue> & { value: { value: [] } } {
    return property.value?.type === 'collection' && !!property.value.value?.length;
  }

  private processPropRecursive(
    prop: PropertyOptional,
    id: number,
    parentId?: number,
    index?: number,
    level?: number,
    parent?: TablePropertyOptional
  ): [TablePropertyOptional, number] {
    const childrenShouldBeInlined = (level ?? 0) < TABLE_MAX_INLINE_LEVEL;
    const hasInlineChildren = this.isPropertyWithChildren(prop) && childrenShouldBeInlined;
    const processedProp = {
      ...prop,
      overrideMode: 'direct',
      __internalState: {
        ref: prop,
        parent,
        itemId: ++id,
        itemIndex: index,
        itemParentId: parentId
      },
      treeStatus: !!this.tableUiState[id]
        ? 'expanded'
        : hasInlineChildren
          ? 'collapsed'
          : 'disabled'
    } as TablePropertyOptional;
    if (this.isPropertyWithChildren(prop) && childrenShouldBeInlined) {
      const newParentId = id;
      processedProp.value = {
        ...prop.value,
        value: [
          ...prop.value.value.map((subprop, subIndex) => {
            let processedSubprop: TablePropertyOptional;
            [processedSubprop, id] = this.processPropRecursive(
              subprop as PropertyOptional,
              id,
              newParentId,
              subIndex,
              (level ?? 0) + 1,
              processedProp
            );
            return processedSubprop;
          })
        ]
      };
    }
    return [processedProp, id];
  }

  private copyAnnotations<T extends PropertyOptional>(
    annotatedProperty: T & TablePropertyOptional,
    property: T
  ): T & TablePropertyOptional {
    const modifiedProperty: T = {
      ...property,
      overrideMode: 'direct'
    };
    return {
      ...modifiedProperty,
      __internalState: {
        ...annotatedProperty.__internalState,
        ref: property
      },
      treeStatus: annotatedProperty.treeStatus
    };
  }

  private processProperties(): void {
    let id = 0;
    this.processedProperties = this.filteredProperties.map((prop, index) => {
      let processedProp: TablePropertyOptional;
      [processedProp, id] = this.processPropRecursive(prop, id, undefined, index, undefined);
      return processedProp;
    });
  }

  private flattenPropertiesRecursive(properties: TablePropertyOptional[]): TablePropertyOptional[] {
    return properties
      .map(prop =>
        prop.treeStatus && prop.treeStatus !== 'disabled' && this.isPropertyWithChildren(prop)
          ? [prop, ...this.flattenPropertiesRecursive(prop.value.value as TablePropertyOptional[])]
          : prop
      )
      .flat();
  }

  private flattenProperties(): void {
    this.tableUiState = [];
    this.tableProperties = this.flattenPropertiesRecursive(this.processedProperties);
  }

  private updateTableProperties(): void {
    this.processProperties();
    this.flattenProperties();
  }
}
