<router-outlet name="main" *ngIf="outletName === 'main'" (activate)="onActivate($event)" />
    <router-outlet name="o1" *ngIf="outletName === 'o1'" (activate)="onActivate($event)" />
    <router-outlet name="o2" *ngIf="outletName === 'o2'" (activate)="onActivate($event)" />
    <router-outlet name="o3" *ngIf="outletName === 'o3'" (activate)="onActivate($event)" />
    <router-outlet name="o4" *ngIf="outletName === 'o4'" (activate)="onActivate($event)" />
    <router-outlet name="o5" *ngIf="outletName === 'o5'" (activate)="onActivate($event)" />
    <router-outlet name="o6" *ngIf="outletName === 'o6'" (activate)="onActivate($event)" />
    <router-outlet name="o7" *ngIf="outletName === 'o7'" (activate)="onActivate($event)" />
    <router-outlet name="o8" *ngIf="outletName === 'o8'" (activate)="onActivate($event)" />
    <router-outlet name="o9" *ngIf="outletName === 'o9'" (activate)="onActivate($event)" />
    <router-outlet name="o10" *ngIf="outletName === 'o10'" (activate)="onActivate($event)" />
    <router-outlet name="o11" *ngIf="outletName === 'o11'" (activate)="onActivate($event)" />
    <router-outlet name="o12" *ngIf="outletName === 'o12'" (activate)="onActivate($event)" />
    <router-outlet name="o13" *ngIf="outletName === 'o13'" (activate)="onActivate($event)" />
    <router-outlet name="o14" *ngIf="outletName === 'o14'" (activate)="onActivate($event)" />
    <router-outlet name="o15" *ngIf="outletName === 'o15'" (activate)="onActivate($event)" />
    <router-outlet name="o16" *ngIf="outletName === 'o16'" (activate)="onActivate($event)" />
    <router-outlet name="o17" *ngIf="outletName === 'o17'" (activate)="onActivate($event)" />
    <router-outlet name="o18" *ngIf="outletName === 'o18'" (activate)="onActivate($event)" />
    <router-outlet name="o19" *ngIf="outletName === 'o19'" (activate)="onActivate($event)" />
    <router-outlet name="o20" *ngIf="outletName === 'o20'" (activate)="onActivate($event)" />
    <router-outlet name="o21" *ngIf="outletName === 'o21'" (activate)="onActivate($event)" />
    <router-outlet name="o22" *ngIf="outletName === 'o22'" (activate)="onActivate($event)" />
    <router-outlet name="o23" *ngIf="outletName === 'o23'" (activate)="onActivate($event)" />
    <router-outlet name="o24" *ngIf="outletName === 'o24'" (activate)="onActivate($event)" />
    <router-outlet name="o25" *ngIf="outletName === 'o25'" (activate)="onActivate($event)" />
    <router-outlet name="o26" *ngIf="outletName === 'o26'" (activate)="onActivate($event)" />
    <router-outlet name="o27" *ngIf="outletName === 'o27'" (activate)="onActivate($event)" />
    <router-outlet name="o28" *ngIf="outletName === 'o28'" (activate)="onActivate($event)" />
    <router-outlet name="o29" *ngIf="outletName === 'o29'" (activate)="onActivate($event)" />