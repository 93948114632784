import { Injectable } from "@angular/core";
import { AlarmConfigAttributes, AlarmConfigBx, AlarmConfigurationServiceBase, TraceModules } from "@gms-flex/services";
import { TraceService } from "@gms-flex/services-common";
import { Observable } from "rxjs";

import { AlarmConfigurationProxyService } from "../../bx-services/point/alarm-configuration-proxy.service";

@Injectable({
  providedIn: 'root'
})
export class AlarmConfigurationSubstituteService extends AlarmConfigurationServiceBase {
  public constructor(
    private readonly traceService: TraceService,
    private readonly alarmConfigurationProxy: AlarmConfigurationProxyService) {
    super();
    this.traceService.info(TraceModules.events, 'AlarmConfigurationSubstituteService created.');
  }

  public getAlarmConfiguration(partitionId: string, pointId: string): Observable<AlarmConfigBx[]> {
    return this.alarmConfigurationProxy.getAlarmConfigurations(partitionId, pointId);
  }

  public updateAlarmConfiguration(
    partitionId: string,
    pointId: string,
    updatedConfiguration: AlarmConfigBx
  ): Observable<AlarmConfigBx> {
    return this.alarmConfigurationProxy.updateAlarmConfigurations(partitionId, pointId, updatedConfiguration);
  }

  public deleteAlarmConfiguration(partitionId: string, pointId: string, configurationId: string): Observable<boolean> {
    return this.alarmConfigurationProxy.deleteAlarmConfigurations(partitionId, pointId, configurationId);
  }

  public createAlarmConfiguration(
    partitionId: string,
    pointId: string,
    configurationAttributes: AlarmConfigAttributes
  ): Observable<AlarmConfigBx> {
    return this.alarmConfigurationProxy.createAlarmConfigurations(partitionId, pointId, configurationAttributes);
  }
}
