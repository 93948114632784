import { FormatHelper } from '../../../utilities/format-helper';
import { CommandParameter, ParameterType } from './gms-base-parameter';

export class NumericParameter extends CommandParameter {

  constructor(name: string, order: number, defaultValue: string,
    minValue: string, maxValue: string, precision: number, dataType: string) {

    if (precision === undefined) {
      // set Default = 2
      precision = 2;
    }

    super(name, order, defaultValue, precision, dataType);

    this.MinValue = Number(minValue);
    this.MaxValue = Number(maxValue);
    this.ParameterType = ParameterType.Numeric;
  }
}

export class NumericLongParameter extends NumericParameter {

  constructor(name: string, order: number, defaultValue: string,
    minValue: string, maxValue: string, precision: number, dataType: string) {

    super(name, order, defaultValue, minValue, maxValue, precision, dataType);

    this.ParameterType = (dataType === 'BasicInt64' || dataType === 'ExtendedInt64') ? ParameterType.NumericInt64 : ParameterType.NumericUInt64;
    const MinMax: [Long, Long] = FormatHelper.calculateLongValueRange(minValue, maxValue, this.ParameterType === ParameterType.NumericUInt64);

    this.MinValueLong = MinMax[0];
    this.MaxValueLong = MinMax[1];
  }
}
