import { Subject } from 'rxjs';

import { GmsElement } from './gms-element';

export class GmsButtons {

  public constructor(commandElement: GmsElement) {
    // super(GmsElementType.Buttons);
    this.CommandElement = commandElement;
    this.confirmSubscription = new Subject<void>();
    this.cancelSubscription = new Subject<void>();
  }

  public get CommandElement(): GmsElement {
    return this._commandElement;
  }

  public set CommandElement(value: GmsElement) {
    if (this._commandElement !== value) {
      this._commandElement = value;
    }
  }

  public cancelSubscription: Subject<void>;
  public confirmSubscription: Subject<void>;

  private _commandElement: GmsElement = null;

  public Destroy(): void {
    if (this.cancelSubscription !== undefined) {
      this.cancelSubscription.unsubscribe();
    }

    if (this.confirmSubscription !== undefined) {
      this.confirmSubscription.unsubscribe();
    }
  }
}
