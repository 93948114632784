import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { TreeItem } from "@simpl/element-ng";

import { ActiveMenuItemService } from '../services/active-menu-item.service';
import { SelectionChangedService } from '../services/selection-changed.service';
import { Guid } from '../utilities/guid';
import { Utility } from '../utilities/utility';

@Component({
  selector: '[gms-easy-navigation-bar-top-menu-item]',
  template: `
      @if (hasChildItems(treeItem)) {
        <si-menu-item class="top-menu-item"
                      [cdkMenuTriggerFor]="subMenu"
                      [cdkMenuTriggerData]="{ treeItems: treeItem.children }">{{ treeItem.label }}
        </si-menu-item>
      } @else {
        <si-menu-item class="top-menu-item">{{ treeItem.label }}</si-menu-item>
      }
      <ng-template #subMenu let-treeItems="treeItems">
        <si-menu>
          <ng-container *ngFor="let item of treeItems">
            <si-menu-item *ngIf="!isDividerItem(item)"
                          [cdkMenuTriggerFor]="hasChildItems(item) ? subMenu : null"
                          [cdkMenuTriggerData]="{ treeItems: item.children }">{{ item.label }}
            </si-menu-item>
            <si-menu-divider *ngIf="isDividerItem(item)"/>
          </ng-container>
        </si-menu>
      </ng-template>
  `,
  styleUrl: './gms-easy-navigation-bar-top-menu-item.component.scss'
})
export class GmsEasyNavigationBarTopMenuItemComponent implements AfterViewInit, OnDestroy {
  @Input() public treeItem: any;
  @ViewChild('labelContainer') public labelContainer: ElementRef;
  public hasChildren: boolean;
  private readonly id: string = '';
  private timeout: any = undefined;
  private readonly mouseEnterFuncRef: any;

  constructor(public readonly activeMenuItemService: ActiveMenuItemService,
    private readonly elRef: ElementRef,
    private readonly selectionChangedService: SelectionChangedService) {
    this.initHasChildren();
    this.id = Guid.newGuid();
  }

  public ngAfterViewInit(): void {
    this.timeout = setTimeout((): void => {
      const hostElement: HTMLElement = this?.elRef?.nativeElement;
      if (!isNullOrUndefined(hostElement)) {
        const width: number = hostElement?.getBoundingClientRect()?.width || 0;
        const paddedWidth: number = width + 10;
        hostElement.style.maxWidth = `${paddedWidth}px`;
        hostElement.style.minWidth = `${paddedWidth}px`;
      }
    }, 300);

    // this.addMouseEnterListener();
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
    // this.removeMouseEnterListener();
  }

  // @HostListener('mouseenter', ['$event'])
  // public onMouseEnter(event: Event): void {
  //   if (Utility.hasChildren(this.treeItem)) {
  //     this.activeMenuItemService.setActiveItem(this.id);
  //   }
  //   event.preventDefault();
  //   event.stopPropagation();
  //   event.stopImmediatePropagation();
  // }

  public isDividerItem(item: any): boolean {
    return !isNullOrUndefined(item) && item.label === '-';
  }

  @HostListener('window:keydown.esc', ['$event'])
  public onEscapeKeyDown(): void {
    this.activeMenuItemService.clearActiveMenuItem();
  }

  @HostListener('window:click', ['$event'])
  public onWindowClick(event: Event): void {
    if (this?.labelContainer?.nativeElement?.contains(event?.target)) {
      if (this.activeMenuItemService.isActiveMenuItem(this.id)) {
        this.activeMenuItemService.clearActiveMenuItem();
      } else {
        if (!this.HasChildren) {
          this.selectionChangedService.onSelectionChanged.next(this.treeItem);
        } else {
          this.activeMenuItemService.ActiveMenuItemId = this.id;
        }
      }
    }
  }

  public initHasChildren(): void {
    this.hasChildren = Utility.hasChildren(this.treeItem);
  }

  public get ShowMenu(): boolean {
    return this.activeMenuItemService.isActiveMenuItem(this.id);
  }

  public hasChildItems(item: TreeItem): boolean {
    return item?.children?.length > 0;
  }

  public get HasChildren(): boolean {
    return Utility.hasChildren(this.treeItem);
  }
}
