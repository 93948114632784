import { HttpResponse } from "@angular/common/http";
import {
  BrowserObject,
  CnsFormatOption,
  CnsLabel,
  CnsLabelEn,
  Designation, TagService, TagsKeyValueInfo,
  TagsRequestRepresentation, TagsResponseRepresentation
} from '@gms-flex/services';
import { catchError, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { GmsGraphic } from '../elements/gms-graphic';
import { Datapoint, DataPointPropertyChangeArgs } from '../processor/datapoint/gms-datapoint';
import { GraphicsDatapointHelper } from '../processor/datapoint/gms-datapoint-helper';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { SemanticType } from '../processor/expression';
import { LibraryImage, LibraryImageChangeArgs } from '../processor/image/gms-library-image';
import { TextGroupEntry, TextGroupEntryPropertyChangeArgs, TextGroupEntryStatus } from '../processor/textgroup/gms-text-group-entry';
import { TextGroupEntryHelper } from '../processor/textgroup/gms-text-group-helper';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { GmsDataType } from '../types/gms-data-type';
import { GmsElementPropertyType, GmsTextElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { GmsTextAlignmentType, GmsTextTrimmingType, GmsTextType, GmsTextWrappingType } from '../types/gms-text-types';
import { FormatHelper } from '../utilities/format-helper';
import { BACnetDateTimeFormatter } from '../utilities/formatBACnetDateTime';
import { DateTimeFormatter } from '../utilities/formatDateTime';
import { DurationFormatter } from '../utilities/formatDuration';
import { SvgUtility } from '../utilities/parser';
import { TagHelper } from "../utilities/tag-helper";
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';

export class TextLine {
  private _x = 0.0;
  public get X(): number {
    return this._x;
  }
  public set X(value: number) {
    if (this._x !== value) {
      this._x = value;
    }
  }

  private _y = 0.0;
  public get Y(): number {
    return this._y;
  }
  public set Y(value: number) {

    if (this._y !== value) {
      this._y = value;
    }
  }

  private _text = '';
  public get Text(): string {
    return this._text;
  }
  public set Text(value: string) {

    if (this._text !== value) {
      this._text = value;
    }
  }

  public static CreateTextLines(textNode: Node): TextLine[] {
    const result: TextLine[] = [];
    const childnodes: NodeList = textNode.childNodes;
    if (childnodes !== undefined) {
      for (let i = 0; i < childnodes.length; ++i) {
        const tspanNode: Node = childnodes.item(i);
        const textLine: TextLine = TextLine.CreateTextLine(tspanNode);
        if (textLine !== undefined) {
          result.push(textLine);
        }
      }
    }
    return result;
  }

  private static CreateTextLine(tspanNode: Node): TextLine {
    let result: TextLine;
    if (tspanNode.nodeName === 'tspan') {
      const x: string = SvgUtility.GetAttributeValue(tspanNode, 'x');
      const y: string = SvgUtility.GetAttributeValue(tspanNode, 'y');
      if (x !== undefined && y !== undefined) {
        let text: string = tspanNode.textContent;
        if (text === undefined) {
          text = '';
        }
        //  = new TextLine(text, Number(x), Number(y));
        result = new TextLine(text, FormatHelper.StringToNumber(x), FormatHelper.StringToNumber(y));
      }
    }
    return result;
  }

  constructor(text: string, x: number, y: number) {
    this.Text = text;
    this.X = x;
    this.Y = y;
  }
}

export class GmsText extends GmsElement {

  private static readonly DEFAULT_PRECISION: number = Number.MIN_SAFE_INTEGER;
  private static readonly DEFAULT_FONTSIZE: string = '16'; // "14";
  private static readonly DEFAULT_FONTFAMILY: string = 'Arial';
  private static readonly DEFAULT_FONTSTYLE: string = 'normal';
  private static readonly DEFAULT_STRIKETHROUGHT: string = 'false';
  private static readonly DEFAULT_UNDERLINE: string = 'false';
  private static readonly DEFAULT_DECIMALOFFSET: number = Number.NaN;
  private static readonly DEFAULT_DISPLAYVALUE_SEPARATOR: string = '+';
  private readonly _dataImage: string = 'data:image/png;base64,';
  // App Resources
  private static readonly VALUE_NOTANUMBER: string = '#NAN';
  private static readonly FORMAT_ERROR: string = '#FORMAT';
  private static readonly FORMAT_ENG: string = '#ENG';
  private static readonly GENERAL_ERROR: string = '#COM';

  private _context: CanvasRenderingContext2D;

  private _libraryIconSubscription: Subscription;
  private _textGroupEntrySubscription: Subscription;
  private _textGroupEntryIconSubscription: Subscription;
  private _datapointIconSubscription: Subscription;
  private _nameSubscription: Subscription = undefined;

  private _evaluationText: Evaluation;
  private _evaluationTextType: Evaluation;
  private _evaluationWrapping: Evaluation;
  private _evaluationUnits: Evaluation;
  private _evaluationPrecision: Evaluation;
  private _evaluationFontFamily: Evaluation;
  private _evaluationFontSize: Evaluation;
  private _evaluationBold: Evaluation;
  private _evaluationItalic: Evaluation;
  private _evaluationStrikethrough: Evaluation;
  private _evaluationUnderline: Evaluation;
  private _evaluationHorizontalTextAlignment: Evaluation;
  private _evaluationVerticalTextAlignment: Evaluation;
  private _evaluationTrimming: Evaluation;
  private _evaluationDecimalOffset: Evaluation;
  private _evaluationFontWeight: Evaluation;

  // space factor between lines
  private readonly _lineHeightFactor: number = 0.2;
  private _lineHeight = 12;
  private _gapHeight: number = this._lineHeight * this._lineHeightFactor;

  // constant (percentage) determening when word is "too long" and has to be splitted
  // private _minWordSplitFactor: number = 0.33;
  // constant (percentage) determening when available space in the line is "too small" and word should not be splitted in this line
  private readonly _minAvailableSpaceFactor: number = 0.15;

  private _subscriptionGetBrowserObject: Subscription = undefined;
  private readonly _cancelTextUpdate: Subject<null> = new Subject<null>();

  // Alias, Descriptor read default value
  private _subscriptionsDescriptor: Map<string, Subscription> = new Map<string, Subscription>();
  private _subscriptionsAlias: Map<string, Subscription> = new Map<string, Subscription>();
  private _subscriptionsPrecision: Map<string, Subscription> = new Map<string, Subscription>();

  private _designValueHorizontalAlignment: GmsTextAlignmentType = GmsTextAlignmentType.Left;
  private _designValueVerticalAlignment: GmsTextAlignmentType = GmsTextAlignmentType.Top;

  private _textLines: TextLine[] = [];
  public get TextLines(): TextLine[] {
    return this._textLines;
  }
  public set TextLines(value: TextLine[]) {

    if (this._textLines !== value) {
      this._textLines = value;
    }
    this.ShapeChanged();
    this.NotifyPropertyChanged('TextLines');
  }

  private _text = '';
  public get Text(): string {
    return this._text;
  }
  public set Text(value: string) {

    if (this._text !== value) {
      this._text = value;
      this.UpdateText();
    }
  }

  private _textType: GmsTextType = GmsTextType.Text;
  public get TextType(): GmsTextType {
    return this._textType;
  }
  public set TextType(value: GmsTextType) {

    if (this._textType !== value) {

      this._textType = value;

      const result: string = Evaluation.GetValue2(this._evaluationTextType, this._textType, PropertyType.String);
      let type: GmsTextType = GmsTextType.Number;
      if (result !== undefined) {
        if (Number.isNaN(Number(result))) {
          type = GmsTextType[result];
        } else {
          type = Number(result);
        }
      }

      if (type !== undefined) {
        this.IsIcon = type === GmsTextType.Icon;
        this.NotifyPropertyChanged('TextType');
      }
    }
  }

  private _isIcon = false;
  public get IsIcon(): boolean {
    return (this._isIcon && !this.ShowErrorBorder);
  }
  public set IsIcon(value: boolean) {

    if (this._isIcon !== value) {
      this._isIcon = value;

      this.NotifyPropertyChanged('IsIcon');
      this.NotifyPropertyChanged('IsBackgroundVisible');
    }
  }

  private _iconX = 0;
  public get IconX(): number {
    return this._iconX;
  }
  public set IconX(value: number) {

    if (this._iconX !== value) {
      this._iconX = value;
      this.NotifyPropertyChanged('IconX');
      this.ShapeChanged();
    }
  }

  private _iconY = 0;
  public get IconY(): number {
    return this._iconY;
  }
  public set IconY(value: number) {

    if (this._iconY !== value) {
      this._iconY = value;
      this.NotifyPropertyChanged('IconY');
      this.ShapeChanged();
    }
  }

  private _iconWidth = 0;
  public get IconWidth(): number {
    return this._iconWidth;
  }
  public set IconWidth(value: number) {

    if (this._iconWidth !== value) {
      this._iconWidth = value;
      this.NotifyPropertyChanged('IconWidth');
      this.ShapeChanged();
    }
  }

  private _iconHeight = 0;
  public get IconHeight(): number {
    return this._iconHeight;
  }
  public set IconHeight(value: number) {
    if (this._iconHeight !== value) {
      this._iconHeight = value;
      this.NotifyPropertyChanged('IconHeight');
      this.ShapeChanged();
    }
  }
  public get IsBackgroundVisible(): boolean {
    return !this.IsIcon && this.Background !== 'transparent';
  }

  private _iconSource = '';
  public get IconSource(): string {
    // Note: FilesService provides dataURI,  other services are not
    const dataURI: boolean = this._iconSource.startsWith('data:');
    return dataURI ? this._iconSource : this._dataImage + this._iconSource;
  }
  public set IconSource(value: string) {

    if (this._iconSource !== value) {
      this._iconSource = value;
      if (value !== undefined && value.length > 0) {
        // Get dynamically an image size
        const image: HTMLImageElement = document.createElement('img');
        image.onload = ((): void => this.onImageLoaded(image));
        // data:[<media type>][;base64],<data>
        // data:image/gif
        // data:image/jpg
        // etc
        const dataURI: boolean = value.startsWith('data:');
        image.src = dataURI ? value : this._dataImage + value;
      } else {
        this.setImageSize();
      }
    }
  }

  private _units: string;
  public get Units(): string {
    return this._units;
  }
  public set Units(value: string) {

    if (this._units !== value) {
      this._units = value;

      this.NotifyPropertyChanged('Units');
    }
  }

  private _precision: number = GmsText.DEFAULT_PRECISION;

  public get Precision(): number {
    return this._precision;
  }
  public set Precision(value: number) {

    if (this._precision !== value) {
      this._precision = value;

      this.NotifyPropertyChanged('Precision');
    }
  }

  private _tspanX = 0;
  public get TspanX(): number {
    return this._tspanX;
  }
  public set TspanX(value: number) {

    if (this._tspanX !== value) {
      this._tspanX = value;

      this.NotifyPropertyChanged('TspanX');
    }
  }

  private _tspanY = 0;
  public get TspanY(): number {
    return this._tspanY;
  }
  public set TspanY(value: number) {

    if (this._tspanY !== value) {
      this._tspanY = value;

      this.NotifyPropertyChanged('TspanY');
    }
  }

  // NOTE:
  // "hanging" (Top) used for the text position calculation

  // Important: no updates to the view for
  // alignment-baseline="hanging"
  // text-anchor="start"

  // private _baseline: string = "hanging";
  // public get Baseline(): string {
  //    return this._baseline;
  // }
  // public set Baseline(value: string) {

  //    if (this._baseline !== value) {
  //        this._baseline = value;

  //        this.NotifyPropertyChanged("Baseline");
  //    }
  // }

  // private _anchor: string = "start";
  // public get Anchor(): string {
  //    return this._anchor;
  // }

  // public set Anchor(value: string) {

  //    if (this._anchor !== value) {
  //        this._anchor = value;
  //        switch (value) {
  //            case GmsAnchorType[GmsAnchorType.Start]:
  //                this.HorizontalAlignment = GmsTextAlignmentType.Left;
  //                // this.Baseline = "hanging";
  //                break;
  //            case GmsAnchorType[GmsAnchorType.Middle]:
  //                this.HorizontalAlignment = GmsTextAlignmentType.Center;
  //                // this.Baseline = "middle";
  //                break;
  //            case GmsAnchorType[GmsAnchorType.End]:
  //                this.HorizontalAlignment = GmsTextAlignmentType.Right;
  //                // this.Baseline = "baseline";
  //                break;
  //            default:
  //                return;
  //        }

  //        this.NotifyPropertyChanged("Anchor");
  //    }
  // }

  private _horizontalAlignment: GmsTextAlignmentType = GmsTextAlignmentType.Left;
  public get HorizontalAlignment(): GmsTextAlignmentType {
    return this._horizontalAlignment;
  }
  public set HorizontalAlignment(value: GmsTextAlignmentType) {

    if (this._horizontalAlignment !== value && value !== undefined) {
      this._horizontalAlignment = value;
      this.NotifyPropertyChanged('HorizontalAlignment');
    }
  }

  private _VerticalAlignment: GmsTextAlignmentType = GmsTextAlignmentType.Top;
  public get VerticalAlignment(): GmsTextAlignmentType {
    return this._VerticalAlignment;
  }
  public set VerticalAlignment(value: GmsTextAlignmentType) {

    if (this._VerticalAlignment !== value && value !== undefined) {
      this._VerticalAlignment = value;

      this.NotifyPropertyChanged('VerticalAlignment');
    }
  }

  private _trimming: GmsTextTrimmingType = GmsTextTrimmingType.None;
  public get Trimming(): GmsTextTrimmingType {
    return this._trimming;
  }
  public set Trimming(value: GmsTextTrimmingType) {

    if (this._trimming !== value) {
      this._trimming = value;

      this.NotifyPropertyChanged('Trimming');
    }
  }

  private _wrapping: GmsTextWrappingType = GmsTextWrappingType.NoWrap;
  public get Wrapping(): GmsTextWrappingType {
    return this._wrapping;
  }
  public set Wrapping(value: GmsTextWrappingType) {

    if (this._wrapping !== value) {
      this._wrapping = value;

      this.NotifyPropertyChanged('Wrapping');
    }
  }

  private _fontFamily: string = GmsText.DEFAULT_FONTFAMILY;
  public get FontFamily(): string {
    return this._fontFamily;
  }
  public set FontFamily(value: string) {

    if (this._fontFamily !== value) {
      this._fontFamily = value;
      this.UpdateContext();

      this.NotifyPropertyChanged('FontFamily');
    }
  }

  private _fontSize: string = GmsText.DEFAULT_FONTSIZE;
  public get FontSize(): string {
    return this._fontSize;
  }
  public set FontSize(value: string) {

    if (this._fontSize !== value) {
      this._fontSize = value;
      this.UpdateContext();
      this.ShapeChanged();
      this.NotifyPropertyChanged('FontSize');
    }
  }

  private _isBold = 'false';
  public get IsBold(): string {
    return this._isBold;
  }

  public set IsBold(value: string) {

    if (this._isBold !== value) {
      this._isBold = value;
      if (value === 'true') {
        this.FontWeight = 'bold';
      } else {
        this.FontWeight = '';
      }
    }
  }

  private _fontWeight = '';
  public get FontWeight(): string {
    return this._fontWeight;
  }
  public set FontWeight(value: string) {

    if (this._fontWeight !== value) {
      this._fontWeight = value;
      this.UpdateContext();

      this.NotifyPropertyChanged('FontWeight');
    }
  }
  private _italic = 'false';
  public get Italic(): string {
    return this._italic;
  }
  public set Italic(value: string) {

    if (this._italic !== value) {
      this._italic = value;
      this.FontStyle = (value === 'true') ? 'italic' : GmsText.DEFAULT_FONTSTYLE;
      this.NotifyPropertyChanged('FontStyle');
    }
  }

  private _fontStyle: string = GmsText.DEFAULT_FONTSTYLE;
  public get FontStyle(): string {
    // normal, italic, oblique
    return this._fontStyle;
  }
  public set FontStyle(value: string) {

    if (this._fontStyle !== value) {
      this._fontStyle = value;

      this.UpdateContext();

      this.NotifyPropertyChanged('FontStyle');
    }
  }
  private _strikethrough: string = GmsText.DEFAULT_STRIKETHROUGHT;
  public get Strikethrough(): string {
    return this._strikethrough;
  }
  public set Strikethrough(value: string) {

    if (this._strikethrough !== value) {
      this._strikethrough = value;
      this.UpdateTextDecoration();
      this.NotifyPropertyChanged('StrikeThrough');
    }
  }

  private _underline: string = GmsText.DEFAULT_UNDERLINE;
  public get Underline(): string {
    return this._underline;
  }
  public set Underline(value: string) {

    if (this._underline !== value) {
      this._underline = value;
      this.UpdateTextDecoration();
      this.NotifyPropertyChanged('Underline');
    }
  }

  private _decimalOffset: number = GmsText.DEFAULT_DECIMALOFFSET;
  public get DecimalOffset(): number {
    return this._decimalOffset;
  }
  public set DecimalOffset(value: number) {

    if (this._decimalOffset !== value) {
      this._decimalOffset = value;

      this.NotifyPropertyChanged('DecimalOffset');
    }
  }

  private _textDecoration: string;
  public get TextDecoration(): string {
    return this._textDecoration;
  }
  public set TextDecoration(value: string) {

    if (this._textDecoration !== value) {
      this._textDecoration = value;

      this.NotifyPropertyChanged('TextDecoration');
    }
  }
  constructor() {
    super(GmsElementType.Text);
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateText();
    this.UpdateAdornerDimensions();
    this.NotifyShapeChanged();
  }

  public Destroy(): void {

    if (this._subscriptionsAlias !== undefined && this._subscriptionsAlias.size > 0) {
      this._subscriptionsAlias.forEach((value, key, map) => {
        value.unsubscribe();
      });
      this._subscriptionsAlias.clear();
      this._subscriptionsAlias = undefined;
    }

    if (this._subscriptionsDescriptor !== undefined && this._subscriptionsDescriptor.size > 0) {
      this._subscriptionsDescriptor.forEach((value, key, map) => {
        value.unsubscribe();
      });
      this._subscriptionsDescriptor.clear();
      this._subscriptionsDescriptor = undefined;
    }

    if (this._subscriptionsPrecision !== undefined && this._subscriptionsPrecision.size > 0) {
      this._subscriptionsPrecision.forEach((value, key, map) => {
        value.unsubscribe();
      });
      this._subscriptionsPrecision.clear();
      this._subscriptionsPrecision = undefined;
    }

    if (this._nameSubscription !== undefined) {
      this._nameSubscription.unsubscribe();
      this._nameSubscription = undefined;
    }

    this._evaluationText = undefined;
    this._evaluationTextType = undefined;
    this._evaluationWrapping = undefined;
    this._evaluationUnits = undefined;
    this._evaluationPrecision = undefined;
    this._evaluationFontFamily = undefined;
    this._evaluationFontSize = undefined;
    this._evaluationBold = undefined;
    this._evaluationItalic = undefined;
    this._evaluationStrikethrough = undefined;
    this._evaluationUnderline = undefined;
    this._evaluationHorizontalTextAlignment = undefined;
    this._evaluationVerticalTextAlignment = undefined;
    this._evaluationTrimming = undefined;
    this._evaluationDecimalOffset = undefined;
    this._evaluationFontWeight = undefined;

    super.Destroy();
  }

  public Deserialize(node: Node): void {

    super.Deserialize(node);

    // TextType
    let result: string = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.TextType);
    if (result !== undefined) {
      // Double parse it since serialized as int.
      result = GmsTextType[result as string];
      this.TextType = GmsTextType[result as string];
    }

    // Precision
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.Precision);
    if (result !== undefined) {
      this.Precision = FormatHelper.StringToNumber(result); //  Number(result);
    }
    // Units
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.Units);
    if (result !== undefined) {
      this.Units = result;
    }
    // HorizontalTextAlignment
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.HorizontalTextAlignment);
    if (result !== undefined) {
      this.HorizontalAlignment = GmsTextAlignmentType[result];
      this._designValueHorizontalAlignment = this.HorizontalAlignment;
    }
    // VerticalTextAlignment
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.VerticalTextAlignment);
    if (result !== undefined) {
      this.VerticalAlignment = GmsTextAlignmentType[result];
      this._designValueVerticalAlignment = this.VerticalAlignment;
    }
    // Trimming
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.Trimming);
    if (result !== undefined) {
      this.Trimming = GmsTextTrimmingType[result];
    }
    // Wrapping
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.Wrapping);
    if (result !== undefined) {
      this.Wrapping = GmsTextWrappingType[result];
    }
    // DecimalOffset
    result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.DecimalOffset);
    if (result !== undefined) {
      this.DecimalOffset = FormatHelper.StringToNumber(result); // Number(result);
    }

    // Text, FontSize, Anchor, etc
    if (node.hasChildNodes) {
      let nodes: NodeList = node.childNodes;
      let textNode: Node;
      for (let i = 0; i < nodes.length; i++) {
        const innerNode: Node = nodes.item(i);

        if (innerNode.nodeName === 'text') {
          textNode = innerNode;
        } else if (SvgUtility.IsNodeScaleTransformationGroup(innerNode)) {
          i = -1;
          nodes = innerNode.childNodes;
          continue;
        }
      }

      if (textNode !== undefined) {
        // FillColor
        result = SvgUtility.GetAttributeValue(textNode, GmsElementPropertyType.Fill);
        if (result !== undefined) {
          this.Stroke = result;
          result = SvgUtility.GetAttributeValue(textNode, GmsElementPropertyType.FillOpacity);
          if (result !== undefined) {
            this.StrokeOpacity = +result;
          } else {
            this.StrokeOpacity = 1; // Defaults
          }
        } else {
          this.StrokeOpacity = 0;
        }

        // FontFamily
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.FontFamily);
        if (result !== undefined) {
          this.FontFamily = result;
        }

        // FontSize
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.FontSize);
        if (result !== undefined) {
          this.FontSize = result;
        }

        // IsBold
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.Bold);
        if (result !== undefined) {
          this.IsBold = result;
        }
        // Bold
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.FontWeight);
        if (result !== undefined) {
          this.FontWeight = result;
        }
        // FontStyle
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.FontStyle);
        if (result !== undefined) {
          this.FontStyle = result;
        }
        // Italic
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.Italic);
        if (result !== undefined) {
          this.Italic = result;
        }
        // Strikethrough
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.Strikethrough);
        if (result !== undefined) {
          this.Strikethrough = result;
        }
        // Underline
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.Underline);
        if (result !== undefined) {
          this.Underline = result;
        }
        // TextDecoration
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.TextDecoration);
        if (result !== undefined) {
          this.TextDecoration = result;
        }
        // Anchor
        result = SvgUtility.GetAttributeValue(textNode, GmsTextElementPropertyType.Anchor);
        // if (result != undefined) {
        //    this.Anchor = result;
        // }
        // TextLines
        this.TextLines = TextLine.CreateTextLines(textNode);
      }
      // Text
      result = SvgUtility.GetAttributeValue(node, GmsTextElementPropertyType.Text);
      if (result !== undefined) {
        this.Text = result;
      }
    }

    this.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsText): void {
    this.IsCopying = true;

    super.CopyFrom(element);

    // TextType
    this.TextType = element.TextType;

    // Precision
    this.Precision = element.Precision; //  Number(result);

    // Units
    this.Units = element.Units;

    // HorizontalTextAlignment
    this.HorizontalAlignment = element.HorizontalAlignment;

    // VerticalTextAlignment
    this.VerticalAlignment = element.VerticalAlignment;

    // Trimming
    this.Trimming = element.Trimming;

    // Wrapping
    this.Wrapping = element.Wrapping;

    // DecimalOffset
    this.DecimalOffset = element.DecimalOffset; // Number(result);

    // Text, FontSize, Anchor, etc
    // FillColor
    this.Stroke = element.Stroke;
    this.StrokeOpacity = element.StrokeOpacity;

    // FontFamily
    this.FontFamily = element.FontFamily;
    // FontSize
    this.FontSize = element.FontSize;

    // IsBold
    this.IsBold = element.IsBold;

    // Bold
    this.FontWeight = element.FontWeight;

    // FontStyle
    this.FontStyle = element.FontStyle;

    // Italic
    this.Italic = element.Italic;

    // Strikethrough
    this.Strikethrough = element.Strikethrough;

    // Underline
    this.Underline = element.Underline;

    // TextDecoration
    this.TextDecoration = element.TextDecoration;

    // Text Lines
    for (let i = 0; i < element.TextLines.length; i++) {
      const textLine: TextLine = element.TextLines[i];
      this.addTextLine(textLine.Text, textLine.X, textLine.Y);
    }

    // Text
    this.Text = element.Text;

    this.CopyEvaluations(element);

    this.IsCopying = false;
  }

  public UpdateAdornerDimensions(): void {
    if (this.TextType === GmsTextType.Icon) {
      this.AdornerHeight = this.IconHeight ? this.IconHeight : this.Height;
      this.AdornerWidth = this.IconWidth ? this.IconWidth : this.Width;
      this.AdornerX = this._isIcon ? this.IconX : this.X;
      this.AdornerY = this._isIcon ? this.IconY : this.Y;
      return;
    }

    const background: boolean = this.hasBackground();

    // calculate height of adorner based on text height
    const textHeight: number = this._textLines.length * (this._lineHeight + this._gapHeight) - (2 * this._gapHeight);
    this.AdornerHeight = background && textHeight < this.Height ? this.Height : textHeight;
    // get max width of all textlines
    let maxWidth = 0;
    this._textLines.forEach((textLine: TextLine) => {
      if (!!textLine) {
        const w: number = this.measureTextWidth(textLine.Text);
        if (w > maxWidth) {
          maxWidth = w;
        }
      }
    });

    /*
         * DSB: rely on internal measureWidth method to determine width of text lines
        let spaceWidth: number = this.measureTextWidth(" "); // this._context.measureText(" ").width;
        let fontSizePxCount: number = +this.FontSize.match(/\d+/g);  // numeric portion
        let fontScaleFactor: number = fontSizePxCount / +GmsText.DEFAULT_FONTSIZE;
        */

    // set adorner width to widest text line
    this.AdornerWidth = background && maxWidth < this.Width ? this.Width : maxWidth; // + GmsText.DEFAULT_TEXT_ADORNER_PADDING;

    // adjust adorner X in case of Center or Right alignment
    switch (this._horizontalAlignment) {
      case GmsTextAlignmentType.Center:
        this.AdornerX = this.Width / 2 - this.AdornerWidth / 2;
        break;
      case GmsTextAlignmentType.Right:
        this.AdornerX = this.Width - this.AdornerWidth;
        break;
      default:
        break;
    }

    // adjust adorner Y in case of Top, Center, or Bottom alignment
    switch (this.VerticalAlignment) {
      case GmsTextAlignmentType.Top:
        this.AdornerY = background ? 0 : this._gapHeight;
        break;
      case GmsTextAlignmentType.Center:
        this.AdornerY = this.Height / 2 - this.AdornerHeight / 2;
        break;
      case GmsTextAlignmentType.Bottom:
        this.AdornerY = this.Height - this.AdornerHeight;
        break;
      default:
        break;
    }
  }

  protected UpdateErrorBorder(): void {
    // Text element shows #COM text, regardless of the text type (icon, text, raw value, etc)
    super.UpdateErrorBorder();
    this.UpdateText();
    this.NotifyPropertyChanged('IsIcon');
  }

  protected UpdatePropertyText(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationText = evaluation;
    }
    this.UpdateText();
  }

  protected UpdateEvaluationStatus(): void {

    super.UpdateEvaluationStatus();

    this.UpdateText();
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'Text':
        if (!!this.TraceService) {
          this.TraceService.debug(this.traceModule, 'evaluation.Property Text called ');
        }
        this._evaluationText = evaluation;
        break;

      case 'TextType':
        this._evaluationTextType = evaluation;
        break;

      case 'Wrapping':
        this._evaluationWrapping = evaluation;
        break;

      case 'Units':
        this._evaluationUnits = evaluation;
        break;

      case 'Precision':
        this._evaluationPrecision = evaluation;
        break;

      case 'FontFamily':
        this._evaluationFontFamily = evaluation;
        // Invalidate Visual
        const fontFamily: string = Evaluation.GetValue2(evaluation, this.FontFamily, PropertyType.String);
        if (fontFamily !== undefined) {
          this.FontFamily = fontFamily;
        }
        break;

      case 'FontSize':
        this._evaluationFontSize = evaluation;
        // Invalidate Visual
        const fontSize: string = Evaluation.GetValue2(evaluation, this.FontSize, PropertyType.String);
        if (fontSize !== undefined) {
          this.FontSize = fontSize;
        }
        break;

      case 'Bold':
        this._evaluationBold = evaluation;
        // Invalidate Visual
        const isBold: string = Evaluation.GetValue2(evaluation, this.IsBold, PropertyType.String);
        this.IsBold = isBold;
        break;

      case 'Italic':
        this._evaluationItalic = evaluation;
        // Invalidate Visual
        const isItalic: string = Evaluation.GetValue2(evaluation, this.Italic, PropertyType.String);
        this.Italic = isItalic;
        break;

      case 'Strikethrough':
        this._evaluationStrikethrough = evaluation;
        // Invalidate Visual
        const strikethrough: string = Evaluation.GetValue2(evaluation, this.Strikethrough, PropertyType.String);
        this.Strikethrough = strikethrough;
        break;

      case 'Underline':
        this._evaluationUnderline = evaluation;
        // Invalidate Visual
        const underline: string = Evaluation.GetValue2(evaluation, this.Underline, PropertyType.String);
        this.Underline = underline;
        break;

      case 'HorizontalTextAlignment':
        this._evaluationHorizontalTextAlignment = evaluation;
        const horizontalAlignment: string = Evaluation.GetValue2(evaluation, GmsTextAlignmentType[this._designValueHorizontalAlignment], PropertyType.String);
        this.HorizontalAlignment = GmsTextAlignmentType[horizontalAlignment];
        break;

      case 'VerticalTextAlignment':
        this._evaluationVerticalTextAlignment = evaluation;
        const verticalAlignment: string = Evaluation.GetValue2(evaluation, GmsTextAlignmentType[this._designValueVerticalAlignment], PropertyType.String);
        this.VerticalAlignment = GmsTextAlignmentType[verticalAlignment];
        break;

      case 'Trimming':
        this._evaluationTrimming = evaluation;
        break;

      case 'DecimalOffset':
        this._evaluationDecimalOffset = evaluation;
        break;
      case 'FontWeight':
        this._evaluationFontWeight = evaluation;
        break;
      default:
        return;
    }

    this.UpdateText();
  }

  public UpdateText(): void {

    let text = '';

    // The Datapoint associated with the Text evaluation
    const datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;

    // Check all element's properties EvaluationStatus
    if ((this.EvaluationStatus !== DatapointStatus.Valid) ||
            (datapoint !== undefined && datapoint.Status !== DatapointStatus.Valid)) {

      // update text based on EvaluationStatus
      if ((this.EvaluationStatus === DatapointStatus.DoesNotExist) ||
                (datapoint !== undefined && datapoint.Status === DatapointStatus.DoesNotExist)) {
        // text visibility is hidden
        // #ENG: the datapoint doesn't exist
        text = ''; // GmsText.FORMAT_ENG;
      } else if ((this.EvaluationStatus === DatapointStatus.Invalid) ||
                (datapoint !== undefined && datapoint.Status === DatapointStatus.Invalid)) {
        // #COM: datapoint offline, comm error
        text = GmsText.GENERAL_ERROR;
      }
      if (text !== undefined) {
        this.FormatText(text);
      }
      this.NotifyPropertyChanged('IsIcon');
      return;
    }

    // NOTE: Workaround need to correct serialization for text type.
    const result: string = Evaluation.GetValue2(this._evaluationTextType, this._textType, PropertyType.String);
    let type: GmsTextType = GmsTextType.Number;
    if (result !== undefined) {
      if (Number.isNaN(Number(result))) {
        type = GmsTextType[result];
      } else {
        type = Number(result);
      }
    }

    // the datapoint has already the formatted value but it cannot be used when the expression is a script -> use the evaluation value and re-format
    const isScriptExpression: boolean = this._evaluationText !== undefined && this._evaluationText.Enabled &&
            !this._evaluationText.IsEmpty && this._evaluationText.Expressions[0].Semantictype === SemanticType.Script;
    if (isScriptExpression && type !== GmsTextType.Text && type !== GmsTextType.Number) {
      if (!!this.TraceService) {
        this.TraceService.warn(this.traceModule, 'A script cannot be used for TextType other than RawValue or FormattedValue');
      }
    }

    if (type === GmsTextType.Icon) {

      this.FormatText('');
      this.CalculateIcon();
    } else {
      this.IconSource = '';
      this.CalculateText(type, isScriptExpression);
    }
  }

  private GetTextType(): GmsTextType {
    const result: string = Evaluation.GetValue2(this._evaluationTextType, this._textType, PropertyType.String);
    let type: GmsTextType = GmsTextType.Number;
    if (result !== undefined) {
      if (Number.isNaN(Number(result))) {
        type = GmsTextType[result];
      } else {
        type = Number(result);
      }
    }
    return type;
  }

  private hasBackground(): boolean {
    const hasBgColor: boolean = this.Background !== 'transparent' && this.Background !== undefined;
    return hasBgColor && this.BackgroundOpacity > 0;
  }

  private onImageLoaded(image: HTMLImageElement): void {

    let x = 0;
    let y = 0;
    let width: number = image.naturalWidth;
    let height: number = image.naturalHeight;

    if (width < this.Width) {
      x = (this.Width - width) / 2;

      // Keep it, in case we will support HorizontalAlignment for image

      // switch (this.HorizontalAlignment) {
      //    case GmsTextAlignmentType.Right:
      //        x = this.Width - width;
      //        break;
      //    case GmsTextAlignmentType.Center:
      //        x = (this.Width - width) / 2;
      //        break;
      //    case GmsTextAlignmentType.Justify:
      //        width = this.Width;
      //        break;
      //    default:
      //        break;
      // }
    } else {
      width = this.Width;
    }

    if (height < this.Height) {
      y = (this.Height - height) / 2;

      // Keep it, in case we will support VerticalAlignment for image

      // switch (this.VerticalAlignment) {
      //    case GmsTextAlignmentType.Bottom:
      //        y = this.Height - height;
      //        break;
      //    case GmsTextAlignmentType.Center:
      //        y = (this.Height - height) / 2;
      //        break;
      //    case GmsTextAlignmentType.Justify:
      //        height = this.Height;
      //        break;
      //    default:
      //        break;
      // }
    } else {
      height = this.Height;
    }

    this.setImageSize(x, y, width, height);
  }

  private setImageSize(x: number = 0, y: number = 0, width: number = 0, height: number = 0): void {
    this._iconX = x;
    this._iconY = y;
    this._iconWidth = width;
    this._iconHeight = height;
    this.NotifyPropertyChanged('IconX');
    this.NotifyPropertyChanged('IconY');
    this.NotifyPropertyChanged('IconWidth');
    this.NotifyPropertyChanged('IconHeight');
    this.NotifyPropertyChanged('IconSource');
    this.UpdateAdornerDimensions();
    this.NotifyShapeChanged();
  }

  private CalculateTranslatedText(): string {

    // text should be something like "TxG_Room_Symbols_Text.1" or "System1:TxG_Room_Symbols_Text.1"
    // or "System1.ManagementView:ManagementView.FieldNetworks.Devices.Hardware.SimDev1_2.Local_IO.AO_1;"
    const text: string = Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);

    if (this._textGroupEntrySubscription !== undefined) {
      this._textGroupEntrySubscription.unsubscribe();
    }

    if (TextGroupEntryHelper.IsTextGroupReference(text)) {
      const graphic: GmsGraphic = this.Graphic as GmsGraphic;

      // query the new translated text
      if (graphic !== undefined && graphic.TextGroupService !== undefined) {
        const textGroupEntry: TextGroupEntry = graphic.TextGroupService.getTextGroupEntry(text);
        if (textGroupEntry.Status === TextGroupEntryStatus.DoesNotExist) {
          return GmsText.FORMAT_ENG;
        }
        if (textGroupEntry.Status === TextGroupEntryStatus.Resolved && textGroupEntry.LocalTextGroupEntry !== undefined) {
          // this.FormatText(textGroupEntry.LocalTextGroupEntry.Text);
          return textGroupEntry.LocalTextGroupEntry.Text;
        }

        this._textGroupEntrySubscription = textGroupEntry.propertyChanged.subscribe(arg => this.TextGroupEntry_PropertyChanged(arg, textGroupEntry));
        if (textGroupEntry.Status === TextGroupEntryStatus.Pending) {
          // wait for callback.
          return '';
        }
        const selectedObjectSystemId: string = this.SelectedObject !== undefined ? this.SelectedObject.SystemId.toString() : undefined;
        if (graphic.TextGroupService.readTextAndColorForTextGroupEntry(textGroupEntry, selectedObjectSystemId) === true) {
          // clear the current text and wait for callback.
          return '';
        } else {
          // cannot process the textGroupEntry
          textGroupEntry.Status = TextGroupEntryStatus.DoesNotExist;
          // display the #ENG value
          return GmsText.FORMAT_ENG;
        }
      }
    }
    return text;
  }

  private TextGroupEntry_PropertyChanged(arg: TextGroupEntryPropertyChangeArgs, textGroupEntry: TextGroupEntry): void {
    if (textGroupEntry.Status === TextGroupEntryStatus.Pending) {
      return;
    }
    if (this._textGroupEntrySubscription !== undefined) {

      this._textGroupEntrySubscription.unsubscribe();
      this._textGroupEntrySubscription = undefined;
    }
    if (arg === undefined || textGroupEntry === undefined) {
      return;
    }
    if (textGroupEntry.Status === TextGroupEntryStatus.Resolved) {

      textGroupEntry.LocalTextGroupEntry = arg.LocalTextGroupEntry;
      // if (arg.LocalTextGroupEntry.Color !== undefined) {

      // }
      this.FormatText(arg.LocalTextGroupEntry.Text);
    } else if (textGroupEntry.Status === TextGroupEntryStatus.DoesNotExist) {
      this.FormatText(GmsText.FORMAT_ENG);
    }
  }

  private TextGroupEntryIcon_PropertyChanged(arg: TextGroupEntryPropertyChangeArgs): void {
    if (arg === undefined || arg.IconStatus === TextGroupEntryStatus.Pending) {
      return;
    }
    if (this._textGroupEntryIconSubscription !== undefined) {

      this._textGroupEntryIconSubscription.unsubscribe();
      this._textGroupEntryIconSubscription = undefined;
    }
    if (arg.IconStatus === TextGroupEntryStatus.Resolved && arg.IconSource !== undefined) {
      if (arg.IconSource.trim() === '') {
        this.SetDefaultPropertyIcon();
      } else {
        this.IconSource = arg.IconSource;
      }
    } else if (arg.IconStatus === TextGroupEntryStatus.DoesNotExist) {
      this.IconSource = '';
    }
  }

  // There is no text group. Get Property Default Icon
  private SetDefaultPropertyIcon(): void {
    const datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;

    if (datapoint === undefined || graphic === undefined) {
      return;
    }

    if (datapoint.PropertyImage !== undefined && datapoint.PropertyImage.trim() !== '') {
      this.IconSource = datapoint.PropertyImage;
    } else {
      this._datapointIconSubscription = datapoint.propertyChanged.subscribe(args => this.Datapoint_PropertyIconChanged(args));
      graphic.PropertyImageService.readPropertyImage(datapoint);
    }
  }

  private Datapoint_PropertyIconChanged(args: DataPointPropertyChangeArgs): void {

    if (args !== undefined &&
            args.PropertyName === 'PropertyImage') {

      if (this._datapointIconSubscription !== undefined) {

        this._datapointIconSubscription.unsubscribe();
        this._datapointIconSubscription = undefined;
      }
      if (args.SourceDatapoint !== undefined && args.PropertyName === 'PropertyImage' &&
                args.SourceDatapoint.PropertyImage !== undefined &&
                this.IconSource !== this._dataImage + args.SourceDatapoint.PropertyImage) {

        if (args.SourceDatapoint.PropertyImage.trim() !== '') {
          this.IconSource = args.SourceDatapoint.PropertyImage;
        }
      }

    }
  }

  private LibraryiconSource_Changed(libraryImageChangeArgs: LibraryImageChangeArgs): void {
    if (this._libraryIconSubscription !== undefined) {

      this._libraryIconSubscription.unsubscribe();
      this._libraryIconSubscription = undefined;
    }
    this.IconSource = libraryImageChangeArgs.IconSource;
  }

  /**
   * Calculate text displayed as a formatted value
   */
  private CalculateDisplayText(isScriptExpression: boolean): string {

    let text = '';

    const datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;

    if (isScriptExpression && this._evaluationText !== undefined && this._evaluationText.RawValue === undefined) {
      return text;
    }

    // Units
    let units: string = Evaluation.GetValue2(this._evaluationUnits, this.Units, PropertyType.String);
    let noUnitsSpace = false;
    if (units !== undefined) {
      noUnitsSpace = (units.length > 0 && units.startsWith('<'));

      if (noUnitsSpace) {
        units = units.slice(1);
      }
    }
    // handle state texts, which don't require a special formatting, including the script wxpression
    // HFW doesnt support Globalization.Orch for datapoint.MultistateTexts.MultiStateRef.formatState(uintValue);
    if (datapoint !== undefined &&
            datapoint.Status === DatapointStatus.Valid) {
      if (datapoint.DatapointType === 'ExtendedBool' ||
                datapoint.DatapointType === 'BasicBool' ||
                // it allows a string data type to show a formatted value as a text
                // datapoint.DatapointType === "BasicString" ||
                // datapoint.DatapointType === "BasicChar" ||
                datapoint.DatapointType === 'ExtendedEnum' ||
                datapoint.DatapointType === 'BasicBit32') {
        if (isScriptExpression) {

          if (isNaN(+this._evaluationText.RawValue)) {
            return GmsText.FORMAT_ERROR;
          }
        }
        const state: string = datapoint.DisplayValue;
        return isScriptExpression ? this.HandleUnits(state, units, noUnitsSpace) : state;
      }
      if (datapoint.DatapointType === 'ExtendedBitString' || datapoint.DatapointType === 'ExtendedBitString64') {
        let valueArr: string[];
        let result: string = datapoint.DisplayValue;
        try {
          valueArr = JSON.parse(datapoint.DisplayValue);
        } catch {
          return result;
        }
        if (valueArr !== undefined) {
          if (valueArr.length > 0) {
            result = valueArr.join(GmsText.DEFAULT_DISPLAYVALUE_SEPARATOR);
          } else {
            const test: string = datapoint.DisplayValue;
            result = test.replace('[', '').replace(']', '').trim(); // remove array brackets
          }
        }
        return isScriptExpression ? this.HandleUnits(result, units, noUnitsSpace) : result;// result;
      }

      if (datapoint.DatapointType === 'BasicTime') {
        const basicTimeformatter: DateTimeFormatter = new DateTimeFormatter(this.locale);
        const dateTime: string = basicTimeformatter.formatDate(datapoint.Value);
        return this.HandleUnits(dateTime, units, noUnitsSpace);
      }
      if (datapoint.DatapointType === 'ExtendedDateTime') {
        const formatter: BACnetDateTimeFormatter =
                    new BACnetDateTimeFormatter(this.locale,
                      datapoint.BACnetDateTimeResolution,
                      datapoint.BACnetDateTimeDetail);
        const dateTime: string = formatter.formatBACnetDateTime(datapoint.Value);
        return this.HandleUnits(dateTime, units, noUnitsSpace);
      }
      if (datapoint.DatapointType === 'ExtendedDuration') {
        const value: number = FormatHelper.StringToNumber(datapoint.Value);
        if (value === undefined) {
          return '';
        }
        const durationFormatter: DurationFormatter = new DurationFormatter(this.locale,
          datapoint.DurationValueUnits,
          datapoint.DurationDisplayFormat,
          undefined);

        const duration: string = durationFormatter.formatDuration(value);
        if (datapoint !== undefined && (units === undefined || units.length < 1)) {
          units = datapoint.Units;
        }
        return units !== undefined ? this.HandleUnits(duration, units, noUnitsSpace) : duration;
      }
    }

    // FormatNumber
    // Precision
    const precision: number = Evaluation.GetValue2(this._evaluationPrecision, this.Precision, PropertyType.Number);

    if (units === undefined || units.length === 0) {
      if (datapoint !== undefined) {
        // datapoint.Units - it is an optional value
        units = datapoint.Units === undefined ? '' : datapoint.Units;
      } else {
        units = undefined;
      }
    }
    // Get Raw Value
    const rawValue: string = (this._evaluationText !== undefined && (isScriptExpression ||
            (datapoint !== undefined && datapoint.GmsType >= GmsDataType.PvssUint64 && datapoint.GmsType <= GmsDataType.GmsUint64))) ?
      this._evaluationText.RawValue : Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);

    if (isScriptExpression) {
      // Don't use the datapoint precision to format
      // Consider the units from the datapoint, if available
      let evaluationPrecision: number = Evaluation.GetValue2(this._evaluationPrecision, this.Precision, PropertyType.Number);
      if (evaluationPrecision === GmsText.DEFAULT_PRECISION && datapoint !== undefined) {
        evaluationPrecision = datapoint.Precision;
      }
      if (evaluationPrecision !== GmsText.DEFAULT_PRECISION) {
        text = this.FormatValue_Internal(GmsDataType.None, null, rawValue, evaluationPrecision, units, noUnitsSpace);
      } else {
        // handle units
        text = this.HandleUnits(rawValue, units, noUnitsSpace);
      }
      return text;
    }

    if (rawValue !== undefined) {
      // custom formating of floating values and 64bites data types
      if (datapoint !== undefined) {
        text = this.FormatValue_Internal(datapoint.GmsType, datapoint, rawValue, precision, units, noUnitsSpace);
      } else {
        text = this.FormatValue_Internal(GmsDataType.None, null, rawValue, precision, units, noUnitsSpace);
      }
      return text;
    } else {
      if (this._evaluationText === undefined) {
        text = this.Text;
      } else if (datapoint !== undefined) {
        text = datapoint.DisplayValue;
      }
    }
    // handle the case where no format properties are set
    if (units === undefined && precision === GmsText.DEFAULT_PRECISION) {
      if (datapoint !== undefined && rawValue !== undefined) {
        return this.FormatValue_Internal(GmsDataType.GmsReal,
          null, rawValue, precision, units, noUnitsSpace);
      }

      if (this._evaluationText === undefined) {
        // there is no animation for the Text property so just return the design value
        return this.Text;
      }
      if (this._evaluationText.RawValue !== undefined) {
        return this._evaluationText.RawValue.toString();
      }
      // no evaluation, plain text
      return this.Text;
    }
    // handle units
    if (text !== undefined && units !== undefined) {
      text += (noUnitsSpace ? '' : ' ') + units;
    }
    return text;
  }

  private CalculateUnits(): string {
    const datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;
    return datapoint !== undefined && datapoint.Units !== undefined ? datapoint.Units : '';
  }

  private GetDataPoint(designation: string): Datapoint {
    let datapoint: Datapoint;
    designation = GraphicsDatapointHelper.RemoveLeadingSemicolons(designation);
    if (designation !== '') {
      datapoint = this.DatapointService.GetOrCreateByDesignation(designation, false);
    }
    return datapoint;
  }

  private CalculateMinMax(isMinValue: boolean): string {
    const datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;
    if (datapoint !== undefined) {
      if (this.isLongDataPointType(datapoint.DatapointType)) {
        return isMinValue ? FormatHelper.formatNumber(datapoint.MinLongValue
          , this.locale) : FormatHelper.formatNumber(datapoint.MaxLongValue, this.locale);
      } else {
        return isMinValue ? datapoint.MinValue.toString() : datapoint.MaxValue.toString();
      }
    }
    return '';
  }

  private isLongDataPointType(dataType: string): boolean {
    const res: boolean = (dataType === 'BasicInt64' || dataType === 'ExtendedInt64' ||
            dataType === 'BasicUint64' || dataType === 'ExtendedUint64');

    return res;
  }

  private CalculatePropertyDescription(): string {

    const designation: string = Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);

    let datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;
    if (datapoint !== undefined && datapoint.Status > DatapointStatus.Pending) {
      // datapoint already resolved
      return datapoint.Descriptor;
    }
    // check default value
    datapoint = this.GetDataPoint(designation);
    if (datapoint !== undefined && datapoint.Status <= DatapointStatus.Pending && !this._subscriptionsDescriptor.has(datapoint.Designation)) {
      datapoint.CountUsage++;
      const subscription: Subscription = datapoint.propertyChanged.subscribe(args => this.Datapoint_PropertyChanged(args));
      this._subscriptionsDescriptor.set(datapoint.Designation, subscription);
      // return and wait for callback
      return '';
    }
    return datapoint !== undefined ? datapoint.Descriptor : '';
  }

  private CalculatePrecision(): string {
    const designation: string = Evaluation.GetValue2(this._evaluationText, this.Precision, PropertyType.Number);

    let datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;
    if (datapoint !== undefined && datapoint.Status > DatapointStatus.Pending) {
      // datapoint already resolved
      return datapoint.Precision !== undefined ? datapoint.Precision.toString() : '';
    }
    // check default value
    datapoint = this.GetDataPoint(designation);
    if (datapoint !== undefined && datapoint.Status <= DatapointStatus.Pending && !this._subscriptionsPrecision.has(datapoint.Designation)) {
      datapoint.CountUsage++;
      const subscription: Subscription = datapoint.propertyChanged.subscribe(args => this.Datapoint_PropertyChanged(args));
      this._subscriptionsPrecision.set(datapoint.Designation, subscription);
      // return and wait for callback
      return '';
    }
    return datapoint !== undefined && datapoint.Precision !== undefined ? datapoint.Precision.toString() : '';
  }

  private CalculateAliasText(): string {

    const designation: string = Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);

    let datapoint: Datapoint = this._evaluationText !== undefined ? this._evaluationText.LastDatapoint : undefined;
    if (datapoint !== undefined && datapoint.Status > DatapointStatus.Pending) {
      // datapoint already resolved
      return datapoint.Alias;
    }
    // check default value
    datapoint = this.GetDataPoint(designation);
    if (datapoint !== undefined && datapoint.Status <= DatapointStatus.Pending && !this._subscriptionsAlias.has(datapoint.Designation)) {
      datapoint.CountUsage++;
      const subscription: Subscription = datapoint.propertyChanged.subscribe(args => this.Datapoint_PropertyChanged(args));
      this._subscriptionsAlias.set(datapoint.Designation, subscription);
      // return and wait for callback
      return '';
    }
    return datapoint !== undefined ? datapoint.Alias : '';
  }

  private Datapoint_PropertyChanged(arg: DataPointPropertyChangeArgs): void {
    if (arg === undefined) {
      return;
    }
    switch (arg.PropertyName) {
      case 'Alias':
        if (this._subscriptionsAlias.has(arg.SourceDatapoint.Designation)) {
          const subscription: Subscription = this._subscriptionsAlias.get(arg.SourceDatapoint.Designation);
          if (subscription !== undefined) {
            subscription.unsubscribe();
            this._subscriptionsAlias.delete(arg.SourceDatapoint.Designation);
          }

          if (arg.SourceDatapoint !== undefined) {
            arg.SourceDatapoint.CountUsage--;
            this.FormatText(arg.SourceDatapoint.Alias);
          }
        }
        break;

      case 'Descriptor':
        if (this._subscriptionsDescriptor.has(arg.SourceDatapoint.Designation)) {
          const subscription: Subscription = this._subscriptionsDescriptor.get(arg.SourceDatapoint.Designation);
          if (subscription !== undefined) {
            subscription.unsubscribe();
            this._subscriptionsDescriptor.delete(arg.SourceDatapoint.Designation);
          }

          if (arg.SourceDatapoint !== undefined) {
            arg.SourceDatapoint.CountUsage--;
            this.FormatText(arg.SourceDatapoint.Descriptor);
          }
        }
        break;

      case 'Precision':
        if (this._subscriptionsPrecision.has(arg.SourceDatapoint.Designation)) {
          const subscription: Subscription = this._subscriptionsPrecision.get(arg.SourceDatapoint.Designation);
          if (subscription !== undefined) {
            subscription.unsubscribe();
            this._subscriptionsPrecision.delete(arg.SourceDatapoint.Designation);
          }

          if (arg.SourceDatapoint !== undefined) {
            arg.SourceDatapoint.CountUsage--;
            const precision: string = arg.SourceDatapoint.Precision !== undefined ?
              arg.SourceDatapoint.Precision.toString() : '';
            this.FormatText(precision);
          }
        }
        break;

      default:
        break;
    }
  }

  private CalculateTags(): void {
    const designation: string = Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);
    if (designation === this.Text) {
      return;
    }

    const keys: string[] = TagHelper.getKeys(designation);
    const graphic: GmsGraphic = this?.Graphic as GmsGraphic
    graphic?.TagService?.getTags(designation, keys)?.subscribe({
      next: (tagsKeyValueInfo: TagsKeyValueInfo) => {
        this.processTagsResponse(tagsKeyValueInfo);
      },
      error: err => {
        this.Graphic.TraceService.error(this.traceModule, 'GmsText.CalculateTags: ', err);
      }
    })
  }

  private processTagsResponse(tagsKeyValueInfo: TagsKeyValueInfo): void {
    const result: string = TagHelper.replacePlaceholders(this.Text, tagsKeyValueInfo);
    this.FormatText(result);
  }

  private ConvertResultToType(result: any): any {
    if (result === undefined) {
      return result;
    }

    const resultIsString = String(result);
    if (resultIsString.trim() === '') { // Empty string return it.
      return resultIsString;
    }
    const resultIsNumber = Number(result);
    if (resultIsNumber !== undefined && !isNaN(resultIsNumber)) {
      return resultIsNumber;
    }

    const booleanString: string = resultIsString.toLowerCase();
    if (resultIsString !== undefined && (booleanString === 'true' || booleanString === 'false')) {
      return booleanString === 'true';
    }

    if (resultIsString !== undefined) {
      return resultIsString;
    }

    return result;
  }

  /**
   * Calculate text displayed as a text with no formatting
   */
  private CalculateRawText(isScriptExpression: boolean): string {

    let rawValue: string = isScriptExpression ? this._evaluationText.RawValue :
      Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);
    const val = String(rawValue);
    if (val?.trim() === '' || isNaN(+rawValue) || isScriptExpression) {
      return rawValue;
    }

    const resultIsNumber = Number(rawValue);

    if (!Number.isNaN(resultIsNumber) && this._evaluationText !== undefined) {
      const datapoint: Datapoint = this._evaluationText.LastDatapoint;
      if (datapoint !== undefined) {
        if (datapoint.GmsType >= GmsDataType.PvssUint64 && datapoint.GmsType <= GmsDataType.GmsUint64) {
          rawValue = this.ParseLongValue(datapoint.GmsType, datapoint !== null ? datapoint.Status : DatapointStatus.Undefined, rawValue);
        } else {
          rawValue = this.ParseNumber(datapoint.GmsType, datapoint !== null ?
            datapoint.Status : DatapointStatus.Undefined, rawValue, datapoint.Precision, 0);
        }
      }
    }
    return rawValue === undefined ? '' : rawValue;
  }

  private CalculateShortOrLongName(isShortName: boolean): void {
    // Already subscribed - unsubscribe and initialize.
    if (this._nameSubscription !== undefined) {
      this._nameSubscription.unsubscribe();
      this._nameSubscription = undefined;
    }

    const designation: string = Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.String);
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (designation !== undefined && designation !== '') {
      const datapoint: Datapoint = this.GetDataPoint(designation);
      if (datapoint.Status > DatapointStatus.Invalid) {
        this.FormatText(GmsText.FORMAT_ERROR);
      } else if (datapoint.Status <= DatapointStatus.Pending) {
        // Make sure the datapoint is resolved before getting cns info.
        // Already subscribed - unsubscribe and initialize.
        // if (this._nameSubscription !== undefined) {
        //     this._nameSubscription.unsubscribe();
        //     this._nameSubscription = undefined;
        // }
        this._nameSubscription = datapoint.propertyChanged.pipe(takeUntil(this._cancelTextUpdate)).subscribe(args => this.NameDatapointPropertyChanged(args));
      } else if (graphic !== undefined && graphic.GmsObjectSelectionService !== undefined) {
        this._subscriptionGetBrowserObject = graphic.GmsBrowserObjectService.getObject(designation)
          .pipe(
            take(1),
            filter(_ => !!_),
            takeUntil(this._cancelTextUpdate))
          .subscribe(browserObject =>
            this.OnGetBrowserObject(browserObject, isShortName),
          error => this.OnGetBrowserObjectError(designation, error));
      }
    }
  }

  private NameDatapointPropertyChanged(arg: DataPointPropertyChangeArgs): void {
    if (arg.PropertyName === 'Status') {
      const sourceDp: Datapoint = arg.SourceDatapoint;
      const type: GmsTextType = this.GetTextType();

      if (sourceDp.Status > DatapointStatus.Pending) {
        this._nameSubscription.unsubscribe();
        this._nameSubscription = undefined;

        if (sourceDp.Status > DatapointStatus.Invalid) {
          this.FormatText(GmsText.FORMAT_ERROR);
        } else {
          const graphic: GmsGraphic = this.Graphic as GmsGraphic;
          if (graphic !== undefined && graphic.GmsObjectSelectionService !== undefined) {
            this._subscriptionGetBrowserObject = graphic.GmsBrowserObjectService.getObject(sourceDp.Designation)
              .pipe(
                take(1),
                filter(_ => !!_),
                takeUntil(this._cancelTextUpdate))
              .subscribe(browserObject =>
                this.OnGetBrowserObject(browserObject, type === GmsTextType.ShortName),
              error => this.OnGetBrowserObjectError(sourceDp.Designation, error));
          }
        }
      }
    }
  }

  private CalculateIcon(): void {

    let dynamicIconReference: string = Evaluation.GetValue2(this._evaluationText, this.Text, PropertyType.Icon);
    const datapoint: Datapoint = this._evaluationText !== undefined
            && this._evaluationText.Status > DatapointStatus.Pending ? this._evaluationText.LastDatapoint : undefined;

    // clear text group subscription
    if (this._textGroupEntryIconSubscription !== undefined) {
      this._textGroupEntryIconSubscription.unsubscribe();
      this._textGroupEntryIconSubscription = undefined;
    }

    // Clear the datapoint icon subscription
    if (this._datapointIconSubscription !== undefined) {
      this._datapointIconSubscription.unsubscribe();
      this._datapointIconSubscription = undefined;
    }

    // There is an evaluation, but the result is an empty string
    if (this._evaluationText !== undefined &&
            (dynamicIconReference === undefined || dynamicIconReference === '')) {
      // show no icon or text
      this.IconSource = '';
      return;
    }

    if (dynamicIconReference !== undefined) {

      // 1. try the text, could be something like "System1:TxG_Boolean.1" or "TxG_Boolean.0"

      const graphic: GmsGraphic = this.Graphic as GmsGraphic;
      // query the new translated text
      if (graphic !== undefined && graphic.TextGroupService !== undefined) {

        let textGroupEntry: TextGroupEntry = TextGroupEntryHelper.IsTextGroupReference(dynamicIconReference) ?
          graphic.TextGroupService.getTextGroupEntry(dynamicIconReference) : undefined;

        if (textGroupEntry !== undefined && textGroupEntry.IconStatus === TextGroupEntryStatus.Resolved && textGroupEntry.IconSource !== undefined) {
          this.IconSource = textGroupEntry.IconSource;
          return;
        }
        if (textGroupEntry !== undefined) {
          this._textGroupEntryIconSubscription = textGroupEntry.propertyChanged
            .subscribe(arg => this.TextGroupEntryIcon_PropertyChanged(arg));
        }

        if (textGroupEntry !== undefined && textGroupEntry.IconStatus === TextGroupEntryStatus.Pending) {
          return;
        } else {
          const systemId: string = this.SelectedObject !== undefined ? this.SelectedObject.SystemId.toString() : undefined;
          if (textGroupEntry !== undefined && this.ReadIcon(textGroupEntry, systemId, graphic)) {
            // clear the current text and wait for callback.
            return;
          } else {
            // if cannot read
            // cannot process the textGroupEntry
            if (this._textGroupEntryIconSubscription !== undefined) {

              this._textGroupEntryIconSubscription.unsubscribe();
              this._textGroupEntryIconSubscription = undefined;
            }

            // 2. try the text, could be something like "libraries\BA_Device_BACnet_HQ_1\Icons\Burn.ico"
            // LIBRARY_ROOT = "libraries";

            if (dynamicIconReference.startsWith('libraries')) {

              const libraryImage: LibraryImage = graphic.LibraryImageService.getLibraryImageSource(dynamicIconReference);
              if (libraryImage.isResolved && libraryImage.IconSource !== undefined) {
                this.IconSource = libraryImage.IconSource;
                return;
              } else {
                libraryImage.IconSource = '';
                this._libraryIconSubscription = libraryImage.propertyChanged.subscribe(arg => this.LibraryiconSource_Changed(arg));
              }

              const selectedObjectSystemId: number = this.SelectedObject !== undefined ? this.SelectedObject.SystemId : undefined;
              graphic.LibraryImageService.readLibraryImage(libraryImage, selectedObjectSystemId);
            }
          }
        }

        if (datapoint !== undefined &&
                    datapoint.Status === DatapointStatus.Valid) {
          if (datapoint.TextGroupName !== undefined && (datapoint.DatapointType === 'ExtendedEnum' ||
                        datapoint.DatapointType === 'ExtendedBool' ||
                        datapoint.DatapointType === 'ExtendedBitString')) {
            const value: number = Utility.ConvertToDouble(datapoint.Value);
            if (Number.isNaN(value)) {
              return;
            }
            this.IconSource = '';
            // build a request
            const designation: Designation = new Designation(datapoint.Designation);
            if (designation.systemName !== undefined) {
              dynamicIconReference = designation.systemName + ':' + datapoint.TextGroupName + '.' + value.toFixed(0);
            } else {
              dynamicIconReference = datapoint.TextGroupName + '.' + value.toFixed(0);
            }
            textGroupEntry = graphic.TextGroupService.getTextGroupEntry(dynamicIconReference);
            if (textGroupEntry.IconStatus === TextGroupEntryStatus.DoesNotExist) {
              this.IconSource = '';
              return;
            }

            if (textGroupEntry.IconStatus === TextGroupEntryStatus.Resolved && textGroupEntry.IconSource !== undefined) {
              if (textGroupEntry.IconSource.trim() === '') {
                this.SetDefaultPropertyIcon();
              } else {
                this.IconSource = textGroupEntry.IconSource;
              }

              return;
            }
            this._textGroupEntryIconSubscription = textGroupEntry.propertyChanged.subscribe(arg => this.TextGroupEntryIcon_PropertyChanged(arg));
            if (textGroupEntry.IconStatus === TextGroupEntryStatus.Pending) {
              return;
            }
            if (this.ReadIcon(textGroupEntry, value.toFixed(0), graphic) === false) {
              // if cannot read
              // unsibscribe from the update
              if (this._textGroupEntryIconSubscription !== undefined) {

                this._textGroupEntryIconSubscription.unsubscribe();
                this._textGroupEntryIconSubscription = undefined;
              }

              textGroupEntry.IconStatus = TextGroupEntryStatus.DoesNotExist;
            }
          } else {
            this.SetDefaultPropertyIcon();
          }
        }
      }
    }
    this.UpdateAdornerDimensions();
  }

  private CalculateText(type: GmsTextType, isScriptExpression: boolean): void {

    let text = '';
    const isCommandMisconfigured: boolean = (this.CommandVM != null) && !this.CommandVM.IsUIConfigurationValid;
    const isCommandOffline: boolean = (this.CommandVM != null) && !this.CommandVM.IsConnected;
    if (isCommandMisconfigured) {
      // element hidden
      return;
    }
    if (isCommandOffline) {
      this.FormatText(GmsText.GENERAL_ERROR);
      return;
    }

    switch (type) {

      case GmsTextType.Number:
        text = this.CalculateDisplayText(isScriptExpression);
        break;

      case GmsTextType.Text:
        text = this.CalculateRawText(isScriptExpression);
        break;

      case GmsTextType.ShortName:
      case GmsTextType.LongName:
        this.CalculateShortOrLongName(type === GmsTextType.ShortName);
        return;

      case GmsTextType.Units:
        text = this.CalculateUnits();
        break;

      case GmsTextType.Min:
        text = this.CalculateMinMax(true);
        break;

      case GmsTextType.Max:
        text = this.CalculateMinMax(false);
        break;

      case GmsTextType.TranslatedText:
        text = this.CalculateTranslatedText();
        break;

      case GmsTextType.PropertyDescription:
        text = this.CalculatePropertyDescription();
        break;

      case GmsTextType.Precision:
        text = this.CalculatePrecision();
        break;

      case GmsTextType.Alias:
        text = this.CalculateAliasText();
        break;
      case GmsTextType.Tags:
        this.CalculateTags();
        text = '';
        break;
      default:
        return;
    }

    if (text !== undefined) {
      this.FormatText(text);
    }
  }

  private ParseLongValue(gmsType: GmsDataType, status: DatapointStatus, rawValue: string): string {
    const numLong: Long = FormatHelper.parseLongValue(rawValue,
      gmsType !== GmsDataType.PvssInt64 && gmsType !== GmsDataType.GmsInt64);

    if (numLong === undefined && status !== DatapointStatus.Undefined) {
      return GmsText.VALUE_NOTANUMBER;
    }
    const result: string = numLong === undefined ? rawValue : FormatHelper.formatNumber(numLong, this.locale);
    return result;
  }

  private ParseNumber(gmsType: GmsDataType, status: DatapointStatus, rawValue: string, precision: number, minPrecision: number = undefined): string {
    const num: number = FormatHelper.StringToNumber(rawValue);

    if (Number.isNaN(num) && status !== DatapointStatus.Undefined && precision !== GmsText.DEFAULT_PRECISION) {
      return GmsText.VALUE_NOTANUMBER;
    }
    const result: string = Number.isNaN(num) ? rawValue : FormatHelper.NumberToString(num,
      this.locale,
      this.defaultLocale,
      precision,
      minPrecision);

    return result;
  }

  /* Append units if applicable
     */
  private HandleUnits(result: string, units: string, noUnitsSpace: boolean): string {
    // handle units
    if (result !== undefined && units !== undefined && units.trim().length > 0) {
      result += (noUnitsSpace ? '' : ' ') + units;
    }
    return result;
  }

  private FormatValue_Internal(gmsType: GmsDataType, datapoint: Datapoint, rawValue: string, precision: number,
    units: string, noUnitsSpace: boolean): string {

    let result = '';
    if (datapoint !== null && datapoint.Status === DatapointStatus.DoesNotExist) {
      return GmsText.FORMAT_ENG;
    }
    if (datapoint !== null && datapoint.Status === DatapointStatus.FormatError) {
      return GmsText.FORMAT_ERROR;
    }
    if (rawValue === undefined) {
      return result;
    }

    if (precision === GmsText.DEFAULT_PRECISION && units === undefined) {
      return rawValue;
    }
    if (precision === GmsText.DEFAULT_PRECISION) {
      precision = datapoint !== null ? datapoint.Precision : 2;
    }
    const val = String(rawValue);
    if (val?.trim() === '' || Number.isNaN(+rawValue) && datapoint !== null) {
      result = rawValue;
    } else {

      if (gmsType >= GmsDataType.PvssUint64 && gmsType <= GmsDataType.GmsUint64) {
        result = this.ParseLongValue(gmsType, datapoint !== null ? datapoint.Status : DatapointStatus.Undefined, rawValue);
      } else {
        result = this.ParseNumber(gmsType, datapoint !== null ?
          datapoint.Status : DatapointStatus.Undefined, rawValue, precision);
      }
    }
    // handle units
    if (result !== undefined && units !== undefined && units.trim().length > 0) {
      result += (noUnitsSpace ? '' : ' ') + units;
    }

    return result;
  }

  private UpdateTextDecoration(): void {
    let textDecoration = '';
    if (this.Strikethrough === 'true') {
      textDecoration = 'line-through';
    }
    if (this.Underline === 'true') {
      if (textDecoration.length > 0) {
        textDecoration += ' ';
      }
      textDecoration += 'underline';
    }

    this.TextDecoration = textDecoration;
  }

  private addTextLine(text: string, x: number, y: number): void {

    const textLine: TextLine = new TextLine(text, x, y);
    this._textLines.push(textLine);
  }

  private measureTextWidth(txt: string): number {
    let width = 0;
    if (txt !== undefined && this._context !== undefined) {
      width = this._context.measureText(txt).width;
    }
    return width;
  }

  private makeString(line: string[]): string {
    let text = '';
    for (let i = 0; i < line.length; ++i) {
      if (text.length > 0) {
        text += ' ';
      }
      text += line[i];
    }
    return text;
  }

  private UpdateContext(): void {
    if (this._context === undefined) {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      canvas.width = 2000;
      canvas.height = 2000;
      this._context = canvas.getContext('2d');
    }

    this._context.font = this.buildFont();
  }

  private buildFont(): string {
    let font = '';
    const fontstyle: string = Evaluation.GetValue2(this._evaluationFontFamily, this.FontStyle, PropertyType.String);
    const fontSize: string = Evaluation.GetValue2(this._evaluationFontSize, this.FontSize, PropertyType.String);
    const fontFamily: string = Evaluation.GetValue2(this._evaluationFontFamily, this.FontFamily, PropertyType.String);
    const fontWeight: string = Evaluation.GetValue2(this._evaluationFontWeight, this.FontWeight, PropertyType.String);

    if (fontstyle !== undefined && fontstyle.length > 0) {
      font = fontstyle;
    }
    if (fontWeight !== undefined && fontWeight.length > 0) {
      font += ' ' + fontWeight;
    }
    if (fontSize !== undefined && fontSize.length > 0) {
      if (fontSize.endsWith('px')) {
        font += ' ' + fontSize;
      } else {
        font += ' ' + fontSize + 'px';
      }
    }
    if (fontFamily !== undefined && fontFamily.length > 0) {
      font += ' ' + fontFamily;
    }

    return font;
  }

  // GmsText WrappingType
  private GetAnimatedWrappingType(): GmsTextWrappingType {
    let result: any = Evaluation.GetValue2(this._evaluationWrapping, GmsTextWrappingType[this.Wrapping], PropertyType.String);
    result = GmsTextWrappingType[result as string];
    return result;
  }

  // GmsText TrimType
  private GetAnimatedTrimType(): GmsTextTrimmingType {
    let result: any = Evaluation.GetValue2(this._evaluationWrapping, GmsTextTrimmingType[this.Trimming], PropertyType.String);
    result = GmsTextTrimmingType[result as string];
    return result;
  }

  private FormatText(text: string): void {

    // DSB: BTQ00322687 cancel async call to Cns service
    this._cancelTextUpdate.next(null);

    this._textLines.length = 0;

    if (text === undefined || text === '') {
      this.NotifyPropertyChanged('TextLines');
      return;
    }
    // 1. Update Context
    this.UpdateContext();

    // NOTE:  include padding (Decimal offset) calculations here
    const maxWidth: number = this.Width;
    const maxHeight: number = this.Height;

    const value: string = Evaluation.GetValue2(this._evaluationFontSize, this.FontSize, PropertyType.String);
    if (value === undefined) {
      return;
    }
    try {
      let fontSize: string = value;
      if (fontSize.endsWith('px')) {
        fontSize = fontSize.substring(0, fontSize.length - 2);
      }
      const validateFontSize: number = FormatHelper.StringToNumber(fontSize);
      if (Number.isNaN(validateFontSize) || validateFontSize <= 1) {
        fontSize = GmsText.DEFAULT_FONTSIZE;
      }

      // Get trimming and wrapping options
      const wrapping: GmsTextWrappingType = this.GetAnimatedWrappingType();
      const trimming: GmsTextTrimmingType = this.GetAnimatedTrimType();

      this._lineHeight = FormatHelper.StringToNumber(fontSize);
      this._gapHeight = this._lineHeight * this._lineHeightFactor;

      // lineheight exceeds max height. replace text with placeholder: "..."
      if (this._lineHeight > maxHeight &&
                (wrapping !== GmsTextWrappingType.NoWrap &&
                    trimming !== GmsTextTrimmingType.None)) {
        this.addTextLine('...', 0, 0);
        return;
      }

      // Initial text alignment values (top left corner)
      let x = 0;
      let y = 0;
      let txt = '';
      const dots = '...';
      const spaceWidth: number = this.measureTextWidth(' ');
      const dotsWidth: number = this.measureTextWidth(dots);

      // Create text lines list
      const textLines: string[][] = new Array<string[]>();

      // 2. Split text into array of lines
      const lines: string[] = text.toString().replace(/\r\n|\n\r|\r/g, '\n').split('\n');

      if ((wrapping === GmsTextWrappingType.NoWrap && trimming === GmsTextTrimmingType.None) || maxWidth === 0) {
        // nothing else to do here, just push lines
        for (let i = 0; i < lines.length; ++i) {
          const textLine: string[] = new Array<string>();
          textLine.push(lines[i]);
          textLines.push(textLine);
        }
      } else {
        let isDone = false;

        // top position of the current textLine
        y = 0;

        // 3. Split each line into array of wrapped or truncated strings
        for (let i = 0; i < lines.length; ++i) {
          // reset isDone to false - it needs to be done for multiline text
          isDone = false;
          const line: string = lines[i];
          if (line.length === 0) {
            // add empty text line
            textLines.push(new Array<string>());
            y += this._lineHeight + this._gapHeight;
            if (y >= maxHeight) {
              break;
            }

            continue;
          }

          // Split line into array of words
          const words: string[] = line.split(' ');

          // Form text lines out of words
          let startIndex = 0;
          while (startIndex < words.length && !isDone) {
            // start forming new text line
            let endIndex: number = startIndex;
            const textLine: string[] = new Array<string>();
            let lineWidth = 0;

            let splitWord = false;
            let word = '';
            let wordWidth = 0;

            while (endIndex < words.length) {
              // select word from the words array
              word = words[endIndex];
              wordWidth = this.measureTextWidth(word);
              if (lineWidth + wordWidth > maxWidth) {
                splitWord = true;
                break;
              }

              // add word to the current line
              lineWidth += wordWidth;
              textLine.push(word);
              endIndex++;

              if (lineWidth + spaceWidth > maxWidth) {
                break;
              }

              lineWidth += spaceWidth;
            }

            const availableWidth: number = maxWidth - lineWidth;
            if (splitWord) {
              // if no trim and no wrap is enforced, then do not split word, use it as is
              if (trimming === GmsTextTrimmingType.None && wrapping === GmsTextWrappingType.NoWrap) {
                splitWord = false;
              } else {
                // can't add the whole word into this line
                // We split the word, if the following conditions are satisfied:
                // 1. the word has to be splitted in the current line (textLine.length === 0 means the word itself doesn't fit the maxWidth) or
                //    there is an available space to split the current word in this line
                // 2. word is "too long" and can be splitted
                // 2. Have to split if:
                //    a) nowrap mode
                //    b) wrap and only one word in the line, which will be truncated
                splitWord = (wrapping === GmsTextWrappingType.NoWrap) || textLine.length === 0;
              }
            }

            if (splitWord) {
              // estimate number of letters staying in the current line
              let n: number = Math.max(1, Math.round(word.length * availableWidth / wordWidth));
              let direction = 0;
              let w1 = '';
              while (true) {
                w1 = word.substring(0, n);
                wordWidth = this.measureTextWidth(w1);
                if (lineWidth + wordWidth < maxWidth) {
                  if (direction === 0) {
                    direction = 1;
                  } else if (direction < 0) {
                    // stop: word is splitted
                    break;
                  }
                  n = n + 1;
                } else {
                  if (n === 1) {
                    // one letter doesn't fit: put this letter on a separate line, break loop
                    break;
                  }
                  if (direction === 0) {
                    direction = -1;
                  }
                  n = n - 1;
                  if (direction > 0) {
                    // stop: word is splitted
                    break;
                  }
                }
              }

              if (wrapping !== GmsTextWrappingType.NoWrap && trimming === GmsTextTrimmingType.None) {
                words[endIndex] = words[endIndex].substring(n);
                splitWord = false;
              } else {
                words[endIndex] = w1;
                endIndex++;
              }

              // add word to the current line
              textLine.push(w1);
            }

            // textLine is done
            y += this._lineHeight + this._gapHeight;
            if (endIndex >= words.length || (wrapping === GmsTextWrappingType.NoWrap && y + this._lineHeight > maxHeight)) {
              // no new lines will be processed
              isDone = true;
            }

            txt = this.makeString(textLine);
            let textWidth: number = this.measureTextWidth(txt);
            if (splitWord) {
              textWidth += dotsWidth;
            }

            if (textWidth > maxWidth) {
              switch (trimming) {
                case GmsTextTrimmingType.WordEllipsis:
                  if (textLine.length === 1) {
                    while (txt.length >= 1) {
                      // trim characters which do not fit maxWidth and append dots
                      txt = txt.substring(0, txt.length - 1).trim();
                      textWidth = this.measureTextWidth(txt);
                      if (textWidth + dotsWidth < maxWidth || dotsWidth >= maxWidth) {
                        txt += dots;
                        break;
                      }
                    }
                  } else {
                    // trim words which do not fit maxWidth
                    while (textLine.length > 1) {
                      // remove last word and preceding space
                      textLine.pop();
                      txt = this.makeString(textLine);
                      textWidth = this.measureTextWidth(txt);
                      if (textWidth + dotsWidth < maxWidth || dotsWidth >= maxWidth) {
                        txt += dots;
                        break;
                      }
                    }
                  }
                  break;

                case GmsTextTrimmingType.CharacterEllipsis:
                  while (txt.length >= 1) {
                    // trim characters which do not fit maxWidth and append dots
                    txt = txt.substring(0, txt.length - 2).trim();
                    textWidth = this.measureTextWidth(txt);
                    if (textWidth + dotsWidth < maxWidth || dotsWidth >= maxWidth) {
                      txt += dots;
                      break;
                    }
                  }
                  break;

                default:
                  // trim characters which do not fit maxWidth
                  const temp: string = txt;
                  while (txt.length > 1) {
                    txt = txt.substring(0, txt.length - 1);
                    textWidth = this.measureTextWidth(txt);
                    if (textWidth <= maxWidth) {
                      break;
                    }
                  }
                  break;
              }

              // replace last line
              textLine.length = 0;
              textLine.push(txt);
            }

            textLines.push(textLine);

            // move to the next line
            startIndex = endIndex;
          }
        }
      }

      // 4. align text within each textLine
      // 4.1 align vertically
      switch (this.VerticalAlignment) {
        case GmsTextAlignmentType.Top:
          y = this._gapHeight;
          break;
        case GmsTextAlignmentType.Center:
          y = (maxHeight - textLines.length * this._lineHeight - (textLines.length - 1) * this._gapHeight) / 2;
          break;
        case GmsTextAlignmentType.Bottom:
          y = maxHeight - textLines.length * this._lineHeight - (textLines.length - 1) * this._gapHeight;
          break;
        default:
          break;
      }

      // 4.2 align text in each line horizontally
      for (let lineIndex = 0; lineIndex < textLines.length; ++lineIndex) {
        const line: string[] = textLines[lineIndex];
        txt = this.makeString(line);
        x = 0;

        if (this.HorizontalAlignment === GmsTextAlignmentType.Justify) {
          if (lineIndex === textLines.length - 1) {
            // print last line as is, without justifing
            this.addTextLine(txt, x, y);
            break;
          }
          let dx = 0;
          if (line.length > 1) {
            const spaceCount: number = line.length - 1;
            dx = this.measureTextWidth(txt) - spaceCount * spaceWidth;
            dx = (maxWidth - dx) / spaceCount;
          }
          for (let i = 0; i < line.length; ++i) {
            this.addTextLine(line[i], x, y);
            x += this.measureTextWidth(line[i]) + dx;
          }
        } else {
          if (this.HorizontalAlignment !== GmsTextAlignmentType.Left) {
            x = maxWidth - this.measureTextWidth(txt);
            if (this.HorizontalAlignment === GmsTextAlignmentType.Center) {
              x = x / 2.0;
            }
          }

          this.addTextLine(txt, x, y);
        }

        y += this._lineHeight + this._gapHeight;
      }

      this.NotifyPropertyChanged('TextLines');
      this.UpdateAdornerDimensions();
    } catch (ex) {
      if (!!this.TraceService) {
        this.TraceService.debug(this.traceModule, 'GraphicsViewer: FORMATTEXT ERROR!');
      }
    }
  }

  private ReadIcon(textGroupEntry: TextGroupEntry, systemId: string, graphic: GmsGraphic): boolean {

    return graphic.TextGroupService.readIconForTextGroupEntry(textGroupEntry, systemId);
  }

  private OnGetBrowserObject(browserObject: BrowserObject, isShortName: boolean): void {
    if (this._subscriptionGetBrowserObject !== undefined) {
      this._subscriptionGetBrowserObject.unsubscribe();
      this._subscriptionGetBrowserObject = undefined;
    }

    let text = '';
    if (browserObject !== undefined) {
      const graphic: GmsGraphic = this.Graphic as GmsGraphic;
      if (graphic !== undefined && graphic.CnsHelperService !== undefined) {
        const cnsFormatOption: CnsFormatOption = isShortName ? CnsFormatOption.Short : CnsFormatOption.Long;
        text = graphic.CnsHelperService.formatBrowserObject(browserObject, cnsFormatOption);
      }
    }
    this.FormatText(text);
  }

  private OnGetBrowserObjectError(designation: string, error: Error): void {
    if (this._subscriptionGetBrowserObject !== undefined) {
      this._subscriptionGetBrowserObject.unsubscribe();
      this._subscriptionGetBrowserObject = undefined;
    }
    this.FormatText('#FORMAT');
  }
}
