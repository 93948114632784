import { EntityType, EntityTypePoint } from "../shared/base.model";
import { PointAttributes, PointBx, PointBxValueV2, PointValue } from "./point-proxy.model";

export interface PointAttributesWithValue extends PointAttributes {
  lastValue: PointValue;
}

export class PointBxWithValue implements PointBx {
  public id: string;
  public type: EntityTypePoint;
  public attributes: PointAttributesWithValue;

  constructor(point: PointBxValueV2) {
    this.id = point.id;
    this.type = EntityType.Point;
    this.attributes = point;
  }
}
