import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { Observable, of } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { EntityType } from "../shared/base.model";
import { ScheduleBx, ScheduleStatus } from "./schedule-proxy.model";

// TODO: use environment instead of hardcoded URL
// const scheduleUrl = 'https://nb.schedule-service.horizondev.cloud/api/v1/partitions';

@Injectable({
  providedIn: 'root'
})
export class ScheduleProxyService {
  public constructor(
    // private readonly httpClient: HttpClient,
    // private readonly utilityService: UtilityService,
    private readonly traceService: TraceService) {

    this.traceService.info(TraceModules.bxServicesSchedules, 'ScheduleProxyService created.');
  }

  public getSchedules(partitionId: string, entityId: string, status?: ScheduleStatus): Observable<ScheduleBx[]> {
    this.traceService.debug(TraceModules.bxServicesDevices, `ScheduleProxyService.getSchedules() called: partitionId: ${partitionId}, entityId: ${entityId}`);

    // TODO: implement
    const schedule: ScheduleBx = {
      id: crypto.randomUUID(),
      type: EntityType.Schedule,
      base: { name: 'MySchedule', description: 'MyDescription', status: 'ACTIVE' },
      attributes: undefined
    };
    return of([schedule]);

    // const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    // const url = `${scheduleUrl}/${partitionId}/entities/${entityId}`;
    // let params: HttpParams = new HttpParams();
    // if (status) {
    //   params = params.set('status', status);
    // }
    // return this.httpClient.get<ScheduleResponseBx>(url, { headers, params, observe: 'response' }).pipe(
    //   map(response => response.body.data ?? []),
    //   catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getSchedules()')));
  }
}
