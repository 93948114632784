import { isNullOrUndefined } from '@gms-flex/services-common';
import { AnyCollectionValue, ArrayItem, PriorityArrayItem, RecordItem, ValueBase } from '@simpl/element-value-types';
import { AnyProperty, CommandEvent } from '@simpl/object-browser-ng';
import * as Long from 'long';

import { ContextState } from '../view-model/snapin-vm.types';

export class Common {
  private constructor() {
    // class is intended to serve only as a namespace for static utility methods
  }

  public static readonly int32Min: number = -(2147483648);
  public static readonly int32Max: number = 2147483647;
  public static readonly uint32Min: number = 0;
  public static readonly uint32Max: number = 4294967295;
  public static readonly charMin: number = 0;
  public static readonly charMax: number = 255;
  public static readonly int64Min: Long = Long.MIN_VALUE;
  public static readonly int64Max: Long = Long.MAX_VALUE;
  public static readonly uint64Min: Long = Long.UZERO;
  public static readonly uint64Max: Long = Long.MAX_UNSIGNED_VALUE;

  public static numericTypeMin(nativeType: string): number {
    let min: number;
    switch (nativeType) {
      case 'BasicInt':
      case 'ExtendedInt':
        min = Common.int32Min;
        break;
      case 'BasicUint':
      case 'ExtendedUint':
      case 'BasicBit32':
      case 'ExtendedBitString':
        min = Common.uint32Min;
        break;
      case 'BasicChar':
        min = Common.charMin;
        break;
      case 'BasicFloat':
      case 'ExtendedReal':
      case 'ExtendedAny':
        min = Number.NEGATIVE_INFINITY;
        break;
      default:
        break;
    }
    return min;
  }

  public static numericTypeMax(nativeType: string): number {
    let max: number;
    switch (nativeType) {
      case 'BasicInt':
      case 'ExtendedInt':
        max = Common.int32Max;
        break;
      case 'BasicUint':
      case 'ExtendedUint':
      case 'BasicBit32':
      case 'ExtendedBitString':
        max = Common.uint32Max;
        break;
      case 'BasicChar':
        max = Common.charMax;
        break;
      case 'BasicFloat':
      case 'ExtendedReal':
      case 'ExtendedAny':
        max = Number.POSITIVE_INFINITY;
        break;
      default:
        break;
    }
    return max;
  }

  public static longTypeMin(nativeType: string): Long {
    let min: Long;
    switch (nativeType) {
      case 'BasicInt64':
      case 'ExtendedInt64':
        min = Common.int64Min;
        break;
      case 'BasicUint64':
      case 'ExtendedUint64':
      case 'BasicBit64':
      case 'ExtendedBitString64':
        min = Common.uint64Min;
        break;
      default:
        break;
    }
    return min;
  }

  public static longTypeMax(nativeType: string): Long {
    let max: Long;
    switch (nativeType) {
      case 'BasicInt64':
      case 'ExtendedInt64':
        max = Common.int64Max;
        break;
      case 'BasicUint64':
      case 'ExtendedUint64':
      case 'BasicBit64':
      case 'ExtendedBitString64':
        max = Common.uint64Max;
        break;
      default:
        break;
    }
    return max;
  }

  public static isEqualNumber(x: number, y: number): boolean {
    if (x === undefined || y === undefined) {
      return x === y;
    }
    if (isNaN(x) || isNaN(y)) {
      return false;
    }
    if (!isFinite(x)) {
      return !isFinite(y) ? x === y : false;
    }
    if (!isFinite(y)) {
      return false;
    }
    return Math.abs(x - y) < Number.EPSILON;
  }

  public static isEqualLong(x: Long, y: Long): boolean {
    if (x === undefined || y === undefined) {
      return x === y;
    }
    if (!Long.isLong(x) || !Long.isLong(y)) {
      return false;
    }
    return x.equals(y);
  }

  public static isEqualValueNumeric(xStr: string, yStr: string, res: number): boolean {
    if (isNullOrUndefined(xStr)) {
      return isNullOrUndefined(yStr);
    } else if (isNullOrUndefined(yStr)) {
      return false;
    }

    let x: number = parseFloat(xStr);
    let y: number = parseFloat(yStr);
    if (isNaN(x) || isNaN(y)) {
      return false; // cannot compare non-numeric!
    }

    let precision: number = Number.EPSILON;
    if (!isNaN(res)) {
      const r: number = Math.trunc(Math.max(0, res)); // must be non-negative
      precision = Math.max(Number.EPSILON, 1 / Math.pow(10, r));

      // round x & y to provided resolution
      x = Common.roundToResolution(x, r);
      y = Common.roundToResolution(y, r);
    }
    const diff: number = Math.abs(x - y) + Number.EPSILON;

    return diff < precision;
  }

  public static roundToResolution(n: number, res: number): number {
    let nRounded: number = n;
    if (!isNaN(n) && !isNaN(res)) {
      const r: number = Math.trunc(Math.max(0, Math.min(100, res))); // must be int between 0..100 for toFixed()
      nRounded = parseFloat(n.toFixed(r));
    }
    return nRounded;
  }

  /**
   * Limit a number N to a range specified by a MIN and MAX and a resolution.
   *
   * If provided value N is NaN, the MIN or MAX end of the range will be returned
   * depending on the setting of the `defaultMax` parameter.
   */
  public static limit(n: number, res: number, min: number, max: number, defaultMax?: boolean): number {
    // Force within range
    if (!isNaN(n)) {
      n = Math.min(Math.max(n, min), max);
    } else {
      n = defaultMax ? max : min;
    }

    // Round to specified resolution
    if (!isNaN(n) && !isNaN(res)) {
      // ECMA-262 only requires a precision of up to 21 significant digits
      res = Math.min(Math.max(Math.trunc(res), 0), 21);
      n = Number(n.toFixed(res)); // called on Infinity results in Infinity, so ok
    }

    return n;
  }

  /**
   * Limit a Long-integer (64-bit) N to a range specified by a MIN and MAX and a resolution.
   *
   * If provided value N is undefined, the MIN or MAX end of the range will be returned
   * depending on the setting of the `defaultMax` parameter.
   */
  public static limitLong(n: Long, min: Long, max: Long, defaultMax?: boolean): Long {
    // Force within range
    if (n) {
      n = n.lessThan(min) ? min : n;
      n = n.greaterThan(max) ? max : n;
    } else {
      n = defaultMax ? max : min;
    }
    return n;
  }

  public static isEqualArray(arr1: any[], arr2: any[]): boolean {
    if (!arr1) {
      return arr2 ? false : true;
    }
    if (!arr2) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((item, idx) => item === arr2[idx]);
  }

  /**
   * Trim the system name and delimiter from the beginning of the provided property id.
   */
  public static trimSystemName(pid: string): string {
    if (pid) {
      const pos: number = pid.indexOf(':');
      if (pos >= 0) {
        return pid.slice(pos + 1);
      }
    }
    return pid;
  }

  public static toStringProperty(p: AnyProperty, inclValue: boolean = true): string {
    let str = '';
    if (p) {
      str = `{name=${p.name}, id=${p.id}`;
      if (inclValue) {
        str += `, val=${Common.toStringValueBase(p.value)}`;
      }
      str += '}';
    }
    return str;
  }

  public static toStringCommand(c: CommandEvent): string {
    let str = '';
    if (c) {
      str = `{command=${c.command}, property=${Common.toStringProperty(c.property, false)}, params=[`;
      if (c.parameters) {
        for (let i = 0; i < c.parameters.length; ++i) {
          str += i > 0 ? ', ' : '';
          str += Common.toStringProperty(c.parameters[i]);
        }
      }
      str += ']}';
    }
    return str;
  }

  public static toStringValueBase(v: ValueBase, short?: boolean): string {
    if (!v) {
      return '';
    }
    let valueStr: string;
    if (v.type === 'collection') {
      valueStr = '[';
      const vArr: ArrayItem<ValueBase>[] | RecordItem<ValueBase>[] | PriorityArrayItem<ValueBase>[] = v.value;
      if (vArr?.length) {
        for (let i = 0; i < vArr.length; ++i) {
          valueStr += i > 0 ? ', ' : '';
          if (v.kind === 'priority-array') {
            valueStr += Common.toStringPriorityArrayItem(vArr[i] as PriorityArrayItem<ValueBase>);
          } else {
            valueStr += (v as AnyCollectionValue).collectionType === 'record' ?
              Common.toStringRecordItem(vArr[i] as RecordItem<ValueBase>) :
              Common.toStringArrayItem(vArr[i] as ArrayItem<ValueBase>);
          }
        }
      }
      valueStr += ']';
    } else {
      valueStr = String(v.value);
      if (v.value === undefined && v.altText) {
        valueStr = `<${v.altText}>`;
      }
    }
    return short ? `${valueStr}` : `{type=${v.type}, kind=${v.kind}, val=${valueStr}}`;
  }

  public static toStringPriorityArrayItem(item: PriorityArrayItem<ValueBase>): string {
    return item ? `{pos=${item.position}, val=${Common.toStringValueBase(item.value, true)}}` : '';
  }

  public static toStringRecordItem(item: RecordItem<ValueBase>): string {
    return item ? `{name=${item.name}, val=${Common.toStringValueBase(item.value, true)}}` : '';
  }

  public static toStringArrayItem(item: ArrayItem<ValueBase>): string {
    return Common.toStringValueBase(item.value, true);
  }

  public static toStringContextState(c: ContextState): string {
    let traceStr: string;
    switch (c) {
      case ContextState.Empty:
        traceStr = 'empty';
        break;
      case ContextState.SingleObject:
        traceStr = 'single';
        break;
      case ContextState.MultiObjectSame:
      case ContextState.MultiObjectSameOM:
      case ContextState.MultiObjectDifferent:
        traceStr = 'multiple';
        break;
      default:
        traceStr = '???';
        break;
    }
    return traceStr;
  }

}
