import { Event, EventFilter } from "./data.model";

/**
 * Base class for event filter service.
 * See the WSI documentation for details.
 */
export abstract class EventFilterServiceBase {

  /**
   * Evaluate if given event satisfy the given filter criteria.
   *
   * @abstract
   * @returns {boolean}
   *
   * @memberOf EventFilterServiceBase
   */
  public abstract checkForSearchText(eventFilter: EventFilter, event: Event): boolean;
}
