import { ActivatedRoute, UrlTree } from '@angular/router';
import { ModeData } from '@gms-flex/services-common';
import { BehaviorSubject, Observable } from 'rxjs';

import { FullPaneId } from '../../common/fullsnapinid/full-pane-id.model';
import { FullSnapInId } from '../../common/fullsnapinid/fullsnapinid.model';
import { IHfwMessage } from '../../common/interfaces/ihfwmessage';
import { MessageParameters } from '../../common/interfaces/message-parameters.model';
import { QParam } from '../../common/interfaces/q-param.model';
import { SnapinDisplay } from '../../common/interfaces/snapin-display/snapin-display';
import { UnsavedDataReason } from '../../common/unsaved-data';
import { FrameStore, HfwFrame, Pane, PaneStore, PrimaryBarConfig, SnapInStore, VerticalBarConfig } from '../../core/shared';
import { SnapinMessageBroker } from '../../core/snapinmessagebroker/snapinmessagebroker.service';
import { AppStatus } from '../../core/state/app-status.model';
import { HfwState } from '../../core/state/hfw-state';
import { QParamChange } from '../../core/state/q-param-change.model';
import { IPreselectionService } from './ipreselection.service';
import { IQParamService } from './iqparam.service';
import { IStorageService } from './istorage.service';

/**
 * The interface exposed by the StateService to remove circular dependency.
 */
export abstract class IStateService {

  public abstract currentState: HfwState;

  public abstract appStatus: AppStatus;
  
  public abstract qParamChangeDetected: BehaviorSubject<QParamChange>;
  
  public abstract backButton: boolean;

  public abstract updatePaneFromExternNavigate(frameId: string, paneId: string, newSnapinFullId: any): void;
  
  public abstract updatePaneDeactivatedFromNavigate(frameId: string, paneId: string, hostingLayoutId: string): void;

  public abstract getPaneById(paneId: string, frameId: string): Pane | null;
  
  public abstract navigateToFrameViewLayout(frameId: string, viewId: string, layoutId: string | null): Observable<boolean>;
  
  public abstract getCurrentPaneStores(): PaneStore[];

  public abstract checkUnsaved(targetPanes: PaneStore[], reason: UnsavedDataReason): Observable<boolean>;
  
  public abstract getLayoutIdWhenClosed(frame: FrameStore, paneId: string): string | null;

  public abstract navigateToSnapId(fullPaneId: FullPaneId, snapInId: string): Observable<boolean>;
  
  public abstract getFrames(): HfwFrame[] | null;
  
  public abstract activateQParamSubscription(activatedRoute: ActivatedRoute): void;
  
  public abstract updateStateAfterFrameChange(newFrameId: string): void;
  
  public abstract selectView(frameId: string, viewId: string): Observable<boolean>;
  
  public abstract getCurrentLayoutId(frameId: string): Observable<string>;
  
  public abstract getCurrentMode(): Observable<ModeData | null>;
  
  public abstract lockUnlockLayout(frameId: string): void;
  
  public abstract switchToNextFrame(frameId: string, msgBroker: IHfwMessage, message?: MessageParameters | undefined): Observable<boolean>;
  
  public abstract changeMode(mode: ModeData, msgBroker: SnapinMessageBroker,
    preferredFrameId?: string | undefined, firstSelectionObj?: any): Observable<boolean>;
  
  public abstract fullScreenSnapin(location: FullPaneId, fullScreen: boolean): void;
  
  public abstract triggerLogout(skipUnsavedData: boolean, isInactivityLogout: boolean): void;
  
  public abstract canChangeUserRoles(): Observable<boolean>;
  
  public abstract resetFrameSettingsPerSize(frameId: string): Observable<boolean>;
  
  public abstract displaySnapInTab(snapins: SnapinDisplay[], context?: any): Observable<boolean>;
  
  public abstract getUpdatingLocation(fullId: FullSnapInId): FullPaneId;
  
  public abstract getUrlTreeOfCurrentState(skipQParam?: boolean, specificState?: HfwState | undefined): UrlTree;
  
  public abstract cleanTargetSnapinsState(targetSnapIns: Map<string, SnapInStore>): void;
  
  public abstract pushNewSelectionQParam(value: QParam | null, state: HfwState): void;
  
  public abstract navigateToMultipleSelection(isWorkAreaChangingLayout: boolean): void;
  
  public abstract selectFirstAvailableLayoutForPane(paneFullId: FullPaneId): void;
  
  public abstract getPaneWontSurvive(frameId: string, layoutId: string): PaneStore[];
  
  public abstract isLogOutCalling(): boolean;
  
  public abstract updateLayoutFromExternNavigate(frameId: string, viewId: string, layoutId: string): void;
  
  public abstract updateViewFromExternNavigate(frameId: string, viewId: string): void;
  
  public abstract getPrimaryBarConfig(): PrimaryBarConfig;
  
  public abstract getVerticalBarConfig(): VerticalBarConfig[];
  
  public abstract updatePaneDisplayability(frame: FrameStore): void;

  public abstract onNewSelectionQParamDetected(change: QParamChange): void;

  public abstract getIPreselectionService(typeId: string): IPreselectionService;

  public abstract getIStorageService(typeId: string): IStorageService;

  public abstract getIQParamService(typeId: string): IQParamService;
}
