import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SiSplitComponent, SiSplitPartComponent } from '@simpl/element-ng/split';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiMainDetailContainerComponent } from '../main-detail-container/index';

/* eslint-disable detect-bad-words/in-code, detect-bad-words/in-comment */

/**
 * @deprecated `si-main-detail-container` should be used instead
 */
@Component({
  selector: 'si-master-detail-container',
  templateUrl: '../main-detail-container/si-main-detail-container.component.html',
  styleUrl: '../main-detail-container/si-main-detail-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgTemplateOutlet,
    SiSplitComponent,
    SiSplitPartComponent,
    SiTranslateModule
  ]
})
export class SiMasterDetailContainerComponent
  extends SiMainDetailContainerComponent
  implements AfterViewInit
{
  /**
   * CSS class(es) applied to the master container. In combination with `containerClass`,
   * this allows for settings individual padding and margin values on the individual containers.
   */
  @Input() set masterContainerClass(c: string) {
    this.mainContainerClass = c;
    this.cdRef.markForCheck();
  }
  get masterContainerClass(): string {
    return this.mainContainerClass;
  }

  /**
   * The percentage width of the master container from the overall component width.
   * Can be a number or `'default'`, which is 32% when {@link resizableParts} is active, otherwise 50%.
   */
  @Input() set masterContainerWidth(v: number | 'default') {
    this.mainContainerWidth = v;
    this.cdRef.markForCheck();
  }
  get masterContainerWidth(): number | 'default' {
    return this.mainContainerWidth;
  }

  /**
   * Sets the minimal width of the master container in pixel.
   */
  @Input() set minMasterSize(v: number) {
    this.minMainSize = v;
    this.cdRef.markForCheck();
  }
  get minMasterSize(): number {
    return this.minMainSize;
  }

  /**
   * Emits on split changes the new percentage width of the master container from
   * the overall container width.
   */
  @Output() readonly masterContainerWidthChange = new EventEmitter<number>();

  private destroyRef = inject(DestroyRef);
  private cdRef = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    this.mainContainerWidthChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => this.masterContainerWidthChange.next(v));
  }
}
