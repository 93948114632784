import { Injectable } from '@angular/core';

import { FullSnapInId } from '../fullsnapinid';

/**
 * The interface exposed by the  Storage Service in order to save and return custom information
 */
@Injectable({
  providedIn: 'root'
})
export abstract class IStorageService {
  public typeId!: string;

  /**
   * Method that return custom information.
   * Input: FullSnapinId => frameId.snapInId
   * Output: return custom information as any
   */
  public abstract getState(fullId: FullSnapInId): any;

  /**
   * Method that save custom information.
   * Input: FullSnapinId => frameId.snapInId and custom information as state
   * Output: void
   */
  public abstract setState(fullId: FullSnapInId, state: any): void;

  /**
   * Method called only by HFW in order to clear the saved information.
   * Input: FullSnapinId => frameId.snapInId and custom information as state
   * Output: void
   */
  public abstract clearState(fullId: FullSnapInId): void;

  /**
   * Method that returns a boolean.
   * Input: FullSnapinId => frameId.snapInId
   * Output: return dirty state information as boolean
   */
  public abstract getDirtyState(fullId: FullSnapInId): boolean;

  /**
   * Method that saves dirty state information.
   * Input: FullSnapinId => frameId.snapInId
   * Output: void
   */
  public abstract setDirtyState(fullId: FullSnapInId, state: boolean): void;
}
