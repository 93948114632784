<nav class="navbar navbar-secondary" [attr.aria-label]="navAriaLabel | translate">
  <div class="navbar-collapse navbar-expand">
    <ul class="nav navbar-nav nav-tabs focus-inside" role="menu" siListInteraction>
      @for (item of items; track $index) {
        @if (item.items || item.customContent) {
          <li
            class="dropdown nav-item"
            siDropdown
            dropdownContainer="body"
            role="presentation"
            [dropdownFocusOnOpen]="false"
          >
            <si-menu-legacy-item
              #dropdownToggle="si-dropdown-toggle"
              siDropdownToggle
              itemClass="nav-link"
              [item]="item"
              [disableParentClick]="false"
              [forceActive]="active"
              [focusStylingInside]="true"
              [dropdownToggle]="dropdownToggle"
            />
            @if (item.items) {
              <si-menu-legacy
                siDropdownMenu
                class="dropdown-menu"
                position="adjacent"
                [items]="item.items"
                [isSubList]="true"
                (activeChange)="submenuActiveChange($event)"
              />
            }
            @if (item.customContent) {
              <div class="dropdown-menu custom-content" siDropdownMenu>
                <ng-content />
              </div>
            }
          </li>
        }
        @if (!item.items && !item.customContent) {
          <li role="presentation" class="nav-item">
            <si-menu-legacy-item itemClass="nav-link" [item]="item" [focusStylingInside]="true" />
          </li>
        }
      }
    </ul>
  </div>
</nav>
