import { Injectable } from '@angular/core';
import { DisciplineWithSubgroup, EventColors, LocalTextGroupEntry, ObjectTypeWithSubgroup,
  SubTables, Tables, TablesServiceBase, TextEntry, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { delay, Observable, of, throwError } from 'rxjs';

import { TextGroupBxService } from '../properties/text-group-bx.service';
import { DisciplineMapperService } from '../shared/discipline-mapper.service';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';

@Injectable()
export class TablesBxSubstituteService extends TablesServiceBase {

  private readonly categoryColors: Map<number, Map<EventColors, string>> = new Map<number, Map<EventColors, string>>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly objectTypeMapper: ObjectTypeMapperService,
    private readonly disciplineMapper: DisciplineMapperService,
    private readonly textGroupService: TextGroupBxService) {
    super();

    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService created.');
  }

  /**
   * Gets the sepecified table
   *
   * @param table Supported tables are: 'categories', 'disciplines', 'objecttypes', 'units', 'timefilter'
   * @returns
   *
   * @memberOf TablesService
   */
  public getTable(table: Tables): Observable<Map<number, string>> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getTable() called, table name: %s', table);

    return throwError(() => new Error('TablesBxSubstituteService.getTable(): Not Implemented!'));
  }

  /**
   * Gets the specified table with its subgroups from WSI.
   * See WSI documentation for more details.
   *
   * @abstract
   * @param table Supported tables are: 'disciplines', 'objecttypes'
   * @returns
   *
   * @memberOf TablesBase
   */
  public getTableWithSubgroup(table: Tables): Observable<DisciplineWithSubgroup[] | ObjectTypeWithSubgroup[]> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getTableWithSubgroup() called, table name: %s', table);

    return throwError(() => new Error('TablesBxSubstituteService.getTableWithSubgroup(): Not Implemented!'));
  }

  /**
   * Gets the specifed subtable from WSI.
   * See WSI documentation for more details.
   *
   * @param table
   * @param subTable
   * @returns
   *
   * @memberOf TablesService
   */
  public getSubTable(table: Tables, subTable: SubTables): Observable<Map<number, Map<EventColors, string>>> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getSubTable() called, table: %s, subTable : %s', table, subTable);
    return of(this.categoryColors);
  }

  public getGlobalIcon(table: Tables, index: number): Observable<string> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getGlobalIcon() called, table: %s, index: %s', table, index);

    return throwError(() => new Error('TablesBxSubstituteService.getGlobalIcon(): Not Implemented!'));
  }

  public getGlobalIconExt(table: Tables, index: number, format?: string): Observable<any> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getGlobalIconExt() called, table: %s, index: %s, format=%s', table, index, format);

    return throwError(() => new Error('TablesBxSubstituteService.getGlobalIconExt(): Not Implemented!'));
  }

  /**
   * Get the text for the specified system-global table.
   *
   * @param table One of a set of well-known global tables: 'disciplines', 'subdisciplines', 'objecttypes', 'objectsubtypes', 'categories'
   * @param includeSubText Flag indicating if sub-text (subgroup) should be included in response.
   * @returns Array of text entries for the specified table.
   */
  public getGlobalText(table: Tables, includeSubText: boolean): Observable<TextEntry[]> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getGlobalText() called, table: %s, includeSubText: %s', table, includeSubText);
    if (!((table === Tables.ObjectTypes) || (table === Tables.Disciplines) ||
    (table === Tables.ObjectSubTypes) || (table === Tables.SubDisciplines) ||
    (table === Tables.Categories))) {
      return throwError(() => new Error(`TablesBxSubstituteService.getGlobalText(): Invalid arguments, table=${table}`));
    }

    const text: TextEntry[] = [];
    if (table === Tables.Disciplines) {
      if (includeSubText) {
        /*
        // disciplines need to be disabled in OM until its modeling is clear.
        // however, as the event list shows an icon for the event discipline, we return here one disipline.
        text.push(new TextEntry(disciplineBuildingAutomationId, disciplineBuildingAutomationDescriptor, []));
        return of(text);
        */
        // TODO: remove the delay! Extremely ugly, this call needs to be synched with the partition selection!!!
        return of(this.disciplineMapper.textEntriesNested).pipe(delay(100));
      } else {
        /*
        // disciplines need to be disabled in OM until its modeling is clear.
        // however, as the event list shows an icon for the event discipline, we return here one disipline.
        text.push(new TextEntry(disciplineBuildingAutomationId, disciplineBuildingAutomationDescriptor, []));
        return of(text);
        */
        // TODO: remove the delay! Extremely ugly, this call needs to be synched with the partition selection!!!
        return of(this.disciplineMapper.textEntriesNested).pipe(delay(100));
      }
    } else if (table === Tables.ObjectTypes) {
      if (includeSubText) {
        // TODO: remove the delay! Extremely ugly, this call needs to be synched with the partition selection!!!
        // Boostrap phase until all partitions are knowm and the respective data (equipment types and disciplines) are read needs to be properly handled!!!
        return of(this.objectTypeMapper.textEntriesNested).pipe(delay(100));
      } else {
        return throwError(() => new Error('TablesBxSubstituteService.getGlobalText(): Not Implemented!'));
      }
    } else {
      return throwError(() => new Error('TablesBxSubstituteService.getGlobalText(): Not Implemented!'));
    }
  }

  public getIconForTextGroupEntry(systemId: string, tableName: string, index: string): Observable<string> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getIconForTextGroupEntry() called for: ' + tableName);

    return throwError(() => new Error('TablesBxSubstituteService.getIconForTextGroupEntry(): Not Implemented!'));
  }

  public getTextAndColorForTextGroupEntry(systemId: string, tableName: string, index: string): Observable<LocalTextGroupEntry> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getTextAndColorForTextGroupEntry() called for: ' + tableName);

    return throwError(() => new Error('TablesBxSubstituteService.getTextAndColorForTextGroupEntry(): Not Implemented!'));
  }

  public getTextAndColorForTextGroupEntries(systemId: string, textGroupName: string): Observable<LocalTextGroupEntry[]> {
    this.traceService.info(TraceModules.tables, 'TablesBxSubstituteService.getTextAndColorForTextGroupEntries() called for: ' + textGroupName);

    const txg = this.textGroupService.getTextGroupPerName(textGroupName);
    let entries: LocalTextGroupEntry[] = [];
    if (txg !== undefined) {
      const enumKeys = Object.keys(txg.enum);
      entries = enumKeys.map(key => {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        const entry: LocalTextGroupEntry = { Value: this.parseKey(key), Text: txg.enum[key].label, Color: 0 };
        return entry;
      });
    }
    return of(entries);
  }

  private parseKey(key: string): number {
    // TODO: a value should not be of type number in order to support multiple base/raw types such as boolean, number, int, string) => to be fixed
    const index = parseInt(key, 10);
    if (Number.isNaN(index)) {
      if ((key.toLowerCase() === 'true') || (key === '1')) {
        return 1;
      } else if ((key.toLowerCase() === 'false') || (key === '0')) {
        return 0;
      }
    } else {
      return index;
    }
  }
}
