import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-formly-array',
  templateUrl: './si-formly-array.component.html',
  standalone: true,
  imports: [FormlyModule, SiTranslateModule]
})
export class SiFormlyArrayComponent extends FieldArrayType {}
