import { Injectable } from '@angular/core';
import { TextEntry, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';

import { DisciplineBx, DisciplineEnumResponse } from '../../bx-services/location/enums-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class DisciplineMapperService {

  private readonly disciplinesWithIconDefined: Map<string, number> = new Map<string, number>();

  private readonly disciplineToId: Map<string, number> = new Map<string, number>();
  private readonly subDisciplineToId: Map<string, number> = new Map<string, number>();
  private readonly disciplinesNested: TextEntry[] = [];
  private readonly disciplinesNestedMap: Map<string, TextEntry> = new Map<string, TextEntry>();
  private readonly disciplinesBxPerId: Map<string, DisciplineBx> = new Map<string, DisciplineBx>();
  private disciplineNextId = 0;
  // private textEntryEquipment: TextEntry;

  constructor(private readonly traceService: TraceService) {
    this.disciplinesWithIconDefined.set('comfort', 10000);
    this.disciplinesWithIconDefined.set('fireSafety', 10001);
    this.disciplinesWithIconDefined.set('security', 10002);
    this.disciplineNextId = 10003;
  }

  public initWithDisciplinesFromEnumsService(disciplineResponses: DisciplineEnumResponse[]): void {
    disciplineResponses.forEach(disciplineRes => {
      const disciplineResSorted = disciplineRes.data.sort((obj1, obj2) => {
        if (obj1.relationships !== undefined && obj2.relationships !== undefined) {
          return 0;
        } else if (obj1.relationships === undefined && obj2.relationships !== undefined) {
          return -1;
        }
        return 1;
      });

      disciplineResSorted.forEach(disciplineBx => {
        if (!this.disciplinesBxPerId.has(disciplineBx.id)) {
          if (disciplineBx.relationships !== undefined) {
            // subdiscipline
            if (this.disciplinesNestedMap.has(disciplineBx.relationships.isSubDisciplineOf.data.id)) {
              const textEntry = this.disciplinesNestedMap.get(disciplineBx.relationships.isSubDisciplineOf.data.id);
              const idDcc = this.disciplineNextId++;
              const subTextEntry = new TextEntry(idDcc, disciplineBx.attributes.label, []);
              textEntry.subText.push(subTextEntry);
              this.disciplinesNestedMap.set(disciplineBx.id, subTextEntry);
              this.subDisciplineToId.set(disciplineBx.id, idDcc);
            } else {
              this.traceService.error(TraceModules.tables, `Parent discipline not found in BX, Id=${disciplineBx.relationships.isSubDisciplineOf.data.id}`);
            }
          } else {
            // discipline
            let idDcc: number;
            if (this.disciplinesWithIconDefined.has(disciplineBx.id)) {
              idDcc = this.disciplinesWithIconDefined.get(disciplineBx.id);
            } else {
              idDcc = this.disciplineNextId++;
            }
            this.disciplineToId.set(disciplineBx.id, idDcc);
            const textEntry = new TextEntry(idDcc, disciplineBx.attributes.label, []);
            this.disciplinesNested.push(textEntry);
            this.disciplinesNestedMap.set(disciplineBx.id, textEntry);
          }
          this.disciplinesBxPerId.set(disciplineBx.id, disciplineBx);
        }
      });
    });
  }

  public buildingXIdToDccDisciplineId(type: string): number {
    return this.disciplineToId.get(type);
  }

  public buildingXIdToDccSubDisciplineId(subType: string): number {
    return this.subDisciplineToId.get(subType);
  }

  // public getEntityType(typeId: number): string | undefined {
  //   let keyFound = undefined;
  //   this.typeToId.forEach((value, key) => {
  //     if (value === typeId) {
  //       keyFound = key;
  //     }
  //   });
  //   return keyFound;
  // }

  public get disciplineToIds(): ReadonlyMap<string, number> {
    return this.disciplineToId;
  }

  public get subDisciplineToIds(): ReadonlyMap<string, number> {
    return this.subDisciplineToId;
  }

  public get textEntriesNested(): TextEntry[] {
    return this.disciplinesNested;
  }

}
