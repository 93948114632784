import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SiTranslateService, TranslationResult } from './si-translate.service';

const arrayToRecord = (keys: string[]): Record<string, string> =>
  keys.reduce(
    (acc, cur) => {
      acc[cur] = cur;
      return acc;
    },
    {} as Record<string, string>
  );

/**
 * Pass through implementation of the {@link SiTranslateService} which is used as a default.
 *
 * @internal
 */
@Injectable({ providedIn: 'root' })
export class SiNoTranslateService extends SiTranslateService {
  override get currentLanguage(): string {
    return 'en';
  }

  override get availableLanguages(): string[] {
    return ['en'];
  }

  override set availableLanguages(lang: string[]) {}

  override setCurrentLanguage(lang: string): Observable<void> {
    return of();
  }

  override getDefaultLanguage(): string {
    return 'en';
  }

  override setDefaultLanguage(lang: string): void {}

  override translate<T extends string | string[]>(
    keys: T,
    _params?: Record<string, unknown>
  ): TranslationResult<T> {
    // "as any" due to Typescript not being able to handle type guards in combination with generics
    return typeof keys === 'string' ? keys : (arrayToRecord(keys) as any);
  }

  override translateSync<T extends string | string[]>(
    keys: T,
    params?: Record<string, unknown>
  ): TranslationResult<T> {
    return this.translate(keys, params);
  }

  override translateAsync<T extends string | string[]>(
    keys: T,
    params?: Record<string, unknown>
  ): Observable<TranslationResult<T>> {
    return of(this.translate(keys, params));
  }
}
