<div
  class="loading"
  aria-atomic="true"
  aria-live="assertive"
  [class.blocking-spinner]="isBlockingSpinner"
  [class.spinner-overlay]="isSpinnerOverlay"
>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <title>{{ ariaLabel | translate }}</title>
    <g>
      <path d="M256,156a16,16,0,0,1-16-16V80a16,16,0,0,1,32,0v60A16,16,0,0,1,256,156Z" />
      <path
        d="M314.78,175.1a16,16,0,0,1-3.54-22.35l35.27-48.54A16,16,0,1,1,372.39,123l-35.26,48.54A16,16,0,0,1,314.78,175.1Z"
      />
      <path
        d="M351.11,225.1a16,16,0,0,1,10.27-20.16l57.06-18.54a16,16,0,1,1,9.89,30.43l-57.06,18.54A16,16,0,0,1,351.11,225.1Z"
      />
      <path
        d="M351.11,286.9a16,16,0,0,1,20.16-10.27l57.06,18.54a16,16,0,1,1-9.89,30.43l-57.06-18.54A16,16,0,0,1,351.11,286.9Z"
      />
      <path
        d="M314.78,336.9a16,16,0,0,1,22.35,3.54L372.39,389a16,16,0,1,1-25.88,18.81l-35.27-48.54A16,16,0,0,1,314.78,336.9Z"
      />
      <path d="M256,356a16,16,0,0,1,16,16v60a16,16,0,0,1-32,0V372A16,16,0,0,1,256,356Z" />
      <path
        d="M197.22,336.9a16,16,0,0,1,3.54,22.35l-35.27,48.54A16,16,0,1,1,139.61,389l35.26-48.54A16,16,0,0,1,197.22,336.9Z"
      />
      <path
        d="M160.89,286.9a16,16,0,0,1-10.27,20.16L93.56,325.6a16,16,0,0,1-9.89-30.43l57.06-18.54A16,16,0,0,1,160.89,286.9Z"
      />
      <path
        d="M160.89,225.1a16,16,0,0,1-20.16,10.27L83.67,216.83a16,16,0,1,1,9.89-30.43l57.06,18.54A16,16,0,0,1,160.89,225.1Z"
      />
      <path
        d="M197.22,175.1a16,16,0,0,1-22.35-3.54L139.61,123a16,16,0,1,1,25.88-18.81l35.27,48.54A16,16,0,0,1,197.22,175.1Z"
      />
    </g>
  </svg>
</div>
