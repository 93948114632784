<div class="stepper-wrap">
  <div class="line"></div>
  <div class="p-1 stepper-list">
    <div class="stepper-header-wrap elevation-1 card p-5" [class.position-sticky]="stickyHeader">
      <!-- Header -->
      <ng-content select="[header]" />

      <!-- Subheader -->
      <div class="stepper-subheader-wrap">
        <ng-content select="[subheader]" />
      </div>

      @if ((showOnlyRequiredSwitch || showPagination) && steps.length) {
        <div>
          <hr />
          <div class="d-flex justify-content-between align-content-center">
            <!-- Show mandatory switch -->
            @if (showOnlyRequiredSwitch) {
              <div class="d-flex align-items-center">
                <div class="form-switch">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    role="switch"
                    [checked]="showOnlyRequired"
                    [attr.aria-label]="onlyRequiredSwitchLabel | translate"
                    (change)="onShowOnlyRequiredToggleHandler(!showOnlyRequired)"
                  />
                  {{ onlyRequiredSwitchLabel | translate }}
                </div>
              </div>
            }
            <!-- Prev/next buttons -->
            @if (showPagination) {
              <div>
                <button
                  type="button"
                  class="btn btn-circle btn-lg btn-secondary element-left-4 flip-rtl me-6 stepper-prev-btn"
                  [attr.aria-label]="prevBtnAriaLabel | translate"
                  [disabled]="activeStepIdx <= 0"
                  (click)="onPrevHandler()"
                ></button>
                <span class="me-8">
                  <!-- Steps current, total -->
                  {{ activeStepIdx + 1 }} / {{ actualSteps.length }}
                </span>
                <button
                  type="button"
                  class="btn btn-circle btn-lg btn-primary element-right-4 flip-rtl me-6 stepper-next-btn"
                  [attr.aria-label]="nextBtnAriaLabel | translate"
                  [disabled]="activeStepIdx >= actualSteps.length - 1"
                  (click)="onNextHandler()"
                ></button>
              </div>
            }
          </div>
        </div>
      }
    </div>

    <!-- default content -->
    <div class="stepper-content-wrap">
      <ng-content />
      <!-- Footer -->
      <div class="stepper-footer-wrap elevation-1 card p-5">
        <ng-content select="[footer]" />
      </div>
    </div>
  </div>
</div>
