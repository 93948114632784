import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BrowserObject, CnsFormatOption, CnsHelperService, CnsLabel } from '@gms-flex/services';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { Subscription } from 'rxjs';

import { GmsElement } from '../../elements/gms-element';
import { GmsGraphic } from '../../elements/gms-graphic';
import { GmsSymbolInstance } from '../../elements/gms-symbol-instance';
import { Evaluation, PropertyType } from '../../processor/evaluation';
import { GmsBrowserObjectService } from '../../services/gms-browser-object.service';
import { GmsElementType } from '../../types/gms-element-types';
import { Utility } from '../../utilities/utility';

@Component({
  selector: '[gms-tooltip]',
  template: `
      <ng-template #tooltipTemplate>
          <div class="text-start tooltip-inner-template">
              <div *ngFor="let tooltip of displayTooltipArr;" [innerHTML]="tooltip"></div>
          </div>
      </ng-template>
      <span *ngIf="HasTooltipContent"
            [style.display]="HasTooltipContent ? null : 'none'"
            [tooltip]="tooltipTemplate"
            [isOpen]="IsOpen"
            container="body"
            placement="top"
            boundariesElement="window"
            containerClass="graphics-tooltip">
      </span>
  `,
  styleUrl: './gms-tooltip.component.scss'
})

export class GmsTooltipComponent implements OnInit, OnDestroy {
  @Input() public graphic: GmsGraphic = undefined;
  @Input() public containerId: string = undefined;
  public svgContainerRef: HTMLElement = undefined;
  public htmlElementRef: HTMLElement = undefined;
  public displayTooltipArr: string[] = [];
  private readonly subscriptions: Subscription[] = [];
  private cnsLabelObject: CnsLabel = new CnsLabel();
  private tooltipArr: string[] = [];
  private referencesArr: string[] = [];

  public get IsOpen(): boolean {
    return !isNullOrUndefined(this?.graphic?.HoverTooltipElement);
  }

  public get HasTooltipContent(): boolean {
    return !isNullOrUndefined(this.displayTooltipArr) && this.displayTooltipArr.length > 0;
  }

  @HostBinding('style.top.px')
  public get top(): number {
    const topPos: number = this.getTopPosition();
    return topPos;
  }

  @HostBinding('style.left.px')
  public get left(): number {
    const leftPos: number = this.getLeftPosition();
    return leftPos;
  }

  public getLeftPosition(): number {
    const svgContainerRect: DOMRect = this?.svgContainerRef?.getBoundingClientRect();
    const elementRect: DOMRect = this?.htmlElementRef?.getBoundingClientRect();
    const svgContainerLeft: number = svgContainerRect?.left || 0;
    const elementLeft: number = elementRect?.left || 0;
    const elementCenterX: number = (elementRect?.width || 0) / 2;
    const leftPos: number = elementLeft - svgContainerLeft + elementCenterX;
    return leftPos;
  }

  public getTopPosition(): number {
    const svgContainerRect: DOMRect = this?.svgContainerRef?.getBoundingClientRect();
    const elementRect: DOMRect = this?.htmlElementRef?.getBoundingClientRect();
    const svgContainerTop: number = svgContainerRect?.top || 0;
    const elementTop: number = elementRect?.top || 0;
    const topPos: number = (elementTop - svgContainerTop);
    return topPos;
  }

  public clearTooltipInfo(): void {
    this.displayTooltipArr = [];
    this.tooltipArr = [];
    this.referencesArr = [];
  }

  public updateTooltip(): void {
    if (!isNullOrUndefined(this?.graphic?.HoverTooltipElement?.Id)) {
      this.htmlElementRef = document.getElementById(this.graphic.HoverTooltipElement.Id);
      this.clearTooltipInfo();
      this.cdRef.detectChanges();
      this.addTooltipFromCurrentElement();
      this.addTooltipFromParentSources(this.TooltipSources);
      for (const tooltip of this.tooltipArr) {
        if (!isNullOrUndefined(tooltip)) {
          this.displayTooltipArr.push(tooltip);
        }
      }

      this.calculateShortNames();
    }
  }

  public addToDisplayTooltipArr(value: string): void {
    let tooltipStr: string;

    if (value?.length === 0) {
      tooltipStr = undefined;
    } else if (!isNullOrUndefined(value)) {
      tooltipStr = `${value}`.split('\n').join('<br>');
    }

    if (!isNullOrUndefined(tooltipStr) && tooltipStr.length > 0) {
      this.displayTooltipArr.push(tooltipStr);
    }
  }

  public addToTooltipArr(str: string): void {
    if (!isNullOrUndefined(str) && str.length !== 0) {
      if (!this.tooltipArr.includes(str)) {
        this.tooltipArr.push(str);
      }
    }
  }

  public addToReferencesArr(str: string): void {
    if (!isNullOrUndefined(str) && str.length !== 0) {
      if (!this.referencesArr.includes(str)) {
        this.referencesArr.push(str);
      }
    }
  }

  public addTooltipFromCurrentElement(): void {
    if (!isNullOrUndefined(this?.graphic?.HoverTooltipElement?.Tooltip)) {
      this.addToTooltipArr(this.graphic.HoverTooltipElement.Tooltip);
    }

    if (!isNullOrUndefined(this?.graphic?.HoverTooltipElement?.Datapoints)) {
      for (const datapoint of this.graphic.HoverTooltipElement.Datapoints) {
        this.addToReferencesArr(datapoint.Designation);
      }
    }

    if (!isNullOrUndefined(this?.graphic?.HoverTooltipElement?.LinkReference)) {
      this.addToReferencesArr(this?.graphic?.HoverTooltipElement?.LinkReference);
    }

    if (!isNullOrUndefined(this?.graphic?.HoverTooltipElement?.CoverageAreaReference)) {
      this.addToReferencesArr(this?.graphic?.HoverTooltipElement?.CoverageAreaReference);
    }
  }

  public constructor(@Inject(DOCUMENT) private readonly document: Document,
    private readonly cdRef: ChangeDetectorRef,
    private readonly cnsHelperService: CnsHelperService,
    private readonly gmsBrowserObjectService: GmsBrowserObjectService) {
    this.subscriptions.push(
      this.cnsHelperService.activeCnsLabel.subscribe({
        next: label => {
          this.cnsLabelObject = label;
        }
      })
    );
  }

  public calculateShortNames(): void {
    if (this.referencesArr?.length === 0 && this.tooltipArr?.length === 0) {
      return;
    }

    this.subscriptions.push(
      this?.gmsBrowserObjectService?.collectBrowserObjects(this.referencesArr)
        .subscribe({
          next: (browserObjectArr: BrowserObject[]) => {
            const cnsFormatOption: CnsFormatOption = CnsFormatOption.Short;
            for (let i = 0; i < browserObjectArr.length; ++i) {
              const browserObject: BrowserObject = browserObjectArr[i];
              if (!isNullOrUndefined(browserObject)) {
                const shortName: string = this.cnsHelperService
                  .formatBrowserObject(browserObject, cnsFormatOption);
                this.addToDisplayTooltipArr(shortName);
              } else {
                const designation: string = this.referencesArr[i];
                const shortName: string = this.clipDesignation(designation);
                this.addToDisplayTooltipArr(shortName);
              }
            }
          },
          error: (error: any) => this.OnGetBrowserObjectsError(error)
        })
    );
  }

  public clipDesignation(designation: string): string {
    const periodDelimiter = '.';
    const semicolonDelimiter = ';';
    let modifiedDesignation: string = this.getAfterLastDelimiter(designation, periodDelimiter);
    modifiedDesignation = this.getBeforeLastDelimiter(modifiedDesignation, semicolonDelimiter);
    return modifiedDesignation;
  }

  public getAfterLastDelimiter(input: string, delimiter: string): string {
    const lastDelimiterIndex: number = input?.lastIndexOf(delimiter);
    let result: string = input;
    if (lastDelimiterIndex > -1) {
      result = input.substring(lastDelimiterIndex + 1);
    }

    return result;
  }

  public getBeforeLastDelimiter(input: string, delimiter: string): string {
    const lastDelimiterIndex: number = input?.lastIndexOf(delimiter);
    let result: string = input;
    if (lastDelimiterIndex > -1) {
      result = input.substring(0, lastDelimiterIndex);
    }

    return result;
  }

  public OnGetBrowserObjectsError(error: any): void {
    this?.graphic?.TraceService?.error('GmsTooltipComponent calculateShortNames', error);
  }

  public isNonEmptyTooltip(tooltip: string): boolean {
    if (isNullOrUndefined(tooltip)) {
      return false;
    }

    return tooltip?.length !== 0;
  }

  public EvaluatedTooltip(tooltipSource: GmsElement): string {
    const evaluatedTooltip: string = Evaluation.GetValue2(tooltipSource.EvaluationTooltip, undefined, PropertyType.String);
    return evaluatedTooltip;
  }

  public isNonEmptyEvaluatedTooltip(tooltipSource: GmsElement): boolean {
    const evaluatedTooltip: string = this.EvaluatedTooltip(tooltipSource);
    return this.isNonEmptyTooltip(evaluatedTooltip);
  }

  public isNonEmptyDesignValueTooltip(tooltipSource: GmsElement): boolean {
    return this.isNonEmptyTooltip(tooltipSource?.DesignValueTooltip);
  }

  public get TooltipSources(): GmsElement[] {
    const hoverTooltipElement: GmsElement = this?.graphic?.HoverTooltipElement;
    let parentElement: GmsElement = hoverTooltipElement.Parent;
    const tooltipSources: GmsElement[] = [];
    while (!isNullOrUndefined(parentElement)) {
      const isGroupElement: boolean = Utility.isGmsGroupBase(parentElement);
      if (isGroupElement) {
        const isNonEmptyDesignRes: boolean = this.isNonEmptyDesignValueTooltip(parentElement);
        const isNonEmptyEvaluationRes: boolean = this.isNonEmptyEvaluatedTooltip(parentElement);
        if (isNonEmptyDesignRes || isNonEmptyEvaluationRes) {
          tooltipSources.push(parentElement);
        }
      }

      parentElement = parentElement.Parent;
      if (parentElement?.Type === GmsElementType.Layer) {
        break;
      }
    }

    return tooltipSources;
  }

  public addTooltipFromParentSources(tooltipSources: GmsElement[]): void {
    if (tooltipSources.length !== 0) {
      this.tooltipArr = [];
      this.referencesArr = [];
    }

    for (const tooltipSource of tooltipSources) {
      const staticTooltip: string = tooltipSource.DesignValueTooltip;
      if (this.isNonEmptyTooltip(staticTooltip)) {
        this.addToTooltipArr(staticTooltip);
      }

      const evaluationTooltip: string = Evaluation.GetValue2(tooltipSource.EvaluationTooltip, undefined, PropertyType.String);
      if (this.isNonEmptyTooltip(evaluationTooltip)) {
        this.addToTooltipArr(evaluationTooltip);
      }

      if (tooltipSource.Type === GmsElementType.SymbolInstance) {
        const symbolInstance: GmsSymbolInstance = tooltipSource as GmsSymbolInstance;
        const objectRef: string = symbolInstance?.ObjectRef;
        if (this.isNonEmptyTooltip(objectRef)) {
          this.addToReferencesArr(objectRef);
        }
      }
    }
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.graphic.updateHoverTooltip.subscribe(() => this.updateTooltip()));
    this.svgContainerRef = document.getElementById(this.containerId);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
