import { Evaluation, EvaluationType, PropertyType } from '../processor/evaluation';
import { InstanceProperty } from '../processor/gms-symbol-instance-property';
import { GmsEllipseElementPropertyType, GmsPathElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { GmsEllipseType } from '../types/gms-ellipse-types';
import { FormatHelper } from '../utilities/format-helper';
import { MathUtils } from '../utilities/mathUtils';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';

export class GmsEllipse extends GmsElement {

  private _evaluationEllipseType: Evaluation;
  private _evaluationStartAngle: Evaluation;
  private _evaluationEndAngle: Evaluation;
  private _evaluationIsArcClosed: Evaluation;

  private _designValueEllipseType: GmsEllipseType = GmsEllipseType.Ellipse;
  private _designValueStartAngle: number = Number.NaN;
  private _designValueEndAngle: number = Number.NaN;
  private _designValueIsClosed = false;

  private _ellipseType: GmsEllipseType = GmsEllipseType.Ellipse;
  public set EllipseType(value: GmsEllipseType) {

    if (this._ellipseType !== value) {
      this._ellipseType = value;

      this.NotifyPropertyChanged('EllipseType');
    }
  }
  public get EllipseType(): GmsEllipseType {
    return this._ellipseType;
  }

  private _startAngle: number = Number.NaN;
  public set StartAngle(value: number) {

    if (this._startAngle !== value) {
      this._startAngle = value;

      this.NotifyPropertyChanged('StartAngle');
    }
  }
  public get StartAngle(): number {
    return this._startAngle;
  }

  private _endAngle: number = Number.NaN;
  public set EndAngle(value: number) {

    if (this._endAngle !== value) {
      this._endAngle = value;

      this.NotifyPropertyChanged('EndAngle');
    }
  }
  public get EndAngle(): number {
    return this._endAngle;
  }

  private _isArcClosed = false;
  public set IsArcClosed(value: boolean) {

    if (this._isArcClosed !== value) {
      this._isArcClosed = value;

      this.NotifyPropertyChanged('IsArcClosed');
    }
  }
  public get IsArcClosed(): boolean {
    return this._isArcClosed;
  }

  private _radiusX: number;
  public get RadiusX(): number {
    const radX: number = this.HasStroke ? this.Width - this.StrokeWidth : this.Width;
    this._radiusX = Math.abs(radX / 2);
    return this._radiusX;
  }

  private _radiusY: number;
  public get RadiusY(): number {
    const radY: number = this.HasStroke ? this.Height - this.StrokeWidth : this.Height;
    this._radiusY = Math.abs(radY / 2);
    return this._radiusY;
  }

  constructor() {
    super(GmsElementType.Ellipse);
  }

  public get Offset(): string {
    const position: string = 'translate(' + this.Width / 2 + ',' + this.Height / 2 + ')';
    return position;
  }

  public get PathData(): string {
    let path = '';
    const centerX: number = this.Width / 2;
    const centerY: number = this.Height / 2;

    let startAngle: number = isNaN(this.StartAngle) ? 0 : this.StartAngle;
    let endAngle: number = isNaN(this.EndAngle) ? 360 : this.EndAngle;

    startAngle = (startAngle + 360) % 360; // normalize to 360
    endAngle = (endAngle + 360) % 360; // normalize to 360

    const startAngleRad: number = MathUtils.DegreesToRadians(-(startAngle - 90));
    const endAngleRad: number = MathUtils.DegreesToRadians(-(endAngle - 90));

    const xStart: number = centerX + centerX * Math.cos(startAngleRad);
    const yStart: number = centerY - centerY * Math.sin(startAngleRad);
    const xEnd: number = centerX + centerX * Math.cos(endAngleRad);
    const yEnd: number = centerY - centerY * Math.sin(endAngleRad);

    const anglesSwapped: boolean = (startAngle > endAngle) ? true : false;

    let largeFlag = 0;
    const sweepFlag = 1;
    if (Math.abs(endAngle - startAngle) >= 180 && !anglesSwapped) {
      largeFlag = 1;
    }

    if (anglesSwapped && Math.abs(endAngle - startAngle) <= 180) {
      largeFlag = 1;
    }

    path = 'M';

    if (this.EllipseType === GmsEllipseType.Pie) {
      path += centerX + ',' + centerY + ' L';
    }

    path += xStart + ',' + yStart + ' A' + centerX + ',' + centerY + ' 0 ' + largeFlag + ',' + sweepFlag + ' ' + xEnd + ',' + yEnd;

    if (this.EllipseType === GmsEllipseType.Pie || (this.EllipseType === GmsEllipseType.Arc && this.IsArcClosed)) {
      path += ' z';
    }

    return path;
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {

    let result: string = SvgUtility.GetAttributeValue(node, GmsEllipseElementPropertyType.EllipseType);
    if (result !== undefined) {
      this.EllipseType = GmsEllipseType[result] as GmsEllipseType;
      this._designValueEllipseType = this.EllipseType;
    }

    result = SvgUtility.GetAttributeValue(node, GmsEllipseElementPropertyType.StartAngle);
    if (result !== undefined) {
      this.StartAngle = FormatHelper.StringToNumber(result); // +result;
      this._designValueStartAngle = this.StartAngle;
    }

    result = SvgUtility.GetAttributeValue(node, GmsEllipseElementPropertyType.EndAngle);
    if (result !== undefined) {
      this.EndAngle = FormatHelper.StringToNumber(result); // +result;
      this._designValueEndAngle = this.EndAngle;
    }

    result = SvgUtility.GetAttributeValue(node, GmsEllipseElementPropertyType.IsArcClosed);
    if (result !== undefined) {
      this.IsArcClosed = Boolean(result);
      this._designValueIsClosed = this.IsArcClosed;
    }

    result = SvgUtility.GetAttributeValue(node, GmsPathElementPropertyType.StrokeDashArray);
    if (result !== undefined) {
      this.StrokeDashArray = result;
    }

    super.Deserialize(node);
    super.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsEllipse): void {
    this.IsCopying = true;
    this.EllipseType = element._designValueEllipseType;
    this._designValueEllipseType = element._designValueEllipseType;

    this.StartAngle = element._designValueStartAngle; // +result;
    this._designValueStartAngle = element._designValueStartAngle;

    this.EndAngle = element._designValueEndAngle; // +result;
    this._designValueEndAngle = element._designValueEndAngle;

    this.IsArcClosed = element._designValueIsClosed;
    this._designValueIsClosed = element._designValueIsClosed;

    this.StrokeDashArray = element.StrokeDashArray;

    super.CopyFrom(element);
    super.CopyEvaluations(element);
    this.IsCopying = false;
  }

  public Apply(instanceProperties: InstanceProperty[]): void {

    if (this.InternalId !== null && instanceProperties !== undefined) {
      const instanceProperty: InstanceProperty =
                instanceProperties.find((inst: InstanceProperty) => inst.SourceId === this.InternalId);
      const elementProperties: Map<string, [string, string]> = instanceProperty !== undefined ?
        instanceProperty.ElementProperties : undefined;
      // property type and value
      let propertyValue: [string, string];
      if (elementProperties !== undefined && elementProperties.size > 0) {
        // EllipseType
        propertyValue = elementProperties.get(GmsEllipseElementPropertyType.EllipseType.toString());
        if (propertyValue !== undefined) {
          const ellipseType: string = GmsEllipseType[propertyValue[1]];
          this.EllipseType = GmsEllipseType[ellipseType];
          elementProperties.delete(GmsEllipseElementPropertyType.EllipseType.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // StartAngle
        propertyValue = elementProperties.get(GmsEllipseElementPropertyType.StartAngle.toString());
        if (propertyValue !== undefined) {
          this.StartAngle = FormatHelper.StringToNumber(propertyValue[1]);
          elementProperties.delete(GmsEllipseElementPropertyType.StartAngle.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // EndAngle
        propertyValue = elementProperties.get(GmsEllipseElementPropertyType.EndAngle.toString());
        if (propertyValue !== undefined) {
          this.EndAngle = FormatHelper.StringToNumber(propertyValue[1]);
          elementProperties.delete(GmsEllipseElementPropertyType.EndAngle.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
        // IsArcClosed
        propertyValue = elementProperties.get(GmsEllipseElementPropertyType.IsArcClosed.toString());
        if (propertyValue !== undefined) {
          this.IsArcClosed = Boolean(propertyValue[1]);
          elementProperties.delete(GmsEllipseElementPropertyType.IsArcClosed.toString());
          if (elementProperties.size === 0) {
            return;
          }
        }
      }
      super.Apply(instanceProperties);
    }
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'EllipseType':
        this.UpdatePropertyEllipseType(evaluation);
        break;

      case 'StartAngle':
        this.UpdatePropertyStartAngle(evaluation);
        break;
      case 'EndAngle':
        this.UpdatePropertyEndAngle(evaluation);
        break;

      case 'IsArcClosed':
        this.UpdatePropertyIsArcClosed(evaluation);
        break;

      default:
        return;
    }
  }

  private UpdatePropertyEllipseType(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationEllipseType = evaluation;
    }
    const type: any = Evaluation.GetValue2(this._evaluationEllipseType, GmsEllipseType[this._designValueEllipseType], PropertyType.String);

    this.EllipseType = GmsEllipseType[type as string];
  }

  private UpdatePropertyStartAngle(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationStartAngle = evaluation;
    }
    const startAngle: number = Evaluation.GetValue2(this._evaluationStartAngle, this._designValueStartAngle, PropertyType.Number);

    this.StartAngle = startAngle;
  }

  private UpdatePropertyEndAngle(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationEndAngle = evaluation;
    }
    const endAngle: number = Evaluation.GetValue2(this._evaluationEndAngle, this._designValueEndAngle, PropertyType.Number);

    this.EndAngle = endAngle;
  }

  private UpdatePropertyIsArcClosed(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationIsArcClosed = evaluation;
    }
    const isArcClosed: boolean = Evaluation.GetValue2(this._evaluationIsArcClosed, this._designValueIsClosed, PropertyType.Boolean);

    this.IsArcClosed = isArcClosed;
  }

}
