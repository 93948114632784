import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsGroupElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementCursorType, GmsElementType } from '../types/gms-element-types';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';
import { GmsSymbolInstance } from './gms-symbol-instance';

export class GmsGroup extends GmsElement {

  private _isCommandGroupEnabled = false;
  private _isCommandGroupEnabledDesign = false;

  public get IsCommandGroupEnabled(): boolean {
    return this._isCommandGroupEnabled;
  }
  public set IsCommandGroupEnabled(value: boolean) {
    if (this._isCommandGroupEnabled !== value) {
      this._isCommandGroupEnabled = value;
      this.NotifyPropertyChanged('IsCommandGroupEnabled');
    }
  }

  constructor(type: GmsElementType = GmsElementType.Group) {
    super(type);

    this.children = [];
  }

  public AddChild(element: GmsElement): void {

    this.children.push(element);
    if (this.CommandVM !== null) {
      element.CommandVM = this.CommandVM;
    }
    // ChangeDetection: Let others know that a property changed
    this.NotifyPropertyChanged();
  }

  public RemoveChild(element: GmsElement): void {
    const index: number = this.children.indexOf(element, 0);
    if (index > -1) {
      element.CommandVM = null;
      this.children.splice(index, 1);
      // ChangeDetection: Let others know that a property changed
      this.NotifyPropertyChanged();
    }
  }
  public UpdateWidthOfChildren(): void {
    // ENP - updates have to be applied for childElement with InternalId!!
    if (this.Width !== this.BoundingRectDesign.Width && this.BoundingRectDesign.Width > 0) {
      const factor: number = this.Width / this.BoundingRectDesign.Width;
      this.children.forEach(childElement => {
        childElement.UpdateWidthResize(factor);

        if (childElement instanceof GmsSymbolInstance || childElement instanceof GmsGroup
        // GmsPipe doesnt support resize!!
        /* || childElement instanceof GmsPipe*/) {
          childElement.UpdateWidthOfChildren();
        }
      });
    }
  }

  public UpdateHeightOfChildren(): void {
    if (this.Height !== this.BoundingRectDesign.Height && this.BoundingRectDesign.Height > 0) {
      const factor: number = this.Height / this.BoundingRectDesign.Height;
      this.children.forEach(childElement => {
        childElement.UpdateHeightResize(factor);

        if (childElement instanceof GmsSymbolInstance || childElement instanceof GmsGroup) {
          childElement.UpdateHeightOfChildren();
        }
      });
    }
  }

  public async ShapeChanged(): Promise<any> {
    if (this.children == null) {
      return;
    }

    for (const child of this.children) {
      child.ShapeChanged();
    }

    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {
    // IsCommandGroupEnabled
    const result: string = SvgUtility.GetAttributeValue(node, GmsGroupElementPropertyType.IsCommandGroupEnabled);
    if (result !== undefined) {
      this._isCommandGroupEnabledDesign = Utility.ConvertBooleanStringToBool(result);
      this.IsCommandGroupEnabled = this._isCommandGroupEnabledDesign;
    }
    super.Deserialize(node);

    this.DeserializeEvaluations(node);
  }

  public Destroy(): void {
    if (this.children !== undefined) {
      const itemsToDestroy: GmsElement[] = this.children.slice(); // Copies the array, since destroy changes the source array
      itemsToDestroy.forEach(child => {
        child.Destroy();
      });
      itemsToDestroy.length = 0;
    }

    super.Destroy();
  }

  public CopyFrom(element: GmsGroup): void {
    this.IsCopying = true;
    this._isCommandGroupEnabledDesign = element._isCommandGroupEnabledDesign;
    this.IsCommandGroupEnabled = element.IsCommandGroupEnabled;

    super.CopyFrom(element);

    this.Graphic.ReplicationService.CopyChildren(element, this);

    this.CopyEvaluations(element);

    this.IsCopying = false;
  }
  public isCommandGroupEnabled(): boolean {
    return this.IsCommandGroupEnabled;
  }

  public PropagateAlarm(): boolean {
    let alarmPropogationHandled = false;

    // Don't propagate the alarms from
    // replication clones
    if (this.IsReplicationClone) {
      return false;
    }

    // For groups inside symbols alarms will be propagated upwards
    // to the top level symbol instance
    if (this.Parent !== undefined) {
      alarmPropogationHandled = this.Parent.PropagateAlarm();
    }

    return alarmPropogationHandled;
  }

  public GetDpsForAlarms(): Datapoint[] {
    if (!this.CalculateVisible()) {
      return [];
    }

    const ownedDpsForAlarms: Datapoint[] = super.GetDpsForAlarms();
    const propagatedDpsForAlarms: Datapoint[] = ownedDpsForAlarms;

    if (this.children.length > 0) {
      this.AllChildren.forEach(child => {
        const childDpsForAlarms: Datapoint[] = child.GetDpsForAlarms();
        // Process the datapoints only for the visible children
        if (child.Visible) {
          childDpsForAlarms.forEach(childDp => {
            if (!propagatedDpsForAlarms.includes(childDp)) {
              propagatedDpsForAlarms.push(childDp);
            }
          });
        }
      });
    }

    return propagatedDpsForAlarms;
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {
      case 'IsCommandGroupEnabled':
        const val: boolean = Evaluation.GetValue2(evaluation, this._isCommandGroupEnabledDesign, PropertyType.Boolean);
        this.IsCommandGroupEnabled = val;
        break;

      default:
        break;
    }
  }

  protected UpdateChildrenCommandVM(): void {
    if (this.CommandVM !== null && !!this.children) {
      this.SetCommandButtonExist();
      const group: GmsGroup = this.FindTopCommandGroup(this);
      if (group !== undefined || this.GetAnimatedIsCommandTriggerEnabled()) {
        // Update elements' VMs
        this.children.forEach((element: GmsElement) => {
          element.CommandVM = this.CommandVM;
          element.SetCommandButtonExist();
          if (element.IsSlidingThumb || element.IsSpinButton || this.GetAnimatedIsCommandTriggerEnabled()) {
            element.CursorType = GmsElementCursorType.Hand;
          }
        });
      }
    }
  }
  protected AddCommandButtons(): void {
    const graphic: any = this.Graphic;
    graphic.buttonsService.createButtons(this);
  }
}
