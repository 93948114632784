import { ControlEditorMode } from '../processor/command-view-model/gms-parameter-vm.base';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { Constants } from '../utilities/constants';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { GmsCommandControl } from './gms-commandcontrol';

export class GmsCommandControlString extends GmsCommandControl {

  protected static DEFAULT_MINIMUMLENGTH = 0;
  protected static DEFAULT_MAXIMUMLENGTH: number = Number.MAX_SAFE_INTEGER;

  private _evaluationMinimumLength: Evaluation;
  private _evaluationMaximumLength: Evaluation;

  private _minimumLength = 0;
  public get MinimumLength(): number {
    return this.ParameterVM == null ? this.GetAnimatedCommandParameterMinimumLength() : this.ParameterVM.minLength;
  }
  public set MinimumLength(value: number) {
    if (Number.isNaN(value) || value < GmsCommandControlString.DEFAULT_MINIMUMLENGTH) {
      value = GmsCommandControlString.DEFAULT_MINIMUMLENGTH;
    }
    if (this.ParameterVM != null) {
      this.ParameterVM.minLength = value;
      this.NotifyPropertyChanged('MinimumLength');
    }
  }

  private _maximumLength: number = Number.MAX_SAFE_INTEGER;
  public get MaximumLength(): number {
    return this.ParameterVM == null ? this.GetAnimatedCommandParameterMaximumLength() : this.ParameterVM.maxLength;
  }
  public set MaximumLength(value: number) {
    if (Number.isNaN(value) ||
            value > GmsCommandControlString.DEFAULT_MAXIMUMLENGTH ||
            value < GmsCommandControlString.DEFAULT_MINIMUMLENGTH) {

      value = GmsCommandControlString.DEFAULT_MAXIMUMLENGTH;
    }
    if (this.ParameterVM != null) {
      this.ParameterVM.maxLength = value;
      this.NotifyPropertyChanged('MaximumLength');
    }
  }

  public get FormattedValue(): string {
    if (this.ParameterVM === null) {
      return Constants.GENERAL_ERROR;
    }
    const value: string = this.IsControlInEditMode ? this.ParameterVM.FormattedValue : this.ParameterVM.Value;
    if (this.IsInHighlightMode) {
      this.SelectionStart = 0;
      this.SelectionEnd = value.length;
    }

    return value;
  }

  public set FormattedValue(value: string) {
    if (value !== undefined && this.ParameterVM !== null && this.ParameterVM.FormattedValue !== value) {
      this.ParameterVM.FormattedValue = value;
      this.NotifyPropertyChanged('FormattedValue');
    }
  }

  public setControlEditorMode(mode: ControlEditorMode): void {
    if (this.ParameterVM.setControlEditorMode(mode)) {
      if (this.IsControlInEditMode) {
        this.FormattedValue = this.ParameterVM.Value;
        this.NotifyPropertyChanged('FormattedValue');
      }

      this.NotifyPropertyChanged('IsInViewMode');
      this.NotifyPropertyChanged('IsInHighlightMode');
      this.NotifyPropertyChanged('IsControlInEditMode');

    }
  }

  constructor() {
    super(GmsCommandControlType.String);
  }

  public Deserialize(node: Node): void {

    // Text MinimumLength
    let result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.MinimumLength);
    if (result !== undefined) {
      this._minimumLength = FormatHelper.StringToNumber(result);
    }

    // Text MaximumLength
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.MaximumLength);
    if (result !== undefined) {
      this._maximumLength = FormatHelper.StringToNumber(result);
    }

    super.Deserialize(node);
  }

  public CopyFrom(element: GmsCommandControlString): void {
    super.CopyFrom(element);

    this._minimumLength = element.MinimumLength;
    this._maximumLength = element.MaximumLength;
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public onPropertyChanged(propertyName: string): void {
    if (propertyName !== undefined && propertyName.length > 0) {
      this.NotifyPropertyChanged(propertyName);
    }
  }
  public Destroy(): void {

    this._evaluationMinimumLength = undefined;

    super.Destroy();
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'MinimumLength':
        this.UpdatePropertyMinimumLength(evaluation);
        break;

      case 'MaximumLength':
        this.UpdatePropertyMaximumLength(evaluation);
        break;

      default:
        break;
    }
  }
  protected UpdatePropertyMinimumLength(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationMinimumLength = evaluation;
    }
    this.MinimumLength = this.GetAnimatedCommandParameterMinimumLength();
  }

  protected UpdatePropertyMaximumLength(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationMaximumLength = evaluation;
    }
    this.MaximumLength = this.GetAnimatedCommandParameterMaximumLength();
  }

  protected GetAnimatedCommandParameterMinimumLength(): number {
    const value: number = Evaluation.GetValue2(this._evaluationMinimumLength, this._minimumLength, PropertyType.Number);
    return value;
  }

  protected GetAnimatedCommandParameterMaximumLength(): number {
    const value: number = Evaluation.GetValue2(this._evaluationMaximumLength, this._maximumLength, PropertyType.Number);
    return value;
  }

  protected UpdateParameterVM(): void {
    super.UpdateParameterVM();

    this.UpdatePropertyMinimumLength(null);
    this.UpdatePropertyMaximumLength(null);
  }
}
