import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  numberAttribute,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { EntityStatusType, STATUS_ICON, StatusIcon } from '@simpl/element-ng/common';
import { SiIconModule } from '@simpl/element-ng/icon';
import { TranslatableString } from '@simpl/element-ng/translate';

export type AvatarSize = 'tiny' | 'xsmall' | 'small' | 'regular' | 'large' | 'xlarge';

const DATA_COLORS_MAX = 17;
const DATA_COLOR_NEUTRAL = 17;
const ASCII_CODE_INDEX_A = 64;

@Component({
  selector: 'si-avatar',
  templateUrl: './si-avatar.component.html',
  styleUrl: './si-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, SiIconModule],
  standalone: true
})
export class SiAvatarComponent implements OnChanges {
  /** Size of the component. */
  @Input() @HostBinding('class') size: AvatarSize = 'regular';
  /** Image src URL when using an image. */
  @Input() imageUrl?: string;
  /** Icon name when using an icon. */
  @Input() icon?: string;
  /**
   * Initials to be displayed as default avatar if no `icon` or `imageUrl` are provided.
   * If also no initials are provided, they will be automatically calculated from the `altText`.
   */
  @Input() initials?: string;
  /**
   * The desired color index from $element-data-* color tokens. This can be set to any kind of
   * positive integer that is then mapped to a color index.
   * A better way to set a pseudo-random color is to set * {@link autoColor} to `true`.
   */
  @Input({ transform: numberAttribute }) color?: number;
  /** The `alt` text for image, `title` for other modes. */
  @Input({ required: true }) altText!: string;
  /**
   * The status (success, info, warning, caution, danger, critical, pending, progress) to be
   * visualized.
   */
  @Input() status?: EntityStatusType;
  /**
   * aria-label for status
   */
  @Input() statusAriaLabel?: TranslatableString;
  /**
   * Automatically set the color based on the {@link initials} or {@link altText}.
   * If set, {@link color} will be ignored.
   */
  @Input({ transform: booleanAttribute }) autoColor = false;

  protected statusIcon?: StatusIcon;
  protected displayInitials?: string;

  @HostBinding('style.--background') protected backgroundStyle? = 'var(--element-data-17)';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.statusIcon = this.status ? STATUS_ICON[this.status] : undefined;
    }

    if (changes.color) {
      this.setColor(this.color);
    }

    if (changes.initials || changes.altText) {
      this.setInitials();
      this.calculateColorFromInitials();
    }
  }

  private setColor(color?: number): void {
    if (this.color === 0) {
      this.backgroundStyle = undefined;
    } else {
      const actualColor = color ? color : DATA_COLOR_NEUTRAL;
      const colorIndex = ((actualColor - 1) % DATA_COLORS_MAX) + 1;
      this.backgroundStyle = `var(--element-data-${colorIndex})`;
    }
  }

  private setInitials(): void {
    if (this.initials) {
      this.displayInitials = this.initials;
    } else {
      const parts = this.altText.split(' ');
      let first = parts.shift() ?? '';
      if (first) {
        first = first[0].toLocaleUpperCase();
      }
      let last = parts.pop() ?? '';
      if (last) {
        last = last[0].toLocaleUpperCase();
      }
      this.displayInitials = first + last;
    }
  }

  private calculateColorFromInitials(): void {
    if (!this.autoColor || !this.displayInitials) {
      return;
    }

    let color = 0;
    for (let i = 0; i < this.displayInitials.length; i++) {
      color *= 17; // this prevents 'JD' to have the same color as 'DJ'
      color += this.displayInitials.charCodeAt(i) - ASCII_CODE_INDEX_A;
    }
    this.setColor(color);
  }
}
