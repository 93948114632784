import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ApplicationRight,
  AppRightsService,
  BrowserObject,
  CnsHelperService,
  ExecuteCommandServiceBase,
  GmsManagedTypes,
  GraphicInfo,
  GraphicsService,
  Operation,
  Tables,
  TablesServiceBase,
  TagService,
  TextEntry
} from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiEmptyStateModule, SiToastNotificationService } from '@simpl/element-ng';
import { asyncScheduler, Subject, Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { EventsCommonServiceBase } from '../../../events/services/events-common.service.base';
import { ValidationDialogService } from '../../../validation-dialog/services/validation-dialog.service';
import { GraphicSnapinHldlConfig } from '../common/interfaces/GraphicSnapinHldlConfig';
import { GraphicSnapInArgs } from '../common/interfaces/GraphicsSnapinArgs';
import { TraceChannel } from '../common/trace-channel';
import { GmsGraphic } from '../elements/gms-graphic';
import { GmsLayer } from '../elements/gms-layer';
import { SvgLoader } from '../processor/gms-svgloader';
import { GraphicRelatedItems } from '../processor/graphic-related-items';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsAnimationTimerService } from '../services/gms-animation-timer.service';
import { GmsBrowserObjectService } from '../services/gms-browser-object.service';
import { ElementBrushService } from '../services/gms-brush.service';
import { GmsCommandService } from '../services/gms-command-service';
import { DataPointService } from '../services/gms-datapoint2.service';
import { LibraryImageService } from '../services/gms-library-image-service';
import { GmsObjectSelectionService } from '../services/gms-object-selection.service';
import { PropertyImageService } from '../services/gms-property-image.service';
import { GmsReplicationService } from '../services/gms-replication.service';
import { GraphicStateStorageService } from '../services/gms-storage.service';
import { GmsSystemsService } from '../services/gms-systems.service';
import { TextGroupService } from '../services/gms-text-group-service';
import { GraphicsSnapinService } from '../services/graphics-snapin.service';
import { TimerPhaseEnum, TimerService } from '../services/timer-service';
import { GraphicState } from '../shared/graphicState';
import { GraphicType } from '../types/gms-element-property-types';
import { MathUtils } from '../utilities/mathUtils';
import { GmsGraphicComponent } from './gms-graphic.component';

@Component({
  selector: 'gms-graphic-view',
  template: `
    <div #graphicContainer *ngIf="appRights && graphic !== undefined && !graphic.loadError" class="hfw-flex-container-column hfw-flex-item-grow rounded-2"
         style="background-color:#FFFFFF">
      <hfw-panel-navigation class="hfw-flex-container-column hfw-flex-item-grow"
                            (rightBtnClicked)="incrementRelatedItems()" (leftBtnClicked)="decrementRelatedItems()"
                            [hideBtns]="singleRelatedItem || isFolder">
        <gms-graphic [graphic]="graphic" *ngIf="isFolder === false"
                     class="hfw-flex-container-column hfw-flex-item-grow" />
      </hfw-panel-navigation>
    </div>
    <div *ngIf="!appRights || (graphic !== undefined && graphic.loadError)"
      class="hfw-flex-container-column hfw-flex-item-grow">
      <si-empty-state
        class="empty-list"
        icon="element-technical-operator"
        [heading]="!appRights ? noAppRightsLabel : noRelevantDataLabel"
      />
    </div>
    `,
  styles: `
  .empty-list {
    height: 100%;
    place-content: center;
    align-items: center
  }
  `,
  viewProviders: [GmsGraphicComponent]
})
export class GraphicViewComponent implements OnInit, OnDestroy {

  public graphic: GmsGraphic = undefined;
  public singleRelatedItem = true;
  public isFolder = false;
  public placeHolder: string;
  public appRights = true;
  public noRelevantDataLabel = ''; // No relevant data to display for this application.';
  public noAppRightsLabel = ''; // You dont have the show permissions for this application.';

  public _snapinArgs: GraphicSnapInArgs;
  @ViewChild('graphicContainer') public graphicContainer: ElementRef<any>;
  @Input() public snapinArgsSubject: Subject<GraphicSnapInArgs> = undefined;
  @Input() public searchPlaceHolder: string;

  public _selectedObject: BrowserObject;

  // Context for GraphicTemplate, when selection is from related items
  private _customDataObject: BrowserObject;

  // has the snapin hldl config
  @Input() private readonly _hldlConfig: GraphicSnapinHldlConfig;

  private readonly relatedItems: GraphicRelatedItems;
  private readonly traceModule: string = TraceChannel.Component;
  private _subscriptionReadGraphicsInfoCall: Subscription;
  private _subscriptionReadGraphicsContentCall: Subscription;
  private _subscriptionActiveCnsLabel: Subscription;
  private _subscriptionTranslateService: Subscription;
  private graphicsObjectId: string;
  private subscriptionSvgLoaderCall: Subscription = undefined;
  private updateGraphicViewSub: Subscription = undefined;
  private _onReadPropertiesMultiSubscription: Map<string, Subscription>;
  public restoreRelatedItemSub: Subscription;
  public onUpdateSnapinArgsSub: Subscription;
  private readonly GRAPHIC_VERSION: string = '1.0.90.161';

  constructor(
    public translateService: TranslateService,
    public gmsAdornerService: GmsAdornerService,
    private readonly graphicsService: GraphicsService,
    private readonly graphicsSnapinService: GraphicsSnapinService,
    private readonly dataPointService: DataPointService,
    private readonly textGroupService: TextGroupService,
    private readonly propertyImageService: PropertyImageService,
    private readonly libraryImageService: LibraryImageService,
    private readonly elementBrushService: ElementBrushService,
    private readonly animationTimerService: GmsAnimationTimerService,
    private readonly gmsSystemsService: GmsSystemsService,
    private readonly gmsObjectSelectionService: GmsObjectSelectionService,
    private readonly gmsBrowserObjectService: GmsBrowserObjectService,
    private readonly cnsHelperService: CnsHelperService,
    private readonly traceService: TraceService,
    private readonly ngZone: NgZone,
    private readonly timerService: TimerService,
    private readonly gmsCommandService: GmsCommandService,
    private readonly executeCommandService: ExecuteCommandServiceBase,
    public toastNotificationService: SiToastNotificationService,
    private readonly replicationService: GmsReplicationService,
    private readonly storageService: GraphicStateStorageService,
    private readonly tablesService: TablesServiceBase,
    private readonly validationDialogService: ValidationDialogService,
    private readonly tagService: TagService,
    private readonly appRightsService: AppRightsService,
    private readonly eventCommonService: EventsCommonServiceBase,
    private readonly changeDetector: ChangeDetectorRef) {

    this.graphic = new GmsGraphic();
    this.graphic.DatapointService = this.dataPointService;
    this.graphic.TextGroupService = this.textGroupService;
    this.graphic.PropertyImageService = this.propertyImageService;
    this.graphic.LibraryImageService = this.libraryImageService;
    this.graphic.ElementBrushService = this.elementBrushService;
    this.graphic.GmsSystemsService = this.gmsSystemsService;
    this.graphic.AnimationTimerService = this.animationTimerService;
    this.graphic.CnsHelperService = this.cnsHelperService;
    this.graphic.TraceService = this.traceService;
    this.graphic.Zone = this.ngZone;
    this.graphic.TimerService = this.timerService;
    this.graphic.GmsObjectSelectionService = this.gmsObjectSelectionService;
    this.graphic.GmsBrowserObjectService = this.gmsBrowserObjectService;
    this.graphic.translateService = this.translateService;
    this.graphic.GmsCommandService = this.gmsCommandService;
    this.graphic.ExecuteCommandService = this.executeCommandService;
    this.graphic.ToastNotificationService = this.toastNotificationService;
    this.graphic.ReplicationService = this.replicationService;
    this.graphic.ValidationDlgService = this.validationDialogService;
    this.graphic.TagService = this.tagService;

    this.gmsAdornerService.setAdornerService(this.graphic.adorners);
    this.relatedItems = new GraphicRelatedItems();
    this.relatedItems.currentGraphicItem.pipe(
      filter(_ => !!_)
    ).subscribe((graphicInfo: GraphicInfo) => {
      this.readGraphicContent(graphicInfo);
      this.graphic.resetDepth();
    });
  }

  @HostListener('keydown.PageUp', ['$event'])
  public incrementShortcut(e: KeyboardEvent): void {
    e.preventDefault();
    this.incrementRelatedItems();
  }

  @HostListener('keydown.PageDown', ['$event'])
  public decrementShortcut(e: KeyboardEvent): void {
    e.preventDefault();
    this.decrementRelatedItems();
  }

  @HostListener('window:keydown.O', ['$event'])
  public loggingVersionShortcut(e: KeyboardEvent): void {
    if (this.graphic.TraceService.isTraceEnabled && e.altKey && e.ctrlKey) {
      this.graphic.TraceService.info(this.traceModule, `Graphic Version ${this.GRAPHIC_VERSION}`);
    }
  }

  public ngOnInit(): void {
    this.getTranslations();
    this.appRights = this.CheckShowAppRights();
    if (this.appRights) {
      this._subscriptionActiveCnsLabel = this.cnsHelperService.activeCnsLabel.subscribe(
        cnsLabel => this.onCnsDisplayLabelChanged());
      this.gmsSystemsService.getSystems(); // Should be called once at snapin initialization.

      this.updateGraphicViewSub = this.graphic.updateGraphicView.subscribe(() => this.updateGraphicViewComponent());
      this.onUpdateSnapinArgsSub = this.snapinArgsSubject.subscribe((snapinArgs: GraphicSnapInArgs) => this.onSnapinArgsUpdate(snapinArgs));

      this.setHldlConfig();

      // Extension methods for scripting
      // eslint-disable-next-line
      Math["sum"] = MathUtils.sum;
      // eslint-disable-next-line
      Math["average"] = MathUtils.average;

      this.loadDisciplines();
    }
  }

  public saveLayerVisibility(): void {
    if (this.graphic !== undefined) {
      const layers: GmsLayer[] = this.graphic.children as GmsLayer[];
      for (const layer of layers) {
        this.graphic.visibleLayers.push(layer.Visible);
      }
    }
  }

  public ngOnDestroy(): void {
    this.traceService.info(this.traceModule, 'ngOnDestroy called.');
    // 1. Dispose services
    if (this.dataPointService !== undefined) {
      this.dataPointService.unsubscribeToEvents();
    }

    if (this._subscriptionReadGraphicsInfoCall !== undefined) {
      this._subscriptionReadGraphicsInfoCall.unsubscribe();
    }
    if (this._subscriptionReadGraphicsContentCall !== undefined) {
      this._subscriptionReadGraphicsContentCall.unsubscribe();
      this._subscriptionReadGraphicsContentCall = undefined;
    }
    if (this.subscriptionSvgLoaderCall !== undefined) {
      this.subscriptionSvgLoaderCall.unsubscribe();
    }
    if (this._subscriptionActiveCnsLabel !== undefined) {
      this._subscriptionActiveCnsLabel.unsubscribe();
    }
    if (this._subscriptionTranslateService !== undefined) {
      this._subscriptionTranslateService.unsubscribe();
    }

    this.updateGraphicViewSub?.unsubscribe();
    this.onUpdateSnapinArgsSub?.unsubscribe();
    this.gmsAdornerService.ClearAdorners();
    this.gmsObjectSelectionService.reset();

    // Holds the layer visibility values to be used with the graphic state.
    this.saveLayerVisibility();
    let selectedDisciplines: Set<string>;
    if (this.graphic !== undefined) {
      selectedDisciplines = new Set<string>(this.graphic.selectedDisciplines);
    }

    // 2. Dispose graphic
    this.ngZone.runOutsideAngular(() => {
      this.graphic.Destroy();
    });

    let relatedItemIdx: number;
    if (this.relatedItems.hasRelatedItems) {
      relatedItemIdx = this.relatedItems.relatedItemsIdx;
    }

    const searchString: string = this.graphicsSnapinService.SearchString;

    if (this._selectedObject !== undefined) {
      const state: GraphicState = new GraphicState(this._selectedObject.Designation,
        this.graphic.scrollLeft, this.graphic.scrollTop, this.graphic.svgDocumentWidth,
        this.graphic.svgDocumentHeight, this.graphic.CurrentZoomLevel,
        this.graphic.transformMatrix, relatedItemIdx, this.graphic.SelectedDepth, selectedDisciplines,
        this.graphic.visibleLayers, this.graphic.IsPermScaleToFit, this.graphic.CoverageAreaMode,
        searchString);
      this.storageService.setState(state);
    }

    // Clear the extension methods
    // eslint-disable-next-line
    Math["sum"] = undefined;
    // eslint-disable-next-line
    Math["average"] = undefined;

    this._selectedObject = undefined;
    this._customDataObject = undefined;

    this.graphic.DatapointService = undefined;
    this.graphic.TextGroupService = undefined;
    this.graphic.PropertyImageService = undefined;
    this.graphic.LibraryImageService = undefined;
    this.graphic.ElementBrushService = undefined;
    this.graphic.GmsSystemsService = undefined;
    this.graphic.AnimationTimerService = undefined;
    this.graphic.CnsHelperService = undefined;
    this.graphic.TraceService = undefined;
    this.graphic.Zone = undefined;
    this.graphic.TimerService = undefined;
    this.graphic.GmsObjectSelectionService = undefined;
    this.graphic.GmsBrowserObjectService = undefined;
    this.graphic.GmsCommandService = undefined;
    this.graphic.ExecuteCommandService = undefined;
    this.graphic.ReplicationService = undefined;
    this.graphic = undefined;
  }

  public onSnapinArgsUpdate(_snapinArgs: GraphicSnapInArgs): void {
    this.gmsObjectSelectionService.reset();

    if (_snapinArgs === undefined) {
      return;
    }

    if (_snapinArgs?.SelectionObject?.ObjectId === this.graphicsObjectId) {
      return;
    }

    const prevSelectedObject: BrowserObject = this._selectedObject;

    // Set the selection objects
    this._selectedObject = _snapinArgs.SelectionObject;
    this._customDataObject = _snapinArgs.CustomDataObject;
    this.gmsBrowserObjectService.SetPrimarySelection(this._selectedObject);

    if (this._selectedObject !== undefined && this._selectedObject !== null && this._selectedObject.ObjectId !== undefined) {
      // this._selectedObject.ObjectId - is a currently selected object's Id in the System Browser
      //  when a native graphic item selected (graphics page, graphics folder), the graphics load optimization applied for
      //  skip sending a request to get graphics related items if a graphic item selected
      this.readGraphicsInfo(this._selectedObject);
    }
  }

  public decrementRelatedItems(): void {
    if (!!this.relatedItems) {
      this.relatedItems.decrement();
    }
  }

  public incrementRelatedItems(): void {
    if (!!this.relatedItems) {
      this.relatedItems.increment();
    }
  }

  protected onCnsDisplayLabelChanged(): void {
    if (this.graphic !== undefined) {
      this.graphic.CnsDisplayLabelChanged();
    }
  }

  private loadDisciplines(): void {
    this.tablesService.getGlobalText(Tables.Disciplines, false)
      .subscribe((textEntries: TextEntry[]) => {
        for (const textEntry of textEntries) {
          const disciplineKey: string = '_' + textEntry.value;
          const disciplineValue: string = textEntry.text;
          if (this.graphic !== undefined) {
            this.graphic.addDisciplinePair(disciplineKey, disciplineValue);
          }
        }
      }, error => {
        this.traceService.error('Cannot load disciplines in loadDisciplines()');
      });
  }

  /*
  * This method deteremines whether or not the managedtype is a graphic item
  *
  * @param {string} managedType
  * @returns {boolean} true if the specified managed type is graphic/viewport/template.
  */
  private isGraphicItem(managedTypeName: string): boolean {
    if (managedTypeName === GmsManagedTypes.GRAPHIC.name || // graphic
      managedTypeName === GmsManagedTypes.GRAPHIC_PAGE.name || // viewport
      managedTypeName === GmsManagedTypes.GRAPHIC_TEMPLATE.name) { // template
      return true;
    }
    return false;
  }

  private readGraphicsInfo(node: BrowserObject): void {
    if (this.graphic !== undefined) {
      this.graphic.loadError = false;
    }

    if (node !== undefined && node !== null && node.ObjectId !== undefined && node.Attributes !== undefined) {
      // this._selectedObject.ObjectId - is a currently selected object's Id in the System Browser
      // when a native graphic item selected (graphics page, graphics folder), the graphics load optimization applied for
      // skip sending a request to get graphics related items if a graphic item selected
      this.traceService.info(this.traceModule, 'readGraphicsInfo: Selected System Browser object: ' + node.ObjectId);
    } else {
      this.traceService.info(this.traceModule, 'Failed to readGraphicsInfo: Selected System Browser object: ' + node.ObjectId);
      return;
    }

    // NOTE: If the primary selection has changed, clear the state.
    this.storageService.isDesignationEquals(this._selectedObject.Designation);

    const selectedObjectType = node.Attributes.ManagedTypeName;
    this.isFolder = selectedObjectType === GmsManagedTypes.PROJECT_GRAPHIC_ROOT_FOLDER.name;
    this.restoreSearchForTileView();
    try { // For Uncaught exceptions
      // If a graphic/graphic template is selected from related items
      // - use the _customDataObject ObjectId to get all related items
      const designation = this._customDataObject !== undefined && this.isGraphicItem(selectedObjectType) ? this
        ._customDataObject.Designation : this._selectedObject.Designation;

      this._subscriptionReadGraphicsInfoCall = this.graphicsService.getGraphicsItems(designation).subscribe(
        // Initialize the related graphic items for this selected object
        graphicInfos => {
          let currentIndex = 0;
          const relatedItemIdx: number = this.storageService.getRelatedItemIdx();
          if (relatedItemIdx !== undefined) {
            currentIndex = relatedItemIdx;
          } else if (graphicInfos?.length > 0) {
            // In case of graphic/graphic-template selection from related items,
            // since we use the _customDataObject.ObjectId to retrieve the related items,
            // have to set the correct graphic/graphic template using the _selectedObject.ObjectId
            // Usecase - Multiple graphic/graphic-templates tied to an object
            const currentGraphicInfo: GraphicInfo = graphicInfos
              .find(graphicInfo => graphicInfo.ObjectId === this._selectedObject.ObjectId);
            if (currentGraphicInfo !== undefined) {
              currentIndex = graphicInfos.indexOf(currentGraphicInfo);
            }
          }

          this.relatedItems.initRelatedItems(graphicInfos, currentIndex);
          this.singleRelatedItem = this.relatedItems.singleRelatedItem;
        },
        error => this.onReadGraphicContentError(error)
      );
    } catch (ex) {
      if (this.graphic !== undefined) {
        this.graphic.SetGraphicLoaded();
      }
    }
  }

  private restoreSearchForTileView(): void {
    if (!this.isFolder) {
      return;
    }

    this.storageService.isDesignationEquals(this._selectedObject?.Designation);
    if (this.storageService.HasDefinedState) {
      this.restoreStoredStringData();
    } else {
      this.deleteStoredStringData();
    }
  }

  private restoreStoredStringData(): void {
    const graphicState: GraphicState = this.storageService.getState();
    this.graphicsSnapinService.SearchString = graphicState.searchString;
    this.storageService.clearState();
  }

  private deleteStoredStringData(): void {
    this.graphicsSnapinService.clearSearchString();
    this.storageService.clearState();
  }

  private onReadGraphicContentError(error: any): void {
    if (this.graphic !== undefined) {
      this.graphic.SetGraphicLoaded();
    }

    this.traceService.error(this.traceModule, 'onReadGraphicContentError(): Selected Graphic: %s error: %s',
      this._selectedObject !== undefined ? this._selectedObject.Designation : '?', error.message);

    // Clean up the view
    this.ngZone.runOutsideAngular(() => {
      this.graphic.Destroy();
    });
  }

  private ClearSubscriptions(): void {
    if (this._subscriptionReadGraphicsInfoCall !== undefined) {
      this._subscriptionReadGraphicsInfoCall.unsubscribe();
      this._subscriptionReadGraphicsInfoCall = undefined;
    }
    if (this._subscriptionReadGraphicsContentCall !== undefined) {
      this._subscriptionReadGraphicsContentCall.unsubscribe();
      this._subscriptionReadGraphicsContentCall = undefined;
    }

    // subscribe is called AfterContentLoad in the loader.
    if (this.dataPointService !== undefined) {
      this.dataPointService.unsubscribeToEvents();
    }

    this.ClearReadPropertiesMultiSubscription();

    if (!this.graphic) {

      if (this.graphic.TextGroupService !== undefined) {
        this.graphic.TextGroupService.unsubscribe();
      }
      if (this.graphic.PropertyImageService !== undefined) {
        this.graphic.PropertyImageService.unsubscribe();
      }
      if (this.graphic.LibraryImageService !== undefined) {
        this.graphic.LibraryImageService.unsubscribe();
      }
      if (this.graphic.ElementBrushService !== undefined) {
        this.graphic.ElementBrushService.unsubscribe();
      }
      if (this.graphic.AnimationTimerService !== undefined) {
        this.graphic.AnimationTimerService.cleartimers();
      }
      if (this.graphic.GmsSystemsService !== undefined) {
        this.graphic.GmsSystemsService.unsubscribe();
      }
    }
  }

  /**
   * Process graphic item before loading
   * @param graphicInfo metadata about a graphic item
   */
  private readGraphicContent(graphicInfo: GraphicInfo): void {
    this.graphic.loadError = false;
    const timerService: TimerService = this.graphic.TimerService;

    // Try stop timer phase: RetrievingGraphicItems
    if (timerService.isStartedPhase(TimerPhaseEnum.RetrievingGraphicItems) && !timerService.isEndedPhase(TimerPhaseEnum.RetrievingGraphicItems)) {
      timerService.stopPhase(TimerPhaseEnum.RetrievingGraphicItems);
    }

    // Try start timer phase: GetGraphicsContent
    if (!timerService.isStartedPhase(TimerPhaseEnum.GetGraphicsContent) && !timerService.isEndedPhase(TimerPhaseEnum.GetGraphicsContent)) {
      timerService.startPhase(TimerPhaseEnum.GetGraphicsContent);
    }

    // Try start timer phase: GetGraphicsContent
    if (!timerService.isStartedPhase(TimerPhaseEnum.GetGraphicsContent) && !timerService.isEndedPhase(TimerPhaseEnum.GetGraphicsContent)) {
      timerService.startPhase(TimerPhaseEnum.GetGraphicsContent);
    }

    // NOTE: Handle the Graphics Template case
    if (this.graphic !== undefined &&
      graphicInfo !== undefined) {
      if ((graphicInfo.ObjectId === this.graphicsObjectId) &&
        this.graphic.GraphicType !== GraphicType.GraphicTemplate) {
        this.graphic.SelectedObject = this._customDataObject !== undefined && this.isGraphicItem(this._selectedObject.Attributes.ManagedTypeName) ? this._customDataObject : this._selectedObject;

        /**
         * NOTE: This is necessary to make designations without system names function
         */
        this.graphic.DatapointService.setCurrentSystemContext(this?.graphic?.SelectedObject);
        this.graphic.resetDepth();
        this.graphic.resetDisciplines();
        this.graphic.GmsObjectSelectionService.reset();
        asyncScheduler.schedule(this.refreshGraphicSelection, 0, this.graphic);
        return;
      }
    }

    if (graphicInfo.ObjectId !== this.graphicsObjectId) {
      this._subscriptionReadGraphicsContentCall?.unsubscribe();
      this._subscriptionReadGraphicsInfoCall?.unsubscribe();
      this.traceService.info(this.traceModule, 'readGraphicContent: Selected System Browser object: ' + this._selectedObject.ObjectId);
    }

    const blackList: string[] = [GmsManagedTypes.PROJECT_GRAPHIC_ROOT_FOLDER.name];
    const managedType: string = graphicInfo.ManagedType;
    if (!blackList.includes(managedType)) {
      this.graphic.UnsetGraphicLoaded();
      this.changeDetector.detectChanges();
    }

    this.traceService.info(this.traceModule, 'readGraphicContent: Selected System Browser object: ' + this._selectedObject.ObjectId);
    // NOTE: Related Items
    // what is the graphicsInfo? It is a list of related graphics items to the selected System Browser object
    this.ClearSubscriptions();
    // Clean up the view
    this.ngZone.runOutsideAngular(() => {
      this.graphic.Destroy();
    });

    // Currently used datapoints in the graphics page  - cached and scheduled to be removed on a timer
    this.dataPointService.scheduleRemove(this.graphic.datapoints);
    if (graphicInfo != null) {
      // SelectedObject: needs for Translated Text, Template Graphics
      this.graphic.SelectedObject = this._customDataObject !== undefined
      && this.isGraphicItem(this._selectedObject.Attributes.ManagedTypeName) ? this._customDataObject : this._selectedObject;

      /**
       * NOTE: This is necessary to make designations without system names function
       */
      this.graphic.DatapointService.setCurrentSystemContext(this?.graphic?.SelectedObject);

      // Context/objectreference for GraphicTemplate
      if (graphicInfo.ManagedType === GmsManagedTypes.GRAPHIC_TEMPLATE.name) {
        this.graphic.TemplateContext = graphicInfo.Context;
      }

      // DisplayName: needed for loading viewports when selecting datapoints
      this.graphic.DisplayName = graphicInfo.DisplayName;

      // load a first item from the list
      this.graphicsObjectId = graphicInfo.ObjectId;

      this.graphic?.SetIsLoadingBeforeDeserialization();

      try { // For Uncaught exceptions
        this._subscriptionReadGraphicsContentCall = this.graphicsService.getGraphicsContent(this.graphicsObjectId)?.subscribe({
          next: (graphicsContent: string) => {
            return this.loadGraphicContent(graphicsContent);
          },
          error: (error: any) => {
            this.onloadGraphicContentError(error);
          }
        });
      } catch (ex) {
        this.graphic.SetGraphicLoaded();
      }
    }
  }

  private refreshGraphicSelection(graphic: GmsGraphic): void {
    graphic.refreshGraphicSelection();
  }

  private updateGraphicViewComponent(): void {
    this.changeDetector.detectChanges();
  }

  private loadGraphicContent(content: string): void {
    if (content === undefined || content === null || (content.trim() === '')) {
      this.graphic.SetGraphicLoaded();
    }

    const timerService: TimerService = this.graphic.TimerService;

    // Try stop timer phase: GetGraphicsContent
    if (timerService.isStartedPhase(TimerPhaseEnum.GetGraphicsContent) && !timerService.isEndedPhase(TimerPhaseEnum.GetGraphicsContent)) {
      this.graphic.TimerService.stopPhase(TimerPhaseEnum.GetGraphicsContent);
    }

    // Try start timer phase: Deserialization
    if (!timerService.isStartedPhase(TimerPhaseEnum.Deserialization) && !timerService.isEndedPhase(TimerPhaseEnum.Deserialization)) {
      timerService.startPhase(TimerPhaseEnum.Deserialization);
    }

    if (this._subscriptionReadGraphicsContentCall !== undefined) {
      this._subscriptionReadGraphicsContentCall.unsubscribe();
      this._subscriptionReadGraphicsContentCall = undefined;
    }

    this.graphic.resetLayerValues();

    const loader: SvgLoader = new SvgLoader();
    if (this._selectedObject !== undefined) {
      if (this.subscriptionSvgLoaderCall !== undefined) {
        this.subscriptionSvgLoaderCall.unsubscribe();
        this.subscriptionSvgLoaderCall = undefined;
      }

      this.graphic.stateRestored = false;
      this.subscriptionSvgLoaderCall = loader.loadSVGContent(content, this.graphic,
        this._onReadPropertiesMultiSubscription, this.ngZone).subscribe(result => {
        this.traceService.info(this.traceModule, 'onloadGraphic(): loading SVG content succeeded.');
        const state: GraphicState = this.storageService.getState();
        this.graphic.updateCoverageArea.next();
        this.graphic.resetDepth();
        this.graphic.scaleView.next(undefined);
        if (state !== undefined) {
          this.storageService.clearState();
        }
      },
      error => {
        this.traceService.error(this.traceModule, 'onloadGraphicContent(): loading SVG content failed.');
      });
    } else {
      this.traceService.error(this.traceModule, 'loadGraphicContent failed: selectedObject is undefined');
    }
  }

  private ClearReadPropertiesMultiSubscription(): void {
    if (this._onReadPropertiesMultiSubscription === undefined) {
      this._onReadPropertiesMultiSubscription = new Map<string, Subscription>();
    } else {
      this._onReadPropertiesMultiSubscription.forEach(value => {
        value.unsubscribe();
      });
      this._onReadPropertiesMultiSubscription.clear();
    }
  }

  private onloadGraphicContentError(error: Error): void {
    if (this.graphic !== undefined) {
      this.graphic.SetGraphicLoaded();
    }
    this.traceService.error(this.traceModule, 'onloadGraphicContentError(): Selected Graphic: %s error: %s',
      this._selectedObject !== undefined ? this._selectedObject.Designation : '?', error.message);
  }

  private setHldlConfig(): void {
    if (!isNullOrUndefined(this._hldlConfig)) {
      if (this._hldlConfig.EnableAlarmIndication !== undefined) {
        this.graphic.ShowAlarmIndication = this._hldlConfig.EnableAlarmIndication;
      }

      if (this._hldlConfig.AlarmIconSize !== undefined) {
        this.graphic.AlarmIconSize = this._hldlConfig.AlarmIconSize;
      }

      if (this._hldlConfig.EnableDownwardNavigation !== undefined) {
        this.graphic.GmsObjectSelectionService.DownWardNavigationEnabled = this._hldlConfig.EnableDownwardNavigation;
      }

      if (this._hldlConfig.ExcludeFromDownwardNavigation !== undefined) {
        this.graphic.GmsObjectSelectionService.ExcludeFromDownwardNavigation = this._hldlConfig.ExcludeFromDownwardNavigation;
      }
    }
  }

  private get IsInGraphicsViewerSnapin(): boolean {
    const graphicsViewerSnapinName = 'gms-graphics-viewer-snapin';
    let currentElement: HTMLElement = this.graphicContainer.nativeElement;
    while (!isNullOrUndefined(currentElement) && !isNullOrUndefined(currentElement.parentElement)) {
      const currentTagName: string = currentElement.tagName.toLowerCase();
      if (currentTagName === graphicsViewerSnapinName) {
        return true;
      }

      currentElement = currentElement.parentElement;
    }

    return false;
  }

  private CheckShowAppRights(): boolean {
    const graphicViewerSnapinId = 18;
    const showAppRightsId = 576;

    const appRight: ApplicationRight | undefined = this.appRightsService?.getAppRights(graphicViewerSnapinId);

    let show: boolean;
    if (!isNullOrUndefined(appRight)) {
      const showRights: Operation[] = isNullOrUndefined(appRight.Operations) ? [] : appRight.Operations.filter(f => f.Id === showAppRightsId);
      show = showRights.length > 0;
    }
    return show;
  }
  private getTranslations(): void {
    this._subscriptionTranslateService =
      this.translateService.get([
        'GRAPHICS-VIEWER.NO-RELEVANT-DATA',
        'GRAPHICS-VIEWER.NO-APP-RIGHTS'
      ]).subscribe(strings => {
        this.noRelevantDataLabel = strings['GRAPHICS-VIEWER.NO-RELEVANT-DATA'];
      });
    this.eventCommonService.getNoRightsLabel().then(string => {
      this.noAppRightsLabel = string;
    });
  }
}
