import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as Long from 'long';

import { FormatHelper } from '../utilities/format-helper';
@Directive({
  selector: '[gmsNumericValueValidator]'
})
export class NumericValueDirective {

  public constructor(private readonly elementRef: ElementRef) {
  }

  private static readonly specialKeys: string[] = ['Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Enter', 'Escape', ' '];
  @Input() public minValue: number;
  @Input() public maxValue: number;
  @Input() public minValueLong: Long;
  @Input() public maxValueLong: Long;
  @Input() public isUnsignedType: boolean;
  @Input() public isFloatType: boolean;
  @Input() public locale: string;
  @Input() public precision: number;
  private decimalSeparator: string;
  private groupingSeparator: string;

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {

    // Allow tab, end, space and home keys
    if (event.ctrlKey || NumericValueDirective.specialKeys.includes(event.key)) {
      return;
    }
    this.decimalSeparator = FormatHelper.getDecimalSeparator(this.locale);
    this.groupingSeparator = FormatHelper.getGroupingSeparator(this.locale);

    if (event.key === this.groupingSeparator) {
      event.preventDefault();
      return;
    }

    if (!this.isFloatType && event.key === this.decimalSeparator) {
      event.preventDefault();
      return;
    }

    if (this.isUnsignedType && event.key === '-') {
      event.preventDefault();
      return;
    }

    // Ensure that it is a number
    if (event.key !== this.decimalSeparator &&
            event.key !== '-' &&
            event.key !== 'Backspace' &&
            (isNaN(Number(event.key)))) {

      event.preventDefault();
      return;
    }

    const current: string = this.elementRef.nativeElement.value;
    let newValue = '';
    // "Backspace"
    if (event.key === 'Backspace') {
      if (this.elementRef.nativeElement.selectionStart === this.elementRef.nativeElement.selectionEnd) {
        if (this.elementRef.nativeElement.selectionStart > 0) {
          newValue = current.substring(0, this.elementRef.nativeElement.selectionStart - 1);
          newValue = newValue.concat(current.substring(this.elementRef.nativeElement.selectionStart));

        } else {
          newValue = current;
        }
      } else {
        if (this.elementRef.nativeElement.selectionStart >= 0) {
          newValue = current.substring(0, this.elementRef.nativeElement.selectionStart - 1);
        }
        if (this.elementRef.nativeElement.selectionEnd < current.length) {
          newValue = newValue.concat(current.substring(0, this.elementRef.nativeElement.selectionEnd));
        }
      }
    } else if (this.elementRef.nativeElement.selectionStart !== null) {
      if (this.elementRef.nativeElement.selectionStart > 0) {
        newValue = current.substring(0, this.elementRef.nativeElement.selectionStart).concat(event.key);
      } else {
        newValue = event.key;
      }

      if (this.elementRef.nativeElement.selectionEnd != null &&
                this.elementRef.nativeElement.selectionEnd < current.length) {
        if (this.elementRef.nativeElement.selectionStart === this.elementRef.nativeElement.selectionEnd) {
          newValue = newValue.concat(current.substring(this.elementRef.nativeElement.selectionStart));
        } else {
          newValue = newValue.concat(current.substring(this.elementRef.nativeElement.selectionEnd + 1));
        }
      }
    } else {
      newValue = current;
    }
    if (newValue.length === 0) {
      return;
    }
    if (this.Validate(newValue) === false) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const current: string = this.elementRef.nativeElement.value;
    let newValue = '';
    if (this.elementRef.nativeElement.selectionStart > 0) {
      const start: string = current.substring(0, this.elementRef.nativeElement.selectionStart - 1);
      const end: string = current.substring(this.elementRef.nativeElement.selectionEnd);
      const paste: string = event.clipboardData.getData('text');
      newValue = newValue.concat(start, paste, end);
    } else {
      newValue = event.clipboardData.getData('text');
    }
    if (this.Validate(newValue) === false) {
      event.preventDefault();
    }
  }

  private ValidateFloatNumber(text: string): boolean {
    let isValid = true;

    if (text === '-' || (text.length === 2 && text[0] === '-' && text[1] === this.decimalSeparator)) {
      isValid = this.minValue !== undefined && this.minValue < 0;
      return isValid;
    }

    if (text === FormatHelper.getDecimalSeparator(this.locale)) {
      isValid = this.maxValue !== undefined && this.maxValue >= 0;
    } else {
      const parts: string[] = text.split(this.decimalSeparator);
      isValid = parts.length < 2 || (parts.length === 2 && parts[1].length <= this.precision);
      const value: number = FormatHelper.getNumberfromString(text, this.locale);
      isValid = isValid && !Number.isNaN(value);

      if (isValid) {
        // check for minValue
        if (this.minValue >= 0 && (value < 0 || text.includes('-'))) {
          isValid = false;
        }

        // check for maxValue
        if (this.maxValue !== undefined) {
          if (this.maxValue > 0 && value > this.maxValue) {
            isValid = false;
          } else if (this.maxValue < 0 && value > 0) {
            isValid = false;
          }
        }
      }
    }
    return isValid;
  }

  private ValidateIntNumber(text: string): boolean {
    let isValid = !text.includes(this.decimalSeparator);
    if (isValid && text.length > 0) {
      const t: string = FormatHelper.Replace(text, this.groupingSeparator);
      if (t.length !== text.length) {
        isValid = t.length > 0;
        if (isValid) {
          text = t;
        }
      }
    }

    const hasMinValue: boolean = this.minValue !== undefined && !Number.isNaN(this.minValue);
    if (isValid && text === '-') {
      isValid = !this.isUnsignedType && hasMinValue && this.minValue < 0;
      return isValid;
    }

    if (isValid) {
      const value = Number(text);
      isValid = !Number.isNaN(value);

      if (isValid && this.maxValue !== undefined && !Number.isNaN(this.maxValue) && this.maxValue > 0 && value > this.maxValue) {
        isValid = false;
      }
      if (this.minValue !== undefined && this.minValue <= 0 && value < this.minValue) {
        isValid = false;
      }
    }

    return isValid;
  }

  private ValidateLongNumber(text: string): boolean {
    let isValid = !text.includes(this.decimalSeparator);
    if (isValid && text.length > 0) {
      const t: string = FormatHelper.Replace(text, this.groupingSeparator);
      if (t.length !== text.length) {
        isValid = t.length > 0;
        if (isValid) {
          text = t;
        }
      }
    }

    if (isValid && text === '-') {
      isValid = !this.isUnsignedType && this.minValueLong !== undefined && this.minValueLong.lessThan(0);
      return isValid;
    }

    if (isValid) {
      const value: Long = FormatHelper.parseLongValue(text, this.isUnsignedType);
      if (value === undefined || value.toString() !== text) {
        // value overflow, or undefined
        isValid = false;
      }
      if (this.maxValueLong !== undefined && this.maxValueLong.greaterThanOrEqual(0) && value.greaterThan(this.maxValueLong)) {
        isValid = false;
      }

      if (this.minValueLong !== undefined && this.minValueLong.lessThanOrEqual(0) && value.lessThan(this.minValueLong)) {
        isValid = false;
      }
    }

    return isValid;
  }

  private Validate(text: string): boolean {
    let result: boolean = text === '-' || text === this.decimalSeparator; //  allow single '.', '-' characters

    if (!result) {
      if (this.isFloatType) {
        // validate real types
        result = this.ValidateFloatNumber(text);
      } else if (this.minValueLong !== undefined && (this.maxValueLong !== undefined)) {
        // validate long types
        result = this.ValidateLongNumber(text);
      } else {
        // validate int types
        result = this.ValidateIntNumber(text);
      }
    }

    return result;
  }
}
