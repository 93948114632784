import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  MenuItem,
  SiContentActionBarComponent,
  SiEmptyStateComponent,
  SiInlineNotificationComponent,
  SiTranslateModule,
  ViewType
} from '@simpl/element-ng';
import { clone } from '@simpl/object-browser-ng/common';
import {
  AnyProperty,
  CommandEvent,
  DisplayMode,
  SiDatapointComponent
} from '@simpl/object-browser-ng/property-viewer';

import {
  DEFAULT_DATAPOINT_ICON,
  getPropertyId,
  getSelectedPropertyId,
  NotificationError,
  setPropertyId,
  ValidationError
} from '../../helpers/default-models';
import {
  enableDisableMenuItem,
  enableDisableProperty,
  getCommandEventValue,
  getCommandValue,
  getExecutionSettingValues,
  getSelectedAction,
  updateCollectionPropertyValueInDataPointProperties,
  updateEnableDisableContentAction,
  updatePrioArrayInUI,
  updatePropertyValue
} from '../../helpers/scene-property-command-helper';
import { DataPoint, Scene, SceneCommand, SceneModel } from '../../interfaces/scene-editor';
import { SiSceneEditorConfigService, SiSceneEditorService } from '../../services/index';
import {
  DataPointResult,
  PropertyEvent,
  SiConfigureDatapointComponent
} from '../si-configure-datapoint/si-configure-datapoint.component';

export interface AddDataPointDialogResult {
  result: 'ok' | 'cancel';
  data?: any;
}

@Component({
  selector: 'si-datapoint-view',
  templateUrl: './si-datapoint-view.component.html',
  styleUrls: ['./si-datapoint-view.component.scss'],
  standalone: true,
  imports: [
    SiConfigureDatapointComponent,
    SiContentActionBarComponent,
    SiDatapointComponent,
    SiEmptyStateComponent,
    SiInlineNotificationComponent,
    SiTranslateModule
  ]
})
export class SiDatapointViewComponent implements OnInit {
  @Input() objectId!: string;
  @Input() sceneModel!: SceneModel;
  @Input() newDataPoints!: DataPoint[];
  @Input() displayMode!: DisplayMode;
  @Input() modalTitleId!: string;

  @Output() readonly closeDialog = new EventEmitter<AddDataPointDialogResult>();

  @ViewChild('configureDatapoint') configureDatapoint!: SiConfigureDatapointComponent;

  selectedDataPoint!: DataPoint;
  selectedScenePoints: SceneCommand[] = [];
  newSelectedScenePoints: SceneCommand[] = [];
  newSceneDataPoints!: SceneCommand[];
  propertyCommands: AnyProperty[] = [];
  showAll = false;
  isComingFromSelection = false;
  isDuplicateDataPoint = false;
  readOnly = false;
  isPropertyPopOpen = false;
  inlineNotificationError?: NotificationError;
  defaultDataPointIcon: string = DEFAULT_DATAPOINT_ICON;

  private defaultProperty = new Map<string, [string, AnyProperty]>();
  private comingFromSelection = new Map<string, AnyProperty>();
  private changedCommand = new Map<string, [string, AnyProperty]>();

  readonly viewType: ViewType = 'expanded';
  readonly primaryActions: MenuItem[] = [
    {
      id: 'add-more-data-points',
      title: 'SCENE_EDITOR.TEXT_SHORT.ADD_MORE_DATA_POINTS',
      icon: 'element-plus',
      action: () => this.addDataPointClick()
    }
  ];
  readonly propertyContentActions: MenuItem[] = [
    {
      id: 'enable-disable',
      title: '',
      action: property => this.enableDisableDataPoint(property)
    }
  ];
  private siSceneService = inject(SiSceneEditorService);
  private sceneConfigService = inject(SiSceneEditorConfigService);

  ngOnInit(): void {
    this.addExecuteSettingAction();
    this.addAdvancedCommandsAction();
    this.updateModel();
    this.setActiveItem();
  }

  private setActiveItem(): void {
    if (this.newDataPoints?.length) {
      this.selectedDataPoint = this.newDataPoints[0];
    }
  }

  private addExecuteSettingAction(): void {
    if (this.sceneConfigService.get().actionToDisable.executeSettings) {
      this.propertyContentActions.splice(
        this.propertyContentActions.findIndex(w => w.id === 'enable-disable') + 1,
        0,
        {
          id: 'execution-settings',
          title: 'SCENE_EDITOR.TEXT_SHORT.EXECUTION_SETTINGS'
        }
      );
    }
  }
  private addAdvancedCommandsAction(): void {
    if (!this.sceneConfigService.get().actionToDisable.advanced) {
      this.propertyContentActions.push(
        {
          title: '-'
        },
        {
          id: 'advanced-commands',
          title: 'SCENE_EDITOR.TEXT_SHORT.ADVANCED_COMMANDS',
          disabled: true
        }
      );
    }
  }

  updateModel(): void {
    this.newSceneDataPoints = this.createNewSceneDataPoints(
      this.newDataPoints,
      this.sceneModel.scenes
    );
    if (this.newDataPoints?.length) {
      this.validateDataPoints();
      this.handleSelectedListItemChange(this.newDataPoints[0]);
    }
    this.primaryActions.forEach(obj => {
      obj.disabled = this.sceneModel.readonly;
    });
  }

  validateDataPoints(): void {
    let duplicationError: ValidationError | undefined;
    let removalError: ValidationError | undefined;
    const datapoints = this.getInvalidDatapoints();
    if (datapoints?.length) {
      removalError = {
        errorReason: 'CannotAddDatapoint',
        errorObject: datapoints
      };
    } else {
      this.newDataPoints.forEach(element => {
        const actualObjectId = this.siSceneService.getOriginalId(element.id);
        const sameDataPoint = this.sceneModel.dataPoints.filter(obj =>
          obj.id.includes(actualObjectId)
        );
        const alreadyAddedDataPoint = this.newDataPoints.filter(
          dataPoint =>
            dataPoint.id.startsWith(actualObjectId) &&
            dataPoint.selectedPropertyId === element.selectedPropertyId
        );
        if (alreadyAddedDataPoint.length > 1) {
          sameDataPoint.push(element);
        }
        if (sameDataPoint?.length) {
          const datapointWithSameProp = sameDataPoint.filter(
            item => item.selectedPropertyId === element.selectedPropertyId
          );
          if (datapointWithSameProp?.length && !duplicationError) {
            duplicationError = {
              errorReason: 'DuplicateDataPoint',
              errorObject: element
            };
          }
        }
      });
    }
    this.updateInlineNotification(
      removalError ? removalError : duplicationError ? duplicationError : undefined
    );
  }

  getInvalidDatapoints(): DataPoint[] {
    return this.newDataPoints.filter(datapoint => !datapoint.properties?.length);
  }

  updateInlineNotification(error?: ValidationError): void {
    if (error) {
      const errorDataPoint = error.errorObject as DataPoint;
      this.inlineNotificationError = {
        severity:
          error.errorReason === 'DuplicateDataPoint'
            ? 'warning'
            : error.errorReason === 'CannotAddDatapoint'
              ? 'danger'
              : 'warning',
        message:
          error.errorReason === 'DuplicateDataPoint'
            ? 'SCENE_EDITOR.MESSAGES.DUPLICATE_DATA_POINT_PROPERTY'
            : error.errorReason === 'CannotAddDatapoint'
              ? 'SCENE_EDITOR.MESSAGES.NOT_SUPPORTED_DATAPOINT'
              : '',
        action: {
          title:
            error.errorReason === 'DuplicateDataPoint' &&
            !this.sceneConfigService.get().restrictPropertyEdit
              ? 'SCENE_EDITOR.DIALOG_TEXT_SHORT.SELECT_ANOTHER_PROPERTY'
              : error.errorReason === 'CannotAddDatapoint'
                ? 'SCENE_EDITOR.DIALOG_TEXT_SHORT.REMOVE'
                : '',
          action: (param: ValidationError) => this.correctiveAction(error)
        },
        errorParams: {
          propertyId: errorDataPoint?.selectedPropertyId,
          datapointId: errorDataPoint?.name
        }
      };
    } else {
      this.inlineNotificationError = undefined;
    }
  }

  correctiveAction(element: ValidationError): any {
    if (element.errorReason === 'CannotAddDatapoint') {
      (element.errorObject as DataPoint[]).forEach(dataPoint => this.removeDataPoint(dataPoint));
      this.validateDataPoints();
    } else if (element.errorReason === 'DuplicateDataPoint') {
      this.handleSelectedListItemChange(element.errorObject as DataPoint);
      this.configureDatapoint.initializeSelectProperties();
      this.isDuplicateDataPoint = true;
    }
  }

  createNewSceneDataPoints(newDataPoints: DataPoint[], scenes?: Scene[]): SceneCommand[] {
    const sceneDataPoint: SceneCommand[] = [];
    if (scenes && newDataPoints) {
      scenes.forEach(scene => {
        newDataPoints.forEach(dataPoint => {
          const sceneCommand = this.createNewSceneDataPoint(scene, dataPoint);
          if (sceneCommand) {
            sceneDataPoint.push(sceneCommand);
          }
        });
      });
    }
    return sceneDataPoint;
  }

  createNewSceneDataPoint(scene: Scene, dataPoint: DataPoint): SceneCommand | undefined {
    const selectedProp = dataPoint.properties?.find(
      prop => prop.id === dataPoint.selectedPropertyId
    );
    const selectedAction = getSelectedAction(selectedProp);
    if (selectedProp && selectedAction) {
      return {
        dataPointId: dataPoint.id,
        sceneId: scene.id,
        isEnabled: true,
        selectedCommand: selectedAction?.action,
        value: selectedAction?.isActive
          ? selectedProp?.value.value
          : getCommandEventValue(selectedAction)
      } as SceneCommand;
    }
    return undefined;
  }

  handleSelectedListItemChange(dataPoint: DataPoint): void {
    this.selectedDataPoint = dataPoint;
    this.newSelectedScenePoints = this.newSelectedScenePoints = [];
    if (!this.newSceneDataPoints) {
      this.newSceneDataPoints = this.createNewSceneDataPoints(
        this.newDataPoints,
        this.sceneModel.scenes
      );
    }
    if (!this.showAll) {
      this.newSelectedScenePoints = this.newSceneDataPoints.filter(
        point => point.dataPointId === this.selectedDataPoint.id
      );
    } else {
      this.newSelectedScenePoints = this.newSceneDataPoints.filter(
        point => point.dataPointId === this.selectedDataPoint.id
      );
      this.selectedScenePoints.forEach(element => {
        this.newSelectedScenePoints.push(element);
      });
    }
    this.handlePropertyList(this.comingFromSelection.get(this.selectedDataPoint.id));
  }

  addDataPointClick(): void {
    const totalDpS: DataPoint[] = [];
    this.sceneModel.dataPoints.forEach(dp => totalDpS.push(dp));
    this.newDataPoints.forEach(dp => totalDpS.push(dp));
    this.siSceneService.addDataPoint(this.objectId).subscribe(addedPoints => {
      const newlyDataPoints = this.siSceneService.generateUniqueDataPoints(
        this.objectId,
        addedPoints,
        totalDpS
      );
      this.newDataPoints.push(...newlyDataPoints);
      this.createNewSceneDataPoints(newlyDataPoints, this.sceneModel.scenes).forEach(newSceneDp => {
        this.newSceneDataPoints.push(newSceneDp);
      });
      if (this.newDataPoints[0]) {
        this.handleSelectedListItemChange(this.newDataPoints[0]);
      }
      this.validateDataPoints();
    });
  }

  handlePropertyList(property?: AnyProperty, isCommandChange?: boolean): void {
    this.propertyCommands = [];
    this.newSelectedScenePoints.forEach(sceneCommand => {
      const dataPoint = this.newDataPoints.find(dp => dp.id === sceneCommand.dataPointId);
      const scene = this.sceneModel.scenes.find(element => element.id === sceneCommand.sceneId);
      if (dataPoint && scene) {
        if (!property) {
          property = dataPoint.properties?.find(prop => prop.id === dataPoint.selectedPropertyId);
        }
        if (property) {
          this.defaultProperty.set(scene.id, [this.selectedDataPoint.id, property]);
          const displayProperty = clone(property);
          if (property.id) {
            displayProperty.id = setPropertyId(scene.id, property.id);
          }
          displayProperty.name = scene.name;
          this.propertyCommands.push(
            clone(this.updateProperty(sceneCommand, displayProperty, isCommandChange))
          );
        }
      }
    });
  }

  handleRemoveDataPoint(event: DataPointResult): void {
    this.removeDataPoint(event.dataPoint);
    this.validateDataPoints();
  }

  private removeDataPoint(datapoint: DataPoint): void {
    this.newDataPoints = this.newDataPoints.filter(object => object.id !== datapoint.id);
    this.newSceneDataPoints = this.newSceneDataPoints.filter(
      object => object.dataPointId !== datapoint.id
    );
    if (this.newDataPoints[0]) {
      this.handleSelectedListItemChange(this.newDataPoints[0]);
    }
  }

  popOpenedState(isOpen: boolean): void {
    enableDisableMenuItem(this.primaryActions, isOpen);
  }

  commandSelected(event: CommandEvent): void {
    const displayProperty = event.property as AnyProperty;
    const command = event.command;
    const sceneCommand = this.getSceneCommand(displayProperty);
    if (sceneCommand) {
      if (command === 'execution-settings') {
        this.updateExecutionSettingValues(event, sceneCommand, displayProperty);
      } else {
        this.updateSceneCommandValue(sceneCommand, command, displayProperty, event);
      }
    }
  }

  getSceneCommand(property: AnyProperty): SceneCommand | undefined {
    if (property?.id) {
      const sceneId = getPropertyId(property.id);
      return this.newSelectedScenePoints.find(
        command => command.sceneId === sceneId && command.dataPointId === this.selectedDataPoint.id
      );
    }
    return undefined;
  }

  private updateSceneCommandValue(
    sceneCommand: SceneCommand,
    command: string,
    displayProperty: AnyProperty,
    event: CommandEvent
  ): void {
    if (sceneCommand.selectedCommand === command) {
      sceneCommand.value = getCommandValue(displayProperty, event);
      this.updateProperty(sceneCommand, displayProperty, true);
    } else {
      sceneCommand.selectedCommand = command;
      sceneCommand.value = undefined;
      this.updateProperty(sceneCommand, displayProperty, true);
      sceneCommand.value = getCommandValue(displayProperty, event);
    }
    this.changedCommand.set(sceneCommand.sceneId, [
      this.selectedDataPoint.id,
      clone(displayProperty)
    ]);
  }

  private updateExecutionSettingValues(
    event: CommandEvent,
    sceneCommand: SceneCommand,
    property: AnyProperty
  ): void {
    getExecutionSettingValues(event, sceneCommand);
    this.updateProperty(sceneCommand, property, false);
  }

  private updateProperty(
    command: SceneCommand,
    displayProperty: AnyProperty,
    isCommandChange?: boolean
  ): AnyProperty {
    updatePropertyValue(
      this.selectedDataPoint,
      command,
      displayProperty,
      this.propertyContentActions,
      command.selectedCommand,
      isCommandChange
    );
    updateEnableDisableContentAction(displayProperty, command);
    return displayProperty;
  }

  private enableDisableDataPoint(property: AnyProperty): void {
    const sceneCommand = this.getSceneCommand(property);
    if (sceneCommand) {
      // else condition value can be undefined
      const id = property?.id ? getPropertyId(property.id) : property?.id;
      if (id && !this.changedCommand?.has(id) && sceneCommand.isEnabled) {
        // not changed command && if it commanded for disabling
        this.changedCommand.set(sceneCommand.sceneId, [this.selectedDataPoint.id, clone(property)]);
      }

      enableDisableProperty(
        property,
        this.selectedDataPoint.id,
        this.defaultProperty,
        this.changedCommand,
        sceneCommand
      );
      updateEnableDisableContentAction(property, sceneCommand);
      this.updateInModel(property, sceneCommand);
    }
  }

  private updateInPropertyList(property: AnyProperty): void {
    if (property?.id) {
      this.selectedDataPoint.selectedPropertyId = getSelectedPropertyId(property.id);
      // update datapoint selected property
      this.newDataPoints
        .filter(dataPoint => dataPoint.id === this.selectedDataPoint.id)
        .forEach(dp => {
          dp.selectedPropertyId = this.selectedDataPoint.selectedPropertyId;
        });

      this.newSceneDataPoints
        .filter(command => command.dataPointId === this.selectedDataPoint.id)
        .forEach(sceneCommand => {
          if (property.value.type === 'collection') {
            if (property.value.value?.length) {
              sceneCommand.value = [property.value.value[0]];
            }
          } else {
            sceneCommand.value = getCommandValue(property);
          }
          sceneCommand.selectedCommand = getSelectedAction(property)?.id;
        });
    }
  }

  private updateInModel(property: AnyProperty, target?: SceneCommand): void {
    if (property?.id) {
      const sceneId = getPropertyId(property.id);
      this.newSceneDataPoints
        .filter(
          command =>
            command.dataPointId === this.selectedDataPoint.id && command.sceneId === sceneId
        )
        .forEach(sceneCommand => {
          if (target) {
            sceneCommand.value = target.value;
            sceneCommand.selectedCommand = target.selectedCommand;
            sceneCommand.isEnabled = target.isEnabled;
          } else {
            sceneCommand.value = property.value.value;
          }
          this.updateValueInPriorityProperty(sceneCommand, property);
        });
    }
  }

  private updateValueInPriorityProperty(
    sceneCommand: SceneCommand,
    property: AnyProperty,
    event?: CommandEvent
  ): void {
    if (property.value.type === 'collection' && property.value.kind === 'priority-array') {
      const advanceAction = property.actions?.find(action => action.id === 'advanced-commands');
      const inActiveCommand = advanceAction?.items?.find(item => !item.isActive);
      if (sceneCommand.selectedCommand !== inActiveCommand?.id) {
        const prop = this.propertyCommands.find(p => p.id === property.id);
        this.selectedDataPoint = updateCollectionPropertyValueInDataPointProperties(
          clone(this.selectedDataPoint),
          property
        );
        if (prop) {
          updatePrioArrayInUI(this.selectedDataPoint, prop);
        }
        this.newDataPoints.forEach(dataPoint => {
          if (dataPoint.id === this.selectedDataPoint.id) {
            dataPoint = this.selectedDataPoint;
          }
        });
      }
    }
  }

  propertyChanged(event: PropertyEvent): void {
    if (event?.data) {
      const property = event.data as AnyProperty;
      if (event.change === 'CommandValue') {
        this.updateInModel(property);
      } else if (event.change === 'SelectedProperty') {
        this.comingFromSelection.set(this.selectedDataPoint.id, property);
        this.updateInPropertyList(property);
        this.handlePropertyList(property, true);
      } else if (event.change === 'UserDefinedName') {
        this.selectedDataPoint.userDefinedName = event.data as string;
      }
    }
    this.validateDataPoints();
  }

  public cancel(): void {
    this.closeDialog.emit({ result: 'cancel' });
    this.selectedScenePoints = [];
    this.newSceneDataPoints = [];
    this.newSelectedScenePoints = [];
  }

  public confirm(): void {
    this.siSceneService.setIsDirty(true);
    this.closeDialog.emit({
      result: 'ok',
      data: {
        newDataPoints: this.newDataPoints,
        newSceneDataPoints: this.newSceneDataPoints
      }
    });
  }
}
