import { Component, Input } from '@angular/core';

import { GmsGraphic } from '../../elements/gms-graphic';
import { GmsDepth } from '../../processor/gms-depth';

@Component({
  selector: '[gms-menu-depths]',
  template: `<label class="gms-menu-item-title">{{ 'FILTER_MENU.DEPTHS' | translate }}</label>
               <ul class="top-level-list">
                <ng-container *ngFor="let depth of Depths;">
                 <li class="graphics-menu-item" style="margin-right: 0px;" (click)="OnMouseClick(depth)">
                  <label style="margin-right: 20px !important;">
                    {{ depth.Name }}
                  </label>
                  <label class="bullet-point"
                    style="position: absolute; right: 0px;"
                    [style.display]="IsDepthSelected(depth) ? null : 'none'">
                    &#8226;
                  </label>
                 </li>
                </ng-container>
               </ul>`,
  styleUrl: './gms-menu.scss'
})

export class GmsMenuDepthsComponent {
  @Input() public graphic: GmsGraphic;

  public get Depths(): GmsDepth[] {
    if (this.graphic !== undefined && this.graphic.depths !== undefined) {
      const depths: GmsDepth[] = this.graphic.depths.depthList;
      return depths;
    }

    return [];
  }

  public IsDepthSelected(depth: GmsDepth): boolean {
    if (depth !== undefined && this.graphic !== undefined
            && this.graphic.depths !== undefined
            && this.graphic.SelectedDepth !== undefined) {
      return depth.Name === this.graphic.SelectedDepth.Name;
    }

    return false;
  }

  public OnMouseClick(depth: GmsDepth): void {
    if (this.graphic?.SelectedDepth?.Name !== undefined && this.graphic?.SelectedDepth?.Name !== depth?.Name) {
      this.graphic.SelectedDepth = depth;
      this.graphic.updateLayers();
      if (this.graphic?.IsPermScaleToFit === false) {
        this.graphic.allocateScrollbarSpaceDepth.next();
      }
    }
  }
}
