<si-password-strength>
  <input
    type="password"
    name="password"
    class="form-control"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [siPasswordStrength]="{
      minLength: props.minLength && props.minLength > 0 ? props.minLength : 8,
      uppercase: props.upperCase !== false,
      lowercase: props.lowerCase !== false,
      digits: props.digits !== false,
      special: props.special !== false
    }"
  />
</si-password-strength>
