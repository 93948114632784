import { Component, Input } from '@angular/core';

import { GmsGraphic } from '../../elements/gms-graphic';

@Component({
  selector: '[gms-menu-disciplines]',
  template: `<label class="gms-menu-item-title">{{ 'FILTER_MENU.DISCIPLINES' | translate }}</label>
               <label style="position: absolute; top: 12px; right: 5px; font-weight: 400; cursor: pointer;"
                class="show-all-option"
                (click)="ToggleVisibilityOfDisciplines()">
                    {{ (IsShowAllOption ? "FILTER_MENU.SHOW_ALL" : "FILTER_MENU.HIDE_ALL") | translate }}
               </label>
               <ul class="top-level-list">
                <ng-container *ngFor="let discipline of DisciplineIds;">
                 <li class="graphics-menu-item"
                     style="margin-right: 0px;"
                     (click)="FilterByDisciplineId(discipline)">
                     <div style="display: flex;">
                      <label [className]="IconStyle(discipline)" style="position: relative; top: 7.5px;"></label>
                      <label style="white-space: nowrap; margin-right: 30px !important;"> {{ GetDisciplineName(discipline) }} </label>
                      <label
                        #currLayer class="checkmark"
                        style="position: absolute; right: 0px;"
                        [style.display]="IsFilteredByDiscipline(discipline) ? null : 'none'">
                        &#10003;
                      </label>
                     </div>
                 </li>
                </ng-container>
               </ul>`,
  styleUrl: './gms-menu.scss'
})

export class GmsMenuDisciplinesComponent {
  @Input() public graphic: GmsGraphic;

  public get DisciplineIds(): Set<string> {
    if (this.graphic !== undefined && this.graphic.disciplines !== undefined) {
      return this.graphic.disciplines;
    }

    return undefined;
  }

  public get IsShowAllOption(): boolean {
    if (this.graphic !== undefined
            && this.graphic.disciplines !== undefined
            && this.graphic.selectedDisciplines !== undefined) {
      return this.graphic.disciplines.size !== this.graphic.selectedDisciplines.size;
    }

    return false;
  }

  public IconStyle(discipline: string): string {
    const iconStore: any = { '_50': 'element-plant-filled',
      '_20': 'element-special-object',
      '_200': 'element-power-filled',
      '_100': 'element-fire-filled',
      '_0': 'element-tv',
      '_250': 'element-mass-notification',
      '_150': 'element-security-filled'
    };

    if (discipline in iconStore) {
      return iconStore[discipline];
    }

    return undefined;
  }

  public GetDisciplineName(discipline: string): string {
    if (this.graphic !== undefined && this.graphic.disciplineFromIdMap !== undefined) {
      return this.graphic.disciplineFromIdMap.get(discipline);
    }

    return undefined;
  }

  public IsFilteredByDiscipline(discipline: string): boolean {
    if (this.graphic !== undefined && this.graphic.selectedDisciplines !== undefined) {
      return this.graphic.selectedDisciplines.has(discipline);
    }

    return false;
  }

  public ToggleVisibilityOfDisciplines(): void {
    if (this.IsShowAllOption) {
      if (this.graphic?.disciplines !== undefined && this.graphic?.selectedDisciplines !== undefined) {
        for (const discipline of this.graphic.disciplines) {
          this.graphic.selectedDisciplines.add(discipline);
        }
      }
    } else {
      this.graphic.selectedDisciplines.clear();
    }

    this.graphic.NotifyFilterMenuChanged();
  }

  public FilterByDisciplineId(discipline: string): void {
    if (this.graphic !== undefined && this.graphic.selectedDisciplines !== undefined) {
      if (this.graphic.selectedDisciplines.size !== 0 && this.IsFilteredByDiscipline(discipline)) {
        this.graphic.selectedDisciplines.delete(discipline);
      } else {
        this.graphic.selectedDisciplines.add(discipline);
      }

      this.graphic.updateLayers();
    }
  }
}
