import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormControl,
  NgControl,
  RequiredValidator,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiFormFieldsetComponent } from '../form-fieldset/si-form-fieldset.component';
import { SiFormContainerComponent } from '../si-form-container/si-form-container.component';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '../si-form-item.control';
import {
  SI_FORM_VALIDATION_ERROR_MAPPER,
  SiFormValidationErrorMapper
} from '../si-form-validation-error-mapper.provider';
import { SiFormFieldFallbackControl } from './si-form-field-fallback.control';

interface SiFormError {
  message: string;
  params: any;
}

@Component({
  selector: 'si-form-item',
  styleUrl: '../si-form.shared.scss',
  templateUrl: './si-form-item.component.html',
  standalone: true,
  imports: [SiTranslateModule, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiFormItemComponent implements AfterViewInit, AfterContentChecked, OnChanges {
  /** @deprecated property has longer an effect. SiFormItem detects IDs automatically  */
  @Input() inputId?: string;

  /**
   * The label to be displayed in the form item.
   * It will be translated if a translation key is available.
   */
  @Input() label?: string | null;

  /**
   * @deprecated This property has no effect and will be dropped.
   * Classes must be applied directly to the `si-form-item`.
   * In general, the label should not be styled.
   */
  @Input() labelClass?: string;

  /** @deprecated this flag has no effect. Using colons is not intended by the design system. */
  @Input({ transform: booleanAttribute }) labelColon = false;

  /**
   * A custom width value to be applied to the label.
   * A value of 0 is allowed as well to visually hide away the label area.
   *
   * Numbers will be converted to pixels.
   * Using numbers is discouraged and might be dropped.
   *
   * @example labelWidth="100px"
   */
  @Input() labelWidth?: string | number;

  /**
   * @deprecated this flag has no effect. A label is always wrapped if it is too long. Clipping is not intended by the design system.
   */
  @Input({ transform: booleanAttribute }) labelWrap = true;

  /** @deprecated this flag has no effect. An additional border is never shown.  */
  @Input({ transform: booleanAttribute }) hideBorder = true;

  /**
   * @deprecated This property has no effect and will be dropped.
   * Classes must be applied directly to the `si-form-item`.
   * In general, the content should not be styled.
   */
  @Input() contentClass?: string;

  /** @deprecated This input has no effect and can be removed. */
  @Input({ transform: booleanAttribute }) readonly = false;

  /** Disables the automatic error printing. Error printing will be enabled by default in v46. */
  @Input({ transform: booleanAttribute }) disableErrorPrinting = true;

  @Input() formErrorMapper?: SiFormValidationErrorMapper;

  /**
   * Defines that this form item is required for the overall form to be valid.
   */
  @HostBinding('class.required') @Input({ transform: booleanAttribute }) required = false;

  @ContentChild(SI_FORM_ITEM_CONTROL) protected fieldControl?: SiFormItemControl;
  @ContentChild(SI_FORM_ITEM_CONTROL, { read: NgControl }) ngControl?: NgControl;
  @ContentChild(SI_FORM_ITEM_CONTROL, { read: RequiredValidator })
  protected requiredValidator?: RequiredValidator;

  protected errors?: SiFormError[];

  protected fieldset = inject(SiFormFieldsetComponent, { optional: true });
  private content = inject<ElementRef<HTMLElement>>(ElementRef);
  protected container = inject(SiFormContainerComponent, { optional: true });

  private applicationFormErrorMapper = inject(SI_FORM_VALIDATION_ERROR_MAPPER);
  private requiredTestControl = new FormControl('');
  private validator?: ValidatorFn | null;
  private previousErrors?: ValidationErrors | null;

  private changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('style.--si-form-label-width')
  protected get labelWidthCssVar(): string | undefined {
    if (typeof this.labelWidth === 'number') {
      return `${this.labelWidth}px`;
    }

    return this.labelWidth;
  }

  protected get printErrors(): boolean {
    return !this.disableErrorPrinting && !(this.container?.disableErrorPrinting ?? false);
  }

  @HostBinding('class.form-check')
  get isCheckItem(): boolean {
    return this.fieldControl?.isFormCheck ?? false;
  }

  @HostBinding('class.form-check-inline') get isInline(): boolean {
    return this.fieldset?.inline ?? false;
  }

  @HostBinding('class.si-form-input') get isFormInput(): boolean {
    return !this.fieldset;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formErrorMapper) {
      this.updateValidationMessages();
    }
  }

  ngAfterContentChecked(): void {
    this.updateRequiredState();
    this.updateValidationMessages();
  }

  ngAfterViewInit(): void {
    queueMicrotask(() => {
      this.changeDetectorRef.markForCheck();
      this.fieldControl ??= SiFormFieldFallbackControl.createForNearestInput(this.content);
    });
  }

  private updateRequiredState(): void {
    if (this.requiredValidator) {
      // the easy case: required validator is applied in the template: <input required>
      const newRequired = booleanAttribute(this.requiredValidator.required);
      if (this.required !== newRequired) {
        this.required = newRequired;
        this.changeDetectorRef.markForCheck();
      }
    } else {
      // No required validator is applied via template, it could still be applied in the code.
      // If validators are applied via code, the validator object will change of controls.
      // So we only need to check for required if the validator object was changed.
      const validator = this.ngControl?.control?.validator;

      if (this.validator !== validator) {
        this.validator = validator;
        this.requiredTestControl.setValidators(this.validator ?? null);
        this.requiredTestControl.updateValueAndValidity();
        this.required = this.requiredTestControl.errors?.required ?? false;
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private updateValidationMessages(): void {
    const currentErrors = this.ngControl?.errors;

    if (this.printErrors && this.previousErrors !== currentErrors) {
      this.changeDetectorRef.markForCheck();
      this.previousErrors = currentErrors;
      if (this.previousErrors) {
        const containerMapper = this.container?.formErrorMapper;
        /*
         * Converts the angular error object (like: {required: true, minLength: {actualLength: 1, requiredLength: 3}})
         * to SiFormError[].
         * Therefore, the error key is look up in the error mappers.
         * If it can be resolved, the error will be printed using the resolved text.
         */
        this.errors = Object.entries(this.previousErrors)
          .map(([key, params]) => ({
            message:
              // lookup value in component specific map
              this.formErrorMapper?.[key] ??
              // Lookup value in the si-form-container mapper (optional).
              // This mapper currently includes the applicationFormErrorMapper.
              // The first look-up uses the full name: `controlName.errorKey`, the second one only the error key.
              containerMapper?.[`${this.ngControl?.name}.${key}`] ??
              containerMapper?.[key] ??
              // form-item can be used standalone, so we need to check to global mapper again
              this.applicationFormErrorMapper?.[`${this.ngControl?.name}.${key}`] ??
              this.applicationFormErrorMapper?.[key],
            params
          }))
          .filter(error => !!error.message) as SiFormError[];
      } else {
        this.errors = undefined;
      }
    }
  }
}
