import { CommandParameters, EnumerationItem } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { EnumValue } from '@simpl/element-value-types';

import { Common } from '../shared/common';
import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType } from './command-vm.types';
import { WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

/**
 * Enum parameter view-model implementation.
 */
export class EnumParamViewModel extends CommandParamViewModel {

  protected textArr: string[];
  protected valueArr: number[];
  protected defaultIdx: number;
  protected min: number;
  protected max: number;

  /**
   * List of possible text values.
   */
  public get enumTextArr(): readonly string[] {
    return this.textArr;
  }

  /**
   * Corresponding list of raw values.
   */
  public get enumValueArr(): readonly number[] {
    return this.valueArr;
  }

  /**
   * Parameter default value as an index into both the `enumValueArr` and `enumTextArr`
   * properties.
   */
  public get defaultIndex(): number {
    return this.defaultIdx;
  }

  /**
   * Minimum allowed value.
   */
  public get minValue(): number {
    return this.min;
  }

  /**
   * Maximum allowed value.
   */
  public get maxValue(): number {
    return this.max;
  }

  /**
   * Parameter data type.
   */
  public get dataType(): CommandParamType {
    return CommandParamType.Enum;
  }

  /**
   * Compare two enumeration items alphabetically by text descriptor.
   * Return a numeric value indicating results:
   *  negative: a < b
   *         0: a == b
   *  positive: a > b
   *
   */
  private static compareEnumTexts(locale: string, a: EnumerationItem, b: EnumerationItem): number {
    const descA: string = a ? a.Descriptor : undefined;
    const descB: string = b ? b.Descriptor : undefined;
    return EnumParamViewModel.compareTexts(locale, descA, descB);
  }

  private static compareTexts(locale: string, a: string, b: string): number {
    if (isNullOrUndefined(a)) {
      return isNullOrUndefined(b) ? 0 : -1;
    } else if (isNullOrUndefined(b)) {
      return 1;
    }

    // NOTE: Use a simple string comparison for enum value comparison (sorting).
    //  localeComparer is very inefficient and probably not crucial for sorting the
    //  values of enum command params....
    let cmpVal = 0;
    if (a < b) {
      cmpVal = -1;
    } else if (a > b) {
      cmpVal = 1;
    }
    /*
    // NOTE: i18n string sorting.  Can be resurected if necessary, but beware of performance hit!
    let opt: Intl.CollatorOptions = {
      sensitivity: "base",        // case insensitive
      ignorePunctuation: false,
      numeric: true               // enable numeric collation, "1" < "2" < "10"
    };
    try {
      cmpVal = a.localeCompare(b, locale, opt);
    }
    catch (e) {   // added for BTQ00320570 - invalid locale string will cause exception on some browsers
      try {
        cmpVal = a.localeCompare(b, undefined, opt);
      }
      catch (e) {
        if (a < b) {
          cmpVal = -1;
        }
        else if (a > b) {
          cmpVal = 1;
        }
      }
    }
*/
    return cmpVal;
  }

  private static isValueInRage(en: EnumerationItem, min: number, max: number): boolean {
    if (en && en.Value >= min && en.Value <= max) {
      return true;
    }
    return false;
  }

  /**
   * Constructor.
   */
  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    this.siParam.value = {
      type: 'enum',
      readonly: false,
      optional: false,
      value: undefined,
      options: []
    } as EnumValue<number>;

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Encode the provided object as a numeric value which is enumeration of the string/value pair.
   * The corresponding enum numeric value will be returned as a string.
   *
   * If the provided object is not an enmeration, "undefined" will be returned.
   *
   * There is a special case for "BasicBool" in which case the 0/1 value is encoded as "false"/"true".
   */
  public encodeValue(obj: any): string {
    let enumValStr: string;
    if (obj && obj.type === 'enum') {
      const enumVal: number = obj.value;
      enumValStr = WsiTranslator.encodeNumeric(enumVal);
    } else if (obj && obj.type === 'boolean') {
      const boolValue: boolean = obj.value;
      enumValStr = String(boolValue);
    }
    return enumValStr;
  }

  public resetParamValue(): void {
    const siVal: EnumValue<number> = this.siParam.value as EnumValue<number>;
    siVal.value = undefined;
    if (this.valueArr && !isNaN(this.defaultIdx)) {
      siVal.value = this.valueArr[this.defaultIdx];
    }
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(param: CommandParamViewModel): boolean {
    if (!param || param.dataType !== CommandParamType.Enum) {
      return false; // undefined param or type mismatch!
    }
    const p: EnumParamViewModel = param as EnumParamViewModel;

    // Combine enumerations in case of enum filtering
    const isChanged: boolean = this.combineEnumTexts(p);

    // Re-evaluate default enum index
    let idx: number;
    if (this.paramRaw.DefaultValue === p.paramRaw.DefaultValue) {
      if (isChanged) {
        // Reset default index as enumeration has been updated!
        const dv: number = parseInt(p.paramRaw.DefaultValue, 10);
        if (!isNaN(dv)) {
          const i: number = this.valueArr.findIndex(v => v === dv);
          if (i >= 0) {
            idx = i; // found
          }
        }
      } else {
        idx = this.defaultIdx; // no change to enumeration or default value
      }
    }
    this.defaultIdx = idx;

    return true;
  }

  private combineEnumTexts(p: EnumParamViewModel): boolean {
    if (this.inferred) {
      return false; // Filtered enum parameters are not supported (or possible) on inferred parameters (representing array index)!
    }
    if (!p || p.valueArr.length === 0) {
      return false; // nothing to combine
    }

    // If enum arrays are the same (by value), there is nothing to do
    const xArr: number[] = this.valueArr;
    const yArr: number[] = p.valueArr;
    if (xArr.length === yArr.length && xArr.every((v, idx) => v === yArr[idx])) {
      return false; // enumerations are the same by value
    }

    // Create combined enumeration of unique items from both parameter enumerations
    const enumTexts: EnumerationItem[] = this.paramRaw.EnumerationTexts
      .filter(item => EnumParamViewModel.isValueInRage(item, this.minValue, this.maxValue));
    p.paramRaw.EnumerationTexts
      .filter(item => EnumParamViewModel.isValueInRage(item, p.minValue, p.maxValue))
      .forEach(item => {
        if (item && enumTexts.findIndex(i => i.Value === item.Value) < 0) {
          enumTexts.push(item);
        }
      });

    // Sort resulting enumeration
    enumTexts.sort((a, b) => EnumParamViewModel.compareEnumTexts(this.vmContext.locale, a, b));

    // Rebuild public array elements
    this.textArr = [];
    this.valueArr = [];
    enumTexts.forEach(en => {
      if (en) {
        this.textArr.push(en.Descriptor);
        this.valueArr.push(en.Value);
      }
    });

    return true; // enum updated!
  }

  /**
   * Update the parameter default value.
   * In the case of enumerations, the default value is used to set the default index into
   * the enumeration text and value arrays.
   */
  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;

    if (!p) {
      return;
    }

    const uint32Max = 4294967295;
    this.max = Common.limit(parseInt(p.Max, 10), 0, 0, uint32Max, true);
    this.min = Common.limit(parseInt(p.Min, 10), 0, 0, this.max, false);
    this.textArr = [];
    this.valueArr = [];

    if (p.EnumerationTexts && p.EnumerationTexts.length > 0) {
      const enumTexts: EnumerationItem[] = p.EnumerationTexts;
      // Sort texts only of this parameter is NOT inferred.  Inferred parameters correspond
      //  to array index values and should remain in index order.
      if (!this.inferred) {
        enumTexts.sort((a, b) => EnumParamViewModel.compareEnumTexts(this.vmContext.locale, a, b));
      }

      // Extract sorted texts to VM property arrays
      enumTexts.forEach(en => {
        if (en && EnumParamViewModel.isValueInRage(en, this.minValue, this.maxValue)) {
          this.textArr.push(en.Descriptor);
          this.valueArr.push(en.Value);
        }
      });

    // Native PVSS bool property types (and associated boolean command parameters) will not have
    // a text-table assignment at the server, which would normally provide valid value/text value
    // pairs.  So, we treat this as a SPECIAL CASE by hard coding simple text/value info in
    // order to allow commands with these property types to be useable.
    } else if (this.nativeType === 'BasicBool') {
      this.valueArr.push(0, 1);
      this.textArr = this.valueArr.map(i => String(Boolean(i)));
    }

    // Set index of default value
    this.defaultIdx = undefined;
    const dv: number = parseInt(p.DefaultValue, 10);
    if (!isNaN(dv)) {
      const idx: number = this.valueArr.findIndex(v => v === dv);
      if (idx >= 0) {
        this.defaultIdx = idx; // found
      }
    }

    // Update si-property value set
    const siVal: EnumValue<number> = this.siParam.value as EnumValue<number>;
    siVal.options = this.valueArr.map((key, idx) => ({
      value: key,
      text: this.textArr[idx]
    }));
    this.resetParamValue();
  }
}
