import { EventEmitter } from '@angular/core';
import { BulkCommandInput2, CommandInput2, ExecuteCommandServiceBase, ValidationCommandInfo, ValidationResult, ValidationResultStatus } from '@gms-flex/services';
import { Observer, Subject, Subscription } from 'rxjs';

import { ValidationDialogService } from '../../../../validation-dialog/services/validation-dialog.service';
import { DatapointStatus } from '../../types/datapoint/gms-status';
import { FormatHelper } from '../../utilities/format-helper';
import { GmsCommand } from '../command/gms-command';
import { CommandStatus, CommandStatusChangeArgs, CommandValidationStatus } from '../command/gms-command-status';
import { CommandParameter, ParameterType } from '../command/parameters/gms-base-parameter';
import { ParameterMultiStateViewModel } from './gms-parameter-multistate-vm';
import { ParameterNumericViewModel } from './gms-parameter-numeric-vm';
import { ParameterStringViewModel } from './gms-parameter-string-vm';
import { ParameterViewModelBase } from './gms-parameter-vm.base';

export class GmsCommandResult {
  constructor(
    private readonly success: boolean,
    private readonly error?: string) {
  }
  public get Success(): boolean {
    return this.success;
  }
  public get Error(): string {
    return this.error;
  }
}

export class PropertyChangedEventArgs {
  private _propertyName: string;
  public get PropertyName(): string {
    return this._propertyName;
  }
  public set PropertyName(value: string) {

    if (this._propertyName !== value) {
      this._propertyName = value;
    }
  }
}

export class CommandViewModel {
  private _suspended = false;
  public get Suspended(): boolean {
    return this._suspended;
  }
  public set Suspended(value: boolean) {
    this._suspended = value;
  }

  private readonly _locale: string;
  public get locale(): string {
    return this._locale;
  }

  private readonly _defaultlocale: string;
  public get defaultlocale(): string {
    return this._defaultlocale;
  }

  public get Parameters(): Map<string, ParameterViewModelBase[]> {
    if (this._parameters === undefined) {
      this._parameters = new Map<string, ParameterViewModelBase[]>();
    }
    return this._parameters;
  }

  /**
   * Command Parameter format supplied by a user.
   * Example is Priority=8;Value
   */
  public get CommandParameter(): string {
    return this._commandParameter;
  }
  public set CommandParameter(value: string) {
    this._commandParameter = value;
  }
  public get Name(): string {
    return this._name;
  }
  public set Name(value: string) {
    this._name = value;
  }
  public get Key(): string {
    return this._key;
  }
  public set Key(value: string) {
    this._key = value;
  }
  public get IsInDesignMode(): boolean {
    return this._isInDesignMode;
  }
  public set IsInDesignMode(value: boolean) {
    this._isInDesignMode = value;
  }
  public get IsDefaultCommand(): boolean {
    return this._isDefaultCommand;
  }
  public set IsDefaultCommand(value: boolean) {
    this._isDefaultCommand = value;
  }

  public get IsUIEnabled(): boolean {
    return this._commandStatus.IsEnabled;
  }
  public set IsUIEnabled(value: boolean) {
    const oldValue: boolean = this._commandStatus.IsUIEnabled;
    this._commandStatus.IsUIEnabled = value;
    if (oldValue !== value) {
      this.NotifyPropertyChanged('IsUIEnabled');
      this.NotifyPropertyChanged('IsEnabled');
      this.NotifyPropertyChanged('IsCommandEnabled');
      this.NotifyPropertyChanged('IsTriggerEnabled');
    }
  }
  // Is command UI enabled status
  public get IsEnabled(): boolean {
    return this._commandStatus.IsEnabled;
  }

  /**
   *  Is command UI enabled and connected status (can be executed)
   */
  public get IsCommandEnabled(): boolean {
    return this._commandStatus.IsCommandEnabled;
  }
  public get IsCommandStatusValid(): boolean {
    return this._commandStatus.IsValid;
  }
  public get IsTriggerEnabled(): boolean {
    return this._commandStatus.IsTriggerEnabled;
  }
  public set IsTriggerEnabled(value: boolean) {
    const oldValue: boolean = this._commandStatus.IsTriggerEnabled;
    this._commandStatus.IsTriggerEnabled = value;
    if (oldValue !== value) {
      this.NotifyPropertyChanged('IsTriggerEnabled');
    }
  }

  public get IsConnected(): boolean {
    return this._commandStatus.IsConnected;
  }
  public set IsConnected(value: boolean) {
    this._commandStatus.IsConnected = value;
    this.NotifyPropertyChanged('IsConnected');
    this.NotifyPropertyChanged('IsCommandEnabled');
  }

  public get IsBLEnabled(): boolean {
    return this._commandStatus.IsBLEnabled;
  }
  public set IsBLEnabled(value: boolean) {
    const oldValue: boolean = this._commandStatus.IsBLEnabled;
    this._commandStatus.IsBLEnabled = value;
    if (oldValue !== value) {
      this.NotifyPropertyChanged('IsBLEnabled');
      this.NotifyPropertyChanged('IsUIEnabled');
      this.NotifyPropertyChanged('IsEnabled');
      this.NotifyPropertyChanged('IsCommandEnabled');
      this.NotifyPropertyChanged('IsTriggerEnabled');
    }
  }

  public get IsUIConfigurationValid(): boolean {
    return this._commandStatus.IsUIConfigurationValid;
  }
  public set IsUIConfigurationValid(value: boolean) {
    if (this._commandStatus.IsUIConfigurationValid !== value) {
      this._commandStatus.IsUIConfigurationValid = value;
      this.NotifyPropertyChanged('IsUIConfigurationValid');
    }
  }
  public get isModified(): boolean {
    return this._isModified;
  }
  public set isModified(value: boolean) {
    if (this._isModified !== value) {
      this._isModified = value;
      this.NotifyPropertyChanged('isModified');
    }
  }
  public get isCommandExecuteDone(): boolean {
    return this._isCommandExecuteDone;
  }
  public set isCommandExecuteDone(value: boolean) {
    if (this._isCommandExecuteDone !== value) {
      this._isCommandExecuteDone = value;
      this._isCommandExecuteCancel = false;
      this.NotifyPropertyChanged('isCommandExecuteDone');
    }
  }
  public get isCommandExecuteCancel(): boolean {
    return this._isCommandExecuteCancel;
  }
  public set isCommandExecuteCancel(value: boolean) {
    if (this._isCommandExecuteCancel !== value) {
      this._isCommandExecuteCancel = value;
      this.NotifyPropertyChanged('isCommandExecuteCancel');
    }
  }
  public get ErrorDescription(): string {
    return this._errorDescription;
  }

  private _commandButtonCounter = 0;
  public get CommandButtonExist(): boolean {
    return this._commandButtonCounter > 0;
  }

  public AddCommandButton(): void {
    this._commandButtonCounter++;
  }

  public RemoveCommandButton(): void {
    this._commandButtonCounter--;
  }

  public constructor(parametersFormat: string, command: GmsCommand, locale: string, defaultlocale: string) {
    this._locale = locale;
    this._defaultlocale = defaultlocale;
    if (command === undefined || command === null) { // build a design mode VM
      this._commandStatus = new CommandStatus(true);
      this._commandStatus.IsUIEnabled = true;
      this._commandStatus.IsConnected = true;

      this.CommandParameter = (parametersFormat !== undefined) ? '' : parametersFormat;

      this.IsInDesignMode = true;
    } else {
      this.initCommandViewModel(command, parametersFormat);
    }
  }

  public propertyChanged: Subject<PropertyChangedEventArgs> = new Subject<PropertyChangedEventArgs>();

  private _commandStatus: CommandStatus;
  private _commandParameter = '';
  private _propertyId = '';
  private _commandPropertyChangedSub: Subscription;
  private _parameters: Map<string, ParameterViewModelBase[]>;

  private _name: string;

  private _key: string;

  private _isInDesignMode: boolean;
  private _isDefaultCommand: boolean;
  private _isModified = false;

  private _isCommandExecuteDone = false;
  // Spinner delayed execute command
  private _isCommandExecuteCancel = false;

  private _errorDescription: string = undefined;

  private _hasParameters = false;
  private onDialogUpdate: Subject<any>;
  private subscriptionValidationResult: Subscription;

  public IsInputValid(locale: string): boolean {
    let ret = true;
    this._errorDescription = undefined;
    this.Parameters.forEach(parmVM => {
      if (ret && !parmVM[0].isInputValid(locale)) {
        this._errorDescription = parmVM[0].errorStatus;
        ret = false;
      }
    });
    return ret;
  }

  public unsubscribeFromUpdates(): void {
    if (this._commandPropertyChangedSub !== undefined) {
      this._commandPropertyChangedSub.unsubscribe();
      this._commandPropertyChangedSub = undefined;
    }
  }

  public initCommandViewModel(command: GmsCommand, commandParameter: string): void {

    this._commandStatus = new CommandStatus(false);
    this._commandStatus.IsUIEnabled = false;
    this._commandStatus.IsConnected = command.Datapoint !== undefined && command.Datapoint.Status === DatapointStatus.Valid; // true;
    this.CommandParameter = commandParameter;
    if (command === null || command === undefined || command.Datapoint === undefined) {
      return;
    }
    this._commandStatus.CommandValidationStatus = command.CommandStatus.CommandValidationStatus;

    this._commandStatus.IsBLEnabled = command.CommandStatus.IsBLEnabled;

    this.Name = command.CommandName;
    this.Key = command.Key;
    this._propertyId = command.Id;

    this.IsInDesignMode = false;
    this.IsDefaultCommand = command.DefaultCommand;

    this._hasParameters = command.Parameters.length > 0;

    this.SubscribeForCommandUpdates(command);

    this.UpdateCommandViewModel(command);
  }

  public Destroy(): void {
    if (this.subscriptionValidationResult !== undefined && !this.subscriptionValidationResult.closed) {
      this.subscriptionValidationResult.unsubscribe();
      this.subscriptionValidationResult = undefined;
    }
    this.Parameters.forEach(parmList => {
      parmList.forEach(parameter => {
        parameter.Destroy();
      });
    });
    this.Parameters.clear();
  }

  public executeCommand(validateService: ValidationDialogService,
    executeCommandservice: ExecuteCommandServiceBase,
    observer: Observer<GmsCommandResult>, locale: string): void {
    try {
      this.ExecuteCommandImplementation(observer, validateService, executeCommandservice, locale);
    } catch (err) {
      observer.error(err);
    }
  }

  public addParameterViewModel(command: GmsCommand, parameterName: string, subscribeForUpdate: boolean = true): ParameterViewModelBase {

    if (command === null) {
      return null;
    }
    if (!command.CommandStatus.IsValid) {
      return null;
    }
    let parameterVM: ParameterViewModelBase = null;
    command.Parameters.forEach(p => {
      if (p.Name === parameterName) {
        parameterVM = this.CreateCommandParameterViewModel(p);
        if (parameterVM !== null) {

          let list: ParameterViewModelBase[];
          if (this.Parameters.has(parameterName)) {
            list = this.Parameters.get(parameterName);
            if (list.length === 1 && list[0].countUsage === 0) {
              parameterVM = list[0];
              parameterVM.countUsage += 1;
            } else {
              list.push(parameterVM);
              this.Parameters.set(parameterName, list);
            }
          } else {
            list = new Array<ParameterViewModelBase>();
            list.push(parameterVM);
            this.Parameters.set(parameterName, list);
          }
          parameterVM.locale = this.locale;
          parameterVM.defaultlocale = this.defaultlocale;
          parameterVM.SetParameterValue(p.Datapoint);
          parameterVM.subscribeForCommandParameterUpdates(p);
        }
      }

    });
    return parameterVM;
  }

  public validateCommandParameter(command: GmsCommand): void {
    if (!!command && this.IsCommandStatusValid) {
      if (this.Parameters.keys.length > 0) {
        const parameterValuesMap: Map<string, string> = this.ParseParameterString(this.CommandParameter);
        command.Parameters.forEach(par => {
          if (!parameterValuesMap.has(par.Name)) {
            this.IsUIConfigurationValid = false;
          }
        });
      } else {
        this.IsUIConfigurationValid = true;
      }
    }
  }

  public setParametersReadOnly(readOnly: boolean = true): void {
    this.Parameters.forEach(values => {
      values.forEach(parmVM => {
        parmVM.isReadOnly = readOnly;
        if (!this.IsConnected) {
          parmVM.Value = '#COM';
        }
      });
    });
  }

  public updateParameters(): void {
    this.Parameters.forEach(parmList => {
      parmList.forEach(parameter => {
        parameter.setParameterToDefaultValue();
      });
    });
  }

  public SetParamFormattedValue(sender: ParameterViewModelBase, value: string): void {

    const parmList: ParameterViewModelBase[] = sender ? this.Parameters.get(sender.name) : undefined;
    if (parmList === undefined) {
      return;
    }
    parmList.forEach(parameter => {
      if (parameter !== sender) {
        parameter.setParameterFormattedValue(value, true);
      }
    });
  }

  public unsubscribe(): void {
    this.unsubscribeFromUpdates();
    this.UnsubscribeFromParametersUpdates();
  }

  public UnsubscribeFromParametersUpdates(): void {
    this.Parameters.forEach(list => {
      list.forEach(paramVM => {
        paramVM.unSubscribeFromCommandParameterUpdates();
      });
    });
  }

  private ExecuteCommandImplementation(observer: Observer<GmsCommandResult>,
    validationDialogService: ValidationDialogService,
    executeCommandservice: ExecuteCommandServiceBase, locale: string): void {
    if (this.isCommandExecuteCancel) { // Spinner's delayed command canceled (cancel button pressed)
      this.isCommandExecuteCancel = false;
      return;
    }
    if (this.Suspended) {
      this.Suspended = false;
      return;
    }
    this.Suspended = true;

    const propertyIdsList: string[] = [this._propertyId];
    const commandInfo: ValidationCommandInfo = new ValidationCommandInfo(propertyIdsList, 0);
    this.onDialogUpdate = validationDialogService.show(commandInfo);
    this.subscriptionValidationResult = this.onDialogUpdate.subscribe((validationResult: ValidationResult) => {
      this.subscriptionValidationResult.unsubscribe();
      this.subscriptionValidationResult = undefined;
      if (validationResult === undefined || validationResult.Status !== ValidationResultStatus.Success) {
        const msg: string = validationResult === undefined ? '' : validationResult.Error;
        this.onExecuteCommandError(observer, msg);
        this.Suspended = false;
      } else {
        let parameterValuesMap: Map<string, string> = new Map<string, string>();
        const commandInput: CommandInput2[] = [];
        // 1. Parse ParametersFormat string to map (param name, param value)
        parameterValuesMap = this.ParseParameterString(this.CommandParameter);

        // 2. Get parameters list
        const parameterList: ParameterViewModelBase[][] = Array.from(this.Parameters.values());

        // 3. Fill the dictionary with actual parameter values
        parameterList.forEach(parmVMs => {
          if (parmVMs.length > 0) {
            const parmVM: ParameterViewModelBase = parmVMs[0];
            const cmndInpt: CommandInput2 = this.createCommandInput(parmVM, parameterValuesMap, locale);
            commandInput.push(cmndInpt);
          }
        });
        const bulkCommandInput: BulkCommandInput2 = {
          CommandInputForExecution: commandInput,
          PropertyIds: [this._propertyId],
          Comments: validationResult.Comments,
          Password: validationResult.Password,
          SuperName: validationResult.SuperName,
          SuperPassword: validationResult.SuperPassword,
          SessionKey: validationResult.SessionKey
        };
        this.Suspended = false;
        executeCommandservice.executeCommands2(this.Name, bulkCommandInput)
          .subscribe(
            () => this.onExecuteCommandSuccess(observer),
            (err: any) => this.onExecuteCommandError(observer, err)
          );
      }
    });
  }

  private onExecuteCommandSuccess(observer: Observer<GmsCommandResult>): void {
    if (!this.IsDefaultCommand) {
      this.Parameters.forEach(values => {
        values.forEach(parmVM => {
          let val: string = parmVM.DpValue;
          if (val !== undefined) {
            if (parmVM.ParameterType === ParameterType.Numeric) {
              const formattedValue: number = FormatHelper.getNumberfromString(val, parmVM.locale);
              val = FormatHelper.NumberToString(formattedValue, FormatHelper.enUS, null, 10);
              parmVM.DpValue = val;
            }
          }
          parmVM.defaultValue = parmVM.DpValue;
          parmVM.Value = parmVM.DpValue;
        });
      });
    }
    const result: GmsCommandResult = new GmsCommandResult(true);
    observer.next(result);
  }

  private onExecuteCommandError(observer: Observer<GmsCommandResult>, err: any): void {
    let errMesg: string;
    if (err && err.message) {
      errMesg = String(err.message);
    }
    const result: GmsCommandResult = new GmsCommandResult(false, errMesg);
    observer.next(result);
  }

  private createCommandInput(parmVM: ParameterViewModelBase, parameterValuesMap: Map<string, string>, locale: string): CommandInput2 {
    const mappedValue: string = parameterValuesMap.get(parmVM.name);
    const formattedValue: string = mappedValue !== '' && mappedValue !== undefined ? mappedValue : parmVM.FormattedValue;

    let result: string;
    // remove grouping separator for numerics
    if (parmVM.ParameterType === ParameterType.Numeric) {
      // "5,1" => "5.1" from locale to enUS
      result = FormatHelper.Replace(formattedValue, FormatHelper.getGroupingSeparator(locale));
      const num: number = FormatHelper.getNumberfromString(result, locale);
      // format the number to a enUS string
      result = FormatHelper.NumberToString(num, FormatHelper.enUS, FormatHelper.enUS, parmVM.precision);
      // trim groupingSeparator from the enUS string
      result = FormatHelper.Replace(result, FormatHelper.getGroupingSeparator(FormatHelper.enUS));
    } else {
      result = formattedValue;
    }
    return {
      Name: parmVM.name,
      DataType: parmVM.dataType,
      Value: result
    };
  }

  private ParseParameterString(parameters: string): Map<string, string> {
    const parameterValuesMap: Map<string, string> = new Map<string, string>();
    if (parameters === undefined || parameters === '') {

      return parameterValuesMap;
    }
    try {

      // Examples:
      // Value = 1.5; Priority = 8             -> just values
      // Value = ”Text1; Text2”; Priority = 8  -> value contains separator -> use quotes
      // Value = My Text; Priority = 8         -> value contains space
      // Value;Priority                        -> value not set

      // Split string info into individual parameters
      // eslint-disable-next-line @typescript-eslint/quotes
      const pattern: any = new RegExp("(?:^|;)((?:[^\";]|\"[^\"]*\")*)");
      const parameterList: string[] = parameters.split(pattern);

      parameterList.forEach((parameter: string) => {
        // Split the parameter into name and value "Value = 1.5"
        const parts: string[] = parameter.split('=');

        const key: string = parts.length > 0 ? parts[0].trim() : ''; // Value
        const value: string = parts.length > 1 ? parts[1] : ''; // 1.5

        if (!parameterValuesMap.has(key)) {
          parameterValuesMap.set(key, value);
        }
      });
    } catch (ex) {
      // NOTE: add traces
    }

    return parameterValuesMap;

  }

  private SubscribeForCommandUpdates(command: GmsCommand): void {
    if (command === undefined || command === null) {
      return;
    }
    if (this._commandPropertyChangedSub !== undefined) {
      this._commandPropertyChangedSub.unsubscribe();
      this._commandPropertyChangedSub = undefined;
    }
    this._commandPropertyChangedSub = command.statusChanged.subscribe(args => this.Command_PropertyChanged(args));
  }

  private CreateCommandParameterViewModel(parameter: CommandParameter): ParameterViewModelBase {

    let commandParameterViewModel: ParameterViewModelBase = null;

    switch (parameter.ParameterType) {

      case ParameterType.Numeric:
        commandParameterViewModel = new ParameterNumericViewModel(parameter);
        break;
      case ParameterType.NumericInt64:
      case ParameterType.NumericUInt64:
        commandParameterViewModel = new ParameterNumericViewModel(parameter);
        break;
      case ParameterType.Priority:
        // no sorting
        commandParameterViewModel = new ParameterMultiStateViewModel(parameter);
        break;

      case ParameterType.MultiState:
        commandParameterViewModel = new ParameterMultiStateViewModel(parameter);
        break;

      case ParameterType.String:
        commandParameterViewModel = new ParameterStringViewModel(parameter);
        break;

      default:
        break;
    }
    if (commandParameterViewModel !== null) {
      commandParameterViewModel.CommandViewModel = this;
    }
    return commandParameterViewModel;
  }

  private UpdateCommandViewModel(command: GmsCommand): void {
    if (command === undefined || command === null) {
      return;
    }

    this.Parameters.clear();

    if (!command.CommandStatus.IsValid) {
      this.IsUIEnabled = false;
    } else {
      command.Parameters.forEach(p => {
        const parameter: ParameterViewModelBase = this.CreateCommandParameterViewModel(p);
        if (parameter !== null) {
          const array: ParameterViewModelBase[] = new Array<ParameterViewModelBase>();
          array.push(parameter);
          this.Parameters.set(p.Name, array);
        } else {
          // NOTE: log info
        }
      });

      this.IsBLEnabled = command.IsEnabled;
    }
    this._hasParameters = command.Parameters.length > 0;
  }

  private Command_PropertyChanged(args: CommandStatusChangeArgs): void {
    const command: GmsCommand = args !== undefined ? args.Command : undefined;
    if (command !== undefined) {
      switch (args.PropertyName) {

        case 'Status':
          this.IsConnected = (command.Datapoint !== undefined) && (command.Datapoint.Value !== undefined) &&
                        (command.Datapoint.Status === DatapointStatus.Valid);
          break;

        case 'CommandStatus':
          this.IsUIConfigurationValid = command.CommandStatus.CommandValidationStatus === CommandValidationStatus.Valid;
          break;

        case 'IsEnabled':
          this.IsBLEnabled = command.IsEnabled;
          break;

        default:
          break;
      }
    }
  }

  private NotifyPropertyChanged(propertyName: string = ''): void {
    const e: PropertyChangedEventArgs = new PropertyChangedEventArgs();
    e.PropertyName = propertyName;
    this.propertyChanged.next(e);
  }

}
