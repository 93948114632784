<div
  class="alert d-flex align-items-start"
  role="alert"
  aria-atomic="true"
  aria-live="assertive"
  [ngClass]="'alert-' + severity"
  [hidden]="!severity"
>
  <si-icon
    class="me-4 flex-shrink-0"
    alt=""
    [icon]="icon.icon"
    [color]="icon.color"
    [stackedIcon]="icon.stacked"
    [stackedColor]="icon.stackedColor"
  />
  <div class="d-flex flex-grow-1 flex-wrap mt-1">
    @if (heading) {
      <strong class="pe-2">{{ heading | translate: translationParams }}: </strong>
    }
    <span>{{ message | translate: translationParams }}</span>
  </div>
  @if (action && action.title) {
    <a class="alert-link ms-4 flex-shrink-0 mt-1" [siLink]="action">
      {{ action.title | translate: translationParams }}
    </a>
  }
</div>
