import {
  booleanAttribute,
  ChangeDetectorRef,
  computed,
  Directive,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  Signal
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import {
  SI_SELECT_OPTIONS_STRATEGY,
  SiSelectOptionsStrategy
} from '../options/si-select-options-strategy';

/**
 * Selection strategy base class.
 */
@Directive({ standalone: true })
export abstract class SiSelectSelectionStrategy<T, IV = T | T[]> implements ControlValueAccessor {
  /** Whether the select input is disabled.   */
  @HostBinding('class.disabled')
  @Input({ transform: booleanAttribute })
  disabled = false;

  /**
   * The selected value(s).
   */
  @Input() set value(value: IV | undefined) {
    this.updateFromInput(this.toArrayValue(value));
  }

  /** Emitted when the selection is changed */
  @Output() readonly valueChange = new EventEmitter<IV>();

  /**
   * Whether the select control allows to select multiple values.
   * @internal
   */
  abstract readonly allowMultiple: boolean;

  /**
   * Provides the internal value always as an array
   * @internal
   */
  arrayValue: Signal<readonly T[]> = computed(() =>
    this.selectOptions.selectedRows().map(option => option.value)
  );

  /**
   * Registered form callback which shall be called on blur.
   * @internal
   */
  onTouched: () => void = () => {};

  protected onChange: (_: any) => void = () => {};

  private selectOptions = inject<SiSelectOptionsStrategy<T>>(SI_SELECT_OPTIONS_STRATEGY);
  private changeDetectorRef = inject(ChangeDetectorRef);

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * CDK Listbox value changed handler.
   * @internal
   *
   * @param values
   */
  updateFromUser(values: T[]): void {
    const parsedValue = this.fromArrayValue(values);
    this.onChange(parsedValue);
    this.valueChange.emit(parsedValue);
    this.selectOptions.onValueChange(values);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  writeValue(obj: any): void {
    this.updateFromInput(this.toArrayValue(obj));
  }

  protected abstract toArrayValue(value: IV | undefined): readonly T[];

  protected abstract fromArrayValue(value: readonly T[]): IV | undefined;

  private updateFromInput(values: readonly T[]): void {
    this.selectOptions.onValueChange(values);
  }
}
