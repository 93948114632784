import { BrowserObject, CnsHelperService, CnsLabel, CnsLabelEn,
  CommandSubscriptionServiceBase, ExecuteCommandServiceBase, GmsSubscription, PropertyCommand,
  PropertyDetails, PropertyInfo, PropertyServiceBase,
  ReadCommandServiceBase, ValueDetails, ValueSubscription2ServiceBase } from '@gms-flex/services';
import { isNullOrUndefined, SettingsServiceBase, TraceService } from '@gms-flex/services-common';
import { ValidationDialogService } from '@gms-flex/snapin-common';
import { AnyCommandingProperty, AnyProperty, BulkProperty, BulkPropertyValues } from '@simpl/object-browser-ng';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Common } from '../shared/common';
import { TraceModules } from '../shared/trace-modules';
import { WsiTranslator } from './data-model-helper';
import { AggregatePropertyInstance } from './property-instance-aggregate-vm';
import { ObjectPropertyInstance } from './property-instance-single-vm';
import { PropertyViewModel } from './property-vm';
import { ContextState, ServiceStore, ViewModelContext } from './snapin-vm.types';

/**
 * SnapIn view-model.
 */
export class PropertySnapInViewModel {

  public siSelectionContext: string | string[] | undefined;

  private locale: string;

  private context: BrowserObject[];
  private contextDetailProp: AggregatePropertyInstance;
  private contextStateInternal: ContextState;
  private contextId = 0;
  private contextDesc: string[];

  // View-model property lists
  // These view-model property lists are set once on each context change and used to derive
  // the filtered si-property lists that are ultimately bound to the si-property-viewer control.
  private propertyListStd: PropertyViewModel[];
  private propertyListExt: PropertyViewModel[];

  // si-propetry lists filtered
  private siPropertyListStd: AnyCommandingProperty[];
  private siPropertyListExt: AnyCommandingProperty[];
  // si-buld-property lists filtered
  private siBulkPropertyListStd: BulkProperty[];
  private siBulkPropertyListExt: BulkProperty[];

  private hasFunction = false;
  private showExt: boolean;
  private propFilter: string;
  private readonly propFilterChangedInd: Subject<void>;
  private readonly propertyListChangedInd: Subject<void>;
  private readonly loadingInd: Subject<boolean>;
  private readonly contextChange = new Subject<void>();

  private valueSubscriptionReg: string = undefined;
  private cmdSubscriptionReg: string = undefined;
  private isDisposed = false;
  private cnsSetting: CnsLabel;

  private cnsHelperServiceSubscription: Subscription;

  private readonly usageMaskConfiguration: number = 0x8; // a.k.a., display-level 0
  private readonly usageMaskMainOp: number = 0x4; // a.k.a., display-level 1
  private readonly usageMaskStdOp: number = 0x1; // a.k.a., display-level 2
  private readonly usageMaskExtOp: number = 0x2; // a.k.a., display-level 3

  private readonly userSettingOperationDisplayMode: string = 'Flex_PropertyViewer_OperDisplayMode';

  private readonly trmod: string = TraceModules.pvc;

  private readonly svc: ServiceStore;

  /**
   * Id of the VM (typically the snapId of the associated snapin).
   */
  public get id(): string {
    return this.sniId;
  }

  /**
   * State of the current selection context (empty, single, multiple objects).
   */
  public get contextState(): ContextState {
    return this.contextStateInternal;
  }

  /**
   * Number of objects in the current selection context.
   */
  public get contextObjectCount(): number {
    return this.context ? this.context.length : 0;
  }

  /**
   * Currenct selection context list.
   */
  public get contextObjectList(): BrowserObject[] {
    return this.context || [];
  }

  /**
   * Description of the currently set view-model context (the DP or set of DPs).
   * Returns `undefined` if not single selection context.
   */
  public get title(): string {
    if (this.contextState === ContextState.SingleObject) {
      return this.contextDesc && this.contextDesc.length > 0 ? this.contextDesc[0] : undefined;
    }
    return undefined;
  }

  /**
   * Sub-description of the currently set view-model context (shown optionally
   * depending on state of `subTitleEnabled`)
   */
  public get subTitle(): string {
    if (this.contextState === ContextState.SingleObject) {
      return this.subTitleEnabled ? this.contextDesc[1] : undefined;
    }
    return undefined;
  }

  /**
   * Indicates whether the `subTitle` property is in use.
   */
  public get subTitleEnabled(): boolean {
    if (!this.cnsSetting) {
      return false;
    }
    const lab: CnsLabelEn = this.cnsSetting.cnsLabel;
    return Boolean(this.contextDesc && this.contextDesc.length > 1 &&
      lab !== CnsLabelEn.Description && lab !== CnsLabelEn.Name);
  }

  /**
   * Set true if the extended property list is being shown; false if the
   * standard property list is being shown.
   */
  public get showPropertyListExt(): boolean {
    return Boolean(this.showExt);
  }

  /**
   * Control whether the standard or extended property list is shown.
   */
  public set showPropertyListExt(flag: boolean) {
    const flagCoerced = Boolean(flag); // in case undefined
    if (this.showExt === flagCoerced) {
      return; // no change!
    }
    this.showExt = flagCoerced;
    // Notify that a the property list has been switched
    this.propertyListChangedInd.next();
    // Write the changed operation state to user-settings at the server
    const val: string = WsiTranslator.encodeBoolean(this.showExt);
    this.svc.settingsService.putSettings(this.userSettingOperationDisplayMode, val).subscribe();
  }

  /**
   * String used to filter the items shown in the property list.
   */
  public get propertyFilter(): string {
    return this.propFilter || '';
  }

  public set propertyFilter(filter: string) {
    if (this.propFilter === filter) {
      return;
    }
    this.propFilter = filter || undefined;
    this.propFilterChangedInd.next();
  }

  public get siPropertyList(): AnyCommandingProperty[] {
    return this.showPropertyListExt ? this.siPropertyListExt : this.siPropertyListStd;
  }

  public get siBulkPropertyList(): BulkProperty[] {
    return this.showPropertyListExt ? this.siBulkPropertyListExt : this.siBulkPropertyListStd;
  }

  public get propertyListChanged(): Observable<void> {
    return this.propertyListChangedInd;
  }

  public get loading(): Observable<boolean> {
    return this.loadingInd;
  }

  /**
   * Indicates if the data-point represented by the context has assigned
   * function properties.
   */
  public get hasFunctionProperties(): boolean {
    return this.hasFunction;
  }

  /**
   * If a detail context has been set, this property will reference the aggregate-property
   * instance that is the target of the detail view.
   *
   * If the detail context is not set (clear), this property will be undefined.
   */
  public get detailProperty(): AggregatePropertyInstance {
    return this.contextDetailProp;
  }

  /**
   * Compare two BrowserObject instance for equality.
   */
  public static browserObjectEqual(b1: BrowserObject, b2: BrowserObject): boolean {
    let isSame = false;
    if (b1 === undefined || b1 === null) {
      isSame = b2 === undefined || b2 === null;
    } else if (b2 === null || b2 === undefined) {
      isSame = false;
    } else {
      const b1Alias: string = b1.Attributes ? b1.Attributes.Alias : undefined;
      const b2Alias: string = b2.Attributes ? b2.Attributes.Alias : undefined;

      isSame =
            b1.Descriptor === b2.Descriptor &&
            b1.Designation === b2.Designation &&
            b1.Name === b2.Name &&
            b1.Location === b2.Location &&
            b1.ObjectId === b2.ObjectId &&
            b1.SystemId === b2.SystemId &&
            b1.ViewId === b2.ViewId &&
            b1Alias === b2Alias;
    }

    return isSame;
  }

  /**
   * Compare two view-model contexts for equality.
   */
  public static contextEqual(c1: BrowserObject[], c2: BrowserObject[]): boolean {
    if (!c1 || c1.length === 0) {
      return !c2 || c2.length === 0;
    } else if (!c2 || c2.length === 0) {
      return false;
    } else if (c1.length !== c2.length) {
      return false;
    }

    if (c1.find((v, idx) => !PropertySnapInViewModel.browserObjectEqual(v, c2[idx]))) {
      return false; // found a non-matching object!
    }
    return true; // consider equal
  }

  /**
   * Constructor.
   */
  constructor(
    private readonly sniId: string,
    propertyService: PropertyServiceBase,
    settingsService: SettingsServiceBase,
    valueSubscriptionService: ValueSubscription2ServiceBase,
    readCommandService: ReadCommandServiceBase,
    execCommandService: ExecuteCommandServiceBase,
    cmdSubscriptionService: CommandSubscriptionServiceBase,
    cnsHelperService: CnsHelperService,
    validationDialogService: ValidationDialogService,
    private readonly traceService: TraceService) {

    if (isNullOrUndefined(sniId) || sniId.length === 0) {
      throw new Error('sniId cannot be undefined or empty');
    }
    this.propertyListChangedInd = new Subject<void>();
    this.loadingInd = new Subject<boolean>();

    // Hold all services in a separate object that can be injected in all subordinate VMs
    this.svc = {
      propertyService,
      settingsService,
      valueSubscriptionService,
      readCommandService,
      cmdSubscriptionService,
      execCommandService,
      cnsHelperService,
      validationDialogService
    };

    this.clear();

    // Subscribe for changes to the property-list filter setting
    this.propFilterChangedInd = new Subject<void>();
    this.propFilterChangedInd
      .pipe(
        debounceTime(50))
      .subscribe(
        () => {
          this.updateHiddenByUserState();
        });

    // Subscribe for changes to CNS name format selection
    if (cnsHelperService) {
      this.cnsHelperServiceSubscription = cnsHelperService.activeCnsLabel.subscribe(label => {
        this.cnsSetting = label;
        this.updateContextDescription();
      });
    }
  }

  /**
   * Called by client to dispose of internal resources before view-model is destructed.
   */
  public dispose(): void {
    if (this.isDisposed) {
      return; // already disposed
    }

    this.deactivate();

    // Unregister with value subscription service
    if (this.valueSubscriptionReg) {
      this.svc.valueSubscriptionService.disposeClient(this.valueSubscriptionReg);
      this.valueSubscriptionReg = undefined;
    }

    // Unregister with cmd subscription service
    if (this.cmdSubscriptionReg) {
      this.svc.cmdSubscriptionService.disposeClient(this.cmdSubscriptionReg);
      this.cmdSubscriptionReg = undefined;
    }

    // Unsubscribe CnsHelperService
    if (this.cnsHelperServiceSubscription) {
      this.cnsHelperServiceSubscription.unsubscribe();
      this.cnsHelperServiceSubscription = undefined;
    }

    this.isDisposed = true;

    if (this.traceService) {
      this.traceService.info(this.trmod, 'Disposed view-model: sniId=%s', this.sniId);
    }
  }

  /**
   * This method can be called by the view-model client to disable any resourses the
   * view-model is managing that can be regained through a call to activate.
   *
   * The main scenario is the snap-in view ngComponent is destroyed (taken out of view) and
   * later re-created and initialized.  During the time it is 'away' the subscriptions it has
   * open should be closed.  They will be re-opened on a call to 'activate' when the snap-in
   * re-registers for the view-model instance.
   */
  public deactivate(): void {
    if (this.traceService) {
      this.traceService.info(this.trmod, 'Deactivate view-model: sniId=%s', this.sniId);
    }
    this.unsubscribePropertyValuesAndCommands();
  }

  /**
   * Called to re-obtain any resources that were let go in the deactivate call.
   */
  public activate(): void {
    if (this.traceService) {
      this.traceService.info(this.trmod, 'Activate view-model: sniId=%s', this.sniId);
    }

    // Read the operation-disply mode button state from user-settings at the server if not yet initialized
    if (this.showExt === undefined) {
      this.showExt = false;
      this.svc.settingsService.getSettings(this.userSettingOperationDisplayMode)
        .subscribe(
          val => {
            this.showExt = WsiTranslator.decodeBoolean(val);
          });
    }

    // Register view-model with value subscription service if not already
    if (!this.valueSubscriptionReg) {
      this.valueSubscriptionReg = this.svc.valueSubscriptionService.registerClient(this.sniId);
    }

    // Register view-model with cmd subscription service if not already
    if (!this.cmdSubscriptionReg) {
      this.cmdSubscriptionReg = this.svc.cmdSubscriptionService.registerClient(this.sniId);
    }

    // For single-object selection context
    this.subscribePropertyValues();
    this.subscribeCommands();

    // For multi-object selection context
    // NOTE: Need to identify all aggregate properties that were "active" on previous
    //  instance of SNI component.
    if (this.contextDetailProp) {
      this.contextDetailProp.activate();
    }
  }

  /**
   * Set the locale to be used for the formatting of numeric and date-time values.
   */
  public setLocale(locale: string): void {
    this.locale = locale;
    this.traceService.info(this.trmod, 'View-model locale set: %s', this.locale);
  }

  public findProperty(id: string): PropertyViewModel {
    const pList: PropertyViewModel[] = this.showExt ? this.propertyListExt : this.propertyListStd;
    if (pList && id) {
      return pList.find(p => p.propertyDef.propertyId === id);
    }
    return undefined;
  }

  /**
   * Set the current context to detail the specified property.
   *
   * This has the effect, in a multi-object context, to set the `detailProperty`
   * reference to the aggregate-property instance matching the provided property id.
   * This will initiate calls to the server to read detailed information for all object-property
   * instances associated with the aggregate.
   *
   * Passing an undefined property id will clear the detail context.
   *
   * This is applicable in the case of a multi-object selection context.  It has no effect in a
   * single-object context.
   */
  public setContextDetail(propertyId: string): boolean {
    if (this.contextState === ContextState.SingleObject) {
      return false; // not applicable to single-object selection context
    }

    // Check for request to clear detail property context
    if (!propertyId) {
      if (this.contextDetailProp) {
        this.contextDetailProp.deactivate();
        this.contextDetailProp.objectFilter = undefined;
      }
      this.contextDetailProp = undefined;
      this.propertyListChangedInd.next();
      return true;
    }

    // Find the aggregate-property with matching pid in the currently displayed property list
    const pList: PropertyViewModel[] = this.showPropertyListExt ? this.propertyListExt : this.propertyListStd;
    const instList: AggregatePropertyInstance[] = pList
      .filter(pvm => pvm.instance.isAggregate)
      .map(pvm => pvm.instance as AggregatePropertyInstance) || [];
    const detailProp: AggregatePropertyInstance = instList.find(inst => inst.def.propertyId === propertyId);
    if (!detailProp) {
      this.traceService.error(this.trmod, 'Request to set context detail failed.  Property id not found: %s', propertyId);
      return false; // not found!
    }

    // Set new detail property
    if (this.contextDetailProp && this.contextDetailProp !== detailProp) {
      this.contextDetailProp.deactivate();
      this.contextDetailProp.objectFilter = undefined;
    }
    this.contextDetailProp = detailProp;
    this.contextDetailProp.activate();
    this.propertyListChangedInd.next();
    return true;
  }

  /**
   * Set the datapoint context used to read all property and command information.
   *
   * Setting the context will clear all current property information and initiate a
   * call to the server to read properties for the new context.
   */
  public setContext(context: BrowserObject[]): void {
    this.checkDisposed();

    // Trace message
    if (this.traceService.isInfoEnabled(this.trmod)) {
      if (!context || context.length === 0) {
        this.traceService.info(this.trmod, 'Set snapin context: undefined or empty context provided; clear context.');
      } else {
        this.traceService.info(this.trmod, 'Set snapin context: %s',
          context.length === 1 ? context[0].ObjectId : context.length + ' objects');
      }
    }

    // Remove potentially undefined items from the provided object array
    let c: BrowserObject[] = context || [];
    c = c.filter(i => !isNullOrUndefined(i));

    // Remove any duplicates from the provided object array
    c = c.filter((bo, index, self) =>
      index === self.findIndex(t => (
        t.Designation === bo.Designation &&
        t.ObjectId === bo.ObjectId
      ))
    );

    // If provided context is same as current, leave state as is
    if (PropertySnapInViewModel.contextEqual(c, this.context)) {
      this.traceService.info(this.trmod, 'Provided context is equal to current context.  No update performed.');
      return; // no-op
    }

    // Update context
    this.setContextInternal(c);
  }

  /**
   * Set new context.
   */
  private setContextInternal(c: BrowserObject[]): void {
    this.clear();
    this.context = c;
    this.contextId++;
    this.contextChange.next();

    this.updateContextDescription();

    // Set context state
    this.contextStateInternal = ContextState.Empty;
    if (this.context && this.context.length === 1) {
      // Single object selected
      this.contextStateInternal = ContextState.SingleObject;
      this.readProperties(this.contextId);
    } else if (this.context && this.context.length > 1) {
      // Multiple objects selected
      // Must all be from same system
      if (this.context.every(bo => bo.SystemId === this.context[0].SystemId)) {

        // Evaluate consistency of object-model and function assignments
        if (this.context.every(bo => bo.Attributes !== undefined)) {
          // a little dirty to unsubscribe on loading, but it is the best option without major refactoring
          this.svc.propertyService.isBulkable(this.context).pipe(takeUntil(this.contextChange)).subscribe(bulkable => {
            const fn: string = this.context[0].Attributes.FunctionName;
            if (!bulkable) {
              this.contextStateInternal = ContextState.MultiObjectDifferent; // not all same OM
            } else if (this.context.some(bo => bo.Attributes.FunctionName !== fn)) {
              this.contextStateInternal = ContextState.MultiObjectSameOM; // same OM; not all same Func
            } else {
              this.contextStateInternal = ContextState.MultiObjectSame; // same OM; same Func
            }
            this.readProperties(this.contextId);
          });
        } else {
          // Not all objects in selection have provided OM and Function assignment information
          // Treat as different since we cannot determine otherwise.
          this.traceService.warn(this.trmod, 'Multiple object selection missing OM and Function info; treating objects as different types');
          this.contextStateInternal = ContextState.MultiObjectDifferent;
          this.readProperties(this.contextId);
        }
      } else {
        this.traceService.info(this.trmod, 'Multiple object selection spans multiple systems; treating objects as different types');
        this.contextStateInternal = ContextState.MultiObjectDifferent;
        this.readProperties(this.contextId);
      }
    }

    this.traceService.info(this.trmod, 'Context updated: cid=%s, state=%s', this.contextId, this.contextState);
  }

  /**
   * Update the context description fields.
   * This is called when the context changes and also when the CNS name format selection changes.
   */
  private updateContextDescription(): void {
    this.contextDesc = undefined;
    if (this.context && this.context.length > 0) {
      // NOTE: Set the `contextDesc` to the first element in the context EVEN IN THE CASE OF MULTI-SELECT.
      //  In this case, the labels will not be used, but the display characteristics of 1 vs. 2 labels will be!
      this.contextDesc = this.svc.cnsHelperService.getCnsLabelsOrdered(this.context[0]);
    }

    // Re-label all object-property instance VMs in existence
    if (this.propertyListExt) {
      this.propertyListExt.forEach(pvm => pvm.instance.updateObjectLabels());
    }
    if (this.propertyListStd) {
      this.propertyListStd.forEach(pvm => pvm.instance.updateObjectLabels());
    }

    this.traceService.debug(this.trmod, 'Context description set: title=%s, subTitleEnabled=%s, subTitle=%s',
      this.title, this.subTitleEnabled, this.subTitle);
  }

  /**
   * Set the hidden-by-filter flag on all properties in both std and ext property lists.
   */
  private updateHiddenByUserState(): void {
    const filter: string = this.propertyFilter ? this.propertyFilter.toLocaleLowerCase() : undefined;
    this.propertyListStd.forEach(p => {
      let hidden = false;
      if (filter) {
        hidden = !p.def.description.toLocaleLowerCase().includes(filter);
      }
      p.setHiddenByFilter(hidden);
    });
    this.propertyListExt.forEach(p => {
      let hidden = false;
      if (filter) {
        hidden = !p.def.description.toLocaleLowerCase().includes(filter);
      }
      p.setHiddenByFilter(hidden);
    });
    this.updateSiPropertyLists();
  }

  private updateSiPropertyLists(): void {
    const saveListStd: AnyCommandingProperty[] = (this.siPropertyListStd || []).slice(0);
    const saveListExt: AnyCommandingProperty[] = (this.siPropertyListExt || []).slice(0);
    const saveBulkListStd: BulkProperty[] = (this.siBulkPropertyListStd || []).slice(0);
    const saveBulkListExt: BulkProperty[] = (this.siBulkPropertyListExt || []).slice(0);

    this.siPropertyListStd = [];
    this.siPropertyListExt = [];
    this.siBulkPropertyListStd = [];
    this.siBulkPropertyListExt = [];

    // Rebuild si-property-viewer bound lists based on snap-in context, user filter, and
    // datapoint state information
    // NOTE: user filter string is now passed in a binding to the si-property-viewer where
    //  the provided si-property list will be locally filtered based on this criteria.
    if (this.contextState === ContextState.SingleObject) {
      this.propertyListStd
        .filter(pvm => !pvm.instance.isAggregate) // just to be sure about the casting!
        .forEach(pvm => {
          const pInst: ObjectPropertyInstance = pvm.instance as ObjectPropertyInstance;
          if (!(pInst.isAbsent || pInst.isHiddenByState)) {
            this.siPropertyListStd.push(pInst.siProperty);
          }
        });
      this.propertyListExt
        .filter(pvm => !pvm.instance.isAggregate)
        .forEach(pvm => {
          const pInst: ObjectPropertyInstance = pvm.instance as ObjectPropertyInstance;
          if (!pInst.isAbsent) {
            this.siPropertyListExt.push(pInst.siProperty);
          }
        });
    } else {
      this.propertyListStd
        .filter(pvm => pvm.instance.isAggregate) // just to be sure about the casting!
        .forEach(pvm => {
          const agInst: AggregatePropertyInstance = pvm.instance as AggregatePropertyInstance;
          if (!agInst.isAbsent) {
            this.siBulkPropertyListStd.push(agInst.siBulkPropertyDesc);
          }
        });
      this.propertyListExt
        .filter(pvm => pvm.instance.isAggregate)
        .forEach(pvm => {
          const agInst: AggregatePropertyInstance = pvm.instance as AggregatePropertyInstance;
          if (!agInst.isAbsent) {
            this.siBulkPropertyListExt.push(agInst.siBulkPropertyDesc);
          }
        });
    }

    // Check for changes in original lists and notify UI control if detected
    if (!Common.isEqualArray(saveListStd, this.siPropertyListStd) ||
      !Common.isEqualArray(saveListExt, this.siPropertyListExt) ||
      !Common.isEqualArray(saveBulkListStd, this.siBulkPropertyListStd) ||
      !Common.isEqualArray(saveBulkListExt, this.siBulkPropertyListExt)) {

      this.propertyListChangedInd.next();
    }
  }

  /**
   * Read all properties for current context.
   */
  private readProperties(contextId: number): void {
    if (this.contextState === ContextState.Empty || this.contextState === ContextState.MultiObjectDifferent) {
      return; // no context or unsupported context!
    }

    // Even though all objects share the same OM, property lists for each may vary based on user's scope.
    // For this reason, we must read ALL.
    const oidList: string[] = this.context.map(bo => bo.ObjectId);

    this.loadingInd.next(true);

    // Read property information
    this.traceService.info(this.trmod, 'Read properties: cid=%s, objectIdCount=%s', contextId, oidList.length);
    this.svc.propertyService.readPropertiesMulti(oidList, 3, true).subscribe(
      resp => {
        this.readPropertiesCallback(contextId, resp);
        this.loadingInd.next(false);
      },
      err => {
        this.traceService.error(this.trmod, 'Read properties error: cid=%s, %s', contextId, err);
        this.loadingInd.next(false);
      });
  }

  /**
   * Process read property response.
   */
  private readPropertiesCallback(contextId: number, resp: PropertyInfo<PropertyDetails>[]): void {
    this.traceService.info(this.trmod, 'Read properties response: cid=%s, propertySets(#dps)=%s',
      contextId,
      resp ? resp.length : 0);

    if (!resp || resp.length === 0) {
      return;
    }

    if (contextId !== this.contextId) {
      this.traceService.info(this.trmod, 'Ignoring read properties response for old context: cidResp=%s, cidCurrent=%s',
        contextId, this.contextId);
      return; // async response received for now out-of-date context
    }

    // Set the view-model context before creating new view-model
    // let d: Designation;
    // if (this.contextState === ContextState.SingleObject && this.context[0].Designation) {
    //   d = new Designation(this.context[0].Designation);
    // }
    const vmContext: ViewModelContext = {
      locale: this.locale,
      state: this.contextState,
      browserObjList: this.context,
      valueSubscriptionReg: this.valueSubscriptionReg,
      cmdSubscriptionReg: this.cmdSubscriptionReg
    };

    // Determine default property id
    let defaultPropOm: string;
    let defaultPropFunc: string;
    if (this.context[0].Attributes) {
      defaultPropOm = this.context[0].Attributes.DefaultProperty;
      defaultPropFunc = this.context[0].Attributes.FunctionDefaultProperty;
    }

    // Merge property lists for each DP into a single list of ext-operation and single list of std-operation properties
    const pdExt: PropertyDetails[] = this.mergeProperties(resp, 'Properties') || [];
    let pdStd: PropertyDetails[];
    this.hasFunction = undefined;
    if (this.contextState !== ContextState.MultiObjectSameOM &&
      resp[0].FunctionProperties &&
      resp[0].FunctionProperties.length) {

      this.hasFunction = true;
      pdStd = this.mergeProperties(resp, 'FunctionProperties') || [];
    } else {
      // No function properties present OR multiple DPs have been selected that do not all share the same function assignment;
      // Use OM properties for the standard list
      this.hasFunction = false;
      pdStd = pdExt.slice(0);
    }

    // Create view-model objects for extended property list
    pdExt.forEach(pd => {
      // eslint-disable-next-line no-bitwise
      if (pd && (pd.Usage & this.usageMaskExtOp)) { // extended-display properties only!
        let pvm: PropertyViewModel;
        if (this.contextState === ContextState.SingleObject) {
          pvm = PropertyViewModel.createSingleObjectPropertyVM(
            this.traceService,
            this.svc,
            vmContext,
            this.context[0],
            pd,
            false,
            defaultPropOm);
          (pvm.propertyInstance as ObjectPropertyInstance).objectPropertyEvent.subscribe(() => this.updateSiPropertyLists());
        } else {
          pvm = PropertyViewModel.createMultipleObjectPropertyVM(
            this.traceService,
            this.svc,
            vmContext,
            pd,
            false,
            defaultPropOm);
        }
        this.propertyListExt.push(pvm);
      }
    });
    this.propertyListExt.sort(PropertyViewModel.displayOrderCompare);

    // Debug trace extended SiMPL property list
    if (this.traceService.isDebugEnabled(this.trmod)) {
      this.traceService.debug(this.trmod, 'Extended property list for OM=%s (%s of %s props returned): %s',
        this.context[0].Attributes.ObjectModelName,
        this.propertyListExt.length,
        pdExt.length,
        this.propertyListExt.map(p => p.def.description).join(', '));
    }

    // Create view-model objects for Function properties
    if (pdStd) { // if different functions are assigned this is undefined
      pdStd.forEach(pd => {
        // eslint-disable-next-line no-bitwise
        if (pd && (pd.Usage & this.usageMaskStdOp)) { // standard-display properties only!
          let pvm: PropertyViewModel;
          if (this.contextState === ContextState.SingleObject) {
            pvm = PropertyViewModel.createSingleObjectPropertyVM(
              this.traceService,
              this.svc,
              vmContext,
              this.context[0],
              pd,
              this.hasFunction,
              this.hasFunction ? defaultPropFunc : defaultPropOm);
            (pvm.propertyInstance as ObjectPropertyInstance).objectPropertyEvent.subscribe(() => this.updateSiPropertyLists());
          } else {
            pvm = PropertyViewModel.createMultipleObjectPropertyVM(
              this.traceService,
              this.svc,
              vmContext,
              pd,
              this.hasFunction,
              this.hasFunction ? defaultPropFunc : defaultPropOm);
          }
          this.propertyListStd.push(pvm);
        }
      });
    }
    this.propertyListStd.sort(PropertyViewModel.displayOrderCompare);

    // Update UI component selection context
    if (this.contextState === ContextState.SingleObject) {
      this.siSelectionContext = this.context[0].ObjectId;
    } else {
      this.siSelectionContext = this.context.map(bo => bo.ObjectId);
    }

    // Re-apply current property filter to newly created property lists
    // This call also re-builds si-property lists bound to UI component and notifies of the change
    this.updateHiddenByUserState();

    // Debug trace standard property list
    if (this.traceService.isDebugEnabled(this.trmod)) {
      this.traceService.debug(this.trmod, 'Standard property list for %s=%s (%s of %s props returned): %s',
        this.hasFunction ? 'Func' : 'OM',
        this.hasFunction ? this.context[0].Attributes.FunctionName : this.context[0].Attributes.ObjectModelName,
        this.propertyListStd.length,
        pdStd.length,
        this.propertyListStd.map(p => p.def.description).join(', '));
    }

    // For single-object selection, subscribe for property values and read commands.
    // For multi-object selection, this step is deferred until aggregate properties are activated.
    if (this.contextState === ContextState.SingleObject) {
      this.subscribePropertyValues();
      this.readCommands(contextId);
    }
  }

  private mergeProperties(resp: PropertyInfo < PropertyDetails > [], listPropName: string): PropertyDetails[] {
    const pdListMerged: PropertyDetails[] = [];
    if (resp && resp.length > 0) {
      resp.forEach(info => {
        const pdList: PropertyDetails[] = info[listPropName];
        if (pdList) {
          pdList.forEach(pd => {
            if (!pdListMerged.some(i => i.PropertyName === pd.PropertyName)) {
              pdListMerged.push(pd);
            }
          });
        }
      });
    }
    return pdListMerged;
  }

  /**
   * Subscribe for value changes for all properties in both property lists.
   */
  private subscribePropertyValues(): void {
    const propList: ObjectPropertyInstance[] = [];

    // Collect all non-aggregate properties from both lists
    this.propertyListExt
      .filter(p => !p.instance.isAggregate)
      .forEach(p => propList.push(p.instance as ObjectPropertyInstance));
    this.propertyListStd
      .filter(p => !p.instance.isAggregate)
      .forEach(p => propList.push(p.instance as ObjectPropertyInstance));

    if (propList.length > 0) {
      this.traceService.info(this.trmod, 'Subscribe values: property count=%s', propList.length);

      // Subscribe for property value changes
      const valueSubscriptionArr: GmsSubscription<ValueDetails>[] =
        this.svc.valueSubscriptionService.subscribeValues(propList.map(p => p.objectAndPropertyId), this.valueSubscriptionReg);

      // Update each object-property-instance VM with its associated value subscription
      if (valueSubscriptionArr && valueSubscriptionArr.length === propList.length) {
        propList.forEach((p, idx) => p.updateValueSubscription(valueSubscriptionArr[idx]));
      } else {
        this.traceService.error(this.trmod, 'Subscribe values: invalid response');
      }
    }
  }

  /**
   * Read all commands for all properties of current context.
   */
  private readCommands(contextId: number): void {
    if (this.contextState === ContextState.MultiObjectDifferent) {
      return;
    }

    // Ok to use first object even for multi-object context for reading property command lists since
    // these should be consistent across all objects (otherwise we wouldn't have gotten this far).
    const oid: string = this.context[0].ObjectId;

    // Collect all properties from both lists
    // NOTE: the order in which properties are inserted into the request list is
    //  depended upon by the read-commands callback method!
    const propIdList: string[] = [];
    this.propertyListExt.forEach(p => propIdList.push(oid + '.' + p.def.propertyId));
    this.propertyListStd.forEach(p => {
      const pid: string = oid + (p.def.isFunctionProperty ? '' : '.') + p.def.propertyId;
      propIdList.push(pid);
    });

    // Read command information
    if (propIdList.length > 0) {
      this.traceService.info(this.trmod, 'Read commands: cid=%s, property count=%s', contextId, propIdList.length);
      this.svc.readCommandService.readPropertyCommands(propIdList, undefined, false, undefined).subscribe(
        resp => this.readCommandsCallback(contextId, resp),
        err => this.traceService.error(this.trmod, 'Read commands error: cid=%s, %s', contextId, err)
      );
    }
  }

  /**
   * Process read commands response.
   */
  private readCommandsCallback(contextId: number, resp: PropertyCommand[]): void {
    this.traceService.info(this.trmod, 'Read commands response: cid=%s, property count=%s',
      contextId,
      resp ? resp.length : 0);

    if (!resp || resp.length === 0) {
      return;
    }

    if (contextId !== this.contextId) {
      this.traceService.info(this.trmod, 'Ignoring read commands response for old context: cidResp=%s, cidCurrent=%s',
        contextId, this.contextId);
      return; // async response received for now out-of-date context
    }

    // Inject command sets into each property.

    // The order of property command sets is equivalent to the order of property ids
    // in read-command request (extend properties first, standard properties second).
    if (resp.length === this.propertyListExt.length + this.propertyListStd.length) {
      this.propertyListExt.forEach((p, idx) => p.setBaseCommands(resp[idx]));
      const offset: number = this.propertyListExt.length;
      this.propertyListStd.forEach((p, idx) => p.setBaseCommands(resp[idx + offset]));
    } else {
      this.traceService.error(this.trmod, 'Read commands: invalid response');
    }

    // For single-object selection, subscribe for command changes.  For multi-object selection,
    // this step is deferred until aggregate properties are activated.
    if (this.contextState === ContextState.SingleObject) {
      this.subscribeCommands();
    }
  }

  /**
   * Subscribe for command changes for all properties in both property lists.
   */
  private subscribeCommands(): void {
    const propList: ObjectPropertyInstance[] = [];

    // Collect properties from both lists that have one or more commands.
    // We put references to these properties in a single, separate array used to request
    // command subscriptions and attached resulting command subscription objects.
    this.propertyListExt
      .filter(p => !p.instance.isAggregate)
      .forEach(p => propList.push(p.instance as ObjectPropertyInstance));
    this.propertyListStd
      .filter(p => !p.instance.isAggregate)
      .forEach(p => propList.push(p.instance as ObjectPropertyInstance));

    if (propList.length > 0) {
      this.traceService.info(this.trmod, 'Subscribe commands (only properties with non-zero cmd list): property count=%s', propList.length);

      // Subscribe for property command changes
      const cmdSubscriptionArr: GmsSubscription<PropertyCommand>[] =
        this.svc.cmdSubscriptionService.subscribeCommands(propList.map(pVm => pVm.objectAndPropertyId), this.cmdSubscriptionReg);

      // Update each property VM with its associated value subscription
      if (cmdSubscriptionArr && cmdSubscriptionArr.length === propList.length) {
        propList.forEach((p, idx) => p.updateCommandSubscription(cmdSubscriptionArr[idx]));
      } else {
        this.traceService.error(this.trmod, 'Subscribe commands: invalid response');
      }
    }
  }

  /**
   * Unsubscribe all open value and command subscription.
   * Used on context update and shutdown.
   */
  private unsubscribePropertyValuesAndCommands(): void {
    // Close all subscriptions held indirectly through aggregate property instances
    this.deactivateAggregateProperties();

    // Close all subscriptions held directly in single object property instances
    this.unsubscribeObjectPropertyValues();
    this.unsubscribeObjectPropertyCommands();
  }

  /**
   * Deactivate all aggregate properties in both property lists.
   * The has the effect of unsubscribing from all open value and command subscriptions held by
   * each aggregate instance.
   */
  private deactivateAggregateProperties(): void {
    if (this.propertyListExt) {
      this.propertyListExt
        .filter(p => p.instance.isAggregate)
        .forEach(p => (p.instance as AggregatePropertyInstance).deactivate());
    }
    if (this.propertyListStd) {
      this.propertyListStd
        .filter(p => p.instance.isAggregate)
        .forEach(p => (p.instance as AggregatePropertyInstance).deactivate());
    }
  }

  /**
   * Unsubscribe value changes for all non-aggregate properties in both property lists.
   */
  private unsubscribeObjectPropertyValues(): void {
    if (!this.valueSubscriptionReg) {
      return;
    }
    // Collect all open property subscriptions (single object)
    const valueSubscriptionList: GmsSubscription<ValueDetails>[] = [];
    if (this.propertyListExt) {
      this.propertyListExt
        .filter(p => !p.instance.isAggregate)
        .forEach(p => {
          const vs: GmsSubscription<ValueDetails> = (p.instance as ObjectPropertyInstance).updateValueSubscription(undefined);
          if (vs) {
            valueSubscriptionList.push(vs);
          }
        });
    }
    if (this.propertyListStd) {
      this.propertyListStd
        .filter(p => !p.instance.isAggregate)
        .forEach(p => {
          const vs: GmsSubscription<ValueDetails> = (p.instance as ObjectPropertyInstance).updateValueSubscription(undefined);
          if (vs) {
            valueSubscriptionList.push(vs);
          }
        });
    }

    // Unsubscribe all properties
    if (valueSubscriptionList.length > 0) {
      this.traceService.info(this.trmod, 'Unsubscribe values: property count=%s', valueSubscriptionList.length);
      this.svc.valueSubscriptionService.unsubscribeValues(valueSubscriptionList, this.valueSubscriptionReg);
    }
  }

  /**
   * Unsubscribe command changes for all non-aggregate properties in both property lists.
   */
  private unsubscribeObjectPropertyCommands(): void {
    if (!this.valueSubscriptionReg) {
      return;
    }

    // Collect all open property command subscriptions
    const cmdSubscriptionArr: GmsSubscription<PropertyCommand>[] = [];
    this.propertyListExt
      .filter(p => !p.instance.isAggregate)
      .forEach(p => {
        const cs: GmsSubscription<PropertyCommand> = (p.instance as ObjectPropertyInstance).updateCommandSubscription(undefined);
        if (cs) {
          cmdSubscriptionArr.push(cs);
        }
      });
    this.propertyListStd
      .filter(p => !p.instance.isAggregate)
      .forEach(p => {
        const cs: GmsSubscription<PropertyCommand> = (p.instance as ObjectPropertyInstance).updateCommandSubscription(undefined);
        if (cs) {
          cmdSubscriptionArr.push(cs);
        }
      });

    // Unsubscribe all property commands
    if (cmdSubscriptionArr.length > 0) {
      this.traceService.info(this.trmod, 'Unsubscribe commands: property count=%s', cmdSubscriptionArr.length);
      this.svc.cmdSubscriptionService.unsubscribeCommands(cmdSubscriptionArr, this.cmdSubscriptionReg);
    }
  }

  /**
   * Clear view-model context.
   */
  private clear(): void {
    this.unsubscribePropertyValuesAndCommands();

    this.siSelectionContext = undefined;
    this.context = [];
    this.contextDetailProp = undefined;
    this.contextStateInternal = ContextState.Empty;
    this.contextDesc = undefined;
    this.hasFunction = false;
    this.propertyListStd = [];
    this.propertyListExt = [];
    this.siPropertyListStd = [];
    this.siPropertyListExt = [];
    this.siBulkPropertyListStd = [];
    this.siBulkPropertyListExt = [];

    this.propertyListChangedInd.next();
  }

  /**
   * Verify view-model has not been disposed before use.
   */
  private checkDisposed(): void {
    if (this.isDisposed) {
      throw new Error(`View model has been disposed: sniId=${this.sniId}`);
    }
  }
}
