import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  OnInit
} from '@angular/core';

import { SiDropdownDirective } from './si-dropdown.directive';

/**
 * @deprecated The {@link SiDropdownToggleDirective} and its related directives should no longer be used.
 * - For creating menus, use {@link SiMenuModule} instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating plain overlays, use the {@link https://material.angular.io/cdk/overlay/overview CDK Overlay}: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/dropdowns/
 */
@Directive({
  selector: '[siDropdownToggle]',
  exportAs: 'si-dropdown-toggle',
  host: { class: 'dropdown-toggle' },
  standalone: true
})
export class SiDropdownToggleDirective implements OnDestroy, OnInit, AfterViewInit {
  content = inject(ElementRef).nativeElement as HTMLElement;

  private siDropdownDirective = inject(SiDropdownDirective);
  private toggleExecutedInTick = false;

  ngAfterViewInit(): void {
    this.setA11y(false);
  }

  @HostBinding('class.show') dropdownOpen = false;

  ngOnDestroy(): void {
    this.siDropdownDirective.removeToggle(this);
  }

  ngOnInit(): void {
    this.siDropdownDirective.addToggle(this);
  }

  updateState(state: boolean): void {
    this.dropdownOpen = state;
    this.setA11y(state);
  }

  private setA11y(state: boolean): void {
    const menuItem = this.content.querySelector('[role=menuitem]');
    if (menuItem) {
      menuItem.setAttribute('aria-expanded', `${state}`);
      menuItem.setAttribute('aria-haspopup', 'true');
    } else {
      this.content.setAttribute('aria-expanded', `${state}`);
      this.content.setAttribute('aria-haspopup', 'true');
    }
  }

  open(): void {
    this.siDropdownDirective.open();
  }

  close(): void {
    this.siDropdownDirective.close();
  }

  @HostListener('click')
  @HostListener('keydown.enter')
  onClick(): void {
    // when toggle is used on a button, enter also fires a click event --> suppressing it.
    if (!this.toggleExecutedInTick) {
      this.siDropdownDirective.toggle();
      this.toggleExecutedInTick = true;
      setTimeout(() => (this.toggleExecutedInTick = false));
    }
  }
}
