import { Tables, TextEntry } from "@gms-flex/services/wsi-proxy-api/tables/data.model";
import { HeaderData } from "../event-data.model";
import { Observable } from "rxjs";
import { HfwFilterPillData } from "@gms-flex/controls";
import { EventFilter } from "@gms-flex/services";

export abstract class EventListSettingsServiceBase {
  public abstract getStateFilter(): any;
  public abstract getSourceFilter(): any;
  public abstract getHiddenFilter(): any;
  public abstract getInvestigativeSettings(): any;
  public abstract getAvailableColumns(displayParams: any): HeaderData[];
  public abstract getDisciplines(): Observable<TextEntry[]>;
  public abstract getFilterCriteria(eventFilter: EventFilter, pillDataArr: HfwFilterPillData[]): HfwFilterPillData[];
  public abstract getAllowedFilters(): any;
}
