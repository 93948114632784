export enum DatapointStatus {
  // eslint-disable-next-line id-blacklist
  Undefined = 0, // #COM (in the beginning)
  Pending = 1, // #COM (subscribed but IndValue was not yet called)
  Valid = 2, // show value (the value is set and everything is OK)
  Invalid = 3, // #COM (the datapoint doesn't have a valid value)
  DoesNotExist = 4, // #ENG (the datapoint doesn't exist)
  FormatError = 5, // #FORMAT
  Hide = 6 // hide the element when at least one datapoint in an expression "xxx?" doesn't exist
}
