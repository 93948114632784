import { NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MemoViewModelIfc } from '../view-model/memo-vm.base';

export abstract class MemoPopoverServiceBase {

  public readonly commonTranslateService: TranslateService;

  public abstract registerViewModel(id: string, ngZone: NgZone): MemoViewModelIfc;
  public abstract unregisterViewModel(id: string): void;

}
