import { Injectable } from "@angular/core";
import { EventInfoSettingsServiceBase } from "./event-info-settings.service.base";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { CnsLabel, CnsLabelEn, Event } from "@gms-flex/services";

@Injectable({
  providedIn: "root"
})

export class EventInfoSettingsService implements EventInfoSettingsServiceBase {

  private readonly showNotes: boolean = true; // default visibility

  public getEventInfoSettings(): any {
    return {
      showInProcessBy: true,
      showDesignation: true,
      showEventId: true,
      showDiscipline: true,
      showSystemId: true,
      showBuildingName: false,
      showPartitionName: false,
      showMsgText: true,
      showCategory: true,
      showSystemName: true
    }
  }

  public isNotesVisible(): boolean {
    return this.showNotes;
  }

  public sourceNameDescription(event: Event, currCnsLabel: CnsLabel): string[] {
    let srcNameDescription: string[] = [];

    if (currCnsLabel != null) {
      switch (currCnsLabel.cnsLabel) {
        case CnsLabelEn.Description:
          srcNameDescription = [event.srcDescriptor];
          break;
        case CnsLabelEn.DescriptionAndAlias:
          srcNameDescription = [event.srcDescriptor, event.srcAlias];
          break;
        case CnsLabelEn.DescriptionAndName:
          srcNameDescription = [event.srcDescriptor, event.srcName];
          break;
        case CnsLabelEn.Name:
          srcNameDescription = [event.srcName];
          break;
        case CnsLabelEn.NameAndAlias:
          srcNameDescription = [event.srcName, event.srcAlias];
          break;
        case CnsLabelEn.NameAndDescription:
          srcNameDescription = [event.srcName, event.srcDescriptor];
          break;
        default:
          break;
      }
    }
    return srcNameDescription;
  }

  public getSourceProperty(srcNameDescription: string[]): string {
    if (srcNameDescription !== undefined) {
      return srcNameDescription[0]?.includes('.') ? srcNameDescription[0].split('.', 2)[1] : '';
    }
  }
}