<div class="mb-6 row">
  @if (props.label) {
    <label class="col-sm-2 col-form-label" [attr.for]="id">
      {{ props.label }}
      @if (props.required && props.hideRequiredMarker !== true) {
        *
      }
    </label>
  }
  <div class="col-sm-10">
    <ng-template #fieldComponent />
  </div>

  @if (showError) {
    <div class="col-sm-3 invalid-feedback d-block">
      <formly-validation-message [field]="field" />
    </div>
  }
</div>
