import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng/modal';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { AlertDialogResult } from '../si-action-dialog.types';

@Component({
  selector: 'si-alert-dialog',
  templateUrl: './si-alert-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiAlertDialogComponent {
  @Input() titleId?: string;
  @Input() heading = '';
  @Input() message = '';
  @Input() confirmBtnName = $localize`:@@SI_ALERT_DIALOG.OK:OK`;
  @Input() translationParams: { [key: string]: any } = {};
  @Input() icon = '';

  protected modalRef = inject(ModalRef<SiAlertDialogComponent, AlertDialogResult>);

  protected onConfirm(): void {
    this.modalRef.hide(AlertDialogResult.Confirm);
  }
}
