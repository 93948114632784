import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { useCache } from '../shared/http-utility.service';
import { EquipmentTypeResponse } from './equipment-type-proxy.model';
import { EquipmentTypeProxyService } from './equipment-type-proxy.service';
import { EquipmentType } from './equipment-type.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentTypeService {
  private readonly equipmentTypesPerPartition: Map<string, EquipmentType[]> = new Map<string, EquipmentType[]>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly equipmentTypeProxy: EquipmentTypeProxyService) {

    this.traceService.info(TraceModules.bxServicesEquipmentTypes, 'EquipmentTypeService created.');
  }

  public getEquipmentTypes(partitionId: string): Observable<EquipmentType[]> {
    if ((useCache) && (this.equipmentTypesPerPartition.has(partitionId))) {
      return of(this.equipmentTypesPerPartition.get(partitionId));
    } else {
      return this.equipmentTypeProxy.getEquipmentTypes(partitionId).pipe(
        map(result => this.mapEquipmentType(result)),
        tap(result => this.equipmentTypesPerPartition.set(partitionId, result)),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesEquipmentTypes,
            `EquipmentTypeService: Equipment type not found for partitionId: ${partitionId}, error=${error}`);
          this.equipmentTypesPerPartition.set(partitionId, []);
          return of([]);
        })
      );
    }
  }

  private mapEquipmentType(equipmentTypeRes: EquipmentTypeResponse): EquipmentType[] {
    const devices: EquipmentType[] = [];
    equipmentTypeRes.data.forEach(item => {
      const dvc = new EquipmentType(item);
      devices.push(dvc);
    });
    return devices;
  }

}
