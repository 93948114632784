<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="!isValid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    <ng-container
      [ngTemplateOutlet]="
        property.defaultText || !modelValue.value
          ? defaultAltText
          : isBacnetDatepicker()
            ? displayVal
            : siDatepicker
      "
      input
    />

    <ng-template #defaultAltText>
      <div
        class="form-control d-flex align-items-center"
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        (keydown.enter)="popover.open(true)"
      >
        <span>
          {{ property.defaultText || property.value.altText }}
        </span>
      </div>
    </ng-template>

    <ng-template #displayVal>
      <div
        class="form-control d-flex align-items-center"
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        (keydown.enter)="popover.open(true)"
      >
        <span>
          {{ displayValue }}
        </span>
      </div>
    </ng-template>
    <ng-template #siDatepicker>
      <input
        #inputBox
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        [attr.aria-label]="property.name"
        (keydown.enter)="popover.open(true)"
        name="main"
        type="text"
        class="form-control"
        [(ngModel)]="selectedDate"
        siDateInput
        [siDatepickerConfig]="{
          showTime: true,
          showMinutes: true,
          showSeconds: true,
          mandatoryTime: true,
          dateTimeFormat: formatForPicker
        }"
      />
    </ng-template>

    @if (property.value.wildcardAllowed || property.value.specialAllowed) {
      <si-bacnet-datetime-picker
        [value]="modelValue"
        [timeFormat]="timeFormat!"
        [dateFormat]="dateFormat!"
        (submitEnter)="popover.submitEnter()"
        body
      />
    } @else {
      <div class="d-flex justify-content-center">
        <si-datepicker
          class="border-0"
          [(date)]="selectedDate"
          [config]="{
            showTime: true,
            mandatoryTime: true,
            showSeconds: true
          }"
          [time12h]="hasHours12()"
          body
        />
      </div>
    }
  </si-property-popover>
</form>
