import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, throwError as observableThrowError, zip as observableZip } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TraceModules } from '../shared/trace-modules';
import { TraceService } from '../trace/trace.service';

export const HFW_TRANSLATION_FILE_TOKEN: InjectionToken<string> = new InjectionToken<string>('hfwTranslationFilePath');

/**
 * Supports loading of all translation files which are provided with the token HFW_TRANSLATION_FILE_TOKEN
 * at application bootstrap time.
 * All files are merged together; thus be cautious for nameing clashes.
 *
 * @export
 * @class MultiTranslateHttpLoader
 * @implements {TranslateLoader}
 */
export class MultiTranslateHttpLoader implements TranslateLoader {
  private readonly suffix = '.json';

  /**
   * Creates an instance of MultiTranslateHttpLoader.
   * @param http
   * @param [prefixApp="/i18n/"] the relative path to the application translation folder.
   * @param [prefixOptionals] optional relative paths to translation folders
   * @memberof MultiTranslateHttpLoader
   */
  public constructor(private readonly http: HttpClient, private readonly trace: TraceService,
    public prefixApp: string = '/i18n/', private readonly prefixOptionals?: string[]) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<any> {
    const obs: Observable<any>[] = [];
    obs.push(this.http.get(`${this.prefixApp}${lang}${this.suffix}`));
    if ((this.prefixOptionals !== undefined) && (this.prefixOptionals.length > 0)) {
      this.prefixOptionals.forEach(pfx => {
        obs.push(this.http.get(`${pfx}${lang}${this.suffix}`));
      });
    }
    return observableZip(...obs, (...translations) => this.onGetTranslations(translations)).pipe(catchError(
      error => this.onError(error)
    ));
  }

  private onGetTranslations(translations: any[]): any {
    if (translations.length === 0) {
      return {};
    } else if (translations.length === 1) {
      return translations[0];
    } else {
      let target: any = translations[0];
      for (let idx = 1; idx < translations.length; idx++) {
        target = this.mergeDeep(target, translations[idx]);
      }
      return target;
    }
  }

  private onError(error: HttpErrorResponse): Observable<any> {
    this.trace.error(TraceModules.translation, error.message);
    return observableThrowError(error);
  }

  private isObject(item: any): boolean {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  private mergeDeep(target: any, source: any): any {
    const output: any = Object.assign({}, target);
    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach((key: any) => {
        if (this.isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = this.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  }
}
