<input
  type="number"
  class="focus-none"
  [attr.id]="inputId"
  [step]="step"
  [readonly]="readonly"
  [disabled]="disabled"
  [attr.aria-label]="ariaLabel"
  [attr.placeholder]="placeholder"
  [attr.min]="min"
  [attr.max]="max"
  [value]="internalValue"
  (blur)="onTouched()"
  (input)="input($event)"
/>
@if (unit) {
  <span class="unit ms-2" [class.text-secondary]="!disabled" [class.text-disabled]="disabled">{{
    unit
  }}</span>
}
@if (showButtons) {
  <button
    type="button"
    tabindex="-1"
    aria-hidden="true"
    class="dec btn btn-circle btn-tertiary btn-xs element-minus my-n3 ms-2"
    [disabled]="disabled || readonly || !canDec"
    (mousedown)="autoUpdateStart($event, false)"
    (touchstart)="autoUpdateStart($event, false)"
    (touchend)="autoUpdateStop()"
    (mouseup)="autoUpdateStop()"
    (mouseleave)="autoUpdateStop()"
  >
  </button>
  <button
    type="button"
    tabindex="-1"
    aria-hidden="true"
    class="inc btn btn-circle btn-tertiary btn-xs element-plus my-n3 me-n2"
    [disabled]="disabled || readonly || !canInc"
    (mousedown)="autoUpdateStart($event, true)"
    (touchstart)="autoUpdateStart($event, true)"
    (touchend)="autoUpdateStop()"
    (mouseup)="autoUpdateStop()"
    (mouseleave)="autoUpdateStop()"
  >
  </button>
}
