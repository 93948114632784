import { Injectable, NgZone } from '@angular/core';
import {
  Command,
  CommandParameters,
  CommandSubscriptionServiceBase,
  GmsSubscription,
  PropertyCommand,
  ReadCommandServiceBase
} from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Subscription } from 'rxjs';

import { GmsCommand } from '../processor/command/gms-command';
import { CommandValidationStatus } from '../processor/command/gms-command-status';
import { SubscribedProperty } from '../processor/command/gms-subscribed-property';
import { CommandParameter } from '../processor/command/parameters/gms-base-parameter';
import { MultiStateParameter } from '../processor/command/parameters/gms-multistate-parameters';
import { NumericLongParameter, NumericParameter } from '../processor/command/parameters/gms-numeric-parameter';
import { PasswordParameter } from '../processor/command/parameters/gms-password-parameter';
import { StringParameter } from '../processor/command/parameters/gms-string-parameter';
import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { FormatHelper } from '../utilities/format-helper';
import { Guid } from '../utilities/guid';
import { MathUtils } from '../utilities/mathUtils';

@Injectable()
export class GmsCommandService {

  private readonly globalCommands: Map<string, GmsCommand> = new Map<string, GmsCommand>();
  private readonly resolvePendingCommands: Map<string, GmsCommand> = new Map<string, GmsCommand>();
  private readonly globalSubscribedProperties: Map<string, SubscribedProperty> = new Map<string, SubscribedProperty>();

  /** key - property Id, value - collection of enable commands
   */
  private readonly _enabledCommandsCache: Map<string, string[]> = new Map<string, string[]>();

  // Subscribers to the Property Commands definition. Key is GUID
  private readonly _readPropertyCommandsSubscription: Map<string, Subscription> = new Map<string, Subscription>();
  // Connection id of the HUB to uniquely identify each connection for notification.
  private clientId: string;

  private readonly traceModule: string = 'gmsSnapins_GraphicsCommandService';

  /**
   * Request for a client Id and Initialise Values Subscription
   * @param clientName
   */
  public initClient(snapinName: string): void {

    // Get Client Id/token
    this.clientId = this.commandSubscriptionService.registerClient(snapinName);
  }

  /**
   *
   * @param clientName
   */
  public unInitialiseCommandUpdateSubscriptionService(): void {

    this.unSubscribeAll();

    this.commandSubscriptionService.disposeClient(this.clientId);
  }
  public getCommand(key: string): GmsCommand {
    const command: GmsCommand = this.globalCommands.get(key);
    return command;
  }

  public getOrCreateCommand(datapoint: Datapoint, commandName: string): GmsCommand {
    if (datapoint === undefined || datapoint.Id === undefined || datapoint.Id === '' || commandName === undefined || commandName === '') {
      return null;
    }

    const key: string = GmsCommand.getKey(datapoint.Id, commandName);

    let command: GmsCommand = this.globalCommands.get(key);

    if (command === undefined) {
      command = this.resolvePendingCommands.get(key);
      if (command === undefined) {
        command = new GmsCommand(datapoint.Designation, datapoint.Id, commandName);
        this.resolvePendingCommands.set(key, command);
        this.validateCommands([datapoint], [command]);
        return command;
      }
    }
    // Id same
    command.Designation = datapoint.Designation;
    return command;
  }

  public validateCommands(datapoints: Datapoint[], commands: GmsCommand[]): void {
    this.zone.runOutsideAngular(() => {

      if (datapoints !== undefined && commands !== undefined) {
        const propertiesList: string[] = new Array<string>();
        datapoints.forEach(dp => {
          commands.forEach(c => {
            if (c.Designation === dp.Designation) {

              if (dp.Status === DatapointStatus.DoesNotExist) {
                c.Datapoint = dp;
                c.setCommandValidationStatus(CommandValidationStatus.DoesNotExist);
              } else {
                c.Id = dp.Id;
                c.Datapoint = dp;
                if (this.resolvePendingCommands.has(c.Key) || !this.globalCommands.has(c.Key)) {
                  if (c.CommandStatus.IsUndefined) {
                    c.setCommandValidationStatus(CommandValidationStatus.Validating);
                    propertiesList.push(dp.Id);
                  }
                }
              }
            }
          });
        });
        if (propertiesList.length > 0) {
          const guid: string = Guid.newGuid();
          const subscription: Subscription = this.readCommandService.readPropertyCommands(
            propertiesList, undefined, false, undefined, true).subscribe(
            result => this.OnReadCommandsCallback(result, commands, guid),
            err => this.OnReadCommandsError(err, commands, guid)
          );
          if (subscription !== undefined) {
            this._readPropertyCommandsSubscription.set(guid, subscription);
          }
        }
      }
    });
  }

  public removeCommands(dpeId: string): void {
    if (dpeId === undefined) {
      return;
    }
    const commands: GmsCommand[] = [];

    this.globalCommands.forEach(c => {
      if (c.Datapoint.Id === dpeId) {

        if (c.Datapoint.CountUsage <= 0) {
          c.unsubscribe();
          commands.push(c);

          this.UnSubscribeDpeForCommandUpdates(c.Id);
        }
      }
    });
    commands.forEach(c => {
      this.globalCommands.delete(c.Key);
    });
  }

  public unsubscribeFromUpdates(dpeId: string): void {
    if (dpeId === undefined) {
      return;
    }
    this.globalCommands.forEach(c => {
      if (c.Datapoint.Id === dpeId) {
        c.unsubscribeFromUpdates();
      }
    });
  }

  public constructor(private readonly traceService: TraceService,
    private readonly readCommandService: ReadCommandServiceBase,
    private readonly commandSubscriptionService: CommandSubscriptionServiceBase,
    private readonly zone: NgZone) {

    this.traceService.info(this.traceModule, 'Graphics Command service created.');
  }

  private SubscribeDpeForCommandUpdates(propertyIds: string[]): void {
    if (propertyIds !== undefined && propertyIds.length > 0) {
      const subscribedProperties: SubscribedProperty[] = [];
      propertyIds.forEach(p => {
        if (!this.globalSubscribedProperties.has(p)) {
          const subscrProperty: SubscribedProperty = new SubscribedProperty(p);
          subscribedProperties.push(subscrProperty);
          this.globalSubscribedProperties.set(p, subscrProperty);
        }
        this.globalSubscribedProperties.get(p).addCommand();
      });

      if (subscribedProperties.length > 0) {
        const commandSubscriptions: GmsSubscription<PropertyCommand>[] =
                    this.commandSubscriptionService.subscribeCommands(subscribedProperties.map((sp: SubscribedProperty) => sp.DpId), this.clientId, true);

        // Update each property VM with its associated value subscription
        if (commandSubscriptions && commandSubscriptions.length === subscribedProperties.length) {
          subscribedProperties.forEach((p, i) => {
            p.CommandSubscription = commandSubscriptions[i];
            p.CommandChangeSubscription = p.CommandSubscription.changed.subscribe(cmd => this.onPropertyCommandsChange(cmd));

          });
        } else {
          this.traceService.error(this.traceModule, 'SubscribeDpeForCommandUpdates - failed');
        }
      }
    }
  }

  private UnSubscribeDpeForCommandUpdates(dpId: string): void {
    const subscriptionProperty: SubscribedProperty = this.globalSubscribedProperties.get(dpId);
    if (subscriptionProperty !== undefined) {
      if (subscriptionProperty.deleteCommand()) {
        this.globalSubscribedProperties.delete(dpId);
        subscriptionProperty.CommandChangeSubscription.unsubscribe();
        this.commandSubscriptionService.unsubscribeCommands([subscriptionProperty.CommandSubscription], this.clientId);
      }
    }
  }

  private updateCommands(cmdListUpdate: Command[]): void {
    cmdListUpdate.forEach(c => {
      this.globalCommands.forEach(cmnd => {
        if (cmnd.Id === c.PropertyId) {
          cmnd.Parameters.forEach(p => {
            const cp: CommandParameters = c.Parameters.find(x => x.Name === p.Name);
            if (cp !== undefined) {
              p.DefaultValue = cp.DefaultValue;
            }
          });
        }
      });
    });
  }

  private onPropertyCommandsChange(cmd: PropertyCommand): void {

    const cmdListUpdate: Command[] = cmd.Commands || [];

    // enabled commands
    cmdListUpdate.forEach(c => {

      this.traceService.info(this.traceModule,
        'enabled command:  Id = %s Command Name = %s', c.PropertyId, c.Descriptor);
    });

    this.updateCommands(cmdListUpdate);

    const enabledCommands: string[] = cmdListUpdate.map((c: Command) => c.Id);
    if (this._enabledCommandsCache.has(cmd.PropertyId)) {
      this._enabledCommandsCache.delete(cmd.PropertyId);
    }
    this._enabledCommandsCache.set(cmd.PropertyId, enabledCommands);

    const propertyCommands: GmsCommand[] = new Array<GmsCommand>();

    this.globalCommands.forEach(value => {
      if (value.Id === cmd.PropertyId) {
        propertyCommands.push(value);
      }
    });

    propertyCommands.forEach(c => {
      // enable / disable the command
      const isEnabled: boolean = (enabledCommands !== undefined && enabledCommands.includes(c.CommandName));
      c.IsEnabled = isEnabled;
    });
  }

  private UnsubscribeFromReadPropertyCommands(guid: string): void {
    if (this._readPropertyCommandsSubscription !== undefined) {
      const subscription: Subscription = this._readPropertyCommandsSubscription.get(guid);
      if (subscription !== undefined) {
        subscription.unsubscribe();
        this._readPropertyCommandsSubscription.delete(guid);
      }
    }
  }

  private OnReadCommandsError(error: any, commands: GmsCommand[], guid: string): void {
    const commandsList: string[] = new Array<string>();
    if (commands !== undefined) {
      commands.forEach(c => {
        commandsList.push(c.Id);
      });
    }
    this.traceService.error(this.traceModule,
      'OnReadCommands error returned: Error: %s, Commands Id: %s',
      error.message, commandsList.toString());

    this.UnsubscribeFromReadPropertyCommands(guid);
  }

  private OnReadCommandsCallback(result: PropertyCommand[], commands: GmsCommand[], guid: string): void {

    this.UnsubscribeFromReadPropertyCommands(guid);

    if (result === undefined || commands === undefined) {
      return;
    }
    let command: GmsCommand;
    let index: number;
    const unresolved: PropertyCommand[] = [];
    result.forEach(r => {
      const items: Command[] = r.Commands;
      // NOTE: Add Error code  to the data-model
      if (items === undefined || items.length === 0) {
        index = commands.findIndex(item => item.Id === r.PropertyId);
        if (index >= 0 && index < commands.length) {
          command = commands[index];
          if (this.globalCommands.has(command.Key)) {
            // unscubscribe the command.Datapoint from COV updates
            command.unsubscribeFromUpdates();
          }
          this.globalCommands.set(command.Key, command);
          command.setCommandValidationStatus(CommandValidationStatus.Misconfigured);
          unresolved.push(r);
        }
      } else {
        items.forEach(c => {

          const commandName: string = c.Id;
          index = commands.findIndex(item => item.CommandName === commandName);
          if (index >= 0 && index < commands.length) {
            command = commands[index];
            commands.splice(index, 1);

            if (command !== undefined && !this.globalCommands.has(command.Key)) {
              let commandParameter: CommandParameter;
              let min: string;
              let max: string;
              if (c.Parameters !== undefined) {
                c.Parameters.forEach(p => {
                  switch (p.DataType) {
                    case 'BasicInt':
                    case 'ExtendedInt':
                      min = p.Min === undefined ? FormatHelper.int32Min.toString() : p.Min;
                      max = p.Max === undefined ? FormatHelper.int32Max.toString() : p.Max;
                      commandParameter = new NumericParameter(p.Name, p.Order, p.DefaultValue,
                        min, max, 0, p.DataType);
                      break;

                    case 'BasicFloat':
                    case 'ExtendedReal':
                    case 'ExtendedAny':
                      min = p.Min === undefined ? (0 - Number.MAX_VALUE).toString() : p.Min;
                      max = p.Max === undefined ? Number.MAX_VALUE.toString() : p.Max;
                      commandParameter = new NumericParameter(p.Name, p.Order, p.DefaultValue,
                        min, max, p.Resolution, p.DataType);
                      break;

                    case 'BasicChar':
                      min = p.Min === undefined ? '0' : p.Min;
                      max = p.Max === undefined ? '255' : p.Max;
                      commandParameter = new NumericParameter(p.Name, p.Order, p.DefaultValue,
                        min, max, 0, p.DataType);

                      break;

                    case 'BasicUint':
                    case 'ExtendedUint':
                      min = p.Min === undefined ? FormatHelper.uint32Min.toString() : p.Min;
                      max = p.Max === undefined ? FormatHelper.uint32Max.toString() : p.Max;
                      commandParameter = new NumericParameter(p.Name, p.Order, p.DefaultValue,
                        min, max, 0, p.DataType);

                      break;

                    case 'BasicUint64':
                    case 'ExtendedUint64':
                    case 'BasicInt64':
                    case 'ExtendedInt64':
                      // min = p.Min === undefined ? FormatHelper.LongMinToNumber.toString() : p.Min;
                      // max = p.Max === undefined ? FormatHelper.LongMaxToNumber.toString() : p.Max;
                      commandParameter = new NumericLongParameter(p.Name, p.Order, p.DefaultValue,
                        p.Min, p.Max, 0, p.DataType);
                      break;

                    case 'BasicBool':
                    case 'ExtendedBool':
                    case 'ExtendedEnum':
                      const isPriorityParameter: boolean = p.Application === 1;
                      if (p.Min === undefined) {
                        p.Min = '0';
                      }
                      commandParameter = new MultiStateParameter(p.Name, p.Order, p.DefaultValue,
                        p.Min, p.Max, p.EnumerationTexts, p.DataType, isPriorityParameter);
                      break;

                    case 'BasicString':
                      if (p.IsPassword) {
                        commandParameter = new PasswordParameter(p.Name, p.Order, p.DefaultValue, p.Min, p.Max, p.DataType);
                      } else {
                        commandParameter = new StringParameter(p.Name, p.Order, p.DefaultValue, p.Min, p.Max, p.DataType);
                      }
                      break;

                    default:
                      this.traceService.info(this.traceModule, 'Command Parameter is not supported ' + 'Data Type ' + p.DataType);
                      break;
                  }
                  if (commandParameter !== undefined) {
                    command.Parameters.push(commandParameter);
                  }
                });
              }
            }
            if (command !== undefined) {
              command.DefaultCommand = c.IsDefault;
            }
          }
        });
      }
      // handle Misconfigured command - not found
      if (commands.length > 0) {
        commands.forEach(c => {
          this.globalCommands.set(c.Key, c);
          c.setCommandValidationStatus(CommandValidationStatus.Misconfigured);
          unresolved.push(r);
        });
      }
      if (command !== undefined) {
        if (!this.globalCommands.has(command.Key)) {
          this.globalCommands.set(command.Key, command);
          command.setCommandValidationStatus(CommandValidationStatus.Valid);
          if (this._enabledCommandsCache.has(command.Id)) {
            command.IsEnabled = this._enabledCommandsCache.get(command.Id).includes(command.CommandName);
          }
        }
        if (this.resolvePendingCommands.has(command.Key)) {
          this.resolvePendingCommands.delete(command.Key);
        }
      }
    });

    const diff: Set<PropertyCommand> = MathUtils.difference(new Set(result), new Set(unresolved));
    const propertiesToSubcribe: string[] = [];
    diff.forEach(value => {
      propertiesToSubcribe.push(value.PropertyId);
      this.traceService.info(this.traceModule, 'SubscribeDpeForCommandUpdates: ' + value.PropertyId);
    });
    // Subscribe DPe for Commands Enable status updates
    this.SubscribeDpeForCommandUpdates(propertiesToSubcribe);
  }

  private unSubscribeAll(): void {

    if (this._readPropertyCommandsSubscription !== undefined) {
      this._readPropertyCommandsSubscription.forEach(s => {
        s.unsubscribe();
      });
      this._readPropertyCommandsSubscription.clear();
    }
    if (this.globalSubscribedProperties !== undefined) {
      this.globalSubscribedProperties.forEach(s => {
        s.unsubscribeCommandSubscription();

      });
      // Collect all open property command subscriptions
      const commandSubscriptions: GmsSubscription<PropertyCommand>[] = [];
      this.globalSubscribedProperties.forEach(p => {
        commandSubscriptions.push(p.CommandSubscription);
      });

      // Unsubscribe all property commands
      if (commandSubscriptions.length > 0) {
        this.commandSubscriptionService.unsubscribeCommands(commandSubscriptions, this.clientId);
      }
      this.globalSubscribedProperties.clear();
      this.globalCommands.forEach(c => {
        c.unsubscribe();
      });
      this.globalCommands.clear();
    }
  }
}
