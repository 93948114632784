import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng/modal';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { EditDiscardDialogResult } from '../si-action-dialog.types';

@Component({
  selector: 'si-edit-discard-dialog',
  templateUrl: './si-edit-discard-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiEditDiscardDialogComponent {
  @Input() titleId?: string;
  @Input() heading = '';
  @Input()
  message =
    $localize`:@@SI_EDIT_DISCARD_DIALOG.MESSAGE:Do you want to save changes to the modified element?`;
  @Input() saveBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.SAVE_BTN:Save`;
  @Input() discardBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.DISCARD_BTN:Don't save`;
  @Input() cancelBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.CANCEL_BTN:Cancel`;
  @Input({ transform: booleanAttribute }) disableSave = false;
  @Input()
  disableSaveMessage =
    $localize`:@@SI_EDIT_DISCARD_DIALOG.DISABLE_SAVE_MESSAGE:Do you want to discard the changes`;
  @Input()
  disableSaveDiscardBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.DISABLE_SAVE_DISCARD_BTN:Discard`;
  @Input() translationParams: { [key: string]: any } = {};
  @Input() icon = '';

  protected modalRef = inject(ModalRef<SiEditDiscardDialogComponent, EditDiscardDialogResult>);

  protected onSave(): void {
    this.modalRef.hide(EditDiscardDialogResult.Save);
  }

  protected onDiscard(): void {
    this.modalRef.hide(EditDiscardDialogResult.Discard);
  }

  protected onCancel(): void {
    this.modalRef.hide(EditDiscardDialogResult.Cancel);
  }
}
