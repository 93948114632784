export const environment = {
  production: true,
  product: 'cbms',
  environmentName: '',
  app: {
    name: 'cbms',
    version: ''
  },
  profileUrl: 'https://uss.login-qa.siemens.com/userprofile',
  auth: {
    clientId: 'lYvSUmJAwrypADV8H4vlW9wLTR3mri3S',
    issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
    audience: 'https://horizonint.cloud',
    siemensIdBaseUrl: 'https://login-qa.siemens.com',
    tenant: 'main-tenant-oidc',
    msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  },
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js',
  bxPlatform: {
    accountManagerAppUrl: 'https://account.apps.horizondev.cloud',
    omInfoApiUrl: 'https://om.boic.horizonint.cloud/api',
    subscriptionsApiUrl: 'https://product-int.subscription.horizonint.cloud/api',
    userMgmtApiUrl: 'https://prodint.um.user.horizonint.cloud/api',
    aodsApiUrl: 'https://product-int.aods.horizonint.cloud/api',
    alarmVerticalApiUrl: 'https://product-int.alarm.horizonint.cloud/api',
    deviceVerticalApiUrl: 'https://product-int-dm.device.horizonint.cloud/api',
    locationVerticalApiUrl: 'https://product-int.locations.horizonint.cloud',
    pointVerticalApiUrl: 'https://product-int.point.horizonint.cloud/api',
    boBffApiUrl: 'https://boic.horizondev.cloud/api',
    faultApiUrl: 'https://int.fdd.fault.horizonint.cloud/api',
    rulesApiUrl: 'https://product-int-rs.rules.horizonint.cloud/api',
    dmTunnelApiUrl: 'https://tunnel-product-int.siemens.com',
    userOrganizationsApiV1Url: 'https://prodint.user.horizonint.cloud/api/v1',
    notificationVerticalApiV1Url: 'https://prodint.notification.horizonint.cloud/api/v1',
    webAppVerticalApiUrl: 'https://product-int.webapp.horizonint.cloud'  
  },
  appSwitcherSource: 'https://product-dev.webapp.horizonint.cloud/api/v1/me/apps',
  aMapApiKey: '',
  googleAPIChannel: '107',
  googleMapsApiKey: 'AIzaSyA-sSzcLt_aaEuIEblwzQrDnFifJhucpXM'
};
