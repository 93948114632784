import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { TreeItem } from '@simpl/element-ng';
import { Observable, Subscription } from 'rxjs';

import { ObjectViewIfc } from '../../object-manager-core/view-model/object-view';
import { ActiveMenuItemService } from '../services/active-menu-item.service';
import { CnsLabelService } from '../services/cns-label.service';
import { GmsEasyNavigationResizeService } from '../services/gms-easy-navigation-resize.service';
import { SelectionChangedService } from '../services/selection-changed.service';

@Component({
  selector: 'gms-easy-navigation-bar-top-menu',
  template: `
    <si-menu-bar #siMenuBar>
      <ng-container *ngFor="let treeItem of managedTreeItems">
<!--        @if (!isResizing || !isLastTreeItem(treeItem)) {-->
<!--        }-->
          @if (hasChildItems(treeItem)) {
            <si-menu-item class="top-menu-item pe-0"
                          [cdkMenuTriggerFor]="treeItemMenu"
                          [cdkMenuTriggerData]="{ treeItems: treeItem.children }">
              {{ treeItem.label }}
            </si-menu-item>
          } @else {
            <si-menu-item class="top-menu-item" (click)="onSelectionChanged(treeItem)">{{ treeItem.label }}
            </si-menu-item>
          }
      </ng-container>
      @if (ellipsisTreeItems?.length > 0) {
        <si-menu-item
          class="top-menu-item"
          [style.display]="isResizing ? null : 'display'"
          icon="element-options-vertical"
          aria-label="Options"
          [cdkMenuTriggerFor]="treeItemMenu"
          [cdkMenuTriggerData]="{ treeItems: ellipsisTreeItems }"/>
      }
    </si-menu-bar>
    <ng-template #treeItemMenu let-treeItems="treeItems" let-parentHasScrollbar="parentHasScrollbar">
      @if (treeItems.length > 0) {
        <si-menu #siMenuElementRef class="si-menu"
                 [ngClass]="{'si-menu-parent-with-scrollbar': parentHasScrollbar === true,
                                   'si-menu-parent-no-scrollbar': parentHasScrollbar === false}">
          <ng-container *ngFor="let item of treeItems">
            <si-menu-item *ngIf="!isDividerItem(item)"
                          [cdkMenuTriggerFor]="hasChildItems(item) ? treeItemMenu : null"
                          [cdkMenuTriggerData]="{ treeItems: item.children, parentHasScrollbar: calculateHasScrollbar(siMenuElementRef)}"
                          (click)="onSelectionChanged(item)">{{ item.label }}
            </si-menu-item>
            <si-menu-divider *ngIf="isDividerItem(item)"/>
          </ng-container>
        </si-menu>
      }
    </ng-template>
  `,
  styleUrl: './gms-easy-navigation-bar-top-menu.component.scss'
})

export class GmsEasyNavigationBarTopMenuComponent implements OnInit, OnDestroy {
  @ViewChild('siMenuBar') public siMenuBar: ElementRef;
  @Input() public view: ObjectViewIfc;
  @Input() public items: TreeItem[];
  public managedTreeItems: TreeItem[] = [];
  public ellipsisTreeItems: TreeItem[] = [];
  public subscriptions: Subscription[] = [];
  public isResizing = false;
  private rootItems: TreeItem[] = [];
  private readonly topMenuItemTagName: string = 'si-menu-item';

  constructor(public selectionChangedService: SelectionChangedService,
    public gmsEasyNavigationResizeService: GmsEasyNavigationResizeService,
    public cnsLabelService: CnsLabelService,
    public cdRef: ChangeDetectorRef) {
    this.cnsLabelService.initEasyNavigationItems(this.items);
  }

  public isLastTreeItem(treeItem: TreeItem): boolean {
    const lastItem: TreeItem = this?.managedTreeItems?.at(-1);
    return treeItem === lastItem;
  }

  public calculateHasScrollbar(element: HTMLElement): boolean {
    let hasScrollbar = false;
    if (!isNullOrUndefined(element)) {
      const scrollbarWidth: number = element.offsetWidth - element.clientWidth;
      hasScrollbar = scrollbarWidth >= 5;
    }

    return hasScrollbar;
  }

  public isDividerItem(item: any): boolean {
    return !isNullOrUndefined(item) && item.label === '-';
  }

  public onSelectionChanged(treeItem: TreeItem): void {
    if (!this.hasChildItems(treeItem)) {
      this.selectionChangedService.onSelectionChanged.next(treeItem);
    }
  }

  public hasChildItems(item: TreeItem): boolean {
    return item?.children?.length > 0;
  }

  public onLoadChildren(treeItem: TreeItem): Observable<TreeItem[]> {
    return this.view.getChildren(treeItem);
  }

  public initManagedTreeItems(): void {
    this.managedTreeItems = [];
    for (const rootItem of this.rootItems) {
      this.managedTreeItems.push(rootItem);
    }
  }

  public initManagedAndEllipsisTreeItems(): void {
    this.clearNavigationBarItems();
    // this.cdRef.detectChanges();
    let isInNavigationContainerBoundsBool = true;

    for (let i = 0; i < this.rootItems.length; ++i) {
      if (isInNavigationContainerBoundsBool) {
        this.managedTreeItems.push(this.rootItems[i]);
        this.cdRef.detectChanges();
        isInNavigationContainerBoundsBool = this.getIsInNavigationContainerBounds();
        if (!isInNavigationContainerBoundsBool) {
          this.managedTreeItems.pop();
          this.ellipsisTreeItems.push(this.rootItems[i]);
        }
      } else {
        this.ellipsisTreeItems.push(this.rootItems[i]);
      }
    }
  }

  public ngOnInit(): void {
    this.initRootItems();
    this.gmsEasyNavigationResizeService.resizeObs.subscribe(() => this.onResize());
    this.cnsLabelService.initEasyNavigationItems(this.items);
    this.initializeTreeItemsRecursive(this.items);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public onResize(): void {
    this.isResizing = true;
    this.cdRef.detectChanges();
    this.initManagedAndEllipsisTreeItems();
    this.isResizing = false;
  }

  private hasDashTitle(item: any): boolean {
    return item?.label === '-';
  }

  private clearNavigationBarItems(): void {
    this.managedTreeItems = [];
    this.ellipsisTreeItems = [];
  }

  private getIsInNavigationContainerBounds(): boolean {
    const siMenuBarRight: number = (this?.siMenuBar?.nativeElement?.getBoundingClientRect()?.right || 0) + 20;
    const navigationBarRight: number = this?.gmsEasyNavigationResizeService?.NavigationContainerRight || 0;
    return siMenuBarRight <= navigationBarRight;
  }

  private initRootItems(): void {
    if (this.items.length <= 1) {
      this.rootItems = this.items?.[0]?.children;
    } else {
      this.rootItems = this.items;
    }
  }

  private initializeTreeItemsRecursive(items: any[], parent?: TreeItem, level: number = 0): void {
    let lastMenuItem: any = undefined;
    if (items.length !== 0) {
      lastMenuItem = items.at(-1);
    }

    for (const item of items) {
      if (!isNullOrUndefined(item?.action) || this.hasDashTitle(item)) {
        continue;
      }

      item.parent = parent;
      item.level = level;
      item.title = item.label;
      item.context = this;
      item.icon = undefined;
      item.action = function (): void {
        if (isNullOrUndefined(this?.items) || this?.items?.length === 0) {
          this?.context?.onSelectionChanged(this);
        }
      };

      this.onLoadChildren(item).subscribe({
        next: children => {
          const navigationItem: any = {
            label: item.label,
            action: item.action,
            context: item.context,
            customData: item.customData
          };

          const dash: any = {
            label: '-'
          };

          let managedChildren: any[] = [];

          if (children?.length > 0) {
            if (level > 0) {
              managedChildren = [navigationItem, dash, ...children];
            } else {
              managedChildren = children;
            }
          }

          item.children = managedChildren;

          if (item?.children?.length > 0) {
            this.initializeTreeItemsRecursive(item.children, item, level + 1);
          }

          if (item == lastMenuItem) {
            this.initManagedAndEllipsisTreeItems();
          }
        }
      });
    }
  }
}
