/* tslint:disable:interface-name */
export interface ISingleInstanceStoreMap {
  [name: string]: SingleInstanceStore;
}
/* tslint:enable:interface-name */

export class SingleInstanceStore {
  public lastMessage: any;

  public appendMessage(messageData: any): void {
    this.lastMessage = messageData;
  }
}
