import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CnsHelperService, ObjectsServiceBase, SiIconMapperService, SystemBrowserServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { TraceModules } from '../../shared/trace-modules';
import { TraceServiceDelegate } from '../../shared/trace-service-delegate';
import { ServiceCatalog } from '../view-model/types';
import { MemoViewModel } from '../view-model/memo-vm';
import { MemoViewModelIfc } from '../view-model/memo-vm.base';
import { ObjectManagerCoreServiceBase } from '../../object-manager-core';
import { MemoPopoverServiceBase } from './memo-popover.service.base';

@Injectable({
  providedIn: 'root'
})
export class MemoPopoverService implements MemoPopoverServiceBase, OnDestroy {

  private readonly traceSvc: TraceServiceDelegate;
  private vmMap: Map<string, MemoViewModel>;
  private readonly serviceCatalog: ServiceCatalog;

  public get commonTranslateService(): TranslateService {
    return this.translateService;
  }

  constructor(
    traceService: TraceService,
    private readonly translateService: TranslateService,
    objectsService: ObjectsServiceBase,
    systemBrowserService: SystemBrowserServiceBase,
    iconMapperService: SiIconMapperService,
    cnsHelperService: CnsHelperService,
    cnsCoreService: ObjectManagerCoreServiceBase) {

    this.traceSvc = new TraceServiceDelegate(traceService, TraceModules.aboutObject);
    this.vmMap = new Map<string, MemoViewModel>();
    // Service catalog will be provided to view-model objects
    this.serviceCatalog = new ServiceCatalog(
      traceService,
      cnsHelperService,
      cnsCoreService,
      objectsService,
      systemBrowserService,
      iconMapperService);
  }

  public ngOnDestroy(): void {
    this.traceSvc.info('Destroying MemoPopoverService instance.');
    this.vmMap.forEach(vm => vm.dispose());
    this.vmMap.clear();
    this.vmMap = undefined;
  }

  public registerViewModel(id: string, ngZone: NgZone): MemoViewModelIfc {
    if (!this.vmMap) {
      throw new Error('service has been destroyed');
    }
    if (!id) {
      throw new Error('empty registration id');
    }
    // Retrieve a view-model from the map, or create it if it does not yet exist
    let vm: MemoViewModel = this.vmMap.get(id);
    if (!vm) {
      this.traceSvc.info('Create new memo view-model: id=%s', id);
      vm = new MemoViewModel(this.serviceCatalog, id, ngZone);
      this.vmMap.set(id, vm); // add to map
    } else {
      this.traceSvc.info('Retrieve existing memo view-model: id=%s', id);
    }
    return vm;
  }

  public unregisterViewModel(id: string): void {
    // Remove a view-model from the map and dispose of it
    const vm: MemoViewModel = this.vmMap.get(id);
    if (vm) {
      this.traceSvc.info('Delete memo view-model: id=%s', id);
      this.vmMap.delete(id);
      vm.dispose();
    }
  }

}
