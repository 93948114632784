import { NgModule } from '@angular/core';

import { SiComfortScoreStatusComponent } from './si-comfort-score-status.component';
import { SiComfortScoreComponent } from './si-comfort-score.component';

@NgModule({
  exports: [SiComfortScoreComponent, SiComfortScoreStatusComponent],
  imports: [SiComfortScoreComponent, SiComfortScoreStatusComponent]
})
export class SiComfortScoreModule {}
