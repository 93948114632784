import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CnsHelperService, CommandSubscriptionServiceBase, ExecuteCommandServiceBase,
  PropertyServiceBase, ReadCommandServiceBase, ValueSubscription2ServiceBase } from '@gms-flex/services';
import { SettingsServiceBase, TraceService } from '@gms-flex/services-common';
import { ValidationDialogService } from '@gms-flex/snapin-common';

import { TraceModules } from '../shared/trace-modules';
import { PropertySnapInViewModel } from '../view-model/snapin-vm';
import { PropertySnapInServiceBase } from './property-snapin.service.base';

@Injectable({
  providedIn: 'root'
})
export class PropertySnapInService implements PropertySnapInServiceBase, OnDestroy {

  private sniVmMap: Map<string, PropertySnapInViewModel>;

  /**
   * Constructor.
   */
  constructor(
    private readonly propertyService: PropertyServiceBase,
    private readonly settingsService: SettingsServiceBase,
    private readonly valueSubscriptionService: ValueSubscription2ServiceBase,
    private readonly readCommandService: ReadCommandServiceBase,
    private readonly execCommandService: ExecuteCommandServiceBase,
    private readonly commandSubscriptionService: CommandSubscriptionServiceBase,
    private readonly cnsHelperService: CnsHelperService,
    private readonly validationDialogService: ValidationDialogService,
    private readonly traceService: TraceService) {

    this.sniVmMap = new Map<string, PropertySnapInViewModel>();
  }

  /**
   * Called when service is destroyed.
   */
  public ngOnDestroy(): void {
    if (this.sniVmMap) {
      // Ensure all VMs are disposed
      try {
        this.sniVmMap.forEach(vm => vm.dispose());
      } catch (err) {
      // NOTE: In the event underlying services are in an unstable state, catch
      //  potential exceptions here in order to exit cleanly.
      }
      this.sniVmMap.clear();
      this.sniVmMap = undefined;
    }
  }

  /**
   * Get existing or create new snap-in view-model.
   */
  public registerViewModel(sniId: string): PropertySnapInViewModel {
    if (!sniId || sniId.length === 0) {
      throw new Error('sniId argument cannot be undefined or empty');
    }

    let vm: PropertySnapInViewModel = this.sniVmMap.get(sniId);
    if (!vm) {
      this.traceService.info(TraceModules.pvcServices, `Create new view-model: sniId=[${sniId}]`);
      vm = new PropertySnapInViewModel(
        sniId,
        this.propertyService,
        this.settingsService,
        this.valueSubscriptionService,
        this.readCommandService,
        this.execCommandService,
        this.commandSubscriptionService,
        this.cnsHelperService,
        this.validationDialogService,
        this.traceService);
      this.sniVmMap.set(sniId, vm);
    }
    vm.activate();
    return vm;
  }

  /**
   * Remove and dispose of snap-in view-model.
   */
  public unregisterViewModel(sniId: string): void {
    const vm: PropertySnapInViewModel = this.sniVmMap.get(sniId);
    if (vm) {
      this.traceService.info(TraceModules.pvcServices, `Destroy view-model: sniId=[${sniId}]`);
      vm.dispose();
      this.sniVmMap.delete(vm.id);
    }
  }
}
