import {
  AnyCollectionValue,
  AnyPriorityArrayValue,
  BACnetObjectIdentifierValue,
  BlindValue
} from '@simpl/element-value-types';

import { Property } from '../interfaces/property';

export const resetValueSimple = (property: Property): void => {
  property.value.value = undefined;
};

export const resetValueBlind = (property: Property<BlindValue>): void => {
  if (property.value.value) {
    property.value.value.angle.value = undefined;
    property.value.value.height.value = undefined;
  }
};

export const resetValueObjectid = (property: Property<BACnetObjectIdentifierValue>): void => {
  if (property.value.value) {
    property.value.value.instance.value = undefined;
    property.value.value.objectType.value = undefined;
  }
};

export const resetValueCollection = (property: Property<AnyCollectionValue>): void => {
  if (property.value.value) {
    property.value.value.forEach(item => {
      if (item.value.type === 'collection') {
        resetValueCollection(item as Property<AnyCollectionValue>);
      } else if (item.value.type === 'composition' && item.value.kind === 'blind') {
        resetValueBlind(item as Property<BlindValue>);
      } else if (
        item.value.type === 'composition' &&
        item.value.kind === 'bacnet-object-identifier'
      ) {
        resetValueObjectid(item as Property<BACnetObjectIdentifierValue>);
      } else {
        resetValueSimple(item as Property);
      }
    });
  }
};

export const isValueSet = (property: Property): boolean => {
  if (property.value.type === 'composition' && property.value.kind === 'blind') {
    return property.value.value?.angle.value != null && property.value.value?.height.value != null;
  } else if (
    property.value.type === 'composition' &&
    property.value.kind === 'bacnet-object-identifier'
  ) {
    return (
      property.value.value?.instance.value != null && property.value.value?.objectType.value != null
    );
  } else {
    return property.value.value != null;
  }
};

export const mergeProperties = (update: Property, onto: Property): void => {
  if (onto.value.kind !== 'priority-array') {
    onto.value.value = update.value.value;
    return;
  }

  // priority array only contains the changed element, so merge
  const updateArray = update as Property<AnyPriorityArrayValue>;
  const ontoArray = onto as Property<AnyPriorityArrayValue>;

  const prioValue = updateArray.value.value?.[0];
  if (prioValue) {
    const item = ontoArray.value.value?.find(i => i.position === prioValue.position);
    if (item) {
      item.value.value = prioValue.value.value;
    }
  }
};
