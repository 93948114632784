import { Observable, Observer } from 'rxjs';

import { GmsCommandResult } from '../processor/command-view-model/gms-command-vm';
import { ParameterMultiStateViewModel } from '../processor/command-view-model/gms-parameter-multistate-vm';
import { ParameterViewModelBase } from '../processor/command-view-model/gms-parameter-vm.base';
import { ParameterType } from '../processor/command/parameters/gms-base-parameter';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsCommandControl } from './gms-commandcontrol';

export class GmsCommandControlSelector extends GmsCommandControl {

  constructor() {
    super(GmsCommandControlType.Selector);
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public ExecuteCommand(): Observable<GmsCommandResult> {
    return Observable.create((observer: Observer<GmsCommandResult>) => {
      if (this.CommandVM !== null && this.ParameterVM.IsTriggerEnabled) {
        this.CommandVM.executeCommand(
          this.ValidationService, this.ExecuteCommandService, observer, this.locale);
      }
    });
  }

  protected CreateDesignModeParameterViewModel(): ParameterViewModelBase {
    return new ParameterMultiStateViewModel(null);
  }

  protected ValidateControlType(parameterVM: ParameterViewModelBase): boolean {
    const isValid: boolean = (parameterVM !== null) &&
            (parameterVM.ParameterType === ParameterType.Priority ||
                parameterVM.ParameterType === ParameterType.MultiState);

    return isValid;
  }
}
