import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { isRTL } from '@simpl/element-ng/common';

import * as calendarUtils from '../date-time-helper';
import { Cell, SiCalendarBodyComponent } from './si-calendar-body.component';
import { SiCalendarDirectionButtonComponent } from './si-calendar-direction-button.component';
import { YearCompareAdapter } from './si-compare-adapter';
import { SiInitialFocusComponent } from './si-initial-focus.component';

/**
 * Show months of a single year as table and handles the keyboard interactions.
 * The focus and focusedDate is handled according the keyboard interactions.
 */
@Component({
  selector: 'si-year-selection',
  templateUrl: './si-year-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiCalendarDirectionButtonComponent, SiCalendarBodyComponent, DatePipe]
})
export class SiYearSelectionComponent extends SiInitialFocusComponent implements OnChanges {
  /** The active date, the cell which will receive the focus. */
  @Input() focusedDate = calendarUtils.today();
  /** Emits when the active focused date changed to another month / year, typically during keyboard navigation. */
  @Output() readonly yearRangeChange = new EventEmitter<Date[]>();
  /** Listen Escape event to switch view back */
  @HostListener('keydown.Escape', ['$event']) triggerEsc(event: KeyboardEvent): void {
    this.selectedValueChange.emit(null);
    event.preventDefault();
    event.stopPropagation(); // Prevents the overlay from closing.
  }
  /** Number of column before the row is wrapped */
  private readonly columnCount = 3;
  /** The number of years which shall be displayed, this number should be even and dividable by columnCount */
  private readonly yearsToDisplay = 18;
  /** Lower windows bound for displayed year range */
  protected fromYear?: Date;
  /** Upper windows bound for displayed year range */
  protected toYear?: Date;
  /**
   * The current visible list of calendar years.
   * Every time the focusedDate changes to another year the list will be rebuilt.
   */
  protected yearCells: Cell[][] = [];
  /** Is previous button disabled based on the minDate */
  protected disablePreviousButton = false;
  /** Is next button  disabled  based on the maxDate */
  protected disableNextButton = false;
  protected compareAdapter = new YearCompareAdapter();
  private readonly cdRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate || changes.focusedDate || changes.maxDate || changes.minDate) {
      this.initView();
    }
  }

  protected calendarBodyKeyDown(event: KeyboardEvent): void {
    const isRtl = isRTL();
    const oldActiveDate = this.focusedDate;
    switch (event.key) {
      case 'ArrowLeft':
        this.setYearOffset(isRtl ? 1 : -1);
        break;
      case 'ArrowRight':
        this.setYearOffset(isRtl ? -1 : 1);
        break;
      case 'ArrowUp':
        this.setYearOffset(-1 * this.columnCount);
        break;
      case 'ArrowDown':
        this.setYearOffset(this.columnCount);
        break;
      case 'PageUp':
        this.setYearOffset(-1 * (this.focusedDate.getFullYear() - this.fromYear!.getFullYear()));
        break;
      case 'PageDown':
        this.setYearOffset(this.toYear!.getFullYear() - this.focusedDate.getFullYear());
        break;
      case 'Escape':
        this.selectedValueChange.emit(null);
        event.preventDefault();
        event.stopPropagation(); // Prevents the overlay from closing.
        return;
      case 'Enter':
      case 'Space':
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }

    if (!this.compareAdapter.isEqual(oldActiveDate, this.focusedDate)) {
      this.focusActiveCell();
    }
    // Prevent unexpected default actions such as form submission.
    event.preventDefault();
  }

  /**
   * Change the active date and the range of displayed years.
   * The windowOffset control the amount of ranges the view shall move forward or backward.
   * The number of displayed years ia controlled by yearsToDisplay.
   */
  protected changeVisibleYearRange(windowOffset: number): void {
    const offset = windowOffset * this.yearsToDisplay;
    this.setYearOffset(offset);
  }

  protected emitSelectedValue(selected: Date): void {
    this.selectedValueChange.emit(selected);
    this.cdRef.markForCheck();
  }

  /**
   * Determine the year range start and end year.
   * - Based on the active date this function will find the start and
   * ending year of the current displayed range.
   * - In case the focusedDate is either before or after the current range the
   * start and end year will move the entire window (yearsToDisplay)
   */
  private initYearRange(): void {
    // Did we exceed the display current displayed year range
    let changed = false;
    if (!this.fromYear) {
      const start = this.focusedDate.getFullYear() - this.yearsToDisplay / 2;
      this.fromYear = new Date(start, 0, 1);
      this.toYear = new Date(start + this.yearsToDisplay - 1, 0, 1);
      changed = true;
    } else if (this.compareAdapter.isAfter(this.focusedDate, this.toYear!)) {
      // Change window forward
      const rangeDistance = Math.floor(
        Math.abs(this.focusedDate.getFullYear() - this.fromYear.getFullYear()) / this.yearsToDisplay
      );
      const newFromYear = this.fromYear.getFullYear() + rangeDistance * this.yearsToDisplay;

      this.fromYear = new Date(newFromYear, 0, 1);
      this.toYear = new Date(newFromYear + this.yearsToDisplay - 1, 0, 1);
      changed = true;
    } else if (this.compareAdapter.isAfter(this.fromYear, this.focusedDate)) {
      // Change window backwards
      const rangeDistance = Math.ceil(
        Math.abs(this.focusedDate.getFullYear() - this.fromYear.getFullYear()) / this.yearsToDisplay
      );
      const newFromYear = this.fromYear.getFullYear() - rangeDistance * this.yearsToDisplay;

      this.fromYear = new Date(newFromYear, 0, 1);
      this.toYear = new Date(newFromYear + this.yearsToDisplay - 1, 0, 1);
      changed = true;
    }

    this.disablePreviousButton = this.isPreviousButtonDisabled();
    this.disableNextButton = this.isNextButtonDisabled();

    if (changed) {
      this.yearRangeChange.emit([this.fromYear!, this.toYear!]);
    }
  }

  /**
   * Initialize view based on the focusedDate.
   */
  private initView(): void {
    // Initial year limits
    this.initYearRange();

    this.yearCells = [];
    let row: Cell[] = [];

    // The cell date object needs to be the first to prevent that we jump to the next month when
    // setting the month. For example the focusedDate is 31. setting february would result in the
    // 3. March.
    const startDate = calendarUtils.getFirstDateInYear(this.fromYear!);
    const today = calendarUtils.today();
    for (let i = 0; i < this.yearsToDisplay; i++) {
      if (i > 0 && i % this.columnCount === 0) {
        this.yearCells.push(row);
        row = [];
      }

      const date = calendarUtils.createDate(startDate);
      date.setFullYear(date.getFullYear() + i);
      const isToday = this.compareAdapter.isEqual(date, today);
      const isDisabled = !this.compareAdapter.isEqualOrBetween(date, this.minDate, this.maxDate);
      const year = date.getFullYear().toString();
      row.push({
        value: date.getDate(),
        disabled: isDisabled,
        ariaLabel: year,
        displayValue: year,
        isPreview: false,
        isToday,
        valueRaw: calendarUtils.createDate(date),
        cssClasses: ['year', 'si-title-1']
      });
    }
    this.yearCells.push(row);
  }

  /**
   * Add offset to year and update focusedDate.
   * If the new year is outside min/max date the year will set to the closest year in range.
   */
  private setYearOffset(offset: number): void {
    const newActive = calendarUtils.addYearsInRange(
      this.focusedDate,
      offset,
      this.minDate,
      this.maxDate
    );
    this.focusedDate = newActive;
    if (!this.fromYear || !calendarUtils.isBetween(this.focusedDate, this.fromYear, this.toYear)) {
      // Re-calc years view
      this.initView();
    }
    this.cdRef.markForCheck();
  }

  /**
   * Indicate the previous button shall be disabled.
   * This happens when the lower window bound is equal or before the minDate.
   */
  private isPreviousButtonDisabled(): boolean {
    if (!this.minDate && !this.minMonth) {
      return false;
    }
    const min = calendarUtils.minDate(this.minDate, this.minMonth)!;
    return (
      this.compareAdapter.isEqual(this.fromYear!, min) ||
      this.compareAdapter.isAfter(min, this.fromYear!)
    );
  }

  /**
   * Indicate the next button shall be disabled.
   * This happens when the upper window bound is equal or after the maxDate.
   */
  private isNextButtonDisabled(): boolean {
    if (!this.maxDate) {
      return false;
    }
    return (
      this.compareAdapter.isEqual(this.toYear!, this.maxDate) ||
      this.compareAdapter.isAfter(this.toYear!, this.maxDate)
    );
  }
}
