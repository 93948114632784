import { NgModule } from '@angular/core';

import { SiTreeViewItemNextComponent } from './si-tree-view-item-next/si-tree-view-item-next.component';
import { SiTreeViewItemNextTemplateDirective } from './si-tree-view-item-next/si-tree-view-item-next.directive';
import { SiTreeViewItemTemplateDirective } from './si-tree-view-item-template.directive';
import { SiTreeViewItemComponent } from './si-tree-view-item/si-tree-view-item.component';
import { SiTreeViewComponent } from './si-tree-view.component';

@NgModule({
  imports: [
    SiTreeViewComponent,
    SiTreeViewItemComponent,
    SiTreeViewItemNextComponent,
    SiTreeViewItemNextTemplateDirective,
    SiTreeViewItemTemplateDirective
  ],
  exports: [
    SiTreeViewComponent,
    SiTreeViewItemComponent,
    SiTreeViewItemNextComponent,
    SiTreeViewItemNextTemplateDirective,
    SiTreeViewItemTemplateDirective
  ]
})
export class SiTreeViewModule {}
