import { LocalTextGroupEntry } from '@gms-flex/services';
import { Subject } from 'rxjs';

export enum TextGroupEntryStatus {
  // eslint-disable-next-line id-blacklist
  Undefined = 0,
  Pending = 1,
  Resolved = 2,
  DoesNotExist = 3
}

export class TextGroupEntry {

  public propertyChanged: Subject<TextGroupEntryPropertyChangeArgs> = new Subject<TextGroupEntryPropertyChangeArgs>();

  private _txGrId: string;
  public get TxGroupId(): string {
    return this._txGrId;
  }
  public set TxGroupId(value: string) {
    if (this._txGrId !== value) {
      this._txGrId = value;
    }
  }

  private _localTextGroupEntry: LocalTextGroupEntry;
  public get LocalTextGroupEntry(): LocalTextGroupEntry {
    return this._localTextGroupEntry;
  }
  public set LocalTextGroupEntry(value: LocalTextGroupEntry) {
    if (this._localTextGroupEntry !== value) {
      this._localTextGroupEntry = value;
    }
  }

  private _iconSource: string;
  public get IconSource(): string {
    return this._iconSource;
  }
  public set IconSource(value: string) {
    if (this._iconSource !== value) {
      this._iconSource = value;
      this.NotifyPropertyChanged();
    }
  }

  private _textGroupEntryStatus: TextGroupEntryStatus = TextGroupEntryStatus.Undefined;
  public get Status(): TextGroupEntryStatus {
    return this._textGroupEntryStatus;
  }
  public set Status(value: TextGroupEntryStatus) {
    if (this._textGroupEntryStatus !== value) {
      this._textGroupEntryStatus = value;
      this.NotifyPropertyChanged();
    }
  }
  private _textGroupIconEntryStatus: TextGroupEntryStatus = TextGroupEntryStatus.Undefined;
  public get IconStatus(): TextGroupEntryStatus {
    return this._textGroupIconEntryStatus;
  }
  public set IconStatus(value: TextGroupEntryStatus) {
    if (this._textGroupIconEntryStatus !== value) {
      this._textGroupIconEntryStatus = value;
      this.NotifyPropertyChanged();
    }
  }
  private _systemId = '';
  public get SystemId(): string {
    return this._systemId;
  }
  public set SystemId(value: string) {
    if (this._systemId !== value) {
      this._systemId = value;
    }
  }

  private _systemName = '';
  public get SystemName(): string {
    return this._systemName;
  }
  public set SystemName(value: string) {
    if (this._systemName !== value) {
      this._systemName = value;
    }
  }

  private _tableName = '';
  public get TableName(): string {
    return this._tableName;
  }

  public set TableName(value: string) {
    if (this._tableName !== value) {
      this._tableName = value;
    }
  }

  private _index = '';
  public get Index(): string {
    return this._index;
  }

  public set Index(value: string) {
    if (this._index !== value) {
      this._index = value;
    }
  }

  constructor(txGrId: string) {
    this.TxGroupId = txGrId;
    this.Status = TextGroupEntryStatus.Undefined;
    this.IconStatus = TextGroupEntryStatus.Undefined;
  }

  protected NotifyPropertyChanged(): void {

    if (this.propertyChanged.observers !== null && this.propertyChanged.observers.length > 0) {

      const args: TextGroupEntryPropertyChangeArgs = new TextGroupEntryPropertyChangeArgs();
      args.TxGroupId = this.TxGroupId;
      args.LocalTextGroupEntry = this.LocalTextGroupEntry;
      args.IconSource = this.IconSource;
      args.Status = this.Status;
      args.IconStatus = this.IconStatus;
      this.propertyChanged.next(args);
    }
  }
}

export class TextGroupEntryPropertyChangeArgs {
  private _status: TextGroupEntryStatus;
  public get Status(): TextGroupEntryStatus {
    return this._status;
  }
  public set Status(value: TextGroupEntryStatus) {
    if (this._status !== value) {
      this._status = value;
    }
  }
  private _iconStatus: TextGroupEntryStatus;
  public get IconStatus(): TextGroupEntryStatus {
    return this._iconStatus;
  }
  public set IconStatus(value: TextGroupEntryStatus) {
    if (this._iconStatus !== value) {
      this._iconStatus = value;
    }
  }
  private _txGroupId: string;
  public get TxGroupId(): string {
    return this._txGroupId;
  }
  public set TxGroupId(value: string) {

    if (this._txGroupId !== value) {
      this._txGroupId = value;
    }
  }
  private _iconSource: string;
  public get IconSource(): string {
    return this._iconSource;
  }
  public set IconSource(value: string) {

    if (this._iconSource !== value) {
      this._iconSource = value;
    }
  }

  private _localTextGroupEntry: LocalTextGroupEntry;
  public get LocalTextGroupEntry(): LocalTextGroupEntry {
    return this._localTextGroupEntry;
  }
  public set LocalTextGroupEntry(value: LocalTextGroupEntry) {
    if (this._localTextGroupEntry !== value) {
      this._localTextGroupEntry = value;
    }
  }
}
