import { TimeDurationFormat, TimeDurationUnit } from '@simpl/element-value-types';

import { TimeFormat } from '../interfaces/date-time-formats';

// Implements https://simpl.code.siemens.io/simpl-element/architecture/common-value-types/#time
export const timeString = (value: string | undefined, format: TimeFormat): string => {
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const [hh, mm, ss, hs] = (value || '*:*:*:*')
    .replace('.', ':')
    .split(':')
    .map(i => i || '0')
    .map(i => (i === '*' ? i : i.padStart(2, '0')));
  const seconds = format.includes('ss') ? `:${ss || '00'}` : '';
  const hundredths = format.includes('hs') ? `.${hs || '00'}` : '';
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
  if (!format.includes('tt') || hh === '*') {
    return `${hh}:${mm}${seconds}${hundredths}`;
  } else {
    const isAm = Number(hh) < 12 || Number(hh) === 24;
    const hours = String(Number(hh) % 12 || 12).padStart(2, '0');
    return `${hours}:${mm}${seconds}${hundredths} ${isAm ? 'AM' : 'PM'}`;
  }
};

// Implements https://simpl.code.siemens.io/simpl-element/architecture/common-value-types/#timeduration
const durationConversionList = [
  { unit: 'ms', format: 'ms', base: 1 },
  { unit: 'hs', format: 'hs', base: 10 },
  { unit: 'ts', format: 'ts', base: 100 },
  { unit: 's', format: 'ss', base: 1000 },
  { unit: 'min', format: 'mm', base: 60000 },
  { unit: 'h', format: 'hh', base: 3600000 },
  { unit: 'd', format: 'dd', base: 86400000 }
];

export const durationString = (
  value: number | undefined,
  unit: TimeDurationUnit = 'ms',
  format: TimeDurationFormat = 'ms'
): string => {
  let output = String(format);
  let normalizedValue =
    (value ?? 0) * (durationConversionList.find(item => item.unit === unit)?.base ?? 1);
  for (const pattern of format.replace('.', ':').split(':')) {
    const base = durationConversionList.find(item => item.format === pattern)?.base ?? 1;
    const result = Math.floor(normalizedValue / base);
    normalizedValue = normalizedValue % base;
    output = output.replace(pattern, String(result).padStart(2, '0'));
  }
  return output;
};
