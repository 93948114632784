import { Injectable } from '@angular/core';
import { ConnectionState, EventSoundProxyServiceBase, EventSoundWsi, ResoundCategory, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject, throwError } from 'rxjs';

@Injectable()
export class EventSoundBxSubstituteProxyService extends EventSoundProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _soundEvents: Subject<EventSoundWsi> = new Subject<EventSoundWsi>();

  public constructor(
    private readonly traceService: TraceService) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered. There is no constant streaming channel.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.traceService.info(TraceModules.eventsSound, 'EventSoundBxSubstituteProxyService created.');
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public getCurrentSound(): Observable<EventSoundWsi> {
    this.traceService.info(TraceModules.eventsSound, 'EventSoundBxSubstituteProxyService.getCurrentSound() called.');

    return throwError(() => new Error('EventSoundBxSubstituteProxyService.getCurrentSound(): Not Implemented!'));
  }

  public subscribeEventSound(disableCategories?: string[], resoundData?: ResoundCategory[]): Observable<boolean> {
    this.traceService.info(TraceModules.eventsSound, 'EventSoundBxSubstituteProxyService.subscribeEventSound() called.');

    return scheduled([true], asapScheduler);
  }

  public unSubscribeEventSound(): Observable<boolean> {
    this.traceService.info(TraceModules.eventsSound, 'EventSoundBxSubstituteProxyService.unSubscribeEventSound() called');

    return scheduled([true], asapScheduler);
  }

  public resetResoundTimer(): Observable<boolean> {
    this.traceService.info(TraceModules.eventsSound, 'EventSoundBxSubstituteProxyService.resetResoundTimer() called');

    return throwError(() => new Error('EventSoundBxSubstituteProxyService.resetResoundTimer(): Not Implemented!'));
  }

  public eventSoundNotification(): Observable<EventSoundWsi> {
    return this._soundEvents.asObservable();
  }
}
