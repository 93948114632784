import { CdkPortalOutlet, PortalModule } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  BOOTSTRAP_BREAKPOINTS,
  MenuItem,
  ModalRef,
  SiActionDialogService,
  SiContentActionBarComponent,
  SiMainDetailContainerComponent,
  SiModalService,
  SiTranslateService,
  ViewType
} from '@simpl/element-ng';
import { PriorityArrayItem, ValueBase } from '@simpl/element-value-types';
import { clone, focusDialogContent, getNextModalId } from '@simpl/object-browser-ng/common';
import {
  AnyProperty,
  CommandEvent,
  DisplayMode,
  PropertyCommand,
  PropertyInfoData
} from '@simpl/object-browser-ng/property-viewer';
import { Subscription } from 'rxjs';

import { getPropertyId, setPropertyId } from '../../helpers/default-models';
import {
  checkSelectedCommandDisabled,
  enableDisableProperty,
  getCommandValue,
  getDataPointFullName,
  getExecutionSettingValues,
  setReadOnlyProperties,
  updateCollectionPropertyValueInDataPointProperties,
  updateEnableDisableContentAction,
  updatePrioArrayInUI,
  updatePrioArrayItems,
  updatePropertyValue
} from '../../helpers/scene-property-command-helper';
import { DataPoint, Scene, SceneCommand, SceneModel } from '../../interfaces/scene-editor';
import { SiSceneEditorConfigService, SiSceneEditorService } from '../../services/index';
import { SiCommandListComponent } from '../si-command-list/si-command-list.component';
import {
  ConfigureDataPointDialogResult,
  SiConfigureModelComponent
} from '../si-configure-model/si-configure-model.component';
import {
  AddDataPointDialogResult,
  SiDatapointViewComponent
} from '../si-datapoint-view/si-datapoint-view.component';
import { SiScenePropertiesComponent } from '../si-scene-properties/si-scene-properties.component';

export interface AdvancedCommandParameter {
  property: AnyProperty;
  command: PropertyCommand;
}

@Component({
  selector: 'si-scene-master-detail',
  templateUrl: './si-scene-master-detail.component.html',
  styleUrls: ['./si-scene-master-detail.component.scss'],
  standalone: true,
  imports: [
    PortalModule,
    SiCommandListComponent,
    SiConfigureModelComponent,
    SiContentActionBarComponent,
    SiDatapointViewComponent,
    SiMainDetailContainerComponent,
    SiScenePropertiesComponent
  ]
})
export class SiSceneMasterDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() objectId!: string;
  @Input() description?: string;
  @Input() sceneModel!: SceneModel;
  @Input() readOnly = false;
  @Input() selectedScene!: Scene;
  @Input() displayMode!: DisplayMode;

  @Output() readonly isProcessing = new EventEmitter<boolean>();

  @ViewChild('dataPointViewModalContent', { static: true, read: TemplateRef })
  dataPointViewModalContent!: TemplateRef<any>;
  @ViewChild('configureModalContent', { static: true, read: TemplateRef })
  configureModalContent!: TemplateRef<any>;
  @ViewChild('commandListViewer') commandListViewer!: SiCommandListComponent;
  @ViewChild('portalOutlet', { read: CdkPortalOutlet, static: false })
  portalOutlet!: CdkPortalOutlet;

  hasChanges = false;
  newDataPoints: DataPoint[] = [];
  newSceneDataPoints?: SceneCommand[] = [];
  modalRef?: ModalRef;
  modalTitleId = '';
  subscription?: Subscription;
  sceneSubscription?: Subscription;
  readonly viewType: ViewType = 'expanded';

  propertyCommands: AnyProperty[] = [];
  sceneDataPointsListforSelectedScene: SceneCommand[] = [];
  private defaultProperty = new Map<string, [string, AnyProperty]>();
  private changedCommand = new Map<string, [string, AnyProperty]>();
  selectedDataPoint?: DataPoint;
  sceneCommands: SceneCommand[] = [];
  sceneList: Scene[] = [];

  readonly propertyContentActions: PropertyCommand[] = [
    {
      id: 'enable-disable',
      title: '',
      action: property => this.enableDisableDataPoint(property)
    },
    { title: '-' },
    {
      id: 'configure-data-point',
      title: 'SCENE_EDITOR.TEXT_SHORT.CONFIGURE_DATA_POINT',
      action: property => this.configureDataPoint(property)
    },
    { title: '-' },
    {
      id: 'about-data-point',
      title: 'SCENE_EDITOR.TEXT_SHORT.ABOUT_DATA_POINT',
      action: property => this.aboutDataPoint(property)
    },
    {
      id: 'navigate-to',
      title: 'SCENE_EDITOR.TEXT_SHORT.NAVIGATE_TO',
      action: property => this.navigateToDataPoint(property)
    }
  ];

  additionalPrimaryActions: MenuItem[] = [
    {
      id: 'add-data-point',
      title: 'SCENE_EDITOR.TEXT_SHORT.ADD_DATAPOINT',
      action: () => this.addNewDataPoint()
    }
  ];

  readonly primaryActions: MenuItem[] = [
    {
      id: 'add',
      title: 'SCENE_EDITOR.TEXT_SHORT.ADD',
      items: this.additionalPrimaryActions,
      icon: 'element-plus'
    }
  ];

  secondaryActions: MenuItem[] = [
    {
      id: 'save',
      title: 'SCENE_EDITOR.TEXT_SHORT.SAVE_SCENE',
      action: () => this.saveSceneModel(),
      disabled: !this.hasChanges
    },
    {
      id: 'saveAs',
      title: 'SCENE_EDITOR.TEXT_SHORT.SAVE_AS_SCENE',
      action: () => this.saveSceneModelAs()
    },
    {
      id: 'discard',
      title: 'SCENE_EDITOR.TEXT_SHORT.DISCARD',
      action: () => this.discardChanges(),
      disabled: !this.hasChanges
    },
    { title: '-' },
    {
      id: 'delete',
      title: 'SCENE_EDITOR.TEXT_SHORT.DELETE_SCHEDULE_OBJECT',
      action: () => this.deleteSceneModel()
    }
  ];

  /**
   * Master detail container
   */
  largeLayoutBreakpoint = BOOTSTRAP_BREAKPOINTS.mdMinimum; // this is the default
  detailsActive = false;
  isLoading = 0;
  rows: Scene[] = [];
  selectedEntity: Scene | undefined;
  selectedEntities: Scene[] = [];
  masterData: Scene[] = [];

  private sceneService = inject(SiSceneEditorService);
  private sceneConfigService = inject(SiSceneEditorConfigService);
  private modalService = inject(SiModalService);
  public siModal = inject(SiActionDialogService);
  private translateService = inject(SiTranslateService);

  ngOnChanges(): void {
    this.addExecuteSettingAction();
    this.setActiveItem();
    this.sceneService.isProcessing$.subscribe(isProcessing => this.isProcessing.emit(isProcessing));
    this.secondaryActions
      .filter(item => item.id === 'delete')
      .forEach(item => (item.disabled = this.sceneModel.isNew));
    this.updateDataModel();
  }

  ngOnInit(): void {
    this.updateDataModel();
    this.setContentActions();
    this.sceneSubscription = this.sceneService.propertyInfo$.subscribe(selectPropertyInfo => {
      if (selectPropertyInfo.dataPointInfo && selectPropertyInfo.dataPointProperty) {
        const propInfo: PropertyInfoData = {
          property: selectPropertyInfo.dataPointProperty,
          propertyInfo: selectPropertyInfo.dataPointInfo
        };
        this.commandListViewer.displayAboutPopover(propInfo);
      }
    });
    this.subscription = this.sceneService.hasChanges$.subscribe(hasChanges => {
      this.hasChanges = hasChanges;
      this.secondaryActions
        .filter(item => item.id === 'save' || item.id === 'saveAs' || item.id === 'discard')
        .forEach(item => (item.disabled = !hasChanges));
    });
    this.secondaryActions
      .filter(item => item.id === 'delete')
      .forEach(item => (item.disabled = this.sceneModel.isNew));
    this.addSortAction();
    this.addExecuteSettingAction();
    this.setActiveItem();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.modalRef?.hide();
    this.sceneSubscription?.unsubscribe();
  }

  addSortAction(): void {
    if (!this.sceneConfigService.get().actionToDisable.sort) {
      this.secondaryActions.unshift({ title: '-' });
      this.secondaryActions.unshift({
        title: 'SCENE_EDITOR.TEXT_SHORT.SORT_SCENE'
      });
    }
  }

  setContentActions(): void {
    if (!this.sceneConfigService.get().restrictSceneEdit) {
      this.additionalPrimaryActions.unshift({
        id: 'add-scene',
        title: 'SCENE_EDITOR.TEXT_SHORT.ADD_SCENE',
        action: () => this.addNewScene()
      });
    }
    if (!this.sceneConfigService.get().actionToDisable.advanced) {
      this.propertyContentActions.splice(3, 0, {
        id: 'advanced-commands',
        title: 'SCENE_EDITOR.TEXT_SHORT.ADVANCED_COMMANDS',
        disabled: true
      });
    }
  }

  addExecuteSettingAction(): void {
    if (this.sceneConfigService.get().actionToDisable.executeSettings) {
      const index = this.propertyContentActions.findIndex(w => w.id === 'execution-settings');
      if (index === -1) {
        this.propertyContentActions.splice(
          this.propertyContentActions.findIndex(w => w.id === 'enable-disable') + 1,
          0,
          {
            id: 'execution-settings',
            title: 'SCENE_EDITOR.TEXT_SHORT.EXECUTION_SETTINGS'
          }
        );
      }
    }
  }

  addNewScene(): void {
    const scene = this.sceneService.addNewScene(this.objectId);
    this.handleSelectedListItemChange(scene);
  }

  handleSelectedListItemChange(scene: Scene): void {
    if (scene) {
      this.selectedScene = scene;
      this.sceneOnSelect(this.sceneModel.scenes);
    }
    this.sceneDataPointsListforSelectedScene = this.sceneService.getCommandsForScene(
      this.objectId,
      scene.id
    );
    const datapointIds = this.sceneDataPointsListforSelectedScene.map(result => result.dataPointId);
    this.propertyCommands = [];
    const dataPoints = this.sceneService.getDataPoints(this.objectId, datapointIds);
    dataPoints.forEach(datapoint => {
      const sceneCommand = this.sceneDataPointsListforSelectedScene.find(
        item => item.dataPointId === datapoint.id && item.sceneId === scene.id
      );
      const property = datapoint.properties?.find(prop => prop.id === datapoint.selectedPropertyId);
      if (sceneCommand) {
        let selectedProperty: AnyProperty;
        if (property) {
          this.defaultProperty.set(datapoint.id, [this.selectedScene.id, clone(property)]);
          selectedProperty = clone(this.updateProperty(sceneCommand, clone(property)));
        } else {
          const errorProperty = this.getInValidProperty(datapoint, sceneCommand);
          selectedProperty = clone(errorProperty);
        }
        this.propertyCommands.push(selectedProperty);
      }
    });
  }

  private getInValidProperty(datapoint: DataPoint, sceneCommand: SceneCommand): AnyProperty {
    const actions: PropertyCommand[] = [];
    const dataPointName = this.translateService.translateSync('SCENE_EDITOR.TEXT_SHORT.UNKNOWN');
    const configureDpAction = this.propertyContentActions.find(
      obj => obj.id === 'configure-data-point'
    );
    if (configureDpAction) {
      actions.push(configureDpAction);
    }

    const errorProperty: AnyProperty = {
      id: datapoint.id,
      name: datapoint.name && datapoint.name !== '' ? datapoint.name : dataPointName,
      value: {
        type: 'string',
        value: sceneCommand.value,
        readonly: true
      },
      actions
    };
    return errorProperty;
  }

  addNewDataPoint(): void {
    this.sceneService.addDataPoint(this.objectId).subscribe(newDataPoints => {
      const newlyAddedDp = this.sceneService.generateUniqueDataPoints(this.objectId, newDataPoints);
      if (newlyAddedDp) {
        this.newDataPoints = newlyAddedDp;
        const id = getNextModalId();
        this.modalTitleId = id.titleId;
        this.modalRef = this.modalService.show(this.dataPointViewModalContent, {
          class: 'modal-dialog-centered modal-lg',
          ariaLabelledBy: this.modalTitleId,
          ignoreBackdropClick: true
        });
        focusDialogContent(this.modalRef, false);
      } else {
        this.newDataPoints = [];
      }
    });
  }

  discardChanges(): void {
    this.sceneService.discardChanges(this.objectId);
  }

  saveSceneModel(): void {
    this.sceneService.saveSceneModel(this.objectId).subscribe();
  }

  saveSceneModelAs(): void {
    this.sceneService.saveSceneModelAs(this.objectId).subscribe();
  }

  deleteSceneModel(): void {
    this.sceneService.deleteSceneModel(this.objectId, this.description).subscribe();
  }

  handleDialogClose(event: AddDataPointDialogResult): void {
    this.updateBusyState(true);
    if (event.result === 'ok') {
      this.handleNewlyAddedDataPoints(event.data);
    } else {
      this.handleCancelAddedDataPoints();
    }
    this.handleSelectedListItemChange(this.selectedScene);
    this.updateBusyState(false);
    this.modalRef?.hide();
    this.modalRef = undefined;
    this.newDataPoints = [];
  }

  private updateBusyState(isBusy: boolean): void {
    this.sceneService.setReadOnly(this.objectId, isBusy);
    this.isProcessing.emit(isBusy);
  }

  private handleNewlyAddedDataPoints(event: any): void {
    if (event?.newDataPoints && event?.newSceneDataPoints) {
      this.sceneModel.dataPoints.push(...event.newDataPoints);
      this.sceneModel.commands.push(...event.newSceneDataPoints);
      this.sceneService.setModel(this.objectId, this.sceneModel);
      this.sceneService.setIsDirty(true);
    }
  }

  private handleCancelAddedDataPoints(): void {
    this.newDataPoints = [];
    this.newSceneDataPoints = [];
  }

  handleRemoveScene(event: Scene): void {
    let index = this.sceneModel.scenes.findIndex(object => object.id === event.id);
    if (index !== -1 && this.sceneModel.scenes.length > 1) {
      this.sceneService.removeScene(this.objectId, event);
      // showing previous object in case of last
      index = index === this.sceneModel.scenes.length ? index - 1 : index;
      this.handleSelectedListItemChange(this.sceneModel.scenes[index]);
    }
  }

  private setActiveItem(): void {
    if (this.sceneModel.scenes?.length && this.sceneModel.scenes[0]) {
      this.selectedScene = this.sceneModel.scenes[0];
    }
  }

  sceneOnSelect(items: Scene[]): void {
    this.selectedEntities = [...items];
    this.selectedEntity = this.selectedEntities[0];
    this.detailsActive = true;
  }

  propertyChanged(eventProperty: AnyProperty): void {
    if (eventProperty?.id) {
      const sceneCommand = this.getSceneCommand(eventProperty);
      if (sceneCommand) {
        sceneCommand.value = getCommandValue(eventProperty);
        if (eventProperty.value.type === 'collection') {
          const datapoints = this.sceneService.getDataPoints(this.objectId, [
            sceneCommand.dataPointId
          ]);
          const datapoint = updateCollectionPropertyValueInDataPointProperties(
            datapoints[0],
            eventProperty
          );
          const prop = this.propertyCommands.find(p => p.id === eventProperty.id);
          if (prop) {
            updatePrioArrayInUI(datapoint, prop);
          }
          this.sceneService.updatePropertyValueInModel(this.objectId, [sceneCommand], [datapoint]);
        } else {
          this.sceneService.updatePropertyValueInModel(this.objectId, [sceneCommand]);
        }
        this.sceneService.setIsDirty(true);
      }
    }
  }

  getSceneCommand(property: AnyProperty): SceneCommand | undefined {
    if (property?.id) {
      const datapointId = getPropertyId(property.id);
      return this.sceneDataPointsListforSelectedScene.find(
        command => command.sceneId === this.selectedScene.id && command.dataPointId === datapointId
      );
    }
    return undefined;
  }

  commandSelected(event: CommandEvent): void {
    const property = event.property as AnyProperty;
    const command = event.command;
    const sceneCommand = this.getSceneCommand(property);
    if (sceneCommand) {
      if (command === 'execution-settings') {
        // set value for execution settings of scene command of selected property
        this.updateExecutionSettingValues(event, sceneCommand, property);
      } else {
        // set value of advance command selected for scene command of selected property
        this.updateSceneCommandValue(sceneCommand, command, property, event);
      }
      this.sceneService.setIsDirty(true);
    }
  }

  private updateSceneCommandValue(
    sceneCommand: SceneCommand,
    command: string,
    property: AnyProperty,
    event: CommandEvent
  ): void {
    if (sceneCommand.selectedCommand === command) {
      sceneCommand.value = getCommandValue(property, event);
      this.updateProperty(sceneCommand, property, true);
    } else {
      sceneCommand.selectedCommand = command;
      sceneCommand.value = undefined;
      this.updateProperty(sceneCommand, property, true);
      sceneCommand.value = getCommandValue(property, event);
      this.getSceneCommandValueForPriorityProperty(property, sceneCommand, event.property);
    }
    this.changedCommand.set(sceneCommand.dataPointId, [this.selectedScene.id, clone(property)]);
  }

  private updateExecutionSettingValues(
    event: CommandEvent,
    sceneCommand: SceneCommand,
    property: AnyProperty
  ): void {
    getExecutionSettingValues(event, sceneCommand);
    this.updateProperty(sceneCommand, property, false);
  }

  private updateProperty(
    command: SceneCommand,
    property: AnyProperty,
    isCommandChange?: boolean
  ): AnyProperty {
    if (property.id) {
      property.id = setPropertyId(command.dataPointId, property.id);
    }

    const datapoint = this.sceneService.getDataPoints(this.objectId, [command.dataPointId]);

    const advancedCommandSelected = datapoint[0]?.properties
      ?.find(prop => prop.id === datapoint[0].selectedPropertyId)
      ?.actions?.find(act => act.action === command.selectedCommand && !act.isActive);

    updatePropertyValue(
      datapoint[0],
      command,
      property,
      this.propertyContentActions,
      command.selectedCommand,
      isCommandChange
    );
    updateEnableDisableContentAction(property, command);

    if (command.isEnabled && !advancedCommandSelected) {
      setReadOnlyProperties(property, this.sceneModel.readonly);
    }
    property.name = clone(getDataPointFullName(datapoint[0], property, this.displayMode));
    return property;
  }

  private enableDisableDataPoint(property: AnyProperty): void {
    const sceneCommand = this.getSceneCommand(property);
    if (sceneCommand) {
      // else condition value can be undefined
      const id = property?.id ? getPropertyId(property.id) : property?.id;
      if (
        id &&
        !this.changedCommand?.has(id) &&
        checkSelectedCommandDisabled(property, sceneCommand)
      ) {
        // not changed command
        // setting change command when it is disabled and non active
        this.changedCommand.set(sceneCommand.dataPointId, [this.selectedScene.id, clone(property)]);
      }

      enableDisableProperty(
        property,
        this.selectedScene.id,
        this.defaultProperty,
        this.changedCommand,
        sceneCommand
      );
      updateEnableDisableContentAction(property, sceneCommand);
      this.sceneService.updatePropertyValueInModel(this.objectId, [sceneCommand]);
      this.sceneService.setIsDirty(true);
    }
  }

  configureDataPoint(property: AnyProperty): void {
    if (property?.id) {
      const datapointId = getPropertyId(property.id);
      const dataPoints = this.sceneService.getDataPoints(this.objectId, [datapointId]);
      if (dataPoints?.length) {
        this.selectedDataPoint = dataPoints[0];
        this.sceneCommands = this.sceneService
          .getCommandsForDataPoint(this.objectId, this.selectedDataPoint.id)
          .map(obj => clone(obj));

        const sceneIds = this.sceneCommands
          .map(item => item.sceneId)
          .filter((value, index, self) => self.indexOf(value) === index);
        this.sceneList = this.sceneService.getScenes(this.objectId, sceneIds);

        const id = getNextModalId();
        this.modalTitleId = id.titleId;
        this.modalRef = this.modalService.show(this.configureModalContent, {
          class: 'modal-dialog-centered modal-lg',
          ariaLabelledBy: this.modalTitleId,
          ignoreBackdropClick: true
        });
        focusDialogContent(this.modalRef, false);
      }
    }
  }

  private getSceneCommandValueForPriorityProperty(
    property: AnyProperty,
    sceneCommand: SceneCommand,
    eventProperty: AnyProperty
  ): void {
    if (property.value.type === 'collection' && property.value.kind === 'priority-array') {
      const advanceAction = property.actions?.find(action => action.id === 'advanced-commands');
      const inActiveCommand = advanceAction?.items?.find(item => !item.isActive);
      if (sceneCommand.selectedCommand !== inActiveCommand?.id) {
        property.value.value = eventProperty.value.value as PriorityArrayItem<ValueBase>[];
        const newVal = property.value.value[0] as PriorityArrayItem<ValueBase>;
        updatePrioArrayItems(newVal, eventProperty.value.value as PriorityArrayItem<ValueBase>[]);
        const prop = this.propertyCommands.find(p => p.id === eventProperty.id);
        if (prop) {
          prop.value.value = property.value.value;
        }
        sceneCommand.value = clone([newVal]);
      }
    }
  }

  aboutDataPoint(property: AnyProperty): void {
    if (property.id) {
      this.sceneService.aboutDataPoint({
        dataPointId: getPropertyId(property.id),
        dataPointProperty: property
      });
    }
  }

  navigateToDataPoint(property: AnyProperty): void {
    if (property.id) {
      this.sceneService.navigateToDataPoint({
        dataPointId: getPropertyId(property.id),
        dataPointProperty: property
      });
    }
  }

  handleDPDialogClose(event: ConfigureDataPointDialogResult): void {
    if (event.result === 'ok') {
      this.handleOkConfigureDataPoint(event);
      this.handleSelectedListItemChange(this.selectedScene);
    } else {
      this.sceneCommands = [];
      this.sceneList = [];
      this.selectedDataPoint = undefined;
    }
    this.modalRef?.hide();
    this.modalRef = undefined;
  }

  handleOkConfigureDataPoint(event: ConfigureDataPointDialogResult): void {
    if (event.data?.isDeleted && event.data?.selectedDataPoint) {
      this.sceneService.removeDataPoint(this.objectId, event.data?.selectedDataPoint);
    } else if (event.data) {
      if (this.selectedDataPoint) {
        if (event.data?.selectedProperty?.id) {
          this.selectedDataPoint.selectedPropertyId = event.data.selectedProperty.id;
          this.selectedDataPoint.properties?.push(event.data.selectedProperty);
          this.sceneService.setIsDirty(true);
        }
        if (event.data?.selectedDataPoint) {
          this.selectedDataPoint.userDefinedName = event.data.selectedDataPoint?.userDefinedName;
          const uiItem = this.propertyCommands.find(prop => prop.id === this.selectedDataPoint?.id);
          if (uiItem) {
            uiItem.name = getDataPointFullName(this.selectedDataPoint);
          }
          this.sceneService.setIsDirty(true);
        }
      }

      if (event.data.sceneCommands?.length && event.data?.selectedDataPoint) {
        this.sceneService.updatePropertyValueInModel(this.objectId, event.data?.sceneCommands, [
          event.data.selectedDataPoint
        ]);
        this.sceneService.setIsDirty(true);
      }
    }
  }

  updateDataModel(): void {
    if (this.selectedScene) {
      this.handleSelectedListItemChange(this.selectedScene);
    }
  }

  addingDataPoint(): void {
    this.addNewDataPoint();
  }

  trackBySceneId(index: number, scene: Scene): string {
    return scene.id;
  }
}
