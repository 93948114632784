import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'si-formly-object',
  templateUrl: './si-formly-object.component.html',
  standalone: true,
  imports: [FormlyModule]
})
export class SiFormlyObjectComponent extends FieldType {}
