import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import { SiDropdownDirective } from './si-dropdown.directive';

/**
 * @deprecated The {@link SiDropdownMenuDirective} and its related directives should no longer be used.
 * - For creating menus, use {@link SiMenuModule} instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating plain overlays, use the {@link https://material.angular.io/cdk/overlay/overview CDK Overlay}: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/dropdowns/
 */
@Directive({
  selector: '[siDropdownMenu]',
  exportAs: 'si-dropdown-menu',
  host: {
    class: 'dropdown-menu'
  },
  standalone: true
})
export class SiDropdownMenuDirective implements OnDestroy, OnInit {
  content: HTMLElement = inject(ElementRef).nativeElement;
  parentElement!: HTMLElement;
  private siDropdownDirective = inject(SiDropdownDirective);

  /**
   * Whether the dropdown has previously been opened.
   */
  dropdownOpened = false;

  @HostBinding('class.show') dropdownOpen = false;
  @HostBinding('class.align-start') alignStart = true;
  @HostBinding('class.align-end') alignEnd = false;
  @HostBinding('class.align-center') alignCenter = false;

  @Input() @HostBinding('tabindex') protected tabindex = -1;

  ngOnInit(): void {
    this.siDropdownDirective.addMenu(this);
    this.parentElement = this.content.parentElement!;
  }

  ngOnDestroy(): void {
    this.siDropdownDirective.removeMenu(this);
    clearTimeout(this.keyboardTimeout);
  }

  updateState(state: boolean, align = ''): void {
    this.alignCenter = align === 'center';
    this.alignStart = !align || align === 'start';
    this.alignEnd = align === 'end';

    this.dropdownOpen = state;
    if (state) {
      this.dropdownOpened = true;
    }
  }

  private keyboardTimeout: any;

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  onClickOrKeydown(event: MouseEvent | KeyboardEvent, force = false): void {
    let currentTarget: HTMLElement | null = event.target as HTMLElement;
    const tag = currentTarget.tagName.toLowerCase();
    // Wait for the click caused by the enter on <button> and <a> with href, in order to prevent timing issues.
    if (
      !force &&
      event instanceof KeyboardEvent &&
      (tag === 'button' || (tag === 'a' && (currentTarget as HTMLAnchorElement).href !== undefined))
    ) {
      clearTimeout(this.keyboardTimeout);
      this.keyboardTimeout = setTimeout(() => {
        this.onClickOrKeydown(event, true);
      });
      return;
    }

    clearTimeout(this.keyboardTimeout);

    const itemSelector = this.siDropdownDirective.dropdownItemSelector;
    const itemSelectorException = this.siDropdownDirective.dropdownItemSelectorException;
    while (
      currentTarget &&
      currentTarget !== this.content &&
      (!currentTarget.matches(itemSelector) || currentTarget.matches(itemSelectorException))
    ) {
      currentTarget = currentTarget.parentElement;
    }
    if (
      currentTarget &&
      currentTarget !== this.content &&
      currentTarget.matches(itemSelector) &&
      !currentTarget.matches(itemSelectorException)
    ) {
      if (!currentTarget.classList.contains('disabled')) {
        this.siDropdownDirective.selectItem(currentTarget);
      }
    }
  }
}
