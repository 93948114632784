import { Injectable, TemplateRef } from "@angular/core";
import { GraphicsCommonTemplateServiceBase } from "@gms-flex/services";

/**
 * NOTE: This service is used to import graphics-common into events.
 * It used to be the case that circular dependencies were allowed in Angular.
 *
 * Due to Angular's partial compilation mode, that type of import is no longer possible.
 * If this compilation issue becomes resolved due to changes in Angular's partial compilation mode,
 * then this service will be removed.
 */
@Injectable({
  providedIn: 'root'
})
export class GraphicsCommonTemplateService extends GraphicsCommonTemplateServiceBase {
  public get GraphicsCommonTemplate(): TemplateRef<any> {
    return this._graphicsCommonTemplate;
  }

  public set GraphicsCommonTemplate(template: TemplateRef<any>) {
    this._graphicsCommonTemplate = template;
  }
}
