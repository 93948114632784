import * as calendarUtils from '../date-time-helper';

/**
 * Compare the dates based on the active view.
 */
export interface CompareAdapter {
  isAfter(current: Date, start: Date): boolean;
  isBetween(current: Date, from?: Date, to?: Date): boolean;
  isEqual(current: Date, other?: Date): boolean;
  isEqualOrBefore(current: Date, other: Date): boolean;
  isEqualOrBetween(current: Date, from?: Date, to?: Date): boolean;
}

/**
 * Compare dates in the month view.
 */
export class DayCompareAdapter implements CompareAdapter {
  isAfter(current: Date, start: Date): boolean {
    return calendarUtils.isAfter(current, start);
  }

  isBetween(current: Date, from?: Date | undefined, to?: Date | undefined): boolean {
    return calendarUtils.isBetween(current, from, to);
  }

  isEqual(current: Date, other: Date): boolean {
    return calendarUtils.isSameDate(current, other);
  }

  isEqualOrBefore(current: Date, other: Date): boolean {
    return calendarUtils.isSameOrBefore(current, other);
  }

  isEqualOrBetween(current: Date, from?: Date, to?: Date): boolean {
    return calendarUtils.isSameOrBetween(current, from, to);
  }
}

/**
 * Compare dates in the year view.
 */
export class MonthCompareAdapter implements CompareAdapter {
  isAfter(current: Date, start: Date): boolean {
    return calendarUtils.isAfterMonth(current, start);
  }

  isBetween(current: Date, from?: Date | undefined, to?: Date | undefined): boolean {
    return calendarUtils.isBetweenMonth(current, from, to);
  }

  isEqual(current: Date, other: Date): boolean {
    return calendarUtils.isSameMonth(current, other);
  }

  isEqualOrBefore(current: Date, other: Date): boolean {
    return calendarUtils.isSameOrBeforeMonth(current, other);
  }

  isEqualOrBetween(current: Date, from?: Date, to?: Date): boolean {
    return calendarUtils.isSameOrBetweenMonth(current, from, to);
  }
}

export class YearCompareAdapter implements CompareAdapter {
  isAfter(current: Date, other: Date): boolean {
    return calendarUtils.isAfterYear(current, other);
  }

  isBetween(current: Date, from?: Date | undefined, to?: Date | undefined): boolean {
    return calendarUtils.isBetweenYears(current, from, to);
  }

  isEqual(current: Date, other: Date): boolean {
    return calendarUtils.isSameYear(current, other);
  }

  isEqualOrBefore(current: Date, other?: Date): boolean {
    if (!other) {
      return false;
    }
    return calendarUtils.isSameOrBeforeYear(current, other);
  }

  isEqualOrBetween(current: Date, from?: Date, to?: Date): boolean {
    return calendarUtils.isSameOrBetweenYears(current, from, to);
  }
}
