import { GmsText } from '../elements/gms-text';
import { GmsDepth } from '../processor/gms-depth';
import { GmsLayerElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { SvgUtility } from '../utilities/parser';
import { GmsElement } from './gms-element';
import { GmsGraphic } from './gms-graphic';

export class GmsLayer extends GmsElement {

  private static readonly DEPTHSDELIMITER: string = ';';

  private _depthsString: string; // ';' separated depths
  public get DepthsString(): string {
    return this._depthsString;
  }
  public set DepthsString(value: string) {

    if (this._depthsString !== value) {
      this._depthsString = value;

      this._depths = this._depthsString.split(GmsLayer.DEPTHSDELIMITER).filter(depth => depth !== '');

      this.NotifyPropertyChanged('DepthsString');
    }
  }

  private _depths: string[];
  public get Depths(): string[] {
    return this._depths;
  }

  // private Discipline: string = "";
  // public get DisciplineId(): string {
  //     return this.Discipline;
  // }

  private _discipline = '';
  public get Discipline(): string {
    return this._discipline;
  }
  public set Discipline(value: string) {

    if (this._discipline !== value) {
      this._discipline = value;

      this.NotifyPropertyChanged('Discipline');
    }
  }

  public get IsLayerVisible(): string {
    const isRuntimeVisible: string = this.GetVisible();
    const graphic: any = this.Graphic as any;
    const result: boolean = this.Use && this.Visible &&
            (graphic !== undefined && graphic.SelectedDepth !== undefined && this._depths !== undefined && this._depths.includes(graphic.SelectedDepth.Name))
            && (isRuntimeVisible === 'visible' || isRuntimeVisible === 'inherit')
            && (graphic.disciplines !== undefined && graphic.selectedDisciplines !== undefined)
            && (graphic.disciplines.has(this.Discipline) === false || graphic.selectedDisciplines.has(this.Discipline));

    if (!result) {
      return 'hidden';
    }
    return 'visible'; // or "inherit"
  }

  public CalculateVisible(): boolean {
    const graphic: any = this.Graphic as any;
    const result: boolean = this.Use &&
        (graphic !== undefined && graphic.SelectedDepth !== undefined && this._depths !== undefined && this._depths.includes(graphic.SelectedDepth.Name))
        && (graphic.disciplines !== undefined && graphic.selectedDisciplines !== undefined)
        && (graphic.disciplines.has(this.Discipline) === false || graphic.selectedDisciplines.has(this.Discipline));

    return result;
  }

  public UpdateVisible(): void {
    const layerVisible: boolean = this.CalculateVisible();
    const elementVisible: boolean = super.CalculateVisible();
    this.Visible = layerVisible && elementVisible;
  }

  private _use = false;
  // For runtime only: It will be loaded if it is in Use.
  public get Use(): boolean {
    return this._use;
  }
  public set Use(value: boolean) {

    if (this._use !== value) {
      this._use = value;

      this.NotifyPropertyChanged('Use');
    }
  }

  public AddChild(element: GmsElement): void {

    this.children.push(element);

    // ChangeDetection: Let others know that a property changed
    this.NotifyPropertyChanged();
  }

  public RemoveChild(element: GmsElement): void {
    const index: number = this.children.indexOf(element, 0);
    if (index > -1) {
      this.children.splice(index, 1);

      // ChangeDetection: Let others know that a property changed
      this.NotifyPropertyChanged();
    }
  }

  constructor() {
    super(GmsElementType.Layer);

    this.children = [];
  }

  public async ShapeChanged(): Promise<any> {
    if (this.children == null) {
      return;
    }

    for (const child of this.children) {
      child.ShapeChanged();
    }

    this.NotifyPropertyChanged('ShapeChanged');
  }

  public async CnsDisplayLabelChanged(): Promise<any> {
    const elements: GmsElement[] = this.GetAll(true);
    const length: number = elements === undefined ? 0 : elements.length;
    for (let i = 0; i < length; i++) {
      const element: GmsText = elements[i] as GmsText;
      if (element !== undefined) {
        element.UpdateText();
      }
    }
  }

  public readDiscipline(result: string): string {
    if (result === undefined) {
      return '_0';
    }

    if (result !== '_') {
      return result;
    }

    return undefined;
  }

  /**
   * Iterate attributes and assign values to properties
   * @param node
   */
  public Deserialize(node: Node): void {
    // Use
    let result: string = SvgUtility.GetAttributeValue(node, GmsLayerElementPropertyType.Use);
    if (result !== undefined) {
      this.Use = Boolean(result);
    }

    // Discipline
    result = SvgUtility.GetAttributeValue(node, GmsLayerElementPropertyType.Discipline);
    this.Discipline = this.readDiscipline(result);

    const graphic: GmsGraphic = this.Graphic as any;
    if (this.Discipline !== undefined
            && graphic !== undefined
            && graphic.disciplines !== undefined
            && graphic.selectedDisciplines !== undefined
            && this.Use) {
      graphic.disciplines.add(this.Discipline);

      if (graphic.stateRestored === false) {
        graphic.selectedDisciplines.add(this.Discipline);
      }
    }

    // Depths
    result = SvgUtility.GetAttributeValue(node, GmsLayerElementPropertyType.Depths);
    if (result !== undefined) {
      this.DepthsString = result;
    }

    super.Deserialize(node);

    this.DeserializeEvaluations(node);
  }

  public Destroy(): void {

    if (this.children !== undefined) {
      const itemsToDestroy: GmsElement[] = this.children.slice(); // Copies the array, since destroy changes the source array
      itemsToDestroy.forEach(child => {
        child.Destroy();
      });
      itemsToDestroy.length = 0;
    }
    this.children.length = 0;

    super.Destroy();
  }

  public UpdateLayerVisibility(): void {
    this.UpdateVisible();
    this.NotifyPropertyChanged('IsLayerVisible');
  }

  public async UpdateAlarmState(): Promise<void> {
    // Do nothing, Layers won't show alarms
  }

  private getDisciplineFromId(result: string): string {
    const graphic: any = this.Graphic as any;
    if (graphic !== undefined) {
      const disciplineFromIdMap: Map<string, string> = graphic.disciplineFromIdMap;
      if (disciplineFromIdMap !== undefined && disciplineFromIdMap.has(result)) {
        return disciplineFromIdMap.get(result);
      }
    }

    return undefined;
  }
}
