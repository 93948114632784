import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * Data interface to persist the ui state of a split and split part.
 *
 * @see SiSplitComponent
 * @see SiSplitPartComponent
 */
export interface SplitPartState {
  /**
   * The split part size in percent of the split, after the user resized
   * a split part. Will be used to restore the split after reload.
   */
  size: number;
  /**
   * When resizing a split part, we also store the initial split size from the input API
   * `sizes` of the split. On reload, we restore the split part size when the initial input `sizes`
   * did not changed. A changed `sizes` input property has higher importance than the store size.
   */
  initialSize: number;
  /**
   * Determines if a split part is expanded or collapsed.
   */
  expanded: boolean;
}

/**
 * A UIState object holds the states we can persist and relates them to a contextId. The contextId
 * represents a component like a vertical navigation or a page and ensures that state objects to not
 * accidentally overwrite each other.
 */
export interface UIState {
  /**
   * Optional context id to make sure not to overwrite the state object of a different context.
   * When `undefined`, every `save()` overwrites the previous state with `undefined` context id.
   */
  contextId?: string;
  /**
   * A map of stateIds from expandable components (e.g. menu entries with sub items in a vertical navigation)
   * to their expanded state (e.g. expanded is `true`, otherwise false).
   */
  expandables?: { [stateId: string]: boolean };
  /**
   * A map of stateIds of `SiSplitPartComponent`s to their persisted ui state.
   */
  splitParts?: { [stateId: string]: SplitPartState };
}

/**
 * The object is used to hold the different UIState objects and is used to write all in one call to the
 * store like localStorage or sessionStorage.
 */
export interface UIStateStorage {
  /**
   * State object that has no contextId.
   */
  default?: UIState;
  /**
   * Map from context ids to their state object.
   */
  contexts?: { [contextId: string]: UIState };
}

export const SI_UI_STATES_STORAGE_KEY = 'si-ui-states';

/**
 * Service to save and load `UIState` objects. UI state objects contain a context id, which are used
 * as storage keys and prevents unwanted overwrites.
 * To enable the service,
 *
 * - add it to the `providers` declaration in your app module and
 * - set unique `stateId`s to the components (e.g. vertical navbar menu items or splits).
 *
  ```ts
  import { SiUIStateService } from '@simpl/element-ng';
  :
  providers: [
    { provide: SiUIStateService }
   ]
  ```
 *
 */
@Injectable()
export class SiUIStateService {
  /**
   * The storage used persist the ui states. Default is localStorage, but you can also use
   * sessionStorage or implement your own.
   */
  storage?: Storage = isPlatformBrowser(inject(PLATFORM_ID)) ? localStorage : undefined;

  /**
   * Saves the provided state in the storage.
   *
   * @param state The state to be save. state with context ids are preferred to prevent unwanted overwrites.
   * @returns True if all goes well as an observable for potential asynchronous remote storage implementations.
   */
  save(state: UIState): Observable<boolean> {
    const store = this.loadStore();
    if (state.contextId) {
      if (!store.contexts) {
        store.contexts = {};
      }
      store.contexts[state.contextId] = state;
    } else {
      store.default = state;
    }

    this.saveStore(store);
    return of(true);
  }

  /**
   * Loads and returns the UIState for the given context id.
   * @param contextId The optional but recommended context id for which the UI state object is returned.
   * @returns The UI state object or undefined, wrapped within an observable to support asynchronous
   * remote storage implementations.
   */
  load(contextId?: string): Observable<UIState | undefined> {
    const store = this.loadStore();
    if (contextId) {
      return of(store.contexts?.[contextId]);
    }
    return of(store.default);
  }

  private loadStore(): UIStateStorage {
    const storeStr = this.storage?.getItem(SI_UI_STATES_STORAGE_KEY);
    if (storeStr) {
      return JSON.parse(storeStr) as UIStateStorage;
    }
    return {
      default: undefined,
      contexts: {}
    };
  }

  private saveStore(store: UIStateStorage): void {
    this.storage?.setItem(SI_UI_STATES_STORAGE_KEY, JSON.stringify(store));
  }
}
