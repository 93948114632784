import { Constants } from '../../utilities/constants';
import { FormatHelper } from '../../utilities/format-helper';
import { GmsCommand } from '../command/gms-command';
import { CommandParameter, ParameterType } from '../command/parameters/gms-base-parameter';
import { NumericLongParameter, NumericParameter } from '../command/parameters/gms-numeric-parameter';
import { ParameterViewModelBase } from './gms-parameter-vm.base';

export class ParameterNumericViewModel extends ParameterViewModelBase {
  constructor(parameter: CommandParameter) {
    super(parameter);

    let numericParameter: NumericParameter;
    if (parameter == null) {
      // Create a empty parameter that is used for design mode
      numericParameter = new NumericParameter(ParameterViewModelBase.designModeParameterName, 0, '', '0.0', '0.0', 0,
        ParameterViewModelBase.designModeParameterDataType);
    } else {

      numericParameter = parameter as NumericParameter;
    }
    this.ParameterType = numericParameter.ParameterType;

    this.precision = numericParameter !== null ? numericParameter.Precision : ParameterViewModelBase.defaultPrecision;
    this.minValue = numericParameter !== null ? Number(numericParameter.MinValue) : ParameterViewModelBase.defaultModelMinimum;
    this.maxValue = numericParameter !== null ? Number(numericParameter.MaxValue) : ParameterViewModelBase.defaultModelMaximum;

    if (numericParameter !== null && numericParameter.ParameterType !== ParameterType.Numeric) {

      const numericLongParameter: NumericLongParameter = parameter as NumericLongParameter;

      this.minValueLong = numericLongParameter !== null ? numericLongParameter.MinValueLong : undefined;

      this.maxValueLong = numericLongParameter !== null ? numericLongParameter.MaxValueLong : undefined;

    }
    this.dataType = numericParameter !== null ? numericParameter.DataType : '';
    if (numericParameter.DefaultValue === undefined) {
      numericParameter.DefaultValue = '0';
    }
    this.defaultValue = numericParameter !== null ? numericParameter.DefaultValue : Constants.GENERAL_ERROR;
    // this.Value = this.defaultValue;
  }

  public getModelMaximum(command: GmsCommand): number {
    let res: number = ParameterViewModelBase.defaultModelMaximum;
    if (command !== undefined) {
      command.Parameters.forEach(parameter => {
        if (parameter.Name === this.name) {
          res = parameter.MaxValue;
        }
      });
    }
    return res;
  }

  public getModelMinimum(command: GmsCommand): number {
    let res: number = ParameterViewModelBase.defaultModelMinimum;
    if (command !== undefined) {
      command.Parameters.forEach(parameter => {
        if (parameter.Name === this.name) {
          res = parameter.MinValue;
        }
      });
    }
    return res;
  }

  public getModelMaximumLong(command: GmsCommand): Long {
    let res: Long;
    if (command !== undefined) {
      command.Parameters.forEach(parameter => {
        if (parameter.Name === this.name) {
          res = parameter.MaxValueLong;
        }
      });
    }
    return res;
  }

  public getModelMinimumLong(command: GmsCommand): Long {
    let res: Long;
    if (command !== undefined) {
      command.Parameters.forEach(parameter => {
        if (parameter.Name === this.name) {
          res = parameter.MinValueLong;
        }
      });
    }
    return res;
  }

  public isInputValid(locale: string): boolean {
    let res = true;
    // parameter hardcoded - no input to validate -> return true
    if (this.FormattedValue !== undefined) {
      if (this.ParameterType === ParameterType.Numeric) {
        const formattedValue: number = FormatHelper.getNumberfromString(this.FormattedValue, locale);
        res = !Number.isNaN(formattedValue) && formattedValue >= this.minValue && formattedValue <= this.maxValue;
        this.errorStatus = (res) ? undefined : `Value must be greater than or equal to ${this.minValue} and less than or equal to ${this.maxValue}`;
      } else {
        const formattedValueLong: Long = FormatHelper.parseLongValue(this.FormattedValue, this.ParameterType === ParameterType.NumericUInt64);
        res = formattedValueLong !== undefined && formattedValueLong
          .greaterThanOrEqual(this.minValueLong) && formattedValueLong.lessThanOrEqual(this.maxValueLong);
        this.errorStatus = (res) ? undefined : `Value must be greater than or equal to ${this.minValueLong} and less than or equal to ${this.maxValueLong}`;
      }
    }
    return res;
  }

  protected BuildFormattedValue(value: string): string {
    let result: string = value;
    switch (this.ParameterType) {
      case ParameterType.Numeric:
        const num: number = FormatHelper.getNumberfromString(value, FormatHelper.enUS);
        if (!Number.isNaN(num)) {
          result = FormatHelper.NumberToString(num,
            this.locale,
            this.defaultlocale,
            this.precision);
        }
        break;
        // case ParameterType.NumericInt64:
        //    break;
      default:
        break;
    }
    return result;
  }
}
