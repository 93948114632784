/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { OneTrustCookieTypes, Partition, SiCookiePolicyService, SiCookiesService } from '@building-x/common-ui-ng';
import { Customer } from '@building-x/common-ui-ng/uo-vertical/models/customer.model';
import { BehaviorSubject } from 'rxjs';

import { Organization } from '../api/models/organization';
import { Site } from '../api/models/site';

@Injectable()
export class ContextService {
  constructor(private readonly cookiePolicyService: SiCookiePolicyService, private readonly cookieService: SiCookiesService) {}

  private readonly selectedSiteBehaviorSubject$ = new BehaviorSubject<Site>(null);
  public selectedSite$ = this.selectedSiteBehaviorSubject$.asObservable();
  public set selectedSite(site: Site) {
    this.selectedSiteBehaviorSubject$.next(site);
  }
  public get selectedSite(): Site {
    return this.selectedSiteBehaviorSubject$.value;
  }

  private readonly selectedCustomerBehaviorSubject$ = new BehaviorSubject<Customer>(null);
  public selectedCustomer$ = this.selectedCustomerBehaviorSubject$.asObservable();
  public set selectedCustomer(customer: Customer) {
    // Save the orgId so that pressing "Siemens" or "Operations Manager" sends user to last org visited
    // sessionStorage.setItem('custId', customer?.id);
    if (this.cookiePolicyService.permitted(OneTrustCookieTypes.Required)) {
      const cookieExpiry = new Date();
      cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
      this.cookieService.setCookie('custId', customer?.id, '', cookieExpiry.toUTCString());
    }
    this.selectedCustomerBehaviorSubject$.next(customer);
  }
  public get selectedCustomer(): Customer {
    return this.selectedCustomerBehaviorSubject$.value;
  }

  private readonly selectedPartitionBehaviorSubject$ = new BehaviorSubject<Partition[]>(null);
  public selectedPartitions$ = this.selectedPartitionBehaviorSubject$.asObservable();
  public set selectedPartitions(partitions: Partition[]) {
    if (this.cookiePolicyService.permitted(OneTrustCookieTypes.Required)) {
      const cookieExpiry = new Date();
      cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
      const selectedPartitionIds = partitions.map(partition => partition.id);
      this.cookieService.setCookie('omSelectedPartitions', selectedPartitionIds.join(), '', cookieExpiry.toUTCString());
    }
    this.selectedPartitionBehaviorSubject$.next(partitions);
  }
  public get selectedPartitions(): Partition[] {
    return this.selectedPartitionBehaviorSubject$.value;
  }

  private readonly currentPartitionBehaviorSubject$ = new BehaviorSubject<Partition>(null);
  public currentPartition$ = this.currentPartitionBehaviorSubject$.asObservable();
  public set currentPartition(partition: Partition) {
    // Save the orgId so that pressing "Siemens" or "Operations Manager" sends user to last org visited
    sessionStorage.setItem('currentPartition', partition?.id);

    this.currentPartitionBehaviorSubject$.next(partition);
  }
  public get currentPartition(): Partition {
    return this.currentPartitionBehaviorSubject$.value;
  }

  // TODO: What is the purpose of this public property? Should the code that uses it be adapted to use partition name since organizations is never set?
  public organizations: Organization[] = [];
}
