import { ElementRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { ModalOptions } from './si-modal.service';

/**
 * Reference to a modal dialog
 * @type T: the type of the content
 * @type CT: the close type
 */
export class ModalRef<T = never, CT = any> {
  /** Emits the close value when the modal is hidden. */
  hidden = new Subject<CT | undefined>();
  /** Emits the modal element reference when it is shown. */
  shown = new ReplaySubject<ElementRef>(1);
  /**
   * The modal options passed during creation.
   * @see SiModalService
   */
  data: ModalOptions = {};
  ignoreBackdropClick = true;
  /** Custom class for modal-dialog */
  dialogClass = '';
  /** The layer of the modal. The modal with the highest layer will be shown on top. */
  layer = 0;
  /** The default close value of the modal. */
  closeValue?: CT;

  get content(): T {
    return undefined as unknown as T;
  }

  isCurrent: () => boolean = () => false;
  detach: () => void = () => {};
  /** Close the modal with a custom close value. */
  hide: (reason?: CT) => void = () => {};
}
