import {
  Directive,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  TemplateRef
} from '@angular/core';

import {
  SI_SELECT_OPTIONS_STRATEGY,
  SiSelectOptionsStrategy
} from '../options/si-select-options-strategy';
import { SiSelectSelectionStrategy } from '../selection/si-select-selection-strategy';
import { SiSelectGroupRow, SiSelectOptionRow, SiSelectRow } from '../si-select.types';

@Directive({
  standalone: true,
  host: {
    class: 'dropdown-menu show position-static overflow-auto w-100 py-4'
  }
})
export abstract class SiSelectListBase<T> {
  @Input({ required: true }) baseId!: string;
  @Input() optionTemplate?: TemplateRef<{ $implicit: SiSelectOptionRow<T> }>;
  @Input() groupTemplate?: TemplateRef<{ $implicit: SiSelectGroupRow<T> }>;
  @Input() labelledby: string | null = null;

  @Output() readonly closeOverlay = new EventEmitter<void>();

  protected selectionStrategy = inject<SiSelectSelectionStrategy<T>>(SiSelectSelectionStrategy);
  protected selectOptions = inject<SiSelectOptionsStrategy<T>>(SI_SELECT_OPTIONS_STRATEGY);
  protected rows = this.selectOptions.rows;

  @HostBinding('class.si-multi-select') multiSelect = this.selectionStrategy.allowMultiple;

  protected trackByItem: <I extends SiSelectRow<T>>(index: number, item: I) => I = <
    I extends SiSelectRow<T>
  >(
    index: number,
    item: I
  ) => item;

  protected closeOverlayAlways(): void {
    this.closeOverlay.emit();
  }

  protected closeOverlayIfSingle(): void {
    if (!this.selectionStrategy.allowMultiple) {
      this.closeOverlayAlways();
    }
  }
}
