<si-property-popover
  #popover
  [property]="property"
  [valueState]="valueState"
  [modalTemplate]="modalTemplate"
  [forceReadonly]="forceReadonly"
  (stateChange)="stateChange($event)"
>
  <div
    class="form-control d-flex align-items-center overflow-hidden"
    tabindex="0"
    (keydown.enter)="popover.open(true)"
    input
  >
    <span>
      @if (property.defaultText || property.value.value === undefined) {
        {{ property.defaultText || property.value.altText }}
      } @else {
        @for (item of property.value.options; track $index) {
          <span
            class="bit-item"
            [class.is-set]="getPreviewState(item.value)"
            [attr.aria-disabled]="!getPreviewState(item.value)"
          >
            <span>{{ item.value }}</span>
          </span>
        }
      }
    </span>
  </div>
</si-property-popover>

<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title" [id]="popover.modalTitleId">{{ property.name }}</h4>
    <button
      type="button"
      class="close btn-close"
      (click)="popover.close()"
      [attr.aria-label]="'OBJECT_BROWSER.DIALOG_TEXT_SHORT.CLOSE' | translate"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0" tabindex="0">
    <div class="inline-table">
      @for (option of property.value.options; track i; let i = $index) {
        <div class="table-row d-flex align-items-center p-5">
          <span class="text-secondary badge-table">{{ option.value }}</span>
          <span class="si-title-2 flex-grow-1" [id]="'__si-bitstring-property-label-' + i">{{
            option.text
          }}</span>
          @if (readonly || option.allowed === false) {
            <span class="value">
              {{ getState(option.value) ? 'True' : 'False' }}
            </span>
          }
          @if (!readonly && option.allowed !== false) {
            <div class="si-switch from-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                [attr.aria-labelledby]="'__si-bitstring-property-label-' + i"
                [ngModel]="getState(option.value)"
                (ngModelChange)="setState(option.value, $event)"
              />
            </div>
          }
        </div>
      }
    </div>
  </div>
  <div class="modal-footer text-right text-end p-5">
    @if (!readonly && popover.overrideMode !== 'direct') {
      <div class="d-flex justify-content-end">
        @if (
          property.overridden &&
          (property.overrideMode === 'normal' ||
            property.overrideMode === 'warning' ||
            property.overrideMode === 'danger')
        ) {
          <button
            type="button"
            class="btn btn-circle btn-sm me-auto release-button"
            [ngClass]="{
              'btn-secondary': property.overrideMode === 'normal',
              'btn-warning': property.overrideMode === 'warning',
              'btn-danger': property.overrideMode === 'danger',
              'element-manual': property.overrideMode === 'normal',
              'element-manual-filled':
                property.overrideMode === 'warning' || property.overrideMode === 'danger'
            }"
            (click)="popover.release()"
          >
            <span class="visually-hidden">{{
              'OBJECT_BROWSER.DIALOG_TEXT_SHORT.RELEASE' | translate
            }}</span>
          </button>
        }
        <button
          type="button"
          class="btn btn-circle btn-sm btn-secondary element-cancel mr-3 me-3"
          (click)="popover.cancel()"
        >
          <span class="visually-hidden">{{
            'OBJECT_BROWSER.DIALOG_TEXT_SHORT.CANCEL' | translate
          }}</span>
        </button>
        <button
          type="button"
          [ngClass]="{
            'btn-warning': property.overrideMode === 'warning',
            'btn-danger': property.overrideMode === 'danger',
            'btn-primary': property.overrideMode !== 'warning' && property.overrideMode !== 'danger'
          }"
          class="btn btn-circle btn-sm element-ok"
          (click)="popover.submit()"
        >
          <span class="visually-hidden">{{
            'OBJECT_BROWSER.DIALOG_TEXT_SHORT.OK' | translate
          }}</span>
        </button>
      </div>
    }
  </div>
</ng-template>
