/**
 * Represents possible result of Timeout Dialog
 */
export enum SessionCookieStorage {
  TabCounter = 'tabCounter',
  TabCounterActive = 'tabCounterActive',
  ActiveState = 'activeState',
  Mute = 'mute',
  DomainName = 'domainName',
  PathName = 'pathName',
  True = 'true',
  False = 'false',
  TabLogged = 'tabLogged',
  ShowModal = 'showModal',
  RefreshTab = 'refreshTab',
  TimerLogOff = 'timerLogOff',
  UserRoles = 'userRoles',
  SkipDefaultUserAutoLogin = 'skipDefaultUserAutoLogin'
}
