import { SvgUtility } from '../utilities/parser';
import { GmsViewport } from './gms-viewport';

export class GmsAutoViewport extends GmsViewport {
  public get IsAutoViewport(): boolean {
    return true;
  }

  constructor(x: number, y: number, width: number, height: number, depthIndex: number) {
    super(x, y, width, height);
    this.DepthIndex = depthIndex;
  }
}
