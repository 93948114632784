import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MobileNavigationService } from "@gms-flex/core";
import { SettingsServiceBase } from '@gms-flex/services-common';
import { Subscription } from "rxjs";
import { first } from 'rxjs/operators';

import { GmsGraphic } from '../../elements/gms-graphic';

@Component({
  selector: '[gms-toolbar]',
  template: `
    <ng-container #mobileToolbar *ngIf="isMobile; else defaultToolbar">
      <ng-container *ngIf="showToolbar">
          <div>
            <div *ngIf="graphic !== undefined && graphic.HasCoverageArea">
              <xhtml:button (click)="onCoverageAreaHighlight($event)"
                            class="btn btn-circle btn-lg btn-secondary element-video me-6"
                            style="position: absolute; bottom: 215px; right: 30px;"
                            [ngClass]="graphic !== undefined && graphic.CoverageAreaMode ? 'pressed-button-background' : 'default-button-background' "/>
            </div>
            <xhtml:button style="position: absolute; bottom: 170px; right: 30px;"
                          (click)="onTooltipToggle()"
                          class="btn btn-circle btn-lg btn-secondary me-6 default-button-background"
                          [ngClass]="graphic !== undefined && graphic.IsTooltipEnabled ? 'element-notification' : 'element-notification-off' "/>
            <xhtml:button (click)="OnAlarmsToggle($event)"
                          style="position: absolute; bottom: 125px; right: 30px;"
                          [ngClass]="'btn btn-circle btn-lg btn-secondary me-6 default-button-background '+ alarmsToggle"/>
            <xhtml:button (click)="onScaleToFit($event)" style="position: absolute; bottom: 80px; right: 30px;"
                          class="btn btn-circle btn-lg btn-secondary element-show me-6"
                          [ngClass]="graphic !== undefined && graphic.IsPermScaleToFit ? 'pressed-button-background' : 'default-button-background' "/>
          </div>
      </ng-container>
    </ng-container>
    <ng-template #defaultToolbar>
      <ng-container *ngIf="showToolbar">
        <div *ngIf="graphic !== undefined && graphic.HasCoverageArea" class="mobile-container">
          <xhtml:button (click)="onCoverageAreaHighlight($event)"
                        class="btn btn-circle btn-lg btn-secondary element-video me-6"
                        style="position: absolute; bottom: 305px; right: 30px;"
                        [ngClass]="graphic !== undefined && graphic.CoverageAreaMode ? 'pressed-button-background' : 'default-button-background' "/>
        </div>
        <xhtml:button style="position: absolute; bottom: 260px; right: 30px;"
                      (click)="onTooltipToggle()"
                      class="btn btn-circle btn-lg btn-secondary me-6 default-button-background"
                      [ngClass]="graphic !== undefined && graphic.IsTooltipEnabled ? 'element-notification' : 'element-notification-off' "/>
        <xhtml:button (click)="OnAlarmsToggle($event)"
                      style="position: absolute; bottom: 215px; right: 30px;"
                      [ngClass]="'btn btn-circle btn-lg btn-secondary me-6 default-button-background '+ alarmsToggle"/>
        <xhtml:button (click)="onScaleToFit($event)" style="position: absolute; bottom: 170px; right: 30px;"
                      class="btn btn-circle btn-lg btn-secondary me-6 default-button-background"
                      [ngClass]="graphic !== undefined && graphic.IsPermScaleToFit ? 'element-show' : 'element-hide' "/>
        <xhtml:button (click)="onZoomIn($event)"
                      *ngIf="!isMobile"
                      style="position: absolute; bottom: 125px; right: 30px;"
                      class="btn btn-circle btn-lg btn-secondary element-plus me-6 default-button-background"/>
        <xhtml:button (click)="onZoomOut($event)"
                      *ngIf="!isMobile"
                      style="position: absolute; bottom: 80px; right: 30px;"
                      class="btn btn-circle btn-lg btn-secondary element-minus me-6 default-button-background"/>
      </ng-container>
    </ng-template>
    <xhtml:button (click)="onMenuPress($event)"
                  style="position: absolute; bottom: 35px; right: 30px;"
                  class="btn btn-circle btn-lg btn-secondary element-menu me-6 default-button-background"/>
  `,
  styles: [`.allptrevents {
    pointer-events: all;
  }`,
  `.toolbar-button-icon::before {
      position: absolute;
      top: 7px;
      left: 7px;
      font-size: 24px;
    }`,
  `.toolbar-eye-icon::before {
      position: absolute;
      top: 7px;
      left: 7px;
      font-size: 24px;
    }`,
  `.toolbar-math-icon::before {
      position: absolute;
      bottom: 3.5px;
      left: 3.5px;
      font-size: 32px;
    }`],
  styleUrl: './gms-menu.scss'
})

export class GmsToolbarComponent implements OnInit, OnDestroy {
  @Input() public graphic: GmsGraphic;
  public isMobile = false;
  public showToolbar = false;
  private readonly graphicsTooltipEnabledId = `IsGraphicsTooltipEnabled`;
  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly settingsService: SettingsServiceBase,
    @Inject(MobileNavigationService) private readonly mobileNavigationService: MobileNavigationService) {
  }

  public ngOnInit(): void {
    this.settingsService.getSettings(this.graphicsTooltipEnabledId).pipe(first()).subscribe({
      next: (isTooltipEnabled: string) => {
        this.graphic.IsTooltipEnabled = isTooltipEnabled === 'true';
      }
    });

    this.subscriptions.push(
      this.mobileNavigationService.mobileOnlyVisibility$.subscribe((mobileOnlyVisibility: boolean): void => {
        this.isMobile = mobileOnlyVisibility;
      })
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public get alarmsToggle(): string {
    return this.graphic.ToggleAlarmsVisibility ? 'element-alarm' : ' element-alarm-off';
  }

  public onCoverageAreaHighlight(event: Event): void {
    if (this.graphic !== undefined) {
      this.graphic.CoverageAreaMode = !this.graphic.CoverageAreaMode;
    }
  }

  public onTooltipToggle(): void {
    this?.graphic?.TooltipToggle?.next();
    const isTooltipEnabled = String(this?.graphic?.IsTooltipEnabled);
    this.settingsService.putSettings(this.graphicsTooltipEnabledId, isTooltipEnabled).subscribe({
      next: (result: boolean) => {
      },
      error: (error: any) => {
      }
    });
  }

  public onScaleToFit(event: Event): void {
    if (this.graphic !== undefined) {
      this.graphic.IsPermScaleToFit = !this.graphic.IsPermScaleToFit;
      if (this.graphic.IsPermScaleToFit) {
        this.graphic.scaleToFit.next(undefined);
      }
    }
  }

  public onMenuPress(event: MouseEvent): void {
    this.showToolbar = !this.showToolbar;
  }

  public onZoomIn(event: MouseEvent): void {
    if (this.graphic !== undefined) {
      this.graphic.zoomInButton.next();
      this.setPointCenteredZoom(event);
    }
  }

  public onZoomOut(event: MouseEvent): void {
    if (this.graphic !== undefined) {
      this.graphic.zoomOutButton.next();
      this.setPointCenteredZoom(event);
    }
  }

  public setPointCenteredZoom(event: MouseEvent): void {
    if (event.shiftKey) {
      this.graphic.setPointCenteredZoom.next();
    }
  }

  public OnAlarmsToggle(event: Event): void {
    if (this.graphic !== undefined) {
      this.graphic.ToggleAlarmsVisibility = !this.graphic.ToggleAlarmsVisibility;
    }
  }
}
