import { TextGroupEntry } from '../textgroup/gms-text-group-entry';

export class TextGroupEntryHelper {

  public static SYSTEMNAME_SEPARATOR = ':';
  public static TEXTGROUP_IDENTIFIER = 'TxG_';
  public static INDEX_SEPARATOR = '.';
  public static TEXTGROUP_WITH_SYSTEMPREFIX_IDENTIFIER: string = ':' + TextGroupEntryHelper.TEXTGROUP_IDENTIFIER;

  /**
   * Parses a given text and return the tx_group name and index
   * @param text
   */
  public static Parse(textgroupEntry: TextGroupEntry, systemId: string): boolean {

    if (textgroupEntry === undefined) {
      return false;
    }

    const textGroupId: string = textgroupEntry.TxGroupId.toString();
    if (textGroupId === undefined || textGroupId.length === 0) {
      return false;
    }

    let systemName = '';
    const index = '';

    const splitted: string[] = textGroupId.split(TextGroupEntryHelper.SYSTEMNAME_SEPARATOR);
    if (splitted === undefined) {
      return false;
    }

    if (splitted.length === 1 && (systemId !== undefined)) {
      textgroupEntry.SystemId = systemId;
    } else if (splitted.length > 1) {
      systemName = splitted[0];
    }

    const txgPos: number = textGroupId.indexOf(TextGroupEntryHelper.TEXTGROUP_IDENTIFIER);
    const textGroupRef = txgPos >= 0 ? textGroupId.substring(txgPos) : undefined;
    const result: string[] = textGroupRef !== undefined ? textGroupRef.split(TextGroupEntryHelper.INDEX_SEPARATOR) : undefined;
    if (result !== undefined && result.length > 1) {

      // all done
      textgroupEntry.SystemName = systemName;
      textgroupEntry.TableName = result[0];
      textgroupEntry.Index = result[1];
      return true;
    }
    return false;
  }

  /**
   * Parses TxGroupId and returns a system name, an empty string if it is not found, undefined if any error
   * @param textgroupEntry
   */
  public static ParseSystemName(textgroupEntry: TextGroupEntry): string {
    let systemName = '';
    if (textgroupEntry === undefined) {
      return systemName;
    }
    const textGroupId: string = textgroupEntry.TxGroupId.toString();
    if (textGroupId === undefined || textGroupId.length === 0) {
      return systemName;
    }

    const splitted: string[] = textGroupId.split(TextGroupEntryHelper.SYSTEMNAME_SEPARATOR);
    if (splitted === undefined) {
      return systemName;
    }

    if (splitted.length === 1) {
      return splitted[0].startsWith(TextGroupEntryHelper.TEXTGROUP_IDENTIFIER, 0) ? '' : undefined;
    } else if (splitted.length > 1 && splitted[1].startsWith(TextGroupEntryHelper.TEXTGROUP_IDENTIFIER, 0)) {
      systemName = splitted[0];
    }
    return systemName;
  }

  /**
   * Checks to see if the supplied string is likely to be a text group reference. This is done by an emperical
   * assumption that text groups contain the prefix "TxG_".
   * @param strTextReference - The string to check, e.g. "TxG_Room_Symbols_Text.1" or "System1:TxG_Room_Symbols_Text.1"
   * or System1.ManagementView:ManagementView.FieldNetworks.KNXnet1.KNX_adapter.KNX_Secure_Panel_ColorTemp_RDGTest.1.1-1.1-1-30;.TxG_GAMMA_KNX_GraphicTexts.2
   */
  public static IsTextGroupReference(strTextReference: string): boolean {
    if (strTextReference === undefined) {
      return false;
    }

    // Check for text group name
    const txgPos: number = strTextReference.indexOf(TextGroupEntryHelper.TEXTGROUP_IDENTIFIER);
    const textRef: string = txgPos >= 0 ? strTextReference.substring(txgPos) : undefined;

    // Check for text group index
    const indexPos: number = textRef !== undefined ? textRef.lastIndexOf(TextGroupEntryHelper.INDEX_SEPARATOR) : -1;
    const index: string = indexPos >= 0 ? textRef.substring(indexPos + 1) : undefined;
    const indexCheck: number = index !== undefined && index.length > 0 ? Number(index) : Number.NaN;

    // Reference exists with index
    return (textRef !== undefined && textRef.length > 0) && !Number.isNaN(indexCheck);
  }
}
