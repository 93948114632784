import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { DatepickerInputConfig } from '@simpl/element-ng';
import { Observable, Subject } from 'rxjs';

import { DateFormat, TimeFormat } from '../../../interfaces/date-time-formats';
import { DateTime, dayOptions, monthOptions, yearOptions } from '../models/date-time';
import { DateTimeValidator } from '../models/date-time-validator';
import { Bacnet, ViewChange, WildcardBACnet } from '../models/enums';
import {
  DateLabelKeys,
  FormValue,
  InputDateProperty,
  InputProperty,
  InputValue,
  TimeKeyLabels
} from '../models/interfaces';
import { Options } from '../models/options';
import { SpecialChars } from '../models/special-chars';
import { TValue } from '../models/types';

@Injectable()
export class ViewService {
  private _date!: Date | null;
  private _options = new Options();
  private _viewChangeSubject = new Subject<ViewChange>();
  private _value!: DateTime;

  dateFormat: DateFormat = 'mm/dd/yyyy';
  dateInputs: InputDateProperty[] = [];
  dateLabelKeys: DateLabelKeys = {
    date: '',
    day: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.DAY',
    month: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.MONTH',
    year: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.YEAR'
  };

  timeFormat: TimeFormat = 'hh:mm:ss tt';
  timeInputs: InputProperty[] = [];
  timeLabelKeys: TimeKeyLabels = {
    h: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.HOUR',
    m: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.MINUTE',
    s: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.SECOND',
    hs: 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.HUNDREDTH',
    t: ''
  };

  set date(value: Date | null) {
    this._value.year = value ? value.getFullYear() : undefined;
    this._value.month = value ? value.getMonth() + 1 : undefined;
    this._value.day = value ? value.getDate() : undefined;
    this._date = value !== undefined ? value : null;
  }
  get date(): Date | null {
    return this._date;
  }

  get datePickerConfig(): Partial<DatepickerInputConfig> {
    return {
      dateFormat: this.dateFormat?.replace('mm', 'MM')
    };
  }

  get options(): Options {
    return this._options;
  }

  get value(): DateTime {
    return this._value;
  }

  get viewChange$(): Observable<ViewChange> {
    return this._viewChangeSubject.asObservable();
  }

  hasDate(): boolean {
    return this.dateInputs.length !== 0;
  }

  isSpecialAllowed(): boolean {
    return this.options.isSpecial;
  }

  hasTime(): boolean {
    return this.timeInputs.length !== 0;
  }

  initializeView(): void {
    this.updateView(true);
  }

  mapTo(input: InputProperty): InputValue {
    switch (input.key) {
      case 'amPm':
        return { validator: [], value: this._value.amPm };

      case 'date':
        return { validator: this.validatorDate(input), value: this.date };

      case 'day':
        return { validator: this.validatorDay(input), value: this._value.toDayStr(false) };

      case 'hours':
        return {
          validator: this.validatorHours(input),
          value: this.hasHours12() ? this._value.toHours12Str() : this._value.toHoursStr()
        };

      case 'hundredths':
        return { validator: this.validatorHundredths(input), value: this._value.toHundredthsStr() };

      case 'minutes':
        return { validator: this.validatorMinutes(input), value: this._value.toMinutesStr() };

      case 'month':
        return { validator: this.validatorMonth(input), value: this._value.toMonthStr(false) };

      case 'seconds':
        return { validator: this.validatorSeconds(input), value: this._value.toSecondsStr() };

      case 'year':
        return { validator: this.validatorYear(input), value: this._value.toYearStr(false) };

      default:
        throw new Error('Unknown input control');
    }
  }

  updateValue(value: TValue): void {
    const dirty = this.updateOptions(value);
    this._value = DateTime.parse(value, this.options);

    if (!this.hasWildcards()) {
      this.updateAllOptional(false);
      this.date =
        this.value.year != null && this.value.month != null && !this.options.allOptional
          ? new Date(this.value.year, this.value.month - 1, this.value.day)
          : null;
    }

    this.updateView(dirty);
  }

  valueChange(form: FormValue): string | undefined {
    if (this.hasDate()) {
      if (this.hasWildcards()) {
        this.value.setDay(form.day);
        this.value.setMonth(form.month);
        this.value.setYear(form.year);
      } else {
        this.date = form.date!;
      }
    }
    if (this.hasTime()) {
      this.value.setHours(form.hours, form.amPm);
      this.value.setMinutes(form.minutes);
      if (this.hasSeconds()) {
        this.value.setSeconds(form.seconds);
      }
      if (this.hasHundreds()) {
        this.value.setHundredths(form.hundredths);
      }
    }
    this.updateAllOptional();
    const valueString = this.value.toString();
    return valueString !== '' ? valueString : undefined;
  }

  private getWildcards(key: string): string[] {
    // eslint-disable-next-line no-bitwise
    return this.options.isOptional && !this.options.hasOption(Options.special | Options.wildcard)
      ? [SpecialChars.optional]
      : this.options.isSpecial || this.options.isWildcarded
        ? (SpecialChars as any)[key]
        : [];
  }

  private hasHours12(): boolean {
    return this.timeFormat?.includes('tt') ?? false;
  }

  private hasHundreds(): boolean {
    return this.timeFormat?.includes('hs') ?? false;
  }

  private hasSeconds(): boolean {
    return this.timeFormat?.includes('ss') ?? false;
  }

  hasWildcards(): boolean {
    // eslint-disable-next-line no-bitwise
    return this.options.hasOption(Options.special | Options.wildcard);
  }

  private inputSequenceDate(): InputDateProperty[] {
    const inputSequence: InputDateProperty[] = [];
    if (this.hasWildcards()) {
      const order = this.dateFormat ? this.dateFormat[0] : undefined;
      const inputs: InputDateProperty[] = [
        {
          key: 'day',
          label: this.dateLabelKeys.day,
          max: Bacnet.DAYS,
          maxLength: 9,
          min: 1,
          selectOptions: dayOptions
        },
        {
          key: 'month',
          label: this.dateLabelKeys.month,
          max: Bacnet.MONTHS,
          maxLength: 11,
          min: 1,
          selectOptions: monthOptions
        },
        {
          key: 'year',
          label: this.dateLabelKeys.year,
          max: Bacnet.END_YEAR,
          maxLength: 8,
          min: Bacnet.START_YEAR,
          selectOptions: yearOptions
        }
      ];

      switch (order) {
        case 'd':
          inputSequence[0] = inputs.filter(input => input.key === 'day')[0];
          inputSequence[1] = inputs.filter(input => input.key === 'month')[0];
          inputSequence[2] = inputs.filter(input => input.key === 'year')[0];
          break;

        case 'y':
          inputSequence[0] = inputs.filter(input => input.key === 'year')[0];
          inputSequence[1] = inputs.filter(input => input.key === 'month')[0];
          inputSequence[2] = inputs.filter(input => input.key === 'day')[0];
          break;

        case 'm':
        default:
          inputSequence[0] = inputs.filter(input => input.key === 'month')[0];
          inputSequence[1] = inputs.filter(input => input.key === 'day')[0];
          inputSequence[2] = inputs.filter(input => input.key === 'year')[0];
          break;
      }
    } else {
      inputSequence[0] = {
        key: 'date',
        label: this.dateLabelKeys.date,
        max: 2054,
        maxLength: 10,
        min: 1900
      };
    }
    return inputSequence;
  }

  private inputSequenceTime(): InputProperty[] {
    const minHours = this.hasHours12() ? 1 : 0;
    const maxHours = this.hasHours12() ? 12 : 23;
    const inputs: InputProperty[] = [
      { key: 'hours', label: this.timeLabelKeys.h, max: maxHours, maxLength: 2, min: minHours },
      { key: 'minutes', label: this.timeLabelKeys.m, max: 59, maxLength: 2, min: 0 }
    ];

    if (this.hasSeconds()) {
      inputs.push({ key: 'seconds', label: this.timeLabelKeys.s, max: 59, maxLength: 2, min: 0 });
    }
    if (this.hasHundreds()) {
      inputs.push({
        key: 'hundredths',
        label: this.timeLabelKeys.hs,
        max: 99,
        maxLength: 2,
        min: 0
      });
    }
    if (this.hasHours12()) {
      inputs.push({ key: 'amPm', label: this.timeLabelKeys.t, max: 1, maxLength: 2, min: 0 });
    }

    return inputs;
  }

  private isDateFormat(value: string): value is DateFormat {
    return Object.values(DateFormat).findIndex(item => item === value) !== 1;
  }

  private updateAllOptional(notifyChange: boolean = true): void {
    const allOptional = this._value.allOptional;
    if (this.options.allOptional !== allOptional) {
      this.options.toggleAllOptional();
      if (notifyChange) {
        this._viewChangeSubject.next(ViewChange.update);
      }
    }
  }

  private updateOptions(value: TValue): boolean {
    let dirty = false;

    if (this.options.dateFormat !== this.dateFormat) {
      this.options.dateFormat = this.dateFormat;
      dirty = true;
    }

    if (this.options.timeFormat !== this.timeFormat) {
      this.options.timeFormat = this.timeFormat;
      dirty = true;
    }

    const type = value ? value.type : 'date-time';
    if (this.options.type !== type) {
      this.options.type = type;
      dirty = true;
    }

    const optional = !!value && !!value.optional;
    if (this.options.isOptional !== optional) {
      this.options.toggleOptional();
      dirty = true;
    }

    const readonly = !!value && !!value.readonly;
    if (this.options.isReadonly !== readonly) {
      this.options.toggleReadonly();
      dirty = true;
    }

    const specialAllowed =
      !!value && (value.type === 'date' || value.type === 'date-time') && !!value.specialAllowed;
    if (this.options.isSpecial !== specialAllowed) {
      this.options.toggleSpecial();
      dirty = true;
    }

    const wildcardAllowed = !!value && !!value.wildcardAllowed;
    if (this.options.isWildcarded !== wildcardAllowed) {
      this.options.toggleWildcard();
      dirty = true;
    }

    return dirty;
  }

  private updateView(dirty: boolean): void {
    if (dirty) {
      this._viewChangeSubject.next(ViewChange.remove);

      switch (this.options.type) {
        case 'date':
          this.dateInputs = this.inputSequenceDate();
          this.timeInputs = [];
          break;

        case 'time':
          this.dateInputs = [];
          this.timeInputs = this.inputSequenceTime();
          break;

        case 'date-time':
        default:
          this.dateInputs = this.inputSequenceDate();
          this.timeInputs = this.inputSequenceTime();
          break;
      }

      if (this.dateLabelKeys.date === '&nbsp;' || this.isDateFormat(this.dateLabelKeys.date)) {
        this.dateLabelKeys = { ...this.dateLabelKeys, date: this.dateFormat };
      }

      this._viewChangeSubject.next(ViewChange.add);
    } else {
      this._viewChangeSubject.next(ViewChange.update);
    }
  }

  private validatorDate(input: InputProperty): ValidatorFn[] {
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validateDate(input.min, input.max, this.getWildcards(input.key))
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorDay(input: InputProperty): ValidatorFn[] {
    const wildcardIndex = [
      WildcardBACnet.allDays.toString(),
      WildcardBACnet.lastDayOfMonth.toString(),
      WildcardBACnet.evenDays.toString(),
      WildcardBACnet.oddDays.toString()
    ];
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validateDay(this.value, wildcardIndex, this.hasWildcards())
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorHours(input: InputProperty): ValidatorFn[] {
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validate(input.min, input.max, this.getWildcards(input.key))
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorHundredths(input: InputProperty): ValidatorFn[] {
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validate(input.min, input.max, this.getWildcards(input.key))
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorMinutes(input: InputProperty): ValidatorFn[] {
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validate(input.min, input.max, this.getWildcards(input.key))
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorMonth(input: InputProperty): ValidatorFn[] {
    const wildcardIndex = [
      WildcardBACnet.allMonths.toString(),
      WildcardBACnet.evenMonths.toString(),
      WildcardBACnet.oddMonths.toString()
    ];
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validate(input.min, input.max, wildcardIndex)
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorSeconds(input: InputProperty): ValidatorFn[] {
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validate(input.min, input.max, this.getWildcards(input.key))
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }

  private validatorYear(input: InputProperty): ValidatorFn[] {
    const wildcardIndex = [WildcardBACnet.allYears.toString()];
    const validator = [
      Validators.maxLength(input.maxLength),
      DateTimeValidator.validate(input.min, input.max, wildcardIndex)
    ];
    if (!this.options.allOptional) {
      validator.splice(0, 0, Validators.required);
    }
    return validator;
  }
}
