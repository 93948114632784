import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CategoryService } from "@gms-flex/services";
import { SelectOption } from "@simpl/element-ng";
import { Subscription } from "rxjs";
import { EventCategoryBx } from "src/app/bx-services/alarm/events-proxy.model";

@Component({
  selector: "point-alarm-category",
  templateUrl: "./point-alarm-category.component.html",
  styleUrl: "./point-alarm-category.component.scss"
})

export class PointAlarmCategoryComponent implements OnInit, OnDestroy {
  @Input() public isAnalog: boolean;
  @Input() public selectedCategory: EventCategoryBx;
  @Input() public canSaveData: boolean;
  @Input() public translatedLabels: Map<string, string>;
  @Output() public readonly newCategoryEvent = new EventEmitter<EventCategoryBx>();

  public categoryValues: SelectOption[] = [];

  private categorySubscription: Subscription;

  constructor(private readonly categoryService: CategoryService) { }

  public ngOnInit(): void {

    this.initializeCategories();
    this.selectedCategory = this.selectedCategory || EventCategoryBx.Medium;
  }

  public ngOnDestroy(): void {
    this.categorySubscription.unsubscribe();
  }

  public onChange(category: EventCategoryBx): void {
    this.newCategoryEvent.emit(category);
  }

  private initializeCategories(): void {
    this.categorySubscription = this.categoryService.getCategories().subscribe(categories => {
      if (categories) {
        this.categoryValues = [];
        categories.forEach(category => {
          this.categoryValues.push(
            {
              id: category.descriptor,
              title: category.descriptor
            })
        })
      }
    })
  }

}
