import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsImageElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { StretchType } from '../types/gms-image-types';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';

export class GmsImage extends GmsElement {
  private _href: string;
  public get Href(): string {
    return this._href;
  }
  public set Href(value: string) {

    if (this._href !== value) {
      this._href = value;

      this.NotifyPropertyChanged('Href');
    }
  }

  private _stretch: StretchType = StretchType.Uniform;
  public get Stretch(): StretchType {
    return this._stretch;
  }
  public set Stretch(value: StretchType) {

    if (this._stretch !== value) {
      this._stretch = value;

      this.NotifyPropertyChanged('Stretch');
    }
  }

  private _imageHeight = 0;
  public get ImageHeight(): number {
    return this._imageHeight;
  }
  public set ImageHeight(value: number) {

    if (this._imageHeight !== value) {
      this._imageHeight = value;

      this.NotifyPropertyChanged('ImageHeight');
    }
  }

  private _imageWidth = 0;
  public get ImageWidth(): number {
    return this._imageWidth;
  }
  public set ImageWidth(value: number) {

    if (this._imageWidth !== value) {
      this._imageWidth = value;

      this.NotifyPropertyChanged('ImageWidth');
    }
  }

  private _dpiX = 0;
  public get DpiX(): number {
    return this._dpiX;
  }
  public set DpiX(value: number) {

    if (this._dpiX !== value) {
      this._dpiX = value;

      this.NotifyPropertyChanged('DpiX');
    }
  }

  private _dpiY = 0;
  public get DpiY(): number {
    return this._dpiY;
  }
  public set DpiY(value: number) {

    if (this._dpiY !== value) {
      this._dpiY = value;

      this.NotifyPropertyChanged('DpiY');
    }
  }

  private _viewBox = '';
  public get ViewBox(): string {
    switch (this.Stretch) {
      case StretchType.None:
        const width: number = this.Width / 96 * this.DpiX;
        const height: number = this.Height / 96 * this.DpiY;
        const vbX: number = this.Width > this.ImageWidth ? (this.Width - this.ImageWidth) / 2 : 0;
        const vbY: number = this.Height > this.ImageHeight ? (this.Height - this.ImageHeight) / 2 : 0;
        this._viewBox = vbX + ' ' + vbY + ' ' + width + ' ' + height;
        break;
      case StretchType.Fill:
        this._viewBox = '0 0 ' + this.ImageWidth + ' ' + this.ImageHeight;
        break;

      case StretchType.Uniform:
        this._viewBox = '0 0 ' + this.ImageWidth + ' ' + this.ImageHeight;
        break;

      case StretchType.UniformToFill:
        const scaleX: number = this.Width > 0 ? this.ImageWidth / this.Width : 1;
        const scaleY: number = this.Height > 0 ? this.ImageHeight / this.Height : 1;
        const scale: number = Math.min(scaleX, scaleY);
        this._viewBox = '0 0 ' + (scale * this.Width) + ' ' + scale * this.Height;
        break;
      default:
        this._viewBox = '0 0 ' + this.Width + '' + this.Height;
    }

    return this._viewBox;
  }

  public UpdateAdornerDimensions(): void {
    this.AdornerHeight = this.Height;
    this.AdornerWidth = this.Width;
  }

  public get PreserveAspectRatio(): string {
    return this.Stretch !== StretchType.Fill ? 'xMidYMid' : 'none';
  }

  constructor(imageType: GmsElementType = GmsElementType.Image) {
    super(imageType);
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {

    let innerNode: Node;

    let result: string = SvgUtility.GetAttributeValue(node, GmsImageElementPropertyType.Stretch);
    if (result !== undefined) {
      this.Stretch = StretchType[result] as StretchType;
    }
    let nodes: NodeList = node.childNodes;
    for (let i = 0; i < nodes.length; i++) {
      const child: Node = nodes[i];
      if (child.nodeName === 'svg') {
        innerNode = child;
        break;
      } else if (SvgUtility.IsNodeScaleTransformationGroup(child)) {
        i = -1;
        nodes = child.childNodes;
        continue;
      }
    }

    result = SvgUtility.GetAttributeValue(innerNode, GmsImageElementPropertyType.DpiX);
    if (result !== undefined) {
      this.DpiX = FormatHelper.StringToNumber(result); //  +result;
    }

    result = SvgUtility.GetAttributeValue(innerNode, GmsImageElementPropertyType.DpiY);
    if (result !== undefined) {
      this.DpiY = FormatHelper.StringToNumber(result); //  +result;
    }

    result = SvgUtility.GetAttributeValue(innerNode, GmsImageElementPropertyType.ImageWidth);
    if (result !== undefined) {
      this.ImageWidth = FormatHelper.StringToNumber(result); //  +result;
    }

    result = SvgUtility.GetAttributeValue(innerNode, GmsImageElementPropertyType.ImageHeight);
    if (result !== undefined) {
      this.ImageHeight = FormatHelper.StringToNumber(result); //  +result;
    }
    if (innerNode !== undefined) {
      for (let i: number = (innerNode.childNodes.length - 1); i >= 0; i--) {
        const child: Node = innerNode.childNodes[i];
        if (innerNode.childNodes[i].nodeName === 'use') {
          innerNode = child;
          break;
        }
      }

      result = SvgUtility.GetAttributeValue(innerNode, GmsImageElementPropertyType.Href);
      if (result !== undefined) {
        this.Href = result;
      }
    }

    super.Deserialize(node);

    this.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsImage): void {
    this.IsCopying = true;

    this.Stretch = element.Stretch;

    this.DpiX = element.DpiX;
    this.DpiY = element.DpiY;

    this.ImageWidth = element.ImageWidth;
    this.ImageHeight = element.ImageHeight;

    this.Href = element.Href;

    super.CopyFrom(element);

    this.CopyEvaluations(element);

    this.IsCopying = false;
  }
}
