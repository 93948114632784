import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TraceService } from '@gms-flex/services-common';
import { TraceModules } from '../../shared/trace-modules';
import { TraceServiceDelegate } from '../../shared/trace-service-delegate';
import { MemoPopoverServiceBase } from '../services/memo-popover.service.base';
import { MemoViewModelIfc } from '../view-model/memo-vm.base';

@Component({
  selector: 'gms-memo-view',
  templateUrl: './memo.component.html',
  styleUrl: './memo.component.scss'
})
export class MemoViewComponent implements OnInit, OnDestroy {

  @Input() public vm: MemoViewModelIfc;
  @Output() public readonly isDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly saveResult: EventEmitter<Error> = new EventEmitter<Error>();

  public headerTitle: string;
  public labelEditButton: string;
  public labelEditCancelButton: string;
  public labelEditSaveButton: string;

  private readonly traceSvc: TraceServiceDelegate;
  private readonly translateService: TranslateService;
  private destroyInd: Subject<void>;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    traceService: TraceService,
    memoPopoverService: MemoPopoverServiceBase) {

    this.traceSvc = new TraceServiceDelegate(traceService, TraceModules.memoPopover);
    this.translateService = memoPopoverService.commonTranslateService;
    this.destroyInd = new Subject<void>();

    const ts: TranslateService = memoPopoverService.commonTranslateService;
    ts.get('MEMO.TITLE').subscribe(s => this.headerTitle = s);
    ts.get('MEMO.EDIT-BUTTON-LABEL').subscribe(s => this.labelEditButton = s);
    ts.get('MEMO.EDIT-CANCEL-BUTTON-LABEL').subscribe(s => this.labelEditCancelButton = s);
    ts.get('MEMO.EDIT-SAVE-BUTTON-LABEL').subscribe(s => this.labelEditSaveButton = s);
  }

  public ngOnInit(): void {
    this.vm.setInitialView();
    this.vm.dataChangedUndetected
      .pipe(
        debounceTime(100),
        takeUntil(this.destroyInd))
      .subscribe(() => {
        this.cdRef.detectChanges();
      });
    this.vm.contextChanged
      .pipe(
        takeUntil(this.destroyInd))
      .subscribe(() => {
        this.vm.editCancel();
        this.vm.setInitialView();
      });
    this.vm.dirtyStateChanged
      .pipe(
        takeUntil(this.destroyInd))
      .subscribe(dirtyFlag => {
        this.isDirty.emit(dirtyFlag);
      });
  }

  public ngOnDestroy(): void {
    this.destroyInd.next();
    this.destroyInd.complete();
    this.destroyInd = undefined;
  }

  // NOTE: It is necessary to stopPropagation of edit-control buttons within the gms-memo-view template.
  // Otherwise, these events will bubble up to the outside-click handler registered by the parent component
  // at the document-level.  And since these buttons are ngIf-ed out of the DOM when clicked, the outside
  // click handler will not recognize these elements as having been part of the popover (the button elements
  // will no longer have a parentElement property defined by the time the outside-click handler interrogates
  // them as click targets).  Easiest approach is to suppress these specific click events from bubbling.

  public onEdit(event: MouseEvent): void {
    this.vm.editMemo();
    event.stopPropagation();
  }

  public onEditCancel(event: MouseEvent): void {
    this.vm.editCancel();
    event.stopPropagation();
  }

  public onEditSave(event: MouseEvent): void {
    this.vm.editSave()
      .subscribe(
        res => {
          this.saveResult.emit(res);
        },
        err => {
          this.traceSvc.error('Unexpected error on memo save request: %s', err);
        });
    event.stopPropagation();
  }
}
