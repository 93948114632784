import * as XRegExp from 'xregexp';

import { KeyValuePair } from '../utilities/data-structures';
import { Utility } from '../utilities/utility';

//  Defines the type of range
export enum RangeType {
  // No range
  None,
  // Range from .. to
  Range,
  // Equal
  Equal,
  // Not equal
  NotEqual,
  // Smaller than
  SmallerThan,
  // Smaller than or equal
  SmallerThanOrEqual,
  // Greater than
  GreaterThan,
  // Greater than or equal
  GreaterThanOrEqual,
  // Binary and
  And
}

export class Range {

  // following variants are possible
  //  3.4
  //  != 3.4
  //  < 3.4
  //  <= 3.4
  //  > 3.4
  //  >= 3.4
  //  3.4 ..
  //  .. 3.4
  //  1.2 .. 3.4
  //  3.4 .. 1.2
  //  ..
  //  "Hello"
  //  !="Hello"
  //  & 0F04
  // Alternative 1: (Signed Decimal(Zero or Once))(whitespaces)(..(Zero or Once))(whitespaces)(Signed Decimal(Zero or Once))
  // Alternative 2: (!= or >= or > or <= or <(Once))(spaces)(Signed Decimal(Once))
  // Alternative 3: (& (Once))(spaces)(AlphaNumeric(a to f or A to F))
  private static rangePattern: RegExp = undefined; // Moved inside Parse to satisfy ng compiler
  private _rangeType: RangeType = RangeType.None;
  private _value1: any = undefined;
  private _value2: any = undefined;

  /**
   *
   * @param value The value to be parsed
   * @returns
   */
  public static Parse(value: string): Range {
    if (Range.rangePattern === undefined) {
      // eslint-disable-next-line
            Range.rangePattern = XRegExp("^(\
                (?<value1>[-+]?[0-9]*(\.|\,)?[0-9]+)?\s*(?<op>(\.\.))?\s*(?<value2>[-+]?[0-9]*(\.|\,)?[0-9]+)?\
                | (?<op1>(\!\=|\>\=|\>|\<\=|\<))\s*(?<value12>[-+]?[0-9]*(\.|\,)?[0-9]+)\
                | (?<op2>(\&))\s*(?<value13>[0-9a-fA-F]+))$");
    }

    value = value.trim();
    const matches: any[] = XRegExp.match(value, Range.rangePattern, 'all');
    if (matches.length === 0 || matches.length > 1) {
      throw new Error('No literal or equation found. Following values are allowed:'
                + '\n' + 'N, N;N, <N, <=N, >N, >=N, !=N, N..N, N.., ..N, .., &X, "Text", <empty>'
                + '\n' + 'where N stands for an analog number'
                + '\n' + 'and X stands for a hexadecimal number'
                + '\n' + 'Individual parts can be separated by a ";" (semicolon)');
    }

    let operator = '';
    let value1 = '';
    let value2 = '';

    if (matches[0].hasOwnProperty('op')) { // Match for Alternative 1
      operator = matches[0].op.toString();
      value1 = matches[0].value1.toString();
      value2 = matches[0].value2.toString();
    } else if (matches[0].hasOwnProperty('op1')) { // Match for Alternative 2
      operator = matches[0].op1.toString();
      value1 = matches[0].value12.toString();
    } else if (matches[0].hasOwnProperty('op2')) { // Match for Alternative 3
      operator = matches[0].op2.toString();
      value1 = matches[0].value13.toString();
    }

    const range: Range = new Range();

    switch (operator) {
      case '<':
        range._rangeType = RangeType.SmallerThan;
        break;
      case '<=':
        range._rangeType = RangeType.SmallerThanOrEqual;
        break;
      case '>':
        range._rangeType = RangeType.GreaterThan;
        break;
      case '>=':
        range._rangeType = RangeType.GreaterThanOrEqual;
        break;
      case '!=':
        range._rangeType = RangeType.NotEqual;
        break;
      case '..':
        range._rangeType = RangeType.Range;
        break;
      case '&':
        range._rangeType = RangeType.And;
        break;

      default:
        range._rangeType = RangeType.Equal;
        range._value1 = value; // raw value as string
        range._value2 = undefined;

        value = Utility.ConvertDecimalSeparator(value);
        const parsedValue: number = parseFloat(value);
        if (!Number.isNaN(parsedValue)) {
          range._value2 = parsedValue;
        }
        return range;
    }

    range._value1 = undefined;
    range._value2 = undefined;

    if (range._rangeType !== RangeType.And) {
      value1 = Utility.ConvertDecimalSeparator(value1);
      const parsedValue1: number = parseFloat(value1);
      if (!Number.isNaN(parsedValue1)) {
        range._value1 = parsedValue1;
      }

      value2 = Utility.ConvertDecimalSeparator(value2);
      const parsedValue2: number = parseFloat(value2);
      if (Number.isNaN(parsedValue2)) {
        // make sure that _value1 is smaller than _value2 (if both exist)
        if (range._value1 !== undefined && parsedValue1 > parsedValue2) {
          range._value1 = parsedValue2;
          range._value2 = parsedValue1;
        } else {
          range._value2 = parsedValue2;
        }
      }
    } else { // RangeType == And
      const parsedValue1: number = parseInt(value1, 16); // Hex parse TBD test it thoroughly
      if (!Number.isNaN(parsedValue1)) {
        range._value1 = parsedValue1;
      }
    }

    return range;
  }

  /**
   * Converts a string representation of a range.
   * @param value The value to be parsed
   * @returns A collection of Ranges
   */
  public static ParseImproved(value: string): KeyValuePair<string, Range[]> {
    const result: KeyValuePair<string, Range[]> = new KeyValuePair<string, Range[]>(undefined, undefined);
    let errorMessage: string;

    if (value === undefined) {
      return undefined;
    }
    value = value.trim();
    if (value.length === 0) {
      return undefined;
    }

    const ranges: Range[] = new Array<Range>();

    // first check the string
    if (value.length > 1 && Utility.IsCharStringLiteral(value[value.length - 1])) {
      if (Utility.IsCharStringLiteral(value[0])) {
        ranges.push(new Range(RangeType.Equal, value.substring(1, value.length - 1))); // e.g. '"Hello"'
      } else if (value.startsWith('!=')) { // TBD locale info might be needed
        ranges.push(new Range(RangeType.NotEqual, value.substring(3, value.length)
          .trim().replace(/^[\"]+/g, '').replace(/[\"]+$/g, ''))); // e.g. '!="Hello"'
      }
      result.Value = ranges;
      return result;
    }

    let value1 = '';
    let value2 = '';

    let c: string;
    let index = 0;
    let startIndex: number;
    let tempRange: Range;
    const notAValidRange = 'Not a valid range';
    while (index < value.length) {
      c = value[index];

      if (c === '&') {
        index++;
        while (index < value.length && value[index] === ' ') {
          index++;
        }

        if (index === value.length - 1 && value[index] === ' ') {
          return undefined;
        }
        if (value[index] === ';') {
          continue;
        }

        startIndex = index;

        while (index < value.length && Utility.IsHexDigit(value[index]) && value[index] !== ';') {
          index++;
        }

        tempRange = new Range();

        const parsedValue1: number = parseInt(value.substring(startIndex, index), 16); // Hex parse need to be tested
        if (!Number.isNaN(parsedValue1)) {
          tempRange._value1 = parsedValue1;
        } else {
          errorMessage = value.substring(startIndex, index) + ' is not a valid hex number';
          return undefined;
        }

        tempRange._rangeType = RangeType.And;

        ranges.push(tempRange);
        value1 = '';
        value2 = '';
      } else if (c === '<' || c === '>' || c === '!' || c === '=') {
        tempRange = new Range();

        index++;

        if (c === '<') {
          if (value[index] === '=') {
            tempRange._rangeType = RangeType.SmallerThanOrEqual;
          } else {
            tempRange._rangeType = RangeType.SmallerThan;
          }
        } else if (c === '>') {
          if (value[index] === '=') {
            tempRange._rangeType = RangeType.GreaterThanOrEqual;
          } else {
            tempRange._rangeType = RangeType.GreaterThan;
          }
        } else if (c === '!') {
          if (value[index] === '=') {
            tempRange._rangeType = RangeType.NotEqual;
          } else {
            errorMessage = 'Not a valid RangeType';
            return undefined;
          }
        } else if (c === '=') {
          if (value[index] === '=') {
            tempRange._rangeType = RangeType.Equal;
          } else {
            errorMessage = 'Not a valid RangeType';
            return undefined;
          }
        } else {
          throw new Error('code error');
        }

        if (value[index] === '=') {
          index++;
        }

        while (index < value.length && value[index] === ' ') {
          index++;
        }

        if (index === value.length && value[index] !== ';') {
          return undefined;
        }

        startIndex = index;

        while (index < value.length && (Utility.IsNumeric(value[index]) || value[index] === '.' || value[index] === ','
                    || (value[index] === '.' && index !== value.length && value[index + 1] !== '.') || value[index] === '+'
                    || value[index] === '-') && value[index] !== ';') {
          index++;
        }

        if (index < value.length) {
          if (!Utility.IsNumeric(value[index]) && value[index] !== '+' && value[index] !== '-'
                        && value[index] !== ',' && value[index] !== ';' && value[index] !== ' ' && value[index] !== '.') {
            errorMessage = notAValidRange;
            return undefined;
          }
        }

        value1 = value.substring(startIndex, index);

        value1 = Utility.ConvertDecimalSeparator(value1);
        const parsedValue1: number = parseFloat(value1);
        if (!Number.isNaN(parsedValue1)) {
          tempRange._value1 = parsedValue1;
        }

        value2 = Utility.ConvertDecimalSeparator(value2);
        const parsedValue2: number = parseFloat(value2);
        if (!Number.isNaN(parsedValue2)) {
          // make sure that _value1 is smaller than _value2 (if both exist)
          if (tempRange._value1 !== undefined && parsedValue1 > parsedValue2) {
            tempRange._value1 = parsedValue2;
            tempRange._value2 = parsedValue1;
          } else {
            tempRange._value2 = parsedValue2;
          }
        }

        ranges.push(tempRange);
        value1 = '';
        value2 = '';
      } else if (c !== ' ') {
        tempRange = new Range();

        if (Utility.IsNumeric(value[index]) || value[index] === '+' || value[index] === '-' || value[index] === ','
                    || (value[index] === '.' && index !== value.length && value[index + 1] !== '.')) {
          tempRange._rangeType = RangeType.Equal;
        } else if (value[index] === '.' && index !== value.length && value[index + 1] === '.') {
          tempRange._rangeType = RangeType.Range;
        }

        if (tempRange._rangeType === RangeType.Equal) {
          startIndex = index;
          let decimalPoint = false;
          while (index < value.length && (Utility.IsNumeric(value[index]) || value[index] === '+' || value[index] === '-'
                        || (value[index] === '.' && index !== value.length && value[index + 1] !== '.') || value[index] === ',') && value[index] !== ';') {
            index++;

            if (index < value.length) {
              if ((value[index] === '.' || value[index] === ',')) {
                if (decimalPoint && !(index + 1 < value.length && value[index + 1] === '.')) {
                  result.Key = 'Not a valid decimal number'; // decimal point has already been set. invalid number
                  return result;
                } else if (index + 1 < value.length && Utility.IsNumeric(value[index + 1])) {
                  decimalPoint = true;
                }
              }
            }
          }

          decimalPoint = false;

          if (index < value.length) {
            if (!Utility.IsNumeric(value[index]) && value[index] !== '+' && value[index] !== '-'
                            && value[index] !== ',' && value[index + 1] !== '.' && value[index] !== ';' && value[index] !== ' ') {
              result.Key = notAValidRange;
              return result;
            }
          }

          value1 = value.substring(startIndex, index);

          if (index < value.length) {
            while (index < value.length && value[index] === ' ') {
              index++;
            }

            if (value[index] === '.' && index !== value.length && value[index + 1] === '.') {
              tempRange._rangeType = RangeType.Range;
            } else if (value[index] !== ';') {
              result.Key = notAValidRange;
              return result;
            }
          }

          if (tempRange._rangeType === RangeType.Equal) {
            tempRange._value1 = value1; // raw value as string
            tempRange._value2 = undefined;

            value1 = Utility.ConvertDecimalSeparator(value1);
            const parsedValue: number = parseFloat(value1);
            if (!Number.isNaN(parsedValue)) {
              tempRange._value2 = parsedValue;
            }
          }
        }

        if (tempRange._rangeType === RangeType.Range) {
          let maxIterations = 2;
          while (index < value.length && value[index] === '.') {
            if (maxIterations < 1) {
              result.Key = 'Invalid operator';
              return result;
            }

            index++;

            if (index < value.length && value[index] === '.') {
              maxIterations--;
            }
          }

          while (index < value.length && value[index] === ' ') {
            index++;
          }

          startIndex = index;

          while (index < value.length && (Utility.IsNumeric(value[index]) || value[index] === '.' || value[index] === ','
                        || value[index] === '+' || value[index] === '-') && value[index] !== ';') {
            index++;
          }

          value2 = value.substring(startIndex, index);

          value1 = Utility.ConvertDecimalSeparator(value1);
          const parsedValue1: number = parseFloat(value1);
          if (!Number.isNaN(parsedValue1)) {
            tempRange._value1 = parsedValue1;
          }

          value2 = Utility.ConvertDecimalSeparator(value2);
          const parsedValue2: number = parseFloat(value2);
          if (!Number.isNaN(parsedValue2)) {
            // make sure that _value1 is smaller than _value2 (if both exist)
            if (tempRange._value1 !== undefined && parsedValue1 > parsedValue2) {
              tempRange._value1 = parsedValue2;
              tempRange._value2 = parsedValue1;
            } else {
              tempRange._value2 = parsedValue2;
            }
          }
        }

        value1 = '';
        value2 = '';

        if (tempRange._rangeType === RangeType.Range || tempRange._rangeType === RangeType.Equal) {
          ranges.push(tempRange);
        } else {
          result.Key = 'Couldn\'t determine RangeType';
          return result;
        }
      }

      index++;
    }

    if (ranges.length === 0) {
      return result;
    }
    result.Value = ranges;
    return result;
  }

  // Gets the type of range
  public get RangeType(): RangeType {
    return this._rangeType;
  }

  // Gets a value indicating whether the range is of type equal
  public IsSingleValue(): boolean {
    return this._rangeType === RangeType.Equal;
  }

  /**
   *
   * @param rangeType The type of range
   * @param value1 The first value
   * @param value2 The second value
   */
  public constructor(rangeType: RangeType = RangeType.None, value1: any = undefined, value2: any = undefined) {
    this._rangeType = rangeType;
    this._value1 = value1;
    this._value2 = value2;
  }

  /**
   * Compares the value (or into double converted value) against the range value1 (and value2)
   * @param value The raw expression value
   * @param convertedValue The expression value as double
   * @returns True if the condition is true
   */
  public CompareTo(value: any, convertedValue: number): boolean {
    if (this._rangeType === RangeType.Equal) {
      return !Number.isNaN(parseFloat(this._value2)) ? this._value2 === convertedValue : this._value1.toString() === value.toString();
    } else if (this._rangeType === RangeType.NotEqual) {
      return !Number.isNaN(parseFloat(this._value2)) ? this._value2 !== convertedValue : this._value1.toString() !== value.toString();
    } else if (this._rangeType === RangeType.SmallerThan) {
      return convertedValue < this._value1;
    } else if (this._rangeType === RangeType.SmallerThanOrEqual) {
      return convertedValue <= this._value1;
    } else if (this._rangeType === RangeType.GreaterThan) {
      return convertedValue > this._value1;
    } else if (this._rangeType === RangeType.GreaterThanOrEqual) {
      return convertedValue >= this._value1;
    } else if (this._rangeType === RangeType.Range) {
      const value1: number = parseFloat(this._value1);
      const value2: number = parseFloat(this._value2);

      return (isNaN(value1) || convertedValue >= value1) && (isNaN(value2) || convertedValue <= value2);
    } else if (this._rangeType === RangeType.And) {
      const value1: number = parseFloat(this._value1);
      value = parseFloat(value);
      // eslint-disable-next-line no-bitwise
      return !isNaN(value) && !isNaN(value1) && value1 !== 0 && (value & value1) === (value1);
    }

    return false;
  }

  /**
   * Calculates the middle value
   * @returns The middle value
   */
  public GetMiddleValue(): number {
    let middeValue: number;
    switch (this._rangeType) {
      case RangeType.Range:
        if (this._value1 === undefined) {
          middeValue = this._value2;
        } else if (this._value2 === undefined) {
          middeValue = this._value1;
        } else {
          middeValue = this._value1 + this._value2 / 2;
        }
        break;

      case RangeType.GreaterThan:
        middeValue = this._value1 + 1;
        break;
      case RangeType.NotEqual:
        middeValue = this._value1 + 1;
        break;

      case RangeType.GreaterThanOrEqual:
        middeValue = this._value1;
        break;
      case RangeType.SmallerThanOrEqual:
        middeValue = this._value1;
        break;

      case RangeType.SmallerThan:
        middeValue = this._value1 - 1;
        break;
      default:
        middeValue = this._value1; // return the condition value (which will be written to the datapoint)
    }

    return middeValue;
  }

  public Clear(): void {
    this._value1 = undefined;
    this._value2 = undefined;
  }
}
