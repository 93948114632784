import { Injectable } from '@angular/core';
import { EventCategoriesProxyServiceBase, EventCategoryWsi, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Observable, of } from 'rxjs';

import { EventCategoryBx } from '../../bx-services/alarm/events-proxy.model';

@Injectable()
export class EventCategoriesBxSubstituteProxyService extends EventCategoriesProxyServiceBase {

  private readonly categoryPerName: Map<EventCategoryBx, EventCategoryWsi> = new Map<EventCategoryBx, EventCategoryWsi>();

  public constructor(
    private readonly traceService: TraceService) {
    super();

    // TODO: Localization
    /* eslint-disable @typescript-eslint/naming-convention */
    this.categoryPerName.set(EventCategoryBx.Emergency, { CategoryId: 1, CategoryName: 'Emergency' });
    this.categoryPerName.set(EventCategoryBx.LifeSafety, { CategoryId: 2, CategoryName: 'Life Safety' });
    this.categoryPerName.set(EventCategoryBx.Security, { CategoryId: 3, CategoryName: 'Security' });
    this.categoryPerName.set(EventCategoryBx.Supervisory, { CategoryId: 4, CategoryName: 'Supervisory' });
    this.categoryPerName.set(EventCategoryBx.Trouble, { CategoryId: 5, CategoryName: 'Trouble' });
    this.categoryPerName.set(EventCategoryBx.High, { CategoryId: 6, CategoryName: 'High' });
    this.categoryPerName.set(EventCategoryBx.Medium, { CategoryId: 7, CategoryName: 'Medium' });
    this.categoryPerName.set(EventCategoryBx.Low, { CategoryId: 8, CategoryName: 'Low' });
    this.categoryPerName.set(EventCategoryBx.Fault, { CategoryId: 9, CategoryName: 'Fault' });
    this.categoryPerName.set(EventCategoryBx.Status, { CategoryId: 10, CategoryName: 'Status' });
    this.categoryPerName.set(EventCategoryBx.Unknown, { CategoryId: 11, CategoryName: 'None' });
    /* eslint-enable @typescript-eslint/naming-convention */

    this.traceService.info(TraceModules.events, 'EventCategoriesBxSubstituteProxyService created.');
  }

  public getEventCategories(): Observable<EventCategoryWsi[]> {
    this.traceService.info(TraceModules.events, 'getEventCategories() called.');
    const categories: EventCategoryWsi[] = [];
    this.categoryPerName.forEach(value => categories.push(value));
    return of(categories);
  }

  public getCategory(name: EventCategoryBx): EventCategoryWsi {
    if (this.categoryPerName.has(name)) {
      return this.categoryPerName.get(name);
    } else {
      this.traceService.error(TraceModules.events, `EventCategoriesBxSubstituteProxyService.getCategory(): Unknown category: ${name}`);
    }
  }
}
