import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlarmConfigAttributes, BinaryPointConfiguration } from "@gms-flex/services";
import { SelectOption } from "@simpl/element-ng";
import { PointBxWithValue } from "src/app/bx-services/point/point.model";

import { PointHelper } from "../shared/point-helper";

@Component({
  selector: "point-alarm-binary",
  templateUrl: "./point-alarm-binary.component.html",
  styleUrl: "./point-alarm-binary.component.scss"
})
export class PointAlarmBinaryComponent implements OnInit {
  @Input() public pointAlarmConfig: AlarmConfigAttributes;
  @Input() public point: PointBxWithValue;
  @Input() public translatedLabels: Map<string, string>;
  @Output() public readonly newBinaryEvent = new EventEmitter<BinaryPointConfiguration>();

  public binaryValues: SelectOption[] = [];
  public alarmData: BinaryPointConfiguration;
  public value: string;

  public ngOnInit(): void {
    this.alarmData = (this.pointAlarmConfig.configuration as BinaryPointConfiguration) ?? undefined;

    this.value = String(this.alarmData?.binaryPointAlarmConfiguration.alarmValue);
    this.binaryValues = PointHelper.getStatesOptions(this.point);
  }

  public onSelect(val: any): void {
    const valueToEmit: BinaryPointConfiguration = {
      binaryPointAlarmConfiguration: {
        alarmValue: val === 'true' ? true : false
      }
    }

    this.newBinaryEvent.emit(valueToEmit);
  }
}
