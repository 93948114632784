<ul class="list-unstyled d-flex flex-column mb-0">
  @for (step of steps; track $index) {
    <li
      class="position-relative d-flex align-items-center timeline px-6 py-5"
      [class.text-primary]="step.state === 'Running'"
    >
      @if (step.state === 'Running') {
        <si-loading-spinner class="me-4" />
      }
      @if (step.icon && step.state !== 'Running') {
        <si-icon class="me-4" [icon]="step.icon" />
      }
      @if (!step.icon && step.state !== 'Running') {
        @switch (step.state) {
          @case (resultDetailStepState.Passed) {
            <si-icon
              class="me-4"
              icon="element-circle-filled"
              color="status-success"
              stackedIcon="element-state-tick"
              stackedColor="status-critical-contrast"
            />
          }
          @case (resultDetailStepState.Failed) {
            <si-icon
              class="me-4"
              icon="element-circle-filled"
              color="status-danger"
              stackedIcon="element-state-exclamation-mark"
              stackedColor="status-critical-contrast"
            />
          }
          @case (resultDetailStepState.NotStarted) {
            <si-icon class="me-4" icon="element-not-checked" />
          }
          @default {
            <si-icon class="me-4" icon="element-out-of-service" />
          }
        }
      }
      <div class="d-flex flex-column justify-content-center w-100">
        <div class="d-flex">
          <span class="result-description si-title-2">{{ step.description | translate }}</span>
          @if (step.value) {
            <span class="result-value">{{ step.value }}</span>
          }
        </div>
        @if (step.errorMessage) {
          <span class="si-title-2 text-danger">{{ step.errorMessage | translate }}</span>
        }
      </div>
    </li>
  }
</ul>
