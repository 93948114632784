@switch (property.value?.type) {
  <!-- Basic -->
  @case ('big-number') {
    <si-big-number-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [allowValuesOutOfRange]="allowValuesOutOfRange"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('bitstring') {
    <si-bitstring-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('boolean') {
    <si-boolean-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('date') {
    <si-date-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [format]="dateFormat"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('date-time') {
    <si-datetime-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [dateFormat]="dateFormat"
      [timeFormat]="timeFormat"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('enum') {
    <si-enum-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('number') {
    <si-number-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [allowValuesOutOfRange]="allowValuesOutOfRange"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('string') {
    <si-string-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('time') {
    <si-time-property
      [ngClass]="propertyClass"
      [property]="$any(property)"
      [valueState]="valueState"
      [format]="timeFormat"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  @case ('time-duration') {
    <si-timeduration-property
      [ngClass]="propertyClass"
      [valueState]="valueState"
      [property]="$any(property)"
      [forceReadonly]="forceReadonly"
      (submitted)="submit($event)"
    />
  }
  <!-- Composition -->
  @case ('composition') {
    @switch (property.value?.kind) {
      @case ('blind') {
        <si-blind-property
          [ngClass]="propertyClass"
          [property]="$any(property)"
          [valueState]="valueState"
          [allowValuesOutOfRange]="allowValuesOutOfRange"
          [forceReadonly]="forceReadonly"
          (submitted)="submit($event)"
        />
      }
      @case ('bacnet-object-identifier') {
        <si-objectid-property
          [ngClass]="propertyClass"
          [property]="$any(property)"
          [valueState]="valueState"
          [forceReadonly]="forceReadonly"
          (submitted)="submit($event)"
        />
      }
      @default {
        @if (customTemplate) {
          <ng-container
            *ngTemplateOutlet="
              customTemplate;
              context: { property: $any(property), valueState, submit: getSubmit }
            "
          />
        }
      }
    }
  }
  <!-- Collection -->
  @case ('collection') {
    @switch (property.value?.kind) {
      @case ('priority-array') {
        <si-priority-array-property
          [ngClass]="propertyClass"
          [property]="$any(property)"
          [valueState]="valueState"
          [dateFormat]="dateFormat"
          [timeFormat]="timeFormat"
          [customToggle]="displayChildren()"
          [forceReadonly]="forceReadonly"
          (submitted)="submit($event)"
        />
      }
      @case (undefined) {
        <si-collection-property
          [ngClass]="propertyClass"
          [property]="$any(property)"
          [valueState]="valueState"
          [dateFormat]="dateFormat"
          [timeFormat]="timeFormat"
          [customToggle]="displayChildren()"
          [forceReadonly]="forceReadonly"
          (submitted)="submit($event)"
        />
      }
      @default {
        @if (customTemplate) {
          <ng-container
            *ngTemplateOutlet="
              customTemplate;
              context: { property: $any(property), valueState, submit: getSubmit }
            "
          />
        } @else {
          <si-collection-property
            [ngClass]="propertyClass"
            [property]="$any(property)"
            [valueState]="valueState"
            [dateFormat]="dateFormat"
            [timeFormat]="timeFormat"
            [customToggle]="displayChildren()"
            (submitted)="submit($event)"
          />
        }
      }
    }
    @if (displayChildren()) {
      <span
        aria-hidden="true"
        class="icon dropdown-caret"
        [ngClass]="childrenExpanded() ? 'element-up-2' : 'element-down-2'"
      ></span>
    }
  }
  @default {
    <ng-container *ngTemplateOutlet="default" />
  }
}

<ng-template #default>
  <si-default-property [ngClass]="propertyClass" [property]="$any(property)" />
</ng-template>
