import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GmsButtons } from '../elements/gms-buttons';
import { GmsElement } from '../elements/gms-element';

@Injectable()
export class GmsButtonsService {
  public buttonsChanged: Subject<string> = new Subject<string>();
  private buttonElementsRef: GmsButtons[];

  public setButtonsService(buttonElements: GmsButtons[]): void {
    this.buttonElementsRef = buttonElements;
  }
  // NOTE: specify command control
  public createButtons(commandControlElement: GmsElement): void {
    if (commandControlElement !== undefined) {
      const buttons: GmsButtons = new GmsButtons(commandControlElement);
      this.buttonElementsRef.push(buttons);
      commandControlElement.buttons = buttons;
    }
  }
  public RemoveButtons(button: GmsButtons): void {
    const index: number = this.buttonElementsRef.indexOf(button);
    if (index > -1) {
      this.buttonElementsRef.splice(index, 1);
      this.NotifyButtonsChanged('Buttons Removed');
    }
  }

  public NotifyButtonsChanged(propertyName: string = ''): void {
    if (this.buttonsChanged.observers !== null && this.buttonsChanged.observers.length > 0) {
      this.buttonsChanged.next(propertyName);
    }
  }
}
