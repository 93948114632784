import { Component, Input } from '@angular/core';

import { GmsElement } from '../../elements/gms-element';
import { GmsGraphic } from '../../elements/gms-graphic';
import { GmsLayer } from '../../elements/gms-layer';

@Component({
  selector: '[gms-menu-layers]',
  template: `<label class="gms-menu-item-title">{{ 'FILTER_MENU.LAYERS' | translate }}</label>
               <label style="position: absolute; top: 12px; right: 5px; font-weight: 400; cursor: pointer;"
                class="show-all-option"
                (click)="ToggleVisiblityOfLayers()">
                    {{ (IsShowAllOption ? "FILTER_MENU.SHOW_ALL" : "FILTER_MENU.HIDE_ALL") | translate }}
               </label>
               <ul class="top-level-list">
                <ng-container *ngFor="let layer of Layers; let layerIndex = index;">
                 <li class="graphics-menu-item"
                     *ngIf="layer.Depths !== undefined && layer.Depths.length > 0"
                     (click)="OnMouseClick(layer)"
                     [style.opacity]="layer.DesignValueVisible ? null : '0.4'">
                     <label style="margin-right: 30px !important;">
                     {{ layer.Description }}
                     </label>
                     <label
                        #currLayer class="checkmark"
                        style="position: absolute; right: 0px;"
                        [style.display]="layer.Visible || layer.DesignValueVisible === false ? null : 'none'">
                    &#10003;
                    </label>
                 </li>
                </ng-container>
               </ul>`,
  styleUrl: './gms-menu.scss'
})

export class GmsMenuLayersComponent {
  @Input() public graphic: GmsGraphic;

  public get Layers(): GmsLayer[] {
    if (this.graphic !== undefined) {
      return this.graphic.children as GmsLayer[];
    }

    return [];
  }

  public get IsShowAllOption(): boolean {
    if (this.graphic !== undefined) {
      const layers: GmsLayer[] = this.graphic.children as GmsLayer[];
      for (const layer of layers) {
        if (layer.Visible === false && layer.DesignValueVisible) {
          return true;
        }
      }

      return false;
    }
  }

  public OnMouseClick(layer: GmsLayer): void {
    if (layer !== undefined) {
      this.setLayerVisiblity(layer, !layer.Visible);
    }
  }

  public setLayerVisiblity(layer: GmsElement, visible: boolean): void {
    if (layer.DesignValueVisible) {
      layer.Visible = visible;
    }
  }

  public ToggleVisiblityOfLayers(): void {
    const showLayers: boolean = this.IsShowAllOption;
    if (this.graphic !== undefined) {
      for (const layer of this.graphic.children) {
        this.setLayerVisiblity(layer, showLayers);
      }
    }
  }
}
