import { FocusableOption } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  ViewChild
} from '@angular/core';

import { SiTreeViewItemComponent } from '../si-tree-view-item/si-tree-view-item.component';
import { SiTreeViewComponent } from '../si-tree-view.component';
import { TREE_ITEM_CONTEXT } from './si-tree-view-item-next.directive';

export const SI_TREE_VIEW_ITEM_NEXT = new InjectionToken('SI_TREE_VIEW_ITEM_NEXT');

@Component({
  selector: 'si-tree-view-item-next',
  template: `<si-tree-view-item
    class="focus-none"
    [attr.tabindex]="treeItemContext.currentIndex === parent.activeIndex ? 0 : -1"
    [class.si-tree-ellipsis]="!parent.horizontalScrolling"
    [treeItem]="treeItem"
    [templates]="parent.templates"
    [scrollIntoView]="parent.scrollChildIntoView"
    [childrenLoaded]="parent.childrenLoaded"
    [indentLevel]="treeItem.level ?? 0"
    [icons]="parent.icons"
    [contextMenuItemsProvider]="parent.contextMenuItems"
    [stickyEndItems]="parent.horizontalScrolling"
    [displayFolderState]="parent.hasAnyChildren"
  />`,
  standalone: true,
  styleUrl: '../si-tree-view.component.scss',
  imports: [SiTreeViewItemComponent],
  changeDetection: ChangeDetectionStrategy.Default, // This must be ChangeDetectionStrategy.Default
  host: {
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '[class.mt-4]': '!parent.compactMode && (parent.flatTree || (treeItem.level ?? 0) < 1)'
  },
  providers: [
    {
      provide: SI_TREE_VIEW_ITEM_NEXT,
      useExisting: SiTreeViewItemNextComponent
    }
  ]
})
export class SiTreeViewItemNextComponent implements FocusableOption {
  protected treeItemContext = inject(TREE_ITEM_CONTEXT);
  protected parent = inject(SiTreeViewComponent);

  treeItem = this.treeItemContext.item;

  @ViewChild(SiTreeViewItemComponent) private siTreeViewItemComponent!: SiTreeViewItemComponent;

  focus(): void {
    this.siTreeViewItemComponent.focus();
  }
  getLabel(): string {
    return this.treeItem?.label ?? '';
  }
}
