/* eslint-disable @angular-eslint/directive-selector */
import { CdkMenuBar, CdkTargetMenuAim } from '@angular/cdk/menu';
import { Directive, HostBinding, inject, Input } from '@angular/core';

@Directive({
  selector: 'si-menu-bar',
  standalone: true,
  hostDirectives: [CdkMenuBar, CdkTargetMenuAim],
  host: {
    class: 'd-inline-flex'
  }
})
export class SiMenuBarDirective {
  private menuBar = inject(CdkMenuBar, { self: true });

  @HostBinding('tabindex')
  protected get tabIndex(): 0 | -1 | null {
    return this.disabled ? -1 : this.menuBar._getTabIndex();
  }

  /**
   * Sets the menu-bar disabled, i.e. sets tabindex="-1"
   */
  @Input() disabled?: boolean;
}
