import { isNullOrUndefined } from '@gms-flex/services-common';
import { parseLong, parseLongArr } from '@gms-flex/snapin-common';
import * as Long from 'long';

import { DateTimeType } from './command-vm.types';

/**
 * Property Types - indicates the source of the property (object model, indexed, function)
 */

export enum PropertySourceType {
  Type = 0,
  Indexed = 1,
  Functions = 2
}

/**
 * Property value types.
 */

export enum PropertyValueType {
  /**
   * String-type or enumerated values.
   * Also used as a catchall for complex or unsupported property types that will
   * be handled by default like strings.
   */
  StringValue = 0,

  NumericValue, // used for signed/unsigned 32-bit integers and float values (all easily convertable to `number`)

  Integer64Value, // need to distinguish between signed/unsigned 64-bit values to accomodate 3rd party lib (long.js)
  UnsignedInteger64Value,

  DateTimeValue,

  BACnetDateTimeValue,

  DurationValue,

  Bitstring32Value,

  Bitstring64Value,

  BooleanValue,

  EnumeratedValue
}

/**
 * Duration units.
 * Duration property values are represented as unsigned integer values.  The value
 * units may vary.
 */
export enum DurationUnits {
  Day = 0,
  Hour,
  Min,
  Sec,
  Dsec,
  Csec,
  Msec
}

/**
 * Duration display format.
 */
export enum DurationDisplayFormat {
  None = 0,
  Day,
  DayHour,
  DayHourMin,
  DayHourMinSec,
  DayHourMinSecMs,
  Hour,
  HourMin,
  HourMinSec,
  HourMinSecMs,
  Min,
  MinSec,
  MinSecMs,
  Sec,
  SecMs
}

export enum BACnetDateDayOfMonthSpecifier {
  Unspecified = 0,
  Absolute,
  OddDays,
  EvenDays,
  LastDay,
  Invalid
}

/**
 * Fields of a BACnet date-time object.
 */
export interface BACnetDateTime {
  yearOffset: number;
  month: number;
  dayOfMonth: number;
  dayOfWeek: number;
  hour: number;
  minute: number;
  second: number;
  hundreth: number;
}

/**
 * WSI data model helper class.
 * Provides static methods for encoding/decoding of data to/from the WSI.
 */
export class WsiTranslator {

  /**
   * Map a native property type string-value string to its equivalent
   * local property type enum-value.
   */
  public static toPropertyValueType(nativeType: string): PropertyValueType {
    let vType: PropertyValueType = PropertyValueType.StringValue;
    switch (nativeType) {
      case 'BasicInt':
      case 'BasicUint':
      case 'BasicChar':
      case 'ExtendedInt':
      case 'ExtendedUint':
      case 'BasicFloat':
      case 'ExtendedReal':
      case 'ExtendedAny': // underlying type of `any` is ExtendedReal
        vType = PropertyValueType.NumericValue;
        break;

      case 'BasicInt64':
      case 'ExtendedInt64':
        vType = PropertyValueType.Integer64Value;
        break;

      case 'BasicUint64':
      case 'ExtendedUint64':
        vType = PropertyValueType.UnsignedInteger64Value;
        break;

      case 'BasicTime':
        vType = PropertyValueType.DateTimeValue;
        break;

      case 'ExtendedDateTime':
        vType = PropertyValueType.BACnetDateTimeValue;
        break;

      case 'ExtendedDuration':
        vType = PropertyValueType.DurationValue;
        break;

      case 'BasicBit32':
      case 'ExtendedBitString':
        vType = PropertyValueType.Bitstring32Value;
        break;

      case 'BasicBit64':
      case 'ExtendedBitString64':
        vType = PropertyValueType.Bitstring64Value;
        break;

      case 'BasicBool':
      case 'ExtendedBool':
        vType = PropertyValueType.BooleanValue;
        break;

      case 'ExtendedEnum':
        vType = PropertyValueType.EnumeratedValue;
        break;

      case 'BasicChar':
      case 'BasicString':
      case 'BasicObjectOrPropertyId':
      case 'BasicLangText':
      case 'BasicBlob':
      case 'ExtendedApplSpecific':
      case 'ExtendedComplex':
      default:
        break;
    }
    return vType;
  }

  public static isNumericFloat(nativeType: string): boolean {
    if (nativeType === 'BasicFloat' ||
      nativeType === 'ExtendedReal' ||
      nativeType === 'ExtendedAny') {
      return true;
    }
    return false;
  }

  /**
   * Convert a display specialization numeric value from WSI to a local enum value.
   */
  /*
  public static toPropertyDisplaySpecialization(n: number): PropertyDisplaySpecialization {
    let spec: PropertyDisplaySpecialization;
    switch (n) {
      case 1:
        spec = PropertyDisplaySpecialization.PriorityArray;
        break;
      case 2:
        spec = PropertyDisplaySpecialization.EventTimeStamp;
        break;
      case 0:
      default:
        spec = PropertyDisplaySpecialization.None;
        break;
    }
    return spec;
  }
*/
  /**
   * Convert a duration units numeric value from WSI to a local enum value.
   */
  public static toDurationUnits(n: number): DurationUnits {
    let units: DurationUnits;
    switch (n) {
      case 1:
        units = DurationUnits.Day;
        break;
      case 2:
        units = DurationUnits.Hour;
        break;
      case 3:
        units = DurationUnits.Min;
        break;
      case 4:
        units = DurationUnits.Sec;
        break;
      case 5:
        units = DurationUnits.Dsec;
        break;
      case 6:
        units = DurationUnits.Csec;
        break;
      case 7:
        units = DurationUnits.Msec;
        break;
      default:
        // If not explicitly set or not valid, return `undefined`
        break;
    }
    return units;
  }

  /**
   * Convert a duration display format numeric value from WSI to a local enum value.
   */
  public static toDurationDisplayFormat(n: number): DurationDisplayFormat {
    let displayFmt: DurationDisplayFormat;
    switch (n) {
      case 1:
        displayFmt = DurationDisplayFormat.Day;
        break;
      case 2:
        displayFmt = DurationDisplayFormat.DayHour;
        break;
      case 3:
        displayFmt = DurationDisplayFormat.DayHourMin;
        break;
      case 4:
        displayFmt = DurationDisplayFormat.DayHourMinSec;
        break;
      case 5:
        displayFmt = DurationDisplayFormat.DayHourMinSecMs;
        break;
      case 6:
        displayFmt = DurationDisplayFormat.Hour;
        break;
      case 7:
        displayFmt = DurationDisplayFormat.HourMin;
        break;
      case 8:
        displayFmt = DurationDisplayFormat.HourMinSec;
        break;
      case 9:
        displayFmt = DurationDisplayFormat.HourMinSecMs;
        break;
      case 10:
        displayFmt = DurationDisplayFormat.Min;
        break;
      case 11:
        displayFmt = DurationDisplayFormat.MinSec;
        break;
      case 12:
        displayFmt = DurationDisplayFormat.MinSecMs;
        break;
      case 13:
        displayFmt = DurationDisplayFormat.Sec;
        break;
      case 14:
        displayFmt = DurationDisplayFormat.SecMs;
        break;
      case 0:
      default:
        displayFmt = DurationDisplayFormat.None;
        break;
    }
    return displayFmt;
  }

  /**
   * Convert a date-time display type numeric value from WSI to a local enum value.
   */
  public static toDateTimeType(n: number): DateTimeType {
    let dtType: DateTimeType;
    switch (n) {
      case 1:
        dtType = DateTimeType.DateOnly;
        break;
      case 2:
        dtType = DateTimeType.TimeOnly;
        break;
      case 0:
      default:
        dtType = DateTimeType.DateAndTime;
        break;
    }
    return dtType;
  }

  /**
   * Convert a BACnet date day-of-month field value to a local enum
   */
  public static toBACnetDateDayOfMonthSpecifier(n: number): BACnetDateDayOfMonthSpecifier {
    let spec: BACnetDateDayOfMonthSpecifier = BACnetDateDayOfMonthSpecifier.Unspecified;
    if (!isNaN(n)) {
      if (n > 0 && n < 34) {
        switch (n) {
          case 32:
            spec = BACnetDateDayOfMonthSpecifier.LastDay;
            break;
          case 33:
            spec = BACnetDateDayOfMonthSpecifier.OddDays;
            break;
          case 34:
            spec = BACnetDateDayOfMonthSpecifier.EvenDays;
            break;
          default:
            spec = BACnetDateDayOfMonthSpecifier.Absolute;
            break;
        }
      } else {
        spec = BACnetDateDayOfMonthSpecifier.Invalid;
      }
    }
    return spec;
  }

  /**
   * Parse a string value representing a JSON encoded array to an array of any type.
   */
  public static jsonStringToArray(s: string): any[] {
    let arr: any[];
    try {
      if (s) {
        const obj: any = JSON.parse(s);
        if (Array.isArray(obj)) {
          arr = obj as any[];
        }
      }
    } catch (e) {
      arr = undefined;
    }
    return arr;
  }

  /**
   * Format a BACnet date-time to a quasi-ISO date-time string.
   * This is an aid to the view-model and view components, which employ a UI component
   * that requires a string in this (odd) format.
   *
   * Example (BACnet date-time value provided in parts, but example shows it in string
   * form for simplicity):
   *
   *  "1180913F155629FF" ==> "2018-09-13T15:56:29.*"
   *  "118FF13FFFFFFFFF" ==> "2018-*-13T*:*:*.*"
   */
  public static formatQuasiISODateTime(bndt: BACnetDateTime): string {
    let qdt: string;

    if (bndt) {
      const yr: string = bndt.yearOffset !== undefined ? String(bndt.yearOffset + 1900) : '*';
      const mo: string = bndt.month !== undefined ? WsiTranslator.encodeBACnetDateTimeField(bndt.month, 2) : '*';
      const dom: string = bndt.dayOfMonth !== undefined ? WsiTranslator.encodeBACnetDateTimeField(bndt.dayOfMonth, 2) : '*';

      const hr: string = bndt.hour !== undefined ? WsiTranslator.encodeBACnetDateTimeField(bndt.hour, 2) : '*';
      const min: string = bndt.minute !== undefined ? WsiTranslator.encodeBACnetDateTimeField(bndt.minute, 2) : '*';
      const sec: string = bndt.second !== undefined ? WsiTranslator.encodeBACnetDateTimeField(bndt.second, 2) : '*';
      const hsec: string = bndt.hundreth !== undefined ? WsiTranslator.encodeBACnetDateTimeField(bndt.hundreth, 2) : '*';

      qdt = `${yr}-${mo}-${dom}T${hr}:${min}:${sec}.${hsec}`;
    }

    return qdt;
  }

  /**
   * Parse a quasi-ISO date-time string to a BACnet date-time string.
   * This is an aid to the view-model and view components, which employ a UI component
   * that requires a string in this (odd) format.
   *
   * See above format method for examples.
   */
  public static parseQuasiISODateTime(qdt: string): BACnetDateTime {
    let bndt: BACnetDateTime;
    if (qdt) {
      const parts: string[] = qdt.split('T');
      if (parts && parts.length >= 2) {
        const bndate: BACnetDateTime = WsiTranslator.parseQuasiISODate(parts[0]);
        const bntime: BACnetDateTime = WsiTranslator.parseQuasiISOTime(parts[1]);
        if (bndate && bntime) {
          bndt = {
            yearOffset: bndate.yearOffset,
            month: bndate.month,
            dayOfMonth: bndate.dayOfMonth,
            dayOfWeek: bndate.dayOfWeek,
            hour: bntime.hour,
            minute: bntime.minute,
            second: bntime.second,
            hundreth: bntime.hundreth
          };
        }
      }
    }
    return bndt;
  }

  /**
   * Simple encoding and decoding of a boolean value.
   */
  public static encodeBoolean(valBoolean: boolean): string {
    return JSON.stringify(Boolean(valBoolean));
  }

  public static decodeBoolean(val: any): boolean {
    let valBoolean = false;
    try {
      const valParsed: any = JSON.parse((val as string).toLowerCase());
      if (typeof (valParsed) === 'boolean') {
        valBoolean = Boolean(valParsed);
      }
    } catch (e) {
      valBoolean = false;
    }
    return valBoolean;
  }

  public static decodeBooleanArray(boolArrString: string): boolean[] {
    let booleanArr: boolean[];

    const objArr: any[] = WsiTranslator.jsonStringToArray(boolArrString);
    if (objArr) {
      booleanArr = objArr.map(obj => {
        let value: boolean;
        if (typeof obj === 'boolean' || obj instanceof Boolean) {
          value = WsiTranslator.decodeBoolean(String(obj));
        }
        return value;
      });
    }

    return booleanArr;
  }

  /**
   * Decode a string as a numeric value.
   *
   * If the string is undefined/empty or does not represent a numeric value, the return
   * value will be undefined.
   *
   * Optionally, the caller can specify a numeric value that should be treated as "undefined".
   * This is to support command priority array entries, which use valid numeric value (e.g., max-int)
   * to indicated undefined entries.
   */
  public static decodeNumeric(numString: string, explicitUndefinedValue?: string): number {
    let num: number;
    if (numString && numString !== explicitUndefinedValue) {
      num = Number(numString);
      if (isNaN(num)) {
        num = undefined; // does not convert to a number
      }
    }
    return num;
  }

  public static decodeNumericArray(numArrString: string, explicitUndefinedValue?: string): number[] {
    let numArr: number[];
    const objArr: any[] = WsiTranslator.jsonStringToArray(numArrString);
    if (objArr) {
      numArr = objArr.map(obj => {
        let num: number;
        if (typeof obj === 'number' || obj instanceof Number) {
          num = WsiTranslator.decodeNumeric(String(obj), explicitUndefinedValue);
        }
        return num;
      });
    }
    return numArr;
  }

  public static encodeNumeric(num: number): string {
    return isNaN(num) ? undefined : String(num);
  }

  /**
   * Decode a string as a long (64-bit) integer value.
   *
   * If the string is undefined/empty or does not represent a numeric value, the return
   * value will be undefined.
   *
   * Optionally, the caller can specify a value that should be treated as "undefined".
   * This is to support command priority array entries, which use valid numeric value (e.g., max-int)
   * to indicated undefined entries.
   */
  public static decodeInteger64(numString: string, isUnsigned: boolean, explicitUndefinedValue?: string): Long {
    let bigint: Long;
    if (numString && numString !== explicitUndefinedValue) {
      bigint = parseLong(numString, isUnsigned);
    }
    return bigint;
  }

  public static decodeInteger64Array(numArrString: string, isUnsigned: boolean, explicitUndefinedValue?: string): Long[] {
    let bigintArr: Long[];
    if (numArrString) {
      const bigIntUndefinedValue: Long = WsiTranslator.decodeInteger64(explicitUndefinedValue, isUnsigned);
      bigintArr = parseLongArr(numArrString, isUnsigned);

      if (bigintArr && bigIntUndefinedValue) {
        for (let idx = 0; idx < bigintArr.length; idx++) {
          if (bigintArr[idx].compare(bigIntUndefinedValue) === 0) {
            bigintArr[idx] = undefined;
          }
        }
      }
    }
    return bigintArr;
  }

  public static encodeInteger64(bigint: Long): string {
    return bigint ? bigint.toString() : undefined;
  }

  /**
   * Decode a string as a text array value.
   *
   * Text values from the server in some cases contain an array label (applies
   * to command priority arrays).  This will be stripped off when encountered.
   *
   * Optionally, the caller can specify a value that should be treated as "undefined".
   * This is to support command priority array entries, which use valid text values (e.g., "<NULL>")
   * to indicated undefined entries.
   */
  public static decodeText(textStr: string, explicitUndefinedValue?: string): string {
    let text: string;
    if (!isNullOrUndefined(textStr)) {
      text = textStr;
      const parts: string[] = text.split('@', 2);
      if (parts && parts.length > 0) {
        text = parts[0].trim();
      }
    }
    return text !== explicitUndefinedValue ? text : undefined;
  }

  public static decodeTextArray(textArrString: string, explicitUndefinedValue?: string): string[] {
    let textArr: string[];
    const objArr: any[] = WsiTranslator.jsonStringToArray(textArrString);
    if (objArr) {
      textArr = objArr.map(obj => {
        let text: string;
        if (typeof obj === 'string' || obj instanceof String) {
          text = WsiTranslator.decodeText(String(obj), explicitUndefinedValue);
        } else {
          text = WsiTranslator.decodeAny(obj);
        }
        return text;
      });
    }
    return textArr;
  }

  /**
   * Generic decoder.
   *
   * Decode any value type into a string.  This is used as a default decoder when no
   * other explicit type decoder applies.
   */
  public static decodeAny(obj: any): string {
    return !isNullOrUndefined(obj) ? String(obj) : undefined;
  }

  public static decodeAnyArray(objArrString: string): string[] {
    let strArr: string[];
    const objArr: any[] = WsiTranslator.jsonStringToArray(objArrString);
    if (objArr) {
      strArr = objArr.map(obj => WsiTranslator.decodeAny(obj));
    }
    return strArr;
  }

  /**
   * Decode a string as a date-time value.
   *
   * - WSI sends PVSS date-time values as ISO formatted strings in UTC time.
   *   For example, "2018-09-12T19:49:06.000Z"
   * - The Date.parse() method will recognize TimeZone information in the ISO
   *   string if present and will assume UTC if it is not.
   * - The format of the resulting date-time value is defined by the `ValueDateTime`
   *   interface in the si-element value-type library: "YYYY-MM-DD[ W]Thh:mm[:ss[.hs]]"
   * - The resulting date-time value does NOT container TimeZone information; it is
   *   assumed to be in local time by the consuming si-element UI component.
   * - First parsing the input string to a JS Date object allows for conversion from
   *   UTC to local time (reason for not converting directly from ISO-string to SI-string).
   */
  public static decodeDateTime(dtString: string): Date {
    let dt: Date;
    const dtMsec: number = Date.parse(dtString); // will return NaN if cannot parse date-time string
    if (!isNaN(dtMsec)) {
      dt = new Date(dtMsec);
    }
    return dt;
  }

  public static decodeDateTimeArray(dtArrString: string): Date[] {
    let dtArr: Date[];
    const objArr: any[] = WsiTranslator.jsonStringToArray(dtArrString);
    if (objArr) {
      dtArr = objArr.map(obj => WsiTranslator.decodeDateTime(obj));
      dtArr = objArr.map(obj => {
        let dt: Date;
        if (typeof obj === 'string' || obj instanceof String) {
          dt = WsiTranslator.decodeDateTime(String(obj));
        }
        return dt;
      });
    }
    return dtArr;
  }

  public static encodeDateTime(dt: Date): string {
    return dt ? dt.toISOString() : undefined;
  }

  /**
   * Decode a string as a BACnet date-time.
   *
   * YYYMMDDWhhmmssdd
   *
   * where:
   *  YYY is the year as an offset from 190
   *  MM  is the month number
   *  DD  is the day-of-month (w/ 32 = last, 33 = odd days, 34 = even days)
   *  W   is the day-of-week (1..7)
   *  hh  is the hour
   *  mm  is the minute
   *  ss  is the second
   *  dd  is the hundreths of second
   */
  public static decodeBACnetDateTime(bndtString: string, explicitUndefinedValue?: string): BACnetDateTime {
    let bndt: BACnetDateTime;
    const s: string = bndtString;
    if (s && s.length === 16 && s !== explicitUndefinedValue) {
      bndt = {
        yearOffset: WsiTranslator.decodeBACnetDateTimeField(s.substr(0, 3), 0, 254),
        month: WsiTranslator.decodeBACnetDateTimeField(s.substr(3, 2), 1, 14),
        dayOfMonth: WsiTranslator.decodeBACnetDateTimeField(s.substr(5, 2), 1, 34),
        dayOfWeek: WsiTranslator.decodeBACnetDateTimeField(s.substr(7, 1), 1, 7),
        hour: WsiTranslator.decodeBACnetDateTimeField(s.substr(8, 2), 0, 23),
        minute: WsiTranslator.decodeBACnetDateTimeField(s.substr(10, 2), 0, 59),
        second: WsiTranslator.decodeBACnetDateTimeField(s.substr(12, 2), 0, 59),
        hundreth: WsiTranslator.decodeBACnetDateTimeField(s.substr(14, 2), 0, 99)
      };
    }
    return bndt;
  }

  public static decodeBACnetDateTimeArray(bndtArrString: string, explicitUndefinedValue?: string): BACnetDateTime[] {
    let bndtArr: BACnetDateTime[];
    const objArr: any[] = WsiTranslator.jsonStringToArray(bndtArrString);
    if (objArr) {
      bndtArr = objArr.map(obj => {
        let bndt: BACnetDateTime;
        if (typeof obj === 'string' || obj instanceof String) {
          bndt = WsiTranslator.decodeBACnetDateTime(String(obj), explicitUndefinedValue);
        }
        return bndt;
      });
    }
    return bndtArr;
  }

  public static decodeBACnetDateTimeField(s: string, min: number, max: number): number {
    const n: number = parseInt(s, 10);
    if (!isNaN(n) && n >= min && n <= max) {
      return n;
    }
    return undefined;
  }

  public static encodeBACnetDateTime(bndt: BACnetDateTime): string {
    bndt = bndt || {} as BACnetDateTime;
    const bndtString: string =
      WsiTranslator.encodeBACnetDateTimeField(bndt.yearOffset, 3) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.month, 2) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.dayOfMonth, 2) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.dayOfWeek, 1) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.hour, 2) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.minute, 2) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.second, 2) +
      WsiTranslator.encodeBACnetDateTimeField(bndt.hundreth, 2);
    return bndtString;
  }

  public static encodeBACnetDateTimeField(num: number, len: number): string {
    let numStr: string;
    let padChar: string;
    len = Math.max(1, len);
    if (!isNaN(num)) {
      numStr = String(num);
      padChar = '0';
    }
    if (numStr === undefined || numStr.length > len) {
      numStr = 'F';
      padChar = 'F';
    }
    while (numStr.length < len) {
      numStr = padChar + numStr;
    }
    return numStr;
  }

  /**
   * Decode a string as a bitstring value.
   */
  public static decodeBitstring(bsString: string, size: number, offset: number): boolean[] {
    if (size < 0 || offset < 0 || size + offset > 64) {
      throw new Error('invalid arg');
    }
    const bitArr: boolean[] = [];
    const bs: Long = parseLong(bsString, true) || Long.UZERO;
    let mask: Long = Long.UONE.shiftLeft(offset);
    for (let idx = 0; idx < size; ++idx) {
      bitArr.push(bs.and(mask).notEquals(Long.UZERO));
      mask = mask.shiftLeft(1);
    }
    return bitArr;
  }

  /* eslint-disable no-bitwise */
  public static setBitValue(bsIn: number, offset: number, bitPos: number, bitValue: boolean): number {
    let bsOut: number;
    if (!isNaN(bsIn) && offset + bitPos < 32) {
      const bitMask: number = 1 << (offset + bitPos);
      if (bitValue) {
        bsOut = bsIn | bitMask; // turn bit on
      } else {
        bsOut = bsIn & ~bitMask; // turn bit off
      }
    }
    return bsOut;
  }
  /* eslint-enable no-bitwise */

  public static setBitValue64(bsIn: Long, offset: number, bitPos: number, bitValue: boolean): Long {
    let bsOut: Long;
    if (bsIn && offset + bitPos < 64) {
      const lmask: Long = Long.UONE.shiftLeft(offset + bitPos);
      if (bitValue) {
        bsOut = bsIn.or(lmask); // turn bit on
      } else {
        bsOut = bsIn.and(lmask.not()); // turn bit off
      }
    }
    return bsOut;
  }

  public static encodeBitstring(bs: number): string {
    // eslint-disable-next-line no-bitwise
    return !isNaN(bs) ? String(bs >>> 0) : undefined; // zero-fill right shift operator (>>>) applied to ensure an unsigned value
  }

  // 64-bit version
  public static encodeBitstring64(bs: Long): string {
    let ubs: Long;
    if (bs) {
      ubs = new Long(bs.low, bs.high, true); // ensure unsigned value
    }
    return ubs !== undefined ? ubs.toString() : undefined;
  }

  /**
   * Methods below are obsolete and should be removed.
   */
  private static parseQuasiISODate(qdate: string): BACnetDateTime {
    let bndate: BACnetDateTime;
    if (qdate) {
      const parts: string[] = qdate.split(qdate.includes('-') ? '-' : '.');
      if (parts && parts.length >= 3) {
        const yr: number = parseInt(parts[0], 10);
        const mo: number = parseInt(parts[1], 10);
        const dom: number = parseInt(parts[2], 10);
        bndate = {
          yearOffset: isNaN(yr) ? undefined : yr - 1900,
          month: isNaN(mo) ? undefined : mo,
          dayOfMonth: isNaN(dom) ? undefined : dom,
          dayOfWeek: undefined,
          hour: undefined,
          minute: undefined,
          second: undefined,
          hundreth: undefined
        };
      }
    }
    return bndate;
  }

  private static parseQuasiISOTime(qtime: string): BACnetDateTime {
    let bntime: BACnetDateTime;
    if (qtime) {
      const parts: string[] = qtime.split(':');
      if (parts && parts.length >= 3) {
        const hr: number = parseInt(parts[0], 10);
        const min: number = parseInt(parts[1], 10);
        const sec: any = WsiTranslator.parseQuasiISOSec(parts[2]);
        bntime = {
          yearOffset: undefined,
          month: undefined,
          dayOfMonth: undefined,
          dayOfWeek: undefined,
          hour: isNaN(hr) ? undefined : hr,
          minute: isNaN(min) ? undefined : min,
          second: sec ? sec.seconds : undefined,
          hundreth: sec ? sec.hundreths : undefined
        };
      }
    }
    return bntime;
  }

  // Convert a substring (of a quasi-ISO date-time string) representing seconds to its
  // whole number and fractional part in hundreths of a second.
  // NOTE: Fractional seconds will be rounded DOWN to nearest hundreths of a second.
  //  Rounding up would affect seconds which, in turn, would effect minutes (and so on).
  //  Because these time strings represent BACnet times (which support only hundreths of
  //  a second resolution), any precision beyond this can be reasonable ignored.
  // Examples,
  //  "12"           sec=12, hundreths=undefined
  //  "12.3"         sec=12, hundreths=30
  //  "12.34Z"       sec=12, hundreths=34
  //  "12.999Z"      sec=12, hundreths=99
  //  "12.Z"         sec=12, hundreths=undefined
  //  "12Z"          sec=12, hundreths=undefined
  //  "12+5:00"      sec=12, hundreths=undefined
  //  "12.349+5:00"  sec=12, hundreths=34
  //  "12.*Z"        sec=12, hundreths=undefined
  //  "Z"            sec=undefined, hundreths=undefined
  //  "*.34Z"        sec=undefined, hundreths=34
  private static parseQuasiISOSec(qsec: string): any {
    let sec: number;
    let hundreths: number;
    if (qsec) {
      const parts: string[] = qsec.split('.');
      sec = parseInt(parts[0], 10);
      if (parts[1]) {
        hundreths = parseInt(parts[1].padEnd(2, '0').substr(0, 2), 10);
      }
    }
    return {
      seconds: isNaN(sec) ? undefined : sec,
      hundreths: isNaN(hundreths) ? undefined : hundreths
    };
  }

}

export class SiTranslator {

  // SiMPL date-time string value format per si-element library:
  //
  //  <date-fmt>T<time-fmt>
  //
  // where:
  //  date-fmt = YYYY-MM-DD[ W]
  //  time-fmt = hh:mm[:ss[.hs]]
  //
  // (looks kinda like a date-time string in ISO standard format, but isn't)

  public static toBACnetDateTime(dt: Date): BACnetDateTime {
    let bndt: BACnetDateTime;
    if (dt && dt.getFullYear() >= 1900) {
      bndt = {
        yearOffset: dt.getFullYear() - 1900,
        month: dt.getMonth() + 1, // BACnet Jan=1, JS-Date Jan=0
        dayOfMonth: dt.getDate(),
        dayOfWeek: undefined,
        hour: dt.getHours(),
        minute: dt.getMinutes(),
        second: dt.getSeconds(),
        hundreth: Math.trunc(dt.getMilliseconds() / 10)
      };
    }
    return bndt;
  }

  public static toDate(bndt: BACnetDateTime): Date {
    let dt: Date;
    if (bndt) {
      // Date c'tor interprets fields as local time
      dt = new Date(
        isNaN(bndt.yearOffset) ? 1900 : 1900 + bndt.yearOffset,
        isNaN(bndt.month) ? 0 : bndt.month - 1, // BACnet Jan=1, JS-Date Jan=0
        isNaN(bndt.dayOfMonth) || bndt.dayOfMonth > 31 ? 1 : bndt.dayOfMonth,
        isNaN(bndt.hour) ? 0 : bndt.hour,
        isNaN(bndt.minute) ? 0 : bndt.minute,
        isNaN(bndt.second) ? 0 : bndt.second,
        isNaN(bndt.hundreth) ? 0 : bndt.hundreth * 10);
    }
    return dt;
  }

  public static formatSiDateTimeFromBACnet(bndt: BACnetDateTime): string {
    let sidt: string;
    if (bndt) {
      const sidate: string = SiTranslator.formatSiDateFromBACnet(bndt);
      const sitime: string = SiTranslator.formatSiTimeFromBACnet(bndt);
      sidt = `${sidate}T${sitime}`;
    }
    return sidt;
  }

  public static formatSiDateFromBACnet(bndt: BACnetDateTime): string {
    let sidate: string;
    if (bndt) {
      const yr: string = isNaN(bndt.yearOffset) ? '*' : SiTranslator.zeropad(String(bndt.yearOffset + 1900), 4);
      const mo: string = isNaN(bndt.month) ? '*' : SiTranslator.zeropad(String(bndt.month), 2);
      const dom: string = isNaN(bndt.dayOfMonth) ? '*' : SiTranslator.zeropad(String(bndt.dayOfMonth), 2);
      sidate = `${yr}-${mo}-${dom}`;
      if (!isNaN(bndt.dayOfWeek)) {
        sidate += ` ${bndt.dayOfWeek}`;
      }
    }
    return sidate;
  }

  public static formatSiTimeFromBACnet(bndt: BACnetDateTime): string {
    let sitime: string;
    if (bndt) {
      const hr: string = isNaN(bndt.hour) ? '*' : SiTranslator.zeropad(String(bndt.hour), 2);
      const min: string = isNaN(bndt.minute) ? '*' : SiTranslator.zeropad(String(bndt.minute), 2);
      sitime = `${hr}:${min}`;
      if (!isNaN(bndt.minute) || !isNaN(bndt.hundreth)) {
        const sec: string = isNaN(bndt.second) ? '*' : SiTranslator.zeropad(String(bndt.second), 2);
        sitime += `:${sec}`;
        if (!isNaN(bndt.hundreth)) {
          const hund: string = SiTranslator.zeropad(String(bndt.hundreth), 2);
          sitime += `.${hund}`;
        }
      }
    }
    return sitime;
  }

  public static parseSiDateTimeToBACnet(sidt: string): BACnetDateTime {
    let bndt: BACnetDateTime;
    if (sidt) {
      const parts: string[] = sidt.split('T');
      let datePart: string;
      let timePart: string;
      if (parts.length >= 2) {
        datePart = parts[0];
        timePart = parts[1];
      } else {
        if (parts[0].includes('-')) {
          datePart = parts[0];
        } else {
          timePart = parts[0];
        }
      }
      const bndate: BACnetDateTime = SiTranslator.parseSiDate(datePart);
      const bntime: BACnetDateTime = SiTranslator.parseSiTime(timePart);
      if (bndate || bntime) {
        bndt = {
          yearOffset: bndate?.yearOffset,
          month: bndate?.month,
          dayOfMonth: bndate?.dayOfMonth,
          dayOfWeek: bndate?.dayOfWeek,
          hour: bntime?.hour,
          minute: bntime?.minute,
          second: bntime?.second,
          hundreth: bntime?.hundreth
        };
      }
    }
    return bndt;
  }

  public static translateToSiBitstring(bsString: string, size: number, offset: number): number[] {
    const numberArray: number[] = [];

    if (bsString === undefined) {
      return undefined;
    } else {
      const bitBool: boolean[] = WsiTranslator.decodeBitstring(bsString, size, offset);

      for (let i = 0; i < bitBool.length; i++) {
        if (bitBool[i]) { numberArray.push(i); }
      }
    }

    return numberArray;
  }

  public static translateToSiBacnetDatetime(bndt: BACnetDateTime, datetimeFormat: DateTimeType): string {
    let sidt: string;

    switch (datetimeFormat) {
      case DateTimeType.DateOnly:
        sidt = SiTranslator.formatSiDateFromBACnet(bndt);
        break;
      case DateTimeType.TimeOnly:
        sidt = SiTranslator.formatSiTimeFromBACnet(bndt);
        break;
      case DateTimeType.DateAndTime:
      default:
        sidt = SiTranslator.formatSiDateTimeFromBACnet(bndt);
        break;
    }

    return sidt;
  }

  private static parseSiDate(sidate: string): BACnetDateTime {
    let bndate: BACnetDateTime;
    if (sidate) {
      const parts: string[] = sidate.split('-');
      if (parts && parts.length >= 3) {
        const yr: number = parseInt(parts[0], 10);
        const mo: number = parseInt(parts[1], 10);
        const doParts: string[] = parts[2].split(' ');
        const dom: number = parseInt(doParts[0], 10);
        let dow: number;
        if (doParts.length > 1) {
          dow = parseInt(doParts[1], 10);
        }
        bndate = {
          yearOffset: isNaN(yr) ? undefined : yr - 1900,
          month: isNaN(mo) ? undefined : mo,
          dayOfMonth: isNaN(dom) ? undefined : dom,
          dayOfWeek: isNaN(dow) ? undefined : dow,
          hour: undefined,
          minute: undefined,
          second: undefined,
          hundreth: undefined
        };
      }
    }
    return bndate;
  }

  private static parseSiTime(sitime: string): BACnetDateTime {
    let bntime: BACnetDateTime;
    if (sitime) {
      const parts: string[] = sitime.split(':');
      if (parts && parts.length >= 2) {
        const hr: number = parseInt(parts[0], 10);
        const min: number = parseInt(parts[1], 10);
        let sec: any;
        if (parts.length > 2) {
          sec = SiTranslator.parseSiSec(parts[2]);
        }
        bntime = {
          yearOffset: undefined,
          month: undefined,
          dayOfMonth: undefined,
          dayOfWeek: undefined,
          hour: isNaN(hr) ? undefined : hr,
          minute: isNaN(min) ? undefined : min,
          second: sec ? sec.seconds : undefined,
          hundreth: sec ? sec.hundreths : undefined
        };
      }
    }
    return bntime;
  }

  private static parseSiSec(sisec: string): any {
    let sec: number;
    let hundreths: number;
    if (sisec) {
      const parts: string[] = sisec.split('.');
      sec = parseInt(parts[0], 10);
      if (parts[1]) {
        hundreths = parseInt(parts[1].padEnd(2, '0').substr(0, 2), 10);
      }
    }
    return {
      seconds: isNaN(sec) ? undefined : sec,
      hundreths: isNaN(hundreths) ? undefined : hundreths
    };
  }

  private static zeropad(s: string, l: number): string {
    let str: string = s || '';
    const len: number = l || 0;
    while (str.length < len) {
      str = '0' + str;
    }
    return str;
  }

}
