<div class="modal-header">
  @if (icon) {
    <i class="icon" [ngClass]="icon"></i>
  }
  <span class="modal-title" [id]="titleId">{{ heading | translate: translationParams }}</span>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    [attr.aria-label]="confirmBtnName | translate"
    (click)="onConfirm()"
  >
  </button>
</div>
<div class="modal-body text-pre-wrap text-break">{{ message | translate: translationParams }}</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onConfirm()">{{
    confirmBtnName | translate
  }}</button>
</div>
