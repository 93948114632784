import { Directive, ElementRef, HostBinding, inject, Input } from '@angular/core';

import { Cell } from './si-calendar-body.component';
import { CompareAdapter } from './si-compare-adapter';

@Directive({
  selector: '[siCalendarDateCell]',
  standalone: true,
  host: {
    class: 'si-calendar-date-cell'
  }
})
export class SiCalendarDateCellDirective {
  @Input() cell!: Cell;
  @Input() compareAdapter!: CompareAdapter;
  ref = inject(ElementRef);

  @HostBinding('attr.aria-disabled')
  @HostBinding('class.disabled')
  protected get disabled(): boolean {
    return this.cell.disabled;
  }
  @HostBinding('attr.aria-label') protected get ariaLabel(): string {
    return this.cell.ariaLabel;
  }
  @HostBinding('class.today') protected get today(): boolean {
    return this.cell.isToday;
  }
  @HostBinding('attr.aria-current') protected get ariaToday(): string | null {
    return this.cell.isToday ? 'date' : null;
  }
}
