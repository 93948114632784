import { Observable } from 'rxjs';

import { ErrorDisplayItem } from './data.model';

/**
 * Base class for the error notification API.
 * Support the notifications of errors to the GUI.
 * Such error are displayed either as a modal dialog  with a message, blocking user inputs while active.
 * Or as a 'toast' with a message showing up for some seconds. The GUI is responsible for the toast timing!
 *
 */
export abstract class ErrorNotificationServiceBase {

  /**
   * To be used by the sender/owner of the error; typically a service.
   * Any state change of the error shall be notified with this method.
   * Note, that the 'id' property of the ErrorDisplayItem is used for error identification.
   *
   * @abstract
   * @param {ErrorDisplayItem} error
   * @memberof ErrorNotificationServiceBase
   */
  public abstract notifyErrorChange(error: ErrorDisplayItem): void;

  /**
   * Supports clients to subscribe for changes of error items.
   *
   * @readonly
   * @abstract
   * @type {Observable<ErrorDisplayItem>}
   * @memberof ErrorNotificationServiceBase
   */
  public abstract get errorChanged(): Observable<ErrorDisplayItem>;

  /**
   * Returns all current error items.
   *
   * @readonly
   * @abstract
   * @type {ErrorDisplayItem[]}
   * @memberof ErrorNotificationServiceBase
   */
  public abstract get errors(): ErrorDisplayItem[];
}
