import { Injectable } from "@angular/core";

import { FullSnapInId, IStorageService } from "@gms-flex/core";
import { TraceService } from "@gms-flex/services-common";
import { traceModule } from "./summary-bar-preselect.service";

@Injectable()
export class SummaryBarStorageService implements IStorageService {

  public typeId: string = "SummaryBarType";

  constructor(private traceService: TraceService) {
    this.traceService.info(traceModule, "SummaryBarStorageService created.");
  }

  public getState(fullId: FullSnapInId): any {
    //
  }

  public setState(fullId: FullSnapInId, state: any): void {
    //
  }

  public clearState(fullId: FullSnapInId): void {
    //
  }

  public getDirtyState(fullId: FullSnapInId): boolean {
    return;
  }

  public setDirtyState(fullId: FullSnapInId, state: boolean): void {
    //
  }
}
