<form #form="ngForm">
  <si-property-popover
    #popover
    [valueInfo]="property.value.min + '..' + property.value.max + ' ' + property.value.unit"
    [property]="property"
    [submitDisabled]="form.invalid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    @if (popover.isBlocked || !popover.isActive || property.defaultText) {
      <div
        class="form-control d-flex align-items-center"
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        (keydown.enter)="popover.open(true)"
        input
      >
        <span>
          @if (!property.defaultText && property.value.value !== undefined) {
            {{
              property.value.value
                | siBigNumber: property.value.resolution : property.value.decimalsAllowed
            }}
            {{ property.value.unit }}
          } @else {
            {{ property.defaultText || property.value.altText }}
          }
          <ng-template #defaultText>{{
            property.defaultText || property.value.altText
          }}</ng-template>
        </span>
      </div>
    }
    @if (popover.isActive) {
      <input
        #inputBox
        name="main"
        type="text"
        class="form-control"
        autocomplete="off"
        (keydown)="keydown($event)"
        (keydown.enter)="popover.submitEnter($event)"
        (paste)="paste($event)"
        [attr.aria-label]="property.name"
        [(ngModel)]="popover.modelValue"
        [required]="!property.value.optional"
        [siNumberValidator]="property.value"
        [allowValuesOutOfRange]="allowValuesOutOfRange"
        input
      />
    }
  </si-property-popover>
</form>
