import { Injectable } from '@angular/core';
import { SystemInfo } from '@gms-flex/services';

import { Partition } from '../../bx-services/subscription/partition-proxy.model';

@Injectable({
  providedIn: 'root'
})
export class SystemsMapperBxToGmsService {

  private static systemIdCounter = 1;
  private readonly systemIdToPartition: Map<number, Partition> = new Map<number, Partition>();
  private readonly partitionIdToSystemId: Map<string, number> = new Map<string, number>();

  public mapPartitions(partitions: Partition[]): SystemInfo[] {
    return partitions.map(partition => {
      if (this.partitionIdToSystemId.has(partition.id)) {
        /* eslint-disable @typescript-eslint/naming-convention */
        const systemInfo: SystemInfo = {
          Name: partition.id,
          Id: this.partitionIdToSystemId.get(partition.id),
          IsOnline: true
        };
        return systemInfo;
      } else {
        const systemInfo: SystemInfo = {
          Name: partition.id,
          Id: SystemsMapperBxToGmsService.systemIdCounter,
          IsOnline: true
        };
        /* eslint-enable @typescript-eslint/naming-convention */
        this.partitionIdToSystemId.set(partition.id, SystemsMapperBxToGmsService.systemIdCounter);
        this.systemIdToPartition.set(SystemsMapperBxToGmsService.systemIdCounter, partition);
        SystemsMapperBxToGmsService.systemIdCounter++;
        return systemInfo;
      }
    });
  }
}
