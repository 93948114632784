@if (!isLoading) {
<form class="d-flex flex-column pt-5 ps-5 pe-5 h-100 me-6 testClassForm">
  @if (propertyDetails.Type !== 'NoType') {
  <div class="d-flex flex-row justify-content-start"></div>
  <div class="form-check mb-5">
    <label>
      <input class="form-check-input" type="checkbox" [checked]="pointAlarmConfig.attributes.enabled" (change)="toggleAlarm()" />
      <span class="form-check-label"> {{ translatedLabels.get('enabled') }} </span>
    </label>
  </div>
  <div class="d-flex flex-row justify-content-center w-100">
    @if (multistateTypes.includes(propertyDetails.Type)) {
    <point-alarm-multistate class="w-100" [pointAlarmConfig]="pointAlarmConfig.attributes" [point]="point"
      [translatedLabels]="translatedLabels" (newMultistateEvent)="onSettingChange($event)" />
    }
    @if (analogTypes.includes(propertyDetails.Type)) {
    <point-alarm-analog class="w-100" [pointAlarmConfig]="pointAlarmConfig.attributes"
      [unit]="propertyDetails.UnitDescriptor" [translatedLabels]="translatedLabels"
      (newAnalogValueEvent)="onSettingChange($event)" />
    }
    @if (binaryTypes.includes(propertyDetails.Type)) {
    <point-alarm-binary class="w-100" [pointAlarmConfig]="pointAlarmConfig.attributes" [point]="point"
      [translatedLabels]="translatedLabels" (newBinaryEvent)="onSettingChange($event)" />
    }
  </div>
  <div class="d-flex flex-row justify-content-center w-100">
    <point-alarm-category class="w-100" [selectedCategory]="pointAlarmConfig.attributes.category"
      [translatedLabels]="translatedLabels" (newCategoryEvent)="onCategorySelect($event)" />
  </div>
  <div class="d-flex flex-row justify-content-center w-100">
    <point-alarm-text class="w-100" [message]="pointAlarmConfig.attributes.message" [translatedLabels]="translatedLabels"
      (newMessageEvent)="onComment($event)" />
  </div>
  <div class="d-flex flex-row justify-content-end mt-4">
    <button type="submit" class="btn btn-secondary" [disabled]="!canSaveAlarmData" (click)="onSave()" >
      {{ translatedLabels.get("apply") }}
    </button>
  </div>
  } @else {
  <si-empty-state class="d-flex flex-column" icon="element-out-of-service"
    [heading]="translatedLabels.get('empty-state-heading')" />
  }
</form>
} @else {
<div class="d-flex justify-content-center w-100">
  <si-loading-spinner />
</div>
}