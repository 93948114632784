import { Injectable } from '@angular/core';
import { FullSnapInId, IStorageService } from '@gms-flex/core';
import { TraceService } from '@gms-flex/services-common';

import { TraceModules } from '../shared/trace-modules';

@Injectable()
export class PropertyStorageService implements IStorageService {

  public typeId = 'PropertyViewerType';

  constructor(private readonly traceService: TraceService) {
    this.traceService.info(TraceModules.pvcServices, 'PropertyStorageService created');
  }

  public getState(fullId: FullSnapInId): any {
    //
  }

  public setState(fullId: FullSnapInId, state: any): void {
    //
  }

  public clearState(fullId: FullSnapInId): void {
    //
  }

  public getDirtyState(fullId: FullSnapInId): boolean {
    return;
  }

  public setDirtyState(fullId: FullSnapInId, state: boolean): void {
    //
  }
}
