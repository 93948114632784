import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserObject, GraphicInfo, GraphicsServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, of, scheduled } from 'rxjs';

@Injectable()
export class GraphicsBxSubstituteService extends GraphicsServiceBase {

  public constructor(
    traceService: TraceService,
    httpClient: HttpClient) {
    super(traceService, httpClient);
    this.traceService.info(TraceModules.graphics, 'GraphicsBxSubstituteService created.');
  }

  /**
   * returns true if the object is graphical or it has a graphical item related to it, otherwise false
   */
  public hasGraphicalItems(objectId: string): Observable<boolean> {
    this.traceService.info(TraceModules.graphics, 'hasGraphicalItems() called for: ' + objectId);

    return scheduled(of(false), asapScheduler);
  }

  /**
   * Gets the graphics items based on provided designation
   */
  public getGraphicsItems(designation: string): Observable<GraphicInfo[]> {
    this.traceService.info(TraceModules.graphics, 'getGraphicsItems() called for: ' + designation);

    return scheduled(of([]), asapScheduler);
  }

  /**
   * Gets the graphics content on based on provided objectId
   */
  public getGraphicsContent(objectId: string): Observable<string> {
    this.traceService.info(TraceModules.graphics, 'getGraphicsItems() called: ' + objectId);

    return scheduled(of(''), asapScheduler);
  }

  /**
   * Gets the first child of the designation which has graphics.
   */
  public getChildWithGraphics(designation: string): Observable<BrowserObject> {
    this.traceService.info(TraceModules.graphics, 'getChildWithGraphics() called: ' + designation);

    return scheduled(of(undefined), asapScheduler);
  }
}
