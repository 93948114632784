import { booleanAttribute, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  SiAutoCollapsableListAdditionalContentDirective,
  SiAutoCollapsableListDirective,
  SiAutoCollapsableListItemDirective,
  SiAutoCollapsableListOverflowItemDirective
} from '@simpl/element-ng/auto-collapsable-list';
import { BackgroundColorVariant } from '@simpl/element-ng/common';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { Filter } from './filter';
import { SiFilterPillComponent } from './si-filter-pill.component';

@Component({
  selector: 'si-filter-bar',
  templateUrl: './si-filter-bar.component.html',
  styleUrl: './si-filter-bar.component.scss',
  standalone: true,
  imports: [
    SiAutoCollapsableListDirective,
    SiAutoCollapsableListItemDirective,
    SiAutoCollapsableListOverflowItemDirective,
    SiAutoCollapsableListAdditionalContentDirective,
    SiFilterPillComponent,
    SiTranslateModule
  ]
})
export class SiFilterBarComponent {
  /**
   * Title of filter bar.
   */
  @Input() filterTitle = $localize`:@@SI_FILTER_BAR.TITLE:Filters`;
  /**
   * Custom text if no filters are selected.
   */
  @Input() filterDefaultText = $localize`:@@SI_FILTER_BAR.NO_FILTERS:No filters applied`;
  /**
   * Array of filter items to show
   */
  @Input() filters: Filter[] = [];
  /**
   * Enable to render responsive variant.
   * @deprecated The non-responsive variant of the filter bar will be discontinued in simpl element v47.
   * The default variant will be responsive
   */
  @Input({ transform: booleanAttribute }) responsive = true;
  /**
   * Text for reset button
   */
  @Input() resetText = $localize`:@@SI_FILTER_BAR.RESET_FILTERS:Reset filters`;
  /**
   * Set false to hide reset filters button
   */
  @Input({ transform: booleanAttribute }) allowReset = true;
  /**
   * Color to use for component background
   */
  @Input() colorVariant: BackgroundColorVariant = 'base-1';
  @ViewChild(SiAutoCollapsableListDirective, { static: true })
  private collapsableListDirective!: SiAutoCollapsableListDirective;

  /**
   * Set to true to disable component
   */
  @Input({ transform: booleanAttribute }) disabled = false;

  /**
   * Output callback to be executed when the filter changes
   */
  @Output() readonly filtersChange: EventEmitter<Filter[]> = new EventEmitter();

  /**
   * Output callback to be executed when the reset filter is clicked
   */
  @Output() readonly resetFilters: EventEmitter<void> = new EventEmitter();

  protected deleteFilters(deletedPill: Filter): void {
    this.filters.splice(this.filters.indexOf(deletedPill), 1);
    this.filtersChange.emit(this.filters);
  }

  /**
   * Whether the filter bar currently doesn't contain any filters.
   */
  get isEmpty(): boolean {
    return !this.filters.length;
  }

  protected onResetFilters(): void {
    this.filters = [];
    this.resetFilters.emit();
  }

  protected deleteOverflowFilter(): void {
    this.filters.splice(
      this.collapsableListDirective.items.toArray().findIndex(item => !item.isVisible)
    );
    this.filtersChange.emit(this.filters);
  }
}
