<!-- section header -->
@if (!isLink && !item.items) {
  <div class="section-item fw-bold text-secondary px-5">
    {{ item.title | translate }}
  </div>
}

<!-- normal item -->
@if (isLink && !item.items) {
  <a
    #itemLink="siLink"
    class="nav-link focus-inside"
    activeClass="active"
    [siLink]="item"
    [attr.aria-current]="itemLink.active"
    (click)="itemTrigger.emit()"
  >
    @if (item.icon) {
      <i
        class="icon ms-5 me-n2"
        role="presentation"
        [ngClass]="[item.icon, itemLink.active ? item.icon + '-filled' : '']"
      ></i>
    }
    @if (item.icon && item.badge) {
      <span class="badge-text">{{ item.badge }}</span>
    }
    <span
      class="item-title text-truncate fw-bold"
      [class.ps-4]="item.icon"
      [class.ps-5]="!item.icon"
      >{{ item.title | translate }}</span
    >
    @if (item.badge !== undefined && item.badge !== '') {
      <span class="badge" [ngClass]="'bg-' + item.badgeColor">
        {{ item.badge }}
      </span>
    }
  </a>
}

<!-- sub menu -->
@if (item.items) {
  <div class="sub-menu" [class.sub-active]="itemActive">
    <div class="d-flex">
      @if (parent.collapsed) {
        <button
          type="button"
          class="flex-fill nav-link focus-inside"
          [class.navbar-vertical-no-collapse]="!isLink"
          [class.active]="itemActive"
          [attr.title]="item.tooltip ? (item.tooltip | translate) : null"
          [attr.aria-current]="itemActive"
          [attr.role]="isLink ? 'link' : 'button'"
          [siNavbarDropdownTriggerFor]="flyoutMenu"
          (click)="triggerItemOrMenu($event)"
        >
          <ng-template [ngTemplateOutlet]="itemContent" />
        </button>
      } @else {
        <button
          type="button"
          class="flex-fill nav-link focus-inside dropdown-toggle"
          [class.show]="item.expanded"
          [attr.id]="isLink ? null : id + 'menu-toggle'"
          [attr.aria-expanded]="isLink ? null : item.expanded ?? false"
          [attr.aria-controls]="isLink ? null : id + 'menu-body'"
          [class.navbar-vertical-no-collapse]="!isLink"
          [class.active]="itemActive"
          [attr.title]="item.tooltip ? (item.tooltip | translate) : null"
          [attr.aria-current]="itemActive"
          [attr.role]="isLink ? 'link' : 'button'"
          (click)="triggerItemOrMenu($event)"
        >
          <ng-template [ngTemplateOutlet]="itemContent" />
          @if (!isLink) {
            <span role="presentation" class="dropdown-caret element-down-2"></span>
          }
        </button>
      }
      @if (isLink) {
        <button
          type="button"
          class="nav-link ps-5 expander navbar-vertical-no-collapse focus-inside dropdown-toggle"
          [class.show]="item.expanded"
          [id]="id + 'menu-toggle'"
          [attr.aria-expanded]="item.expanded ?? false"
          [attr.aria-controls]="id + 'menu-body'"
          [attr.aria-label]="
            ((item.expanded ? collapseButtonText : expandButtonText) | translate) +
            ' ' +
            (item.title | translate)
          "
          (click)="triggerMenu()"
        >
          <span role="presentation" class="dropdown-caret element-down-2"></span>
        </button>
      }
    </div>
    @if (!parent.collapsed && item.expanded) {
      <div
        class="menu-body"
        role="region"
        [attr.aria-labelledby]="id + 'menu-toggle'"
        @collapse
        [id]="id + 'menu-body'"
      >
        @for (subitem of item.items; track $index) {
          <a
            #siLink="siLink"
            class="nav-link nav-sub-item focus-inside ps-9"
            activeClass="active"
            [siLink]="subitem"
            [attr.aria-current]="siLink.active"
            (click)="itemTrigger.emit()"
          >
            @if (subitem.icon) {
              <i
                class="icon ps-5 me-n2"
                role="presentation"
                [ngClass]="[subitem.icon, siLink.active ? subitem.icon + '-filled' : '']"
              ></i>
            }
            <span class="item-title text-truncate" [class.ps-5]="!textOnly">{{
              subitem.title | translate
            }}</span>
            @if (subitem.badge !== undefined && subitem.badge !== '') {
              <span class="badge" [ngClass]="'bg-' + subitem.badgeColor">
                {{ subitem.badge }}
              </span>
            }
          </a>
        }
      </div>
    }
  </div>
}

<ng-template #flyoutMenu>
  <si-navbar-dropdown>
    <si-navbar-dropdown-items-factory [items]="flyoutItems" />
  </si-navbar-dropdown>
</ng-template>

<ng-template #itemContent>
  <span #itemLink="siLink" class="d-none" [siLink]="item"></span>
  @if (item.icon) {
    <i
      class="icon ps-5 me-n2"
      role="presentation"
      [ngClass]="[item.icon, itemActive ? item.icon + '-filled' : '']"
    ></i>
  }
  <span class="item-title text-truncate fw-bold ps-5">{{ item.title | translate }}</span>
  @if (item.badge !== undefined && item.badge !== '') {
    <span class="badge" [ngClass]="'bg-' + item.badgeColor">
      {{ item.badge }}
    </span>
  }
</ng-template>
