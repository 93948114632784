import { Injectable } from '@angular/core';

export const traceChannelHorizon = 'Horizon';
export const useCache = true;
export const useMulticast = true;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private static signalRCtxCounter = 0;
  private static subscriptionKeyCounter = 0;

  public getSignalRCtx(): string {
    const ctx = UtilityService.signalRCtxCounter.toString();
    UtilityService.signalRCtxCounter++;
    return ctx;
  }

  public getSubscriptionKey(): number {
    const key = UtilityService.subscriptionKeyCounter;
    UtilityService.subscriptionKeyCounter++;
    return key;
  }
}
