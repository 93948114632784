import { Injectable, NgZone } from '@angular/core';
import {
  BrowserObject,
  Designation,
  Event,
  EventFilter,
  EventService,
  EventSubscription,
  GmsSubscription,
  PropertyDetails,
  PropertyInfo,
  PropertyServiceBase,
  SystemInfo,
  SystemsServiceBase,
  ValueDetails,
  ValueSubscription2ServiceBase
} from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { asapScheduler, Subject, Subscription, takeUntil, timer } from 'rxjs';

import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { WildCardReferenceItem } from '../processor/replication/wildcardreferenceitem';
import { AlarmState } from '../types/datapoint/gms-alarm-state';
import { MergeMode } from '../types/datapoint/gms-merge-mode';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { FormatHelper } from '../utilities/format-helper';
import { MathUtils } from '../utilities/mathUtils';
import { PropertyInfoFunction, SubscriptionData } from './data.model';
import { GmsCommandService } from './gms-command-service';

@Injectable()
export class DataPointService {

  public propertyChanged: Subject<PropertyChangeArgs> = new Subject<PropertyChangeArgs>();

  private readonly systemsOnline: string[];

  private _runInDeferredMode = false;
  public get RunInDeferredMode(): boolean {
    return this._runInDeferredMode;
  }
  public set RunInDeferredMode(value: boolean) {
    if (this._runInDeferredMode !== value) {
      this._runInDeferredMode = value;
    }
  }

  private _deferReplicationIndexResolution = false;
  public get DeferReplicationIndexResolution(): boolean {
    return this._deferReplicationIndexResolution;
  }
  public set DeferReplicationIndexResolution(value: boolean) {
    if (this._deferReplicationIndexResolution !== value) {
      this._deferReplicationIndexResolution = value;
    }
  }

  private _indexResolutionInProgress = false;

  // WildCardReferences for which index resolution needs to continue/start
  private readonly _activeWildCardreferences: Map<string, WildCardReferenceItem> = new Map<string, WildCardReferenceItem>();

  // WildCardReferences for which indexes are resolved.
  private readonly _resolvedWildCardreferences: Map<string, WildCardReferenceItem> = new Map<string, WildCardReferenceItem>();

  // Key is Designation
  private readonly globalDatapoints: Map<string, Datapoint> = new Map<string, Datapoint>();

  // To match events-datapoints - we need property id based keys to be fast
  // Key is object id - <SystemName>:<ObjectID>
  // Only valid datapoints
  private readonly globalDatapointsForEvents: Map<string, Datapoint[]> = new Map<string, Datapoint[]>();
  private readonly globalEvents: Map<string, Event[]> = new Map<string, Event[]>();

  // Utilized for deferring read and subscribe for the datapoints from evaluations
  // Processed for read and subscribe after the current graphicview is rendered.
  private readonly deferredDatapoints: Map<string, Datapoint> = new Map<string, Datapoint>();

  // Connection id of the HUB to uniquely identify each connection for notification.
  private clientId: string;

  private errorMessage: string;

  private readonly traceModule: string = 'gmsSnapins_DataPointService';

  // Subscribers to the ReadPropertyValuesMulti . Key is graphics' selected objectId
  private readonly _readPropertyValuesMutiSubscription: Map<string, Subscription> = new Map<string, Subscription>();

  // Subscribers to the ReadPropertyValuesMultiWildCard.
  private readonly _readPropertyValuesMultiWildCardSubscription: Subscription[] = new Array<Subscription>();

  // Subscribers to the ReadProperty Values. Key is Dp designation
  private readonly _readPropertyValuesSubscription: Map<string, Subscription> = new Map<string, Subscription>();

  // Subscribers to the Datapoints COV. Key is Dp designation
  private readonly _subscriptionInfoPerDataPoint: Map<string, SubscriptionData> = new Map<string, SubscriptionData>();

  // Handling removing datapoints on a timer
  private readonly _timer: any;
  private _timerSubscription: Subscription;

  private _eventsSubscription: EventSubscription;
  private _eventsSubscriptionRef: Subscription;
  private readonly destroyInd: Subject<void>;
  /**
   *
   * @param traceService
   * @param valueSubscriptionService
   * @param propertyService
   */
  public constructor(private readonly traceService: TraceService,
    private readonly valueSubscriptionService: ValueSubscription2ServiceBase,
    private readonly propertyService: PropertyServiceBase,
    private readonly commandService: GmsCommandService,
    private readonly eventService: EventService,
    private readonly systemsService: SystemsServiceBase,
    private readonly zone: NgZone) {

    this.traceService.info(this.traceModule, 'Graphics DataPoint service created.');

    // Handling removing datapoints on a timer: Emits ones, after 1 minute
    this._timer = timer(60000);
    this.destroyInd = new Subject<void>();
    // Subscribe for system online/offline state changes
    this.systemsOnline = [];
    this.systemsService.subscribeSystems();
    this.systemsService.systemsNotification()
      .pipe(
        takeUntil(this.destroyInd))
      .subscribe(
        systemInfoArr => {
          this.onSystemChange(systemInfoArr);
        });
  }
  /**
   * provides a list of online systems only
   * @param siArr
   */
  private onSystemChange(siArr: SystemInfo[]): void {
    this.systemsOnline.length = 0;
    if (siArr) {
      siArr.forEach(s => { this.systemsOnline.push(s.Name); });
      this.globalDatapoints.forEach(dp => {
        if (this.systemsOnline.findIndex(item => dp.SystemName !== '' && item === dp.SystemName) < 0) {
          if (dp.Status === DatapointStatus.Valid) {
            dp.Status = DatapointStatus.Invalid;
          }
        }
      });
    }
  }

  /**
   * decrease the counter of graphics header datapoints and
   * subscribe for handling removing datapoints (from graphics header and expressions)
   * @param datapoints
   */
  public scheduleRemove(datapoints: Datapoint[]): void {
    // Datapoints to be removed for:
    // graphics header
    // expressions
    if (this._timer === undefined) {
      return;
    }

    if (datapoints !== undefined) {
      datapoints.forEach(value => {
        const dp: Datapoint = this.globalDatapoints.get(value.Designation);
        if (dp !== undefined && dp.CountUsage > 0) {
          dp.CountUsage--;
          this.commandService.unsubscribeFromUpdates(dp.Id);
        }
      });
    }

    // if timer is running -> stop it
    if (this._timerSubscription !== undefined) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }

    this._timerSubscription = this._timer.subscribe(t => this.onScheduleRemoveTimerTick(t));
  }

  public onScheduleRemoveTimerTick(t: any): void {

    // List points to be removed
    const removePointsList: Datapoint[] = [];
    this.globalDatapoints.forEach(value => {
      if (value !== undefined && value.CountUsage <= 0) {
        removePointsList.push(value);
      }
    });
    // Finally delete datapoints
    this.Remove(removePointsList);
  }

  public async subscribeToEvents(): Promise<void> {

    this.zone.runOutsideAngular(() => setTimeout(() => this.subscribeEventsCallBack(), 5));
  }

  public unsubscribeToEvents(): void {
    if (this._eventsSubscription !== undefined) {
      const subscriptionId = this._eventsSubscription.id;
      this._eventsSubscriptionRef.unsubscribe();
      this.eventService.destroyEventSubscription(subscriptionId);
      this._eventsSubscription = undefined;
    }

    // Clear the cached events
    this.globalEvents.forEach(value => {
      value.length = 0;
    });
    this.globalEvents.clear();

    // Clear the datapoint event state upon graphic selection change
    this.globalDatapointsForEvents.forEach(datapoints => {
      datapoints.forEach(datapoint => datapoint.ResetAlarmState());
    });
  }

  /**
   * Add datapoints to global datapoints array and subscribe to updates on each
   * @param datapoints
   * @param selectedObjectId
   */
  public addPoints2(datapoints: Datapoint[], selectedObjectId: string): void {
    if (datapoints !== undefined) {
      const dps: Datapoint[] = new Array<Datapoint>();
      const len: number = datapoints.length;
      for (let i = 0; i < len; i++) {
        const datapoint: Datapoint = datapoints[i];
        const globaldatapoint: Datapoint = this.globalDatapoints.get(datapoint.Designation);
        if (globaldatapoint === undefined) { // current datapoint does not exist in global array
          datapoint.CountUsage++;
          // this.Add(datapoint);
          // add datapoint to collection
          this.globalDatapoints.set(datapoint.Designation, datapoint);

          // if datapoint is not subscribed yet for update, then will subscribe it.
          if (datapoint.CountUsage < 2 || datapoint.Status === DatapointStatus.Undefined) {
            // either a client added it (CountUsage was increased by a client),
            // or reading and adding it from the graphics header
            datapoint.Status = DatapointStatus.Pending;
            dps.push(datapoint);
          }
        } else { // current datapoint exists in global array
          if (globaldatapoint.Id !== datapoint.Id) {
            // discrepancy in Id
            this.traceService.error(this.traceModule, 'AddPoints(): globaldatapoint.Id !== datapoint.Id');
            continue;
          } else {
            // Update datapoint with the latest
            globaldatapoint.CountUsage++;
            globaldatapoint.MergeDataFrom(datapoint, MergeMode.Merge);
          }
        }
      }
      if (dps.length > 0) {
        this.readPropertiesMulti(dps, selectedObjectId);
      }
    }
  }

  /**
   * Validate Graphics Header datapoints and subscribe to COV's.
   * @param datapoints
   */
  public addPoints(datapoints: Datapoint[]): void {

    if (datapoints !== undefined) {

      const len: number = datapoints.length;
      for (let i = 0; i < len; i++) {
        const datapoint: Datapoint = datapoints[i];
        const globaldatapoint: Datapoint = this.globalDatapoints.get(datapoint.Designation);
        if (globaldatapoint === undefined) {
          datapoint.CountUsage++;
          this.Add(datapoint);
        } else {
          if (globaldatapoint.Id !== datapoint.Id) {
            this.traceService.error(this.traceModule, 'AddPoints(): globaldatapoint.Id !== datapoint.Id');
            continue;
          } else {
            // Update datapoint with  the latest
            globaldatapoint.CountUsage++;
            globaldatapoint.MergeDataFrom(datapoint, MergeMode.Merge);
          }
        }
      }
    }
  }

  /**
   * Try to retrieve an existing datapoint from the datapoints collection
   * @param designation
   */
  public GetByDesignation(designation: string): Datapoint {
    let datapoint: Datapoint;
    if (this.globalDatapoints.has(designation)) {
      datapoint = this.globalDatapoints.get(designation);
    }
    return datapoint;
  }

  public GetOrCreateByDesignation(designation: string, subscribeForCOV: boolean = true): Datapoint {
    /**
     * NOTE: This is necessary to make designations without system names function
     */
    const designationObj: Designation = new Designation(designation);
    if (!isNullOrUndefined(designationObj) && !isNullOrUndefined(this?._currentSystem) && designationObj?.isSystemValid === false) {
      const designationWithoutSystem = designationObj?.designationWoSystem;
      if (!isNullOrUndefined(this?._currentSystem?.Name) && this._currentSystem.Name.length > 0
        && !isNullOrUndefined(designationWithoutSystem) && designationWithoutSystem.length > 0) {
        const datapointSeparator = ".";
        designation = this._currentSystem.Name + datapointSeparator + designationWithoutSystem;
      }
    }

    let datapoint: Datapoint = this.globalDatapoints.get(designation);

    if (datapoint === undefined) {
      datapoint = this.deferredDatapoints.get(designation);
    }

    if (datapoint === undefined) {
      datapoint = new Datapoint(designation);

      if (this._runInDeferredMode) {
        // Count Usage done by a client
        // datapoint.CountUsage++;
        this.DefferredAdd(datapoint);
      } else {
        this.Add(datapoint, subscribeForCOV);
      }
    }
    return datapoint;
  }

  public GetOrCreateWildCardReference(wildCardReference: string): WildCardReferenceItem {
    let wildCardReferenceItem: WildCardReferenceItem;

    if (this._resolvedWildCardreferences.has(wildCardReference)) {
      wildCardReferenceItem = this._resolvedWildCardreferences.get(wildCardReference);
      return wildCardReferenceItem;
    }

    if (this._activeWildCardreferences.has(wildCardReference)) {
      wildCardReferenceItem = this._activeWildCardreferences.get(wildCardReference);
      return wildCardReferenceItem;
    }

    wildCardReferenceItem = new WildCardReferenceItem(wildCardReference);
    this._activeWildCardreferences.set(wildCardReference, wildCardReferenceItem);

    if (!this._deferReplicationIndexResolution && !this._indexResolutionInProgress) {
      // Kick start the resolution of indexes again for the activewildcardreferences.
      this.zone.runOutsideAngular(() => setTimeout(() => this.processDeferredWildCards(), 10));
    }

    return wildCardReferenceItem;
  }

  public subscribeMultiForCOV(datapoints: Datapoint[]): void {

    if (datapoints === undefined) {
      return;
    }

    const ids: string[] = [];
    datapoints.forEach((dp, index, array) => {
      if (!ids.includes(dp.Id)) { // one value subscription per dp id
        ids.push(dp.Id);
      }
    });

    const valSubscriptions: GmsSubscription<ValueDetails>[] =
            this.valueSubscriptionService.subscribeValues(ids, this.clientId, false, true);

    valSubscriptions.forEach((sub, index) => {
      // same datapoint Id, but a diff designation
      const result: Datapoint[] = datapoints.filter(d => d.Id === sub.gmsId);
      if (result !== undefined) {
        result.forEach((d, i, arr) => {
          const subData: SubscriptionData = this._subscriptionInfoPerDataPoint.get(d.Designation);
          subData.subscription = sub.changed.subscribe(
            valueDetails => this.onValueUpdate(d, valueDetails),
            error => this.onValueUpdateError(d, error));
          subData.valueSubscription = sub;
        });
      }
    });
  }

  public subscribeForCOV(datapoint: Datapoint): boolean {
    const ids: string[] = [datapoint.Id];

    const valSubscriptions: GmsSubscription<ValueDetails>[] = this.valueSubscriptionService.subscribeValues(
      ids, this.clientId, false, true);

    valSubscriptions.forEach((sub, index) => {

      const subData: SubscriptionData = this._subscriptionInfoPerDataPoint.get(datapoint.Designation);
      subData.subscription = sub.changed.subscribe(
        valueDetails => this.onValueUpdate(datapoint, valueDetails),
        error => this.onValueUpdateError(datapoint, error));
      subData.valueSubscription = sub;

    });
    return true;
  }

  /**
   * Request for a client Id and Initialise Values Subscription
   * @param clientName
   */
  public initialiseValueSubscriptionService(snapinName: string): void {

    // Get Client Id/token
    this.clientId = this.valueSubscriptionService.registerClient(snapinName);
    if (this.commandService !== undefined) {
      this.commandService.initClient(snapinName);
    }
  }

  /**
   *
   * @param clientName
   */
  public unInitialiseValueSubscriptionService(): void {

    if (this._timerSubscription !== undefined) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }
    this.unSubscribeAll();

    this.destroyInd.next();
    this.destroyInd.complete();
    // This breaks the graphics viewer destroy in a AT step
    // this.destroyInd = undefined;
    this.systemsService.unSubscribeSystems();
  }

  /**
   *
   * @param dataPoint
   */
  public async processDeferredDatapoints(selectedObjectId: string): Promise<void> {
    const deferredDatapoints: Datapoint[] = Array.from(this.deferredDatapoints.values());
    this.addPoints2(deferredDatapoints, selectedObjectId + '_Deferred'); // Key to hold the multi ValueSubscription
    this.deferredDatapoints.clear();
  }

  public async processDeferredWildCards(): Promise<void> {
    this._indexResolutionInProgress = true;

    if (this._activeWildCardreferences.size <= 0) {
      return;
    }

    // iteratre thru the wildcardreferenceitems
    let dpsToResolve: string[] = [];
    this._activeWildCardreferences.forEach((value: WildCardReferenceItem, key: string, map: Map<string, WildCardReferenceItem>) => {
      const nextDpIdsToResolve: string[] = value.NextWildCardReferencesToPoll;
      dpsToResolve = dpsToResolve.concat(nextDpIdsToResolve);
    });

    const subscription: Subscription = this.propertyService.readPropertiesMulti(dpsToResolve, 2, false)
      .subscribe(propertyInfo => this.OnWildCardPropertyReadNotification(dpsToResolve, propertyInfo),
        error => this.OnWildCardPropertyReadError(dpsToResolve, error));

    if (subscription !== undefined) {
      this._readPropertyValuesMultiWildCardSubscription.push(subscription);
    }
  }

  // Introduced for better profiling
  private subscribeEventsCallBack(): void {
    if (this._eventsSubscription === undefined) {
      // Creating filter option to always get all the events in the system
      const eventFilter: EventFilter = { empty: true };
      // Subscribe to eventService
      this._eventsSubscription = this.eventService.createEventSubscription(eventFilter);
      if (this._eventsSubscription !== undefined) {
        this._eventsSubscriptionRef = this._eventsSubscription.events.subscribe(
          values => this.onEventsNotification(values),
          error => this.clearEvents());
      }
    }
  }

  private OnWildCardPropertyReadNotification(dpsResolved: string[], propertyInfos: PropertyInfo<PropertyDetails>[]): void {
    let startIndex = 0;
    const batchsize = 20; // Preset in WildCardReferenceItem.
    let endIndex: number = batchsize;
    const inValidDps: string[] = new Array<string>();
    const resolvedWildCardReferenceItems: WildCardReferenceItem[] = []; // Remove from _activewildcardreferences and add to _resolvedWildCardReferences
    this._activeWildCardreferences.forEach((value: WildCardReferenceItem, key: string, map: Map<string, WildCardReferenceItem>) => {
      const batch: PropertyInfo<PropertyDetails>[] = propertyInfos.slice(startIndex, endIndex);
      const dpsbatch: string[] = dpsResolved.slice(startIndex, endIndex);

      if (startIndex > propertyInfos.length || endIndex > propertyInfos.length) {
        return;
      }

      for (let i = 0; i < batch.length; i++) {
        const propertyInfo: PropertyInfo<PropertyDetails> = batch[i];
        const correspondingdp: string = dpsbatch[i];
        if (propertyInfo.ErrorCode !== 0 || propertyInfo.ObjectId === undefined || propertyInfo.Properties === undefined) {
          inValidDps.push(correspondingdp);
        }
      }

      if (inValidDps.length > 0) { // Set the WildCardReferenceItem resolve
        value.SetResolved(inValidDps);
        resolvedWildCardReferenceItems.push(value);
      } else {
        value.SetBatchValid();
      }

      inValidDps.length = 0;
      startIndex = endIndex;
      endIndex = startIndex + 20;
    });

    for (let i = 0; i < resolvedWildCardReferenceItems.length; i++) {
      const wildCardReferenceItem: WildCardReferenceItem = resolvedWildCardReferenceItems[i];
      this._activeWildCardreferences.delete(wildCardReferenceItem.WildCardReference); // Remove from active
      this._resolvedWildCardreferences.set(wildCardReferenceItem.WildCardReference, wildCardReferenceItem); // Add to the resolved.
    }

    if (this._activeWildCardreferences.size > 0) {
      this.zone.runOutsideAngular(() => setTimeout(() => this.processDeferredWildCards(), 10)); // Process the next batches of wildcardreferences
    } else {
      this._indexResolutionInProgress = false;
    }
  }

  private OnWildCardPropertyReadError(dpsToResolve: string[], error: Error): void {
    // TBD
  }

  private onValueUpdate(datapoint: Datapoint, valueDetail: ValueDetails): void {
    this.zone.runOutsideAngular(() => {

      if (datapoint === undefined || valueDetail === undefined) {
        return;
      }

      datapoint.DisplayValue = valueDetail.Value.DisplayValue;

      if (valueDetail.Value.IsPropertyAbsent) {
        datapoint.Value = '';
        datapoint.Status = DatapointStatus.DoesNotExist;
      } else if (valueDetail.Value.DisplayValue === '#COM') {
        datapoint.Value = undefined;
        datapoint.Status = DatapointStatus.Invalid;
      } else {
        datapoint.Value = valueDetail.Value.Value;
        datapoint.IsArray = valueDetail.IsArray;
        datapoint.Status = DatapointStatus.Valid;

        FormatHelper.StringToNumber(valueDetail.Value.Quality);
        const parsedValue: number = FormatHelper.StringToNumber(valueDetail.Value.Quality);
        if (!isNaN(parsedValue)) {
          this.traceService.info(this.traceModule,
            'onValueUpdate called: datapoint Id: %s, DisplayValue = %s, QualityBits = %s , QualityGood = %s, IsPropertyAbsent = %s',
            datapoint.Id, valueDetail.Value.DisplayValue,
            parsedValue.toString(2),
            valueDetail.Value.QualityGood === true ? 'TRUE' : 'FALSE',
            valueDetail.Value.IsPropertyAbsent === undefined ? 'UNDEFINED' :
              valueDetail.Value.IsPropertyAbsent ? 'TRUE' : 'FALSE');
        }
      }
    });
  }

  private onValueUpdateError(datapoint: Datapoint, error: Error): void {
    this.traceService.error(this.traceModule, 'onValueUpdateError(): datapoint Id: % error: %s', datapoint.Id, error.message);
    this.errorMessage = error as any;
  }

  /**
   *
   * @param dataPoint
   */
  private Remove(removePointsList: Datapoint[]): void {

    if (removePointsList !== undefined) {

      const ids: string[] = [];

      removePointsList.forEach(value => {
        this.traceService.info(this.traceModule, 'RemovePointsList: Datapoint - ' + value.Designation);

        const dp: Datapoint = this.globalDatapoints.get(value.Designation);
        if (dp !== undefined) {
          // Count Usage done by a client
          // dataPoint.CountUsage--;
          if (value.CountUsage <= 0) {

            if (value.Id !== undefined) {
              ids.push(value.Designation);
            }
            this.commandService.removeCommands(value.Id);
            this.traceService.info(this.traceModule, 'removeCommands: Datapoint - ' + value.Designation);
            // delete Datapoint
            this.globalDatapoints.delete(value.Designation);

            if (value.ObjectIdEvents !== undefined) {
              const datapoints: Datapoint[] = this.globalDatapointsForEvents.get(value.ObjectIdEvents);
              const indexToRemove: number = datapoints !== undefined && datapoints.length > 0 ? datapoints.indexOf(value) : -1;
              if (indexToRemove > 0) {
                datapoints.splice(indexToRemove, 1);
              }

              if (datapoints.length === 0) {
                this.globalDatapointsForEvents.delete(value.ObjectIdEvents);
              }
            }
          }
        }
      });

      if (ids.length > 0) {
        this.unSubscribeValues(ids);
      }
    }
  }

  /**
   * ids - list of datapoint Id
   * @param ids
   */
  private unSubscribeValues(designations: string[]): void {

    const valueSubscriptions: GmsSubscription<ValueDetails>[] = [];
    const subMap: Map<string, SubscriptionData> = this._subscriptionInfoPerDataPoint;

    designations.forEach((value, index, arr) => {
      if (subMap.has(value)) {
        const sub: SubscriptionData = subMap.get(value);
        sub.subscription.unsubscribe();
        valueSubscriptions.push(sub.valueSubscription);
        subMap.delete(value);
      }
    });

    this.valueSubscriptionService.unsubscribeValues(valueSubscriptions, this.clientId);
  }

  /**
   * Read datapoints properties and subscribe for COV
   * @param datapoints
   */
  private readPropertiesMulti(datapoints: Datapoint[], selectedObjectId: string): void {

    // cleanup
    this.unSubscribeFromReadPropertyValuesMulti(selectedObjectId);

    const dpIds: string[] = [];

    if (datapoints !== undefined) {
      datapoints.forEach((dp, index, array) => {
        dpIds.push(dp.Designation);
      });
    }
    const subscription: Subscription = this.propertyService.readPropertiesMulti(
      dpIds, 3, false, false, true)
      .subscribe(propertyInfo => this.onPropertiesMultiReadNotification(dpIds, selectedObjectId, propertyInfo),
        error => this.onPropertiesMultiReadError(dpIds, selectedObjectId, error));

    if (subscription !== undefined) {
      this._readPropertyValuesMutiSubscription.set(selectedObjectId, subscription);
    }
  }

  /**
   *
   * @param dpIds - collection of global dapoints' designation Id
   * @param selectedObjectId - graphics' selected object Id
   * @param propertyInfos - returned collection of datapoints' properties and attributes values
   */
  private onPropertiesMultiReadNotification(dpIds: string[], selectedObjectId: string, propertyInfos: PropertyInfo<PropertyDetails>[]): void {
    this.zone.runOutsideAngular(() => {
      // clean up
      this.unSubscribeFromReadPropertyValuesMulti(selectedObjectId);

      const datapoints: Datapoint[] = new Array<Datapoint>();
      let propertyInfo: PropertyInfo<PropertyDetails>;
      let datapoint: Datapoint;
      if (dpIds !== undefined && propertyInfos !== undefined && dpIds.length === propertyInfos.length) {
        dpIds.forEach((key, index, array) => {
          datapoint = this.globalDatapoints.get(key);
          if (datapoint !== undefined) {
            propertyInfo = propertyInfos[index];
            if (propertyInfo !== undefined) {
              this.onPropertyReadNotification(datapoint, [propertyInfo], false);
              if (datapoint.Status === DatapointStatus.Valid) {
                // subscribe for COV
                datapoints.push(datapoint);
                // keep subscription  per data item
                this._subscriptionInfoPerDataPoint.set(datapoint.Designation,
                  { objectId: datapoint.Id, valueSubscription: undefined, subscription: undefined });
              }
            }
          }
        });
      }
      if (datapoints.length > 0) {
        this.subscribeMultiForCOV(datapoints);
      }
      this.NotifyPropertyChanged('PropertiesMultiRead', datapoints);
    });
  }

  private onPropertiesMultiReadError(objectIds: string[], selectedObjectId: string, error: Error): void {

    this.unSubscribeFromReadPropertyValuesMulti(selectedObjectId);
  }

  private unSubscribeFromReadPropertyValuesMulti(selectedObjectId: string): void {
    const sub: Subscription = this._readPropertyValuesMutiSubscription.get(selectedObjectId);

    if (sub !== undefined) {
      sub.unsubscribe();
      this._readPropertyValuesMutiSubscription.delete(selectedObjectId);
    }
  }

  /**
   *
   * @param dataPoint
   */
  private readPropertyValues(datapoint: Datapoint, subscribeForCOV: boolean): void {

    if (datapoint?.Designation?.trim() !== '') {
      const subscription: Subscription = this.propertyService.readProperties(
        datapoint.Designation, 3, false, true, true)
        .subscribe(propertyInfo => this.onPropertyReadNotification(datapoint, propertyInfo, subscribeForCOV),
          error => this.onReadPropertyValuesError(datapoint, error));
      if (subscription !== undefined) {
        this._readPropertyValuesSubscription.set(datapoint.Designation, subscription);
      }
    } else if (datapoint !== undefined && datapoint !== null) {
      datapoint.Status = DatapointStatus.DoesNotExist;
      datapoint.ClearEvents();
    }
  }

  private onPropertyReadNotification(datapoint: Datapoint, propertyInfo: PropertyInfo<PropertyDetails>[], subscribeForCOV: boolean): void {
    this.zone.runOutsideAngular(() => {

      if (datapoint === undefined) {
        return;
      }

      this.unSubscribeFromReadPropertyValues(datapoint.Designation);

      if (propertyInfo[0].ErrorCode === 0) {
        const propertyDetails: PropertyDetails[] = propertyInfo[0].Properties !== undefined ?
          propertyInfo[0].Properties : propertyInfo[0].FunctionProperties !== undefined ? propertyInfo[0].FunctionProperties : undefined;

        const objectId: string = propertyInfo[0].Attributes !== undefined ? propertyInfo[0].Attributes.ObjectId : undefined;

        if (propertyDetails === undefined) {
          if (objectId !== undefined) {
            datapoint.Id = objectId; // set the datapoint id if available
          }

          // log error code
          this.traceService.error(this.traceModule, 'onPropertyReadNotification() called: dp Designation: %s. Property details undefined.',
            datapoint.Designation);
        } else {

          const isFunctionProperty: boolean = propertyInfo[0].FunctionProperties !== undefined;
          const propertyName: string = propertyDetails[0].PropertyName;

          // update datapoint Id
          if (propertyName !== undefined && objectId !== undefined) {
            if (isFunctionProperty) {
              if (!propertyInfo[0].FunctionProperties[0].MappedPropertyId) {
                // MappedPropertyId does not exist
                this.traceService.warn(this.traceModule, `Dp Designation: ${datapoint.Designation} is missing MappedPropertyId`);
              }
              datapoint.Id = propertyInfo[0].FunctionProperties[0].MappedPropertyId;
            } else {
              datapoint.Id = objectId + '.' + propertyName;
            }
          } else {
            datapoint.Id = objectId;
          }

          const hasAttributes: boolean = propertyInfo[0].Attributes !== undefined;
          this.traceService.info(this.traceModule, 'ONPROPERTYREADNOTIFICATION() called: DP DESIGNATION: %s DP ID: %s',
            datapoint.Designation, datapoint.Id);
          datapoint.FunctionName = hasAttributes ? propertyInfo[0].Attributes.FunctionName : undefined;

          // Get the correct Object Model Name.
          const propertyInfoFunction: PropertyInfoFunction<PropertyDetails> = propertyInfo[0] as PropertyInfoFunction<PropertyDetails>;
          const functionObjectModelName: string = (propertyInfoFunction !== undefined && propertyInfoFunction.ObjectFunctionAttribute !== undefined
                    && propertyInfoFunction.ObjectFunctionAttribute.ObjectModelName !== undefined) ? propertyInfoFunction
              .ObjectFunctionAttribute.ObjectModelName : undefined;

          if (functionObjectModelName !== undefined && functionObjectModelName.trim().length !== 0) {
            datapoint.ObjectModelName = propertyInfoFunction.ObjectFunctionAttribute.ObjectModelName;
          } else {
            datapoint.ObjectModelName = hasAttributes ? propertyInfo[0].Attributes.ObjectModelName : undefined;
          }

          datapoint.Alias = hasAttributes ? propertyInfo[0].Attributes.Alias : undefined;
          datapoint.ManagedType = hasAttributes ? propertyInfo[0].Attributes.ManagedType : undefined;
          datapoint.ManagedTypeName = hasAttributes ? propertyInfo[0].Attributes.ManagedTypeName : undefined;
          if (propertyDetails !== undefined) {
            datapoint.TextGroupName = propertyDetails[0].TextTable;
            datapoint.Units = propertyDetails[0].UnitDescriptor;

            datapoint.Descriptor = propertyDetails[0].Descriptor;

            datapoint.DatapointType = propertyDetails[0].Type;
            datapoint.Precision = propertyDetails[0].Resolution;
            datapoint.BACnetDateTimeDetail = propertyDetails[0].BACnetDateTimeDetail;
            datapoint.BACnetDateTimeResolution = propertyDetails[0].BACnetDateTimeResolution;
            datapoint.DurationDisplayFormat = propertyDetails[0].DurationDisplayFormat;
            datapoint.DurationValueUnits = propertyDetails[0].DurationValueUnits;

            if (datapoint.DatapointType !== undefined) {
              let minValue: number = Number.NaN;
              let maxValue: number = Number.NaN;
              const valMin: string = propertyDetails[0].Min;
              const valMax: string = propertyDetails[0].Max;
              switch (datapoint.DatapointType) {
                case 'BasicBool':
                case 'ExtendedBool':
                  minValue = 0;
                  maxValue = 1;
                  break;
                case 'ExtendedEnum':
                  minValue = valMin === undefined ? 0 : Number(valMin);
                  maxValue = valMax === undefined ? FormatHelper.uint32Max : Number(valMax);
                  break;
                case 'ExtendedReal':
                case 'BasicFloat':
                  minValue = valMin === undefined ? 0 - Number.MAX_VALUE : parseFloat(valMin);
                  maxValue = valMax === undefined ? Number.MAX_VALUE : parseFloat(valMax);
                  break;
                case 'ExtendedUint':
                  minValue = valMin === undefined ? 0 : Number(valMin);
                  maxValue = valMax === undefined ? FormatHelper.uint32Max : Number(valMax);
                  break;
                case 'ExtendedInt':
                  minValue = valMin === undefined ? FormatHelper.int32Min : Number(valMin);
                  maxValue = valMax === undefined ? FormatHelper.int32Max : Number(valMax);
                  break;
                case 'ExtendedBitString':
                case 'ExtendedBitString64':
                  minValue = valMin === undefined ? 0 : Number(valMin);
                  maxValue = valMax === undefined ? MathUtils.ToUint32(-1) : Number(valMax);
                  break;
                case 'ExtendedUint64':
                case 'BasicUint64':
                  minValue = valMin === undefined ? 0 : Number(valMin);
                  maxValue = valMax === undefined ? Number.MAX_SAFE_INTEGER : Number(valMax);
                  datapoint.MinLongValue = valMin === undefined ? FormatHelper.uint64Min : FormatHelper.parseLongValue(valMin, true);
                  datapoint.MaxLongValue = valMin === undefined ? FormatHelper.uint64Max : FormatHelper.parseLongValue(valMax, true);
                  break;

                case 'ExtendedInt64':
                case 'BasicInt64':
                  minValue = valMin === undefined ? Number.MIN_SAFE_INTEGER : Number(valMin);
                  maxValue = valMax === undefined ? Number.MAX_SAFE_INTEGER : Number(valMax);
                  datapoint.MinLongValue = valMin === undefined ? FormatHelper.int64Min : FormatHelper.parseLongValue(valMin, false);
                  datapoint.MaxLongValue = valMin === undefined ? FormatHelper.int64Max : FormatHelper.parseLongValue(valMax, false);
                  break;

                default:
                  break;
              }
              datapoint.MinValue = minValue;
              datapoint.MaxValue = maxValue;
            }
          }
          datapoint.Status = datapoint.SystemName === '' || this.systemsOnline.includes(datapoint.SystemName) ?
            DatapointStatus.Valid : DatapointStatus.Invalid;

          if (subscribeForCOV === true) {
            this._subscriptionInfoPerDataPoint.set(datapoint.Designation,
              { objectId: datapoint.Id, valueSubscription: undefined, subscription: undefined });

            this.subscribeForCOV(datapoint);
          }

          this.setDatapointToRecieveEvents(datapoint);

          // no errors detected, return
          return;
        }
      } else {
        // log error code
        // this.traceService.error(this.traceModule, "onPropertyReadNotification() called: dp Designation: %s; errorCode: %s",
        // datapoint.Designation, propertyInfo[0].ErrorCode);
      }

      // handling error
      datapoint.Value = undefined;
      // NOTE: Handle Error Codes cases
      // datapoint doesn't exist
      datapoint.Status = DatapointStatus.DoesNotExist;

      // datapoint ValueDone used #ENG visibility and snapshot creation updates
      datapoint.ValueDone = true;

      datapoint.ClearEvents();
    });
  }

  private onSubscribeValueUpdatesError(error: Error): void {
    this.traceService.error(this.traceModule, 'onSubscribeValueUpdatesError(): error: %s', error.message);
    this.errorMessage = error as any;
  }

  private onValueChangeNotificationError(error: Error): void {
    this.traceService.error(this.traceModule, 'onValueChangeNotificationError(): error: %s', error.message);
    this.errorMessage = error as any;
  }

  private onReadPropertyValuesError(datapoint: Datapoint, error: Error): void {
    if (datapoint !== undefined) {
      this.unSubscribeFromReadPropertyValues(datapoint.Designation);
    }
    this.traceService.error(this.traceModule, 'onReadPropertyValuesError(): error: %s', error.message);
    this.errorMessage = error as any;

    datapoint.Status = DatapointStatus.DoesNotExist;

    datapoint.ClearEvents();
  }

  private unSubscribeFromReadPropertyValues(datapointDesignation: string): void {

    const sub: Subscription = this._readPropertyValuesSubscription.get(datapointDesignation);

    if (sub !== undefined) {
      sub.unsubscribe();
      this._readPropertyValuesSubscription.delete(datapointDesignation);
    }
  }

  /**
   *
   * @param dataPoint
   */
  private Add(datapoint: Datapoint, subscribeForCOV: boolean = true): void {
    // add datapoint to collection
    this.globalDatapoints.set(datapoint.Designation, datapoint);

    // if datapoint is not subscribed yet for update, then will subscribe it.
    if (datapoint.CountUsage < 2) { // either a client added it, or reading and adding it from the graphics header
      datapoint.Status = DatapointStatus.Pending;
      // read property
      this.readPropertyValues(datapoint, subscribeForCOV);
    }
  }

  /**
   *
   * @param dataPoint
   */
  private DefferredAdd(datapoint: Datapoint): void {
    // add datapoint to collection
    this.deferredDatapoints.set(datapoint.Designation, datapoint);
    // update status
    // datapoint.Status = DatapointStatus.Pending;
  }

  /**
   *  Unsubscribe all datapoints from COVs
   */
  private unSubscribeAll(): void {

    this._currentSystem = undefined;
    const valueSubscriptions: GmsSubscription<ValueDetails>[] = [];

    this._readPropertyValuesMutiSubscription.forEach((sub, index, arr) => {
      sub.unsubscribe();
    });

    this._readPropertyValuesMutiSubscription.clear();

    this._subscriptionInfoPerDataPoint.forEach((sub, index, arr) => {
      sub?.subscription?.unsubscribe();

      // One value subscription per dp id
      if (!valueSubscriptions.includes(sub.valueSubscription)) {
        valueSubscriptions.push(sub.valueSubscription);
      }
    });

    if (valueSubscriptions.length > 0) {
      this.valueSubscriptionService.unsubscribeValues(valueSubscriptions, this.clientId);
    }
    this._subscriptionInfoPerDataPoint.clear();

    // unsubscribe property read, if any
    this._readPropertyValuesSubscription.forEach((subscription, key) => {
      subscription.unsubscribe();
    });

    if (this.clientId !== undefined) {
      this.valueSubscriptionService.disposeClient(this.clientId);
    }
    this.globalDatapoints.clear();
    this.globalDatapointsForEvents.forEach(value => {
      value.length = 0; // Clear the array of datapoints
    });
    this.globalDatapointsForEvents.clear();

    this.globalEvents.forEach(value => {
      value.length = 0; // Clear the array of events
    });
    this.globalEvents.clear();

    this._activeWildCardreferences.forEach((value: WildCardReferenceItem, key: string, map: Map<string, WildCardReferenceItem>) => {
      value.Destroy();
    });

    this._activeWildCardreferences.clear();

    this._resolvedWildCardreferences.forEach((value: WildCardReferenceItem, key: string, map: Map<string, WildCardReferenceItem>) => {
      value.Destroy();
    });

    this._resolvedWildCardreferences.clear();

    for (let i: number; i < this._readPropertyValuesMultiWildCardSubscription.length; i++) {
      const subscription: Subscription = this._readPropertyValuesMultiWildCardSubscription[i];
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    }
    this._readPropertyValuesMultiWildCardSubscription.length = 0;
  }

  private async setDatapointToRecieveEvents(datapoint: Datapoint): Promise<void> {
    if (datapoint === undefined) {
      return;
    }

    const splitResult: string[] = datapoint.Id.split('.');

    // Syntax of datapoint id - <SystemName>:<ObjectID>.<PropertyName>
    // ObjectId to match with events = SystemName:ObjectID
    const objectId: string = splitResult.length > 0 ? splitResult[0] : undefined;

    if (objectId !== undefined) {
      if (this.globalDatapointsForEvents.has(objectId)) {
        const datapointGroup: Datapoint[] = this.globalDatapointsForEvents.get(objectId);
        datapointGroup.push(datapoint);
        datapoint.ObjectIdEvents = objectId;
      } else {
        const arrayInitial: Datapoint[] = new Array<Datapoint>();
        arrayInitial.push(datapoint);
        this.globalDatapointsForEvents.set(objectId, arrayInitial);
        datapoint.ObjectIdEvents = objectId;
      }
    }

    this.setEventInfosToDp(datapoint);

  }

  private async onEventsNotification(events: Event[]): Promise<void> {
    this.zone.runOutsideAngular(() => {
      // Alarm Datapoint(s) state maintenance
      events.forEach((event: Event) => {
        this.setEventInfoToDp(event);
      });

      // Events list maintenance
      events.forEach((event: Event) => {
        const splitResult: string[] = event.srcPropertyId.split('.');
        const objectId: string = splitResult.length > 0 ? splitResult[0] : undefined;

        if (objectId === undefined) {
          return;
        }

        if (this.globalEvents.has(objectId)) {
          const eventGroup: Event[] = this.globalEvents.get(objectId);
          if (!eventGroup.includes(event)) {
            const state: AlarmState = AlarmState[event.originalState];
            if (state === AlarmState.Closed) {
              const indexToRemove: number = eventGroup.indexOf(event);
              eventGroup.splice(indexToRemove, 1);
            }
            eventGroup.push(event);
          } else {
            const state: AlarmState = AlarmState[event.originalState];
            if (state !== AlarmState.Closed) {
              eventGroup.push(event);
            }
          }
        } else {
          const eventGroup: Event[] = [event];
          this.globalEvents.set(objectId, eventGroup);
        }
      });
    });
  }

  private async setEventInfoToDp(event: Event): Promise<void> {
    this.zone.runOutsideAngular(() => {
      // srcPropertyId has the ObjectId
      const splitResult: string[] = event.srcPropertyId.split('.');
      const objectId: string = splitResult.length > 0 ? splitResult[0] : undefined;
      if (objectId === undefined) {
        return;
      }

      // Query for the datapoints associated to the ObjectId
      const associatedDatapoint: Datapoint[] = this.globalDatapointsForEvents.get(objectId);

      if (associatedDatapoint !== undefined && associatedDatapoint.length > 0) {
        asapScheduler.schedule(() => {
          this.zone.runOutsideAngular(() => {
            associatedDatapoint.forEach(datapoint => {
              datapoint.SetEventInfo(event);
            });
          });
        }, 0);
      }
    });
  }

  private async setEventInfosToDp(datapoint: Datapoint): Promise<void> {
    this.zone.runOutsideAngular(() => {
      // If events already exist update the datapoint with events
      const events: Event[] = this.globalEvents.get(datapoint.ObjectIdEvents);
      if (events === undefined || events.length <= 0) {
        return;
      }

      events.forEach((event: Event) => {
        // srcPropertyId has the ObjectId
        const splitResult: string[] = event.srcPropertyId.split('.');
        const objectId: string = splitResult.length > 0 ? splitResult[0] : undefined;
        if (objectId === undefined) {
          return;
        }

        asapScheduler.schedule(() => {
          this.zone.runOutsideAngular(() => {
            datapoint.SetEventInfo(event);
          });
        }, 0);
      });
    });
  }

  private clearEvents(): void {
    this.globalEvents.forEach(value => {
      value.length = 0;
    });
    this.globalEvents.clear();
    this.unsubscribeToEvents();
  }

  private NotifyPropertyChanged(propertyName: string = '', datapoints: Datapoint[]): void {
    const args: PropertyChangeArgs = new PropertyChangeArgs();
    args.PropertyName = propertyName;
    args.Datapoints = datapoints;
    this.propertyChanged.next(args);
  }

  private _currentSystem: SystemInfo = undefined;
  public setCurrentSystemContext(browserObject: BrowserObject): void {
    if (isNullOrUndefined(browserObject)) {
      this._currentSystem = undefined;
      return;
    }

    if (!isNullOrUndefined(this._currentSystem) && this._currentSystem?.Id === browserObject?.SystemId) {
      return;
    }

    this.systemsService.getSystems().subscribe({
      next: (systemInfos: SystemInfo[]) => {
        const systemInfo = systemInfos.find(x => x.Id === browserObject.SystemId);
        this._currentSystem = systemInfo;
      },
      error: (err: any) => {
        this.traceService.error(this.traceModule, 'setCurrentSystemContext(): ', err);
      }
    });
  }
}

export class PropertyChangeArgs {
  private _propertyName: string;
  public get PropertyName(): string {
    return this._propertyName;
  }
  public set PropertyName(value: string) {

    if (this._propertyName !== value) {
      this._propertyName = value;
    }
  }

  private _datapoints: Datapoint[];
  public get Datapoints(): Datapoint[] {
    return this._datapoints;
  }
  public set Datapoints(value: Datapoint[]) {
    if (this._datapoints !== value) {
      this._datapoints = value;
    }
  }
}
