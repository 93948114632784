import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { FakeSceneApiService, SceneEditorApi } from '../api/scene-editor.api';
import { SiSceneEditorService } from '../services';
import { SiSceneEditorComponent } from './si-scene-editor.component';
import { SiSceneMasterDetailComponent } from './si-scene-master-detail/si-scene-master-detail.component';

@NgModule({
  exports: [SiSceneEditorComponent, SiSceneMasterDetailComponent],
  imports: [SiSceneEditorComponent, SiSceneMasterDetailComponent]
})
export class SimplSceneEditorNgModule {
  static forRoot(
    config: { sceneApi?: Provider } = {}
  ): ModuleWithProviders<SimplSceneEditorNgModule> {
    return {
      ngModule: SimplSceneEditorNgModule,
      providers: [
        config.sceneApi ?? { provide: SceneEditorApi, useClass: FakeSceneApiService },
        { provide: SiSceneEditorService }
      ]
    };
  }
}
