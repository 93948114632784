import { Subscription } from 'rxjs';

import { Datapoint, DataPointPropertyChangeArgs } from '../processor/datapoint/gms-datapoint';
import { GraphicsDatapointHelper } from '../processor/datapoint/gms-datapoint-helper';
import { Evaluation } from '../processor/evaluation';
import { Expression } from '../processor/expression';
import { InstanceProperty } from '../processor/gms-symbol-instance-property';
import { Substitution } from '../processor/substitution';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { GmsElementType } from '../types/gms-element-types';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';
import { GmsGroup } from './gms-group';
import { GmsLayer } from './gms-layer';

export class GmsSymbolInstance extends GmsElement {
  private _objectRefDpSubscription: Subscription = undefined;
  private _objectRefDatapoint: Datapoint = undefined;
  private _symbolRef: string;
  private _alarmAnchor: GmsElement = undefined;

  public get SymbolRef(): string {
    return this._symbolRef;
  }
  public set SymbolRef(value: string) {

    if (this._symbolRef !== value) {
      this._symbolRef = value;

      this.NotifyPropertyChanged('SymbolRef');
    }
  }

  private _objectRef: string = undefined;
  public get ObjectRef(): string {
    return this._objectRef;
  }

  public set ObjectRef(value: string) {

    if (this._objectRef !== value) {
      this._objectRef = value;

      this.NotifyPropertyChanged('ObjectRef');
    }
  }

  public get ObjectRefDatapoint(): Datapoint {
    return this._objectRefDatapoint;
  }

  // If the symbol has the Alarm Anchor element
  // Element with Description "alarm-anchor"
  public get AlarmAnchor(): GmsElement {
    return this._alarmAnchor !== undefined ? this._alarmAnchor : this;
  }

  private readonly _substitutions: Substitution[] = new Array<Substitution>();
  public get Substitutions(): Substitution[] {
    return this._substitutions;
  }

  private readonly _instanceProperties: InstanceProperty[] = new Array<InstanceProperty>();
  public get InstanceProperties(): InstanceProperty[] {
    return this._instanceProperties;
  }

  public get SymbolBoundingTransformation(): string {
    return 'translate(' + this.AnchorX + ',' + this.AnchorY + ')';
  }

  // Symbol's bounding rect width
  private _actualWidth = 0;
  public get ActualWidth(): number {
    return this._actualWidth;
  }
  public set ActualWidth(value: number) {

    if (this._actualWidth !== value) {
      this._actualWidth = value;
    }
  }

  // Symbol's bounding rect height
  private _actualHeight = 0;
  public get ActualHeight(): number {
    return this._actualHeight;
  }
  public set ActualHeight(value: number) {

    if (this._actualHeight !== value) {
      this._actualHeight = value;
    }
  }

  private _anchorX = 0;
  public get AnchorX(): number {
    return this._anchorX;
  }
  public set AnchorX(value: number) {

    if (this._anchorX !== value) {
      this._anchorX = value;

      this.NotifyPropertyChanged('AnchorX');
    }
  }

  private _anchorY = 0;
  public get AnchorY(): number {
    return this._anchorY;
  }
  public set AnchorY(value: number) {

    if (this._anchorY !== value) {
      this._anchorY = value;

      this.NotifyPropertyChanged('AnchorY');
    }
  }

  private _isHiddenDueToNonExistantObjectRef = false;
  public get IsHiddenDueToNonExistantObjectRef(): boolean {
    return this._isHiddenDueToNonExistantObjectRef;
  }
  public set IsHiddenDueToNonExistantObjectRef(value: boolean) {

    if (this._isHiddenDueToNonExistantObjectRef !== value) {
      this._isHiddenDueToNonExistantObjectRef = value;
    }
  }

  public get AllSubstitution(): GmsElement[] {
    const allSubstitutions: GmsElement[] = [];

    if (this.Replication !== undefined && this.Replication.children.length > 0) {
      this.Replication.children.forEach((clone: GmsElement) => {
        allSubstitutions.push(clone);
      });
    }

    // don't return any children for GmsSymbolInstances
    // Other than the replication clones
    return allSubstitutions;
  }

  public AddChild(element: GmsElement): void {

    // Store the initial design values. This will be used for resize usecases.
    element.BoundingRectDesign.X = ((element.BoundingRectDesign.X - this.AnchorX));
    element.BoundingRectDesign.Y = ((element.BoundingRectDesign.Y - this.AnchorY));

    // Set the initial x, y values for the children.
    element.X = ((element.X - this.AnchorX));
    element.Y = ((element.Y - this.AnchorY));
    element.ChildAnchorX = this.AnchorX;
    element.ChildAnchorY = this.AnchorY;

    this.children.push(element);

    if (this.CommandVM !== null) {
      element.CommandVM = this.CommandVM;
    }

    // Marks the alarm anchor element if present
    this.markAlarmAnchor(element);

    // ChangeDetection: Let others know that a property changed
    this.NotifyPropertyChanged();
  }

  public UpdateWidthOfChildren(): void {
    if (this.ActualWidth <= 0) {
      return;
    }
    const factor: number = this.Width / this.ActualWidth;
    // coverage area elements support
    if (this.InstanceProperties.length > 0) {
      this.children.forEach(childElement => {
        if (childElement.InternalId !== null) {
          this.UpdateInternalWidthOfChild(childElement, factor);
        }
      });
    } else {
      // general elements support
      if (this.Width !== this.ActualWidth) {
        this.children.forEach(childElement => {
          childElement.UpdateWidthResize(factor);
          if (childElement instanceof GmsSymbolInstance || childElement instanceof GmsGroup) {
            // GmsPipe doesnt support resize!!
            /* || childElement instanceof GmsPipe*/
            childElement.UpdateWidthOfChildren();
          }
        });
      }
    }
  }

  public UpdateInternalWidthOfChild(childElement: GmsElement, factor: number): void {

    const childFactor: number = childElement.Width / childElement.DesignValueWidth;
    if (childElement.IsWidthModified && !childElement.IsFigureModified()) {
      if (childFactor !== 1) {
        childElement.UpdateInternalWidthResize(childFactor, factor);
      } else {
        childElement.UpdateWidthResize(factor);
      }
    } else {
      // factor != 1, childFactor != 1, figure modified
      if (/* childFactor !== 1 && */factor !== 1 && childElement.IsFigureModified()) {
        childElement.UpdateInternalWidthResize(1, factor);
      } else {
        childElement.UpdateWidthResize(factor);
      }
    }
    if (childElement instanceof GmsSymbolInstance || childElement instanceof GmsGroup) {
      // GmsPipe doesnt support resize!!
      /* || childElement instanceof GmsPipe*/
      childElement.UpdateWidthOfChildren();
    }
  }

  public UpdateInternalHeightOfChild(childElement: GmsElement, factor: number): void {
    const childFactor: number = childElement.Height / childElement.DesignValueHeight;
    if (childElement.IsHeightModified && !childElement.IsFigureModified()) {
      if (childFactor !== 1) {
        childElement.UpdateInternalHeightResize(childFactor, factor);
      } else {
        childElement.UpdateHeightResize(factor);
      }
    } else {
      // factor != 1, childFactor != 1, figure modified
      if (/* childFactor !== 1 && */factor !== 1 && childElement.IsFigureModified()) {
        childElement.UpdateInternalHeightResize(1, factor);
      } else {
        childElement.UpdateHeightResize(factor);
      }
    }
    if (childElement instanceof GmsSymbolInstance || childElement instanceof GmsGroup) {
      // GmsPipe doesnt support resize!!
      /* || childElement instanceof GmsPipe*/
      childElement.UpdateHeightOfChildren();
    }
  }
  protected UpdatePropertyHeight(evaluation: Evaluation): void {
    super.UpdatePropertyHeight(evaluation);
    this.UpdateHeightOfChildren();
  }

  protected UpdatePropertyWidth(evaluation: Evaluation): void {
    super.UpdatePropertyWidth(evaluation);
    this.UpdateWidthOfChildren();
  }

  public UpdateHeightOfChildren(): void {
    if (this.ActualHeight <= 0) {
      return;
    }
    const factor: number = this.Height / this.ActualHeight;
    // coverage area elements support
    if (this.InstanceProperties.length > 0) {
      this.children.forEach(childElement => {
        if (childElement.InternalId !== null) {
          this.UpdateInternalHeightOfChild(childElement, factor);
        }
      });
    } else {
      // general elements support
      if (this.Height !== this.ActualHeight) {
        this.children.forEach(childElement => {
          childElement.UpdateHeightResize(factor);
          if (childElement instanceof GmsSymbolInstance || childElement instanceof GmsGroup) {
            // GmsPipe doesnt support resize!!
            /* || childElement instanceof GmsPipe*/
            childElement.UpdateHeightOfChildren();
          }
        });
      }
    }
  }

  public RemoveChild(element: GmsElement): void {
    const index: number = this.children.indexOf(element, 0);
    if (index > -1) {
      element.CommandVM = null;
      this.children.splice(index, 1);

      // ChangeDetection: Let others know that a property changed
      this.NotifyPropertyChanged();
    }
  }

  constructor(type: GmsElementType = GmsElementType.SymbolInstance) {
    super(type);
    this.children = [];
  }

  public Deserialize(node: Node): void {
    super.Deserialize(node);

    let result: string = SvgUtility.GetAttributeValue(node, 'ObjectRef');
    if (result !== undefined) {
      this.ObjectRef = result;
    }

    result = SvgUtility.GetAttributeValue(node, 'SymbolRef');
    if (result !== undefined) {
      this.SymbolRef = result;
    }
    this.DeserializeEvaluations(node);
    this.DeserializeSubstitutions(node);
    // CA support
    this.DeserializeInstanceProperties(node);

  }

  public CopyFrom(element: GmsSymbolInstance): void {
    this.IsCopying = true;

    this.ActualWidth = element.ActualWidth;
    this.ActualHeight = element.ActualHeight;
    this.AnchorX = element.AnchorX;
    this.AnchorY = element.AnchorY;

    this.ObjectRef = element.ObjectRef;
    this.SymbolRef = element.SymbolRef;

    super.CopyFrom(element);

    this.CopySubstitutions(element);
    this.CopyEvaluations(element);

    this.Graphic.ReplicationService.CopyChildren(element, this);

    this.IsCopying = false;
  }

  public IsTopLevelSymbolInstance(): boolean {
    if (this.Parent === undefined) {
      return false;
    }

    return this.CheckParent(this.Parent);
  }

  /**
   * Updates/merges substitutions of children of the symbolInstance to symbolInstance
   */
  public ProcessSubstitutions(): void {

    const elements: GmsElement[] = this.children;
    let elementsSubstitution: GmsElement[] = elements;

    for (const child of elements) {
      // get all nested elements but without the children of GmsSymbolInstances
      elementsSubstitution = elementsSubstitution.concat(child.AllSubstitution);
    }

    // collect all substitutions from the elements (its evaluations and substitutions)
    const substitutionsFromContent: Map<string, Substitution> = new Map<string, Substitution>(); // the substitutions from the parsed expression

    for (const element of elementsSubstitution) {
      element.AddOrUpdateSubstitutions(substitutionsFromContent);
    }

    // merge substitutionsFromContent to Substitutions, where unused Substitutions will be deleted
    substitutionsFromContent.forEach((sourceSubstitution: Substitution, key: string) => {
      const substitution: Substitution = this.Substitutions.find(value =>
        value.Key.toLowerCase() === sourceSubstitution.Key.toLowerCase()
      );

      if (substitution == null) {
        this.Substitutions.push(sourceSubstitution);
        if (sourceSubstitution.IsObjectRef) {
          // the ObjectRef property could have been set earlier during the load process
          sourceSubstitution.Value = this.ObjectRef;
        }
      } else {
        sourceSubstitution.Value = substitution.Value; // this will update the substitution sources (expressions)

        // copy also the substitution sources
        for (const iSubst of sourceSubstitution.SubstitutionSources) {
          if (!substitution.SubstitutionSources.includes(iSubst)) {
            substitution.SubstitutionSources.push(iSubst);
          }
        }
      }
    });

    // remove all unused substitutions (those without substitution sources)
    const substitutionsToRemove: Substitution[] = this.Substitutions.filter((substitution: Substitution) =>
      substitution.SubstitutionSources.length === 0
    );

    substitutionsToRemove.forEach((substitution: Substitution) => {
      const indexToRemove: number = this.Substitutions.indexOf(substitution);
      this.Substitutions.splice(indexToRemove, 1);
    });
  }

  public UpdateObjectRefSubstitutions(): void {
    this.Substitutions.forEach((substitution: Substitution) => {
      if (substitution.IsObjectRef) {
        substitution.Value = this.ObjectRef;
      }
    });
  }

  /**
   * Adds or updates a collection of substitutions
   * @param substitutions a collection of substitutions
   */
  public AddOrUpdateSubstitutions(substitutions: Map<string, Substitution>): void {
    // get all substitutions from all evaluations
    if (this.Evaluations != null) {
      this.Evaluations.forEach((evaluation: Evaluation) => {
        evaluation.Expressions.forEach((expression: Expression) => {
          expression.Substitutions.forEach((substitution: Substitution) => {
            this.AddOrUpdate(substitutions, substitution, evaluation); // the evaluation is the substitution source
          });
        });
      });
    }

    // AllSubstitution doesn't return the internal elements of a GmsSymbolInstance so instead use its substitution values
    if (this instanceof GmsSymbolInstance) {
      for (const substitution of this.Substitutions) {
        if (substitution.Substitutions !== undefined) {
          substitution.Substitutions.forEach((substNested: Substitution) => {
            this.AddOrUpdate(substitutions, substNested, substitution); // the substitution is the source for the nested substitutions
          });
        }
      }
    }
  }

  public UpdateGraphicViewObjectReference(substitutionObjectReference: Substitution): void {
    if (!this.IsTopLevelSymbolInstance() || substitutionObjectReference === undefined) {
      return;
    }

    this.ObjectRef = substitutionObjectReference.ValueSubstituted;
    this.Substitutions.forEach(substitution => {
      if (substitution.Substitutions !== undefined && substitution.Substitutions.size > 0) {
        substitution.Update(false, substitutionObjectReference);
      }
    });
  }

  public CheckObjectReferenceExistence(): void {

    let objectrefsubstituted: string;
    const objectRefSubstitution: Substitution = this.Substitutions.find(substitution => substitution.IsObjectRef);
    if (objectRefSubstitution !== undefined) {
      objectrefsubstituted = objectRefSubstitution.ValueSubstituted;
    }

    if (objectRefSubstitution === undefined) {
      objectrefsubstituted = this.ObjectRef;
    }

    if (objectrefsubstituted === undefined || (objectrefsubstituted !== undefined && objectrefsubstituted.trim() === '')) {
      return;
    }

    if (objectrefsubstituted.includes('?') || objectrefsubstituted.includes('{') || objectrefsubstituted.includes('"')
            || Utility.IsNumeric(objectrefsubstituted[0])) {
      return;
    }

    if (objectrefsubstituted.includes('[*]') && !this.IsReplicationClone && this.Replication === undefined) {
      this.CreateReplication();
      this.Replication.WildCardItem = this;
      this.Replication.WildCardReference = objectrefsubstituted;
      return;
    }

    const designation: string = GraphicsDatapointHelper.RemoveLeadingSemicolons(objectrefsubstituted);

    if (designation === undefined || designation !== undefined && designation.trim() === '') {
      return;
    }

    const objectRefDp: Datapoint = this.DatapointService.GetOrCreateByDesignation(designation);

    if (objectRefDp.Status === DatapointStatus.DoesNotExist) {
      this.IsHiddenDueToNonExistantObjectRef = true;
      this.UpdateVisible();
      return;
    }

    if (objectRefDp.Status !== DatapointStatus.Valid) {
      this._objectRefDpSubscription = objectRefDp.propertyChanged.subscribe(args => this.Datapoint_PropertyChanged(args));
    }
  }

  public UpdateVisible(): void {
    if (this.IsHiddenDueToNonExistantObjectRef === true) {
      this.Visible = false;
      return;
    }

    super.UpdateVisible();
  }

  public DeserializeSubstitutions(node: Node): void {
    const substitutionsTagName = '.Substitutions';
    for (let i: number = node.childNodes?.length - 1; i >= 0; i--) {
      const child: Node = node.childNodes[i];
      if (child.nodeName.endsWith(substitutionsTagName)) {
        for (let j: number = child.childNodes.length - 1; j >= 0; j--) {
          const substitutionNode: Node = child.childNodes[j];
          if (substitutionNode.nodeName === 'Substitution') {
            const substitution: Substitution = new Substitution();
            substitution.ParentSymbolInstance = this;
            substitution.Deserialize(substitutionNode);
            this.Substitutions.push(substitution);
          }
        }
        break;
      }
    }
  }

  public Destroy(): void {

    if (this.children !== undefined) {
      const itemsToDestroy: GmsElement[] = this.children.slice(); // Copies the array, since destroy changes the source array
      itemsToDestroy.forEach(child => {
        child.Destroy();
      });
      itemsToDestroy.length = 0;

      if (this._alarmAnchor !== undefined) {
        this._alarmAnchor = undefined;
      }
    }

    this.Substitutions.forEach(substitution => substitution.Clear());
    this.Substitutions.splice(0, this.Substitutions.length);

    if (this._objectRefDpSubscription !== undefined) {
      this._objectRefDpSubscription.unsubscribe();
      this._objectRefDpSubscription = undefined;
    }

    this._objectRefDatapoint = undefined;

    super.Destroy();
  }

  public PropagateAlarm(): boolean {
    let alarmPropogationHandled = false;

    // Don't propagate the alarms from
    // replication clones
    if (this.IsReplicationClone) {
      this.UpdateAlarmState();
      return true;
    }

    // Inside nested symbols instances
    // Show the propagated alarms only on the
    // top level symbol instance
    // or if it is the replication clone
    const isTopLevel: boolean = this.IsTopLevelSymbolInstance();
    if (isTopLevel || this.IsReplicationClone) {
      if (this.Zone !== undefined) {
        this.Zone.runOutsideAngular(() => setTimeout(() => this.UpdateAlarmState(), 0));
      }
      return true;
    }

    // For groups inside symbols alarms will be propagated upwards
    // to the top level symbol instance
    if (this.Parent !== undefined) {
      alarmPropogationHandled = this.Parent.PropagateAlarm();
    }

    return alarmPropogationHandled;
  }

  public GetDpsForAlarms(): Datapoint[] {
    if (!this.CalculateVisible()) {
      return [];
    }

    const ownedDpsForAlarms: Datapoint[] = super.GetDpsForAlarms();
    const propagatedDpsForAlarms: Datapoint[] = ownedDpsForAlarms;

    if (this.children.length > 0) {
      this.AllChildren.forEach(child => {
        const childDpsForAlarms: Datapoint[] = child.GetDpsForAlarms();
        // Process the datapoints only for the visible children
        if (child.Visible) {
          childDpsForAlarms.forEach(childDp => {
            if (!propagatedDpsForAlarms.includes(childDp)) {
              propagatedDpsForAlarms.push(childDp);
            }
          });
        }
      });
    }

    return propagatedDpsForAlarms;
  }

  // Marks the configured alarm-anchor element in the immediate children
  // Position of the alarm-anchor element will be used
  // to position the Alarm Indication
  private markAlarmAnchor(child: GmsElement): void {
    if (child === undefined || child.Description === undefined) {
      return;
    }

    const description: string = child.Description.trim().toLowerCase();
    if (description === Utility.ALARM_ANCHOR_DESCRIPTION) {
      this._alarmAnchor = child;
    }
  }

  // True if this symbolinstance is a child of the graphic itself
  // not the content of a symbolinstance inside the graphic.
  private CheckParent(parent: GmsElement): boolean {
    if (parent === undefined) {
      return false;
    }

    if (parent instanceof GmsSymbolInstance) {
      return false;
    } else if (parent instanceof GmsLayer) {
      return true;
    } else if (parent.Parent !== undefined) {
      return this.CheckParent(parent.Parent);
    }

    return false;
  }

  private CopySubstitutions(element: GmsSymbolInstance): void {
    const substitutions: Substitution[] = element.Substitutions;
    for (let i = 0; i < substitutions.length; i++) {
      const sourceSubstitution: Substitution = substitutions[i];
      const newSubstitution: Substitution = new Substitution();
      newSubstitution.ParentSymbolInstance = this;
      newSubstitution.CopyFrom(sourceSubstitution);
      this.Substitutions.push(newSubstitution);
    }
  }

  private DeserializeInstanceProperties(node: Node): void {
    for (let i: number = node.childNodes?.length - 1; i >= 0; i--) {
      const child: Node = node.childNodes[i];
      // skip the ScaleTransform group and access the InstanceProperties node
      if (SvgUtility.IsNodeScaleTransformationGroup(child)) {
        this.DeserializeInstanceProperties(child);
        break;
      }
      if (child.nodeName.endsWith('.InstanceProperties')) {
        for (let j: number = child.childNodes.length - 1; j >= 0; j--) {
          const instanceNode: Node = child.childNodes[j];
          if (instanceNode.nodeName === 'InstanceProperty') {
            const instanceProperty: InstanceProperty = InstanceProperty.Deserialize(instanceNode);
            if (instanceProperty !== null) {
              this.InstanceProperties.push(instanceProperty);
            }
          }
        }
        break;
      }
    }
  }

  // private DeserializeInstanceProperties(node: Node): void {
  //    const instancePropertiesTagName = ".InstanceProperties";
  //    for (let i: number = node.childNodes.length - 1; i >= 0; i--) {
  //        const child: Node = node.childNodes[i];
  //        if (child.nodeName.endsWith(instancePropertiesTagName)) {
  //            for (let j: number = child.childNodes.length - 1; j >= 0; j--) {
  //                const instanceNode: Node = child.childNodes[j];
  //                if (instanceNode.nodeName === "InstanceProperty") {
  //                    const instanceProperty: InstanceProperty = InstanceProperty.Deserialize(instanceNode);
  //                    if (instanceProperty !== null) {
  //                        this.InstanceProperties.push(instanceProperty);
  //                    }
  //                }
  //            }
  //            break;
  //        }
  //    }
  // }

  private async Datapoint_PropertyChanged(args: DataPointPropertyChangeArgs): Promise<void> {
    if (args.PropertyName === 'Status') {
      const sourceDp: Datapoint = args.SourceDatapoint;
      if (sourceDp.Status === DatapointStatus.DoesNotExist) {
        this.IsHiddenDueToNonExistantObjectRef = true;
      }

      if ((sourceDp.Status === DatapointStatus.DoesNotExist) || (sourceDp.Status === DatapointStatus.Valid)) {
        this._objectRefDpSubscription.unsubscribe();
      }

      this.UpdateVisible();
    }
  }
}
