<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="form.invalid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    @if (readonly || popover.isBlocked || property.defaultText) {
      <div class="form-control d-flex align-items-center" [attr.readonly]="readonly" input>
        <span>
          @if (!property.defaultText && property.value.value !== undefined) {
            {{ activeValue }}
          } @else {
            {{ property.defaultText || property.value.altText }}
          }
          <ng-template #defaultText>
            {{ property.defaultText || property.value.altText }}
          </ng-template>
        </span>
      </div>
    }
    @if (!readonly && !popover.isBlocked) {
      <select
        name="main"
        class="form-control"
        [(ngModel)]="popover.modelValue"
        [required]="!property.value.optional && (!property.value.altText || popover.isActive)"
        [attr.aria-label]="property.name"
        (ngModelChange)="valueChange()"
        (mousedown)="popover.open()"
        (touchdown)="popover.open()"
        (keydown)="popover.openCloseOnKeyboard($event)"
        input
      >
        @if (property.value?.altText) {
          <option [ngValue]="undefined" disabled>
            {{ property.value.altText }}
          </option>
        }
        @for (option of property.value.options; track $index) {
          <option [ngValue]="option.value">
            {{ option.text }}
          </option>
        }
      </select>
    }
  </si-property-popover>
</form>
