import { Injectable } from '@angular/core';
import { CommandSubscriptionProxyServiceBase, ConnectionState, DpIdentifier, PropertyCommand, SubscriptionDeleteWsi,
  SubscriptionGmsCmd, SubscriptionWsiCmd, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, asyncScheduler, Observable, observeOn, of, Subject, Subscription, throwError, timer } from 'rxjs';

import { PointService } from '../../bx-services/point/point.service';
import { UtilityService } from '../shared/utility.service';
import { CommandMapperBxToGmsService } from './command-mapper-bx-to-gms.service';

@Injectable()
export class CommandSubscriptionBxSubstituteProxyService extends CommandSubscriptionProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _cmdEvents: Subject<PropertyCommand[]> = new Subject<PropertyCommand[]>();
  private readonly subscriptions: Map<number, SubscriptionGmsCmd> = new Map<number, SubscriptionGmsCmd>();
  private timerSubscription: Subscription;

  public constructor(private readonly traceService: TraceService,
    private readonly commandMapper: CommandMapperBxToGmsService,
    private readonly pointService: PointService,
    private readonly utilityService: UtilityService) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.traceService.info(TraceModules.command, 'CommandSubscriptionBxSubstituteProxyService created.');
  }

  /**
   * Subscribes the specified object/property ids.
   */
  public subscribeCommands(propertyIds: string[], booleansAsNumericText?: boolean): Observable<SubscriptionGmsCmd[]> {

    if ((propertyIds == null) || (propertyIds.length === 0)) {
      this.traceService.error(TraceModules.command, 'CommandSubscriptionBxSubstituteProxyService.subscribeCommands() called with invalid arguments!');
      return throwError(() => new Error('CommandSubscriptionBxSubstituteProxyService.subscribeCommands() called with invalid arguments'));
    }
    this.traceSubscribeCommands(propertyIds);

    this.stopTimerForSubscription();
    const subKeys: SubscriptionGmsCmd[] = [];
    const requestId = this.utilityService.getSignalRCtx();
    propertyIds.forEach(objId => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const subKeyWsi: SubscriptionWsiCmd = {
        Key: this.utilityService.getSubscriptionKey(),
        PropertyId: objId,
        SubscriptionId: objId,
        ErrorCode: 0,
        RequestId: requestId,
        RequestFor: 'notifyCommands'
      };
      /* eslint-enable @typescript-eslint/naming-convention */
      const subKey: SubscriptionGmsCmd = new SubscriptionGmsCmd(subKeyWsi);
      subKeys.push(subKey);
      this.subscriptions.set(subKey.key, subKey);
    });

    this.traceSubscribedCommandsCount();
    this.startTimerForSubscription(100);
    return of(subKeys).pipe(observeOn(asyncScheduler));
  }

  /**
   * Event for the command notifications.
   */
  public commandChangeNotification(): Observable<PropertyCommand[]> {
    return this._cmdEvents;
  }

  /**
   * Event for connection state changes
   */
  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  /**
   * Unsubscribes objectOrPropertyIds (associated with the subscription keys).
   */
  public unSubscribeCommands(keys: number[]): Observable<SubscriptionDeleteWsi[]> {
    if ((keys == null) || (keys.length === 0)) {
      this.traceService.error(TraceModules.command, 'CommandSubscriptionBxSubstituteProxyService.unSubscribeCommands() called with invalid arguments!');
      return throwError(() => new Error('CommandSubscriptionBxSubstituteProxyService.unSubscribeCommands() called with invalid arguments'));
    }
    const index: number = keys.findIndex(item => (item === undefined));
    if (index !== -1) {
      this.traceService.error(TraceModules.command, 'Invalid keys!');
      return throwError(() => new Error('CommandSubscriptionBxSubstituteProxyService.unSubscribeCommands() called with invalid arguments'));
    }
    this.traceUnsubscribeCommands(keys);

    const subDeleteKeys: SubscriptionDeleteWsi[] = [];
    keys.forEach(key => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const subDeleteKey: SubscriptionDeleteWsi = {
        Key: key,
        ErrorCode: 0
      };
      /* eslint-enable @typescript-eslint/naming-convention */
      if (this.subscriptions.has(subDeleteKey.Key)) {
        this.subscriptions.delete(subDeleteKey.Key);
      } else {
        this.traceService.info(TraceModules.command, `CommandSubscriptionBxSubstituteProxyService.unSubscribeCommands() called; key is unknown: ${key}`);
      }
      subDeleteKeys.push(subDeleteKey);
    });

    this.traceSubscribedCommandsCount();
    return of(subDeleteKeys).pipe(observeOn(asyncScheduler));
  }

  public pollAndNotifySubscribedCommands(delay: number): void {
    this.startTimerForSubscription(delay);
  }

  private onTimerSubscription(): void {
    this.traceSubscribedCommandsCount();

    this.notifySubscribedCommands();
    // Option: do we need to poll the values and check the read values and control the available/reasonable commands accordingly?
    // this.startTimerForSubscription(pollRateValues);
  }

  private notifySubscribedCommands(): void {
    this.subscriptions.forEach((value, key) => {
      const dpId = new DpIdentifier(value.originalId);
      this.pointService.getPointById(dpId.systemName, dpId.objectIdWoSystem).subscribe(
        (pointBx => {
          const propCmd = this.commandMapper.createPropertyCommand(value.originalId, pointBx.attributes, value);
          this._cmdEvents.next([propCmd]);
        })
      );
    });
  }

  private startTimerForSubscription(delay: number): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    this.timerSubscription = timer(delay).subscribe(count => this.onTimerSubscription());
  }

  private stopTimerForSubscription(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
  }

  private traceSubscribedCommandsCount(): void {
    this.traceService.info(TraceModules.command, `CommandSubscriptionBxSubstituteProxyService.traceSubscribedCommandsCount():
    Number of subscriptions: ${this.subscriptions.size}`);
  }

  private traceSubscribeCommands(propertyIds: string[]): void {
    this.traceService.info(TraceModules.command,
      'CommandSubscriptionBxSubstituteProxyService.subscribeCommands() called; number of propertyIds:%s', propertyIds.length);
    if (this.traceService.isDebugEnabled(TraceModules.command)) {
      this.traceService.debug(TraceModules.command,
        'CommandSubscriptionBxSubstituteProxyService.subscribeCommands(): propertyIds to subscribe:\n%s', propertyIds.join('\n'));
    }
  }

  private traceUnsubscribeCommands(keys: number[]): void {
    this.traceService.info(TraceModules.command, 'CommandSubscriptionBxSubstituteProxyService.unSubscribeCommands() called; number of keys:\n%s', keys.length);
    if (this.traceService.isDebugEnabled(TraceModules.command)) {
      this.traceService.debug(TraceModules.command, 'CommandSubscriptionBxSubstituteProxyService.unSubscribeCommands():\nKeys: %s', keys.toString());
    }
  }
}
