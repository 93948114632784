<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="!isValid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    <div
      class="form-control d-flex align-items-center"
      [attr.readonly]="readonly"
      [attr.tabindex]="readonly ? '' : '0'"
      (keydown.enter)="popover.open(true)"
      input
    >
      <span>
        @if (property.defaultText || !modelValue.value) {
          {{ property.defaultText || property.value.altText }}
        } @else {
          {{ displayValue }}
        }
      </span>
    </div>
    <si-bacnet-datetime-picker
      [value]="modelValue"
      [timeFormat]="format"
      (submitEnter)="popover.submitEnter()"
      body
    />
  </si-property-popover>
</form>
