<div class="rpanel-header pt-5">
  <p class="si-title-2 my-0 ms-6 auto-hide text-truncate">{{ heading | translate }}</p>
  @if ((primaryActions.length || secondaryActions.length) && focusable) {
    <si-content-action-bar
      class="auto-hide ms-auto border-end"
      viewType="expanded"
      [primaryActions]="primaryActions"
      [secondaryActions]="secondaryActions"
      [toggleItemLabel]="toggleItemLabel"
    />
  }
  <button
    type="button"
    class="close-button btn btn-circle btn-sm btn-ghost element-cancel me-4"
    [attr.aria-label]="closeButtonLabel | translate"
    [tabindex]="focusable ? '0' : '-1'"
    (click)="toggleSidePanel($event)"
  ></button>
  <div class="collapse-toggle">
    <button
      type="button"
      class="side-panel-collapse-toggle btn btn-circle btn-sm btn-ghost mx-4"
      [attr.aria-label]="toggleItemLabel | translate"
      (click)="toggleSidePanel($event)"
    >
      <i
        class="flip-rtl"
        [class.badge-dot]="showMobileDrawerBadge"
        [class.element-double-right]="!isCollapsed"
        [class.element-double-left]="isCollapsed"
      ></i>
    </button>
  </div>
</div>
<div class="rpanel-wrapper d-flex flex-column">
  @if (statusActions.length && focusable) {
    <div class="rpanel-header rpanel-statusactions px-5 pb-4">
      @for (dp of statusActions; track $index) {
        @if (!dp.disabled) {
          <a
            class="rpanel-statusaction position-relative focus-inside"
            [attr.aria-label]="!isCollapsed ? '' : (dp.title | translate)"
            [siLink]="dp"
          >
            <i class="icon" aria-hidden="true" [ngClass]="dp.icon!"></i>
            @if (dp.overlayIcon) {
              <i class="icon position-absolute" aria-hidden="true" [ngClass]="dp.overlayIcon"></i>
            }
            <span class="ms-2 auto-hide">{{ dp.title | translate }}</span>
          </a>
        }
        @if (dp.disabled) {
          <div class="rpanel-statusaction dot-outer" aria-hidden="true">
            <div class="dot text-muted text-center">&bull;</div>
          </div>
        }
      }
    </div>
  }
  @if (searchable && focusable) {
    <div class="nav-search px-5 pt-2 auto-hide">
      <si-search-bar
        #searchBar
        class="m-0"
        [colorVariant]="'base-0'"
        [placeholder]="searchPlaceholder | translate"
        [debounceTime]="400"
        [showIcon]="true"
        [prohibitedCharacters]="'*?'"
        (searchChange)="searchEvent.emit($event)"
      />
    </div>
  }
  @if (focusable) {
    <div class="rpanel-content">
      <ng-content />
    </div>
  }
</div>
