<form class="row d-flex justify-content-end" [formGroup]="form">
  @for (input of inputs; track trackByItem(i, input); let i = $index) {
    <label class="mb-6 col-4 text-end">
      <span class="form-label">{{ input }}</span>
      <input
        class="form-control text-end"
        type="number"
        min="0"
        [formControlName]="input"
        autocomplete="off"
        [value]="values[i]"
        [readonly]="value.readonly"
        (keydown.enter)="onEnter(i)"
      />
    </label>
  }
</form>
