import { Observable } from 'rxjs';

import { Credentials, UserInfo } from './data.model';

/**
 * This class represents the interface for the AuthenticationService.
 * We define a class instead of an interface since the Angular2 DI framework explicitly needs classes.
 */
export abstract class AuthenticationServiceBase {

  /**
   * Login the user with its password. If valid a token is returned.
   *
   * @abstract
   * @param {Credentials} credentials
   * @returns {Observable<string>}
   *
   * @memberOf AuthenticationServiceBase
   */
  public abstract login(credentials: Credentials): Observable<string>;

  /**
   * Log out the current user
   *
   * @abstract
   * @param {Credentials} isInactivityLogout
   * @returns {Observable<boolean>}
   *
   * @memberOf AuthenticationServiceBase
   */
  public abstract logout(isInactivityLogout: boolean): Observable<boolean>;

  /**
  * Observable If the user is logged in or not
  *
  * @readonly
  * @abstract
  * @type {boolean}
  * @memberOf AuthenticationServiceBase
  */
  public abstract readonly loginObservable: Observable<boolean>;

  /**
   * Attempt login a default user on a configured machine.
   * If current machine is configured in the system for default user within a valid certificates it returns
   * an user token. Otherwise the
   *
   * @abstract
   * @returns {Observable<string>}
   *
   * @memberOf AuthenticationServiceBase
   */
  public abstract loginDefaultUser(): Observable<string>;

  /**
   * Gets the current user token observable
   *
   * @readonly
   * @abstract
   * @type {Observable<string>}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userTokenEvent(): Observable<string>;

  /**
   * Gets the current user token.
   *
   * @readonly
   * @abstract
   * @type {string}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userToken(): string;

  /**
   * Gets the current user name observable
   *
   * @readonly
   * @abstract
   * @type {Observable<string>}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userNameEvent(): Observable<string>;

  /**
   * Gets the current user name.
   *
   * @readonly
   * @abstract
   * @type {string}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userName(): string;

  /**
   * Gets the current user descriptor observable
   *
   * @readonly
   * @abstract
   * @type {Observable<string>}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userDescriptorEvent(): Observable<string>;

  /**
   * Gets the current user descriptor.
   *
   * @readonly
   * @abstract
   * @type {string}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userDescriptor(): string;

  /**
   * Gets the current user profile.
   *
   * @readonly
   * @abstract
   * @type {string}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userProfile(): string;

  /**
   * Gets the current user profile observable.
   *
   * @readonly
   * @abstract
   * @type {string}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userProfileEvent(): Observable<string>;

  /**
   * Gets the current authentication kind (via form or via saved token)
   *
   * @readonly
   * @abstract
   * @type {boolean}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get restoreAuthentication(): Observable<boolean>;

  /**
   * Login the user with its password. If valid a token is returned.
   *
   * @abstract
   * @param {openIdRedirectUrl,openIdAllowedLogOutUrl}
   * @returns {Observable<OpenIdUriRepresentation>}
   * @memberOf AuthenticationServiceBase
   */
  public abstract getUserInfo(userName: string): Observable<UserInfo>;

  /**
   * Login the user with its password. If valid a token is returned.
   *
   * @abstract
   * @param {code:string}
   * @returns {Observable<string>}
   * @memberOf AuthenticationServiceBase
   */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  public abstract LoginUsingAuthorizationCode(code: string): Observable<string>;

  /**
   * Gets the current user inactivity timeout.
   *
   * @readonly
   * @abstract
   * @type {string}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get userInactivity(): string;

  /**
   * Set Default User LoggedIn.
   *
   * @abstract
   * @param {isDefault: boolean}
   * @returns {void}
   * @memberOf AuthenticationServiceBase
   */
  public abstract setDefaultUserLoggedIn(isDefault: boolean): void;

  /**
   * Get if current user is Default User logged in.
   *
   * @abstract
   * @returns {boolean}
   * @memberOf AuthenticationServiceBase
   */
  public abstract get defaultUserLoggedIn(): boolean;
}
