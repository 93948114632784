import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StringValue } from '@simpl/element-value-types';

import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-string-property',
  templateUrl: './si-string-property.component.html',
  standalone: true,
  imports: [FormsModule, SiPropertyPopoverComponent]
})
export class SiStringPropertyComponent {
  @Input({ required: true }) property!: Property<StringValue>;
  @Input() valueState: ValueState = 'none';
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<StringValue>>();

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }
}
