import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';

@Injectable()
export class ActiveMenuItemService {
  private _activeMenuItemId: string;
  public set ActiveMenuItemId(id: string) {
    this._activeMenuItemId = id;
  }

  public isActiveMenuItem(id: string): boolean {
    return this._activeMenuItemId === id;
  }

  public get HasActiveMenuItem(): boolean {
    return !isNullOrUndefined(this._activeMenuItemId);
  }

  public clearActiveMenuItem(): void {
    this._activeMenuItemId = undefined;
  }

  public setActiveItem(id: string): void {
    if (this.HasActiveMenuItem) {
      this._activeMenuItemId = id;
    } else {
      this.clearActiveMenuItem();
    }
  }
}
