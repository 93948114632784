import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MessageParameters } from './message-parameters.model';

@Injectable({
  providedIn: 'root'
})
export abstract class IQParamService {

  public typeId!: string;

  /*
   * This method is called by HFW to a snapin in different occasions:
   *
   * - Each time the query parameter related to the IQParamService changes (browser back-foward navigation).
   * - When the application starts with a deeplink which contains a query parameter related to the IQParamService.
   * - When the IQParamService is configured as deeplinkMasterId for a frame mode and the application starts with a deeplink to that frame mode.
   *
   * Its purpose is to get message information related to the query parameter string value.
   * With this information hfw, will fire a selection message impersonating the IQParamService and being able to precalculate the final url to be navigated.
   *
   * @param {param} param, the string param name written in query parameters.
   * @param {paramValue} paramValue, the string value written in query parameters, from which the IQParamService should be able to create all the necessary
   * information to send the corresponding selection.
   * @returns {Observable<MessageParameters>}, if the value emitted by observable is null the IQParamService has no selection to fire for that parameter,
   * otherwise the MessageParameters will contains all the necessary information to perform the selection.
   *
   * @memberOf IQParamService
   */
  public abstract getMessageParameters(param: string, paramValue: string | null): Observable<MessageParameters>;

  /*
   * This method is called by HFW to the IQParamService which is configured in the hldl to handle frame QParam in default mode.
   * this IQParamService is the one which drive the main selection in a frame and mode.
   * The method is called only once in the application lifetime, the first time the frame is going
   * to be displayed (it can happen right after the login or the first time the frame gets selected).
   *
   * Its purpose is to get the information related to the first automatic selection that will be performed.
   * On the returned observable, the IQParamService shall push the evaluated these information incapsulated into the MessageParametersObject.
   * Not all IQParamService needs a first automatic selection, in those case is enough to return of(null).
   *
   * With the retrieved MessageParameters, hfw will perfom the selection impersonating the IQParamService
   * in order to pre-calculate the final url (containing the snapin selected in the destinations panes) and
   * being able to opening the frame with just one navigation.
   *
   * @param {string} frameId, the frame for which is invoked.
   * @returns {Observable<MessageParameters>}, if the value emitted by observable is null the IQParamService has no automatic selection to fire,
   * otherwise the MessageParameters will contains all the necessary information to perform the first selection.
   *
   * @memberOf IQParamService
   */
  public abstract getFirstAutomaticSelection(frameId: string): Observable<MessageParameters>;
}
