<div class="p-6" (click)="onInsideClick($event)">
    <div class="layout-title-container mb-5">
        <div class="layout-title">
            <p class="si-title-1 gms-layout-text">{{ 'LAYOUT.LABEL' | translate }}</p>
        </div>
        <div *ngIf="multiMonitorService.runsInElectron && multiMonitorService.isMainManager()" class="btn-container">
            <si-content-action-bar [viewType]="'expanded'" [primaryActions]="layoutPrimaryActions" [secondaryActions]="layoutSecondaryActions" />
        </div>
        <div *ngIf="!multiMonitorService.runsInElectron || (multiMonitorService.runsInElectron && !multiMonitorService.isMainManager())" class="btn-container">
            <button type="button" class="btn btn-circle btn-sm btn-ghost element-cancel" aria-label="Close" style="float:right" (click)="onClose()">
            </button>
        </div>
    </div>
    <div id="statusBarSettings" class="status-bar-state-selector">
        <div class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-header-container">
          <p class="gms-layout-text" style="margin-top: 0;">{{ 'LAYOUT.STATUS-BAR-HEIGHT' | translate }}</p>
        </div>
        <div class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-items-container">
            <!--Layout Buttons -->
            <div class="gms-layout-container" btnRadioGroup [(ngModel)]="statusBarState">
                <label class="gms-layout-toggle-button" btnRadio="{{statusBarStateEnum.Expanded}}" tabindex="0"
                    role="button" (click)="onStatusBarStateSelection($event, statusBarStateEnum.Expanded)">
                    <i class="element-layout-statusbar-large gms-layout-icons"></i>
                </label>
                <div class="gms-layout-vertical-separator"></div>
                <label class="gms-layout-toggle-button" btnRadio="{{statusBarStateEnum.Collapsed}}" tabindex="0"
                    role="button" (click)="onStatusBarStateSelection($event, statusBarStateEnum.Collapsed)">
                    <i class="element-layout-statusbar-small gms-layout-icons"></i>
                </label>
            </div>
        </div>
    </div>
    <div class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-header-container">
        <p class="gms-layout-text">{{ 'LAYOUT.PANE-LAYOUT' | translate }}</p>
    </div>
    <div class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-items-container">
        <!--Layout Buttons -->
        <div class="gms-layout-container" btnRadioGroup [(ngModel)]="currentLayoutId">
            <div *ngFor="let layout of layouts; index as i" class="gms-layout-container">
                <div *ngIf="i > 0" class="gms-layout-vertical-separator"></div>
                <label btnRadio="{{layout.id}}" class="gms-layout-toggle-button" tabindex="0" role="button"
                    (click)="onLayoutSelection($event, layout.id)">
                    <i [ngClass]="layout.iconClass !== null ? layout.iconClass : 'element-layout-pane-1'"
                        class="gms-layout-icons"></i>
                </label>
            </div>
        </div>
        <button type="button" [(ngModel)]="isLayoutLocked" btnCheckbox (click)="onLock($event)"
            class="btn btn-circle btn-sm btn-secondary gms-layout-lock"
            [ngClass]="{ 'element-unlock': !isLayoutLocked, 'element-lock-filled': isLayoutLocked }">
        </button>
    </div>
    <!-- Texts List-->
    <div class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-header-container">
        <p class="gms-layout-text">{{ 'LAYOUT.TEXT-REPRESENTATION' | translate }}</p>
    </div>
    <div class="gms-layout-container mb-6 rounded-1">
        <div *ngFor="let cnsLabel of cnsLabels"
            [ngClass]="{'gms-layout-cns-item-selected': cnsHelper?.activeCnsLabelValue?.cnsLabel === cnsLabel?.cnsLabel}"
            class="list-group-item gms-layout-cns-list rounded-1 my-3 mx-2" (click)="cnsLabelSelectionChanged(cnsLabel)">

            <div class="gms-layout-cns-list-item" (mousedown)="onMouseDownCnsItem($event)">

                <span class="gms-layout-object-type hfw-icon element-special-object p-1"></span>

                <div class="gms-layout-object-data">
                    <p>{{cnsLabel.displayValue | async}}</p>
                    <p *ngIf="cnsLabel.secondaryDisplayValue">{{cnsLabel.secondaryDisplayValue | async}}</p>
                </div>

            </div>
        </div>
    </div>
    <!-- <div class="gms-layout-horizontal-separator" [style.display]="currentLayoutId !== 'l' ? 'block' : 'none'"></div> -->

    <div class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-header-container">
        <p class="gms-layout-text">{{ 'LAYOUT.MODE' | translate }}</p>
    </div>
    <div class="btn-group mb-6 mode-buttons" role="group" aria-label="Mode buttons">
        <input type="radio" class="btn-check" name="btnradioMode" id="btnradiolight" autocomplete="off" [checked]="isThemeModeSelected('light')">
        <label class="btn" for="btnradiolight" (click)="applyThemeType('light')">{{ 'LAYOUT.LIGHT' | translate }}</label>

        <input type="radio" class="btn-check" name="btnradioMode" id="btnradiodark" autocomplete="off" [checked]="isThemeModeSelected('dark')">
        <label class="btn" for="btnradiodark" (click)="applyThemeType('dark')">{{ 'LAYOUT.DARK' | translate }}</label>

        <input type="radio" class="btn-check" name="btnradioMode" id="btnradioauto" autocomplete="off" [checked]="isThemeModeSelected('auto')">
        <label class="btn" for="btnradioauto" (click)="applyThemeType('auto')">{{ 'LAYOUT.AUTO' | translate }}</label>
      </div>

    <!-- <div class="gms-layout-horizontal-separator" [style.display]="currentLayoutId !== 'l' ? 'block' : 'none'"></div> -->


    <div *ngIf="!multiMonitorService.runsInElectron && currentLayoutId !== 'l'" class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-items-container">
    </div>
    <div *ngIf="multiMonitorService.runsInElectron" class="electron-ops-wrapper">
        <div *ngIf="isMainManager || isManagerWithEvent" class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-header-container">
            <p class="gms-layout-text" style="margin-top: 0;">{{ 'LAYOUT.DISPLAY' | translate }}</p>
        </div>
        <div class="gms-layout-settings-items-container desktop-buttons">
            <button [disabled]="!isCurrentMMConfigChangeAllowed" *ngIf="isMainManager" type="button" class="btn btn-secondary mx-0 mb-5" (click)="multiMonitorService.startAdditionalSystemManager()"><i class="icon element-plus"></i>{{ 'LAYOUT.NEW-SYSTEM-WINDOW' | translate }}</button>
            <button [disabled]="!isCurrentMMConfigChangeAllowed" *ngIf="isMainManager && !detachEventMgrMenuItemDisabled"  type="button" class="btn btn-secondary mx-0 mb-5" (click)="detachEventManagerClicked()"><i class="icon element-export"></i>{{ 'LAYOUT.DETACH-EVENTS' | translate }}</button>
            <button [disabled]="!isCurrentMMConfigChangeAllowed" *ngIf="isMainManager && detachEventMgrMenuItemDisabled" type="button" class="btn btn-secondary mx-0 mb-5" (click)="resumeEventManagerClicked()"><i class="icon element-import"></i>{{ 'LAYOUT.RESUME-EVENTS' | translate }}</button>
            <button [disabled]="!isCurrentMMConfigChangeAllowed" *ngIf="!isMainManager && isManagerWithEvent" type="button" class="btn btn-secondary mx-0 mb-5" (click)="resumeEventManagerClicked()"><i class="icon element-import"></i>{{ 'LAYOUT.RESUME-EVENTS-IN-MAIN' | translate }}</button>
        </div>
    </div>
    <div *ngIf="!multiMonitorService.runsInElectron && currentLayoutId !== '2-pane-mobile' && currentLayoutId !== '1'" class="hfw-flex-container-row hfw-flex-item-grow gms-layout-settings-items-container">
        <label class="btn btn-secondary btn-block gms-layout-default-button ms-0"
            (click)="onDefaultLayout()">{{ 'LAYOUT.DEFAULT-LAYOUT' | translate }}</label>
    </div>
</div>
