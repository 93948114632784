import { Injectable } from '@angular/core';
import { PropertyServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Subscription } from 'rxjs';

import { Datapoint } from '../processor/datapoint/gms-datapoint';

@Injectable()
export class PropertyImageService {

  private readonly traceModule: string = 'gmsSnapins_PropertyImageService';

  // Key: property Id
  private readonly _subscriptionsMap: Map<string, Subscription> = new Map<string, Subscription>();

  public constructor(private readonly traceService: TraceService, private readonly propertyService: PropertyServiceBase) {

    this.traceService.info(this.traceModule, 'Graphics Property Image service created.');
  }

  public readPropertyImage(datapoint: Datapoint): void {

    let id = '';
    if (datapoint !== undefined) {
      id = datapoint.Id;
      this.UnsubscribePropertyImage(id);
    } else {
      this.traceService.error(this.traceModule, 'readPropertyImage called failed: datapoint undefined');
      return;
    }

    this.traceService.info(this.traceModule, 'readPropertyImage called for property: %s', id);

    this.UnsubscribePropertyImage(id);

    const subscription: Subscription = this.propertyService.readPropertyImage(id).subscribe(image => this.onReadPropertyImage(datapoint, image),
      error => this.onReadPropertyImageError(datapoint, error));

    this._subscriptionsMap.set(id, subscription);

  }

  public unsubscribe(): void {

    this._subscriptionsMap.forEach(subscription => {
      subscription.unsubscribe();
    });
    this._subscriptionsMap.clear();
  }

  private onReadPropertyImage(datapoint: Datapoint, image: string): void {

    if (datapoint === undefined) {
      return;
    }
    this.UnsubscribePropertyImage(datapoint.Id);

    datapoint.PropertyImage = image;
  }

  private onReadPropertyImageError(datapoint: Datapoint, error: Error): void {

    let id: string;
    if (datapoint !== undefined) {
      id = datapoint.Id;
      this.UnsubscribePropertyImage(datapoint.Id);
    }
    this.traceService.error(this.traceModule, 'onReadPropertyImageError(): error: %s, property Id: %s', error.message, id);
  }

  private UnsubscribePropertyImage(datapointId: string): void {
    const sub: Subscription = this._subscriptionsMap.get(datapointId);
    if (sub !== undefined) {
      sub.unsubscribe();
      this._subscriptionsMap.delete(datapointId);
    }

  }
}
