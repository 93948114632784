import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { AnyPriorityArrayValue, PriorityArrayItem, ValueBase } from '@simpl/element-value-types';
import { clone } from '@simpl/object-browser-ng/common';

import { isValueSet } from '../../helpers/property';
import { DateFormat, TimeFormat } from '../../interfaces/date-time-formats';
import { OverrideMode, Property, StateChange, ValueState } from '../../interfaces/property';
import { SiBigNumberPropertyComponent } from '../si-big-number-property/si-big-number-property.component';
import { SiBitstringPropertyComponent } from '../si-bitstring-property/si-bitstring-property.component';
import { SiBlindPropertyComponent } from '../si-blind-property/si-blind-property.component';
import { SiBooleanPropertyComponent } from '../si-boolean-property/si-boolean-property.component';
import { SiDatePropertyComponent } from '../si-date-property/si-date-property.component';
import { SiDatetimePropertyComponent } from '../si-datetime-property/si-datetime-property.component';
import { SiEnumPropertyComponent } from '../si-enum-property/si-enum-property.component';
import { SiNumberPropertyComponent } from '../si-number-property/si-number-property.component';
import { SiObjectidPropertyComponent } from '../si-objectid-property/si-objectid-property.component';
import { SiPropertyContainerComponent } from '../si-property-popover/si-property-container.component';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';
import { SiPropertyComponent } from '../si-property/si-property.component';
import { SiStringPropertyComponent } from '../si-string-property/si-string-property.component';
import { SiTimePropertyComponent } from '../si-time-property/si-time-property.component';
import { SiTimedurationPropertyComponent } from '../si-timeduration-property/si-timeduration-property.component';

@Component({
  selector: 'si-priority-array-property',
  templateUrl: './si-priority-array-property.component.html',
  styleUrls: ['./si-priority-array-property.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    SiBlindPropertyComponent,
    SiBigNumberPropertyComponent,
    SiBitstringPropertyComponent,
    SiBooleanPropertyComponent,
    SiDatePropertyComponent,
    SiDatetimePropertyComponent,
    SiEnumPropertyComponent,
    SiNumberPropertyComponent,
    SiObjectidPropertyComponent,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => SiPropertyComponent),
    SiPropertyContainerComponent,
    SiPropertyPopoverComponent,
    SiStringPropertyComponent,
    SiTimePropertyComponent,
    SiTimedurationPropertyComponent,
    SiTranslateModule
  ]
})
export class SiPriorityArrayPropertyComponent {
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  @Input({ required: true }) property!: Property<AnyPriorityArrayValue>;
  @Input() valueState?: ValueState | { [key: number]: ValueState } = 'none';
  @Input() forceReadonly = false;
  @Input() customToggle = false;
  @Output() readonly submitted = new EventEmitter<Property<AnyPriorityArrayValue>>();

  // Internally used in templates
  @ViewChild('popover', { read: SiPropertyPopoverComponent })
  protected popover?: SiPropertyPopoverComponent;
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;

  protected get readonly(): boolean {
    return (this.forceReadonly || this.property.value.readonly) ?? false;
  }

  trackByItem = (index: number, item: any): any => item;
  isPrioSet = (item: PriorityArrayItem<ValueBase>): boolean => isValueSet(item as Property);

  private positionToPriority(priority?: number, isItemValueSet?: boolean): OverrideMode {
    if (this.property.overrideMode === 'direct') {
      // If a value is set, return "normal" so that values can be cleared
      return isItemValueSet ? 'normal' : 'direct';
    }
    if (priority && priority <= 3) {
      return 'danger';
    } else if (priority && priority > 3 && priority < 9) {
      return 'warning';
    } else {
      return 'normal';
    }
  }

  // Property<any> because Ivy compiler messes up the template type checking...
  itemValue(item: PriorityArrayItem<ValueBase>): Property<any> {
    item.value.optional = true;
    const isItemValueSet = isValueSet(item as Property);
    return {
      ...item,
      id: String(item.position),
      overridden: isItemValueSet || !!item.value.altText,
      overrideMode: this.positionToPriority(item.position, isItemValueSet)
    };
  }

  itemState(item: PriorityArrayItem<ValueBase>): ValueState {
    if (typeof this.valueState === 'object') {
      return item.position && this.valueState ? this.valueState[item.position] || 'none' : 'none';
    } else {
      return 'none';
    }
  }

  itemSubmit(item: PriorityArrayItem<ValueBase>): void {
    if (item.value.altText) {
      item.value.altText = undefined;
    }
    const cloned = clone(this.property);
    cloned.value.value = [item];
    (cloned as any)._original = this.property;
    this.submitted.emit(cloned);
  }

  globalState(): ValueState {
    return typeof this.valueState === 'object' ? 'none' : (this.valueState as ValueState);
  }

  stateChange(_state: StateChange): void {}
}
