<ngx-datatable
  #table
  class="table-element flex-fill mt-1"
  [rows]="tableProperties"
  [columns]="columns"
  [columnMode]="ColumnMode.force"
  [footerHeight]="0"
  [virtualization]="false"
  [scrollbarV]="true"
  [scrollbarH]="true"
  [selectionType]="SelectionType.single"
  [rowClass]="getTableRowClass"
  [treeFromRelation]="'__internalState.itemParentId'"
  [treeToRelation]="'__internalState.itemId'"
  siDatatableInteraction
  [datatableInteractionAutoSelect]="false"
  (activate)="toggleTableRowGroup($event)"
/>

<ng-template #valueCellTempl let-row="row" ngx-datatable-cell-template>
  <ng-container
    *ngTemplateOutlet="
      propertyTempl;
      context: { property: row, index: row.__internalState.itemIndex }
    "
  />
</ng-template>

<ng-template #toggleTempl let-cell="cellContext" />
