<si-navbar-primary class="collapse navbar-collapse"
[appTitle] = "appTitle"
[primaryItems]="primaryItems" [account]="userItem"
[logoURL] = "logoUrl"
[home] = "homeLink"
(click)="logoClick($event)"
    [accountItems]="userDropDown">
    <si-navbar-item *ngIf="runsInElectron && additionalItems?.length > 0" quickAction [item]="{ title: 'Desktop actions', icon: 'element-special-object', items: additionalItems }" />
    <!-- #messageItem -->
    <si-navbar-item *ngIf="isMainManager" quickAction [item]="notificationItem"/>
    <si-navbar-item quickAction [item]="layoutSettingsItem"/>
    <si-navbar-item quickAction [item]="{ title: helpTitle, icon: 'element-help', items: helpItems }" />
</si-navbar-primary>


<ng-template #notificationPanel cdkPortal>
  <si-side-panel-content [heading]="'NAVBAR.notifconfig-frame-id' | translate">
    <gms-notification-center />
  </si-side-panel-content>
</ng-template>

<ng-template #layoutPanel cdkPortal>
  <si-side-panel-content [heading]="'LAYOUT.LABEL' | translate">
    <gms-layout-settings [primaryItems]="primaryItems" />
  </si-side-panel-content>
</ng-template>

<ng-template #userRolesPanel cdkPortal>
  <si-side-panel-content [heading]="'ROLES.LABEL' | translate">
    <gms-user-roles />
  </si-side-panel-content>
</ng-template>
