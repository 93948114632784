import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
  CheckboxClickEventArgs,
  ClickEventArgs,
  FolderStateEventArgs,
  TreeItem
} from './si-tree-view.model';
import { ROOT_LEVEL } from './si-tree-view.utils';

/**
 * Supports communication between the TreeViewComponent and the TreeViewItemComponent and the services.
 * Stores tree specific settings.
 * One instance per TreeViewComponent exists.
 */
@Injectable()
export class SiTreeViewService {
  clickEvent: Subject<ClickEventArgs> = new Subject<ClickEventArgs>();
  folderClickEvent: Subject<FolderStateEventArgs> = new Subject<FolderStateEventArgs>();
  checkboxClickEvent: Subject<CheckboxClickEventArgs> = new Subject<CheckboxClickEventArgs>();
  loadChildrenEvent: Subject<TreeItem> = new Subject<TreeItem>();
  scrollIntoViewEvent: Subject<ElementRef> = new Subject<ElementRef>();
  focusParentEvent: Subject<TreeItem> = new Subject<TreeItem>();
  focusFirstChildEvent: Subject<TreeItem> = new Subject<TreeItem>();
  inheritChecked = true;
  enableContextMenuButton = true;
  deleteChildrenOnCollapse = false;
  enableSelection = false;
  enableDataField1 = false;
  enableDataField2 = false;
  enableStateIndicator = true;
  enableIcon = true;
  enableCheckbox = false;
  enableOptionbox = false;
  expandOnClick = false;
  flatTree = false;
  singleSelectMode = false;
  childrenIndentation = 14;
  groupedList = false;
  isVirtualized = true;
  folderStateStart = true;
  disableFilledIcons = false;
  noActionsString = 'No actions available';
  triggerMarkForCheck: Subject<void> = new Subject<void>();
  scroll$!: Observable<Event>;

  isGroupedItem(item: TreeItem): boolean {
    return (!item.parent || item.parent.level === ROOT_LEVEL) && this.groupedList;
  }
}
