import { Directive, forwardRef, HostBinding, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { BigNumberValue, NumberValue } from '@simpl/element-value-types';

@Directive({
  selector: '[siNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => SiNumberValidatorDirective),
      multi: true
    }
  ],
  standalone: true
})
export class SiNumberValidatorDirective implements Validator {
  @Input() siNumberValidator?: NumberValue | BigNumberValue;
  @Input() allowValuesOutOfRange?: boolean;

  @HostBinding('class.ng-warning') warning = false;

  private isValid(value: string): boolean {
    if (!this.siNumberValidator) {
      return true;
    }

    const min =
      (!this.allowValuesOutOfRange ? this.siNumberValidator.min : undefined) ??
      this.siNumberValidator.typeMin;
    const max =
      (!this.allowValuesOutOfRange ? this.siNumberValidator.max : undefined) ??
      this.siNumberValidator.typeMax;
    const decimalsAllowed = this.siNumberValidator.decimalsAllowed;
    const resolution = this.siNumberValidator.resolution;
    const optional = this.siNumberValidator.optional;
    const empty = value === null || value === undefined || value === '';

    if (optional && empty) {
      return true;
    } else if (!optional && empty) {
      return false;
    }
    const numValue = Number(value);
    if (min !== undefined && numValue < Number(min)) {
      return false;
    } else if (max !== undefined && numValue > Number(max)) {
      return false;
    } else if (decimalsAllowed ? isNaN(parseFloat(value)) : !Number.isInteger(numValue)) {
      return false;
    } else if (
      resolution !== undefined &&
      !this.isValidForResolution(numValue, Number(resolution))
    ) {
      return false;
    }

    return true;
  }

  private isValidForResolution(value: number, resolution: number): boolean {
    // this is a bit tricky because of float math issues
    // the basic idea is that the value divided by the resolution must be integer, but this doesn't
    // work, e.g. 35.05 / 0.01 gives 3504.9999999999995
    // so we do a bit of rounding, subtract the value and check if the difference is within a tiny
    // range
    const r = 1 / resolution;
    const x = value * r;
    return Math.round(x) - x < 1e-11;
  }

  private isValidWithWarnings(value: string): boolean {
    if (!this.allowValuesOutOfRange) {
      return false;
    }
    const min = this.siNumberValidator?.min;
    const max = this.siNumberValidator?.max;

    const numValue = Number(value);
    if (min !== undefined && numValue < Number(min)) {
      return true;
    } else if (max !== undefined && numValue > Number(max)) {
      return true;
    }

    return false;
  }

  validate(control: FormControl<string>): { error: boolean } | null {
    const isValid = this.isValid(control.value);
    this.warning = isValid && this.isValidWithWarnings(control.value);
    if (!isValid || this.warning) {
      control.markAsTouched();
    }
    return isValid ? null : { error: true };
  }
}
