import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';

import { OverrideMode } from '../../interfaces/property';
import { SiPropertyPopoverService } from './si-property-popover.service';

@Component({
  selector: 'si-property-container',
  providers: [SiPropertyPopoverService],
  template: '<ng-content />',
  standalone: true
})
export class SiPropertyContainerComponent implements OnChanges, OnDestroy {
  @Input() overrideMode?: OverrideMode;
  @Output() readonly validityChange = new EventEmitter<boolean>();

  private service = inject(SiPropertyPopoverService);
  private validSub = this.service.validityChange$.subscribe(this.validityChange);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.overrideMode) {
      this.service.containerOverrideMode = this.overrideMode;
    }
  }

  ngOnDestroy(): void {
    this.validSub.unsubscribe();
  }
}
