/**
 * This interface defines a detailed step of a result.
 */
export interface ResultDetailStep {
  /**
   * A description of the step.
   */
  description: string;
  /**
   * A state object defining the state of the step.
   */
  state: ResultDetailStepState;
  /**
   * Optional value to be displayed for the step.
   */
  value?: string;
  /**
   * Optional error message to display.
   */
  errorMessage?: string;
  /**
   * Optional custom icon to display. Otherwise a default icon will be shown based on the state property.
   */
  icon?: string;
}

/**
 * This enum defines the state that a detailed result step can have.
 */
export enum ResultDetailStepState {
  Passed = 'Passed',
  Failed = 'Failed',
  Running = 'Running',
  NotSupported = 'Not supported',
  NotStarted = 'Not started'
}
