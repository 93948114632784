<div>
  <div *ngIf="userRoles !== null">
    <div class="modal-header">
      <span class="modal-title">{{dialogTitle}}</span>
      <button
        type="button"
        class="btn btn-circle btn-sm btn-ghost element-cancel"
        aria-label="Close"
        (click)="onClose()"
      > </button>
    </div>
    <div style="margin-left: 12px;">
      <div *ngIf="userRoles.length > 0">
        <div *ngFor="let role of userRoles">
          <div class="mb-6 row">
            <div class="form-switch">
              <input
                id="switch"
                type="checkbox"
                class="form-check-input"
                role="switch"
                [checked]="role.RoleState"
                (change)="changeRoleState(role)"
              >
              <label class="form-check-label" for="switch-input">{{role.RoleName}}</label>
              <!-- <si-switch
                id="switch"
                [on]="role.RoleState"
                (toggle)="changeRoleState(role)"></si-switch>
              {{role.RoleName}} -->
            </div>
            
          </div>
        </div>
      </div>
      <div *ngIf="userRoles.length === 0">
        {{ dialogNoRolesToDisplay}}
      </div>
    </div>
  </div>
  <div class="modal-footer pl-0" style="margin-top: 50px;">
    <div class="right-col">
      <!--button class="btn btn-secondary mr-6" [disabled] = "!isDataChanged" (click)="onCancel()">{{ dialogCancelBtn }}</button-->
      <button type="button" class="btn btn-primary"  [disabled] = "!isDataChanged" (click)="onApply()">{{ dialogApplyBtn }}</button>
    </div>
  </div>
</div>
