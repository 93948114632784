<div class="list-header list-header-sorting">
  <div class="list-title">{{ sortTitle | translate }}:</div>
  @for (item of sortCriteria; track $index) {
    <div
      class="sort-item"
      tabindex="0"
      [attr.data-key]="item.key"
      (click)="setActive(item.key)"
      (keydown.enter)="setActive(item.key)"
    >
      <span>{{ item.name | translate }}</span>
      <span
        class="icon"
        [class.element-sort-up]="item.key === activeSortCriteria && !sortIsDescending"
        [class.element-sort-down]="item.key === activeSortCriteria && sortIsDescending"
      ></span>
    </div>
  }
</div>
