<form class="dynamic-form" [formGroup]="form" autocomplete="off">
  @if (hasDate && !hasWildcards) {
    <div class="d-flex flex-row flex-wrap">
      <label
        class="mb-6 mx-6 text-end"
        [class.date]="hasTime"
        [class.has-error]="form.get('date')?.invalid"
      >
        <span class="form-label">{{ (dateLabelKeys.date | translate) || '&nbsp;' }}</span>
        <input
          class="form-control text-end"
          [formControlName]="'date'"
          [maxlength]="10"
          siDatepicker
          [siDatepickerConfig]="datePickerConfig"
          (keydown.enter)="onEnter($event)"
        />
      </label>
    </div>
  }

  @if (hasDate && hasWildcards) {
    <div class="d-flex flex-row flex-wrap">
      @for (input of dateInputs; track $index) {
        <div class="form-group mb-6 mx-6 min-width text-end">
          <label for="input.key" class="form-label">
            {{ input.label | translate }}
          </label>
          <select
            [name]="input.key"
            class="form-control"
            [attr.aria-label]="input.key"
            [formControlName]="input.key"
          >
            @for (field of input.selectOptions; track $index) {
              <option [value]="field.id">
                {{ field.name | translate }}
              </option>
            }
          </select>
        </div>
      }
    </div>
  }

  @if (hasTime) {
    <div class="d-flex flex-row flex-wrap">
      @for (input of timeInputs; track $index) {
        @switch (input.key) {
          @case ('amPm') {
            <label class="mb-6 mx-6 min-width text-end">
              <span class="form-label">{{ (input.label | translate) || '&nbsp;' }}</span>
              <select
                class="form-control min-width"
                [formControlName]="input.key"
                [attr.aria-label]="input.key"
                (keydown.enter)="onEnter($event)"
              >
                @for (key of amPmKeys; track i; let i = $index) {
                  <option [ngValue]="i">
                    {{ key | translate }}
                  </option>
                }
              </select>
            </label>
          }
          @default {
            <label
              class="mb-6 mx-6 min-width text-end"
              [class.has-error]="form.get(input.key)?.invalid"
            >
              <span class="form-label">{{ (input.label | translate) || '&nbsp;' }}</span>
              <input
                class="form-control text-end"
                [name]="input.key"
                [formControlName]="input.key"
                [maxlength]="input.maxLength"
                (keydown)="upDown($event)"
                (keydown.enter)="onEnter($event)"
              />
            </label>
          }
        }
      }
    </div>
  }
</form>
