<div
  #range
  class="form-control d-flex align-items-center pe-2"
  cdkMonitorSubtreeFocus
  [class.disabled]="disabled"
  [class.readonly]="readonly"
  [class.ng-invalid]="!dateRangeControl?.valid"
  [class.ng-touched]="dateRangeControl?.touched"
  [class.ng-dirty]="dateRangeControl?.dirty"
  (cdkFocusChange)="focusChange($event)"
>
  <input
    #startInput="ngModel"
    type="text"
    class="border-0 p-0 focus-none"
    siDateInput
    [ngModel]="dateRangeControl?.value && dateRangeControl?.value.start"
    [siDatepickerConfig]="siDatepickerConfig"
    [placeholder]="startDatePlaceholder | translate"
    [disabled]="disabled"
    [readonly]="readonly"
    [dateInputDebounceTime]="debounceTime"
    (ngModelChange)="onInputChanged({ start: $event, end: value?.end })"
  />
  <span class="mx-3">-</span>
  <input
    #endInput="ngModel"
    type="text"
    class="border-0 p-0 focus-none text-end"
    siDateInput
    [ngModel]="dateRangeControl?.value && dateRangeControl?.value.end"
    [siDatepickerConfig]="siDatepickerConfig"
    [placeholder]="endDatePlaceholder | translate"
    [disabled]="disabled"
    [readonly]="readonly"
    [dateInputDebounceTime]="debounceTime"
    (ngModelChange)="onInputChanged({ start: value?.start, end: $event })"
  />
  <button
    #button
    type="button"
    class="btn btn-circle btn-tertiary btn-xs element-calendar ms-2"
    [attr.aria-label]="ariaLabelCalendarButton | translate"
    [disabled]="disabled || readonly"
    (click)="show()"
  >
  </button>
</div>
