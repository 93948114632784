<form [formGroup]="analogForm">
  <div class="form-group mb-5 w-100">
    <label for="analogMinValue" class="form-label"> {{ translatedLabels.get('low') }} </label>
    <si-number-input
      class="form-control"
      formControlName="analogMinValueSelection"
      id="analogMinValue"
      name="analogMinValue"
      placeholder=""
      [readOnly]="true"
      [min]="lowLimit"
      [max]="highLimit"
      [unit]="unit"
      [step]="0.01"
      [showButtons]="true"
      [ngClass]="{'is-invalid': !isLowLimitValid}"
    />
  </div>
  <div class="form-group mb-5 w-100">
    <label for="analogMaxValue" class="form-label"> {{ translatedLabels.get('high') }} </label>
    <si-number-input
      class="form-control"
      formControlName="analogMaxValueSelection"
      id="analogMaxValue"
      name="analogMaxValue"
      placeholder=""
      [readOnly]="true"
      [min]="lowLimit"
      [max]="highLimit"
      [unit]="unit"
      [step]="0.01"
      [showButtons]="true"
      [ngClass]="{'is-invalid': !isHighLimitValid}"
    />
  </div>
</form>
