<ng-content />
@if (showVisibilityIcon) {
  <button
    type="button"
    class="btn btn-tertiary btn-circle btn-xs text-body password-visibility-icon"
    [class.element-hide]="showPassword"
    [class.element-show]="!showPassword"
    [attr.aria-label]="(showPassword ? hideLabel : showLabel) | translate"
    (click)="toggle()"
  ></button>
}
