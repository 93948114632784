import { ParameterType } from './gms-base-parameter';
import { StringParameter } from './gms-string-parameter';

export class PasswordParameter extends StringParameter {

  public constructor(name: string, order: number, defaultValue: string,
    minLength: string, maxLength: string, dataType: string) {

    super(name, order, defaultValue, minLength, maxLength, dataType);

    this.ParameterType = ParameterType.Password;
  }
}
