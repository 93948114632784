import { Injectable } from "@angular/core";
import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointAttributes, PointValue } from "src/app/bx-services/point/point-proxy.model";

import { PropertyMapper } from "./property-mapper";
import { TextGroupBxService } from "./text-group-bx.service";

export const pointPropertyName = 'PresentValue';

@Injectable({
  providedIn: 'root'
})

export class PointValueMapperService extends PropertyMapper {

  constructor(private readonly textGroupOmService: TextGroupBxService, qudtPipe: SiqudtUnitConversionPipe) {
    super(qudtPipe);
  }

  public createPointProperty(pointId: string, pointAttributes: PointAttributes, order: number): PropertyDetails {
    const txtGroup = this.textGroupOmService.createTextGroup(pointId, pointAttributes);
    /* eslint-disable @typescript-eslint/naming-convention */
    const propDetails: PropertyDetails = {
      PropertyName: pointPropertyName,
      Descriptor: 'Value',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: this.evalResolution(pointAttributes),
      Type: PropertyMapper.getDccType(pointAttributes),
      Value: undefined,
      Min: pointAttributes.minimum,
      Max: pointAttributes.maximum,
      UnitDescriptor: this.getDccUnit(pointAttributes.unit)?.unit,
      UnitId: this.getDccUnit(pointAttributes.unit)?.id,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      TextTable: txtGroup ? txtGroup.id : undefined,
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return propDetails
  }

  public createValue(subGms: SubscriptionGmsVal, pointId: string, pointValue: PointValue, pointAttributes: PointAttributes): ValueDetails {
    // TODO: finalize quality bits
    let displayValue = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        displayValue = this.textGroupOmService.resolveRawValue(pointId, pointValue.value);
      }
      if (displayValue === undefined) {
        displayValue = pointValue.value;
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }

    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: PropertyMapper.getDccType(pointAttributes),
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        Value: (!isNullOrUndefined(pointValue)) ? pointValue.value : '',
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0' // TODO: check the usage
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
