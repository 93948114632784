import { Directive, Input, TemplateRef } from '@angular/core';
import { SiHeaderDropdownTriggerDirective } from '@simpl/element-ng/header-dropdown';

export {
  /** @deprecated */
  SiHeaderDropdownComponent as SiNavbarDropdownComponent,
  /** @deprecated */
  SiHeaderDropdownItemComponent as SiNavbarDropdownItemComponent,
  /** @deprecated */
  SiHeaderDropdownItemsFactoryComponent as SiNavbarDropdownItemsFactoryComponent,
  /** @deprecated */
  SI_HEADER_WITH_DROPDOWNS as SI_NAVBAR_WITH_DROPDOWNS,
  /** @deprecated */
  HeaderWithDropdowns as NavbarWithDropdowns
} from '@simpl/element-ng/header-dropdown';

/** @deprecated This directive was renamed to SiHeaderDropdownTriggerDirective.
 * This export will be removed in v46.
 */
@Directive({
  selector: '[siNavbarDropdownTriggerFor]',
  standalone: true,
  exportAs: 'siNavbarDropdownTrigger',
  host: {
    class: 'dropdown-toggle'
  },
  providers: [
    { provide: SiHeaderDropdownTriggerDirective, useExisting: SiNavbarDropdownTriggerDirective }
  ]
})
export class SiNavbarDropdownTriggerDirective extends SiHeaderDropdownTriggerDirective {
  @Input({ alias: 'siNavbarDropdownTriggerFor', required: true })
  override dropdown: TemplateRef<unknown> = undefined as any;
}
