import { Injectable } from '@angular/core';
import { LocalTextGroupEntry, SystemInfo, TablesServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Subscription } from 'rxjs';

import { TextGroupEntry, TextGroupEntryStatus } from '../processor/textgroup/gms-text-group-entry';
import { TextGroupEntryHelper } from '../processor/textgroup/gms-text-group-helper';
import { GmsSystemsService } from './gms-systems.service';

@Injectable()
export class TextGroupService {

  private readonly traceModule: string = 'gmsSnapins_TextGroupService';
  private readonly _subscriptionsTextMap: Map<TextGroupEntry, Subscription> = new Map<TextGroupEntry, Subscription>();
  private readonly _subscriptionsImageMap: Map<TextGroupEntry, Subscription> = new Map<TextGroupEntry, Subscription>();

  private readonly globalTextGroupEntries: Map<string, TextGroupEntry> = new Map<string, TextGroupEntry>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly gmsSystemsService: GmsSystemsService,
    private readonly tablesService: TablesServiceBase) {

    this.traceService.info(this.traceModule, 'Graphics TextGroup service created.');

  }

  public unsubscribe(): void {
    const subscriptions: any[] = Array.from((this._subscriptionsTextMap).values());
    if (subscriptions !== undefined) {
      subscriptions.forEach((value: any, index: number, array: any[]) => {
        const subscription: Subscription = value as Subscription;
        if (subscription !== undefined) {
          subscription.unsubscribe();
        }
      });
    }
    const subscriptionsImage: any[] = Array.from((this._subscriptionsImageMap).values());
    if (subscriptionsImage !== undefined) {
      subscriptionsImage.forEach((value: any, index: number, array: any[]) => {
        const subscription: Subscription = value as Subscription;
        if (subscription !== undefined) {
          subscription.unsubscribe();
        }
      });
    }

    this._subscriptionsTextMap.clear();
    this._subscriptionsImageMap.clear();
    this.globalTextGroupEntries.clear();
  }

  public getTextGroupEntry(txGrId: string): TextGroupEntry {
    let entry: TextGroupEntry = this.globalTextGroupEntries.get(txGrId);
    if (entry === undefined) {
      entry = new TextGroupEntry(txGrId);
      this.globalTextGroupEntries.set(txGrId, entry);
    }
    return entry;
  }

  public deleteTextGroupEntry(txGrId: string): void {
    if (this.globalTextGroupEntries.has(txGrId)) {

      this.globalTextGroupEntries.delete(txGrId);
    }
  }

  public readIconForTextGroupEntry(textgroupEntry: TextGroupEntry, systemId: string): boolean {

    this.traceService.info(this.traceModule, 'readIconForTextGroupEntry called for textgroupEntry: %s', textgroupEntry.TxGroupId);

    // Retrieve the System Id for a given SytemName
    const systemName: string = TextGroupEntryHelper.ParseSystemName(textgroupEntry);
    let resolvedSystemId;
    if (systemName !== undefined && systemName !== '') {

      resolvedSystemId = this.gmsSystemsService.getSystemIdentifier(systemName);

      if (resolvedSystemId === undefined) {
        this.traceService.info(this.traceModule, 'readIconForTextGroupEntry(): system id not found for system name: %s', systemName);
      }
    }

    // If the text group reference does not have a system name enclosed
    // consider the primary selection system id.
    resolvedSystemId = resolvedSystemId !== undefined ? resolvedSystemId : systemId;

    if (resolvedSystemId !== undefined) {
      this.ProcessTextGroupEntry(textgroupEntry, resolvedSystemId, true);
      return true;
    } else {
      this.traceService.info(this.traceModule, 'readIconForTextGroupEntry(): system id is undefined, text group cannot be resolved');
    }

    // System Name cannot be parsed
    return false;
  }

  /**
   *
   * @param textgroupEntry
   * @param systemId - current/selected graphic's Object SystemId
   */
  public readTextAndColorForTextGroupEntry(textgroupEntry: TextGroupEntry, systemId: string): boolean {

    this.traceService.info(this.traceModule, 'readIconForTextGroupEntry called for textgroupEntry: %s', textgroupEntry);

    // Retrieve the System Id for a given SytemName
    const systemName: string = TextGroupEntryHelper.ParseSystemName(textgroupEntry);
    let resolvedSystemId;
    if (systemName !== undefined && systemName !== '') {

      resolvedSystemId = this.gmsSystemsService.getSystemIdentifier(systemName);

      if (resolvedSystemId === undefined) {
        this.traceService.info(this.traceModule, 'readTextAndColorForTextGroupEntry(): system id not found for system name: %s', systemName);
      }
    }

    // If the text group reference does not have a system name enclosed
    // consider the primary selection system id.
    resolvedSystemId = resolvedSystemId !== undefined ? resolvedSystemId : systemId;

    if (resolvedSystemId !== undefined) {
      this.ProcessTextGroupEntry(textgroupEntry, resolvedSystemId, false);
      return true;
    } else {
      this.traceService.info(this.traceModule, 'readTextAndColorForTextGroupEntry(): system id is undefined, text group cannot be resolved');
    }

    // System Name cannot be parsed
    return false;
  }

  private ProcessTextGroupEntry(textgroupEntry: TextGroupEntry, systemId: string, isReadIcon: boolean): void {
    if (textgroupEntry === undefined) {
      // NOTE: error log
      return;
    }
    if (isReadIcon) {
      textgroupEntry.IconStatus = TextGroupEntryStatus.Pending;
    } else {
      textgroupEntry.Status = TextGroupEntryStatus.Pending;
    }

    textgroupEntry.SystemId = systemId;
    if (TextGroupEntryHelper.Parse(textgroupEntry, systemId) === true) {
      if (isReadIcon) {
        const readIconSubscription: Subscription = this.tablesService.getIconForTextGroupEntry(textgroupEntry.SystemId,
          textgroupEntry.TableName, textgroupEntry.Index).subscribe(image => this.onReadIcon(textgroupEntry, image),
          error => this.onReadIconError(textgroupEntry, error));
        if (readIconSubscription !== undefined) {
          this._subscriptionsImageMap.set(textgroupEntry, readIconSubscription);
        }
      } else {
        const readTextAndColorSubscription: Subscription = this.tablesService.getTextAndColorForTextGroupEntry(textgroupEntry.SystemId,
          textgroupEntry.TableName, textgroupEntry.Index).subscribe(textAndColor => this.onReadTextAndColor(textgroupEntry, textAndColor),
          error => this.onReadTextAndColorError(textgroupEntry, error));

        if (readTextAndColorSubscription !== undefined) {
          this._subscriptionsTextMap.set(textgroupEntry, readTextAndColorSubscription);
        }
      }
    }
  }

  private onReadTextAndColor(textgroupEntry: TextGroupEntry, textAndColor: LocalTextGroupEntry): void {
    if (textgroupEntry !== undefined) {
      textgroupEntry.LocalTextGroupEntry = textAndColor;
      textgroupEntry.Status = TextGroupEntryStatus.Resolved;
      this.UnsubscribeReadText(textgroupEntry);
    }

  }
  private onReadTextAndColorError(textgroupEntry: TextGroupEntry, error: Error): void {
    textgroupEntry.Status = TextGroupEntryStatus.DoesNotExist;
    this.traceService.error(this.traceModule, 'onReadTextAndColorError(): error: %s error name: %s', error.message, error.name);
    this.UnsubscribeReadText(textgroupEntry);
  }

  private onReadIcon(textGroupEntry: TextGroupEntry, iconSource: string): void {
    // NOTE: drop it into the storage, the element model will get the notification
    textGroupEntry.IconSource = iconSource;
    // textGroupEntry.IsIconResolved = true;
    textGroupEntry.IconStatus = TextGroupEntryStatus.Resolved;
    this.traceService.info(this.traceModule, 'onReadIcon():  %s', textGroupEntry.TxGroupId);

    this.UnsubscribeReadIcon(textGroupEntry);
  }

  private onReadIconError(textGroupEntry: TextGroupEntry, error: Error): void {

    this.traceService.error(this.traceModule, 'onReadIconError(): error: %s, textgroupEntry: %s',
      error.message, textGroupEntry.TxGroupId);
    textGroupEntry.IconStatus = TextGroupEntryStatus.DoesNotExist;
    this.UnsubscribeReadIcon(textGroupEntry);
  }

  private UnsubscribeReadIcon(textGroupEntry: TextGroupEntry): void {
    const subscription: Subscription = this._subscriptionsImageMap.get(textGroupEntry);
    if (subscription != null) {
      subscription.unsubscribe();
      this._subscriptionsImageMap.delete(textGroupEntry);
    }
  }

  private UnsubscribeReadText(textGroupEntry: TextGroupEntry): void {
    const subscription: Subscription = this._subscriptionsTextMap.get(textGroupEntry);
    if (subscription != null) {
      subscription.unsubscribe();
      this._subscriptionsTextMap.delete(textGroupEntry);
    }
  }
}
