import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

/** @internal */
@Injectable({
  providedIn: null
})
export class SiAccordionHCollapseService {
  /**
   * Subject emitting the current horizontal collapsed state.
   */
  readonly hcollapsed$ = new ReplaySubject<boolean>(1);
  /**
   * Subject to emit to open the accordion.
   */
  readonly open$ = new Subject();
}
