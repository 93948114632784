<ng-template #criterionItemTemplate let-model="item">
  <span [innerHTML]="highlight(model) | async"></span>
</ng-template>
<div #scrollContainer class="d-flex align-items-center overflow-auto py-1 w-100 ms-2 pe-2">
  <!-- placeholder icon-->
  @if (showIcon) {
    <span
      class="placeholder-icon icon element-search mx-0"
      [class.empty]="!internSearchCriteria.criteria.length && !internSearchCriteria.value"
    >
    </span>
  }

  @for (criterion of internSearchCriteria.criteria; track i; let i = $index) {
    <div
      class="criteria"
      [class.disabled]="disabled"
      [attr.aria-disabled]="disabled"
      [class.invalid-criterion]="!validateCriterionLabel(criterion)"
    >
      <div class="criterion-label me-1 si-title-2" (click)="editCriterion(criterion)">
        {{ criterion.label }}
      </div>
      <div class="criterion-operator-section">
        <!-- criterion operator input -->
        @if (isOperatorSpecified(criterion.name) && editCriterionIndex === i) {
          <input
            type="text"
            class="operator-input"
            [ngClass]="'operator-input-' + i"
            [siTypeahead]="getCriterionOperators(criterion.name, criterion.operator)"
            [typeaheadProcess]="false"
            [typeaheadAutoSelectIndex]="
              getSelectedOperatorIndex(criterion.name, criterion.operator)
            "
            [typeaheadMinLength]="0"
            [typeaheadOptionsLimit]="0"
            [readOnly]="false"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="optionsInScrollableView"
            [attr.aria-label]="searchLabel | translate"
            [attr.size]="getLongestOperatorLength(criterion.name)"
            [ngModel]="criterion.operator"
            (ngModelChange)="onCriterionOperatorInputChange(criterion, $event)"
            (keyup)="operatorKeyUp($event)"
            (click)="editOperator(criterion, $event)"
            (focus)="inputFocused($event, i)"
            (focusout)="inputFocusOut($event, criterion.name, i)"
            (typeaheadOnSelect)="typeaheadOnSelectOperator()"
          />
        }
        <!-- criterion operator field -->
        @if (criterion.operator && editCriterionIndex !== i) {
          <div
            class="criterion-operator me-1 focus-inside"
            (keyup.enter)="editOperator(criterion, $event)"
            (click)="editOperator(criterion, $event)"
          >
            @if (!!criterion.operator) {
              <span class="criterion-operator-text focus-inside" [tabindex]="!disabled ? 0 : -1">
                {{ criterion.operator }}
              </span>
            }
          </div>
        }
      </div>
      <div class="criterion-value-section">
        <!-- criterion value field -->
        @if (editCriterionIndex !== i) {
          <div
            class="criterion-value px-0 focus-inside"
            [class.invalid-criterion]="
              !validateCriterionLabel(criterion) || !validateCriterionValue(criterion)
            "
            (keyup.enter)="editValue(criterion, $event)"
            (click)="editValue(criterion, $event)"
          >
            <ng-container
              [ngTemplateOutlet]="
                !!criterion.dateValue && isDateTime(criterion.name)
                  ? dateTimeTemplate
                  : !!criterion.dateValue && isDate(criterion.name)
                    ? dateTemplate
                    : !!criterion.value && hasMultiSelections(criterion.name, criterion.value)
                      ? itemCount
                      : valueTemplate
              "
            />
            <ng-template #dateTemplate>
              <span class="criterion-value-text focus-inside" [tabindex]="disabled ? -1 : 0">
                {{ criterion.dateValue | date: shortDateFormat }}
              </span>
            </ng-template>
            <ng-template #dateTimeTemplate>
              <span class="criterion-value-text p-0">
                <span class="d-block px-4 focus-inside" [tabindex]="disabled ? -1 : 0">
                  {{ criterion.dateValue | date: getDateTimeFormat(criterion) }}
                </span>
              </span>
            </ng-template>
            <ng-template #itemCount>
              @if (!!criterion.value && itemCountText) {
                <span class="criterion-value-text p-0">
                  <span class="d-block px-4 focus-inside" [tabindex]="disabled ? -1 : 0">
                    {{ itemCountText | translate: { itemCount: criterion.value.length } }}
                  </span>
                </span>
              }
              @if (!!criterion.value && !itemCountText) {
                <span class="criterion-value-text p-0">
                  <span class="ps-4 pe-2 d-block focus-inside" [tabindex]="disabled ? -1 : 0">
                    {{ criterion.value.length }} {{ items | translate }}
                  </span>
                </span>
              }
            </ng-template>
            <ng-template #valueTemplate>
              <span class="criterion-value-text p-0">
                <span class="d-block px-4 focus-inside" [tabindex]="disabled ? -1 : 0">
                  {{ criterion | toLabel: getOptionsFromCriteria(criterion.name) }}
                </span>
              </span>
            </ng-template>
            @if (!readonly) {
              <button
                type="button"
                class="criterion-value-button ps-0 ms-0 py-0 btn btn-circle btn-xs btn-ghost element-cancel focus-inside"
                [attr.aria-label]="clearButtonLabel | translate"
                [disabled]="disabled"
                (click)="deleteCriterion($event, i)"
              >
              </button>
            }
          </div>
        }
        <!-- criterion value input -->
        @if (editCriterionIndex === i && !isDateOrDateTime(criterion.name)) {
          <input
            #valueInput
            typeaheadOptionField="label"
            class="criterion-value pe-4"
            [ngClass]="'value-input-' + i"
            [class.me-n8]="criterion.value.length"
            [class.pe-8]="criterion.value.length"
            [type]="getInputType(criterion.name)"
            [step]="getStepAttribute(criterion.name)"
            [ngModel]="criterion.valueLabel"
            [siTypeahead]="
              getCriterionOptions(
                criterion.name,
                i,
                isMultiSelect(criterion.name) ? '' : criterion.value
              )
            "
            [typeaheadProcess]="isMultiSelect(criterion.name)"
            [typeaheadMultiSelect]="isMultiSelect(criterion.name)"
            [typeaheadMinLength]="0"
            [typeaheadOptionsLimit]="maxCriteriaOptions"
            [readOnly]="
              readonly || onlySelectValue || this.getCriterionOnlySelectValue(criterion.name)
            "
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="optionsInScrollableView"
            [attr.aria-label]="searchLabel | translate"
            (keydown)="filterKeyInputEvents($event, criterion.name)"
            (keyup)="onValueInputKeyEvent($event, criterion.name)"
            (focus)="inputFocused($event, i)"
            (blur)="focusState = false"
            (ngModelChange)="onCriterionValueInputChange(criterion, $event)"
            (typeaheadOnSelect)="typeaheadOnSelectValue(criterion.name, i, $event)"
            (typeaheadOnMultiselectClose)="focusFreeInputField()"
            (typeaheadClosed)="onValueInputClosed()"
          />
        }
        @if (editCriterionIndex === i && isDate(criterion.name)) {
          <input
            type="text"
            siDatepicker
            class="criterion-value datepicker"
            [ngClass]="'value-input-' + i"
            [class.me-n8]="criterion.dateValue"
            [class.pe-8]="criterion.dateValue"
            [attr.aria-label]="searchLabel | translate"
            [siDatepickerConfig]="getDatepickerConfig(criterion)"
            [ngModel]="criterion.dateValue!"
            (keydown)="onDateTimeKeyDown($event)"
            (keyup)="onDateTimeKeyEvent($event)"
            (focus)="inputFocused($event, i)"
            (blur)="focusState = false"
            (siDatepickerClose)="onDatePickerClose()"
            (ngModelChange)="selectDate(criterion, $event)"
          />
        }
        @if (editCriterionIndex === i && isDateTime(criterion.name)) {
          <input
            type="text"
            siDatepicker
            class="criterion-value datepicker"
            [ngClass]="'value-input-' + i"
            [class.me-n8]="criterion.dateValue"
            [class.pe-8]="criterion.dateValue"
            [attr.aria-label]="searchLabel | translate"
            [siDatepickerConfig]="getDatepickerConfig(criterion)"
            [ngModel]="criterion.dateValue!"
            (keydown)="onDateTimeKeyDown($event)"
            (keyup)="onDateTimeKeyEvent($event)"
            (focus)="inputFocused($event, i)"
            (blur)="focusState = false"
            (siDatepickerClose)="onDatePickerClose()"
            (siDatepickerDisabledTime)="disableTime(criterion, $event)"
            (ngModelChange)="selectDate(criterion, $event)"
          />
        }
        @if (
          editCriterionIndex === i && (criterion.value.length || criterion.dateValue) && !readonly
        ) {
          <button
            #clearInputButton
            type="button"
            class="clear-input btn btn-circle btn-xs btn-ghost element-cancel"
            [attr.aria-label]="clearButtonLabel | translate"
            (click)="clearValueInput(i, criterion)"
          ></button>
        }
      </div>
    </div>
  }
  <!-- criterion input -->
  <input
    type="text"
    class="value-input ps-2 me-2"
    typeaheadOptionField="name"
    [class.ms-1]="internSearchCriteria.criteria.length"
    [class.value-input-shrink]="!internSearchCriteria.value"
    [siTypeahead]="dataSource"
    [typeaheadProcess]="false"
    [typeaheadMinLength]="readonly ? 1 : 0"
    [typeaheadOptionsLimit]="typeaheadOptionsLimit"
    [typeaheadAutoSelectIndex]="selectedCriteriaIndex"
    [typeaheadItemTemplate]="criterionItemTemplate"
    [typeaheadScrollable]="true"
    [typeaheadOptionsInScrollableView]="optionsInScrollableView"
    [readonly]="readonly"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [attr.aria-label]="searchLabel | translate"
    [ngModel]="internSearchCriteria.value"
    (ngModelChange)="onSearchTermChange($event)"
    (keyup)="criterionInputKeyUp($event)"
    (paste)="handleContextMenuPaste($event)"
    (click)="editFreeInputField()"
    (blur)="editCriterionIndex = -2; focusState = false"
    (focus)="inputFocused($event)"
    (typeaheadOnInput)="onTypeaheadInputChange($event)"
    (typeaheadOnSelect)="typeaheadOnSelectCriterion($event.option)"
  />
</div>
<!-- clear all searchCriteria -->
@if ((internSearchCriteria.criteria.length || internSearchCriteria.value.length) && !readonly) {
  <button
    type="button"
    class="btn-circle btn-xs btn-ghost element-cancel mx-2"
    [disabled]="disabled"
    [attr.aria-label]="clearButtonLabel | translate"
    (click)="deleteAllCriteria($event)"
  ></button>
}
