import { NgModule } from '@angular/core';
import { IPreselectionService, IStorageService } from '@gms-flex/core';

import { EventDetailsPreselectService } from './services/event-details-preselect.service';
import { EventDetailsStorageService } from './services/event-details-storage.service';

@NgModule({
  providers: [{ provide: IPreselectionService, useClass: EventDetailsPreselectService, multi: true },
    { provide: IStorageService, useClass: EventDetailsStorageService, multi: true }]
})
export class GmsEventDetailsRootServicesModule {}
