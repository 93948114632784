import { NgModule } from '@angular/core';

import {
  SiPasswordStrengthComponent,
  SiPasswordStrengthDirective
} from './si-password-strength.component';

@NgModule({
  imports: [SiPasswordStrengthComponent, SiPasswordStrengthDirective],
  exports: [SiPasswordStrengthComponent, SiPasswordStrengthDirective]
})
export class SiPasswordStrengthModule {}
