<div class="d-flex flex-column align-content-stretch flex-grow-1 flex-shrink-1">
  @if (disableContainerBreakpoints || hasParentContainer) {
    <div class="flex-grow-1 flex-shrink-1 overflow-auto">
      <ng-container *ngTemplateOutlet="contentTemplate" />
    </div>
  } @else {
    <div
      class="flex-grow-1 flex-shrink-1 overflow-auto"
      siResponsiveContainer
      [breakpoints]="contentContainerBreakpoints"
    >
      <ng-container *ngTemplateOutlet="contentTemplate" />
    </div>
  }

  @if (!readonly) {
    <div class="d-flex flex-row flex-nowrap flex-grow-0 flex-shrink-0 p-4">
      <div
        class="d-flex flex-grow-1 flex-shrink-1 justify-content-end text-end overflow-hidden mx-6 my-auto"
        [class.text-success]="validFormContainerMessage"
        [class.text-danger]="invalidFormContainerMessage"
      >
        <ng-content select="[si-form-container-message]" />
      </div>
      @if (enableValidationHelp && getValidationErrors() && invalidFormContainerMessage) {
        <ng-template #popoverContent>
          <ul class="p-0 list-unstyled">
            @for (error of getValidationErrors(); track $index) {
              <li class="form-container-help-popover-error">
                @if (error.controlNameTranslationKey) {
                  <b>{{ error.controlNameTranslationKey | translate }}: </b>
                }
                @if (error.errorCode) {
                  <span>
                    {{
                      error.errorCodeTranslationKey || error.errorCode
                        | translate: error.errorParams
                    }}
                  </span>
                }
              </li>
            }
          </ul>
        </ng-template>
        <span
          #popoverTrigger
          class="form-container-edit-help ms-0 me-6 my-auto"
          triggers="click"
          containerClass="form-container-help-popover"
          tabindex="0"
          [innerHTML]="helpTitle | translate"
          [siPopover]="popoverContent"
          (keydown.enter)="popoverTrigger.click()"
        ></span>
      }
      <div class="m-auto flex-grow-0 flex-shrink-0">
        <ng-content select="[si-form-container-buttons]" />
      </div>
    </div>
  }
</div>

<ng-template #contentTemplate>
  <ng-content select="[si-form-container-content]" />
</ng-template>
