import { CdkContextMenuTrigger, CdkMenuTrigger } from "@angular/cdk/menu";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SiMenuModule } from '@simpl/element-ng';

import {
  GmsEasyNavigationBarEllipsisComponent
} from './gms-easy-navigation-bar-ellipsis/gms-easy-navigation-bar-ellipsis.component';
import { GmsEasyNavigationBarTopMenuItemComponent } from './gms-easy-navigation-bar-top-menu-item/gms-easy-navigation-bar-top-menu-item.component';
import {
  GmsEasyNavigationBarCommonComponent
} from './gms-easy-navigation-bar-view-common/gms-easy-navigation-bar-common.component';
import {
  GmsEasyNavigationBarMenuComponent
} from './gms-easy-navigation-bar-view-menu/gms-easy-navigation-bar-menu.component';
import {
  GmsEasyNavigationBarTopMenuComponent
} from './gms-easy-navigation-bar-view/gms-easy-navigation-bar-top-menu.component';
import { ActiveMenuItemService } from './services/active-menu-item.service';
import { CnsLabelService } from './services/cns-label.service';
import { GmsEasyNavigationResizeService } from './services/gms-easy-navigation-resize.service';
import { SelectionChangedService } from './services/selection-changed.service';

@NgModule({
  imports: [
    CdkContextMenuTrigger,
    CdkMenuTrigger,
    CommonModule,
    GmsEasyNavigationBarMenuComponent,
    SiMenuModule
  ],
  declarations: [
    GmsEasyNavigationBarCommonComponent,
    GmsEasyNavigationBarEllipsisComponent,
    GmsEasyNavigationBarTopMenuComponent,
    GmsEasyNavigationBarTopMenuItemComponent
  ],
  providers: [ActiveMenuItemService, CnsLabelService, GmsEasyNavigationResizeService, SelectionChangedService],
  exports: [GmsEasyNavigationBarCommonComponent]
})
export class GmsEasyNavigationBarModule {
}
