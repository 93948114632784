import { Constants } from '../../utilities/constants';
import { CommandParameter, ParameterType } from '../command/parameters/gms-base-parameter';
import { StringParameter } from '../command/parameters/gms-string-parameter';
import { ParameterViewModelBase } from './gms-parameter-vm.base';

export class ParameterStringViewModel extends ParameterViewModelBase {

  constructor(parameter: CommandParameter) {
    super(parameter);

    let stringParameter: StringParameter;
    if (parameter == null) {
      // Create a empty parameter that is used for design mode
      stringParameter = new StringParameter(ParameterViewModelBase.designModeParameterName, 0, '', '0', '100', '');
    } else {
      stringParameter = parameter as StringParameter;
    }

    this.ParameterType = ParameterType.String;
    this.minLength = stringParameter !== null ? Number(stringParameter.MinLength) : 0;
    this.maxLength = stringParameter !== null ? Number(stringParameter.MaxLength) : Number.MAX_SAFE_INTEGER;
    this.dataType = stringParameter !== null ? stringParameter.DataType : '';
    this.defaultValue = stringParameter !== null ? stringParameter.DefaultValue : Constants.GENERAL_ERROR;
    this.Value = this.defaultValue;
  }

  public isInputValid(locale: string): boolean {
    const res: boolean = this.FormattedValue !== undefined && this.FormattedValue.length >= this.minLength && this.FormattedValue.length <= this.maxLength;
    this.errorStatus = (res) ? undefined : `Text length must be greater than or equal to ${this.minLength} and less than or equal to ${this.maxLength}`;
    return res;

  }

}
