<div class="modal-header">
  @if (icon) {
    <i class="icon" [ngClass]="icon"></i>
  }
  <span class="modal-title" [id]="titleId">{{ heading | translate: translationParams }}</span>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    [attr.aria-label]="cancelBtnName | translate"
    (click)="onCancel()"
  >
  </button>
</div>
<div class="modal-body text-pre-wrap text-break">{{
  (!disableSave ? message : disableSaveMessage) | translate: translationParams
}}</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">{{
    cancelBtnName | translate
  }}</button>
  <button type="button" class="btn btn-danger" (click)="onDiscard()">{{
    (!disableSave ? discardBtnName : disableSaveDiscardBtnName) | translate
  }}</button>
  @if (!disableSave) {
    <button type="button" class="btn btn-primary" (click)="onSave()">{{
      saveBtnName | translate
    }}</button>
  }
</div>
