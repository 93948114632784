/* eslint-disable @angular-eslint/no-inputs-metadata-property, @angular-eslint/no-outputs-metadata-property, @angular-eslint/no-output-rename*/
import { CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { SiMenuItemBase } from './si-menu-item-base.directive';

@Component({
  selector: 'si-menu-item, a[si-menu-item], button[si-menu-item]',
  templateUrl: './si-menu-item.component.html',
  styleUrl: './si-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
  hostDirectives: [
    {
      directive: CdkMenuItem,
      inputs: ['cdkMenuItemDisabled: disabled'],
      outputs: ['cdkMenuItemTriggered: triggered']
    }
  ]
})
export class SiMenuItemComponent extends SiMenuItemBase {
  private menuTrigger = inject(CdkMenuTrigger, { optional: true, self: true });

  protected get hasSubmenu(): boolean {
    return !!this.menuTrigger?.menuTemplateRef;
  }
}
