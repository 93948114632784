import { Subject, Subscription } from 'rxjs';

import { Evaluation, EvaluationType, PropertyType } from '../processor/evaluation';
import { Range } from '../processor/range';
import { TextGroupEntry, TextGroupEntryPropertyChangeArgs, TextGroupEntryStatus } from '../processor/textgroup/gms-text-group-entry';
import { TextGroupEntryHelper } from '../processor/textgroup/gms-text-group-helper';
import { TextGroupService } from '../services/gms-text-group-service';
import { ColorUtility, ColorWrap } from '../utilities/color-utility';
import { KeyValuePair } from '../utilities/data-structures';
// import { CompoundBrush } from "../processor/compound-brush";
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';

// Contains information that defines a condition for evaluating the value from an evaluation
export class Condition {

  // Occures when a property of the Condition has changed
  public propertyChanged: Subject<string> = new Subject<string>();

  private _textGroupEntrySubscription: Subscription;

  private _ranges: Range[] = undefined;

  // Gets the Range collection.
  public get Ranges(): Range[] {
    return this._ranges;
  }

  private _multiDigitals: boolean[] = undefined;

  // Gets the parsed multi digital condition values where "0"=false, "1"=true and "-"=undefined.
  public get MultiDigitals(): boolean[] {
    return this._multiDigitals;
  }

  private _resolvedBrush: ColorWrap = undefined;

  private _evaluation: Evaluation = undefined;

  // Parent Evaluation
  public get Evaluation(): Evaluation {
    return this._evaluation;
  }
  public set Evaluation(value: Evaluation) {
    if (this._evaluation !== value) {
      this._evaluation = value;
    }
  }

  private _textGroupService: TextGroupService = undefined;
  private get TextGroupService(): TextGroupService {
    if (this._textGroupService === undefined) {
      this._textGroupService = this.Evaluation.Element !== undefined && this.Evaluation.Element.TextGroupService !== undefined ?
        this.Evaluation.Element.TextGroupService : undefined;
    }
    return this._textGroupService;
  }

  private _isMulti: boolean = undefined; // undefined=undefined, true=is multi, false=is not multi

  // Gets or sets a value that indicates whether this is for an Evaluation of type Multi
  public get IsMulti(): boolean {
    return this._isMulti;
  }

  public set IsMulti(value: boolean) {
    if (this._isMulti !== value) {
      this._isMulti = value;
      this.NotifyPropertyChanged('IsMulti');
      this.UpdateMultiDigitalsOrRanges();
    }
  }

  private _isLinear = false;

  // Sets a value that indicates whether this is for an Evaluation of type Linear
  public set IsLinear(value: boolean) {

    if (this._isLinear !== value) {
      this._isLinear = value;
      this.UpdateMultiDigitalsOrRanges();
    }
  }

  private _values: string = undefined;

  // Gets or sets the values
  public get Values(): string {
    return this._values;
  }

  public set Values(value: string) {
    if (this._values !== value) {
      this._values = value;
      this.UpdateMultiDigitalsOrRanges();
      this.NotifyPropertyChanged('Values');
      if (this.Evaluation !== undefined) {
        this.Evaluation.OnConditionValuesOrResultChanged();
      }
    }
  }

  private _result: any = undefined;

  // Gets or sets the Result.
  public get Result(): any {
    return this._result;
  }

  public set Result(value: any) {
    if (this._result !== value) {
      this._result = value;
      this.NotifyPropertyChanged('Result');
      if (this.Evaluation !== undefined) {
        this.Evaluation.OnConditionValuesOrResultChanged();
      }

      if (this._result === undefined) {
        this._res = undefined;
        this._type = undefined;
      } else {
        this._res = this._result.toString();
        // this._type = this._result.getType();

        // CheckAndResolveResourceBrush();
      }
    }
  }

  private _errorMessage: string = undefined;

  // Gets or sets the error message
  public get ErrorMessage(): string {
    return this._errorMessage;
  }

  public set ErrorMessage(value: string) {
    if (this._errorMessage !== value) {
      this._errorMessage = value;
      this.NotifyPropertyChanged('ErrorMessage');

      this.HasError = this._errorMessage !== undefined && this._errorMessage !== '';
    }
  }

  private _hasError = false;

  // Gets or sets a value that indicates whether this has an error
  public get HasError(): boolean {
    return this._hasError;
  }

  public set HasError(value: boolean) {
    if (this._hasError !== value) {
      this._hasError = value;
      this.NotifyPropertyChanged('HasError');
    }
  }

  private _res: string = undefined;
  // Gets or sets the Res.
  // <note type="note">use Res and Type instead of Result to avoid large xaml files</note>
  public get Res(): string {
    return this._res;
  }
  public set Res(value: string) {
    this._res = value;
    this.UpdateResult();
  }

  private _type: any = undefined;
  // Gets or sets the Type.
  // <note type="note">use Res and Type instead of Result to avoid large xaml files</note>
  public get Type(): any {
    return this._type;
  }
  public set Type(value: any) {
    this._type = value;

    if (this._type === 'boolean' && this._res === undefined) {
      this._res = true.toString();
      this.Result = true;
      return;
    }

    this.UpdateResult();
  }

  private _textGroupRef: string = undefined;
  // Gets or sets the text group ref
  public get TextGroupRef(): string {
    return this._textGroupRef;
  }
  public set TextGroupRef(value: string) {
    if (this._textGroupRef !== value) {
      this._textGroupRef = value;
    }
  }

  /**
   * Initializes a new instance of the Condition class
   * @param isMulti Determines if this is for an evaluation of type Multi
   * @param values The values for this condition to be true.
   * If isMulti is true, it consists of the binary result for each expression (in the same order as the expressions).
   * "0" stands for false, "1" for true and "-" for false or true.
   * Example for 3 binary expressions: Condition "01-" is true when the first expression is false and the second is true.
   * The third expression doesn't matter
   * @param result The result, which will be used when the condition is true.
   * Important: The result data type has to match the element property, which is animated
   */
  public constructor(isMulti: boolean = undefined, values: string = '', result: any = undefined) {
    this.IsMulti = isMulti;
    this.Values = values;
    this.Result = result;
  }

  public UpdateMultiDigitalsOrRanges(): void {
    try {
      if (this.IsMulti === undefined) {
        return; // not yet set
      }

      if (this.IsMulti) {
        this._multiDigitals = this.FromString(this._values);
      } else {
        const errorMessage: string = undefined;
        const result: KeyValuePair<string, Range[]> = Range.ParseImproved(this._values);
        this._ranges = result ? result.Value : undefined;
        if (errorMessage !== undefined && errorMessage !== '') {
          // this.ErrorMessage = ResourceCollection.GetString("Condition_MessageBox_ErrorInCondition_String")
          // + Environment.NewLine + ResourceCollection.GetString("Condition_MessageBox_ErrorRangeSyntax_String");
          // GlobalPersistency.ThreadInstance.Trace(TraceGroup.Warning, true, "Parse range", undefined, "Range="
          // + _values + ", Error=" + errorMessage, undefined);
          return;
        }
      }

      // this.ErrorMessage = undefined;
    } catch (ex) {
      this.ErrorMessage = ex.stack;
    }
  }

  /**
   * Compare all ranges and returns true if the first range item returns true
   * @param value The value to be compared
   * @param convertedValue The converted value
   * @returns true if the first range item returns true
   */
  public CompareTo(value: any, convertedValue: number): boolean {
    if (this._ranges === undefined) {
      return true;
    }

    // compare all ranges and return true if the first range item returns true
    let result = false;
    for (let i = 0; i < this._ranges.length; i++) {
      const range: Range = this._ranges[i];
      if (range.CompareTo(value, convertedValue)) {
        result = true;
        break;
      }
    }

    return result;
  }

  /**
   * Compare a list of values to all ranges and returns true if the first range item returns true
   * @param convertedValues A list of converted value
   * @returns true if the first range item returns true
   */
  public CompareToList(convertedValues: boolean[]): boolean {
    if (this._multiDigitals === undefined) {
      return true;
    }
    for (let index = 0; index < this._multiDigitals.length; index++) {
      if (this._multiDigitals[index] !== undefined && index < convertedValues.length && this._multiDigitals[index] !== convertedValues[index]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Determines whether this instance and another specified condition have the same values.
   * @param obj The Condition to compare
   * @returns true if the values are the same as this instance; otherwise, false
   */
  public Equals(obj: any): boolean {
    if (obj === undefined || !(obj instanceof Condition)) {
      return false;
    }

    const condition: Condition = obj as Condition;
    return this._values === condition._values && this._result === condition._result;
  }

  public Deserialize(node: Node): void {
    // Condition Properties
    if (node === undefined) {
      return;
    }

    let result: string = SvgUtility.GetAttributeValue(node, ConditionProperties.Values);
    if (result !== undefined) {
      this.Values = result;
    }

    result = SvgUtility.GetAttributeValue(node, ConditionProperties.Res);
    if (result !== undefined) {
      this.Result = result;
    }

    if (this.Evaluation !== undefined) {
      this.IsMulti = this.Evaluation.EvaluationType === EvaluationType.Multi;
      this.IsLinear = this.Evaluation.EvaluationType === EvaluationType.Linear;
    }

    // Resolve text group references for color and text
    if (TextGroupEntryHelper.IsTextGroupReference(this.Result) && (this.Evaluation
      .PropertyType === PropertyType.Color || this.Evaluation.PropertyType === PropertyType.String)) {
      if (this.Evaluation.Element !== undefined && this.TextGroupService !== undefined) {

        const textGroupEntry: TextGroupEntry = this.TextGroupService.getTextGroupEntry((this._result));
        if (textGroupEntry.Status === TextGroupEntryStatus.DoesNotExist) {
          // NOTE: handle the condition
          return;
        }
        if (textGroupEntry.Status === TextGroupEntryStatus.Resolved && textGroupEntry.LocalTextGroupEntry !== undefined) {
          this.SetTextGroupResult(textGroupEntry);
          return;
        }
        // subscribe and wait for callback
        this._textGroupEntrySubscription = textGroupEntry.propertyChanged.subscribe(arg => this.TextGroupEntry_PropertyChanged(arg, textGroupEntry));
        if (textGroupEntry.Status === TextGroupEntryStatus.Pending) {
          return;
        }

        const selectedObjectSystemId: string = this.Evaluation.Element.SelectedObject !== undefined
          ? this.Evaluation.Element.SelectedObject.SystemId.toString() : undefined;

        if (this.TextGroupService.readTextAndColorForTextGroupEntry(textGroupEntry, selectedObjectSystemId) === false) {
          // cannot process the textGroupEntry
          textGroupEntry.Status = TextGroupEntryStatus.DoesNotExist;
        }
      }
    }
  }

  public CopyFrom(condition: Condition): void {

    this.Values = condition.Values;
    this.Result = condition._result;

    if (this.Evaluation !== undefined) {
      this.IsMulti = this.Evaluation.EvaluationType === EvaluationType.Multi;
      this.IsLinear = this.Evaluation.EvaluationType === EvaluationType.Linear;
    }

    // Resolve text group references for color and text
    if (TextGroupEntryHelper.IsTextGroupReference(this.Result) && (this.Evaluation
      .PropertyType === PropertyType.Color || this.Evaluation.PropertyType === PropertyType.String)) {
      if (this.Evaluation.Element !== undefined && this.TextGroupService !== undefined) {

        const textGroupEntry: TextGroupEntry = this.TextGroupService.getTextGroupEntry((this._result));
        if (textGroupEntry.Status === TextGroupEntryStatus.DoesNotExist) {
          // NOTE: handle the condition
          return;
        }
        if (textGroupEntry.Status === TextGroupEntryStatus.Resolved && textGroupEntry.LocalTextGroupEntry !== undefined) {
          this.SetTextGroupResult(textGroupEntry);
          return;
        }
        // subscribe and wait for callback
        this._textGroupEntrySubscription = textGroupEntry.propertyChanged.subscribe(arg => this.TextGroupEntry_PropertyChanged(arg, textGroupEntry));
        if (textGroupEntry.Status === TextGroupEntryStatus.Pending) {
          return;
        }

        const selectedObjectSystemId: string = this.Evaluation.Element.SelectedObject !== undefined
          ? this.Evaluation.Element.SelectedObject.SystemId.toString() : undefined;

        if (this.TextGroupService.readTextAndColorForTextGroupEntry(textGroupEntry, selectedObjectSystemId) === false) {
          // cannot process the textGroupEntry
          textGroupEntry.Status = TextGroupEntryStatus.DoesNotExist;
        }
      }
    }
  }

  public Clear(): void {
    this._evaluation = undefined;
    this._result = undefined;
    this._values = undefined;
    this._textGroupService = undefined;

    if (this._ranges !== undefined) {
      this._ranges.forEach(range => range.Clear());
      this._ranges.length = 0;
    }

    if (this._multiDigitals !== undefined) {
      this._multiDigitals.length = 0;
    }

    if (this._textGroupEntrySubscription !== undefined) {
      this._textGroupEntrySubscription.unsubscribe();
      this._textGroupEntrySubscription = undefined;
    }
  }

  // Returns the resolves brush
  public GetResolvedCompundBrush(): ColorWrap {
    if (this._resolvedBrush !== undefined) {
      return this._resolvedBrush;
    } else {
      this._resolvedBrush = new ColorWrap(this.Result);
      return this._resolvedBrush;
    }
  }

  private TextGroupEntry_PropertyChanged(arg: TextGroupEntryPropertyChangeArgs, textGroupEntry: TextGroupEntry): void {
    if (textGroupEntry.Status === TextGroupEntryStatus.Pending) {
      return;
    }
    if (this._textGroupEntrySubscription !== undefined) {

      this._textGroupEntrySubscription.unsubscribe();
      this._textGroupEntrySubscription = undefined;
    }
    if (arg === undefined || textGroupEntry === undefined) {
      return;
    }
    if (textGroupEntry.Status === TextGroupEntryStatus.DoesNotExist) {
      // NOTE: error handling in condtion
    } else if (textGroupEntry.Status === TextGroupEntryStatus.Resolved) {
      textGroupEntry.LocalTextGroupEntry = arg.LocalTextGroupEntry;

      this.SetTextGroupResult(textGroupEntry);

    }
  }

  private SetTextGroupResult(textGroupEntry: TextGroupEntry): void {
    switch (this.Evaluation.PropertyType) {
      case PropertyType.Color:
        if (isNaN(textGroupEntry.LocalTextGroupEntry.Color)) {
          this.Result = '#00000000';
        }

        let colorString: string = Number(textGroupEntry.LocalTextGroupEntry.Color).toString(16);

        // Alpha channel not available or partial
        // fallback if the color is not available
        while (colorString.length < 8) {
          colorString = '0' + colorString;
        }
        this.TextGroupRef = this.Result;
        this.Result = '#' + colorString;
        break;
      case PropertyType.String:
        this.TextGroupRef = this.Result;
        this.Result = textGroupEntry.LocalTextGroupEntry.Text;
        break;
      default:
        // Dont do anything;
        break;
    }
  }

  private FromString(values: string): boolean[] {
    const list: boolean[] = new Array<boolean>();
    if (values !== undefined && values !== '') {
      for (let index = 0; index < values.length; index++) {
        const c: string = values[index];
        if (c === '1') {
          list.push(true);
        } else if (c === '0') {
          list.push(false);
        } else if (c === '-') {
          list.push(undefined);
        } else {
          throw new Error(`Syntax error. Only '1', '0' or '-' are allowed.`);
        }
      }
    }
    return list;
  }

  private UpdateResult(): void {
    if (this._res === undefined || this._type === undefined) {
      if (this._type === undefined) {
        this.Result = undefined;
      } else {
        // TBD Need to handle it appropriately
        // this.Result = this.GetDefaultValue(this._type);
      }
    } else {
      if (this._type === 'number') {
        this._res = Utility.ConvertDecimalSeparator(this._res);
      }
      this.Result = this._res;
    }
  }

  /**
   * Fire the event PropertyChanged
   * @param propertyName The property name or context of the change
   */
  private NotifyPropertyChanged(propertyName: string = ''): void {
    this.propertyChanged.next(propertyName);
  }
}

export enum ConditionProperties {
  Values = `Values`,
  Res = `Res`
}
