import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-progressbar',
  templateUrl: './si-progressbar.component.html',
  styleUrl: './si-progressbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiProgressbarComponent implements OnChanges {
  /**
   * Needed for a11y
   */
  @Input() ariaLabel = $localize`:@@SI_PROGRESSBAR.LABEL:Progress`;

  /**
   * Max value for progressbar
   */
  @Input() max = 100;

  /**
   * Current value
   */
  @Input() value? = 0;

  /**
   * Heading to display on top of progress bar.
   */
  @Input() heading?: string;

  /**
   * Optional progress text to be shown on top right (in LTR).
   * It can be percentage value or a progress status. E.g `50 %` or `Downloading 2/8`.
   */
  @Input() progress?: string;

  /**
   * Height for progress bar.
   */
  @Input() height: ProgressbarHeight = 'normal';

  protected percent = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.max) {
      this.percent = (100 * Number(this.value ?? 0)) / Number(this.max ?? 100);
    }
  }
}

export type ProgressbarHeight = 'small' | 'normal';
