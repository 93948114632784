<si-customer-list #customerList
  [customers]="allCustomers"
  [selectedCustomer]="selectedCustomer"
  [isAllPartitionsLoaded] = "isAllPartitionsLoaded"
  [customerPartitions]="customerPartitions"
  [selectedPartitions]="selectedPartitions"
  [allPermissions]="allPermissions"
  [allowFilter]="allowFilter"
  [showPartitionSubscriptionName]="showPartitionSubscriptionName"
  [appPermission]="'app.boic'"
  (customerChange)="onCustomerChange($event)"
  (selectedCustomerPartitions)="onSelectedCustomerPartitions($event)">
></si-customer-list>
