<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="form.invalid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    @if (popover.isBlocked || property.defaultText) {
      <div class="form-control d-flex align-items-center" [attr.readonly]="readonly" input>
        <span>
          @if (!property.defaultText) {
            {{ property.value.value }}
          } @else {
            {{ property.defaultText }}
          }
          <ng-template #defaultText>{{ property.defaultText }}</ng-template>
        </span>
      </div>
    }
    @if (!popover.isBlocked) {
      <input
        name="main"
        type="text"
        autocomplete="off"
        class="form-control"
        [attr.aria-label]="property.name"
        [(ngModel)]="popover.modelValue"
        [readonly]="readonly"
        [minlength]="property.value.minLength!"
        [maxlength]="property.value.maxLength!"
        [placeholder]="
          (!popover.isActive && property.value.altText) || property.value.placeholder || ''
        "
        [pattern]="property.value.pattern!"
        [required]="!property.value.optional && (!property.value.altText || popover.isActive)"
        (keydown)="popover.openCloseOnKeyboard($event)"
        input
      />
    }
  </si-property-popover>
</form>
