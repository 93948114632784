<div class="focus-none">
  <div class="focus-none">
    <header
      #navbar
      class="navbar navbar-primary navbar-expand-sm fixed-top elevation-1 focus-inside focus-direct-sub-inside"
      [attr.aria-label]="navAriaLabel | translate"
    >
      <button
        type="button"
        class="navbar-toggler focus-inside"
        [class.show]="showMobileNav"
        [attr.aria-expanded]="showMobileNav"
        (click)="hideAppSwitcher(); toggleMobileNav()"
      >
        <span class="visually-hidden">{{ toggleNavigationText | translate }}</span>
        <span class="element-menu"></span>
        @if (quickActionBadgeCount()) {
          <span class="badge-dot"></span>
        }
      </button>
      @if (appTitle) {
        <div class="title-outside">
          <a
            class="navbar-app-title focus-inside"
            [class.has-selected-item]="selectedItemTitle"
            [siLink]="home"
          >
            <div class="navbar-app-title-inner text-truncate px-6 si-body-1">{{ appTitle }}</div>
          </a>
        </div>
      }
      <div class="d-sm-none selected-item">
        @if (selectedItemTitle) {
          <span>{{ selectedItemTitle | translate }}</span>
        }
      </div>
      <div class="collapse navbar-collapse" [class.show]="showMobileNav">
        <div class="start">
          @if (appItems || appCategoryItems) {
            <a
              href="#"
              class="app-switcher-trigger navbar-collapse focus-inside"
              [class.show]="appsVisible"
              [attr.aria-label]="openAppSwitcherText | translate"
              (click)="toggleAppSwitcher(); $event.preventDefault()"
            >
              <i class="element-thumbnails m-4"></i>
            </a>
          }
          <si-navbar-brand class="d-none d-lg-flex" [logoUrl]="logoUrl" [link]="home" />
          @if (appTitle) {
            <div class="title-collapse">
              <a
                class="navbar-app-title focus-inside"
                [class.has-selected-item]="selectedItemTitle"
                [siLink]="home"
              >
                <div class="navbar-app-title-inner text-truncate px-6 si-body-1"
                  >{{ appTitle }}
                </div>
              </a>
            </div>
          }
        </div>
        @if (appItems || appCategoryItems) {
          <div class="app-switcher-menu navbar-nav">
            <div class="nav-item">
              <a
                href="#"
                class="nav-link"
                (click)="toggleAppSwitcher(); hideMobileNav(); $event.preventDefault()"
              >
                <i class="icon element-thumbnails"></i>
                <span class="item-title">{{ appSwitcherTitle | translate }}</span>
              </a>
            </div>
          </div>
        }
        <nav class="navbar-start navbar-nav ms-sm-6" [attr.aria-label]="ariaLabelMainMenu">
          @for (item of primaryItems; track $index) {
            <si-navbar-item [item]="item" />
          }
        </nav>
        <nav class="navbar-end end navbar-nav" [attr.aria-label]="ariaLabelSecondaryMenu">
          <ng-content
            select="si-navbar-item[quickAction], element-navbar-item[quickAction='true']"
          />
          @if (!!accountItem) {
            <si-navbar-profile-menu [items]="accountItems" [account]="accountItem">
              <ng-content />
            </si-navbar-profile-menu>
          }
        </nav>
      </div>
    </header>
  </div>
  @if (appsVisible) {
    <si-launchpad
      [closeAppSwitcherText]="closeAppSwitcherText"
      [appSwitcherTitle]="appSwitcherTitle"
      [appSwitcherSubTitle]="appSwitcherSubTitle"
      [appCategoryItems]="appCategoryItems"
      [appItems]="appItems"
      [appItemsFavorites]="appItemsFavorites"
      [favoriteAppsTitle]="favoriteAppsTitle"
      [defaultAppsTitle]="defaultAppsTitle"
      [showMoreAppsTitle]="showMoreAppsTitle"
      [showLessAppsTitle]="showLessAppsTitle"
      [allAppsLink]="allAppsLink"
      (appItemFavoriteChanged)="appItemFavoriteChanged.emit($event)"
      (hide)="hideAppSwitcher()"
    />
  }
</div>
