/* eslint-disable no-console, no-restricted-syntax*/
export interface ModuleSetting {
  label: string;
  enabled: boolean;
}

export interface TraceSettingsStore {
  infoEnabled: boolean;
  debugEnabled: boolean;
  allModulesEnabled: boolean;
  modules: Map<string, ModuleSetting>;
  vendorModules: Map<string, ModuleSetting>;
}

const traceSettingsKey = 'traceSettingsLocStore';

export class TraceSettings {

  public static readTraceSettingsFromLocStore(): TraceSettings {
    try {
      if (TraceSettings.checklocalStorage() === true) {

        const storageData = window.localStorage.getItem(traceSettingsKey);
        if (storageData === null) {
          return TraceSettings.default();
        } else {
          const settingsRead: TraceSettingsStore = TraceSettings.fromJson(storageData);

          console.info('Trace settings successfully read!');

          const newSettings: TraceSettings = new TraceSettings(settingsRead);
          console.debug('Trace settings: %s', newSettings.toString());
          return newSettings;
        }

      } else {
        return TraceSettings.default();
      }
    } catch (e) {
      console.error('Trace settings read failed: %s', e);
      return TraceSettings.default();
    }
  }

  public static default(): TraceSettings {
    const settingsStore: TraceSettingsStore = {
      infoEnabled: true,
      debugEnabled: false,
      allModulesEnabled: true,
      modules: new Map<string, ModuleSetting>(),
      vendorModules: new Map<string, ModuleSetting>() };

    return new TraceSettings(settingsStore);
  }

  private static checklocalStorage(): boolean {
    try {
      const storage: any = window.localStorage;
      const test = 'storageTest';
      storage.setItem(test, test);
      storage.getItem(test);
      storage.removeItem(test);
      return true;
    } catch (e) {
      console.error('Loacal storage not available!');
      return false;
    }
  }

  private static getJson(settings: TraceSettingsStore): string {
    return JSON.stringify(settings, TraceSettings.replacerForMap);
  }

  private static fromJson(data: string): TraceSettingsStore {
    return JSON.parse(data, TraceSettings.reviverForMap);
  }

  private static replacerForMap(key: string, value: any): any {
    if (key === 'modules') {
      const allModules: ModuleSetting[] = [];
      (value as Map<string, ModuleSetting>).forEach(item => allModules.push(item));
      return allModules;
    }
    if (key === 'vendorModules') {
      const allVendors: ModuleSetting[] = [];
      (value as Map<string, ModuleSetting>).forEach(item => allVendors.push(item));
      return allVendors;
    }
    return value;
  }

  private static reviverForMap(key: string, value: any): any {
    if (key === 'modules') {
      const modulesMap: Map<string, ModuleSetting> = new Map<string, ModuleSetting>();
      (value as ModuleSetting[]).forEach(item => modulesMap.set(item.label, item));
      return modulesMap;
    }
    if (key === 'vendorModules') {
      const vendorMap: Map<string, ModuleSetting> = new Map<string, ModuleSetting>();
      (value as ModuleSetting[]).forEach(item => vendorMap.set(item.label, item));
      return vendorMap;
    }
    return value;
  }

  public storeTraceSettingsToLocStore(): void {
    if (TraceSettings.checklocalStorage() === true) {
      const settings = TraceSettings.getJson(this.settingsStore);
      window.localStorage.setItem(traceSettingsKey, settings);
      const checkSettings = window.localStorage.getItem(traceSettingsKey);
      if (settings !== checkSettings) {
        console.error('TraceSettings not stored correctly!');
      }
    }
  }

  public constructor(
    private readonly settingsStore: TraceSettingsStore) {
    if (this.settingsStore.modules === undefined || this.settingsStore.modules === null) {
      this.settingsStore.modules = new Map<string, ModuleSetting>();
    }
    if (this.settingsStore.vendorModules === undefined || this.settingsStore.vendorModules === null) {
      this.settingsStore.vendorModules = new Map<string, ModuleSetting>();
    }
  }

  public get modules(): Map<string, ModuleSetting> {
    return this.settingsStore.modules;
  }

  public get vendorModules(): Map<string, ModuleSetting> {
    return this.settingsStore.vendorModules;
  }

  public get infoEnabled(): boolean {
    return this.settingsStore.infoEnabled;
  }

  public set infoEnabled(value: boolean) {
    this.settingsStore.infoEnabled = value;
  }

  public get debugEnabled(): boolean {
    return this.settingsStore.debugEnabled;
  }

  public set debugEnabled(value: boolean) {
    this.settingsStore.debugEnabled = value;
  }

  public get allModulesEnabled(): boolean {
    return this.settingsStore.allModulesEnabled;
  }

  public set allModulesEnabled(value: boolean) {
    this.settingsStore.allModulesEnabled = value;
  }

  /**
   * Important:
   * Use this method only for functionality which do not log traces via the 'TraceService';
   * such as ng-router, signalR or any 3rd party components.
   * Using this method adds the specified module to the list of modules/channels. This allows to enable/disable the corresponding channel.
   * However to activate a restart app is required mostly as based on this setting, the corresponding flags of the component need to be set!
   * See signalR for an example.
   *
   * @param source
   * @memberof TraceSettings
   */
  public addToVendorModules(source: string): void {
    if (this.settingsStore.vendorModules.has(source) === false) {
      this.settingsStore.vendorModules.set(source, { label: source, enabled: false });
    }
  }

  public addToModules(source: string): void {
    if (this.settingsStore.modules.has(source) === false) {
      this.settingsStore.modules.set(source, { label: source, enabled: false });
    }
  }

  /**
   * Returns true, if the module exists and is enabled. Otherwise false.
   *
   * @param source
   * @returns
   * @memberof TraceSettings
   */
  public isModuleEnabled(source: string): boolean {
    if (this && this.settingsStore && this.settingsStore.modules && this.settingsStore.modules.has(source)) {
      return this.settingsStore.modules.get(source)!.enabled;
    } else {
      return false;
    }
  }

  /**
   * Returns true, if the module exists and is enabled. Otherwise false.
   *
   * @param source
   * @returns
   * @memberof TraceSettings
   */
  public isVendorModuleEnabled(source: string): boolean {
    if (this.settingsStore.vendorModules.has(source)) {
      return this.settingsStore.vendorModules.get(source)!.enabled;
    }
    return false;
  }

  public isModuleExisting(source: string): boolean {
    return this.settingsStore.modules.has(source);
  }

  public isVendorModuleExisting(source: string): boolean {
    return this.settingsStore.vendorModules.has(source);
  }

  public clearAllModules(): void {
    this.settingsStore.modules.clear();
    this.settingsStore.vendorModules.clear();
  }

  public toString(): string {
    let mods = '';
    this.settingsStore.modules.forEach(mod => {
      mods = `${mods}${mod.label}:${mod.enabled}; `;
    });
    let vendors = '';
    this.settingsStore.vendorModules.forEach(vendor => {
      vendors = `${vendors}${vendor.label}:${vendor.enabled}; `;
    });
    return `infoEnabled=${this.settingsStore.infoEnabled}, debugEnabled=${this.settingsStore.debugEnabled},
            allModulesEnabled=${this.settingsStore.allModulesEnabled}, modules=${mods}, vendorModules=${vendors}`;
  }
}
