import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { GraphicsFilterMenuItem } from '../../common/interfaces/GraphicsFilterMenuItem';
import { GmsElement } from '../../elements/gms-element';
import { GmsGraphic } from '../../elements/gms-graphic';
import { GmsLayer } from '../../elements/gms-layer';
import { GmsDepth } from '../../processor/gms-depth';
import { GmsMenuDepthsComponent } from './gms-menu-depths.component';
import { GmsMenuDisciplinesComponent } from './gms-menu-disciplines.component';
import { GmsMenuLayersComponent } from './gms-menu-layers.component';

@Component({
  selector: '[gms-menu]',
  template: `
    <div #gmsMenuTopContainer class="graphics-filter-menu-top-container">
      <div class="gms-menu-container">
        <xhtml:button [ngClass]="this.graphic?.isDoneLoading === false ? 'noptrevents' : 'allptrevents'"
                      class="btn btn-circle btn-lg btn-secondary element-filter-filled me-6 default-button-background"
                      (click)="toggleShowMenu()"/>
      </div>
      <si-menu-factory *ngIf="showMenu" [items]="items" class="graphics-si-filter-menu" />
    </div>
  `,
  styleUrl: './gms-menu.scss',
  viewProviders: [GmsMenuDepthsComponent, GmsMenuDisciplinesComponent, GmsMenuLayersComponent]
})

export class GmsMenuComponent implements OnInit, OnDestroy {
  @Input() public graphic: GmsGraphic;
  @ViewChild('gmsMenuTopContainer', { static: false }) public gmsMenuTopContainer: ElementRef;
  public readonly maxHeight: number = 225;
  public showMenu = false;
  public subscriptions: Subscription[] = [];
  public layerLabel: string;
  public depthsLabel: string;
  public disciplineLabel: string;
  public items: GraphicsFilterMenuItem[] = [];
  private onWindowKeyDownRef: (event: KeyboardEvent) => void;
  private onWindowClickRef: (event: MouseEvent) => void;
  private depthsItems: GraphicsFilterMenuItem[] = [];
  private disciplineItems: GraphicsFilterMenuItem[] = [];
  private layerItems: GraphicsFilterMenuItem[] = [];

  constructor(private readonly translateService: TranslateService) {
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.graphic.setupMenuItems.subscribe(() => this.setupForMenuItems()));
    this.onWindowKeyDownRef = this.onWindowKeyDown.bind(this);
    this.onWindowClickRef = this.onWindowClick.bind(this);
    window.addEventListener('keydown', this.onWindowKeyDownRef);
    window.addEventListener('click', this.onWindowClickRef);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('keydown', this.onWindowKeyDownRef);
    window.removeEventListener('click', this.onWindowClickRef);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public setupForMenuItems(): void {
    if (isNullOrUndefined(this.items) || this.items.length === 0) {
      this.subscriptions.push(this.translateService.get([
        'FILTER_MENU.LAYERS',
        'FILTER_MENU.DEPTHS',
        'FILTER_MENU.DISCIPLINES'
      ]).subscribe((res: any) => {
        this.layerLabel = res['FILTER_MENU.LAYERS'];
        this.disciplineLabel = res['FILTER_MENU.DISCIPLINES'];
        this.depthsLabel = res['FILTER_MENU.DEPTHS'];
        this.initMenuItems();
      }
      ));
    } else {
      this.initMenuItems();
    }
  }

  public initMenuItems(): void {
    this.depthsItems = this.getDepthItems();
    this.disciplineItems = this.getDisciplineItems();
    this.layerItems = this.getLayersItems();
    this.items = [...this.depthsItems, ...this.disciplineItems, ...this.layerItems];
  }

  public resetLayerItems(): void {
    this.layerItems = this.getLayersItems();
    this.items = [...this.depthsItems, ...this.disciplineItems, ...this.layerItems];
  }

  public getLayersItems(): GraphicsFilterMenuItem[] {
    const result: GraphicsFilterMenuItem[] = [];
    if (this.graphic !== undefined) {
      if (this.items.length !== 0) {
        result.push({ title: '-' });
      }

      result.push({ title: this.layerLabel, isHeading: true });

      for (const currentLayerElement of this.graphic.children) {
        const layer: GmsLayer = currentLayerElement as GmsLayer;

        if (this.isLayerVisibleWithCurrentDepth(layer, this?.graphic?.depths?.selectedDepth) === false) {
          continue;
        }

        result.push({
          title: layer?.Description,
          layer: layer as GmsLayer,
          context: this,
          selectionState: this?.getLayerSelectionState(layer),
          type: 'check',
          action(): void {
            this.context.OnMouseClick(this.layer);
            this.selectionState = this.context.getLayerSelectionState(layer);
          }
        });
      }
    }

    return result;
  }

  public isLayerVisibleWithCurrentDepth(layer: GmsLayer, depth: GmsDepth): boolean {
    let result = false;

    if (!isNullOrUndefined(layer?.Depths) && !isNullOrUndefined(depth)) {
      for (const depthName of layer?.Depths) {
        if (depthName === depth.Name) {
          result = true;
          break;
        }
      }
    }

    return result;
  }

  public getLayerSelectionState(layer: GmsElement): any {
    return layer.Visible ? 'check' : '';
  }

  public OnMouseClick(layer: GmsLayer): void {
    if (layer !== undefined) {
      this.setLayerVisiblity(layer, !layer.Visible);
    }
  }

  public setLayerVisiblity(layer: GmsElement, visible: boolean): void {
    if (layer.DesignValueVisible) {
      layer.Visible = visible;
    }
  }

  public getDisciplineItems(): GraphicsFilterMenuItem[] {
    const results: GraphicsFilterMenuItem[] = [];
    if (this.graphic !== undefined) {
      const disciplinesLength: number = this?.graphic?.disciplines?.size || 0;

      if (disciplinesLength === 0) {
        return results;
      }

      if (this.items.length !== 0) {
        results.push({ title: '-' });
      }

      results.push({ title: this.disciplineLabel, isHeading: true });

      for (const discipline of this.graphic.disciplines) {
        results.push({
          title: this.getDisciplineName(discipline),
          discipline,
          context: this,
          icon: this.getDisciplineIcon(discipline),
          selectionState: 'check',
          type: 'check',
          action(): void {
            this.context.filterByDisciplineId(this.discipline);
            const isFilteredByDiscipline: boolean = this.context.isFilteredByDiscipline(this.discipline);
            this.selectionState = isFilteredByDiscipline ? 'check' : '';
          }
        });
      }
    }

    return results;
  }

  public getDepthItems(): GraphicsFilterMenuItem[] {
    const results: GraphicsFilterMenuItem[] = [];
    if (this.graphic !== undefined) {
      const depthListLength: number = this?.graphic?.depths?.depthList?.length || 0;
      if (depthListLength <= 1) {
        return results;
      }

      results.push({ title: this.depthsLabel, isHeading: true });

      for (const depth of this.graphic.depths.depthList) {
        results.push({
          title: depth.Name,
          context: this,
          selectionState: this.getDepthSelectionState(depth),
          type: 'radio',
          action(): void {
            const graphic: GmsGraphic = this?.context?.graphic;
            if (!isNullOrUndefined(graphic)) {
              this.context.resetDepthSelectionState();
              graphic.depths.selectedDepth = depth;
              this.selectionState = 'radio';
              graphic.updateLayers();
              this.context.resetLayerItems();
            }
          }
        });
      }
    }

    return results;
  }

  public resetDepthSelectionState(): void {
    const depthsLength = this?.graphic?.depths?.depthList?.length || 0;
    for (let i = 1; i <= depthsLength; ++i) {
      this.items[i].selectionState = '';
    }
  }

  public getDepthSelectionState(depth: GmsDepth): any {
    return this.isSelectedDepth(depth) ? 'radio' : '';
  }

  public isSelectedDepth(depth: GmsDepth): boolean {
    return this?.graphic?.depths?.selectedDepth === depth;
  }

  public getDisciplineIcon(discipline: string): string {
    const iconStore: any = { '_50': 'element-plant-filled',
      '_20': 'element-special-object',
      '_200': 'element-power-filled',
      '_100': 'element-fire-filled',
      '_0': 'element-tv',
      '_250': 'element-mass-notification',
      '_150': 'element-security-filled'
    };

    if (discipline in iconStore) {
      return iconStore[discipline];
    }

    return undefined;
  }

  public getDisciplineName(discipline: string): string {
    if (this.graphic !== undefined && this.graphic.disciplineFromIdMap !== undefined) {
      return this.graphic.disciplineFromIdMap.get(discipline);
    }

    return undefined;
  }

  public isFilteredByDiscipline(discipline: string): boolean {
    if (this.graphic !== undefined && this.graphic.selectedDisciplines !== undefined) {
      return this.graphic.selectedDisciplines.has(discipline);
    }

    return false;
  }

  public filterByDisciplineId(discipline: string): void {
    if (this.graphic !== undefined && this.graphic.selectedDisciplines !== undefined) {
      if (this.graphic.selectedDisciplines.size !== 0 && this.isFilteredByDiscipline(discipline)) {
        this.graphic.selectedDisciplines.delete(discipline);
      } else {
        this.graphic.selectedDisciplines.add(discipline);
      }

      this.graphic.updateLayers();
    }
  }

  public toggleShowMenu(): void {
    this.showMenu = !this.showMenu;
  }

  public onWindowKeyDown(event: KeyboardEvent): void {
    const escKey = 'Escape';
    if (event.key === escKey) {
      this.showMenu = false;
    }
  }

  public onWindowClick(event: MouseEvent): void {
    if (this.graphic.isDoneLoading === false) {
      return;
    }

    const containsTarget: boolean = this?.gmsMenuTopContainer?.nativeElement?.contains(event?.target);
    if (containsTarget === false) {
      this.showMenu = false;
    }
  }
}
