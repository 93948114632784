import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { SiPasswordStrengthComponent, SiPasswordStrengthDirective } from '@simpl/element-ng';

@Component({
  selector: 'si-formly-password',
  templateUrl: './si-formly-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    SiPasswordStrengthComponent,
    SiPasswordStrengthDirective
  ]
})
export class SiFormlyPasswordComponent extends FieldType<FieldTypeConfig> {
  /*
  Sample config:
  "password.field": {
    "type": "string",
    "title": "Password",
    "widget": {
      "formlyConfig": {
        "type": "password",
        "props": {
          "minLength": 5,
          "upperCase": true,
          "loweCase": true,
          "digits": true,
          "special": true
        }
      }
    }
  }
  */
}
