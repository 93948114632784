import { Directive, Input, OnChanges } from '@angular/core';

import { SelectOption } from '../si-select.types';
import { SI_SELECT_OPTIONS_STRATEGY } from './si-select-options-strategy';
import { SiSelectOptionsStrategyBase } from './si-select-options-strategy.base';

/**
 * The directive allows to pass SelectOption options.
 * Otherwise use the {@link SiSelectComplexOptionsDirective} directive.
 *
 * @example
 * <si-select [options]="[{ id: 'one', title: 'One' }, { id: 'two', title: 'Two' }]"></si-select>
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'si-select[options]',
  providers: [{ provide: SI_SELECT_OPTIONS_STRATEGY, useExisting: SiSelectSimpleOptionsDirective }],
  standalone: true
})
export class SiSelectSimpleOptionsDirective
  extends SiSelectOptionsStrategyBase<string>
  implements OnChanges
{
  /** Options to be shown in select dropdown */
  @Input() options: SelectOption[] | undefined | null;

  /** @internal */
  override optionsEqual = (a: string, b: string): boolean => a === b;

  ngOnChanges(): void {
    if (this.options) {
      this.allRows.set(
        this.options?.map(option => ({
          type: 'option',
          value: option.id,
          label: option.title,
          color: option.color,
          icon: option.icon,
          disabled: option.disabled
        }))
      );
    } else {
      this.allRows.set([]);
    }

    this.updateOptionsSubject();
  }

  override onFilter(filterValue: string | undefined): void {
    this.updateOptionsSubject(filterValue);
  }

  private updateOptionsSubject(filterValue?: string): void {
    if (filterValue) {
      const filterValueLC = filterValue.toLowerCase();
      this.rows.set(this.allRows().filter(row => row.label!.toLowerCase().includes(filterValueLC)));
    } else {
      this.rows.set(this.allRows());
    }
  }
}
