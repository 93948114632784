import { inject, Injectable } from '@angular/core';
import { createModalConfig, SiModalService } from '@simpl/element-ng/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AlertDialogResult,
  ConfirmationDialogResult,
  DeleteConfirmationDialogResult,
  EditAbortDialogResult,
  EditDiscardDialogResult
} from './si-action-dialog.types';
import { SiAlertDialogComponent } from './si-alert-dialog/si-alert-dialog.component';
import { SiConfirmationDialogComponent } from './si-confirmation-dialog/si-confirmation-dialog.component';
import { SiDeleteConfirmationDialogComponent } from './si-delete-confirmation-dialog/si-delete-confirmation-dialog.component';
import { SiEditDiscardDialogComponent } from './si-edit-discard-dialog/si-edit-discard-dialog.component';

/**
 * The service provides convenient methods to show common modal dialogs.
 * All return an observable, which emit the dialog results. After emitting,
 * the observables complete. Therefore, clients do not need to unsubscribe.
 */
@Injectable({ providedIn: 'root' })
export class SiActionDialogService {
  private modalService = inject(SiModalService);

  /**
   * Show an alert dialog.
   */
  showAlertDialog(
    message: string,
    heading?: string,
    confirmBtnName?: string,
    translationParams?: { [key: string]: any },
    icon?: string
  ): Observable<AlertDialogResult> {
    return new Observable<AlertDialogResult>(subscriber => {
      const config = createModalConfig({
        message,
        heading,
        confirmBtnName,
        translationParams,
        icon
      });
      config.keyboard = true;

      const modalRef = this.modalService.show(
        SiAlertDialogComponent,
        config,
        AlertDialogResult.Confirm
      );
      modalRef.hidden.subscribe(subscriber);

      return () => modalRef.detach();
    });
  }

  /**
   * Show a confirmation dialog.
   */
  showConfirmationDialog(
    message: string,
    heading?: string,
    confirmBtnName?: string,
    declineBtnName?: string,
    translationParams?: { [key: string]: any },
    icon?: string
  ): Observable<ConfirmationDialogResult> {
    return new Observable<ConfirmationDialogResult>(subscriber => {
      const config = createModalConfig({
        message,
        heading,
        confirmBtnName,
        declineBtnName,
        translationParams,
        icon
      });
      config.keyboard = true;

      const modalRef = this.modalService.show(
        SiConfirmationDialogComponent,
        config,
        ConfirmationDialogResult.Decline
      );
      modalRef.hidden.subscribe(subscriber);

      return () => modalRef.detach();
    });
  }

  /**
   * @deprecated use {@link showEditDiscardDialog} instead.
   */
  showEditAbortDialog(
    disableSave?: boolean,
    message?: string,
    heading?: string,
    saveBtnName?: string,
    abortBtnName?: string,
    cancelBtnName?: string,
    disableSaveMessage?: string,
    disableSaveAbortBtnName?: string,
    translationParams?: { [key: string]: any }
  ): Observable<EditAbortDialogResult> {
    return this.showEditDiscardDialog(
      disableSave,
      message,
      heading,
      saveBtnName,
      abortBtnName,
      cancelBtnName,
      disableSaveMessage,
      disableSaveAbortBtnName,
      translationParams
    ).pipe(
      map((editDialogRes: EditDiscardDialogResult) => {
        switch (editDialogRes) {
          case EditDiscardDialogResult.Cancel:
            return EditAbortDialogResult.Cancel;
          case EditDiscardDialogResult.Discard:
            // eslint-disable-next-line detect-bad-words/in-code
            return EditAbortDialogResult.Abort;
          case EditDiscardDialogResult.Save:
            return EditAbortDialogResult.Save;
        }
      })
    );
  }

  /**
   * Show an edit dialog with the option to discard.
   */
  showEditDiscardDialog(
    disableSave?: boolean,
    message?: string,
    heading?: string,
    saveBtnName?: string,
    discardBtnName?: string,
    cancelBtnName?: string,
    disableSaveMessage?: string,
    disableSaveDiscardBtnName?: string,
    translationParams?: { [key: string]: any },
    icon?: string
  ): Observable<EditDiscardDialogResult> {
    return new Observable<EditDiscardDialogResult>(subscriber => {
      const config = createModalConfig({
        message,
        heading,
        saveBtnName,
        discardBtnName,
        cancelBtnName,
        disableSave,
        disableSaveMessage,
        disableSaveDiscardBtnName,
        translationParams,
        icon
      });
      config.keyboard = true;

      const modalRef = this.modalService.show(
        SiEditDiscardDialogComponent,
        config,
        EditDiscardDialogResult.Cancel
      );
      modalRef.hidden.subscribe(subscriber);

      return () => modalRef.detach();
    });
  }

  /**
   * Show a dialog confirming a deletion.
   */
  showDeleteConfirmationDialog(
    message?: string,
    heading?: string,
    deleteBtnName?: string,
    cancelBtnName?: string,
    translationParams?: { [key: string]: any },
    icon?: string
  ): Observable<DeleteConfirmationDialogResult> {
    return new Observable<DeleteConfirmationDialogResult>(subscriber => {
      const config = createModalConfig({
        message,
        heading,
        deleteBtnName,
        cancelBtnName,
        translationParams,
        icon
      });
      config.keyboard = true;

      const modalRef = this.modalService.show(
        SiDeleteConfirmationDialogComponent,
        config,
        DeleteConfirmationDialogResult.Cancel
      );
      modalRef.hidden.subscribe(subscriber);

      return () => modalRef.detach();
    });
  }
}
