import { ModeData } from '@gms-flex/services-common';
import { Observable, of } from 'rxjs';

import { FullPaneId } from '../fullsnapinid/full-pane-id.model';
import { FullQParamId } from '../fullsnapinid/full-qparam-id.model';
import { FullSnapInId } from '../fullsnapinid/fullsnapinid.model';
import { FrameInfo } from './frame-info.model';
import { IPreselectionService } from './ipreselection.service';
import { IStorageService } from './istorage.service';
import { MessageParameters } from './message-parameters.model';
import { QParam } from './q-param.model';
import { SnapinDisplay } from './snapin-display/snapin-display';
/**
 * The interface exposed by the SnapInMessageBroker to send message to snapins and panes.
 */
export abstract class IHfwMessage {

  /**
   * This method allows snapin instance to send messages.
   *
   * @param {FullSnapInId} fullId, The fullId identifying the snapin instance of the sender.
   * @param {FullPaneId} location, The location id identifying the hosting pane of the sender.
   * @param {string[]} types, The list of message types related with the outgoing message (i.e. 'Trend', 'Aggregator').
   * @param {any} messageBody, The content data of the message.
   * @param {boolean} preselection, If true sends a message with preselection (changing foreground snapin and visible snapins tabs on target panes).
   * @param { QParam } qParam, Along with preseleciton snapins can choose to set a qParam in url.
   * (The parameter key will be the plain QParam fullId and the parameter value will be the one specified here).
   * @param {boolean} broadcast, if true the outgoing communication rules are ignored and the message will be broadcasted.
   * After this time the handler, returns all received results of the snapins, no matter if all snapins replied.
   * @param {string} applyRuleId, When specified, only the outgoing communication rules configured with the same rule id will be considered.
   * This allows snapins to send message to different targets in different scenarios.
   * @param {boolean} secondarySelectionInSinglePane, If true, the message will never arrive to the sender, even if the canSendToSelf property is
   * configured in hldl. At the same time, even if the sender snap-in will not be a target of the message, it will not be removed from pane.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the send message operation.
   * The send message infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract sendMessage(fullId: FullSnapInId,
    location: FullPaneId,
    types: string[],
    messageBody: any,
    preselection: boolean,
    qParam: QParam,
    broadcast: boolean,
    applyRuleId: string,
    secondarySelectionInSinglePane: boolean): Observable<boolean>;

  /**
   * This method allowsf right-panel's components to send messages.
   *
   * @param {string} senderId, The id identifying the sender.
   * @param {string} communicationId, The communication rules to be use to route the message.
   * @param {string[]} types, The list of message types related with the outgoing message (i.e. 'Trend', 'Aggregator').
   * @param {any} messageBody, The content data of the message.
   * @param {boolean} preselection, If true sends a message with preselection (changing foreground snapin and visible snapins tabs on target panes).
   * @param { QParam } qParam, Along with preseleciton senders can choose to set a qParam in url.
   * (The parameter key will be the plain QParam fullId and the parameter value will be the one specified here).
   * @param {boolean} broadcast, if true the outgoing communication rules are ignored and the message will be broadcasted.
   * After this time the handler, returns all received results of the snapins, no matter if all snapins replied.
   * @param {string} applyRuleId, When specified, only the outgoing communication rules configured with the same rule id will be considered.
   * This allows senders to send message to different targets in different scenarios.
   * @param {boolean} secondarySelectionInSinglePane, If true, the message will never arrive to the sender, even if the canSendToSelf property is
   * configured in hldl. At the same time, even if the sender will not be a target of the message, it will not be removed from pane.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the send message operation.
   * The send message infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract sendMessageFromRightPanel(senderId: string,
    senderFrameId: string,
    communicationId: string,
    types: string[],
    messageBody: any,
    preselection: boolean,
    qParam: QParam,
    broadcast: boolean,
    applyRuleId: string,
    secondarySelectionInSinglePane: boolean): Observable<boolean>;

  /**
   * This method allows snapins to change the current frame in working area.
   * @param {string} frameId, The frame id configured in hldl file.
   * @param {MessageParameters} message?, Optional message information to be sent by frame's qParam service before changing frame.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the frame change operation.
   * The frame change infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract switchToNextFrame(frameId: string, message?: MessageParameters): Observable<boolean>;

  /**
   * This method allows snapins to set the application mode.
   * @param {ModeData} mode, The ModeData object defining the new mode.
   * @param {string} preferredFrameId?, Changenging mode you would switch to a preferred frame configured in the hldl.
   * @param {MessageParameters} firstSelectionObj?, In case the new mode should start with a new selection already in place,
   * specifying this MessageParameters allows hfw to perform the first automatic selection along with the mode change,
   * in order to perform an unique navigation switching between modes.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the change mode operation.
   * The change of mode infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract changeMode(mode: ModeData, preferredFrameId: string, firstSelectionObj?: MessageParameters): Observable<boolean>;

  /**
   * This method returns an observable, which emits a new value of FrameInfo each time the visible frame in the workarea changes.
   * Right after the subscription the FrameInfo related to the last frame selected is retreived.
   *
   * @returns {Observable<FrameInfo>} A FrameInfo observable.
   *
   * @memberOf IHfwMessage
   *
   */
  public getCurrentWorkAreaFrameInfo(): Observable<FrameInfo> | null {
    return null;
  }

  /**
   * This method returns an observable, which emits the id of the workarea view, if exists.
   *
   * @returns {Observable<string | undefined>} The id of the view.
   *
   * @memberOf IHfwMessage
   *
   */
  public getCurrentWorkAreaView(): Observable<string | undefined> {
    return of(undefined);
  }

  /**
   * This method returns an observable, which emits the latest layoutId selected for the specified frame.
   * @param {string} frameId, The frame id configured in hldl file.
   *
   * @returns {Observable<string>} A string observable emitting layout id values.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getCurrentLayoutId(frameId: string): Observable<string>;

  /**
   * This method returns an observable, which emits the latest ModeData selected for the specified frame.
   *
   * @returns  {Observable<ModeData>} A ModeData observable.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getCurrentMode(): Observable<ModeData | null>;

  /**
   * This method allows snapins to change the selected layout for the specified frame.
   * @param {string} frameId, The frame id configured in hldl file.
   * @param {string} layoutId, The layout id configured in hldl file.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the layout change operation.
   * The layout change infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract changeLayout(frameId: string, layoutId: string): Observable<boolean>;

  /**
   * This method allows snapins to change the selected view for the specified frame.
   * @param {string} frameId, The frame id configured in hldl file.
   * @param {string} viewId, The viewId id configured in hldl file.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the view change operation.
   * The view change infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract changeView(frameId: string, viewId: string): Observable<boolean>;

  /**
   * This method allows snapins to lock the current layout of the specified frame.
   * Locking a layout means block all the splitter and splitter button to modify the layout.
   * @param {string} frameId, The frame id configured in hldl file.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract lockLayout(frameId: string): void;

  /**
   * This method allows snapins to logout the current user from the client returning to login page.
   *
   * @param {boolean} skippUnsavedData optional, if true snapins are not informed that flex client will exit.
   * @param {boolean} isInactivityLogout optional, if true Log Off is fired by inactivity Timeout and not Manually
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract logout(skipUnsavedData?: boolean, isInactivityLogout?: boolean): void;

  /**
   * This method allows snapins to evaluate if a role change can be performed.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract canChangeUserRoles(): Observable<boolean>;

  /**
   * For each snapin instance the last message received is maintained, so that destroying it throught a tab change,
   * layout change, frame change in case it is recreated it can restore the last information retreived.
   * There are use cases in which this functionality is not required, so by calling this method the last message retreived
   * will be cleared.
   *
   * @param {FullSnapInId} fullId, The frame id configured in hldl file.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract clearLastMessage(fullId: FullSnapInId): void;

  /**
   * This method allows snapins to read incoming messages.
   * @param {FullSnapInId} fullId, The fullId identifying the snapin instance.
   *
   * @returns {Observable<any>} Observable which emits the last message received by the snapin.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getMessage(fullId: FullSnapInId): Observable<any>;

  /**
   * This method allows right panels to read incoming messages.
   * @param {string} frameId, The right panel's frame Id.
   *
   * @returns {Observable<any>} Observable which emits the last message received by the right panel.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getRightPanelMessage(frameId: string): Observable<any>;

  /**
   * This method returns the preselection service instance related to the specified snapin.
   * @param {FullSnapInId} fullId, The fullId identifying the snapin instance.
   *
   * @returns {IPreselectionService} The preselection service instance related to the specified snapin.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getPreselectionService(fullId: FullSnapInId): IPreselectionService;

  /**
   * This method returns the storage service instance related to the specified snapin.
   * @param {FullSnapInId} fullId, The fullId identifying the snapin instance.
   *
   * @returns {IStorageService} The storage service instance related to the specified snapin.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getStorageService(fullId: FullSnapInId): IStorageService;

  /**
   * This method returns an observable, which emits the latest query parameter value written in the url and related to the specified QParam.
   * Throught this method snapins can update their UI upon browser's navigations.
   *
   * @param {FullQParamId} qParamId, The fullId identifying the QParam.
   *
   * @returns {Observable<string | null>} A string observable, which emits the latest query parameter value written in the url for the specified QParam.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getQueryParam(qParamId: FullQParamId): Observable<string | null>;

  /**
   * This method returns an observable which emits the information of success or fail of the process.
   * @param {string} frameId, The id identifying the frame.
   *
   * @returns {Observable<boolean>} A boolean observable which emits the info of success or fail.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract resetFrameSettingsToDefault(frameId: string): Observable<boolean>;

  /**
   * Set tab visibilitiy for the snapin specified in the snapins collection.
   * @param {SnapinDisplay[]} snapins, The SnapinDisplay array definitions.
   * @param {any} context, An object that will be sent as message to all snapins in the array.
   *
   * @returns {Observable<boolean>} A boolean observable which emits the info of success or fail.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract displaySnapInTab(snapins: SnapinDisplay[], context?: any): Observable<boolean>;

  /**
   * This method allows snapin to calculate the next url, after the message sent as parameter.
   *
   * @param {FullSnapInId} fullId, The fullId identifying the snapin instance of the sender.
   * @param {FullPaneId} location, The location id identifying the hosting pane of the sender.
   * @param {string[]} types, The list of message types related with the outgoing message (i.e. 'Trend', 'Aggregator').
   * @param {any} messageBody, The content data of the message.
   * @param { QParam } qParam, Along with preseleciton snapins can choose to set a qParam in url.
   * (The parameter key will be the plain QParam fullId and the parameter value will be the one specified here).
   * @param {boolean} broadcast, if true the outgoing communication rules are ignored and the message will be broadcasted.
   * After this time the handler, returns all received results of the snapins, no matter if all snapins replied.
   * @param {string} applyRuleId, When specified, only the outgoing communication rules configured with the same rule id will be considered.
   * This allows snapins to send message to different targets in different scenarios.
   * @param {boolean} secondarySelectionInSinglePane, If true, the message will never arrive to the sender, even if the canSendToSelf property is
   * configured in hldl. At the same time, even if the sender snap-in will not be a target of the message, it will not be removed from pane.
   *
   * @returns {string} The url calculated after the selection.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract calculateUrlOnSelection(fullId: FullSnapInId,
    location: FullPaneId,
    types: string[],
    messageBody: any,
    qParam: QParam,
    broadcast: boolean,
    applyRuleId: string,
    secondarySelectionInSinglePane: boolean): Observable<string>;

  /**
   * This method shouldn't be called directly by snap-ins.
   * It is needed by snapin-base along with a reuse process on layout change, to calculate the next FullPaneId
   * of the calling snap-in.
   *
   * @param {FullSnapInId} fullId, The fullId identifying the snapin instance of the sender.
   *
   * @returns {FullPaneId} The updated FullPaneId, null if it doesn't change.
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract getUpdatingLocation(fullId: FullSnapInId): FullPaneId;

  /**
   * This method forces the hosting pane of the sender (e.g. hosting BIM or a graphic) to full screen or to normal size
   * @param {FullPaneId} location}, The location id identifying the hosting pane of the sender.
   * @param {boolean} fullScreen}, If true, the pane aspect is forced to full screen, if false, the pane aspect is forced to normal aspect
   *
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract fullScreenSnapin(location: FullPaneId, fullScreen: boolean): void;

  /**
   * Send a selection message from a QParameterService specified in the parameters.
   * @param {MessageParameters} message}, All the needed message information to send the selection.
   *
   *
   * @memberOf IHfwMessage
   *
   */
  public abstract selectViaQParamService(message: MessageParameters): Observable<boolean>;
}
