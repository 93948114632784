import { ControlEditorMode } from '../processor/command-view-model/gms-parameter-vm.base';
import { ParameterType } from '../processor/command/parameters/gms-base-parameter';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { MouseMode } from '../types/gms-graphics-mouse-mode-types';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsCommandControl } from './gms-commandcontrol';
import { GmsElement } from './gms-element';
import { GmsGraphic } from './gms-graphic';

export class GmsCommandControlSlider extends GmsCommandControl {
  protected static disableInterval = -1;

  public get UpdateInterval(): number {
    return this._updateInterval;
  }
  public set UpdateInterval(value: number) {
    if (this._updateInterval !== value) {
      if (Number.isNaN(value)) {
        this._updateInterval = GmsCommandControl.DEFAULT_UPDATEINTERVAL;
      }

      if (value <= GmsCommandControlSlider.disableInterval) {
        this._updateInterval = GmsCommandControlSlider.disableInterval;
      } else {
        // Don't allow values below 1 ms
        if (value < 1) {
          this._updateInterval = 1;
        }

        this._updateInterval = value;
      }
      this.NotifyPropertyChanged('UpdateInterval');
    }
  }

  public get TickFrequency(): number {
    return this._tickFrequency;
  }

  public set TickFrequency(value: number) {
    if (this._tickFrequency !== value) {
      this._tickFrequency = Number.isNaN(value) ? GmsCommandControl.DEFAULT_TICKFREQUENCY : value;
      this.NotifyPropertyChanged('TickFrequency');
    }
  }

  public get SnapToTickEnabled(): boolean {
    return this._snapToTickEnabled;
  }

  public set SnapToTickEnabled(value: boolean) {
    if (this._snapToTickEnabled !== value) {
      this._snapToTickEnabled = value;
      this.NotifyPropertyChanged('SnapToTickEnabled');
    }
  }
  public get SliderShadow(): boolean {
    return this._sliderShadow;
  }
  public set SliderShadow(value: boolean) {
    this._sliderShadow = value;
  }

  protected get Thumb(): GmsElement {
    return this.children[1];
  }

  protected get ThumbClone(): GmsElement {
    return this.children[0];
  }
  public get IsSliding(): boolean {
    return this._isSliding;
  }
  public set IsSliding(value: boolean) {
    if (this._isSliding !== value) {
      this._isSliding = value;
      const graphic: GmsGraphic = this.Graphic as GmsGraphic;
      if (graphic !== undefined) {
        if (!this._isSliding) {
          graphic.mouseMode = MouseMode.None;
        }
        // update graphic cursor
        graphic.IsSliding = value;
      }
    }
  }

  protected _startThumbX = 0;
  protected _startThumbY = 0;
  protected _startMouseX = 0;
  protected _startMouseY = 0;
  protected _thumbWidth2 = 0;
  protected StartTimer(): void {
    this._timerID = setInterval(() => this.OnTick(), this.GetAnimatedUpdateInterval());
  }

  protected StopTimer(): void {
    clearInterval(this._timerID);
  }

  protected CalculateMinMax(): [number, number] {
    let minimum: number;
    let maximum: number;
    if (this.isLongTypeParameter) {
      const minimumLong: Long = this.GetAnimatedMinimumLong();
      const maximumLong: Long = this.GetAnimatedMaximumLong();
      minimum = minimumLong ? minimumLong.toNumber() : FormatHelper.LongMinToNumber();
      maximum = maximumLong ? maximumLong.toNumber() : FormatHelper.LongMaxToNumber();
    } else {
      minimum = this.GetAnimateMinimum();
      maximum = this.GetAnimateMaximum();
    }
    return [minimum, maximum];
  }

  protected UpdateValue(): void {
    if (!this.CommandVM.Suspended && this.ParameterVM !== null) {
      let minimumLong: Long;
      let maximumLong: Long;
      let tickLong: Long;
      let tick: number;
      const x: number = this.Thumb.X;
      if (this.isLongTypeParameter) {
        minimumLong = this.GetAnimatedMinimumLong();
        maximumLong = this.GetAnimatedMaximumLong();
      }
      const MinMax: [number, number] = this.CalculateMinMax();
      const minimum: number = MinMax[0];
      const maximum: number = MinMax[1];

      tick = minimum + (maximum - minimum) * (x + this._thumbWidth2) / this.Width;

      if (this.GetAnimatedSnapToTickEnabled()) {
        tick = minimum + this.GetAnimatedTickFrequencyInterval() * Math.round((tick - minimum) / this.GetAnimatedTickFrequencyInterval());
      }
      if (this.isLongTypeParameter || this.ParameterVM.precision === 0) {
        tick = Math.round(tick);
      }
      if (this.isLongTypeParameter) {
        if (x + this._thumbWidth2 > this.Width - 0.00000001) {
          tickLong = maximumLong;
        } else if (x + this._thumbWidth2 < 0.00000001) {
          tickLong = minimumLong;
        } else {
          tickLong = FormatHelper.parseLongValue(tick, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
        }
      }
      if (tick < minimum) {
        tick = minimum;
      } else if (tick > maximum) {
        tick = maximum;
      }
      this.ThumbClone.X = x;

      const result: string = this.isLongTypeParameter ? tickLong?.toString() :
        FormatHelper.NumberToString(tick, this.locale, null, 10);

      this.ValueChanged(result);
    }
  }

  protected AddCommandButtons(): void {
    // slider and Rotator dont support buttons
  }

  // This method is called when a property of the ParameterVM changes
  protected OnParameterVMPropertyChanged(propertyName: string): void {
    if (!this.IsSliding) {
      switch (propertyName) {
        case 'Value':
          if (!this.CommandVM.isModified) {
            this.UpdatePosition();
          }
          break;

        case 'FormattedValue':
          if (this.CommandVM.isModified) {
            this.UpdatePosition();
          }
          break;
        default:
          break;
      }
    }
  }

  protected UpdatePosition(): void {
    if (!this.CommandVM.Suspended && this.ParameterVM !== null) {
      let value: number = Number.NaN;
      const MinMax: [number, number] = this.CalculateMinMax();
      const minimum: number = MinMax[0];
      const maximum: number = MinMax[1];

      if (this.isLongTypeParameter) {
        const valueLong: Long = this.CommandVM.isModified ? FormatHelper.parseLongValue(this.ParameterVM.FormattedValue, this.ParameterVM.ParameterType === ParameterType.NumericUInt64) :
          FormatHelper.parseLongValue(this.ParameterVM.Value, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
        if (valueLong) {
          value = valueLong.toNumber();
        }
      } else {
        // value = this.CommandVM.isModified ? Utility.ConvertToDouble(this.ParameterVM.FormattedValue) :
        //    Utility.ConvertToDouble(this.ParameterVM.Value);
        value = FormatHelper.getNumberfromString(this.CommandVM.isModified ?
          this.ParameterVM.FormattedValue : this.ParameterVM.Value, this.locale);
      }

      if (Number.isNaN(value)) {
        value = minimum;
      } else if (value < minimum) {
        value = minimum;
      } else if (value > maximum) {
        value = maximum;
      }
      if (this._thumbWidth2 === 0) {
        this._thumbWidth2 = this.Thumb.Width / 2;
      }
      const x: number = this.Width * (value - minimum) / (maximum - minimum) - this._thumbWidth2;
      this.Thumb.X = x;
    }
  }

  public constructor() {
    super(GmsCommandControlType.Slider);
    this.children = [];
  }

  protected GetAnimatedUpdateInterval(): number {
    return Evaluation.GetValue2(this._evaluationUpdateInterval, this.UpdateInterval, PropertyType.Number);
  }

  protected GetAnimatedSnapToTickEnabled(): boolean {
    return Evaluation.GetValue2(this._evaluationSnapToTickEnabled, this.SnapToTickEnabled, PropertyType.Boolean);
  }
  protected GetAnimatedSliderShadow(): boolean {
    return Evaluation.GetValue2(this._evaluationSliderShadow, this.SliderShadow, PropertyType.Boolean);
  }

  protected GetAnimatedTickFrequencyInterval(): number {
    return Evaluation.GetValue2(this._evaluationTickFrequencyInterval, this.TickFrequency, PropertyType.Number);
  }

  protected GetExecuteCommandCouter(): number {
    return this._executeCommandCounter;
  }

  protected AddExecuteCommandCouter(): void {
    this._executeCommandCounter = this._executeCommandCounter + 1;
  }

  protected UpdateExecuteCommandStatus(success: boolean, error: any = undefined): void {
    if (this.CommandVM === null) {
      return;
    }
    if (success) {
      const msg = `Command executed with success: ${this.CommandVM.Key}, Name = ${this.CommandVM.Name}`;
      this.TraceService.info(this.traceModuleCommand, msg);
    } else if (error !== undefined) {
      if (this.ToastNotificationService !== undefined) {
        this.ToastNotificationService.queueToastNotification('warning', 'Command Execute failed', error.message);
      }
    }
  }
  protected UpdateEvaluation(evaluation: Evaluation): void {
    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'UpdateInterval':
        this._evaluationUpdateInterval = evaluation;
        break;

      case 'IsSnapToTickEnabled':
        this._evaluationSnapToTickEnabled = evaluation;
        break;

      case 'TickFrequencyInterval':
        this._evaluationTickFrequencyInterval = evaluation;
        break;

      case 'SliderShadow':
        this._evaluationSliderShadow = evaluation;
        break;

      default:
        break;
    }
  }
  private _executeCommandCounter = 0;

  private _updateInterval: number = GmsCommandControl.DEFAULT_UPDATEINTERVAL;
  private _tickFrequency: number = GmsCommandControl.DEFAULT_TICKFREQUENCY;
  private _snapToTickEnabled = false;
  private _sliderShadow = true;

  private _evaluationUpdateInterval: Evaluation;
  private _evaluationTickFrequencyInterval: Evaluation;
  private _evaluationSnapToTickEnabled: Evaluation;
  private _evaluationSliderShadow: Evaluation;

  private _isSliding = false;

  private _timerID: any;

  public StartSliding(x: number, y: number): void {
    this.IsSliding = true;
    this._startMouseX = x;
    this._startMouseY = y;
    this._startThumbX = this.Thumb.X;
    this._startThumbY = this.Thumb.Y;
    this._thumbWidth2 = this.Thumb.Width / 2;

    const interval: number = this.GetAnimatedUpdateInterval();
    if (interval >= 0) {
      this.StartTimer();
    }
    if (this.GetAnimatedSliderShadow()) {
      // update clone position
      if (this.children.length > 1) {
        this.ThumbClone.X = this.Thumb.X;
        this.ThumbClone.Y = this.Thumb.Y;
        this.ThumbClone.Visible = true;
      }
    }

    this.CommandVM.isCommandExecuteDone = false;
    this.CommandVM.isCommandExecuteCancel = false;
    if (!this.GetAnimatedIsCommandTriggerEnabled()) {
      // command will not be executed, so keep it modified
      this.CommandVM.isModified = true;
    }
  }

  public Slide(x: number, y: number): void {
    if (this.ParameterVM === null) {
      return;
    }

    const MinMax: [number, number] = this.CalculateMinMax();
    const minimum: number = MinMax[0];
    const maximum: number = MinMax[1];
    const factor: number = (maximum - minimum) / this.Width;

    let dx: number = x - this._startMouseX;
    let dy: number = y - this._startMouseY;
    if (this.Angle !== 0) {
      const angle: number = -Math.PI * this.Angle / 180;
      const c: number = Math.cos(angle);
      const s: number = Math.sin(angle);
      const dx1: number = dx * c - dy * s;
      dy = dx * s + dy * c;
      dx = dx1;
    }

    if (this.ScaleX !== 1) {
      dx = dx / this.ScaleX;
    }
    if (this.ScaleY !== 1) {
      dy = dy / this.ScaleY;
    }

    if (this.CurrentZoomLevel !== 1) {
      dx = dx / this.CurrentZoomLevel;
      dy = dy / this.CurrentZoomLevel;
    }

    x = this._startThumbX + dx;
    if (x < /* this.X */-this._thumbWidth2) {
      x = /* this.X*/ -this._thumbWidth2;
    } else if (x > /* this.X + */this.Width - this._thumbWidth2) {
      x = /* this.X + */this.Width - this._thumbWidth2;
    }

    let tick: number = minimum + factor * (x + this._thumbWidth2);
    if (tick < minimum) {
      tick = minimum;
    } else if (tick > maximum) {
      tick = maximum;
    }

    x = (tick - minimum) / factor - this._thumbWidth2;
    if (x !== this.Thumb.X) {
      this.Thumb.X = x;
    }
  }
  public CancelSliding(): void {

    this.StopTimer();
    this.UpdateValue();

    this.NotifyShapeChanged();

    this.ThumbClone.Visible = false;

    this.IsSliding = false;
  }

  public EndSliding(x: number, y: number): void {

    this.StopTimer();

    this.Slide(x, y);

    this.UpdateValue();

    this.NotifyShapeChanged();

    this.ThumbClone.Visible = false;

    this.IsSliding = false;
  }

  public setControlEditorMode(mode: ControlEditorMode): void {
    if (mode === ControlEditorMode.View) {
      this.StopTimer();
      this.UpdatePosition();
    }
  }

  public AddChild(element: GmsElement): void {

    this.children.push(element);

    if (this.CommandVM !== null && !element.IsSlidingClone) {
      element.CommandVM = this.CommandVM;
    }
    // ChangeDetection: Let others know that a property changed
    this.NotifyPropertyChanged();
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {

    // UpdateInterval
    let result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.UpdateInterval);
    if (result !== undefined) {
      this.UpdateInterval = FormatHelper.StringToNumber(result);
    }
    // TickFrequency
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.TickFrequency);
    if (result !== undefined) {
      this.TickFrequency = FormatHelper.StringToNumber(result);
    }
    // IsSnapToTickEnabled
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.IsSnapToTickEnabled);
    if (result !== undefined) {
      this.SnapToTickEnabled = Utility.ConvertToBool(result);
    }

    // IsSnapToTickEnabled
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.SliderShadow);
    if (result !== undefined) {
      this.SliderShadow = Utility.ConvertToBool(result);
    }

    super.Deserialize(node);
  }

  public Destroy(): void {

    this._evaluationUpdateInterval = undefined;
    this._evaluationSnapToTickEnabled = undefined;
    this._evaluationTickFrequencyInterval = undefined;
    this._evaluationSliderShadow = undefined;
    this.ParameterVM = null;

    super.Destroy();
  }

  public IsSlider(): boolean {
    return true;
  }

  private OnTick(): void {
    if (!this.CommandVM.Suspended) {
      this.UpdateValue();
    }
  }
}
