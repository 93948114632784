<form class="form-group mb-5 w-100">
  <label class="form-label" for="binarySelection"> {{ translatedLabels.get("value") }} </label>
  <si-select
    name="binarySelection"
    id="binarySelection"
    class="form-select"
    [placeholder]="translatedLabels.get('select-placeholder')"
    [options]="binaryValues"
    [(value)]="value"
    (valueChange)="onSelect($event)"
  />
</form>
