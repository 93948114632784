import { BreakpointObserver } from '@angular/cdk/layout';
import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SiAccordionHCollapseService } from '@simpl/element-ng/accordion';
import { MenuItem } from '@simpl/element-ng/common';
import { SiContentActionBarComponent } from '@simpl/element-ng/content-action-bar';
import { SiLinkDirective } from '@simpl/element-ng/link';
import { BOOTSTRAP_BREAKPOINTS } from '@simpl/element-ng/resize-observer';
import { SiSearchBarComponent } from '@simpl/element-ng/search-bar';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subscription } from 'rxjs';

import { SiSidePanelService } from './si-side-panel.service';

/**
 * An extension of MenuItem to support combined icons
 */
export interface StatusItem extends MenuItem {
  overlayIcon?: string;
}

@Component({
  selector: 'si-side-panel-content',
  templateUrl: './si-side-panel-content.component.html',
  providers: [SiAccordionHCollapseService],
  styleUrl: './si-side-panel-content.component.scss',
  standalone: true,
  imports: [
    NgClass,
    SiContentActionBarComponent,
    SiLinkDirective,
    SiSearchBarComponent,
    SiTranslateModule
  ]
})
export class SiSidePanelContentComponent implements OnInit, OnDestroy {
  /*
   * If true, the panel is collapsed instead of completely hidden, just like the vertical navbar
   */
  @Input({ transform: booleanAttribute }) collapsible = false;

  /**
   * Header of side panel
   */
  @Input() heading = '';

  /**
   * Input list of primary action items
   */
  @Input() primaryActions: MenuItem[] = [];

  /**
   * Input list of secondary action items.
   */
  @Input() secondaryActions: MenuItem[] = [];

  /**
   * Status icons/actions
   */
  @Input() statusActions: StatusItem[] = [];

  /**
   * Toggles search bar
   */
  @Input({ transform: booleanAttribute }) searchable = false;

  /**
   * Placeholder text for search
   */
  @Input() searchPlaceholder = $localize`:@@SI_SIDE_PANEL.SEARCH_PLACEHOLDER:Search...`;

  /**
   * Aria label for close button. Needed for a11y
   */
  @Input() closeButtonLabel = $localize`:@@SI_SIDE_PANEL.CLOSE:Close`;

  /**
   * Toggle icon aria-label, required for a11y
   */
  @Input() toggleItemLabel = $localize`:@@SI_SIDE_PANEL.TOGGLE:Toggle`;

  /**
   * Show a badge on the mobile drawer indicating a new alert or notification
   */
  @Input({ transform: booleanAttribute }) showMobileDrawerBadge = false;

  /**
   * Output for search bar input
   */
  @Output() readonly searchEvent = new EventEmitter<string>();

  @HostBinding('class.collapsed') protected isCollapsed = false;
  @HostBinding('class.expanded') protected isExpanded = true;
  @HostBinding('class.enable-mobile') protected get enableMobile(): boolean {
    return this.service?.enableMobile ?? false;
  }

  protected mobileSize = false;
  protected get focusable(): boolean {
    return !this.mobileSize || !this.enableMobile || !this.isCollapsed;
  }

  /**
   * The $rpanel-transition-duration in the style is 0.5 seconds.
   * For the animation we need to wait until the resize is done.
   */
  private readonly resizeAnimationDelay = 500;
  private subscription: Subscription;
  private service = inject(SiSidePanelService);
  private breakpointObserver = inject(BreakpointObserver);
  private cdRef = inject(ChangeDetectorRef);

  private expandedTimeout: any;

  constructor() {
    const accordionHcollapse = inject(SiAccordionHCollapseService);
    this.subscription = this.service.isOpen$.subscribe(state => {
      this.isCollapsed = !state;
      clearTimeout(this.expandedTimeout);
      this.expandedTimeout = undefined;
      if (!state) {
        this.isExpanded = false;
        this.cdRef.markForCheck();
      } else {
        this.expandedTimeout = setTimeout(() => {
          this.isExpanded = true;
          this.cdRef.markForCheck();
        }, this.resizeAnimationDelay / 2);
      }
      accordionHcollapse.hcollapsed$.next(!state);
    });
    this.subscription.add(accordionHcollapse.open$.subscribe(() => this.service.open()));
  }

  ngOnInit(): void {
    this.subscription.add(
      this.breakpointObserver
        .observe('(max-width: ' + BOOTSTRAP_BREAKPOINTS.smMinimum + 'px)')
        .subscribe(({ matches }) => {
          this.mobileSize = matches;
          this.cdRef.markForCheck();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected toggleSidePanel(event?: MouseEvent): void {
    if (event?.detail !== 0) {
      // Blur except if triggered by keyboard
      (document?.activeElement as HTMLElement)?.blur();
    }
    if (this.service.isTemporaryOpen()) {
      this.service.hideTemporaryContent();
    } else {
      this.service.toggle();
    }
  }
}
