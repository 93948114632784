import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { SiTranslateService } from '@simpl/element-ng/translate';

export class SiFormlyTranslateExtension {
  constructor(private translate: SiTranslateService) {}
  prePopulate(field: FormlyFieldConfig): void {
    const to = field.props ?? {};
    if (to.translate === false || to._translated) {
      //eslint-disable-line @typescript-eslint/naming-convention
      return;
    }

    field.expressions ??= {};

    to._translated = true; //eslint-disable-line @typescript-eslint/naming-convention
    if (to.label) {
      field.expressions['props.label'] = this.translate.translateAsync(to.label);
    }

    if (to.options) {
      // e.g. a select
      let i = -1;
      to.options.forEach((val: any) => {
        i++;
        if (field.expressions) {
          field.expressions[`props.options.${i}.label`] = this.translate.translateAsync(val.label);
        }
      });
    }

    if (to.placeholder) {
      field.expressions['props.placeholder'] = this.translate.translateAsync(to.placeholder);
    }

    if (to.description) {
      field.expressions['props.description'] = this.translate.translateAsync(to.description);
    }

    if (field.validation?.messages) {
      const msgs = field.validation.messages;
      for (const msg in msgs) {
        if (typeof msgs[msg] === 'string') {
          // This unfortunately blocks any opportunity to create context specific messages
          // Specific messages could be done via the "map" function when the schema itself is parsed
          field.expressions[`validation.messages.${msg}`] = this.translate.translateAsync(
            msgs[msg] + ''
          );
        }
      }
    }

    // Trigger a change
    field.expressions = { ...(field.expressions ?? {}) };
  }
}

export const registerTranslateExtension = (translate: SiTranslateService): ConfigOption => ({
  // General validation messages
  validationMessages: [
    // TODO uncomment this if a default translation for required fields is available
    // {
    //   name: 'required',
    //   message: () => translate.stream('FORM.VALIDATION.REQUIRED')
    // }
  ],
  extensions: [
    {
      name: 'translate',
      extension: new SiFormlyTranslateExtension(translate)
    }
  ]
});
