<button
  type="button"
  class="dropdown-item nav-link focus-inside ps-4 px-sm-4"
  [siNavbarDropdownTriggerFor]="dropdown"
>
  <si-avatar
    size="small"
    [initials]="account.initials"
    [imageUrl]="account.image"
    [icon]="account.icon"
    [color]="0"
    [altText]="account.title"
    [status]="account.avatarStatus"
    [statusAriaLabel]="account.avatarStatusAriaLabel"
  />
  <span class="title d-sm-none">{{ account.title }}</span>
  <span class="item-end ms-auto d-sm-none">
    <span class="dropdown-caret menu-end-icon element-down-2 m-0 me-n3 ps-2"></span>
  </span>
</button>

<ng-template #dropdown>
  <si-navbar-dropdown>
    <div class="d-block userinfo mx-5 mb-4">
      <div class="fw-bold text-truncate">{{ account.title }}</div>
      @if (account.email) {
        <div class="email">{{ account.email }}</div>
      }
      <div class="d-flex align-items-center text-secondary mt-2">
        @if (account.company) {
          <span class="label me-4">{{ account.company | translate }}</span>
        }
        @if (account.role) {
          <span class="ms-0 badge bg-default">
            {{ account.role | translate }}
          </span>
        }
      </div>
    </div>
    @if (items?.length) {
      <div class="d-block dropdown-divider" role="separator"></div>
      <si-navbar-dropdown-items-factory [items]="items!" />
    }
    @if (account.customContent) {
      <div class="d-block dropdown-divider" role="separator"></div>
      <ng-content />
    }
  </si-navbar-dropdown>
</ng-template>
