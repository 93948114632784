import { NgModule } from '@angular/core';
import { SiAvatarComponent } from '@simpl/element-ng/avatar';

import { SiNavbarBrandComponent } from './si-navbar-brand/si-navbar-brand.component';
import { SiNavbarItemComponent } from './si-navbar-item/si-navbar-item.component';
import { SiNavbarPrimaryComponent } from './si-navbar-primary/si-navbar-primary.component';
import { SiNavbarProfileMenuComponent } from './si-navbar-profile-menu/si-navbar-profile-menu.component';

const components = [
  SiAvatarComponent,
  SiNavbarBrandComponent,
  SiNavbarItemComponent,
  SiNavbarProfileMenuComponent,
  SiNavbarPrimaryComponent
];

@NgModule({
  imports: components,
  exports: components
})
export class SiNavbarModule {}
