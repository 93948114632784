export class SpecialChars {
  static readonly all = '*';
  static readonly optional = '';

  static readonly anyDay = SpecialChars.all;
  static readonly evenDay = 34;
  static readonly oddDay = 33;
  static readonly lastDay = 32;

  static readonly anyMonth = SpecialChars.all;
  static readonly evenMonth = 14;
  static readonly oddMonth = 13;

  static readonly anyYear = SpecialChars.all;

  static readonly day = [
    SpecialChars.anyDay,
    SpecialChars.lastDay,
    SpecialChars.evenDay,
    SpecialChars.oddDay
  ];
  static readonly hours = [SpecialChars.all];
  static readonly hundredths = [SpecialChars.all];
  static readonly minutes = [SpecialChars.all];
  static readonly month = [SpecialChars.anyMonth, SpecialChars.evenMonth, SpecialChars.oddMonth];
  static readonly seconds = [SpecialChars.all];
  static readonly year = [SpecialChars.anyYear];
}
