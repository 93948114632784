import { Observable } from 'rxjs';

import { AlarmConfigAttributes, AlarmConfigBx } from './data.model';

export abstract class AlarmConfigurationServiceBase {
  public abstract getAlarmConfiguration(partitionId: string, pointId: string): Observable<AlarmConfigBx[] | undefined>;

  public abstract updateAlarmConfiguration(
    partitionId: string,
    pointId: string,
    updatedConfiguration: AlarmConfigBx
  ): Observable<AlarmConfigBx | undefined>;

  public abstract deleteAlarmConfiguration(partitionId: string, pointId: string, configurationId: string): Observable<boolean | undefined>;

  public abstract createAlarmConfiguration(
    partitionId: string,
    pointId: string,
    configurationAttributes: AlarmConfigAttributes
  ): Observable<AlarmConfigBx | undefined>;
}