import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ProductInfo } from '../product/data.model';
import { ProductService } from '../product/product.service';
import { isNullOrUndefined } from '../utility';
@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private readonly localLang = 'en';

  private languages!: string[];

  private language: string | undefined;

  public constructor(private readonly translateService: TranslateService,
    private readonly productService: ProductService) {
    this.getInstalledLAnguages();
    // this.translateService.onLangChange.subscribe(this.storeLang); no needed without dorpdown menu
  }

  public detectLanguage(language: string): string | undefined {
    if (this.setUserLanguage(language)) {
      return this.language;
    }
    return this.localLang;
  }

  private setUserLanguage(language: string): boolean {
    if (isNullOrUndefined(this.languages)) {
      this.productService.getProductSettings().subscribe((productInfo: ProductInfo) => {
        this.languages = productInfo.installedLanguages;
        if (!this.languageIsSupported(language)) {
          return this.callBackLanguage(language);
        }
      });
    } else {
      if (!this.languageIsSupported(language)) {
        return this.callBackLanguage(language);
      } else {
        return true;
      }
    }
    return false;
  }

  private getInstalledLAnguages(): void {
    this.productService.getProductSettings().subscribe((productInfo: ProductInfo) => {
      this.languages = productInfo.installedLanguages;
      this.translateService.setDefaultLang(productInfo.fallbackCulture);
      for (const item of this.languages) {
        if (item === this.translateService.getBrowserLang()) {
          this.translateService.use(this.translateService.getBrowserLang()!);
          return;
        }
      }
      this.translateService.use(productInfo.fallbackCulture);
    });
  }

  private callBackLanguage(language: string): boolean {
    if (!this.languageNoRegionIsSupported(language)) {
      this.language = this.translateService.getBrowserLang();
      return false;
    }
    return true;
  }

  private languageIsSupported(language: string): boolean {
    for (const item of this.languages) {
      if (item === language) {
        this.language = item;
        return true;
      }
    }
    return false;
  }

  private languageNoRegionIsSupported(language: string): boolean {
    for (const item of this.languages) {
      if (item === language?.substr(0, 2)) {
        this.language = item;
        return true;
      }
    }
    return false;
  }

}
