import { CommandParameters } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { AnyValueType, DateTimeValue, ValueType } from '@simpl/element-value-types';
import { AnyPropertyValueType } from '@simpl/object-browser-ng';

import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType, DateFieldOrder, DateTimeType } from './command-vm.types';
import { BACnetDateTime, SiTranslator, WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

/**
 * String parameter view-model implementation.
 */
export class BACnetDateTimeParamViewModel extends CommandParamViewModel {

  private defaultBACnetDt: BACnetDateTime;
  private readonly siValueType: ValueType;

  public get allowWildcards(): boolean {
    return Boolean(this.param.AllowWildcards);
  }

  public get allowDayOfWeek(): boolean {
    return Boolean(this.param.AllowDayOfWeek);
  }

  public get dateTimeType(): DateTimeType {
    return WsiTranslator.toDateTimeType(this.param.BACnetDateTimeDetail);
  }

  public get timeResolution(): number {
    return this.param.BACnetDateTimeResolution !== undefined ? this.param.BACnetDateTimeResolution : 0;
  }

  public get dateFieldOrder(): DateFieldOrder {
    return CommandParamViewModel.dateFieldOrderFromLocale(this.vmContext.locale);
  }

  public get dataType(): CommandParamType {
    return CommandParamType.BACnetDateTime;
  }

  /**
   * Constructor.
   */
  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    switch (this.dateTimeType) {
      case DateTimeType.DateOnly:
        this.siValueType = 'date';
        break;
      case DateTimeType.TimeOnly:
        this.siValueType = 'time';
        break;
      case DateTimeType.DateAndTime:
      default:
        this.siValueType = 'date-time';
        break;
    }

    this.siParam.value = {
      type: this.siValueType,
      readonly: false,
      optional: false,
      value: undefined
    };

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Encode the provided object into a a string value.
   *
   * Method expects a string value representing a date-time.  If timezone information is not
   * included, time will be assumed to be local time.
   *
   * Returned string will be an ISO formatted string representing provided value in UTC.
   * Example,
   *   2018-09-14T19:49:06Z  ==> 1180914F194906FF
   */
  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let bndtString: string;
    if (valObj && (valObj.type === 'date' || valObj.type === 'time' || valObj.type === 'date-time')) {
      const bndt: BACnetDateTime = SiTranslator.parseSiDateTimeToBACnet(valObj.value);
      if (bndt) {
        bndtString = WsiTranslator.encodeBACnetDateTime(bndt);
      }
    }
    return bndtString;
  }

  public resetParamValue(): void {
    const siVal: DateTimeValue = this.siParam.value as DateTimeValue;
    const bndt: BACnetDateTime = this.defaultBACnetDt;
    if (bndt) {
      switch (this.dateTimeType) {
        case DateTimeType.DateOnly:
          siVal.value = SiTranslator.formatSiDateFromBACnet(bndt);
          break;
        case DateTimeType.TimeOnly:
          siVal.value = SiTranslator.formatSiTimeFromBACnet(bndt);
          break;
        case DateTimeType.DateAndTime:
        default:
          siVal.value = SiTranslator.formatSiDateTimeFromBACnet(bndt);
          break;
      }
    }
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.BACnetDateTime) {
      return false; // undefined param or type mismatch!
    }
    const param: BACnetDateTimeParamViewModel = p as BACnetDateTimeParamViewModel;

    // If default value of alignment param does not match, clear the local default value
    if (SiTranslator.formatSiDateTimeFromBACnet(this.defaultBACnetDt) !== SiTranslator.formatSiDateTimeFromBACnet(param.defaultBACnetDt)) {
      this.defaultBACnetDt = undefined;
    }

    return true;
  }

  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;
    if (!p) {
      return;
    }
    this.defaultBACnetDt = WsiTranslator.decodeBACnetDateTime(p.DefaultValue);

    const siVal: DateTimeValue = this.siParam.value as DateTimeValue;
    siVal.wildcardAllowed = this.allowWildcards;
    siVal.specialAllowed = this.allowDayOfWeek;

    this.defaultBACnetDt = WsiTranslator.decodeBACnetDateTime(p.DefaultValue);
    this.resetParamValue();
  }
}
