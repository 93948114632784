@if (item.icon) {
  <i
    class="me-4"
    role="presentation"
    [ngClass]="[item.icon, 'status-' + item.status, item.selected ? item.icon + '-filled' : '']"
    [class.item-disabled]="item.disabled"
  ></i>
}
<div
  class="flex-fill d-flex flex-column"
  [class.text-muted]="item.disabled"
  [attr.aria-disabled]="item.disabled"
>
  <div class="score-title d-flex mb-3">
    {{ item.title | translate }}
    @if (size !== 'large') {
      <div
        class="score si-caption mt-2"
        [class.text-secondary]="!item.disabled"
        [class.text-muted]="item.disabled"
      >
        {{ item.value }}{{ item.unit ?? ' / 100' }}
      </div>
    }
  </div>
  @if (size !== 'small' && item.description) {
    <div class="score-description">
      {{ item.description | translate }}
    </div>
  }
</div>
