import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { EMPTY, expand, map, Observable, of, reduce, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import {
  BuildingBx, BuildingPartBx, CampusBx, CampusPartBx, FloorAreaBx, FloorBx, LocationEntityType,
  LocationsResponse, LocationType, MultiFloorAreaBx, OutsideBx, RoomBx, RoomSegmentBx, SingleLocationResponse
} from './location-proxy.model';

const locationUrl = `${environment.bxPlatform.locationVerticalApiUrl}/v2/partitions`

@Injectable({
  providedIn: 'root'
})
export class LocationProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesLocations, 'LocationProxyService created.');
  }

  public getLocationRoots(partitionId: string): Observable<{ campusRoots: CampusBx[]; buildingRoots: BuildingBx[] }> {
    const campus$ = this.getLocationCampus(partitionId);
    const building$ = this.getLocationBuilding(partitionId);
    return zip(campus$, building$).pipe(
      map((results: [CampusBx[], BuildingBx[]]) => ({ campusRoots: results[0], buildingRoots: results[1] })));
  }

  public getLocationRootsByName(partitionId: string, name: string): Observable<{ campusRoots: CampusBx[]; buildingRoots: BuildingBx[] }> {
    const campus$ = this.getLocationCampusByName(partitionId, name);
    const building$ = this.getLocationBuildingByName(partitionId, name);
    return zip(campus$, building$).pipe(
      map((results: [CampusBx[], BuildingBx[]]) => ({ campusRoots: results[0], buildingRoots: results[1] })));
  }

  public getLocationChildren(partitionId: string, entityId: string, entityType: string): Observable<LocationType[]> {
    let res$: Observable<LocationType[]>;
    switch (entityType) {
      case LocationEntityType.Campus:
        res$ = this.getLocationChildrenOfCampus(partitionId, entityId);
        break;
      case LocationEntityType.CampusPart:
        res$ = this.getLocationChildrenOfCampusPart(partitionId, entityId);
        break;
      case LocationEntityType.Building:
        res$ = this.getLocationChildrenOfBuilding(partitionId, entityId);
        break;
      case LocationEntityType.BuildingPart:
        res$ = this.getLocationChildrenOfBuildingPart(partitionId, entityId);
        break;
      case LocationEntityType.Floor:
        res$ = this.getLocationChildrenOfFloor(partitionId, entityId);
        break;
      case LocationEntityType.FloorArea:
        res$ = this.getLocationChildrenOfFloorArea(partitionId, entityId);
        break;
      case LocationEntityType.MultifloorArea:
        res$ = this.getLocationChildrenOfMultiFloorArea(partitionId, entityId);
        break;
      case LocationEntityType.Room:
        res$ = this.getLocationChildrenOfRoom(partitionId, entityId);
        break;
      case LocationEntityType.RoomSegment:
        res$ = of([]);
        break;
      default:
        res$ = of([]);
        break;
    }
    return res$;
    // return res$.pipe(map((result: LocationChildrenResponse) => this.removeParent(result, entityId)));
  }

  // private removeParent(result: LocationChildrenResponse, parentEntityId: string): LocationChildrenResponse {
  //   const idx = result.data.findIndex(location => location.id === parentEntityId);
  //   if (idx !== -1) {
  //     result.data.splice(idx, 1);
  //   }
  //   return result;
  // }

  public getLocationCampus(partitionId: string): Observable<CampusBx[]> {
    return this.getLocationEntity(partitionId, LocationEntityType.Campus) as Observable<CampusBx[]>;
  }

  public getLocationCampusByName(partitionId: string, name: string): Observable<CampusBx[]> {
    return this.getLocationEntity(partitionId, LocationEntityType.Campus, name) as Observable<CampusBx[]>;
  }

  // public getLocationCampusPart(partitionId: string): Observable<{ data: Array<CampusPart> }> {
  //   return this.getLocationEntity(partitionId, LocationEntityType.CampusPart);
  // }

  public getLocationBuilding(partitionId: string): Observable<BuildingBx[]> {
    return this.getLocationEntity(partitionId, LocationEntityType.Building) as Observable<BuildingBx[]>;
  }

  public getLocationBuildingByName(partitionId: string, name: string): Observable<BuildingBx[]> {
    return this.getLocationEntity(partitionId, LocationEntityType.Building, name) as Observable<BuildingBx[]>;
  }

  public getLocationById(partitionId: string, locationId: string): Observable<LocationType | undefined> {
    this.traceService.debug(TraceModules.bxServicesLocations, `LocationProxyService.getLocationById() called: locationId: ${locationId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${locationUrl}/${partitionId}/locations/${locationId}`;
    let params: HttpParams = new HttpParams();
    params = params.set('fields', 'hasAssets');

    return this.httpClient.get<SingleLocationResponse>(url, { headers, params, observe: 'response' }).pipe(
      map(response => response.body.data),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getLocationById()')));
  }

  public getLocationChildrenOfParent(partitionId: string, parentId: string): Observable<LocationType[]> {
    // Same functionality as previous commented function, but different HTTP call which supports returning equipment
    return this.getLocationChildrenOfParentInt(partitionId, parentId);
  }

  public getLocationChildrenOfParentByName(partitionId: string, parentId: string, childName: string): Observable<LocationType[]> {
    // Same functionality as previous commented function, but different HTTP call which supports returning equipment
    return this.getLocationChildrenOfParentInt(partitionId, parentId, childName);
  }

  private getLocationEntity(partitionId: string, locationType: LocationEntityType, name?: string): Observable<LocationType[]> {
    this.traceService.debug(TraceModules.bxServicesLocations,
      `LocationProxyService.getLocationEntity() called: partitionId=${partitionId}; type: ${locationType}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${locationUrl}/${partitionId}/locations`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[size]', 1000);
    params = params.set('filter[type]', locationType);
    params = params.set('fields', 'hasAssets');
    if (name !== undefined) {
      params = params.set('filter[label]', name);
    }

    return this.httpClient.get<LocationsResponse>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.links?.next) {
          params = params.set('page[number]', response.body.meta.page.number + 1);
          return this.httpClient.get<LocationsResponse>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => response.body.data ?? []),
      reduce((accumulator, current) => [...accumulator, ...current]),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getLocationEntity()')));
  }

  private getLocationChildrenOfCampus(partitionId: string, entityId: string): Observable<CampusPartBx[] | BuildingBx[] | OutsideBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<CampusPartBx[] | BuildingBx[] | OutsideBx[]>;
  }

  private getLocationChildrenOfCampusPart(partitionId: string, entityId: string): Observable<BuildingBx[] | OutsideBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<BuildingBx[] | OutsideBx[]>;
  }

  private getLocationChildrenOfBuilding(partitionId: string, entityId: string): Observable<BuildingPartBx[] | FloorBx[] | OutsideBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<BuildingPartBx[] | FloorBx[] | OutsideBx[]>;
  }

  private getLocationChildrenOfBuildingPart(partitionId: string, entityId: string): Observable<FloorBx[] | OutsideBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<FloorBx[] | OutsideBx[]>;
  }

  private getLocationChildrenOfFloor(partitionId: string, entityId: string): Observable<FloorAreaBx[] | MultiFloorAreaBx[] | RoomBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<FloorAreaBx[] | MultiFloorAreaBx[] | RoomBx[]>;
  }

  private getLocationChildrenOfFloorArea(partitionId: string, entityId: string): Observable<RoomBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<RoomBx[]>;
  }

  private getLocationChildrenOfMultiFloorArea(partitionId: string, entityId: string): Observable<RoomBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<RoomBx[]>;
  }

  private getLocationChildrenOfRoom(partitionId: string, entityId: string): Observable<RoomSegmentBx[]> {
    return this.getLocationChildrenOfParent(partitionId, entityId) as Observable<RoomSegmentBx[]>;
  }

  private getLocationChildrenOfParentInt(partitionId: string, parentId: string, childName?: string): Observable<LocationType[]> {
    // Same functionality as previous commented function, but different HTTP call which supports returning equipment
    this.traceService.debug(TraceModules.bxServicesLocations, `LocationProxyService.getLocationChildrenOfParentInt() called: parentId: ${parentId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${locationUrl}/${partitionId}/locations`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[size]', 1000);
    params = params.set('filter[isPartOf.data.id]', parentId);
    params = params.set('fields', 'hasAssets');
    if (childName !== undefined) {
      params = params.set('filter[label]', childName);
    }

    return this.httpClient.get<LocationsResponse>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.links?.next) {
          params = params.set('page[number]', response.body.meta.page.number + 1);
          return this.httpClient.get<LocationsResponse>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => response.body.data ?? []),
      reduce((accumulator, current) => [...accumulator, ...current]),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getLocationChildrenOfParentInt()')));
  }

  private getLocationChildrenOfParentIntPaged(partitionId: string, parentId: string, childName?: string): Observable<LocationsResponse> {
    // Same functionality as previous commented function, but different HTTP call which supports returning equipment
    this.traceService.debug(TraceModules.bxServicesLocations, `LocationProxyService.getLocationChildrenOfParentInt() called: parentId: ${parentId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${locationUrl}/${partitionId}/locations`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[size]', 1000);
    params = params.set('filter[isPartOf.data.id]', parentId);
    params = params.set('fields', 'hasAssets');
    if (childName !== undefined) {
      params = params.set('filter[label]', childName);
    }

    return this.httpClient.get(url, { headers, params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getLocationChildrenOfParentInt()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getLocationChildrenOfParentInt()')));
  }
}
