import { Injectable } from '@angular/core';
import { TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';

import { EnumLabel, PointAttributes } from '../../bx-services/point/point-proxy.model';

export interface TextGroupBx {
  enum: { [key: string]: EnumLabel };
  id: string;
}

export const textGroupIdStatusFlags = 'TxG_Global_Status_Flags'; // global text group in DCC
export const textGroupIdCurrentPriority = 'TxG_BA_PXCurrentPriority'; // local text group in DCC

@Injectable({
  providedIn: 'root'
})
export class TextGroupBxService {
  private readonly textGroupPerPoint: Map<string, TextGroupBx> = new Map<string, TextGroupBx>();
  private readonly textGroupPerId: Map<string, TextGroupBx> = new Map<string, TextGroupBx>();
  private readonly textGroupPerIdGlobal: Map<string, TextGroupBx> = new Map<string, TextGroupBx>();

  public constructor(private readonly traceService: TraceService) {
    this.createGlobalTextGroupForStatusFlags();
    this.createGlobalTextGroupForCurrentPriority();
    this.traceService.info(TraceModules.tables, 'TextGroupBxService created.');
  }

  public getGlobalTextGroup(txgId: string): TextGroupBx | undefined {
    return this.textGroupPerIdGlobal.has(txgId) ? this.textGroupPerIdGlobal.get(txgId) : undefined;
  }

  public getTextGroup(pointId: string): TextGroupBx | undefined {
    return this.textGroupPerPoint.has(pointId) ? this.textGroupPerPoint.get(pointId) : undefined;
  }

  public getTextGroupPerName(name: string): TextGroupBx | undefined {
    return this.textGroupPerId.has(name) ? this.textGroupPerId.get(name) : undefined;
  }

  public createTextGroup(pointId: string, pointAttributes: PointAttributes, overwriteExisting: boolean = false): TextGroupBx {
    if (this.textGroupPerPoint.has(pointId) && (overwriteExisting === false)) {
      return this.textGroupPerPoint.get(pointId);
    } else {
      return this.createTextGroupInt(pointId, pointAttributes);
    }
  }

  public resolveRawValue(pointId: string, rawValue: string): string | undefined {
    if (this.textGroupPerPoint.has(pointId)) {
      if (this.textGroupPerPoint.get(pointId).enum[rawValue] !== undefined) {
        return this.textGroupPerPoint.get(pointId).enum[rawValue].label;
      } else {
        // TODO: discuss with data producer, seems to be an error!
        this.traceService.error(TraceModules.tables, `TextGroupBxService.resolveRawValue(): Value cannot be resolved; Enumeration does not contain the rawValue!
        rawValue: ${rawValue}
        enum: ${this.traceEnum(pointId)}`);
      }

    } else {
      return undefined;
    }
  }

  private createGlobalTextGroupForStatusFlags(): TextGroupBx {
    const items: Record<string, EnumLabel> = {
      /* eslint-disable @typescript-eslint/naming-convention */
      '0': { label: 'In alarm' },
      '1': { label: 'Fault' },
      '2': { label: 'Overridden' },
      '3': { label: 'Out of service' }
      /* eslint-enable @typescript-eslint/naming-convention */
    }
    const txg: TextGroupBx = { enum: items, id: textGroupIdStatusFlags };
    this.textGroupPerIdGlobal.set(txg.id, txg);
    return txg;
  }

  private createGlobalTextGroupForCurrentPriority(): TextGroupBx {
    const items: Record<string, EnumLabel> = {
    /* eslint-disable @typescript-eslint/naming-convention */
      '0': { label: '00 - None' },
      '1': { label: '01 - Life Safety' },
      '2': { label: '02 - Life Safety' },
      '3': { label: '03 - Priority 3' },
      '4': { label: '04 - Critical Equipment Ctl' },
      '5': { label: '05 - Critical Equipment Ctl' },
      '6': { label: '06 - Minimum On/off Time' },
      '7': { label: '07 - Manual Operation' },
      '8': { label: '08 - Manual Operation' },
      '9': { label: '09 - Priority 9' },
      '10': { label: '10 - Priority 10' },
      '11': { label: '11 - Priority 11' },
      '12': { label: '12 - Priority 12' },
      '13': { label: '13 - Priority 13' },
      '14': { label: '14 - Specific Command Control' },
      '15': { label: '15 - Program Control' },
      '16': { label: '16 - System Control' }
    /* eslint-enable @typescript-eslint/naming-convention */
    }
    const txg: TextGroupBx = { enum: items, id: textGroupIdCurrentPriority };
    this.textGroupPerIdGlobal.set(txg.id, txg);
    return txg;
  }

  private createTextGroupInt(pointId: string, pointAttributes: PointAttributes): TextGroupBx | undefined {
    if (pointAttributes.enum !== undefined) {
      const txg: TextGroupBx = { enum: pointAttributes.enum, id: `TxG_Point_${pointId}` };
      this.textGroupPerPoint.set(pointId, txg);
      this.textGroupPerId.set(txg.id, txg);
      return txg;
    } else {
      return undefined;
    }
  }

  private traceEnum(pointId: string): string {
    if (this.textGroupPerPoint.has(pointId)) {
      const enumEntries = Object.keys(this.textGroupPerPoint.get(pointId).enum).map(key => `${key}=${this.textGroupPerPoint.get(pointId).enum[key].label}`);
      return enumEntries.join('; ');
    } else {
      return 'No enum defined!';
    }
  }
}
