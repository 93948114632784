@if (props.multi) {
  <si-select
    class="form-control"
    multi
    [options]="props.optionsList"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [readonly]="props.readonly || false"
  />
} @else {
  <si-select
    class="form-control"
    [options]="props.optionsList"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [readonly]="props.readonly || false"
  />
}
