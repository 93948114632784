export enum GmsDataType {
  None,
  PvssChar,
  PvssUint,
  PvssInt,
  PvssFloat,
  PvssBool,
  PvssBit32,
  PvssString,
  PvssTime,
  PvssDpId,
  PvssLangText,
  PvssBlob,
  GmsBool,
  GmsInt,
  GmsUint,
  GmsReal,
  GmsEnum,
  GmsBitString,
  GmsDateTime,
  GmsApplSpecific,
  GmsAny,
  GmsComplex,
  GmsDuration,
  PvssUint64,
  PvssInt64,
  PvssBit64,
  GmsInt64,
  GmsUint64,
  GmsBitString64
}
