import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import {
  getDatepickerFormat,
  SiDateInputDirective,
  SiDatepickerComponent
} from '@simpl/element-ng';
import { SiTranslateService } from '@simpl/element-ng/translate';
import { DateTimeValue } from '@simpl/element-value-types';
import { clone } from '@simpl/object-browser-ng/common';

import {
  dateString,
  dateToIsoString,
  isWeekSpecified,
  parseBacnetDateTimeFormat
} from '../../helpers/date';
import { timeString } from '../../helpers/time';
import { DateFormat, TimeFormat } from '../../interfaces/date-time-formats';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { dayOptions, monthOptions } from '../si-bacnet-datetime-picker/models/date-time';
import { WildcardBACnet } from '../si-bacnet-datetime-picker/models/enums';
import { SiBACnetDateTimePickerComponent } from '../si-bacnet-datetime-picker/si-bacnet-datetime-picker.component';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-datetime-property',
  templateUrl: './si-datetime-property.component.html',
  styleUrls: ['./si-datetime-property.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    SiBACnetDateTimePickerComponent,
    SiDateInputDirective,
    SiDatepickerComponent,
    SiPropertyPopoverComponent
  ]
})
export class SiDatetimePropertyComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) property!: Property<DateTimeValue>;
  @Input() valueState: ValueState = 'none';
  @Input() dateFormat?: DateFormat;
  @Input() timeFormat?: TimeFormat;
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<DateTimeValue>>();

  @ViewChild('form') form!: NgForm;
  @ViewChild('popover', { static: true }) popover!: SiPropertyPopoverComponent;
  @ViewChild('inputBox') inputBox!: ElementRef;

  isValid = true;
  formatForPicker!: string;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  // note this is duplicated from popover since the si-bacnet-datetime-picker expect the full DateTimeValue
  private editValue?: DateTimeValue;
  private delimiter = '-';
  private hasMeridian = false;
  private selectedDt!: Date;
  private weekNum!: string;
  private months: { id: number; name: string }[] = [];
  private days: { id: number; name: string }[] = [];

  private elementRef = inject(ElementRef);
  private locale = inject(LOCALE_ID);
  private translateService = inject(SiTranslateService);

  ngOnInit(): void {
    if (this.isBacnetDatepicker()) {
      if (!this.dateFormat) {
        this.dateFormat = 'dd.mm.yyyy';
      }
      if (!this.timeFormat) {
        this.timeFormat = 'hh:mm:ss';
      }
    } else {
      if (!this.dateFormat || !this.timeFormat) {
        this.hasMeridian = getDatepickerFormat(this.locale, { showTime: true }).includes('a');
        this.formatForPicker = '';
      } else {
        // If the time is 12h, keep the `hh` as is since it represent 12h time with range 00-12
        // If the time is 24h, replace `hh` with `HH` representing the range 00-24 as per doc:
        // https://angular.io/api/common/DatePipe#custom-format-options
        const timeFormat = this.hasHours12()
          ? this.timeFormat.replace('tt', 'a')
          : this.timeFormat.replace('hh', 'HH');
        this.formatForPicker =
          this.dateFormat.replace('mm', 'MM') + ' ' + this.delimiter + ' ' + timeFormat;
      }
    }
    if (this.isBacnetDatepicker()) {
      this.months.push(monthOptions[WildcardBACnet.oddMonths]);
      this.months.push(monthOptions[WildcardBACnet.evenMonths]);

      this.months.forEach(element => {
        element.name = this.translateService.translateSync(element.name);
      });

      this.days.push(dayOptions[WildcardBACnet.oddDays]);
      this.days.push(dayOptions[WildcardBACnet.evenDays]);
      this.days.push(dayOptions[WildcardBACnet.lastDayOfMonth]);

      this.days.forEach(element => {
        element.name = this.translateService.translateSync(element.name);
      });
    }
  }

  get selectedDate(): Date {
    return this.selectedDt;
  }

  set selectedDate(val: Date) {
    if (val) {
      this.selectedDt = val;

      if (this.editValue) {
        this.editValue.value = dateToIsoString(val) + 'T' + val.toTimeString().split(' ')[0];
      }
      // When default text is specified, form status is not updated on selecting the date,
      // since the default text view is fixed. To overcome this mauanlly set the isValid flag here
      if (this.property.defaultText) {
        this.isValid = true;
      }
    }
  }

  get modelValue(): any {
    return this.popover.isActive ? this.editValue : this.property?.value;
  }

  ngAfterViewInit(): void {
    this.form.statusChanges!.subscribe(() => {
      // setTimeout to work around Angular bug 23657
      setTimeout(() => (this.isValid = !!this.form.valid));
    });

    const propDate = this.property.value?.value;
    if (propDate) {
      const parsedDate = parseBacnetDateTimeFormat(propDate);
      if (parsedDate) {
        if (!this.isBacnetDatepicker()) {
          setTimeout(() => (this.selectedDate = new Date(parsedDate)));
        }
        this.property.value.value = parsedDate;
      }

      const date = propDate.split('T')[0];
      if (date) {
        this.weekNum = date.split(' ')[1];
      }
    }
  }

  isBacnetDatepicker(): boolean {
    return !!this.property.value.wildcardAllowed || !!this.property.value.specialAllowed;
  }

  get displayValue(): string {
    const [date, time] = (this.modelValue.value ?? '').split('T');
    return `${dateString(date, this.dateFormat!, this.months, this.days)} ${
      this.delimiter
    } ${timeString(time, this.timeFormat!)}`;
  }

  hasHours12(): boolean {
    /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
    return (this.timeFormat?.includes('tt') || this.hasMeridian) ?? false;
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
        setTimeout(() =>
          this.elementRef.nativeElement.querySelector('si-bacnet-datetime-picker input')?.focus()
        );
        this.editValue = clone(this.property.value);
        this.parseEditValue();
        this.setSelectedDateAndValidStatus();
        break;
      case 'cancel':
      case 'open':
        this.editValue = clone(this.property.value);
        this.parseEditValue();
        this.setSelectedDateAndValidStatus();
        break;
      case 'submit':
        this.property.value.value = this.editValue!.value;
        this.editValue = undefined;
        this.appendWeek();
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.appendWeek();
        this.submitted.emit(this.property);
        break;
    }
  }

  private appendWeek(): void {
    if (this.weekNum) {
      const propDate = this.property.value?.value;
      if (!propDate) {
        return;
      }

      const [date, time] = propDate.split('T');
      if (date && time) {
        if (isWeekSpecified(date)) {
          return;
        }
        const updatedWeek = new Date(propDate).getDay();
        if (!isNaN(updatedWeek)) {
          this.weekNum = updatedWeek.toString();
        }
        this.property.value.value = date + ' ' + this.weekNum + 'T' + time;
      }
    }
  }

  private setSelectedDateAndValidStatus(): void {
    if (!this.isBacnetDatepicker()) {
      if (this.editValue?.value) {
        this.selectedDate = new Date(this.editValue.value);
      } else if (!this.property.value?.optional) {
        this.isValid = false;
      }
    }
  }

  private parseEditValue(): void {
    if (this.editValue?.value) {
      const parsedDate = parseBacnetDateTimeFormat(this.editValue.value);
      if (parsedDate) {
        this.editValue.value = parsedDate;
      }
    }
  }
}
