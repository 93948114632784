<div #sidePanel class="side-panel elevation-1" tabindex="-1">
  <div class="inner" [class.d-none]="showTempContent">
    <ng-content select="si-side-panel-content, element-side-panel-content" />
    <ng-container #portalOutlet cdkPortalOutlet />
  </div>
  <div class="inner" [class.d-none]="!showTempContent">
    <ng-container #tmpPortalOutlet cdkPortalOutlet />
  </div>
</div>
<div #content class="main-content si-layout-inner">
  <ng-content />
</div>
