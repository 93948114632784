import { BrowserObject, CnsHelperService, CommandSubscriptionServiceBase, Designation,
  ExecuteCommandServiceBase, PropertyServiceBase, ReadCommandServiceBase,
  ValueSubscription2ServiceBase } from '@gms-flex/services';
import { SettingsServiceBase } from '@gms-flex/services-common';
import { ValidationDialogService } from '@gms-flex/snapin-common';

/**
 * State of the SNI selection context.
 */
export enum ContextState {

  // Context is empty (nothing selected)
  Empty = 0,

  // Context represents a single object selection
  SingleObject,

  // Context represents a multiple object selection in which all the objects are the
  // the same in terms of both object-model and function assignment
  MultiObjectSame,

  // Context represents a multiple object selection in which all the objects are the
  // the same in terms of object-model only (functions differ)
  MultiObjectSameOM,

  // Context represents a multiple object selection in which not all the objects are the
  // the same in terms of object-model
  MultiObjectDifferent

}

/**
 * Properties describing the context surrounding the current SNI view-model.
 *
 * An object of this shape is created each time a new SNI view-model is created and passed down to
 * all child view-model objects.  It is used to pass any information that may be needed/useful for
 * the full creation of the view-model.
 */
export interface ViewModelContext {

  /**
   * User's locale
   */
  locale: string;

  /**
   * Selection context state (empty, single, multiple, etc.)
   */
  state: ContextState;

  /**
   * List of browser objects passed to the SNI in the current selection.
   */
  browserObjList: BrowserObject[];

  /**
   * Client id registered with the value subscription service
   */
  valueSubscriptionReg: string;

  /**
   * Client id registered with the cmd subscription service
   */
  cmdSubscriptionReg: string;
}

/**
 * Gms-services used in VM.
 */
export interface ServiceStore {

  propertyService: PropertyServiceBase;

  settingsService: SettingsServiceBase;

  valueSubscriptionService: ValueSubscription2ServiceBase;

  readCommandService: ReadCommandServiceBase;

  cmdSubscriptionService: CommandSubscriptionServiceBase;

  execCommandService: ExecuteCommandServiceBase;

  cnsHelperService: CnsHelperService;

  validationDialogService: ValidationDialogService;

}
