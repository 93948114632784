import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppSettingsService } from './app-settings/app-settings.service';
import { ProductService } from './product/product.service';

export const initAppSettings = (appSettingsService: AppSettingsService): any => {
  return (): any => appSettingsService.getAppSettings().toPromise();
};

export const initAppProductSettings = (productService: ProductService): any => {
  return (): any => productService.getProductSettings().toPromise();
};
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: APP_INITIALIZER, multi: true, useFactory: initAppSettings, deps: [AppSettingsService] },
    { provide: APP_INITIALIZER, multi: true, useFactory: initAppProductSettings, deps: [ProductService] }]
})

export class HfwServicesCommonModule {}
