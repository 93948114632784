import { ParameterNumericViewModel } from '../processor/command-view-model/gms-parameter-numeric-vm';
import { ParameterViewModelBase } from '../processor/command-view-model/gms-parameter-vm.base';
import { ParameterType } from '../processor/command/parameters/gms-base-parameter';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { Constants } from '../utilities/constants';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsCommandControl } from './gms-commandcontrol';

export class GmsCommandControlNumeric extends GmsCommandControl {

  private static readonly DEFAULT_COMMANDPRECISION: number = Number.MIN_SAFE_INTEGER;
  private _evaluationCommandPrecision: Evaluation;

  /** Gets or sets a number representing the number of decimal places of analog values
   */
  private _commandPrecision: number = GmsCommandControlNumeric.DEFAULT_COMMANDPRECISION;
  public get CommandPrecision(): number {
    return this._commandPrecision;
  }
  public set CommandPrecision(value: number) {
    if (value !== GmsCommandControlNumeric.DEFAULT_COMMANDPRECISION) {
      value = Utility.ApplyRange(value, -99, 99);
    }
    this._commandPrecision = value;
  }

  public get FormattedValue(): string {
    if (this.ParameterVM === null) {
      return Constants.GENERAL_ERROR;
    }
    let value: string = this.IsControlInEditMode ? this.ParameterVM.FormattedValue : this.ParameterVM.Value;
    if (!this.IsControlInEditMode && value !== Constants.GENERAL_ERROR) {
      if (this.ParameterVM.ParameterType === ParameterType.Numeric) {
        // not in IsControlInEditMode - value in en-US
        const num: number = FormatHelper.getNumberfromString(value, FormatHelper.enUS);
        if (!Number.isNaN(num)) {
          value = FormatHelper.NumberToString(num,
            this.locale,
            this.defaultLocale,
            this.isFloatType ? this.GetAnimatedCommandPrecision() : 0);
        }
      } else {
        const numLong: Long = FormatHelper.parseLongValue(value, this.ParameterVM.ParameterType === ParameterType.NumericUInt64);
        if (numLong !== undefined) {
          value = FormatHelper.formatNumber(numLong, this.locale);
        }
      }
    }
    return value;
  }

  public set FormattedValue(value: string) {
    if (value !== undefined && this.ParameterVM !== null && this.ParameterVM.FormattedValue !== value) {
      this.ParameterVM.FormattedValue = value;
      this.NotifyPropertyChanged('FormattedValue');
    }
  }
  /*
     * this property is utilized by the numeric directive to validate input sign
     */
  public get isUnsignedType(): boolean {
    return this.ParameterVM !== null &&
            this.ParameterVM.ParameterType === ParameterType.NumericUInt64 ||
            (this.ParameterVM.ParameterType === ParameterType.NumericInt64 && this.ParameterVM.minValueLong !== undefined && this.ParameterVM.minValueLong.greaterThanOrEqual(0)) ||
            (!Number.isNaN(this.ParameterVM.minValue) && Number(this.ParameterVM.minValue) >= 0);

  }

  public get MinValue(): number {
    return this.ParameterVM !== null ? this.ParameterVM.minValue : null;
  }

  public get MaxValue(): number {
    return this.ParameterVM !== null ? this.ParameterVM.maxValue : null;
  }

  public get MaxValueLong(): Long {
    return this.ParameterVM !== null ? this.ParameterVM.maxValueLong : null;
  }

  public get MinValueLong(): Long {
    return this.ParameterVM !== null ? this.ParameterVM.minValueLong : null;
  }

  public get ParameterPrecision(): number {
    return this.ParameterVM !== null ? this.ParameterVM.precision : null;
  }

  constructor() {
    super(GmsCommandControlType.Numeric);
  }

  public Deserialize(node: Node): void {
    // CommandPrecision
    const result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.CommandPrecision);
    if (result !== undefined) {
      this.CommandPrecision = FormatHelper.StringToNumber(result);
    }

    super.Deserialize(node);
  }

  public CopyFrom(element: GmsCommandControlNumeric): void {
    super.CopyFrom(element);

    // CommandPrecision
    this.CommandPrecision = element.CommandPrecision;
  }

  public Destroy(): void {

    this._evaluationCommandPrecision = undefined;

    super.Destroy();
  }

  public GetParameterValue(): string {
    if (this.ParameterVM === null) {
      return Constants.GENERAL_ERROR;
    }
    if (this.IsControlInEditMode) {
      return this.ParameterVM !== null ? this.ParameterVM.FormattedValue : Constants.GENERAL_ERROR;
    } else {
      return this.ParameterVM !== null ? this.ParameterVM.Value : Constants.GENERAL_ERROR;
    }
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }
  public onPropertyChanged(propertyName: string): void {
    if (propertyName !== undefined && propertyName.length > 0) {
      this.NotifyPropertyChanged(propertyName);
    }
  }

  protected ValidateControlType(parameterVM: ParameterViewModelBase): boolean {

    let isValid = false;

    if (parameterVM !== null) {
      if (parameterVM.ParameterType === ParameterType.Numeric ||
                parameterVM.ParameterType === ParameterType.NumericUInt64 ||
                parameterVM.ParameterType === ParameterType.NumericInt64 ||
                parameterVM.ParameterType === ParameterType.MultiState ||
                parameterVM.ParameterType === ParameterType.Priority) {

        isValid = true;
        if (parameterVM.ParameterType === ParameterType.MultiState ||
                    parameterVM.ParameterType === ParameterType.Priority) {
          parameterVM.UpdateToNumericDefaults();
        }
      }
    }
    return isValid;
  }

  protected CreateDesignModeParameterViewModel(): ParameterViewModelBase {
    return new ParameterNumericViewModel(null);
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'CommandPrecision':
        this._evaluationCommandPrecision = evaluation;
        this.UpdatePropertyCommandPrecision(evaluation);
        break;

      default:
        break;
    }
  }

  protected UpdateParameterVM(): void {
    super.UpdateParameterVM();

    this.UpdatePropertyCommandPrecision(null);
  }

  protected UpdatePropertyCommandPrecision(evaluation: Evaluation): void {
    if (evaluation !== null) {
      this._evaluationCommandPrecision = evaluation;
    }
    this.UpdateCommandParameterPrecision();
  }

  protected GetAnimatedCommandPrecision(): number {
    if (this.isLongTypeParameter) {
      return 0;
    }
    let value: number = Evaluation.GetValue2(this._evaluationCommandPrecision, this.CommandPrecision, PropertyType.Number);
    if (value === GmsCommandControlNumeric.DEFAULT_COMMANDPRECISION) {
      value = this.ParameterVM !== null ? this.ParameterVM.modelPrecision : ParameterViewModelBase.defaultPrecision;
    }
    return value;
  }

  protected UpdateCommandParameterPrecision(): void {
    if (this.ParameterVM !== null) {
      const result: number = this.GetAnimatedCommandPrecision();
      if (result !== this.ParameterVM.precision) {
        this.ParameterVM.precision = result;
        this.ParameterVM.UpdateFormattedValue();
        this.NotifyPropertyChanged('ParameterPrecision');
      }
    }
  }
}
