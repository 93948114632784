import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  SiEmptyStateComponent,
  SiResponsiveContainerDirective,
  SiTranslateModule
} from '@simpl/element-ng';
import {
  AnyProperty,
  CommandEvent,
  PropertyInfoData,
  SiPropertyListComponent
} from '@simpl/object-browser-ng/property-viewer';

@Component({
  selector: 'si-command-list',
  templateUrl: './si-command-list.component.html',
  styleUrls: ['./si-command-list.component.scss'],
  standalone: true,
  imports: [
    SiEmptyStateComponent,
    SiPropertyListComponent,
    SiResponsiveContainerDirective,
    SiTranslateModule
  ]
})
export class SiCommandListComponent {
  @Input() objectId!: string;
  @Input() propertyList!: AnyProperty[];
  @Input() isSceneView = false;
  @Input() readOnly = false;

  @Output() readonly addingDataPoints = new EventEmitter<void>();
  @Output() readonly propertyChanged = new EventEmitter<AnyProperty>();
  @Output() readonly command = new EventEmitter<CommandEvent>();

  @ViewChild('propertyListViewer') propertyListViewer!: SiPropertyListComponent;

  public displayAboutPopover(popoverContent: PropertyInfoData): void {
    this.propertyListViewer.showPropertyInfoPopover(popoverContent);
  }
}
