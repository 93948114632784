import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  QueryList
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { SiPasswordToggleComponent } from '../password-toggle/si-password-toggle.component';

const RE_UPPER_CASE = /[A-Z]/;
const RE_LOWER_CASE = /[a-z]/;
const RE_DIGITS = /[0-9]/;
const RE_SPECIAL_CHARS = /[\x21-\x2F|\x3A-\x40|\x5B-\x60]/;
const RE_WHITESPACES = /\s/;

export interface PasswordPolicy {
  /**
   * Define minimal number of characters.
   */
  minLength: number;
  /**
   * Define if uppercase characters are required in password.
   */
  uppercase: boolean;
  /**
   * Define if lowercase characters are required in password.
   */
  lowercase: boolean;
  /**
   * Define if digits are required in password.
   */
  digits: boolean;
  /**
   * Define if special characters are required in password.
   */
  special: boolean;
}

@Directive({
  selector: '[siPasswordStrength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SiPasswordStrengthDirective, multi: true }],
  standalone: true
})
export class SiPasswordStrengthDirective implements OnChanges, Validator {
  private maxStrength = 1;

  @HostBinding('class.no-validation') protected noValidation = false;

  /**
   * Define Siemens password strength.
   */
  @Input() siPasswordStrength: PasswordPolicy = {
    minLength: 8,
    uppercase: true,
    lowercase: true,
    digits: true,
    special: true
  };

  /**
   * Output callback event called when the password changes. The number
   * indicated the number of rules which still can be met. (`-2` --> 2 rules are
   * still unmet, `0` --> all met)
   */
  @Output() readonly passwordStrengthChanged = new EventEmitter<number>();

  ngOnChanges(): void {
    this.maxStrength = 1;
    this.maxStrength += this.siPasswordStrength.uppercase ? 1 : 0;
    this.maxStrength += this.siPasswordStrength.lowercase ? 1 : 0;
    this.maxStrength += this.siPasswordStrength.digits ? 1 : 0;
    this.maxStrength += this.siPasswordStrength.special ? 1 : 0;
  }

  /** @internal */
  validate(control: AbstractControl): ValidationErrors {
    if (this.getStrength(control.value) >= this.maxStrength) {
      return {};
    }
    return { siPasswordStrength: true };
  }

  private getStrength(password: string): number {
    let strength = 0;
    if (password && password !== '') {
      // Strength check
      strength += password.length >= this.siPasswordStrength.minLength ? 1 : 0;
      strength += this.siPasswordStrength.uppercase && password.match(RE_UPPER_CASE) ? 1 : 0;
      strength += this.siPasswordStrength.lowercase && password.match(RE_LOWER_CASE) ? 1 : 0;
      strength += this.siPasswordStrength.digits && password.match(RE_DIGITS) ? 1 : 0;
      strength += this.siPasswordStrength.special && password.match(RE_SPECIAL_CHARS) ? 1 : 0;
      // Hard limit check
      strength = password.match(RE_WHITESPACES) ? 0 : strength;
      this.noValidation = true;
      // Notify listeners
      this.passwordStrengthChanged.emit(strength - this.maxStrength);
      return strength;
    }
    this.noValidation = false;
    this.passwordStrengthChanged.emit();
    return strength;
  }
}

@Component({
  selector: 'si-password-strength',
  template: `
    <si-password-toggle [showVisibilityIcon]="showVisibilityIcon" (typeChange)="toggle($event)">
      <ng-content />
    </si-password-toggle>
  `,
  styleUrl: './si-password-strength.component.scss',
  standalone: true,
  imports: [SiPasswordToggleComponent]
})
export class SiPasswordStrengthComponent implements AfterViewInit {
  /** Whether to show the visibility toggle icon. */
  @Input()
  showVisibilityIcon = true;

  @ContentChildren(SiPasswordStrengthDirective)
  private passwordStrengthDirective!: QueryList<SiPasswordStrengthDirective>;
  private cdRef = inject(ChangeDetectorRef);
  private elRef = inject(ElementRef);

  @HostBinding('class.bad') protected bad = false;
  @HostBinding('class.weak') protected weak = false;
  @HostBinding('class.medium') protected medium = false;
  @HostBinding('class.good') protected good = false;
  @HostBinding('class.strong') protected strong = false;

  protected toggle(type: string): void {
    const input = this.elRef.nativeElement.querySelector('input');
    if (input) {
      input.type = type;
    }
  }

  ngAfterViewInit(): void {
    this.passwordStrengthDirective.forEach(directive => {
      directive.passwordStrengthChanged.subscribe((strength: number) => {
        this.strong = strength === 0;
        this.good = strength === -1;
        this.medium = strength === -2;
        this.weak = strength === -3;
        this.bad = strength === -4;
        this.cdRef.markForCheck();
      });
    });
  }
}
