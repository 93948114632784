import { AlarmState } from '../../types/datapoint/gms-alarm-state';

export class AlarmInfo {
  private _id: number;
  public get Id(): number {
    return this._id;
  }
  public set Id(value: number) {

    if (this._id !== value) {
      this._id = value;
    }
  }

  private _srcDesignationEvents: string;
  public get SrcDesignationEvents(): string {
    return this._srcDesignationEvents;
  }
  public set SrcDesignationEvents(value: string) {
    if (this._srcDesignationEvents !== value) {
      this._srcDesignationEvents = value;
    }
  }

  private _designation: string;
  public get Designation(): string {
    return this._designation;
  }
  public set Designation(value: string) {

    if (this._designation !== value) {
      this._designation = value;
    }
  }

  private _state: AlarmState;
  public get State(): AlarmState {
    return this._state;
  }
  public set State(value: AlarmState) {

    if (this._state !== value) {
      this._state = value;
    }
  }

  private _isActive = false;
  public get IsActive(): boolean {
    return this._isActive;
  }
  public set IsActive(value: boolean) {

    if (this._isActive !== value) {
      this._isActive = value;
    }
  }

  private _disciplineColor = 'rgb(255, 255, 255)';
  public get DisciplineColor(): string {
    return this._disciplineColor;
  }
  public set DisciplineColor(value: string) {

    if (this._disciplineColor !== value) {
      this._disciplineColor = value;
    }
  }

  constructor(designation: string, state: AlarmState) {
    this._designation = designation;
    this._state = state;
  }
}
