import { GmsElementType } from '../types/gms-element-types';
import { GmsElement } from './gms-element';
import { GmsPath } from './gms-path';

export class GmsLine extends GmsPath {

  constructor() {
    super(GmsElementType.Line);
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public UpdateAdornerDimensions(): void {
    if (!!this.BoundingRectDesign) {
      const scaleWidth: number = this.BoundingRectDesign.Width > 0 ? Math.abs(this.Width / this.BoundingRectDesign.Width) : 1;
      const scaleHeight: number = this.BoundingRectDesign.Height > 0 ? Math.abs(this.Height / this.BoundingRectDesign.Height) : 1;
      this.AdornerWidth = this.BoundingRectDesign.Width * scaleWidth * this.ScaleX + (2 * this.StrokeWidth);
      this.AdornerHeight = this.BoundingRectDesign.Height * scaleHeight * this.ScaleY + (2 * this.StrokeWidth);
      this.AdornerX = -this.StrokeWidth;
      this.AdornerY = -this.StrokeWidth;
    }
  }

  public Deserialize(node: Node): void {
    super.Deserialize(node);
  }

  public CopyFrom(element: GmsLine): void {
    super.CopyFrom(element);
  }
}
