import { AbstractControl, ValidatorFn } from '@angular/forms';

import { Bacnet, WildcardBACnet } from './enums';
import { DateValidator } from './interfaces';
import { SpecialChars } from './special-chars';

export class DateTimeValidator {
  private static validateInput(
    value: string,
    min: number,
    max: number,
    wildcards: string[] = []
  ): boolean {
    // isn't it a valid string
    if (value == null) {
      return false;
    }
    // is it a wildcard
    if (wildcards.includes(value)) {
      return true;
    }
    // isn't it an integer number
    if (!value.match(/^\d+$/m)) {
      return false;
    }
    // is number in range
    const num = parseInt(value, 10);
    return num >= min && num <= max;
  }

  private static validateRangeDay(
    value: string,
    date: DateValidator,
    wildcards: string[],
    isSpecial = false
  ): boolean {
    const month =
      date.month != null &&
      date.month !== WildcardBACnet.allDays &&
      date.month < WildcardBACnet.lastDayOfMonth
        ? date.month
        : 1;
    const year = date.year != null && date.year !== WildcardBACnet.allYears ? date.year : 2000;

    const maxDay = isSpecial ? Bacnet.DAYS : new Date(year, month, 0).getDate();
    return DateTimeValidator.validateInput(value, 1, maxDay, wildcards);
  }

  private static validateRangeYear(
    value: Date,
    min: number,
    max: number,
    wildcards: string[] = []
  ): boolean {
    const year = value instanceof Date ? value.getFullYear() : undefined;
    return year ? year >= min && year <= max : wildcards.includes(SpecialChars.optional);
  }

  static validate(min: number, max: number, wildcards: string[]): ValidatorFn {
    return (c: AbstractControl) =>
      DateTimeValidator.validateInput(c.value, min, max, wildcards)
        ? null
        : { dateTimeValidator: { valid: false } };
  }

  static validateDate(min: number, max: number, wildcards: string[]): ValidatorFn {
    return (c: AbstractControl) =>
      DateTimeValidator.validateRangeYear(c.value, min, max, wildcards)
        ? null
        : { dateTimeValidator: { valid: false } };
  }

  static validateDay(date: DateValidator, wildcards: string[], isSpecial = false): ValidatorFn {
    return (c: AbstractControl) =>
      DateTimeValidator.validateRangeDay(c.value, date, wildcards, isSpecial)
        ? null
        : { dateTimeValidator: { valid: false } };
  }
}
