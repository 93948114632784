import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  TemplateRef
} from '@angular/core';

import { SiSelectOptionComponent } from '../select-option/si-select-option.component';
import { SiSelectOptionRow } from '../si-select.types';

@Component({
  selector: 'si-select-option-row',
  standalone: true,
  imports: [SiSelectOptionComponent],
  templateUrl: './si-select-option-row.component.html',
  styleUrl: './si-select-option-row.component.scss',
  host: {
    class: 'dropdown-item focus-none pe-4 gap-4'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiSelectOptionRowComponent {
  @Input({ required: true }) option!: SiSelectOptionRow<unknown>;
  @Input() optionTemplate?: TemplateRef<unknown>;
  @Input({ transform: booleanAttribute }) selected = false;

  @HostBinding('attr.data-id')
  protected get dataId(): unknown {
    return this.option.value;
  }

  @HostBinding('class.disabled')
  protected get disabled(): boolean {
    return !!this.option.disabled;
  }
}
