<!-- Wrapper around target element to host popover -->
<ng-container *ngIf="!excludePopover">
  <div
    [popover]="memoPopoverTemplate"
    container="body"
    [placement]="popoverPlacement"
    [outsideClick]="false"
    [isOpen]="isOpen"
    [triggers]="''"
    (click)="onClick($event)"
    (onShown)="onShown($event)"
    (onHidden)="onHidden()">

    <ng-content />
  </div>
</ng-container>

<!-- Exclude popover (just provide inner about-object container) -->
<ng-container *ngIf="excludePopover">
  <ng-container *ngTemplateOutlet="memoPopoverTemplate" />
</ng-container>

<!-- Popover content -->
<ng-template #memoPopoverTemplate>
  <gms-memo-view
    [vm]="vm"
    (isDirty)="onDirtyStateChanged($event)"
    (saveResult)="onSaveResult($event)" />
</ng-template>
