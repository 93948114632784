import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsElement } from '../elements/gms-element';
import { GmsAdornerType } from '../types/gms-adorner-types';

@Injectable()
export class GmsAdornerService {
  public adornersChanged: Subject<string> = new Subject<string>();
  private adornersRef: GmsAdorner[];

  // currently active hover adorners (subset of adornersRef)
  private readonly hoverAdorners: GmsAdorner[] = [];

  public setAdornerService(adorners: GmsAdorner[]): void {
    this.adornersRef = adorners;
  }

  public createSelectionAdorner(gmsElement: GmsElement): GmsAdorner {
    if (gmsElement == null) {
      return null;
    }

    const adorner: GmsAdorner = new GmsAdorner(GmsAdornerType.Selection, gmsElement);
    adorner.Id = gmsElement.Id;
    this.adornersRef.push(adorner);
    this.NotifyAdornersChanged('Selection Adorner Added');

    return adorner;
  }

  public createHoverAdorner(gmsElement: GmsElement): GmsAdorner {
    if (gmsElement == null) {
      return null;
    }

    const adorner: GmsAdorner = new GmsAdorner(GmsAdornerType.Hover, gmsElement);
    adorner.Id = gmsElement.Id;

    // clear any existing hover adorners
    for (let i = 0; i < this.hoverAdorners.length; i++) {
      this.RemoveAdorner(this.hoverAdorners[i]);
    }

    // there are no hover adorners
    this.hoverAdorners.length = 0;

    // add new hover adorner to adorners arrays
    this.adornersRef.push(adorner);
    this.hoverAdorners.push(adorner);
    this.NotifyAdornersChanged('Hover Adorner Added');

    return adorner;
  }

  public RemoveAdorner(adorner: GmsAdorner): void {
    const index: number = this.adornersRef.indexOf(adorner);
    if (index > -1) {
      this.adornersRef.splice(index, 1);
      this.NotifyAdornersChanged('Adorner Removed');
    }
  }

  public NotifyAdornersChanged(propertyName: string = ''): void {
    if (this.adornersChanged.observers !== null && this.adornersChanged.observers.length > 0) {
      this.adornersChanged.next(propertyName);
    }
  }

  public ClearAdorners(): void {
    //  selection adorners are cleaned up by the graphic
    this.hoverAdorners.length = 0;
  }
}
