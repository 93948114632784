import { BehaviorSubject, Observable } from 'rxjs';
import { FullSnapInId, IObjectSelection } from '@gms-flex/core';
import { CnsHelperService, CnsLabelEn, GmsMessageData, SiIconMapperService, TablesEx } from '@gms-flex/services';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';

interface SelectionInfo {
  icon: string;
  title: string;
  msg: GmsMessageData;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectSelectionService implements IObjectSelection {

  private readonly snapInsSelections: Map<string, BehaviorSubject<SelectionInfo>> = new Map<string, BehaviorSubject<SelectionInfo>>();

  private readonly rightPanelSelection: Map<string, BehaviorSubject<SelectionInfo>> = new Map<string, BehaviorSubject<SelectionInfo>>();

  private readonly multiSelectionText = 'Multi selection';
  private readonly defaultIcon = 'element-special-object';

  private isCnsAlreadySubscribed = false;

  constructor(
    private readonly cns: CnsHelperService,
    private readonly iconMapperService: SiIconMapperService
  ) {
  }

  public getSelectedObject(fullSnapInId: FullSnapInId): Observable<SelectionInfo> {
    if (!isNullOrUndefined(fullSnapInId)) {
      if (!this.snapInsSelections.has(fullSnapInId.fullId())) {
        this.snapInsSelections.set(fullSnapInId.fullId(), new BehaviorSubject<SelectionInfo>(null));
      }
      return this.snapInsSelections.get(fullSnapInId.fullId()).asObservable();
    } else {
      return null;
    }
  }

  public setSelectedObject(fullSnapInId: FullSnapInId, msg: GmsMessageData): void {
    if (!this.isCnsAlreadySubscribed) {
      this.cns.activeCnsLabel.subscribe(activeLabel => {
        this.onCnsLabelChange();
      });
      this.isCnsAlreadySubscribed = true;
    }

    const selectionInfo: SelectionInfo = this.calculateSelectionInfo(msg);

    if (!this.snapInsSelections.has(fullSnapInId.fullId())) {
      this.snapInsSelections.set(fullSnapInId.fullId(), new BehaviorSubject<SelectionInfo>(selectionInfo));
    } else {
      this.snapInsSelections.get(fullSnapInId.fullId()).next(selectionInfo);
    }
  }

  public getRightPanelSelectedObject(frameId: string): Observable<SelectionInfo> {
    if (!isNullOrUndefined(frameId)) {
      if (!this.rightPanelSelection.has(frameId)) {
        this.rightPanelSelection.set(frameId, new BehaviorSubject<SelectionInfo>(null));
      }
      return this.rightPanelSelection.get(frameId).asObservable();
    } else {
      return null;
    }
  }

  public setRightPanelSelectedObject(frameId: string, msg: GmsMessageData): void {
    if (!this.isCnsAlreadySubscribed) {
      this.cns.activeCnsLabel.subscribe(activeLabel => {
        this.onCnsLabelChange();
      });
      this.isCnsAlreadySubscribed = true;
    }
    const selectionInfo: SelectionInfo = this.calculateSelectionInfo(msg);
    if (!this.rightPanelSelection.has(frameId)) {
      this.rightPanelSelection.set(frameId, new BehaviorSubject<SelectionInfo>(selectionInfo));
    } else {
      this.rightPanelSelection.get(frameId).next(selectionInfo);
    }
  }

  private calculateSelectionInfo(msg: GmsMessageData): SelectionInfo {
    const selectionInfo: SelectionInfo = {
      icon: null,
      title: null,
      msg
    };

    let typeId: number;
    let subTypeId: number;
    let icon: string;
    let isDefaultIcon: boolean;

    if (msg?.data?.length > 0) {
      if (msg.data.length > 1 || msg.customData?.length > 1) {
        if (msg.data.every((val, i, arr) =>
          this.iconMapperService.getGlobalIconSync(TablesEx.ObjectSubTypes, val.Attributes.SubTypeId, val.Attributes.TypeId) ===
            this.iconMapperService.getGlobalIconSync(TablesEx.ObjectSubTypes, arr[0].Attributes.SubTypeId, arr[0].Attributes.TypeId))) {
          isDefaultIcon = false;
        } else {
          isDefaultIcon = true;
        }
        selectionInfo.title = 'OM-ABOUT-MULTI-SELECTION-TITLE';
      } else {
        isDefaultIcon = false;
        if (this.cns.activeCnsLabelValue.cnsLabel === CnsLabelEn.Description ||
          this.cns.activeCnsLabelValue.cnsLabel === CnsLabelEn.DescriptionAndAlias ||
          this.cns.activeCnsLabelValue.cnsLabel === CnsLabelEn.DescriptionAndName) {
          selectionInfo.title = msg.data[0].Descriptor;
        } else {
          selectionInfo.title = msg.data[0].Name;
        }
      }

      if (!isDefaultIcon) {
        typeId = msg.data[0].Attributes.TypeId;
        subTypeId = msg.data[0].Attributes.SubTypeId;
        icon = this.iconMapperService.getGlobalIconSync(TablesEx.ObjectSubTypes, subTypeId, typeId);
        selectionInfo.icon = icon;
      } else {
        selectionInfo.icon = this.defaultIcon;
      }
    }
    return selectionInfo;
  }

  private onCnsLabelChange(): void {
    this.snapInsSelections.forEach(value => {
      const oldSelectionInfo = value.getValue();
      if (!isNullOrUndefined(oldSelectionInfo)) {
        const newSelectionInfo = this.calculateSelectionInfo(oldSelectionInfo.msg);
        value.next(newSelectionInfo);
      }
    });

    this.rightPanelSelection.forEach(value => {
      const oldSelectionInfo = value.getValue();
      if (!isNullOrUndefined(oldSelectionInfo)) {
        const newSelectionInfo = this.calculateSelectionInfo(oldSelectionInfo.msg);
        value.next(newSelectionInfo);
      }
    });
  }
}
