import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EnumerationItem } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { takeUntil } from 'rxjs/operators';

import { GmsCommandControlSelector } from '../elements/gms-commandcontrol-selector';
import { GmsCommandResult } from '../processor/command-view-model/gms-command-vm';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-selector]',
  template: `<svg:g #selectorGroup
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents' : 'allptrevents'"
                    [attr.transform]="element.GetTransformations()"
                    [attr.filter]="element.Filter?.Url"
                    [attr.visibility]="element.GetVisible()"
                    [attr.id]="element.Id"
                    (mouseenter)="OnMouseEnter($event)"
                >
                <svg:foreignObject
                style="overflow: visible;"
                [attr.width]="element.Width"
                [attr.height]="element.Height"

                class="allptrevents">
                    <xhtml:div class="foreign-object">
                        <select
                        #selectList
                        class="foreign-object"
                        style="box-shadow: none; overflow: visible;"
                        (keydown)="OnKeyDown($event)"
                        (change)="onConfirm()"
                        [ngModel]="SelectorItem !== undefined ? SelectorItem.Descriptor : ''"
                        [style.font-family]="element.CommandFontFamily"
                        [style.font-size]="element.CommandFontSize"
                        [style.min-width]="element.Width"
                        [style.max-width]="element.Width"
                        [style.min-height]="element.Height"
                        [style.max-height]="element.Height"
                        [attr.opacity]="element.Opacity"
                        [style.background-color]="element.GetBackgroundColor()"
                        [style.border-color]="element.CommandFillColor"
                        [style.color]="element.StrokeColor"
                        [style.pointer-events]="element.GetCommandEnabled() ? null : 'none'">
                            <ng-container *ngIf="element.ShowErrorBorder === false">
                                <xhtml:option style="background-color: white" *ngFor="let item of (element.ParameterVM ? element.ParameterVM.enums : []);">{{item.Descriptor}}</xhtml:option>
                            </ng-container>
                        </select>
                    </xhtml:div>
                </svg:foreignObject>
                <svg:g [attr.transform]="divOffset" style="pointer-events: none;">
                    <g [attr.fill]="element.CommandFillColor ? element.CommandFillColor : '#9DA3A5'"
                    stroke="#000000" stroke-width="1" [attr.height]="indicatorSize" [attr.width]="indicatorSize">
                        <rect [attr.width]="indicatorSize - 0.5" [attr.height]="indicatorSize - 1.8"/>
                    </g>
                    <g [attr.width]="indicatorSize / 2" [attr.height]="indicatorSize / 2"
                       [attr.fill]="GetIndicatorBackground()" stroke="#000000"
                       stroke-width="1" [attr.transform]="transformString">
                        <path [attr.d]="indicatorPath" fill-rule="evenodd"/>
                    </g>
                </svg:g>
               <svg:rect *ngIf="element.ShowErrorBorder"
                           [attr.width]="element.Width"
                           [attr.height]="element.Height"
                           fill="url(#pattern-error-comm)"
                           stroke-width="2" stroke="#5A5D60" />
               `,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{ pointer-events:all; }`,
    `.allptrevents input:focus{pointer-events:all; }`,
    `select {
            display: block;
            line-height: normal;
            padding: 3px;
            /* text-align-last: center; */
            box-sizing: border-box;
            margin: 0;
            border: 1px solid #aaa;
            box-shadow: none;
            border-radius: 0px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: 100%;
            background-attachment: fixed;
            outline: none; }`,
    `select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }`,
    `option:not(:checked) {
            /* NOTE: for firefox, prevent <option>s from becoming transparent as well */
            color: black;
         }`
  ]
  // changeDetection: ChangeDetectionStrategy.OnPush,
  // viewProviders: [GmsButtonsComponent]
})

export class GmsSelectorComponent extends GmsElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public element: GmsCommandControlSelector = null;
  @ViewChild('selectorGroup', { static: false }) public selectorGroup: any;
  @ViewChild('selectList', { static: true }) public selectList: any;
  public divOffset: string;
  public transformString: string;
  public indicatorPath: string;
  public indicatorSize = 20;
  public isKeyModified = false;

  private _selectorItem: EnumerationItem = undefined;

  public get SelectorItem(): EnumerationItem {
    this.htmlSelectorValue();
    return this._selectorItem;
  }

  public set SelectorItem(value: EnumerationItem) {
    if (this._selectorItem !== value) {
      this._selectorItem = value;
    }
  }

  constructor(changeDetector: ChangeDetectorRef,
    adornerService: GmsAdornerService,
    private readonly traceService: TraceService) {
    super(changeDetector, adornerService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.indicatorSize = this.element.Height;
    this.divOffset = `translate(${(this.element.Width - this.indicatorSize) - 0.5}, 1)`;
    this.indicatorPath = `M0,0L${this.indicatorSize / 2},0L${this.indicatorSize / 4},${this.indicatorSize / 2.25},L0,0`;
    this.transformString = `translate(${this.indicatorSize / 4} ${this.indicatorSize / 4})`;
  }

  public ngAfterViewInit(): void {
    this.htmlSelectorValue();
    if (this.element.buttons !== undefined) {
      this.element.buttons.confirmSubscription.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.onConfirm());
      this.element.buttons.cancelSubscription.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.onCancel());
    }
  }

  public ngOnDestroy(): void {
    if (this.element.buttons !== undefined) {
      this.element.buttons.Destroy();
    }
  }

  public GetIndicatorBackground(): string {
    const color: string = this.element.GetBackgroundColor();
    return color ? color : 'white';
  }

  public htmlSelectorValue(): void {
    if (this.element.ParameterVM === undefined || this.element.ParameterVM.enums === undefined) {
      return;
    }

    if (this.element.ParameterVM.enums === undefined || this.element.ParameterVM.Value === undefined) {
      return;
    }

    if (this.element.ParameterVM.isModified) {
      const item: EnumerationItem = this.searchEnumsByValue(this.element.ParameterVM.FormattedValue);
      if (item !== undefined) {
        this.selectList.nativeElement.value = item.Descriptor;
      }

      return;
    }

    const item: EnumerationItem = this.searchEnumsByValue(this.element.ParameterVM.Value);
    this._selectorItem = item;
    this.setParameterFormattedValue();
  }

  public setParameterFormattedValue(): void {
    const descriptor: string = this.selectList.nativeElement.value;
    const item: EnumerationItem = this.searchEnumsByDescriptor(descriptor);

    if (item) {
      this.element.ParameterVM.FormattedValue = String(item.Value);
    } else {
      this.element.ParameterVM.FormattedValue = '';
    }
  }

  public CanExecuteCommand(): boolean {
    return this.element.CanExecuteCommand;
  }

  public searchEnumsByValue(value: string): EnumerationItem {
    for (const item of this.element.ParameterVM.enums) {
      if (item.Value === Number(value)) {
        return item;
      }
    }
  }

  public searchEnumsByDescriptor(descriptor: string): EnumerationItem {
    for (const item of this.element.ParameterVM.enums) {
      if (item.Descriptor === descriptor) {
        return item;
      }
    }
  }

  public OnKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        this.isKeyModified = true;
        break;
      case 'Enter':
        event.preventDefault();
        this.onConfirm();
        break;
      case 'Escape':
        this.isKeyModified = false;
        break;
      default:
        break;
    }
  }

  public onConfirm(): void {
    const descriptor: string = this.selectList.nativeElement.value;
    const item: EnumerationItem = this.searchEnumsByDescriptor(descriptor);
    if (item !== undefined) {
      this.element.ParameterVM.FormattedValue = String(item.Value);
    }

    if (this.element.CommandVM !== undefined && this.isKeyModified) {
      this.isKeyModified = false;
      this.element.CommandVM.isModified = true;
      return;
    }

    if (this.element.CommandVM !== undefined && this.element.IsControlGrouped) {
      this.SelectorItem = item;
      this.element.CommandVM.isModified = true;
      return;
    }

    if (!this.element.CanExecuteCommand) {
      this.SelectorItem = item;
      this.element.CommandVM.isModified = true;
      return;
    }

    if (this.CanExecuteCommand()) {
      this.element.subExecuteStatus = this.element.ExecuteCommand().subscribe(
        (res: GmsCommandResult) => {
          if (res.Success) {
            this.Updatestatus(true);
          } else {
            this.Updatestatus(false, res.Error);
          }
        },
        error => {
          this.Updatestatus(false, error.message);
        }
      );
    } else {
      this.onCancel();
    }
  }

  public onCancel(): void {
    this.selectList.nativeElement.value = this.SelectorItem.Descriptor;
    this.element.ParameterVM.FormattedValue = this.SelectorItem.Descriptor;
  }

  protected Updatestatus(success: boolean, error: string = undefined): void {
    this.element.UpdateCommandExecuteStatus(success, error);
    if (!success) {
      this.onCancel();
    }
  }
}
