import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, Observable, of, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { useCache } from '../shared/http-utility.service';
import { DisciplineEnumResponse, EnumsProxyService } from './enums-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {
  private readonly disciplinesPerPartition: Map<string, DisciplineEnumResponse> = new Map<string, DisciplineEnumResponse>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly enumsProxy: EnumsProxyService) {

    this.traceService.info(TraceModules.bxServicesEnums, 'EnumsService created.');
  }

  public getDisciplinesEnum(partitionId: string): Observable<DisciplineEnumResponse> {
    if ((useCache) && (this.disciplinesPerPartition.has(partitionId))) {
      return of(this.disciplinesPerPartition.get(partitionId));
    } else {
      return this.enumsProxy.getDisciplinesEnum(partitionId).pipe(
        tap(result => this.disciplinesPerPartition.set(partitionId, result)),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesEnums,
            `EnumsService.getDisciplinesEnum(): Disciplines not found for partitionId: ${partitionId}, error=${error}`);
          const resp: DisciplineEnumResponse = { data: [], links: undefined };
          this.disciplinesPerPartition.set(partitionId, resp);
          return of(resp);
        })
      );
    }
  }
}
