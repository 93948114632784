import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { AnyValueType } from '@simpl/element-value-types';
import { AnyCommandingProperty, AnyPropertyValueType, BulkCommandEvent, BulkProperty, BulkPropertyValues,
  BulkResult, CommandEvent, PropertyApi } from '@simpl/object-browser-ng';
import { Observable, Observer, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { Common } from '../shared/common';
import { TraceModules } from '../shared/trace-modules';
import { TraceServiceDelegate } from '../shared/trace-service-delegate';
import { PropertyInstance } from '../view-model/property-instance-vm';
import { PropertyViewModel } from '../view-model/property-vm';
import { PropertySnapInViewModel } from '../view-model/snapin-vm';
import { ContextState } from '../view-model/snapin-vm.types';

@Injectable()
export class PropertyApiService extends PropertyApi {

  private vm: PropertySnapInViewModel;
  private readonly commandErrorInd: Subject<string>;

  // Use ReplaySubject(1) instead of BehaviorSubject to avoid having to provide an initial seed value
  // Replay is needed in the case where the UI component subscribes late (after property list is established).
  // Subject is needed because list can change for the same selection context (user switch between ext/op modes
  // being the main use-case).
  // These will be created once for the life-time of the service.  They will emit empty array values when the
  // selection context is empty or the property-list of a new selection context is not yet know (i.e, read-properties
  // request pending state).
  private readonly singleObjectPropertyList: ReplaySubject<AnyCommandingProperty[]>;
  private readonly mulipleObjectPropertyList: ReplaySubject<BulkProperty[]>;

  // Current subscriber to bulkPropertyValues (can be only one at a time!)
  private bulkPropertyValuesObserver: Observer<BulkPropertyValues>;

  private readonly traceSvc: TraceServiceDelegate;

  public get commandError(): Observable<string> {
    return this.commandErrorInd;
  }

  public constructor() {
    super();
    this.traceSvc = new TraceServiceDelegate(undefined, TraceModules.pvc);
    this.singleObjectPropertyList = new ReplaySubject<AnyCommandingProperty[]>(1);
    this.mulipleObjectPropertyList = new ReplaySubject<BulkProperty[]>(1);
    this.bulkPropertyValuesObserver = undefined;
    this.commandErrorInd = new Subject<string>();
  }

  public initialize(t: TraceService, vm: PropertySnapInViewModel): void {
    if (!(t && vm)) {
      throw new Error('invalid argument');
    }
    this.traceSvc.native = t; // inject native trace service into our local delegate
    this.traceSvc.info('PropertyApiService initialize called');
    this.vm = vm;
    // Ensure the current property-list is represented to the si-property-viewer control after
    // obtaining the view-model
    this.updatePropertyList();
    // Update the UI-bound property list on changes in the view-model
    this.vm.propertyListChanged.subscribe(() => this.updatePropertyList());
  }

  public getProperties(objectId: string): Observable<AnyCommandingProperty[]> {
    this.traceSvc.info('PropertyApiService get-properties called: objectId=%s', objectId);
    return this.singleObjectPropertyList;
  }

  public writeProperty(objectId: string, property: AnyCommandingProperty): Observable<void> {
    if (!this.vm) {
      return throwError(new Error('service not ready'));
    }
    this.traceSvc.info('execute-default-command (via write-property) called: objectId=%s, property=%s', objectId, Common.toStringProperty(property));
    if (!property) {
      return throwError(new Error('invalid command'));
    }
    // Find the object-property instance
    let propertyInst: PropertyInstance;
    if (this.vm.contextState === ContextState.SingleObject) {
      // Single-object selection; find object-property from main property list by prop-id
      const pVm: PropertyViewModel = this.vm.findProperty(property.id);
      if (pVm) {
        propertyInst = pVm.instance;
      }
    } else {
      // Multi-object selection; find object-property from detail-property's object-instance list by object-id
      if (this.vm.detailProperty) {
        propertyInst = this.vm.detailProperty.instances.find(p => p.objectId === objectId);
      }
    }
    if (!propertyInst) {
      return throwError(new Error('invalid object or property-id'));
    }
    const arg: AnyPropertyValueType = property.value;
    return propertyInst.executeDefaultCommand(arg)
      .pipe(
        map(res => {
          if (!res?.isSuccess) {
            throw new Error(res?.errorMesg);
          }
          this.traceSvc.info('execute-default-command success: objectId=%s, pid=%s', objectId, property.id);
        }),
        catchError(err => {
          this.traceSvc.error('execute-default-command error: objectId=%s, pid=%s, %s', objectId, property.id, err);
          propertyInst.resetDisplayValue();
          this.notifyCommandError(err);
          return throwError(err); // indicate command failure
        }));
  }

  public executeCommand(objectId: string, command: CommandEvent): Observable<void> {
    if (!this.vm) {
      return throwError(new Error('service not ready'));
    }
    this.traceSvc.info('execute-command called: objectId=%s, command=%s', objectId, Common.toStringCommand(command));
    if (!command?.property) {
      return throwError(new Error('invalid command'));
    }
    // Find the object-property instance
    let propertyInst: PropertyInstance;
    if (this.vm.contextState === ContextState.SingleObject) {
      // Single-object selection; find object-property from main property list by prop-id
      const pVm: PropertyViewModel = this.vm.findProperty(command.property.id);
      if (pVm) {
        propertyInst = pVm.instance;
      }
    } else {
      // Multi-object selection; find object-property from detail-property's object-instance list by object-id
      if (this.vm.detailProperty) {
        propertyInst = this.vm.detailProperty.instances.find(p => p.objectId === objectId);
      }
    }
    if (!propertyInst) {
      return throwError(new Error('invalid object or property-id'));
    }
    const args: AnyValueType[] = command.parameters ? command.parameters.map(p => p?.value) : [];
    return propertyInst.executeCommand(command.command, args)
      .pipe(
        map(res => {
          if (!res?.isSuccess) {
            throw new Error(res?.errorMesg);
          }
          this.traceSvc.info('execute-command success: objectId=%s, command=%s', objectId, command.command);
        }),
        catchError(err => {
          this.traceSvc.error('execute-command error: objectId=%s, command=%s, %s', objectId, command.command, err);
          this.notifyCommandError(err);
          return throwError(err);
        }));
  }

  public getBulkProperties(objectIds: string[]): Observable<BulkProperty[]> {
    this.traceSvc.info('PropertyApiService get-bulk-properties called: object-count=%s', objectIds ? objectIds.length : 0);
    return this.mulipleObjectPropertyList;
  }

  public getBulkPropertyValues(objectIds: string[], propertyId: string): Observable<BulkPropertyValues> {
    this.traceSvc.info('PropertyApiService get-bulk-property-values called: propertyId=%s', propertyId);

    return new Observable<BulkPropertyValues>(
      (o: Observer<BulkPropertyValues>) => {

        // getBulkPropertyValues is intended to be called by a single consumer (the one si-property-viewer contained
        // in the SNI instance that provided this local service instance).  Further, it is by design that the
        // consumer MUST unsubscribe from the returned Observable before making a second call to getBulkPropertyValues.
        // The unsubscribe call to the returned observable is the only indication we are provided by si-property-viewer
        // component that the BACK button in the UI has been pressed and the UI state has transitioned back out of
        // the "detail" view of all object-property values.
        // This check is here to ensure this contract is adhered to by the UI component!
        if (this.bulkPropertyValuesObserver) {
          this.traceSvc.error('PropertyApiService get-bulk-property-values called without unsubscribing from previous call');
          o.error(new Error('invalid call'));
          return;
        }

        this.bulkPropertyValuesObserver = o;
        const res: boolean = this.vm.setContextDetail(propertyId);
        if (!res) {
          this.traceSvc.error('Failed to set detail-context: propertyId=%s', propertyId);
          this.bulkPropertyValuesObserver = undefined;
          o.error(new Error('invalid call'));
          return;
        }

        // Detail context set
        this.bulkPropertyValuesObserver = o;
        return (): void => {
          // Unsubscribe logic
          this.traceSvc.info('PropertyApiService get-bulk-property-values unsubscribed: propertyId=%s', propertyId);
          this.vm.setContextDetail(undefined);
          this.bulkPropertyValuesObserver = undefined;
        };
      });
  }

  public writeBulkProperty(objectIds: string[], propertyId: string, arg: AnyPropertyValueType): Observable<BulkResult> {
    if (!this.vm) {
      return throwError(new Error('service not ready'));
    }
    this.traceSvc.info('execute-default-bulk-command (via write-bulk-property) called: objectId-count=%s, pid=%s', objectIds?.length, propertyId);
    // Find the object-property instance
    let propertyInst: PropertyInstance;
    const pVm: PropertyViewModel = this.vm.findProperty(propertyId);
    if (pVm) {
      propertyInst = pVm.instance;
    }
    if (!propertyInst) {
      return throwError(new Error('invalid object or property-id'));
    }
    return propertyInst.executeDefaultCommand(arg, objectIds)
      .pipe(
        map(res => {
          this.traceSvc.info('execute-default-bulk-command state: objectId=%s, pid=%s, success=%s, err=%s',
            res?.objectId, res?.propertyId, res?.isSuccess, res?.errorMesg);
          return {
            objectId: res?.objectId,
            propertyId: res?.propertyId,
            success: res?.isSuccess ? true : false
          } as BulkResult;
        }),
        finalize(() => {
          this.traceSvc.info('execute-default-bulk-command complete: pid=%s', propertyId);
        }),
        catchError(err => {
          this.traceSvc.error('execute-default-bulk-command error: pid=%s, err=%s', propertyId, err);
          this.notifyCommandError(err);
          propertyInst.resetDisplayValue();
          return throwError(err); // indicate command failure
        }));
  }

  public executeBulkCommand(objectIds: string[], command: BulkCommandEvent): Observable<BulkResult> {
    if (!this.vm) {
      return throwError(new Error('service not ready'));
    }
    this.traceSvc.info('execute-bulk-command called: objectId-count=%s, pid=%s', objectIds?.length, command?.propertyId);
    if (!command) {
      return throwError(new Error('invalid command'));
    }
    const propertyVm: PropertyViewModel = this.vm.findProperty(command.propertyId);
    if (!propertyVm) {
      return throwError(new Error('invalid property-id'));
    }
    const args: AnyValueType[] = command.parameters ? command.parameters.map(p => p?.value) : [];
    return propertyVm.instance.executeCommand(command.command, args, objectIds)
      .pipe(
        map(res => {
          this.traceSvc.info('execute-bulk-command state: objectId=%s, pid=%s, success=%s, err=%s',
            res?.objectId, res?.propertyId, res?.isSuccess, res?.errorMesg);
          return {
            objectId: res?.objectId,
            propertyId: res?.propertyId,
            success: res?.isSuccess ? true : false
          } as BulkResult;
        }),
        finalize(() => {
          this.traceSvc.info('execute-bulk-command complete: pid=%s', command.propertyId);
        }),
        catchError(err => {
          this.traceSvc.error('execute-bulk-command error: pid=%s, err=%s', command.propertyId, err);
          this.notifyCommandError(err);
          return throwError(err); // indicate command failure
        }));
  }

  private updatePropertyList(): void {
    if (!this.vm) {
      return;
    }
    const pList: AnyCommandingProperty[] = this.vm.siPropertyList || [];
    const bulkList: BulkProperty[] = this.vm.siBulkPropertyList || [];
    const bulkProperty: BulkPropertyValues = this.vm.detailProperty ? this.vm.detailProperty.siBulkProperty : undefined;
    this.traceSvc.info('Update UI bound property lists: vm-context-state=%s, property count=%s, detail propId=%s',
      Common.toStringContextState(this.vm.contextState),
      this.vm.contextState === ContextState.SingleObject ? pList.length : bulkList.length,
      bulkProperty?.aggregate ? bulkProperty.aggregate.id : '');

    // Note about values provided to the `si-property-viewer` UI component through these indications:
    // * component assumes `AnyCommandingProperty` array sent via `getProperties` will never be undefined; send empty array instead.
    // * component does not appear to make assumptions about `BulkProperty` array sent via `getBulkProperties` but we will always
    //   send empty array instead of undefined for consistency (and just in case).
    // * component assumes `BulkPropertyValues` object sent via `getBulkPropertyValues` will never be undefined; further it assumes
    //   the `objectValues` array property of this object will never be undefined; don't emit when value is undefined.
    this.singleObjectPropertyList.next(pList);
    this.mulipleObjectPropertyList.next(bulkList);
    if (this.bulkPropertyValuesObserver && bulkProperty) {
      this.bulkPropertyValuesObserver.next(bulkProperty);
    }
  }

  private notifyCommandError(err: any): void {
    let errorMesg: string;
    if (err) {
      if (err instanceof Error) {
        errorMesg = (err as Error).message;
      } else if (typeof err === 'string' || err instanceof String) {
        errorMesg = String(err);
      }
    }
    this.commandErrorInd.next(errorMesg);
  }

}
