import { Injectable, OnDestroy } from '@angular/core';
import { BrowserObject } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  concatMap,
  map,
  takeUntil
} from 'rxjs/operators';

import { SelectableGraphicElement } from '../common/interfaces/selectableGraphicElement';
import { TraceChannel } from '../common/trace-channel';
import { MathUtils } from '../utilities/mathUtils';
import { GmsBrowserObjectService } from './gms-browser-object.service';

/**
 * Manage user selections of datapoints within a graphic.
 */
@Injectable()
export class GmsObjectSelectionService implements OnDestroy {

  // designation strings of currently selected items
  public selections: BehaviorSubject<string[]>;

  // browser objects corresponding to selections
  public selectedObjects: BehaviorSubject<BrowserObject[]>;

  // browser objects corresponding to navigations
  public navigate: Subject<BrowserObject[]>;

  private latestSelections: Set<string>;
  private readonly traceModule: string = TraceChannel.Services;
  private readonly unsubscribe: Subject<void>;

  private navigations: Set<string>;

  // elements that are currently selected
  private selectedElements: SelectableGraphicElement[];

  // Initialized with snapin hldl config value
  private _downwardNavigationEnabled = false;
  public get DownWardNavigationEnabled(): boolean {
    return this._downwardNavigationEnabled;
  }
  public set DownWardNavigationEnabled(value: boolean) {
    if (value !== undefined && value !== this._downwardNavigationEnabled) {
      this._downwardNavigationEnabled = value;
    }
  }

  private _excludeFromDownwardNavigation: string[] = [];
  public get ExcludeFromDownwardNavigation(): string[] {
    return this._excludeFromDownwardNavigation;
  }
  public set ExcludeFromDownwardNavigation(value: string[]) {
    if (value !== undefined) {
      this._excludeFromDownwardNavigation = value;
    }
  }

  public constructor(
    private readonly traceService: TraceService,
    private readonly gmsBrowserObjectService: GmsBrowserObjectService) {
    this.latestSelections = new Set<string>();
    this.selectedObjects = new BehaviorSubject<BrowserObject[]>([]);
    this.selections = new BehaviorSubject<string[]>([]);
    this.navigate = new Subject<BrowserObject[]>();
    this.navigations = new Set<string>();
    this.unsubscribe = new Subject<void>();

    this.selectedElements = [];

    this.selections.pipe(takeUntil(this.unsubscribe)).pipe(
      concatMap((selection: string[]) => this.gmsBrowserObjectService.collectBrowserObjects(selection)),
      map((browserObjects: BrowserObject[]) => browserObjects.filter((obj: BrowserObject) => !!obj)))
      .subscribe(this.selectedObjects);

    this.selections.pipe(takeUntil(this.unsubscribe)).subscribe((selection: string[]) => {
      let infoString = `${selection.length} objects selected`;
      selection.forEach((des: string) => {
        infoString += `\n->${des}`;
      });
      this.traceService.debug(this.traceModule, infoString);
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  /**
   * Process the selection of datapoints
   * @param designationStrings an array of designation strings representing the selected items
   * @param selectedElements an array of newly selected elements.
   * @param clearPreviousSelections true then clear the previous selections
   */
  public setSelections(designationStrings: string[], selectedElements: SelectableGraphicElement[] = [], clearPreviousSelections: boolean = true): void {
    // unselect previous selections
    if (clearPreviousSelections) {
      this.latestSelections.clear();
      this.selectedElements.forEach((element: SelectableGraphicElement) => element.unSelect());
      this.selectedElements = [];
    }

    // empty selections case
    if (designationStrings.length === 0) {
      this.selections.next([]);
      return;
    }

    // add set of new selections to emptied secondarySelectionItems
    this.addSelections(designationStrings, selectedElements);
  }
  public createInitialSelections(designationStrings: string[], selectedElements: SelectableGraphicElement[]): void {
    const newSelectionsSet: Set<string> = new Set(designationStrings);

    this.selectedElements = this.selectedElements.concat(selectedElements);

    // check if new selections are a subset of existing
    const isSubset: boolean = MathUtils.isSuperset(this.latestSelections, newSelectionsSet);
    if (isSubset) {
      return;
    }

    this.latestSelections = MathUtils.union(this.latestSelections, newSelectionsSet);
  }
  /**
   * Add new selections to existing
   */
  public addSelections(designationStrings: string[], selectedElements?: SelectableGraphicElement[], isInitialSelection: boolean = false): void {
    const newSelectionsSet: Set<string> = new Set(designationStrings);

    this.selectedElements = this.selectedElements.concat(selectedElements);

    // check if new selections are a subset of existing
    const isSubset: boolean = MathUtils.isSuperset(this.latestSelections, newSelectionsSet);
    if (isSubset) {
      return;
    }

    this.latestSelections = MathUtils.union(this.latestSelections, newSelectionsSet);
    if (!isInitialSelection) {
      this.selections.next(Array.from(this.latestSelections));
    }
  }

  /**
   * Removes selections from existing
   * @param designationStrings an array of designation strings representing the selected items
   * @param selectedElements an array of selected elements to unselect.
   */
  public removeSelections(designationStrings: string[], selectableElements?: SelectableGraphicElement[]): void {
    const selectionsetToRemove: Set<string> = new Set(designationStrings);
    selectableElements.forEach(element => {
      if (this.selectedElements.includes(element)) {
        const index: number = this.selectedElements.indexOf(element);
        this.selectedElements.splice(index, 1);
      }
    });

    this.latestSelections = MathUtils.difference(this.latestSelections, selectionsetToRemove);
    this.selections.next(Array.from(this.latestSelections));
  }

  /**
   * Get all currently selected datapoints as BrowserObject[]
   */
  public getSelections(): BrowserObject[] {
    return this.selectedObjects.getValue();
  }

  /**
   * clear selections
   */
  public clearSelections(): void {
    this.setSelections([]);
  }

  /**
   * Update navigation targets
   */
  public addNavigations(designationStrings: string[]): void {
    this.navigations = MathUtils.union(this.navigations, new Set(designationStrings));
  }

  /**
   * clear existing navigations
   * @param designationStrings
   */
  public clearNavigations(): void {
    this.navigations.clear();
  }

  /**
   * Update navigate to alert subscriber of navigation execution
   */
  public executeNavigation(): void {
    const currentNavigations = Array.from(this.navigations);

    // DownwardNavigate only if there is one object/dp to navigate.
    if (this.DownWardNavigationEnabled && currentNavigations.length === 1) {

      this.gmsBrowserObjectService.collectBrowserObjects(currentNavigations).subscribe(
        (objs: BrowserObject[]) => {
          // filter exclusions mentioned for downward navigations
          objs = objs.filter((b: BrowserObject) => !!b);
          const excludedObj = objs.find((b: BrowserObject) => this._excludeFromDownwardNavigation.includes(b?.Attributes?.ObjectModelName));
          if (objs.length === 0) {
            this.traceService.warn(this.traceModule,
              `collectBrowserObjects: No valid navigation targets exist on the commanded element`);
          } else if (excludedObj !== undefined) {
            this.navigate.next(objs);
          } else {
            this.gmsBrowserObjectService.collectChildBrowserObjects(currentNavigations).subscribe(
              (childObjs: BrowserObject[]) => {
                childObjs = childObjs.filter((b: BrowserObject) => !!b);
                if (childObjs.length === 0) {
                  this.traceService.warn(this.traceModule,
                    `collectChildBrowserObjects: No valid navigation targets exist on the commanded element`);
                } else {
                  this.navigate.next(childObjs);
                }
              });
          }
        });
    } else {
      this.gmsBrowserObjectService.collectBrowserObjects(currentNavigations).subscribe(
        (ret: BrowserObject[]) => {
          ret = ret.filter((b: BrowserObject) => !!b);
          if (ret.length === 0) {
            this.traceService.warn(this.traceModule,
              `collectBrowserObjects: No valid navigation targets exist on the commanded element`);
          } else {
            this.navigate.next(ret);
          }
        });
    }
  }

  public reset(): void {
    this.clearNavigations();
    this.clearSelections();
  }

  /**
   * update secondary selection
   */
  private sendMessage(selection: BrowserObject[]): void {
    // send message to hfw
    // this.messageBroker.sendMessage(GraphicsSnapinComponent.fullId, ;
  }
}
