import { BACnetDateTimeDetail, BACnetDateTimeResolution, FormatBACnetDateTime, FormatDate, FormatDuration, FormatNumeric } from '@gms-flex/controls';
import { PropertyDetails } from '@gms-flex/services';
import { TimeDurationFormat, TimeDurationUnit } from '@simpl/element-value-types';
import * as Long from 'long';

import { DateTimeType } from './command-vm.types';
import { DurationDisplayFormat, DurationUnits, PropertySourceType, PropertyValueType, WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

export interface ParamFormatNumeric {
  locale: string;
  res: number;
  grouping?: boolean;
}
/**
 * Property definition.
 */
export class PropertyDefinition {

  // can be overridden on an instance basis:
  // min, max, engUnits, resolution,
  // allow doy, date/time/date-time, resolution

  // Applicable to arrays and simple bitstrings only
  private readonly elLabelArr: string[];
  private readonly bitLabelArr: string[];
  // private sizeArr: number;
  private readonly sizeBs: number;
  private readonly offsetBs: number;

  private formatServiceNumeric: FormatNumeric;
  private formatServiceLong: FormatNumeric;
  private formatServiceDate: FormatDate;
  private formatServiceBAC: FormatBACnetDateTime;
  private formatServiceDuration: FormatDuration;

  /**
   * Indicates if the property (DateTime) allows day of week configuration
   */

  public get isAllowDayOfWeek(): boolean {
    return Boolean(this.pd.AllowDayOfWeek);
  }

  /**
   * Indicates if the property (DateTime) allows wildcards
   */

  public get isAllowWildcards(): boolean {
    return Boolean(this.pd.AllowWildcards);
  }

  /**
   * Indicates if the property is defined by a Function (otherwise, it is
   * an Object Model property).
   */
  public get isFunctionProperty(): boolean {
    return Boolean(this.isFunc);
  }

  /**
   * Property description.
   */
  public get description(): string {
    return this.pd.Descriptor;
  }

  /**
   * Property id.
   */
  public get propertyId(): string {
    return this.pd.PropertyName;
  }

  /**
   * Indicates if the property is the default property.
   */
  public get isDefaultProperty(): boolean {
    return this.isDefault;
  }

  /**
   * The display order of this property relative to other properties of the datapoint.
   */
  public get displayOrder(): number {
    return this.pd.Order;
  }

  /**
   * Indicates if the property is an indexed property
   */
  public get isIndexed(): boolean {
    return this.pd.PropertyType === PropertySourceType.Indexed;
  }

  /**
   * Logical property type (based on native-type)
   */
  public get valueType(): PropertyValueType {
    return WsiTranslator.toPropertyValueType(this.nativeType);
  }

  public get isNumericFloat(): boolean {
    return WsiTranslator.isNumericFloat(this.nativeType);
  }

  /**
   * Native property type as defined at the server.
   */
  public get nativeType(): string {
    return this.pd.Type;
  }

  /**
   * Indicates if the property is an type of array (including array of bitstrings).
   */
  public get isArray(): boolean {
    return Boolean(this.pd.IsArray);
  }

  /**
   * Indicates if the property is a SIMPLE bitstring (non-array property).
   */
  public get isSimpleBitstring(): boolean {
    return this.isBitstring && !this.isArray;
  }

  public get isBitstring(): boolean {
    return this.valueType === PropertyValueType.Bitstring32Value || this.valueType === PropertyValueType.Bitstring64Value;
  }

  /**
   * Indicates if the property is a BACnet priority-array.
   */
  public get isPriorityArray(): boolean {
    let flag = false;
    if (this.isArray) {
      switch (this.pd.DisplayType) {
        case 1: // = priority-array
          flag = true;
          break;
        case 2: // = event-timestamp
        case 0: // = none
        default:
          break;
      }
    }
    return flag;
  }

  /**
   * Flag indicating if the property should be displayed in the standard property list
   * only when its value is off-normal.
   */
  public get displayWhenOffNormalOnly(): boolean {
    return !this.isArray && Boolean(this.pd.DisplayOffNormalOnly);
  }

  /**
   * Normal value (in "raw" form--same encoding used in ValueDetails `Value.Value` property).
   * May be undefined if property has not been assigned a normal value.
   */
  public get normalValue(): string {
    return this.pd.NormalValue;
  }

  /**
   * Some properties (namely, command priority array entries) use specific numeric/string values
   * to indicate an "undefined" entry.  This is a specialization of priority array properites.
   */
  public get explicitUndefinedValue(): string {
    let undefVal: string;
    if (this.isPriorityArray) {
      switch (this.nativeType) {
        case 'BasicInt':
        case 'ExtendedInt':
          undefVal = '2147483647'; // max Int32
          break;

        case 'BasicUint':
        case 'ExtendedUint':
        case 'ExtendedDuration':
        case 'ExtendedEnum':
        case 'BasicBit32': // For cmd pri arrays of bitstring values, not simple bitstrings
        case 'ExtendedBitString': // For cmd pri arrays of bitstring values, not simple bitstrings
          undefVal = '4294967295'; // max UInt32
          break;

        case 'BasicString':
        case 'ExtendedDateTime':
          undefVal = '<NULL>';
          break;

        case 'BasicInt64':
        case 'ExtendedInt64':
          undefVal = '9223372036854775807';
          break;

        case 'BasicUint64':
        case 'ExtendedUint64':
        case 'ExtendedBitString64':
          undefVal = '18446744073709551615';
          break;

        default:
          break;
      }
    }
    return undefVal;
  }

  /**
   * Indicates of the property has assigned engineering units.
   */
  public get hasEngineeringUnits(): boolean {
    return this.pd.UnitDescriptor !== undefined && this.pd.UnitDescriptor.length > 0;
  }

  /**
   * Engineering units.
   */
  public get engineeringUnits(): string {
    return this.pd.UnitDescriptor;
  }

  /**
   * Size of array.
   * NOTE: For arrays, the size is ultimately determined by the size of the value array delivered
   *  with the first instance COV.  In this case, the size value in the property-definition serves
   *  as a default (i.e., in the case that a COV has not yet been received).
   */
  public get sizeArrayDefault(): number {
    return this.elLabelArr ? this.elLabelArr.length : 0;
  }

  /**
   * Size of bitstring.
   */
  public get sizeBitstring(): number {
    return this.sizeBs;
  }

  /**
   * Bitstring offset.
   */
  public get offsetBitstring(): number {
    return this.offsetBs;
  }

  /**
   * Labels for array elements.
   */
  public get arrayItemLabels(): readonly string[] {
    return this.elLabelArr;
  }

  public get bitstringItemLabels(): readonly string[] {
    return this.bitLabelArr;
  }

  /**
   * Property resolution or `undefined` if not provided.
   */
  public get resolution(): number {
    return this.pd.Resolution;
  }

  /**
   * Units of a duration property value (`undefined` if not set for the property)
   */
  public get durationValueUnits(): DurationUnits {
    return WsiTranslator.toDurationUnits(this.pd.DurationValueUnits);
  }

  /**
   * Duration display format (`undefined` if not set for the property).
   */
  public get durationDisplayFormat(): DurationDisplayFormat {
    return WsiTranslator.toDurationDisplayFormat(this.pd.DurationDisplayFormat);
  }

  /**
   * For BACnet date-time properties, indicates if the value represents a date only, time only,
   * or full date and time.
   */
  public get bnDateTimeDetail(): DateTimeType {
    return WsiTranslator.toDateTimeType(this.pd.BACnetDateTimeDetail);
  }

  /**
   * For BACnet date-time properties, indicates the time resolution in seconds (0), tenths-of-sec (1), or
   * hundreths (2); may be undefined.
   */
  private get bnDateTimeResolution(): number {
    return this.pd.BACnetDateTimeResolution;
  }

  /**
   * Trim the system name and delimiter from the beginning of the provided property id.
   */
  public static trimSystemName(pid: string): string {
    if (pid) {
      const pos: number = pid.indexOf(':');
      if (pos >= 0) {
        return pid.slice(pos + 1);
      }
    }
    return pid;
  }

  /**
   *
   * Duration units map to TimeDurationUnit helper
   */

  public static mapTimeDurationUnit(du: DurationUnits): TimeDurationUnit {
    let units: TimeDurationUnit = '';
    switch (du) {
      case DurationUnits.Day:
        units = 'd';
        break;
      case DurationUnits.Hour:
        units = 'h';
        break;
      case DurationUnits.Min:
        units = 'min';
        break;
      case DurationUnits.Sec:
        units = 's';
        break;
      case DurationUnits.Dsec:
        units = 'ts';
        break;
      case DurationUnits.Csec:
        units = 'hs';
        break;
      case DurationUnits.Msec:
        units = 'ms';
        break;
      default:
        units = '';
        break;
    }
    return units;
  }

  /**
   * format specifier map to be used with TimeDurationFormat helper.
   */

  public static mapTimeDurationFormat(ddf: DurationDisplayFormat): TimeDurationFormat {
    let fmt: TimeDurationFormat = '';
    switch (ddf) {
      case DurationDisplayFormat.Day:
        fmt = 'dd';
        break;
      case DurationDisplayFormat.DayHour:
        fmt = 'dd:hh';
        break;
      case DurationDisplayFormat.DayHourMin:
        fmt = 'dd:hh:mm';
        break;
      case DurationDisplayFormat.DayHourMinSec:
        fmt = 'dd:hh:mm:ss';
        break;
      case DurationDisplayFormat.DayHourMinSecMs:
        fmt = 'dd:hh:mm:ss';
        break;
      case DurationDisplayFormat.Hour:
        fmt = 'hh';
        break;
      case DurationDisplayFormat.HourMin:
        fmt = 'hh:mm';
        break;
      case DurationDisplayFormat.HourMinSec:
        fmt = 'hh:mm:ss';
        break;
      case DurationDisplayFormat.HourMinSecMs:
        fmt = 'hh:mm:ss';
        break;
      case DurationDisplayFormat.Min:
        fmt = 'mm';
        break;
      case DurationDisplayFormat.MinSec:
        fmt = 'mm:ss';
        break;
      case DurationDisplayFormat.MinSecMs:
        fmt = 'mm:ss.ms';
        break;
      case DurationDisplayFormat.Sec:
        fmt = 'ss';
        break;
      case DurationDisplayFormat.SecMs:
        fmt = 'ss.ms';
        break;
      case DurationDisplayFormat.None:
      default:
        break;
    }
    return fmt;
  }

  /**
   * Duration units map to be used with formatDuration helper.
   */
  private static mapDurationUnits(du: DurationUnits): string {
    let units = '';
    switch (du) {
      case DurationUnits.Day:
        units = 'd';
        break;
      case DurationUnits.Hour:
        units = 'h';
        break;
      case DurationUnits.Min:
        units = 'm';
        break;
      case DurationUnits.Sec:
        units = 's';
        break;
      case DurationUnits.Dsec:
        units = 'ts';
        break;
      case DurationUnits.Csec:
        units = 'hs';
        break;
      case DurationUnits.Msec:
        units = 'ms';
        break;
      default:
        break;
    }
    return units;
  }

  /**
   * format specifier map to be used with formatDuration helper.
   */
  private static mapDurationtoFormatSpecifier(ddf: DurationDisplayFormat): string {
    let fmt = '';
    switch (ddf) {
      case DurationDisplayFormat.Day:
        fmt = 'D.FFF';
        break;
      case DurationDisplayFormat.DayHour:
        fmt = 'D:h.FFF';
        break;
      case DurationDisplayFormat.DayHourMin:
        fmt = 'D:hh:mm.FFF';
        break;
      case DurationDisplayFormat.DayHourMinSec:
        fmt = 'D:hh:mm:ss.FFF';
        break;
      case DurationDisplayFormat.DayHourMinSecMs:
        fmt = 'D:hh:mm:ss.fff';
        break;
      case DurationDisplayFormat.Hour:
        fmt = 'H.FFF';
        break;
      case DurationDisplayFormat.HourMin:
        fmt = 'H:mm.FFF';
        break;
      case DurationDisplayFormat.HourMinSec:
        fmt = 'H:mm:ss.FFF';
        break;
      case DurationDisplayFormat.HourMinSecMs:
        fmt = 'H:mm:ss.fff';
        break;
      case DurationDisplayFormat.Min:
        fmt = 'M.FFF';
        break;
      case DurationDisplayFormat.MinSec:
        fmt = 'M:ss.FFF';
        break;
      case DurationDisplayFormat.MinSecMs:
        fmt = 'M:ss.fff';
        break;
      case DurationDisplayFormat.Sec:
        fmt = 'S.FFF';
        break;
      case DurationDisplayFormat.SecMs:
        fmt = 'S.fff';
        break;
      case DurationDisplayFormat.None:
      default:
        break;
    }
    return fmt;
  }

  /**
   * Convert local DateTimeType value to an enum that can be used in the Format helper.
   */
  private static toBACnetDateTimeDetail(dtType: DateTimeType): BACnetDateTimeDetail {
    let retVal: BACnetDateTimeDetail;
    switch (dtType) {
      case DateTimeType.DateOnly:
        retVal = BACnetDateTimeDetail.DateOnly;
        break;
      case DateTimeType.TimeOnly:
        retVal = BACnetDateTimeDetail.TimeOnly;
        break;
      case DateTimeType.DateAndTime:
        retVal = BACnetDateTimeDetail.DateAndTime;
        break;
      default:
        retVal = BACnetDateTimeDetail.Unspecified;
        break;
    }
    return retVal;
  }

  /**
   * Convert local BACnet seconds resolution to an enum that can be used in the Format helper.
   */
  private static toBACnetDateTimeResolution(res: number): BACnetDateTimeResolution {
    let retVal: BACnetDateTimeResolution;
    switch (res) {
      case 0:
        retVal = BACnetDateTimeResolution.Seconds;
        break;
      case 1:
        retVal = BACnetDateTimeResolution.Tenths;
        break;
      case 2:
      default:
        retVal = BACnetDateTimeResolution.Hundredths;
        break;
    }
    return retVal;
  }

  /**
   * Constructor.
   */
  public constructor(
    private readonly vmContext: ViewModelContext,
    private readonly pd: PropertyDetails,
    private readonly isFunc: boolean,
    private readonly isDefault: boolean) {

    // IMPLEMENTATION NOTE:
    // -------------------
    // Determing ARRAY SIZE is property-type dependent:
    //
    // For ARRAY-TYPE properties (IsArray=true), the size of the array is NOT provided
    // in the property meta-data.  It is not known until the property value is successully
    // read or obtained from the server through a COV, and at this point it is determined
    // by the size of the array returned (again, not through an explicit property attribute).
    //
    // Since reading the property value is dependent on several potential factors (connectivity
    // to the EV, state of the driver, availability of the device,  ...), we will set
    // the array size on VM creation to the size of the ELEMENT LABEL array.  Although
    // not guaranteed to be the same as size of the forthcoming value array, it will be
    // most of the time and will serve as a better placeholder than 0 or `undefined` until
    // the true size can be obtained.
    //
    // For BITSTRING propertyies, the size of the array is provided through the MIN (default=0)
    // and MAX (default=31|63) attributes in the property meta-data.  These determine the bits in
    // the associated interger value that are used for this GMS property.  In this case, the
    // ELEMENT LABEL attribute array is not a factor.
    //
    // Client code should be written with the following considerations:
    // 1)  always rely on the `arraySize` property to determine the size of the property array.
    // 2)  expect that the `arraySize` may change once the property value is obtained.
    // 3)  if `valueArr` is NOT `undefined`, then `arraySize` will EQUAL `valueArr.length`
    // 4)  do not depend on the element label array and value array to be the same size.
    //
    if (this.isBitstring) {
      this.bitLabelArr = pd.BitStringLabels || [];
      const hiBitMax: number = this.valueType === PropertyValueType.Bitstring64Value ? 63 : 31;

      // Calculate bitstring `size` (32-bit uint)
      const loBit: number = parseInt(pd.Min, 10) || 0; // first-bit (as defined in Object Model)
      const hiBit: number = parseInt(pd.Max, 10) || hiBitMax; // last-bit
      this.sizeBs = Math.max(0, hiBit - loBit + 1);
      this.offsetBs = loBit;
    }
    if (this.isArray) {
      if (this.isPriorityArray) {
        this.elLabelArr = pd.ElementLabels.slice(1); // SPECIAL CASE: ignore 0 element, want elements 1-16
      } else {
        this.elLabelArr = pd.ElementLabels || [];
      }
    }
  }

  /**
   * Format a full object-property id for this property given an object id.
   */
  public formatObjectPropertyId(oid: string): string {
    return oid + (this.isFunctionProperty ? '' : '.') + this.propertyId;
  }

  public objectIdFromObjectPropertyId(objectPropertyId: string): string {
    let oid: string;
    if (objectPropertyId) {
      let pos: number = objectPropertyId.indexOf(this.propertyId);
      pos -= this.isFunctionProperty ? 0 : 1;
      if (pos >= 0) {
        oid = objectPropertyId.substring(0, pos);
      }
    }
    return oid;
  }

  /**
   * Format a numeric value for display according to this properties attributes.
   * Example:
   *   6.88   ==> "6.9"       (assumes resolution = 1)
   *   NaN    ==> undefined
   *   12     ==> "12.0"
   */
  public formatNumeric(num: number): string {
    let numStr: string;
    const paramsFormatNumb: ParamFormatNumeric = {
      locale: this.vmContext.locale,
      res: this.resolution
    };
    if (!isNaN(num)) {
      if (this.formatServiceNumeric === undefined) {
        // Create numeric formatter first time through
        this.formatServiceNumeric = new FormatNumeric(paramsFormatNumb);
      }
      numStr = this.formatServiceNumeric.format(num);
    }
    return numStr;
  }

  /**
   * Format a Long value for display according to this properties attributes.
   * Example:
   *   6.88   ==> "6.9"       (assumes resolution = 1)
   *   12     ==> "12.0"
   */
  public formatLong(bigint: Long): string {
    let numStr: string;
    const paramNumeric: ParamFormatNumeric = {
      locale: this.vmContext.locale,
      res: this.resolution
    };
    if (bigint) {
      if (this.formatServiceLong === undefined) {
        // Create formatter first time through
        this.formatServiceLong = new FormatNumeric(paramNumeric);
      }
      numStr = this.formatServiceLong.format(bigint);
    }
    return numStr;
  }

  /**
   * Format a duration value for display according to this properties attributes.
   * Example:
   *   176781 ==> "49:06:21 h:mm:ss"
   */
  public formatDuration(duration: number): string {
    let durationStr: string;
    if (!isNaN(duration)) {
      if (this.formatServiceDuration === undefined) {
        // Create duration formatter first time through
        const units: string = PropertyDefinition.mapDurationUnits(this.durationValueUnits);
        const fmt: string = PropertyDefinition.mapDurationtoFormatSpecifier(this.durationDisplayFormat);
        this.formatServiceDuration = new FormatDuration(this.vmContext.locale, units, fmt);
      }
      durationStr = this.formatServiceDuration.format(duration);
    }
    return durationStr;
  }

  /**
   * Format a date-time value for display according to this properties attributes.
   */
  public formatDateTime(dt: Date): string {
    let dtStr: string;
    if (dt !== undefined) {
      if (this.formatServiceDate === undefined) {
        // Create date-time formatter first time through
        this.formatServiceDate = new FormatDate(this.vmContext.locale);
      }
      dtStr = this.formatServiceDate.format(dt);
    }
    return dtStr;
  }

  /**
   * Format a BACnet date-time value for display according to this properties attributes.
   * Example:
   * 	"1180622F16503551"  ==> "6/22/2018 4:50 PM"
   *  "FFFFFFFFFFFFFFFF"  ==> "* / * / * *:*"
   */
  public formatBACnetDateTime(bndt: string): string {
    let bndtStr: string;
    if (bndt !== undefined) {
      if (this.formatServiceBAC === undefined) {
        const detail: BACnetDateTimeDetail = PropertyDefinition.toBACnetDateTimeDetail(this.bnDateTimeDetail);
        const res: BACnetDateTimeResolution = PropertyDefinition.toBACnetDateTimeResolution(this.bnDateTimeResolution);
        this.formatServiceBAC = new FormatBACnetDateTime(this.vmContext.locale, detail, res);
      }
      bndtStr = this.formatServiceBAC.format(bndt);
    }
    return bndtStr;
  }

}
