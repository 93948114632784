import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { AnyBitStringValue } from '@simpl/element-value-types';

import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-bitstring-property',
  templateUrl: './si-bitstring-property.component.html',
  styleUrls: ['./si-bitstring-property.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, SiPropertyPopoverComponent, SiTranslateModule]
})
export class SiBitstringPropertyComponent {
  @Input({ required: true }) property!: Property<AnyBitStringValue>;
  @Input() valueState: ValueState = 'none';
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<AnyBitStringValue>>();

  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  @ViewChild('popover', { static: true }) popover!: SiPropertyPopoverComponent;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  getState(value: string | number | boolean): any {
    return (this.popover.editValue ?? []).some((item: any) => item === value);
  }

  getPreviewState(value: string | number | boolean): boolean {
    return (this.property.value.value ?? []).some(item => item === value);
  }

  setState(value: string | number | boolean, state: boolean): void {
    this.popover.editValue = (this.popover.editValue ?? []).filter((item: any) => item !== value);
    if (state) {
      this.popover.editValue.push(value);
    }
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }
}
