import { AnyValueType } from '@simpl/element-value-types';
import { AnyPropertyValueType } from '@simpl/object-browser-ng';
import { Observable } from 'rxjs';

import { CommandInvocState } from './command-invoc-vm';

/**
 * Aggregate value state.
 */
export enum AggregateValueState {

  // Value is unknown
  Inactive = 0,

  // All values in aggregation are known and are aligned (able to report a single value)
  Aligned,

  // All values in aggregation are known and but are NOT aligned (NOT able to report a single value (a.k.a. "*"))
  Misaligned

}

/**
 * Property value or command subscription state.
 */
export enum PropertySubscriptionState {

  // Subscription is closed or not yet requested
  Closed = 0,

  // Value subscription request pending
  OpenPending,

  // Subscription is open
  Open,

  // Subscription error
  Error

}

/**
 * Property command invocation result.
 */
export class PropertyCommandResult {
  constructor(
    private readonly oid: string,
    private readonly pid: string,
    private readonly success: boolean,
    private readonly error?: string) {
  }
  public get objectId(): string {
    return this.oid;
  }
  public get propertyId(): string {
    return this.pid;
  }
  public get isSuccess(): boolean {
    return this.success;
  }
  public get errorMesg(): string {
    return this.error;
  }
}
