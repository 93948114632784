import { Observable } from 'rxjs';
// import { DurationDisplayFormat, DurationUnits } from "./data-model-helper";

/**
 * Command parameter data type.
 */
export enum CommandParamType {

  Numeric = 0, // includes 32-bit signed/unsigned integers and float

  Integer64, // 64-bit signed/unsigned integers

  Enum,

  StringType,

  DateTime,

  BACnetDateTime,

  Duration,

  Bitstring32,

  Bitstring64

}

/**
 * Numeric parameter sub-type.
 */
export enum NumericType {

  Float = 0,

  Integer

}

/**
 * Type of date-time parameter.
 */
export enum DateTimeType {

  DateAndTime = 0,

  DateOnly,

  TimeOnly
}

/**
 * Possible date field order for date value input.
 * This is needed to indicate to the view which of two format specifiers to inject into
 * the UI component used to prompt for data input.
 * Eventually, it is expected that the UI component used will recognize a locale and then
 * this enum and corresponding VM property can be removed.
 */
export enum DateFieldOrder {

  DayMonthYear = 0,

  MonthDayYear
}
