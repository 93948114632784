import { NgZone } from '@angular/core';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { BrowserObject, ServiceTextParameters } from '@gms-flex/services';
import { TraceServiceDelegate } from '../../shared/trace-service-delegate';
import { TraceModules } from '../../shared/trace-modules';
import { MemoState, ServiceCatalog } from './types';
import { MemoViewModelIfc } from './memo-vm.base';

export class MemoViewModel implements MemoViewModelIfc {

  private readonly traceSvc: TraceServiceDelegate;
  private readonly dirtyStateChangedInd: Subject<boolean>;
  private readonly emptyStateChangedInd: Subject<boolean>;
  private readonly dataChangedUndetectedInd: Subject<void>;
  private readonly contextChangedInd: Subject<void>;
  private destroyInd: Subject<void>;

  private bo: BrowserObject;
  private objectMemo: string;
  private objectMemoOriginal: string;
  private dirtyFlag: boolean;
  private emptyFlag: boolean;
  private memoStateInternal: MemoState;

  public get context(): BrowserObject {
    return this.bo;
  }

  public get contextChanged(): Observable<void> {
    return this.contextChangedInd;
  }

  public get memo(): string {
    return this.objectMemo;
  }

  public set memo(val: string) {
    if (this.memoState !== MemoState.EditActive) {
      return;
    }
    this.objectMemo = val;
    this.checkDirty();
  }

  public get isEditActive(): boolean {
    return this.memoState !== MemoState.Normal;
  }

  public get isMemoDirty(): boolean {
    return !!this.dirtyFlag;
  }

  public get isMemoEmpty(): boolean {
    return this.emptyFlag;
  }

  public get isSavePending(): boolean {
    return this.memoState === MemoState.SavePending;
  }

  public get dirtyStateChanged(): Observable<boolean> {
    return this.dirtyStateChangedInd;
  }

  public get emptyStateChanged(): Observable<boolean> {
    return this.emptyStateChangedInd;
  }

  public get dataChangedUndetected(): Observable<void> {
    return this.dataChangedUndetectedInd;
  }

  private get memoState(): MemoState {
    return this.memoStateInternal;
  }

  private set memoState(val: MemoState) {
    if (this.memoStateInternal === val) {
      return; // no-op
    }
    this.memoStateInternal = val;
    this.checkDirty();
  }

  private get isDisposed(): boolean {
    return this.destroyInd === undefined;
  }

  public constructor(
    private readonly svc: ServiceCatalog,
    private readonly id: string,
    private readonly ngZone: NgZone) {

    if (!svc) {
      throw new Error('invalid argument');
    }
    this.traceSvc = new TraceServiceDelegate(svc.traceService, TraceModules.memoPopover);
    this.traceSvc.info('Create object-memo view-model: id=%s', id);

    this.destroyInd = new Subject<void>();
    this.dirtyStateChangedInd = new Subject<boolean>();
    this.emptyStateChangedInd = new Subject<boolean>();
    this.dataChangedUndetectedInd = new Subject<void>();
    this.contextChangedInd = new Subject<void>();
    this.clear();
  }

  public dispose(): void {
    if (this.isDisposed) {
      return;
    }
    this.traceSvc.info('Dispose object-memo view-model: id=%s', this.id);
    this.clear();
    // Dispose all sub-vms here...
    this.destroyInd.next();
    this.destroyInd.complete();
    this.destroyInd = undefined;
  }

  public setContext(boInp: BrowserObject): Observable<void> {
    if (this.bo === boInp) {
      return of(undefined); // same as existing; no-op
    }
    return this.setContextInternal(boInp);
  }

  public setInitialView(): void {
    this.editCancel();
    if (this.isMemoEmpty) {
      this.editMemo();
    }
  }

  public editMemo(): void {
    if (this.isEditActive) {
      return;
    }
    this.memoState = MemoState.EditActive;
  }

  public editCancel(): void {
    if (this.memoState !== MemoState.EditActive) {
      return;
    }
    this.objectMemo = this.objectMemoOriginal;
    this.memoState = MemoState.Normal;
  }

  public editSave(): Observable<Error> {
    if (this.memoState !== MemoState.EditActive || !this.bo) {
      return throwError(new Error('invalid save request'));
    }
    const params: ServiceTextParameters = {
      /* eslint-disable-next-line */
      Memo: this.objectMemo || ''
    };
    this.memoState = MemoState.SavePending;
    return this.svc.objectsService.setServiceText(this.bo.ObjectId, params)
      .pipe(
        map(() => {
          // If call to server is successful, update the current memo string with the set value
          this.traceSvc.info('Memo save request successful');
          this.objectMemoOriginal = this.objectMemo;
          this.memoState = MemoState.Normal;
          this.checkEmpty();
          return undefined; // lack of Error object indicates success
        }),
        catchError(err => {
          this.traceSvc.info('Memo save request error: %s', err);
          this.memoState = MemoState.EditActive;
          return of(err); // save error!
        }));
  }

  private clear(): void {
    this.bo = undefined;
    this.objectMemo = undefined;
    this.objectMemoOriginal = this.objectMemo;
    this.memoState = MemoState.Normal;
    this.checkDirty();
    this.checkEmpty();
  }

  private checkDirty(): void {
    let flag = false;
    if (!this.objectMemoOriginal) {
      flag = this.objectMemo ? true : false;
    } else if (!this.objectMemo) {
      flag = true;
    } else if (this.objectMemoOriginal.length !== this.objectMemo.length) {
      flag = true;
    } else {
      flag = this.objectMemoOriginal !== this.objectMemo;
    }
    if (this.dirtyFlag !== flag) {
      this.dirtyFlag = flag;
      this.dirtyStateChangedInd.next(this.dirtyFlag);
    }
  }

  private checkEmpty(): void {
    const flag: boolean = this.objectMemoOriginal ? false : true;
    if (this.emptyFlag !== flag) {
      this.emptyFlag = flag;
      this.emptyStateChangedInd.next(this.emptyFlag);
    }
  }

  private setContextInternal(boInput: BrowserObject): Observable<void> {
    this.traceSvc.info('Set memo context: objectId=%s', boInput ? boInput.ObjectId : undefined);
    if (!boInput) {
      return of(undefined)
        .pipe(
          tap(() => {
            this.traceSvc.info('Clear memo context');
            this.clear();
            this.contextChangedInd.next();
          }));
    }
    return of(boInput)
      .pipe(
        tap(bo => {
          this.clear();
          this.bo = bo;
          this.contextChangedInd.next();
        }),
        concatMap(bo => this.svc.objectsService.getServiceText(bo.ObjectId)),
        map(serviceText => {
          this.traceSvc.info('Received service-text: objectId=%s, memo=%s', this.bo?.ObjectId, serviceText?.ServiceText?.Memo);
          if (serviceText?.ServiceText && serviceText.ObjectId === this.bo?.ObjectId) {
            this.objectMemo = serviceText.ServiceText.Memo;
            this.objectMemoOriginal = this.objectMemo;
            this.checkEmpty();
          }
        }),
        catchError(err => {
          this.traceSvc.error('Failed to read object memo: objectId=%s, %s', this.bo?.ObjectId, err);
          return of(undefined);
        }));
  }

}
