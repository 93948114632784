import { Link } from '@simpl/element-ng';
import { StringValue } from '@simpl/element-value-types';
import { OverrideMode } from '@simpl/object-browser-ng/property-viewer';

import { DataPoint, Scene, SceneModel } from '../interfaces/scene-editor';

export const DEFAULT_COMMAND_MODE: OverrideMode = 'direct';
export const NORMAL_MODE: OverrideMode = 'normal';
export const DISABLED_TEXT = '---';
export const DEFAULT_DATAPOINT_ICON = 'element-physical-output';

export const EMPTY_SCENE_MODEL: SceneModel = {
  isNew: true,
  readonly: false,
  scenes: [
    {
      name: 'Scene 1',
      id: '1'
    }
  ],
  dataPoints: [],
  commands: []
};

export const EMPTY_SCENE: Scene = {
  id: '1',
  name: 'Scene 1'
};

export const DISABLED_STRING_VALUE: StringValue = {
  type: 'string',
  value: DISABLED_TEXT,
  minLength: 2,
  maxLength: 100,
  placeholder: 'Name',
  readonly: true
};

export const getPropertyId = (id: string): string => id.split('.')[0];
export const getSelectedPropertyId = (id: string): string => id.split('.')[1] ?? id;
export const setPropertyId = (id: string, propId: string): string =>
  `${id}.${propId?.substring(propId.lastIndexOf('.') + 1)}`;
export const getPropertyName = (name: string): string => name.substring(name.lastIndexOf('.') + 1);
export const objectOrPropertyId = (id: string, propId: string): string => `${id}.${propId}`;

export interface ValidationError {
  errorObject: DataPoint | DataPoint[] | undefined;
  errorReason: 'DuplicateDataPoint' | 'CannotAddDatapoint';
}

export interface NotificationError {
  severity: 'warning' | 'danger';
  message: string;
  action: Link;
  errorParams: { [key: string]: any };
}
