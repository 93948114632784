<div class="datapoint-modal">
  <div class="modal-header">
    <h4 class="modal-title" [id]="modalTitleId">
      {{ 'SCENE_EDITOR.TEXT_SHORT.CONFIGURE_NEW_DATA_POINT' | translate }}
    </h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  @if (inlineNotificationError) {
    <si-inline-notification
      class="d-block mx-8 my-n3"
      [severity]="inlineNotificationError.severity"
      [message]="inlineNotificationError.message"
      [translationParams]="inlineNotificationError.errorParams"
      [action]="inlineNotificationError.action"
    />
  }
  <div class="modal-body">
    <div class="bg-base-1 mx-1 my-4 rounded-2 elevation-1 si-datapoint-view">
      <div class="datapoint-list">
        <div class="si-add-datapoint">
          <div class="col btn-outline-light d-flex">
            <si-content-action-bar
              class="ms-auto mr-4 me-4 mt-6"
              [primaryActions]="primaryActions"
              [viewType]="viewType"
            />
          </div>
        </div>
        @if (newDataPoints.length) {
          <div class="vertical-menu py-4 datapoint-list-container">
            @for (listItem of newDataPoints; track $index) {
              <a
                [class.active]="selectedDataPoint === listItem"
                class="focus-inside"
                tabindex="0"
                (click)="handleSelectedListItemChange(listItem)"
                (keydown.enter)="handleSelectedListItemChange(listItem)"
              >
                <div class="media-data">
                  <div class="col d-flex align-items-center">
                    <si-datapoint
                      [icon]="listItem.icon"
                      [name]="listItem.name"
                      [description]="listItem.description"
                      [alias]="listItem.alias"
                      [displayMode]="displayMode"
                    />
                    <i class="ms-auto icon element-right-2"></i>
                  </div>
                </div>
              </a>
            }
          </div>
        }
        @if (!newDataPoints.length) {
          <div>
            <div class="form-group text-center mt-5">
              <span>{{ 'SCENE_EDITOR.MESSAGES.NO_SUPPORTED_DATA_POINT_ADDED' | translate }}</span>
            </div>
          </div>
        }
      </div>
      <div class="seperator"> </div>
      <div class="property-list">
        @if (newSceneDataPoints.length) {
          <si-configure-datapoint
            #configureDatapoint
            [selectedDataPoint]="selectedDataPoint"
            [propertyList]="propertyCommands"
            [objectId]="objectId"
            [readOnly]="readOnly"
            [isDuplicateDataPoint]="isDuplicateDataPoint"
            [displayMode]="displayMode"
            (propertyChanged)="propertyChanged($event)"
            (removeDataPoint)="handleRemoveDataPoint($event)"
            (isPopOpen)="popOpenedState($event)"
            (addDataPoint)="addDataPointClick()"
            (command)="commandSelected($event)"
          />
        }
        @if (!newSceneDataPoints.length) {
          <si-empty-state
            icon="element-physical-output"
            heading="SCENE_EDITOR.DIALOG_TEXT_LONG.NO_DATA_POINT_HEADER"
            content="SCENE_EDITOR.DIALOG_TEXT_LONG.NO_DATA_POINT_CONTENT"
          >
            <button
              type="button"
              class="btn btn-default btn-secondary"
              [disabled]="sceneModel.readonly"
              (click)="addDataPointClick()"
            >
              {{ 'SCENE_EDITOR.TEXT_SHORT.ADD_DATAPOINT' | translate }}
            </button>
          </si-empty-state>
        }
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-secondary" (click)="cancel()">
      {{ 'SCENE_EDITOR.DIALOG_TEXT_SHORT.CANCEL' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-default btn-primary"
      [disabled]="inlineNotificationError || !newSceneDataPoints.length || isPropertyPopOpen"
      (click)="confirm()"
    >
      {{ 'SCENE_EDITOR.DIALOG_TEXT_SHORT.ADD' | translate }}
    </button>
  </div>
</div>
