import { computed, Directive, signal } from '@angular/core';

import { SiSelectRow } from '../si-select.types';
import { SiSelectOptionsStrategy } from './si-select-options-strategy';

/**
 * Input options strategy base class, for eagerly fetched options.
 */
@Directive({ standalone: true })
export abstract class SiSelectOptionsStrategyBase<T> implements SiSelectOptionsStrategy<T> {
  /**
   * Function to compare two values on equality which is used to match/filter options.
   */
  abstract optionsEqual(optionA: T, optionB: T): boolean;

  readonly rows = signal<SiSelectRow<T>[]>([]);

  readonly allRows = signal<SiSelectRow<T>[]>([]);

  private readonly value = signal<T[]>([]);

  readonly selectedRows = computed(() => {
    const values = this.value();
    return this.allRows()
      .map(row => (row.type === 'group' ? row.options : row))
      .flat()
      .filter(option => values.some(value => this.optionsEqual(value, option.value)));
  });

  onValueChange(value: T[]): void {
    this.value.set(value);
  }

  onFilter?(filterValue?: string): void;
}
