import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiNumberInputComponent } from '@simpl/element-ng/number-input';
import {
  SelectOption,
  SiSelectComponent,
  SiSelectSimpleOptionsDirective,
  SiSelectSingleValueDirective
} from '@simpl/element-ng/select';

import { ONE_DAY, ONE_MINUTE } from './si-date-range-filter.types';

interface OffsetOption extends SelectOption {
  offset: number;
}

@Component({
  selector: 'si-relative-date',
  templateUrl: './si-relative-date.component.html',
  styleUrl: './si-relative-date.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    SiNumberInputComponent,
    SiSelectComponent,
    SiSelectSingleValueDirective,
    SiSelectSimpleOptionsDirective
  ]
})
export class SiRelativeDateComponent implements OnInit, OnChanges {
  private cdRef = inject(ChangeDetectorRef);

  @Input() value = 0;
  @Input({ transform: booleanAttribute }) enableTimeSelection = false;
  @Input() valueLabel!: string;
  @Input() unitLabel!: string;
  @Output() readonly valueChange = new EventEmitter<number>();

  protected internalValue = 0;
  protected offset = 0;
  protected unit = 'days';

  private fullOffsetList: OffsetOption[] = [
    {
      id: 'minutes',
      title: $localize`:@@SI_DATE_RANGE_FILTER.MINUTES:Minutes`,
      offset: ONE_MINUTE
    },
    { id: 'hours', title: $localize`:@@SI_DATE_RANGE_FILTER.HOURS:Hours`, offset: 60 * ONE_MINUTE },
    { id: 'days', title: $localize`:@@SI_DATE_RANGE_FILTER.DAYS:Days`, offset: ONE_DAY },
    { id: 'weeks', title: $localize`:@@SI_DATE_RANGE_FILTER.WEEKS:Weeks`, offset: 7 * ONE_DAY },
    { id: 'months', title: $localize`:@@SI_DATE_RANGE_FILTER.MONTHS:Months`, offset: 30 * ONE_DAY },
    { id: 'years', title: $localize`:@@SI_DATE_RANGE_FILTER.YEARS:Years`, offset: 365 * ONE_DAY }
  ];

  protected offsetList: OffsetOption[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enableTimeSelection) {
      this.setupOffsetList();
    }
    if (changes.value && this.value !== this.internalValue) {
      this.internalValue = this.value;
      this.calculateOffset();
    }
  }

  ngOnInit(): void {
    this.setupOffsetList();
    this.calculateOffset();
  }

  private setupOffsetList(): void {
    if (this.enableTimeSelection) {
      this.offsetList = this.fullOffsetList;
    } else {
      this.offsetList = this.fullOffsetList.filter(item => item.offset >= ONE_DAY);
    }
  }

  private calculateOffset(): void {
    for (let i = this.offsetList.length - 1; i >= 0; i--) {
      const item = this.offsetList[i];
      const tmp = Math.round(this.internalValue / item.offset);
      if (tmp > 0) {
        this.offset = tmp;
        this.unit = item.id;
        this.changeUnit();
        break;
      }
    }
  }

  protected updateValue(): void {
    const item = this.offsetList.find(x => x.id === this.unit)!;
    this.internalValue = this.offset * item.offset;
    this.valueChange.emit(this.internalValue);
    this.cdRef.markForCheck();
  }

  protected changeUnit(): void {
    const item = this.offsetList.find(x => x.id === this.unit)!;
    this.offset = Math.max(1, Math.round(this.internalValue / item.offset));
    this.internalValue = this.offset * item.offset;
    this.valueChange.emit(this.internalValue);
    this.cdRef.markForCheck();
  }
}
