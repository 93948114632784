import { Component, Input } from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import {
  SiDropdownDirective,
  SiDropdownMenuDirective,
  SiDropdownToggleDirective
} from '@simpl/element-ng/dropdown';
import { SiListInteractionDirective } from '@simpl/element-ng/list-interaction';
import { SiMenuLegacyComponent, SiMenuLegacyItemComponent } from '@simpl/element-ng/menu-legacy';
import { SiTranslateModule } from '@simpl/element-ng/translate';

/**
 * @deprecated This component will be dropped without replacement.
 * It is recommended to rely entirely on the primary and vertical navbar.
 *
 * If this is not applicable, one can re-create the secondary navbar in a template driven manner:
 * @code ```html
 * <div class="navbar bg-base-1">
 *   <div class="navbar-collapse navbar-expand">
 *     <div class="navbar-nav nav nav-tabs">
 *       <a class="nav-link" routerLink="item1" routerLinkActive="active">item 1</a>
 *       <a class="nav-link" routerLink="item2" routerLinkActive="active">item 2</a>
 *       <button class="nav-link" type="button" [siNavbarDropdownTriggerFor]="dropdown">
 *         item 3
 *         <span class="dropdown-caret element-down-2"></span>
 *       </button>
 *     </div>
 *   </div>
 * </div>
 *
 * <ng-template #dropdown>
 *   <si-header-dropdown>
 *     <a si-header-dropdown-item routerLink="item3-1">item 3.1</a>
 *     <a si-header-dropdown-item routerLink="item3-2">item 3.2</a>
 *   </si-header-dropdown>
 * </ng-template>```
 */
@Component({
  selector: 'si-navbar-secondary',
  templateUrl: './si-navbar-secondary.component.html',
  styleUrl: './si-navbar-secondary.component.scss',
  standalone: true,
  imports: [
    SiDropdownDirective,
    SiDropdownMenuDirective,
    SiDropdownToggleDirective,
    SiListInteractionDirective,
    SiMenuLegacyComponent,
    SiMenuLegacyItemComponent,
    SiTranslateModule
  ]
})
export class SiNavbarSecondaryComponent {
  /**
   * Array of navbar items.
   */
  @Input() items: MenuItem[] = [];
  /**
   * If multiple secondary navbars are used each one needs a distinct aria label.
   * Required for a11y
   */
  @Input() navAriaLabel = $localize`:@@SI_NAVBAR_SECONDARY.NAV_LABEL:Secondary`;

  protected active = false;

  protected submenuActiveChange(active: boolean): void {
    this.active = active;
  }
}
