<div class="si-scene-properties-row row mx-0 rounded-2">
  <div class="col-md-4 col-4">
    <div class="mb-6 ms-2">
      <label [for]="sceneNameInputId" class="form-label">
        {{ 'SCENE_EDITOR.TEXT_SHORT.NAME' | translate }}
      </label>
      <input
        type="text"
        [id]="sceneNameInputId"
        [readonly]="restrictSceneEdit || readOnly"
        class="form-control input-box"
        [class.text-border-danger]="errorText"
        [value]="[displaySceneName]"
        (keyup)="hasChangesflag($any($event.target).value)"
      />
      @if (errorText) {
        <div class="text-danger">
          {{ errorText | translate }}
        </div>
      }
    </div>
  </div>
  <div class="col-md-8 col-8">
    <div class="mt-6 me-2 d-flex">
      @if (!readOnly) {
        <si-content-action-bar
          class="ms-auto"
          [primaryActions]="primaryActions"
          [secondaryActions]="secondaryActions"
          [viewType]="viewType"
        />
      }
    </div>
  </div>
</div>
