import { Injectable } from "@angular/core";
import { SummaryBarSnapInServiceBase } from "@gms-flex/summary-bar";

@Injectable()
export class SummaryBarBxSubstituteService extends SummaryBarSnapInServiceBase {
  public constructor() {
    super();
  }

  public buzzerVisible(): boolean {
    return false;
  }

}
