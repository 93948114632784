import { FormatHelper } from '../../../utilities/format-helper';
import { CommandParameter, ParameterType } from './gms-base-parameter';

export class StringParameter extends CommandParameter {

  private readonly _minLength: number;
  public get MinLength(): number {
    return this._minLength;
  }
  private readonly _maxLength: number;
  public get MaxLength(): number {
    return this._maxLength;
  }

  constructor(name: string, order: number, defaultValue: string,
    minLength: string, maxLength: string, dataType: string) {

    super(name, order, defaultValue, 0, dataType);

    this._minLength = minLength === undefined ? 0 : FormatHelper.StringToNumber(minLength);
    this._maxLength = maxLength === undefined ? Number.MAX_SAFE_INTEGER : FormatHelper.StringToNumber(maxLength);
    this.ParameterType = ParameterType.String;
  }
}
