import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { map, Observable, tap } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { ScheduleBx, ScheduleStatus } from "./schedule-proxy.model";
import { ScheduleProxyService } from "./schedule-proxy.sevice";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly scheduleProxyService: ScheduleProxyService) {

    this.traceService.info(TraceModules.bxServicesSchedules, 'ScheduleService created.');
  }

  public getSchedules(partitionId: string, entityId: string, scheduleName?: string, status?: ScheduleStatus): Observable<ScheduleBx[]> {
    this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules() called: partitionId: ${partitionId}, entityId: ${entityId}`);

    return this.scheduleProxyService.getSchedules(partitionId, entityId, status).pipe(
      map(result => this.filterSchedules(result, scheduleName)),
      tap(schedules => {
        this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules() returned: no of schedules: ${schedules.length} from backend
          partitionId=${partitionId}, scheduleName=${scheduleName}`);
      })
    );
  }

  private filterSchedules(schedules: ScheduleBx[], name?: string): ScheduleBx[] {
    if (name) {
      const found = this.findSchedule(schedules, name);
      return found ? [found] : [];
    } else {
      return schedules;
    }
  }

  private findSchedule(scheduleData: ScheduleBx[], name: string): ScheduleBx | undefined {
    return scheduleData.find(item => item.base.name === name);
  }
}
