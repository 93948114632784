import { EnumerationItem } from '@gms-flex/services';
import { isNullOrUndefined } from '@gms-flex/services-common';

import { CommandParameter, ParameterType } from './gms-base-parameter';

export class MultiStateParameter extends CommandParameter {

  private readonly _parameterEnums: EnumerationItem[] = [];
  public get ParameterEnum(): EnumerationItem[] {
    return this._parameterEnums;
  }

  constructor(name: string, order: number, defaultValue: string,
    minValue: string, maxValue: string, enumValues: EnumerationItem[], dataType: string,
    isPriorityParameter: boolean) {

    super(name, order, defaultValue, 0, dataType);

    this.MinValue = Number(minValue);
    this.MaxValue = Number(maxValue);
    this._parameterEnums = enumValues.filter(value => value.Descriptor !== '' && value.Descriptor !== ' ');
    this.ParameterType = isPriorityParameter ? ParameterType.Priority : ParameterType.MultiState;

    if (!isPriorityParameter) {
      this._parameterEnums.sort((a, b) => this.compareEnumTexts(a, b));
    }
  }
  private compareEnumTexts(a: EnumerationItem, b: EnumerationItem): number {
    const descA: string = a ? a.Descriptor : undefined;
    const descB: string = b ? b.Descriptor : undefined;
    return this.compareTexts(descA, descB);
  }
  private compareTexts(a: string, b: string): number {
    if (isNullOrUndefined(a)) {
      return isNullOrUndefined(b) ? 0 : -1;
    } else if (isNullOrUndefined(b)) {
      return 1;
    }
    let cmpVal = 0;
    if (a < b) {
      cmpVal = -1;
    } else if (a > b) {
      cmpVal = 1;
    }
    return cmpVal;
  }
}
