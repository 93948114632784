import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ModeData } from './data.model';

@Injectable({
  providedIn: 'root'
})
export class ModeService {
  private readonly currentMode: BehaviorSubject<ModeData> = new BehaviorSubject<ModeData>(null!);

  public setMode(mode: ModeData): void {
    this.currentMode.next(mode);
  }

  public getCurrentMode(): Observable<ModeData> {
    return this.currentMode.asObservable();
  }

  public getCurrentModeValue(): ModeData {
    return this.currentMode.value;
  }

}
