import { Event, EventColors } from '@gms-flex/services';
import { Subject } from 'rxjs';

import { AlarmState } from '../../types/datapoint/gms-alarm-state';
import { MergeMode } from '../../types/datapoint/gms-merge-mode';
import { GmsDataPointPropertyType } from '../../types/datapoint/gms-property-type';
import { DatapointStatus } from '../../types/datapoint/gms-status';
import { GmsDataType } from '../../types/gms-data-type';
import { FormatHelper } from '../../utilities/format-helper';
import { SvgUtility } from '../../utilities/parser';
import { AlarmInfo } from './gms-alarm-info';
import { GraphicsDatapointHelper } from './gms-datapoint-helper';

export class Datapoint {

  public propertyChanged: Subject<DataPointPropertyChangeArgs> = new Subject<DataPointPropertyChangeArgs>();
  // Datapoint ValueDone - a flag that is used for the validation of the datapoint exists or not: see it
  // in the generating graphics report,
  // symbol instance object Ref existence to update hidden status
  private _valueDone = false;
  public get ValueDone(): boolean {
    return this._valueDone;
  }
  public set ValueDone(value: boolean) {
    if (this._valueDone !== value) {
      this._valueDone = value;
    }
  }
  // Used for the file header.  It is set to true if this datapoint requires a subscription at least once (e.g. in an evaluation)
  private _requiresSubscription = false;
  public get RequiresSubscription(): boolean {
    return this._requiresSubscription;
  }
  public set RequiresSubscription(value: boolean) {
    if (this._requiresSubscription !== value) {
      this._requiresSubscription = value;
    }
  }
  private _isSubscribed = false;
  // Datapoint subscription status: true if datapoint is subcribed to datapoint updates service, false otherwise.
  public get IsSubscribed(): boolean {
    return this._isSubscribed;
  }
  public set IsSubscribed(value: boolean) {
    if (this._isSubscribed !== value) {
      this._isSubscribed = value;
    }
  }

  // The counter how many times the datapoint is used in any expression or other place
  private _countUsage = 0;
  public get CountUsage(): number {
    return this._countUsage;
  }
  public set CountUsage(value: number) {
    if (this._countUsage !== value) {
      this._countUsage = value;
    }
  }

  private _countSubscriptions = 0;
  public get CountSubscriptions(): number {
    return this._countSubscriptions;
  }
  public set CountSubscriptions(value: number) {
    if (this._countSubscriptions !== value) {
      this._countSubscriptions = value;
    }
  }

  // public string CountUsageAndSubscriptions { get { return _countUsage + "/" + _countSubscriptions; } }

  private _designation: string;
  public get Designation(): string {
    return this._designation;
  }
  public set Designation(value: string) {
    if (this._designation !== value) {
      this._designation = value;
    }
  }
  /**
   * Datapoint Id
   */
  private _id: string;
  public get Id(): string {
    return this._id;
  }
  public set Id(value: string) {
    if (this._id !== value) {
      this._id = value;
    }
  }

  // Object Id without property name suffixes
  private _objectId: string = undefined;
  public get ObjectId(): string {
    if (this.Id) {
      const splitResult: string[] = this.Id.split('.');
      this._objectId = splitResult.length > 0 ? splitResult[0] : undefined;
    }

    return this._objectId;
  }

  // Object Id used for matching events/alarms
  private _objectIdEvents: string = undefined;
  public get ObjectIdEvents(): string {
    return this._objectIdEvents;
  }
  public set ObjectIdEvents(value: string) {
    if (this._objectIdEvents !== value) {
      this._objectIdEvents = value;
    }
  }

  /**
   * Datapoint Managed Type
   */
  private _managedType: number;
  public get ManagedType(): number {
    return this._managedType;
  }
  public set ManagedType(value: number) {
    if (this._managedType !== value) {
      this._managedType = value;
    }
  }

  /**
   * Datapoint Managed Type Name
   */
  private _managedTypeName: string;
  public get ManagedTypeName(): string {
    return this._managedTypeName;
  }
  public set ManagedTypeName(value: string) {
    if (this._managedTypeName !== value) {
      this._managedTypeName = value;
    }
  }

  /**
   * Datapoint data type supported from GMS
   */
  private _gmsType: GmsDataType = GmsDataType.None;
  public get GmsType(): GmsDataType {
    return this._gmsType;
  }
  public set GmsType(value: GmsDataType) {
    if (this._gmsType !== value) {
      this._gmsType = value;
    }
  }

  /**
   * Data type that the Pvss.Types.Variant type can hold
   */
  private _datapointType: string;
  public get DatapointType(): string {
    return this._datapointType;
  }
  public set DatapointType(value: string) {
    if (this._datapointType !== value) {
      this._datapointType = value;
    }
  }

  private _minValue: number = Number.NaN;
  public get MinValue(): number {
    return this._minValue;
  }
  public set MinValue(value: number) {
    if (this._minValue !== value) {
      this._minValue = value;
    }
  }

  private _maxValue: number = Number.NaN;
  public get MaxValue(): number {
    return this._maxValue;
  }
  public set MaxValue(value: number) {
    if (this._maxValue !== value) {
      this._maxValue = value;
    }
  }

  private _minLongValue: Long;
  public get MinLongValue(): Long {
    return this._minLongValue;
  }
  public set MinLongValue(value: Long) {
    if (this._minLongValue !== value) {
      this._minLongValue = value;
    }
  }

  private _maxLongValue: Long;
  public get MaxLongValue(): Long {
    return this._maxLongValue;
  }
  public set MaxLongValue(value: Long) {
    if (this._maxLongValue !== value) {
      this._maxLongValue = value;
    }
  }
  /**
   */
  private _precision = 0;
  public get Precision(): number {
    return this._precision;
  }
  public set Precision(value: number) {
    if (this._precision !== value) {
      this._precision = value;
      this.NotifyPropertyChanged('Precision');
    }
  }

  /**
   */
  private _alias = '';
  public get Alias(): string {
    return this._alias;
  }
  public set Alias(value: string) {
    if (this._alias !== value) {
      this._alias = value;
      this.NotifyPropertyChanged('Alias');
    }
  }
  /**
   */
  private _isArray = false;
  public get IsArray(): boolean {
    return this._isArray;
  }
  public set IsArray(value: boolean) {
    if (this._isArray !== value) {
      this._isArray = value;
    }
  }

  /**
   */
  private _depthIndex = -1;
  public get DepthIndex(): number {
    return this._depthIndex;
  }
  public set DepthIndex(value: number) {
    if (this._depthIndex !== value) {
      this._depthIndex = value;
    }
  }

  private _graphicViewport: number[];
  public get GraphicViewport(): number[] {
    return this._graphicViewport;
  }
  public set GraphicViewport(value: number[]) {
    if (this._graphicViewport !== value) {
      this._graphicViewport = value;
    }
  }

  private _commands: string[];
  public get Commands(): string[] {
    return this._commands === undefined ? new Array<string>() : this._commands;
  }
  public set Commands(value: string[]) {
    this._commands = value;
  }

  /**
   * Datapoint Units
   */
  private _units: string;
  public get Units(): string {
    return this._units;
  }
  public set Units(value: string) {
    if (this._units !== value) {
      this._units = value;
      this.NotifyPropertyChanged('Units');
    }
  }

  private _functionName: string;
  public get FunctionName(): string {
    return this._functionName;
  }
  public set FunctionName(value: string) {
    if (this._functionName !== value) {
      this._functionName = value;
      this.NotifyPropertyChanged('FunctionName');
    }
  }

  private _objectModel: string;
  public get ObjectModelName(): string {
    return this._objectModel;
  }
  public set ObjectModelName(value: string) {
    if (this._objectModel !== value) {
      this._objectModel = value;
      this.NotifyPropertyChanged('ObjectModel');
    }
  }
  private _textGroupName: string;
  public get TextGroupName(): string {
    return this._textGroupName;
  }
  public set TextGroupName(value: string) {
    if (this._textGroupName !== value) {
      this._textGroupName = value;
    }
  }

  private _allowDayOfWeek: boolean;
  public get AllowDayOfWeek(): boolean {
    return this._allowDayOfWeek;
  }
  public set AllowDayOfWeek(value: boolean) {
    if (this._allowDayOfWeek !== value) {
      this._allowDayOfWeek = value;
    }
  }

  private _allowWildCards: boolean;
  public get AllowWildCards(): boolean {
    return this._allowWildCards;
  }
  public set AllowWildCards(value: boolean) {
    if (this._allowWildCards !== value) {
      this._allowWildCards = value;
    }
  }

  private _BACnetDateTimeDetail: number;
  public get BACnetDateTimeDetail(): number {
    return this._BACnetDateTimeDetail;
  }
  public set BACnetDateTimeDetail(value: number) {
    if (this._BACnetDateTimeDetail !== value) {
      this._BACnetDateTimeDetail = value;
    }
  }

  private _BACnetDateTimeResolution: number;
  public get BACnetDateTimeResolution(): number {
    return this._BACnetDateTimeResolution;
  }
  public set BACnetDateTimeResolution(value: number) {
    if (this._BACnetDateTimeResolution !== value) {
      this._BACnetDateTimeResolution = value;
    }
  }

  private _displayType: number;
  public get DisplayType(): number {
    return this._displayType;
  }
  public set DisplayType(value: number) {
    if (this._displayType !== value) {
      this._displayType = value;
    }
  }

  private _durationDisplayFormat: number;
  public get DurationDisplayFormat(): number {
    return this._durationDisplayFormat;
  }
  public set DurationDisplayFormat(value: number) {
    if (this._durationDisplayFormat !== value) {
      this._durationDisplayFormat = value;
    }
  }

  private _durationValueUnits: number;
  public get DurationValueUnits(): number {
    return this._durationValueUnits;
  }
  public set DurationValueUnits(value: number) {
    if (this._durationValueUnits !== value) {
      this._durationValueUnits = value;
    }
  }

  // BitStringLabels?: string[];
  // DisplayOffNormalOnly?: boolean;

  /**
   * Index to evaluate against the array property of a data point.
   */
  private _status: DatapointStatus = DatapointStatus.Undefined;
  public get Status(): DatapointStatus {
    return this._status;
  }
  public set Status(value: DatapointStatus) {
    if (this._status !== value) {
      const previousStatus: DatapointStatus = this._status;
      this._status = value;

      if (previousStatus <= DatapointStatus.Pending
                && value > DatapointStatus.Pending) {
        // Datapoint is resolved if the status change from pending
        // to greater than pending indicate it
        // Must happen once per datapoint
        // Introduce a separate property, if needed
        this.NotifyPropertyChanged('Resolved');
      }

      this.NotifyPropertyChanged('Status');
      this.NotifyPropertyChanged('Value');
    }
  }

  private _value: any;
  public get Value(): any {
    return this._value;
  }
  public set Value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.NotifyPropertyChanged('Value');
    }
  }

  private _displayValue: any;
  public get DisplayValue(): any {
    return this._displayValue;
  }
  public set DisplayValue(value: any) {
    if (this._displayValue !== value) {
      this._displayValue = value;
      this.NotifyPropertyChanged('DisplayValue');
    }
  }

  /**
   * Datapoint(property)  Descriptor
   */
  private _descriptor: string;
  public get Descriptor(): string {
    return this._descriptor;
  }
  public set Descriptor(value: string) {
    if (this._value !== value) {
      this._descriptor = value;
      this.NotifyPropertyChanged('Descriptor');
    }
  }

  /**
   * Default Datapoint(property) Image
   */
  private _propertyImage: string;
  public get PropertyImage(): string {
    return this._propertyImage;
  }
  public set PropertyImage(value: string) {
    if (this._value !== value) {
      this._propertyImage = value;
      this.NotifyPropertyChanged('PropertyImage');
    }
  }

  private _alarmState: AlarmState = AlarmState.None;
  public get AlarmState(): AlarmState {
    return this._alarmState;
  }
  public set AlarmState(value: AlarmState) {
    if (this._alarmState !== value) {
      this._alarmState = value;
    }
  }

  private readonly alarmInfos: Map<number, AlarmInfo> = new Map<number, AlarmInfo>();
  public get AlarmInfos(): AlarmInfo[] {
    return Array.from(this.alarmInfos.values());
  }

  public get HasAlarms(): boolean {
    return this.alarmInfos.size > 0;
  }
  private _systemName = '';
  public get SystemName(): string {
    return this._systemName;
  }
  public constructor(designation: string = '') {

    this.Designation = designation;
    this.RequiresSubscription = true;
    this.ValueDone = false;
    this.DepthIndex = -1;
  }

  public ResetAlarmState(): void {
    this._alarmState = AlarmState.None;
    this.alarmInfos.clear();
  }

  public SetEventInfo(event: Event): void {
    // update the collection by using the event id
    const state: AlarmState = AlarmState[event.originalState];

    if (state !== undefined) {
      // Update the active state from the event.
      const showActive: boolean = event.srcState === 'Active';

      if (this.alarmInfos.has(event.eventId)) {
        const alarmInfo: AlarmInfo = this.alarmInfos.get(event.eventId);
        alarmInfo.IsActive = showActive;
        alarmInfo.State = state;
      } else {
        const alarmInfo: AlarmInfo = new AlarmInfo(this._designation, state);
        alarmInfo.Id = event.eventId;
        alarmInfo.IsActive = showActive;
        alarmInfo.SrcDesignationEvents = event.designationList[0].Descriptor ?? event.srcDesignation;
        alarmInfo.DisciplineColor = this.getDisciplineColor(event);
        this.alarmInfos.set(alarmInfo.Id, alarmInfo);
      }
    } else if (this.alarmInfos.has(event.eventId)) {
      this.alarmInfos.delete(event.eventId);
    } else {
      return;
    }

    // Get the cumulative alarm state for the datapoint.
    let prioAlarmInfo: AlarmInfo = this.AlarmInfos.find(alarminfo => alarminfo.State === AlarmState.Unprocessed);
    if (prioAlarmInfo === undefined) {
      prioAlarmInfo = this.AlarmInfos.find(alarminfo => alarminfo.State === AlarmState.Acked);
      if (prioAlarmInfo === undefined) {
        prioAlarmInfo = this.AlarmInfos.find(alarminfo => alarminfo.State === AlarmState.ReadyToBeReset);
        if (prioAlarmInfo === undefined) {
          prioAlarmInfo = this.AlarmInfos.find(alarminfo => alarminfo.State === AlarmState.Closed);
        }
      }
    }

    // Set the cumulative alarm state for the datapoint.
    if (prioAlarmInfo === undefined) {
      this._alarmState = AlarmState.None;
      this.alarmInfos.clear();
      this.NotifyPropertyChanged('AlarmState');
    } else {

      this._alarmState = prioAlarmInfo.State;
      this.NotifyPropertyChanged('AlarmState');
    }
  }

  public ClearEvents(): void {
    if (this.AlarmState > AlarmState.None) {
      this._alarmState = AlarmState.None;
      this.alarmInfos.clear();
      this.NotifyPropertyChanged('AlarmState');
    }
  }

  public MergeDataFrom(datapoint: Datapoint, mergeMode: MergeMode = MergeMode.Merge): void {

    // Id
    if (mergeMode === MergeMode.Update || (this.Id === undefined && datapoint.Id !== undefined)) {
      this.Id = datapoint.Id;
    }

    // ManagedType
    if (mergeMode === MergeMode.Update || (this.ManagedType === undefined && datapoint.ManagedType !== undefined)) {
      this.ManagedType = datapoint.ManagedType;
    }

    // ManagedTypeName
    if (mergeMode === MergeMode.Update || (this.ManagedTypeName === undefined && datapoint.ManagedTypeName !== undefined)) {
      this.ManagedTypeName = datapoint.ManagedTypeName;
    }

    // GmsType
    if (mergeMode === MergeMode.Update || (this.GmsType === GmsDataType.None && datapoint.GmsType !== GmsDataType.None)) {
      this.GmsType = datapoint.GmsType;
    }

    // DatapointType
    if (mergeMode === MergeMode.Update || (this.DatapointType === 'Uninitialized' && datapoint.DatapointType !== 'Uninitialized')) {
      this.DatapointType = datapoint.DatapointType;
    }

    // Precision
    if (mergeMode === MergeMode.Update || (this.Precision === Number.MIN_VALUE && datapoint.Precision !== Number.MIN_VALUE)) {
      this.Precision = datapoint.Precision;
    }

    // MinValue
    if (mergeMode === MergeMode.Update || (Number.isNaN(this.MinValue) && !Number.isNaN(datapoint.MinValue))) {
      this.MinValue = datapoint.MinValue;
    }

    // MaxValue
    if (mergeMode === MergeMode.Update || (Number.isNaN(this.MaxValue) && !Number.isNaN(datapoint.MaxValue))) {
      this.MaxValue = datapoint.MaxValue;
    }

    if (mergeMode === MergeMode.Update) {

      // NOTE:
      // PropertiesStatus = datapoint.PropertiesStatus;
      //
    }
  }

  public Deserialize(node: Node): void {

    // Designation
    let result: string = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.Designation);
    if (result !== undefined) {
      this.Designation = result;
    }
    // Id
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.Id);
    if (result !== undefined) {
      this.Id = result;
      // Defect 2524373: [FT8] Flex client shows values as #COM in graphic templates [Origin PCR - 2522327] - point references in template symbols - V8
      this._systemName = GraphicsDatapointHelper.getSystemNameFromIdSeparator(result);
    }
    // ManagedType
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.ManagedType);
    if (result !== undefined) {
      this.ManagedType = +result;
    }
    // ManagedTypeName
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.ManagedTypeName);
    if (result !== undefined) {
      this.ManagedTypeName = result;
    }
    // GmsType
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.GmsType);
    if (result !== undefined) {
      this.GmsType = GmsDataType[result];
    }
    // DataPointType
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.DatapointType);
    if (result !== undefined) {
      this.DatapointType = result;
    }
    // Min
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.Min);
    if (result !== undefined) {
      this.MinValue = FormatHelper.StringToNumber(result); // Number(result);
    }
    // Max
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.Max);
    if (result !== undefined) {
      this.MaxValue = FormatHelper.StringToNumber(result); // Number(result);
    }
    // Precision
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.Precision);
    if (result !== undefined) {
      this.Precision = FormatHelper.StringToNumber(result); // Number(result);
    }
    // DepthIndex
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.DepthIndex);
    if (result !== undefined) {
      this.DepthIndex = FormatHelper.StringToNumber(result); // Number(result);
    }
    // GraphicViewport
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.GraphicViewport);
    if (result !== undefined) {
      const viewport: string = result;
      if (viewport !== undefined) {
        this.GraphicViewport = viewport.split(',').map(x => Number(x));
      }
    }
    // Commands
    result = SvgUtility.GetAttributeValue(node, GmsDataPointPropertyType.Commands);
    if (result !== undefined) {
      this.String2Commands(result);
    }
  }

  /**
   *
   */
  public SubscribeForUpdates(): void {
    // NOTE:
    // this.CountSubscriptions += 1;
  }

  /**
   *
   */
  public unSubscribeFromUpdates(): void {
    // NOTE:
    // this.CountSubscriptions -= 1;
  }

  protected NotifyPropertyChanged(propertyName: string = ''): void {
    const args: DataPointPropertyChangeArgs = new DataPointPropertyChangeArgs();
    args.PropertyName = propertyName;
    args.SourceDatapoint = this;
    this.propertyChanged.next(args);
  }

  private getDisciplineColor(event: Event): string {
    return `rgb(${event.category.colors.get(EventColors.ButtonGradientDark)})`;
  }

  private String2Commands(commands: string): void {
    this.Commands.length = 0;
    if (commands !== undefined || commands !== '') {
      this.Commands = commands.split(',');
    }
  }
}

export class DataPointPropertyChangeArgs {
  private _propertyName: string;
  public get PropertyName(): string {
    return this._propertyName;
  }
  public set PropertyName(value: string) {

    if (this._propertyName !== value) {
      this._propertyName = value;
    }
  }
  private _defaultValue: string;
  public get DefaultValue(): string {
    return this._defaultValue;
  }
  public set DefaultValue(value: string) {

    if (this._defaultValue !== value) {
      this._defaultValue = value;
    }
  }

  private _sourceDatapoint: Datapoint;
  public get SourceDatapoint(): Datapoint {
    return this._sourceDatapoint;
  }
  public set SourceDatapoint(value: Datapoint) {
    if (this._sourceDatapoint !== value) {
      this._sourceDatapoint = value;
    }
  }
}
