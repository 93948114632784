import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiComfortScoreStatusComponent } from './si-comfort-score-status.component';
import { ComfortScoreItem } from './si-comfort-score.model';

@Component({
  selector: 'si-comfort-score',
  templateUrl: './si-comfort-score.component.html',
  styleUrl: './si-comfort-score.component.scss',
  standalone: true,
  imports: [NgClass, SiComfortScoreStatusComponent, SiTranslateModule]
})
export class SiComfortScoreComponent implements OnChanges {
  @Input() items: ComfortScoreItem[] = [];
  @Input({ transform: booleanAttribute }) showValues = true;
  @Input({ transform: booleanAttribute }) labelOnly = false;
  @Input() gridParts = 2;

  @Output() readonly selected = new EventEmitter<ComfortScoreItem>();

  protected trackByItem = (index: number, item: ComfortScoreItem): any => item;

  protected gridPartArray: any[] = new Array(this.gridParts);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridParts) {
      this.gridPartArray = new Array(this.gridParts);
    }
  }
}
