import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FullSnapInId } from '../fullsnapinid/fullsnapinid.model';

@Injectable({
  providedIn: 'root'
})
export abstract class IPreselectionService {

  public typeId!: string;

  /*
   * This method is called by HFW in order to allow snapins evaluate if it can handle the corresponding message.
   * The snapin shall return an observable 'immediately'!
   * On the returned observable, the snapin shall push the evaluated result (true if it can handle the message, else false) within a wellknown timeout.
   * The timeout is defined in the HLDL (hfw-core) and shall not be larger than 100ms
   * Important: The returned observable must support 'teardownLogic'!!
   * => Any client subscribing to the observable can call 'unsubscribe' on the correponding subscription. This causes the disposal of all underlying resources.
   *
   * @param {Array<string>} messageTypes, the messageTypes.
   * @param {*} messageData, the messageData.
   * @param {FullSnapInId} fullId, the snapinId for which the preselection is invoked.
   * @returns {Observable<boolean>}, true if the specified snapin can handle the message, else false.
   *
   * @memberOf IPreselectionService
   */
  public abstract receivePreSelection(messageTypes: string[], messageBody: any, fullId: FullSnapInId): Observable<boolean>;
}
