import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'si-input-pill',
  standalone: true,
  imports: [],
  templateUrl: './si-input-pill.component.html',
  styleUrl: './si-input-pill.component.scss'
})
export class SiInputPillComponent {
  @Output() readonly deletePill = new EventEmitter<void>();

  @Input() @HostBinding('class.pe-4') hideClose = false;
}
