import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { ConfigOption, FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { SiFormModule } from '@simpl/element-ng/form';
import { SiTranslateService } from '@simpl/element-ng/translate';

import { SiFormlyButtonComponent } from './fields/button/si-formly-button.component';
import { SiFormlyDateTimeComponent } from './fields/datetime/si-formly-datetime.component';
import { SiFormlyEmailComponent } from './fields/email/si-formly-email.component';
import { SiFormlyPasswordComponent } from './fields/password/si-formly-password.component';
import { SiFormlySelectComponent } from './fields/select/si-formly-select.component';
import { SiFormlyTextDisplayComponent } from './fields/text/si-formly-text-display.component';
import { SiFormlyTextareaComponent } from './fields/textarea/si-formly-textarea.component';
import { SiFormlyTimeComponent } from './fields/time/si-formly-time.component';
import { registerTranslateExtension } from './si-formly-translate.extension';
import { SiFormlyComponent } from './si-formly.component';
import { SiFormlyArrayComponent } from './structural/si-formly-array/si-formly-array.component';
import { SiFormlyObjectGridComponent } from './structural/si-formly-object-grid/si-formly-object-grid.component';
import { SiFormlyObjectPlainComponent } from './structural/si-formly-object-plain/si-formly-object-plain.component';
import { SiFormlyObjectComponent } from './structural/si-formly-object/si-formly-object.component';
import { SiFormlyObjectTabsetComponent } from './structural/si-formly-tabset/si-formly-object-tabset.component';
import { SiFormlyHorizontalWrapperComponent } from './wrapper/si-formly-horizontal-wrapper.component';
import { SiFormlyIconWrapperComponent } from './wrapper/si-formly-icon-wrapper.component';
import { SiFormlyNoIconWrapperComponent } from './wrapper/si-formly-no-icon-wrapper.component';
import { SiFormlyWrapperComponent } from './wrapper/si-formly-wrapper.component';

const DYN_UI_FORMLY_CONFIG = {
  types: [
    { name: 'string', extends: 'input' },
    {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    },
    {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    },
    {
      name: 'checkbox',
      defaultOptions: {
        props: {
          formCheck: 'form-check'
        }
      }
    },
    { name: 'boolean', extends: 'checkbox' },
    { name: 'enum', extends: 'select' },
    { name: 'array', component: SiFormlyArrayComponent },
    { name: 'object', component: SiFormlyObjectComponent },
    { name: 'object-plain', component: SiFormlyObjectPlainComponent },
    { name: 'object-grid', component: SiFormlyObjectGridComponent },
    { name: 'tabset', component: SiFormlyObjectTabsetComponent },
    { name: 'textdisplay', component: SiFormlyTextDisplayComponent },
    { name: 'email', component: SiFormlyEmailComponent, wrappers: ['form-field'] },
    { name: 'date', component: SiFormlyDateTimeComponent, wrappers: ['form-field'] },
    { name: 'datetime', extends: 'date' },
    { name: 'password', component: SiFormlyPasswordComponent, wrappers: ['form-field'] },
    { name: 'textarea', component: SiFormlyTextareaComponent, wrappers: ['form-field'] },
    { name: 'button', component: SiFormlyButtonComponent },
    { name: 'si-select', component: SiFormlySelectComponent, wrappers: ['form-field-no-icon'] },
    { name: 'time', component: SiFormlyTimeComponent, wrappers: ['form-field-no-icon'] }
  ],
  wrappers: [
    // { name: 'form-field-original', component: FormlyWrapperFormField }, // Overrides the default
    { name: 'form-field', component: SiFormlyWrapperComponent }, // Overrides the default
    { name: 'form-field-no-icon', component: SiFormlyNoIconWrapperComponent },
    { name: 'form-field-horizontal', component: SiFormlyHorizontalWrapperComponent },
    { name: 'icon-wrapper', component: SiFormlyIconWrapperComponent }
  ]
};

@NgModule({
  declarations: [SiFormlyComponent],
  exports: [SiFormlyComponent],
  imports: [
    CommonModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot(DYN_UI_FORMLY_CONFIG),
    ReactiveFormsModule,
    SiFormlyArrayComponent,
    SiFormlyButtonComponent,
    SiFormlyDateTimeComponent,
    SiFormlyEmailComponent,
    SiFormlyHorizontalWrapperComponent,
    SiFormlyIconWrapperComponent,
    SiFormlyNoIconWrapperComponent,
    SiFormlyObjectComponent,
    SiFormlyObjectGridComponent,
    SiFormlyObjectPlainComponent,
    SiFormlyObjectTabsetComponent,
    SiFormlyPasswordComponent,
    SiFormlySelectComponent,
    SiFormlyTextareaComponent,
    SiFormlyTextDisplayComponent,
    SiFormlyWrapperComponent,
    SiFormModule
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [SiTranslateService]
    }
  ]
})
export class SiFormlyModule {
  static forRoot(formlyConfig: ConfigOption): ModuleWithProviders<SiFormlyModule> {
    return {
      ngModule: SiFormlyModule,
      providers: [{ provide: FORMLY_CONFIG, multi: true, useValue: formlyConfig }]
    };
  }
}
