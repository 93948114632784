import { Injectable } from '@angular/core';
import { TraceModules } from '@gms-flex/services';
import { SettingsServiceBase, TraceService } from '@gms-flex/services-common';
import { Observable, of } from 'rxjs';

@Injectable()
export class SettingsBxSubstituteService extends SettingsServiceBase {

  public constructor(
    private readonly traceService: TraceService) {
    super();
    this.traceService.info(TraceModules.settings, 'SettingsBxSubstituteService created.');
  }

  public getSettings(settingID: string): Observable<string> {
    const item = localStorage.getItem(settingID);
    return of(item);
  }

  public putSettings(settingID: string, settingValue: string | JSON): Observable<boolean> {
    this.traceService.info(TraceModules.settings, 'putSettings() called, Setting ID: %s', settingID);
    if (typeof settingValue === 'string') {
      localStorage.setItem(settingID, settingValue);
    } else {
      localStorage.setItem(settingID, JSON.stringify(settingValue));
    }
    return of(true);
  }

  public deleteSettings(settingID: string): Observable<boolean> {
    this.traceService.info(TraceModules.settings, 'deleteSettings() called, Setting ID: %s', settingID);
    localStorage.removeItem(settingID);
    return of(true);
  }
}
