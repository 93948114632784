import { Pipe, PipeTransform } from '@angular/core';

import { formatNumber, getFractionDigits } from '../../helpers/number-helper';

@Pipe({
  name: 'siBigNumber',
  standalone: true
})
export class SiBigNumberPipe implements PipeTransform {
  transform(value?: string, resolution?: string, decimalsAllowed?: boolean): string {
    const numberValue = Number(value);
    if (value === undefined || isNaN(numberValue)) {
      return '';
    }
    const numberObject =
      !decimalsAllowed && Number.isInteger(numberValue) ? BigInt(value) : numberValue;
    return formatNumber(
      numberObject,
      decimalsAllowed ? getFractionDigits(Number(resolution) || 0) : 0
    );
  }
}
