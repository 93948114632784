import { BrowserObject, Command, PropertyCommand, PropertyDetails } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';

import { TraceModules } from '../shared/trace-modules';
import { CommandViewModel } from './command-vm';
import { PropertyDefinition } from './property-definition-vm';
import { AggregatePropertyInstance } from './property-instance-aggregate-vm';
import { ObjectPropertyInstance } from './property-instance-single-vm';
import { PropertyInstance } from './property-instance-vm';
import { ServiceStore, ViewModelContext } from './snapin-vm.types';

/**
 * Single property view-model.
 */
export class PropertyViewModel {

  protected readonly trmod: string = TraceModules.pvc;

  public propertyDef: PropertyDefinition;
  public propertyInstance: PropertyInstance;

  // NOTE: A reference to this array is passed to the `propertyInstance`.  If this happens
  //  to be an aggregate-property instance, this object will in turn pass the reference
  //  on to its subordinate object-property instances.  In this way, the base list of commands
  //  is shared.  Further, the list can be added to by an object-property instance if a command
  //  COV is received with new commands.
  //  This is not an ideal setup, but it is expected to be deprecated with an upcoming change
  //  to the WSI: the command COV will contain ALL commands with a new flag indicating which
  //  are active.  When this new behavior is adopted, the SNI will no longer have to perform
  //  a command READ operation on each property to get the full command list to store here.
  private readonly propertyCmdListBase: Command[];

  /**
   * Locale to use for formatting.
   */
  public get locale(): string {
    return this.vmContext.locale;
  }

  public get def(): PropertyDefinition {
    return this.propertyDef;
  }

  public get instance(): PropertyInstance {
    return this.propertyInstance;
  }

  /**
   * Compare the display order of two properties.
   * if x < y, returns -value
   * if x > y, returns +value
   * if x === y, returns 0
   */
  public static displayOrderCompare(x: PropertyViewModel, y: PropertyViewModel): number {
    const xIndexed = Boolean(x?.def?.isIndexed);
    const yIndexed = Boolean(y?.def?.isIndexed);
    const xOrder: number = x?.def?.displayOrder;
    const yOrder: number = y?.def?.displayOrder;

    if (xIndexed !== yIndexed) {
      if (xIndexed) {
        return -1;
      } else {
        return 1;
      }
    } else {
      if (isNaN(xOrder)) {
        return isNaN(yOrder) ? 0 : -1;
      } else if (isNaN(yOrder)) {
        return 1;
      }
    }

    return xOrder - yOrder;
  }

  /**
   * Factory method to create PropertyViewModel instance representing a single-object property.
   */
  public static createSingleObjectPropertyVM(
    traceService: TraceService,
    svcStore: ServiceStore,
    vmContext: ViewModelContext,
    browserObject: BrowserObject,
    pd: PropertyDetails,
    isFunc: boolean,
    defaultPropId: string): PropertyViewModel {

    // Create VM with definition information
    const pvm: PropertyViewModel = new PropertyViewModel(
      traceService,
      vmContext,
      pd,
      isFunc,
      defaultPropId
    );

    // Add property instance for single object
    pvm.propertyInstance = new ObjectPropertyInstance(
      traceService,
      svcStore,
      vmContext,
      pvm.propertyDef,
      pvm.propertyCmdListBase,
      browserObject
    );

    return pvm;
  }

  /**
   * Factory method to create PropertyViewModel instance representing a multiple-object property (aggregate).
   */
  public static createMultipleObjectPropertyVM(
    traceService: TraceService,
    svcStore: ServiceStore,
    vmContext: ViewModelContext,
    pd: PropertyDetails,
    isFunc: boolean,
    defaultPropId: string): PropertyViewModel {

    // Create VM with definition information
    const pvm: PropertyViewModel = new PropertyViewModel(
      traceService,
      vmContext,
      pd,
      isFunc,
      defaultPropId
    );

    // Add aggregate property instance for multiple objects
    pvm.propertyInstance = new AggregatePropertyInstance(
      traceService,
      svcStore,
      vmContext,
      pvm.propertyDef,
      pvm.propertyCmdListBase
    );

    return pvm;
  }

  /**
   * Private constructor.
   * Instances created by single/multi-object factory methods.
   */
  protected constructor(
    private readonly traceService: TraceService,
    private readonly vmContext: ViewModelContext,
    pd: PropertyDetails,
    isFunc: boolean,
    defaultPropertyId: string) {

    if (!pd || !vmContext) {
      throw new Error('undefined argument');
    }

    // Property definition
    this.propertyDef = new PropertyDefinition(
      vmContext,
      pd,
      isFunc,
      pd.PropertyName === defaultPropertyId);

    // To be set by single/multi-object factory method
    this.propertyCmdListBase = []; // reference to this array will be passed to property instance!
    this.propertyInstance = undefined;
  }

  public setHiddenByFilter(flag: boolean): void {
    if (this.propertyInstance) {
      this.propertyInstance.isHiddenByFilter = flag;
    }
  }

  /**
   * Set the base list of commands for the property.
   * Expected to be called when commands have been read from the server (prior to cmd subscription)
   */
  public setBaseCommands(propCmd: PropertyCommand): void {
    this.propertyCmdListBase.length = 0;
    if (propCmd) {
      if (propCmd.ErrorCode > 0) {
        this.traceService.error(this.trmod, 'Property command object error: pid=%s, errorCode=%s',
          this.def.propertyId, propCmd.ErrorCode);
        return;
      }

      const cmdList: Command[] = propCmd.Commands || [];
      this.propertyCmdListBase.push(...cmdList);

      if (this.traceService.isDebugEnabled(this.trmod)) {
        this.traceService.debug(this.trmod, 'Set base property commands: pid=%s, cmds=%s',
          this.def.propertyId,
          this.propertyCmdListBase.map(c => String(`${c.Id}|${c.Descriptor}|${c.GroupNumber || '-'}`)).join(', '));
      }
    }

    // Force update of exposed command list
    this.propertyInstance.updateCommandList();
  }

}
