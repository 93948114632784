import { Observable } from 'rxjs';

/**
 * Base class for the Settings service.
 */
export abstract class SettingsServiceBase {

  /**
   * Deletes the sepecified setting from Web API.
   *
   * @abstract
   * @param {string} settingID
   * @returns {Observable<boolean>}
   *
   * @memberOf SettingsServiceBase
   */
  public abstract deleteSettings(settingID: string): Observable<boolean>;

  /**
   * Gets the specifed Settings from Web API.
   *
   * @abstract
   * @param {string} settingID
   * @returns {Observable<string>} An Observable returning a string.
   *
   * @memberOf SettingsServiceBase
   */
  public abstract getSettings(settingID: string): Observable<string>;

  /**
   * Puts the settings for specified snapin control.
   *
   * @abstract
   * @param {string} settingID .
   * @param {string | JSON} settingValue .
   * @returns {Observable<boolean> } An Observable returning a base64 encoded string
   * representing the icon image in PNG format.
   *
   * @memberOf SettingsServiceBase
   */
  public abstract putSettings(settingID: string, settingValue: string | JSON): Observable<boolean> ;
}
