<span
  class="d-inline-block position-relative"
  [attr.aria-label]="altText ? (altText | translate) : null"
  [attr.role]="altText ? 'img' : 'presentation'"
  [ngClass]="[icon ?? '', color ?? '', size]"
>
  @if (stackedIcon) {
    <i
      class="position-absolute start-0"
      aria-hidden="true"
      [ngClass]="[stackedIcon, stackedColor ?? '']"
    ></i>
  }
</span>
