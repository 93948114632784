import { GraphicType } from '../types/gms-graphic-types';
import { GmsGraphicInfoPropertyTypes } from '../types/gms-graphicinfo-property-types';
import { SvgUtility } from '../utilities/parser';
// import { Datapoint } from "../processor/datapoint/gms-datapoint";

export class GraphicInfo {

  // GraphicType
  private _graphicType: GraphicType;

  // Selected Graphic Name
  private _name: string;
  public get Name(): string {
    return this._name;
  }
  public set Name(value: string) {
    if (this._name !== value) {
      this._name = value;
    }
  }

  // DepthName
  // private _depthName: string;

  // Graphic Version
  private _version: string;

  // Graphic CreationDate
  // private _creationDate: string;

  // NOTE
  // private _primarySelection: Datapoint[];

  // Rect Viewport
  // ManualViewport
  // HasPrimarySelection

  // LogicalScaleFactor
  private _logicalScaleFactor: number;

  public Deserialize(node: Node): void {

    const classTag = 'Class';
    const attrClass: Attr = (node as Element).attributes[classTag];
    if (attrClass === undefined) {
      // Add Traces
      return;
    }

    const className: string = attrClass.nodeValue;

    if (className === 'Graphic') {

      let result: string = SvgUtility.GetAttributeValue(node, GmsGraphicInfoPropertyTypes.GraphicType);
      if (result !== undefined) {
        this._graphicType = GraphicType[result];
      }

      result = SvgUtility.GetAttributeValue(node, GmsGraphicInfoPropertyTypes.Version);
      if (result !== undefined) {
        this._version = result;
      }

      result = SvgUtility.GetAttributeValue(node, GmsGraphicInfoPropertyTypes.CreationDate);
      if (result !== undefined) {
        this._version = result;
      }

      result = SvgUtility.GetAttributeValue(node, GmsGraphicInfoPropertyTypes.LogicalScaleFactor);
      if (result !== undefined) {
        this._logicalScaleFactor = Number(result);
      }
    }
  }
}
