import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { SiFormItemComponent } from '@simpl/element-ng/form';

import { SiFormlyWrapperComponent } from './si-formly-wrapper.component';

@Component({
  selector: 'si-formly-no-icon-wrapper',
  templateUrl: './si-formly-wrapper.component.html',
  standalone: true,
  imports: [SiFormItemComponent, NgIf, FormlyModule]
})
export class SiFormlyNoIconWrapperComponent extends SiFormlyWrapperComponent {
  override withValidationIcon = false;
}
