import { Subject, Subscription } from 'rxjs';

import { Datapoint, DataPointPropertyChangeArgs } from '../../datapoint/gms-datapoint';

export enum ParameterType {

  /** Uninitialized parameter type
   */
  Unknown = 0,

  /** Multistate (internally an Int32)
   */
  MultiState = 1,

  /** String
   */
  // eslint-disable-next-line id-blacklist
  String = 2,

  /** Password
   */
  Password = 3,

  /** Command priority (an enumeration)
   */
  Priority = 4,

  /**  Date/time
   */
  DateTime = 5,

  /** Numeric (one of the various numeric data types)
   */
  Numeric = 6,

  /** BitString
   */
  BitString = 7,

  /** Duration (raw value is unsigned integer)
   */
  Duration = 8,

  /**  GMS date/time (string with BACnet date/time)
   */
  GmsDateTime = 9,

  /** Numeric INT64 (one of the various numeric data types)
   */
  NumericInt64 = 10,
  /** Numeric UINT64 (one of the various numeric data types)
   */
  NumericUInt64 = 11

}

export class CommandParameter {

  public valueChanged: Subject<DataPointPropertyChangeArgs> = new Subject<DataPointPropertyChangeArgs>();
  private _datapointPropertyChangedSubscription: Subscription;

  /**
   */
  private _parameterType: ParameterType = ParameterType.Unknown;
  public get ParameterType(): ParameterType {
    return this._parameterType;
  }
  public set ParameterType(value: ParameterType) {
    this._parameterType = value;
  }

  /**
   * command parameter name
   */
  private readonly _name: string = '';

  public get Name(): string {
    return this._name;
  }

  /**
   * command parameter order
   */
  private readonly _order: number;
  public get Order(): number {
    return this._order;
  }

  private _defaultValue: string;
  public get DefaultValue(): string {
    return this._defaultValue;
  }
  public set DefaultValue(value: string) {
    if (this._defaultValue !== value) {
      this._defaultValue = value;
      this.NotifyPropertyChanged('DefaultValue');
    }
  }
  private readonly _dataType: string;
  public get DataType(): string {
    return this._dataType;
  }

  private _datapoint: Datapoint;
  public get Datapoint(): Datapoint {
    return this._datapoint;
  }
  public set Datapoint(value: Datapoint) {
    this._datapoint = value;
  }

  private _precision: number;
  public get Precision(): number {
    return this._precision;
  }
  public set Precision(value: number) {
    this._precision = value;
  }
  private _minValue: number;
  public get MinValue(): number {
    return this._minValue;
  }
  public set MinValue(value: number) {
    this._minValue = value;
  }
  private _maxValue: number;
  public get MaxValue(): number {
    return this._maxValue;
  }
  public set MaxValue(value: number) {
    this._maxValue = value;
  }
  private _minValueLong: Long;
  public get MinValueLong(): Long {
    return this._minValueLong;
  }
  public set MinValueLong(value: Long) {
    this._minValueLong = value;
  }
  private _maxValueLong: Long;
  public get MaxValueLong(): Long {
    return this._maxValueLong;
  }
  public set MaxValueLong(value: Long) {
    this._maxValueLong = value;
  }

  public subscribeForUpdates(): void {
    if (this.Datapoint === undefined) {
      return;
    }

    if (this._datapointPropertyChangedSubscription === undefined) {
      this.Datapoint.CountUsage++;
      this._datapointPropertyChangedSubscription = this.Datapoint.propertyChanged.subscribe(arg => this.onDataPointPropertyChanged(arg));
    }
  }

  protected NotifyPropertyChanged(propertyName = ''): void {
    const args: DataPointPropertyChangeArgs = new DataPointPropertyChangeArgs();
    args.PropertyName = propertyName;
    args.SourceDatapoint = this.Datapoint;
    args.DefaultValue = this.DefaultValue;
    this.valueChanged.next(args);
  }

  public unsubscribeFromUpdates(): void {
    if (this.Datapoint === undefined) {
      return;
    }
    this.Datapoint.CountUsage--;
  }

  public unsubscribe(): void {

    if (this.Datapoint !== undefined && this.Datapoint.CountUsage > 0) {

      this.Datapoint.CountUsage--;
    }

    if (this._datapointPropertyChangedSubscription !== undefined && !this._datapointPropertyChangedSubscription.closed) {
      this._datapointPropertyChangedSubscription.unsubscribe();
      this._datapointPropertyChangedSubscription = undefined;
    }
  }

  /**
   * @param name
   * @param order
   * @param description
   * @param defaultValue
   */
  public constructor(name: string, order: number, defaultValue: string, precision: number, dataType: string) {
    this._name = name;
    this._order = order;
    this._defaultValue = defaultValue;
    if (precision === undefined) {
      precision = 0;
    }
    this._precision = precision;
    this._dataType = dataType;
  }

  private onDataPointPropertyChanged(arg: DataPointPropertyChangeArgs): void {
    if (arg.PropertyName === 'Value') {
      this.NotifyPropertyChanged(arg.PropertyName);
    }
  }

}
