import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiMonitorServiceBase } from '@gms-flex/services';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gms-layout-modal',
  templateUrl: './layout-modal.component.html',
  styleUrl: './layout-modal.component.scss'
})
export class LayoutModalComponent {

  @Input() public title: string;
  @Input() public saveLayoutMsg: string;
  @Input() public enforceLayoutMsg: string;
  @Input() public cancel: string;
  @Input() public save: string;

  public checkboxClicked = false;

  constructor(
    public bsModalRef: BsModalRef,
    public readonly multiMonitorService: MultiMonitorServiceBase
  ) {}

  public onDecline(): void {
    this.bsModalRef.hide();
  }

  public onConfirmEnforce(): void {
    this.saveElectronConfig();
    this.bsModalRef.hide();
  }

  public onConfirmNoEnforce(): void {
    this.saveElectronConfigOverruleAllowed();
    this.bsModalRef.hide();
  }

  private saveElectronConfig(): void {
    if (this.multiMonitorService.isMainManager()) {
      this.multiMonitorService.saveCurrentConfigurationAsDefault(false);
    }
  }

  private saveElectronConfigOverruleAllowed(): void {
    if (this.multiMonitorService.isMainManager()) {
      this.multiMonitorService.saveCurrentConfigurationAsDefault(true);
    }
  }

}
