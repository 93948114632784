import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { ElementDimensions, ResizeObserverService } from '@simpl/element-ng';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class GmsEasyNavigationResizeService implements OnDestroy {
  public resizeObs: Observable<ElementDimensions> = new Observable<ElementDimensions>();
  private navigationContainerElement: HTMLElement = undefined;
  private readonly unsubscribe: Subject<void> = new Subject<void>();

  constructor(public resizeObserverService: ResizeObserverService) {
  }

  public initialize(navigationContainer: ElementRef): void {
    this.navigationContainerElement = navigationContainer?.nativeElement;
    if (!isNullOrUndefined(this?.navigationContainerElement)) {
      const RESIZE_THROTTLE_TIMEOUT = 300;
      this.resizeObs = this.resizeObserverService
        .observe(this.navigationContainerElement, RESIZE_THROTTLE_TIMEOUT, true, true)
        .pipe(takeUntil(this.unsubscribe));
    }
  }

  public get NavigationContainerRect(): DOMRect {
    return this?.navigationContainerElement?.getBoundingClientRect();
  }

  public get NavigationContainerRight(): number {
    return this?.navigationContainerElement?.getBoundingClientRect()?.right || 0;
  }

  public get NavigationContainerWidth(): number {
    return this?.navigationContainerElement?.getBoundingClientRect()?.width || 0;
  }

  // NOTE: Check if this is automatically destroyed
  public ngOnDestroy(): void {
    this.unsubscribe.next();
  }
}
