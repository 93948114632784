<div class="si-datapoint-row p-4">
  <div class="col mt-2 text-truncate">
    <span
      [siPopover]="propertyPopupRef"
      placement="bottom"
      triggers="focus"
      container="body"
      containerClass="si-sceneviewer-property-popover mt-0"
      #popExpander="si-popover"
      class="select-property-popover"
    >
    </span>
    <span
      [siPopover]="aboutDatapointPopupRef"
      placement="bottom"
      triggers="focus"
      container="body"
      containerClass="si-sceneviewer-about-popover mt-0"
      #popUpExpander="si-popover"
      class="about-datapoint-popover"
    >
    </span>
    <div [hidden]="isRenaming">
      <si-datapoint
        [icon]="selectedDataPointIcon"
        [name]="selectedDataPointName"
        [description]="selectedDataPointDescription"
        [alias]="selectedDataPointAlias"
        [displayMode]="displayMode"
      />
    </div>
    <div [hidden]="!isRenaming" class="media-body">
      <div class="d-flex flex-wrap pl-4 ps-4">
        <div>
          <si-datapoint
            [icon]="selectedDataPointIcon"
            name=""
            description=""
            [displayMode]="displayMode"
          />
        </div>
        <div class="media-object-data ms-n3">
          <si-string-property
            #stringInputProp
            [property]="renameStringProperty"
            valueState="none"
            (submitted)="onDataPointNameChanged($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="mt-4">
      <div class="si-sceneviewer-style-popover">
        <span
          #selectProp
          class="pointer d-flex flex-row-reverse"
          (click)="onExpandableClicked($event, popExpander)"
        >
          <si-content-action-bar
            [primaryActions]="primaryActions"
            [secondaryActions]="secondaryActions"
            [viewType]="viewType"
          />
        </span>
      </div>
    </div>
  </div>
</div>

@if (!isLoadingProperties) {
  <div class="si-datapoint-property">
    @if (!readOnly && propertyList.length) {
      <si-command-list
        #commandListViewer
        [objectId]="objectId"
        [propertyList]="propertyList"
        [isSceneView]="false"
        (propertyChanged)="propertyChange($event)"
        (addingDataPoints)="addNewDataPoint()"
        (command)="command.emit($event)"
      />
    }
  </div>
}

@if (readOnly || !propertyList.length) {
  <si-empty-state
    icon="element-delete status-danger"
    heading=""
    content="SCENE_EDITOR.DIALOG_TEXT_LONG.DATA_POINT_REMOVE"
  >
    @if (readOnly) {
      <button type="button" class="btn btn-secondary" (click)="revertChanges()">
        {{ 'SCENE_EDITOR.TEXT_SHORT.REVERT_DATA_POINT_DELETE' | translate }}
      </button>
    }
    @if (!readOnly) {
      <button type="button" class="btn btn-secondary" (click)="deleteDataPoint()">
        {{ 'SCENE_EDITOR.DIALOG_TEXT_SHORT.REMOVE' | translate }}
      </button>
    }
  </si-empty-state>
}

<ng-template #propertyPopupRef>
  <div (siClickOutside)="closePopups()">
    <div class="modal-header">
      <div class="col mr-3 me-3 mt-2 media-object-type text-truncate">
        <si-datapoint
          [icon]="selectedDataPointIcon"
          [name]="selectedDataPoint?.name"
          [description]="selectedDataPoint?.description"
          [alias]="selectedDataPoint?.alias"
          [displayMode]="displayMode"
        />
      </div>
    </div>
    <hr class="mr-4 me-4 ml-4 ms-4 my-0" />
    <div class="modal-body">
      <div class="prop-container">
        @if (!isLoadingProperties) {
          <si-enum-property
            [property]="enumPropertyEnums"
            valueState="none"
            (submitted)="onPropertyChanged($event)"
          />
        }
      </div>
    </div>
  </div>
</ng-template>

<div class="loading-spinner">
  @if (isLoadingProperties) {
    <si-loading-spinner />
  }
</div>

<ng-template #aboutDatapointPopupRef>
  <div (siClickOutside)="closePopups()">
    <ng-container #aboutDataPointTemplate cdkPortalOutlet />
  </div>
</ng-template>
