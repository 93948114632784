import { Injectable } from '@angular/core';
import { BrowserObject, ManagerInfo, MultiMonitorConfigurationInfo, MultiMonitorServiceBase } from '@gms-flex/services';
import { Observable } from 'rxjs';

@Injectable()
export class MultiMonitorBxSubstituteService implements MultiMonitorServiceBase {
  
  public get runsInElectron(): boolean {
    return false;
  }

  public isMainManager(): boolean | undefined {
    return true;
  }

  public isSingleSystemManager(): Promise<boolean | undefined> {
    return Promise.resolve(undefined);
  }

  public onCurrentMultiMonitorConfigurationChanged(): Observable<MultiMonitorConfigurationInfo> {
    throw new Error('Method not implemented.');
  }

  public onCurrentManagerConfigurationChanged(): Observable<ManagerInfo> {
    throw new Error('Method not implemented.');
  }

  public isManagerWithEvent(): boolean | undefined {
    return false;
  }

  public saveCurrentConfigurationAsDefault(overruleAllowed: boolean): void {
    // Nothing to do
  }

  public sendObjectToWindow(objectToSend: any): void {
    // Nothing to do
  }

  public sendObjectToMainManager(objectToSend: any): void {
    // Nothing to do
  }

  public sendObjectToAllWindows(objectToSend: any): void {
    // Nothing to do
  }

  public sendObject(webContentsId: number, data: any): void {
    // Nothing to do
  }
  public sendEvent(eventToSend: any): void {
    // Nothing to do
  }

  public resetToDefaultConfiguration(): Promise<boolean> {
    return Promise.resolve(false);
  }

  public synchronizeUiState(state: any): void {
    throw new Error('Method not implemented.');
  }

  public isCurrentMultiMonitorConfigurationChangeAllowed(): boolean | undefined {
    return false;
  }

  public synchronizeWithUserSettings(): boolean {
    return false;
  }

  public startAdditionalSystemManager(): void {
    // Nothing to do
  }

  public detachEventManager(initialUrl?: string): void {
    // Nothing to do
  }

  public resumeEventManager(initialUrl?: string): void {
    // Nothing to do
  }
  
  public isDefaultMultiMonitorConfigurationChangeAllowed(): boolean | undefined {
    return false;
  }

  public setStartupNode(designation: string): void {
    // Nothing to do
  }

  public getStartupNode(): string | undefined {
    return undefined;
  }

  public setActiveLayout(frameId: string, viewId: string, layoutId: string): void {
    // Nothing to do
  }

  public getActiveLayout(frameId: string, viewId: string): string | undefined {
    return undefined;
  }

  public matchCommunicationRules(node: BrowserObject): number | undefined {
    return undefined;
  }

}
