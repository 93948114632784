import { NgModule } from '@angular/core';

import { SiPropertyContainerComponent } from './si-property-container.component';
import { SiPropertyPopoverComponent } from './si-property-popover.component';

@NgModule({
  imports: [SiPropertyContainerComponent, SiPropertyPopoverComponent],
  exports: [SiPropertyContainerComponent, SiPropertyPopoverComponent]
})
export class SiPropertyPopoverModule {}
