<div class="modal-header">
  <span class="modal-title" [id]="titleId">{{ heading | translate: translationParams }}</span>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    [attr.aria-label]="cancelBtnName | translate"
    (click)="cancelColumnSelection()"
  ></button>
</div>
<p class="text-secondary px-8 pb-5 mb-0">{{ bodyTitle | translate: translationParams }}</p>
<div #modalBody class="modal-body py-0">
  <div
    cdkListbox
    cdkListboxMultiple
    cdkDropList
    aria-keyshortcuts="Alt+ArrowUp Alt+ArrowDown"
    [cdkListboxValue]="visibleIds"
    [attr.aria-label]="listAriaLabel | translate: translationParams"
    (cdkListboxValueChange)="changeColumnVisibilityFromListbox($event)"
    (cdkDropListDropped)="drop($event)"
  >
    @for (headerContent of columns; track headerContent; let index = $index) {
      <div
        cdkDrag
        class="d-flex justify-content-between user-select-none my-4 mx-1 p-5 rounded-2 elevation-1 bg-base-1"
        [cdkDragDisabled]="!headerContent.draggable"
        [cdkOption]="headerContent.id"
        [cdkOptionDisabled]="headerContent.disabled"
        (keydown.alt.arrowUp)="moveUp(index, $event)"
        (keydown.alt.arrowDown)="moveDown(index, $event)"
      >
        <div class="d-flex align-items-center">
          <div
            cdkDragHandle
            aria-hidden="true"
            class="element-menu me-5 rounded-1"
            [class.disabled]="headerContent.disabled"
          ></div>
          <span class="si-body-2" [class.disabled]="headerContent.disabled">{{
            headerContent.title | translate: translationParams
          }}</span>
        </div>
        @if (columnVisibilityConfigurable) {
          <div class="d-flex align-items-center form-check form-switch" aria-hidden="true">
            <span
              class="si-body-2 me-4 form-check-label"
              [class.disabled]="headerContent.disabled"
              >{{ (headerContent.visible ? visibleText : hiddenText) | translate }}</span
            >
            <span
              type="checkbox"
              class="form-check-input"
              [class.disabled]="headerContent.disabled"
              [class.checked]="headerContent.visible"
            ></span>
          </div>
        }
      </div>
    }
  </div>
</div>
<div class="modal-footer">
  @if (restoreEnabled) {
    <button type="button" class="btn btn-secondary me-auto" (click)="restoreToDefault()">
      {{ restoreToDefaultBtnName | translate }}
    </button>
  }
  <button type="button" class="btn btn-secondary" (click)="cancelColumnSelection()">
    {{ cancelBtnName | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="submitColumnSelection()">
    {{ submitBtnName | translate }}
  </button>
</div>
