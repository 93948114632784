import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UnsavedDataDialogResult, UnsaveDialogService } from '@gms-flex/controls';
import { IHfwMessage, ISnapInConfig, MobileNavigationService, SettingsService, StateService } from '@gms-flex/core';
import { CnsHelperService, CnsLabel, CnsLabelEn, MultiMonitorServiceBase, NavbarCommunicationService, StatusBarState } from '@gms-flex/services';
import { AppContextService, isNullOrUndefined, SettingsServiceBase, TraceService } from '@gms-flex/services-common';
import { EventsCommonServiceBase } from '@gms-flex/snapin-common';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, NavbarItem, SiSidePanelService, ThemeType } from '@simpl/element-ng';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observer, Subscription } from 'rxjs';

import { LayoutModalComponent } from '../layout-modal/layout-modal.component';
import { TraceModules } from '../shared/trace-modules';
import { settingsMode, UtilityPanelsEn } from '../shared/utility-panel-model';

export const NAVBAR_RESOURCE_KEY = 'NAVBAR.';
const INVESTIGATIVE_MODE_ID = 'investigative';
const ASSISTED_MODE_ID = 'assisted';
const DEFAULT_MODE_ID = 'default';

@Component({
  selector: 'gms-layout-settings',
  templateUrl: './layout-settings.component.html',
  styleUrl: '../gms-navbar.scss'
})

export class LayoutSettingsComponent implements AfterViewInit, OnInit, OnDestroy {

  private static readonly layout1PaneIcon = 'element-layout-pane-1';
  private static readonly layout2PaneMobileIcon = 'element-smartphone';
  private static readonly statusBarCompact = '.responsive.compact';
  private static readonly statusBarWrapper = '__si-status-bar-1';
  private static readonly statusBarLayoutSettings = 'statusBarSettings';
  private static readonly layoutLockIcon = '.gms-layout-lock';
  private static readonly defaultLayoutButton = '.gms-layout-default-button';

  @Input() public primaryItems: NavbarItem[];

  @Output() public readonly utilityPanelChanged: EventEmitter<UtilityPanelsEn> = new EventEmitter<UtilityPanelsEn>();

  public statusBarStateEnum: any = StatusBarState;

  public layouts: any[];

  public cnsLabels: CnsLabel[];

  public currentLayoutId: string;

  public isLayoutLocked = false;

  public isInSidePanel = true;

  public statusBarState: StatusBarState = StatusBarState.Expanded;

  public isMainManager = false;

  public isMobile = false;

  public isManagerWithEvent = false;
  public detachEventMgrMenuItemDisabled = false;
  public isCurrentMMConfigChangeAllowed = false;
  public layoutPrimaryActions: MenuItem[] = [];
  public layoutSecondaryActions: MenuItem[] = [];
  public frameChangeHeader = ''; // "...Frame change";
  public frameChangeBody = ''; // "... do you want to proceed?";
  public frameChangeYes = ''; // "Yes";
  public frameChangeNo = ''; // "No";

  private resetLayout = '';
  private saveLayoutTitle = '';
  private saveLayoutMsg = '';
  private enforceLayoutMsg = '';
  private cancel = '';
  private save = '';
  private close = '';
  private isInInvestigativeMode = false;
  private isInAssistedMode = false;

  // private eventMgrIndex: number;

  private readonly _settingsId = 'SummaryBar_CollapseSettings';

  private previousLayoutId: string;

  private frameSubscription: Subscription;

  private currentLayoutSub: Subscription;

  private availableLayoutSub: Subscription;

  private currentFrameId: string;

  private cnsLabelSub: Subscription;

  public constructor(
    public cnsHelper: CnsHelperService,
    private readonly snapinConfig: ISnapInConfig,
    private readonly traceService: TraceService,
    private readonly messageBroker: IHfwMessage,
    private readonly sidePanelService: SiSidePanelService,
    private readonly settingsService: SettingsServiceBase,
    public readonly multiMonitorService: MultiMonitorServiceBase,
    private readonly stateService: StateService,
    private readonly settings: SettingsService,
    private readonly appContextService: AppContextService,
    private readonly modalService: BsModalService,
    private readonly translateService: TranslateService,
    private readonly eventsCommonService: EventsCommonServiceBase,
    private readonly uds: UnsaveDialogService,
    private readonly element: ElementRef,
    @Inject(NavbarCommunicationService) private readonly navbarService: NavbarCommunicationService,
    @Inject(MobileNavigationService) private readonly mobileNavigationService: MobileNavigationService) {
  }

  public ngOnInit(): void {
    this.translateService.get([
      'LAYOUT.RESET-LAYOUT',
      'LAYOUT.SAVE-LAYOUT',
      'LAYOUT.SAVE-LAYOUT-MESSAGE',
      'LAYOUT.ENFORCE-LAYOUT',
      'LAYOUT.CANCEL',
      'LAYOUT.SAVE',
      'LAYOUT.CLOSE',
      'LAYOUT.FRAME-CHANGE-HEADER',
      'LAYOUT.FRAME-CHANGE-BODY',
      'LAYOUT.FRAME-CHANGE-YES',
      'LAYOUT.FRAME-CHANGE-NO'
    ]).subscribe(values => this.onTraslateStrings(values));

    this.isMainManager = this.multiMonitorService.isMainManager();
    this.isManagerWithEvent = this.multiMonitorService.isManagerWithEvent();
    this.isCurrentMMConfigChangeAllowed = this.multiMonitorService.isCurrentMultiMonitorConfigurationChangeAllowed();

    this.frameSubscription = this.messageBroker.getCurrentWorkAreaFrameInfo().subscribe(
      res => {
        if (res) {
          // Mobile Only Visibility
          this.isMobile = this.mobileNavigationService.mobileOnlyVisibilityLast;
          this.statusBarSettingsHandler();
          this.mobileNavigationService.mobileOnlyVisibility$.subscribe((mobileOnlyVisibility: boolean) => {
            this.isMobile = mobileOnlyVisibility;
            this.statusBarSettingsHandler();
            this.updateCurentFrameSettings();
          });
          this.currentFrameId = res.id;
          this.updateCurentFrameSettings();
          this.isLayoutLocked = res.isLayoutLocked;
          this.cnsLabels = this.cnsHelper.cnsLabels;
        }
      });

    this.navbarService.getStatusBarState().subscribe(state => {
      if (state) {
        if (state === StatusBarState.Default) {
          this.statusBarState = StatusBarState.Expanded;
        } else {
          this.statusBarState = state;
        }
      }
    });

    this.messageBroker.getCurrentMode().subscribe(mode => {
      this.isInInvestigativeMode = mode.id === INVESTIGATIVE_MODE_ID;
      this.isInAssistedMode = mode.id === ASSISTED_MODE_ID;
    });

    if (this.multiMonitorService.runsInElectron) {
      this.cnsLabelSub = this.navbarService.getCnsLabel().subscribe(cnsLabel => {
        if (cnsLabel) {
          this.cnsHelper.setActiveCnsLabel(cnsLabel);
        }
      });
    }

    this.createLayoutMenuItems();

    if (this.multiMonitorService.isMainManager()) {
      this.detachEventMgrMenuItemDisabled =
        !this.multiMonitorService.isCurrentMultiMonitorConfigurationChangeAllowed() || !this.multiMonitorService.isManagerWithEvent();
      this.multiMonitorService.onCurrentManagerConfigurationChanged().subscribe(info => {
        this.detachEventMgrMenuItemDisabled =
          !this.multiMonitorService.isCurrentMultiMonitorConfigurationChangeAllowed() || !this.multiMonitorService.isManagerWithEvent();
      });
    }

    this.stateService.layoutSelected.subscribe(selectedLayout => {
      if (selectedLayout) {
        if (this.multiMonitorService.runsInElectron) {
          if (this.multiMonitorService.isCurrentMultiMonitorConfigurationChangeAllowed()) {
            this.multiMonitorService.setActiveLayout(selectedLayout.frameId, selectedLayout.viewId, selectedLayout.layoutId);
            if (this.multiMonitorService.synchronizeWithUserSettings() && this.multiMonitorService.isMainManager()) {
              this.settings.saveLayoutSettings(selectedLayout.frameId, selectedLayout.viewId, selectedLayout.favoriteLayouts);
            }
          }
        } else {
          this.settings.saveLayoutSettings(selectedLayout.frameId, selectedLayout.viewId, selectedLayout.favoriteLayouts);
        }
      }
    });
  }

  public ngAfterViewInit(): void {
    // Create a MutationObserver to observe changes in the DOM
    const observer = new MutationObserver(() => {
      this.statusBarSettingsHandler();
    });

    // Start observing the entire DOM, including child elements
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  }

  /**
   * Show/Hide the layout settings for the status-bar based on compact/extended mode
   */
  public statusBarSettingsHandler(): void {
    // Utility function to toggle 'hide' class based on a condition
    const toggleHideClass = (element: HTMLElement | Element | null, condition: boolean): void => {
      if (element) {
        element.classList.toggle('hide', condition);
      }
    };

    // Const layout-settings elements
    const statusBarCompact = document.querySelector(LayoutSettingsComponent.statusBarCompact);
    const statusBarLayoutSettings = document.getElementById(LayoutSettingsComponent.statusBarLayoutSettings);
    const layoutLockIcon = document.querySelector(LayoutSettingsComponent.layoutLockIcon);
    const defaultLayoutButton = document.querySelector(LayoutSettingsComponent.defaultLayoutButton);
    const statusBarWrapper = document.getElementById(LayoutSettingsComponent.statusBarWrapper);

    // Toggle the 'hide' class based on the presence of compact status bar component and mobile conditions.
    /* The SiMPL Status Bar has two modes: 1. Extended Mode 2. Compact Mode
     Status bar height can be adjusted only in Extended Mode.
     In Compact Mode, the height adjustment option is unnecessary.
     The status bar switches to Compact Mode when the viewport width is below:
     - 1200px (right panel closed)
     - 1600px (right panel open)
     Desired behavior:
     - Above these thresholds (maximized), height adjustment options should be available.
     - Below these thresholds, height adjustment options should not be available. */
    toggleHideClass(statusBarLayoutSettings,
      !isNullOrUndefined(statusBarCompact) || isNullOrUndefined(statusBarWrapper));
    toggleHideClass(layoutLockIcon, this.isMobile);
    toggleHideClass(defaultLayoutButton, this.isMobile);
  }

  public isThemeModeSelected(themeType: ThemeType): boolean {
    return (this.appContextService.themeTypeValue === themeType);
  }

  public onClose(): void {
    this.sidePanelService.hideTemporaryContent();
    this.utilityPanelChanged.emit(null);
  }

  public ngOnDestroy(): void {
    if (this.frameSubscription != null) {
      this.frameSubscription.unsubscribe();
    }
    if (this.currentLayoutSub != null) {
      this.currentLayoutSub.unsubscribe();
    }
    if (this.availableLayoutSub != null) {
      this.availableLayoutSub.unsubscribe();
    }
    if (this.cnsLabelSub != null) {
      this.cnsLabelSub.unsubscribe();
    }
  }

  public applyThemeType(theme: ThemeType): void {
    this.appContextService.setThemeType(theme);
    this.putModeSettings(theme);

    if (this.multiMonitorService.runsInElectron) {
      this.multiMonitorService.synchronizeUiState({ sendToItself: false, state: { themeType: theme } });
    }
  }

  public onLayoutSelection(event: MouseEvent, layoutId: string): void {
    this.messageBroker.changeLayout(this.currentFrameId, layoutId).subscribe((res: boolean) => {
      this.traceService.debug(TraceModules.layoutSettings, 'changeLayout completed. result: %s', res);
      if (res === false) {
        this.currentLayoutId = this.previousLayoutId;
      }
    });
  }

  public onStatusBarStateSelection(event: MouseEvent, state: StatusBarState): void {
    if (state === StatusBarState.Default) {
      this.navbarService.getHldlConfig().subscribe(res => {
        if (res) {
          this.traceService.info(TraceModules.layoutSettings, 'Retrieving hldl config...');
          this.statusBarState = res.IsCollapsed ? StatusBarState.Collapsed : StatusBarState.Expanded;
        }
      });
    } else {
      this.statusBarState = state;
    }
    this.navbarService.setStatusBarState(this.statusBarState).subscribe(res => {
      if (res) {
        this.traceService.info(TraceModules.layoutSettings, 'Pushing status bar state info...');
      }
    });
    this.storeSettings();
    if (this.multiMonitorService.runsInElectron) {
      this.multiMonitorService.synchronizeUiState({
        sendToItself: false,
        state: { statusBarHeight: this.statusBarState }
      });
    }
  }

  public onDefaultLayout(): void {
    if (this.layouts != null && this.layouts.length > 0) {
      this.onStatusBarStateSelection(null, StatusBarState.Default);
      this.messageBroker.resetFrameSettingsToDefault(this.currentFrameId).subscribe(res => {
        this.traceService.debug(TraceModules.layoutSettings, 'reset succeeds : %s', res);
      });
      // reset event list columns and splitter position
      this.eventsCommonService.resetColumnsToDefault.next(true);
    }
  }

  public onLock(event: MouseEvent): void {
    this.messageBroker.lockLayout(this.currentFrameId);
  }

  public cnsLabelSelectionChanged(cnsLabel: CnsLabel): void {
    this.cnsHelper.setActiveCnsLabel(cnsLabel as CnsLabel);
    if (this.multiMonitorService.runsInElectron) {
      this.navbarService.setCnsLabel(cnsLabel as CnsLabel);
      this.multiMonitorService.synchronizeUiState({
        sendToItself: false,
        state: { textRepresentation: JSON.parse(JSON.stringify(cnsLabel)) }
      });
    }
  }

  public onMouseDownCnsItem(event: MouseEvent): void {
    if (event.shiftKey === true) {
      // let selection: Selection = window.getSelection();
      // selection.removeAllRanges();
      // we do not want the tree item text selected: thus we prevent the default behaviour in this situation
      event.preventDefault();
    }
  }

  public onInsideClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  public detachEventManagerClicked(): void {
    if (this.isInAssistedMode) {
      this.uds.showDialog('navigation-bar', { header: this.frameChangeHeader, body: this.frameChangeBody, yes: this.frameChangeYes, no: this.frameChangeNo })
        .subscribe((res: UnsavedDataDialogResult) => {
          switch (res) {
            case UnsavedDataDialogResult.Yes:
              this.uds.closeDialog();
              this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, this.currentFrameId).subscribe((modeChanged: boolean) => {
                this.traceService.debug(TraceModules.layoutSettings, 'changeMode() completed. result: %s', modeChanged);
                this.uds.closeDialog();
                const isEventSelected = this.primaryItems?.find(x => x.title === NAVBAR_RESOURCE_KEY + 'events' && x.isActive === true) !== undefined;
                const initialUrl = (isEventSelected) ? window.location.href : undefined;
                this.multiMonitorService.detachEventManager(initialUrl);
              });
              break;
            case UnsavedDataDialogResult.No:
            case UnsavedDataDialogResult.Cancel:
            default:
              this.uds.closeDialog();
              break;
          }
        });
    } else {
      const isEventSelected = this.primaryItems?.find(x => x.title === NAVBAR_RESOURCE_KEY + 'events' && x.isActive === true) !== undefined;
      const initialUrl = (isEventSelected) ? window.location.href : undefined;
      this.multiMonitorService.detachEventManager(initialUrl);
    }
  }

  public resumeEventManagerClicked(): void {
    const isEventSelected = this.primaryItems?.find(x => x.title === NAVBAR_RESOURCE_KEY + 'events' && x.isActive === true) !== undefined;
    const initialUrl = (isEventSelected) ? window.location.href : undefined;
    this.multiMonitorService.resumeEventManager(initialUrl);
  }

  private updateCurentFrameSettings(): void {
    if (this.availableLayoutSub != null) {
      this.availableLayoutSub.unsubscribe();
    }
    this.availableLayoutSub = this.snapinConfig.getLayouts(this.currentFrameId).subscribe(
      layouts => {
        if (layouts) {
          this.layouts = layouts;
          // In this section, we manually adjust the layout-icon. Initially, it is assigned by the `createDefaultLayout()` method in hldl.service.ts.
          // However, since this service is among the first to be initialized, the mobileNavigationService is not created yet. As a result,
          // we need to make adjustments here. Otherwise, we would be unable to change the icon based on screen size until the service is refactored
          // to support this operation.
          this.layouts[0].iconClass = this.isMobile
            ? LayoutSettingsComponent.layout2PaneMobileIcon
            : LayoutSettingsComponent.layout1PaneIcon;
        }
      });

    if (this.currentLayoutSub != null) {
      this.currentLayoutSub.unsubscribe();
    }
    this.currentLayoutSub = this.messageBroker.getCurrentLayoutId(this.currentFrameId).subscribe(
      res => {
        this.currentLayoutId = res;
        this.previousLayoutId = (' ' + res).slice(1); // deep copy string.
      });
  }

  private storeSettings(): void {
    const storedConfig: string = this.statusBarState.toString();

    this.settingsService.putSettings(this._settingsId, storedConfig).subscribe(
      val => this.onPutSettings(val)
    );
  }

  private putModeSettings(mode: string): void {

    this.settingsService.putSettings(settingsMode, mode).subscribe({
      next: value => {
        if (!isNullOrUndefined(value)) {
          this.onPutSettings(value);
        }
      },
      error: error => {
        this.traceService.warn(TraceModules.layoutSettings, 'Error Writing Mode user settings ');
      }
    });
  }

  private onPutSettings(isSuccess: boolean): void {
    this.traceService.info(TraceModules.layoutSettings, 'onPutSettings() : %s', isSuccess.valueOf.toString());
  }

  private resetToDefault(): void {
    this.onDefaultLayout();
    if (this.multiMonitorService.isMainManager()) {
      this.multiMonitorService.resetToDefaultConfiguration().then(result => {
        this.traceService.info(`Reset to default configuration executed by electron main process, result: ${result}`);
      });
    }
  }

  private createLayoutMenuItems(): void {
    if (this.multiMonitorService.runsInElectron && this.multiMonitorService.isMainManager()) {
      this.layoutPrimaryActions.push(
        {
          title: this.saveLayoutTitle,
          icon: 'element-save',
          action: () => this.openLayoutModal(),
          disabled: !this.multiMonitorService.isDefaultMultiMonitorConfigurationChangeAllowed()
        },
        {
          title: this.resetLayout,
          icon: 'element-factory-reset',
          action: () => this.resetToDefault(),
          disabled: !this.multiMonitorService.isCurrentMultiMonitorConfigurationChangeAllowed()
        }
      );
      this.layoutSecondaryActions.push(
        {
          title: this.close,
          icon: 'element-cancel',
          action: () => this.onClose()
        }
      );
    }
  }

  private openLayoutModal(): void {
    const initialState: any = {
      title: this.saveLayoutTitle,
      saveLayoutMsg: this.saveLayoutMsg,
      enforceLayoutMsg: this.enforceLayoutMsg,
      cancel: this.cancel,
      save: this.save
    };
    const modalOptions: ModalOptions = { ignoreBackdropClick: true, keyboard: true, animated: true, initialState };
    this.modalService.show(LayoutModalComponent, modalOptions);
  }

  private onTraslateStrings(strings: Map<string, string>): void {
    this.resetLayout = strings['LAYOUT.RESET-LAYOUT'];
    this.saveLayoutTitle = strings['LAYOUT.SAVE-LAYOUT'];
    this.saveLayoutMsg = strings['LAYOUT.SAVE-LAYOUT-MESSAGE'];
    this.enforceLayoutMsg = strings['LAYOUT.ENFORCE-LAYOUT'];
    this.cancel = strings['LAYOUT.CANCEL'];
    this.save = strings['LAYOUT.SAVE'];
    this.close = strings['LAYOUT.CLOSE'];
    this.frameChangeHeader = strings['LAYOUT.FRAME-CHANGE-HEADER'];
    this.frameChangeBody = strings['LAYOUT.FRAME-CHANGE-BODY'];
    this.frameChangeYes = strings['LAYOUT.FRAME-CHANGE-YES'];
    this.frameChangeNo = strings['LAYOUT.FRAME-CHANGE-NO'];
  }

}
