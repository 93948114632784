import { A11yModule, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Attribute,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ProviderToken,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  positionBottomCenter,
  positionBottomEnd,
  positionBottomStart,
  positionTopCenter,
  positionTopEnd,
  positionTopStart
} from '@simpl/element-ng/common';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { compareDate, getMaxDate, getMinDate } from './date-time-helper';
import { SiDateInputDirective } from './si-date-input.directive';
import { SiDatepickerOverlayComponent } from './si-datepicker-overlay.component';
import { CloseCause, SiDatepickerOverlayDirective } from './si-datepicker-overlay.directive';
import { DatepickerInputConfig, DateRange } from './si-datepicker.model';

@Component({
  selector: 'si-date-range',
  templateUrl: './si-date-range.component.html',
  styleUrl: './si-date-range.component.scss',
  host: {
    class: 'form-control p-0 border-0',
    role: 'group'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiDateRangeComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiDateRangeComponent,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiDateRangeComponent
    }
  ],
  hostDirectives: [
    {
      directive: SiDatepickerOverlayDirective,
      // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property
      outputs: ['siDatepickerClose']
    }
  ],
  standalone: true,
  imports: [FormsModule, SiDateInputDirective, SiTranslateModule, A11yModule]
})
export class SiDateRangeComponent
  implements
    ControlValueAccessor,
    Validator,
    AfterViewInit,
    OnChanges,
    OnDestroy,
    SiFormItemControl
{
  private static idCounter = 0;

  @ViewChildren(SiDateInputDirective) private inputDirectives!: QueryList<SiDateInputDirective>;
  @ViewChild('startInput', { static: true }) private startInput!: NgModel;
  @ViewChild('endInput', { static: true }) private endInput!: NgModel;
  @ViewChild('button', { read: ElementRef, static: true }) button!: ElementRef<HTMLElement>;

  @Input() id = `__si-date-range-${SiDateRangeComponent.idCounter++}`;

  // This works as attribute is a valid token, yet angular has broken types.
  // There will be a working token available in v18: https://github.com/angular/angular/pull/54604
  @HostBinding('attr.aria-labelledby') labelledby =
    inject(new Attribute('aria-labelledby') as unknown as ProviderToken<string>, {
      optional: true
    }) ?? `${this.id}-label`;

  /** Date range component configuration. */
  @Input() siDatepickerConfig: DatepickerInputConfig = { enableDateRange: true };
  /** Placeholder of the start date input. */
  @Input() startDatePlaceholder = $localize`:@@SI_DATEPICKER.START_DATE_PLACEHOLDER:Start date`;
  /** Placeholder of the end date input. */
  @Input() endDatePlaceholder = $localize`:@@SI_DATEPICKER.END_DATE_PLACEHOLDER:End date`;
  /** Aria label of the date-range calendar toggle button. */
  @Input()
  ariaLabelCalendarButton = $localize`:@@SI_DATEPICKER.CALENDAR_TOGGLE_BUTTON:Open calendar`;
  /** Form label of the start timepicker. */
  @Input() startTimeLabel = $localize`:@@SI_DATEPICKER.START_TIME_LABEL:from`;
  /** Form label of the start timepicker. */
  @Input() endTimeLabel = $localize`:@@SI_DATEPICKER.END_TIME_LABEL:to`;
  /**
   * The debounce time for the date input changes.
   */
  @Input() debounceTime = 200;
  /** Automatically close overlay on date selection. */
  @Input({ transform: booleanAttribute }) autoClose = false;
  /** Emits on the date range value changes. */
  @Output() readonly siDatepickerRangeChange = new EventEmitter<DateRange>();
  /**
   * Emits an event to notify about disabling the time from the range picker.
   * When time is disable, we construct a pure date objects in UTC 00:00:00 time.
   */
  @Output() readonly disabledTimeChange = new EventEmitter<boolean>();

  /**
   * Whether the date range input is disabled.
   * @defaultref {@link _disabled}
   */
  @Input()
  set disabled(value: boolean | '') {
    this._disabled = coerceBooleanProperty(value);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Whether the date range input is readonly.
   * @defaultref {@link _readonly}
   */
  @Input()
  set readonly(value: boolean | '') {
    this._readonly = coerceBooleanProperty(value);
  }

  get readonly(): boolean {
    return this._readonly;
  }

  get value(): typeof this._value {
    return this._value;
  }

  /**
   * Set the date-range object displayed in the control.
   * The input can be used if the control is used outside Angular forms.
   * @defaultref {@link _value}
   */
  @Input()
  set value(value) {
    if (value !== this._value) {
      this._value = value;
      // this allows angular's built in required validator to work correctly
      if (!value?.start && !value?.end) {
        this._value = undefined;
      }
      this.onChange(this._value);
      this.cdRef.markForCheck();
    }
  }

  protected dateRangeControl?: AbstractControl | null;
  private _readonly = false;
  private _disabled = false;
  private _value?: {
    start: Date | undefined;
    end: Date | undefined;
  };
  private validator!: ValidatorFn;
  private onChange = (val: any): void => {};
  private onTouch = (): void => {};
  private readonly destroyer$ = new Subject<void>();
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly overlayToggle = inject(SiDatepickerOverlayDirective);
  private readonly injector = inject(Injector);
  private readonly defaultPlacement = [
    positionBottomCenter,
    positionBottomStart,
    positionBottomEnd,
    positionTopCenter,
    positionTopStart,
    positionTopEnd
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.siDatepickerConfig) {
      this.siDatepickerConfig = {
        ...changes.siDatepickerConfig.currentValue,
        enableDateRange: true,
        startTimeLabel: changes.siDatepickerConfig.currentValue.startTimeLabel
          ? changes.siDatepickerConfig.currentValue.startTimeLabel
          : this.startTimeLabel,
        endTimeLabel: changes.siDatepickerConfig.currentValue.endTimeLabel
          ? changes.siDatepickerConfig.currentValue.endTimeLabel
          : this.endTimeLabel
      };
    }
    this.overlayToggle.setInputs({ config: this.siDatepickerConfig, dateRange: this.value });
  }

  ngAfterViewInit(): void {
    this.dateRangeControl = this.injector.get(NgControl).control;

    this.validator = Validators.compose([
      () => this.endAfterStartValidator(),
      () => this.rangeBeforeMinDateValidator(),
      () => this.rangeAfterMaxDateValidator(),
      this.mapChildValidator('invalidFormat', 'invalidStartDateFormat', this.startInput),
      this.mapChildValidator('invalidFormat', 'invalidEndDateFormat', this.endInput)
    ])!;

    this.overlayToggle.placement = this.defaultPlacement;
    this.overlayToggle.siDatepickerClose.pipe(takeUntil(this.destroyer$)).subscribe(cause => {
      if ([CloseCause.Escape, CloseCause.Select].includes(cause)) {
        this.button.nativeElement.focus();
      } else {
        // Mark component as touch when the focus isn't recovered on input
        this.onTouch();
        this.cdRef.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  writeValue(dateRange: DateRange): void {
    this.value = dateRange ?? this.value;
    this.siDatepickerRangeChange.emit(dateRange);
    this.overlayToggle.setInputs({ dateRange });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator ? this.validator(c) : null;
  }

  /**
   * Focus out shall close the datepicker except we are moving the focus to the datepicker or one of the input elements.
   * @param event focus out event with the related target
   */
  @HostListener('focusout', ['$event'])
  protected onFocusOut(event: FocusEvent): void {
    const target = event.relatedTarget as HTMLInputElement;
    if (!this.overlayToggle.contains(target)) {
      this.overlayToggle.closeOverlay();
    }
  }

  /** Forward date range input changes to datepicker overlay */
  protected onInputChanged(dateRange: DateRange): void {
    this.writeValue(dateRange);
  }

  protected show(): void {
    if (this.readonly || this.disabled) {
      return;
    }
    this.subscribeRangeChanges(
      this.overlayToggle.showOverlay(true, {
        config: this.siDatepickerConfig,
        dateRange: this.value
      })
    );
  }

  private subscribeRangeChanges(overlay?: ComponentRef<SiDatepickerOverlayComponent>): void {
    overlay?.instance.dateRangeChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(d => this.onRangeChanged(d));
    overlay?.instance.disabledTimeChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(disabledTime => {
        this.inputDirectives.forEach(inputDirective => inputDirective.onDisabledTime(disabledTime));
        this.disabledTimeChange.emit(disabledTime);
      });
  }

  private onRangeChanged(range: DateRange): void {
    this.writeValue({
      ...range
    });
    this.validateChildren();

    if (this.autoClose && this.value?.start && this.value?.end) {
      this.overlayToggle.closeAfterSelection();
    }
    this.cdRef.markForCheck();
  }

  /** Run validators on the start/end inputs. */
  private validateChildren(): void {
    this.inputDirectives.forEach(d => d.validatorOnChange());
  }

  /** The form control validator for the end date is greater equal start date. */
  private endAfterStartValidator(): ValidationErrors | null {
    const endDate = this.endInput.value;
    const startDate = this.startInput.value;

    return !endDate || !startDate || endDate >= startDate
      ? null
      : {
          endBeforeStart: {
            start: startDate,
            end: endDate
          }
        };
  }

  private rangeBeforeMinDateValidator(): ValidationErrors | null {
    const endDate = this.endInput.value;
    const startDate = this.startInput.value;
    const min = getMinDate(this.siDatepickerConfig?.minDate);

    return !min ||
      ((!startDate || compareDate(startDate, min) >= 0) &&
        (!endDate || compareDate(endDate, min) >= 0))
      ? null
      : {
          rangeBeforeMinDate: {
            min,
            start: startDate,
            end: endDate
          }
        };
  }

  private rangeAfterMaxDateValidator(): ValidationErrors | null {
    const endDate = this.endInput.value;
    const startDate = this.startInput.value;
    const max = getMaxDate(this.siDatepickerConfig?.maxDate);

    return !max ||
      ((!startDate || compareDate(startDate, max) <= 0) &&
        (!endDate || compareDate(endDate, max) <= 0))
      ? null
      : {
          rangeAfterMaxDate: {
            max,
            start: startDate,
            end: endDate
          }
        };
  }

  /** Create validator function to expose nested child errors to parent control */
  private mapChildValidator = (
    childValidationKey: string,
    exposedValidationKey: string,
    childControl: NgModel
  ): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      return !childControl.invalid || !childControl.hasError(childValidationKey)
        ? null
        : {
            [exposedValidationKey]: childControl.errors![childValidationKey]
          };
    };
  };

  protected focusChange(event: FocusOrigin): void {
    if (event === null && !this.overlayToggle.isShown()) {
      this.onTouch();
    }
  }
}
