import { CommandParameters } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { parseLong } from '@gms-flex/snapin-common';
import { AnyValueType, BigNumberValue } from '@simpl/element-value-types';
import { AnyPropertyValueType } from '@simpl/object-browser-ng';
import * as Long from 'long';

import { Common } from '../shared/common';
import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType } from './command-vm.types';
import { WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

/**
 * 64-bit integer parameter view-model implementation.
 */
export class LongParamViewModel extends CommandParamViewModel {

  private unsigned: boolean;
  private min: Long;
  private max: Long;
  private defaultVal: Long;

  public get isUnsigned(): boolean {
    return Boolean(this.unsigned);
  }

  public get minValue(): Long {
    return this.min;
  }

  public get maxValue(): Long {
    return this.max;
  }

  // public get defaultValue(): Long {
  //   return this.defaultVal;
  // }

  public get engUnits(): string {
    return this.param.UnitDescriptor;
  }

  public get dataType(): CommandParamType {
    return CommandParamType.Integer64;
  }

  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    // Initialize the si-param value
    this.siParam.value = {
      type: 'big-number',
      readonly: false,
      optional: false,
      value: undefined,
      decimalsAllowed: false,
      resolution: '1'
    } as BigNumberValue;

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Decode the provided object as a long and encoded it as a string that can be passed
   * to the WSI as a command parameter value.
   *
   * If the provided object cannot at first be converted to a long, undefined will be returned
   * to indicate `invalid` argument.
   */
  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let valEnc: string;
    if (valObj && valObj.type === 'big-number') {
      const longValue: Long = Long.fromString(valObj.value, this.unsigned);
      valEnc = WsiTranslator.encodeInteger64(longValue);
    }
    return valEnc;
  }

  public resetParamValue(): void {
    const siVal: BigNumberValue = this.siParam.value as BigNumberValue;
    siVal.value = this.defaultVal ? this.defaultVal.toString() : undefined;
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.Integer64) {
      return false; // undefined param or type mismatch!
    }
    const param: LongParamViewModel = p as LongParamViewModel;

    // Cannot align if engineering units are not the same!
    if (this.engUnits !== param.engUnits) {
      return false;
    }
    // Cannot align if min/max value range is the same!
    if (!Common.isEqualLong(this.minValue, param.minValue) ||
      !Common.isEqualLong(this.maxValue, param.maxValue)) {
      return false;
    }
    // If default value of alignment param does not match, clear the local default value
    if (!Common.isEqualLong(this.defaultVal, param.defaultVal)) {
      this.defaultVal = undefined;
    }

    return true;
  }

  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;
    if (!p) {
      return;
    }

    const int64Min: Long = Long.MIN_VALUE;
    const int64Max: Long = Long.MAX_VALUE;
    const uint64Min: Long = Long.UZERO;
    const uint64Max: Long = Long.MAX_UNSIGNED_VALUE;

    let unsigned: boolean;

    // Set min/max values
    switch (this.nativeType) {
      case 'BasicBit64':
      case 'ExtendedBitString64':
        // In the case where the numeric param vm is used to represent a bitstring, treat the param
        // as a 64-bit uint.  Parameters Min/Max will indicate the size of the bitstring, which can be
        // used to calculate max uint value.
        const loBit: number = Common.limit(parseInt(p.Min, 10), 0, 0, 63, false);
        const hiBit: number = Common.limit(parseInt(p.Max, 10), 0, loBit, 63, true);
        const size: number = Math.max(0, hiBit - loBit + 1);
        let maxVal: Long = Long.fromNumber(1, true);
        for (let i = 1; i < size; ++i) {
          maxVal = maxVal.shiftLeft(1).or(1);
        }
        this.unsigned = true;
        this.max = maxVal;
        this.min = uint64Min;
        break;

      case 'BasicInt64':
      case 'ExtendedInt64':
        this.unsigned = false;
        this.max = Common.limitLong(parseLong(p.Max, false), int64Min, int64Max, true);
        this.min = Common.limitLong(parseLong(p.Min, false), int64Min, this.max, false);
        break;

      case 'BasicUint64':
      case 'ExtendedUint64':
      default:
        this.unsigned = true;
        this.max = Common.limitLong(parseLong(p.Max, true), uint64Min, uint64Max, true);
        this.min = Common.limitLong(parseLong(p.Min, true), uint64Min, this.max, false);
        break;
    }

    // Set default value
    this.defaultVal = undefined;
    if (!isNullOrUndefined(p.DefaultValue)) {
      this.defaultVal = Common.limitLong(parseLong(p.DefaultValue, this.unsigned), this.min, this.max, false);
    }

    const siVal: BigNumberValue = this.siParam.value as BigNumberValue;
    siVal.unit = this.param.UnitDescriptor;
    siVal.min = this.min.toString();
    siVal.max = this.max.toString();
    siVal.typeMin = (this.unsigned ? uint64Min : int64Min).toString();
    siVal.typeMax = (this.unsigned ? uint64Max : int64Max).toString();

    this.resetParamValue();
  }
}
