import { BrowserObject, CnsFormatOption, CnsHelperService, ViewInfo } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { TraceChannel } from '../common/trace-channel';
import { GmsGraphic } from '../elements/gms-graphic';
import { Datapoint, DataPointPropertyChangeArgs } from '../processor/datapoint/gms-datapoint';
import { GraphicsDatapointHelper } from '../processor/datapoint/gms-datapoint-helper';
import { GmsBrowserObjectService } from '../services/gms-browser-object.service';
import { DataPointService } from '../services/gms-datapoint2.service';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { Evaluation } from './evaluation';
import { Substitution, SubstitutionParseOutput } from './substitution';

// Defines the semantic type
export enum SemanticType {
  // Expression is a reference
  Reference,
  // Expression is looked up in function space
  Function,
  // Expression is looked up in object model space
  ObjectModel,
  // Expression is a script
  Script
}

// Defines the type of expression
export enum ExpressionType {
  // Default expression type
  None,
  // Expression is of type reference
  Ref,
  // Expression is of type literal
  Literal
}

export class Expression {
  private readonly traceModule: string;
  private _partialExpression: PartialExpression = PartialExpression.Empty();
  private _isScriptChanged = false;

  private _value: string = undefined;
  public get Value(): string {
    return this._value;
  }
  public set Value(value: string) {
    if (this._value !== value) {
      this._value = value;
      this.Update(true, undefined);
    }
  }

  // private _subscriptions: Dictionary<string, Subscription> = new Dictionary<string, Subscription>();
  private readonly _subscriptions: Map<string, Subscription> = new Map<string, Subscription>();

  private readonly _partialExpressionsCountChanged: boolean = false;

  private _datapoints: Datapoint[] = new Array<Datapoint>();

  private readonly _readFunction: () => void = this.Read.bind(this);

  private readonly _traceFunction: () => void = this.Trace.bind(this);

  private readonly _readCnsDescription: () => void = this.ReadCnsDescription.bind(this);

  // -------> CNS Description
  // Subscription to CNS activeView
  private _activeViewChangeSubscription: Subscription = undefined;
  // Subscription to BrowserObjects collects
  private _collectBrowserObjectsSubscription: Subscription = undefined;
  // indicates that it has ReadCnsScript - avoid UpdateResult() on datapoint Value update notification
  private _hasReadCnsScript = false;
  // indicates that it is subscribed once for the activeViewChange
  private _subscribedToViewChange = false;

  private _systemBrowserService: GmsBrowserObjectService = undefined;
  public get GmsBrowserObjectService(): GmsBrowserObjectService {
    if (this._systemBrowserService === undefined && this.Evaluation?.Element !== undefined) {
      const graphic: GmsGraphic = this.Evaluation.Element.Graphic as GmsGraphic;
      this._systemBrowserService = graphic?.GmsBrowserObjectService;
    }
    return this._systemBrowserService;
  }

  private _cnsHelperService: CnsHelperService = undefined;
  public get CnsHelperService(): CnsHelperService {
    if (this._cnsHelperService === undefined && this.Evaluation?.Element !== undefined) {
      const graphic: GmsGraphic = this.Evaluation.Element.Graphic as GmsGraphic;
      this._cnsHelperService = graphic?.CnsHelperService;
    }
    return this._cnsHelperService;
  }
  // <------- CNS Description

  public get Datapoints(): Datapoint[] {
    return this._datapoints;
  }
  public set Datapoints(value: Datapoint[]) {
    if (this._datapoints !== value) {
      this._datapoints = value;
    }
  }

  private _semanticType: SemanticType = SemanticType.Reference;
  public get Semantictype(): SemanticType {
    return this._semanticType;
  }
  public set Semantictype(value: SemanticType) {
    if (this._semanticType !== value) {
      this._semanticType = value;
    }
  }

  private _hasRemainingPartialExpressions = false;
  public get HasRemainingPartialExpressions(): boolean {
    return this._hasRemainingPartialExpressions;
  }
  public set HasRemainingPartialExpressions(value: boolean) {
    if (this._hasRemainingPartialExpressions !== value) {
      this._hasRemainingPartialExpressions = value;
    }
  }

  private _valueSubstituted: string = undefined;
  public get ValueSubstituted(): string {
    return this._valueSubstituted;
  }
  public set ValueSubstituted(value: string) {
    if (this._valueSubstituted !== value) {
      this._isScriptChanged = true;
      this._valueSubstituted = value;
    }
  }

  private _errorMessage: string = undefined;
  public get ErrorMessage(): string {
    return this._errorMessage;
  }
  public set ErrorMessage(value: string) {
    if (this._errorMessage !== value) {
      this._errorMessage = value;
      this.NotifyPropertyChanged('ErrorMessage');
    }
  }

  private _status: DatapointStatus = DatapointStatus.Undefined;
  public get Status(): DatapointStatus {
    return this._status;
  }
  public set Status(value: DatapointStatus) {
    if (this._status !== value) {
      this._status = value;
      this.NotifyPropertyChanged('Status');
    }
  }

  private _result: string = undefined;
  public get Result(): string {
    return this._result;
  }
  public set Result(value: string) {
    if (this._result !== value) {
      this._result = value;
      this.NotifyPropertyChanged('Result');
    }
  }

  private get DataPointService(): DataPointService {
    return this._evaluation !== undefined && this._evaluation.Element !== undefined && this._evaluation.Element.DatapointService !== undefined ?
      this._evaluation.Element.DatapointService : undefined;
  }

  private get TraceService(): TraceService {
    return this._evaluation !== undefined && this._evaluation.Element !== undefined && this._evaluation.Element.TraceService !== undefined ?
      this._evaluation.Element.TraceService : undefined;
  }

  // undefined = undefined (initial and after expression changed)
  // false = no partial expression ends with '?' and there are still partial expressions with more than one non-empty item
  // true =
  // hide the element when there is nothing after the '?' in the expression and the left part doesn't exist.
  // true = at least one partial expression has only one non- empty item left and ends with '?'
  private _hideElementIfDatapointDoesntExist = false;
  public get HideElementIfDatapointDoesntExist(): boolean {
    return this._hideElementIfDatapointDoesntExist;
  }
  public set HideElementIfDatapointDoesntExist(value: boolean) {
    if (this._hideElementIfDatapointDoesntExist !== value) {
      this._hideElementIfDatapointDoesntExist = value;
    }
  }

  // used for generic expressions
  private _partialExpressionDict: Map<string, QuestionMarkExpression> = undefined;

  private _substitutions: Map<string, Substitution> = new Map<string, Substitution>();
  public get Substitutions(): Map<string, Substitution> {
    return this._substitutions;
  }

  private _evaluation: Evaluation = undefined;
  public get Evaluation(): Evaluation {
    return this._evaluation;
  }
  public set Evaluation(value: Evaluation) {
    if (this._evaluation !== value) {
      this._evaluation = value;
    }
  }

  public get Initialized(): boolean {
    return this._evaluation !== undefined;
  }

  private _isResolved = false;
  public get IsResolved(): boolean {
    return this._isResolved;
  }
  public set IsResolved(value: boolean) {
    if (this._isResolved !== value) {
      this._isResolved = value;
    }
  }

  public get IsIndexedExpression(): boolean {
    return this._partialExpression !== undefined && this._partialExpression.HasIndexToEvaluate;
  }

  /**
   * Converts the string representation of an expression to an Expression instance.
   * @param expression The string containing the Expression
   * @param decimalCharacter the decimal characters
   * @param parseOutput
   * @returns
   */
  public static Parse(expression: string, decimalCharacter: string, parseOutput: ExpressionParseOutput): void {
    parseOutput.ExpressionType = ExpressionType.None;
    parseOutput.Value = undefined;
    parseOutput.ErrorMessage = undefined;
    try {
      if (expression === undefined || expression === '') {
        return;
      }

      const c: string = expression[0];

      // check for a number
      if (Utility.IsNumeric(c) || c === '+' || c === '-' || ((c === '.' || c === ',')
                && expression.length > 1 && Utility.IsNumeric(expression[1]))) {
        const otherDecimalCharacter: string = decimalCharacter === '.' ? ',' : '.';
        const expressionCorrected: string = expression.replace(otherDecimalCharacter, decimalCharacter);
        const doubleValue = Number(expressionCorrected); // Returns Nan for non float
        if (Number.isNaN(doubleValue)) {
          parseOutput.ExpressionType = ExpressionType.Ref;
          parseOutput.Value = expression;
          return; // it seems to an invalid number
        } else {
          parseOutput.ExpressionType = ExpressionType.Literal;
          parseOutput.Value = doubleValue;
        }
        return;
      }

      // check for string literal
      if (c === '\"') {
        if (expression.length === 1 || expression[expression.length - 1] !== '\"') {
          parseOutput.ErrorMessage = 'Missing \'\\\' at end of string';
        } else {
          parseOutput.ExpressionType = ExpressionType.Literal;
          parseOutput.Value = expression.substring(1, expression.length - 1);
        }
        return;
      }

      // check for true or false
      if (expression.length === 4 || expression.length === 5) {
        const expressionLower: string = expression.toLowerCase(); // Locale info needed here.
        if (expressionLower === 'true') {
          parseOutput.ExpressionType = ExpressionType.Literal;
          parseOutput.Value = true;
          return;
        }
        if (expressionLower === 'false') {
          parseOutput.ExpressionType = ExpressionType.Literal;
          parseOutput.Value = false;
          return;
        }
      }

      // must be a ref now
      parseOutput.ExpressionType = ExpressionType.Ref;
      parseOutput.Value = expression;
    } catch (ex) {
      parseOutput.ErrorMessage = ex.stack;
    }
  }

  /**
   * Helps to create a expression with parameters(value, semanticType) or without(defaults).
   * @param value
   * @param semanticType
   */
  public constructor(value: any = undefined, semanticType: SemanticType = SemanticType.Reference) {
    this._semanticType = semanticType;
    this.Value = value;
    this.traceModule = TraceChannel.Processor;
  }

  public Deserialize(node: Node): void {
    // Evaluation Properties
    if (node === undefined) {
      return;
    }

    let result: string = SvgUtility.GetAttributeValue(node, ExpressionProperties.SemanticType);
    if (result !== undefined) {
      this.Semantictype = SemanticType[result];
    }

    result = SvgUtility.GetAttributeValue(node, ExpressionProperties.Value);
    if (result !== undefined) {
      this.Value = result;
    }
  }

  public CopyFrom(expression: Expression): void {
    this.Semantictype = expression.Semantictype;
    this.Value = expression.Value;
  }

  public get IsEmpty(): boolean {

    let res: boolean = this.Value === undefined;
    if (res === false) {
      res = this.Value.trim() === '';
    }
    return res;
  }

  /**
   * The expression string changed or a substitution value changed -> Parse the expression and initialize the data point list
   * @param clearSubstitutions Determines whether to clear the substitution or not
   * @param origin The source substitution (undefined if the Expression changed itself)
   * @returns
   */
  public Update(clearSubstitutions: boolean, origin: Substitution): void {
    try {
      if (clearSubstitutions) {
        // the substitution collection is cleared -> it will be populated in the Parse method
        this.Substitutions.clear();
      } else if (this.Substitutions.size > 0 && origin !== undefined) {
        // 'forward' the property ValueSubstituted from the origin of the change to the corresponding substitution
        // TBD
        if (origin.IsObjectRef) {
          this.Substitutions.forEach((s, key, map) => {
            if (s.IsObjectRef && s !== origin) {
              s.ValueSubstituted = origin.ValueSubstituted;
              s.IsResolved = origin.IsResolved;
            }
          });
        } else {
          // TBD locale information need to be considered
          const subst: Substitution = this.Substitutions.get(origin.Name.toLowerCase());
          if (subst !== undefined) {
            if (subst !== origin) {
              subst.ValueSubstituted = origin.ValueSubstituted;
              subst.IsResolved = origin.IsResolved;
            }
          }
        }
      }

      // either parse for substitutions or set the substitutions
      const parseOutput: SubstitutionParseOutput = new SubstitutionParseOutput();
      parseOutput.ValueSubstituted = undefined;
      parseOutput.Resolved = false; // false if at least one substitution is unresolved (= no value or default value present)

      const success: boolean = Substitution.Parse(this.Value, this._substitutions, parseOutput);
      this._substitutions = parseOutput.Substitutions;
      this.ValueSubstituted = parseOutput.Resolved ? parseOutput.ValueSubstituted : undefined;
      if (clearSubstitutions) {
        this.IsResolved = this.Substitutions.size === 0;
      } else {
        this.IsResolved = this.Substitutions !== undefined &&
                    Array.from(this.Substitutions).every(keyValue => keyValue[1].IsResolved);
      }

      if (!success) {
        throw new Error('Substitution parse error');
      }

      this.ErrorMessage = undefined;
      if (this.Initialized) {
        // update the GlobalDatapoints collection only when the element is in a GraphicView
        this.ParseValueSubstituted(undefined);
      } else {
        this.UpdateExpressionStatus();
        this.UpdateResult();
      }
    } catch (ex) {
      this.ErrorMessage = `Error in expression '${this.Value}' ${this.Substitutions !== undefined && this.Substitutions.size > 0 ? `('${this.ValueSubstituted}')` : ''} ${ex.stack}`;

      if (!!this.TraceService) {
        this.TraceService.error(this.traceModule, this.ErrorMessage);
      }
      if (this.Initialized) {
        this.Clear();
        return;
      }
    }
  }

  public Clear(): void {

    this.UnsubscribeToViewChange();

    if (this._collectBrowserObjectsSubscription !== undefined) {
      this._collectBrowserObjectsSubscription.unsubscribe();
      this._collectBrowserObjectsSubscription = undefined;
    }
    this._evaluation = undefined;

    if (this.Datapoints !== undefined && this.Datapoints.length > 0) {
      // Unsubscribe and clear the datapoints.
      this.Datapoints.forEach(datapoint => {
        const subscription: Subscription = this._subscriptions.get(datapoint.Designation);
        if (subscription !== undefined) {
          datapoint.CountUsage--;
          subscription.unsubscribe();
          this._subscriptions.delete(datapoint.Designation);
        }
      });
      this.Datapoints.splice(0, this.Datapoints.length);
    }

    if (this.Substitutions !== undefined) {
      this.Substitutions.forEach(substitution => substitution.Clear());
      this.Substitutions.clear();
    }

    if (this._partialExpressionDict !== undefined) {
      this._partialExpressionDict.forEach(value => value.Clear());
      this._partialExpressionDict.clear();
      this._partialExpressionDict = undefined;
    }

    if (this._partialExpression !== undefined) {
      this._partialExpression.Clear();
      this._partialExpression = undefined;
    }

    this._hideElementIfDatapointDoesntExist = false;
    this._hasRemainingPartialExpressions = false;
    this._status = DatapointStatus.Valid;
    this._errorMessage = undefined;
    this._result = undefined;
  }

  public ReplaceReplicationIndex(replicationIndex: number): void {
    if (this._value.includes(Utility.REPLICATION_WILDCARD)) {
      this._value = this._value.replace(Utility.REPLICATION_SEARCH_REGX(), '[' + replicationIndex + ']');
    }
    this.Update(false, undefined);
  }

  protected NotifyPropertyChanged(propertyName: string = ''): void {

    if (this.Evaluation !== undefined) {
      if (!!this.TraceService) {
        this.TraceService.debug(this.traceModule, 'Expression Result:%s, Status:%s  ', this.Result, DatapointStatus[this.Status]);
      }
      this.Evaluation.ExpressionChanged(this, propertyName);
    }
  }

  private SetDatapoint(partialExpression: PartialExpression): void {
    this._partialExpression = partialExpression; // store the expression properties
    // compare the first active datapoint against partialExpression.Datapoint
    // the new datapoint is a) another, b) the same or c) undefined (maybe a literal)
    const datapoint: Datapoint = partialExpression.Datapoint;
    const isSameDatapoint: boolean = (this._datapoints.length === 0 && datapoint === undefined)
            || (this._datapoints.length === 1 && this._datapoints[0] === datapoint);
    if (!isSameDatapoint) {

      // remove old
      if (this._datapoints !== undefined && this._datapoints.length > 0) {
        const datapointToRemove: Datapoint = this._datapoints[0];

        // unsubscribe previous datapoint
        const subscription: Subscription = this._subscriptions.get(datapointToRemove.Designation);
        if (subscription !== undefined) {
          datapointToRemove.CountUsage--;
          subscription.unsubscribe();
          this._subscriptions.delete(datapointToRemove.Designation);
        }
      }

      this._datapoints.length = 0;

      // add new
      if (datapoint !== undefined) {
        this._datapoints.push(datapoint);
        datapoint.CountUsage++;
        const subscription: Subscription = datapoint.propertyChanged.subscribe(args => this.Datapoint_PropertyChanged(args));
        if (!this._subscriptions.has(datapoint.Designation)) {
          this._subscriptions.set(datapoint.Designation, subscription);
        }

        if (this.Value.includes(Utility.REPLICATION_WILDCARD) && datapoint.Designation !== undefined
                    && datapoint.Designation.includes(Utility.REPLICATION_WILDCARD)) {
          this.SetReplication(datapoint);
        }
      }

      this.NotifyPropertyChanged('Datapoints');
    }

    if (datapoint !== undefined && datapoint?.Status === DatapointStatus.Pending) {
      return; // Datapoint changes will trigger the result update and status
    }

    this.UpdateExpressionStatus();
    this.UpdateResult();
  }

  private SetReplication(datapoint: Datapoint): void {
    const element: any = this.Evaluation.Element;

    if (element !== undefined && !element.IsReplicationClone && element.Replication === undefined) {

      const occurences: number = datapoint.Designation.length > 0 ? (datapoint.Designation.split(Utility.REPLICATION_WILDCARD).length - 1) : 0;

      if (occurences === 1) {
        element.CreateReplication();
        element.Replication.WildCardItem = this;
        element.Replication.WildCardReference = datapoint.Designation;
      }
    }
  }

  /**
   * Parse _valueSubstituted to a partialExpression
   * @param datapointReason The datapoint, which doesn't exist => use the next partial expression;
   * undefined => use the first partial expression if there are '?'
   * @returns True when the next partial expression was parsed, False at the last index, i.e. nothing was don
   */
  private ParseValueSubstituted(datapointReason: Datapoint): boolean {
    const errorMsg = 'Error in expression value:';
    try {
      if (this._semanticType === SemanticType.Script) {
        this.Evaluate();
        return true;
      }

      // parse for literal or datapoint
      let partialExpression: PartialExpression = PartialExpression.Empty();
      this.HideElementIfDatapointDoesntExist = false;

      // _valueSubstituted is now e.g. "dp1" or "dp1?dp2?4.5" or "dp1?dp2?" or hello or hello?
      // set variables 'expressionType', 'expressionValue', 'ErrorMessage', '_partialExpressions'
      // and 'HideElementIfDatapointDoesntExist' from _valueSubstituted
      if (this._valueSubstituted !== undefined) {
        let partialExpressions: QuestionMarkExpression;
        if (datapointReason === undefined) {
          if (Utility.IsStringLiteral(this._valueSubstituted)) {
            // handle special case where the expression starts and ends with a "
            partialExpression = new PartialExpression(ExpressionType.Literal
              , this._valueSubstituted.substring(1, this._valueSubstituted.length - 1));
            partialExpression.DatapointService = this.DataPointService;
            this.ErrorMessage = undefined;
            this._partialExpressionDict = undefined;
          } else if (!this._valueSubstituted.includes('?')) {
            // expression doesn't have a '?'
            partialExpression = new PartialExpression();
            partialExpression.DatapointService = this.DataPointService;
            partialExpression.Expr = this._valueSubstituted;
            partialExpression.Parse();
            if (partialExpression.ErrorMessage !== undefined && partialExpression.ErrorMessage !== '') {
              this.ErrorMessage = errorMsg + this._value + ' Status:'
                                + DatapointStatus[this.Status] + partialExpression.ErrorMessage;
            } else {
              this.ErrorMessage = undefined;
            }
            this._partialExpressionDict = undefined;
          } else {
            // initialize (=split by '?')
            if (this._partialExpressionDict === undefined) {
              this._partialExpressionDict = new Map<string, QuestionMarkExpression>();
            } else {
              this._partialExpressionDict.clear();
            }
            partialExpressions = new QuestionMarkExpression(this._valueSubstituted, this.DataPointService);
            if (partialExpressions.EndsWithQuestionmark) {
              this.HideElementIfDatapointDoesntExist = true; // hide the element when there is nothing after the '?'
            }
            this._partialExpressionDict.set('', partialExpressions); // there will be only one item so no real key needed
          }
        } else {
          // the datapoint status changed to DoesNotExist
          if (this._partialExpressionDict === undefined) {
            // shortcut: the expression is either a string literal or doesn't have a '?'
            this.UpdateExpressionStatus();
            this.UpdateResult();
            return false;
          }

          partialExpressions = this._partialExpressionDict.size > 0 ?
            this._partialExpressionDict.values().next().value : undefined;
        }

        // in case there is a dictionary -> get the next partial expression
        if (partialExpressions !== undefined) {
          // loop through the expressions as long as the datapoints are known and have a status DoesNotExist
          partialExpression = partialExpressions.GetFirstNonEmptyPartialExpressionWithExistingDatapoint();
          if (datapointReason !== undefined && !partialExpressions.PartialExpressionRemoved) {
            return false; // item didn't change
          }

          if (partialExpression.ErrorMessage !== undefined && partialExpression.ErrorMessage !== '') {
            this.ErrorMessage = errorMsg + this._value + ' Status:'
                            + DatapointStatus[this.Status] + partialExpression.ErrorMessage;
          }
        }
      }

      this.SetDatapoint(partialExpression);
    } catch (ex) {
      this.ErrorMessage = errorMsg + this._value + ' Status:' + DatapointStatus[this.Status] + ex.stack;
      if (!!this.TraceService) {
        this.TraceService.error(this.traceModule, this.ErrorMessage);
      }
      this.Clear();
    }
    return true;
  }

  private async Datapoint_PropertyChanged(args: DataPointPropertyChangeArgs): Promise<void> {
    if (!this.Initialized) { // Since Async could be called even after the expression is cleared
      return;
    }

    if (args.PropertyName === 'Status') {
      if (!!this.TraceService) {
        this.TraceService.debug(this.traceModule,
          'Datapoint to Expression status change notification: Datapoint Designation:%s, Result:%s, Status:%s ',
          args.SourceDatapoint.Designation, args.SourceDatapoint.Value, DatapointStatus[args.SourceDatapoint.Status]);
      }
      this.UpdateExpressionStatus(args.SourceDatapoint);
    } else if (args.PropertyName === 'Value') {
      if (!!this.TraceService) {
        this.TraceService.debug(this.traceModule, 'Datapoint Designation:%s, Result:%s, Status:%s ',
          args.SourceDatapoint.Designation, args.SourceDatapoint.Value, DatapointStatus[args.SourceDatapoint.Status]);
      }
      this.UpdateResult();
    } else if (args.PropertyName === 'Text' || args.PropertyName === 'DefaultIconReference') {
      // NotifyPropertyChanged("Format");    // notify any kind of format change (the expression result didn't change)
    } else if (args.PropertyName === 'AlarmState') {
      // Deferred Processing
      if (this.Evaluation.Element !== undefined && this.Evaluation.Element.Zone !== undefined) {
        this.Evaluation.Element.Zone.runOutsideAngular(() => setTimeout(() => this.NotifyPropertyChanged('AlarmState'), 0));
      }
    } else if (args.PropertyName === 'Resolved') {
      // Datapoint is resolved
      // DatapointStatus greater than pending
      this.NotifyPropertyChanged('Datapoints');

    }
  }

  /**
   * Re-calculates the expression Status based on the datapoints
   * @param datapointReason The source datapoint in case the status has to be re-calculated because of a datapoint status change. Can be undefined
   * @returns
   */
  private UpdateExpressionStatus(datapointReason: Datapoint = undefined): void {
    if (this._datapoints === undefined || this._datapoints.length === 0) {
      this.Status = DatapointStatus.Valid;
      return;
    }

    if (this._semanticType === SemanticType.Script) {
      // if datapointReason is passed (this datapoint changed its status), the status calculation can be done much faster
      if (datapointReason !== undefined) {
        // the datapoint status changed
        if (datapointReason.Status === DatapointStatus.Valid) {
          const hasInvalidDatapoint: boolean = this._datapoints.some(dp => dp.Status !== DatapointStatus.Valid);
          if (!hasInvalidDatapoint) {
            this.Evaluate(); // re-evaluate
          }
        } else if (datapointReason.Status === DatapointStatus.DoesNotExist) {
          if (this.HideElementIfDatapointDoesntExist) {
            this.Status = DatapointStatus.Hide;
          } else {
            if (this.HasRemainingPartialExpressions) {
              this.Evaluate();
            } else {
              this.Status = DatapointStatus.DoesNotExist;
            }
          }
        } else if (datapointReason.Status > DatapointStatus.Pending) {
          // map all other datapoint status to Invalid
          this.Status = DatapointStatus.Invalid;
        } else {
          this.Status = datapointReason.Status; // undefined or pending
        }
      }
      return;
    }

    if (datapointReason !== undefined && datapointReason.Status === DatapointStatus.DoesNotExist) {
      if (this.ParseValueSubstituted(datapointReason)) { // try the next partial expression
        return; // there was another partial expression so don't change the status yet
      }

      if (this.HideElementIfDatapointDoesntExist) {
        this.Status = DatapointStatus.Hide;
        return;
      }
    }

    if (this.HideElementIfDatapointDoesntExist && this._datapoints.some(dp => dp.Status === DatapointStatus.DoesNotExist)) {
      this.Status = DatapointStatus.Hide;
    } else if (this._partialExpression.ExpressionType !== ExpressionType.Ref) {
      this.Status = DatapointStatus.Valid;
    } else {
      this.Status = this._datapoints[0].Status;
    }
  }

  // Update the expression Result based on the semantic type, some other properties and the Datapoints collection
  private UpdateResult(): void {
    // Script
    if (this._semanticType === SemanticType.Script) {
      if (this.Initialized && this._valueSubstituted !== undefined) {
        this.Evaluate(); // the Result will be set in SetStatusAndResult()
      } else {
        this.Result = undefined;
      }
      return;
    }

    // Reference, Function or ObjectModel
    if (this._valueSubstituted === undefined || this._partialExpression.ExpressionValue === undefined
            || (this.ErrorMessage === undefined && this.ErrorMessage === '')) {
      this.Result = undefined;
      return;
    }

    if (this._partialExpression.ExpressionType === ExpressionType.Literal) {
      this.Result = this._partialExpression.ExpressionValue;
      return;
    }

    if (this._datapoints !== undefined && this._datapoints.length > 0) {
      if (this._semanticType === SemanticType.Reference) {
        this.ProcessReferenceExpression();
      } else if (this._semanticType === SemanticType.Function) {

        if (this._datapoints[0].FunctionName !== undefined) {
          this.Result = this._datapoints[0].FunctionName;
          this.Status = DatapointStatus.Valid;
        }
      } else if (this._semanticType === SemanticType.ObjectModel) {
        if (this._datapoints[0].ObjectModelName !== undefined) {
          this.Result = this._datapoints[0].ObjectModelName;
          this.Status = DatapointStatus.Valid;
        }
      }
      return;
    }

    this.Result = undefined;
  }

  private ProcessReferenceExpression(): void {
    if (this._partialExpression.HasIndexToEvaluate && this._datapoints[0].Value !== undefined && this._datapoints[0].Value !== undefined) {

      const parsedValue: any = JSON.parse(this._datapoints[0].Value);
      const arrayValues: any[] = Array.isArray(parsedValue) ? parsedValue : [];
      if ((this._partialExpression.IndexToEvaluate < arrayValues.length)) {
        this.Result = arrayValues[this._partialExpression.IndexToEvaluate];
      } else {
        // If current partial expression has an invalid index.
        // Remove it and process the subsequent expression if available.

        // SemanticType.Reference expression will have only one questionmarkexpression in the partialexpressions dictionary.
        const partialExpressions: QuestionMarkExpression = this._partialExpressionDict !== undefined && this._partialExpressionDict.size > 0
          ? this._partialExpressionDict.values().next().value
          : undefined;
        if (partialExpressions !== undefined && partialExpressions.Count > 1) {

          partialExpressions.Remove(this._partialExpression);

          const nextPartialExpression: PartialExpression = partialExpressions.Count > 0
            ? partialExpressions.GetFirstNonEmptyPartialExpressionWithExistingDatapoint()
            : undefined;
          if (nextPartialExpression !== undefined) {
            this.SetDatapoint(nextPartialExpression);
          }
        } else {
          this.Result = undefined;
          this.Status = partialExpressions !== undefined && partialExpressions.EndsWithQuestionmark
            ? DatapointStatus.Hide
            : this.Status = DatapointStatus.DoesNotExist;
        }
      }
    } else {
      this.Result = this._datapoints[0].Value;
    }
  }

  private async Evaluate(): Promise<void> {
    if (this._valueSubstituted !== undefined && this.Initialized) {

      if (this._isScriptChanged === true) {
        // Clear all partial expressions
        if (this._partialExpressionDict !== undefined) {
          this._partialExpressionDict.clear();
        }

        // clear the all the datapoints
        if (this.Datapoints !== undefined && this.Datapoints.length > 0) {
          // Unsubscribe and clear the datapoints.
          this.Datapoints.forEach(datapoint => {
            const subscription: Subscription = this._subscriptions.get(datapoint.Designation);
            if (subscription !== undefined) {
              datapoint.CountUsage--;
              subscription.unsubscribe();
              this._subscriptions.delete(datapoint.Designation);
            }
          });
          this.Datapoints.splice(0, this.Datapoints.length);
          this.NotifyPropertyChanged('Datapoints');
        }

        this._isScriptChanged = false;
      }

      if (this._semanticType === SemanticType.Script) {
        if (this.Value !== undefined) {
          // eslint-disable-next-line
                    const Read: Function = this._readFunction;

          // eslint-disable-next-line
                    const Trace: Function = this._traceFunction;

          // eslint-disable-next-line
                    const ReadCnsDescription: Function = this._readCnsDescription;

          let scriptResult: any;
          try {
            // eslint-disable-next-line
                        scriptResult = eval(this.ValueSubstituted);

          } catch (ex) {
            this.ErrorMessage = 'Script execution failed for expression:' + this.Value + 'Error:' + ex.stack;
            // this.TraceService.error(this.traceModule, this.ErrorMessage);
          }

          this.SetDatapoints();

          // Set the status and result
          this.SetStatusAndResult(scriptResult);
        }
      }
    } else {
      this.ClearDatapoints();
    }
  }

  private SetStatusAndResult(scriptResult: any): void {

    if (this._partialExpressionsCountChanged) {
      this.SetDatapoints();
    }

    // check the status of all datapoints
    // if 1 dp doesn't exists => DoesNotExist or Hide
    // if 1 dp invalid => Invalid
    if (this._datapoints.length > 0) {
      if (this._datapoints.some(dp => dp.Status === DatapointStatus.DoesNotExist)) {
        this.Status = this.HideElementIfDatapointDoesntExist ? DatapointStatus.Hide : DatapointStatus.DoesNotExist;
        this.ErrorMessage = undefined;
        this.Result = undefined;
        return;
      }

      if (this._datapoints.some(dp => dp.Status !== DatapointStatus.Valid)) {
        this.Status = DatapointStatus.Invalid;
        this.ErrorMessage = undefined;
        this.Result = undefined;
        return;
      }
    }

    // Check whether the script had an Index Expression wherein the index was Invalid
    // Since the datapoints do not know.
    let hasInvalidIndexExpression = false;
    if (this._partialExpressionDict !== undefined && this._partialExpressionDict.size > 0) {
      this._partialExpressionDict.forEach(expression => {
        if (expression.PartialExpressions !== undefined) {
          expression.PartialExpressions.forEach(partialExpression => {
            if (partialExpression.IndexIsInvalid) {
              hasInvalidIndexExpression = true;
            }
          });
        }
      });
    }

    if (hasInvalidIndexExpression) {
      this.Status = this.HideElementIfDatapointDoesntExist ? DatapointStatus.Hide : DatapointStatus.DoesNotExist;
      this.ErrorMessage = undefined;
      this.Result = undefined;
      return;
    }

    // now check the result itself
    if (scriptResult !== undefined && (typeof scriptResult === 'number' && !isFinite(scriptResult)) || scriptResult === 'NaN') {
      // invalid result
      this.Status = DatapointStatus.Invalid;
      let message = 'Invalid result';
      if (isNaN(scriptResult)) {
        message += '. Value is not a number.';
      } else {
        message += '. Value is infinite.';
      }
      this.ErrorMessage = message;
      this.Result = undefined;
      return;
    }

    this.Status = DatapointStatus.Valid;
    this.Result = scriptResult;
  }

  private SetDatapoints(): void {
    this.HasRemainingPartialExpressions = false;

    const hasPartialExpressions: boolean = this._partialExpressionDict != null && this._partialExpressionDict.size > 0;
    if (this.Datapoints.length === 0 && !hasPartialExpressions) {
      return; // nothing to do
    }

    // create parsedDatapoints from the next (first) item from every QuestionmarkExpression
    let reEvaluateRequired = false;
    let parsedDatapoints: Datapoint[];
    if (hasPartialExpressions) {
      this._partialExpressionDict.forEach(partialExpressions => {

        const partialExpression: PartialExpression = partialExpressions.GetFirstNonEmptyPartialExpressionWithExistingDatapoint();
        if (partialExpressions.PartialExpressionRemoved) {
          reEvaluateRequired = true; // force a re-evaluate when something changed since the Read() call
        }
        if (partialExpressions.Count > 1) {
          this.HasRemainingPartialExpressions = true;
        }

        // add the datapoint to the parsedDatapoints collection
        if (partialExpression.Datapoint !== undefined) {
          if (parsedDatapoints === undefined) {
            parsedDatapoints = new Array<Datapoint>(partialExpression.Datapoint);
          } else if (!parsedDatapoints.includes(partialExpression.Datapoint)) {
            parsedDatapoints.push(partialExpression.Datapoint);
          }

          // compare value and status and force a re-evaluate when something changed since the Read() call
          if (!reEvaluateRequired && (partialExpression.Datapoint.Status
                        !== partialExpression.InitialDatapointStatus || partialExpression.Datapoint.Value !== partialExpression.InitialDatapointValue)) {
            reEvaluateRequired = true;
          }
          partialExpression.InitialDatapointValue = null; // don't need it anymore
        }
      });
    }

    if (this.Datapoints.length > 0 || parsedDatapoints !== undefined) {
      let toRemove: Datapoint[] = [];
      let toAdd: Datapoint[] = [];

      if (parsedDatapoints === undefined) {
        toRemove = this.Datapoints;
      } else if (this.Datapoints.length === 0) {
        toAdd = parsedDatapoints;
      } else {
        toRemove = this.Datapoints.filter(datapointToRemove => !parsedDatapoints.includes(datapointToRemove));
        toAdd = parsedDatapoints.filter(datapointToAdd => !this.Datapoints.includes(datapointToAdd));
        if (toAdd.length === 0 && toRemove.length === 0) {
          return; // nothing changed
        }
      }

      toRemove.forEach(datapointToRemove => {
        const index: number = this.Datapoints.indexOf(datapointToRemove);
        datapointToRemove.CountUsage--;
        const subscription: Subscription = this._subscriptions.get(datapointToRemove.Designation);
        if (subscription !== undefined) {
          subscription.unsubscribe();
          this._subscriptions.delete(datapointToRemove.Designation);
        }

        this.Datapoints.splice(index, 1);
      });

      toAdd.forEach(datapointToAdd => this.Datapoints.push(datapointToAdd));
      toAdd.forEach(datapointToAdd => {

        datapointToAdd.CountUsage++;
        if (!this._subscriptions.has(datapointToAdd.Designation)) { // if the datapoint is already subscribed, skip
          const subscription: Subscription = datapointToAdd.propertyChanged.subscribe(args => this.Datapoint_PropertyChanged(args));
          this._subscriptions.set(datapointToAdd.Designation, subscription);
        }
      });

      this.NotifyPropertyChanged('Datapoints');
    }

    if (reEvaluateRequired) {
      this.UpdateResult();
    }

    if (this.Datapoints.length > 0 && this.Value.includes(Utility.REPLICATION_WILDCARD)) {
      const firstReplication: Datapoint = this.Datapoints
        .find(dp => dp.Designation !== undefined && dp.Designation.includes(Utility.REPLICATION_WILDCARD));
      if (firstReplication !== undefined) {
        this.SetReplication(firstReplication);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private Read(expression: string): object {

    // expr is e.g. "dp1" or "dp1?" or "dp1?dp2?4"
    // initialize (=split by '?')
    if (this._partialExpressionDict === undefined) {
      this._partialExpressionDict = new Map<string, QuestionMarkExpression>();
    }

    let partialExpressions: QuestionMarkExpression;
    if (!this._partialExpressionDict.has(expression)) {
      // it can happen that new partial expressions are parsed even when _scriptChanged==false
      partialExpressions = new QuestionMarkExpression(expression, this.DataPointService);
      if (partialExpressions.EndsWithQuestionmark) {
        this.HideElementIfDatapointDoesntExist = true; // hide the element when there is nothing after the '?'
      }

      this._partialExpressionDict.set(expression, partialExpressions);
      // this._partialExpressionsCountChanged = partialExpressions.PartialExpressionRemoved;
    } else {
      partialExpressions = this._partialExpressionDict.get(expression);
    }

    // process the next expression
    // this also parses the expression
    let partialExpression: PartialExpression = partialExpressions.GetFirstNonEmptyPartialExpressionWithExistingDatapoint();
    // this._partialExpressionsCountChanged = partialExpressions.PartialExpressionRemoved; // forces to call SetDatapoints()

    // If the current partial expression has an invalid index for a valid datapoint value.
    // Update the current expression with the subsequent expression if available.
    // Which must be an index expression with a valid index
    // (OR)
    // Which is not a index expression
    partialExpression = this.ProcessIndexedExpression(partialExpression, partialExpressions);

    let result: any;
    if (partialExpression !== undefined && partialExpression.ExpressionType === ExpressionType.Literal) {
      result = partialExpression.ExpressionValue;
    } else if (partialExpression.Datapoint !== undefined) {
      const datapoint: Datapoint = partialExpression.Datapoint;

      if (partialExpression.HasIndexToEvaluate && datapoint.Value !== undefined) {
        const parsedValue: any = JSON.parse(datapoint.Value);
        const arrayValues: any[] = Array.isArray(parsedValue) ? parsedValue : [];
        if ((partialExpression.IndexToEvaluate < arrayValues.length)) {
          result = arrayValues[partialExpression.IndexToEvaluate];
        } else {
          partialExpression.IndexIsInvalid = true; // Used to set the expression Status once the script completes.
        }
      } else {
        result = datapoint.Value; // return the datapoint value (it could already be available)
      }

      // store the current value and status. They will be checked in SetDatapoints, when the script finished parsing
      partialExpression.InitialDatapointValue = result;
      partialExpression.InitialDatapointStatus = datapoint.Status;
    }

    return this.ConvertReadResultToType(result);
  }

  private ConvertReadResultToType(readResult: any): any {

    if (readResult === undefined) {
      return readResult;
    }

    try {
      const resultIsArray: any = JSON.parse(readResult);
      if (resultIsArray !== undefined && Array.isArray(resultIsArray)) {
        return resultIsArray;
      }
    } catch (ex) {
      // continue execution - if there is a parse error
    }

    const resultIsString = String(readResult);
    if (resultIsString.trim() === '') { // Empty string return it.
      return resultIsString;
    } else if (resultIsString === 'NaN') {
      return NaN;
    }

    if (!isNaN(+readResult)) {
      const resultIsNumber: number = FormatHelper.StringToNumber(readResult); // Number(readResult);
      if (resultIsNumber !== undefined && !isNaN(resultIsNumber)) {
        return resultIsNumber;
      }
    }

    // NOTE need to know the possible cases
    const booleanString: string = resultIsString.toLowerCase();
    if (resultIsString !== undefined && (booleanString === 'true' || booleanString === 'false')) {
      return booleanString === 'true';
    }

    if (resultIsString !== undefined) {
      return resultIsString;
    }

    return readResult;
  }

  private Trace(message: any): void {
    // TBD Trace - trace channel for scripting needed
    if (message === undefined) {
      return;
    }

    const traceMessage = String(message);
    if (traceMessage.trim().length !== 0) {
      if (!!this.TraceService) {
        this.TraceService.info(TraceChannel.Scripting, traceMessage);
      }
    }
  }

  private ProcessIndexedExpression(currentPartialExpression: PartialExpression, partialExpressions: QuestionMarkExpression): PartialExpression {
    let datapoint: Datapoint;
    while (partialExpressions.Count > 1 && currentPartialExpression.HasIndexToEvaluate) {
      datapoint = currentPartialExpression.Datapoint;
      if (datapoint === undefined || datapoint.Value === undefined) {
        return currentPartialExpression;
      }

      const parsedValue: any = JSON.parse(datapoint.Value);
      const arrayValues: any[] = Array.isArray(parsedValue) ? parsedValue : [];
      if (currentPartialExpression.IndexToEvaluate > arrayValues.length) {
        partialExpressions.Remove(currentPartialExpression);
        // this._partialExpressionsCountChanged = true;
        currentPartialExpression = partialExpressions.GetFirstNonEmptyPartialExpressionWithExistingDatapoint();
      } else {
        break; // If there is a valid index
      }
    }

    return currentPartialExpression;
  }

  private ClearDatapoints(): void {
    this._partialExpression = PartialExpression.Empty();
    this._partialExpressionDict = undefined;

    if (this.Datapoints !== undefined && this.Datapoints.length > 0) {
      // Unsubscribe and clear the datapoints.
      this.Datapoints.forEach(datapoint => {
        const subscription: Subscription = this._subscriptions.get(datapoint.Designation);
        if (subscription !== undefined) {
          datapoint.CountUsage--;
          subscription.unsubscribe();
          this._subscriptions.delete(datapoint.Designation);
        }
      });
      this.Datapoints.splice(0, this.Datapoints.length);
    }
    this.NotifyPropertyChanged('Datapoints');

    this.HideElementIfDatapointDoesntExist = false;
    this.HasRemainingPartialExpressions = false;
    this.Status = DatapointStatus.Valid;
    this.Result = undefined;
  }

  private SubscribeToViewChange(): void {
    if (!this._subscribedToViewChange && this.Evaluation !== undefined) {
      this._subscribedToViewChange = true;
      if (this.CnsHelperService !== undefined && this._activeViewChangeSubscription === undefined) {
        this._activeViewChangeSubscription = this.CnsHelperService.activeView.subscribe(view => this.activeViewChange(view));
      }
    }
  }

  private async activeViewChange(viewInfo: ViewInfo): Promise<void> {
    if (viewInfo !== undefined && this._hasReadCnsScript) {
      let partialExpressions: PartialExpression[] = [];
      // Get all the partial expressions which need a cns update
      this._partialExpressionDict.forEach((value: QuestionMarkExpression) => {
        const cnsNeedExpressions: PartialExpression[] = value.PartialExpressions
          .filter(partialexpression => partialexpression.NeedCNSInfo);
        partialExpressions = partialExpressions.concat(cnsNeedExpressions);
      });
      this.UpdateCnsDescription(partialExpressions);
    }
  }

  private UnsubscribeToViewChange(): void {
    if (this._activeViewChangeSubscription !== undefined) {
      this._activeViewChangeSubscription.unsubscribe();
      this._activeViewChangeSubscription = undefined;
    }
    this._subscribedToViewChange = false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private ReadCnsDescription(expression: string): object {
    let result: any;
    if (expression === '' || expression === undefined) {
      return result;
    }

    if (this._partialExpressionDict === undefined) {
      this._partialExpressionDict = new Map<string, QuestionMarkExpression>();
    }

    let partialExpressions: QuestionMarkExpression;
    if (!this._partialExpressionDict.has(expression)) {
      // it can happen that new partial expressions are parsed even when _scriptChanged==false
      partialExpressions = new QuestionMarkExpression(expression, this.DataPointService);
      if (partialExpressions.EndsWithQuestionmark) {
        this.HideElementIfDatapointDoesntExist = true; // hide the element when there is nothing after the '?'
      }

      this._partialExpressionDict.set(expression, partialExpressions);
    } else {
      partialExpressions = this._partialExpressionDict.get(expression);
    }

    const partialExpression: PartialExpression = partialExpressions.GetFirstNonEmptyPartialExpressionWithExistingDatapoint();
    if (partialExpression.HasIndexToEvaluate) {
      // not supported, so return undefined
      return result;
    }
    if (partialExpression !== undefined && partialExpression.ExpressionType === ExpressionType.Literal) {
      return partialExpression.ExpressionValue;
    } else if (partialExpression.Datapoint !== undefined) {
      const datapoint: Datapoint = partialExpression.Datapoint;
      if (datapoint.Status === DatapointStatus.Valid || datapoint.Status === DatapointStatus.Invalid) {
        this._hasReadCnsScript = true;
        if (partialExpression.CNSDescription.length === 0) {
          // active view change will provide info based on this flag
          partialExpression.NeedCNSInfo = true;

          this.GetCnsDescription(datapoint, partialExpression);
        }

        if (!this._subscribedToViewChange && this.Evaluation !== undefined) {
          this.SubscribeToViewChange();
        }

        result = partialExpression.CNSDescription;
      }
      partialExpression.InitialDatapointStatus = datapoint.Status;
    }
    return result;
  }

  private GetCnsDescription(datapoint: Datapoint, partialExpression: PartialExpression): void {

    if (this._collectBrowserObjectsSubscription !== undefined) {
      this._collectBrowserObjectsSubscription.unsubscribe();
      this._collectBrowserObjectsSubscription = undefined;
    }
    if (this.GmsBrowserObjectService !== undefined) {
      this.GmsBrowserObjectService.collectBrowserObjects([datapoint.Designation]).subscribe(
        ret => this.OnCollectBrowserObjects(ret, [partialExpression]),
        error => this.OnCollectBrowserObjectsError(error));
    }
  }

  // Bulk update of the partial expressions which need CNS Info.
  // Usecase - A script can have multiple ReadCnsDescription calls.
  private UpdateCnsDescription(partialExpressions: PartialExpression[]): void {
    if (this._collectBrowserObjectsSubscription !== undefined) {
      this._collectBrowserObjectsSubscription.unsubscribe();
      this._collectBrowserObjectsSubscription = undefined;
    }

    const designations: string[] = partialExpressions.map(partialExpression => partialExpression.Datapoint.Designation);
    if (this.GmsBrowserObjectService !== undefined) {
      this.GmsBrowserObjectService.collectBrowserObjects(designations).subscribe(
        ret => this.OnCollectBrowserObjects(ret, partialExpressions),
        error => this.OnCollectBrowserObjectsError(error));
    }
  }

  private OnCollectBrowserObjects(result: BrowserObject[], partialExpressions: PartialExpression[]): void {
    if (this._collectBrowserObjectsSubscription !== undefined) {
      this._collectBrowserObjectsSubscription.unsubscribe();
      this._collectBrowserObjectsSubscription = undefined;
    }

    if (this.CnsHelperService !== undefined && result !== undefined && result.length === partialExpressions.length) {
      try {
        for (let i = 0; i < result.length; i++) {
          // Cns Description only
          const cnsDescription: string = result[i].Descriptor;
          partialExpressions[i].CNSDescription = cnsDescription !== undefined ? cnsDescription : '';
        }
      } catch (error: any) {
        this.TraceService.error(this.traceModule, `collectBrowserObjects: Failed to update cns description.`);
      }
    } else {
      this.TraceService.error(this.traceModule,
        `collectBrowserObjects: No valid designation exist`);
    }
    this.Evaluate();
  }

  private OnCollectBrowserObjectsError(error: Error): void {
    if (this._collectBrowserObjectsSubscription !== undefined) {
      this._collectBrowserObjectsSubscription.unsubscribe();
      this._collectBrowserObjectsSubscription = undefined;

    }
    this.TraceService.error(this.traceModule, `collectBrowserObjects: ` + error.message);
  }
}

export class QuestionMarkExpression {
  // Index to evaluate against the array property of a data point.
  public _endsWithQuestionmark: boolean;
  public get EndsWithQuestionmark(): boolean {
    return this._endsWithQuestionmark;
  }
  public set EndsWithQuestionmark(value: boolean) {
    if (this._endsWithQuestionmark !== value) {
      this._endsWithQuestionmark = value;
    }
  }

  private readonly _partialExpressions: PartialExpression[] = new Array<PartialExpression>();
  public get PartialExpressions(): PartialExpression[] {
    return this._partialExpressions;
  }

  private _partialExpressionRemoved: boolean;
  public get PartialExpressionRemoved(): boolean {
    return this._partialExpressionRemoved;
  }

  public get Count(): number {
    return this._partialExpressions !== undefined ? this._partialExpressions.length : 0;
  }

  public constructor(expression: string, datapointService: DataPointService) {
    const partialExpressions: string[] = expression.split('?');
    for (let i = 0; i < partialExpressions.length; i++) {
      const partialexp: string = partialExpressions[i];
      const partialExpression: PartialExpression = new PartialExpression();
      partialExpression.DatapointService = datapointService;
      partialExpression.Expr = partialexp;
      this._partialExpressions.push(partialExpression);
    }

    const expressionsCount: number = this._partialExpressions.length;
    // remove the last item if it's empty
    if (expressionsCount === 1 && expression.endsWith('?')) {
      this.EndsWithQuestionmark = true;
    }
  }

  public GetFirstNonEmptyPartialExpressionWithExistingDatapoint(): PartialExpression {
    this._partialExpressionRemoved = false;
    while (true) {
      // process the next expression (the first item in the remaining collection
      // 'partialExpressions'), i.e take the next espression from the top of the list and remove it
      const partialExpression: PartialExpression = this._partialExpressions[0];
      partialExpression.Parse();
      const expressionsCount: number = this._partialExpressions.length;
      if (expressionsCount === 1) {
        return partialExpression; // always return the last item
      }

      // check whether the datapoint is already known as DoesNotExist
      if (partialExpression.Expr.length > 0 && (partialExpression.Datapoint === undefined
                || partialExpression.ExpressionValue.includes(Utility.REPLICATION_WILDCARD)
                || partialExpression.Datapoint.Status !== DatapointStatus.DoesNotExist)) {
        return partialExpression; // either no datapoint or datapoint does not exist
      }

      const indexToRemove: number = this._partialExpressions.indexOf(partialExpression);
      this._partialExpressions.splice(indexToRemove, 1);
      this._partialExpressionRemoved = true;
    }
  }

  public Remove(partialExpression: PartialExpression): void {
    const indexToRemove: number = this._partialExpressions.indexOf(partialExpression);
    if (indexToRemove !== -1) {
      this._partialExpressions.splice(indexToRemove, 1);
    }
  }

  public Clear(): void {
    this._partialExpressions.length = 0;
  }
}

export class PartialExpression {
  public _expr = '';
  public get Expr(): string {
    return this._expr;
  }
  public set Expr(value: string) {
    if (this._expr !== value) {
      this._expr = value;
    }
  }

  private _expressionType: ExpressionType = ExpressionType.Ref;
  public get ExpressionType(): ExpressionType {
    return this._expressionType;
  }
  public set ExpressionType(value: ExpressionType) {
    if (this._expressionType !== value) {
      this._expressionType = value;
    }
  }

  private _expressionValue: any = undefined;
  public get ExpressionValue(): any {
    return this._expressionValue;
  }
  public set ExpressionValue(value: any) {
    if (this._expressionValue !== value) {
      this._expressionValue = value;
    }
  }

  private _errorMessage = '';
  public get ErrorMessage(): string {
    return this._errorMessage;
  }
  public set ErrorMessage(value: string) {
    if (this._errorMessage !== value) {
      this._errorMessage = value;
    }
  }

  private _datapoint: Datapoint = undefined;
  public get Datapoint(): Datapoint {
    return this._datapoint;
  }
  public set Datapoint(value: Datapoint) {
    if (this._datapoint !== value) {
      this._datapoint = value;
    }
  }

  // private _subscription: Subscription = undefined;
  // public get Subscription(): Subscription {
  //    return this._subscription;
  // }
  // public set Subscription(value: Subscription) {
  //    if (this._subscription !== value) {
  //        this._subscription = value;
  //    }
  // }

  private _initialDatapointValue: any = undefined;
  public get InitialDatapointValue(): any {
    return this._initialDatapointValue;
  }
  public set InitialDatapointValue(value: any) {
    if (this._initialDatapointValue !== value) {
      this._initialDatapointValue = value;
    }
  }

  private _initialDatapointStatus: DatapointStatus = DatapointStatus.Undefined;
  public get InitialDatapointStatus(): DatapointStatus {
    return this._initialDatapointStatus;
  }
  public set InitialDatapointStatus(value: DatapointStatus) {
    if (this._initialDatapointStatus !== value) {
      this._initialDatapointStatus = value;
    }
  }

  private _datapointService: DataPointService = undefined;
  public get DatapointService(): DataPointService {
    return this._datapointService;
  }
  public set DatapointService(value: DataPointService) {
    if (this._datapointService !== value) {
      this._datapointService = value;
    }
  }

  // Regular expression to match expression string with Index at the end.
  // ex- ObjectRef.ArrayProperty[Integer Value].
  // Note define specific examples for the arrayproperty type.
  // eslint-disable-next-line
    private _regExIndexedExpression: any = new RegExp("\[[0-9]*\]$");

  // True if reference expression has a valid index to evaluate.
  private _hasIndexToEvaluate = false;
  public get HasIndexToEvaluate(): boolean {
    return this._hasIndexToEvaluate;
  }
  public set HasIndexToEvaluate(value: boolean) {
    if (this._hasIndexToEvaluate !== value) {
      this._hasIndexToEvaluate = value;
    }
  }

  // Index to evaluate against the array property of a data point.
  private indexToEvaluate: number;
  public get IndexToEvaluate(): number {
    return this.indexToEvaluate;
  }
  public set IndexToEvaluate(value: number) {
    if (this.indexToEvaluate !== value) {
      this.indexToEvaluate = value;
    }
  }

  // True if the IndexToEvaluate is out of range to the Datapoint.Value
  private _indexIsInvalid = false;
  public get IndexIsInvalid(): boolean {
    return this._indexIsInvalid;
  }
  public set IndexIsInvalid(value: boolean) {
    if (this._indexIsInvalid !== value) {
      this._indexIsInvalid = value;
    }
  }

  private _parsed = false;

  // current value of the CNS Description
  private _cnsDescription = '';
  public get CNSDescription(): string {
    return this._cnsDescription;
  }
  public set CNSDescription(value: string) {
    if (this._cnsDescription !== value) {
      this._cnsDescription = value;
    }
  }

  // True - only if the partial expression datapoint exists
  private _needsCNSInfo: boolean;
  public get NeedCNSInfo(): boolean {
    return this._needsCNSInfo;
  }
  public set NeedCNSInfo(value: boolean) {
    if (this._needsCNSInfo !== value) {
      this._needsCNSInfo = value;
    }
  }

  public static Empty(): PartialExpression {
    const empty: PartialExpression = new PartialExpression(ExpressionType.None, '');
    empty._parsed = true;
    return empty;
  }

  constructor();
  constructor(expressionType: ExpressionType, expressionValue: string);
  constructor(expressionType: ExpressionType = ExpressionType.None, expressionValue: string = undefined) {
    this.ExpressionType = expressionType;
    this.ExpressionValue = expressionValue;

    if (expressionValue !== undefined) {
      this._parsed = true;
    }
  }

  /**
   * Parses the expression and sets the properties ErrorMessage, ExpressionType, ExpressionValue and Datapoint (if skipDatapoint is set to false)
   * @param skipDatapoint Don't set the Datapoint property if set to true
   * @returns
   */
  public Parse(skipDatapoint: boolean = false): void {
    if (this._parsed) {
      return; // already parsed
    }
    this._parsed = true;

    if (this.Expr.length === 0) {
      return; // empty expression
    }

    const expressionParseOutput: ExpressionParseOutput = new ExpressionParseOutput();
    Expression.Parse(this.Expr, '.', expressionParseOutput);
    this.ErrorMessage = expressionParseOutput.ErrorMessage;
    this.ExpressionType = expressionParseOutput.ExpressionType;
    this.ExpressionValue = expressionParseOutput.Value;

    // get (or add) the datapoint from the global collection
    if (!skipDatapoint && expressionParseOutput.ExpressionType === ExpressionType.Ref) {
      let expressionValueString: string = expressionParseOutput.Value;
      // Removes trailing index from the expression ex: DP.ArrayProperty[integer value]
      // If the replication [*] is not replaced with valid index, Skip.
      // Such that the partial expression of the resolved expression has the index and flag set.
      if (!expressionValueString.includes(Utility.REPLICATION_WILDCARD)) {
        const matches: string[] = expressionValueString.match((this._regExIndexedExpression));
        if (matches !== null) {
          const indexString: string = matches[0];
          const index: string = indexString.substring(1, indexString.length - 1);
          this.HasIndexToEvaluate = parseInt(index, 10) >= 0;
          this.IndexToEvaluate = parseInt(index, 10);
          expressionValueString = this.HasIndexToEvaluate ? expressionValueString.replace(indexString, '') : expressionValueString;
          this.ExpressionValue = expressionValueString;
        }
      }

      if (this.DatapointService !== undefined) {
        const designation: string = GraphicsDatapointHelper.RemoveLeadingSemicolons(expressionValueString);
        if (designation !== '') {
          this.Datapoint = this.DatapointService.GetOrCreateByDesignation(designation);
        }
      }
    }
  }

  public Clear(): void {
    this._datapointService = undefined;
    this._regExIndexedExpression = undefined;
    this._datapoint = undefined;
  }
}

export enum ExpressionProperties {
  Value = `Value`,
  // eslint-disable-next-line
    SemanticType = `SemanticType`
}

export class ExpressionParseOutput {
  private _expressionType: ExpressionType = ExpressionType.None;
  public get ExpressionType(): ExpressionType {
    return this._expressionType;
  }
  public set ExpressionType(value: ExpressionType) {
    if (this._expressionType !== value) {
      this._expressionType = value;
    }
  }

  private _value: any = undefined;
  public get Value(): any {
    return this._value;
  }
  public set Value(value: any) {
    if (this._value !== value) {
      this._value = value;
    }
  }

  private _errorMessage: string = undefined;
  public get ErrorMessage(): string {
    return this._errorMessage;
  }
  public set ErrorMessage(value: string) {
    if (this._errorMessage !== value) {
      this._errorMessage = value;
    }
  }
}
