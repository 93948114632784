import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';

import { TraceChannel } from '../common/trace-channel';
import { GmsAnimatedGif } from '../elements/gms-animated-gif';
import { GmsCommandControl } from '../elements/gms-commandcontrol';
import { GmsCommandControlNumeric } from '../elements/gms-commandcontrol-numeric';
import { GmsElement } from '../elements/gms-element';
import { GmsEllipse } from '../elements/gms-ellipse';
import { GmsGroup } from '../elements/gms-group';
import { GmsImage } from '../elements/gms-image';
import { GmsLine } from '../elements/gms-line';
import { GmsPath } from '../elements/gms-path';
import { GmsPolygon } from '../elements/gms-polygon';
import { GmsRectangle } from '../elements/gms-rectangle';
import { GmsSymbolInstance } from '../elements/gms-symbol-instance';
import { GmsText } from '../elements/gms-text';
import { GmsXps } from '../elements/gms-xps';
import { Replication } from '../processor/replication/replication';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementType } from '../types/gms-element-types';

@Injectable()
export class GmsReplicationService implements OnDestroy {

  private readonly traceModule: string = 'gmsSnapins_ReplicationService';
  private readonly replications: Replication[] = new Array<Replication>();
  private graphicLoaded = false;

  private _currentReplicationCloned: Replication = undefined;
  public get CurrentReplication(): Replication {
    return this._currentReplicationCloned;
  }

  public constructor(private readonly traceService: TraceService) {
    traceService.info(this.traceModule, 'GmsReplicationService created.');
  }

  public CopyChildren(fromElement: GmsSymbolInstance | GmsGroup, toElement: GmsSymbolInstance | GmsGroup): void {
    let element: GmsElement;
    try {
      for (let i = 0; i < fromElement.children.length; i++) {

        const sourceElement: GmsElement = fromElement.children[i];
        const sourceElementType: GmsElementType = sourceElement.Replication === undefined ? sourceElement.Type : sourceElement.OriginalType;

        switch (sourceElementType) {
          case GmsElementType.Group:
            const group: GmsGroup = new GmsGroup();
            group.Graphic = sourceElement.Graphic;
            group.CopyFrom(sourceElement as GmsGroup);
            group.Parent = toElement;
            element = group;
            break;
          case GmsElementType.Path:
            const path: GmsPath = new GmsPath();
            path.Graphic = sourceElement.Graphic;
            path.CopyFrom(sourceElement as GmsPath);
            path.Parent = toElement;
            element = path;
            break;
          case GmsElementType.Polygon:
            const polygon: GmsPolygon = new GmsPolygon();
            polygon.Graphic = sourceElement.Graphic;
            polygon.CopyFrom(sourceElement as GmsPolygon);
            polygon.Parent = toElement;
            element = polygon;
            break;
          case GmsElementType.Ellipse:
            const ellipse: GmsEllipse = new GmsEllipse();
            ellipse.Graphic = sourceElement.Graphic;
            ellipse.CopyFrom(sourceElement as GmsEllipse);
            ellipse.Parent = toElement;
            element = ellipse;
            break;
          case GmsElementType.Rectangle:
            const rectangle: GmsRectangle = new GmsRectangle();
            rectangle.Graphic = sourceElement.Graphic;
            rectangle.CopyFrom(sourceElement as GmsRectangle);
            rectangle.Parent = toElement;
            element = rectangle;
            break;
          case GmsElementType.Line:
            const line: GmsLine = new GmsLine();
            line.Graphic = sourceElement.Graphic;
            line.CopyFrom(sourceElement as GmsLine);
            line.Parent = toElement;
            element = line;
            break;
          case GmsElementType.Text:
            const text: GmsText = new GmsText();
            text.Graphic = sourceElement.Graphic;
            text.CopyFrom(sourceElement as GmsText);
            text.Parent = toElement;
            element = text;
            break;
          case GmsElementType.Image:
            const image: GmsImage = new GmsImage();
            image.Graphic = sourceElement.Graphic;
            image.CopyFrom(sourceElement as GmsImage);
            image.Parent = toElement;
            element = image;
            break;
          case GmsElementType.AnimatedGif:
            const animatedGif: GmsAnimatedGif = new GmsAnimatedGif();
            animatedGif.Graphic = sourceElement.Graphic;
            animatedGif.CopyFrom(sourceElement as GmsAnimatedGif);
            animatedGif.Parent = toElement;
            element = animatedGif;
            break;
          case GmsElementType.Xps:
            const xps: GmsXps = new GmsXps();
            xps.Graphic = sourceElement.Graphic;
            xps.CopyFrom(sourceElement as GmsXps);
            xps.Parent = toElement;
            element = xps;
            break;
          case GmsElementType.SymbolInstance:
            const symbolInstance: GmsSymbolInstance = new GmsSymbolInstance();
            symbolInstance.Graphic = sourceElement.Graphic;
            symbolInstance.CopyFrom(sourceElement as GmsSymbolInstance);
            symbolInstance.Parent = toElement;

            symbolInstance.UpdateWidthOfChildren();
            symbolInstance.UpdateHeightOfChildren();
            symbolInstance.ProcessSubstitutions();

            element = symbolInstance;
            break;
          case GmsElementType.CommandControl:
            const commandControl: GmsCommandControl = sourceElement as GmsCommandControl;
            switch (commandControl.ControlType) {
              case GmsCommandControlType.Numeric:
                const numericCommandControl: GmsCommandControlNumeric = new GmsCommandControlNumeric();
                numericCommandControl.Graphic = sourceElement.Graphic;
                numericCommandControl.CopyFrom(sourceElement as GmsCommandControlNumeric);
                numericCommandControl.Parent = toElement;
                element = numericCommandControl;
                break;
              default:
                element = undefined;
                break;
            }
            break;
          default:
            element = undefined;
            break;
        }
      }
    } catch (ex) {
      fromElement.Graphic.TraceService.error(TraceChannel.Services, 'Replication Cloning - Copy Children failed:', ex.stack);
    }
  }

  public CreateClone(sourceElement: GmsElement, parentReplication: Replication): GmsElement {
    this._currentReplicationCloned = parentReplication;
    let clone: GmsElement;
    const sourceElementType: GmsElementType = sourceElement.Replication === undefined ? sourceElement.Type : sourceElement.OriginalType;

    try {
      switch (sourceElementType) {
        case GmsElementType.Group:
          const group: GmsGroup = new GmsGroup();
          group.IsReplicationClone = true;
          group.Graphic = sourceElement.Graphic;
          group.CopyFrom(sourceElement as GmsGroup);
          clone = group;
          break;
        case GmsElementType.Path:
          const path: GmsPath = new GmsPath();
          path.IsReplicationClone = true;
          path.Graphic = sourceElement.Graphic;
          path.CopyFrom(sourceElement as GmsPath);
          clone = path;
          break;
        case GmsElementType.Polygon:
          const polygon: GmsPolygon = new GmsPolygon();
          polygon.IsReplicationClone = true;
          polygon.Graphic = sourceElement.Graphic;
          polygon.CopyFrom(sourceElement as GmsPolygon);
          clone = polygon;
          break;
        case GmsElementType.Ellipse:
          const ellipse: GmsEllipse = new GmsEllipse();
          ellipse.IsReplicationClone = true;
          ellipse.Graphic = sourceElement.Graphic;
          ellipse.CopyFrom(sourceElement as GmsEllipse);
          clone = ellipse;
          break;
        case GmsElementType.Rectangle:
          const rectangle: GmsRectangle = new GmsRectangle();
          rectangle.IsReplicationClone = true;
          rectangle.Graphic = sourceElement.Graphic;
          rectangle.CopyFrom(sourceElement as GmsRectangle);
          clone = rectangle;
          break;
        case GmsElementType.Line:
          const line: GmsLine = new GmsLine();
          line.IsReplicationClone = true;
          line.Graphic = sourceElement.Graphic;
          line.CopyFrom(sourceElement as GmsLine);
          clone = line;
          break;
        case GmsElementType.Text:
          const text: GmsText = new GmsText();
          text.IsReplicationClone = true;
          text.Graphic = sourceElement.Graphic;
          text.CopyFrom(sourceElement as GmsText);
          clone = text;
          break;
        case GmsElementType.Image:
          const image: GmsImage = new GmsImage();
          image.IsReplicationClone = true;
          image.Graphic = sourceElement.Graphic;
          image.CopyFrom(sourceElement as GmsImage);
          clone = image;
          break;
        case GmsElementType.AnimatedGif:
          const animatedGif: GmsAnimatedGif = new GmsAnimatedGif();
          animatedGif.IsReplicationClone = true;
          animatedGif.Graphic = sourceElement.Graphic;
          animatedGif.CopyFrom(sourceElement as GmsAnimatedGif);
          clone = animatedGif;
          break;
        case GmsElementType.Xps:
          const xps: GmsXps = new GmsXps();
          xps.IsReplicationClone = true;
          xps.Graphic = sourceElement.Graphic;
          xps.CopyFrom(sourceElement as GmsXps);
          clone = xps;
          break;
        case GmsElementType.SymbolInstance:
          const symbolInstance: GmsSymbolInstance = new GmsSymbolInstance();
          symbolInstance.IsReplicationClone = true;
          symbolInstance.Graphic = sourceElement.Graphic;

          symbolInstance.CopyFrom(sourceElement as GmsSymbolInstance);
          symbolInstance.UpdateWidthOfChildren();
          symbolInstance.UpdateHeightOfChildren();
          symbolInstance.ProcessSubstitutions();

          clone = symbolInstance;
          break;
        case GmsElementType.CommandControl:
          const commandControl: GmsCommandControl = sourceElement as GmsCommandControl;
          switch (commandControl.ControlType) {
            case GmsCommandControlType.Numeric:
              const numericCommandControl: GmsCommandControlNumeric = new GmsCommandControlNumeric();
              numericCommandControl.IsReplicationClone = true;
              numericCommandControl.Graphic = sourceElement.Graphic;
              numericCommandControl.CopyFrom(sourceElement as GmsCommandControlNumeric);
              clone = numericCommandControl;
              break;
            default:
              clone = undefined;
              break;
          }
          break;
        default:
          clone = undefined;
          break;
      }
    } catch (ex) {
      sourceElement.Graphic.TraceService.error(TraceChannel.Services, 'Replication Cloning - Create clone failed:', ex.stack);
    }

    this._currentReplicationCloned = undefined;
    return clone;
  }

  public CreateReplication(owner: GmsElement): Replication {
    const replication: Replication = new Replication(owner);
    replication.IsCloneReady = this.graphicLoaded;
    this.replications.push(replication);
    return replication;
  }

  public Initialize(): void {
    this.replications.length = 0;
    this.graphicLoaded = false;
  }

  // Only create clones after the deserialization is complete.
  public async ProcessDeferredReplications(): Promise<void> {
    this.graphicLoaded = true;
    this.replications.forEach((value: Replication) => {

      // Mark the replication, if the graphic load/deserialization is complete.
      value.IsCloneReady = true;

      // CreateClones if replication-wildcardreference is resolved.
      // Else createclones will be intitiated by replication.
      if (value.IsResolved) {
        value.CreateClones();
      }
    });

    this.replications.length = 0;
  }

  public ngOnDestroy(): void {
    this.replications.length = 0;
    if (this._currentReplicationCloned !== undefined) {
      this._currentReplicationCloned = undefined;
    }
  }
}
