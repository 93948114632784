import { GmsDepths } from '../processor/gms-depth';
import { SvgUtility } from '../utilities/parser';

export class GmsViewport {
  private readonly _x: number = 0;
  public get X(): number {
    return this._x;
  }

  private readonly _y: number = 0;
  public get Y(): number {
    return this._y;
  }

  private readonly _width: number = 0;
  public get Width(): number {
    return this._width;
  }

  private readonly _height: number = 0;
  public get Height(): number {
    return this._height;
  }

  public get IsAutoViewport(): boolean {
    return false;
  }

  public get Value(): number[] {
    return [this._x, this._y, this._width, this._height];
  }

  public _depthIndex: number = undefined;
  public get DepthIndex(): number {
    return this._depthIndex;
  }

  public set DepthIndex(value: number) {
    if (this._depthIndex !== value) {
      this._depthIndex = value;
    }
  }

  constructor(x: number, y: number, width: number, height: number) {
    this._x = x;
    this._y = y;
    this._width = width;
    this._height = height;
  }

  // eslint-disable-next-line
    public static createViewport(graphicNode: Node, viewportName: string, depths: GmsDepths): GmsViewport {
    const childNodes: NodeList = graphicNode.childNodes;
    if (childNodes !== undefined) {
      for (let i = 0; i < childNodes.length; ++i) {
        if (childNodes[i].nodeName === 'Graphic.Viewports') {
          const viewportNodes: any = childNodes[i].childNodes;
          for (let j = 0; j < viewportNodes.length; ++j) {
            const currentNode: any = viewportNodes[j];
            if (currentNode.attributes === undefined) {
              continue;
            }

            if (SvgUtility.GetAttributeValue(currentNode, 'Name') === viewportName) {
              const viewportString: string = SvgUtility.GetAttributeValue(currentNode, 'Viewport');
              const [x, y, width, height] = viewportString.split(',').map(val => Number(val));
              const result: GmsViewport = new GmsViewport(x, y, width, height);

              const associatedDepthString: string = SvgUtility.GetAttributeValue(currentNode, 'AssociatedDepth');
              const index: number = depths.depthList.findIndex(depth => (depth.Name === associatedDepthString));
              if (index !== undefined) {
                result.DepthIndex = index;
              } else {
                result.DepthIndex = 0;
              }

              return result;
            }
          }
        }
      }
    }

    return undefined;
  }
}
