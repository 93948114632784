import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

export type Direction = 'left' | 'right';

@Component({
  selector: 'si-calendar-direction-button',
  template: `<button
    role="button"
    type="button"
    class="btn btn-circle btn-sm btn-tertiary"
    [ngClass]="buttonClass"
    [disabled]="disabled || null"
    [attr.aria-label]="ariaLabel"
    (click)="onClick()"
  >
    <i class="icon flip-rtl" [ngClass]="icon"></i>
  </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class SiCalendarDirectionButtonComponent {
  @Input() ariaLabel = '';
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input() set direction(v: Direction) {
    switch (v) {
      case 'left':
        this.icon = 'element-left-2';
        this.buttonClass = 'previous-button';
        break;
      case 'right':
        this.icon = 'element-right-2';
        this.buttonClass = 'next-button';
        break;
    }
  }

  /**
   * Emit on button click.
   */
  @Output() readonly clicked = new EventEmitter();

  protected onClick(): void {
    this.clicked.emit();
  }

  protected icon!: string;
  protected buttonClass!: string;
}
