@if (!isCheckItem || (!fieldset && label)) {
  @if (label) {
    @if (fieldControl?.labelledby) {
      <span
        class="text-break form-label"
        [id]="fieldControl!.labelledby"
        [class.required]="required"
        >{{ label | translate }}</span
      >
    } @else {
      <label class="text-break form-label" [for]="fieldControl?.id" [class.required]="required">{{
        label | translate
      }}</label>
    }
  }
  <div
    class="form-item-content"
    [class.form-control-has-icon]="
      printErrors &&
      fieldControl?.supportsValidationIcon &&
      ngControl?.invalid &&
      ngControl?.touched
    "
  >
    <!--
     This is necessary for the fallback to work.
     At first checkbox will be inserted here, so that we can  query the html element.
     Then we detect it is a checkbox and the ui is updated.
    -->
    <ng-container [ngTemplateOutlet]="checkbox" />
    <ng-container [ngTemplateOutlet]="content" />
    @if (printErrors) {
      @if (fieldControl?.supportsValidationIcon) {
        <span class="invalid-icon element-validation-issue"></span>
      }
      <div class="invalid-feedback">
        @for (error of errors; track error) {
          <div>
            {{ error.message | translate: error.params }}
          </div>
        }
      </div>
    }
  </div>
} @else {
  <!--Block the space if no form-field is provided. Otherwise it looks weird.-->
  @if (!fieldset) {
    <div class="form-label"></div>
  }
  <div class="form-item-content">
    <ng-container [ngTemplateOutlet]="checkbox" />
    @if (fieldControl?.labelledby) {
      <span
        class="text-break form-check-label"
        [id]="fieldControl!.labelledby"
        [class.required]="required"
        >{{ label! | translate }}</span
      >
    } @else {
      <label
        class="text-break form-check-label"
        [for]="fieldControl?.id"
        [class.required]="required"
        >{{ label! | translate }}</label
      >
    }
    <ng-container [ngTemplateOutlet]="content" />
    @if (printErrors) {
      <div class="invalid-feedback">
        @for (error of errors; track error) {
          <div>
            {{ error.message | translate: error.params }}
          </div>
        }
      </div>
    }
  </div>
}

<ng-template #checkbox>
  <ng-content select="input[type='checkbox'], input[type='radio']" />
</ng-template>
<ng-template #content>
  <ng-content />
</ng-template>
