import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-pagination',
  templateUrl: './si-pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiPaginationComponent implements OnChanges {
  private static maxItems = 7;

  /**
   * The total number of pages.
   * Alternatively use `itemsPerPage` and `totalItems`
   */
  @Input() totalPages?: number;
  /**
   * The current active page, counting starts from 1
   */
  @Input() currentPage = 1;
  /**
   * The number of items per page.
   * Used to calculate the total number of page if `totalPages` is not available
   */
  @Input() pageSize?: number;
  /**
   * The total number of items.
   * Used to calculate the total number of page if `totalPages` is not available
   */
  @Input() totalRowCount?: number;

  /**
   * The text of the back button for pagination. Required for a11y.
   */
  @Input() backButtonText = $localize`:@@SI_PAGINATION.BACK:Back`;

  /**
   * The text of the forward button for pagination. Required for a11y.
   */
  @Input() forwardButtonText = $localize`:@@SI_PAGINATION.FORWARD:Forward`;
  /**
   * When multiple paginations are used on the same page each pagination needs a distinct aria label.
   * Required for a11y.
   */
  @Input() navAriaLabel = $localize`:@@SI_PAGINATION.NAV_LABEL:Pagination`;

  /**
   * Fired when the user changes the page
   */
  @Output() readonly currentPageChange = new EventEmitter<number>();

  protected pageButtons: { page: number; sep: boolean }[] = [];
  protected prevDisabled = false;
  protected nextDisabled = false;

  ngOnChanges(): void {
    this.createPages();
  }

  private createPages(): void {
    this.pageButtons = [];

    const totalPages =
      this.totalPages ?? Math.ceil((this.totalRowCount ?? 0) / (this.pageSize ?? 1));

    if (totalPages <= SiPaginationComponent.maxItems) {
      for (let i = 0; i < totalPages; i++) {
        this.pageButtons.push({ page: i + 1, sep: false });
      }
    } else if (this.currentPage < SiPaginationComponent.maxItems - 2) {
      // separator only on the right (in LTR)
      for (let i = 0; i < SiPaginationComponent.maxItems - 2; i++) {
        this.pageButtons.push({ page: i + 1, sep: false });
      }
      this.pageButtons.push({ page: 0, sep: true });
      this.pageButtons.push({ page: totalPages, sep: false });
    } else if (this.currentPage >= totalPages - 3) {
      // separator only on the left (in LTR)
      this.pageButtons.push({ page: 1, sep: false });
      this.pageButtons.push({ page: 0, sep: true });
      for (let i = totalPages - SiPaginationComponent.maxItems + 2; i < totalPages; i++) {
        this.pageButtons.push({ page: i + 1, sep: false });
      }
    } else {
      // separator on both sides
      this.pageButtons.push({ page: 1, sep: false });
      this.pageButtons.push({ page: 0, sep: true });
      for (let i = -1; i <= 1; i++) {
        this.pageButtons.push({ page: this.currentPage + i, sep: false });
      }
      this.pageButtons.push({ page: 0, sep: true });
      this.pageButtons.push({ page: totalPages, sep: false });
    }

    this.prevDisabled = this.currentPage === 1;
    this.nextDisabled = this.currentPage === totalPages;
  }

  protected direction(event: Event, delta: number): void {
    this.setPage(event, this.currentPage + delta);
  }

  protected setPage(event: Event, page: number): void {
    (event.currentTarget as HTMLElement).blur();
    this.currentPage = page;
    this.createPages();
    this.currentPageChange.emit(page);
  }
}
