export enum GmsDataPointPropertyType {
  Designation = `Designation`,
  Id = `Id`,
  ManagedType = `ManagedType`,
  ManagedTypeName = `ManagedTypeName`,
  GmsType = `GmsType`,
  DatapointType = `DatapointType`,
  Min = `Min`,
  Max = `Max`,
  Precision = `Precision`,
  DepthIndex = `DepthIndex`,
  GraphicViewport = `GraphicViewport`,
  Commands = `Commands`
}
