import { Observable } from 'rxjs';

import { Language } from './data.model';

/**
 * Base class for the language service.
 */
export abstract class LanguageServiceBase {

  /**
   * Gets the user language of the logged in user
   *
   * @abstract
   * @returns {Observable<Language>}
   *
   * @memberOf LanguageBase
   */
  public abstract getUserLanguage(): Observable<Language>;
}
