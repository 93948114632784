import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SummaryBarSnapInComponent } from "./snapin/summary-bar-snapin.component";
import { summarybarSnapInRouting } from "./gms-summary-bar-snapin.routing";
import { HfwControlsModule } from "@gms-flex/controls";
import { MultiTranslateHttpLoader, TraceService } from "@gms-flex/services-common";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { SiActionDialogModule, SiNavbarModule, SiStatusBarModule } from "@simpl/element-ng";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TraceSettingsComponent } from "@gms-flex/controls";
import { EventsModule, GmsSnapInCommonModule } from '@gms-flex/snapin-common';

export function createTranslateLoader(http: HttpClient, trace: TraceService): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, trace, "./@gms-flex/summary-bar/i18n/", ["./i18n/"]);
}

@NgModule({
  imports:          [ HttpClientModule,
    ButtonsModule.forRoot(), BsDropdownModule, ModalModule, SiActionDialogModule, SiNavbarModule, SiStatusBarModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader,
        useFactory: (createTranslateLoader),
      deps: [HttpClient, TraceService] },
      isolate: true
    }), HfwControlsModule, CommonModule, FormsModule, GmsSnapInCommonModule, EventsModule, summarybarSnapInRouting ],
  declarations:     [ SummaryBarSnapInComponent ],
  exports:          [ SummaryBarSnapInComponent ],
  schemas:          [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class GmsSummaryBarSnapInModule {
  public static forRoot(): ModuleWithProviders<GmsSummaryBarSnapInModule> {
    return {
      ngModule: GmsSummaryBarSnapInModule
    };
  }
}
