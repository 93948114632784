import { Injectable } from '@angular/core';
import { TraceModules } from '@gms-flex/services';
import { Language, LanguageServiceBase, TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled } from 'rxjs';

@Injectable()
export class LanguageBxSubstituteService extends LanguageServiceBase {
  public constructor(
    private readonly traceService: TraceService) {
    super();

    this.traceService.info(TraceModules.language, 'LanguageBxSubstituteService created');
  }

  public getUserLanguage(): Observable<Language> {
    this.traceService.info(TraceModules.language, 'LanguageBxSubstituteService.getUserLanguage() called.');
    // return of({ Code: 'en-US', Descriptor: 'English (United States)' });
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    return scheduled([{ Code: 'en-US', Descriptor: 'English (United States)' }], asapScheduler);
  }
}
