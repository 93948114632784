import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SingleSignOnComponent } from '@building-x/common-ui-ng';
import { PageComponent, PageNotFoundComponent } from '@gms-flex/core';
import { TraceSettings } from '@gms-flex/services-common';
import { AuthGuardService } from 'src/app/core/guards/auth-guard.service';

import { LoginComponent } from './core-ui/login/login.component';
import { LoadingComponent } from './loading/loading.component';
import { MainComponent } from './main/main.component';
import { appRightsResolver } from './routing/app-rights.resolver';
import { customerResolver } from './routing/customer.resolver';
import { hfwInstanceResolver } from './routing/hfw-instance.resolver';
import { licenseOptionsResolver } from './routing/license-options.resolver';
import { rightPanelSettingsResolver } from './routing/right-panel-settings.resolver';

export const routerTraceModuleName = 'ng-router';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sso',
    component: SingleSignOnComponent
  },
  {
    path: 'AboutSnapinType',
    loadChildren: () => import('@gms-flex/about').then(mod => mod.GmsAboutSnapInModule)
  },
  {
    path: 'AccountSnapinType',
    loadChildren: () => import('@gms-flex/account').then(mod => mod.GmsAccountSnapInModule)
  },
  {
    path: 'EventListType',
    loadChildren: () => import('@gms-flex/event-list').then(mod => mod.GmsEventListSnapInModule)
  },
  {
    path: 'EventDetailsType',
    loadChildren: () => import('@gms-flex/event-details').then(mod => mod.GmsEventDetailsSnapInModule)
  },
  {
    path: 'TextualViewerType',
    loadChildren: () => import('@gms-flex/textual-viewer').then(mod => mod.GmsTextualViewerSnapInModule)
  },
  {
    path: 'PropertyViewerType',
    loadChildren: () => import('@gms-flex/property').then(mod => mod.GmsPropertySnapInModule)
  },
  {
    path: 'SummaryBarType',
    loadChildren: () => import('@gms-flex/summary-bar').then(mod => mod.GmsSummaryBarSnapInModule)
  },
  {
    path: 'SystemBrowserType',
    loadChildren: () => import('@gms-flex/system-browser').then(mod => mod.GmsSystemBrowserSnapInModule)
  },
  {
    path: 'NotifConfigSnapinType',
    loadChildren: () => import('@gms-flex/notifconfig').then(mod => mod.GmsNotifConfigSnapInModule)
  },
  {
    path: 'investigativeTreatmentType',
    loadChildren: () => import('@gms-flex/investigative-treatment').then(mod => mod.GmsInvestigationSnapinModule)
  },
  {
    path: 'TrendSnapInType',
    loadChildren: () => import('@gms-flex/trend').then(mod => mod.GmsTrendSnapInModule)
  },
  {
    path: 'GraphicsViewerType',
    loadChildren: () => import('@gms-flex/graphics-viewer').then(mod => mod.GmsGraphicsViewerSnapInModule)
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuardService],
    resolve: {
      hfwInstance: hfwInstanceResolver,
      appRights: appRightsResolver,
      licenseOptions: licenseOptionsResolver,
      rpSettings: rightPanelSettingsResolver,
      customer: customerResolver
    },
    children: [
      {
        path: 'page',
        component: PageComponent
      },
      {
        path: '404notfound',
        component: PageNotFoundComponent
      }
    ]
  },
  {
    path: '',
    redirectTo: 'main/page',
    pathMatch: 'full'
  },
  {
    path: 'loading',
    component: LoadingComponent,
    resolve: {
      appRights: appRightsResolver,
      licenseOptions: licenseOptionsResolver,
      hfwInstance: hfwInstanceResolver,
      customer: customerResolver
    }
  },
  {
    path: '**',
    redirectTo: 'main/page'
  },
  {
    path: '404notfound',
    component: PageNotFoundComponent
  }
];
/* eslint-enable @typescript-eslint/explicit-function-return-type */

const traceSettings: TraceSettings = TraceSettings.readTraceSettingsFromLocStore();
const routeChannelEnabled: boolean = (traceSettings.isVendorModuleEnabled(routerTraceModuleName) /* || traceSettings.allModulesEnabled */);

export const routing: ModuleWithProviders<RouterModule> =
RouterModule.forRoot(appRoutes, { enableTracing: routeChannelEnabled, useHash: true });
