import { CdkPortalOutlet, Portal, PortalModule } from '@angular/cdk/portal';
import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import { SiContentActionBarComponent, ViewType } from '@simpl/element-ng/content-action-bar';
import { ModalRef, SiModalService } from '@simpl/element-ng/modal';
import { SiPopoverDirective } from '@simpl/element-ng/popover';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import {
  clone,
  focusDialogContent,
  focusFirstFocusable,
  SiClickOutsideDirective
} from '@simpl/object-browser-ng/common';
import { Subscription } from 'rxjs';

import { getNextModalId } from '../../helpers/modal-helpers';
import { AnyProperty, OverrideMode, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverService } from './si-property-popover.service';

@Component({
  selector: 'si-property-popover',
  templateUrl: './si-property-popover.component.html',
  styleUrls: ['./si-property-popover.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    PortalModule,
    SiClickOutsideDirective,
    SiContentActionBarComponent,
    SiPopoverDirective,
    SiTranslateModule
  ]
})
export class SiPropertyPopoverComponent implements OnDestroy, OnChanges {
  private readonly valueStateIcons = {
    loading: 'element-busy rotate',
    passed: 'element-validation-success status-success',
    failed: 'element-validation-issue status-danger',
    partial: 'element-validation-warning status-warning'
  };
  private readonly valueOveriddenIcons = {
    warning: 'element-manual-filled status-warning',
    danger: 'element-manual-filled status-danger'
  };

  isActive = false;
  isBlocked = false;
  viewType: ViewType = 'mobile';
  primaryActions: MenuItem[] = [];
  editValue: any;
  expanderPopup = { pop: undefined as any };
  modalTitleId = '';
  dangerModalTitleId = '';

  private subscription?: Subscription;
  private aboutPropertySubscription?: Subscription;
  private listeners: (() => void)[] = [];
  private modalReference?: ModalRef;
  private modalSubscription?: Subscription;
  private dangerModalReference?: ModalRef;
  private dangerModalSubscription?: Subscription;

  @Input() valueInfo?: string;
  @Input() modalTemplate?: TemplateRef<any>;
  @Input() submitDisabled: boolean | null = false;
  @Input() valueState?: ValueState;
  @Input() property!: AnyProperty;
  @Input() skipClone = false;
  @Input() forceReadonly = false;
  @Input() displayOnly = false;

  @Output() readonly stateChange = new EventEmitter<StateChange>();

  @ViewChild('dangerModalTemplate') dangerModalTemplate!: TemplateRef<any>;
  @ViewChild('aboutPropertyTemplate', { read: CdkPortalOutlet, static: false })
  aboutPropertyTemplate!: CdkPortalOutlet;
  @ViewChild('popExpander') popExpander!: SiPopoverDirective;
  @ViewChild('valueContent') valueContent!: ElementRef;

  private element = inject(ElementRef);
  private renderer = inject(Renderer2);
  private modalService = inject(SiModalService);
  private popoverService? = inject(SiPropertyPopoverService, { optional: true });
  private focusOutTimer: any;
  private ignoreNextFocusOut = false;

  constructor() {
    if (this.popoverService) {
      this.subscription = this.popoverService.stateChange$.subscribe(caller => {
        const isDirect = this.overrideMode === 'direct';
        const isNotThis = !!caller && caller !== this;
        this.isBlocked = isNotThis && !isDirect;
        if (this.isActive && isNotThis && isDirect) {
          this.submit();
        }
      });
      this.aboutPropertySubscription = this.popoverService.propertyInfoDialog$.subscribe(
        selectedPropertyInfo => {
          this.closePopups();
          if (selectedPropertyInfo.property === this.property) {
            setTimeout(() => {
              this.onExpandableClicked(this.popExpander);
              setTimeout(() => this.attachAboutContent(selectedPropertyInfo.propertyInfo));
            });
          }
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.popoverService && changes.submitDisabled) {
      this.popoverService.setValidity(!this.submitDisabled, this);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.aboutPropertySubscription?.unsubscribe();
    this.closePopover(undefined, true);
  }

  get valueIcon(): any {
    if (this.valueState && this.valueState !== 'none') {
      return this.valueStateIcons[this.valueState] || '';
    } else if (this.property?.overridden && this.overrideMode) {
      return (this.valueOveriddenIcons as any)[this.overrideMode] || '';
    } else {
      return '';
    }
  }

  get overrideMode(): OverrideMode | undefined {
    return this.property?.overrideMode ?? this.popoverService?.containerOverrideMode;
  }

  get readonly(): boolean {
    return (this.forceReadonly || !!this.property.value?.readonly) && !this.modalTemplate;
  }

  get modelValue(): any {
    return this.isActive ? this.editValue : this.property?.value?.value;
  }

  set modelValue(value: any) {
    this.editValue = value;
  }

  protected openClick(event: Event): void {
    if (!this.displayOnly) {
      event.stopPropagation();
      this.open();
    }
  }

  open(byKeyboard = false): void {
    if (!this.readonly && !this.isActive) {
      if (this.popoverService && !this.popoverService.open(this.overrideMode, this)) {
        return;
      }

      this.editValue = this.skipClone
        ? this.property.value.value
        : clone(this.property.value.value);
      this.stateChange.emit(byKeyboard ? 'openKeyboard' : 'open');

      if (this.modalTemplate) {
        this.showModal(byKeyboard);
      } else {
        this.isActive = true;
      }

      if (!this.modalTemplate) {
        setTimeout(() => {
          this.listeners.push(
            this.renderer.listen('window', 'mousedown', e => this.handleClickOutside(e))
          );
          this.listeners.push(
            this.renderer.listen('window', 'touchstart', e => this.handleClickOutside(e))
          );
          this.valueContent.nativeElement?.scrollIntoView({ block: 'nearest' });
        });
      }
    }
    this.closePopups(true);
  }

  // this is for properties that present an input directly. The open() needs to trigger on any
  // interaction in order for the workflows like cancel() to work correctly
  openCloseOnKeyboard(event: KeyboardEvent): void {
    if (this.isActive) {
      if (event.key === 'Enter') {
        this.submitEnter(event);
      }
      return;
    }
    switch (event.key) {
      case 'Tab':
      case 'Alt':
      case 'Shift':
      case 'Meta':
      case 'Escape':
        return;
    }
    this.open();
  }

  close(): void {
    if (this.submitDisabled || this.overrideMode !== 'direct') {
      this.cancel();
    } else {
      this.submit();
    }
  }

  escape(event: Event): void {
    if (!this.isActive) {
      return;
    }
    this.cancel();
    event.stopPropagation();
  }

  closeDanger(): void {
    this.closeDangerModal();
    this.closePopups();
  }

  cancel(): void {
    this.closePopover('cancel');
  }

  release(): void {
    if (this.overrideMode === 'danger') {
      this.showDangerModal();
    } else {
      this.closePopover('release');
    }
  }

  submit(): void {
    this.property.value.value = this.editValue;
    this.editValue = undefined;
    this.closePopover('submit');
  }

  submitEnter(event?: Event): void {
    setTimeout(() => this.submit());
  }

  submitDanger(): void {
    this.closeDangerModal();
    this.closePopover('release');
  }

  private closePopover(state?: StateChange, noFocus = false): void {
    this.closeModal();
    this.popoverService?.close();
    this.listeners.forEach(l => l());
    this.listeners = [];
    this.isActive = false;
    this.editValue = undefined;
    if (state) {
      this.stateChange.emit(state);
    }
    this.closePopups(noFocus);
  }

  @HostListener('focusout')
  protected handleFocusOut(): void {
    if (!this.isActive || this.ignoreNextFocusOut) {
      this.ignoreNextFocusOut = false;
      return;
    }
    this.focusOutTimer = setTimeout(() => {
      if (!this.isActive || !document.activeElement || document.activeElement === document.body) {
        return;
      }
      if (!this.element.nativeElement.contains(document.activeElement)) {
        this.close();
      }
    });
  }

  private handleClickOutside(event: Event): void {
    const offsetX = (event as MouseEvent).offsetX ?? 0;
    const clientWidth = (event.target as HTMLElement)?.clientWidth ?? 0;
    if (offsetX > clientWidth) {
      // special case: user clicks on scrollbar of an outer container.
      // this only works when scrollbars are always visible and with MouseEvent, not with Touch
      this.ignoreNextFocusOut = true;
      return;
    }

    if (
      event.target === this.element.nativeElement ||
      !this.element.nativeElement.contains(event.target)
    ) {
      this.close();
    } else {
      this.closePopups();
    }
  }

  private showModal(byKeyboard: boolean): void {
    const backdropClick = this.overrideMode === 'direct';
    const id = getNextModalId();
    this.modalTitleId = id.titleId;
    this.modalReference = this.modalService.show(this.modalTemplate!, {
      class: 'object-browser modal-dialog-scrollable',
      ignoreBackdropClick: !backdropClick,
      keyboard: true,
      ariaLabelledBy: this.modalTitleId
    });
    focusDialogContent(this.modalReference, byKeyboard);
    this.modalSubscription = this.modalReference.hidden?.subscribe(() => this.close());
  }

  private showDangerModal(): void {
    const id = getNextModalId();
    this.dangerModalTitleId = id.titleId;
    this.dangerModalReference = this.modalService.show(this.dangerModalTemplate, {
      class: 'object-browser modal-danger-confirm',
      ignoreBackdropClick: true,
      keyboard: true,
      ariaLabelledBy: this.dangerModalTitleId
    });
    this.dangerModalSubscription = this.dangerModalReference.hidden?.subscribe(() =>
      this.closeDangerModal()
    );
  }

  private closeModal(): void {
    if (this.modalReference) {
      this.modalSubscription?.unsubscribe();
      this.modalReference.hide();
      this.modalReference = undefined;
      this.modalSubscription = undefined;
      this.focusValueContent();
    }
  }

  private closeDangerModal(): void {
    if (this.dangerModalReference) {
      this.dangerModalSubscription?.unsubscribe();
      this.dangerModalReference.hide();
      this.dangerModalReference = undefined;
      this.dangerModalSubscription = undefined;
      this.focusValueContent();
    }
  }

  private attachAboutContent(portal: Portal<any>): void {
    this.aboutPropertyTemplate?.detach();
    this.aboutPropertyTemplate?.attach(portal);
  }

  private focusValueContent(): void {
    setTimeout(() => focusFirstFocusable(this.valueContent.nativeElement));
  }

  onExpandableClicked(pop: SiPopoverDirective): void {
    this.expanderPopup.pop = pop;
    pop.show();
  }

  closePopups(noFocus = false): void {
    clearTimeout(this.focusOutTimer);
    this.expanderPopup.pop?.hide();
    this.expanderPopup.pop = undefined;
    this.aboutPropertyTemplate?.detach();
    // If different popup is not open, and we can find a parent row, move focus back to row.
    // This allows the user to continue navigating by keyboard.
    // TODO: Find better solution.
    if (!noFocus) {
      (
        (this.element.nativeElement as HTMLElement).closest('.datatable-body-row') as HTMLElement
      )?.focus();
    }
  }
}
