import { Observable } from 'rxjs';

import { PrimaryBarConfig, SnapInType, VerticalBarConfig } from '../../core/shared/hldl/hldl-data.model';
import { FullPaneId } from '../fullsnapinid/full-pane-id.model';
import { FullSnapInId } from '../fullsnapinid/fullsnapinid.model';
import { RuntimeInfo } from './runtime-info.model';

/**
 * The interface exposed by the SnapInConfigService to send hldl configs to snapins.
 */
export abstract class ISnapInConfig {
  public abstract getLayouts(frameId: string): Observable<any[]>;

  public abstract getSnapInHldlConfig(fullId: FullSnapInId, location: FullPaneId): any;

  public abstract getFrames(): any[];

  public abstract paneCanBeDisplayed(frameId: string, paneId: string): Observable<boolean>;

  public abstract getRuntimeInfo(): RuntimeInfo;

  public abstract getAvailableModes(): string[] | null;

  public abstract getPrimaryBarConfig(): PrimaryBarConfig;

  public abstract getVerticalBarConfig(): VerticalBarConfig[];

  public abstract hasLoadedProfile(profileFileName: string): boolean;

  public abstract getSnapInTypes(): SnapInType[];
}
