import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { Observable, of } from 'rxjs';

import { FullPaneId } from '../fullsnapinid/full-pane-id.model';
import { FullSnapInId } from '../fullsnapinid/fullsnapinid.model';
import { MessageParameters } from '../interfaces';
import { IHfwMessage } from '../interfaces/ihfwmessage';
import { QParam } from '../interfaces/q-param.model';
import { UnsavedDataReason } from '../unsaved-data/unsaved-data.model';
import { SnapInComponent } from './snap-in.component';
/**
 * Base class from which all snapins must inherit.
 */

export interface ParamsSendMessage {
  messageBody: any;
  preselection: boolean;
  qParam?: QParam;
  broadcast: false;
  applyRuleId?: string;
  secondarySelectionInSinglePane?: boolean;
}
export abstract class SnapInBase implements SnapInComponent {

  /*
   * SnapIn instance identifier composed by frameId and snapInId.
   * Identifies the snapin instance within the frame across all its hosting panes.
   * It should be used whenever it is required to identify data/operations specific of the current snapin instance.
   */
  public fullId!: FullSnapInId;

  /*
   * Pane instance identifier composed by frameId and paneId.
   * Identifies the pane instance in which the current snapin instance is hosted.
   * This field should be used only by hfw. Avoid to use this field in case you plan to implement different snapin behaviors
   * basing on the pane in which the snapin is hosted. Hldl 'config' field should be considered instead.
   */
  public location!: FullPaneId;

  public constructor(protected messageBroker: IHfwMessage,
    protected activatedRoute: ActivatedRoute
  ) {
    this.getFullSnapInIdFromRoute();
  }

  /**
   * This method allows snapin instance to send messages.
   *
   * @param {string[]} types, The list of message types related with the outgoing message (i.e. 'Trend', 'Aggregator').
   * @param {any} messageBody, The content data of the message.
   * @param {boolean} preselection, If true sends a message with preselection (changing forground snapin and visible snapins tabs on target panes).
   * @param {QParam} qParam, Along with preseleciton snapins can choose to set a string value in url as query parameters.
   * (The parameter key will be the plain snapin fullId and the parameter value will be the one specified here).
   * @param {boolean} broadcast, if true the outgoing communication rules are ignored and the message will be broadcasted.
   * After this time the handler, returns all received results of the snapins, no matter if all snapins replied.
   * @param {string} applyRuleId, When specified, only the outgoing communication rules configured with the same rule id will be considered.
   * This allows snapins to send message to different targets in different scenarios.
   * @param {boolean} secondarySelectionInSinglePane, If true, the message will never arrive to the sender, even if the canSendToSelf property is
   * configured in hldl. At the same time, even if the sender snap-in will not be a target of the message, it will not be removed from pane.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the send message operation.
   * The send message infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf SnapInBase
   *
   */
  public sendMessage(types: string[], param: ParamsSendMessage): Observable<boolean> {
    return this.messageBroker.sendMessage(this.fullId, this.location, types, param.messageBody, param.preselection, param.qParam!, param.broadcast,
      param.applyRuleId!, param.secondarySelectionInSinglePane!);
  }

  /**
   * This method allows snapins to change the visible frame.
   * @param {string} frameId, The frame id configured in hldl file.
   * @param {MessageParameters} message?, Optional message information to be sent by frame's qParam service before changing frame.
   *
   * @returns {Observable<boolean>} A boolean observable which completes with the result of the frame change operation.
   * The frame change infact, can be aborted, for example trought unsaved data dialog.
   *
   * @memberOf SnapInBase
   *
   */
  public switchToNextFrame(frameId: string, message?: MessageParameters): Observable<boolean> {
    return this.messageBroker.switchToNextFrame(frameId, message);
  }

  /**
   * This method is handled by the SnapInBase class at constructor and on beforeAttach.
   * Its duty is to set the fullId and the location properties basing on the current ActivatedRoute data.
   * @memberOf SnapInBase
   *
   */
  public getFullSnapInIdFromRoute(): void {
    if (!isNullOrUndefined(this.activatedRoute) &&
        !isNullOrUndefined(this.activatedRoute.snapshot.data) &&
        !isNullOrUndefined(this.activatedRoute.snapshot.data.snapinId)) {
      const snapinId: FullSnapInId = this.activatedRoute.snapshot.data.snapinId;
      const paneId: FullPaneId = this.activatedRoute.snapshot.data.paneId;

      this.fullId = new FullSnapInId(snapinId.frameId, snapinId.snapInId);
      this.location = new FullPaneId(paneId.frameId, paneId.paneId);
    }
  }

  /*
   * This method is called whenever Angular (via ReuseRouterStrategy) has just dettached the snapin.
   * The state of the snapin component (and the DOM) is fully valid.
   */
  public onAfterDettach(): void {
    // TODO document why this method 'onAfterDettach' is empty
  }

  /*
   * This method is called whenever Angular (via ReuseRouterStrategy) is about to attach the snapin.
   * The state of the snapin component (and the DOM) is fully valid.
   * However, note that some DOM (such as scroll positions) is not retained after angular attaches the snapin/component.
   * Thus, it is appropriate to read state here from the component/DOM elements and persist it or directly use it with the next animationFrame cycle.
   * Note also, there is no event which is fired after the component is attached again.
   */
  public onBeforeAttach(): void {
    this.updateLocation();
  }

  /*
   * This method is called whenever the snapin is going to be destroyed due some operation (tab-change, layout-change, frame-change, new-selection).
   * Snapin interested on unsaved data functionality should override this method,
   * evaluate if they have unsaved data and then reply to the hfw (in case asking to the user whether to proceed or not).
   * If the snapin replies 'true' the operation which trigger the call will go ahead, otherwise it will be aborted.
   */
  public onUnsavedDataCheck(_reason: UnsavedDataReason): Observable<boolean> {
    return of(true);
  }

  private updateLocation(): void {
    const newLocation = this.messageBroker.getUpdatingLocation(this.fullId);
    if (!isNullOrUndefined(newLocation)) {
      this.location = newLocation;
    }
  }

}
