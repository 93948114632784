<div class="component-notification mb-5">
  <div class="notification-title-container p-6">
      <p class="si-title-1 gms-notification-text">{{ 'NAVBAR.notifconfig-frame-id' | translate }}</p>
        <button type="button" class="btn btn-circle btn-sm btn-ghost element-cancel" aria-label="Close" (click)="onClose()"></button>
  </div>
    <div dropdown>
    <div (click)="$event.stopPropagation()">
        <div class="notificationGrouped" *ngFor="let sender of senders">
            <div class="groupBody" (click)="group(sender)">
                <table class="notifTable">
                    <tr>
                        <td style="vertical-align: middle">
                            <div class="groupIcon" [ngClass]="configurations.get(sender.sender).getIcon()"></div>
                        </td>
                        <td style="width: 100%; padding-left: 5px">
                            <span class="groupTitle">{{configurations.get(sender.sender).getDescription()}}</span>
                            <span class="badge bg-secondary countBadge" *ngIf="sender.count > 1">{{sender.count}}</span>
                        </td>
                        <td class="groupArrow">
                            <span class="element-down-2" *ngIf="sender.grouped === true"></span><span class="element-up-2" *ngIf="sender.grouped === false"></span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="si-caption dismiss" *ngIf="sender.grouped === false && sender.count > 3 && configurations.get(sender.sender).getPersistent() === false" (click)="cancelAll(sender.sender)">
                {{dismissAllLabel}}
            </div>
            <div class="groupContainer" [ngClass]="{'opened': !sender.grouped === true, 'closed': !sender.grouped === false}">
                <div class="notification" *ngFor="let notif of notificationsSorted" [class.notifWithAction]="notif.actions?.length > 0 && notif.actions[0] !== undefined" (click)="clickAction(notif)">
                    <div class="notifBody" *ngIf="notif.sender === sender.sender">
                        <table class="notifTable">
                            <tr>
                                <td class="notifTableIcon">
                                  <si-circle-status *ngIf="notif.icon !== undefined" [color]="notif.iconBg" [icon]="notif.icon" />
                                </td>
                                <td style="padding-left: 10px">
                                    <div class="si-title-2 notifTitle">{{notif.title}}</div>
                                    <div class="si-body-2 notifText">{{notif.text}}</div>
                                </td>
                                <td *ngIf="notif.actions?.length > 1">
                                    <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
                                    <button type="button"
                                        class="btn btn-tertiary btn-block actionButton element-cancel"
                                        (click)="$event.stopPropagation(); clickAction(notif, notif.actions[1])"
                                    ></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="si-body-2 notifEmpty" *ngIf="count < 1">
            {{emptyNotifString}}
        </div>
    </div>
    </div>
</div>
