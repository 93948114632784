/*
 *  This class represents the unique identifier of a Pane Instance.
 */
export class FullPaneId {

  /*
     * Returns true if the two specified FullPaneId points at the same path.
     */
  public static areEqual(x: FullPaneId, y: FullPaneId): boolean {
    return (x.paneId === y.paneId && x.frameId === y.frameId);
  }

  public constructor(public frameId: string,
    public paneId: string) {
  }

  /*
     * Gets the full Id as concatenated string.
     */
  public fullId(): string {
    return `${this.frameId}.${this.paneId}`;
  }

}
