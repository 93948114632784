import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutObjectComponent } from './about-object.component';
import { AboutObjectContainerComponent } from './view/container.component';
import { ObjectInfoViewComponent } from './view/object-detail.component';
import { ObjectListViewComponent } from './view/object-list.component';
import { ObjectPathViewComponent } from './view/object-path.component';
import { SiAccordionModule, SiInlineNotificationModule } from '@simpl/element-ng';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    AboutObjectComponent,
    AboutObjectContainerComponent,
    ObjectInfoViewComponent,
    ObjectListViewComponent,
    ObjectPathViewComponent
  ],
  exports: [
    AboutObjectComponent,
    AboutObjectContainerComponent,
    ObjectInfoViewComponent,
    ObjectListViewComponent,
    ObjectPathViewComponent
  ],
  imports: [
    CommonModule,
    PopoverModule,
    SiAccordionModule,
    SiInlineNotificationModule
  ]
})
export class AboutPopoverModule { }
