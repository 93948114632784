import { BehaviorSubject, Observable, Observer, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

import { TraceService } from '@gms-flex/services-common';
import { TraceModules } from '../shared/trace-modules';

import { HistLogColumnDescription, HistoryLogMetaData, LogViewerServiceBase, RowDetailsDescription } from '@gms-flex/services';

/**
 * Description:
 * ------------
 * ...
 */
@Injectable({
  providedIn: 'root'
})
export class HistoryLogService {
  public logViewRowDetails = new BehaviorSubject<RowDetailsDescription | null>(null);
  public logViewerColumnDescriptionMap = new BehaviorSubject<HistLogColumnDescription[]>([]);
  public logViewDatahideShowVeryDetailPane = new BehaviorSubject<boolean>(true);
  public detailPaneIsLoaded: Subject<boolean> = new Subject();
  public splitDetailControls = new BehaviorSubject<boolean>(false);
  private readonly subHistoryLogs: Map<Subscription, Subscription> = new Map();

  // to tranfer row data from table to details component

}
