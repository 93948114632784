import { NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { Filter } from './filter';

@Component({
  selector: 'si-filter-pill',
  templateUrl: './si-filter-pill.component.html',
  styleUrl: './si-filter-pill.component.scss',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SiTranslateModule]
})
export class SiFilterPillComponent {
  /**
   * Settings of the filter pill.
   */
  @Input({ required: true })
  filter!: Filter;

  /**
   * True if variant is responsive
   */
  @Input({ transform: booleanAttribute }) responsive = false;

  @Input({ transform: booleanAttribute }) disabled = false;

  @Input() totalPills = 0;

  /**
   * Output callback event which will provide you the name of the deleted filter
   * pill if a filter was deleted.
   */
  @Output() readonly deleteFilters = new EventEmitter<Filter>();

  protected deleteClicked(): void {
    this.deleteFilters.emit(this.filter);
  }
}
