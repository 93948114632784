import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { TimeValue } from '@simpl/element-value-types';
import { clone } from '@simpl/object-browser-ng/common';

import { timeString } from '../../helpers/time';
import { TimeFormat } from '../../interfaces/date-time-formats';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiBACnetDateTimePickerComponent } from '../si-bacnet-datetime-picker/si-bacnet-datetime-picker.component';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-time-property',
  templateUrl: './si-time-property.component.html',
  styleUrls: ['./si-time-property.component.scss'],
  standalone: true,
  imports: [FormsModule, SiBACnetDateTimePickerComponent, SiPropertyPopoverComponent]
})
export class SiTimePropertyComponent implements AfterViewInit {
  @Input({ required: true }) property!: Property<TimeValue>;
  @Input() valueState: ValueState = 'none';
  @Input() format: TimeFormat = 'hh:mm:ss';
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<TimeValue>>();

  @ViewChild('form') form!: NgForm;
  @ViewChild('popover', { static: true }) popover!: SiPropertyPopoverComponent;

  isValid = true;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  // note this is duplicated from popover since the si-bacnet-datetime-picker expect the full TimeValue
  private editValue?: TimeValue;

  private elementRef = inject(ElementRef);

  get modelValue(): any {
    return this.popover.isActive ? this.editValue : this.property?.value;
  }

  get displayValue(): string {
    return timeString(this.modelValue.value, this.format);
  }

  ngAfterViewInit(): void {
    this.form.statusChanges!.subscribe(() => {
      // setTimeout to work around Angular bug 23657
      setTimeout(() => (this.isValid = !!this.form.valid));
    });
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
        setTimeout(() =>
          this.elementRef.nativeElement.querySelector('si-bacnet-datetime-picker input')?.focus()
        );
        this.editValue = clone(this.property.value);
        break;
      case 'open':
        this.editValue = clone(this.property.value);
        break;
      case 'submit':
        this.property.value.value = this.editValue!.value;
        this.editValue = undefined;
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }
}
