/**
 * Returns the initals of the given value
 */
export class Initials {

  /**
   * Returns the initial letters of a name in a string.
   */
  public static getInitials(name: string, size: number): string {
    name = name ? name.trim() : null;

    if (!name) {
      return '';
    }

    const initials: string[] = name.split(' ');
    const elements: string[] = (size && size < initials.length) ? initials.slice(0, size) : initials;

    if (!elements || !elements.length) {
      return '';
    }

    return elements
      .filter(element => element && element.length > 0)
      .map(element => element[0].toUpperCase())
      .join('');
  }

}
