<div class="col dp-width pl-0 ps-0 pb-2">
  <div class="mt-2 media d-flex">
    <div class="px-4 mt-4 media-object-type">
      <span class="media-object icon" [ngClass]="icon!"></span>
    </div>
    <div class="mt-1 media-body media-object-data datapoint-label-container">
      @if (
        (displayMode === 'name' ||
          displayMode === 'name-description' ||
          displayMode === 'name-alias') &&
        name
      ) {
        <div class="pd-2 datapoint-label text-body" [ngClass]="{ 'me-5 mt-4': singleRowView }">
          <span class="datapoint-name-desc">{{ name }}</span>
        </div>
      }
      @if (displayMode === 'name-description' && description) {
        <div [ngClass]="{ 'me-5 mt-4': singleRowView }">
          <span class="text-secondary datapoint-name-desc">{{ description }}</span>
        </div>
      }
      @if (displayMode === 'name-alias' && alias) {
        <div [ngClass]="{ 'me-5 mt-4': singleRowView }">
          <span class="text-secondary datapoint-name-desc">{{ alias }}</span>
        </div>
      }
      @if (
        (displayMode === 'description' ||
          displayMode === 'description-name' ||
          displayMode === 'description-alias') &&
        description
      ) {
        <div class="pd-2 datapoint-label text-body" [ngClass]="{ 'me-5 mt-4': singleRowView }">
          <span class="datapoint-name-desc">{{ description }}</span>
        </div>
      }
      @if (displayMode === 'description-name' && name) {
        <div [ngClass]="{ 'me-5 mt-4': singleRowView }">
          <span class="text-secondary datapoint-name-desc">{{ name }}</span>
        </div>
      }
      @if (displayMode === 'description-alias' && alias) {
        <div [ngClass]="{ 'me-5 mt-4': singleRowView }">
          <span class="text-secondary datapoint-name-desc">{{ alias }}</span>
        </div>
      }
    </div>
  </div>
</div>
