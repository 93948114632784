import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppContextService, isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// import { FilterPillData } from './filter-pill.model';
import { HfwFilterPillData } from '@gms-flex/controls';
import { first, map } from 'rxjs/operators';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'gms-om-filter-pill',
  templateUrl: './filter-pill.component.html',
  styleUrl: './filter-pill.component.scss',
  providers: [TranslateService]
})
export class FilterPillComponent implements OnInit, OnDestroy {

  public readonly emptyValueLabel: string = '';
  public multipleValuesLabel = '';
  public traceModule = 'gmsSnapins_FilterPill';

  @Input()
  public pillData: HfwFilterPillData = undefined;

  @Output()
  public readonly deleteClick: EventEmitter<HfwFilterPillData> = new EventEmitter<HfwFilterPillData>();

  private readonly subscriptions: Subscription[] = [];

  public constructor(private readonly httpClient: HttpClient,
    private readonly appContextService: AppContextService,
    private readonly translateService: TranslateService,
    private readonly traceService: TraceService) {
  }

  public ngOnInit(): void {
    this.loadTranslations();
  }

  public loadTranslations(): void {
    const translationPrefix = './@gms-flex/snapin-common/i18n/';
    this.translateService.currentLoader = new TranslateHttpLoader(this.httpClient, translationPrefix, '.json');

    this.appContextService.defaultCulture.pipe(first()).subscribe({
      next: (defaultCulture: string) => {
        if (!isNullOrUndefined(defaultCulture)) {
          this.traceService.info(this.traceModule, `Use default culture: ${defaultCulture} `);
          this.translateService.setDefaultLang(defaultCulture);
        } else {
          this.traceService.warn(this.traceModule, 'No default culture from appContextService');
          this.translateService.setDefaultLang(this.translateService.getBrowserCultureLang());
        }
      }
    });

    this.appContextService.userCulture.pipe(first()).subscribe({
      next: (userCulture: string) => {
        if (!isNullOrUndefined(userCulture)) {
          this.traceService.info(this.traceModule, `Use user culture: ${userCulture}`);
          this.translateService.use(userCulture).pipe(first()).subscribe({
            next: (res: any) => {
              this.traceService.info(this.traceModule, `User user culture loaded: ${userCulture}`);
            }
          });
        }
      },
      error: (err: any) => {
        this.traceService.warn(this.traceModule, 'No user culture from appContextService');
      }
    });
  }
  /**
   * Convenience property for access filter title.
   */
  public get filterTitle(): string {
    return !isNullOrUndefined(this.pillData) ? this.pillData.title : '';
  }

  /**
   * Number of values associated with this filter.
   */
  public get filterValuesCount(): number {
    return !isNullOrUndefined(this?.pillData?.values) ? this.pillData?.values?.length : 0;
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public pillInfo(): Observable<string> {
    if (this.filterValuesCount === 1) {
      return of(this.pillData.values[0]);
    }

    return this.translateService
      .get('OM-FILTER-MULTIPLE-VALUES-LABEL', { filterCount: this.filterValuesCount })
      .pipe(
        map((res: string): string => `${res}`)
      );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
  }

  /**
   * Delete button click handler.
   */
  public onDeleteClick(): void {
    this.deleteClick.emit(this.pillData);
  }
}
