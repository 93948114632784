<div
  cdkListbox
  class="focus-none"
  [id]="baseId + '-listbox'"
  [cdkListboxMultiple]="selectionStrategy.allowMultiple"
  [cdkListboxValue]="selectionStrategy.arrayValue()"
  [attr.aria-labelledby]="baseId + '-aria-label' + ' ' + labelledby + ' ' + (baseId + '-content')"
  (cdkListboxValueChange)="listBoxValueChange($event)"
  (keydown.tab)="closeOverlayAlways()"
  (click)="closeOverlayIfSingle()"
  (keydown.enter)="closeOverlayIfSingle()"
  (keydown.space)="closeOverlayIfSingle()"
>
  @for (item of rows(); track trackByItem(index, item); let index = $index) {
    @if (item.type === 'group') {
      <div
        role="group"
        class="ps-0"
        aria-disabled="false"
        [attr.aria-labelledby]="baseId + '-' + index + '-group-header'"
      >
        <span
          class="dropdown-header"
          role="presentation"
          [id]="baseId + '-' + index + '-group-header'"
        >
          <ng-container
            [ngTemplateOutlet]="groupTemplate ?? defaultGroupTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"
          />
        </span>
        @for (optionRow of item.options; track trackByItem($index, optionRow)) {
          <ng-container
            [ngTemplateOutlet]="optionRowTemplate"
            [ngTemplateOutletContext]="{ $implicit: optionRow }"
          />
        }
      </div>
    }
    @if (item.type === 'option') {
      <ng-container
        [ngTemplateOutlet]="optionRowTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"
      />
    }
  }
  <ng-template #optionRowTemplate let-option siSelectOptionRowTemplate>
    <!-- https://github.com/angular/components/issues/28464 -->
    <!-- cdkOptionTypeaheadLabel must be null if the content should be used -->
    <!-- eslint-disable @angular-eslint/template/no-any -->
    <si-select-option-row
      #cdkOption="cdkOption"
      [option]="option"
      [optionTemplate]="optionTemplate"
      [cdkOption]="option.value"
      [cdkOptionDisabled]="!!option.disabled"
      [cdkOptionTypeaheadLabel]="(option.typeaheadLabel | translate)!"
      [class.active]="cdkOption.isActive()"
      [selected]="cdkOption.isSelected()"
    />
    <!-- eslint-enable @angular-eslint/template/no-any -->
  </ng-template>
</div>

<ng-template #defaultGroupTemplate let-group siSelectGroupTemplate>
  {{ group.label | translate }}
</ng-template>
