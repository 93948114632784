import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormRecord,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  DatepickerInputConfig,
  SiDatepickerDirective,
  SiDatepickerOverlayDirective
} from '@simpl/element-ng';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { DateFormat, MeridianDesignation, TimeFormat } from '../../interfaces/date-time-formats';
import { ViewChange } from './models/enums';
import {
  DateLabelKeys,
  InputDateProperty,
  InputProperty,
  TimeKeyLabels
} from './models/interfaces';
import { TValue } from './models/types';
import { ViewService } from './services/view.service';

@Component({
  selector: 'si-bacnet-datetime-picker',
  templateUrl: './si-bacnet-datetime-picker.component.html',
  styleUrls: ['./si-bacnet-datetime-picker.component.scss'],
  providers: [ViewService],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SiDatepickerOverlayDirective,
    SiDatepickerDirective,
    SiTranslateModule
  ]
})
export class SiBACnetDateTimePickerComponent implements OnChanges, OnInit {
  private _changing = false;
  private _unsubscribe: Subject<boolean> = new Subject();

  private _viewSvc = inject(ViewService);
  private elementRef = inject(ElementRef);
  private _controlContainer? = inject(ControlContainer, { optional: true });

  @Input() amPmKeys = ['AM', 'PM'];
  @Input({ required: true }) value!: TValue;

  @Output() readonly submitEnter = new EventEmitter<void>(true);

  form!: FormRecord<ReturnType<SiBACnetDateTimePickerComponent['createControl']>>;

  get dateInputs(): InputDateProperty[] {
    return this._viewSvc.dateInputs;
  }

  set date(value: Date) {
    this._viewSvc.date = value;
  }
  get date(): Date {
    return this._viewSvc.date!;
  }

  @Input() set dateFormat(value: DateFormat) {
    this._viewSvc.dateFormat = value;
  }
  get dateFormat(): DateFormat {
    return this._viewSvc.dateFormat;
  }

  get hasWildcards(): boolean {
    return this._viewSvc.hasWildcards();
  }

  @Input() set dateLabelKeys(value: DateLabelKeys) {
    this._viewSvc.dateLabelKeys = value;
  }
  get dateLabelKeys(): DateLabelKeys {
    return this._viewSvc.dateLabelKeys;
  }

  get datePickerConfig(): Partial<DatepickerInputConfig> {
    return this._viewSvc.datePickerConfig;
  }

  @Input() set timeFormat(value: TimeFormat) {
    this._viewSvc.timeFormat = value;
  }
  get timeFormat(): TimeFormat {
    return this._viewSvc.timeFormat;
  }

  get timeInputs(): InputProperty[] {
    return this._viewSvc.timeInputs;
  }

  @Input() set timeLabelKeys(value) {
    this._viewSvc.timeLabelKeys = value;
  }
  get timeLabelKeys(): TimeKeyLabels {
    return this._viewSvc.timeLabelKeys;
  }

  get hasDate(): boolean {
    return this._viewSvc.hasDate();
  }

  get hasTime(): boolean {
    return this._viewSvc.hasTime();
  }

  ngOnChanges(): void {
    this._changing = true;
    this._viewSvc.updateValue(this.value);
    this._changing = false;
  }

  ngOnInit(): void {
    this.form = (this._controlContainer?.control as typeof this.form) ?? new FormRecord({});
    this.form.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        filter(() => !!this.value && !this._changing),
        debounceTime(20)
      )
      .subscribe(form => (this.value.value = this._viewSvc.valueChange(form)));

    this._viewSvc.viewChange$
      .pipe(
        takeUntil(this._unsubscribe),
        filter(() => !!this.form),
        tap(view => {
          switch (view) {
            case ViewChange.add:
              this.dateInputs.forEach(input =>
                this.form.addControl(input.key, this.createControl(input))
              );
              this.timeInputs.forEach(input =>
                this.form.addControl(input.key, this.createControl(input))
              );
              break;

            case ViewChange.remove:
              this.dateInputs.forEach(input => this.form.removeControl(input.key));
              this.timeInputs.forEach(input => this.form.removeControl(input.key));
              break;

            case ViewChange.update:
            default:
              this.dateInputs.forEach(input => this.updateControlValue(input));
              this.timeInputs.forEach(input => this.updateControlValue(input));
              break;
          }

          if (this._viewSvc.options.isReadonly) {
            this.form.disable();
          } else {
            this.form.enable();
          }
        })
      )
      .subscribe();

    this._viewSvc.initializeView();
  }

  upDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      const key = (event.target as HTMLInputElement).name;
      const value = event.key === 'ArrowUp' ? this.inputIncrement(key) : this.inputDecrement(key);
      this.form.get(key)?.patchValue(value);
    }
  }

  onEnter(event: Event): void {
    const inputs = Array.from(
      this.elementRef.nativeElement.querySelectorAll('input, select') as NodeListOf<HTMLElement>
    );
    const index = inputs.indexOf(event.target as HTMLElement);
    if (index > -1) {
      if (index + 1 === inputs.length) {
        this.submitEnter.emit();
      } else {
        inputs[index + 1].focus();
      }
    }
  }

  private createControl(
    input: InputProperty
  ): FormControl<string | Date | MeridianDesignation | null | undefined> {
    const data = this._viewSvc.mapTo(input);
    return new FormControl(data.value, data.validator);
  }

  private findInputProperty(key: string): InputProperty | undefined {
    return this.timeInputs.find(input => input.key === key);
  }

  private inputDecrement(key: string): string {
    let value = (this._viewSvc.value as any)[key];

    const input = this.findInputProperty(key);
    if (input) {
      value = value !== undefined && value > input.min ? --value : input.max;
    }

    return value.toString();
  }

  private inputIncrement(key: string): string {
    let value = (this._viewSvc.value as any)[key];

    const input = this.findInputProperty(key);
    if (input) {
      value = value !== undefined && value < input.max ? ++value : input.min;
    }

    return value.toString();
  }

  private updateControlValue(input: InputProperty): void {
    if (this.form) {
      const data = this._viewSvc.mapTo(input);
      const ctrl = this.form.get(input.key);
      if (ctrl) {
        ctrl.patchValue(data.value, { onlySelf: true });
        ctrl.setValidators(data.validator);
        ctrl.updateValueAndValidity();
      }
    }
  }
}
