import { Component } from '@angular/core';
import { AVAILABLE_LANGUAGES } from '@building-x/common-ui-ng';

@Component({
  templateUrl: './login.component.html'
})

export class LoginComponent {
  public availableLangs = AVAILABLE_LANGUAGES;
}
