import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HfwInstance, StateService } from '@gms-flex/core';
import { ManagerInfo } from '@gms-flex/services';

export const hfwInstanceResolver: ResolveFn<HfwInstance> = (route, state) => {
  let managerInfo: ManagerInfo;
  let desktopParams: { layoutsPerFrameViewMap: Map<string, string>; userLayoutStoringEnabled: boolean };
  return inject(StateService).getHfwInstance(managerInfo?.framesToCreate, desktopParams?.layoutsPerFrameViewMap, desktopParams?.userLayoutStoringEnabled);
}
