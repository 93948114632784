import { TrackByFunction } from '@angular/core';

const trackByIdentity = <T>(index: number, item: T): any => item;

/**
 * Creates a trackByFunction for ngFor, that tracks an Item by its identity.
 * It should be used with a generic parameter, to generate a proper type:
 *
 * @example  @Input() trackByFunction = buildTrackByIdentity<ItemType>()
 */
export const buildTrackByIdentity = <T>(): TrackByFunction<T> => trackByIdentity;

const trackByIndex = (index: number): number => index;

/**
 * Creates a trackByFunction for ngFor, that tracks an Item by its index.
 * It should be used with a generic parameter, to generate a proper type:
 *
 * @example  @Input() trackByFunction = buildTrackByIdentity<ItemType>()
 */
export const buildTrackByIndex = <T>(): TrackByFunction<T> => trackByIndex;
