/* eslint-disable no-console, no-restricted-syntax*/
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { AppSettings } from '../app-settings/app-settings.model';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { TraceSettings } from './data.model';
export const routerTraceModuleName = 'ng-router';
import { ITrace } from './itrace.interface';

/**
 * Application trace service.
 *
 * At the moment this service just wrap some methods of the console web API. Check the documentation here:
 * https://developer.chrome.com/devtools/docs/console-api
 *
 */
@Injectable({
  providedIn: 'root'
})
export class TraceService implements ITrace {

  public traceSettings: TraceSettings;

  public appSettings!: AppSettings;

  public isTraceEnabled = true;

  public constructor(private readonly appSettingsService: AppSettingsService) {
    console.info('TraceService(): created.');
    this.traceSettings = TraceSettings.readTraceSettingsFromLocStore();
    this.appSettingsService.getAppSettings().pipe(first()).subscribe((settings: AppSettings) => {
      if (settings !== null) {
        this.isTraceEnabled = settings.traceEnabled;
      }
      if (this.isTraceEnabled === true) {
        console.info('TraceService(): Trace enabled.');
        this.traceSettings.addToVendorModules(routerTraceModuleName);
      } else {
        console.info('TraceService(): Trace disabled.');
        this.traceSettings.allModulesEnabled = false;
      }

    });
  }

  /**
   * Displays a message in the console. You pass one or more objects to this method, each of which are
   * evaluated and concatenated into a space-delimited string. The first parameter you pass to log()
   * may contain format specifiers, a string token composed of the percent sign (%) followed by a letter
   * that indicates the formatting to be applied.
   * Note: If the source and/or the severity is disabled, nothing is logged.
   *
   * source:         The name of component/module/source which is calling the method.
   * message:        The body of the message.
   * optionalParams: Parameters that will be concatenated to the message.
   */
  public debug(source: string, message?: string, ...optionalParams: any[]): void {
    if (this.isTraceEnabled) {
      if (this.traceSettings !== undefined) {
        this.traceSettings.addToModules(source);
        if ((this.traceSettings.debugEnabled === true) && (this.isModuleEnabled(source))) {
          console.log(`${source}: ${message}`, ...optionalParams);
        }
      } else {
        console.log(`${source}: ${message}`, ...optionalParams);
      }
    }
  }

  /**
   * Similar to console.debug(), console.error() and also includes a stack trace from where the method was called.
   */
  public error(source: string, message?: string, ...optionalParams: any[]): void {
    if (this.traceSettings !== undefined) {
      this.traceSettings.addToModules(source);
    }
    console.error(`${source}: ${message}`, ...optionalParams);
  }

  /**
   * See method debug() for the description
   */
  public info(source: string, message?: string, ...optionalParams: any[]): void {
    if (this.isTraceEnabled) {
      if (this.traceSettings !== undefined) {
        this.traceSettings.addToModules(source);
        if ((this.traceSettings.infoEnabled === true) && (this.isModuleEnabled(source))) {
          console.info(`${source}: ${message}`, ...optionalParams);
        }
      } else {
        console.info(`${source}: ${message}`, ...optionalParams);
      }
    }
  }

  /**
   * This method is like console.log() but also displays a yellow warning icon along with the logged message.
   */
  public warn(source: string, message?: string, ...optionalParams: any[]): void {
    if (this.isTraceEnabled) {
      if (this.traceSettings !== undefined) {
        this.traceSettings.addToModules(source);
      }
      console.warn(`${source}: ${message}`, ...optionalParams);
    }
  }

  /**
   * Returns true, if the source and the severity is enabled
   *
   * @param source
   * @returns
   *
   * @memberOf TraceService
   */
  public isDebugEnabled(source: string): boolean {
    if (this.traceSettings !== undefined) {
      if ((this.traceSettings.debugEnabled === true) && (this.isModuleEnabled(source))) {
        return true;
      }
      return false;
    }
    return true;
  }

  /**
   * Returns true, if the source and the severity is enabled
   *
   * @param source
   * @returns
   *
   * @memberOf TraceService
   */
  public isInfoEnabled(source: string): boolean {
    if (this.traceSettings !== undefined) {
      if ((this.traceSettings.infoEnabled === true) && (this.isModuleEnabled(source))) {
        return true;
      }
      return false;
    }
    return true;
  }

  private isModuleEnabled(source: string): boolean {
    if ((this.traceSettings.allModulesEnabled === true) ||
          (this.traceSettings.isModuleEnabled(source)) ||
          (this.traceSettings.isModuleExisting(source) === false)) {
      return true;
    }
    return false;
  }
}
