<div class="slider-container">
  <button
    type="button"
    class="btn btn-sm btn-circle btn-secondary element-minus decrement-button"
    [disabled]="isDisabled"
    [attr.aria-label]="decrementLabel | translate"
    (mousedown)="autoUpdateStart($event, false)"
    (touchstart)="autoUpdateStart($event, false)"
    (keydown.enter)="autoUpdateStart($event, false)"
    (touchend)="autoUpdateStop()"
    (mouseup)="autoUpdateStop()"
    (keyup.enter)="autoUpdateStop()"
    (mouseleave)="autoUpdateStop()"
  ></button>

  <div class="slider-wrapper">
    <div class="value-indicator-wrapper">
      <span class="value-indicator" [style.inset-inline-start.%]="indicatorPos">
        <span>
          <ng-content />
        </span>
      </span>
    </div>

    <div class="slider" [class.dragging]="isDragging">
      <div class="track"></div>
      <div
        class="thumb-handle-wrapper"
        [class.dragging]="isDragging"
        (mousedown)="handleMouseDown($event)"
        (touchstart)="handleTouchStart($event)"
      >
        <div #handle class="thumb-handle" [style.inset-inline-start.%]="indicatorPos">
          <div
            class="thumb"
            tabindex="0"
            role="slider"
            [attr.aria-label]="sliderLabel | translate"
            [attr.aria-valuenow]="value"
            [class.dragging]="isDragging"
            (keydown)="autoUpdateKeydown($event)"
            (keyup)="autoUpdateStop()"
          >
            @if (thumbIcon) {
              <i [ngClass]="thumbIcon"></i>
            }
            @if (!thumbIcon) {
              <div class="hover-circle"></div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="btn btn-sm btn-circle btn-secondary element-plus increment-button"
    [disabled]="isDisabled"
    [attr.aria-label]="incrementLabel | translate"
    (mousedown)="autoUpdateStart($event, true)"
    (touchstart)="autoUpdateStart($event, true)"
    (keydown.enter)="autoUpdateStart($event, true)"
    (touchend)="autoUpdateStop()"
    (mouseup)="autoUpdateStop()"
    (keyup.enter)="autoUpdateStop()"
    (mouseleave)="autoUpdateStop()"
  ></button>
</div>

@if (minLabel && maxLabel) {
  <div class="range-indicator-wrapper">
    <div class="range-indicator">
      <span>{{ minLabel }}</span>
      <span class="ms-auto">{{ maxLabel }}</span>
    </div>
  </div>
}
