import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { EMPTY, expand, Observable, reduce } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { Partition, PartitionResponse, PartitionResponsePaged, PartitionResponseSingle } from './partition-proxy.model';

const customersUrl = `${environment.bxPlatform.subscriptionsApiUrl}/v2/customers`
const partitionsUrl = `${environment.bxPlatform.subscriptionsApiUrl}/v2/partitions`

@Injectable({
  providedIn: 'root'
})
export class PartitionProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesPartitions, 'PartitionProxyService created.');
  }

  public getPartitions(customerId: string): Observable<PartitionResponse> {
    this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionProxyService.getPartitions() called for customerId=${customerId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${customersUrl}/${customerId}/partitions`;
    let params: HttpParams = new HttpParams();
    params = params.set('include', 'ownedByCustomer.name');

    return this.httpClient.get<PartitionResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.links?.next) {
          params = params.set('page[next]', response.body?.links?.next);
          return this.httpClient.get<PartitionResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body.data ?? [], included: response.body.included ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data], included: [...accumulator.included, ...current.included] })),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPartitions()')));
  }

  public getPartition(partitionId: string): Observable<PartitionResponseSingle> {
    this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionProxyService.getPartition() called: partitionId=${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${partitionsUrl}/${partitionId}`;
    let params: HttpParams = new HttpParams();
    params = params.set('include', 'ownedByCustomer.name');

    return this.httpClient.get<PartitionResponseSingle>(url, { headers, params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getPartition()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPartition()')));
  }

  public getPartitionOfCustomer(customerId: string, partitionId: string): Observable<PartitionResponseSingle> {
    this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionProxyService.getPartitionOfCustomer() called:
      customerId=${customerId}, partitionId=${partitionId}`);

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${customersUrl}/${customerId}/partitions/${partitionId}`;
    let params: HttpParams = new HttpParams();
    params = params.set('include', 'ownedByCustomer.name');

    return this.httpClient.get<PartitionResponseSingle>(url, { headers, params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getPartitionOfCustomer()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getPartitionOfCustomer()')));
  }

  public findPartition(customerId: string, partitionId: string): Observable<Partition | undefined> {
    this.traceService.debug(TraceModules.bxServicesPartitions, 'findPartition() called');
    return this.getPartitions(customerId).pipe(
      map(response => response.data.find(partition => partition.id === partitionId))
    );
  }
}
