import {
  Criterion,
  OptionCriterion,
  OptionType,
  SearchCriteria,
  ValidationType
} from './si-filtered-search.model';

export interface OptionCriterionIntern extends OptionCriterion {
  label: string;
  selected: boolean;
}

export interface CriterionIntern extends Criterion {
  label: string;
  value: string | string[];
  valueLabel?: string;
  dateValue?: Date;
  isSubmitItem?: boolean;
}

export interface SearchCriteriaIntern extends SearchCriteria {
  criteria: CriterionIntern[];
}

interface Labelwise {
  label: string;
}

interface Namewise {
  name: string;
}

interface ValidationTypewise {
  validationType?: ValidationType;
}

/** Convert options to option criterions */
export const toOptionCriteria = (values?: OptionType[]): OptionCriterionIntern[] =>
  values?.map(v =>
    typeof v === 'string'
      ? { label: v, value: v, selected: false }
      : { label: v.label ?? v.value, value: v.value, selected: false, iconClass: v.iconClass }
  ) ?? [];

/*
 * Update selected state the matching is based on value since plain
 * string options will automatically fill the value attribute with the
 * actual string value.
 */
export const selectOptions = (options: OptionCriterionIntern[], toSelect: string[]): void =>
  options.forEach(val => (val.selected = toSelect.includes(val.value)));

/** Filter option criterions by label (case-insensitive) */
export const filterByLabel = <Type extends Labelwise>(
  options: Type[],
  searchLabel?: string
): Type[] =>
  searchLabel
    ? options.filter(e => e.label.toLowerCase().includes(searchLabel.toLowerCase()))
    : options;

/** Whether the criteria's contain the label (case-insensitive) */
export const hasCriteriaLabel = <Type extends Labelwise>(
  criterias: Type[],
  searchLabel?: string
): boolean =>
  searchLabel
    ? criterias.some(c => c.label.toLowerCase().includes(searchLabel.toLowerCase()))
    : false;

/** Find criterion by name */
export const findByName = <Type extends Namewise>(source: Type[], name: string): Type | undefined =>
  source.find(item => name === item.name);

/**
 * Difference by name, create an array that contains those elements of criteria's a that are not in criteria's b.
 * This operation is also sometimes called minus (-).
 */
export const differenceByName = <Type extends Namewise>(a: Type[], b: Type[]): Type[] =>
  a.filter(x => b.filter(y => y.name === x.name).length === 0); // filter has not

/** Whether Criteria is date or date-time */
const isDateTimeCriteria = <Type extends ValidationTypewise>(crit?: Type): boolean =>
  crit?.validationType === 'date' || crit?.validationType === 'date-time';

/** Convert criteria to internal model criteria */
export const toCriteriaIntern = (crit: Criterion): CriterionIntern => {
  const result: CriterionIntern = {
    ...crit,
    label: crit.label ?? crit.name,
    options: crit.options ?? [],
    value: crit.value ?? ''
  };

  if (isDateTimeCriteria(result)) {
    result.dateValue = result.value ? new Date(result.value.toString()) : new Date();
  }
  return result;
};

/** Convert search criteria to internal model search criteria */
export const toSearchCriteriaIntern = (
  crit: Criterion,
  critConfig?: CriterionIntern
): CriterionIntern => {
  const defaultValue = critConfig?.multiSelect ? [] : '';
  const result: CriterionIntern = {
    ...crit,
    label: crit.label ?? critConfig?.label ?? crit.name,
    options: crit.options ?? [],
    value: crit.value ?? defaultValue
  };

  // Fix input, in case the user provided the value as string for the multi-select use case.
  if (critConfig?.multiSelect && typeof result.value === 'string') {
    result.value = result.value !== '' ? [result.value] : [];
  }

  // Resolve value label from options to set the correct value in the input field.
  if (typeof result.value === 'string') {
    const matchingOption = critConfig?.options?.find(o =>
      typeof o !== 'string' ? o.value === result.value : false
    ) as OptionCriterion | null;
    result.valueLabel = matchingOption?.label ?? result.value;
  }

  if (isDateTimeCriteria(critConfig)) {
    result.dateValue = crit.value ? new Date(crit.value.toString()) : new Date();
  }

  return result;
};
