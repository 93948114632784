<ul class="dropdown-menu-child" role="menu">
  @for (item of items; track $index) {
    @if (!hasChildren(item)) {
      <li
        [attr.role]="item.title === '-' ? 'separator' : 'presentation'"
        [class.dropdown-divider]="item.title === '-'"
      >
        @if (item.title !== '-') {
          <si-menu-legacy-item
            badgeStyle="inline"
            [item]="item"
            [actionParam]="actionParam"
            [disableParentClick]="true"
            [disableLinklessClick]="true"
            [forceTitle]="forceTitle"
            (activeChange)="linkActiveChange($event)"
          />
        }
      </li>
    } @else {
      <li
        role="presentation"
        [class.dropstart]="actualDropstart"
        [class.dropend]="!actualDropstart"
        [class.disabled]="item.disabled"
      >
        <si-menu-legacy-item
          #parentItem
          badgeStyle="inline"
          dropdownCaret="end"
          [item]="item"
          [actionParam]="actionParam"
          [disableParentClick]="true"
          [disableLinklessClick]="true"
          (activeChange)="linkActiveChange($event)"
        />
        <si-menu-legacy
          [class.hidden]="childrenHidden"
          [items]="item.items!"
          [dropstart]="actualDropstart"
          [responsiveDropstart]="responsiveDropstart"
          [class.dropupwards]="item.dropUpwards"
          [focusLastFromParent]="!!item.dropUpwards"
          [responsivelyDropUpwards]="responsivelyDropUpwards"
          [actionParam]="actionParam"
          [isSubList]="true"
          [parentItem]="parentItem"
          [forceTitle]="forceTitle"
          (activeChange)="linkActiveChange($event)"
        />
      </li>
    }
  }
</ul>
