import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  booleanAttribute,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  NgZone,
  OnDestroy
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiDateInputDirective } from './si-date-input.directive';
import { SiDatepickerOverlayComponent } from './si-datepicker-overlay.component';
import { CloseCause, SiDatepickerOverlayDirective } from './si-datepicker-overlay.directive';
import { getDatepickerFormat } from './si-datepicker.model';

@Directive({
  selector: '[siDatepicker]',
  exportAs: 'siDatepicker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiDatepickerDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiDatepickerDirective,
      multi: true
    }
  ],
  hostDirectives: [
    {
      directive: SiDatepickerOverlayDirective,
      // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property
      outputs: ['siDatepickerClose']
    }
  ],
  standalone: true
})
export class SiDatepickerDirective
  extends SiDateInputDirective
  implements AfterViewInit, OnDestroy
{
  /**
   * Automatically close overlay on date selection.
   * Do not use this behavior with config showTime = true, because it
   * will close the overlay when the user change one of the time units.
   */
  @Input({ transform: booleanAttribute }) autoClose = false;
  /**
   * The input element which shall gain the focus when closing the datepicker overlay with Escape.
   */
  @Input() triggeringInput: ElementRef = inject(ElementRef);

  /**
   * During focus on close the datepicker will not show since we recover the focus on element.
   * The focus on close is only relevant when the directive is configured without a calendar button.
   */
  private focusOnClose = false;
  private overlaySubscriptions?: Subscription[];
  private externalTrigger?: ElementRef<HTMLElement>;
  private readonly ngZone = inject(NgZone);
  private readonly focusMonitor = inject(FocusMonitor);
  private readonly overlayToggle = inject(SiDatepickerOverlayDirective);

  ngAfterViewInit(): void {
    if (!this.externalTrigger) {
      this.focusMonitor
        .monitor(this.triggeringInput)
        .subscribe(origin => this.ngZone.run(() => this.focusChange(origin)));
    }

    // Monitor datepicker close
    this.overlayToggle.siDatepickerClose.pipe(takeUntil(this.destroyer$)).subscribe(cause => {
      if ([CloseCause.Escape, CloseCause.Select].includes(cause)) {
        // In scenarios where the user closed the dropdown via escape we set the focus on the input
        this.focusOnClose = true;
        this.focusMonitor.focusVia(this.externalTrigger ?? this.triggeringInput, 'program');
      } else {
        // Mark component as touch when the focus isn't recovered on input
        this.onTouched();
        this.cdRef.markForCheck();
      }
    });
    // Update datepicker with new date value
    this.dateChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(date => this.overlayToggle.setInputs({ date }));
  }

  override ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.triggeringInput);
  }

  /**
   * On click shall show datepicker.
   */
  @HostListener('click', ['$event'])
  protected onClick(): void {
    if (!this.externalTrigger) {
      this.show();
    }
  }

  /**
   * Focus out shall close the datepicker except we are moving the focus to the datepicker.
   * @param event focus out event with the related target
   */
  @HostListener('focusout', ['$event'])
  protected onFocusOut(event: FocusEvent): void {
    const target = event.relatedTarget as HTMLElement;
    if (!this.overlayToggle.contains(target)) {
      this.overlayToggle.closeOverlay();
    }
  }

  /**
   * @internal
   */
  public show(initialFocus = false): void {
    if (
      this.disabled ||
      this.readonly ||
      (!this.externalTrigger && this.focusOnClose) ||
      this.overlayToggle.isShown()
    ) {
      return;
    }

    this.subscribeDateChanges(
      this.overlayToggle.showOverlay(initialFocus, {
        config: this.siDatepickerConfig,
        date: this.date,
        time12h: this.getTime12h()
      })
    );
  }

  /**
   * @internal
   */
  public useExternalTrigger(element: ElementRef<HTMLElement>): void {
    this.externalTrigger = element;
  }

  private focusChange(origin: FocusOrigin): void {
    if (origin) {
      this.show();
    }
    this.focusOnClose = false;
  }

  private getTime12h(): boolean | undefined {
    const dateFormat = getDatepickerFormat(this.locale, this.siDatepickerConfig, true);
    return dateFormat?.includes('a');
  }

  private subscribeDateChanges(overlay?: ComponentRef<SiDatepickerOverlayComponent>): void {
    this.overlaySubscriptions?.forEach(s => s.unsubscribe());

    overlay?.instance.dateChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(d => this.onDateChanged(d));
    overlay?.instance.disabledTimeChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(d => this.onDisabledTime(d));
  }

  /**
   * Callback when the datepicker changes his value.
   * @param date updated date
   */
  protected override onDateChanged(date: Date): void {
    super.onDateChanged(date);
    if (this.autoClose) {
      // a tick later so the event won't end on the wrong element
      setTimeout(() => this.overlayToggle.closeAfterSelection());
    }
  }
}
