import { Injectable } from '@angular/core';
import { FullSnapInId, IHfwMessage, IStorageService } from '@gms-flex/core';

import { GraphicState } from '../shared/graphicState';

@Injectable()
export class GraphicStateStorageService {
  public storageService: IStorageService;
  public fullId: FullSnapInId;

  public initStorageService(messageBroker: IHfwMessage, fullId: FullSnapInId): void {
    this.storageService = messageBroker.getStorageService(fullId) as IStorageService;
    this.fullId = fullId;
  }

  public getState(): GraphicState {
    return this.storageService?.getState(this.fullId);
  }

  public setState(state: GraphicState): void {
    this.storageService?.setState(this.fullId, state);
  }

  public clearState(): void {
    this.storageService?.clearState(this.fullId);
  }

  public get SnapinId(): string {
    return this.fullId?.snapInId;
  }

  public get HasDefinedState(): boolean {
    return this.getState() !== undefined;
  }

  public getRelatedItemIdx(): number {
    const state: GraphicState = this.getState();
    if (state?.relatedItemIdx !== undefined) {
      return state.relatedItemIdx;
    } else {
      return undefined;
    }
  }

  public isDesignationEquals(designation: string): void {
    const state: GraphicState = this.getState();
    if (state !== undefined && state.designation !== designation) {
      this.clearState();
    }
  }
}
