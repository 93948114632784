import { Observable, Subject } from 'rxjs';

export class LibraryImage {

  public propertyChanged: Subject<LibraryImageChangeArgs> = new Subject<LibraryImageChangeArgs>();

  public isResolved: boolean;

  private _imagePath: string;
  public get ImagePath(): string {
    return this._imagePath;
  }

  public set ImagePath(value: string) {

    if (this._imagePath !== value) {
      this._imagePath = value;
    }
  }
  private _iconSource: string;
  public get IconSource(): string {
    return this._iconSource;
  }
  public set IconSource(value: string) {

    if (this._iconSource !== value) {
      this._iconSource = value;
      this.NotifyPropertyChanged();
    }
  }
  constructor(imagePath: string) {
    this._imagePath = imagePath;
    this.isResolved = false;
  }

  protected NotifyPropertyChanged(): void {

    if (this.propertyChanged.observers?.length > 0) {

      const args: LibraryImageChangeArgs = new LibraryImageChangeArgs();
      args.ImagePath = this.ImagePath;
      args.IconSource = this.IconSource;

      this.propertyChanged.next(args);
    }
  }
}

export class LibraryImageChangeArgs {
  private _imagePath: string;
  public get ImagePath(): string {
    return this._imagePath;
  }

  public set ImagePath(value: string) {
    if (this._imagePath !== value) {
      this._imagePath = value;
    }
  }

  private _iconSource: string;
  public get IconSource(): string {
    return this._iconSource;
  }
  public set IconSource(value: string) {
    if (this._iconSource !== value) {
      this._iconSource = value;
    }
  }
}
