import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { isNullOrUndefined } from '@gms-flex/services-common';

import { ActiveMenuItemService } from '../services/active-menu-item.service';
import { GmsEasyNavigationResizeService } from '../services/gms-easy-navigation-resize.service';
import { Guid } from '../utilities/guid';

@Component({
  selector: `gms-easy-navigation-bar-ellipsis`,
  template: `
    <div #ellipsisMenuContainer class="element-options-vertical h-100 ellipsis-menu"
         [style.display]="ShowEllipsis ? null : 'none'">
    </div>
    <si-menu-factory *ngIf="ShowMenu"
                  class="easy-navigation-bar-ellipsis-si-menu"
                  [items]="treeItems" />
  `,
  styleUrl: './gms-easy-navigation-bar-ellipsis.component.scss'
})
export class GmsEasyNavigationBarEllipsisComponent implements OnInit {
  @Input() public treeItems: any[];
  @ViewChild('ellipsisMenuContainer') public ellipsisMenuContainer: ElementRef;
  public offset = 0;
  private readonly id: string = Guid.newGuid();
  private isResizing = false;
  private resizeTimeout: any = undefined;

  constructor(private readonly activeMenuItemService: ActiveMenuItemService,
    private readonly gmsEasyNavigationResizeService: GmsEasyNavigationResizeService,
    public cdRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.gmsEasyNavigationResizeService.resizeObs.subscribe(() => this.onResize());
  }

  public get LeftOffset(): string {
    const containerRight: number = this?.gmsEasyNavigationResizeService?.NavigationContainerRect?.right || 0;
    const ellipsisLeft: number = this.ellipsisMenuContainer?.nativeElement?.getBoundingClientRect()?.left || 0;
    const currOffset: number = containerRight - ellipsisLeft;
    this.offset = this.offset + currOffset - 30;
    const offsetString = `${this.offset}px`;
    return offsetString;
  }

  public get ShowEllipsis(): boolean {
    if (this.ShowMenu) {
      return true;
    }

    if (isNullOrUndefined(this?.treeItems?.length) || this.isResizing) {
      return false;
    }

    return this?.treeItems?.length > 0;
  }

  public onResize(): void {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    if (!this.isResizing) {
      this.setIsResizing(true);
    }

    this.resizeTimeout = setTimeout(() => {
      this.setIsResizing(false);
      this.resizeTimeout = undefined;
    }, 400);
  }

  public setIsResizing(isResizing: boolean): void {
    this.isResizing = isResizing;
    this.cdRef.detectChanges();
  }

  @HostListener('mouseenter', ['$event'])
  public onMouseEnter(): void {
    this.activeMenuItemService.setActiveItem(this.id);
  }

  @HostListener('window:click', ['$event'])
  public onWindowClick(event: Event): void {
    if (this.ellipsisMenuContainer.nativeElement.contains(event.target)) {
      if (this.activeMenuItemService.isActiveMenuItem(this.id)) {
        this.activeMenuItemService.clearActiveMenuItem();
      } else {
        this.activeMenuItemService.ActiveMenuItemId = this.id;
      }
    }
  }

  public get ShowMenu(): boolean {
    const isActiveMenuItem: boolean = this.activeMenuItemService.isActiveMenuItem(this.id);
    return isActiveMenuItem;
  }
}
