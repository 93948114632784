import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng/modal';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { DeleteConfirmationDialogResult } from '../si-action-dialog.types';

@Component({
  selector: 'si-delete-confirmation-dialog',
  templateUrl: './si-delete-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiDeleteConfirmationDialogComponent {
  @Input() titleId?: string;
  @Input() heading = '';
  @Input()
  message =
    $localize`:@@SI_DELETE_CONFIRMATION_DIALOG.MESSAGE:Do you really want to delete the selected elements?`;
  @Input() deleteBtnName = $localize`:@@SI_DELETE_CONFIRMATION_DIALOG.DELETE_BTN:Delete`;
  @Input() cancelBtnName = $localize`:@@SI_DELETE_CONFIRMATION_DIALOG.CANCEL_BTN:Cancel`;
  @Input() translationParams: { [key: string]: any } = {};
  @Input() icon = '';

  protected modalRef = inject(
    ModalRef<SiDeleteConfirmationDialogComponent, DeleteConfirmationDialogResult>
  );

  protected onDelete(): void {
    this.modalRef.hide(DeleteConfirmationDialogResult.Delete);
  }

  protected onCancel(): void {
    this.modalRef.hide(DeleteConfirmationDialogResult.Cancel);
  }
}
