import { AfterViewChecked, Component, Inject, Input, NgZone, OnDestroy, OnInit, Self, ViewChild } from '@angular/core';
import {
  FullPaneId, FullQParamId, FullSnapInId, IHfwMessage, IObjectSelection, ISnapInConfig,
  MessageParameters, MobileNavigationService, QParam
} from '@gms-flex/core';
import { ContextState, PropertyApiService, PropertySnapInService, PropertySnapInViewModel } from '@gms-flex/property';
import { BrowserObject, GmsMessageData, SystemBrowserServiceBase } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { GridData } from '@gms-flex/snapin-common/events/event-data.model';
import { TranslateService } from '@ngx-translate/core';
import {
  Criterion, MenuItem, SiDropdownDirective, SiSidePanelService, SiToastNotificationService, StatusItem
} from '@simpl/element-ng';
import { DateFormat, PropertyApi, SiPropertyConfig, SiPropertyService, TimeFormat } from '@simpl/object-browser-ng';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PointBx } from 'src/app/bx-services/point/point-proxy.model';

import { PointService } from '../../bx-services/point/point.service';

export const systemRPModuleName = 'gmsApplication_SystemRightPanel';
const PROPERTY_PANEL_ID = 'Properties';

export interface ParamsSendMessage {
  fullId: FullSnapInId;
  location?: FullPaneId;
  types: string[];
  messageBody: any;
  preselection: boolean;
  qParam?: QParam;
  broadcast?: boolean;
  applyRuleId: string;
  secondarySelectionInSinglePane: boolean;
}

interface StateIcons {
  icon: string;
  secondaryIcon: string;
}

@Component({
  selector: 'gms-sytem-rp',
  templateUrl: './sytem-rp.component.html',
  providers: [
    SiPropertyConfig,
    SiPropertyService,
    { provide: PropertyApi, useClass: PropertyApiService }
  ]
})
export class SytemRPComponent implements AfterViewChecked, OnInit, OnDestroy {
  private static readonly rightPanelClass = '.full-height';

  @Input() public frameId: string;
  @Input() public parentId: string;
  @Input() public displayRelatedItems = true;
  public isFromRPComponent = true;
  public isPointSelected = false;
  public fromRightSnapin = true;
  public detailPaneHeight = '';
  public isRightsApplicable = false;
  public isLoadingData = false;
  public criteriaData: Criterion[];
  public systemName: string;
  public prevMessageBodyData: BrowserObject[];
  public selectedPoint: PointBx;
  public detailPaneActive: boolean;
  public dropDownRef = '';
  public dropDownRefEvents = '';
  public selectedNodeHeading = '';
  public viewId: number;
  public blockOpacity = 0;
  public currentLocation = '';

  public memoMenuItem: MenuItem = { title: 'Memo', icon: 'element-notes-edit', iconOnly: true, action: (): void => { this.dropdownMemo.toggle(); } };

  public primaryActions: MenuItem[] = [
    { title: 'About', icon: 'element-info', iconOnly: true, action: (): void => { this.dropdown.toggle(); } },
    this.memoMenuItem
  ];
  public secondaryActions: MenuItem[];

  public statusActions: StatusItem[] = [
    {
      id: 'event-popover-btn',
      title: '',
      tooltip: '',
      icon: '',
      overlayIcon: '',
      disabled: true,
      action: (): void => this.dropdownEvents.toggle()
    }
  ];

  public snapInVm: PropertySnapInViewModel;

  public isMemoEmpty;
  public placeholderFilter: string;
  public labelAdvancedSwitch: string;
  public labelBasicSwitch: string;
  public saveErrorTitle: string;
  public commandErrorTitle: string;
  public defaultCommandErrorMesg: string;
  public noPropertiesText: string;
  public propertiesText: string;
  public differentPropertyTypesText: string;
  public multiEventsTitle: string;
  public eventConfigurationHeading: string;
  public isMobile: boolean;

  public isLoading: boolean;
  public isLoadingSpinnerEnabled: boolean;

  // to set record id of event object
  // E.g.: "System1:GmsDevice_1_72000_4194305.Alarm.OffNormal:_alert_hdl.2._value~638107035612400000~2~518"
  public eventSelectedId: string | undefined;

  // related item properties
  public hasComparisonPane: boolean;

  // Event popover properties
  public eventsCounter = 0;
  public events: GridData[];

  public lastHeadingOpened;
  @Input() public readonly isRightPanelExpanded: boolean;

  @ViewChild('dropdown', { read: SiDropdownDirective }) private readonly dropdown!: SiDropdownDirective;
  @ViewChild('dropdownMemo', { read: SiDropdownDirective }) private readonly dropdownMemo!: SiDropdownDirective;
  @ViewChild('dropdownEvents', { read: SiDropdownDirective }) private readonly dropdownEvents!: SiDropdownDirective;

  private readonly subs: Subscription[] = [];
  private readonly propertyApiService: PropertyApiService;
  private isRightPanelOpen: boolean;
  public readonly trackByIndex = (index: number): number => index;

  constructor(private readonly traceService: TraceService,
    private readonly mgsBroker: IHfwMessage,
    private readonly objectSelection: IObjectSelection,
    @Inject(PropertySnapInService) private readonly propertySnapInService: PropertySnapInService,
    @Inject(SiToastNotificationService) private readonly toastNotificationService: SiToastNotificationService,
    @Inject(TranslateService) private readonly translateService: TranslateService,
    @Self() private readonly siPropertyConfig: SiPropertyConfig,
    @Self() propertyApi: PropertyApi,
    private readonly ngZone: NgZone,
    private readonly snapinConfig: ISnapInConfig,
    private readonly pointService: PointService,
    private readonly systemBrowserService: SystemBrowserServiceBase,
    @Inject(SiSidePanelService) private readonly sidePanelService: SiSidePanelService,
    @Inject(MobileNavigationService) private readonly mobileNavigationService: MobileNavigationService) {
    this.isFromRPComponent = true;

    // Get the browser locale
    const browserLocale = this.translateService.getBrowserLang();

    // The PropertyApiService is provided to the SiPropertyService injected into the si-property-viewercomponent.
    // It must be initialized with the SNI view-model during local component initialization in order to process
    // get-property and property-command requests from the child si-property-viewer component.
    this.propertyApiService = propertyApi as PropertyApiService;

    // The SiPropertyConfig service is shared with the si-property-viewer component and holds component configuration information.
    this.siPropertyConfig.update({
      dateFormat: browserLocale === 'en' ? DateFormat.DF_MMDDYYYY_FSLASH : DateFormat.DF_DDMMYYYY_FSLASH,
      timeFormat: TimeFormat.TWELVE
    });
  }

  public ngOnInit(): void {
    this.isFromRPComponent = true;

    this.dropDownRef = '#' + this.parentId + ' .rpanel-wrapper > .rpanel-header';
    this.dropDownRefEvents = '.rpanel-content';
    this.snapInVm = this.propertySnapInService.registerViewModel('system-right-panel');

    if (!this.snapInVm) {
      throw new Error('view-model registration failed');
    }
    // Initialize the propertyApiService to process si-property-viewer component requests
    this.propertyApiService.initialize(this.traceService, this.snapInVm);
    this.propertyApiService.commandError
      .subscribe(errMesg => {
        this.toastNotificationService.queueToastNotification('danger', this.commandErrorTitle, errMesg || this.defaultCommandErrorMesg);
      });

    // Set locale for formatting numeric and date-time values (from browser setting)
    this.snapInVm.setLocale(this.translateService.getBrowserLang());
    this.translateService.get('RIGHT-PANEL.FILTER-PLACEHOLDER').subscribe(s => this.placeholderFilter = s);
    this.translateService.get('RIGHT-PANEL.ADVANCED-SWITCH-LABEL').subscribe(s => this.labelAdvancedSwitch = s);
    this.translateService.get('RIGHT-PANEL.BASIC-SWITCH-LABEL').subscribe(s => this.labelBasicSwitch = s);
    this.translateService.get('RIGHT-PANEL.SAVE-ERROR-TITLE').subscribe(s => this.saveErrorTitle = s);
    this.translateService.get('RIGHT-PANEL.COMMAND-ERROR-TITLE').subscribe(s => this.commandErrorTitle = s);
    this.translateService.get('RIGHT-PANEL.COMMAND-ERROR-MESSAGE-DEFAULT').subscribe(s => this.defaultCommandErrorMesg = s);
    this.translateService.get('RIGHT-PANEL.NO-PROPERTIES').subscribe(s => this.noPropertiesText = s);
    this.translateService.get('RIGHT-PANEL.PROPERTIES').subscribe(s => this.propertiesText = s);
    this.translateService.get('RIGHT-PANEL.DIFFERENT-PROPERTY-TYPES').subscribe(s => this.differentPropertyTypesText = s);
    this.translateService.get('RIGHT-PANEL.EVENT-POPOVER-EVENTS').subscribe(s => this.multiEventsTitle = s);
    this.translateService.get('RIGHT-PANEL.EVENT-POPOVER-EVENTS-TOOLTIP').subscribe(s => this.statusActions[0].tooltip = s);
    this.translateService.get('ALARM_CONFIGURATION.PANEL_HEADING').subscribe(s => this.eventConfigurationHeading = s);

    // Related Items begin.
    // Load language dependent strings

    // Subscribe to changes in the availability of a "comparison-pane" in the layout
    this.snapinConfig.paneCanBeDisplayed(this.frameId, 'comparison-pane')
      // .pipe(
      //   takeUntil(this.destroyInd))
      .subscribe(
        val => {
          this.hasComparisonPane = val;
        });

    this.subs.push(this.mgsBroker.getRightPanelMessage(this.frameId).subscribe(
      (m => {
        if (m != null) {
          this.traceService.debug(systemRPModuleName, 'message arrived.', m);
          this.receiveMessage(m);
        }
      })
    ));
    this.subs.push(this.objectSelection.getRightPanelSelectedObject(this.frameId).subscribe(
      (info => {
        this.selectedNodeHeading = info?.title;
      })
    ));

    this.snapInVm.loading.subscribe(loading => {
      this.isLoading = loading;
      if (this.isLoading) {
        // Delay showing the spinner until we have been waiting a short period of time.
        // This avoids the spinner "blinking" quickly in/out of view on every selection no
        // matter how quickly the new context loads.
        setTimeout(() => this.isLoadingSpinnerEnabled = this.isLoading, 800);
      } else {
        this.isLoadingSpinnerEnabled = false;
      }
    });

    this.sidePanelService.isOpen$.subscribe(isOpen => {
      this.isRightPanelOpen = isOpen;
    });

    // Mobile Only Visibility
    this.isMobile = this.mobileNavigationService.mobileOnlyVisibilityLast;

    this.subs.push(this.mobileNavigationService.mobileOnlyVisibility$.subscribe((mobileOnlyVisibility: boolean) => {
      this.isMobile = mobileOnlyVisibility;
    }));
  }

  public onPropertyToggle(): void {
    this.lastHeadingOpened = PROPERTY_PANEL_ID;
  }

  public onRelatedToggle(heading: string): void {
    this.lastHeadingOpened = heading;
  }

  public ngAfterViewChecked(): void {
    this.onRightPanelExpandCollapse(this.isRightPanelOpen);
  }

  public ngOnDestroy(): void {
    this.subs.forEach(subscription => {
      if (subscription !== null) {
        subscription.unsubscribe();
      }
    });

    if (!isNullOrUndefined(this?.snapInVm?.detailProperty?.objectFilter)) {
      this.snapInVm.detailProperty.objectFilter = '';
    }

    this.snapInVm.propertyFilter = '';
  }

  /**
   * Handles the expand and collapse events of the right panel.
   * Adjusts the size and state of the right panel, as well as performs
   * additional actions based on the panel's open or closed status.
   *
   * @param isOpen A boolean indicating whether the right panel is being expanded (true) or collapsed (false).
   */
  public onRightPanelExpandCollapse(isOpen: boolean): void {
    // Select the right panel element using its class
    const element = document.querySelector(SytemRPComponent.rightPanelClass) as HTMLElement;

    // Run the following operations outside of the Angular zone for performance optimization
    this.ngZone.runOutsideAngular(() => {
      // Set a timeout to delay the adjustments for a smoother visual transition
      setTimeout(() => {
        // Adjust the blockSize property of the right panel based on its open or closed status
        // At the moment it is handled like this to stop inconsistent behavior on Safari.
        if (element) {
          element.style.blockSize = isOpen ? '100%' : '0%';

          // If in mobile view, update the right panel state in the mobile navigation service
          // for configureView() adjustments in mobile-view.component
          if (this.isMobile) {
            this.mobileNavigationService.setRightPanelState(isOpen);
          }
        }
      }, 100);
    });
  }

  public onMemoEmptyStateChanged(event: boolean): void {
    this.memoMenuItem = { ...this.memoMenuItem, badgeDot: !event };
    const memoIndex = this.primaryActions.findIndex(i => i.title === 'Memo');
    if (memoIndex >= 0) {
      this.primaryActions[memoIndex] = this.memoMenuItem;
    }
  }

  public togglePropertyList(): void {
    if (this.snapInVm) {
      this.snapInVm.showPropertyListExt = !this.snapInVm.showPropertyListExt;
    }
  }

  public get filterPattern(): string {
    if (!this.snapInVm) {
      return undefined;
    }
    return this.snapInVm.detailProperty ? this.snapInVm.detailProperty.objectFilter : this.snapInVm.propertyFilter;
  }

  public get switchLabel(): string {
    let lab = '';
    if (this.snapInVm) {
      lab = this.snapInVm.showPropertyListExt ? this.labelAdvancedSwitch : this.labelBasicSwitch;
    }
    return lab;
  }

  public get isPropertyListEmpty(): boolean {
    if (!(this.snapInVm && this.snapInVm.contextState !== ContextState.Empty)) {
      return false;
    }
    const propListLen: number = this.snapInVm.siPropertyList?.length || 0;
    const bulkListLen: number = this.snapInVm.siBulkPropertyList?.length || 0;
    return this.snapInVm.contextState === ContextState.SingleObject ? propListLen === 0 : bulkListLen === 0;
  }

  public get propertyListEmptyText(): string {
    return this.snapInVm.contextState === ContextState.MultiObjectDifferent ? this.differentPropertyTypesText : this.noPropertiesText;
  }

  public get isMemoEnabled(): boolean {
    return this.snapInVm?.contextState === ContextState.SingleObject;
  }

  public onFilterChange(filter: string): void {
    if (this.snapInVm.detailProperty) {
      this.snapInVm.detailProperty.objectFilter = filter;
    } else {
      this.snapInVm.propertyFilter = filter;
    }
  }

  public getMenuItemCreateText(): Observable<string> {
    return this.translateService.get('RIGHT-PANEL.NEW-ITEM-MENU');
  }

  public receiveMessage(messageBody: GmsMessageData): boolean {
    this.processReceivedMessage(messageBody);
    // 0 = explicit selection type of None. Used when alarms are reset for example.
    if (messageBody?.selectionType === 0 || messageBody?.data?.length === 0) {
      this.snapInVm.setContext(undefined);
      this.eventSelectedId = undefined;
      this.setPopoverToDefault();
      return;
    }
    if (messageBody?.data?.length > 0) {
      // if event list snapin is selected we got selectionType = 1
      // we are setting evnt record id for sending to logviewer which will pass as
      // additionalinfo for fetching history data specific to event snapin
      if (messageBody.selectionType === 1) {
        this.eventSelectedId = messageBody.customData?.[0].id;
      }
      this.viewId = messageBody?.data[0]?.ViewId;
      this.snapInVm.setContext(messageBody.data);
    }

    this.prevMessageBodyData = messageBody.data;
  }

  public onEventsCounterChange(eventsNum: number): void {
    this.eventsCounter = eventsNum;
    const evtPopoverBtn = this.statusActions.find(itm => itm.id === 'event-popover-btn');

    evtPopoverBtn.disabled = eventsNum < 1;
  }

  public onEventsChange(events: GridData[]): void {
    this.events = events;
    const evtPopoverBtn = this.statusActions.find(itm => itm.id === 'event-popover-btn');

    if (events.length > 0) {
      // if (events.length === 1){
      const ev = events[0];
      const srcState = ev?.cellData?.get('srcState');
      const srcStateText = Array.isArray(srcState) ? srcState[0] : srcState;
      const state = ev?.cellData?.get('state');
      const stateText = Array.isArray(state) ? state[0] : state;
      const iconClasses = this.getSourceStateIcons(srcState, state[0]);

      evtPopoverBtn.title = `${srcStateText}\n${stateText}`;
      evtPopoverBtn.icon = iconClasses.icon;
      evtPopoverBtn.overlayIcon = iconClasses.secondaryIcon;
    } else {
      evtPopoverBtn.title = `${this.multiEventsTitle}: ${events.length}`;
    }
  }

  public onPopoverClose(ev): void {
    this.dropdownEvents.close();
  }

  private setPopoverToDefault(): void {
    const evtPopoverBtn = this.statusActions.find(itm => itm.id === 'event-popover-btn');

    evtPopoverBtn.icon = 'dot text-muted text-center';
    evtPopoverBtn.overlayIcon = '';
    evtPopoverBtn.disabled = true;
  }

  private getSourceStateIcons(sourceState: string, eventState: string): StateIcons {
    const icons = { icon: '', secondaryIcon: undefined };
    if (sourceState === 'Quiet') {
      if (this.isEventAcked(eventState)) {
        icons.icon = 'element-alarm-background text-body';
        icons.secondaryIcon = 'element-alarm-tick text-body';
      } else {
        icons.icon = 'element-alarm text-body';
      }
    } else {
      if (this.isEventAcked(eventState)) {
        icons.icon = 'element-alarm-background-filled event-info-source-icon-active-color';
        icons.secondaryIcon = 'element-alarm-tick text-body';
      } else {
        icons.icon = 'element-alarm-filled event-info-source-icon-active-color';
      }
    }

    return icons;
  }

  private isEventAcked(eventState: string): boolean {
    switch (eventState) {
      case 'Unprocessed':
      case 'UnprocessedWithTimer': {
        return false;
      }
      default: {
        return true;
      }
    }
  }

  private processReceivedMessage(msg: GmsMessageData): void {
    if (!(msg?.data)) {
      this.isPointSelected = false;
      return;
    }

    if (msg?.data[0]?.Attributes?.TypeDescriptor === 'Point') {
      this.isPointSelected = true;
      this.retrievePointData(msg?.data[0]?.ObjectId.split(':')[0], msg?.data[0]?.ObjectId.split(':')[1]);
    } else {
      this.isPointSelected = false;
      this.selectedPoint = null;
    }

    let boArr: BrowserObject[];
    if (msg.selectionType !== 0) { // 0 = explicit selection type of 'None'; ignore message data!
      boArr = msg.data;
    }
  }

  private retrievePointData(partitionId: string, pointId: string): void {
    this.currentLocation = partitionId;
    this.subs.push(
      this.pointService
        .getPointById(partitionId, pointId)
        .subscribe(point => this.selectedPoint = point)
    );
  }

  private sendSelectionMessage(bo: BrowserObject, secondaryPane: boolean, frame: string): void {
    this.traceService.info(systemRPModuleName, 'Send selection message: bo=%s, secondaryPane=%s', bo ? bo.Designation : undefined, secondaryPane);
    if (!bo) {
      return;
    }
    const boArr: BrowserObject[] = [bo];
    const messageTypes: string[] = boArr.map(item => item.Attributes ? bo.Attributes.ManagedTypeName : '');
    const data: GmsMessageData = new GmsMessageData(boArr);

    const applyRuleId = secondaryPane ? 'SecondarySelection' : undefined;

    const fullQParamId = new FullQParamId('system-manager', 'SystemQParamService', 'primary');
    const qParamValue: QParam = { name: fullQParamId.fullId(), value: boArr[0].Designation };
    const message: MessageParameters = {
      messageBody: data,
      qParam: qParamValue,
      types: messageTypes
    };

    if (this.frameId === 'system-manager') {
      this.mgsBroker.sendMessageFromRightPanel('right-panel-related-item',
        this.frameId,
        frame,
        messageTypes,
        data,
        true,
        undefined,
        false,
        applyRuleId,
        false).subscribe(
        res => this.traceService.debug(systemRPModuleName, 'sendMessage completed: result=%s', res),
        err => this.traceService.debug(systemRPModuleName, 'sendMessage error: %s', err)
      );
    }
    if (this.frameId === 'event-list') {
      this.mgsBroker.switchToNextFrame(fullQParamId.frameId, message).pipe(take(1)).subscribe((frameChanged: boolean) => {
        this.traceService.debug(systemRPModuleName, 'goToSystem() completed. result: %s', frameChanged);
      });
    }
  }

  private sendSelection(objectId: string, receivedRuleName?: string): void {
    if (objectId) {
      // this.systemBrowserService.searchNodeMultiple(this.systemIdLogViewerNavigate, [objectId]).subscribe(nodes => {
      //   const navigationBrowserObject: BrowserObject[] = [nodes[0].Nodes[0]];
      //   this.sendSelectionMessage(navigationBrowserObject[0], false, receivedRuleName);
      // });
    }
  }
}
