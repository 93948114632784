<div class="wrapper" [class.labels]="showValues">
  <div
    class="grid d-flex flex-column position-relative text-secondary small"
    [style.grid-column-end]="items.length + 1"
  >
    @for (p of gridPartArray; track $index) {
      <div class="border-top flex-fill"></div>
    }
    @if (showValues) {
      <div class="axis-label position-absolute text-end label-100">100</div>
      <div class="axis-label position-absolute text-end label-0">0</div>
    }
  </div>
  @for (item of items; track trackByItem(i, item); let i = $index) {
    <div class="bar-outer position-relative" [style.grid-column]="i + 1">
      <div
        class="bar position-absolute d-flex flex-column text-center"
        [class.detail]="!labelOnly"
        [style.height.%]="item.value"
        (click)="selected.emit(item)"
      >
        <div
          class="bar-inner flex-fill"
          [class.selected]="item.selected"
          [class.disabled]="item.disabled"
          [ngClass]="'status-' + item.status"
        >
          @if (showValues && !item.disabled) {
            <div class="value text-body small"> {{ item.value }}{{ item.unit ?? '' }} </div>
          }
        </div>
      </div>
    </div>
    <div
      class="status"
      tabindex="0"
      [attr.aria-disabled]="item.disabled"
      (click)="selected.emit(item)"
      (keydown.enter)="selected.emit(item)"
    >
      @if (!labelOnly) {
        <si-comfort-score-status size="large" [item]="item" />
      }
      @if (labelOnly) {
        <div class="small text-center" [class.text-muted]="item.disabled">{{
          item.title | translate
        }}</div>
      }
    </div>
  }
</div>
