import { animate, AnimationBuilder, style } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { STATUS_ICON } from '@simpl/element-ng/common';
import { SiIconModule } from '@simpl/element-ng/icon';
import { SiLinkModule } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiToast } from '../si-toast.model';

@Component({
  selector: 'si-toast-notification',
  templateUrl: './si-toast-notification.component.html',
  styleUrl: './si-toast-notification.component.scss',
  imports: [NgClass, SiLinkModule, SiIconModule, SiTranslateModule],
  standalone: true
})
export class SiToastNotificationComponent implements OnChanges {
  @Input() toast!: SiToast;

  protected closeAriaLabel = $localize`:@@SI_TOAST.CLOSE:Close`;

  protected statusIcon = STATUS_ICON.info;

  @ViewChild('autoCloseBar', { static: true }) private autoCloseBar!: ElementRef<HTMLDivElement>;

  private animationBuilder = inject(AnimationBuilder);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.toast.currentValue) {
      if (this.toast.state === 'connection') {
        this.statusIcon = STATUS_ICON.danger;
      } else {
        this.statusIcon = STATUS_ICON[this.toast.state];
      }

      if (!this.toast.disableAutoClose && this.toast.timeout) {
        const animation = this.animationBuilder.build([
          style({ width: '100%' }),
          animate(this.toast.timeout, style({ width: '0%' }))
        ]);
        animation.create(this.autoCloseBar.nativeElement).play();
      }

      this.closeAriaLabel = this.toast.closeAriaLabel ?? this.closeAriaLabel;
    }
  }

  protected close(): void {
    this.toast.close!();
  }
}
