import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  DestroyRef,
  Directive,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SiAutocompleteOptionDirective } from './si-autocomplete-option.directive';
import { SiAutocompleteDirective } from './si-autocomplete.directive';

@Directive({
  selector: '[siAutocompleteListboxFor]',
  standalone: true,
  exportAs: 'siAutocompleteListbox',
  host: {
    role: 'listbox'
  }
})
export class SiAutocompleteListboxDirective<T> implements OnInit, AfterContentInit {
  private static idCounter = 0;

  @ContentChildren(SiAutocompleteOptionDirective, { descendants: true })
  private options!: QueryList<SiAutocompleteOptionDirective<T>>;

  @Input()
  @HostBinding('id')
  id = `__si-autocomplete-listbox-${SiAutocompleteListboxDirective.idCounter++}`;

  @Input('siAutocompleteListboxFor') autocomplete!: SiAutocompleteDirective<T>;

  @Input() siAutocompleteDefaultIndex = 0;

  @Output() readonly siAutocompleteOptionSubmitted = new EventEmitter<T>();

  private keyManager?: ActiveDescendantKeyManager<SiAutocompleteOptionDirective<T>>;

  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    // For some reason, this is needed sometimes. Otherwise, one may get ExpressionChangedAfterItHasBeenCheckedError.
    queueMicrotask(() => {
      this.changeDetectorRef.markForCheck();
      this.autocomplete.listbox = this;
    });
    this.destroyRef.onDestroy(() => (this.autocomplete.listbox = undefined));
  }

  ngAfterContentInit(): void {
    this.keyManager = new ActiveDescendantKeyManager(this.options)
      .withWrap(true)
      .withVerticalOrientation(true);

    this.options.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setActiveItem());

    this.setActiveItem();
  }

  private setActiveItem(): void {
    queueMicrotask(() => {
      this.keyManager!.setActiveItem(this.siAutocompleteDefaultIndex);
      this.changeDetectorRef.markForCheck();
    });
  }

  /** @internal */
  onKeydown(event: KeyboardEvent): void {
    this.keyManager!.onKeydown(event);
    if (event.key === 'Enter' && this.keyManager!.activeItem) {
      this.siAutocompleteOptionSubmitted.emit(this.keyManager!.activeItem.value);
    }
    this.changeDetectorRef.markForCheck();
  }

  get active(): SiAutocompleteOptionDirective<T> | null {
    return this.keyManager?.activeItem ?? null;
  }
}
