<div class="d-flex flex-column pb-3 rounded-bottom h-100">
  <div class="property-viewer-toolbar w-100 px-5" style="flex: 0 0 auto; overflow: hidden;">

    <!-- Property filter -->
    <div *ngIf="paneWidth > 290" class="d-flex flex-row me-5 w-100" style="flex: 1 1 auto;">
      <div style="flex: 1 1 auto; max-width: 230px;">
        <si-search-bar
          [debounceTime]="100"
          [placeholder]="placeholderFilter"
          [colorVariant]="'base-0'"
          [showIcon]="true"
          [value]="filterPattern"
          [disabled]="snapInVm.detailProperty !== undefined"
          (searchChange)="onFilterChange($event)" />
      </div>
    </div>

    <!-- Informational popovers -->
    <div class="d-flex flex-row justify-content-center align-items-center" style="flex: 0 0 auto;">
      <gms-about-popover style="flex: 0 0 auto;"
        [clientId]="snapInVm.id"
        [objectList]="snapInVm.contextObjectList"
        [popoverPlacement]="'top'"
        (openStateChanged)="isAboutOpen = $event">
        <div role="button" class="btn-circle btn-flat-ovrd" [class.popover-open]="isAboutOpen" tabindex="0">
          <i class="element-info"></i>
        </div>
      </gms-about-popover>

      <gms-event-popover #eventPopover class="mx-1" style="flex: 0 0 auto;"
        [disabled]="snapInVm.contextObjectCount > 0"
        [fullSnapinID]="fullSnapinID"
        [nodes]="snapInVm.contextObjectList" />

      <gms-memo-popover style="flex: 0 0 auto;"
        [clientId]="snapInVm.id"
        [object]="snapInVm.contextObjectCount === 1 ? snapInVm.contextObjectList[0] : undefined"
        [popoverPlacement]="'top'"
        [isDisabled]="!isMemoEnabled"
        (openStateChanged)="onOpenStateChanged($event)"
        (emptyStateChanged)="isMemoEmpty = $event"
        (memoSaveError)="onMemoSaveError($event)">
        <div role="button" class="btn-circle btn-flat-ovrd" tabindex="0"
          [class.popover-open]="isMemoOpen"
          [class.popover-disabled]="!isMemoEnabled">
          <i class="element-list" [class.badge-dot]="!isMemoEmpty"></i>
        </div>
      </gms-memo-popover>
    </div>

    <!-- Toggle control for standard / extended operation views -->
    <div class="d-flex flex-row justify-content-end align-items-center ms-5 w-100" style="flex: 1 1 auto;">
      <div class="form-group mb-0 d-flex flex-row align-items-center">
        <label *ngIf="paneWidth > 365" class="px-4 py-0 m-0" for="advswitch">{{ switchLabel }}</label>
        <div class="form-switch">
          <input
              id="advswitch"
              type="checkbox"
              class="form-check-input col-sm-2 col-xs-2 show"
              role="switch"
              [disabled]="snapInVm.detailProperty !== undefined"
              [checked]="snapInVm.showPropertyListExt"
              (change)="togglePropertyList()"
          >
      </div>
        <!-- <si-switch id="advswitch"
          [disabled]="snapInVm.detailProperty !== undefined"
          [on]="snapInVm.showPropertyListExt"
          (toggle)="togglePropertyList()">
        </si-switch> -->
      </div>
    </div>

  </div>

  <!-- Property container -->
  <div #propContainer class="flex-grow-1 px-5 pt-5" style="overflow-y: auto; overflow-x: hidden;">

    <ng-container *ngIf="isLoading; else propertyViewTemplate">
      <div class="d-flex flex-column justify-content-center h-100 pb-10">
        <si-loading-spinner *ngIf="isLoadingSpinnerEnabled" />
      </div>
    </ng-container>

    <ng-template #propertyViewTemplate>
      <si-empty-state *ngIf="isPropertyListEmpty" class="flex-grow-1" icon="element-technical-operator" [heading]="propertyListEmptyText" />

      <si-property-viewer *ngIf="!isPropertyListEmpty"
        [objectId]="snapInVm.siSelectionContext"
        [filter]="snapInVm.detailProperty ? snapInVm.detailProperty.objectFilter : snapInVm.propertyFilter" />
    </ng-template>

  </div>

</div>
