import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DisplayMode } from '../../interfaces/property';

@Component({
  selector: 'si-datapoint',
  templateUrl: './si-datapoint.component.html',
  styleUrls: ['./si-datapoint.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class SiDatapointComponent implements OnChanges {
  @Input() name?: string;
  @Input() description?: string;
  @Input() alias?: string;
  @Input() icon?: string;
  @Input() displayMode?: DisplayMode = 'name';

  singleRowView = true;

  ngOnChanges(changes: SimpleChanges): void {
    this.checkForSingleRowView();
  }

  checkForSingleRowView(): void {
    switch (this.displayMode) {
      case 'name':
      case 'description':
        this.singleRowView = true;
        break;
      case 'name-description':
      case 'description-name':
        this.singleRowView = !this.description || !this.name;
        break;
      case 'name-alias':
        this.singleRowView = !this.alias || !this.name;
        break;
      case 'description-alias':
        this.singleRowView = !this.alias || !this.description;
        break;
      default:
        break;
    }
  }
}
