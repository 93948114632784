import { ControlEditorMode, ParameterViewModelBase } from '../processor/command-view-model/gms-parameter-vm.base';
import { ParameterType } from '../processor/command/parameters/gms-base-parameter';
import { Evaluation, PropertyType } from '../processor/evaluation';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementCursorType, GmsElementType } from '../types/gms-element-types';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { GmsCommandControl } from './gms-commandcontrol';
import { GmsElement } from './gms-element';

export class GmsCommandControlSpinner extends GmsCommandControl {

  public get ChangeAmount(): number {
    return this._changeAmount;
  }
  public set ChangeAmount(value: number) {
    if (value !== this._changeAmount) {
      this._changeAmount = Number.isNaN(value) || !Number.isFinite(value) ?
        GmsCommandControlSpinner.DEFAULT_CHANGEAMOUNT : value;
      this._changeAmountPrecision = NaN;
    }
  }

  public ChangeAmountPrecision(maxPrecision: number): number {
    if (Number.isNaN(this._changeAmountPrecision)) {
      this._changeAmountPrecision = FormatHelper.calculatePrecision(maxPrecision, this.GetAnimatedChangeAmount());
    }
    return this._changeAmountPrecision;
  }

  public get UpdateDelay(): number {
    return this._updateDelay;
  }
  public set UpdateDelay(value: number) {
    if (Number.isNaN(value) || !Number.isFinite(value)) {
      value = GmsCommandControlSpinner.DEFAULT_UPDATEDELAY;
    }
    const val: number = value;
    if (val <= GmsCommandControlSpinner.DISABLE_DELAY) {
      value = GmsCommandControlSpinner.DISABLE_DELAY;
    } else if (val <= GmsCommandControlSpinner.IMMEDIATE) {
      value = GmsCommandControlSpinner.IMMEDIATE;
    }
    this._updateDelay = value;
  }

  public constructor() {
    super(GmsCommandControlType.Spinner);
    this.children = [];
  }

  // Commanding
  protected UpdateCommandCursor(): void {
    if (this.CommandVM != null) {
      this.CursorType = this.GetCommandEnabled() ?
        GmsElementCursorType.Hand : GmsElementCursorType.Default;
      if (!!this.children) {
        this.children.forEach((element: GmsElement) => {
          element.CursorType = this.CursorType;
        });
      }
    }
  }

  // Commanding
  protected UpdateEvaluation(evaluation: Evaluation): void {
    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);
    switch (evaluation.Property) {

      case 'UpdateDelay':
        this.UpdateCommandDelay(evaluation);
        break;

      case 'ChangeAmount':
        this.UpdateCommandChangeAmount(evaluation);
        break;

      default:
        break;
    }
  }

  protected UpdateCommandDelay(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationUpdateDelay = evaluation;
    }
  }

  protected UpdateCommandChangeAmount(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationChangeAmount = evaluation;
      // Spinner: Force to update Precision
      this._changeAmountPrecision = Number.NaN;
    }
  }

  protected GetAnimatedUpdateDelay(): number {
    const value: number = Evaluation.GetValue2(this._evaluationUpdateDelay, this.UpdateDelay, PropertyType.Number);
    return value;
  }

  protected GetAnimatedChangeAmount(): number {
    const value: number = Evaluation.GetValue2(this._evaluationChangeAmount, this.ChangeAmount, PropertyType.Number);
    return value;
  }

  protected IsUpButton(element: GmsElement): boolean {
    if (this.children.length > 1) {
      return (this.children[0] === element ||
                (this.children[0].hasChildren && this.children[0].children.includes(element)));
    }
    return false;
  }

  protected IsDownButton(element: GmsElement): boolean {
    if (this.children.length > 1) {
      return (this.children[1] === element ||
                (this.children[1].hasChildren && this.children[1].children.includes(element)));
    }
    return false;
  }

  private static readonly DEFAULT_CHANGEAMOUNT: number = 1.0;
  private static readonly DEFAULT_UPDATEDELAY: number = 500;
  private static readonly DISABLE_DELAY: number = -1;
  private static readonly IMMEDIATE: number = 0;
  private _evaluationUpdateDelay;
  private _evaluationChangeAmount;

  private _changeAmount: number = GmsCommandControlSpinner.DEFAULT_CHANGEAMOUNT;
  // Floating-Point Arithmetic: https://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html
  // So Spinner Value Precision needs to be calculated to avoid unexpected results on some numbers.
  private _changeAmountPrecision: number = Number.NaN;

  private _updateDelay: number = GmsCommandControlSpinner.DEFAULT_UPDATEDELAY;

  public static getSpinnerFromButton(element: GmsElement): GmsCommandControlSpinner {
    if (element instanceof GmsCommandControlSpinner) {
      return element;
    }

    let spinner: GmsCommandControlSpinner;
    while (element !== undefined && element.Parent !== undefined) {
      if (element.Parent.Type === GmsElementType.CommandControl) {
        spinner = element.Parent as GmsCommandControlSpinner;
        return spinner;
      }
      element = element.Parent;
    }
    return spinner;
  }

  public setControlEditorMode(mode: ControlEditorMode): void {
    if (this.ParameterVM !== null) {
      this.ParameterVM.setControlEditorMode(mode);
    }
  }

  public UpDownButton_Click(element: GmsElement): boolean {
    if (element === undefined || this.ParameterVM == null || this.CommandVM === null ||
            !this.CommandVM.IsCommandEnabled) {
      return false;
    }
    if (!this.CommandVM.isModified) {
      // convert from en-us -> to curent locale
      this.ParameterVM.UpdateFormattedValue();
      // const number = Number(this.ParameterVM.Value);
      // this.ParameterVM.FormattedValue = FormatHelper.NumberToString(number, this.locale, this.defaultLocale, this.ParameterVM.precision);//this.ParameterVM.Value;
    }
    this.CommandVM.isCommandExecuteDone = false;
    this.CommandVM.isCommandExecuteCancel = false;
    this.CommandVM.isModified = true;

    let valueString: string = this.ParameterVM.FormattedValue;
    //        const precision: number = Math.max(this.ChangeAmountPrecision, this.ParameterVM.precision);

    if (this.CommandVM.Parameters.has(this.ParameterVM.name)) {
      let maxPrecision = 0;
      const list: ParameterViewModelBase[] = this.CommandVM.Parameters.get(this.ParameterVM.name);
      list.forEach(parmVM => {
        if (parmVM.precision > maxPrecision) {
          maxPrecision = parmVM.precision;
        }
      });
      this.ParameterVM.precision = this.ChangeAmountPrecision(maxPrecision);
    }

    const precision: number = this.ParameterVM.precision;
    const delay: number = this.GetAnimatedUpdateDelay();
    const amount: number = this.GetAnimatedChangeAmount();
    if (this.isLongTypeParameter) {
      const minimumLong: Long = this.GetAnimatedMinimumLong();
      const maximumLong: Long = this.GetAnimatedMaximumLong();
      if (this.IsUpButton(element)) {
        valueString = this.ChangeValue(valueString, amount, true, minimumLong, maximumLong);
      } else if (this.IsDownButton(element)) {
        valueString = this.ChangeValue(valueString, amount, false, minimumLong, maximumLong);
      } else if (element.TraceService !== undefined) {
        // This is neither the Up or the Down button
        element.TraceService.warn(this.traceModuleCommand,
          'Click on element that is neither Up nor Down button of Spinner { 0}', element);

        return false;
      }
      this.ValueChanged(valueString, delay);
    } else {
      valueString = FormatHelper.Replace(valueString, FormatHelper.getGroupingSeparator(this.locale));
      let value: number = FormatHelper.getNumberfromString(valueString, this.locale);

      const minimum: number = this.GetAnimateMinimum();
      const maximum: number = this.GetAnimateMaximum();

      if (this.IsUpButton(element)) {
        value += amount;
      } else if (this.IsDownButton(element)) {
        value -= amount;
      } else if (element.TraceService !== undefined) {
        // This is neither the Up or the Down button
        element.TraceService.warn(this.traceModuleCommand,
          'Click on element that is neither Up nor Down button of Spinner { 0}', element);

        return false;
      }

      // Coerce current value to Min/Max
      value = Math.min(value, maximum);
      value = Math.max(value, minimum);

      const result: string = FormatHelper.NumberToString(value, this.locale, null, precision);
      this.ValueChanged(result, delay);
    }
    return true;
  }

  public AddChild(element: GmsElement): void {

    this.children.push(element);
    if (this.CommandVM !== null) {
      element.CommandVM = this.CommandVM;
    }
    // ChangeDetection: Let others know that a property changed
    this.NotifyPropertyChanged();
  }

  public Destroy(): void {

    this._evaluationChangeAmount = undefined;
    this._evaluationUpdateDelay = undefined;
    this.ParameterVM = null;

    super.Destroy();
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {

    // ChangeAmount
    let result: string = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.ChangeAmount);
    if (result !== undefined) {
      this.ChangeAmount = FormatHelper.StringToNumber(result);
    }
    // UpdateDelay
    result = SvgUtility.GetAttributeValue(node, GmsElementPropertyType.UpdateDelay);
    if (result !== undefined) {
      this.UpdateDelay = FormatHelper.StringToNumber(result);
    }

    super.Deserialize(node);

  }

  public IsSpinButton(): boolean {
    return false;
  }

  private ChangeValue(valueString: string, amount: number, plus: boolean, minimum: Long, maximum: Long): string {
    let result: string;
    if (this.ParameterVM.ParameterType === ParameterType.NumericUInt64) {
      let value = FormatHelper.parseLongValue(valueString, true, this.locale);
      value = plus ? value.greaterThan(FormatHelper.uint64Max.subtract(amount)) ? FormatHelper.uint64Max : value.add(amount) :
        value.lessThan(amount) ? FormatHelper.uint64Min : value.subtract(amount);
      result = FormatHelper.coerceLongValue(value, minimum, maximum, plus).toString();
    } else if (this.ParameterVM.ParameterType === ParameterType.NumericInt64) {
      let value = FormatHelper.parseLongValue(valueString, false, this.locale);
      value = plus ? value.greaterThan(FormatHelper.int64Max.subtract(amount)) ? FormatHelper.int64Max : value.add(amount) :
        value.lessThan(FormatHelper.int64Min.add(amount)) ? FormatHelper.int64Min : value.subtract(amount);
      result = FormatHelper.coerceLongValue(value, minimum, maximum, plus).toString();
    }
    return result;
  }
}
