import { Observable } from 'rxjs';

import { UserInfo } from '../authentication/data.model';
// import { UserInfo } from '@gms-flex/services-common';
// import { Credentials, UserInfo } from '../../authentication/data.model';
import { ChangePasswordModel, PasswordPolicyModel } from './data.model';

/**
 * Base class for the users service.
 * See WSI API documentation (Users Service) for details.
 */
export abstract class UsersServiceBase {

  /**
   * Change user password.
   * See WSI API specification for details.
   *
   * @abstract
   * @param { ChangePasswordModel } changePasswordModel
   * @returns { Observable<void> }
   *
   * @memberOf UsersServiceBase
   */
  public abstract changePassword(changePasswordModel: ChangePasswordModel): Observable<void>;

  /**
   * Retrieve the user account login information.
   *
   * @abstract
   * @param { string } userName
   * @returns { Observable<UserInfoDetail> }
   */
  public abstract getLoginInfo(userName: string): Observable<UserInfo>;

  /**
   * Returns if the account password can be changed.
   *
   * @abstract
   * @param { string } userName
   * @returns { Observable<boolean> }
   */
  public abstract allowPasswordChange(userName: string): Observable<boolean>;

  /**
   * Gets the password policy.
   *
   * @readonly
   * @abstract
   * @returns {Observable<PasswordPolicyModel>}
   * @memberOf UsersServiceBase
   */
  public abstract getPasswordPolicy(): Observable<PasswordPolicyModel>;
}
