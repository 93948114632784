import { GmsAdornerType } from '../types/gms-adorner-types';
import { GmsElementType } from '../types/gms-element-types';
import { GmsElement } from './gms-element';

export class GmsAdorner extends GmsElement {
  private static readonly selectableColor: string = '#FF0000'; // red
  private static readonly commandableColor: string = '#75FF38'; // green
  private static readonly selectedColor: string = '#EF6926'; // orange
  private static readonly errorColor: string = '#000000'; // black

  private _sourceElement: GmsElement = undefined;
  public get SourceElement(): GmsElement {
    return this._sourceElement;
  }
  public set SourceElement(value: GmsElement) {
    if (this._sourceElement !== value) {
      this._sourceElement = value;
      this.NotifyPropertyChanged('SourceElement');
    }
  }

  private _adornerType: GmsAdornerType = undefined;
  public get AdornerType(): GmsAdornerType {
    return this._adornerType;
  }
  public set AdornerType(value: GmsAdornerType) {
    if (this._adornerType !== value) {
      this._adornerType = value;
      this.NotifyPropertyChanged('AdornerType');
    }
  }

  public NotifyAdornerChanged(): void {
    this.NotifyPropertyChanged('SourceElement');
  }

  constructor(adornerType: GmsAdornerType, sourceElement: GmsElement) {
    super(GmsElementType.Adorner);

    this.StrokeWidth = 1;
    this.StrokeDashArray = (adornerType === GmsAdornerType.Hover) ? '2 2' : '';
    this.AdornerType = adornerType;

    this.Stroke = this.getStrokeColor(sourceElement);
    this.SourceElement = sourceElement;
  }

  public Destroy(): void {
    this._sourceElement = null;
  }

  private getStrokeColor(sourceElement: GmsElement): string {
    let strokeColor: string;
    if (sourceElement.IsCommandable() || sourceElement.IsTargetNavigable) {
      strokeColor = GmsAdorner.commandableColor;
    } else if (sourceElement.IsSelectable) {
      if (sourceElement.IsSelected) {
        strokeColor = GmsAdorner.selectedColor;
      } else {
        strokeColor = GmsAdorner.selectableColor;
      }
    } else {
      if (!!sourceElement.TraceService) {
        sourceElement.TraceService.error(this.traceModule, `Attempting to set stroke color on adorner that is not commandable or selectable`);
      }
      strokeColor = GmsAdorner.errorColor;
    }
    return strokeColor;
  }
}
