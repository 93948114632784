import { Injectable, OnInit } from '@angular/core';
import { SystemInfo, SystemsServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable()
export class GmsSystemsService {

  public isResolved: BehaviorSubject<boolean>;
  public isError = false;

  public allSystemInfos: SystemInfo[];
  private _subscription: Subscription;
  private readonly traceModule: string = 'gmsSnapins_GmsSystemsService';

  public constructor(private readonly systemsService: SystemsServiceBase, private readonly traceService: TraceService) {
    traceService.info(this.traceModule, 'GmsSystemsService created.');
    this.isResolved = new BehaviorSubject<boolean>(false);
    this.allSystemInfos = undefined;
  }

  public unsubscribe(): void {
    if (this._subscription !== undefined) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }

  public getSystems(): void {

    if (!this.isResolved.getValue()) {
      this._subscription = this.systemsService.getSystems().subscribe(systems => this.onReadSystems(systems),
        error => this.onReadSystemsError(error));
    }
  }

  public getSystemIdentifier(systemName: string): string {

    let systemId: string;
    const systems: SystemInfo[] = this.allSystemInfos;
    if (systemName !== '') {
      const system: SystemInfo = systems.find(x => x.Name.toLowerCase() === systemName.toLowerCase());
      if (system !== undefined) {
        systemId = String(system.Id);
      }
    }
    return systemId;
  }

  private onReadSystems(systems: SystemInfo[]): void {
    this.unsubscribe();
    this.allSystemInfos = systems;

    this.isError = false;
    this.isResolved.next(true);
  }

  private onReadSystemsError(error: Error): void {

    this.traceService.error(this.traceModule, 'onReadSystemsError(): error: %s', error.message);

    this.unsubscribe();
    this.allSystemInfos = undefined;
    this.isError = true;
    this.isResolved.next(false);
  }

}
