@if (viewType !== 'mobile' && primaryActions?.length) {
  <div siAutoCollapsableList class="d-flex" [siAutoCollapsableListContainerElement]="parentElement">
    @if (viewType === 'collapsible' && !expanded && collapsibleListItem.canBeVisible) {
      <button
        #expandElement
        si-content-action-bar-toggle
        type="button"
        icon="element-options-vertical"
        [attr.aria-label]="toggleItemLabel | translate"
        (click)="expand()"
      ></button>
    }
    <si-menu-bar
      #collapsibleListItem="siAutoCollapsableListItem"
      siAutoCollapsableListItem
      [forceHide]="!expanded"
      [disabled]="disabled"
    >
      @for (primaryAction of primaryActions; track primaryAction) {
        @if (isNewItemStyle(primaryAction)) {
          @switch (primaryAction.type) {
            @case ('action') {
              <button
                type="button"
                si-menu-item
                [attr.data-id]="primaryAction.id"
                [badge]="primaryAction.badge"
                [badgeColor]="primaryAction.badgeColor ?? 'secondary'"
                [disabled]="primaryAction.disabled"
                [icon]="primaryAction.icon"
                [iconBadgeDot]="primaryAction.iconBadge"
                (triggered)="runAction(primaryAction)"
                >{{ primaryAction.label }}
              </button>
            }
            @case ('router-link') {
              <a
                si-menu-item
                [attr.data-id]="primaryAction.id"
                [badge]="primaryAction.badge"
                [badgeColor]="primaryAction.badgeColor ?? 'secondary'"
                [disabled]="primaryAction.disabled"
                [icon]="primaryAction.icon"
                [iconBadgeDot]="primaryAction.iconBadge"
                [routerLink]="primaryAction.routerLink"
                [queryParams]="primaryAction.extras?.queryParams"
                [queryParamsHandling]="primaryAction.extras?.queryParamsHandling"
                [fragment]="primaryAction.extras?.fragment"
                [state]="primaryAction.extras?.state"
                [relativeTo]="primaryAction.extras?.relativeTo"
                [preserveFragment]="primaryAction.extras?.preserveFragment"
                [skipLocationChange]="primaryAction.extras?.skipLocationChange"
                [replaceUrl]="primaryAction.extras?.replaceUrl"
                >{{ primaryAction.label }}
              </a>
            }
            @case ('link') {
              <a
                si-menu-item
                [attr.data-id]="primaryAction.id"
                [badge]="primaryAction.badge"
                [badgeColor]="primaryAction.badgeColor ?? 'secondary'"
                [disabled]="primaryAction.disabled"
                [icon]="primaryAction.icon"
                [iconBadgeDot]="primaryAction.iconBadge"
                [href]="primaryAction.href"
                [target]="primaryAction.target"
                >{{ primaryAction.label }}
              </a>
            }
          }
        } @else {
          @if (primaryAction.items) {
            <a
              si-menu-item
              activeClass="active"
              [attr.data-id]="primaryAction.id"
              [siLink]="primaryAction"
              [icon]="primaryAction.icon"
              [attr.aria-label]="primaryAction.title | translate"
              [badge]="primaryAction.badge"
              [actionParam]="actionParam"
              [iconBadgeDot]="primaryAction.badgeDot"
              [disabled]="primaryAction.disabled || disabled"
              [cdkMenuTriggerFor]="primaryAction.items ? mainItemSubmenu : null"
            >
              @if (!primaryAction.iconOnly && primaryAction.title) {
                <span>{{ primaryAction.title | translate }}</span>
              }
              <!-- Cannot use template context because of https://github.com/angular/components/issues/26256 -->
              <ng-template #mainItemSubmenu>
                <si-menu-factory [items]="primaryAction.items" />
              </ng-template>
            </a>
          }
          @if (!primaryAction.items) {
            <a
              si-menu-item
              activeClass="active"
              [attr.data-id]="primaryAction.id"
              [siLink]="primaryAction"
              [icon]="primaryAction.icon"
              [attr.aria-label]="primaryAction.title | translate"
              [badge]="primaryAction.badge"
              [actionParam]="actionParam"
              [iconBadgeDot]="primaryAction.badgeDot"
              [disabled]="primaryAction.disabled || disabled"
            >
              @if (!primaryAction.iconOnly && primaryAction.title) {
                <span>{{ primaryAction.title | translate }}</span>
              }
            </a>
          }
        }
      }
      @if (secondaryActions?.length) {
        <button
          type="button"
          si-content-action-bar-toggle
          cdkMenuItem
          icon="element-options-vertical"
          [attr.aria-label]="toggleItemLabel | translate"
          [cdkMenuTriggerFor]="secondaryActionsMenu"
          [disabled]="disabled"
        ></button>
      }
      @if (viewType === 'collapsible') {
        <button
          type="button"
          si-content-action-bar-toggle
          icon="element-cancel"
          cdkMenuItem
          [attr.aria-label]="toggleItemLabel | translate"
          [disabled]="disabled"
          (cdkMenuItemTriggered)="collapse()"
        >
        </button>
      }
    </si-menu-bar>
    <button
      si-content-action-bar-toggle
      icon="element-options-vertical"
      siAutoCollapsableListOverflowItem
      type="button"
      [cdkMenuTriggerFor]="mobile"
      [disabled]="disabled"
      [attr.aria-label]="toggleItemLabel | translate"
    >
    </button>
  </div>
}

@if (viewType === 'mobile' || !primaryActions?.length) {
  <button
    si-content-action-bar-toggle
    icon="element-options-vertical"
    type="button"
    [cdkMenuTriggerFor]="mobile"
    [disabled]="disabled"
    [attr.aria-label]="toggleItemLabel | translate"
  >
  </button>
}

<ng-template #secondaryActionsMenu>
  <si-menu-factory [items]="secondaryActionsInternal" [actionParam]="actionParam" />
</ng-template>

<ng-template #mobile>
  <si-menu-factory [items]="mobileActions" [actionParam]="actionParam" />
</ng-template>
