import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  signal,
  Signal,
  ViewChild
} from '@angular/core';
import { SiAutocompleteDirective, SiAutocompleteModule } from '@simpl/element-ng/autocomplete';
import { SiLoadingSpinnerComponent } from '@simpl/element-ng/loading-spinner';
import { SiTranslateModule, TranslatableString } from '@simpl/element-ng/translate';

import { SiSelectOptionRowComponent } from '../select-option/si-select-option-row.component';
import { SiSelectGroupTemplateDirective } from '../si-select-group-template.directive';
import { SiSelectOptionRowTemplateDirective } from '../si-select-option-row-template.directive';
import { SiSelectListBase } from './si-select-list.base';

@Component({
  selector: 'si-select-list-has-filter',
  templateUrl: './si-select-list-has-filter.component.html',
  styleUrl: './si-select-list-has-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    SiAutocompleteDirective,
    SiSelectGroupTemplateDirective,
    SiSelectOptionRowComponent,
    SiSelectOptionRowTemplateDirective,
    SiTranslateModule,
    SiAutocompleteModule,
    SiLoadingSpinnerComponent
  ],
  host: { class: 'pt-0' }
})
export class SiSelectListHasFilterComponent<T> extends SiSelectListBase<T> implements OnInit {
  /** Placeholder for search input field. */
  @Input({ required: true }) filterPlaceholder!: TranslatableString;
  /** Label if no item can be found. */
  @Input({ required: true }) noResultsFoundLabel!: TranslatableString;

  @ViewChild('filterInput') protected filterInput?: ElementRef<HTMLInputElement>;

  protected initIndex: Signal<number>;

  @HostBinding('id')
  protected get id(): string {
    return `${this.baseId}-listbox`;
  }

  constructor() {
    super();
    if (!this.selectOptions.onFilter) {
      console.error('Missing implementation for `onFilter`');
    }
    const firstValue = this.selectionStrategy.arrayValue()[0];
    if (firstValue) {
      this.initIndex = computed(() =>
        this.rows().findIndex(row => row.type === 'option' && row.value === firstValue)
      );
    } else {
      this.initIndex = signal(0);
    }
  }

  ngOnInit(): void {
    this.selectOptions.onFilter!();
    setTimeout(() => this.filterInput!.nativeElement.focus());
  }

  protected input(): void {
    this.selectOptions.onFilter!(this.filterInput!.nativeElement.value);
  }

  protected select(newValue: T): void {
    if (this.selectionStrategy.allowMultiple) {
      if (this.selectionStrategy.arrayValue().includes(newValue)) {
        this.selectionStrategy.updateFromUser(
          this.selectionStrategy.arrayValue().filter(value => value !== newValue)
        );
      } else {
        this.selectionStrategy.updateFromUser([...this.selectionStrategy.arrayValue(), newValue]);
      }
    } else {
      this.selectionStrategy.updateFromUser([newValue]);
    }
    this.closeOverlayIfSingle();
  }
}
