import { Injectable } from "@angular/core";
import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointValue } from "src/app/bx-services/point/point-proxy.model";

import { DccDataType, PropertyMapper } from "./property-mapper";
import { TextGroupBxService, textGroupIdStatusFlags } from "./text-group-bx.service";

// do not change this name, it is the BacNet name for this property and used in property viewer as well!
export const bacStatusPropertyName = 'Status_Flags';

@Injectable({
  providedIn: 'root'
})
export class BacStatusFlagsMapperService extends PropertyMapper {

  constructor(private readonly textGroupOmService: TextGroupBxService, qudtPipe: SiqudtUnitConversionPipe) {
    super(qudtPipe);
  }

  public createBacStatus(order: number): PropertyDetails {
    const txgStatusFlags = this.textGroupOmService.getGlobalTextGroup(textGroupIdStatusFlags);
    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: bacStatusPropertyName,
      Descriptor: 'Status Flags',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: undefined,
      Type: DccDataType.ExtendedBitString,
      BitStringLabels: [
        txgStatusFlags.enum['0'].label,
        txgStatusFlags.enum['1'].label,
        txgStatusFlags.enum['2'].label,
        txgStatusFlags.enum['3'].label
      ],
      Value: undefined,
      Min: '0',
      Max: '3',
      UnitDescriptor: undefined,
      UnitId: undefined,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      DisplayOffNormalOnly: true,
      NormalValue: '0',
      TextTable: textGroupIdStatusFlags,
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return propDetails;
  }

  public createBacStatusValue(subGms: SubscriptionGmsVal, pointValue: PointValue, hide: boolean = false): ValueDetails | undefined {
    // TODO: finalize quality bits
    // let displayValue = '#NaN';

    let displayValue = '#NaN';
    let rawValue = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        if (pointValue?.attributes?.bac_status) {
          rawValue = this.calculateFlagsValue(pointValue.attributes.bac_status).toString();
          displayValue = this.createFlagsDisplayValue(pointValue.attributes.bac_status);
        } else {
          // there is no bac prio property
          return undefined;
        }
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: DccDataType.BasicUint,
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        // the bit positions which are set (e.g. value=6 => bit0=false, bit1=true, bit2=true, bit3=false)
        Value: rawValue,
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0',
        IsPropertyAbsent: hide
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private calculateFlagsValue(flags: number[]): number {
    return flags.reverse().reduce((acc, value) => {
      return (acc << 1) | value;
    });
  }

  private createFlagsDisplayValue(flags: number[]): string {
    const txgStatusFlags = this.textGroupOmService.getGlobalTextGroup(textGroupIdStatusFlags);
    const flagsDisplay: string[] = [];
    flags.reverse().forEach((flag, index) => {
      if (flag !== 0) {
        flagsDisplay.push(txgStatusFlags.enum[index.toString()].label);
      }
    });
    return JSON.stringify(flagsDisplay);
  }
}
