import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { catchError, map, Observable, of } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";
import { environment } from "src/environments/environment";

import { HttpUtilityService } from "../shared/http-utility.service";
import { PointDefaultDigestionsPV, PointHistoryRequest, PointHistoryResponsePV } from "./point-history-proxy.model";

const pointUrlV2 = `${environment.bxPlatform.pointVerticalApiUrl}/v2/organizations`;
@Injectable({
  providedIn: 'root'
})
export class PointHistoryProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService
  ) {
    this.traceService.info(TraceModules.bxServicesPointHistory, "PointHistoryProxyService service created.");
  }

  // Retrieves the configured digestion strategy for a specific point
  public getRegisteredDigestionsForPoint(pointId: string, partitionId: string): Observable<PointDefaultDigestionsPV> {
    this.traceService.debug(TraceModules.bxServicesPoints, `PointHistoryProxyService.getRegisteredDigestionsForPoint() called: 
      partitionId: ${partitionId}, pointId: ${pointId}`
    );

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrlV2}/${partitionId}/points/${pointId}/digestions`;

    this.traceService.info(TraceModules.bxServicesPoints, `Getting point default digestions '${pointId}' 
      (partitionId = '${partitionId}'). Request url: PointHistoryProxyService.getRegisteredDigestionsForPoint(), ${url}`
    );

    return this.httpClient.get<PointDefaultDigestionsPV>(url, { headers: headers, observe: 'response' }).pipe(
      map(response => response.body), // Extract the body from the response
      catchError(error => {
        this.httpUtilityService.handleError(error.error, "PointHistoryProxyService.getRegisteredDigestionsForPoint()");
        return of(null); // Return an observable of null in case of error
      })
    );
  }
  // Retrieves point OnDemand Digested data for default digestions for specified range of date
  public getOnDemandDigestedPointHistory(request: PointHistoryRequest, pageCursor: string): Observable<PointHistoryResponsePV> {
    let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    headers = headers.append('PaginationCursor', pageCursor);
    let params: HttpParams = new HttpParams();
    params = params.set("startDateTime", request.startDate.toISOString());
    params = params.set("endDateTime", request.endDate.toISOString());

    const url = `${pointUrlV2}/${request.partitionId}/points/${request.pointId}/digest/${request.strategy}/precision/${request.precision}`;

    this.traceService.info(TraceModules.bxServicesPoints, `Getting point default digestions '${request.pointId}' 
      (partitionId = '${request.partitionId}'). Request url: PointHistoryProxyService.getOnDemandDigestedPointHistory(), ${url}`
    );

    return this.httpClient.get<PointHistoryResponsePV>(url, { headers: headers, params: params, observe: 'response' }).pipe(
      map(response => response.body), // Extract the body from the response
      catchError(error => {
        this.httpUtilityService.handleError(error.response.data, "PointHistoryProxyService.getOnDemandDigestedPointHistory()");
        return of(null); // Return an observable of null in case of error
      })
    );
  }

  // Retrieves Predigested point data for default digestions for specified range of date and combination of strategy + precision
  public getPredigestedPointHistory(request: PointHistoryRequest, pageCursor: string): Observable<PointHistoryResponsePV> {
    let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    headers = headers.append('PaginationCursor', pageCursor);
    let params: HttpParams = new HttpParams();
    params = params.set("startDateTime", request.startDate.toISOString());
    params = params.set("endDateTime", request.endDate.toISOString());
    const url = `${pointUrlV2}/${request.partitionId}/points/${request.pointId}/digests/${request.strategy}+${request.precision}`;

    this.traceService.info(TraceModules.bxServicesPoints, `Getting point default digestions '${request.pointId}' 
      (partitionId = '${request.partitionId}'). Request url: PointHistoryProxyService.getPredigestedPointHistory(), ${url}`
    );

    return this.httpClient.get<PointHistoryResponsePV>(url, { headers: headers, params: params, observe: 'response' }).pipe(
      map(response => response.body), // Extract the body from the response
      catchError(error => {
        this.httpUtilityService.handleError(error.response.data, "PointHistoryProxyService.getPredigestedPointHistory()");
        return of(null); // Return an observable of null in case of error
      })
    );
  }

  // Retrieves Raw point data ( all covs ) for specified range of date
  public getRawPointHistory(request: PointHistoryRequest, pageCursor: string): Observable<PointHistoryResponsePV> {
    let headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    headers = headers.append('PaginationCursor', pageCursor);
    let params: HttpParams = new HttpParams();
    params = params.set("startDateTime", request.startDate.toISOString());
    params = params.set("endDateTime", request.endDate.toISOString());
    const url = `${pointUrlV2}/${request.partitionId}/points/${request.pointId}/covs`;

    this.traceService.info(TraceModules.bxServicesPoints, `Getting point row digestions for point - '${request.pointId}' ,
      partitionId = '${request.partitionId}'). Request url: PointHistoryProxyService.GetRawPointHistory(), ${url}`
    );

    return this.httpClient.get<PointHistoryResponsePV>(url, { headers: headers, params: params, observe: 'response' }).pipe(
      map(response => response.body), // Extract the body from the response
      catchError(error => {
        this.httpUtilityService.handleError(error.response.data, "PointHistoryProxyService.GetRawPointHistory()");
        return of(null); // Return an observable of null in case of error
      })
    );
  }
}
