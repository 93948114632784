<div class="modal-header">
    <h4 class="modal-title pull-left" id="dialog-static-name">{{currentErrorTitle}}</h4>
</div>
<div class="modal-body" style="display: flex; align-items: center; min-height: auto;">
    <div class="row hfw-flex-container-row hfw-flex-item-grow">
        <div class="col-sm-2">
            <div>
                <figure class="icon" style="width:60px; height:60px; font-size:60px;">
                    <div class="element-warning"></div>
                </figure>
            </div>
        </div>
        <div class="col-sm-10" style="display: flex;align-items:center;">
            {{currentErrorMessage}}
        </div>
    </div>
</div>
