import { AfterViewInit, ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsEllipse } from '../elements/gms-ellipse';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsEllipseType } from '../types/gms-ellipse-types';
import { Guid } from '../utilities/guid';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-ellipse]',
  template: `
        <svg:g
                (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                (mouseleave)="OnMouseLeave($event)"
                [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                [attr.transform]="element.GetTransformations()" [attr.clip-path]="element.ClipPathUrl"
                [attr.filter]="element.Filter?.Url"
                [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()" id="{{element.Id}}">
            <defs *ngIf="element.HasClipInformation">
                <clipPath [attr.id]="element.ClipPathId">
                    <path [attr.d]="element.GetClipPathData()"/>
                </clipPath>
            </defs>
            <svg:rect *ngIf="element.Background !== 'transparent'"
                      [ngClass]="element.IsHitTestVisible ? 'allptrevents': 'noptrevents'"
                      [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                      [attr.width]="element.Width"
                      [attr.height]="element.Height"
                      [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity"
                      stroke-opacity="0" />
            <svg:rect *ngIf="element.EllipseType === ellipseType.Ellipse"
                      [ngClass]="'noptrevents'"
                      [attr.width]="element.Width"
                      [attr.height]="element.Height"
            />
            <svg:ellipse
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    #ellipse
                    *ngIf="element.EllipseType === ellipseType.Ellipse"
                    [attr.rx]="element.RadiusX"
                    [attr.ry]="element.RadiusY"
                    [attr.fill]="element.Fill"
                    [attr.fill-opacity]="element.FillOpacity"
                    [attr.stroke-opacity]="element.StrokeOpacity"
                    [attr.stroke]="element.Stroke"
                    [attr.stroke-dasharray]="element.StrokeDashArray"
                    [attr.stroke-width]="element.StrokeWidth" [attr.transform]="element.Offset"
                    [attr.stroke-linecap]="element.StrokeLineCap" />
            <svg:path
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    *ngIf="element.EllipseType === ellipseType.Arc || element.EllipseType === ellipseType.Pie"
                    [attr.d]="element.PathData"
                    [attr.fill]="element.Fill"
                    [attr.fill-opacity]="element.FillOpacity"
                    [attr.stroke]="element.Stroke"
                    [attr.stroke-dasharray]="element.StrokeDashArray"
                    [attr.stroke-opacity]="element.StrokeOpacity"
                    [attr.stroke-width]="element.StrokeWidth" [attr.fill-rule]="evenodd"
                    [attr.stroke-linecap]="element.StrokeLineCap" />
            <svg:rect *ngIf="element.ShowErrorBorder"
                      class="noptrevents"
                      [attr.width]="element.Width"
                      [attr.height]="element.Height"
                      fill="url(#pattern-error-comm)"
                      stroke-width="2" stroke="#5A5D60" />
            <!--<title *ngIf="element.Tooltip !== undefined">{{element.Tooltip}}</title>-->
        </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmsEllipseComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsEllipse = null;
  public ellipseType: any = GmsEllipseType; // Store a reference to the enum, so that we can compare in the template
  public evenodd = true;
}
