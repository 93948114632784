import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AnyEnumValue } from '@simpl/element-value-types';

import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-enum-property',
  templateUrl: './si-enum-property.component.html',
  standalone: true,
  imports: [FormsModule, SiPropertyPopoverComponent]
})
export class SiEnumPropertyComponent {
  @Input({ required: true }) property!: Property<AnyEnumValue>;
  @Input() valueState: ValueState = 'none';
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<AnyEnumValue>>();

  @ViewChild('popover', { static: true }) popover!: SiPropertyPopoverComponent;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  protected valueChange(): void {
    if (this.popover.overrideMode === 'direct') {
      this.popover.submit();
    }
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }

  get activeValue(): string | number | boolean {
    if (this.property.value.value != null) {
      return (
        this.property.value.options?.find(option => option.value === this.property.value.value)
          ?.text ?? this.property.value.value
      );
    }
    return '';
  }
}
