import { GraphicInfo } from '@gms-flex/services';
import { BehaviorSubject } from 'rxjs';

/**
 * Container for graphic items related to a System Browser object
 */
export class GraphicRelatedItems {
  public currentGraphicItem: BehaviorSubject<GraphicInfo> = new BehaviorSubject<GraphicInfo>(undefined);
  public singleRelatedItem = true;

  private _relatedItemsArr: GraphicInfo[];
  private _relatedItemsIdx: number;

  constructor(relatedItems: GraphicInfo[] = [], relatedItemIdx: number = 0) {
    this.initRelatedItems(relatedItems, relatedItemIdx);
  }

  /**
   * Array of graphic items related to a Browser Object
   */

  protected set relatedItemsArr(value: GraphicInfo[]) {
    this._relatedItemsArr = value;
  }
  protected get relatedItemsArr(): GraphicInfo[] {
    return this._relatedItemsArr;
  }

  public get hasRelatedItems(): boolean {
    return this._relatedItemsArr?.length > 0;
  }

  /**
   * Index of current graphic item
   * @throws {RangeError} Will throw an error if idx is out of range
   */
  public set relatedItemsIdx(idx: number) {
    if (idx < this.relatedItemsArr.length && idx >= 0) {
      this._relatedItemsIdx = idx;
      this.currentGraphicItem.next(this.relatedItemsArr[idx]);
    } else {
      throw new RangeError(`Index of ${idx} invalid for Array of graphic items of length ${this.relatedItemsArr.length}`);
    }
  }
  public get relatedItemsIdx(): number {
    return this._relatedItemsIdx;
  }

  /**
   * Initialize graphic items array and current index
   * @param relatedItems
   */
  public initRelatedItems(relatedItems: GraphicInfo[], currentIndex?: number): void {
    this.relatedItemsArr = relatedItems;
    const numRelatedItems: number = relatedItems?.length;

    if (numRelatedItems > 0) {
      if (currentIndex !== undefined) {
        this.relatedItemsIdx = currentIndex;
      } else {
        this.relatedItemsIdx = 0;
      }
    }
    if (numRelatedItems === 1) {
      this.singleRelatedItem = true;
    } else {
      this.singleRelatedItem = false;
    }
  }

  /**
   * Loop forward by 1 through related items arr
   */
  public increment(): void {
    // NOTE: log Empty array
    if (this.relatedItemsArr.length === 0) {
      return;
    }
    this.relatedItemsIdx = (this.relatedItemsIdx + 1) % this.relatedItemsArr.length;
  }

  /**
   * Loop Backward by 1 through related items arr
   */
  public decrement(): void {
    // NOTE: log Empty array
    if (this.relatedItemsArr.length === 0) {
      return;
    }
    if (this.relatedItemsIdx === 0) {
      this.relatedItemsIdx = this.relatedItemsArr.length - 1;
    } else {
      this.relatedItemsIdx--;
    }
  }
}
