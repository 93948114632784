import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlarmsContainer } from '../common/interfaces/AlarmsContainer';
import { GmsAdorner } from '../elements/gms-adorner';
import { Replication } from '../processor/replication/replication';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsAdornerType } from '../types/gms-adorner-types';
import { GmsCommandControlType } from '../types/gms-commandcontrol-types';
import { GmsElementReplicationOrientationType, GmsElementType } from '../types/gms-element-types';
import { GmsAdornerComponent } from './gms-adorner.component';
import { GmsAlarmsContainerComponent } from './gms-alarms-container.component';
import { GmsAnimatedGifComponent } from './gms-animated-gif.component';
import { GmsEllipseComponent } from './gms-ellipse.component';
import { GmsImageComponent } from './gms-image.component';
import { GmsLineComponent } from './gms-line.component';
import { GmsPathComponent } from './gms-path.component';
import { GmsPolygonComponent } from './gms-polygon.component';
import { GmsRectangleComponent } from './gms-rectangle.component';
import { GmsTextComponent } from './gms-text.component';
import { GmsXpsComponent } from './gms-xps.component';

@Component({
  selector: '[gms-replication]',
  template: `<svg:g [ngClass]="'allptrevents'"
                    [attr.transform]="element.GetTransformations()">
                    <svg:foreignObject x="0" y="0" [attr.width]="element.ViewPortWidth" [attr.height]="element.ViewPortHeight">
                        <xhtml:div #ReplicationContainer
                        [ngClass]="getContainerClass()">
                            <svg [attr.width]="element.Width" [attr.height]="element.Height">
                                <svg:g *ngFor="let item of element.children;">
                                    <!-- List all possible components that can be in a replication group -->                                    
                                    <svg:g *ngIf="item.Type === elementType.SymbolInstance" gms-symbol-instance [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Group" gms-group [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Ellipse" gms-ellipse [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Rectangle" gms-rectangle [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Path" gms-path [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Line" gms-line [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Polygon" gms-polygon [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Text" gms-text [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Image" gms-image [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Xps" gms-xps [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.AnimatedGif" gms-animated-gif [element]="item"/>
                                    <svg:g *ngIf="item.ControlType === commandControlType.Numeric" gms-numeric [element]="item"/>
                                    <svg:g *ngIf="item.Type === elementType.Replication" gms-replication [element]="item.Replication"/>
                                </svg:g>
                                <svg:g class="noptrevents" *ngFor="let adornerItem of element.adorners;trackBy:trackAdornerItem">
                                    <g *ngIf="adornerItem.AdornerType === adornerType.Selection" gms-adorner [adorner]="adornerItem"
                                    class="noptrevents" />
                                    <g *ngIf="adornerItem.AdornerType === adornerType.Hover" gms-adorner [adorner]="adornerItem"
                                    class="noptrevents" />
                                </svg:g>
                                <svg:g gms-alarms-container [alarmsContainer]="alarmsContainer" />
                            </svg>
                        </xhtml:div>
                    </svg:foreignObject>
               </svg:g>  
               `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [GmsRectangleComponent, GmsEllipseComponent, GmsPathComponent, GmsLineComponent, GmsPolygonComponent
    , GmsTextComponent, GmsImageComponent, GmsXpsComponent, GmsAnimatedGifComponent, GmsAdornerComponent, GmsAlarmsContainerComponent],
  providers: [GmsAdornerService], // Adorner Service specific to Replication Component.
  styles: [`.allptrevents{pointer-events:all}`, `.noptrevents{pointer-events:none}`
    , `.IPad{width:100%;height:100%;background-color:transparent;text-align:initial;user-select:none;outline:none;}`
    , `.ReplicationContainer{width:100%;height:100%;overflow:auto;background-color:transparent;text-align:initial;user-select:none;outline:none;}`
    , '.Horizontal{overflow-y:Hidden}'
    , '.Vertical{overflow-x:Hidden}'
  ] })
export class GmsReplicationComponent implements OnInit, OnDestroy {
  @Input() public element: Replication = null;
  @ViewChild('ReplicationContainer', { static: false }) public replicationContainer: ElementRef;
  public elementType: any = GmsElementType; // Store a reference to the enum, so that we can compare in the template
  public adornerType: any = GmsAdornerType;
  public commandControlType: any = GmsCommandControlType;
  public adornersChangedSubscription: Subscription = undefined;
  public propertyChangedSubscription: Subscription = undefined;
  public get alarmsContainer(): AlarmsContainer {
    return this.element as AlarmsContainer;
  }

  constructor(public changeDetector: ChangeDetectorRef, public adornerService: GmsAdornerService) {
  }

  @HostListener('touchmove', ['$event'])
  public OnTouchMove(event: any): void {
    if (event.touches.length === 1) {
      event.stopPropagation();
    }
  }

  public trackAdornerItem(index: number, item: GmsAdorner): string {
    return item.Id;
  }

  public getContainerClass(): string {
    const containerClass: string = this.element.Orientation === GmsElementReplicationOrientationType.Horizontal ? 'Horizontal' : 'Vertical';

    if (navigator.userAgent !== undefined || navigator.userAgent !== null) {
      const isIPad: boolean = navigator.userAgent.includes('iPad');
      if (isIPad) {
        return containerClass + ' IPad';
      }
    }

    return containerClass + ' ReplicationContainer';
  }

  public ngOnInit(): void {
    this.SubscribePropertyChanged(this.element);
    this.adornerService.setAdornerService(this.element.adorners);
    this.adornersChangedSubscription = this.adornerService.adornersChanged.subscribe(() => {
      if (this.element.adorners !== undefined && this.element.adorners.length > 0) {
        const firstSelectionAdorner: GmsAdorner = this.element.adorners
          .find(adorner => adorner.AdornerType === GmsAdornerType.Selection);

        // If the selection is auto, a datapoint selection in the system browser
        // Move the replication clone to the view of the user.
        if (firstSelectionAdorner !== undefined && firstSelectionAdorner.SourceElement.AutoSelectionInProgress) {
          if (this.element.Width > this.element.ViewPortWidth || this.element.Height > this.element.ViewPortHeight) {
            if (this.replicationContainer !== undefined && this.replicationContainer.nativeElement !== undefined) {
              if (this.element.Orientation === GmsElementReplicationOrientationType.Horizontal) {
                const offset: number = firstSelectionAdorner.SourceElement.X - 10;
                this.replicationContainer.nativeElement.scrollLeft = offset < 0 ? 0 : offset;
              } else {
                const offset: number = firstSelectionAdorner.SourceElement.Y - 10;
                this.replicationContainer.nativeElement.scrollTop = offset < 0 ? 0 : offset;
              }
            }
          }
        }
      }

      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.UnSubscribePropertyChanged(this.element);
    if (this.adornersChangedSubscription !== undefined) {
      this.adornersChangedSubscription.unsubscribe();
    }
    this.adornerService.ClearAdorners();
  }

  private SubscribePropertyChanged(element: Replication): void {
    // subscribe to the property changes of the element
    this.propertyChangedSubscription = element.propertyChanged.subscribe(value => {
      this.element_PropertyChanged(value);
    });
  }

  private UnSubscribePropertyChanged(element: Replication): void {
    // subscribe to the property changes of the element
    if (this.propertyChangedSubscription !== undefined) {
      this.propertyChangedSubscription.unsubscribe();
      this.propertyChangedSubscription = undefined;
    }
  }

  private element_PropertyChanged(propertyName: string): void {

    // Let Angular know that a property has changed
    this.changeDetector.detectChanges();
  }
}
