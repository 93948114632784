import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FullSnapInId } from '../fullsnapinid/fullsnapinid.model';
import { SnapInActions } from './snapin-actions.model';

/**
 * The interface exposed by the SnapIn Actions Service in order to save and return snapin actions
 */
@Injectable({
  providedIn: 'root'
})
export abstract class ISnapInActions {

  /**
   * This method allows HFW to read the actions from a specific snapIn.
   *
   * @param {FullSnapInId} fullId, The full id identifying the snapIn to retrieve the actions.
   * @returns {Observable<SnapInActions>} An observable of type SnapInActions formed by prrimaryActions, secondaryActions and viewType
   *
   * @memberOf ISnapInActions
   *
   */
  public abstract getSnapInActions(fullId: FullSnapInId): Observable<SnapInActions>;

  /**
   * This method sets the actions for a specific snapIn.
   *
   * @param {FullSnapInId} fullId, The full id identifying the snapIn in which the actions are set.
   * @param {SnapInActions} actions, The actions exposed by the snapin.
   *
   * @memberOf ISnapInActions
   *
   */
  public abstract setSnapInActions(fullId: FullSnapInId, actions: SnapInActions): void;
}
