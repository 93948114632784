import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';

import { SiHeaderDropdownTriggerDirective } from './si-header-dropdown-trigger.directive';
import { SI_HEADER_WITH_DROPDOWNS } from './si-header.model';

/**
 * Creates a dropdown-item. Must be used within an {@link SiHeaderDropdownComponent}.
 *
 * si-navbar-dropdown-item selector is deprecated and will be removed in v46.
 */
@Component({
  selector:
    'si-header-dropdown-item, a[si-header-dropdown-item], button[si-header-dropdown-item], si-navbar-dropdown-item, a[si-navbar-dropdown-item], button[si-navbar-dropdown-item]',
  standalone: true,
  imports: [NgClass],
  templateUrl: './si-header-dropdown-item.component.html',
  styleUrl: './si-header-dropdown-item.component.scss',
  host: {
    class: 'dropdown-item focus-inside'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiHeaderDropdownItemComponent {
  /** Optional icon that will be rendered before the label. */
  @Input() icon?: string;
  /** Badge that is rendered after the label. */
  @Input() badge?: string | number;
  /** Badge (always red) that is attached to the icon. */
  @Input() iconBadge?: string | number;
  /** Color of the badge (not iconBadge). */
  @Input() badgeColor?: string;
  /** Whether the icon is checked with a radio or check mark. */
  @Input() checked?: 'radio' | 'check' | '';

  protected ownTrigger = inject(SiHeaderDropdownTriggerDirective, { self: true, optional: true });
  protected parentTrigger = inject(SiHeaderDropdownTriggerDirective, { skipSelf: true });
  protected navbar = inject(SI_HEADER_WITH_DROPDOWNS, { optional: true });

  @HostListener('click')
  protected click(): void {
    if (!this.ownTrigger) {
      this.parentTrigger.close({ all: true });
      if (this.navbar?.onDropdownItemTriggered) {
        this.navbar?.onDropdownItemTriggered();
      }
    }
  }
}
