import { Injectable, OnDestroy } from '@angular/core';
import { TreeItem } from '@simpl/element-ng';
import { Subject } from 'rxjs';

@Injectable()
export class SelectionChangedService implements OnDestroy {
  public onSelectionChanged: Subject<TreeItem>;

  constructor() {
    this.onSelectionChanged = new Subject<TreeItem>();
  }

  public ngOnDestroy(): void {
    this.onSelectionChanged.unsubscribe();
  }
}
