import { Injectable } from "@angular/core";

import { FullSnapInId, IStorageService } from "@gms-flex/core";
import { TraceService } from "@gms-flex/services-common";
import { traceModule } from "./graphics-preselect.service";

@Injectable()
export class GraphicsStorageService implements IStorageService {

  public typeId: string = "GraphicsViewerType";
  public stateMap: Map<string, any> = new Map();

  constructor(private traceService: TraceService) {
    this.traceService.info(traceModule, "GraphicsStorageService created");
  }

  public getState(fullId: any): any {
    let state: any = undefined;
    let snapInId: string = "";

    if (fullId != undefined) {
      snapInId = fullId.snapInId;
    } else {
      return state;
    }

    if (this.stateMap.has(snapInId)) {
      state = this.stateMap.get(snapInId);
    }

    return state;
  }

  public setState(fullId: any, state: any): void {
    let snapInId: string = "";

    if (fullId != undefined) {
      snapInId = fullId.snapInId;
    } else {
      return;
    }

    this.stateMap.set(snapInId, state);
  }

  public clearState(fullId: any): void {
    let snapInId: string = "";

    if (fullId != undefined)  {
      snapInId = fullId.snapInId;
    } else {
      return;
    }

    if (this.stateMap.has(snapInId)) {
      this.stateMap.delete(snapInId);
    }
  }

  public getDirtyState(fullId: FullSnapInId): boolean {
    return;
  }

  public setDirtyState(fullId: FullSnapInId, state: boolean): void {
    //
  }
}
