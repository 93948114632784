// entry in the list on the right
export interface DateRangePreset {
  label: string;
  offset: number; // milliseconds
}

export interface DateRangeFilter {
  point1: Date | 'now';
  point2: number | Date;
  range?: 'before' | 'after' | 'within';
}

export interface ResolvedDateRange {
  start: Date;
  end: Date;
  valid: boolean;
}

export const ONE_MINUTE = 60 * 1000;
export const ONE_DAY = ONE_MINUTE * 60 * 24;
