import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { areAnimationsDisabled } from '@simpl/element-ng/common';
import { Observable } from 'rxjs';

import { SiToastNotificationComponent } from '../si-toast-notification/si-toast-notification.component';
import { SiToast } from '../si-toast.model';

@Component({
  selector: 'si-toast-notification-drawer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './si-toast-notification-drawer.component.html',
  styleUrl: './si-toast-notification-drawer.component.scss',
  animations: [
    trigger('toastTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(
          '500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          style({ opacity: 1, transform: 'translateY(0%)' })
        )
      ]),
      transition(':leave', [
        style({ 'opacity': 1, 'inset-inline-end': '0%' }),
        animate('300ms', style({ 'opacity': 0, 'inset-inline-end': '-100%' }))
      ])
    ])
  ],
  imports: [AsyncPipe, SiToastNotificationComponent],
  standalone: true
})
export class SiToastNotificationDrawerComponent {
  @Input() toasts?: Observable<SiToast[]>;

  protected animationsDisabled = areAnimationsDisabled();

  protected trackByItem = (index: number, item: SiToast): any => item;
}
