export enum AlertDialogResult {
  Confirm = 0
}

/**
 * @deprecated use {@link EditDiscardDialogResult} instead.
 */
export enum EditAbortDialogResult {
  Save = 0,
  // eslint-disable-next-line detect-bad-words/in-code
  Abort = 1,
  Cancel = 2
}

export enum EditDiscardDialogResult {
  Save = 0,
  Discard = 1,
  Cancel = 2
}

export enum ConfirmationDialogResult {
  Confirm = 0,
  Decline = 1
}

export enum DeleteConfirmationDialogResult {
  Delete = 0,
  Cancel = 1
}
