import { AfterViewInit, ChangeDetectorRef, Component, inject } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { SiTabComponent, SiTabsetComponent } from '@simpl/element-ng/tabs';

@Component({
  selector: 'si-formly-object-tabset',
  templateUrl: './si-formly-object-tabset.component.html',
  standalone: true,
  imports: [SiTabsetComponent, SiTabComponent, FormlyModule]
})
export class SiFormlyObjectTabsetComponent extends FieldType implements AfterViewInit {
  protected selectedTabIndex = 0;
  private readonly cdRef = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    if (this.options?.formState?.hasOwnProperty('selectedTabIndex')) {
      this.selectedTabIndex = this.options.formState.selectedTabIndex;
      this.cdRef.detectChanges(); // ngAfterViewInit is not in the change detection cycle
    }
  }
}
