import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { map, Observable, of } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { FolderType } from '../shared/base.model';

export interface SystemFolder {
  id: string;
  label: string;
  parentId: string;
  folderType: FolderType;
}

export const trendsFolderEntityId = 'TrendsRootFolder';
export const graphicsFolderEntityId = 'GraphicsRootFolder';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  public constructor(
    private readonly traceService: TraceService) {

    this.traceService.info(TraceModules.bxServicesFolders, 'FolderService created.');
  }

  public getRootFolder(parentId: string, folderType: FolderType): Observable<SystemFolder> {
    if (folderType === FolderType.Trend) {
      return of({ label: 'Trends Folder', id: trendsFolderEntityId, parentId, folderType: FolderType.Trend });
    } else if (folderType === FolderType.Graphic) {
      return of({ label: 'Graphics Folder', id: graphicsFolderEntityId, parentId, folderType: FolderType.Graphic });
    } else {
      this.traceService.error(TraceModules.bxServicesFolders, 'Unhandled parameter');
    }
  }

  public createFolder(parentId: string, folderName: string, folderType: FolderType): Observable<SystemFolder> {
    const newFolder: SystemFolder = { label: folderName, id: crypto.randomUUID(), parentId, folderType };
    return this.getFolders(parentId).pipe(
      map(folders => {
        if ((folders !== undefined) && (folders.length > 0)) {
          folders.push(newFolder);
          localStorage.setItem(parentId, JSON.stringify(folders));
        } else {
          localStorage.setItem(parentId, JSON.stringify([newFolder]));
        }
        return newFolder;
      })
    );
  }

  public getFolders(parentId): Observable<SystemFolder[]> {
    const folders = localStorage.getItem(parentId);
    if (folders !== undefined) {
      return of(JSON.parse(folders));
    } else {
      return of([]);
    }
  }

}
