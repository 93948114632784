import { Observable } from 'rxjs';

import { FullSnapInId } from '../fullsnapinid/fullsnapinid.model';

/**
 * The interface to receive information about current selection from snapins.
 */
export abstract class IObjectSelection {

  /**
   * This method allows HFW to get selection info of the snap-in in current pane.
   *
   * @param {FullSnapInId} fullSnapInId, The full snapin id identifying the snap-in.
   * @returns {Observable<any>} An any type observable which completes with the result of the message
   * formed by the icon, title of selected object displayed on the snapin.
   *
   * @memberOf IObjectSelection
   *
   */
  public abstract getSelectedObject(fullSnapInId: FullSnapInId): Observable<any>;

  /**
   * This method sets header information for the snapin.
   *
   * @param {FullSnapInId} fullSnapInId, The full snap-in id identifying the snap-in.
   * @param {any} msg, The message body formed by the icon, title and cns label.
   *
   * @memberOf IObjectSelection
   *
   */
  public abstract setSelectedObject(fullSnapInId: FullSnapInId, msg: any): void;

  /**
   * This method allows to get selection info of the right panel for specified frame.
   *
   * @param {string} frameId, The frame id.
   * @returns {Observable<any>} An any type observable which completes with the result of the message
   * formed by the icon, title of selected object displayed on the snapin.
   *
   * @memberOf IObjectSelection
   *
   */
  public abstract getRightPanelSelectedObject(frameId: string): Observable<any>;

  /**
   * This method sets header information for the snapin.
   *
   * @param {string} frameId, The frame Id.
   * @param {any} msg, The message body formed by the icon, title and cns label.
   *
   * @memberOf IObjectSelection
   *
   */
  public abstract setRightPanelSelectedObject(frameId: string, msg: any): void;
}
