import { Injectable } from '@angular/core';
import { BorderTimeRange, DpIdentifier, SearchOption, SystemBrowserServiceBase,
  TraceModules, TrendDataResult, TrendQualityValue, TrendSeriesInfo, TrendServiceBase, TrendViewDefinition, TrendViewDefinitionUpdate,
  ValidationInput } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asyncScheduler, map, Observable, observeOn, throwError } from 'rxjs';
import { PointHistoryService } from 'src/app/bx-services/point/point-history.service';

import { PointService } from '../../bx-services/point/point.service';
import { EntityType } from '../../bx-services/shared/base.model';
import { TrendProxyService } from '../../bx-services/trend/trend.proxy.service';
import { pointPropertyName } from '../properties/point-value-mapper.service';

@Injectable()
export class TrendBxSubstituteService extends TrendServiceBase {

  public constructor(
    private readonly traceService: TraceService,
    private readonly systemBrowser: SystemBrowserServiceBase,
    private readonly pointService: PointService,
    private readonly pointHistoryService: PointHistoryService,
    private readonly trendProxyService: TrendProxyService) {
    super();
    this.traceService.debug(TraceModules.trends, 'TrendBxSubstituteService created.');
  }

  public getBorderTimeRangeForTrend(trendSeriesId: string): Observable<BorderTimeRange> | any {
    this.traceService.info(TraceModules.trends, 'TrendBxSubstituteService.getTrendData() called, trendSeriesId: %s', trendSeriesId);

    const dpid = new DpIdentifier(trendSeriesId);
    const pointId = dpid.objectIdWoSystem;
    const partitionId = dpid.systemName;
    return this.pointService.getPointById(partitionId, pointId).pipe(
      map(pointResponse => {
        const dateNow = new Date();
        dateNow.setUTCMilliseconds(0);
        const dateFrom = new Date(pointResponse.attributes.createdAt);
        dateFrom.setUTCMilliseconds(0);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const borders: BorderTimeRange = { From: dateFrom.toISOString(), To: dateNow.toISOString() };
        return borders;
      }));
  }

  public getTrendSeriesInfo(objectOrPropertyId: string): Observable<TrendSeriesInfo[]> | any {
    this.traceService.info(TraceModules.trends, 'TrendBxSubstituteService.getTrendSeriesInfo() called, objectOrPropertyId: %s', objectOrPropertyId);

    return this.systemBrowser.searchNodes(1, objectOrPropertyId, undefined, SearchOption.objectId).pipe(
      map(nodes => {
        if ((nodes?.Nodes.length > 0) && (nodes.Nodes[0].Attributes.TypeDescriptor === EntityType.Point)) {
          /* eslint-disable @typescript-eslint/naming-convention */
          const tsInfo: TrendSeriesInfo = {
            ObjectId: objectOrPropertyId,
            PropertyIndex: '1',
            PropertyName: pointPropertyName,
            CollectorObjectOrPropertyId: `${objectOrPropertyId}.${pointPropertyName}`,
            TrendseriesId: `${objectOrPropertyId}.${pointPropertyName}`,
            TrendType: 'TLO',
            TrendedPropertyIdentifier: undefined
          };
          /* eslint-enable @typescript-eslint/naming-convention */
          return [tsInfo];
        } else {
          return [undefined];
        }
      }),
      observeOn(asyncScheduler)
    );
  }

  public getTrendSeriesId(objectOrPropertyId: string, collectorId: string): Observable<string[]> | any {
    this.traceService.info(TraceModules.trends,
      'TrendBxSubstituteService.getTrendSeriesId() called, object Id: %s, collector Id: %s', objectOrPropertyId, collectorId);

    return throwError(() => new Error('TrendBxSubstituteService.getTrendSeriesId(): Not Implemented!'));
  }

  public getTrendViewDefinition(objectId: string): Observable<TrendViewDefinition> | any {
    this.traceService.info(TraceModules.trends, 'TrendBxSubstituteService.getTrendViewDefinition() called, trendViewDefinition DpId: %s', objectId);
    return this.trendProxyService.getTrend(new DpIdentifier(objectId).objectIdWoSystem);
  }

  public getTrendData(trendSeriesId: string, fromDate: string, toDate: string, interval: string, zoomRange: number): Observable<TrendDataResult> {

    const dpid = new DpIdentifier(trendSeriesId);
    const pointId = dpid.objectIdWoSystem;
    const partitionId = dpid.systemName;
    return this.pointHistoryService.getPointHistory(partitionId, pointId, fromDate, toDate, zoomRange).pipe(
      map(pointResponse => {
        const tqvSeries: TrendQualityValue[] = [];
        pointResponse.data.forEach(pointVal => {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          tqvSeries.push({ Value: this.handleValue(pointVal.value), Timestamp: pointVal.createdAt, Quality: '0', QualityGood: 'true' });
        });
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        const trendData: TrendDataResult = { Id: trendSeriesId, SeriesPropertyId: trendSeriesId, Series: tqvSeries };
        return trendData;
      })
    );
  }

  public getStates(textGroupName: string, systemId: any, unitIndex: any): Observable<any> {
    return throwError(() => new Error('TrendBxSubstituteService.getStates(): Not Implemented!'));
  }

  public putTrendViewDefinition(tvdUpdate: TrendViewDefinitionUpdate): Observable<TrendViewDefinition> | any {
    return throwError(() => new Error('TrendBxSubstituteService.putTrendViewDefinition(): Not Implemented!'));
    // const parentDesignation = tvdUpdate.Designation;

    // return this.systemBrowser.searchNodes(1, parentDesignation, undefined, SearchOption.designation).pipe( // TODO: Handle system id properly
    //   concatMap(parent => {
    //     if (parent.Nodes?.length === 1) {
    //       const parentNode = parent.Nodes[0];
    //       const parentEntityId = new DpIdentifier(parentNode.ObjectId).objectIdWoSystem;
    //       return this.trendProxyService.saveTrend(tvdUpdate, parentEntityId).pipe(
    //         map(tvd => {
    //           const browserNode = this.systemBrowserMapper.mapTvdToBrowserObject(
    //             tvd,
    //             this.systemBrowserMapper.getView(parentNode.SystemId, parentNode.ViewId),
    //             parentDesignation,
    //             parentNode.Location,
    //             parentEntityId,
    //             this.systemBrowserMapper.getPartitionId(parentEntityId)
    //           );
    //           (this.systemBrowserSubscriptionService as SystemBrowserSubscriptionBxSubstituteProxyService).notifyAddedNode(browserNode);
    //           return tvd;
    //         })
    //       );
    //     } else {
    //       return of(undefined);
    //     }
    //   })
    // );
  }

  public deleteTrendViewDefinition(objectId: string, validationInput: ValidationInput): Observable<boolean> {
    this.traceService.info(TraceModules.trends, 'TrendBxSubstituteService.deleteTrendViewDefinition() called');

    return throwError(() => new Error('TrendBxSubstituteService.deleteTrendViewDefinition(): Not Implemented!'));
  }

  public deleteOnlineTrendLog(objectId: string, validationInput: ValidationInput): Observable<boolean> {
    this.traceService.info(TraceModules.trends, 'TrendBxSubstituteService.deleteOnlineTrendLog() called');

    return throwError(() => new Error('TrendBxSubstituteService.deleteOnlineTrendLog(): Not Implemented!'));
  }

  private handleValue(pointVal: any): any {
    // DCC cannot handle 'true' and 'false' for boolean values. -> changed to 0 and 1
    // This workaround must be fixed properly
    if ((typeof pointVal === 'string') && (pointVal.toLowerCase() === 'true')) {
      return '1';
    } else if ((typeof pointVal === 'string') && (pointVal.toLowerCase() === 'false')) {
      return '0';
    } else {
      return pointVal;
    }
  }
}
