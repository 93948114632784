import { Injectable } from '@angular/core';
import { Command, CommandParameters, DpIdentifier, EnumerationItem, PropertyCommand, SubscriptionGmsCmd, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';

import { PointAttributes, PointCommandingSemantic, PointSourceType } from '../../bx-services/point/point-proxy.model';
import { pointPropertyName } from '../properties/point-value-mapper.service';
import { DccDataType, PropertyMapper } from '../properties/property-mapper';

// do not change this name, it is used in property viewer as well!
export const cbmsWritePointValueCommandName = 'cbmsWritePointValue';
// do not change this name, it is used in property viewer as well!
export const cbmsReleasePointValueCommandName = 'cbmsReleasePointValue';

@Injectable({
  providedIn: 'root'
})
export class CommandMapperBxToGmsService {

  constructor(private readonly traceService: TraceService) {
  }

  public createPropertyCommand(propertyId: string, pointAttributes: PointAttributes, subscription?: SubscriptionGmsCmd): PropertyCommand {
    /* eslint-disable @typescript-eslint/naming-convention */
    const propCmd: PropertyCommand = {
      PropertyId: propertyId,
      ErrorCode: 0,
      SubscriptionKey: (subscription !== undefined) ? subscription.key : null,
      Commands: this.createCommands(propertyId, pointAttributes)
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return propCmd;
  }

  private createCommands(propertyId: string, pointAttributes: PointAttributes): Command[] {
    const dpId: DpIdentifier = new DpIdentifier(propertyId);
    if (this.propertySupportsCommands(dpId.propertName) && this.pointSupportsCommands(pointAttributes)) {
      const cmds: Command[] = [];
      /* eslint-disable @typescript-eslint/naming-convention */
      let cmd: Command = {
        Id: cbmsWritePointValueCommandName, // ID must be unique within the command!
        PropertyId: propertyId,
        Configuration: 0, // Not used, means no validation
        Descriptor: 'Write value',
        IsDefault: true,
        Parameters: this.createParameters(pointAttributes)
      };
      cmds.push(cmd);
      if (this.pointIsCisAndCommandable(pointAttributes)) {
        cmd = {
          Id: cbmsReleasePointValueCommandName, // ID must be unique within the command!
          PropertyId: propertyId,
          Configuration: 0, // Not used, means no validation
          Descriptor: 'Release value',
          IsDefault: false,
          Parameters: []
        };
        cmds.push(cmd);
      }
      /* eslint-enable @typescript-eslint/naming-convention */
      return cmds;
    } else {
      return [];
    }
  }

  private createParameters(pointAttributes: PointAttributes): CommandParameters[] {
    /* eslint-disable @typescript-eslint/naming-convention */
    const param: CommandParameters = {
      Name: 'Value',
      Descriptor: 'Value',
      DataType: PropertyMapper.getDccType(pointAttributes),
      DefaultValue: '', // possibly we will take the current runtime value?
      Order: 0,
      Min: pointAttributes.minimum,
      Max: pointAttributes.maximum,
      Application: 0, // 0: Normal use; 1: parameter is an index
      ParameterType: 0,
      EnumerationTexts: this.createEnums(pointAttributes)
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return [param];
  }

  private createEnums(pointAttributes: PointAttributes): EnumerationItem[] {
    if (pointAttributes.enum !== undefined) {
      const enumKeys = Object.keys(pointAttributes.enum);
      return enumKeys.map(key => {
        const keyDcc = this.handleKey(pointAttributes, key);
        if (keyDcc !== undefined) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const enumItem: EnumerationItem = { Value: keyDcc, Descriptor: pointAttributes.enum[key].label };
          return enumItem;
        }
      });
    } else {
      return [];
    }
  }

  private handleKey(pointAttributes: PointAttributes, enumKey: string): number | undefined {
    // TODO: FlexClient must be enhanced to handle enum keys of type: string, number, boolean and real!!!!

    const dataType = PropertyMapper.getDccType(pointAttributes);
    if ((dataType === DccDataType.BasicBool) || (dataType === DccDataType.ExtendedBool)) {
      if ((enumKey.toLowerCase() === 'true') || (enumKey === '1')) {
        return 1;
      } else if ((enumKey.toLowerCase() === 'false') || (enumKey === '0')) {
        return 0;
      } else {
        this.traceService.error(TraceModules.command, `Invalid enumeration key for boolean value; enumKey=${enumKey}`);
        return undefined;
      }
    } else if ((dataType === DccDataType.ExtendedInt) || (dataType === DccDataType.ExtendedEnum)) {
      const key = parseInt(enumKey, 10);
      if (Number.isNaN(key)) {
        this.traceService.error(TraceModules.command, `Invalid enumeration key for integer value; enumKey=${enumKey}`);
        return undefined;
      } else {
        return key;
      }
    } else {
      this.traceService.error(TraceModules.command, `Invalid enumeration key for unhandle value type; enumKey=${enumKey}`);
      return undefined;
    }
  }

  private propertySupportsCommands(propertyName: string): boolean {
    return (propertyName === pointPropertyName);
  }

  private pointSupportsCommands(pointAttributes: PointAttributes): boolean {
    return (((pointAttributes.source.type === PointSourceType.PointNB) || (pointAttributes.source.type === PointSourceType.PointCIS)) &&
      (pointAttributes.commandingSemantic === PointCommandingSemantic.Commandable ||
      pointAttributes.commandingSemantic === PointCommandingSemantic.Writeable));
  }

  private pointIsCisAndCommandable(pointAttributes: PointAttributes): boolean {
    return ((pointAttributes.source.type === PointSourceType.PointCIS) &&
      (pointAttributes.commandingSemantic === PointCommandingSemantic.Commandable));
  }

}
