export enum GmsElementType {
  Graphic,
  Layer,
  Group,
  SymbolInstance,
  Text,
  Rectangle,
  Ellipse,
  Path,
  Line,
  Polygon,
  Image,
  AnimatedGif,
  Xps,
  Adorner,
  Buttons,
  CommandControl,
  Replication,
  Pipe
}

export enum PositionType {
  Relative,
  Absolute
}

export enum GmsElementCursorType {
  Default,
  Hand
}

export enum GmsElementDisabledStyle {
  Grayed,
  None,
  Hidden
}
export enum GmsElementReplicationOrientationType {
  Horizontal,
  Vertical
}
