/* eslint-disable no-console, no-restricted-syntax*/
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from './app-settings.model';

/**
 * Application settings service.
 */
@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  // AppConfigService will not trace using TraceService since it is one of its dependencies.
  // hfwServicesCommon_AppConfig

  private readonly foo!: AppSettings;

  private readonly appSettings: BehaviorSubject<AppSettings>;

  private hasBeenRead = false;

  public constructor(protected httpClient: HttpClient,
    @Inject('appSettingFilePath') private readonly appSettingsFilePath: string) {
    this.appSettings = new BehaviorSubject<AppSettings>(this.foo);
    console.info('hfwServicesCommon_AppSettings: AppSettingsService created. appSettingsFilePath: %s', this.appSettingsFilePath);
  }

  public getAppSettings(): Observable<AppSettings> {
    if (this.hasBeenRead === true) {
      return this.appSettings.asObservable();
    } else {
      return this.httpClient.get(this.appSettingsFilePath, { observe: 'response' }).pipe(
        map((response: HttpResponse<any>) => this.extractData(response)),
        catchError((response: HttpResponse<any>) => this.handleError(response)));
    }
  }

  public getAppSettingsValue(): AppSettings {
    return this.appSettings.getValue();
  }

  private extractData(res: HttpResponse<any>): AppSettings {
    this.hasBeenRead = true;
    const body: any = res.body;
    this.logSettings(res.body);
    this.appSettings.next(res.body);
    return body;
  }

  private handleError(error: HttpResponse<any> | any): Observable<any> {
    try {
      console.error('hfwServicesCommon_AppSettings: handleError(): Reading endpoint settings file failed: %s', error.toString());
      console.info('hfwServicesCommon_AppSettings: defaults values will be used.');
      const settings: AppSettings = this.getDefaults();
      this.appSettings.next(settings);
      return observableOf(settings);
    } catch (exc) {
      console.error('hfwServicesCommon_AppSettings: Exception caught: %s', (exc as Error).message.toString());
      const endpointError = 'Endpoint file reading reply error! See trace.';
      return observableThrowError(endpointError);
    }
  }

  private logSettings(settings: AppSettings): void {
    if (settings !== null) {
      console.info('hfwServicesCommon_AppSettings: settings read.');
      console.info('hfwServicesCommon_AppSettings: minimalNotificationUpdateRate set to %s ms', settings.minimalNotificationUpdateRate);
      console.info('hfwServicesCommon_AppSettings: preselectionTimeout set to %s ms', settings.preselectionTimeout);
      console.info('hfwServicesCommon_AppSettings: instrumentationTimeout set to %s s', settings.instrumentationTimeout);
      console.info('hfwServicesCommon_AppSettings: traceEnabled set to %s', settings.traceEnabled);
      console.info('hfwServicesCommon_AppSettings: systemConfiguration set to %s', settings.systemConfiguration);
      console.info('hfwServicesCommon_AppSettings: resizeThrottleTime set to %ms', settings.resizeThrottleTime);
    }
  }

  private getDefaults(): AppSettings {
    return {
      minimalNotificationUpdateRate: 200,
      preselectionTimeout: 4000,
      instrumentationTimeout: 30,
      traceEnabled: false,
      systemConfiguration: false,
      resizeThrottleTime: 100
    };
  }
}
