import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiNumberInputComponent } from '@simpl/element-ng/number-input';
import {
  SelectOption,
  SiSelectComponent,
  SiSelectSimpleOptionsDirective,
  SiSelectSingleValueDirective
} from '@simpl/element-ng/select';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiReadonlyThresholdOptionComponent } from './si-readonly-threshold-option.component';

/**
 * One step in a list of thresholds
 */
export interface ThresholdStep {
  /** Threshold value, the first step has no value */
  value?: number;
  /** One of the `SelectOption.id` */
  optionValue: string;
  /** When set to `false`, input fields are highlighted as invalid */
  valid?: boolean;
}

@Component({
  selector: 'si-threshold',
  templateUrl: './si-threshold.component.html',
  styleUrl: './si-threshold.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    NgTemplateOutlet,
    SiNumberInputComponent,
    SiSelectComponent,
    SiSelectSingleValueDirective,
    SiSelectSimpleOptionsDirective,
    SiTranslateModule,
    SiReadonlyThresholdOptionComponent
  ]
})
export class SiThresholdComponent implements OnChanges {
  /** Options to be shown in select dropdown */
  @Input() options: SelectOption[] = [];
  /** The thresholds */
  @Input() thresholdSteps: ThresholdStep[] = [];
  /** The unit to show */
  @Input() unit = '';
  /** The min. value for the threshold value */
  @Input() minValue = 0;
  /** The max. value for the threshold value */
  @Input() maxValue = 100;
  /** The step size for the threshold value */
  @Input() stepSize = 1;
  /** Max. number of steps, 0 for no hard limit */
  @Input() maxSteps = 0;
  /** Do validation? */
  @Input({ transform: booleanAttribute }) validation = true;
  /** When disabled, steps cannot be added/removed */
  @HostBinding('class.add-remove') @Input({ transform: booleanAttribute }) canAddRemoveSteps = true;
  /** Use horizontal layout? */
  @HostBinding('class.horizontal') @Input({ transform: booleanAttribute }) horizontalLayout = false;
  /** Show dec/inc buttons? */
  @HostBinding('class.dec-inc-buttons') @Input({ transform: booleanAttribute }) showDecIncButtons =
    true;
  /** The obvious */
  @Input({ transform: booleanAttribute }) readonly = false;
  /** Indicate that the threshold options are readonly and cannot be changed. This will also disable adding / removing steps. */
  @Input({ transform: booleanAttribute }) readonlyConditions = false;
  /** The aria-label for delete button */
  @Input() deleteAriaLabel = $localize`:@@SI_THRESHOLD.DELETE:Delete step`;
  /** The aria-label for add button */
  @Input() addAriaLabel = $localize`:@@SI_THRESHOLD.ADD:Add step`;
  /** The aria-label for input field */
  @Input() inputAriaLabel = $localize`:@@SI_THRESHOLD.INPUT_LABEL:Threshold value`;
  /** The aria-label for status selection */
  @Input() statusAriaLabel = $localize`:@@SI_THRESHOLD.STATUS:Status`;

  /** Fired when the thresholds have changed */
  @Output() readonly thresholdStepsChange = new EventEmitter<ThresholdStep[]>();
  /** Fired when validation status changes */
  @Output() readonly validChange = new EventEmitter<boolean>();

  protected readonly trackByItem = (index: number, item: ThresholdStep): any => item;
  protected colors: string[] = [];

  private _valid = true;
  /**
   * Whether the current input value is valid or not.
   */
  get valid(): boolean {
    return this._valid;
  }
  private element = inject(ElementRef);

  ngOnChanges(): void {
    this.calcColor();
    this.validate();
  }

  protected deleteStep(index: number): void {
    this.thresholdSteps.splice(index, 1);
    this.optionChanged();
  }

  protected addStep(index: number): void {
    const newStep: ThresholdStep = { ...this.thresholdSteps[index], value: undefined };
    this.thresholdSteps.splice(index + 1, 0, newStep);
    this.optionChanged();
    setTimeout(
      () => this.element.nativeElement.querySelectorAll('input.form-control')[index]?.focus()
    );
  }

  protected optionChanged(): void {
    this.calcColor();
    this.emitChange();
  }

  protected emitChange(): void {
    this.validate();
    this.thresholdStepsChange.emit(this.thresholdSteps);
  }

  private calcColor(): void {
    const colorMap = new Map<string, string>();
    for (const opt of this.options) {
      colorMap.set(opt.id, opt.color ?? '');
    }
    this.colors = this.thresholdSteps.map(ths => colorMap.get(ths.optionValue) ?? '');
  }

  private validate(): void {
    const prevValid = this.valid;
    this._valid = true;
    for (let i = 1; i < this.thresholdSteps.length; i++) {
      const step = this.thresholdSteps[i];

      if (this.validation) {
        const prev = this.thresholdSteps[i - 1];
        const next = this.thresholdSteps[i + 1];

        // valid: withing min/max, each step is lower than next step with step size between
        step.valid =
          step.value != null &&
          step.value >= this.minValue &&
          step.value <= this.maxValue &&
          (prev.value == null || step.value - this.stepSize >= prev.value) &&
          (next?.value == null || step.value + this.stepSize <= next.value);
        this._valid &&= step.valid;
      } else {
        step.valid = true;
      }
    }
    if (this.valid !== prevValid) {
      this.validChange.emit(this.valid);
    }
  }
}
