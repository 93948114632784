import { Component, Input } from '@angular/core';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { SiLoadingSpinnerComponent } from '@simpl/element-ng/loading-spinner';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { ResultDetailStep, ResultDetailStepState } from './si-result-details-list.datamodel';

@Component({
  selector: 'si-result-details-list',
  templateUrl: './si-result-details-list.component.html',
  styleUrl: './si-result-details-list.component.scss',
  standalone: true,
  imports: [SiLoadingSpinnerComponent, SiIconComponent, SiTranslateModule]
})
export class SiResultDetailsListComponent {
  /**
   * Array of steps to show in the list.
   */
  @Input() steps: ResultDetailStep[] = [];

  protected readonly resultDetailStepState = ResultDetailStepState;
}
