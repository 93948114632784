import { GmsPolygonElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { GmsPath } from './gms-path';

export class GmsPolygon extends GmsPath {

  private _nodes: number = Number.NaN;
  public get Nodes(): number {
    return this._nodes;
  }
  public set Nodes(value: number) {

    if (this._nodes !== value) {
      this._nodes = value;

      this.NotifyPropertyChanged('Nodes');
    }
  }

  private _offset = 0;
  public get Offset(): number {
    return this._offset;
  }
  public set Offset(value: number) {

    if (this._offset !== value) {
      this._offset = value;
      // NOTE:
      // Calculate and Update Path Geometry
      // this.NotifyPropertyChanged("Data");
      this.NotifyPropertyChanged('Offset');
    }
  }

  constructor() {
    super(GmsElementType.Polygon);
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  /**
   * Iterate attributes and assign values to properties
   * @param node
   */
  public Deserialize(node: Node): void {

    // Offset
    // NOTE: Offset is not exported to SVG yet.
    const result: string = SvgUtility.GetAttributeValue(node, GmsPolygonElementPropertyType.Offset);
    if (result !== undefined) {
      this.Offset = FormatHelper.StringToNumber(result); // Number(result);
    }
    super.Deserialize(node);
  }

  public CopyFrom(element: GmsPolygon): void {
    this.IsCopying = true;

    this._offset = element.Offset;

    super.CopyFrom(element);

    this.IsCopying = false;
  }
}
