import { Injectable } from '@angular/core';
import { ConnectionState, HubProxyShared, TraceModules, UserAccount, UserRolesServiceProxyBase, WsiUserRolesRes } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject, throwError } from 'rxjs';

@Injectable()
export class UserRolesBxSubstituteProxyService implements UserRolesServiceProxyBase {

  // TODO: Not needed! Shall be removed from the base class!!!
  public hubProxyShared: HubProxyShared;
  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly userRolesNotificationWSI: Subject<void> = new Subject<void>();

  constructor(private readonly traceService: TraceService) {
    this.traceService.info(TraceModules.roles, 'UserRolesBxSubstituteProxyService created.');
    asapScheduler.schedule(() => {
      // No real connection state is delivered.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);
  }

  public userRolesNotification(): Observable<void> {
    return this.userRolesNotificationWSI.asObservable();
  }

  public getUserRoles(): Observable<WsiUserRolesRes> {
    this.traceService.info(TraceModules.roles, 'UserRolesBxSubstituteProxyService.getUserRoles() called.');

    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    return scheduled([{ RolesMapping: [] }], asapScheduler);
  }

  public updateUserRoles(userRolesInfo: WsiUserRolesRes): Observable<UserAccount> {
    this.traceService.info(TraceModules.roles, 'UserRolesBxSubstituteProxyService.updateUserRoles() called.');

    // User role functionality is disabled. this method will not be called
    return throwError(() => new Error('UserRolesBxSubstituteProxyService.updateUserRoles(): Not Implemented!'));
  }

  public subscribeUserRoles(): Observable<any> {
    this.traceService.info(TraceModules.roles, 'UserRolesBxSubstituteProxyService.subscribeUserRoles() called.');

    return scheduled([true], asapScheduler);
  }

  public unsubscribeUserRoles(): Observable<boolean> {
    this.traceService.info(TraceModules.roles, `UserRolesBxSubstituteProxyService.unsubscribeUserRoles() called`);

    return scheduled([true], asapScheduler);
  }
}
