import { Pipe, PipeTransform } from '@angular/core';

import { CriterionIntern } from './si-filtered-search-helper';
import { OptionCriterion, OptionType } from './si-filtered-search.model';

@Pipe({
  name: 'toLabel',
  standalone: true
})
export class ToLabelPipe implements PipeTransform {
  /**
   * It gets the label from options for the token (value) passed
   * @param options The options
   * @param token The token
   */
  static getLabel(options: OptionType[], token: string | string[]): string | string[] | undefined {
    return ToLabelPipe.getFor(options, 'value', 'label', token);
  }

  /**
   * It gets the value from options for the token (label) passed
   * @param options The options
   * @param token The token
   */
  static getValue(options: OptionType[], token: string | string[]): string | string[] | undefined {
    return ToLabelPipe.getFor(options, 'label', 'value', token);
  }

  private static getFor(
    options: OptionType[],
    propertyToCompare: keyof OptionCriterion,
    propertyToRetrieve: keyof OptionCriterion,
    token: string | string[]
  ): string | string[] | undefined {
    if (!options || options.length === 0) {
      return token;
    }

    if (typeof token === 'string') {
      const option = options.find(op =>
        typeof op === 'string' ? op === token : op[propertyToCompare] === token
      );
      if (option) {
        return typeof option === 'string' ? option : (option[propertyToRetrieve] as string);
      }

      return token;
    } else {
      const tokenArr: string[] = [];
      token?.forEach(tokenVal => {
        const option = options.find(op =>
          typeof op === 'string' ? op === tokenVal : op[propertyToCompare] === tokenVal
        );
        if (option) {
          tokenArr.push(
            typeof option === 'string' ? option : (option[propertyToRetrieve]! as string)
          );
        } else {
          tokenArr.push(tokenVal);
        }
      });
      return tokenArr;
    }
  }

  /**
   * Method which is called on any data passed to the pipe.
   * Called by Angular, should not be called directly.
   */
  transform(token: CriterionIntern, options: OptionType[]): string | string[] | undefined {
    return ToLabelPipe.getLabel(options, token.value);
  }
}
