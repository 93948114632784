import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

/** @internal */
@Injectable({
  providedIn: null
})
export class SiAccordionService {
  /**
   * Subject to emit an item in the accordion which should be toggled.
   */
  readonly toggle$ = new Subject<any>();
  /**
   * Subject to emit when the items should be expanded to their full height or restored to normal height.
   */
  readonly fullHeight$ = new ReplaySubject<boolean>(1);
}
