@if (item.items || item.customContent) {
  <button
    type="button"
    class="nav-link dropdown-item focus-inside"
    [class.active]="active"
    [attr.aria-label]="item.title | translate"
    [siNavbarDropdownTriggerFor]="dropdown"
  >
    <ng-container *ngTemplateOutlet="itemContent" />
  </button>
} @else {
  <a
    class="nav-link dropdown-item focus-inside"
    activeClass="active"
    [siLink]="item"
    [attr.aria-label]="item.title | translate"
    (activeChange)="active = $event"
  >
    <ng-container *ngTemplateOutlet="itemContent" />
  </a>
}

<ng-template #itemContent>
  @if (item.icon) {
    <span class="icon" [ngClass]="[item.icon, trigger?.isOpen ? item.icon + '-filled' : '']"></span>
  }
  @if ((item.badgeStyle === 'dot' || quickAction) && item.badge) {
    <div class="badge-text">{{ item.badge }}</div>
  }
  <span class="item-title text-truncate"> {{ item.title | translate }}</span>
  @if (
    (item.badgeStyle !== 'dot' && item.badge) ||
    item.selectionState ||
    item.items ||
    item.customContent
  ) {
    <div class="item-end d-flex me-n3 gap-1 ms-auto" [class.d-sm-none]="quickAction">
      @if (item.badgeStyle !== 'dot' && item.badge && !quickAction) {
        <span class="mx-0 me-1 ms-2 badge" [ngClass]="'bg-' + item.badgeColor">{{
          item.badge
        }}</span>
      }
      @if (item.selectionState === 'check') {
        <div class="element-ok icon"></div>
      }
      @if (item.selectionState === 'radio') {
        <div class="element-record-filled icon-small me-2"></div>
      }
      @if (item.items || item.customContent) {
        <span class="element-down-2 dropdown-caret dropdown-caret-animation ps-0 m-0"></span>
      }
    </div>
  }
</ng-template>

<ng-template #dropdown>
  <si-navbar-dropdown>
    @if (item.items) {
      <si-navbar-dropdown-items-factory [items]="item.items" (activeChange)="active = $event" />
    }
    @if (item.customContent) {
      <ng-content />
    }
  </si-navbar-dropdown>
</ng-template>
