import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { GmsPipe } from '../elements/gms-pipe';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsElementType } from '../types/gms-element-types';
import { GmsElementComponent } from './gms-element.component';
import { GmsXpsComponent } from './gms-xps.component';

@Component({
  selector: '[gms-pipe]',
  template: `<svg:g #pipeElement
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                    [attr.visibility]="element.GetVisible()"
                    [attr.opacity]="element.Opacity"
                    [attr.transform]="element.GetTransformations()"
                    [attr.id]="element.Id"
                    [attr.clip-path]="element.ClipPathUrl"
                    [attr.filter]="element.Filter?.Url">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <svg:rect
                        class="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                        (mouseenter)="OnMouseEnter($event)"
                        (click)="OnMouseClick($event)"
                        (mouseout)="OnMouseLeave($event)"
                            [attr.width]="element.BackgroundWidth"
                            [attr.height]="element.BackgroundHeight"
                            [attr.x]="element.BackgroundX"
                            [attr.y]="element.BackgroundY"
                            [attr.fill]="element.Background"
                            [attr.fill-opacity]="element.BackgroundOpacity"
                            stroke-opacity="0" />
                    <svg:g
                        [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                        *ngFor="let item of element.children;">
                        <svg:g *ngIf="item.Type === elementType.Xps" gms-xps [element]="item"/>
                    </svg:g>
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [GmsXpsComponent]
})
export class GmsPipeComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsPipe = null;
  @ViewChild('pipeElement', { static: false })
  private _pipeElement: ElementRef;

  public get pipeElement(): ElementRef {
    return this._pipeElement;
  }
  public set pipeElement(value: ElementRef) {
    this._pipeElement = value;
  }
  public elementType: any = GmsElementType; // Store a reference to the enum, so that we can compare in the template

  constructor(public changeDetector: ChangeDetectorRef, adornerService: GmsAdornerService) {
    super(changeDetector, adornerService);
  }
}
