import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StateService } from '@gms-flex/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionService } from 'src/app/core/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly state: StateService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.sessionService.initialized.pipe(
      tap(signedIn => {
        if (signedIn) {
          if (this.state.hasRouteConfigBeenReset) {
            return true;
          } else {
            // route config not ready so redirect to loading page.
            this.router.navigate(['/loading'], { skipLocationChange: true });
            return false;
          }
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }
}
