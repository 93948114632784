import { Directive } from '@angular/core';

import { SiAutoCollapsableListItemDirective } from './si-auto-collapsable-list-item.directive';

@Directive({
  selector: '[siAutoCollapsableListOverflowItem]',
  exportAs: 'siAutoCollapsableListOverflowItem',
  standalone: true
})
export class SiAutoCollapsableListOverflowItemDirective extends SiAutoCollapsableListItemDirective {
  hiddenItemCount = 0;
}
