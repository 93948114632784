import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, map, Observable, of, share, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { useCache, useMulticast } from '../shared/http-utility.service';
import { Partition, PartitionResponseSingle } from './partition-proxy.model';
import { PartitionProxyService } from './partition-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class PartitionService {
  private readonly partitionsPerCustomerId: Map<string, Partition[]> = new Map<string, Partition[]>();
  private readonly partitionsPerIdSingle: Map<string, PartitionResponseSingle> = new Map<string, PartitionResponseSingle>();
  private readonly partitionsPerId: Map<string, Partition> = new Map<string, Partition>();
  private readonly multiCastPartObs: Map<string, Observable<Partition>> = new Map<string, Observable<Partition>>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly partitionProxy: PartitionProxyService) {

    this.traceService.info(TraceModules.bxServicesPartitions, 'PartitionService created.');
  }

  /**
   * Reads all partitions for the specified customer.
   * Filtering with partitionName is done client-side
   */
  public getPartitions(customerId: string, partitionName?: string): Observable<Partition[]> {
    if ((useCache) && (this.partitionsPerCustomerId.has(customerId))) {
      const partitions = this.partitionsPerCustomerId.get(customerId);
      const filtered = this.filterPartitions(partitions, partitionName);
      this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionService.getPartitions() returned:
        no of partitions: ${filtered.length} from cache
        customerId=${customerId}, partitionName=${partitionName}`);
      return of(filtered);
    } else {
      return this.partitionProxy.getPartitions(customerId).pipe(
        map(result => result.data),
        tap(result => {
          this.partitionsPerCustomerId.set(customerId, result);
          this.updatePartitionPerId(result);
        }),
        map(result => this.filterPartitions(result, partitionName)),
        tap(partitions => {
          this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionService.getPartitions() returned:
            no of partitions: ${partitions.length} from backend
            customerId=${customerId}, partitionName=${partitionName}`);
        })
      );
    }
  }

  /**
   * Returns details of a partition.
   * Note that this method needs other permissions than reading partitions together with customer Id
   */
  public getPartitionDetails(partitionId: string): Observable<PartitionResponseSingle> {
    if ((useCache) && (this.partitionsPerIdSingle.has(partitionId))) {
      return of(this.partitionsPerIdSingle.get(partitionId));
    } else {
      return this.partitionProxy.getPartition(partitionId).pipe(
        tap(result => this.partitionsPerIdSingle.set(partitionId, result))
      );
    }
  }

  /**
   * Reads the partition for the specified customer and partition.
   */
  public getPartition(customerId: string, partitionId: string): Observable<Partition> {
    if ((useCache) && (this.partitionsPerId.has(partitionId))) {
      const partition = this.partitionsPerId.get(partitionId);
      this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionService.getPartition() returned partition: ${partition?.attributes.name} from cache
        partitionId=${partitionId}, customerId=${customerId}`);
      return of(partition);
    } else {
      // using multicast to support the concurrent alarm source resolvement more efficient.
      // problem statement: finding/resolving the same object (e.g the device of alarms of the device) causes many parallel calls which do the same.
      // e.g reading the same device, gateway, location, partition... The cache cannot be maintained.
      if ((useMulticast) && (this.multiCastPartObs.has(partitionId))) {
        return this.multiCastPartObs.get(partitionId);
      }
      const partObs: Observable<Partition> = this.partitionProxy.getPartitionOfCustomer(customerId, partitionId).pipe(
        map(result => result.data),
        tap(partition => {
          this.partitionsPerId.set(partitionId, partition);
          this.traceService.debug(TraceModules.bxServicesPartitions, `PartitionService.getPartition() returned:
            partition: ${partition?.attributes.name} from backend
            partitionId=${partitionId}, customerId=${customerId}`);
        }),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesPartitions, `Partition not found: ${partitionId}, error=${error}`);
          this.partitionsPerId.set(partitionId, undefined);
          return of(undefined);
        })
      );
      if (useMulticast) {
        const multicastObs = partObs.pipe(share());
        this.multiCastPartObs.set(partitionId, multicastObs);
        return multicastObs;
      } else {
        return partObs;
      }
    }
  }

  /**
   * Reads the partition for the specified customer and partition.
   * The filter for the partition Id is applied client side
   */
  public getPartitionFilteredClientSide(customerId: string, partitionId: string): Observable<Partition> {
    this.traceService.debug(TraceModules.bxServicesPartitions, 'findPartition() called');
    return this.getPartitions(customerId).pipe(
      map(response => response.find(partition => partition.id === partitionId))
    );
  }

  private filterPartitions(partitions: Partition[], name?: string): Partition[] {
    if (name) {
      const found = this.findPartition(partitions, name);
      return found ? [found] : [];
    } else {
      return partitions.filter(prt => (prt !== undefined));
    }
  }

  private findPartition(partitions: Partition[], name: string): Partition | undefined {
    return partitions.find(prt => prt?.attributes.name === name);
  }

  private updatePartitionPerId(partitions: Partition[]): void {
    partitions.forEach(part => this.partitionsPerId.set(part.id, part));
  }
}
