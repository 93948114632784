import {
  Attribute,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  ProviderToken,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiInputPillComponent } from './si-input-pill.component';
import {
  SiPillsInputValueHandlerDirective,
  SiPillsInputValueHandlerTrigger
} from './si-pills-input-value-handler';

@Component({
  selector: 'si-pills-input',
  templateUrl: './si-pills-input.component.html',
  styleUrl: './si-pills-input.component.scss',
  standalone: true,
  imports: [SiInputPillComponent, SiTranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiPillsInputComponent,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiPillsInputComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'form-control', role: 'listbox', 'aria-orientation': 'horizontal' }
})
export class SiPillsInputComponent implements OnInit, ControlValueAccessor, SiFormItemControl {
  private static idCounter = 0;

  /** The identifier of the pills-input. Will be generated if not provided. */
  @Input() id = `__si-pills-input-${SiPillsInputComponent.idCounter++}`;

  /** The aria-label for the inner input where users enter new items. */
  @Input() inputElementAriaLabel =
    $localize`:@@SI_PILLS_INPUT.INPUT_ELEMENT_ARIA_LABEL:Create item`;

  /** Whether the pills-input is disabled. */
  @Input({ transform: booleanAttribute })
  @HostBinding('class.disabled')
  @HostBinding('attr.aria-disabled')
  disabled = false;

  /** Whether the pills-input is readonly */
  @Input({ transform: booleanAttribute })
  @HostBinding('class.readonly')
  @HostBinding('attr.aria-readonly')
  readonly = false;

  /** The placeholder to be shown if no value is currently present. */
  @Input() placeholder?: string;

  @HostBinding('attr.aria-labelledby') labelledby =
    inject(new Attribute('aria-labelledby') as unknown as ProviderToken<string>, {
      optional: true
    }) ?? `${this.id}-label`;

  protected inputValue = '';
  protected pills: string[] = [];
  protected activePillIndex?: number;
  protected inputId = `${this.id}-input`;

  protected onTouched: () => void = () => {};

  protected onChange: (val: any) => void = () => {};

  @ViewChild('inputElement') private inputElement?: ElementRef<HTMLInputElement>;

  private siPillsInputValueHandlerDirective = inject(SiPillsInputValueHandlerDirective, {
    optional: true
  });
  private cdRef = inject(ChangeDetectorRef);
  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  @HostBinding('attr.aria-activedescendant')
  protected get activeDescendant(): string | null {
    if (this.activePillIndex !== undefined) {
      return `${this.id}-pill-${this.activePillIndex}`;
    } else {
      return null;
    }
  }

  @HostBinding('attr.tabindex') // using attr so that tabindex is removed if not defined
  protected get tabindex(): -1 | 0 | void {
    if (this.disabled) {
      return;
    }
    if (this.readonly) {
      return 0;
    }
    return -1;
  }

  ngOnInit(): void {
    this.siPillsInputValueHandlerDirective ??= {
      handle: (value, trigger) => {
        if (trigger !== 'input') {
          return {
            newPills: [value],
            newValue: ''
          };
        }
        return undefined;
      }
    };
  }

  protected input(): void {
    this.inputValue = this.inputElement!.nativeElement.value;
    this.rebuildValue(this.inputValue, 'input');
  }

  @HostListener('click')
  protected click(): void {
    this.inputElement?.nativeElement.focus();
  }

  protected blur(): void {
    this.rebuildValue(this.inputValue, 'blur');
    this.activePillIndex = undefined;
    this.onTouched();
  }

  protected keydownEnter(event: Event): void {
    this.rebuildValue(this.inputValue, 'keydown.enter');
    event.preventDefault();
  }

  protected keydownBackspace(event: Event): void {
    if (!this.inputValue && this.pills.length) {
      const lastChipValue = this.pills.at(-1)!;
      this.remove(this.pills.length - 1, false);
      this.inputValue = lastChipValue;
      event.preventDefault();
    }
  }

  protected remove(index: number, focus = true): void {
    this.pills.splice(index, 1);
    this.onTouched();
    this.onChange(this.pills);
    if (focus) {
      this.inputElement!.nativeElement.focus();
    }
  }

  private rebuildValue(value: string, trigger: SiPillsInputValueHandlerTrigger): void {
    if (value) {
      const valueParseResult = this.siPillsInputValueHandlerDirective?.handle(value, trigger);
      if (valueParseResult) {
        this.pills.push(...valueParseResult.newPills);
        // Doesn't update when setting to empty string.
        // Not using setTimeout to avoid flickering.
        this.cdRef.detectChanges();
        this.inputValue = valueParseResult.newValue;
        this.cdRef.detectChanges();

        if (valueParseResult.newPills.length) {
          this.onTouched();
          this.onChange(this.pills);
        }
      }
    }
  }

  @HostListener('keydown.arrowLeft') arrowLeft(): void {
    if (this.activePillIndex !== undefined) {
      this.activePillIndex = Math.max(0, this.activePillIndex - 1);
    } else if (!this.inputValue.length && this.pills.length) {
      this.elementRef.nativeElement.focus();
      this.activePillIndex = this.pills.length - 1;
    }
  }

  @HostListener('keydown.arrowRight') arrowRight(): void {
    if (this.activePillIndex !== undefined) {
      this.activePillIndex = this.activePillIndex + 1;

      if (this.activePillIndex >= this.pills.length) {
        this.inputElement?.nativeElement.focus();
        this.activePillIndex = undefined;
      }
    }
  }

  @HostListener('keydown.delete') delete(): void {
    if (this.activePillIndex !== undefined && !this.readonly) {
      const targetIndex =
        this.pills.length > 1 ? Math.min(this.activePillIndex, this.pills.length - 2) : undefined;
      this.remove(this.activePillIndex, targetIndex === undefined);
      this.activePillIndex = targetIndex;
    }
  }

  /** @internal */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @internal */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** @internal */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  /** @internal */
  writeValue(value?: string[] | null): void {
    this.pills = value?.slice() ?? [];
    this.cdRef.markForCheck();
  }
}
