import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, map, Observable } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { EntityBase, LinkSelf, Relationship } from '../shared/base.model';
import { HttpUtilityService } from '../shared/http-utility.service';

const locationUrl = `${environment.bxPlatform.locationVerticalApiUrl}/v2/partitions`

export interface RelationshipsDiscipline {
  isSubDisciplineOf: Relationship;
}

export interface DisciplineAttributes {
  label: string;
}

export interface DisciplineBx extends EntityBase {
  attributes: DisciplineAttributes;
  relationships?: RelationshipsDiscipline;
}

export interface DisciplineEnumResponse {
  data: DisciplineBx[];
  links: LinkSelf;
}

@Injectable({
  providedIn: 'root'
})
export class EnumsProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesEnums, 'EnumsProxyService created.');
  }

  public getDisciplinesEnum(partitionId: string): Observable<DisciplineEnumResponse> {
    this.traceService.debug(TraceModules.bxServicesEnums, `EnumsProxyService.getDisciplinesEnum() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${locationUrl}/${partitionId}/enums/disciplines`;

    return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getDisciplinesEnum()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getDisciplinesEnum()')));
  }
}
