import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-form-fieldset',
  standalone: true,
  imports: [SiTranslateModule],
  templateUrl: './si-form-fieldset.component.html',
  styleUrls: ['../si-form.shared.scss', './si-form-fieldset.component.scss'],
  host: {
    role: 'group',
    class: 'si-form-input'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiFormFieldsetComponent {
  private static labelIdCounter = 0;

  /** The label for the entire fieldset. */
  @Input({ required: true }) label!: string;

  /** Overrides the parent label width. */
  @Input() @HostBinding('style.--si-form-label-width') labelWidth?: string;

  /** Adds a required marker to the label */
  @Input() set required(value: boolean | '') {
    this.isRequired = coerceBooleanProperty(value);
  }

  protected isRequired = false;

  /** Switches all child inputs to inline mode */
  @Input() set inline(value: boolean | '') {
    this.isInline = coerceBooleanProperty(value);
  }

  get inline(): boolean {
    return this.isInline;
  }

  private isInline = false;

  @HostBinding('attr.aria-labelledby')
  protected labelId = `__si-form-fieldset-label-${SiFormFieldsetComponent.labelIdCounter++}`;
}
