/** class imports */
import { Injectable, NgZone } from '@angular/core';
import {
  ClientIdentification, ConnectionState, GmsNotificationDispatcher, GmsSubscription, GmsSubscriptionInstrumentation, GmsSubscriptionStore,
  SubscriptionDeleteWsi, SubscriptionUtility
} from '@gms-flex/services';
import { AppSettingsService, TraceService } from '@gms-flex/services-common';
import { Observable } from 'rxjs';

import { VMSDataSubscriptionProxyService, VMSDataSubscriptionServiceBase } from '.';
import { TraceModules } from '../../shared/trace-modules';
import { SubscriptionGmsVMSData, VMSDataChange } from './vmssubscription.data.model';

/** class constants */
const log = false;

/**
 * Provides the functionality for clients to subscribe for VMS data value changes.
 * Supports shared WSI subscriptions.
 * Supports automatic resubscribe mechanism.
 *
 * @export
 * @class VMSDataSubscriptionService
 * @extends {VMSDataSubscriptionServiceBase}
 */
@Injectable({
  providedIn: 'root'
})
export class VMSDataSubscriptionService extends VMSDataSubscriptionServiceBase {

  private readonly _subscriptionStore: GmsSubscriptionStore<VMSDataChange> =
    new GmsSubscriptionStore<VMSDataChange>(this.trace, TraceModules.videoVmsDataSubscriptionService,
      this.valueChangeNotification);
  private readonly clientsRegistered: Map<string, ClientIdentification> = new Map<string, ClientIdentification>();
  private gotDisconnected = false;
  private readonly vmsDataChangeNotificationDispatcher: GmsNotificationDispatcher<VMSDataChange>;
  private readonly subcriptionInstrumentation: GmsSubscriptionInstrumentation<VMSDataChange>;

  /**
   * Creates an instance of VMSDataSubscriptionService.
   * @memberof VMSDataSubscriptionService
   */
  public constructor(
    private readonly settingsService: AppSettingsService,
    private readonly trace: TraceService,
    private readonly vmsDataSubscriptionProxy: VMSDataSubscriptionProxyService,
    private readonly ngZone: NgZone
  ) {
    super();

    this.vmsDataChangeNotificationDispatcher = new GmsNotificationDispatcher<VMSDataChange>(this.trace, TraceModules.videoVmsDataSubscriptionService,
      this.valueChangeNotification, this._subscriptionStore);
    this.vmsDataSubscriptionProxy.notifyConnectionState().subscribe(connectionState => this.onNotifyConnectionState(connectionState));
    this.subcriptionInstrumentation = new GmsSubscriptionInstrumentation<VMSDataChange>(this.settingsService, this.trace,
      'ValueDetails', this.clientsRegistered,
      this._subscriptionStore, this.ngZone);
  }

  /**
   * getVmsChangeSubscription
   *
   * @param {} clientId
   * @returns {}
   * @memberof VMSDataSubscriptionService
   */
  public getVmsChangeSubscription(clientId: string): Observable<VMSDataChange[]> {
    this.trace.debug(TraceModules.videoVmsDataSubscriptionService, '+++clientId+++ %s', clientId);
    return this.vmsDataSubscriptionProxy.vmsChangeSubscription.get(clientId.split(':')[0]);
  }

  /**
   * All clients must register themselves upfront in order to invoke the further service business functionality.
   * This method returns an ID. All invoked methods must has have this ID as a parameter.
   *
   * @param {} clientName
   * @returns {}
   * @memberof VMSDataSubscriptionService
   */
  public registerClient(clientName: string): string {
    if ((clientName === undefined) || (clientName === '')) {
      this.trace.error(TraceModules.videoVmsDataSubscriptionService, 'VMSDataSubscriptionService.registerclient(): Invalid client name!');
      return undefined;
    }

    const proxy: ClientIdentification = new ClientIdentification(clientName);
    this.clientsRegistered.set(proxy.clientId, proxy);
    this._subscriptionStore.registerClientId(proxy.clientId);
    this.subcriptionInstrumentation.start();
    return proxy.clientId;
  }

  /**
   * All clients must dispose themselves at the end of their lifetime.
   * This method traces an error message, if a client did not unsubscribe all its subscriptions prior to this call.
   *
   * @param clientId The unique client ID retrieved by the registerClient method
   * @returns {}
   * @memberof VMSDataSubscriptionService
   */
  public disposeClient(clientId: string): void {
    const clientProxy: ClientIdentification = this.clientsRegistered.get(clientId);
    if (clientProxy === undefined) {
      this.trace.error(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.disposeClient() called with invalid arguments: clientId=%s', clientId);
      return;
    }
    this.clientsRegistered.delete(clientId);
    this.trace.info(TraceModules.videoVmsDataSubscriptionService, 'VMSDataSubscriptionService.disposeClient() called from client: %s', clientId);

    const valSub: GmsSubscription<VMSDataChange>[] = this._subscriptionStore.getAllActiveGmsSubscription(clientId);
    if (valSub.length > 0) {
      this.trace.error(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.disposeClient() called; client did not unsubscribe all its subscriptions! Client: %s', clientId);
    }
    // we intentinally do not unsubscribe in order that client does not forget to unsubscribe on observable of the ValueSubscription.
    // this.unsubscribeValues(this._subscriptionStore.getAllActiveValueSubscription(clientId), clientId);
    this._subscriptionStore.unregisterClientId(clientId);
  }

  /**
   * Subscribes the specified objects and/or properties.
   * Returns a "ValueSubscription" object per objectOrPropertyIds.
   * Implementation details
   * If the same objectOrPropertyIds is handed over multiple times (even in separate calls), for each objectOrPropertyIds a corresponding
   * "ValueSubscription" is returned. The underlying layer, however, creates just one subscription on WSI. I.e. the underlying layer
   * maintains refCounting.
   * Therefore, if the client unsubscribes/subscribes multiple objectOrPropertyIds in fast succession and and the same objectOrPropertyId
   * is possibly specified in both (unsubscribe and subscribe call), it is more effiecient to subcribe first and the do the unsubscribe after
   * in order to benefit of the refCounting mechanism.
   *
   * @param {} monitorGroupId
   * @param {} clientId The unique client ID retrieved by the registerClient method
   * @returns {}
   * @memberof VMSDataSubscriptionService
   */
  public subscribeVMSDataChange(monitorGroupId: string, clientId: string): GmsSubscription<VMSDataChange>[] {
    const clientProxy: ClientIdentification = this.clientsRegistered.get(clientId);
    if (monitorGroupId === undefined || clientProxy === undefined) {
      this.trace.error(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.subscribeValues() called with invalid arguments: monitorGroupId=%s, clientId=%s',
        monitorGroupId, clientId);
      return [];
    }
    this.trace.info(TraceModules.videoVmsDataSubscriptionService, 'VMSDataSubscriptionService.subscribeValues() called from client: %s', clientId);

    const createdSubs: { toBeSubscribed: string[]; gmsSubscriptions: GmsSubscription<VMSDataChange>[] } =
            this._subscriptionStore.createSubscriptions([monitorGroupId], clientId);
    this.logHelper('createdSubs %s', createdSubs);

    this.vmsDataSubscriptionProxy.clientId = clientId.split(':')[0]; // ***SignalR***

    if (createdSubs.toBeSubscribed.length > 0) {
      this.vmsDataSubscriptionProxy.subscribeVMSDataChanges(monitorGroupId).subscribe(
        subscriptions => this.onSubscribeVMSDataChange(createdSubs.toBeSubscribed, subscriptions),
        error => this.onSubscribeVMSDataChangeError(createdSubs.toBeSubscribed, error));
    } else {
      // ***SignalR***
      this.trace.debug(TraceModules.videoVmsDataSubscriptionService, '***SignalR***(1)');
      this.vmsDataSubscriptionProxy.subscribeVMSDataChanges(monitorGroupId).subscribe(
        subscriptions => this.onSubscribeVMSDataChange(createdSubs.toBeSubscribed, subscriptions),
        error => this.onSubscribeVMSDataChangeError(createdSubs.toBeSubscribed, error));
      // ***SignalR***
    }

    if (this.trace.isDebugEnabled(TraceModules.videoVmsDataSubscriptionService)) {
      this.trace.debug(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.subscribeValues() returned ValueSubscription objects:\n%s',
        createdSubs.gmsSubscriptions.join('\n'));
    }
    return createdSubs.gmsSubscriptions;
  }

  /**
   * Unsubscribes the specified "ValueSubscription" objects.
   * Important: The client must detach from any events of these objects and release all references to them in order to avoid memory leaks.
   *
   * @param {} subscriptions
   * @param {} clientId The unique client ID retrieved by the registerClient method
   * @returns {}
   * @memberof VMSDataSubscriptionService
   */
  public unsubscribeVMSDataChange(subscriptions: GmsSubscription<VMSDataChange>[], clientId: string): void {
    const clientProxy: ClientIdentification = this.clientsRegistered.get(clientId);
    if (subscriptions === null || subscriptions.length === 0 || clientProxy === undefined) {
      this.trace.error(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.unSubscribeValues() called with invalid arguments: clientId=%s', clientId);
      return;
    }
    this.trace.info(TraceModules.videoVmsDataSubscriptionService,
      'VMSDataSubscriptionService.unsubscribeValues() called from client: %s, no of value subscriptions:\n%s', clientId, subscriptions.length);
    if (this.trace.isDebugEnabled(TraceModules.videoVmsDataSubscriptionService)) {
      this.trace.debug(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.unsubscribeValues() called from client: %s, value subscriptions:\n%s', clientId, subscriptions.join('\n'));
    }

    const toBeUnsubscribed: { toBeUnsubscribedKeys: number[]; toBeUnsubscribedIds: string[] } =
            this._subscriptionStore.removeSubscriptions(subscriptions, clientId);
    this.logHelper('toBeUnsubscribed %s', toBeUnsubscribed);

    this.vmsDataSubscriptionProxy.clientId = clientId.split(':')[0]; // ***SignalR***

    if (toBeUnsubscribed.toBeUnsubscribedKeys.length > 0) {
      this.vmsDataSubscriptionProxy.unsubscribeVMSDataChanges(toBeUnsubscribed.toBeUnsubscribedKeys).subscribe(
        subDels => this.onUnsubscribeVMSDataChange(toBeUnsubscribed.toBeUnsubscribedKeys, toBeUnsubscribed.toBeUnsubscribedIds, subDels),
        error => this.onUnsubscribeVMSDataChangeError(toBeUnsubscribed.toBeUnsubscribedKeys, toBeUnsubscribed.toBeUnsubscribedIds, error));
    } else {
      // ***SignalR***
      this.trace.debug(TraceModules.videoVmsDataSubscriptionService, '***SignalR***(2)');
      this.vmsDataSubscriptionProxy.unsubscribeVMSDataChanges([1]).subscribe(
        subDels => this.onUnsubscribeVMSDataChange(toBeUnsubscribed.toBeUnsubscribedKeys, toBeUnsubscribed.toBeUnsubscribedIds, subDels),
        error => this.onUnsubscribeVMSDataChangeError(toBeUnsubscribed.toBeUnsubscribedKeys, toBeUnsubscribed.toBeUnsubscribedIds, error));
      // ***SignalR***
    }
  }

  // ---------------------------------------------------------------------------------------------

  /**
   * valueChangeNotification
   *
   * @readonly
   * @private
   * @type {Observable<VMSDataChange[]>}
   * @memberof VMSDataSubscriptionService
   */
  private get valueChangeNotification(): Observable<VMSDataChange[]> {
    return this.vmsDataSubscriptionProxy.vmsDataChangeNotification();
  }

  /**
   * onSubscribeVMSDataChange
   *
   * @private
   * @param {} toBeSubscribed
   * @param {} subscriptionsWsi
   * @memberof VMSDataSubscriptionService
   */
  private onSubscribeVMSDataChange(toBeSubscribed: string[], subscriptionsWsi: SubscriptionGmsVMSData[]): void {
    // Important note:
    // If the channelized wsi subscription API is used, we get here multiple replies for one request with multiple Id's to subscribe!

    this.trace.info(TraceModules.videoVmsDataSubscriptionService, 'VMSDataSubscriptionService.onSubscribeValues() done; wsi key(s) returned.');

    const toBeUnsubscribed: { keys: number[]; ids: string[] } = this._subscriptionStore.subscribeReply(subscriptionsWsi);
    if (toBeUnsubscribed.keys.length > 0) {
      this.trace.info(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.onSubscribeValues() unsubscribe of values pending, no of values:\n%s', toBeUnsubscribed.keys.length);

      if (this.trace.isDebugEnabled(TraceModules.videoVmsDataSubscriptionService)) {
        this.trace.debug(TraceModules.videoVmsDataSubscriptionService,
          'VMSDataSubscriptionService.onSubscribeValues() unsubscribe of values pending: %s, objectOrPropertyIds to subscribe:\n%s',
          toBeUnsubscribed.ids.join('\n'));
      }

      this.vmsDataSubscriptionProxy.unsubscribeVMSDataChanges(toBeUnsubscribed.keys).subscribe(
        subDels => this.onUnsubscribeVMSDataChange(toBeUnsubscribed.keys, toBeUnsubscribed.ids, subDels),
        error => this.onUnsubscribeVMSDataChangeError(toBeUnsubscribed.keys, toBeUnsubscribed.ids, error));
    }
  }

  /**
   * onSubscribeVMSDataChangeError
   *
   * @private
   * @param {} toBeSubscribed
   * @param {} error
   * @memberof VMSDataSubscriptionService
   */
  private onSubscribeVMSDataChangeError(toBeSubscribed: string[], error: any): void {
    this.trace.warn(TraceModules.videoVmsDataSubscriptionService,
      'VMSDataSubscriptionService.onSubscribeVMSDataChangeError() error; WSI subscription failed.');

    const toBeUnsubscribed: { keys: number[]; ids: string[] } = this._subscriptionStore.subscribeReplyError(toBeSubscribed);
    this.logHelper('toBeUnsubscribed %s', toBeUnsubscribed);

    if (toBeUnsubscribed.keys.length > 0) {
      this.trace.info(TraceModules.videoVmsDataSubscriptionService,
        'VMSDataSubscriptionService.onSubscribeVMSDataChangeError() unsubscribe of VMSDataChange pending, no of values:\n%s',
        toBeUnsubscribed.keys.length);

      if (this.trace.isDebugEnabled(TraceModules.videoVmsDataSubscriptionService)) {
        this.trace.debug(TraceModules.videoVmsDataSubscriptionService,
          'VMSDataSubscriptionService.onSubscribeVMSDataChange() unsubscribe of values pending: %s, objectOrPropertyIds to subscribe:\n%s',
          toBeUnsubscribed.ids.join('\n'));
      }

      this.vmsDataSubscriptionProxy.unsubscribeVMSDataChanges(toBeUnsubscribed.keys).subscribe(
        subDels => this.onUnsubscribeVMSDataChange(toBeUnsubscribed.keys, toBeUnsubscribed.ids, subDels),
        err => this.onUnsubscribeVMSDataChangeError(toBeUnsubscribed.keys, toBeUnsubscribed.ids, err));
    }
  }

  /**
   * onUnsubscribeVMSDataChange
   *
   * @private
   * @param {} toBeUnsubscribedKeys
   * @param {} toBeUnsubscribedIds
   * @param {} subDelWsi
   * @memberof VMSDataSubscriptionService
   */
  private onUnsubscribeVMSDataChange(toBeUnsubscribedKeys: number[], toBeUnsubscribedIds: string[], subDelWsi: SubscriptionDeleteWsi[]): void {
    this.trace.info(TraceModules.videoVmsDataSubscriptionService, 'VMSDataSubscriptionService.onUnsubscribeVMSDataChange() done; wsi del keys returned.');

    // ***SignalR***
    if (subDelWsi !== null) { // ***SignalR***
      this._subscriptionStore.unsubscribeReply(subDelWsi, toBeUnsubscribedIds);
    }
  }

  /**
   * onUnsubscribeVMSDataChangeError
   *
   * @private
   * @param {} toBeUnsubscribedKeys
   * @param { toBeUnsubscribedIds
   * @param {} error
   * @memberof VMSDataSubscriptionService
   */
  private onUnsubscribeVMSDataChangeError(toBeUnsubscribedKeys: number[], toBeUnsubscribedIds: string[], error: any): void {
    this.trace.warn(TraceModules.videoVmsDataSubscriptionService,
      'VMSDataSubscriptionService.onUnsubscribeVMSDataChangeError() error; WSI unsubscription failed.');

    this._subscriptionStore.unsubscribeReplyError(toBeUnsubscribedKeys, toBeUnsubscribedIds);
  }

  /**
   * onNotifyConnectionState
   *
   * @private
   * @param {} connectionState
   * @memberof VMSDataSubscriptionService
   */
  private onNotifyConnectionState(connectionState: ConnectionState): void {
    this.trace.info(TraceModules.videoVmsDataSubscriptionService,
      'VMSDataSubscriptionService.onNotifyConnectionState() state: %s',
      SubscriptionUtility.getTextForConnection(connectionState));

    if (connectionState === ConnectionState.Disconnected) {
      this._subscriptionStore.notifyChannelDisconnected();
      this.gotDisconnected = true;
    } else if ((connectionState === ConnectionState.Connected) && this.gotDisconnected) {
      const toBeResubscribed: string[] = this._subscriptionStore.notifyChannelReconnected();
      this.logHelper('toBeResubscribed %s', toBeResubscribed);

      this.gotDisconnected = false;
      if (toBeResubscribed.length > 0) {
        this.trace.info(TraceModules.videoVmsDataSubscriptionService,
          'VMSDataSubscriptionService.onNotifyConnectionState(): Resubscribing VMSDataChange; number of objectOrPropertyIds:%s',
          toBeResubscribed.length);
        if (this.trace.isDebugEnabled(TraceModules.videoVmsDataSubscriptionService)) {
          this.trace.debug(TraceModules.videoVmsDataSubscriptionService,
            'VMSDataSubscriptionService.onNotifyConnectionState(): objectOrPropertyIds to resubscribe:\n%s', toBeResubscribed.join('\n'));
        }

        this.vmsDataSubscriptionProxy.subscribeVMSDataChanges(toBeResubscribed[0]).subscribe(
          subscriptions => this.onSubscribeVMSDataChange(toBeResubscribed, subscriptions),
          error => this.onSubscribeVMSDataChangeError(toBeResubscribed, error));
      }
    }
  }

  // ---------------------------------------------------------------------------------------------

  /**
   * logHelper
   *
   * @private
   * @param {} [message]
   * @param {} optionalParams
   * @memberof VMSDataSubscriptionProxyService
   */
  private logHelper(message?: any, ...optionalParams: any[]): void {
    if (log) {
      this.trace.debug(TraceModules.videoVmsDataSubscriptionService, message, optionalParams);
    }
  }
}
