import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import {
  SiSelectComponent,
  SiSelectMultiValueDirective,
  SiSelectSimpleOptionsDirective,
  SiSelectSingleValueDirective
} from '@simpl/element-ng/select';

@Component({
  selector: 'si-formly-select',
  templateUrl: './si-formly-select.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    SiSelectComponent,
    SiSelectSimpleOptionsDirective,
    SiSelectMultiValueDirective,
    SiSelectSingleValueDirective
  ]
})
export class SiFormlySelectComponent extends FieldType<FieldTypeConfig> {}
