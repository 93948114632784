import { NgModule } from '@angular/core';

// eslint-disable-next-line detect-bad-words/in-code
import { SiMasterDetailContainerComponent } from './si-master-detail-container.component';

/**
 * @deprecated Use the `SiMainDetailContainerModule` instead.
 */
@NgModule({
  imports: [SiMasterDetailContainerComponent],
  exports: [SiMasterDetailContainerComponent]
})
export class SiMasterDetailContainerModule {}
