import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'si-formly-textarea',
  styleUrl: './si-formly-textarea.component.scss',
  templateUrl: './si-formly-textarea.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormlyModule]
})
export class SiFormlyTextareaComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit
{
  @ViewChild('textArea', { static: true }) protected textArea!: ElementRef;

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(() => {
      this.assignValueToParent();
    });
  }

  ngAfterViewInit(): void {
    this.assignValueToParent();
  }

  private assignValueToParent(): void {
    this.model.i = (this.model.i ?? 0) + 1;
    this.textArea.nativeElement.parentNode.dataset.replicatedValue =
      this.textArea.nativeElement.value;
  }
}
