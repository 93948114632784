import { Injectable, NgZone } from '@angular/core';
import { HubConnection, HubProxyShared, NotifiesPending, TraceModules, WsiEndpointService } from '@gms-flex/services';
import { AuthenticationServiceBase, isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SignalRBxSubstituteService {
  // Counter for SignalR instances
  private static counter = 0;

  // To notify consumers hub is ready
  private readonly norisHubConnectionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Array of proxies for pending notifications
  private readonly proxies: NotifiesPending[] = [];

  // SignalR Hub connection instance
  private _norisHubConnection: HubConnection | undefined;

  // SignalR Hub proxy instance
  private _norisHubProxy: HubProxyShared | undefined;

  constructor(
    private readonly authenticationServiceBase: AuthenticationServiceBase,
    private readonly trace: TraceService,
    private readonly ngZone: NgZone,
    private readonly endpoint: WsiEndpointService
  ) {
    this.trace.info(TraceModules.signalR, 'SignalRBxSubstituteService created.');

    this.createNorisHub();

    // Subscribe to login state changes
    this.authenticationServiceBase.loginObservable.subscribe((isLoggedIn: boolean) => {
      this.createNorisHub(isLoggedIn);
    });
  }

  /**
 * Creates Noris Hub If user is authenticated.
 * @returns {HubConnection | undefined} The SignalR Hub connection.
 */
  public createNorisHub(isLoggedIn?: boolean): void {
    if (isLoggedIn || !isNullOrUndefined(this.authenticationServiceBase.userToken)) {
      // Create SignalR connection and proxy when user is logged in
      this.createNorisHubConnection();
      this.getNorisHub();

    } else {
      this.trace.info(TraceModules.signalR, 'User is not logged in, cannot create the SignalR connection');
    }
  }

  /**
 * Gets the SignalR Hub connection.
 * @returns {HubConnection | undefined} The SignalR Hub connection.
 */
  public getNorisHubConnection(): HubConnection | undefined {
    return this._norisHubConnection;
  }

  /**
   * Gets the SignalR Hub proxy.
   * @returns {HubProxyShared} The SignalR Hub proxy.
   */
  public getNorisHub(): HubProxyShared {
    if (!this._norisHubProxy) {
      this._norisHubProxy = new HubProxyShared(this.trace, this._norisHubConnection, 'norisHub');
    }
    return this._norisHubProxy;
  }

  /**
   * Gets the SignalR Hub connection status.
   * @returns {norisHubConnectionSubject} The SignalR Hub connection status subject.
   */
  public getNorisHubConnectionStatus(): BehaviorSubject<boolean> {
    return this.norisHubConnectionSubject;
  }

  /**
     * Registers a proxy for notifying pending changes.
     * @param {NotifiesPending} proxy - The proxy to register.
     * @returns {void}
     */
  public registerProxy(proxy: NotifiesPending): void {
    this.proxies.push(proxy);
  }

  /**
   * Creates a new instance of the SignalR Hub connection.
   * @returns {HubConnection} The newly created SignalR Hub connection.
   */
  private createNorisHubConnection(): void {
    const id = 'Html5_Client';
    const url: string = this.endpoint.entryPoint + '/signalr';

    if (!this._norisHubConnection) {
      this._norisHubConnection = new HubConnection(this.trace, this.ngZone);
      this._norisHubConnection.initHubConnection(url, this.authenticationServiceBase.userToken);
      SignalRBxSubstituteService.counter++;
    }
    this.norisHubConnectionSubject.next(true); // Notify consumers connection is established
    this.trace.info(TraceModules.signalR, 'createNorisHubConnection(); hub connection returned; id=%s', id);
  }

}
