import { CommandParameters } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { AnyValueType, StringValue } from '@simpl/element-value-types';
import { AnyPropertyValueType } from '@simpl/object-browser-ng';

import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType } from './command-vm.types';
import { ViewModelContext } from './snapin-vm.types';

/**
 * String parameter view-model implementation.
 */
export class StringParamViewModel extends CommandParamViewModel {

  // private min: number;
  // private max: number;
  private defaultVal: string;

  /**
   * Indicates if the string parameter represents a password.
   */
  public get isPassword(): boolean {
    return Boolean(this.param.IsPassword);
  }

  /**
   * Minimum allowed length.
   */
  // public get minLength(): number {
  //   return this.min;
  // }

  /**
   * Maximum allowed length.
   */
  // public get maxLength(): number {
  //   return this.max;
  // }

  /**
   * Default parameter value.
   */
  // public get defaultValue(): string {
  //   return this.defaultVal;
  // }

  /**
   * Parameter data type.
   */
  public get dataType(): CommandParamType {
    return CommandParamType.StringType;
  }

  /**
   * Constructor.
   */
  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    // Initialize the si-param value
    this.siParam.value = {
      type: 'string',
      readonly: false,
      optional: true,
      value: undefined
    } as StringValue;

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Encode the provided object into a a string value.
   */
  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let valEnc: string;
    if (valObj && valObj.type === 'string') {
      valEnc = String(valObj.value);
    }
    return valEnc;
  }

  public resetParamValue(): void {
    const siVal: StringValue = this.siParam.value as StringValue;
    siVal.value = this.defaultVal;
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.StringType) {
      return false; // undefined param or type mismatch!
    }
    const param: StringParamViewModel = p as StringParamViewModel;

    // If default value of alignment param does not match, clear the local default value
    if (this.defaultVal !== param.defaultVal) {
      this.defaultVal = undefined;
    }

    return true;
  }

  protected updateParamAttributes(): void {
    if (!this.param) {
      return;
    }
    const siVal: StringValue = this.siParam.value as StringValue;
    siVal.minLength = this.param.Min ? Number(this.param.Min) : undefined;
    siVal.maxLength = this.param.Max ? Number(this.param.Max) : undefined;
    // note that we intentionally distinguish between empty string and undefined!
    this.defaultVal = !isNullOrUndefined(this.param.DefaultValue) ? this.param.DefaultValue : undefined;
    this.resetParamValue();
  }
}
