/* eslint-disable @typescript-eslint/naming-convention */
enum TraceModulesEn {
  hfwServicesCommon_Translation,
  hfwServicesCommon_Utilities
}

export class TraceModules {
  public static translation: string = TraceModulesEn[TraceModulesEn.hfwServicesCommon_Translation];
  public static utilities: string = TraceModulesEn[TraceModulesEn.hfwServicesCommon_Utilities];
}
