import { Injectable } from '@angular/core';

import { ContentActions } from '../interfaces/scene-editor';

export interface SceneEditorConfig {
  /**
   * If true, the repetiotion time schedule will be available.
   * Default is false.
   */
  repetitionTime: boolean;
  viewContentActions?: ContentActions;
  invalidCharRegEx?: string;
  actionToDisable: {
    about: boolean;
    advanced: boolean;
    test: boolean;
    learnValues: boolean;
    lock: boolean;
    sort: boolean;
    executeSettings: boolean;
  };
  restrictSceneEdit?: boolean;
  restrictPropertyEdit?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SiSceneEditorConfigService {
  private config: SceneEditorConfig = {
    repetitionTime: false,
    invalidCharRegEx: '[.:?*&/;()<>\'"]',
    actionToDisable: {
      about: false,
      advanced: false,
      test: false,
      learnValues: false,
      lock: false,
      sort: false,
      executeSettings: true
    },
    restrictSceneEdit: false,
    restrictPropertyEdit: false
  };

  /**
   * Gets the configuration.
   */
  get(): SceneEditorConfig {
    return this.config;
  }

  /**
   * Updates the specified configuration values.
   */
  update(config: SceneEditorConfig): void {
    this.config = Object.assign(this.config, config);
  }
}
