@if (editMode) {
  <div class="bulk-header border-bottom row mb-5">
    <div class="col-md-6">
      <button type="button" class="btn btn-tertiary mr-6 me-6" (click)="back.emit()">
        <i class="icon element-left-2"></i>
        {{ 'OBJECT_BROWSER.DIALOG_TEXT_SHORT.BACK' | translate }}
      </button>
    </div>
    <si-property-list
      class="col-md-6"
      rowClass=""
      colClass=""
      [dateFormat]="dateFormat"
      [timeFormat]="timeFormat"
      [properties]="combinedProperties"
      [valueState]="combinedValueState"
      (submitted)="bulkSubmit($event)"
      (command)="bulkCommand($event)"
    />
  </div>
} @else {
  <div class="row">
    @for (prop of filteredBulkProps; track prop.id) {
      <div class="form-group mb-6 position-relative col-md-6 col-xl-4">
        <span>{{ prop.name }}</span>
        <div
          class="form-control d-flex align-items-center"
          tabindex="0"
          (click)="propertySelected(prop)"
          (keydown.enter)="propertySelected(prop)"
        >
          {{ 'OBJECT_BROWSER.DIALOG_TEXT_LONG.CLICK_TO_OPERATE' | translate }}
        </div>
      </div>
    }
    @if (filter && filteredBulkProps && !filteredBulkProps.length) {
      <si-empty-state
        class="w-100"
        icon="element-search"
        heading="OBJECT_BROWSER.DIALOG_TEXT_LONG.FILTER_NO_RESULTS"
      />
    }
  </div>
}
