@if (wlan) {
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.none]="numberValue < 1">
    <path
      d="M318.63,304a108.1,108.1,0,0,0-125.1,0,12,12,0,0,0,13.9,19.56,84.09,84.09,0,0,1,97.3,0A12,12,0,1,0,318.63,304Z"
    />
    <path
      d="M377,247.56a188,188,0,0,0-240.64,0A12,12,0,1,0,151.68,266a164,164,0,0,1,209.92,0A12,12,0,0,0,377,247.56Z"
      [class.inactive]="numberValue < 2"
    />
    <path
      d="M433.21,191a268,268,0,0,0-354.42,0,12,12,0,1,0,15.86,18,244,244,0,0,1,322.7,0,12,12,0,0,0,15.86-18Z"
      [class.inactive]="numberValue < 3"
    />
    <circle cx="256" cy="372.22" r="16" />
    @if (numberValue < 1) {
      <g>
        <path d="M412,352a12,12,0,0,0,12-12V263.78a12,12,0,0,0-24,0V340A12,12,0,0,0,412,352Z" />
        <path d="M412.18,400H412a12,12,0,0,0,0,24h.18a12,12,0,1,0,0-24Z" />
      </g>
    }
  </svg>
}

@if (!wlan) {
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.none]="numberValue < 1">
    <path
      d="M128,228a12,12,0,0,0,0,24A132.14,132.14,0,0,1,260,384a12,12,0,0,0,24,0C284,298,214,228,128,228Z"
      [class.inactive]="numberValue < 2"
    />
    <path
      d="M128,116a12,12,0,0,0,0,24A244,244,0,0,1,372,384a12,12,0,0,0,24,0A268,268,0,0,0,128,116Z"
      [class.inactive]="numberValue < 3"
    />
    <circle cx="144" cy="368" r="28" />
    @if (numberValue < 1) {
      <g>
        <path
          d="M412,188.11a12,12,0,0,0,12-12V99.89a12,12,0,1,0-24,0v76.22A12,12,0,0,0,412,188.11Z"
        />
        <path d="M412.18,236.11H412a12,12,0,0,0,0,24h.18a12,12,0,0,0,0-24Z" />
      </g>
    }
  </svg>
}
