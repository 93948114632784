import { Injectable } from '@angular/core';
import { BulkCommandInput, BulkCommandInput2, BulkCommandResponse, CommandInput,
  DpIdentifier, ExecuteCommandServiceBase, TraceModules, ValueSubscriptionProxyServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asyncScheduler, interval, map, Observable, tap, throwError, zip } from 'rxjs';

import { PointService } from '../../bx-services/point/point.service';
import { ValueSubscriptionBxSubstituteProxyService } from '../value-subscriptions/value-subscription-bx-substitute-proxy.service';
import { cbmsReleasePointValueCommandName } from './command-mapper-bx-to-gms.service';

@Injectable()
export class ExecuteCommandBxSubstituteService extends ExecuteCommandServiceBase {

  public constructor(private readonly traceService: TraceService,
    private readonly valueSubscriptionProxy: ValueSubscriptionProxyServiceBase,
    private readonly pointService: PointService) {
    super();
    this.traceService.info(TraceModules.command, 'ExecuteCommandBxSubstituteService created.');
  }

  /**
   * Command the value of a single propertyId.
   */
  public executeCommand(propertyId: string, commandId: string, commandInput: CommandInput[]): Observable<void> {
    if (propertyId === undefined || commandId === undefined) {
      return throwError(() => new Error(`ExecuteCommandBxSubstituteService.executeCommand(): Invalid arguments,
        propertyId: ${propertyId}, commandId: ${commandId}`));
    }
    this.traceService.debug(TraceModules.command, 
      `ExecuteCommandBxSubstituteService.executeCommand() called; propertyId: ${propertyId}; 
      commandId: ${commandId}; commandInput: ${commandInput ? commandInput.toString() : undefined}`);

    // TODO: To be removed!!! Only to collect some trend values
    this.checkSimValuesCreation(propertyId);

    const dpId = new DpIdentifier(propertyId);
    const valueToWrite = (commandId === cbmsReleasePointValueCommandName) ? null : commandInput[0].Value;
    return this.pointService.updatePointValue(dpId.systemName, dpId.objectIdWoSystem, valueToWrite).pipe(
      tap(_retVal => {
        asyncScheduler.schedule(() => {
          (this.valueSubscriptionProxy as ValueSubscriptionBxSubstituteProxyService).pollAndNotifySubscribedValues(2000);
        }, 0);
      }),
      
      map(_retVal => { return; })

      // Remark:
      // In case of a commanding error, throw an error in the map above (throw new Error('Test error');)
      // This will show the exclamation mark in the property field and a toast
    );
  }

  /**
   * Execute the same command on multiple properties of the same data point type.
   */
  public executeCommands(commandId: string, bulkCommandInput: BulkCommandInput): Observable<BulkCommandResponse> {
    if (commandId === undefined || bulkCommandInput === undefined) {
      return throwError(() => new Error(`ExecuteCommandBxSubstituteService.executeCommands(): Invalid arguments, commandId: ${commandId}`));
    }
    this.traceService.debug(TraceModules.command,
      `ExecuteCommandBxSubstituteService.executeCommands() called; commandId: ${commandId}; 
      commandInput: ${bulkCommandInput.CommandInputForExecution.toString()}; propertyIds: ${bulkCommandInput.PropertyIds.toString()}`);

    const updateValueCmds$: Observable<boolean>[] = [];
    for (let idx = 0; idx < bulkCommandInput.PropertyIds.length; idx++) {
      const dpId = new DpIdentifier(bulkCommandInput.PropertyIds[idx]);
      updateValueCmds$.push(
        this.pointService.updatePointValue(dpId.systemName, dpId.objectIdWoSystem, bulkCommandInput.CommandInputForExecution[idx].Value)
      );
    }

    return zip(updateValueCmds$).pipe(
      tap(result => {
        asyncScheduler.schedule(() => {
          (this.valueSubscriptionProxy as ValueSubscriptionBxSubstituteProxyService).pollAndNotifySubscribedValues(2000);
        }, 1);
      }),
      map(result => {
        /* eslint-disable @typescript-eslint/naming-convention */
        const bulkCmdRes: BulkCommandResponse = { Responses: [] };
        bulkCmdRes.Responses = bulkCommandInput.PropertyIds.map(id => ({ PropertyId: id, ErrorCode: 0 }));
        /* eslint-enable @typescript-eslint/naming-convention */
        // TODO: fix the property viewer or WSI!! to handle correct types!
        return bulkCmdRes.Responses as any;
      })
    );
  }

  /**
   * Execute the same command on multiple properties of the same data point type.
   */
  public executeCommands2(commandId: string, bulkCommandInput: BulkCommandInput2): Observable<BulkCommandResponse> {
    if (commandId === undefined || bulkCommandInput === undefined) {
      return throwError(() => new Error(`ExecuteCommandBxSubstituteService.executeCommands2(): Invalid arguments, commandId: ${commandId}`));
    }
    this.traceService.debug(TraceModules.command,
      `ExecuteCommandBxSubstituteService.executeCommands2() called; commandId: ${commandId}; 
      commandInput: ${bulkCommandInput.CommandInputForExecution.toString()}; propertyIds: ${bulkCommandInput.PropertyIds.toString()}`);

    const updateValueCmds$: Observable<boolean>[] = [];
    for (let idx = 0; idx < bulkCommandInput.PropertyIds.length; idx++) {
      const dpId = new DpIdentifier(bulkCommandInput.PropertyIds[idx]);
      updateValueCmds$.push(
        this.pointService.updatePointValue(
          dpId.systemName, dpId.objectIdWoSystem, bulkCommandInput.CommandInputForExecution[idx].Value)
      );
    }

    return zip(updateValueCmds$).pipe(
      tap(result => {
        asyncScheduler.schedule(() => {
          (this.valueSubscriptionProxy as ValueSubscriptionBxSubstituteProxyService).pollAndNotifySubscribedValues(2000);
        }, 1);
      }),
      map(result => {
        /* eslint-disable @typescript-eslint/naming-convention */
        const bulkCmdRes: BulkCommandResponse = { Responses: [] };
        bulkCmdRes.Responses = bulkCommandInput.PropertyIds.map(id => ({ PropertyId: id, ErrorCode: 0 }));
        /* eslint-enable @typescript-eslint/naming-convention */
        // TODO: fix the property viewer or WSI!! to handle correct types!
        return bulkCmdRes.Responses as any;
      })
    );
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  // TODO: remove code when no more PoC!!!

  private analogValue = -5;
  private boolValue = false;
  private boolValue2 = true;
  private enumValue = 1;
  private readonly extRealProp = '5fe31ef1-5fc8-49f3-84cc-263f17b656fb:99e37c1f-d91f-4bb2-a268-21de791d27b5.PresentValue';
  private extRealPropStarted = false;
  private readonly extBoolProp = '5fe31ef1-5fc8-49f3-84cc-263f17b656fb:d88572ff-9dbb-4fcc-a3fe-db6d5590acd7.PresentValue';
  private extBoolPropStarted = false;
  private readonly basicBoolProp = '5fe31ef1-5fc8-49f3-84cc-263f17b656fb:bbe56476-e4ec-4682-875f-72eab9bcac71.PresentValue';
  private basicBoolPropStarted = false;
  private readonly extEnumProp = '5fe31ef1-5fc8-49f3-84cc-263f17b656fb:11b203f0-bfdd-4736-b1d7-0d09267762b8.PresentValue';
  private extEnumPropStarted = false;

  private checkSimValuesCreation(propertyId: string): void {
    if ((propertyId === this.extRealProp) && (!this.extRealPropStarted)) {
      this.extRealPropStarted = true;
      this.createSimValuesExtReal(propertyId);
    }
    if ((propertyId === this.extBoolProp) && (!this.extBoolPropStarted)) {
      this.extBoolPropStarted = true;
      this.createSimValuesExtBool(propertyId);
    }
    if ((propertyId === this.basicBoolProp) && (!this.basicBoolPropStarted)) {
      this.basicBoolPropStarted = true;
      this.createSimValuesBasicBool(propertyId);
    }
    if ((propertyId === this.extEnumProp) && (!this.extEnumPropStarted)) {
      this.extEnumPropStarted = true;
      this.createSimValuesExtEnum(propertyId);
    }
  }

  private createSimValuesExtReal(propertyId: string): void {
    interval(25000).subscribe(counter => {
      // Command 1 object
      /* eslint-disable @typescript-eslint/naming-convention */
      this.executeCommand(propertyId, 'Write',
        [{
          Name: 'Value',
          DataType: 'ExtendedReal',
          Value: this.analogValue.toString(),
          Comments: undefined,
          Password: undefined }
        ]).subscribe();
      /* eslint-enable @typescript-eslint/naming-convention */
      this.analogValue = this.analogValue + 0.93;
      if (this.analogValue > 50) {
        this.analogValue = -5;
      }
    });
  }

  private createSimValuesExtBool(propertyId: string): void {
    interval(15000).subscribe(counter => {
      // Command 2 object
      /* eslint-disable @typescript-eslint/naming-convention */
      this.executeCommand(propertyId, 'Write',
        [{
          Name: 'Value',
          DataType: 'ExtendedBool',
          Value: this.boolValue.toString(),
          Comments: undefined,
          Password: undefined }
        ]).subscribe();
      /* eslint-enable @typescript-eslint/naming-convention */
      this.boolValue = !this.boolValue;
    });
  }

  private createSimValuesBasicBool(propertyId: string): void {
    interval(15000).subscribe(counter => {
      // Command 3 object
      /* eslint-disable @typescript-eslint/naming-convention */
      this.executeCommand(propertyId, 'Write',
        [{
          Name: 'Value',
          DataType: 'BasicBool',
          Value: this.boolValue2.toString(),
          Comments: undefined,
          Password: undefined }
        ]).subscribe();
      /* eslint-enable @typescript-eslint/naming-convention */
      this.boolValue2 = !this.boolValue2;
    });
  }

  private createSimValuesExtEnum(propertyId: string): void {
    interval(60000).subscribe(counter => {
      // Command 4 object
      /* eslint-disable @typescript-eslint/naming-convention */
      this.executeCommand(propertyId, 'Write',
        [{
          Name: 'Value',
          DataType: 'ExtendedEnum',
          Value: this.enumValue.toString(),
          Comments: undefined,
          Password: undefined }
        ]).subscribe();
      /* eslint-enable @typescript-eslint/naming-convention */
      this.enumValue++;
      if (this.enumValue > 8) {
        this.enumValue = 1;
      }
    });
  }

  /* eslint-enable @typescript-eslint/member-ordering */
}
