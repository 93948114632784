import { GmsElementType } from '../types/gms-element-types';
import { StretchType } from '../types/gms-image-types';
import { Guid } from '../utilities/guid';
import { SvgUtility } from '../utilities/parser';
import { GmsElement } from './gms-element';

export class GmsXps extends GmsElement {

  private static readonly GMS_SCALE_TRANSFORM_GROUP: string = 'ScaleTransformGroup';
  private static readonly GMS_XPS_CONTENT_TRANSFORM: string = 'GmsXpsContentTransform';
  private static readonly GMS_XPS_CONTENT: string = 'GmsXpsContent';

  /**
   * SVG Node containing content from XPS file
   */
  private _content: Node;
  public get Content(): Node {
    return this._content;
  }
  public set Content(value: Node) {

    if (this._content !== value) {
      this._content = value;

      this.NotifyPropertyChanged('Content');
    }
  }

  private _viewboxScaleX = 1;
  private _viewboxScaleY = 1;

  private _viewBox = '';
  public get ViewBox(): string {
    const width: number = this.Width / this._viewboxScaleX;
    const height: number = this.Height / this._viewboxScaleY;
    this._viewBox = '0 0 ' + width + ' ' + height;
    return this._viewBox;
  }

  public UpdateWidthResize(factor: number): void {
    if (factor > 0) {
      super.UpdateWidthResize(factor);
      this._viewboxScaleX = factor;
      this.NotifyPropertyChanged('ViewBox');
    }
  }

  // Called only for first level children of GmsGroup or GmsSymbolInstance
  public UpdateHeightResize(factor: number): void {
    if (factor > 0) {
      super.UpdateHeightResize(factor);
      this._viewboxScaleY = factor;
      this.NotifyPropertyChanged('ViewBox');
    }
  }

  constructor() {
    super(GmsElementType.Xps);
  }

  public async ShapeChanged(): Promise<any> {
    this.NotifyPropertyChanged('ShapeChanged');
  }

  public Deserialize(node: Node): void {

    if (node === undefined) {
      return;
    }

    super.Deserialize(node);

    // 1. Handle the ScaleTransformGroup group
    let innerNode: Node;
    const classTag = 'Class';
    let attrClass: Attr;
    if (node.hasChildNodes) {
      const nodes: NodeList = node.childNodes;

      // find child node with class: "ScaleTransformGroup"
      for (let i = 0; i < nodes.length; i++) {
        innerNode = nodes.item(i);
        const innerNodeEl: Element = innerNode as Element;
        attrClass = innerNodeEl.attributes !== undefined ? innerNodeEl.attributes[classTag] : undefined;
        if (attrClass !== undefined && attrClass.nodeValue === GmsXps.GMS_SCALE_TRANSFORM_GROUP && (innerNode.hasChildNodes)) {
          break;
        } else {
          innerNode = undefined;
        }
      }
    }
    if (innerNode === undefined) {
      // ScaleTransformGroup not found, assign the original node
      innerNode = node;
    }
    // 2. Handle the GmsXpsContentTransform group
    for (let i: number = (innerNode.childNodes.length - 1); i >= 0; i--) {
      const child: Node = innerNode.childNodes[i];
      if (child.nodeName === 'g') {
        const childEl: Element = child as Element;
        attrClass = childEl.attributes !== undefined ? childEl.attributes[classTag] : undefined;
        if (attrClass !== undefined && child.hasChildNodes &&
                    attrClass.nodeValue === GmsXps.GMS_XPS_CONTENT_TRANSFORM ||
                    // 3. Handle the GmsXpsContent group
                    attrClass.nodeValue === GmsXps.GMS_XPS_CONTENT) {
          innerNode = child;
          break;
        }
      }
    }

    // 3. Give the clipPath nodes unique ids
    const innerNodeElement: Element = innerNode as Element;
    const nodes: NodeListOf<Element> = innerNodeElement.querySelectorAll('g[clip-path]');
    const nodeArr: Element[] = Array.from(nodes);

    if (nodeArr !== undefined) {
      for (const nodeElement of nodeArr) {
        const xpsClipPathId: string = Guid.newGuid();
        const nodeElementChildren: HTMLCollection = nodeElement.children;

        // last child: clipPath node
        if (nodeElementChildren !== undefined && nodeElementChildren.length > 0) {
          const lastIndex: number = nodeElementChildren.length - 1;
          const lastChildElement: Element = nodeElementChildren[lastIndex];

          const clipPathTagName = 'clipPath';
          const isClipPathNode: boolean = lastChildElement !== undefined && lastChildElement.tagName === clipPathTagName;

          if (isClipPathNode) {
            nodeElement.setAttribute('clip-path', `url(#${xpsClipPathId})`);
            lastChildElement.setAttribute('id', xpsClipPathId);
          }
        }
      }
    }

    this.Content = innerNode;

    super.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsXps): void {
    this.IsCopying = true;

    super.CopyFrom(element);
    this.Content = element.Content?.cloneNode(true);
    super.CopyEvaluations(element);

    this.IsCopying = false;
  }
}
