import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PropertySnapInComponent } from './snapin/property-snapin.component';

const propertySnapInRoutes: Routes = [
  {
    path: '',
    component: PropertySnapInComponent
  }
];

export const propertySnapInRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(propertySnapInRoutes);
