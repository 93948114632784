import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'si-formly-object-plain',
  templateUrl: './si-formly-object-plain.component.html',
  standalone: true,
  imports: [FormlyModule]
})
export class SiFormlyObjectPlainComponent extends FieldType {}
