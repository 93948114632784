import {
  BulkCommandInput,
  BulkCommandInput2,
  BulkCommandResponse,
  CommandInput, CommandInput2,
  CommandResponse,
  CommentsInput,
  ExecuteCommandServiceBase,
  ValidationProps
} from '@gms-flex/services';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

import { Common } from '../shared/common';

/**
 * Command invocation object states.
 */
export enum CommandInvocState {

  // Command has not yet been executed
  Initial,

  // Command is executing
  Executing,

  // Command completed successfully
  Success,

  // Command completed with an error
  Error
}

/**
 * Represents an instance of a command execution.
 * Can be single, bulk, or sub (part of a bulk) command depending on derived type.
 */
export abstract class CommandInvoc {

  protected observer: Observer<void>;
  protected cmdState: CommandInvocState;
  protected errMesg: string;

  /**
   * Command id of the invoked command.
   */
  public get id(): string {
    return this.cmdId;
  }

  /**
   * Command invocation state.
   */
  public get state(): CommandInvocState {
    return this.cmdState;
  }

  /**
   * Command error in the event the command completes in an error state.
   */
  public get error(): string {
    return this.errMesg;
  }

  /**
   * Indicates if the command is complete.
   */
  public get isComplete(): boolean {
    return (this.cmdState === CommandInvocState.Error || this.cmdState === CommandInvocState.Success);
  }

  public abstract get isSubCommandInvoc(): boolean;

  /**
   * Constructor.
   */
  protected constructor(
    protected readonly cmdId: string) {

    this.cmdState = CommandInvocState.Initial;
  }

  protected setState(state: CommandInvocState, errMesg?: string): void {
    if (this.isComplete) {
      return; // no transitions once complete!
    }
    this.cmdState = state;
    this.errMesg = this.cmdState === CommandInvocState.Error ? errMesg : undefined;
    if (this.observer) {
      this.observer.next(undefined);
      if (this.isComplete) {
        this.observer.complete();
      }
    }
  }

}

/**
 * Represents a command execution issued on and by a single object-property instance.
 */
export class SingleCommandInvoc extends CommandInvoc {

  public get isSubCommandInvoc(): boolean {
    return false;
  }

  public constructor(
    public readonly objectAndPropertyId: string,
    cmdId: string,
    private readonly cmdArgs: CommandInput[]) {

    super(cmdId);
  }

  /**
   * Execute command.
   */
  public execute(executeService: ExecuteCommandServiceBase): Observable<void> {
    if (this.observer) {
      throw new Error(`Command cannot be executed more than once: cmdId=${this.cmdId}`);
    }

    return new Observable((o: Observer<void>) => {
      this.observer = o;

      this.setState(CommandInvocState.Executing);
      executeService.executeCommand(this.objectAndPropertyId, this.cmdId, this.cmdArgs).subscribe(
        () => {
          this.setState(CommandInvocState.Success);
        },
        (err: any) => {
          const errMesg: string = err?.message ? String(err.message) : undefined;
          this.setState(CommandInvocState.Error, errMesg);
        }
      );
    });
  }
}

/**
 * Represents a bulk command execution on a set of object-properties issued by an
 * aggregate-property instance.
 */
export class BulkCommandInvoc extends CommandInvoc {

  private executeService: ExecuteCommandServiceBase;

  private readonly pendingCommandList: SubCommandInvoc[];
  private isSuccess: boolean;
  private errMesgGeneral: string;

  private readonly sequencesInParallel: number = 2;
  private readonly segmentSize: number;

  public get isSubCommandInvoc(): boolean {
    return false;
  }

  public constructor(
    subCommandList: SubCommandInvoc[],
    cmdId: string,
    private readonly cmdArgs: CommandInput[]) {

    super(cmdId);

    // Create local array of sub-commands since we will be removing from it as we go
    this.pendingCommandList = subCommandList ? subCommandList.slice(0) : [];

    // Calculate command segment size
    const size: number = Math.trunc(this.pendingCommandList.length / this.sequencesInParallel);
    this.segmentSize = Math.max(1, Math.min(10, size));
  }

  /**
   * Execute command.
   */
  public execute(executeService: ExecuteCommandServiceBase): Observable<void> {
    if (this.observer) {
      throw new Error(`Command cannot be executed more than once: cmdId=${this.cmdId}`);
    }

    this.executeService = executeService;

    return new Observable((o: Observer<void>) => {
      this.observer = o;

      // Parallel sequences of execution
      this.isSuccess = true; // true until the first execution error
      this.setState(CommandInvocState.Executing);
      for (let seq = 0; seq < this.sequencesInParallel; ++seq) {
        this.executeNext();
      }
    });
  }

  private getCommandInput2Arr(commandInputArr: CommandInput[]): CommandInput2[] {
    const commandInput2Arr: CommandInput2[] = [];
    for (const commandInput of commandInputArr) {
      const commandInput2: CommandInput2 = {
        Name: commandInput.Name,
        DataType: commandInput.DataType,
        Value: commandInput.Value
      };

      commandInput2Arr.push(commandInput2);
    }

    return commandInput2Arr;
  }

  private getValidationProps(): ValidationProps {
    const commandInput: CommandInput = this.cmdArgs?.[0];
    const emptyCommentsInput: CommentsInput = {
      CommonText: '',
      MultiLangText: []
    };

    const validationProps: ValidationProps = {
      Comments: commandInput?.Comments || emptyCommentsInput,
      Password: commandInput?.Password || '',
      SuperName: commandInput?.SuperName || '',
      SuperPassword: commandInput?.SuperPassword || '',
      SessionKey: commandInput?.SessionKey || ''
    };

    return validationProps;
  }

  private executeNext(): void {

    if (this.pendingCommandList.length > 0) {
      // Collect next set of commands to execute
      const invocList: SubCommandInvoc[] = this.getNext(this.segmentSize);
      const cmdArgs2: CommandInput2[] = this.getCommandInput2Arr(this.cmdArgs);
      const validationProps: ValidationProps = this.getValidationProps();

      if (invocList.length > 0) {
        // Build bulk command input
        const bci: BulkCommandInput2 = {
          // NOTE: Don't pass undefined args; use empty array instead.  Will cause exception in service for bulk cmd
          /* eslint-disable */
          CommandInputForExecution: cmdArgs2 || [],
          PropertyIds: invocList.map(invoc => invoc.objectAndPropertyId),
          Comments: validationProps.Comments,
          Password: validationProps.Password,
          SuperName: validationProps.SuperName,
          SuperPassword: validationProps.SuperPassword,
          SessionKey: validationProps.SessionKey
          /* eslint-enable */
        };

        // Execute bulk command
        invocList.forEach(invoc => invoc.setCommandState(CommandInvocState.Executing));
        this.executeService.executeCommands2(this.cmdId, bci).subscribe(bulkResp => this.executeCommandsCallback(invocList, bulkResp),
          (err: any) => this.executeCommandsError(invocList, err)
        );
      } else {
        // pending command list is not empty, but all remaining commands have been executed.
        // no more requests to send
      }
    } else {
      // Pending command list empty; bulk command is now complete
      this.setState(this.isSuccess ? CommandInvocState.Success : CommandInvocState.Error, this.errMesgGeneral);
    }
  }

  private executeCommandsCallback(invocList: SubCommandInvoc[], bulkResp: BulkCommandResponse): void {
    const cmdResp: CommandResponse[] = bulkResp as any;

    // bulkResp.Responses.forEach(resp => {
    cmdResp.forEach(resp => {
      const pid: string = Common.trimSystemName(resp.PropertyId);
      const invoc: SubCommandInvoc = invocList.find(i => i.objectAndPropertyId.includes(pid));
      if (invoc) {
        // Set sub-command state
        if (resp.ErrorCode === 0) {
          invoc.setCommandState(CommandInvocState.Success);
        } else {
          const errMesg: string = /* resp.ErrorMesg */ undefined; // No error message in response!
          invoc.setCommandState(CommandInvocState.Error, errMesg);

          // Set bulk-command state to error
          this.isSuccess = false;
          this.errMesgGeneral = this.errMesgGeneral || errMesg; // first error mesg received will be used as general error message
        }

        // Remove sub-command from pending list
        const idx: number = this.pendingCommandList.indexOf(invoc);
        if (idx >= 0) {
          this.pendingCommandList.splice(idx, 1);
        }
      } else {
        // NOTE: trace invalid response
      }
    });

    this.executeNext();
  }

  private executeCommandsError(invocList: SubCommandInvoc[], err: any): void {
    const errMesg: string = err?.message ? String(err.message) : undefined;
    this.isSuccess = false;
    this.errMesgGeneral = this.errMesgGeneral || errMesg; // first error mesg received will be used as general error message

    // Mark all sub-commands as complete in error
    invocList.forEach(invoc => {
      invoc.setCommandState(CommandInvocState.Error, errMesg);
      const idx: number = this.pendingCommandList.indexOf(invoc);
      if (idx >= 0) {
        this.pendingCommandList.splice(idx, 1); // remove command from pending list
      }
    });

    this.executeNext();
  }

  private getNext(segmentSize: number): SubCommandInvoc[] {
    const size: number = Math.max(1, segmentSize);
    const invocList: SubCommandInvoc[] = [];

    if (this.pendingCommandList) {
      for (let i = 0; i < this.pendingCommandList.length && invocList.length < size; ++i) {
        if (this.pendingCommandList[i].state === CommandInvocState.Initial) {
          invocList.push(this.pendingCommandList[i]);
        }
      }
    }
    return invocList;
  }

}

/**
 * Represents an object-property command execution being processed as part of a larger
 * bulk command execution.
 */
export class SubCommandInvoc extends CommandInvoc {

  private readonly stateChangedEvent: BehaviorSubject<void>;

  /**
   * Used by object-property instance to track changes in command being executed as
   * part of a bulk command issued by aggregate-property instance.
   */
  public get stateChanged(): Observable<void> {
    return this.stateChangedEvent;
  }

  public get isSubCommandInvoc(): boolean {
    return true;
  }

  /**
   * Constructor.
   */
  public constructor(
    public objectAndPropertyId: string,
    cmdId: string) {

    super(cmdId);

    this.stateChangedEvent = new BehaviorSubject(undefined);
    this.observer = this.stateChangedEvent;
  }

  /**
   * Used by `BulkCommandInvoc` to set sub-command state per results received in bulk
   * command execution.
   */
  public setCommandState(state: CommandInvocState, errMesg?: string): void {
    this.setState(state, errMesg);
  }

}
