import { ExtendedStatusType } from "@simpl/element-ng";

export enum NotificationState {
  Active = 1,
  Dismissed = 0
}

export class Notification {
  public id!: number;
  private actions!: Action[];
  private icon = '';
  private iconBg = '#FFFFF';
  private title!: string;
  private text!: string;
  private toastTitle!: string;
  private toastText!: string;
  private autoCancel = false;
  private sender!: string;
  private customData: any = undefined;
  private overwrite = false;
  private readonly _timestamp: Date = new Date();
  private _state: NotificationState = NotificationState.Active;

  public constructor(id: number) {
    this.id = id;
  }

  public setActions(action: Action[]): Notification {
    this.actions = action;
    return this;
  }

  public getActions(): Action[] {
    return this.actions;
  }

  public setIcon(icon: string): Notification {
    if (icon !== undefined && icon !== '') {
      this.icon = icon;
    }
    return this;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setIconBg(bg: string): Notification {
    if (bg !== undefined && bg !== '') {
      this.iconBg = bg;
    }
    return this;
  }

  public getIconBg(): string {
    return this.iconBg;
  }

  public setTitle(title: string): Notification {
    if (title !== undefined) {
      this.title = title;
    }
    return this;
  }

  public getTitle(): string {
    return this.title;
  }

  public setOverwrite(overwrite: boolean): Notification {
    this.overwrite = overwrite;
    return this;
  }

  public getOverwrite(): boolean {
    return this.overwrite;
  }

  public setText(text: string): Notification {
    if (text !== undefined) {
      this.text = text;
    }
    return this;
  }

  public getText(): string {
    return this.text;
  }

  public setToastTitle(title: string): Notification {
    if (title !== undefined) {
      this.toastTitle = title;
    }
    return this;
  }

  public getToastTitle(): string {
    return this.toastTitle;
  }

  public setToastText(text: string): Notification {
    if (text !== undefined) {
      this.toastText = text;
    }
    return this;
  }

  public getToastText(): string {
    return this.toastText;
  }

  public setAutoCancel(autoCancel: boolean): Notification {
    this.autoCancel = autoCancel;
    return this;
  }

  public getAutoCancel(): boolean {
    return this.autoCancel;
  }

  public setSender(sender: string): Notification {
    this.sender = sender;
    return this;
  }

  public getSender(): string {
    return this.sender;
  }

  public setCustomData(customData: any): Notification {
    this.customData = customData;
    return this;
  }

  public getCustomData(): any {
    return this.customData;
  }

  public getState(): NotificationState {
    return this._state;
  }

  public cancel(): void {
    this._state = NotificationState.Dismissed;
  }

  public getTimeStamp(): Date {
    return this._timestamp;
  }
}

export class CustomData {
  public name!: string;
  public label!: string;
  public radioValue?: any;
  public data!: CustomSetting[];
  public color?: any;
  public id?: any;
  public override = false;
  public status?: ExtendedStatusType;
}

export class CustomSetting {
  public name!: string;
  public label!: string;
  public value: any;
  public color?: any;
}

export class NotifConfiguration {
  private expanded = true;
  private readonly description: string;
  private show = true;
  private sound = true;
  private icon = '';
  private toast = 'none';
  private customSound = false;
  private customData!: CustomData[];
  private customSettings!: CustomSetting[];
  private persistent = false;
  private groupAction = true;

  public constructor(description: string) {
    this.description = description;
    this.expanded = true;
  }

  public setShow(show: boolean): NotifConfiguration {
    this.show = show;
    return this;
  }

  public getDescription(): string {
    return this.description;
  }

  public getShow(): boolean {
    return this.show;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setSound(sound: boolean): NotifConfiguration {
    this.sound = sound;
    return this;
  }

  public getSound(): boolean {
    return this.sound;
  }

  public setPersistent(persistent: boolean): NotifConfiguration {
    this.persistent = persistent;
    return this;
  }

  public getPersistent(): boolean {
    return this.persistent;
  }

  public setGroupaction(action: boolean): NotifConfiguration {
    this.groupAction = action;
    return this;
  }

  public getGroupaction(): boolean {
    return this.groupAction;
  }

  public setIcon(icon: string): NotifConfiguration {
    this.icon = icon;
    return this;
  }

  public getToast(): string {
    return this.toast;
  }

  public setToast(toast: string): NotifConfiguration {
    this.toast = toast;
    return this;
  }

  public setCustomSound(customSound: boolean): NotifConfiguration {
    this.customSound = customSound;
    return this;
  }

  public getCustomSound(): boolean {
    return this.customSound;
  }

  public getCustomData(): CustomData[] {
    return this.customData;
  }

  public setCustomData(customData: CustomData[]): NotifConfiguration {
    this.customData = customData;
    return this;
  }

  public getCustomSettings(): CustomSetting[] {
    return this.customSettings;
  }

  public setCustomSettings(customSettings: CustomSetting[]): NotifConfiguration {
    this.customSettings = customSettings;
    return this;
  }

  public getExpanded(): boolean {
    return this.expanded;
  }

  public setExpanded(expanded: boolean): NotifConfiguration {
    this.expanded = expanded;
    return this;
  }
}

type Callback = (notification: Notification, action: Action) => void;

export class Action {
  public name: string;
  private description!: string;
  private callback!: Callback;

  public constructor(name: string) {
    this.name = name;
  }

  public setDescription(description: string): Action {
    this.description = description;
    return this;
  }

  public getDescription(): string {
    return this.description;
  }

  public setCallback(callback: Callback): Action {
    this.callback = callback;
    return this;
  }

  public getCallback(): Callback {
    return this.callback;
  }
}
