import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { SimplObjectBrowserCommonNgModule } from '@simpl/object-browser-ng/common';
import {
  FakePropertyApiService,
  PropertyApi,
  SiBACnetDateTimePickerModule,
  SiBigNumberPropertyModule,
  SiBitstringPropertyModule,
  SiBlindPropertyModule,
  SiBooleanPropertyModule,
  SiCollectionPropertyModule,
  SiDatapointModule,
  SiDatePropertyModule,
  SiDatetimePropertyModule,
  SiDefaultPropertyModule,
  SiEnumPropertyModule,
  SiNumberPropertyModule,
  SiObjectidPropertyModule,
  SiPriorityArrayPropertyModule,
  SiPropertyPopoverModule,
  SiPropertyService,
  SiPropertyViewerModule,
  SiStringPropertyModule,
  SiTimeDurationModule,
  SiTimedurationPropertyModule,
  SiTimePropertyModule
} from '@simpl/object-browser-ng/property-viewer';
import { SimplSceneEditorNgModule } from '@simpl/object-browser-ng/scene-editor';

@NgModule({
  exports: [
    SiBACnetDateTimePickerModule,
    SiBigNumberPropertyModule,
    SiBitstringPropertyModule,
    SiBlindPropertyModule,
    SiBooleanPropertyModule,
    SiCollectionPropertyModule,
    SiDatapointModule,
    SiDatePropertyModule,
    SiDatetimePropertyModule,
    SiDefaultPropertyModule,
    SiEnumPropertyModule,
    SimplObjectBrowserCommonNgModule,
    SimplSceneEditorNgModule,
    SiNumberPropertyModule,
    SiObjectidPropertyModule,
    SiPriorityArrayPropertyModule,
    SiPropertyPopoverModule,
    SiPropertyViewerModule,
    SiStringPropertyModule,
    SiTimeDurationModule,
    SiTimedurationPropertyModule,
    SiTimePropertyModule
  ]
})
export class SimplObjectBrowserNgModule {
  static forRoot(
    config: { propertyApi?: Provider } = {}
  ): ModuleWithProviders<SimplObjectBrowserNgModule> {
    return {
      ngModule: SimplObjectBrowserNgModule,
      providers: [
        config.propertyApi ?? { provide: PropertyApi, useClass: FakePropertyApiService },
        SiPropertyService
      ]
    };
  }
}
