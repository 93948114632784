import { TraceService } from '@gms-flex/services-common';

/**
 * Delegate class that wraps the `TraceService` for the following purposes:
 *
 * - Eliminate the need to specify the trace module-name as the first argument of
 * every method to the TraceService.  This, in turn, eliminates the common problem of
 * the caller accidentally omitting this parameter, which is not caught by the TS compiler
 * since it still satisfies the call signature of `error`, `warn`, `info`, and `debug`.
 *
 * - Checks the enabled-state of `info` and `debug` messages on the trance channel and,
 * if not enabled, skips the potentially expensive work of formatting a message that is
 * destined to go unused.
 *
 * Notice that the native TraceService to be wrapped can be specified in the c'tor or left
 * undefined to be assigned later using the `native` property setter.  This allows a consumer
 * to create the delegate in it's own c'tor without yet having obtained the trace service and
 * allow code within it to harmlessly trace messages (no undefined obj exeption) in the cases
 * where the native service cannot be obtained until later in the consumer's life-cycle.
 */
export class TraceServiceDelegate {

  private readonly prefix: string = '';

  public get native(): TraceService {
    return this.svc;
  }

  public set native(t: TraceService) {
    this.svc = t;
  }

  public constructor(
    private svc: TraceService,
    private readonly moduleName: string,
    pre?: string) {

    if (!moduleName) {
      throw new Error('invalid argument');
    }
    if (pre) {
      this.prefix = `${pre} `; // add a space to end of non-empty prefix for separation from message
    }
  }

  public error(message?: string, ...optionalParams: any[]): void {
    if (!this.svc) {
      return;
    }
    this.svc.error(this.moduleName, `${this.prefix}${message}`, ...optionalParams);
  }

  public warn(message?: string, ...optionalParams: any[]): void {
    if (!this.svc) {
      return;
    }
    this.svc.warn(this.moduleName, `${this.prefix}${message}`, ...optionalParams);
  }

  public info(message?: string, ...optionalParams: any[]): void {
    if (!this.svc?.isInfoEnabled(this.moduleName)) {
      return;
    }
    this.svc.info(this.moduleName, `${this.prefix}${message}`, ...optionalParams);
  }

  public debug(message?: string, ...optionalParams: any[]): void {
    if (!this.svc?.isDebugEnabled(this.moduleName)) {
      return;
    }
    this.svc.debug(this.moduleName, `${this.prefix}${message}`, ...optionalParams);
  }
}
