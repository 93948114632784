import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  QueryList
} from '@angular/core';
import { BackgroundColorVariant, WebComponentContentChildren } from '@simpl/element-ng/common';
import { ResizeObserverService } from '@simpl/element-ng/resize-observer';
import { Subscription } from 'rxjs';

import { SiAccordionHCollapseService } from './si-accordion-hcollapse.service';
import { SiAccordionService } from './si-accordion.service';
import { SiCollapsiblePanelComponent } from './si-collapsible-panel.component';

const PANEL_MIN_HEIGHT = 100;

@Component({
  selector: 'si-accordion',
  template: '<div><ng-content /></div>',
  providers: [SiAccordionService],
  styleUrl: './si-accordion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SiAccordionComponent implements AfterContentInit, OnChanges, OnDestroy {
  @Input({ transform: booleanAttribute }) expandFirstPanel = true;
  @HostBinding('class.full-height') @Input({ transform: booleanAttribute }) fullHeight = false;
  @HostBinding('class.hcollapsed') @Input({ transform: booleanAttribute }) hcollapsed = false;
  /** Color to use for component background */
  @HostBinding('class') @Input() colorVariant?: BackgroundColorVariant;

  @WebComponentContentChildren(SiCollapsiblePanelComponent)
  @ContentChildren(SiCollapsiblePanelComponent)
  private panels!: QueryList<SiCollapsiblePanelComponent>;
  private responsive = false;
  private subscription?: Subscription;

  private service = inject(SiAccordionService);
  private resizeObserver = inject(ResizeObserverService);
  private element = inject(ElementRef);
  private cdRef = inject(ChangeDetectorRef);
  private accordionHCollapseService = inject(SiAccordionHCollapseService, { optional: true });

  ngOnChanges(): void {
    this.service.fullHeight$.next(this.fullHeight && !this.responsive);
  }

  ngAfterContentInit(): void {
    this.subscription = this.resizeObserver
      .observe(this.element.nativeElement, 100, true, true)
      .subscribe(() => this.calcFullHeight());
    if (this.accordionHCollapseService) {
      this.subscription.add(
        this.accordionHCollapseService.hcollapsed$.subscribe(state => {
          this.hcollapsed = state;
          this.cdRef.markForCheck();
        })
      );
    }
    if (this.panels.length) {
      this.panels.first.openClose(this.expandFirstPanel, false);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private calcFullHeight(): void {
    if (this.panels?.length) {
      const height = (this.element.nativeElement as HTMLElement).offsetHeight;
      this.responsive = !this.hcollapsed && height < this.panels.length * PANEL_MIN_HEIGHT;
      this.service.fullHeight$.next(this.fullHeight && !this.responsive);
    }
  }
}
