@if (overwritten !== null) {
  <si-icon-status
    class="si-commissioning-status-item me-2"
    icon="element-manual-filled"
    color="status-warning"
    [count]="overwritten"
    [disabled]="disabled"
  />
}
@if (checked !== null) {
  <si-icon-status
    class="si-commissioning-status-item me-2"
    icon="element-circle-filled"
    stackedIcon="element-state-tick"
    color="status-success"
    [count]="checked"
    [disabled]="disabled"
  />
}
@if (failed !== null) {
  <si-icon-status
    class="si-commissioning-status-item me-2"
    icon="element-circle-filled"
    stackedIcon="element-state-exclamation-mark"
    color="status-danger"
    [count]="failed"
    [disabled]="disabled"
  />
}
@if (unchecked !== null) {
  <si-icon-status
    class="si-commissioning-status-item"
    icon="element-not-checked"
    [count]="unchecked"
    [disabled]="disabled"
  />
}
<ng-content />
