import { FocusableOption } from '@angular/cdk/a11y';
import { CdkContextMenuTrigger, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { correctKeyRTL, MenuItem } from '@simpl/element-ng/common';
import { SiLoadingSpinnerComponent } from '@simpl/element-ng/loading-spinner';
import { SiMenuFactoryComponent } from '@simpl/element-ng/menu';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { asyncScheduler, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SiTreeViewItemTemplateDirective } from '../si-tree-view-item-template.directive';
import {
  CheckboxClickEventArgs,
  ClickEventArgs,
  DEFAULT_TREE_ICON_SET,
  FolderStateEventArgs,
  TreeItem,
  TreeViewIconSet
} from '../si-tree-view.model';
import { SiTreeViewService } from '../si-tree-view.service';
import {
  boxClicked,
  doFolderStateChange,
  removeUndefinedState,
  setActive
} from '../si-tree-view.utils';

export type MenuItemsProvider = (item: TreeItem) => MenuItem[] | null;

@Component({
  selector: 'si-tree-view-item',
  templateUrl: './si-tree-view-item.component.html',
  styleUrl: './si-tree-view-item.component.scss',
  standalone: true,
  imports: [
    CdkContextMenuTrigger,
    CdkMenuTrigger,
    NgClass,
    NgTemplateOutlet,
    SiLoadingSpinnerComponent,
    SiMenuFactoryComponent,
    SiTranslateModule
  ],
  host: { role: 'treeitem' }
})
export class SiTreeViewItemComponent
  implements OnInit, OnDestroy, AfterViewInit, FocusableOption, DoCheck
{
  private element = inject(ElementRef);
  private siTreeViewService = inject(SiTreeViewService);
  private cdRef = inject(ChangeDetectorRef);

  /**
   * @defaultref {@link _indentLevel}
   */
  @Input() set indentLevel(value: number) {
    this._indentLevel = value;
  }

  @Input() treeItem!: TreeItem;
  @Input() scrollIntoView: Subject<TreeItem> = new Subject<TreeItem>();
  @Input() childrenLoaded: Subject<TreeItem> = new Subject<TreeItem>();

  @Input() templates?: QueryList<SiTreeViewItemTemplateDirective>;

  private _contextMenuItems: MenuItem[] | MenuItemsProvider | null = [];

  /**
   * @defaultref {@link _contextMenuItems}
   */
  @Input() set contextMenuItemsProvider(items: MenuItem[] | MenuItemsProvider) {
    this._contextMenuItems = items;
  }

  private readonly _noActions = [
    { title: this.siTreeViewService.noActionsString, isHeading: true }
  ];

  private _contextMenuItemsCurrent?: MenuItem[] | null;

  get contextMenuItems(): MenuItem[] | null {
    if (this._contextMenuItemsCurrent) {
      return this._contextMenuItemsCurrent;
    }
    if (this._contextMenuItems) {
      if (Array.isArray(this._contextMenuItems)) {
        return this._contextMenuItems;
      } else {
        this._contextMenuItemsCurrent = this._contextMenuItems(this.treeItem);
        if (!this._contextMenuItemsCurrent?.length) {
          this._contextMenuItemsCurrent = this._noActions;
        }
        return this._contextMenuItemsCurrent;
      }
    } else {
      return null;
    }
  }

  get isContextMenuButtonVisible(): boolean {
    return (
      this.enableContextMenuButton &&
      !!this._contextMenuItems &&
      (!Array.isArray(this._contextMenuItems) || !!this._contextMenuItems.length)
    );
  }

  @Input({ transform: booleanAttribute }) stickyEndItems = false;
  @Input({ transform: booleanAttribute }) displayFolderState = false;

  @Input() icons: TreeViewIconSet = DEFAULT_TREE_ICON_SET;

  private savedElement: ElementRef | undefined;
  private subscriptions: Subscription[] = [];
  private _indentLevel = 0;
  private nextSiblingElement!: HTMLElement;
  @ViewChild(CdkMenuTrigger) menuTrigger?: CdkMenuTrigger;

  @HostBinding('attr.aria-level')
  protected get ariaLevel(): number {
    return (this.treeItem.level ?? 0) + 1;
  }

  @HostBinding('attr.aria-setsize')
  protected get ariaSetsize(): number {
    return this.treeItem.parent?.children?.length ?? 1;
  }

  @HostBinding('attr.aria-posinset')
  protected ariaPosinset?: number;

  @HostBinding('attr.aria-selected')
  protected get ariaSelected(): boolean | null {
    return this.enableSelection && this.treeItem.selectable ? !!this.treeItem.selected : null;
  }

  @HostBinding('attr.aria-checked')
  protected get ariaChecked(): boolean | null {
    return this.showCheckOrOptionBox && this.treeItem.selectable
      ? this.treeItem.checked === 'checked'
      : null;
  }

  @HostBinding('attr.aria-expanded')
  protected get ariaExpanded(): boolean | null {
    if (this.treeItem.state === 'leaf') {
      return null;
    }
    return this.treeItem.state === 'expanded';
  }

  @HostBinding('attr.aria-haspopup')
  protected get ariaHaspopup(): boolean {
    return this.isContextMenuButtonVisible;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.scrollIntoView.subscribe(event => this.onScrollIntoViewByConsumer(event))
    );
    this.subscriptions.push(
      this.childrenLoaded.subscribe(event => this.childrenLoadingDone(event))
    );
    this.subscriptions.push(
      this.siTreeViewService.triggerMarkForCheck.subscribe(() => this.cdRef.markForCheck())
    );
  }

  ngDoCheck(): void {
    if (this.treeItem.parent?.children && this.ariaPosinset) {
      if (this.treeItem.parent.children[this.ariaPosinset] !== this.treeItem) {
        this.ariaPosinset = this.treeItem.parent.children.indexOf(this.treeItem) + 1;
      }
    } else if (this.treeItem.parent?.children) {
      this.ariaPosinset = this.treeItem.parent.children.indexOf(this.treeItem) + 1;
    } else {
      this.ariaPosinset = 1;
    }
  }

  ngAfterViewInit(): void {
    if (this.savedElement) {
      this.siTreeViewService.scrollIntoViewEvent.next(this.savedElement);
      this.savedElement = undefined;
    }
    this.nextSiblingElement = this.element.nativeElement?.nextElementSibling;
  }

  ngOnDestroy(): void {
    this.subscriptions
      .filter(subscription => !!subscription)
      .forEach(subscription => subscription.unsubscribe());
  }

  get enableSelection(): boolean {
    return this.siTreeViewService.enableSelection;
  }

  get enableContextMenuButton(): boolean {
    return this.siTreeViewService.enableContextMenuButton;
  }

  get enableDataField1(): boolean {
    return this.siTreeViewService.enableDataField1;
  }

  get enableDataField2(): boolean {
    return this.siTreeViewService.enableDataField2;
  }

  get deleteChildrenOnCollapse(): boolean {
    return this.siTreeViewService.deleteChildrenOnCollapse;
  }

  get paddingStart(): string {
    return this.siTreeViewService.groupedList
      ? '0'
      : this._indentLevel * this.siTreeViewService.childrenIndentation + 'px';
  }

  get biggerPaddingStart(): string {
    const basePadding = this.showFolderStateStart ? 24 : 0;
    return (
      (this.siTreeViewService.groupedList
        ? basePadding
        : this._indentLevel * this.siTreeViewService.childrenIndentation + basePadding) + 'px'
    );
  }

  get isGroupedItem(): boolean {
    return this.siTreeViewService.isGroupedItem(this.treeItem);
  }

  get showFolderState(): boolean {
    return !this.isFlatTree() && (!!this.isGroupedItem || !this.siTreeViewService.groupedList);
  }

  get showFolderStateStart(): boolean {
    return (
      this.displayFolderState &&
      this.siTreeViewService.folderStateStart &&
      !this.isFlatTree() &&
      (!!this.isGroupedItem || !this.siTreeViewService.groupedList)
    );
  }

  get showFolderStateEnd(): boolean {
    return this.displayFolderState && !this.showFolderStateStart;
  }

  get isExpanding(): boolean {
    return this.treeItem.state === 'expanding';
  }

  get showStateIndicator(): boolean {
    return this.siTreeViewService.enableStateIndicator;
  }

  get showIcon(): boolean {
    return this.siTreeViewService.enableIcon && !!this.treeItem.icon;
  }

  get showCheckOrOptionBox(): boolean {
    return !!this.treeItem.showCheckbox || !!this.treeItem.showOptionbox;
  }

  getItemFolderStateClass(): string {
    if (this.treeItem.state === 'collapsed') {
      if (this.siTreeViewService.flatTree) {
        // flat tree mode
        return this.icons.itemCollapsedFlat;
      } else if (this.siTreeViewService.folderStateStart) {
        // normal tree mode; folder state icon shown on the left (in LTR) side
        return this.icons.itemCollapsedLeft; // si-tree-view-item-collapsed
      } else {
        // normal tree mode; folder state icon shown on the right (in LTR) side
        return this.icons.itemCollapsed; // si-tree-view-item-collapsed
      }
    } else if (this.treeItem.state === 'expanding') {
      return 'si-tree-view-item-expanding'; // because empty is treated as disabled
    } else if (this.treeItem.state === 'expanded') {
      if (this.siTreeViewService.flatTree) {
        // flat tree mode
        return this.icons.itemExpandedFlat;
      } else if (this.siTreeViewService.folderStateStart) {
        // normal tree mode; folder state icon shown on the left (in LTR) side
        return this.icons.itemExpandedLeft; // si-tree-view-item-expanded
      } else {
        // normal tree mode; folder state icon shown on the right (in LTR) side
        return this.icons.itemExpanded; // si-tree-view-item-expanded
      }
    }
    return '';
  }

  /**
   * Show icon if the item is selected.
   * Per default the `filled` icon is used, this can be configured using {@link disableFilledIcons}.
   **/
  getIconClass(): string {
    let iconClass = this.treeItem.icon;
    if (this.treeItem.selected && this.treeItem.selectable && this.enableSelection) {
      iconClass =
        iconClass + (!this.siTreeViewService.disableFilledIcons ? ' ' + iconClass + '-filled' : '');
    }
    return iconClass ?? '';
  }

  getStateIndicatorColor(): string {
    return this.treeItem ? this.treeItem.stateIndicatorColor ?? '' : '';
  }

  onItemFolderClicked(newState?: string): void {
    this.nextSiblingElement = this.element.nativeElement?.nextElementSibling;
    const oldState = removeUndefinedState(this.treeItem.state);
    doFolderStateChange(this.treeItem, this.deleteChildrenOnCollapse, newState);

    this.siTreeViewService.folderClickEvent.next(
      new FolderStateEventArgs(this.treeItem, oldState, removeUndefinedState(this.treeItem.state))
    );

    if (this.treeItem.state === 'expanding') {
      this.siTreeViewService.loadChildrenEvent.next(this.treeItem);
    }

    if (this.treeItem.state === 'expanded') {
      this.scrollChildNodeIntoViewPort();
    }
  }

  private childrenLoadingDone(item: TreeItem): void {
    if (item === this.treeItem) {
      asyncScheduler.schedule(() => this.scrollChildNodeIntoViewPort(), 0);
    }
  }

  private scrollChildNodeIntoViewPort(): void {
    const task = (): void => {
      const container: HTMLElement = this.element.nativeElement.closest('.si-tree-view');
      const first: HTMLElement = this.element.nativeElement;
      this.nextSiblingElement =
        (this.nextSiblingElement?.previousElementSibling as HTMLElement) ?? this.nextSiblingElement;
      const last: HTMLElement = container?.contains(this.nextSiblingElement)
        ? this.nextSiblingElement
        : this.element.nativeElement.parentElement?.lastElementChild;
      const fits =
        container?.offsetHeight -
          (last?.offsetTop + last?.getBoundingClientRect().height - first?.offsetTop) >=
        0;
      const targetElement = fits ? last : first;
      if (targetElement) {
        const observer: IntersectionObserver = new window.IntersectionObserver(
          ([entry]) => {
            requestAnimationFrame(() => {
              const scrollElement = (targetElement.firstChild as HTMLElement) ?? targetElement;
              if (fits) {
                scrollElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
              } else {
                // scrollIntoView messes up with page scroll when body also has a scroll for { block : 'start' }
                const totalScrollTop =
                  container?.scrollTop +
                  first?.getBoundingClientRect().top -
                  container?.getBoundingClientRect().top;
                container?.scrollTo({ top: totalScrollTop, behavior: 'smooth' });
              }
            });
            observer.disconnect();
          },
          {
            root: null,
            threshold: 1
          }
        );
        observer.observe(targetElement);
      }
    };
    asyncScheduler.schedule(task, 0);
  }

  onItemClicked(event: Event): void {
    const previousActive = this.treeItem.active;
    this.siTreeViewService.clickEvent.next(new ClickEventArgs(this.treeItem, event as MouseEvent));

    if (
      !this.siTreeViewService.enableSelection &&
      (this.siTreeViewService.enableCheckbox || this.siTreeViewService.enableOptionbox)
    ) {
      this.onBoxClicked();
    }

    // Toggle expand when item is collapsed, collapse only if the node is active
    const toggle =
      (this.treeItem.state === 'expanded' && previousActive) || this.treeItem.state === 'collapsed';
    if (this.expandOnClick() && toggle) {
      this.onItemFolderClicked();
    }
  }

  onMouseDownTreeItem(event: MouseEvent): void {
    if (event.shiftKey) {
      // let selection: Selection = window.getSelection();
      // selection.removeAllRanges();
      // we do not want the tree item text selected: thus we prevent the default behavior in this situation
      event.preventDefault();
    }
  }

  onToggleContextMenuOpen(): void {
    setActive(this.treeItem, true);
    this.siTreeViewService.scroll$
      .pipe(takeUntil(this.menuTrigger!.closed), take(1))
      .subscribe(() => this.menuTrigger?.close());
  }

  onToggleContextMenuClose(): void {
    setTimeout(() => this.element.nativeElement.focus());
  }

  getInputType(): string {
    // This method will be called only when either of the showCheckbox or showOptionBox is true, thus
    // following condition can be shortened
    return this.treeItem.showCheckbox ? 'checkbox' : 'radio';
  }

  onBoxClicked(): void {
    if (!this.treeItem.selectable) {
      return;
    }
    const oldState = this.treeItem.checked ?? 'unchecked';
    boxClicked(this.treeItem, this.siTreeViewService.inheritChecked);
    this.siTreeViewService.checkboxClickEvent.next(
      new CheckboxClickEventArgs(this.treeItem, oldState, this.treeItem.checked ?? 'unchecked')
    );
  }

  protected renderMatchingTemplate(treeItem: TreeItem): TemplateRef<any> {
    // we check in the HTML template if templates exist.
    const templateDirective = this.templates!.find(td => td.name === treeItem.templateName);
    return templateDirective ? templateDirective.template : this.templates!.toArray()[0].template;
  }

  @HostListener('contextmenu', ['$event']) onContextMenu(event: Event): boolean {
    this.handleContextMenuEvent(event);
    return false;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const rtlCorrectedKey = correctKeyRTL(event.key);
    if (rtlCorrectedKey === 'Enter') {
      event.preventDefault();
      this.onItemClicked(event);
    } else if (rtlCorrectedKey === 'ContextMenu' || (rtlCorrectedKey === 'F10' && event.shiftKey)) {
      this.handleContextMenuEvent(event);
    } else if (rtlCorrectedKey === 'ArrowLeft') {
      if (
        (!this.isFlatTree() &&
          this.showFolderStateStart &&
          (this.treeItem.state !== 'leaf' || this.isGroupedItem)) ||
        this.showFolderStateEnd
      ) {
        event.preventDefault();
        if (this.treeItem.state !== 'collapsed' && this.treeItem.state !== 'leaf') {
          this.onItemFolderClicked('collapsed');
          return;
        }
      }
      this.siTreeViewService.focusParentEvent.next(this.treeItem);
    } else if (rtlCorrectedKey === 'ArrowRight') {
      if (
        (this.showFolderStateStart && (this.treeItem.state !== 'leaf' || this.isGroupedItem)) ||
        this.showFolderStateEnd
      ) {
        event.preventDefault();
        if (this.treeItem.state !== 'expanded') {
          this.onItemFolderClicked('expanded');
          return;
        }
      }
      this.siTreeViewService.focusFirstChildEvent.next(this.treeItem);
    } else if (
      this.showCheckOrOptionBox &&
      (rtlCorrectedKey === 'Space' || rtlCorrectedKey === ' ')
    ) {
      event.preventDefault();
      this.onBoxClicked();
    }
  }

  private handleContextMenuEvent(event: Event): void {
    if (
      this.isContextMenuButtonVisible &&
      this.contextMenuItems?.some(item => !item.disabled && !item.isHeading && item.title !== '-')
    ) {
      event.preventDefault();
      event.stopPropagation();
      // Without the setTimeout in Firefox native menu will open as well.
      // Event suppression does not work.
      // Re-check in the future if FF fixes it.
      setTimeout(() => {
        this.menuTrigger?.open();
        this.menuTrigger?.getMenu()!.focusFirstItem('keyboard');
      });
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  isFlatTree(): boolean {
    return this.siTreeViewService.flatTree;
  }

  focus(): void {
    this.element.nativeElement.focus();
  }

  getLabel(): string {
    return this.treeItem?.label ?? '';
  }

  /**
   * Called by the consumer when they want a node to be scrolled into view.
   */
  private onScrollIntoViewByConsumer(item: TreeItem): void {
    if (item === this.treeItem) {
      this.savedElement = this.element;
      this.siTreeViewService.scrollIntoViewEvent.next(this.element);
    }
  }

  private expandOnClick(): boolean {
    return (
      this.siTreeViewService.expandOnClick &&
      this.treeItem.state !== 'leaf' &&
      !!this.treeItem.selectable &&
      !this.siTreeViewService.flatTree
    );
  }
}
