import { CdkMenuTrigger } from '@angular/cdk/menu';
import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import { SiMenuFactoryComponent } from '@simpl/element-ng/menu';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-electron-titlebar',
  templateUrl: './si-electron-titlebar.component.html',
  styleUrl: './si-electron-titlebar.component.scss',
  standalone: true,
  imports: [CdkMenuTrigger, SiMenuFactoryComponent, SiTranslateModule]
})
export class SiElectrontitlebarComponent {
  /**
   * Title of your application
   */
  @Input({ required: true })
  appTitle!: string;

  /**
   * Defines if the application can go back or not
   */
  @Input({ transform: booleanAttribute }) canGoBack = false;

  /**
   * Defines if the application can go forward or not
   */
  @Input({ transform: booleanAttribute }) canGoForward = false;

  /**
   * Defines if the application is focused or not
   */
  @Input({ transform: booleanAttribute }) hasFocus = true;

  /**
   * List of menu items for the dropdown
   * In this dropdown should the zoom functionality as well as the refresh function be provided
   */
  @Input() menuItems: MenuItem[] = [];

  /**
   * Browsing forward function
   */
  @Output() readonly forward = new EventEmitter<void>();

  /**
   * Browsing back function
   */
  @Output() readonly back = new EventEmitter<void>();

  /**
   * Aria Label for Forward Button
   */
  @Input() ariaLabelForward = $localize`:@@SI_ELECTRON_TITLEBAR.FORWARD:Forward`;

  /**
   * Aria Label for Back Button
   */
  @Input() ariaLabelBack = $localize`:@@SI_ELECTRON_TITLEBAR.BACK:Back`;

  /**
   * Aria Label for Menu Button
   */
  @Input() ariaLabelMenu = $localize`:@@SI_ELECTRON_TITLEBAR.MENU:Menu`;
}
