import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TrendEvalutionService } from './trend-evalution.service';
import { TrendEvalutionServiceBase } from './trend-evalution.service.base';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [{ provide: TrendEvalutionServiceBase, useClass: TrendEvalutionService }]
})
export class TrendEvaluationModule { }
