import { Directive, Input, OnChanges } from '@angular/core';
import { buildTrackByIdentity } from '@simpl/element-ng/common';

import { SiSelectGroupRow, SiSelectOptionRow, SiSelectRow } from '../si-select.types';
import { SI_SELECT_OPTIONS_STRATEGY } from './si-select-options-strategy';
import { SiSelectOptionsStrategyBase } from './si-select-options-strategy.base';

/**
 * The directive allows to pass custom options.
 * Otherwise, use the {@link SiSelectSimpleOptionsDirective} directive.
 *
 * @example
 * <si-select [complexOptions]="['v1', 'v2', 'v3']"></si-select>
 * <si-select [complexOptions]="{ g1: ['g1.i1', 'g1.i2'], g2: ['g2.i1']}"></si-select>
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'si-select[complexOptions]',
  providers: [
    { provide: SI_SELECT_OPTIONS_STRATEGY, useExisting: SiSelectComplexOptionsDirective }
  ],
  standalone: true
})
export class SiSelectComplexOptionsDirective<T>
  extends SiSelectOptionsStrategyBase<T>
  implements OnChanges
{
  /** Options to be shown in select dropdown. */
  @Input() complexOptions: T[] | Record<string, T[]> | null | undefined;

  /**
   * @deprecated Property has no effect and can be removed.
   */
  @Input() trackBy = buildTrackByIdentity<T>();

  /**
   * By default, values are check on equality by reference. Override to customize the behavior.
   */
  // override the input be to better understandable by consumer
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('optionEqualCheckFn') override optionsEqual = (a: T, b: T): boolean => a === b;

  /**
   * The valueProvider is used to extract the display text of a value.
   */
  @Input() valueProvider?: (dropdownOption: T) => string | undefined;

  /**
   * Provides Value for the display text of the dropdown group
   */
  @Input() groupProvider: (groupKey: string) => string | undefined = () => undefined;

  /**
   * The disabledProvider is used to display menu items as disabled.
   */
  @Input() disabledProvider: (dropdownOption: T) => boolean = () => false;

  ngOnChanges(): void {
    if (this.complexOptions instanceof Array) {
      this.allRows.set(this.convertOptionsArray(this.complexOptions));
    } else if (this.complexOptions) {
      this.allRows.set(
        Object.entries(this.complexOptions).map(
          ([key, value]) =>
            ({
              type: 'group',
              key,
              label: this.groupProvider(key) ?? key,
              options: this.convertOptionsArray(value)
            }) as SiSelectGroupRow<T>
        )
      );
    } else {
      this.allRows.set([]);
    }
    this.updateOptionsSubject();
  }

  override onFilter(filterValue: string | undefined): void {
    this.updateOptionsSubject(filterValue);
  }

  private convertOptionsArray(options: T[]): SiSelectOptionRow<T>[] {
    return options.map(option => ({
      type: 'option',
      value: option,
      label: (this.valueProvider ? this.valueProvider(option) : undefined) ?? option + '',
      typeaheadLabel: this.valueProvider ? this.valueProvider(option) : undefined,
      disabled: this.disabledProvider(option)
    }));
  }

  private updateOptionsSubject(filterValue?: string): void {
    if (filterValue) {
      const filterValueLC = filterValue.toLowerCase();
      const checkRow: (row: SiSelectOptionRow<T>) => boolean = (row: SiSelectOptionRow<T>) =>
        (row.typeaheadLabel ?? row.label)!.toLowerCase().includes(filterValueLC!);

      this.rows.set(
        this.allRows().reduce((rows, row) => {
          if (row.type === 'option' && checkRow(row)) {
            rows.push(row);
          } else if (row.type === 'group') {
            if (row.label!.toLowerCase().includes(filterValueLC!)) {
              rows.push(row);
            } else {
              const options = row.options.filter(checkRow);

              if (options.length) {
                rows.push({ ...row, options });
              }
            }
          }

          return rows;
        }, [] as SiSelectRow<T>[])
      );
    } else {
      this.rows.set(this.allRows());
    }
  }
}
