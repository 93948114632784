import { Inject } from '@angular/core';
import { TimeoutDialogService } from '@gms-flex/controls';
import { IHfwMessage } from '@gms-flex/core';
import { MultiMonitorServiceBase, ObjectMessageType } from '@gms-flex/services';
import { AuthenticationServiceBase, isNullOrUndefined, TraceService } from '@gms-flex/services-common';

import { TraceModules } from './trace-modules';

export class InactivityTimeout {

  private timeoutValue = 0;

  constructor(
    private readonly traceService: TraceService,
    private readonly authenticationService: AuthenticationServiceBase,
    private readonly timeoutDialogService: TimeoutDialogService,
    private readonly multiMonitorService: MultiMonitorServiceBase,
    @Inject(IHfwMessage) private readonly messageBroker: IHfwMessage) { }

  /**
   *  read timeout value and start timeout dialog
   */
  public manageInactivityTimeout(isULInactivityTimeout: boolean): void {
    if (this.authenticationService.userInactivity && Number(this.authenticationService.userInactivity) > 0) {
      this.timeoutValue = Number(this.authenticationService.userInactivity);

      if (this.timeoutDialogService.getInstances() == 0) {
        this.timeoutDialogService.startTimeout(this.timeoutValue, isULInactivityTimeout);
        this.timeoutDialogService.logOutAction.subscribe(
          (result: boolean) => {
            if (isULInactivityTimeout) {
              this.messageBroker.switchToNextFrame('event-list');
            } else if (!isNullOrUndefined(result)) {
              // passing as second parameter TRUE means that LogOff is called for Inactivity Timeout
              this.logoutWithSaving(result, true);
            }
          },
          (error: any) => {
            this.traceService.info(TraceModules.navigationBar, 'TimeoutDialog showDialog error %s. Replying TimeoutDialog logout.', error);
            // passing as second parameter FALSE means that LogOff is NOT called for Inactivity Timeout
            this.logoutWithSaving(false, true);
          });
      }
    }
  }

  /**
   * refresh timeout every time that exist a movement
   */
  public refreshUserState(): void {
    if (this.timeoutValue > 0) {
      this.timeoutDialogService.refreshTimeout();
    }
  }

  /** Log out without notify SnapIns
   *
   * @abstract
   * @param dirtyState set to true to indicate that there are chenges to save before log off
   * @param isInactivityLogout set to true to indicate that log off come from Inactivity TimeOut
   * @memberOf AuthenticationServiceBase
   */
  private logoutWithSaving(dirtyState: boolean, isInactivityLogout: boolean): void {
    if (this.multiMonitorService.runsInElectron) {
      this.multiMonitorService.sendObjectToMainManager({
        type: ObjectMessageType.Logout,
        data: { dirtyState }
      });
    } else {
      this.messageBroker.logout(dirtyState, isInactivityLogout);
    }
  }
}
