import { inject, Injectable, InjectionToken, Provider } from '@angular/core';
import { TranslatableString } from '@simpl/element-ng/translate';

export const SI_FORM_VALIDATION_ERROR_MAPPER = new InjectionToken<SiFormValidationErrorMapper>(
  'SI_FORM_VALIDATION_ERROR_MAPPER',
  {
    factory: () => inject(SiFormValidationErrorMapperDefault),
    providedIn: 'root'
  }
);

/**
 * Interface for form error mapper.
 * It resolves a key to either a translatable string or function
 * which is called with the validation error for its key and should return a translatable string.
 */
export interface SiFormValidationErrorMapper {
  required?: TranslatableString | ((error: { required: boolean }) => TranslatableString);
  requiredTrue?: TranslatableString | ((error: { required: boolean }) => TranslatableString);
  email?: TranslatableString | ((error: { email: boolean }) => TranslatableString);
  min?: TranslatableString | ((error: { min: number; actual: number }) => TranslatableString);
  max?: TranslatableString | ((error: { max: number; actual: number }) => TranslatableString);
  minLength?:
    | TranslatableString
    | ((error: { requiredLength: number; actualLength: number }) => TranslatableString);
  maxLength?:
    | TranslatableString
    | ((error: { requiredLength: number; actualLength: number }) => TranslatableString);
  pattern?: TranslatableString | ((error: { pattern: string | RegExp }) => TranslatableString);

  [key: string]: undefined | string | ((error: any) => string);
}

@Injectable({ providedIn: 'root' })
export class SiFormValidationErrorMapperDefault implements SiFormValidationErrorMapper {
  min = $localize`:@@SI_FORM_CONTAINER.ERROR.MIN:The value is too small`;
  max = $localize`:@@SI_FORM_CONTAINER.ERROR.MAX:The value is too large.`;
  required = $localize`:@@SI_FORM_CONTAINER.ERROR.REQUIRED:A value is required.`;
  requiredTrue = $localize`:@@SI_FORM_CONTAINER.ERROR.REQUIRED_TRUE:The value should be true.`;
  email = $localize`:@@SI_FORM_CONTAINER.ERROR.EMAIL:The email is not valid.`;
  minlength = $localize`:@@SI_FORM_CONTAINER.ERROR.MIN_LENGTH:The minimum number of characters is not met.`;
  maxlength = $localize`:@@SI_FORM_CONTAINER.ERROR.MAX_LENGTH:A maximum number of characters is exceeded.`;
  pattern = $localize`:@@SI_FORM_CONTAINER.ERROR.PATTERN:The value does not match the predefined pattern.`;

  [key: string]: string | ((error: any) => string) | undefined;
}

/**
 * The error mappers is used to resolve an angular validation error to a {@link TranslatableString}.
 * It will be merged with already existing error mappers.
 */
export const provideFormValidationErrorMapper = (
  mapper: SiFormValidationErrorMapper
): Provider => ({
  provide: SI_FORM_VALIDATION_ERROR_MAPPER,
  useFactory: () => ({
    ...inject(SiFormValidationErrorMapperDefault),
    ...inject(SI_FORM_VALIDATION_ERROR_MAPPER, { skipSelf: true, optional: true }),
    ...mapper
  })
});
