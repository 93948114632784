import { Evaluation } from '../processor/evaluation';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { GmsElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { Point } from '../utilities/color-utility';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';
import { GmsGraphic } from './gms-graphic';

export class GmsPipe extends GmsElement {
  constructor(type: GmsElementType = GmsElementType.Pipe) {
    super(type);

    this.children = [];
  }
  /**
   * Pipe post flip offsets
   */
  private _postFlipOffsetX = 0;
  private _postFlipOffsetY = 0;
  /**
   * Pipe design FlipX value
   */
  private _designFlipX = false;
  /**
   * Pipe design FlipY value
   */
  private _designFlipY = false;

  /**
   * Pipe background rectangle X
   */
  private _backgroundX = 0;
  public get BackgroundX(): number {
    return this._backgroundX;
  }
  public set BackgroundX(value: number) {
    this._backgroundX = value;
  }

  /**
   * Pipe background rectangle Y
   */
  private _backgroundY = 0;
  public get BackgroundY(): number {
    return this._backgroundY;
  }
  public set BackgroundY(value: number) {
    this._backgroundY = value;
  }
  /**
   * Pipe background rectangle Width
   */
  private _backgroundWidth = 0;
  public get BackgroundWidth(): number {
    if (this.Background === 'transparent' && this._backgroundWidth === 0) {
      return this.Width;
    }
    return this._backgroundWidth;
  }

  /**
   * Pipe background rectangle Height
   */
  private _backgroundHeight = 0;
  public get BackgroundHeight(): number {
    if (this.Background === 'transparent' && this._backgroundHeight === 0) {
      return this.Height;
    }
    return this._backgroundHeight;
  }

  public AddChild(element: GmsElement): void {

    this.children.push(element);

    this.NotifyPropertyChanged();
  }

  public async ShapeChanged(): Promise<any> {

    this.NotifyPropertyChanged('ShapeChanged');
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {
    super.UpdateEvaluation(evaluation);

    if (evaluation !== undefined && evaluation.Status === DatapointStatus.Valid) {
      switch (evaluation.Property) {
        case 'FlipX':
          if (this.FlipX === this._designFlipX) {
            // offset = 0
            this._postFlipOffsetX = 0;
          } else {
            // offset = twice difference between center and background center x value
            this._postFlipOffsetX = 2 * (this.Width / 2 - (this.BackgroundX + this.BackgroundWidth / 2));
          }
          this.NotifyPropertyChanged('FlipX');
          this.NotifyShapeChanged();
          this.ShapeChanged();
          break;

        case 'FlipY':
          if (this.FlipY === this._designFlipY) {
            // offset = 0
            this._postFlipOffsetY = 0;
          } else {
            // offset = twice difference between center and background center y value
            this._postFlipOffsetY = 2 * (this.Height / 2 - (this.BackgroundY + this.BackgroundHeight / 2));
          }
          this.NotifyPropertyChanged('FlipY');
          this.NotifyShapeChanged();
          this.ShapeChanged();
          break;

        default:
          break;
      }
    }
  }

  public GetTransformations(): string {
    // position transform
    let transform: string = Utility.TRANSLATEOPEN + this.X + ',' + this.Y + Utility.TRANSLATECLOSE;

    // nature of pipes - should do both cases, the flip and unflip for the Flip evaluations support.
    if (this.FlipX || this.FlipX !== this._designFlipX || this.FlipY || this.FlipY !== this._designFlipY) {
      const scaleX: string = this.FlipX ? '-1' : '1';
      const scaleY: string = this.FlipY ? '-1' : '1';

      // center point of transform.
      let tx: number = this.Width / 2;
      let ty: number = this.Height / 2;

      // pre-flip transform: move to center point.
      const translatePreFlip: string = Utility.TRANSLATEOPEN + tx + ',' + ty + Utility.TRANSLATECLOSE;

      // post-flip transform: move back
      tx = this._postFlipOffsetX - tx;
      ty = this._postFlipOffsetY - ty;
      const translatePostFlip: string = Utility.TRANSLATEOPEN + tx + ',' + ty + Utility.TRANSLATECLOSE;

      // combined transform: pre-flip + flip/scale + post-flip
      transform += translatePreFlip + 'scale(' + scaleX + ',' + scaleY + ')' + translatePostFlip;
    }

    if (this.Angle !== 0) {
      // Calculate rotation center.
      const cx: number = this.BackgroundX + this.BackgroundWidth / 2;
      const cy: number = this.BackgroundY + this.BackgroundHeight / 2;
      transform += 'rotate(' + this.Angle + ',' + cx + ',' + cy + ')';
    }

    if (this.RotationAngle !== 0) {
      // rotate transform
      const centerX: number = this.AngleCenterX === undefined ? this.BackgroundX : this.BackgroundX + this.AngleCenterX;
      const centerY: number = this.AngleCenterY === undefined ? this.BackgroundY : this.BackgroundY + this.AngleCenterY;
      transform += 'rotate(' + this.RotationAngle + ',' + centerX.toString() + ',' + centerY.toString() + ')';
    }

    if (this.ScaleX !== 1 || this.ScaleY !== 1) {
      // scale transform
      const scaleX: string = this.ScaleX.toString();
      const scaleY: string = this.ScaleY.toString();
      // center point of transform.
      let tx: number = this.BackgroundX + this.BackgroundWidth / 2;
      let ty: number = this.BackgroundY + this.BackgroundHeight / 2;

      // pre-scale transform: move to center point.
      const translatePreScale: string = Utility.TRANSLATEOPEN + tx + ',' + ty + Utility.TRANSLATECLOSE;
      tx = 0 - tx;
      ty = 0 - ty;
      // post-scale transform: move back
      const translatePostScale: string = Utility.TRANSLATEOPEN + tx + ',' + ty + Utility.TRANSLATECLOSE;

      // combined transform: pre-scale + scale + post-scale
      transform += translatePreScale + 'scale(' + scaleX + ',' + scaleY + ')' + translatePostScale;
    }
    /**
     * @NOTE:
     * The following code calculates the positioning and scaling for not zoomable elements.
     */
    const graphic: GmsGraphic = this.Graphic as GmsGraphic;
    if (graphic?.CurrentZoomLevel !== undefined && this.Zoomable === false) {
      const inverseScale: number = 1 / graphic.CurrentZoomLevel;

      if (Number.isNaN(inverseScale) === false) {
        const elementRef: any = document.getElementById(this.Id) as any;
        if (elementRef != null) {
          const elementRect: ClientRect = elementRef.getBoundingClientRect();
          const matrix: SVGMatrix = elementRef.getCTM();

          const centerPoint: Point = new Point((elementRect.left + elementRect.width) / 2,
            (elementRect.top + elementRect.height) / 2);

          const newCenterPoint: Point = new Point((elementRect.left + (elementRect.width * inverseScale)) / 2,
            (elementRect.top + (elementRect.height * inverseScale)) / 2);

          const displacementScale: number = 1 / (matrix.a * inverseScale);
          const displacement: Point = new Point((centerPoint.X - newCenterPoint.X) * displacementScale,
            (centerPoint.Y - newCenterPoint.Y) * displacementScale);

          const inverseScaleString = `scale(${inverseScale} ${inverseScale})`;
          const translateString = `translate(${displacement.X} ${displacement.Y})`;
          transform += inverseScaleString;
          transform += translateString;
        }
      }
    }

    return transform;
  }

  public UpdateWidthOfChildren(): void {
    if (this.Width !== this.BoundingRectDesign.Width && this.BoundingRectDesign.Width > 0) {
      const factor: number = this.Width / this.BoundingRectDesign.Width;
      this.children.forEach(childElement => {
        childElement.UpdateWidthResize(factor);
      });
    }
  }

  public UpdateHeightOfChildren(): void {
    if (this.Height !== this.BoundingRectDesign.Height && this.BoundingRectDesign.Height > 0) {
      const factor: number = this.Height / this.BoundingRectDesign.Height;
      this.children.forEach(childElement => {
        childElement.UpdateHeightResize(factor);
      });
    }
  }

  public ProcessBackgroundRectangle(backgroundNode: Node): void {
    // pipe background X Position
    let result: string = SvgUtility.GetAttributeValue(backgroundNode, GmsElementPropertyType.x);
    if (result !== undefined) {
      this.BackgroundX = FormatHelper.StringToNumber(result);
    }
    // pipe background Y Position
    result = SvgUtility.GetAttributeValue(backgroundNode, GmsElementPropertyType.y);
    if (result !== undefined) {
      this.BackgroundY = FormatHelper.StringToNumber(result);
    }
    // pipe background Width
    result = SvgUtility.GetAttributeValue(backgroundNode, GmsElementPropertyType.Width);
    if (result !== undefined) {
      this._backgroundWidth = FormatHelper.StringToNumber(result);
      this.DesignValueWidth = this._backgroundWidth;
    }
    // pipe background Height
    result = SvgUtility.GetAttributeValue(backgroundNode, GmsElementPropertyType.Height);
    if (result !== undefined) {
      this._backgroundHeight = FormatHelper.StringToNumber(result);
      this.DesignValueHeight = this._backgroundHeight;
    }

    super.ProcessBackgroundRectangle(backgroundNode);
  }

  public Deserialize(node: Node): void {

    super.Deserialize(node);

    this._designFlipX = this.FlipX;
    this._designFlipY = this.FlipY;

    this.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsPipe): void {
    this.IsCopying = true;

    super.CopyFrom(element);

    this.Graphic.ReplicationService.CopyChildren(element, this);

    this.CopyEvaluations(element);

    this.IsCopying = false;
  }

}
