import { NgModule } from '@angular/core';

import { SiListInteractionItemDirective } from './si-list-interaction-item.directive';
import { SiListInteractionDirective } from './si-list-interaction.directive';

/**
 * @deprecated The {@link SiListInteractionModule} and all related symbols should no longer be used.
 * - For creating menus, use {@link SiMenuModule} (not legacy!) instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating listbox, use the {@link https://material.angular.io/cdk/listbox/overview cdkListbox }
 * - For all other cases consider using tab-based strategy
 */
@NgModule({
  imports: [SiListInteractionDirective, SiListInteractionItemDirective],
  exports: [SiListInteractionDirective, SiListInteractionItemDirective]
})
export class SiListInteractionModule {}
