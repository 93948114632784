import { NgModule } from '@angular/core';

import { TooltipComponent } from './si-tooltip.component';
import { SiTooltipDirective } from './si-tooltip.directive';

@NgModule({
  imports: [SiTooltipDirective, TooltipComponent],
  exports: [SiTooltipDirective, TooltipComponent]
})
export class SiTooltipModule {}
