import { Subject } from 'rxjs';

import { GmsCommand } from './gms-command';

export enum CommandValidationStatus {
  // eslint-disable-next-line
    Undefined,
  Validating,
  DoesNotExist,
  Misconfigured,
  Valid
}

export class CommandStatusChangeArgs {
  private _propertyName: string;
  public get PropertyName(): string {
    return this._propertyName;
  }
  public set PropertyName(value: string) {

    if (this._propertyName !== value) {
      this._propertyName = value;
    }
  }
  private _command: GmsCommand;
  public get Command(): GmsCommand {
    return this._command;
  }
  public set Command(value: GmsCommand) {

    if (this._command !== value) {
      this._command = value;
    }
  }
}

// handles command validation status and BL and UI updates
export class CommandStatus {

  public statusChanged: Subject<CommandStatusChangeArgs> = new Subject<CommandStatusChangeArgs>();

  // Status Validation Masks
  private readonly _undefined: number = 0x1; // #COM (in the beginning)
  private readonly _validating: number = 0x2; // #COM (validation in process)
  private readonly _doesNotExist: number = 0x4; // #ENG (command doesn't exist)
  private readonly _misconfigured: number = 0x8; // #ENG (parameter(s) misconfigured) - a command where one or more of the parameters are misconfigured

  // Command Status Masks
  private readonly _comDisabled: number = 0x10; // COM lost
  private readonly _blDisabled: number = 0x20; // BL subscribe related
  private readonly _guiDisabled: number = 0x40; // UI animation related
  private readonly _guiTriggerDisabled: number = 0x80; // UI animation related

  // Helper masks
  private readonly _valid: number = 0xF; // Combination of validation flags

  // Command view model status flags
  private readonly _guiMisconfigured: number = 0x10000; // parameter view model misconfiguration

  private _status: number;
  public get Status(): number {
    return this._status;
  }

  public set Status(value: number) {
    if (this._status !== value) {
      this._status = value;
    }
    this.NotifyPropertyChanged('Status');
  }

  // Status Validation
  public get IsUndefined(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._undefined) !== 0;
  }

  public get IsValidating(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._validating) !== 0;
  }

  public get DoesNotExist(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._doesNotExist) !== 0;
  }

  public get Misconfigured(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._misconfigured) !== 0;
  }

  public get IsValid(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._valid) === 0;
  }

  public get CommandValidationStatus(): CommandValidationStatus {

    // eslint-disable-next-line no-bitwise
    const vs: number = this._status & this._valid;

    return vs === this._undefined ? CommandValidationStatus.Undefined
      : vs === this._doesNotExist ? CommandValidationStatus.DoesNotExist
        : vs === this._misconfigured ? CommandValidationStatus.Misconfigured
          : vs === this._validating ? CommandValidationStatus.Validating
            : CommandValidationStatus.Valid;
  }

  public set CommandValidationStatus(value: CommandValidationStatus) {
    switch (value) {
      case CommandValidationStatus.Validating:
        // eslint-disable-next-line no-bitwise
        this._status = (this._status & ~this._valid) | this._validating;
        break;
      case CommandValidationStatus.DoesNotExist:
        // eslint-disable-next-line no-bitwise
        this._status = (this._status & ~this._valid) | this._doesNotExist;
        break;
      case CommandValidationStatus.Misconfigured:
        // eslint-disable-next-line no-bitwise
        this._status = (this._status & ~this._valid) | this._misconfigured;
        break;
      case CommandValidationStatus.Valid:
        // eslint-disable-next-line no-bitwise
        this._status = this._status & ~this._valid;
        break;
      default:
        // eslint-disable-next-line no-bitwise
        this._status = (this._status & ~this._valid) | this._undefined;
        break;
    }
    this.NotifyPropertyChanged('Status');
  }

  // Command Status
  public get IsConnected(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._comDisabled) === 0;
  }
  public set IsConnected(value: boolean) {
    // eslint-disable-next-line no-bitwise
    this._status = value ? this._status & ~this._comDisabled : this._status | this._comDisabled;
    this.NotifyPropertyChanged('IsConnected');
  }

  // BL - properties commands enabled status
  public get IsBLEnabled(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._blDisabled) === 0;
  }
  public set IsBLEnabled(value: boolean) {
    // eslint-disable-next-line no-bitwise
    this._status = value ? this._status & ~this._blDisabled : this._status | this._blDisabled;
    this.NotifyPropertyChanged('IsBLEnabled');
  }

  // BL - properties commands enabled status
  public get IsUIConfigurationValid(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._guiMisconfigured) === 0;
  }
  public set IsUIConfigurationValid(value: boolean) {
    // eslint-disable-next-line no-bitwise
    this._status = value ? this._status & ~this._guiMisconfigured : this._status | this._guiMisconfigured;
    this.NotifyPropertyChanged('IsUIConfigurationValid');
  }

  /**
   * UI animation flag, it is used by the Command VM instances only
   */
  public get IsUIEnabled(): boolean {
    // eslint-disable-next-line no-bitwise
    return (this._status & this._guiDisabled) === 0;
  }
  public set IsUIEnabled(value: boolean) {
    // eslint-disable-next-line no-bitwise
    this._status = value ? this._status & ~this._guiDisabled : this._status | this._guiDisabled;
    this.NotifyPropertyChanged('IsUIEnabled');
    this.NotifyPropertyChanged('IsCommandEnabled');
  }

  public get IsEnabled(): boolean {
    return this.IsBLEnabled && this.IsUIEnabled;
  }

  /**
   * Is command enabled and connected
   */
  public get IsCommandEnabled(): boolean {
    return this.IsEnabled && this.IsConnected;
  }

  /**
   * UI defined trigger, it is used by the Command VM instances only!!
   */
  public get IsTriggerEnabled(): boolean {
    // eslint-disable-next-line no-bitwise
    return this.IsEnabled && (this._status & this._guiTriggerDisabled) === 0;
  }
  public set IsTriggerEnabled(value: boolean) {
    // eslint-disable-next-line no-bitwise
    this._status = value ? this._status & ~this._guiTriggerDisabled : this._status | this._guiTriggerDisabled;
    this.NotifyPropertyChanged('IsTriggerEnabled');
  }

  protected NotifyPropertyChanged(propertyName = ''): void {
    const args: CommandStatusChangeArgs = new CommandStatusChangeArgs();
    args.PropertyName = propertyName;
    this.statusChanged.next(args);
  }

  public constructor(isDesignMode: boolean) {
    // eslint-disable-next-line no-bitwise
    this._status = isDesignMode ? 0 : this._undefined | this._blDisabled;
  }
}

export class FakeCommandStatusClass extends CommandStatus {
  public test(): void {
    return super.NotifyPropertyChanged();
  }
}
