export const isNullOrUndefined = (value: any): boolean => value === undefined || value === null;

export const triggerWindowResize = (): void => {
  let event: any;
  if (typeof (Event) === 'function') {
    // modern browsers
    event = new Event('resize');
  } else {
    // IE
    event = window.document.createEvent('UIEvents');
    event.initUIEvent('resize', true, false, window, 0);
  }
  window.dispatchEvent(event);
};
