import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { buildTrackByIdentity } from '@simpl/element-ng/common';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { SiSkipLinkTargetDirective } from './si-skip-link-target.directive';

@Component({
  selector: 'si-skip-links',
  templateUrl: './si-skip-links.component.html',
  styleUrl: './si-skip-links.component.scss',
  standalone: true,
  imports: [SiTranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiSkipLinksComponent {
  @Input() skipLinks: readonly SiSkipLinkTargetDirective[] = [];

  protected trackBy = buildTrackByIdentity<SiSkipLinkTargetDirective>();
}
