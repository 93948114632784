<div
  class="filter-pill"
  [class.disabled]="disabled"
  [class.responsive]="responsive"
  [ngClass]="filter.status ? 'filter-pill-' + filter.status : ''"
  [attr.aria-disabled]="disabled"
>
  <div class="wrapper">
    @if (filter.title) {
      <div class="name" [class.empty-desc]="!filter.description && !responsive">{{
        filter.title | translate
      }}</div>
    }
    @if (responsive && filter.title && filter.description) {
      <span class="name pe-4">:</span>
    }
    <div class="value">{{ filter.description | translate }}</div>
    @if (!filter.title && !responsive) {
      <div>&#160;</div>
    }
  </div>
  @if (!responsive || !disabled) {
    <ng-container *ngTemplateOutlet="remove" />
  }
</div>
<ng-template #remove>
  <button
    type="button"
    class="btn-remove element-cancel btn btn-circle btn-ghost focus-inside"
    role="button"
    aria-label="Remove"
    [class.ms-0]="responsive"
    [class.ms-2]="!responsive"
    [class.btn-xs]="responsive"
    [class.btn-sm]="!responsive"
    [disabled]="disabled"
    (keydown.enter)="deleteClicked()"
    (click)="deleteClicked()"
  >
  </button>
</ng-template>
