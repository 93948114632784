import { isNullOrUndefined } from '@gms-flex/services-common';
import { TreeItem } from '@simpl/element-ng';

export class Utility {
  public static MENU_ITEM_WIDTH = 125;
  public static hasChildren(treeItem: TreeItem): boolean {
    const hasChildrenResult: boolean = !isNullOrUndefined(treeItem?.children?.length) && treeItem?.children?.length > 0;
    return hasChildrenResult;
  }
}
