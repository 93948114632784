import { Injectable } from '@angular/core';
import { EventFilter, EventFilterServiceBase, TraceModules } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { Event } from "@gms-flex/services/event/data.model";

@Injectable()
export class EventFilterBxSubstituteService extends EventFilterServiceBase {
  constructor(private readonly traceService: TraceService) {
    super();
    this.traceService.info(TraceModules.events, 'EventFilterBxSubstituteService created.');
  }

  public checkForSearchText(eventFilter: EventFilter, event: Event): boolean {
    if (!isNullOrUndefined(eventFilter.searchText)) {
      if (eventFilter.searchText.length > 0) {
        return this.isFilterMatch(eventFilter.searchText, event);
      }
    }

    return true;
  }

  private isFilterMatch(textToSearch: string, event: Event): boolean {
    // Check for event source descriptor
    let result = this.checkForEventDescriptor(textToSearch, event);

    if (result) {
      return result;
    }

    // Check for event source name
    result = this.checkForEventSrcName(textToSearch, event);

    if (result) {
      return result;   
    }

    // check for event cause (event message)
    result = this.matchString(textToSearch, event.cause);

    if (result) {
      return result;
    }

    // check event for event building name
    result = this.matchString(textToSearch, event.BuildingName);

    return result;
  }

  private checkForEventDescriptor(textToSearch: string, event: Event): boolean {
    for (const descriptorLocation of event.descriptionLocationsList) {
      if (this.matchString(textToSearch, descriptorLocation.Descriptor) === true) {
        this.traceService.info(TraceModules.events,
          'EventFilterBxSubstituteService: filter text %s matched with event source location %s', textToSearch, descriptorLocation.Descriptor);
        return true;
      }
    }

    for (const descriptor of event.descriptionList) {
      if (this.matchString(textToSearch, descriptor.Descriptor) === true) {
        this.traceService.info(TraceModules.events,
          'EventFilterBxSubstituteService: filter text %s matched with event source description %s', textToSearch, descriptor.Descriptor);
        return true;
      }
    }

    return false;
  }

  private checkForEventSrcName(textToSearch: string, event: Event): boolean {
    for (const designation of event.sourceDesignationList) {
      if (this.matchString(textToSearch, designation.Descriptor) === true) {
        this.traceService.info(TraceModules.events,
          'EventFilterBxSubstituteService: filter text %s matched with event source designation %s', textToSearch, designation.Descriptor);
        return true;
      }
    }

    return false;
  }

  private matchString(textToSearch: string, text2: string): boolean {
    return text2.toUpperCase().includes(textToSearch.toUpperCase());
  }
}
