import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AuthenticationServiceBase, ErrorNotificationServiceBase, TraceService } from '@gms-flex/services-common';
import { catchError, delay, map, Observable, Subject, Subscription } from 'rxjs';

import { ConnectionState, WsiOwner, WsiOwnership } from '../public-api';
import { SubscribeContextChannelizedSingle, SubscriptionUtility, TraceModules, WsiUtilityService } from '../shared';
import { HubProxyEvent, HubProxyShared, SignalRService } from '../signalr';
import { WsiEndpointService } from '../wsi-endpoint';

const RECONNECT_TIMEOUT = 5000;
const ENDPOINT_OWNERSHIP = '/api/ownershipCommand';
const ENDPOINT_OWNERSHIP_DELETE = '/api/sr/ownershipsubscriptions/';
const ENDPOINT_OWNERSHIP_CHANNELIZE = '/api/sr/ownershipsubscriptions/channelize/';

// const systemsUrl = '/api/systems';
// const systemsLocalUrl = '/api/systems/local';
// const systemsSubscriptionUrl = '/api/sr/systemssubscriptions/';
// const systemsSubscriptionChannelizeUrl = '/api/sr/systemssubscriptions/channelize/';

type SubscriptionTarget = 'owner' | 'ownership';

@Injectable({
  providedIn: 'root'
})
export class OwnershipServiceProxy implements OnDestroy {
  public hubProxyShared: HubProxyShared;

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _subscribeRequestsInvoked: Map<string, SubscribeContextChannelizedSingle<boolean>> =
    new Map<string, SubscribeContextChannelizedSingle<boolean>>();
  private readonly _subscribeRequestsPending: Map<string, SubscribeContextChannelizedSingle<boolean>> =
    new Map<string, SubscribeContextChannelizedSingle<boolean>>();
  private readonly subscriptions: Subscription[] | undefined = [];

  private readonly hubProxyEventOwnership: HubProxyEvent<any[]>;
  private readonly hubProxyEventOwner: HubProxyEvent<any[]>;
  private readonly ownershipNotificationWSI: Subject<void> = new Subject<void>();

  constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly authenticationServiceBase: AuthenticationServiceBase,
    private readonly wsiEndpointService: WsiEndpointService,
    private readonly wsiUtilityService: WsiUtilityService,
    private readonly errorService: ErrorNotificationServiceBase,
    private readonly signalRService: SignalRService,
    private readonly ngZone: NgZone
  ) {
    this.hubProxyShared = this.signalRService.getNorisHub();

    // Ownership
    this.hubProxyEventOwnership = new HubProxyEvent<any[]>(
      this.traceService, this.hubProxyShared, 'notifyChangeOfOwnership', this.ngZone, this.signalRService);

    // Owner
    this.hubProxyEventOwner = new HubProxyEvent<any[]>(
      this.traceService, this.hubProxyShared, 'notifyChangeOfOwner', this.ngZone, this.signalRService);

    this.hubProxyEventOwnership.eventChanged.subscribe(ownershipItem => this.onChangeOfOwnership(ownershipItem));
    this.hubProxyEventOwner.eventChanged.subscribe(ownershipItem => this.onChangeOfOwnership(ownershipItem));

    this.subscriptions?.push(this.hubProxyShared?.hubConnection?.connectionState?.subscribe((value: any) =>
      this.onSignalRConnectionState(value)));

    const disconnectedObservable: Observable<boolean> | undefined = this.hubProxyShared?.hubConnection?.disconnected;
    if (disconnectedObservable !== undefined) {
      disconnectedObservable.pipe(delay(RECONNECT_TIMEOUT)).subscribe(
        value => this.onSignalRDisconnected(value), error => this.onSignalRDisconnectedError(error));
    }
    this.traceService.info(TraceModules.ownership, 'Ownership service created.');
  }

  public ngOnDestroy(): void {
    this.subscriptions?.forEach(element => element.unsubscribe());
  }

  public ownershipNotification(): Observable<void> {
    return this.ownershipNotificationWSI.asObservable();
  }

  public onChangeOfOwnership(ownershipItem: any): void {
    this.ownershipNotificationWSI.next(ownershipItem);
  }

  public fetchOwnership(): Observable<WsiOwner> {
    this.traceService.info(TraceModules.ownership, 'fetchOwnership() called.');
    const headers: HttpHeaders = this.wsiUtilityService.httpGetDefaultHeader(this.authenticationServiceBase.userToken);
    const url: string = this.wsiEndpointService?.entryPoint + ENDPOINT_OWNERSHIP;

    return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.wsiUtilityService.extractData(response, TraceModules.ownership, 'fetchOwnership()')),
      catchError((response: HttpResponse<any>) =>
        this.wsiUtilityService.handleError(response, TraceModules.ownership, 'fetchOwnership()', this.errorService)));
  }

  public updateOwnership(owner: WsiOwnership): Observable<WsiOwnership> {
    this.traceService.info(TraceModules.ownership, 'updateOwnership() called.');
    const headers: HttpHeaders = this.wsiUtilityService.httpPutDefaultHeader(this.authenticationServiceBase.userToken);

    const url: string = this.wsiEndpointService.entryPoint + ENDPOINT_OWNERSHIP;
    const body: WsiOwnership = owner;

    return this.httpClient.post(url, body, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.wsiUtilityService.extractData(response, TraceModules.ownership, 'updateOwnership()')),
      catchError((response: HttpResponse<any>) =>
        this.wsiUtilityService.handleError(response, TraceModules.ownership, 'updateOwnership()', this.errorService)));
  }

  // Subscriptions

  /**
   * Subscribe to owner or ownership changes
   * @param target derfines target name. Could be owner or ownership
   * @returns
   */
  public subscribe(target: SubscriptionTarget): Observable<any> {
    this.traceService.info(TraceModules.ownership, 'OwnershipProxyService' + target + ' called.');
    const startTime = performance.now();

    const httpPostProxy: Subject<boolean> = new Subject<boolean>();
    const ctx: SubscribeContextChannelizedSingle<boolean> = new SubscribeContextChannelizedSingle<boolean>(httpPostProxy);

    if (this.hubProxyShared.hubConnection?.isConnected === false) {
      this._subscribeRequestsPending.set(ctx.id, ctx);
      this.traceService.debug(TraceModules.ownership,
        'subscribeOwner(): signalr connection not established; need to wait (postpone http calls) until established in order to get connection id.');
      const connectedSubscription: Subscription = this.hubProxyShared.hubConnection.connected.subscribe(started => {
        if (started === true) {
          this.traceService.debug(TraceModules.ownership, 'subscribeOwner(): connected event triggered; conection is now established.');
          // connection ID is available now, we can setup the "post observable" now and not earlier
          // (=> due to this we cannot use rxjs merge stream functionality such as "concat"!!)
          if (connectedSubscription !== undefined) {
            connectedSubscription.unsubscribe();
          }

          this.subscribeWsi(startTime, '(after connecting)...', ctx, target, httpPostProxy);
        }
      });
      this.hubProxyShared.hubConnection.startHubConnection();
    } else {
      this.subscribeWsi(startTime, 'immediately...', ctx, target, httpPostProxy);
    }
    return httpPostProxy.asObservable();
  }

  // Unsubscibe
  public unsubscribeOwnership(target: SubscriptionTarget): Observable<boolean> {
    this.traceService.info(TraceModules.systems, 'OwnershipProxyService.unsubscribeOwnership() called');

    const httpDeleteProxy: Subject<boolean> = new Subject<boolean>();
    if (this.hubProxyShared?.hubConnection?.isConnected === false) {
      this.traceService.debug(TraceModules.systems,
        'unsubscribeOwnership(): signalr connection not established; need to wait ' +
                '(postpone http calls) until established in order to get connection id.');
      const connectedSubscription: Subscription = this.hubProxyShared.hubConnection.connected.subscribe(started => {
        if (started === true) {
          this.traceService.debug(TraceModules.systems, 'unsubscribeOwnership(): connected event triggered: connection is now established.');
          // connection ID is available now, we can setup the "post observable" now and not earlier
          // => due to this we cannot use rxjs merge stream functionality such as "concat"!!
          if (connectedSubscription !== undefined) {
            connectedSubscription.unsubscribe();
          }
          this.traceService.debug(TraceModules.systems, 'unsubscribeOwnership(); http delete can be issued now (connection is finally established)');
          this.invokeHttpDeleteOwnershipSubscription(httpDeleteProxy, target);
        }
      });
      this.hubProxyShared.hubConnection.startHubConnection();
    } else {
      this.traceService.debug(TraceModules.systems,
        'OwnershipProxyService.unsubscribeOwnership(); http delete can be issued immediately (connection is already established)');
      this.invokeHttpDeleteOwnershipSubscription(httpDeleteProxy, target);
    }
    return httpDeleteProxy.asObservable();
  }

  // Subscriptions

  private subscribeWsi(startTime: number, stateMsg: string,
    ctx: SubscribeContextChannelizedSingle<boolean>,
    target: SubscriptionTarget,
    httpPostProxy: Subject<boolean>): void {
    const headers: HttpHeaders = this.wsiUtilityService.httpPostDefaultHeader(this.authenticationServiceBase.userToken);

    const endpoint = ENDPOINT_OWNERSHIP_CHANNELIZE;

    const url: string = this.wsiEndpointService.entryPoint + endpoint + ctx.id + '/' + this.hubProxyShared.connectionId;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const body: any = JSON.stringify({ });
    const httpPost = this.subscriptionPost(url, body, headers);
    this.traceService.debug(TraceModules.ownership,
      'OwnershipProxyService' + target + '  http post can be issued ' + stateMsg);
    httpPost.subscribe(value => this.onSubscribeNext(value, httpPostProxy, startTime, target),
      error => this.onSubscribeError(error, ctx, httpPostProxy, target));
    this._subscribeRequestsInvoked.set(ctx.id, ctx);
    this._subscribeRequestsPending.delete(ctx.id);
  }

  private subscriptionPost(url: string, body: any, headers: HttpHeaders): Observable<boolean> {
    return this.httpClient.post(url, body, { headers }).pipe(
      map((response: HttpResponse<any> | any) =>
        true),
      catchError((response: HttpResponse<any>) =>
        this.wsiUtilityService.handleError(response, TraceModules.ownership, 'subscribeWsi()', this.errorService)));
  }

  private onSubscribeNext(value: boolean, httpPostProxy: Subject<boolean>, startTime: number, target: SubscriptionTarget): void {
    this.traceService.info(TraceModules.ownership, 'OwnershipProxyService subscribe' + target + ' done: success=%s', value);
    this.traceService.info(TraceModules.ownership, 'OwnershipProxyService subscribe' + target + ' done: success=%s, time=%sms',
      value, performance.now() - startTime);
    // nothing to do if okay! we need to wait of the subscription notification over signalR
  }

  private onSubscribeError(error: any, ctx: SubscribeContextChannelizedSingle<boolean>, httpPostProxy: Subject<boolean>, target: SubscriptionTarget): void {
    this.traceService.warn(TraceModules.ownership, 'OwnershipProxyService subscription error' + target + ' http post returned an error; %s', error);
    this._subscribeRequestsInvoked.delete(ctx.id);
    httpPostProxy.error(error);
  }

  // Unsibscribe --------

  private invokeHttpDeleteOwnershipSubscription(httpDeleteProxy: Subject<boolean>, target: SubscriptionTarget): void {
    const methodName = 'OwnershipProxyService.unsubscribeOwnership()';
    const headers: HttpHeaders = this.wsiUtilityService.httpDeleteDefaultHeader(this.authenticationServiceBase.userToken);

    const endpoint = ENDPOINT_OWNERSHIP_DELETE;

    const url: string = this.wsiEndpointService.entryPoint + (endpoint + this.hubProxyShared?.connectionId);
    const httpDelete: Observable<boolean> = this.httpClient.delete(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) =>
        this.wsiUtilityService.extractData(response, TraceModules.systems, methodName)),
      catchError((response: HttpResponse<any>) =>
        this.wsiUtilityService.handleError(response, TraceModules.systems, methodName, this.errorService)));
    httpDelete.subscribe(value => this.onUnsubscribeOwnershipNext(value, httpDeleteProxy),
      error => this.onUnsubscribeOwnershipError(error, httpDeleteProxy));
  }

  private onUnsubscribeOwnershipNext(value: boolean, httpPostProxy: Subject<boolean>): void {
    httpPostProxy.next(value);
    httpPostProxy.complete();
  }

  private onUnsubscribeOwnershipError(error: any, httpPostProxy: Subject<boolean>): void {
    this.traceService.warn(TraceModules.systems, 'OwnershipProxyService.onUnsubscribeOwnershipError(); http post returned an error; %s', error);
    httpPostProxy.error(error);
  }

  // SignalR methods ---------------------

  private onSignalRConnectionState(value: SignalR.ConnectionState): void {
    if (value === SignalR.ConnectionState.Disconnected) {
      this._subscribeRequestsInvoked.forEach(ctx => {
        ctx.postSubject.error('Notification channel disconnected.');
      });
      this._subscribeRequestsInvoked.clear();
    }
    this._notifyConnectionState.next(SubscriptionUtility.convert(value));
  }

  private onSignalRDisconnectedError(error: any): void {
    this.traceService.error(TraceModules.ownership, 'OwnershipProxyService.onSignalRDisconnectedError(): %s', error.toString());
  }

  private onSignalRDisconnected(value: boolean): void {
    if (value === true) {
      if (this.hubProxyShared.hubConnection?.connectionStateValue === SignalR.ConnectionState.Disconnected) {
        this.traceService.info(TraceModules.ownership, 'OwnershipProxyService.onSignalRDisconnected(): starting again the connection');
        this.hubProxyShared.hubConnection.startHubConnection();
      }
    }
  }
}
