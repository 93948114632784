import { Injectable } from '@angular/core';
import { ConnectionState, SuppressedObjects, SuppressedObjectsProxyServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject } from 'rxjs';

@Injectable()
export class SuppressedObjectsBxSubstituteProxyService extends SuppressedObjectsProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _suppressedObjects: Subject<SuppressedObjects> = new Subject<SuppressedObjects>();

  public constructor(
    private readonly traceService: TraceService) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered. There is no constant streaming channel.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.traceService.info(TraceModules.events, 'SuppressedObjectsBxSubstituteProxyService created.');
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public subscribeSuppressedObjects(): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'SuppressedObjectsBxSubstituteProxyService.subscribeSuppressedObjects() called.');

    return scheduled([true], asapScheduler);
  }

  public suppressedObjectsNotification(): Observable<SuppressedObjects> {
    return this._suppressedObjects.asObservable();
  }

  public unSubscribeSuppressedObjects(): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'SuppressedObjectsBxSubstituteProxyService.unSubscribeSuppressedObjects() called');

    return scheduled([true], asapScheduler);
  }
}
