import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { EMPTY, expand, Observable } from 'rxjs';
import { catchError, map, reduce } from 'rxjs/operators';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { CustomerResponse, CustomerResponsePaged } from './user-self-proxy.model';

const customerUrl = `${environment.bxPlatform.userMgmtApiUrl}/v2/me/customers`

@Injectable({
  providedIn: 'root'
})
export class UserSelfProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesUserSelf, 'UserSelfProxyService created.');
  }

  public getCustomers(): Observable<CustomerResponse> {
    this.traceService.debug(TraceModules.bxServicesUserSelf, 'UserSelfProxyService.getCustomers() called');
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    let params: HttpParams = new HttpParams();
    params = params.set('page[size]', '100'); // max is 100

    return this.httpClient.get<CustomerResponsePaged>(customerUrl, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.links?.next) {
          params = params.set('page[next]', response.body?.links?.next);
          return this.httpClient.get<CustomerResponsePaged>(customerUrl, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body.data ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data] })),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getCustomers()')));
  }

  // public findCustomer(entityId: string): Observable<Customer> {
  //   this.traceService.debug(traceChannelHorizon, 'findCustomer() called');
  //   return this.getCustomers().pipe(
  //     map(response => {
  //       return response.data.find(customer => customer.id === entityId)
  //     })
  //   );
  // }

  // public findCustomerByName(name: string): Observable<Customer> {
  //   this.traceService.debug(traceChannelHorizon, 'getCustomer() called');
  //   return this.getCustomers().pipe(
  //     map(response => {
  //       return response.data.find(customer => customer.attributes.name === name)
  //     })
  //   );
  // }

}
