import { Injectable, NgZone } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { Subscription, timer } from 'rxjs';

import { Evaluation } from '../processor/evaluation';

@Injectable()
export class GmsAnimationTimerService {

  private readonly traceModule: string = 'gmsSnapins_AnimationTimerService';

  private _timer: any;
  private _timerSubscription: Subscription;

  private readonly _evaluations: Evaluation[] = new Array<Evaluation>();
  private readonly _intervals: Interval[] = new Array<Interval>();

  public constructor(private readonly traceService: TraceService, public zone: NgZone) {
    this.traceService.info(this.traceModule, 'Animation timer service created.');
    this.zone.runOutsideAngular(() => {
      this._timer = timer(30, 30);
      this._timerSubscription = this._timer.subscribe(value => {
        this.onTimerTick(value);
      });
    });
  }

  public async subscribe(subscriber: any, interval: number): Promise<void> {
    if (subscriber === undefined) {
      return;
    }

    if (this._intervals.find(x => x.AssociatedSubscriber === subscriber) === undefined) {
      this._intervals.push(new Interval(subscriber, interval));
      this.UpdateTimer();
    }
  }

  public async unsubscribe(subscriber: any): Promise<void> {
    if (subscriber === undefined) {
      return;
    }
    const interval: Interval = this._intervals.find(x => x.AssociatedSubscriber === subscriber);
    if (interval !== undefined) {
      interval.Clear();
      const index: number = this._intervals.indexOf(interval);
      this._intervals.splice(index, 1);
    }
  }

  public async cleartimers(): Promise<void> {
    if (this._timerSubscription !== undefined) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }
    if (this._intervals !== undefined) {
      this._intervals.forEach(interval => interval.Clear());
      this._intervals.length = 0;
    }
  }

  private UpdateTimer(): void {
    if (this._evaluations === undefined) {
      return;
    }
    if (this._timerSubscription === undefined) {
      this.zone.runOutsideAngular(() => {
        this._timerSubscription = this._timer.subscribe(value => this.onTimerTick(value));
      });
    }
  }

  private onTimerTick(counter: number): void {
    if (this._intervals === undefined || this._intervals.length === 0) {
      this.cleartimers();
      return;
    }

    this._intervals.forEach(interval => {
      interval.Tick();
    });
  }
}

export class Interval {

  private readonly _delay: number = undefined;
  private readonly _ticks: number = undefined;
  private _count = 0;
  private _subsciber: any = undefined;
  public get AssociatedSubscriber(): any {
    return this._subsciber;
  }

  public Tick(): void {
    if (this._count >= this._ticks) {
      this._subsciber.TickAnimation();
      this._count = 0;
    }
    this._count++;
  }

  constructor(subscriber: any, delay: number) {
    this._subsciber = subscriber;
    this._delay = delay;
    // eslint-disable-next-line no-bitwise
    this._ticks = (delay / 30) | 0;
  }

  public Clear(): void {
    this._subsciber = undefined;
  }
}
