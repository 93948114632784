import { AnyProperty } from '@simpl/object-browser-ng/property-viewer';
import { Observable, of } from 'rxjs';

import { DataPoint, DataPointInfo, SceneCommand, SceneModel } from '../interfaces/scene-editor';

export abstract class SceneEditorApi {
  /**
   * Gets the scene model.
   */
  abstract getSceneModel(objectId: string): Observable<SceneModel>;

  /**
   * Adds a new data point.
   */
  abstract addDataPoint(objectId: string): Observable<DataPoint[]>;

  /**
   * Learn the values for the scene.
   */
  abstract learnValues(
    objectId: string,
    sceneId: string,
    sceneModel: SceneModel
  ): Observable<SceneCommand[]>;

  /**
   * Test the scene.
   */
  abstract testScene(objectId: string, sceneId: string, sceneModel: SceneModel): void;

  /**
   * Save the scene model.
   */
  abstract saveSceneModel(objectId: string, sceneModel: SceneModel): Observable<boolean>;

  /**
   * Save As the scene model.
   */
  abstract saveSceneModelAs(objectId: string, sceneModel: SceneModel): Observable<boolean>;

  /**
   * deleteSceneModel scene model.
   */
  abstract deleteSceneModel(objectId: string): Observable<boolean>;

  /**
   * Reads list of properties for the datapoint to show configure dialog.
   */
  abstract getProperties(objectId: string, dataPointId: string): Observable<AnyProperty[]>;

  /**
   * Reads list of custom menus for datapoint to show.
   */
  abstract navigateToDataPoint(property: DataPointInfo): void;
}

/**
 * Used for testing.
 */
export class FakeSceneApiService implements SceneEditorApi {
  readonly model: SceneModel = {
    scenes: [],
    dataPoints: [],
    commands: []
  };

  getSceneModel(objectId: string): Observable<SceneModel> {
    return of(this.model);
  }

  addDataPoint(objectId: string): Observable<DataPoint[]> {
    return of([]);
  }

  learnValues(
    objectId: string,
    sceneId: string,
    sceneModel: SceneModel
  ): Observable<SceneCommand[]> {
    return of([]);
  }

  testScene(objectId: string, sceneId: string, sceneModel: SceneModel): void {}

  saveSceneModel(objectId: string, sceneModel: SceneModel): Observable<boolean> {
    return of(true);
  }

  saveSceneModelAs(objectId: string, sceneModel: SceneModel): Observable<boolean> {
    return of(true);
  }

  deleteSceneModel(objectId: string): Observable<boolean> {
    return of(true);
  }

  getProperties(objectId: string, dataPointId: string): Observable<AnyProperty[]> {
    return of([]);
  }

  navigateToDataPoint(property: DataPointInfo): void {}
}

/**
 * Used for DRY in testing.
 */
export const fakeSceneApiServiceProvider = {
  provide: SceneEditorApi,
  useClass: FakeSceneApiService
};
