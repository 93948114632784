import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { AlarmsContainer } from '../../common/interfaces/AlarmsContainer';
import { TraceChannel } from '../../common/trace-channel';
import { GmsAdorner } from '../../elements/gms-adorner';
import { GmsAlarm } from '../../elements/gms-alarm';
import { GmsElement } from '../../elements/gms-element';
import { GmsGraphic } from '../../elements/gms-graphic';
import { GmsSymbolInstance } from '../../elements/gms-symbol-instance';
import { Evaluation } from '../../processor/evaluation';
import { Expression, SemanticType } from '../../processor/expression';
import { Substitution } from '../../processor/substitution';
import { GraphicType } from '../../types/gms-element-property-types';
import { GmsElementReplicationOrientationType } from '../../types/gms-element-types';
import { Guid } from '../../utilities/guid';
import { Utility } from '../../utilities/utility';
import { GraphicsDatapointHelper } from '../datapoint/gms-datapoint-helper';
import { WildCardReferenceItem } from './wildcardreferenceitem';

/*
Replication cases
a) Simple expression
Expression=”dp[*]6”
repl2

b) Expression with ?-operator
Expression="xy[*] ? dp[*]","xy[*] ? zt[*] ? 123" and "xy[*] ? zt[*] ?"
repl2b

c) Simple symbol with *-substitution
Expression=”{*xy}”
ObjectRef=”dp[*]7”
repl3, s2

d) Simple symbol with substitution
Expression=”{sub}”
sub=”dp[*]8”
repl4, s3

e) Simple symbol with multiple substitutions
Expression=”{sub1}{sub2}[*]4”
sub1=”d”, sub2=”p”
repl5, s4

f) Graphic Template with symbol
Expression=”{sub}”
sub=”{*}p[*]”
Assign=”d”
gt1, s3

g) Nested symbol with different substitution names
ExpressionNested=”{*xy}”
ObjectRefNested=”{*}”, Expression=”{*dp}”
ObjectRef=”dp[*]3”
repl6, s1, s2

h) Nested symbol with same substitution names
ExpressionNested=”{sub}”
ObjectRefNested=”{sub}”
ObjectRef=”dp[*]1”
repl7, s5c
    */

// Functions like a gms-group with cloned elements, where the group is a passive container.
export class Replication implements AlarmsContainer {

  public propertyChanged: Subject<string> = new Subject<string>();
  public loadComplete: Subject<string> = new Subject<string>();
  public alarmsChanged: Subject<string> = new Subject<string>();

  public children: GmsElement[] = undefined;
  public adorners: GmsAdorner[] = null; // To Display adorners inside the replication container
  public alarms: GmsAlarm[] = undefined;

  private _wildCardReferenceItem: WildCardReferenceItem = undefined;
  private _resolveSubscription: Subscription = undefined;
  private _loadCompleteSubscription: Subscription = undefined;
  private _clonesRendered = false;

  private _X = 0;
  public get X(): number {
    return this._X;
  }
  public set X(value: number) {
    if (this._X !== value) {
      this._X = value;
      this.NotifyPropertyChanged('X');

    }
  }

  private _Y = 0;
  public get Y(): number {
    return this._Y;
  }
  public set Y(value: number) {
    if (this._Y !== value) {
      this._Y = value;
      this.NotifyPropertyChanged('Y');

    }
  }

  private _Width = 0;
  public get Width(): number {
    return this._Width;
  }
  public set Width(value: number) {
    if (this._Width !== value) {
      this._Width = value;
      this.NotifyPropertyChanged('Width');
    }
  }

  private _Height = 0;
  public get Height(): number {
    return this._Height;
  }
  public set Height(value: number) {
    if (this._Height !== value) {
      this._Height = value;
      this.NotifyPropertyChanged('Height');

    }
  }

  private _isParentVisible = true;
  public get IsParentVisible(): boolean {
    return !!this._isParentVisible;
  }
  public set IsParentVisible(value: boolean) {
    if (value !== this._isParentVisible) {
      this._isParentVisible = value;
      this.UpdateChildrenVisible();
    }
  }

  // True if the graphic is deserialized
  private _isCloneReady = false;
  public get IsCloneReady(): boolean {
    return this._isCloneReady;
  }
  public set IsCloneReady(value: boolean) {
    if (this._isCloneReady !== value) {
      this._isCloneReady = value;
    }
  }

  private _isResolved = false;
  public get IsResolved(): boolean {
    return this._isResolved;
  }

  private _owner: GmsElement;
  public get Owner(): GmsElement {
    return this._owner;
  }
  public set Owner(value: GmsElement) {

    if (this._owner !== undefined) {
      this._owner = value;
    }
  }

  // the indexes from the property "Index Range" - what we want
  private _requestedIndexes: number[] = [];
  public get RequestedIndexes(): number[] {
    return this._requestedIndexes;
  }
  public set RequestedIndexes(value: number[]) {

    if (this._requestedIndexes !== value) {
      this._requestedIndexes = value;
    }
  }

  // the indexes, which really exist - what we have
  private _existingIndexes: number[] = [];
  public get ExistingIndexes(): number[] {
    return this._existingIndexes;
  }
  public set ExistingIndexes(value: number[]) {

    this._existingIndexes = value;
    this.RecalculateIndexes();
  }

  // the indexes, which are cloned - what we use
  private _clonedIndexes: number[] = [];
  public get ClonedIndexes(): number[] {
    return this._clonedIndexes;
  }
  public set ClonedIndexes(value: number[]) {

    if (this._clonedIndexes !== value) {
      this._clonedIndexes = value;
    }
  }

  // Source of the Replication can be SymbolInstance | Expression | Substitution.
  private _wildCardItem: any;
  public get WildCardItem(): any {
    return this._wildCardItem;
  }
  public set WildCardItem(value: any) {

    if (value !== undefined) {
      this._wildCardItem = value;
    }
  }

  private _wildCardReference: string;
  public get WildCardReference(): string {
    return this._wildCardReference;
  }
  public set WildCardReference(value: string) {

    if (this._wildCardItem !== undefined && this._wildCardReference !== value) {
      this._wildCardReference = GraphicsDatapointHelper.RemoveLeadingSemicolons(value);

      // Clear the existing clones
      if (this.HasClones) {
        this.children.forEach(clone => {
          clone.Destroy();
        });

        this.children.length = 0;
      }

      this.ResolveWildCardReference();
    }
  }

  public get WildCardReferenceBase(): string {
    if (this._wildCardReference === undefined) {
      return undefined;
    }

    const index: number = this._wildCardReference.lastIndexOf(Utility.REPLICATION_WILDCARD);
    if (index > 0) {
      return this._wildCardReference.substr(0, index);
    } else {
      return this._wildCardReference;
    }
  }

  public get toggleAlarmsVisibility(): BehaviorSubject<boolean> {
    const graphic: GmsGraphic = this._owner.Graphic as GmsGraphic;
    return graphic.toggleAlarmsVisibility;
  }

  private _orientation: GmsElementReplicationOrientationType = GmsElementReplicationOrientationType.Vertical;
  public get Orientation(): GmsElementReplicationOrientationType {
    return this._orientation;
  }
  public set Orientation(value: GmsElementReplicationOrientationType) {
    this._orientation = value;
  }

  private _viewportWidth = 0;
  public get ViewPortWidth(): number {
    return this._viewportWidth;
  }

  private _viewportHeight = 0;
  public get ViewPortHeight(): number {
    return this._viewportHeight;
  }

  private readonly _positionOffset: number = 10;
  private readonly _padding: number = 10;
  private readonly _scrollbarWidth: number = 20;
  private readonly _adornerOffset: number = 6;

  public get HasClones(): boolean {
    return this.children.length > 0;
  }

  public get HasScrollBar(): boolean {
    return this._Width > this._viewportWidth || this._Height > this._viewportHeight;
  }

  public get AlarmsContainer(): AlarmsContainer {
    // Has Scroll
    if (this.HasScrollBar) {
      return this;
    } else {
      // No Scroll
      if (this._owner === undefined) {
        return this;
      }

      const graphic: GmsGraphic = this._owner.Graphic as GmsGraphic;
      return graphic as AlarmsContainer;
    }
  }

  public GetTransformations(): string {
    return 'translate(' + this.X + ',' + this.Y + ')';
  }

  public get Alarms(): GmsAlarm[] {
    return this.alarms;
  }

  constructor(ownerElement: GmsElement) {
    this._owner = ownerElement;
    this.children = [];
    this.adorners = [];
    this.alarms = [];
  }

  public Destroy(): void {
    this._wildCardItem = undefined;
    this._wildCardReference = undefined;
    if (this._wildCardReferenceItem !== undefined) {
      this._wildCardReferenceItem = undefined;
      if (this._resolveSubscription !== undefined) {
        this._resolveSubscription.unsubscribe();
      }
    }

    this._existingIndexes.length = 0;
    this._requestedIndexes.length = 0;
    this._clonedIndexes.length = 0;
    this._isResolved = false;
    this._owner = undefined;

    if (this.children !== undefined) {
      const itemsToDestroy: GmsElement[] = this.children.slice();
      itemsToDestroy.forEach(child => {
        child.Destroy();
      });
      itemsToDestroy.length = 0;
    }

    this.adorners.length = 0;

    this.alarms.forEach(alarm => {
      alarm.Destroy();
    });
    this.alarms.length = 0;
    this.NotifyAlarmsChanged('Alarms Cleared'); // For RelatedItems navigation refresh issue

    if (this._loadCompleteSubscription !== undefined) {
      this._loadCompleteSubscription = undefined;
    }
  }

  public CreateClones(): void {
    try {
      if (this._owner.IsCopying) {
        this.FindParentReplicationAndSubscribe(this._owner);
        return;
      }

      if (this._loadCompleteSubscription !== undefined) {
        this._loadCompleteSubscription = undefined;
      }

      this._owner.Graphic.Zone.runOutsideAngular(() => {
        // Get the Valid Indexes
        this.RequestedIndexes = this.ParseIndexRange(this._owner.ReplicationIndexRange);
        this.ExistingIndexes = this._wildCardReferenceItem.AvailableIndexes;

        if (this._clonedIndexes.length === 0) {
          return;
        }

        // set current Orientation
        this._orientation = this._owner.ReplicationOrientation;

        this.CalculatePositionAndSize();

        let deferredInitiated = true;
        // Optimize datapoint resolution for replication.
        // If graphic load has already completed.
        if (!this._owner.Graphic.DatapointService.RunInDeferredMode) {
          this._owner.Graphic.DatapointService.RunInDeferredMode = true;
        } else {
          // Dont reset it. Already running deferred
          deferredInitiated = false;
        }

        // Clone and replace the indexes in the model based on the WildCardItem
        for (let i = 0; i < this._clonedIndexes.length; i++) {
          const clonedElement: GmsElement = this._owner.Graphic.ReplicationService.CreateClone(this._owner, this);
          clonedElement.IsParentVisible = this._isParentVisible;
          this.children.push(clonedElement);
        }

        this.PositionClones();

        const graphic: GmsGraphic = this._owner.Graphic as GmsGraphic;
        if (graphic.GraphicType === GraphicType.GraphicTemplate) {
          this.UpdateGraphicTemplateObjectReference();
        }

        // Links the clone substitutions to the substitution heirarchy.
        this.ProcessSubstitutionsForClones();

        // Replace the Replication in clones with valid indexes
        this.UpdateClones();

        // Optimize datapoint resolution for replication.
        // If graphic load has already completed.
        if (this._owner.Graphic.DatapointService.RunInDeferredMode && deferredInitiated) {
          this._owner.Graphic.DatapointService.RunInDeferredMode = false;
          this._owner.Graphic.DatapointService.processDeferredDatapoints(this._owner.Graphic.SelectedObject.ObjectId + Guid.newGuid());

          // Resolve replications wildcards "[*]" and start cloning
          // this._owner.Graphic.DatapointService.processDeferredWildCards()
          // .then(() => this._owner.Graphic.ReplicationService.ProcessDeferredReplications());
        }
      });

      this._owner.Graphic.Zone.runOutsideAngular(() => {
        setTimeout(() => this.SetClonesRendered(), 20);
        this.loadComplete.next('Loaded'); // For Child Replication to Clone
      });
    } catch (ex) {
      this._owner.Graphic.TraceService.error(TraceChannel.Processor, 'Replication Create Clones failed:', ex.stack);
    }
  }

  public UpdatePosition(): void {
    if (!this.HasClones) {
      return;
    }

    this._owner.Graphic.Zone.runOutsideAngular(() => {
      this.CalculatePositionAndSize();
      this.PositionClones();
    }); // end run ourside angular

    this._owner.Graphic.Zone.runOutsideAngular(() => { setTimeout(() => this.NotifyPropertyChanged('Render'), 10); });
  }

  public UpdateReplicationIndexRange(): void {
    if (!this.HasClones) {
      return;
    }

    // Clear the clones
    this.children.forEach(clone => {
      clone.Destroy();
    });
    this.children.length = 0;

    // Consider the changed replication index range and clone.
    this.CreateClones();
  }

  public AddAlarm(alarmToAdd: GmsAlarm): void {
    if (!this.alarms.includes(alarmToAdd)) {
      this.alarms.push(alarmToAdd);
      this.NotifyAlarmsChanged('Alarm Added');
    }
  }

  public RemoveAlarm(alarmToRemove: GmsAlarm): void {
    const index: number = this.alarms.indexOf(alarmToRemove);
    if (index > -1) {
      this.alarms.splice(index, 1);
      this.NotifyAlarmsChanged('Alarm Removed');
    }
  }

  public Clear(): void {
    if (this._wildCardReferenceItem !== undefined) {
      this._wildCardReferenceItem = undefined;
      if (this._resolveSubscription !== undefined) {
        this._resolveSubscription.unsubscribe();
        this._resolveSubscription = undefined;
      }
    }

    if (this.children !== undefined) {
      const itemsToDestroy: GmsElement[] = this.children.slice();
      itemsToDestroy.forEach(child => {
        child.Destroy();
      });
      itemsToDestroy.length = 0;
    }

    this._isResolved = false;
    this._clonesRendered = false;
  }

  private SetClonesRendered(): void {
    // mark render complete
    this.NotifyPropertyChanged('Render');

    // Alarms positioning is based on the clones
    // Clones need to be in the DOM, before alarms are rendered
    this._clonesRendered = true;

    this.children.forEach(child => {

      // Replications with scrollbar shall have
      // fixed alarm icon size, defined in alarm component
      if (this.HasScrollBar) {
        child.FixedAlarmSize = true;
      }

      // If clone has started recieving alarms already
      // Force add the alarm instance to container.
      if (child.Alarm !== undefined) {
        this.AlarmsContainer.AddAlarm(child.Alarm);
      }

      child.AlarmsContainerRef = this.AlarmsContainer;
    });
  }

  private async ResolveWildCardReference(): Promise<void> {
    const wildCardRefBase: string = this.WildCardReferenceBase;
    this._wildCardReferenceItem = this._owner.Graphic.DatapointService.GetOrCreateWildCardReference(wildCardRefBase);

    if (!this._wildCardReferenceItem.IsResolved) {
      this._resolveSubscription = this._wildCardReferenceItem.resolved.subscribe(value => this.OnResolved());
    } else { // Already resolved - Start Cloning if deserialization complete
      this._isResolved = true;

      if (this.IsCloneReady) {
        this.CreateClones();
      }
    }
  }

  private RecalculateIndexes(): void {
    let clonedIndexes: number[] = new Array<number>();
    if (this._existingIndexes !== undefined && this._existingIndexes.length > 0) {
      if (this._requestedIndexes === undefined || this._requestedIndexes.length === 0) {
        clonedIndexes = this._existingIndexes;
      } else {
        for (let i = 0; i < this._requestedIndexes.length; i++) {
          const index: number = this._requestedIndexes[i];
          if (this._existingIndexes.includes(index)) {
            clonedIndexes.push(index);
          }
        }
      }
    }

    this._clonedIndexes = clonedIndexes;
  }

  private OnResolved(): void {
    if (this._resolveSubscription !== undefined) {
      this._resolveSubscription.unsubscribe();
      this._resolveSubscription = undefined;
    }

    this._isResolved = true;
    if (this.IsCloneReady) {
      this.CreateClones();
    }
  }

  private UpdateClones(): void {

    try {
      if (this.WildCardItem instanceof GmsSymbolInstance) {
        for (let i = 0; i < this.children.length; i++) {
          const clone: GmsSymbolInstance = this.children[i] as GmsSymbolInstance;
          const clonedIndex: number = this._clonedIndexes[i];
          clone.ObjectRef = this._wildCardReference.replace(Utility.REPLICATION_SEARCH_REGX(), '[' + clonedIndex + ']');
          clone.UpdateObjectRefSubstitutions();
        }
      } else if (this.WildCardItem instanceof Expression) {
        const expression: Expression = this.WildCardItem as Expression;
        const expressionIndex: number = expression.Evaluation.Expressions.indexOf(expression);
        const evaluationName: string = expression.Evaluation.Property;
        for (let i = 0; i < this.children.length; i++) {
          const clone: GmsElement = this.children[i] as GmsElement;
          const clonedIndex: number = this._clonedIndexes[i];
          const cloneEvaluation: Evaluation = clone.Evaluations.get(evaluationName);
          const cloneExpression: Expression = cloneEvaluation.Expressions[expressionIndex];

          // Update expression without affecting the substitution
          cloneExpression.ReplaceReplicationIndex(clonedIndex);
        }
      } else if (this.WildCardItem instanceof Substitution) {
        const owner: GmsSymbolInstance = this._owner as GmsSymbolInstance;
        const substitution: Substitution = this.WildCardItem as Substitution;
        const substitutionIndex: number = owner.Substitutions.indexOf(substitution);
        if (substitutionIndex !== -1) {
          for (let i = 0; i < this.children.length; i++) {
            const clone: GmsSymbolInstance = this.children[i] as GmsSymbolInstance;
            const substitutionClone: Substitution = clone.Substitutions[substitutionIndex];
            const clonedIndex: number = this._clonedIndexes[i];
            substitutionClone.ReplaceReplicationIndex(clonedIndex);
          }
        }
      }

      // Update all the expressions
      for (let i = 0; i < this.children.length; i++) {
        const clone: GmsElement = this.children[i] as GmsElement;

        if (clone.Evaluations !== undefined && clone.Evaluations.size > 0) {
          clone.Evaluations.forEach((evaluation: Evaluation) => {
            evaluation.Expressions.forEach((expression: Expression) => {
              if ((expression.Semantictype === SemanticType.Script || expression.Semantictype === SemanticType.Reference)
                            && expression.Value !== undefined && expression.Value.includes(Utility.REPLICATION_WILDCARD)) {
                const clonedIndex: number = this._clonedIndexes[i];
                expression.ReplaceReplicationIndex(clonedIndex);
              }
            });
          });
        }
      }

      // Update Visibility of the cloned symbolinstances.
      if (this._owner instanceof GmsSymbolInstance) {
        for (let i = 0; i < this.children.length; i++) {
          const clone: GmsSymbolInstance = this.children[i] as GmsSymbolInstance;
          clone.CheckObjectReferenceExistence();
        }
      }

      // Update the zoom visibility of the clones.
      const graphic: GmsGraphic = this._owner.Graphic as GmsGraphic;
      for (let i = 0; i < this.children.length; i++) {
        const clone: GmsElement = this.children[i] as GmsElement;
        graphic.SetChildElementsVisibility(clone);
      }
    } catch (ex) {
      this._owner.Graphic.TraceService.error(TraceChannel.Processor, 'Replication Update Clones failed:', ex.stack);
    }
  }

  private CalculatePositionAndSize(): void {
    const maxReplicationExtent: number = this._owner.GetMaxReplicationExtent();
    const replicationSpace: number = this._owner.GetReplicationSpace();
    const elementWidth: number = this._owner.Width;
    const elementHeight: number = this._owner.Height;

    if (this._clonedIndexes.length === 0) {
      return;
    }

    // set current Orientation
    this._orientation = this._owner.ReplicationOrientation;

    this.X = this._owner.X - this._positionOffset;
    this.Y = this._owner.Y - this._positionOffset;
    const cloneCount: number = this._clonedIndexes.length;

    if (this._orientation === GmsElementReplicationOrientationType.Horizontal) {
      this.Width = (elementWidth * cloneCount) + ((replicationSpace * cloneCount) - replicationSpace) + (this._positionOffset * 2);

      this.Height = this._padding + elementHeight + this._adornerOffset;

      this._viewportWidth = maxReplicationExtent;
      this._viewportHeight = this._padding + elementHeight;

      if (this.Width > this._viewportWidth) {
        this._viewportHeight += this._scrollbarWidth;
      } else {
        this._viewportHeight += this._padding;
      }
    } else {
      this.Height = (elementHeight * cloneCount) + ((replicationSpace * cloneCount) - replicationSpace) + (this._positionOffset * 2);

      this.Width = this._padding + elementWidth + this._adornerOffset;

      this._viewportWidth = this._padding + elementWidth;
      this._viewportHeight = maxReplicationExtent;

      if (this.Height > this._viewportHeight) {
        this._viewportWidth += this._scrollbarWidth;
      } else {
        this._viewportWidth += this._padding;
      }
    }
  }

  private PositionClones(): void {
    let startX: number = this._positionOffset;
    let startY: number = this._positionOffset;
    const elementWidth: number = this._owner.Width;
    const elementHeight: number = this._owner.Height;
    const replicationSpace: number = this._owner.GetReplicationSpace();

    // Clone and replace the indexes in the model based on the WildCardItem
    for (let i = 0; i < this.children.length; i++) {
      const clonedElement: GmsElement = this.children[i];

      if (this._owner.ReplicationOrientation === GmsElementReplicationOrientationType.Horizontal) {
        clonedElement.X = startX;
        clonedElement.Y = startY; // clone(s) Y is taken care of by the replication container.
        startX = startX + elementWidth + replicationSpace;
      } else {
        clonedElement.X = startX; // clone(s) X is taken care of by the replication container.
        clonedElement.Y = startY;
        startY = startY + elementHeight + replicationSpace;
      }

    }
  }

  private NotifyPropertyChanged(propertyName: string = ''): void {
    if (this.propertyChanged.observers !== null && this.propertyChanged.observers.length > 0) {
      this.propertyChanged.next(propertyName);
    }
  }

  private ParseIndexRange(value: string): number[] {
    if (value === undefined || value.trim() === '') {
      return new Array<number>();
    }

    const resultIndexes: number[] = new Array<number>();
    const ranges: string[] = value.includes(';') ? value.split(';') : [value];
    for (let i = 0; i < ranges.length; i++) {
      let range: string = ranges[i];
      range = range.trim();
      if (range.length === 0) {
        continue;
      }

      let rangeStart = 0;
      let rangeEnd = 10000;
      const pos: number = range.indexOf('..');
      if (pos === -1) {
        const result = Number(range);
        if (result !== undefined || Number.isNaN(result)) {
          resultIndexes.push(result);
        }
      } else {
        let rangeString: string = range.substr(0, pos);
        if (rangeString.length > 0 && rangeString.trim().length !== 0) {
          const result = Number(rangeString);
          if (result !== undefined || Number.isNaN(result)) {
            rangeStart = result;
          }
        }
        rangeString = range.substr(pos + 2, range.length);
        if (rangeString.length > 0 && rangeString.trim().length !== 0) {
          const result = Number(rangeString);
          if (result !== undefined || Number.isNaN(result)) {
            rangeEnd = result;
          }
        }
        for (let j: number = rangeStart; j <= rangeEnd; j++) {
          resultIndexes.push(j);
        }
      }
    }

    return resultIndexes;
  }

  private ProcessSubstitutionsForClones(): void {
    if (this._owner.Parent === undefined) {
      return;
    }

    const parentSymbolInstance: GmsSymbolInstance = this.GetParentSymbolInstance(this._owner.Parent);
    if (parentSymbolInstance !== undefined) {
      this.MergeAndLinkSubstitutions(parentSymbolInstance);

      parentSymbolInstance.Substitutions.forEach(substitution => {
        // Update all clone's substition sources
        substitution.Update(false, undefined);
      });
    }
  }

  private MergeAndLinkSubstitutions(parentSymbolInstance: GmsSymbolInstance): void {
    const elements: GmsElement[] = parentSymbolInstance.children;
    let elementsSubstitution: GmsElement[] = elements;

    for (const child of elements) {
      // get all nested elements but without the children of GmsSymbolInstances
      elementsSubstitution = elementsSubstitution.concat(child.AllSubstitution);
    }

    // collect all substitutions from the elements (its evaluations and substitutions)
    const substitutionsFromContent: Map<string, Substitution> = new Map<string, Substitution>(); // the substitutions from the parsed expression

    for (const element of elementsSubstitution) {
      element.AddOrUpdateSubstitutions(substitutionsFromContent);
    }

    // merge substitutionsFromContent to Substitutions, where unused Substitutions will be deleted
    substitutionsFromContent.forEach((sourceSubstitution: Substitution, key: string) => {
      const substitution: Substitution = parentSymbolInstance.Substitutions.find(value =>
        value.Key.toLowerCase() === sourceSubstitution.Key.toLowerCase()
      );

      if (substitution === undefined) {
        parentSymbolInstance.Substitutions.push(sourceSubstitution);
      } else {

        // copy also the substitution sources
        for (const iSubst of sourceSubstitution.SubstitutionSources) {
          if (!substitution.SubstitutionSources.includes(iSubst)) {
            substitution.SubstitutionSources.push(iSubst);
          }
        }
      }
    });

    // remove all unused substitutions (those without substitution sources)
    const substitutionsToRemove: Substitution[] = parentSymbolInstance.Substitutions.filter((substitution: Substitution) =>
      substitution.SubstitutionSources.length === 0
    );

    substitutionsToRemove.forEach((substitution: Substitution) => {
      const indexToRemove: number = parentSymbolInstance.Substitutions.indexOf(substitution);
      parentSymbolInstance.Substitutions.splice(indexToRemove, 1);
    });
  }

  private GetParentSymbolInstance(element: GmsElement): GmsSymbolInstance {

    if (element === undefined) {
      return undefined;
    }

    if (element instanceof GmsSymbolInstance) {
      return element;
    }

    return this.GetParentSymbolInstance(element.Parent);
  }

  private HasParentSymbolInstance(element: GmsElement): boolean {
    const parentSymbolInstance: GmsSymbolInstance = this.GetParentSymbolInstance(element.Parent);
    return parentSymbolInstance !== undefined;
  }

  private FindParentReplicationAndSubscribe(element: GmsElement): void {
    const parentReplication: Replication = element.ReplicationService !== undefined ? element.ReplicationService.CurrentReplication : undefined;

    if (parentReplication === undefined) {
      return;
    }

    // Subscribe to parent replication for completion
    // Until the parent replicatiob finish cloning, don't clone.
    this._loadCompleteSubscription = parentReplication.loadComplete.subscribe(() => this.CreateClones());
  }

  private async UpdateGraphicTemplateObjectReference(): Promise<void> {

    const graphic: GmsGraphic = this._owner.Graphic as GmsGraphic;

    // In the case of graphic template the {*} star or object reference substitution's
    // actual value will not be available at the top level element or  SymbolInstance on template.
    // So get the object reference substitution from the owner element
    const substObjectReference: Substitution = new Substitution('*', graphic.ObjectReference);
    this.children.forEach(element => {

      // handle all star substitutions from the GmsSymbolInstance.Substitutions collection (but only when GmsSymbolInstance.ObjectRef contains "{*}")
      if (this._owner instanceof GmsSymbolInstance && this._owner.IsTopLevelSymbolInstance()) {
        const symbolInstance: GmsSymbolInstance = element as GmsSymbolInstance;

        symbolInstance.Evaluations.forEach(evaluation => {
          evaluation.Expressions.forEach(expression => {
            expression.Substitutions.forEach(substitution => {
              if (substitution.IsObjectRef) {
                // since this is not a child of a symbol instance, the SubstitutionSources have to be updated here
                if (!substitution.SubstitutionSources.includes(evaluation)) {
                  substitution.SubstitutionSources.push(evaluation);
                }
              }
            });
            expression.Update(false, substObjectReference);
          });
        });

        symbolInstance.Substitutions.forEach(substitution => {
          if (substitution.Substitutions !== undefined && substitution.Substitutions.size > 0) {
            symbolInstance.ObjectRef = substObjectReference.ValueSubstituted;
            substitution.Update(false, substObjectReference);
          }
        });
      } else if (!this.HasParentSymbolInstance(this._owner) && element.Evaluations !== undefined) {
        // handle all star substitutions from all evaluations
        element.Evaluations.forEach(evaluation => {
          evaluation.Expressions.forEach(expression => {
            expression.Substitutions.forEach(substitution => {
              if (substitution.IsObjectRef) {
                // since this is not a child of a symbol instance, the SubstitutionSources have to be updated here
                if (!substitution.SubstitutionSources.includes(evaluation)) {
                  substitution.SubstitutionSources.push(evaluation);
                }
              }
            });
            expression.Update(false, substObjectReference);
          });
        });
      }
    });
  }

  private NotifyAlarmsChanged(propertyName: string = ''): void {
    if (!this._clonesRendered) {
      return;
    }

    if (this.alarmsChanged.observers !== null && this.alarmsChanged.observers.length > 0) {
      this.alarmsChanged.next(propertyName);
    }
  }

  private UpdateChildrenVisible(): void {
    if (!this.HasClones) {
      return;
    }

    if (!!this.children) {
      this.children.forEach((element: GmsElement) => { element.IsParentVisible = this._isParentVisible; });
    }
  }
}
