import { booleanAttribute, Component, Input } from '@angular/core';
import { SiIconComponent } from '@simpl/element-ng/icon';

@Component({
  selector: 'si-icon-status',
  templateUrl: './si-icon-status.component.html',
  styleUrl: './si-icon-status.component.scss',
  standalone: true,
  imports: [SiIconComponent]
})
export class SiIconStatusComponent {
  /** Icon to display. */
  @Input({ required: true }) icon!: string;
  /** Stacked icon to display. */
  @Input() stackedIcon?: string;
  /** Counter below the icon */
  @Input() count = 0;
  /** Color of the icon */
  @Input() color?: string;
  /** Whether the icon is disabled. */
  @Input({ transform: booleanAttribute }) disabled?: boolean;

  protected get isDisabledOrCountZero(): boolean {
    return !!this.disabled || this.count === 0;
  }
}
