@if (bulkMode !== 'no') {
  <si-bulk-property-list
    siResponsiveContainer
    [editMode]="bulkMode === 'edit'"
    [bulkPropertyValues]="bulkPropertyValues"
    [bulkProperties]="bulkProperties"
    [filter]="filter"
    [dateFormat]="dateFormat"
    [timeFormat]="timeFormat"
    [allowValuesOutOfRange]="allowValuesOutOfRange"
    (selectProperty)="bulkPropertySelected($event)"
    (bulkPropertyState)="setBulkPropertyState($event)"
    (back)="bulkPropertyBack()"
    (filtered)="filtered.emit($event)"
  />
}

@if (bulkMode !== 'select') {
  <si-property-list
    siResponsiveContainer
    [class.d-flex]="this.displayStyle === 'table'"
    [class.flex-column]="this.displayStyle === 'table'"
    [class.flex-fill]="this.displayStyle === 'table'"
    [dateFormat]="dateFormat"
    [timeFormat]="timeFormat"
    [allowValuesOutOfRange]="allowValuesOutOfRange"
    [properties]="properties"
    [valueState]="valueState"
    [filter]="filter"
    [displayStyle]="displayStyle"
    [customTemplate]="customTemplate"
    (submitted)="submit($event)"
    (command)="command($event)"
    (filtered)="filtered.emit($event)"
  />
}
