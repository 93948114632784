import { isNullOrUndefined } from '@gms-flex/services-common';

/*
 *  This class represents the unique identifier of a SnapInInstance Instance.
 */
export class FullSnapInId {

  /*
   * Returns true if the two specified FullSnapIn points at the same path.
   */
  public static areEqual(x: FullSnapInId, y: FullSnapInId): boolean {
    if (isNullOrUndefined(x)) {
      return isNullOrUndefined(y);
    }
    if (isNullOrUndefined(y)) {
      return isNullOrUndefined(x);
    }
    return (x.snapInId === y.snapInId && x.frameId === y.frameId);
  }

  /*
   * Returns FullSnapIn from a given string
   */
  public static createFrom(fullId: string): FullSnapInId | null {
    if (!isNullOrUndefined(fullId)) {
      const splitted: string[] = fullId.split('.', 2);
      if (!isNullOrUndefined(splitted) && splitted.length === 2) {
        return new FullSnapInId(splitted[0], splitted[1]);
      }
    }
    return null;
  }

  public constructor(public frameId: string,
    public snapInId: string) {
  }

  /*
   * Gets the full Id as concatenated string.
   */
  public fullId(): string {
    return `${this.frameId}.${this.snapInId}`;
  }

}
