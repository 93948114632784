import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from '@building-x/common-ui-ng';
import { StateService } from '@gms-flex/core';
import { AppContextService, AuthenticationServiceBase, LanguageServiceBase, LocalizationService, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiThemeService, Theme, ThemeType } from '@simpl/element-ng';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { NotifyDialogComponent } from './notification-dialog/notify-dialog.component';

export const trcModuleNameApp = 'gmsApplication_Application';
const gmsDeepLink = 'gms-deep-link';

/**
 * GLOBAL DECLARATIONS START
 */
@Component({
  selector: 'gms-app',
  templateUrl: './app.component.html'
})

export class ApplicationComponent implements OnInit, OnDestroy {

  private readonly routingSub: Subscription;
  private modalRef: BsModalRef;
  /*
   * Handles initialization after directive's data-bound properties have been initialized.
   */
  public ngOnInit(): void {
    // localStorage.setItem('selectedPartitions', 
    //   `{"id": "78df2b10-212e-42f6-8a8f-f022d1fbfe4d","type": "Partition","attributes": {"name": "MIL R&D"}, "roles":[]}`);
    // this.authenticationServiceBase.restoreAuthentication.subscribe({ next: (value: boolean) => this.loggedIn(value) });
    // this.localizationService.detectLanguage(this.translateService.getBrowserLang()!);

    // if (this.bxAuthenticationService.checkSignIn) {
    // shall be set when login done (or the token is valid)
    this.appContextService.setUserName('cBms Test');
    this.appContextService.setUserDescriptor('cBms Test');
    this.appContextService.setUserCulture('en');
    // }

    this.siThemeService.themeNames$.pipe(first()).subscribe(names => {
      const customThemeName = 'custom';
      if (!names.includes(customThemeName)) {
        const customThemePath = './assets/themes/theme-' + customThemeName + '.json';
        this.httpClient.get<Theme>(customThemePath).subscribe({
          next: theme => {
            this.traceService.info('Added new custom theme, ' + customThemePath);
            this.siThemeService.addOrUpdateTheme(theme);
            this.traceService.info('Setting active theme to: ' + customThemeName);
            this.siThemeService.setActiveTheme(customThemeName);
          },
          error: error =>
            this.traceService.error('Error loading custom theme ' + customThemePath + ', default theme element is now active, details: ' + error + '.')
        });
      } else {
        this.traceService.info('Setting active theme to: ' + customThemeName);
        this.siThemeService.setActiveTheme(customThemeName);
      }
    });

    this.applyThemeType('auto');
  }

  public ngOnDestroy(): void {
    this.traceService.info(trcModuleNameApp, 'Application component destroyed.');
  }

  constructor(private readonly traceService: TraceService,
    private readonly modalService: BsModalService,
    private readonly stateService: StateService,
    private readonly authenticationServiceBase: AuthenticationServiceBase,
    private readonly bxAuthenticationService: AuthenticationService,
    private readonly router: Router,
    @Inject(TranslateService) private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly languageServiceBase: LanguageServiceBase,
    private readonly localizationService: LocalizationService,
    private readonly siThemeService: SiThemeService,
    private readonly httpClient: HttpClient
  ) {

    this.routingSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.saveFirstUrl(event);
      }
    });
    this.traceService.info(trcModuleNameApp, 'Launching application…');

    this.appContextService.themeType.subscribe(type => {
      this.traceService.info(trcModuleNameApp, `Applying them type: Type: ${type}`);
      if (this.siThemeService.resolvedColorScheme === type) {
        // Note:
        // SiSchemeService will not do a change of its theme type in this case.
        // If the selected scheme type is 'auto', the OS setting is 'dark' => the resolvedColorScheme is 'dark',
        // if we change noew the selected theme type to 'dark', this should cause a change nevertheless. But SiThemeService remains on 'auto'
        // This is a workaround to fix the issue: Force an (unneeded) theme type change which triggers SiThemeService.
        this.siThemeService.applyThemeType((type === 'light') ? 'dark' : 'light');
      }
      this.siThemeService.applyThemeType(type);
    });

  }

  /*
   * Handles AuthenticationService's user is log-in event.
   */
  public loggedIn(value: boolean): void {
    if (value != null) {
      this.appContextService.setUserName(this.authenticationServiceBase.userName);
      this.appContextService.setUserDescriptor(this.authenticationServiceBase.userDescriptor);

      this.languageServiceBase.getUserLanguage().subscribe({
        next: language => {
          if (language != null) {
            const lang = this.localizationService.detectLanguage(language.Code);
            this.translateService.use(lang).subscribe((res: any) => {
              this.appContextService.setUserCulture(lang);
              this.appContextService.setUserLocalizationCulture(this.translateService.getBrowserCultureLang());
              this.traceService.info(trcModuleNameApp, 'Applying user language: ' + this.translateService.currentLang);
            });
          }
          if (this.router.getCurrentNavigation() === null && this.router.routerState.snapshot.url !== '/communicationrules') {
            this.router.navigate(['/loading'], { skipLocationChange: true });
          }
        },
        error: error => {
          this.traceService.warn(trcModuleNameApp, 'Error retrieving user language: ' + error);
        }
      });
    }
  }

  private applyThemeType(themeType?: ThemeType): void {
    if (themeType === undefined) {
      themeType = 'auto';
    }
    this.appContextService.setThemeType(themeType);
  }

  private showNotifyDialog(heading: string, message: string): void {
    const initialState: ModalOptions = {
      initialState: {},
      ignoreBackdropClick: true,
      keyboard: false,
      animated: true
    };
    initialState.initialState['heading'] = heading; // eslint-disable-line
    initialState.initialState['message'] = message; // eslint-disable-line

    this.modalRef = this.modalService.show(NotifyDialogComponent, initialState);
  }

  private hideNotifyDialog(): void {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    }
  }

  private saveFirstUrl(event: any): void {
    this.routingSub.unsubscribe();
    let url: string = ((event.url).toString());
    if (url.startsWith('/loginpage?code')) { // TODO: '/login?code' ?
      if (sessionStorage.getItem(gmsDeepLink)) {
        url = sessionStorage.getItem(gmsDeepLink);
        sessionStorage.removeItem(gmsDeepLink);
        this.stateService.redirectUrl = url;
      } else {
        this.stateService.redirectUrl = '/';
      }
    } else if (url.startsWith('/loginpage') || url === '/') { // TODO: '/login' ?
      this.stateService.redirectUrl = '/';
      if (sessionStorage.getItem(gmsDeepLink)) {
        sessionStorage.removeItem(gmsDeepLink);
      }
    } else {
      sessionStorage.setItem(gmsDeepLink, url);
      this.stateService.redirectUrl = url;
    }
  }

}
