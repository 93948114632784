import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { GmsLayer } from '../elements/gms-layer';
import { GmsElementType } from '../types/gms-element-types';
import { GmsAnimatedGifComponent } from './gms-animated-gif.component';
import { GmsElementComponent } from './gms-element.component';
import { GmsEllipseComponent } from './gms-ellipse.component';
import { GmsGroupComponent } from './gms-group.component';
import { GmsImageComponent } from './gms-image.component';
import { GmsLineComponent } from './gms-line.component';
import { GmsNumericComponent } from './gms-numeric.component';
import { GmsPathComponent } from './gms-path.component';
import { GmsPipeComponent } from './gms-pipe.component';
import { GmsPolygonComponent } from './gms-polygon.component';
import { GmsRectangleComponent } from './gms-rectangle.component';
import { GmsReplicationComponent } from './gms-replication.component';
import { GmsRotatorComponent } from './gms-rotator-component';
import { GmsSliderComponent } from './gms-slider-component';
import { GmsSpinnerComponent } from './gms-spinner.component';
import { GmsStringCommandComponent } from './gms-string-command.component';
import { GmsSymbolInstanceComponent } from './gms-symbol-instance.component';
import { GmsTextComponent } from './gms-text.component';
import { GmsXpsComponent } from './gms-xps.component';
@Component({
  selector: '[gms-layer]',
  template: `<svg:g   [ngClass]="'noptrevents'"
                        [attr.width]="layer.Width" [attr.height]="layer.Height" [attr.fill]="layer.Fill"
                        [attr.visibility]="layer.IsLayerVisible">
                    <rect [attr.width]="layer.Width" [attr.height]="layer.Height"
                        [attr.fill]="layer.Background" [attr.fill-opacity]="layer.BackgroundOpacity" stroke-opacity="0" />
                    <g *ngFor="let item of layer.children">
                        <g *ngIf="item.Type === elementType.SymbolInstance" gms-symbol-instance [element]="item"/>
                        <g *ngIf="item.Type === elementType.Group" gms-group [element]="item"/>
                        <g *ngIf="item.Type === elementType.Ellipse" gms-ellipse [element]="item"/>
                        <g *ngIf="item.Type === elementType.Rectangle" gms-rectangle [element]="item"/>
                        <g *ngIf="item.Type === elementType.Path" gms-path [element]="item"/>
                        <g *ngIf="item.Type === elementType.Line" gms-line [element]="item"/>
                        <g *ngIf="item.Type === elementType.Polygon" gms-polygon [element]="item"/>
                        <g *ngIf="item.Type === elementType.Text" gms-text [element]="item"/>
                        <g *ngIf="item.Type === elementType.Image" gms-image [element]="item"/>
                        <g *ngIf="item.Type === elementType.Xps" gms-xps [element]="item"/>
                        <g *ngIf="item.Type === elementType.AnimatedGif" gms-animated-gif [element]="item"/>
                        <g *ngIf="item.Type === elementType.Replication" gms-replication [element]="item.Replication"/>
                        <g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Numeric" gms-numeric [element]="item"/>
                        <g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Selector" gms-selector [element]="item"/>
                        <g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Spinner" gms-spinner [element]="item"/>
                        <g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Slider" gms-slider [element]="item"/>
                        <g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Rotator" gms-rotator [element]="item"/>
                        <g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.String" gms-string-command [element]="item"/>
                        <g *ngIf="item.Type === elementType.Pipe" gms-pipe [element]="item"/>
                    </g>
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`, `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [GmsRectangleComponent, GmsEllipseComponent, GmsGroupComponent, GmsPathComponent, GmsLineComponent, GmsPolygonComponent,
    GmsTextComponent, GmsImageComponent, GmsXpsComponent, GmsSymbolInstanceComponent, GmsAnimatedGifComponent,
    GmsReplicationComponent, GmsNumericComponent, GmsSpinnerComponent, GmsSliderComponent, GmsRotatorComponent, GmsStringCommandComponent,
    GmsPipeComponent]
})
export class GmsLayerComponent extends GmsElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public layer: GmsLayer = null;
  public elementType: any = GmsElementType; // Store a reference to the enum, so that we can compare in the template

  public ngOnInit(): void {
    this.SubscribePropertyChanged(this.layer);
  }

  public ngOnDestroy(): void {
    this.UnSubscribePropertyChanged(this.layer);
  }
}
