import { CdkOverlayOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  booleanAttribute,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { getOverlay, getPositionStrategy, hasTrigger, positions } from '@simpl/element-ng/common';

import { PopoverComponent } from './si-popover.component';

@Directive({
  selector: '[siPopover]',
  exportAs: 'si-popover',
  standalone: true
})
export class SiPopoverDirective implements OnInit, OnDestroy {
  /**
   * The popover text to be displayed
   */
  @Input() siPopover!: string | TemplateRef<any>;

  /**
   * The placement of the popover. One of 'top', 'start', end', 'bottom'
   */
  @Input() placement: keyof typeof positions = 'auto';

  /**
   * The trigger event(s) on which the popover shall be displayed.
   * Applications can pass multiple triggers separated by space.
   * Supported events are 'click', 'hover' and 'focus'.
   *
   * **Limitations:**
   * Safari browsers do not raise a 'focus' event on host element click and 'focus'
   * on tab key has to be enabled in the advanced browser settings.
   */
  @Input() triggers = 'click';

  /**
   * The title to be displayed on top for the popover
   */
  @Input() popoverTitle = '';

  /**
   * The class that will be applied to container of the popover
   */
  @Input() containerClass = '';

  /**
   * The flag determines whether to close popover on clicking outside
   */
  @Input({ transform: booleanAttribute }) outsideClick = true;

  /**
   * The icon to be displayed besides popover title
   */
  @Input() icon?: string;

  /**
   * Specify whether or not the popover is currently shown
   */
  @Input({ transform: booleanAttribute }) isOpen? = false;

  /**
   * The context for the attached template
   */
  @Input() popoverContext?: unknown;

  /**
   * Emits an event when the popover is shown
   */
  @Output() readonly shown = new EventEmitter<void>();

  /**
   * Emits an event when the popover is hidden
   */
  @Output() readonly hidden = new EventEmitter<void>();

  private overlayref?: OverlayRef;
  private overlay = inject(Overlay);
  private elementRef = inject(ElementRef);

  ngOnInit(): void {
    if (
      this.placement !== 'top' &&
      this.placement !== 'bottom' &&
      this.placement !== 'start' &&
      this.placement !== 'end'
    ) {
      this.placement = 'auto';
    }
    if (this.isOpen) {
      this.show();
    }
  }

  ngOnDestroy(): void {
    this.overlayref?.dispose();
  }

  /**
   * Displays popover and emits 'shown' event.
   */
  show(): void {
    if (!this.overlayref?.hasAttached()) {
      const backdrop =
        this.outsideClick &&
        !hasTrigger('focus', this.triggers) &&
        !hasTrigger('hover', this.triggers);
      this.overlayref = getOverlay(this.elementRef, this.overlay, backdrop, this.placement);
      if (backdrop) {
        this.overlayref.backdropClick().subscribe(() => this.hide());
      }
    }

    if (this.overlayref.hasAttached()) {
      return;
    }
    const popoverPortal = new ComponentPortal(PopoverComponent);
    const popoverRef: ComponentRef<PopoverComponent> = this.overlayref.attach(popoverPortal);

    popoverRef.instance.popover = this.siPopover;
    popoverRef.instance.popoverTitle = this.popoverTitle;
    popoverRef.instance.anchor = new CdkOverlayOrigin(this.elementRef);
    popoverRef.instance.positions = positions[this.placement];
    popoverRef.instance.icon = this.icon;
    popoverRef.instance.containerClass = this.containerClass;
    popoverRef.instance.popoverContext = this.popoverContext;

    const positionStrategy = getPositionStrategy(this.overlayref);
    positionStrategy?.positionChanges.subscribe(change =>
      popoverRef.instance.updateArrow(change, this.elementRef)
    );

    popoverRef.changeDetectorRef.detectChanges();
    this.shown.emit();
  }

  /**
   * Hides the popover and emits 'hidden' event.
   */
  hide(): void {
    this.overlayref?.detach();
    this.hidden.emit();
  }

  /**
   * Updates the position of the popover based on the position strategy.
   */
  updatePosition(): void {
    this.overlayref?.updatePosition();
  }

  @HostListener('mouseenter', ['"hover"'])
  @HostListener('mouseleave', ['"hover"'])
  @HostListener('focus', ['"focus"'])
  @HostListener('click', ['"click"'])
  protected onTrigger(trigger: string): void {
    if (hasTrigger(trigger, this.triggers)) {
      if (this.overlayref?.hasAttached()) {
        this.hide();
      } else {
        this.show();
      }
    }
  }

  @HostListener('touchstart')
  @HostListener('focusout')
  protected focusOut(): void {
    if (hasTrigger('focus', this.triggers)) {
      if (this.outsideClick) {
        this.hide();
      }
    }
  }
}
