import { CommandParameters } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { AnyValueType, DateTimeValue } from '@simpl/element-value-types';
import { AnyPropertyValueType } from '@simpl/object-browser-ng';

import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType, DateFieldOrder } from './command-vm.types';
import { BACnetDateTime, SiTranslator, WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

/**
 * String parameter view-model implementation.
 */
export class DateTimeParamViewModel extends CommandParamViewModel {

  private defaultDt: Date;

  /**
   * Default parameter value as a Date object.
   */
  public get defaultValueAsDate(): Date {
    return this.defaultDt;
  }

  /**
   * Date field input order.
   */
  public get dateFieldOrder(): DateFieldOrder {
    return CommandParamViewModel.dateFieldOrderFromLocale(this.vmContext.locale);
  }

  /**
   * Parameter data type.
   */
  public get dataType(): CommandParamType {
    return CommandParamType.DateTime;
  }

  /**
   * Constructor.
   */
  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    // Initialize the si-param value
    this.siParam.value = {
      type: 'date-time',
      readonly: false,
      optional: false,
      value: undefined,
      wildcardAllowed: false,
      specialAllowed: false
    } as DateTimeValue;

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Decode the provided object as a string representing a date-time value and encoded as a
   * string that can be passed to the WSI as a command parameter value.
   *
   * Method expects a string value representing a date-time.  If timezone information is not
   * included, time will be assumed to be local time.
   *
   * If the provided object cannot at first be converted to a Date object, undefined will be returned
   * to indicate `invalid` argument.
   *
   * Returned string will be an ISO formatted string representing provided value in UTC.
   * Example,
   *  2018-09-14T19:49:06Z  ==> 2018-09-14T19:49:06.000Z
   *  9/14/2018 14:49:06    ==> 2018-09-14T19:49:06.000Z (assuming CST timezone)
   *  9/14/2018 2:49:06 PM  ==> 2018-09-14T19:49:06.000Z (assuming CST timezone)
   */
  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let dtString: string;
    if (valObj && valObj.type === 'date-time') {
      const bndt: BACnetDateTime = SiTranslator.parseSiDateTimeToBACnet(valObj.value);
      const dt: Date = SiTranslator.toDate(bndt);
      if (dt) {
        dtString = dt.toISOString();
      }
    }
    return dtString;
  }

  public resetParamValue(): void {
    const siVal: DateTimeValue = this.siParam.value as DateTimeValue;
    const bndt: BACnetDateTime = SiTranslator.toBACnetDateTime(this.defaultDt);
    siVal.value = undefined;
    if (bndt) {
      siVal.value = SiTranslator.formatSiDateTimeFromBACnet(bndt);
    }
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.DateTime) {
      return false; // undefined param or type mismatch!
    }
    const param: DateTimeParamViewModel = p as DateTimeParamViewModel;

    // If default value of alignment param does not match, clear the local default value
    if (!this.isEqualDate(this.defaultDt, param.defaultDt)) {
      this.defaultDt = undefined;
    }

    return true;
  }

  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;
    if (!p) {
      return;
    }
    this.defaultDt = WsiTranslator.decodeDateTime(p.DefaultValue);
    this.resetParamValue();
  }

  private isEqualDate(d1: Date, d2: Date): boolean {
    if (!d1) {
      return !d2 ? true : false;
    } else if (!d2) {
      return false;
    }
    return d1.getTime() === d2.getTime();
  }
}
