<div
  class="app-switcher ps-0 ps-sm-6 pt-8 text-center text-sm-start"
  cdkTrapFocus
  cdkTrapFocusAutoCapture
  (keydown.escape)="escape($event)"
>
  <div class="mx-9">
    @if (appSwitcherTitle) {
      <p class="mb-4 si-title-1 lh-1">{{ appSwitcherTitle | translate }}</p>
    }
    @if (appSwitcherSubTitle) {
      <p
        class="si-body-2 text-secondary"
        [class.mb-9]="isCategorized"
        [class.mb-4]="!isCategorized"
        >{{ appSwitcherSubTitle | translate }}</p
      >
    }
  </div>
  <div class="apps-scroll px-9 pb-8">
    @if (showFavorites) {
      <div class="apps-category mt-4 focus-none">
        <span class="si-title-1 apps-category-title">{{ favoriteAppsTitle | translate }}</span>
        <div
          class="apps apps-favorite justify-content-center justify-content-sm-start focus-direct-sub-inside"
        >
          @for (app of appsFavorites; track $index) {
            <a si-launchpad-item [siLink]="app" (click)="hideAppSwitcher()">
              <span app-icon [ngClass]="app.icon"></span>
              <span app-name>{{ app.title | translate }}</span>
            </a>
          }
        </div>
      </div>
    }
    @if (showFavorites) {
      <button
        type="button"
        class="d-inline-block btn btn-link dropdown-toggle apps-show-more py-4 mb-6 si-title-2"
        [class.show]="showAllApps"
        (click)="showAllApps = !showAllApps"
      >
        <b>{{ (showAllApps ? showLessAppsTitle : showMoreAppsTitle) | translate }}</b>
        <i class="dropdown-caret icon element-down-2"></i>
      </button>
    }
    @if (!showFavorites || showAllApps) {
      @for (appsCategory of appsCategorized; track i; let i = $index) {
        <div class="apps-category focus-none" [class.mt-4]="isCategorized">
          @if (appsCategory.category) {
            <span class="si-title-1 apps-category-title">{{
              appsCategory.category | translate
            }}</span>
          }
          @if (!appsCategory.category && isCategorized) {
            <span class="si-title-1 apps-category-title">{{ defaultAppsTitle | translate }}</span>
          }
          <div
            class="apps ms-0 justify-content-center justify-content-sm-start focus-direct-sub-inside"
            [class.apps-categorized]="isCategorized"
          >
            @for (app of appsCategory.items; track $index) {
              <a
                si-launchpad-item
                activeClass="active"
                [enableFavoriteToggle]="appItemsFavorites"
                [favorite]="app.isFavorite"
                [siLink]="app"
                (favoriteChanged)="toggleFavorite(app)"
                (click)="hideAppSwitcher()"
              >
                <span app-icon [ngClass]="app.icon"></span>
                <span app-name>{{ app.title | translate }}</span>
              </a>
            }
            <div class="position-relative">
              @if (allAppsLink && appsCategorized!.length - 1 === i) {
                <a si-launchpad-item active [siLink]="allAppsLink" (click)="hideAppSwitcher()">
                  <span app-icon [ngClass]="allAppsLink.icon ?? 'element-plus'"></span>
                  <span app-name>{{ allAppsLink.title || 'Add more' | translate }}</span>
                </a>
              }
            </div>
          </div>
        </div>
      }
    }
  </div>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel position-absolute top-0 end-0 mt-6 me-4"
    [attr.aria-label]="closeAppSwitcherText | translate"
    (click)="hideAppSwitcher()"
  ></button>
</div>
<div class="app-switcher-backdrop" (click)="hideAppSwitcher()"></div>
