const cloneObject = (val: any): any => {
  const res = new val.constructor();
  const keys = Object.keys(val);
  for (const key of keys) {
    res[key] = clone(val[key]);
  }
  return res;
};

const cloneArray = (val: any): any => {
  const len = val.length;
  const res = new val.constructor(len);
  for (let i = 0; i < len; i++) {
    res[i] = clone(val[i]);
  }
  return res;
};

export const clone = <T>(val: T): T => {
  if (val === null) {
    // because typeof null is 'object'....stupid language
    return null as unknown as T;
  }
  if (Array.isArray(val)) {
    return cloneArray(val);
  }
  if (val instanceof Date) {
    return new Date(val) as unknown as T;
  }
  if (typeof val === 'object') {
    return cloneObject(val);
  }
  return val;
};
