/* eslint-disable @typescript-eslint/naming-convention */

// ------------------------------------------------ General Log Types ---------------------------------------------

export enum HistoryLogKind {
  ActivityLog = 'ActivityLogTable',
  AlarmLog = 'AlarmLogTable',
  EventLog = 'EventLogTable',
  LogView = 'LogViewTable',
  ActivityFeed = 'ActivityFeedTable',
  MeterConfigurationReport = 'MeterConfigurationReport'
}

export interface LinkLog {
  Rel: string;
  Href: string;
  IsTemplated: boolean;
}

export interface HistoryLogTable {
  TableName: string;
  Size: number;
  Page: number;
  // Result type depends from the history log kind
  Result: LogViewResult[];
  SnapshotId: string;
  Total: number;
  ErrorInfo: string[];
  _links: LinkLog[];
}

// ------------------------------------------------ LogViewTable --------------------------------------------------
export interface LogViewResult {
  Index?: number;
  Id: number;
  EventId?: number;
  Time: string;
  LogType?: string;
  RecordType: string;
  AuditFlag?: string;
  UserName: string;
  Workstation?: string;
  Action?: string;
  Status?: string;
  Name: string;
  Description: string;
  InternalName: string;
  HiddenInternalName: string;
  DefaultViewDesignation: string;
  DefaultViewLocation: string;
  CurrentViewDesignation: string;
  CurrentViewLocation: string;
  ManagementDesignation: string;
  ManagementLocation: string;
  SystemName: string;
  Discipline: string;
  SubDiscipline: string;
  Type: string;
  SubType: string;
  ValProf: string;
  EventCatPrio?: number;
  EventCause?: string;
  AlertId?: string;
  AlarmCategory?: string;
  AlertMode?: string;
  EventMessageText?: string;
  AlertTime?: string;
  AlertState?: string;
  ObjectPropertyLogView?: string;
  ObserverObjectPropertyLogView?: string;
  LogicalDesignation?: string;
  LogicalLocation?: string;
  ObserverName?: string;
  ObserverDescription?: string;
  ObserverNameInternal?: string;
  ObserverDefaultHierarchyDesignation?: string;
  ObserverDefaultHierarchyLocation2?: string;
  DeviceEventText?: string;
  ValueDurationTicks?: number;
  Value?: string;
  ApplicationDesignation?: string;
  ApplicationLocation?: string;
  PrevValueDurationTicks?: number;
  PrevValue?: string;
  MessageText?: string;
  Error?: string;
  RecordTypeId?: string;
  EventStateId?: string;
}

// ------------------------------------------------ HistoryLogMetaData --------------------------------------------

/**
 * The data model of histroy log meta data is returned by getHistoryLogMetaData().
 * i.e. GET GET /api/historylogs/{systemId}
 */
export interface HistoryLogMetaData {
  Name: string;
  Descriptor: string;
  Description: string;
  HasChildColumns: boolean;
  HasConditionFilter: boolean;
  HasNameFilter: boolean;
  HasTimeFilter: boolean;
  HasTimeFilterDefinedBySource: boolean;
  HasAndOperatorOnly: boolean;
  HasMaxAge: boolean;
  HasSingleNameFilter: boolean;
  SupportedTypes: string[];
}

// --------------------------------------------- HistLogColumnDescripton ------------------------------------------

export interface HistLogColumnDescription {
  Name: string;
  Descriptor: string;
  DataType: string;
  ErrorSupport: boolean;
  IsArray: boolean;
  IsDefault: boolean;
  IsHidden: boolean;
  IsSortable: boolean;
  IsEnum: boolean;
  IsFilterable: boolean;
}

// ---------------------------------------------- RowDataOnSelection ----------------------------------------------

export interface LogViewRowData {
  // Not displayed in UX
  id: number;
  // Not displayed in UX
  eventId: number | undefined;
  dateTime: Date | undefined;
  localeDateTimeString: string;
  utcMilliseconds: number;
  logKind: string;
  sourceDescription: string;
  sourceProperty: string;
  messageText: string;
  eventCategory: string;
}

// ------------------------------------------------ Selected Row Details -------------------------------------------

export interface RowDetailsDescription {
  logViewResult: LogViewResult;
  columnDescriptionMap: Map<string, HistLogColumnDescription>;
}

// ------------------------------------------------ HistLogEnumValues ---------------------------------------------

export interface HistLogEnumValues {
  EnumValues?: string[];
  ErrorInfo?: string[];
}

// -----------------------------------------------------------------------------------------------------------------

export interface SortColumnData {
  Name: string;
  SortType: string;
}

// -------------------------------------------HistoryLogDataParameters--------------------------------------------

export interface HistoryApiParams {
  systemId: number;
  historyLogKind: HistoryLogKind;
  conditionFilter?: string;
  fromDate?: Date;
  toDate?: Date;
  size?: number;
  snapshotSize?: number;
  parentColumns?: string[];
  childColumns?: string[];
  sortColumnData?: SortColumnData[];
  nameFilter?: string[];
  pageNumber?: number;
  snapshotId?: string;
  additionalInfo?: string;
}

export interface FinalSection {
  sectionLabel?: string;
  sectionKey?: string;
  column1?: Control[];
  column2?: Control[];
}
export interface DetailPane {
  sections?: Sections;
  controls?: Controls;
  actions?: Action;
  events?: Action;
  hiddenColumns?: string[];
}
export interface Action {
  sections?: Sections;
  activityIcons?: ActivityIcons;
}
export interface Sections {
  [key: string]: Section;
}
export interface Section {
  detailPaneControls?: DetailPaneControls;
  veryDetailPaneControls?: string[];
}
export interface DetailPaneControls {
  column1?: string[];
  column2?: string[];
}
export interface Controls {
  [key: string]: Control;
}
export interface Control {
  label?: string;
  icon?: string;
  columnName?: string;
  veryDetailPane?: boolean;
  extraCssClasses?: string;
}
export interface ActivityIcons {
  [key: string]: ActivityIcon;
}
export interface ActivityIcon {
  descriptor: string;
  icon: string;
  locationColumnName: string;
  sections?: Sections;
  hideMasterLocation?: boolean;
}
export interface ReportDefination {
  ErrorInfo: string;
  ReportDefinationInfo: ReportDefinationInfo;
}
export interface ReportDefinationInfo {
  layoutDefination: layoutDefination;
}
export interface layoutDefination {
  pageContentSection: pageContentSection;
}
export interface pageContentSection {
  Controls: definationControls[];
}
export interface definationControls {
  ControlId: number;
  ControlName: string;
  AssociatedElementId: number;
  AssociatedContentDefinitionId: string;
  ViewElement: boolean;
  TextGroupName: string;
}

export interface TextGroup {
  Value: number;
  LangText: string[];
}

/* eslint-disable @typescript-eslint/naming-convention */
