import { Injectable } from '@angular/core';
import { SummaryBarSnapInServiceBase } from './summary-bar-snapin.service.base';

/**
 * SummaryBar SnapIn service.
 * Provides the methods to get if summary bar functionalities are visible.
 *
 * @export
 * @class SummaryBarSnapInService
 * @extends {SummaryBarSnapInServiceBase}
 */

@Injectable({
  providedIn: "root"
})
export class SummaryBarSnapInService implements SummaryBarSnapInServiceBase {

  public buzzerVisible(): boolean {
    return true;
  }
}
