import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { SiTooltipDirective } from '@simpl/element-ng';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-formly-icon-wrapper',
  styleUrl: './si-formly-icon-wrapper.component.scss',
  templateUrl: './si-formly-icon-wrapper.component.html',
  standalone: true,
  imports: [SiTooltipDirective, SiTranslateModule]
})
export class SiFormlyIconWrapperComponent extends FieldWrapper {}
