import { NgZone } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TraceChannel } from '../common/trace-channel';
import { GmsElement } from '../elements/gms-element';
import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { Substitution, SubstitutionSource } from '../processor/substitution';
import { TextGroupEntry, TextGroupEntryPropertyChangeArgs, TextGroupEntryStatus } from '../processor/textgroup/gms-text-group-entry';
import { TextGroupEntryHelper } from '../processor/textgroup/gms-text-group-helper';
import { TextGroupService } from '../services/gms-text-group-service';
import { DatapointStatus } from '../types/datapoint/gms-status';
import { BrushType, Color, ColorUtility, ColorWrap, RgbColor } from '../utilities/color-utility';
import { FormatHelper } from '../utilities/format-helper';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { Condition } from './condition';
import { Expression } from './expression';

// Defines the type of evalution
export enum EvaluationType {
  // A simple evaluation
  Simple,
  // A linear evaluation
  Linear,
  // A discrete evaluation
  Discrete,
  // A multi evaluation. (changes based on values from multiple digital points)
  Multi,
  // An animated evaluation. (changes based on interval)
  Animated
}

// Defines the types of property changes
export enum PropertyChangeType {
  // The value has changed
  Value,
  // The status has changed
  Status,
  // The value and status have changed
  ValueAndStatus,
  // The format has changed
  Format,
  // Datapoints have changed
  Datapoints,
  // Evaluations have changed
  Evaluations,
  // Enable/disable has changed
  Enabled,
  // The evaluation type has changed
  // eslint-disable-next-line
    EvaluationType,
  // The auto linear range has changed
  AutoLinearRange
}

export enum PropertyType {
  /* eslint-disable id-blacklist */
  String,
  Boolean,
  Number,
  /* eslint-disable id-blacklist */
  // eslint-disable-next-line
    Color,
  Brush,
  Enum,
  Icon
}

export class Evaluation implements SubstitutionSource {
  private readonly _evaluations: string = 'Evaluations.';
  public propertyChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private _noElementUpdates = false;

  private _textGroupEntrySubscription: Subscription;
  private readonly traceModule: string = TraceChannel.Processor;
  private readonly _expressions: Expression[] = new Array<Expression>();
  public get Expressions(): Expression[] {
    return this._expressions;
  }

  private readonly _conditions: Condition[] = new Array<Condition>();
  public get Conditions(): Condition[] {
    return this._conditions;
  }

  private _textGroupService: TextGroupService = undefined;
  private get TextGroupService(): TextGroupService {
    if (this._textGroupService === undefined) {
      this._textGroupService = this.Element !== undefined && this.Element.TextGroupService !== undefined ?
        this.Element.TextGroupService : undefined;
    }
    return this._textGroupService;
  }

  private get TraceService(): TraceService {
    return this.Element !== undefined && this.Element.TraceService !== undefined ?
      this.Element.TraceService : undefined;
  }

  private get Zone(): NgZone {
    return this.Element !== undefined && this.Element.Zone !== undefined ?
      this.Element.Zone : undefined;
  }

  private _element: GmsElement = undefined;
  public get Element(): GmsElement {
    return this._element;
  }
  public set Element(value: GmsElement) {
    if (this._element !== value) {
      this._element = value;
    }
  }

  public get IsResolved(): boolean {
    return this.Expressions.length > 0 && this.Expressions.every(expression => expression.IsResolved);
  }

  private _property = '';
  public get Property(): string {
    return this._property;
  }

  private _propertyType: PropertyType = PropertyType.String;
  public get PropertyType(): PropertyType {
    return this._propertyType;
  }
  public set PropertyType(value: PropertyType) {
    if (this._propertyType !== value) {
      this._propertyType = value;
    }
  }

  private _evaluationType: EvaluationType = EvaluationType.Simple;
  public get EvaluationType(): EvaluationType {
    return this._evaluationType;
  }
  public set EvaluationType(value: EvaluationType) {
    if (this._evaluationType !== value) {
      this._evaluationType = value;
    }
  }

  private _autoLinearRange = true;
  public get AutoLinearRange(): boolean {
    return this._autoLinearRange;
  }
  public set AutoLinearRange(value: boolean) {
    if (this._autoLinearRange !== value) {
      this._autoLinearRange = value;
    }
  }

  private _errorMessage: string = undefined;
  public get ErrorMessage(): string {
    return this._errorMessage;
  }
  public set ErrorMessage(value: string) {
    if (this._errorMessage !== value) {
      this._errorMessage = value;
      this.NotifyPropertyChanged('ErrorMessage');
    }
  }

  private _waveAnimation = false;
  private _animated = false;
  private _animationFrame = 0;
  private _animationTimer: any = undefined;
  private readonly _timerSubscription: Subscription;

  private _animationInterval = 250;
  public get AnimationInterval(): number {
    return this._animationInterval;
  }
  public set AnimationInterval(value: number) {
    if (this._animationInterval === value) {
      return;
    }

    this._animationInterval = value;
    const animated: boolean = this._animated;
    if (animated) {
      this.AnimationStop();
    }

    this._waveAnimation = this._animationInterval < 0;
    const absoluteValue: number = Math.abs(this._animationInterval);
    this._animationInterval = absoluteValue > 100 && absoluteValue < Number.MAX_VALUE ? Math.abs(this._animationInterval) : 100;

    if (animated) {
      this.AnimationStart();
    }
    this.NotifyPropertyChanged('AnimationInterval');
  }

  private _enabled = true;
  public get Enabled(): boolean {
    return this._enabled;
  }
  public set Enabled(value: boolean) {
    if (this._enabled === value) {
      return;
    }
    this._enabled = value;
    this.ConvertResults();
    this.NotifyPropertyChanged('Enabled');
    this.NotifyElement(PropertyChangeType.Enabled); // notify the element
  }

  private readonly _datapoints: Datapoint[] = [];
  public get Datapoints(): Datapoint[] {
    return this._datapoints;
  }

  private _lastDatapoint: Datapoint = undefined;
  public get LastDatapoint(): Datapoint {
    return this._lastDatapoint;
  }
  public set LastDatapoint(value: Datapoint) {
    if (this._lastDatapoint === value) {
      return;
    }
    this._lastDatapoint = value;
  }

  private _value: any = undefined;
  // Gets the value
  public get Value(): any {
    return this._value;
  }
  public set Value(value: any) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    if (this._value === undefined) {
      this._rawValue = undefined;
    }

    this.NotifyPropertyChanged('Value');
    this.NotifyElement(PropertyChangeType.Value); // notify the element
  }

  private _rawValue: any = undefined;
  // Gets the raw value
  public get RawValue(): any {
    return this._rawValue;
  }

  private _valueTextGroupRef: any = undefined;
  // Gets the text group reference for the evaluation value
  // From the condition based evaluation types
  public get ValueTextGroupRef(): any {
    return this._valueTextGroupRef;
  }

  private _status: DatapointStatus = DatapointStatus.Undefined;
  // Gets the status
  public get Status(): DatapointStatus {
    return this._status;
  }
  public set Status(value: DatapointStatus) {
    if (this._status === value) {
      return;
    }
    this._status = value;
    if (this._status === DatapointStatus.Valid) {
      this.ErrorMessage = undefined;
    } else {
      this._rawValue = undefined; // the raw value can be used in the GmsText element for the formatting
      this.Value = undefined;
    }
    this.NotifyPropertyChanged('Status');
    this.NotifyElement(PropertyChangeType.Status); // notify the element
  }

  public get Initialized(): boolean {
    return this._element !== undefined;
  }

  // Gets a value indicating whether the Evaluation is empty
  public get IsEmpty(): boolean {
    return this.EvaluationType === EvaluationType.Simple && (this.Expressions.length === 0 || this.Expressions[0].IsEmpty);
  }

  /**
   * Returns Value based on useEvaluation status
   * @param evaluation
   * @param defaultValue
   */
  public static GetValue(evaluation: Evaluation, defaultValue: any): any {
    // return result: [value as any, useEvaluation: bool]
    const hasEvaluation: boolean = evaluation !== undefined && evaluation.Enabled && evaluation.IsEmpty === false;
    const useEvaluation: boolean = hasEvaluation &&
            (evaluation.Value !== undefined &&
                evaluation.Status === DatapointStatus.Valid); // || evaluation.IgnoreHide);
    let value: any;
    if (useEvaluation) {
      if (typeof evaluation.Value === 'number') {
        const val = Number(evaluation.Value);
        if (val !== undefined) {
          value = val;
        }
      } else {
        value = evaluation.Value;
      }
    } else {
      value = defaultValue;
    }
    return value;
  }

  /**
   * Returns Value based on useEvaluation status
   * @param evaluation
   * @param defaultValue
   */
  public static GetValue2(evaluation: Evaluation, defaultValue: any, propertyType: PropertyType): any {

    const hasEvaluation: boolean = evaluation !== undefined && evaluation.Enabled && evaluation.IsEmpty === false;
    const useEvaluation: boolean = hasEvaluation &&
            (evaluation.Value !== undefined &&
                evaluation.Status === DatapointStatus.Valid);
    let value: any;
    if (useEvaluation) {

      switch (propertyType) {
        case PropertyType.Boolean:
          value = Evaluation.ConvertEvaluationValueToBool(evaluation.Value);
          break;

        case PropertyType.String:
          if (evaluation.Value?.toLocaleString) {
            value = evaluation.Value.toLocaleString(evaluation.Element.locale);
          } else {
            value = String(evaluation.Value);
          }
          break;

        case PropertyType.Number:
          const convertedValue: number = Evaluation.ConvertEvaluationValueToNumber(evaluation.Value);

          // NOTE: #FORMAT Error ( a Value cannot be converted)
          value = isNaN(convertedValue) ? defaultValue : convertedValue;
          break;

        case PropertyType.Color:
          let colorString = String(evaluation.Value);
          if (TextGroupEntryHelper.IsTextGroupReference(colorString)) {
            evaluation.ResolveTextGroupColor(colorString);
            colorString = evaluation.Value;
          }

          value = String(colorString);
          break;
        case PropertyType.Icon:
          if (evaluation.ValueTextGroupRef !== undefined) {
            value = evaluation.ValueTextGroupRef;
          } else {
            value = String(evaluation.Value);
          }
          break;
        default:
          // return value as is
          value = evaluation.Value;
          break;
      }
    } else {
      value = defaultValue;
    }
    return value;
  }

  // Precondition value must not be undefined
  // Non empty string => true, empty string => false
  // 1 => true, 0 => false
  public static ConvertEvaluationValueToBool(evaluationValue: any): boolean {

    if (evaluationValue === undefined) {
      return false;
    }

    if (typeof (evaluationValue) === 'boolean') {
      return evaluationValue === true;
    }

    const valueString = String(evaluationValue);
    if (valueString === undefined || valueString.trim() === '') {
      return false;
    }

    const valueAsNumber: number = FormatHelper.StringToNumber(valueString);
    if (!isNaN(valueAsNumber) && valueAsNumber === 0) {
      return false;
    }
    return true;
  }

  // Precondition value must not be undefined
  // Empty String => Number.MinValue
  // Non numeric value => NaN
  // Bool true => 1, false => 0
  public static ConvertEvaluationValueToNumber(evaluationValue: any): number {
    if (evaluationValue === undefined) {
      return NaN;
    }
    const valueString = String(evaluationValue);
    if (valueString === 'undefined' || valueString.trim() === '') {
      return NaN;
    }
    const booleanString: string = valueString.toLowerCase();

    return FormatHelper.StringToNumber(booleanString); // Number(valueString);
  }

  /**
   * The handler, which animates
   */
  public async TickAnimation(): Promise<void> {

    this._animationFrame++;
    this.ConvertResults();
  }

  // dynamic graphicview datapoint collection
  public ExpressionChanged(expression: Expression, propertyName: string): void {

    if (propertyName === 'Value') {
      this.NotifyPropertyChanged(this._evaluations + this.Property);
      this.NotifyPropertyChanged('IsEmpty');
      this.NotifyElement(PropertyChangeType.Evaluations); // notify the element
    }
    if (propertyName === 'SemanticType') {
      this.NotifyPropertyChanged(this._evaluations + this.Property);
      this.NotifyElement(PropertyChangeType.Evaluations); // notify the element
    } else if (propertyName === 'Result' || propertyName === 'Status') {
      this.ConvertResults(); // re-calculates the evaluation value from the expression result(s) and the conditions
    } else if (propertyName === 'Datapoints') {
      this.UpdateDatapoints(); // initialize and recollect all datapoints from all expressions
      this.NotifyPropertyChanged('Datapoints');
      this.NotifyElement(PropertyChangeType.Datapoints); // notify the element
    } else if (propertyName === 'AlarmState') {
      this.UpdateDatapoints();
      this.NotifyAlarms();
    }
  }

  public OnConditionValuesOrResultChanged(): void {
    this.ConvertResults(); // re-calculates the evaluation value from the expression result(s) and the conditions
    this.NotifyPropertyChanged(this._evaluations + this.Property);
    this.NotifyElement(PropertyChangeType.Evaluations); // notify the element
  }

  public Deserialize(node: Node): void {
    this._noElementUpdates = true;

    // Evaluation Properties
    if (node === undefined) {
      return;
    }

    let result: string = SvgUtility.GetAttributeValue(node, EvaluationProperties.Key);
    if (result !== undefined) {
      this._property = result;
    }

    result = SvgUtility.GetAttributeValue(node, EvaluationProperties.EvaluationType);
    if (result !== undefined) {
      this._evaluationType = EvaluationType[result];
    }

    result = SvgUtility.GetAttributeValue(node, EvaluationProperties.Enabled);
    if (result !== undefined) {
      this._enabled = result === 'True';
    }

    result = SvgUtility.GetAttributeValue(node, EvaluationProperties.AnimationInterval);
    if (result !== undefined) {
      this.AnimationInterval = +result;
    }

    result = SvgUtility.GetAttributeValue(node, EvaluationProperties.AutoLinearRange);
    if (result !== undefined) {
      this._autoLinearRange = result === 'True';
    }

    result = SvgUtility.GetAttributeValue(node, EvaluationProperties.PropertyType);
    if (result !== undefined) {
      this._propertyType = PropertyType[result];
    }

    for (let i = 0; i < node.childNodes.length; i++) {
      const childNode: Node = node.childNodes[i];
      if (childNode.nodeName === EvaluationProperties.Expressions.toString()) {
        this.CreateExpressions(childNode);
      } else if (childNode.nodeName === EvaluationProperties.Conditions.toString()) {
        this.CreateConditions(childNode);
      }
    }
    this.ConvertResults();
    this._noElementUpdates = false;

    if (this.Datapoints.length > 0 && this.Datapoints.some(datapoint => datapoint.Status === DatapointStatus.Pending)) {
      return;
    }

    this.NotifyElement(PropertyChangeType.ValueAndStatus);
  }

  public CopyFrom(evaluation: Evaluation): void {
    this._noElementUpdates = true;

    // Evaluation Properties
    this._property = evaluation._property;
    this._evaluationType = evaluation._evaluationType;
    this._enabled = evaluation._enabled;
    this.AnimationInterval = evaluation.AnimationInterval;
    this._autoLinearRange = evaluation._autoLinearRange;
    this._propertyType = evaluation._propertyType;

    if (evaluation.Expressions.length > 0) {
      this.CopyExpressions(evaluation);
    }

    if (evaluation.Conditions.length > 0) {
      this.CopyCondition(evaluation);
    }

    this.ConvertResults();
    this._noElementUpdates = false;

    if (this.Datapoints.length > 0 && this.Datapoints.some(datapoint => datapoint.Status === DatapointStatus.Pending)) {
      return;
    }

    this.NotifyElement(PropertyChangeType.ValueAndStatus);
  }

  /**
   * The substitution value changed -> reevaluate the expressions and the evaluation
   * @param clearSubstitutions
   * @param origin
   */
  public Update(clearSubstitutions: boolean, origin: Substitution): void {
    for (const expression of this.Expressions) {
      expression.Update(clearSubstitutions, origin);
    }
  }

  public Clear(graphicDestroy: boolean = false): void {
    this._noElementUpdates = graphicDestroy;
    if (this.EvaluationType === EvaluationType.Animated) { // clear the timer
      this.AnimationStop();
    }
    this._element = undefined;
    this._expressions.forEach(expression => expression.Clear());
    this._expressions.length = 0;
    this.Conditions.forEach(condition => condition.Clear());
    this.Conditions.length = 0;
    this._lastDatapoint = undefined;
    this._datapoints.length = 0;
    this._evaluationType = EvaluationType.Simple;
    this._textGroupService = undefined;
    this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
    this._noElementUpdates = false;
  }

  public ResolveTextGroupColor(evaluationValue: string): void {
    // Resolve text group references for color and text
    if (TextGroupEntryHelper.IsTextGroupReference(evaluationValue) &&
            this.PropertyType === PropertyType.Color || this.PropertyType === PropertyType.String) {
      if (this.Element !== undefined && this.TextGroupService !== undefined) {

        const textGroupEntry: TextGroupEntry = this.TextGroupService.getTextGroupEntry(evaluationValue);

        if (textGroupEntry.Status === TextGroupEntryStatus.DoesNotExist) {
          // NOTE: handle the condition
          return;
        }

        if (textGroupEntry.Status === TextGroupEntryStatus.Resolved && textGroupEntry.LocalTextGroupEntry !== undefined) {
          this.Value = this.SetTextGroupColor(textGroupEntry);
          return;
        }
        // subscribe and wait for callback
        this._textGroupEntrySubscription = textGroupEntry.propertyChanged.subscribe(arg => this.TextGroupEntry_PropertyChanged(arg, textGroupEntry));
        if (textGroupEntry.Status === TextGroupEntryStatus.Pending) {
          return;
        }
        const selectedObjectSystemId: string = this.Element.SelectedObject !== undefined
          ? this.Element.SelectedObject.SystemId.toString() : undefined;

        if (this.TextGroupService.readTextAndColorForTextGroupEntry(textGroupEntry, selectedObjectSystemId) === false) {
          // cannot process the textGroupEntry
          textGroupEntry.Status = TextGroupEntryStatus.DoesNotExist;
        }
      }
    }
  }

  protected NotifyPropertyChanged(propertyName: string = ''): void {
    this.propertyChanged.next(propertyName);
  }

  private ClearEvaluationValueAndStatus(status: DatapointStatus): void {
    this.SetValueAndStatus(undefined, status);
  }

  // One of the expression results changed so convert and re-map the value (Result -> RawValue -> Value)
  private ConvertResults(): void {
    if (!this.Initialized) {
      return;
    }

    if (this.Expressions.length === 0 || !this.Enabled || this._property === undefined) {
      this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
      return;
    }

    // check whether any expression is readonly
    for (const expression of this.Expressions) {
      if (expression.Status === DatapointStatus.Hide) {
        this.ClearEvaluationValueAndStatus(DatapointStatus.Hide);
        return;
      }
    }

    // check whether any expression is invalid (empty expressions will be ignored (=Valid)
    for (const expression of this.Expressions) {
      if (expression.Status > DatapointStatus.Pending && expression.Status !== DatapointStatus.Valid) {
        this.ClearEvaluationValueAndStatus(expression.Status === DatapointStatus.DoesNotExist
          ? DatapointStatus.DoesNotExist : DatapointStatus.Invalid);
        return;
      } else if (expression.Status <= DatapointStatus.Pending) {
        return; // Pending don't process anything.
      }
    }

    let result: any;
    let resultTextGroupRef: string;
    try {
      if (this.EvaluationType === EvaluationType.Simple) {
        const expression: Expression = this.Expressions[0];
        result = expression.Result;
        if (result === undefined) {
          this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
          return; // ignore empty expressions
        }

        this._rawValue = result; // the raw value can be used in the GmsText element for the formatting
        this.SetValueAndStatus(this.ConvertResultToType(result), DatapointStatus.Valid);
      } else if (this.EvaluationType === EvaluationType.Discrete) {
        if (this.Conditions.length === 0) {
          return;
        }
        const expression: Expression = this.Expressions[0];
        if (expression.Result === undefined) {
          this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
          return; // ignore empty expressions
        }

        // compare each condition (from top to bottom) with the result
        result = undefined;
        const convertedValue: number = Utility.ConvertToDouble(expression.Result);
        for (let i = 0; i < this.Conditions.length; i++) {
          const condition: Condition = this.Conditions[i];
          const comparisonResult: boolean = condition.CompareTo(expression.Result, convertedValue);
          if (comparisonResult) {
            result = condition.Result;
            resultTextGroupRef = condition.TextGroupRef;
            break; // use the first result from top
          }
        }

        this._rawValue = result; // the raw value can be used in the GmsText element for the formatting
        this._valueTextGroupRef = resultTextGroupRef;
        this.SetValueAndStatus(this.ConvertResultToType(result), DatapointStatus.Valid);
      } else if (this.EvaluationType === EvaluationType.Animated) {
        const expression: Expression = this.Expressions[0];
        if (expression.Result === undefined) {
          this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
          return; // ignore empty expressions
        }

        this.AnimationInit();

        if (this.Conditions.length === 0) {
          result = undefined; // should not happen, Animated requires at least one condition
        } else {
          const isAnimated: boolean = Utility.ConvertToBool(expression.Result);
          if (!isAnimated) {
            result = this.Conditions[0].Result;
            resultTextGroupRef = this.Conditions[0].TextGroupRef;
          } else {
            if (this.Conditions.length === 1) {
              result = undefined;
            } else if (this.Conditions.length === 2) {
              result = this.Conditions[1].Result;
              resultTextGroupRef = this.Conditions[1].TextGroupRef;
            } else if (!this._waveAnimation) { // negative time interval
              const condition: Condition = this.Conditions[this._animationFrame % (this.Conditions.length - 1) + 1]; // e.g. 1,2,3,4,1,2,3,4,...
              result = condition.Result;
              resultTextGroupRef = condition.TextGroupRef;
            } else { // positive time interval
              // e.g. 1,2,3,4,3,2,1,2,3,4,...
              const length: number = (this.Conditions.length - 2) * 2;
              let index: number = (this._animationFrame % length);
              if (index > this.Conditions.length - 2) {
                index = length - index;
              }

              const condition: Condition = this.Conditions[index + 1];
              result = condition.Result;
              resultTextGroupRef = condition.TextGroupRef;
            }
          }
        }

        this._rawValue = result; // the raw value can be used in the GmsText element for the formatting
        this._valueTextGroupRef = resultTextGroupRef;
        this.SetValueAndStatus(this.ConvertResultToType(result), DatapointStatus.Valid);
      } else if (this.EvaluationType === EvaluationType.Multi) {
        if (this.Conditions.length === 0) {
          return;
        }
        const convertedResults: boolean[] = new Array<boolean>();
        let countEmptyExpressions = 0;
        for (const expression of this.Expressions) {
          if (expression.Result === undefined) {
            countEmptyExpressions++;
            continue; // ignore empty expressions
          }

          const convertedResult: boolean = Utility.ConvertToBool(expression.Result);
          convertedResults.push(convertedResult);
        }
        if (countEmptyExpressions === this.Expressions.length) {
          this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
          return; // ignore empty expressions
        }

        // compare each condition (from top to bottom) with the result
        for (const condition of this.Conditions) {
          if (condition.CompareToList(convertedResults)) {
            result = condition.Result;
            resultTextGroupRef = condition.TextGroupRef;
            break; // use the first result from top
          }
        }

        this._valueTextGroupRef = resultTextGroupRef;
        this._rawValue = result; // the raw value can be used in the GmsText element for the formatting
        this.SetValueAndStatus(this.ConvertResultToType(result), DatapointStatus.Valid);
      } else if (this.EvaluationType === EvaluationType.Linear) {
        result = this.Expressions[0].Result;
        if (this.Conditions.length < 2 || result === undefined) {
          this.ClearEvaluationValueAndStatus(DatapointStatus.Valid);
          return; // ignore empty expressions
        }
        const firstCondition: Condition = this.Conditions[0];
        const secondCondition: Condition = this.Conditions[1];
        const convertedValue: number = Utility.ConvertToDouble(result);

        // just set the range if either one target range values are empty
        if (firstCondition.Result === undefined || secondCondition.Result === undefined) {
          this._rawValue = Utility.ApplyRange(convertedValue, firstCondition.Result,
            secondCondition.Result); // the raw value can be used in the GmsText element for the formatting
          this.SetValueAndStatus(this.ConvertResultToType(this._rawValue), DatapointStatus.Valid);
          return;
        }

        const minMax: any = this.CalculateMinMax(firstCondition.Values, secondCondition.Values, this.AutoLinearRange, this.LastDatapoint);
        const sourceMin: number = minMax.MinValue;
        const sourceMax: number = minMax.MaxValue;

        const sourceRange: number = sourceMax - sourceMin;

        const rangedResult: number = Utility.ApplyRange(convertedValue, Math.min(sourceMin, sourceMax), Math.max(sourceMin, sourceMax));
        const percentage: number = (rangedResult - Math.min(sourceMin, sourceMax)) / sourceRange;

        if (this.PropertyType === PropertyType.Number) {
          const targetMin: number = FormatHelper.StringToNumber(firstCondition.Result);
          const targetMax: number = FormatHelper.StringToNumber(secondCondition.Result);
          const targetRange: number = targetMax - targetMin;
          let targetValue: number = percentage * targetRange;

          if (sourceMin <= sourceMax) {
            targetValue += targetMin;
          } else {
            targetValue += targetMax;
          }

          this._rawValue = targetValue;
          this.SetValueAndStatus(targetValue, DatapointStatus.Valid);
        } else if (this.PropertyType === PropertyType.Boolean) {
          const targetMin: boolean = firstCondition.Result === 'True';
          const targetMax: boolean = secondCondition.Result === 'True';
          this._rawValue = percentage < 0.5 ? targetMin : targetMax;
          this.SetValueAndStatus(this._rawValue, DatapointStatus.Valid);
        } else if (this.PropertyType === PropertyType.String) {
          const targetMin: string = firstCondition.Result;
          const targetMax: string = secondCondition.Result;
          const text: string = this.Interpolate(targetMin, targetMax, percentage, sourceMin <= sourceMax);
          this._rawValue = text;
          this.SetValueAndStatus(text, DatapointStatus.Valid);
        } else if (this.PropertyType === PropertyType.Color) {
          const targetMin: ColorWrap = firstCondition.GetResolvedCompundBrush();
          const targetMax: ColorWrap = secondCondition.GetResolvedCompundBrush();

          const targetValueMain: Color = ColorUtility.InterpolateBrush(targetMin.First, targetMax.First, percentage);
          const targetValueBlink: Color = targetMin.HasBlinkColor || targetMax.HasBlinkColor ? ColorUtility.InterpolateBrush(targetMin
            .Second, targetMax.Second, percentage) : targetValueMain;

          const mainColor: string = targetValueMain !== undefined ? targetValueMain.ColorString : undefined;
          const blinkColor: string = targetValueBlink !== undefined ? targetValueBlink.ColorString : undefined;

          let resultColor: string;
          if (mainColor !== undefined && blinkColor !== undefined) {
            this._element.AddInterpolatedBrushes(targetValueMain);

            if (targetValueBlink !== targetValueMain) {
              this._element.AddInterpolatedBrushes(targetValueBlink);

              if (targetValueMain.HasResolvedBrush && targetValueBlink.HasResolvedBrush) {
                resultColor = mainColor + '/' + blinkColor;
              } else {                
                resultColor = targetValueMain.ActualColorString + '/' + targetValueBlink.ActualColorString;
              }
            } else {
              resultColor = targetValueMain?.HasResolvedBrush ? mainColor : targetValueMain?.ActualColorString;
            }
          } else {
            this._element.AddInterpolatedBrushes(targetValueMain);
            resultColor = targetValueMain?.HasResolvedBrush ? mainColor : targetValueMain?.ActualColorString;            
          }

          this.SetValueAndStatus(resultColor, DatapointStatus.Valid);
        }
      }
    } catch (ex) {
      this.ErrorMessage = 'For Property:' + this._property + 'Error Trace: ' + ex.stack;
      this.TraceService.error(this.traceModule, 'Evaluation convert results error:' + this.ErrorMessage);
    }
  }

  // Sets the appropriate type for the evaluation result, before wrapping it with any.
  private ConvertResultToType(result: any): any {
    if (result === undefined) {
      return result;
    }

    const resultIsString = String(result);
    if (resultIsString.trim() === '') { // Empty string return it.
      return resultIsString;
    }
    const resultIsNumber = Number(result);
    if (resultIsNumber !== undefined && !isNaN(resultIsNumber)) {
      return result;
    }

    const booleanString: string = resultIsString.toLowerCase();
    if (resultIsString !== undefined && (booleanString === 'true' || booleanString === 'false')) {
      return booleanString === 'true';
    }

    if (resultIsString !== undefined) {
      return resultIsString;
    }

    return result;
  }

  private Interpolate(text1: string, text2: string, percentage: number, ascending: boolean): string {
    // interpolates two strings, e.g. ("abc10", "abc50", 0.5) to "abc30" or ("0%", "80%", 0.5) to "40%"
    let endsWidthPercentageSign = false;
    if (text1.endsWith('%') && text2.endsWith('%')) { //  TBD Locale needed
      endsWidthPercentageSign = true;
      text1 = text1.substring(0, text1.length - 1);
      text2 = text2.substring(0, text2.length - 1);
    }

    let lastValue: any = this.LastValue(text1);
    const posMin: number = lastValue.Index;
    const precisionMin: number = lastValue.Precision;
    const targetMinNumber: number = lastValue.Value;

    lastValue = this.LastValue(text2);
    const posMax: number = lastValue.Index;
    const precisionMax: number = lastValue.Precision;
    const targetMaxNumber: number = lastValue.Value;

    if (!isNaN(targetMinNumber) && !isNaN(targetMaxNumber)) {
      const targetRangeNumber: number = targetMaxNumber - targetMinNumber;
      let targetValue: number = percentage * targetRangeNumber;
      if (ascending) {
        targetValue += targetMinNumber;
      } else {
        targetValue += targetMaxNumber;
      }

      const roundedTargetValue: number = Utility.Round(targetValue, Math.max(precisionMin, precisionMax));
      this._rawValue = posMin === 0 ? roundedTargetValue : undefined; // the raw value can be used in the GmsText element for the formatting

      let result: string = text1.substring(0, posMin) + roundedTargetValue;
      if (endsWidthPercentageSign) {
        result += '%';
      }
      return result;
    }
    return undefined;
  }

  private LastValue(text: string): any {
    let index = 0;
    const precision = 0;
    const result: any = { Index: 0, Precision: 0, Value: 0 };

    if (text === undefined) {
      return 0;
    }

    index = text.length;
    while (--index >= 0) {
      const c: string = text[index];
      if (c === ',' || c === '.') {
        result.Precision = text.length - index - 1;
      } else if (!Utility.IsNumeric(c) && c !== '-') {
        break;
      }
    }

    index++;

    const parsedValue: number = parseFloat(text.substring(index));
    if (!isNaN(parsedValue)) {
      result.Value = parsedValue;
    }

    return result;
  }

  // Returns an object with MinValue and MaxValue property
  private CalculateMinMax(min: string, max: string, autoLinearRange: boolean, datapoint: Datapoint): any {
    let resultMin: number = -Number.MAX_VALUE;
    let resultMax: number = Number.MAX_VALUE;
    const result: any = { MinValue: -Number.MAX_VALUE, MaxValue: Number.MAX_VALUE };

    if (autoLinearRange) {
      if (datapoint !== undefined && !isNaN(datapoint.MinValue)) {
        resultMin = datapoint.MinValue;
      }
      if (datapoint !== undefined && !isNaN(datapoint.MaxValue)) {
        resultMax = datapoint.MaxValue;
      }
    } else {
      const minimumValue: number = FormatHelper.StringToNumber(min);
      const maximunValue: number = FormatHelper.StringToNumber(max);

      if (isNaN(minimumValue)) {
        if (datapoint !== undefined && !isNaN(datapoint.MinValue)) {
          resultMin = datapoint.MinValue;
        } else {
          resultMin = -Number.MAX_VALUE;
        }
      } else {
        resultMin = minimumValue;
      }

      if (isNaN(maximunValue)) {
        if (datapoint !== undefined && !isNaN(datapoint.MaxValue)) {
          resultMax = datapoint.MaxValue;
        } else {
          resultMax = -Number.MAX_VALUE;
        }
      } else {
        resultMax = maximunValue;
      }
    }

    result.MinValue = resultMin;
    result.MaxValue = resultMax;

    return result;
  }

  private SetValueAndStatus(value: any, status: DatapointStatus): void {
    if (status !== DatapointStatus.Valid) {
      value = undefined; // an invalid status forces to write undefined for the value
    }

    const valueChanged: boolean = this._value !== value;
    const statusChanged: boolean = this._status !== status;

    // If evaluation status is valid, even if there is no change to status/value allow element notification.
    // To cover late arriving datapoint values, uscase - discrete evaluation with no conditions
    if (!valueChanged && !statusChanged && this._status !== DatapointStatus.Valid) {
      return; // nothing changed
    }

    if (valueChanged) {
      this._value = value;
      if (this._value === undefined) {
        this._rawValue = undefined;
      }

      this.NotifyPropertyChanged('Value');

      if (!statusChanged) {
        this.NotifyElement(PropertyChangeType.Value); // notify the element
        return; // notify only Value
      }
    }

    if (statusChanged) {
      this._status = status;
      if (this._status === DatapointStatus.Valid) {
        this.ErrorMessage = undefined;
      }
      this.NotifyPropertyChanged('Status');

      if (!valueChanged) {
        this.NotifyElement(PropertyChangeType.Status); // notify the element
        return; // notify only Status
      }
    }

    this.NotifyElement(PropertyChangeType.ValueAndStatus); // notify the element
  }

  private AnimationStart(): void {
    if (this._animated) {
      return;
    }

    this.Element.AnimationTimerService.subscribe(this, this.AnimationInterval);
    this._animated = true;
  }

  private AnimationStop(): void {
    if (!this._animated) {
      return;
    }

    // un-set timer delay for AnimationInterval and callback to TickAnimation
    if (this._animationTimer !== undefined) {
      // this._timerSubscription.unsubscribe();
      this.Element.AnimationTimerService.unsubscribe(this);
      this._animationTimer = undefined;
    }

    this._animated = false;
  }

  private AnimationInit(): void {
    if (this.Initialized && this.Enabled && this.EvaluationType === EvaluationType.Animated && this.Conditions.length > 2) {
      this.AnimationStart();
    } else {
      this.AnimationStop();
    }
  }

  private TextGroupEntry_PropertyChanged(arg: TextGroupEntryPropertyChangeArgs, textGroupEntry: TextGroupEntry): void {

    if (this._textGroupEntrySubscription !== undefined) {

      this._textGroupEntrySubscription.unsubscribe();
      this._textGroupEntrySubscription = undefined;
    }
    if (arg === undefined || textGroupEntry === undefined) {
      return;
    }
    textGroupEntry.LocalTextGroupEntry = arg.LocalTextGroupEntry;

    this.Value = this.SetTextGroupColor(textGroupEntry);
  }

  private SetTextGroupColor(textGroupEntry: TextGroupEntry): string {

    if (isNaN(textGroupEntry.LocalTextGroupEntry.Color)) {
      return '#00000000';
    }
    let colorString: string = Number(textGroupEntry.LocalTextGroupEntry.Color).toString(16);

    // Alpha channel not available or partial
    // fallback if the color is not available
    while (colorString.length < 8) {
      colorString = '0' + colorString;
    }

    return '#' + colorString;
  }

  private UpdateDatapoints(): void {
    this._datapoints.length = 0;

    this.Expressions.forEach(expression => {
      expression.Datapoints.forEach(datapoint => {
        if (!this._datapoints.includes(datapoint)) {
          this._datapoints.push(datapoint);
        }
      });
    });

    this.LastDatapoint = this._datapoints.length === 0 ? undefined : this._datapoints[this._datapoints.length - 1];
  }

  private NotifyElement(propertyChangeType: PropertyChangeType): void {

    if (this.Element != null && !this._noElementUpdates) {
      // This keeps the element updated at the load time.
      // Since the ConvertResults will not notify if the value and status are set already.
      this.TraceService.info(this.traceModule, 'Evaluation to Element notification: Evaluation Property:%s Value:%s, Status:%s, PropertyChangeType:%s  '
        , this._property, this.Value, DatapointStatus[this.Status], PropertyChangeType[propertyChangeType]);
      this.Element.EvaluationChanged(this, propertyChangeType);
    }
  }

  private NotifyAlarms(): void {
    this.Element.UpdateAlarmState();
  }

  private CreateExpressions(node: Node): void {
    for (let i = 0; i < node.childNodes.length; i++) {
      const childNode: Node = node.childNodes[i];
      if (childNode.nodeName === 'Expression') {
        const expression: Expression = new Expression();
        expression.Evaluation = this;
        this._expressions.push(expression);
        expression.Deserialize(childNode);
      }
    }
  }

  private CopyExpressions(evaluation: Evaluation): void {
    const expressions: Expression[] = evaluation.Expressions;
    for (let i = 0; i < expressions.length; i++) {
      const sourceExpression: Expression = expressions[i];
      const newExpression: Expression = new Expression();
      newExpression.Evaluation = this;
      this._expressions.push(newExpression);
      newExpression.CopyFrom(sourceExpression);
    }
  }

  private CreateConditions(node: Node): void {
    for (let i = 0; i < node.childNodes.length; i++) {
      const childNode: Node = node.childNodes[i];
      if (childNode.nodeName === 'Condition') {
        const condition: Condition = new Condition();
        condition.Evaluation = this;
        this._conditions.push(condition);
        condition.Deserialize(childNode);
      }
    }
  }

  private CopyCondition(evaluation: Evaluation): void {
    const conditions: Condition[] = evaluation.Conditions;
    for (let i = 0; i < conditions.length; i++) {
      const sourceCondition: Condition = conditions[i];
      const newCondition: Condition = new Condition();
      newCondition.Evaluation = this;
      this._conditions.push(newCondition);
      newCondition.CopyFrom(sourceCondition);
    }
  }
}

export enum EvaluationProperties {
  Key = `Key`,
  // eslint-disable-next-line
    EvaluationType = `EvaluationType`,
  Enabled = `Enabled`,
  AnimationInterval = `AnimationInterval`,
  AutoLinearRange = `AutoLinearRange`,
  Expressions = `Evaluation.Expressions`,
  Conditions = `Evaluation.Conditions`,
  // eslint-disable-next-line
    PropertyType = `PropertyType`
}
