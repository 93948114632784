import { Component, OnInit } from '@angular/core';
import { IHfwMessage, StateService } from '@gms-flex/core';

@Component({
  selector: 'gms-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit {

  constructor(
    private readonly messageBroker: IHfwMessage,
    private readonly stateService: StateService
  ) {
  }

  public ngOnInit(): void {
    this.stateService.navigateFrames(this.messageBroker).subscribe();
  }

}
