<si-landing-page
  [heading]="title"
  subtitle=""
  [backgroundImageUrl]="landingImage"
  [links]="links"
  [availableLanguages]="languages"
  [translationKey] = "translationKey"
>
<hfw-login-authentication *ngIf="!loginHidden" (changePwd)='changePassword($event)' [linkTermOfUse]="linkTermOfUse" />
<hfw-login-change-pwd *ngIf="loginHidden" [accountName]='userName' [password]='currPassword' (submitResults)='changeSubmitResults($event)' />
<hr>
</si-landing-page>
