<div class="form-group mb-6 position-relative">
  <div class="label-container">
    <span class="form-label">{{ property?.name }}</span>
  </div>
  <div class="value-content">
    <div class="form-control d-flex align-items-center" readonly input>
      <span>{{ property?.defaultText! | translate }}</span>
    </div>
  </div>
</div>
