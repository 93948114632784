<div
  class="drag-and-drop rounded p-6"
  [class.drag-over]="dragOver"
  (drop)="dropHandler($event)"
  (dragover)="dragOverHandler($event)"
  (dragleave)="dragOver = false"
>
  <i class="element-upload mb-6" role="presentation"></i>
  <span class="drag-and-drop-description si-title-2 d-flex">
    <span>{{ uploadDropText | translate }}</span>
    &nbsp;
    <label class="select-file mb-0">
      <span class="select-file" tabindex="0" (keydown.enter)="inputEnterHandler()">{{
        uploadTextFileSelect | translate
      }}</span>
      <input
        #fileInput
        type="file"
        tabindex="-1"
        class="d-none"
        [accept]="accept"
        [multiple]="multiple"
        (change)="inputHandler($event)"
      />
    </label>
  </span>
  @if (maxFileSize || accept) {
    <div class="allowed si-caption mt-6">
      @if (maxFileSize) {
        {{ maxFileSizeText | translate }}: {{ maxFileSizeString }}.
      }
      @if (accept) {
        {{ acceptText | translate }}: {{ accept }}.
      }
    </div>
  }
</div>
