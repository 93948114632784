import { Evaluation, EvaluationType, PropertyType } from '../processor/evaluation';
import { InstanceProperty } from '../processor/gms-symbol-instance-property';
import { GmsRectElementPropertyType } from '../types/gms-element-property-types';
import { GmsElementType } from '../types/gms-element-types';
import { SvgUtility } from '../utilities/parser';
import { Utility } from '../utilities/utility';
import { GmsElement } from './gms-element';

export class GmsRectangle extends GmsElement {

  private _evaluationRadiusX: Evaluation;
  private _evaluationRadiusY: Evaluation;

  private _designValueRadiusX = '0';
  private _designValueRadiusY = '0';

  private _radiusX = '0';
  public get RadiusX(): string {
    return this._radiusX;
  }
  public set RadiusX(value: string) {

    if (this._radiusX !== value) {
      this._radiusX = value;

      this.NotifyPropertyChanged('RadiusX');
    }
  }

  private _radiusY = '0';
  public get RadiusY(): string {
    return this._radiusY;
  }
  public set RadiusY(value: string) {

    if (this._radiusY !== value) {
      this._radiusY = value;

      this.NotifyPropertyChanged('RadiusX');
    }
  }

  constructor() {
    super(GmsElementType.Rectangle);
  }

  public async ShapeChanged(): Promise<any> {
    this.UpdateAdornerDimensions();
    this.NotifyPropertyChanged('ShapeChanged');
  }

  /**
   * Iterate attributes and assign values to properties
   * @param node
   */
  public Deserialize(node: Node): void {
    // RadiusX
    let result: string = SvgUtility.GetAttributeValue(node, GmsRectElementPropertyType.RadiusX);
    if (result !== undefined) {
      this.RadiusX = result;
      this._designValueRadiusX = this.RadiusX;
    }
    // RadiusY
    result = SvgUtility.GetAttributeValue(node, GmsRectElementPropertyType.RadiusY);
    if (result !== undefined) {
      this.RadiusY = result;
      this._designValueRadiusY = this.RadiusY;
    }

    super.Deserialize(node);
    this.DeserializeEvaluations(node);
  }

  public CopyFrom(element: GmsRectangle): void {
    this.IsCopying = true;

    // RadiusX
    this.RadiusX = element._designValueRadiusX;
    this._designValueRadiusX = element._designValueRadiusX;

    // RadiusY
    this.RadiusY = element._designValueRadiusY;
    this._designValueRadiusY = element._designValueRadiusY;

    super.CopyFrom(element);
    this.CopyEvaluations(element);

    this.IsCopying = false;
  }

  public Apply(instanceProperties: InstanceProperty[]): void {

    if (this.InternalId !== null && instanceProperties !== undefined) {
      const instanceProperty: InstanceProperty =
                instanceProperties.find((inst: InstanceProperty) => inst.SourceId === this.InternalId);
      const elementProperties: Map<string, [string, string]> = instanceProperty !== undefined ?
        instanceProperty.ElementProperties : undefined;
      // property type and value
      let propertyValue: [string, string];
      if (elementProperties !== undefined && elementProperties.size > 0) {
        // RadiusX
        propertyValue = elementProperties.get(GmsRectElementPropertyType.RadiusX.toString());
        if (propertyValue !== undefined) {

          let parseValue: number = Utility.ParsePercentage(propertyValue[1], this.Width);
          this.RadiusX = this.RadiusPropertyCoerce(parseValue.toString());
          elementProperties.delete(GmsRectElementPropertyType.RadiusX.toString());
          if (elementProperties.size !== 0) {
            // RadiusY
            propertyValue = elementProperties.get(GmsRectElementPropertyType.RadiusY.toString());
            parseValue = Utility.ParsePercentage(propertyValue[1], this.Height);
            this.RadiusY = this.RadiusPropertyCoerce(parseValue.toString());
            elementProperties.delete(GmsRectElementPropertyType.RadiusY.toString());
            if (elementProperties.size === 0) {
              return;
            }
          }
        }
      }
      super.Apply(instanceProperties);
    }
  }

  protected UpdateEvaluation(evaluation: Evaluation): void {

    if (evaluation === undefined) {
      return;
    }
    super.UpdateEvaluation(evaluation);

    switch (evaluation.Property) {

      case 'RadiusX':
        this.UpdatePropertyRadiusX(evaluation);
        break;
      case 'RadiusY':
        this.UpdatePropertyRadiusY(evaluation);
        break;
      default:
        return;
    }
  }

  private UpdatePropertyRadiusX(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationRadiusX = evaluation;
    }
    const rx: string = Evaluation.GetValue2(this._evaluationRadiusX, this._designValueRadiusX, PropertyType.String);
    const parseValue: number = Utility.ParsePercentage(rx, this.Width);
    this.RadiusX = this.RadiusPropertyCoerce(parseValue.toString());
  }

  private UpdatePropertyRadiusY(evaluation: Evaluation): void {
    if (evaluation !== undefined) {
      this._evaluationRadiusY = evaluation;
    }

    const ry: string = Evaluation.GetValue2(this._evaluationRadiusY, this._designValueRadiusY, PropertyType.String);
    const parseValue: number = Utility.ParsePercentage(ry, this.Height);
    this.RadiusY = this.RadiusPropertyCoerce(parseValue.toString());
  }

  private RadiusPropertyCoerce(radius: string): string {
    if (radius === undefined) {
      radius = '0';
    }
    radius = Utility.ConvertDecimalSeparator(radius);
    return radius;
  }
}
