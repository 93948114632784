import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { AnyCollectionValue } from '@simpl/element-value-types';

import { resetValueCollection } from '../../helpers/property';
import { DateFormat, TimeFormat } from '../../interfaces/date-time-formats';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiBigNumberPropertyComponent } from '../si-big-number-property/si-big-number-property.component';
import { SiBitstringPropertyComponent } from '../si-bitstring-property/si-bitstring-property.component';
import { SiBlindPropertyComponent } from '../si-blind-property/si-blind-property.component';
import { SiBooleanPropertyComponent } from '../si-boolean-property/si-boolean-property.component';
import { SiDatePropertyComponent } from '../si-date-property/si-date-property.component';
import { SiDatetimePropertyComponent } from '../si-datetime-property/si-datetime-property.component';
import { SiEnumPropertyComponent } from '../si-enum-property/si-enum-property.component';
import { SiNumberPropertyComponent } from '../si-number-property/si-number-property.component';
import { SiObjectidPropertyComponent } from '../si-objectid-property/si-objectid-property.component';
import { SiPropertyContainerComponent } from '../si-property-popover/si-property-container.component';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';
import { SiPropertyComponent } from '../si-property/si-property.component';
import { SiStringPropertyComponent } from '../si-string-property/si-string-property.component';
import { SiTimePropertyComponent } from '../si-time-property/si-time-property.component';
import { SiTimedurationPropertyComponent } from '../si-timeduration-property/si-timeduration-property.component';

@Component({
  selector: 'si-collection-property',
  templateUrl: './si-collection-property.component.html',
  styleUrls: ['./si-collection-property.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SiBigNumberPropertyComponent,
    SiBitstringPropertyComponent,
    SiBlindPropertyComponent,
    SiBooleanPropertyComponent,
    SiDatePropertyComponent,
    SiDatetimePropertyComponent,
    SiEnumPropertyComponent,
    SiNumberPropertyComponent,
    SiStringPropertyComponent,
    SiObjectidPropertyComponent,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => SiPropertyComponent),
    SiPropertyContainerComponent,
    SiPropertyPopoverComponent,
    SiTimePropertyComponent,
    SiTimedurationPropertyComponent,
    SiTranslateModule
  ]
})
export class SiCollectionPropertyComponent {
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  @Input({ required: true }) property!: Property<AnyCollectionValue>;
  @Input() valueState: ValueState = 'none';
  @Input() forceReadonly = false;
  @Input() customToggle = false;
  @Output() readonly submitted = new EventEmitter<Property<AnyCollectionValue>>();

  @ViewChild('popover', { read: SiPropertyPopoverComponent }) popover?: SiPropertyPopoverComponent;
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  editValue: any[] = []; // any because it's not a Property but used as one with additional fields

  private formatNumber(length: number): string {
    if (this.property.value.collectionType === 'record') {
      return `<${length}>`;
    } else {
      return `[${length}]`;
    }
  }

  get displayValue(): string {
    return this.formatNumber(
      this.property.value.itemCount ?? this.property.value.value?.length ?? 0
    );
  }

  get readonly(): boolean {
    return (this.forceReadonly || this.property.value.readonly) ?? false;
  }

  stateChange(state: StateChange): void {
    switch (state) {
      case 'open':
      case 'openKeyboard':
        this.editValue = this.popover?.editValue;
        break;
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        resetValueCollection(this.property);
        this.submitted.emit(this.property);
        break;
    }
  }
}
