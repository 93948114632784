import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-ng/translate';

@Component({
  selector: 'si-password-toggle',
  templateUrl: './si-password-toggle.component.html',
  styleUrl: './si-password-toggle.component.scss',
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiPasswordToggleComponent {
  /** Whether to show the visibility toggle icon. */
  @HostBinding('class.show-visibility-icon')
  @Input()
  showVisibilityIcon = true;

  /**
   * Emits the `type` attribute for the `<input>` ('password' | 'text')
   * whenever the password visibility is getting toggled.
   */
  @Output() readonly typeChange = new EventEmitter<string>();

  /** The aria-label (translatable) for the password show icon. */
  @Input() showLabel = $localize`:@@SI_PASSWORD_TOGGLE.SHOW:show password`;
  /** The aria-label (translatable) for the password hide icon. */
  @Input() hideLabel = $localize`:@@SI_PASSWORD_TOGGLE.HIDE:hide password`;

  protected showPassword = false;

  /** The `type` attribute for the `<input>` ('password' | 'text'). */
  get inputType(): string {
    return this.showPassword ? 'text' : 'password';
  }

  protected toggle(): void {
    this.showPassword = !this.showPassword;
    this.typeChange.emit(this.inputType);
  }
}
