export class Constants {
  // gmsText
  public static VALUE_NOTANUMBER = '#NAN';
  public static FORMAT_ERROR = '#FORMAT';
  public static FORMAT_ENG = '#ENG';
  public static GENERAL_ERROR = '#COM';
  // gmsCommand
  public static priority = 'PRIORITY';
  public static maxStringLength = 2048;
  public static borderDefaultColor = '#808080';

  // colors
  public static transparentColor = 'transparent';

  /**
   * bool false default text value; numeric indicates no text was found
   */
  public static defaultBoolFalseText = '0';

  /**
   * Value used for bool false when nothing else exists
   */
  public static defaultBoolFalseNumericValue = 0;

  /**
   * bool true default text value; numeric indicates no text was found
   */
  public static defaultBoolTrueText = '1';

  /**
   * Value used for bool true when nothing else exists
   */
  public static defaultBoolTrueNumericValue = 1;
}
