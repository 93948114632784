import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import { SiWizardComponent } from './si-wizard.component';

@Component({
  selector: 'si-wizard-step',
  templateUrl: './si-wizard-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class SiWizardStepComponent implements OnChanges {
  @Input() heading = '';
  @Input({ transform: booleanAttribute }) isValid = true;
  @Input({ transform: booleanAttribute }) isNextNavigable = true;
  @Input({ transform: booleanAttribute }) failed = false;

  @Output() readonly next = new EventEmitter();
  @Output() readonly back = new EventEmitter();
  @Output() readonly save = new EventEmitter();

  private cdRef = inject(ChangeDetectorRef);
  private parent?: SiWizardComponent;
  private _isActive = false;

  /**
   * Whether this step is currently active or not.
   */
  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
    this.cdRef.markForCheck();
  }

  ngOnChanges(): void {
    this.parent?.notifyChildrenUpdated();
  }

  /**
   * Register this wizard step to its parent {@link SiWizardComponent}.
   * Should usually be done automatically by the {@link SiWizardComponent}.
   * @param parent The parent this belongs to.
   */
  registerParent(parent: SiWizardComponent): void {
    this.parent = parent;
  }
}
