import { NgModule }      from "@angular/core";
import { IPreselectionService, IStorageService } from "@gms-flex/core";
import { TrendPreselectService }  from "./services/trend-preselect.service";
import { TrendStorageService }  from "./services/trend-storage.service";

@NgModule({
  providers:  [{ provide: IPreselectionService, useClass: TrendPreselectService, multi: true },
  { provide: IStorageService, useClass: TrendStorageService, multi: true }]
})
export class GmsTrendRootServicesModule { }
