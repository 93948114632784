import { EventEmitter } from '@angular/core';
import { NavbarItem, ViewType } from '@simpl/element-ng';
import { Observable } from 'rxjs';

import { ThumbnailRequestInfo } from './thumbnail.data.model';

export abstract class SearchedItem {
  public initCompleted!: EventEmitter<SearchedItem>;
  public thumbnailGenerationCompleted!: EventEmitter<SearchedItem>;

  public name!: string;
  public description!: string;
  public location!: string;
  public objectID!: string;
  public designation!: string;
  public systemID!: number;
  public viewID!: number;
  public localStorageId!: string;
  public content!: string;
  public imageData!: string;
  public locationPath!: string;
  public designationPath!: string;
  public path!: string;
  public selectedNodeDesignation!: string;
  public selectedNodeLocation!: string;
  public imageDataType!: ImageDataType;
  public abstract onInit(): void;
  // clone method should be appropriate so as seperate
  // object refered while rendering on UI
  public abstract clone(): SearchedItem;

  public abstract generateThumbnail(): Observable<void>;
  public abstract onTileClick(): void;
}

export declare const enum SearchTypes {
  DESCRIPTION,
  NAME,
  ALIAS
}

export declare const enum ImageDataType {
  ICON,
  BASE64,
  PATH
}

export class ContentActionControlConfig {
  public show?: boolean;
  public viewType: ViewType = 'expanded';
  public primaryActions: NavbarItem[] = [];
  public secondaryActions: NavbarItem[] = [];
}

export class SearchParameters {
  public systemId!: number;
  public searchString!: string;
  public searchType!: SearchTypes;
  public objectTypeFilter!: string;
  public searchLimit!: number;
  public currentPage!: number;
  public viewId!: number;
  public designation!: string;
}

export class TileSearchRequest {
  public parameters: any;
  public searchWords!: string;
}

export class TileSearchResponse {
  public request!: TileSearchRequest;
  public searchedItems: SearchedItem[];
  public totalResults!: number;
  constructor(_request: TileSearchRequest) {
    this.searchedItems = new Array<SearchedItem>();
  }
}

export class View {
  public id!: number;
  public name!: string;
}

export class ViewInfo {
  public systemID!: number;
  public viewID!: number;
  public viewName!: string;
}
export enum ScrollDirection {
  Top,
  Bottom
}

export class TileViewScrolled {
  public direction!: ScrollDirection;
  public searchLimit!: number;
  public pageNumber!: number;
  public scrollDirection!: ScrollDirection;
}

export abstract class TileV {
  public initCompleted: EventEmitter<ThumbnailRequestInfo> = new EventEmitter<ThumbnailRequestInfo>();
  // 1. this method will be called once search activity is complete and for each tile it will call this method seperately
  // 2. here user can write additional logic if he want to do some operation so as to initialize the Tile by using
  // the data members which are filled by search control.
  // 3. it is expected from user to call initCompleted method after completion of onInit so as control will call image generation.
  // 4. The ThumbnailRequestInfo 'localStorageId' need to be filled for the tile you are working,
  // so as to map it further & display appropriate image to appropriate tile
  // eg get data, generate trend series ID.
  public abstract onInit(): void;
  public abstract generateThumbnail(): void;
  public abstract onTileClick(): void;
}
