<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="!isValid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    <!-- FIXME: `si-time-duration` modifies readonly reference, correct upstream -->
    <div
      class="form-control d-flex align-items-center"
      [attr.readonly]="readonly"
      [attr.tabindex]="readonly ? '' : '0'"
      (keydown.enter)="popover.open(true)"
      input
    >
      <span>
        @if (property.defaultText || modelValue.value === undefined) {
          {{ property.defaultText || property.value.altText }}
        } @else {
          {{ displayValue }} [{{ property.value.format }}]
        }
      </span>
    </div>
    @if (popover.isActive) {
      <si-time-duration [value]="modelValue" (submitEnter)="popover.submitEnter()" body />
    }
  </si-property-popover>
</form>
