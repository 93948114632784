import { EntityTypeEquipmentType } from '../shared/base.model';
import { EquipmentTypeBx } from './equipment-type-proxy.model';

export class EquipmentType {

  constructor(private readonly equipmentType: EquipmentTypeBx) {
  }

  public get id(): string {
    return this.equipmentType.id;
  }

  public get type(): EntityTypeEquipmentType {
    return this.equipmentType.type;
  }

  public get name(): string {
    return this.equipmentType.attributes.name;
  }

  public get domain(): string {
    return this.equipmentType.attributes.domain;
  }

  public get description(): string {
    return this.equipmentType.attributes.description;
  }

  public get hasPartition(): boolean {
    return (this.equipmentType.relationships.hasPartition !== undefined);
  }

  public get partitionId(): string | undefined {
    return this.equipmentType.relationships.hasPartition ? this.equipmentType.relationships.hasPartition.data.id : undefined;
  }

  public get hasParentEquipmentType(): boolean {
    return (this.equipmentType.relationships.hasParentType !== undefined);
  }

  public get parentEquipmentTypeId(): string | undefined {
    return this.equipmentType.relationships.hasParentType ? this.equipmentType.relationships.hasParentType.data.id : undefined;
  }

}
