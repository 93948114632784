import { Injectable, OnDestroy } from '@angular/core';
import { BrowserObject, CnsHelperService, CnsLabel, CnsLabelEn } from '@gms-flex/services';
import { isNullOrUndefined } from '@gms-flex/services-common';
import { TreeItem } from '@simpl/element-ng';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EasyNavigationItem } from '../interfaces/easy-navigation.item';

@Injectable()
export class CnsLabelService implements OnDestroy {
  public unsubscribe: Subject<void> = new Subject<void>();
  public updateTreeItemTitles: Subject<EasyNavigationItem[]> = new Subject<EasyNavigationItem[]>();
  private label: CnsLabel;
  private items: EasyNavigationItem[] = [];

  constructor(private readonly cnsHelperService: CnsHelperService) {
    this.cnsHelperService
      .activeCnsLabel
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.label = this.cnsHelperService.activeCnsLabelValue;
          this.updateTreeItemTitles.next(this.items);
        }
      });

    this.updateTreeItemTitles
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (treeItems: TreeItem[]) => this.processTreeItemLabels(treeItems)
      });
  }

  public initEasyNavigationItems(items: EasyNavigationItem[]): void {
    this.items = items;
  }

  // NOTE: Check if this method is called
  public ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  private processTreeItemLabels(treeItems: EasyNavigationItem[]): void {
    for (const treeItem of treeItems) {
      if (treeItem.label === '-') {
        continue;
      }

      treeItem.label = this.assignHeading(treeItem?.customData?.refCnsNode?.bo);
      if (treeItem?.children?.length > 0) {
        this.processTreeItemLabels(treeItem.children);
      }
    }
  }

  private assignHeading(tileItem: BrowserObject): string {
    if (isNullOrUndefined(this.label)) {
      return tileItem.Name;
    } else {
      if (
        this.label.cnsLabel === CnsLabelEn.Description ||
        this.label.cnsLabel === CnsLabelEn.DescriptionAndAlias ||
        this.label.cnsLabel === CnsLabelEn.DescriptionAndName
      ) {
        return tileItem.Descriptor;
      } else {
        return tileItem.Name;
      }
    }
  }
}
