import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiSidePanelService {
  private contentSubject = new BehaviorSubject<Portal<any> | undefined>(undefined);
  readonly content$ = this.contentSubject.asObservable();

  private openSubject = new BehaviorSubject<boolean>(false);
  readonly isOpen$ = this.openSubject.asObservable();

  private tempContentSubject = new BehaviorSubject<Portal<any> | undefined>(undefined);
  readonly tempContent$ = this.tempContentSubject.asObservable();

  private tempContentClosed?: Subject<void>;

  enableMobile = false;

  setSidePanelContent(portal: Portal<any> | undefined): void {
    this.contentSubject.next(portal);
  }

  open(): void {
    this.hideTemporaryContent();
    this.openSubject.next(true);
  }

  close(): void {
    if (this.hideTemporaryContent()) {
      return;
    }
    this.openSubject.next(false);
  }

  toggle(): void {
    this.hideTemporaryContent();
    this.openSubject.next(!this.openSubject.value);
  }

  isOpen(): boolean {
    return this.openSubject.value;
  }

  isTemporaryOpen(): boolean {
    return !!this.tempContentSubject.value;
  }

  showTemporaryContent(portal: Portal<any> | undefined): Observable<void> {
    this.hideTemporaryContent();
    this.tempContentSubject.next(portal);

    if (portal) {
      this.tempContentClosed = new Subject();
      return this.tempContentClosed.asObservable();
    }
    return EMPTY;
  }

  hideTemporaryContent(): boolean {
    if (!this.isTemporaryOpen()) {
      return false;
    }
    if (this.tempContentClosed) {
      const sub = this.tempContentClosed;
      this.tempContentClosed = undefined;
      sub.next();
      sub.complete();
    }
    this.tempContentSubject.next(undefined);
    return true;
  }
}
