import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SummaryBarSnapInComponent } from "./snapin/summary-bar-snapin.component";

const summaryBarSnapInRoutes: Routes = [
  {
    path: "",
    component: SummaryBarSnapInComponent
  }
];

export const summarybarSnapInRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(summaryBarSnapInRoutes);
