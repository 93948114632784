//
// Defines how a value is displayed
//
export enum GmsTextType {
  //  Presents text displayed as a formatted value
  // eslint-disable-next-line id-blacklist
  Number = 0,
  //  Presents text displayed as a text with no formatting
  Text = 1,
  //  Presents text displayed with only the name or description
  ShortName = 2,
  //  Presents text displayed with the entire reference path
  LongName = 3,
  //  Presents text displayed as the default icon associated with it
  Icon = 4,
  //  Presents text displayed as the minimum value
  Min = 5,
  //  Presents text displayed as the maximum value
  Max = 6,
  //  Presents text displayed as the units
  Units = 7,
  //  Presents text displayed as the precision
  Precision = 8,
  //  Presents text displayed as translated text
  TranslatedText = 9,
  //  Presents text displayed as property description
  PropertyDescription = 10,
  //  Presents text displayed as the alias
  Alias = 11,
  // Presents text displayed as a tag
  Tags = 12
}

// Summary:
//     Specifies whether text wraps when it reaches the edge of the containing box
export enum GmsTextWrappingType {
  // Summary:
  //     Line-breaking occurs if the line overflows beyond the available block width.
  //     However, a line may overflow beyond the block width if the line breaking
  //     algorithm cannot determine a line break opportunity, as in the case of a
  //     very long word constrained in a fixed-width container with no scrolling allowed.
  WrapWithOverflow = 0,
  //
  // Summary:
  //     No line wrapping is performed.
  NoWrap = 1,
  //
  // Summary:
  //     Line-breaking occurs if the line overflows beyond the available block width,
  //     even if the standard line breaking algorithm cannot determine any line break
  //     opportunity, as in the case of a very long word constrained in a fixed-width
  //     container with no scrolling allowed.
  Wrap = 2
}

// Summary:
//     Describes how text is trimmed when it overflows the edge of its containing
//     box.
export enum GmsTextTrimmingType {
  // Summary:
  //     Text is not trimmed.
  None = 0,
  //
  // Summary:
  //     Text is trimmed at a character boundary. An ellipsis (...) is drawn in place
  //     of remaining text.
  CharacterEllipsis = 1,
  //
  // Summary:
  //     Text is trimmed at a word boundary. An ellipsis (...) is drawn in place of
  //     remaining text.
  WordEllipsis = 2
}

// Summary:
//     Specifies whether the text in the object is left-aligned, right-aligned,
//     centered, or justified.
export enum GmsTextAlignmentType {
  // Summary:
  //     Default. Text is aligned to the left.
  Left = 0,
  //
  // Summary:
  //     Text is aligned to the right.
  Right = 1,
  //
  // Summary:
  //     Text is centered.
  Center = 2,
  //
  // Summary:
  //     Text is justified
  Justify = 3,
  //
  // Summary:
  //     Text is aligned to the top.
  Top = 4,
  //
  // Summary:
  //     Text is aligned to the bottom.
  Bottom = 5
}

export enum GmsAnchorType {
  Start = `start`,
  Middle = `middle`,
  End = `end`
}
