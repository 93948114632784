import { Injectable } from '@angular/core';

@Injectable()
export class TestDelegateOmService {

  public check(messageTypes: string[]): boolean {
    if (messageTypes.length === 1) {
      if (messageTypes[0] === 'Customer') {
        return true;
      }
    }
    return false;
  }
}
