import { ModalRef } from '@simpl/element-ng';

const elementNotHidden = (el: HTMLElement): boolean =>
  !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden') && !!el.offsetWidth;

const focusable = (el?: HTMLElement): boolean => {
  if (!el) {
    return false;
  }
  const ti = el.getAttribute('tabindex');
  return !!ti && ti !== '-1' && elementNotHidden(el);
};

export const getFocusableElements = (element?: HTMLElement, includeSelf = false): HTMLElement[] => {
  if (!element) {
    return [];
  }
  const elements = Array.from(
    element.querySelectorAll(
      'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    ) as NodeListOf<HTMLElement>
  ).filter(elementNotHidden);
  if (includeSelf && focusable(element)) {
    elements.unshift(element);
  }
  return elements;
};

export const focusFirstFocusable = (
  element?: HTMLElement,
  includeSelf = false
): HTMLElement | undefined => {
  const firstEl = includeSelf && focusable(element) ? element : getFocusableElements(element)[0];
  firstEl?.focus();
  return firstEl;
};

export const focusDialogContent = (modalRef: ModalRef, byKeyboard: boolean): void => {
  modalRef.shown.subscribe(element => {
    const modalBody = (element.nativeElement as HTMLElement).querySelector<HTMLElement>(
      '.modal-body'
    )!;
    const firstElem = focusFirstFocusable(modalBody, !byKeyboard);
    if (!byKeyboard) {
      firstElem?.blur();
    }
  });
};
