export enum TraceChannel {
  Viewer = 'gmsSnapins_GraphicsViewer',
  Services = 'gmsSnapins_GraphicsServices',
  Model = 'gmsSnapins_GraphicsModel',
  Component = 'gmsSnapins_GraphicsComponent',
  Processor = 'gmsSnapins_GraphicsProcessor',
  Timings = 'gmsSnapins_GraphicsTimings',
  Scripting = 'gmsSnapins_GraphicsScripting',
  Commanding = 'gmsSnapins_GraphicsCommanding',
  AlarmNotification = 'gmsSnapins_AlarmNotification'
}
