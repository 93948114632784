import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlarmsContainer } from '../common/interfaces/AlarmsContainer';
import { GmsAlarm } from '../elements/gms-alarm';

@Component({
  selector: '[gms-alarms-container]',
  template: `<svg:g [attr.visibility]="(alarmsContainer.toggleAlarmsVisibility | async) ? 'inherit' : 'hidden'" *ngFor="let alarmItem of alarmsContainer.Alarms;trackBy:trackByFunc">
                    <g gms-alarm [alarm]="alarmItem"/>
               </svg:g>
               `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsAlarmsContainerComponent implements OnInit, OnDestroy {
  @Input() public alarmsContainer: AlarmsContainer = undefined;
  @Output() public readonly openAlarmPopover: EventEmitter<any> = new EventEmitter<any>();

  private alarmsChangedSubscription: Subscription = undefined;

  constructor(public changeDetector: ChangeDetectorRef, private readonly zone: NgZone) {

  }

  public trackByFunc(index: number, item: GmsAlarm): string {
    return item.Id;
  }

  public ngOnInit(): void {
    this.alarmsChangedSubscription = this.alarmsContainer.alarmsChanged
      .subscribe(value => { this.zone.runOutsideAngular(() => this.OnAlarmsChanged(value)); });
  }

  public ngOnDestroy(): void {
    if (this.alarmsChangedSubscription !== undefined) {
      this.alarmsChangedSubscription.unsubscribe();
      this.alarmsChangedSubscription = undefined;
    }
  }

  private OnAlarmsChanged(propertyChange: string): void {
    this.changeDetector.detectChanges();
  }
}
