import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  SiAutocompleteDirective,
  SiAutocompleteListboxDirective,
  SiAutocompleteOptionDirective
} from '@simpl/element-ng/autocomplete';
import { BehaviorSubject, Subscription } from 'rxjs';

import { SiTypeaheadDirective } from './si-typeahead.directive';
import { TypeaheadMatch } from './si-typeahead.model';

@Component({
  selector: 'si-typeahead',
  templateUrl: './si-typeahead.component.html',
  styleUrl: './si-typeahead.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    SiAutocompleteListboxDirective,
    SiAutocompleteOptionDirective,
    NgTemplateOutlet
  ]
})
export class SiTypeaheadComponent implements OnDestroy, OnInit, AfterViewInit {
  cdRef = inject(ChangeDetectorRef);
  parent = inject(SiTypeaheadDirective);
  matches = new BehaviorSubject<TypeaheadMatch[]>([]);
  matchesArray: TypeaheadMatch[] = [];
  query = '';

  // The offset on the side based on the icon of the input field.
  iconOffset = 0;
  protected multiselect = false;
  // Store the number of rendered matches to know if the height needs to be recalculated.
  private renderedMatchesLength = 0;

  private subscription!: Subscription;

  @ViewChild('typeahead', { static: true }) private typeaheadElement!: ElementRef;

  protected autocompleteDirective = inject(SiAutocompleteDirective);

  ngOnInit(): void {
    this.multiselect = !!this.parent.typeaheadMultiSelect;
    this.iconOffset = this.parent.iconOffset;

    // Subscribe to the matches and store them in an array, also reset the active index to prevent conflicts.
    this.subscription = this.matches.subscribe(matches => {
      this.matchesArray = this.parent.typeaheadOptionsLimit
        ? matches.slice(0, this.parent.typeaheadOptionsLimit)
        : matches.slice();

      this.cdRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.setHeight(this.typeaheadElement);
    this.renderedMatchesLength = this.matchesArray.length;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('mousedown', ['$event'])
  protected onMouseDown(event: Event): void {
    event.preventDefault();
  }

  /*
   * Set the height of the element passed to it (typeahead) if there are items displayed,
   * the number of displayed items changed and it is scrollable.
   */
  protected setHeight(element: ElementRef): void {
    if (this.matchesArray.length !== 0 && this.matchesArray.length !== this.renderedMatchesLength) {
      if (
        this.parent.typeaheadScrollable &&
        this.parent.typeaheadOptionsInScrollableView < this.matchesArray.length
      ) {
        const computedStyle = getComputedStyle(element.nativeElement);
        const matchComputedStyle = getComputedStyle(element.nativeElement.firstElementChild);
        const matchHeight = parseFloat(matchComputedStyle.height || '0');
        const paddingTop = parseFloat(computedStyle.paddingTop || '0');
        const paddingBottom = parseFloat(computedStyle.paddingBottom || '');
        const height = this.parent.typeaheadOptionsInScrollableView * matchHeight;
        element.nativeElement.style.maxBlockSize = `${
          height + paddingTop + paddingBottom + this.parent.typeaheadScrollableAdditionalHeight
        }px`;
      } else {
        element.nativeElement.style.maxBlockSize = 'auto';
      }
    }
  }

  // Gets called when a match is selected by clicking on it.
  protected selectMatch(match: TypeaheadMatch): void {
    this.parent.selectMatch(match);
  }
}
