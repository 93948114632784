import { Subject, Subscription } from 'rxjs';

export class WildCardReferenceItem {

  public resolved: Subject<WildCardReferenceItem> = new Subject<WildCardReferenceItem>();

  // Key - reference, Value the index
  public _wildCardReferencesPolled: Map<string, number> = new Map<string, number>();

  // Key - reference, Value the index
  private readonly _nextWildCardReferencesToPoll: string[] = new Array<string>();
  public get NextWildCardReferencesToPoll(): string[] {
    return this._nextWildCardReferencesToPoll;
  }

  private _isResolved = false;
  public get IsResolved(): boolean {
    return this._isResolved;
  }
  public set IsResolved(value: boolean) {
    if (this._isResolved !== value) {
      this._isResolved = value;

      if (this._isResolved) {
        this.NotifyResolved();
      }
    }
  }

  // Holds the wild card reference
  private readonly _wildCardReference: string = undefined;
  public get WildCardReference(): string {
    return this._wildCardReference;
  }

  // Advances by 20
  private _currentPollStop = 0;
  private readonly _batchSize: number = 20;

  // wildCardReference - like dpId[*]
  constructor(wildCardReference: string) {
    this._wildCardReference = wildCardReference;
    this.SetNextWildCardReferencesToPoll(); // Set the first batch of references
  }

  public get AvailableIndexes(): number[] {
    let availableIndexes: number[] = new Array<number>();

    if (this._wildCardReferencesPolled.size > 0) {
      availableIndexes = Array.from(this._wildCardReferencesPolled.values());
    }

    return availableIndexes;
  }

  // Mark completion of the index resolution the WilCardReference
  public SetResolved(invalidWildCardRefs: string[]): void {

    // Removed the invalid ones from the poll list.
    for (let i = 0; i < invalidWildCardRefs.length; i++) {
      const wildCardReferenceToRemove: string = invalidWildCardRefs[i];
      if (this._wildCardReferencesPolled.has(wildCardReferenceToRemove)) {
        this._wildCardReferencesPolled.delete(wildCardReferenceToRemove);
      }
    }

    this._isResolved = true;

    // Notifies the Replication with the valid indices.
    this.NotifyResolved();
  }

  // Adds valid indices
  public SetBatchValid(): void {
    this.SetNextWildCardReferencesToPoll(); // Prepare the next batch to process.
  }

  public Destroy(): void {
    this._wildCardReferencesPolled.clear();
    this._wildCardReferencesPolled = undefined;
    this._nextWildCardReferencesToPoll.length = 0;
  }

  private SetNextWildCardReferencesToPoll(): void {
    let iterator: number = this._currentPollStop;
    const uptoIndex: number = this._currentPollStop + this._batchSize;
    this._nextWildCardReferencesToPoll.length = 0;
    for (iterator; iterator < uptoIndex; iterator++) {
      const wildcardReferenceToPoll: string = this._wildCardReference.concat('[' + iterator + '];');
      this._nextWildCardReferencesToPoll.push(wildcardReferenceToPoll);
      this._wildCardReferencesPolled.set(wildcardReferenceToPoll, iterator);
    }

    this._currentPollStop = iterator;
  }

  private NotifyResolved(): void {
    if (this.resolved.observers !== null && this.resolved.observers.length > 0) {
      this.resolved.next(this);
    }
  }
}
