import {
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { SiLoadingSpinnerComponent } from '@simpl/element-ng';
import { DisplayMode } from '@simpl/object-browser-ng/property-viewer';
import { Subscription } from 'rxjs';

import { EMPTY_SCENE_MODEL } from '../helpers/default-models';
import { SceneModel } from '../interfaces/scene-editor';
import { SiSceneEditorService } from '../services/index';
import { SiSceneMasterDetailComponent } from './si-scene-master-detail/si-scene-master-detail.component';

@Component({
  selector: 'si-scene-editor',
  templateUrl: './si-scene-editor.component.html',
  styleUrls: ['./si-scene-editor.component.scss'],
  standalone: true,
  imports: [SiLoadingSpinnerComponent, SiSceneMasterDetailComponent],
  providers: [SiSceneEditorService]
})
export class SiSceneEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() objectId!: string;
  @Input() description?: string;
  @Input() displayMode!: DisplayMode;

  serverSubscription?: Subscription;

  model: SceneModel = EMPTY_SCENE_MODEL;

  isLoading = false;

  private siSceneService = inject(SiSceneEditorService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId) {
      this.handleServerModelUpdate();
    }
  }

  ngOnInit(): void {
    this.handleServerModelUpdate();
  }

  ngOnDestroy(): void {
    this.serverSubscription?.unsubscribe();
  }

  private handleServerModelUpdate(): void {
    this.serverSubscription?.unsubscribe();
    this.isLoading = true;
    this.serverSubscription = this.siSceneService.getSceneModel(this.objectId).subscribe(
      model => {
        this.isLoading = false;
        // Prevent model becoming null
        this.model = model || EMPTY_SCENE_MODEL;
      },
      error => {
        this.isLoading = false;
      }
    );
  }
}
