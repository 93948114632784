<div class="d-flex">
  @if (showSave) {
    <button
      type="button"
      class="element-save btn btn-circle btn-sm btn-secondary"
      [disabled]="saveDisabled"
      [attr.aria-label]="saveLabel | translate"
      (click)="save.emit()"
    ></button>
  }

  <button
    type="button"
    class="ms-auto element-cancel btn btn-circle btn-sm btn-secondary"
    [disabled]="cancelDisabled"
    [attr.aria-label]="cancelLabel | translate"
    (click)="cancel.emit()"
  ></button>
  <button
    type="button"
    class="element-ok btn btn-circle btn-sm btn-primary ms-4"
    [disabled]="confirmDisabled"
    [attr.aria-label]="confirmLabel | translate"
    (click)="confirm.emit()"
  ></button>
</div>
