import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointAttributes } from "src/app/bx-services/point/point-proxy.model";

export interface DccUnit {
  id: number;
  textTable: string;
  unit: string;
}

export const enum DccDataType {
  BasicBool = 'BasicBool',
  BasicFloat = 'BasicFloat',
  BasicInt = 'BasicInt',
  BasicUint = 'BasicUint',
  BasicString = 'BasicString',
  ExtendedReal = 'ExtendedReal',
  ExtendedBool = 'ExtendedBool',
  ExtendedInt = 'ExtendedInt',
  ExtendedEnum = 'ExtendedEnum',
  ExtendedBitString = 'ExtendedBitString'
}

/* eslint-disable id-blacklist */
export const enum PointDataType {
  Number = 'number',
  Boolean = 'boolean',
  Integer = 'integer',
  String = 'string'
}
/* eslint-enable id-blacklist */

export abstract class PropertyMapper {
  private static unitCounter = 0;
  private readonly qudtUnitToDccUnit: Map<string, DccUnit> = new Map<string, DccUnit>();
  private readonly unitTextTable = 'TxG_Unit';

  constructor(private readonly qudtPipe: SiqudtUnitConversionPipe) { }

  public static getDccType(pointAttributes: PointAttributes): DccDataType {
    if (pointAttributes.dataType === PointDataType.Number) {
      if ((pointAttributes.minimum !== undefined) || (pointAttributes.maximum !== undefined) ||
      (pointAttributes.unit !== undefined && pointAttributes.unit !== 'qudt:UNITLESS')) {
        return DccDataType.ExtendedReal;
      } else {
        return DccDataType.BasicFloat;
      }
    } else if (pointAttributes.dataType === PointDataType.Integer) {
      if (pointAttributes.enum !== undefined) {
        return DccDataType.ExtendedEnum;
      } else {
        if (((pointAttributes.minimum !== undefined) && (pointAttributes.maximum !== undefined)) ||
          (pointAttributes.unit !== undefined && pointAttributes.unit !== 'qudt:UNITLESS')) {
          return DccDataType.ExtendedInt;
        } else {
          return DccDataType.BasicInt;
        }
      }
    } else if (pointAttributes.dataType === PointDataType.Boolean) {
      if (pointAttributes.enum !== undefined) {
        return DccDataType.ExtendedBool;
      } else {
        return DccDataType.BasicBool;
      }
    } else if (pointAttributes.dataType === PointDataType.String) {
      return DccDataType.BasicString;
    } else {
      /* eslint-disable-next-line no-console */
      console.error(`PropertyMapperBxToGmsService.getDccType(): Point data type not handled: ${pointAttributes.dataType}`);
      return DccDataType.BasicString;
    }
  }

  protected showDisplayValue(quality?: number): boolean {
    if (quality) {
      return (quality === 0 || quality === 1);
    } else {
      return true;
    }
  }

  protected getDccUnit(qudtUnit: string): DccUnit | undefined {
    if (qudtUnit === undefined) {
      return undefined;
    }

    if (this.qudtUnitToDccUnit.has(qudtUnit)) {
      return this.qudtUnitToDccUnit.get(qudtUnit);
    } else {
      this.qudtUnitToDccUnit.set(qudtUnit,
        { id: PropertyMapper.unitCounter++, textTable: this.unitTextTable, unit: String(this.qudtPipe.transform(qudtUnit)) });
      return this.qudtUnitToDccUnit.get(qudtUnit);
    }
  }

  protected evalResolution(pointAttributes: PointAttributes): number | undefined {
    return !isNullOrUndefined(pointAttributes.precision) ? Math.log10(1 / (parseFloat(pointAttributes.precision))) : undefined;
  }

}
