import { Injectable } from '@angular/core';
import { ConnectionState, LicenseProxyServiceBase, LicenseWsi, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject } from 'rxjs';

@Injectable()
export class LicenseSubscriptionBxSubstituteProxyService extends LicenseProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _license: Subject<LicenseWsi> = new Subject<LicenseWsi>();

  public constructor(
    private readonly traceService: TraceService) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.traceService.info(TraceModules.license, 'LicenseSubscriptionBxSubstituteProxyService created.');
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public licenseNotification(): Observable<LicenseWsi> {
    return this._license.asObservable();
  }

  public unsubscribeLicense(): Observable<boolean> {
    this.traceService.info(TraceModules.license, 'LicenseSubscriptionBxSubstituteProxyService.unsubscribeLicense() called');

    return scheduled([true], asapScheduler);
  }

  public subscribeLicense(): Observable<boolean> {
    this.traceService.info(TraceModules.license, 'LicenseSubscriptionBxSubstituteProxyService.subscribeLicense() called.');

    return scheduled([true], asapScheduler);
  }
}
